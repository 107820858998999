// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type UpdateTelehealthVisitDetailsMutationVariables = Types.Exact<{
  visitId: Types.Scalars["BigInt"]["input"];
  visitType: Types.TelehealthType;
  videoLink?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  visitDetails?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type UpdateTelehealthVisitDetailsMutation = {
  __typename?: "mutation_root";
  editTelehealthDetails?: {
    __typename?: "EditTelehealthDetails";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const UpdateTelehealthVisitDetailsDocument = gql`
  mutation UpdateTelehealthVisitDetails(
    $visitId: BigInt!
    $visitType: TelehealthType!
    $videoLink: String
    $visitDetails: String
  ) {
    editTelehealthDetails(
      visitId: $visitId
      visitType: $visitType
      videoLink: $videoLink
      visitDetails: $visitDetails
    ) {
      ok
      message
    }
  }
`;
export type UpdateTelehealthVisitDetailsMutationFn = Apollo.MutationFunction<
  UpdateTelehealthVisitDetailsMutation,
  UpdateTelehealthVisitDetailsMutationVariables
>;

/**
 * __useUpdateTelehealthVisitDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateTelehealthVisitDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTelehealthVisitDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTelehealthVisitDetailsMutation, { data, loading, error }] = useUpdateTelehealthVisitDetailsMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      visitType: // value for 'visitType'
 *      videoLink: // value for 'videoLink'
 *      visitDetails: // value for 'visitDetails'
 *   },
 * });
 */
export function useUpdateTelehealthVisitDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTelehealthVisitDetailsMutation,
    UpdateTelehealthVisitDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTelehealthVisitDetailsMutation,
    UpdateTelehealthVisitDetailsMutationVariables
  >(UpdateTelehealthVisitDetailsDocument, options);
}
export type UpdateTelehealthVisitDetailsMutationHookResult = ReturnType<
  typeof useUpdateTelehealthVisitDetailsMutation
>;
export type UpdateTelehealthVisitDetailsMutationResult =
  Apollo.MutationResult<UpdateTelehealthVisitDetailsMutation>;
export type UpdateTelehealthVisitDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTelehealthVisitDetailsMutation,
    UpdateTelehealthVisitDetailsMutationVariables
  >;
