import { Stack, Typography } from "@mui/material";
import { partition } from "lodash";
import AdditionalInfo from "@/components/common/additionalInfo/additionalInfo";
import {
  GFE_CHIP_TEXT,
  GFE_SERVICE_STATUS_ICON,
  mapToGfeChipType,
} from "@/components/common/gfe/gfeIndicationChip";
import CheckboxList, {
  CheckboxItem,
} from "@/components/common/list/checkboxList";
import ListAccordion, {
  DefaultParentNodeTitle,
} from "@/components/common/list/listAccordion";
import FilterDialog, {
  FilterDialogBaseProps,
} from "@/components/common/modals/filterDialog";
import FilterDialogWithSelectAll, {
  FilterDialogWithSelectAllProps,
} from "@/components/common/modals/filterDialogWithSelectAll";
import { GFE_STATUS_LABEL } from "@/components/serviceFlow/visitDetails/forms/gfeStatusChip";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import { GfeStatusType } from "@/store/views/serviceFlow/reviewVisits/filters/gfeStatusFilterStore";
import { GfeStatus, ServiceMenuItemGfeStatus } from "@/types/gfe";

// TODO: Adjust when removing newGfeFlowV1 and newGfeFlowV2 feature flags
export type GfeStatusDialogProps = FilterDialogBaseProps &
  FilterDialogWithSelectAllProps & {
    options: GfeStatusType[];
    selectedStatuses: GfeStatusType[];
    onStatusChange: (id: GfeStatusType, value: boolean) => void;
  };

const SHARED_GFE_STATUSES: GfeStatusType[] = [
  GfeStatus.INCOMPLETE,
  GfeStatus.WAITING_FOR_GFE,
];

function GfeStatusDialog({
  options,
  selectedStatuses,
  onStatusChange,
  ...dialogProps
}: GfeStatusDialogProps) {
  const { newGfeFlowV1, newGfeFlowV2, featureFlagsLoading } = useFeatureFlags();

  if (featureFlagsLoading) return null;

  if (newGfeFlowV2)
    return (
      <FilterDialogNew
        options={options}
        selectedStatuses={selectedStatuses}
        onStatusChange={onStatusChange}
        {...dialogProps}
      />
    );

  const items: CheckboxItem<GfeStatusType>[] = options.map((status) => ({
    id: status,
    label:
      newGfeFlowV1 && !SHARED_GFE_STATUSES.includes(status)
        ? GFE_STATUS_LABEL[status] + " (old status only)"
        : GFE_STATUS_LABEL[status],
    onChange: (id, checked) => onStatusChange(id, checked),
    isChecked: selectedStatuses.includes(status),
  }));

  return (
    <FilterDialog
      {...dialogProps}
      title="Filter by GFE Status"
      content={<CheckboxList items={items} />}
    />
  );
}

export default GfeStatusDialog;

export const isNewGfeStatus = (
  status: string
): status is ServiceMenuItemGfeStatus => {
  return Object.values(ServiceMenuItemGfeStatus).includes(
    status as ServiceMenuItemGfeStatus
  );
};

// TODO: Adjust when removing the filter that supports old and new gfe flow
const FilterDialogNew = ({
  options,
  selectedStatuses,
  onStatusChange,
  ...dialogProps
}: GfeStatusDialogProps) => {
  const [newGfeOptions, oldGfeOptions] = partition(options, isNewGfeStatus);

  const newGfeItems: CheckboxItem<ServiceMenuItemGfeStatus>[] =
    newGfeOptions.map((status) => {
      const GfeIcon = GFE_SERVICE_STATUS_ICON[mapToGfeChipType[status]];

      return {
        id: status,
        label: (
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography>{GFE_CHIP_TEXT[mapToGfeChipType[status]]}</Typography>
            <GfeIcon />
          </Stack>
        ),
        onChange: (id, checked) => onStatusChange(id, checked),
        isChecked: selectedStatuses.includes(status),
      };
    });

  const oldGfeItems: CheckboxItem<GfeStatus>[] = oldGfeOptions.map(
    (status) => ({
      id: status,
      label: GFE_STATUS_LABEL[status],
      onChange: (id, checked) => onStatusChange(id, checked),
      isChecked: selectedStatuses.includes(status),
    })
  );

  return (
    <FilterDialogWithSelectAll
      {...dialogProps}
      title="Filter by GFE Status"
      content={
        <ListAccordion
          items={[
            {
              childNode: (
                <>
                  <AdditionalInfo
                    variant="info"
                    text={
                      <>
                        Show appointments that have{" "}
                        <strong>at least one</strong> service menu item with the
                        following GFE status.
                      </>
                    }
                    sx={{ my: 1 }}
                  />
                  <CheckboxList items={newGfeItems} />
                </>
              ),
              parentNode: (
                <DefaultParentNodeTitle>
                  New GFE statuses
                </DefaultParentNodeTitle>
              ),
              id: "new_statuses",
            },
            {
              childNode: <CheckboxList items={oldGfeItems} />,
              parentNode: (
                <DefaultParentNodeTitle>
                  Old GFE statuses
                </DefaultParentNodeTitle>
              ),
              id: "old_statuses",
            },
          ]}
        />
      }
    />
  );
};
