import { isValid, isWithinInterval, startOfToday, subYears } from "date-fns";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
import {
  FormTextField,
  FormRadioField,
  FormDateField,
  FormPhoneField,
  FormStateField,
} from "./formFields";

yup.setLocale({
  mixed: {
    required: "Field required",
  },
  string: {
    max: "Max length ${max} exceeded",
    email: "Invalid email format",
  },
});

export const schema = yup.object().shape({
  firstName: yup.string().max(32).required(),
  lastName: yup.string().max(32).required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .test((value: string) => {
      return isValidPhoneNumber(value);
    })
    .required(),
  birthdate: yup
    .date()
    .nullable()
    .optional()
    .test("birthdate", "Please provide a valid date", (value: Date | null) => {
      if (value === null) return true;
      return (
        isValid(value) &&
        isWithinInterval(value, {
          start: subYears(startOfToday(), 150),
          end: startOfToday(),
        })
      );
    })
    .typeError("Please provide a valid date"),
  zipCode: yup
    .string()
    .optional()
    .test("zipCode", "Please provide valid US zip code", (value: string) => {
      if (!value) return true;

      return value.length === 5;
    }),
});

export const createClientFormDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  gender: "",
  birthdate: null,
  state: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  zipCode: "",
  note: "",
};

export const formSections = {
  generalClientData: [
    { name: "firstName", label: "First name", component: FormTextField },
    { name: "lastName", label: "Last name", component: FormTextField },
    { name: "email", label: "Email", component: FormTextField },
    {
      name: "phone",
      label: "Phone",
      component: FormPhoneField,
    },
  ],
  additionalDetails: [
    {
      name: "birthdate",
      label: "Birthdate",
      component: FormDateField,
    },
    {
      name: "gender",
      label: "Gender",
      component: FormRadioField,
      choices: [
        { value: "female", label: "Female" },
        { value: "male", label: "Male" },
        { value: "non-binary", label: "Non-binary" },
      ],
    },
  ],
  address: [
    { name: "addressLine1", label: "Address line 1", component: FormTextField },
    { name: "addressLine2", label: "Address line 2", component: FormTextField },
    { name: "city", label: "City", component: FormTextField },
    { name: "state", label: "State", component: FormStateField },
    { name: "zipCode", label: "ZIP Code", component: FormTextField },
  ],
};
