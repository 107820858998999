import FullCalendar from "@fullcalendar/react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addMinutes, differenceInMinutes, isSameDay } from "date-fns";
import {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
  useState,
  ForwardRefRenderFunction,
} from "react";
import { interactiveCalendarProps } from "@/components/common/calendar/interactiveCalendarProps";
import MoxieFullCalendar from "@/components/common/calendar/moxieFullCalendar";
import InfoCard from "@/components/common/infoCard/infoCard";
import { BLUE } from "@/config/mui/colorPalette";
import { TEXT_PRIMARY_DARK } from "@/config/mui/colorPalette";
import useCalendarGoToDate from "@/hooks/calendar/useCalendarGoToDate";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import {
  DEFAULT_CALENDAR_HEADER_HEIGHT,
  DEFAULT_PAGE_PADDING_BOTTOM,
  getCalendarHeight,
} from "@/utils";
import TimeSelectorDatePicker from "./datePicker";
import TimeSelectorTimePicker from "./timePicker";

export type TimeSelectorProps = {
  calendarEvents: Array<{
    title: string;
    display?: string;
    start: string;
    end: string;
  }>;
  onSave: (startDate: Date, endDate: Date) => void;
  closeSelector: () => void;
  startDate: Date;
  endDate: Date;
};

export type TimeSelectorRef = {
  cancel: VoidFunction;
  confirm: VoidFunction;
};

const TimeSelector: ForwardRefRenderFunction<
  TimeSelectorRef,
  TimeSelectorProps
> = (
  {
    calendarEvents,
    onSave,
    closeSelector,
    startDate: initialStartDate,
    endDate: initialEndDate,
  },
  ref
) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const { medspaReadableTimezone } = useMedspaTimezone();
  const calendarRef = useRef<FullCalendar | null>(null);
  const { goToDate } = useCalendarGoToDate(calendarRef);

  /**
   * IMPORTANT: Don't use setters directly.
   * Use the `handleDateChange`, `handleStartTimeChange` and `handleEndTimeChange` functions instead.
   */
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date>(initialEndDate);

  const handleCalendarGoToDate = (newDate: Date) => {
    // Move the calendar view to the new date
    goToDate(newDate);

    // Preserve the original time while changing the date
    const preserveTimeAndUpdateDate = (originalDate: Date) => {
      const updatedDate = new Date(newDate);
      updatedDate.setHours(
        originalDate.getHours(),
        originalDate.getMinutes(),
        0,
        0
      );
      return updatedDate;
    };

    // Update start and end dates, keeping their original times
    setStartDate(preserveTimeAndUpdateDate(startDate));
    setEndDate(preserveTimeAndUpdateDate(endDate));
  };

  const handleDateChange = (date: Date) => {
    const updateDateKeepingTime = (originalDate: Date) => {
      const updatedDate = new Date(date);
      updatedDate.setHours(
        originalDate.getHours(),
        originalDate.getMinutes(),
        0,
        0
      );
      return updatedDate;
    };

    const newStartDate = updateDateKeepingTime(startDate);
    const newEndDate = updateDateKeepingTime(endDate);

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    // Move the calendar view to the new date
    goToDate(newStartDate);
  };

  const handleStartTimeChange = (date: Date) => {
    const duration = differenceInMinutes(endDate, startDate);
    const newEndDate = addMinutes(date, duration);
    setStartDate(date);
    setEndDate(newEndDate);
  };

  const handleEndTimeChange = (date: Date) => {
    setEndDate(date);
  };

  const handleCancel = useCallback(() => {
    closeSelector();
  }, [closeSelector]);

  const handleConfirm = useCallback(() => {
    onSave(startDate, endDate);
    closeSelector();
  }, [startDate, endDate, onSave, closeSelector]);

  useImperativeHandle(
    ref,
    () => ({
      cancel: handleCancel,
      confirm: handleConfirm,
    }),
    [handleCancel, handleConfirm]
  );

  return (
    <Box sx={{ overflow: "auto" }} ref={calendarContainerRef}>
      <InfoCard
        sx={{
          mb: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack
            sx={{
              gap: 1.5,
            }}
          >
            <TimeSelectorDatePicker
              value={startDate}
              onChange={(date) => handleDateChange(date)}
              fullWidth
            />
            <Stack
              direction="row"
              sx={{
                gap: 2,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  gap: 2,
                }}
              >
                <TimeSelectorTimePicker
                  value={startDate}
                  onChange={(date) => handleStartTimeChange(date)}
                  label="Start time"
                />
              </Stack>
              <Divider
                orientation="horizontal"
                sx={{
                  borderBottomWidth: "2px",
                  alignSelf: "center",
                  width: ".5rem",
                }}
              />
              <Stack
                direction="row"
                sx={{
                  gap: 2,
                }}
              >
                <TimeSelectorTimePicker
                  value={endDate}
                  onChange={(date) => handleEndTimeChange(date)}
                  label="End time"
                  minTime={
                    isSameDay(startDate, endDate)
                      ? addMinutes(startDate, 1)
                      : undefined
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </LocalizationProvider>
      </InfoCard>
      <Typography
        variant="paragraphSmall"
        sx={{
          my: 1,
          color: "text.secondary",
        }}
      >
        Please note: You operate in{" "}
        <strong style={{ color: TEXT_PRIMARY_DARK }}>
          {medspaReadableTimezone}
        </strong>{" "}
        time.
      </Typography>
      <InfoCard sx={{ mt: 2, zIndex: 9999 }}>
        <MoxieFullCalendar
          {...interactiveCalendarProps}
          calendarRef={calendarRef}
          calendarContainerRef={calendarContainerRef}
          events={[
            ...calendarEvents,
            {
              start: startDate?.toISOString(),
              end: endDate?.toISOString(),
              title: "MD meeting",
              backgroundColor: BLUE[20],
              borderColor: BLUE[40],
              textColor: BLUE[100],
              editable: true,
            },
          ]}
          focusOnTime={startDate}
          handleCalendarGoToDate={handleCalendarGoToDate}
          height={getCalendarHeight(
            68,
            DEFAULT_CALENDAR_HEADER_HEIGHT,
            DEFAULT_PAGE_PADDING_BOTTOM,
            0
          )}
          initDate={startDate}
          initialView="timeGridDay"
          eventChange={({ event }) => {
            const { start, end } = event;
            setStartDate(start);
            setEndDate(end);
          }}
        />
      </InfoCard>
    </Box>
  );
};

export default forwardRef(TimeSelector);
