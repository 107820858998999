import { WatchQueryFetchPolicy } from "@apollo/client";
import { groupBy, isEmpty } from "lodash";
import { useMemo } from "react";
import { useUser } from "@/auth/useUser";
import {
  ApptHistoryFragment,
  ApptHistoryProviderFragment,
  useAllProvidersApptHistoryTableQuery,
  useProviderApptHistoryTableQuery,
} from "@/graphql/queries/complianceHub/apptHistoryTable.graphql.types";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import { useIsProviderOwner } from "@/hooks/user/useIsProviderOwner";
import useUserHasRoles from "@/hooks/user/useUserHasRoles";
import { MEDICAL_DIRECTOR, PROVIDER, ReviewStatus } from "@/types";
import { ServiceMenuItemGfeStatus } from "@/types/gfe";

const NON_COMPLIANT_STATUSES = [
  ServiceMenuItemGfeStatus.FORMS_INCOMPLETE,
  ServiceMenuItemGfeStatus.PENDING_REVIEWER,
  ServiceMenuItemGfeStatus.NOT_INDICATED,
  ServiceMenuItemGfeStatus.CONTRAINDICATED,
];

const isGfeCompliant = (appt: ApptHistoryFragment) =>
  !appt.serviceMenuItemLines.some((sml) =>
    NON_COMPLIANT_STATUSES.includes(
      sml.finalGfeStatus as ServiceMenuItemGfeStatus
    )
  );

type FormattedAppointment = Omit<ApptHistoryFragment, "startTime"> & {
  startTime: Date;
  gfeAdherence: boolean;
  chartOnTime: boolean;
};

type ProviderStats = {
  providerName: string;
  issues: number;
  values?: {
    medicalAppts: number;
    gfeAdherence: {
      compliant: number;
      nonCompliant: FormattedAppointment[];
    };
    chartPunctuality: number;
    mdChartReview: {
      isMdChartReviewRequired: boolean;
      percentageOfChartsToReview: number;
      reviewed: number;
    };
  };
};

const calculateProviderStats = (
  provider: ApptHistoryProviderFragment,
  appointments: FormattedAppointment[]
): ProviderStats => {
  if (isEmpty(appointments))
    return {
      providerName: provider.user.fullName,
      issues: 0,
    };

  const { isMdChartReviewRequired, percentageOfChartsToReview } = provider;

  const medicalAppts = appointments.length;
  const gfeCompliantCount = appointments.filter(
    (appt) => appt.gfeAdherence
  ).length;
  const gfeNonCompliant = appointments.filter((appt) => !appt.gfeAdherence);
  const chartPunctuality = appointments.filter(
    (appt) => appt.chartOnTime
  ).length;
  const reviewedCount = appointments.filter(
    (appt) => appt.reviewStatus === ReviewStatus.REVIEWED
  ).length;

  const meetsGfeComplianceGoal = gfeNonCompliant.length === 0;
  const meetsChartsPunctualityGoal = chartPunctuality === medicalAppts;
  const meetsMdReviewGoal =
    !isMdChartReviewRequired ||
    reviewedCount >= (percentageOfChartsToReview / 100) * medicalAppts;

  const issues = [
    meetsGfeComplianceGoal,
    meetsChartsPunctualityGoal,
    meetsMdReviewGoal,
  ].filter((goal) => !goal).length;

  return {
    providerName: provider.user.fullName,
    issues,
    values: {
      medicalAppts,
      gfeAdherence: {
        compliant: gfeCompliantCount,
        nonCompliant: gfeNonCompliant,
      },
      chartPunctuality,
      mdChartReview: {
        isMdChartReviewRequired,
        percentageOfChartsToReview,
        reviewed: reviewedCount,
      },
    },
  };
};

export default function useApptHistoryTableData({
  startDate,
  endDate,
  fetchPolicy = "cache-first" as WatchQueryFetchPolicy,
  skip = false,
}: {
  startDate?: string;
  endDate?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
}) {
  const { medspa, user } = useUser();
  const { utcToMedspaZonedTime } = useMedspaTimezone();
  const { isProviderOwner } = useIsProviderOwner();
  const isMd = useUserHasRoles([MEDICAL_DIRECTOR]);
  const isProvider = useUserHasRoles([PROVIDER]);

  const rolesLoading = isProviderOwner === undefined || isMd === undefined;
  const getDataForOneProvider = isProvider && !isProviderOwner;

  const shouldSkipQuery =
    skip || !medspa || rolesLoading || !startDate || !endDate;

  const variables = {
    medspaId: medspa,
    startDate,
    endDate,
  };

  const { data: providerData } = useProviderApptHistoryTableQuery({
    variables: {
      ...variables,
      providerId: user.id,
    },
    skip: shouldSkipQuery || !getDataForOneProvider,
    fetchPolicy,
  });

  const { data: allProvidersData } = useAllProvidersApptHistoryTableQuery({
    variables,
    skip: shouldSkipQuery || getDataForOneProvider,
    fetchPolicy,
  });

  const data = allProvidersData || providerData;

  const rows = useMemo(() => {
    if (!data) return [];

    const formatAppointment = (appt: ApptHistoryFragment) => ({
      ...appt,
      startTime: utcToMedspaZonedTime(appt.startTime),
      gfeAdherence: isGfeCompliant(appt),
      chartOnTime: appt.isChartOverdue.length === 0,
    });

    const formattedAppointments = data.appointments.map(formatAppointment);
    const groupedAppointments = groupBy(formattedAppointments, "provider.id");

    return data.providers.map((provider) =>
      calculateProviderStats(provider, groupedAppointments[provider.user.id])
    );
  }, [data, utcToMedspaZonedTime]);

  const issues = useMemo(() => {
    return Object.values(rows).reduce((acc, row) => acc + row.issues, 0);
  }, [rows]);

  return {
    isLoading: !data,
    columns: [
      { id: "providerName", label: "Provider name" },
      {
        id: "medicalAppts",
        label: "Medical Appts",
        tooltip: "Appts needing GFE and chart",
      },
      {
        id: "gfeAdherence",
        label: "GFE adherence",
        tooltip:
          "Service performed in the selected timeframe that were ‘indicated’ through the GFE",
      },
      {
        id: "chartPunctuality",
        label: "Chart punctuality",
        tooltip:
          "Charts should be completed within 72 hours of appointment. View % of charts reviewed within 72 hours",
      },
      {
        id: "mdChartReview",
        label: "MD chart review",
        tooltip:
          "Generally, MDs are required to review 10% of your charts. View what % of your completed charts they have reviewed",
      },
    ],
    rows,
    issues,
  };
}
