import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import {
  Control,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import RequiredLabel from "@/components/common/forms/requiredLabel";
import { ChevronSmallDownIcon } from "@/components/common/icons";
import { PRIMARY } from "@/config/mui/colorPalette";
import { UpdateClientMutationVariables } from "@/graphql/mutations/clients.graphql.types";
import { CreateClientInputs } from "../../../../views/serviceFlow/clients/createClientView";
import { formSections } from "./clientFormHelpers";
import { FormTextField } from "./formFields";

type FormSchemaType = CreateClientInputs | UpdateClientMutationVariables;

function ClientForm<FormSchema extends FormSchemaType>({
  control,
  setValue,
  trigger,
}: {
  control: Control<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
  trigger: UseFormTrigger<FormSchema>;
}) {
  return (
    <form style={{ width: "100%" }}>
      <Stack spacing={2}>
        <Typography
          variant="subtitleSmall"
          sx={{
            color: "secondary.main",
          }}
        >
          Personal info
        </Typography>
        {formSections.generalClientData.map(({ name, label, component }) => {
          const FormElement = component;
          return (
            <FormElement<FormSchema>
              key={name}
              control={control}
              name={name as Path<FormSchema>}
              label={<RequiredLabel label={label} />}
              trigger={trigger}
            />
          );
        })}

        <Accordion sx={{ width: "100%", boxShadow: "none" }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ChevronSmallDownIcon color={PRIMARY} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0 }}
          >
            <Typography
              variant="subtitleSmall"
              sx={{
                color: "secondary.main",
              }}
            >
              Additional details (optional)
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Stack spacing={2}>
              {formSections.additionalDetails.map(
                ({ name, label, component, choices }) => {
                  const FormElement = component;
                  return (
                    <FormElement<FormSchema>
                      key={name}
                      control={control}
                      name={name as Path<FormSchema>}
                      label={label}
                      setValue={setValue}
                      choices={choices}
                      trigger={trigger}
                    />
                  );
                }
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ width: "100%", boxShadow: "none" }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ChevronSmallDownIcon color={PRIMARY} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0 }}
          >
            <Typography
              variant="subtitleSmall"
              sx={{
                color: "secondary.main",
              }}
            >
              About (optional)
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <FormTextField<FormSchema>
              control={control}
              name={"note" as Path<FormSchema>}
              label="Note"
              multiline
              minRows={2}
              inputProps={{ maxLength: 300 }}
              defaultValue={"" as PathValue<FormSchema, Path<FormSchema>>}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{ width: "100%", boxShadow: "none", pb: 4 }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ChevronSmallDownIcon color={PRIMARY} />}
            sx={{ padding: 0 }}
          >
            <Typography
              variant="subtitleSmall"
              sx={{
                color: "secondary.main",
              }}
            >
              Address (optional)
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Stack spacing={2}>
              {formSections.address.map(({ name, label, component }) => {
                const FormElement = component;
                return (
                  <FormElement<FormSchema>
                    key={name}
                    control={control}
                    name={name as Path<FormSchema>}
                    label={label}
                    setValue={setValue}
                    trigger={trigger}
                  />
                );
              })}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </form>
  );
}

export default ClientForm;
