// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";
import { TelehealthIconFieldsFragmentDoc } from "../../fragments/telehealth/telehealthFields.graphql.types";
import { AvailabilityEventListFieldsFragmentDoc } from "../availabilityEvent.graphql.types";
import { VisitDropdownFieldsFragmentDoc } from "./visitDropdown.graphql.types";
import { VisitFieldsFragmentDoc } from "./visits.graphql.types";

const defaultOptions = {} as const;
export type VisitsAndAvailabilityEventsWithDateRangeQueryVariables =
  Types.Exact<{
    medspaId: Types.Scalars["bigint"]["input"];
    startDate?: Types.InputMaybe<Types.Scalars["timestamptz"]["input"]>;
    endDate?: Types.InputMaybe<Types.Scalars["timestamptz"]["input"]>;
    status:
      | Array<Types.Scalars["String"]["input"]>
      | Types.Scalars["String"]["input"];
    providers:
      | Array<Types.Scalars["bigint"]["input"]>
      | Types.Scalars["bigint"]["input"];
    gfeStatusesExp: Types.VisitBoolExp;
    serviceDevicesExp: Types.VisitBoolExp;
  }>;

export type VisitsAndAvailabilityEventsWithDateRangeQuery = {
  __typename?: "query_root";
  availabilityEvent: Array<{
    __typename?: "availabilityEvent";
    id: string;
    startTime?: string | null;
    endTime?: string | null;
    eventType: string;
    label: string;
    allDay: boolean;
    providerId: string;
    userMedspa: {
      __typename?: "userMedspa";
      id: string;
      userId: string;
      user: {
        __typename?: "user";
        id: string;
        firstName: string;
        lastName: string;
      };
    };
  }>;
  visit: Array<{
    __typename?: "visit";
    id: string;
    startTime: string;
    status: string;
    reviewStatus: string;
    isVisitTelehealth?: boolean | null;
    appointment?: {
      __typename?: "SchedulingAppointment";
      id: string;
      startTime?: string | null;
      endTime?: string | null;
    } | null;
    invoice?: { __typename?: "invoice"; id: string; status: string } | null;
    client: {
      __typename?: "client";
      id: string;
      firstName: string;
      lastName: string;
      note: string;
      hadAdverseReaction: boolean;
      formSubmissions: Array<{
        __typename?: "formSubmission";
        id: string;
        gfeStatus: string;
        submittedAt?: string | null;
        archivedAt?: string | null;
        medspa: { __typename?: "medspa"; id: string };
      }>;
      consentFormSignatures: Array<{
        __typename?: "consentFormSignature";
        id: string;
        visitId?: string | null;
        signedAt?: string | null;
        consentForm: {
          __typename?: "consentForm";
          id: string;
          sendOnce: boolean;
        };
      }>;
      hasItemsInWallet: {
        __typename?: "walletTransactionAggregate";
        aggregate?: {
          __typename?: "walletTransactionAggregateFields";
          sum?: {
            __typename?: "walletTransactionSumFields";
            amount?: string | null;
          } | null;
        } | null;
      };
      clientMemberships: Array<{
        __typename?: "ClientMembership";
        id: string;
        membership: { __typename?: "Membership"; id: string; title: string };
      }>;
      pendingGfeReview?: Array<{
        __typename?: "GfeReviewRequest";
        id: string;
        asyncReviewedAt?: string | null;
      }> | null;
      lastGfeReview?: Array<{
        __typename?: "GfeReviewRequest";
        id: string;
      }> | null;
      visits: Array<{ __typename?: "visit"; id: string }>;
    };
    serviceMenuItemLines: Array<{
      __typename?: "ServiceMenuItemLine";
      id: string;
      gfeStatus?: string | null;
      serviceMenuItem: {
        __typename?: "MedspaServiceMenuItem";
        id: string;
        name: string;
      };
    }>;
    provider: {
      __typename?: "user";
      id: string;
      firstName: string;
      lastName: string;
    };
    telehealthVisitDetails?: {
      __typename?: "TelehealthVisitDetails";
      visitType: string;
      videoLink?: string | null;
      visitId: string;
      visitDetails?: string | null;
    } | null;
    lastSignature: Array<{ __typename?: "VisitsChartsignature"; id: string }>;
  }>;
};

export const VisitsAndAvailabilityEventsWithDateRangeDocument = gql`
  query VisitsAndAvailabilityEventsWithDateRange(
    $medspaId: bigint!
    $startDate: timestamptz
    $endDate: timestamptz
    $status: [String!]!
    $providers: [bigint!]!
    $gfeStatusesExp: visitBoolExp!
    $serviceDevicesExp: visitBoolExp!
  ) {
    availabilityEvent(
      where: {
        startTime: { _lt: $endDate }
        endTime: { _gt: $startDate }
        userMedspa: {
          medspaId: { _eq: $medspaId }
          userId: { _in: $providers }
        }
      }
      orderBy: { startTime: ASC }
    ) {
      ...availabilityEventListFields
    }
    visit(
      where: {
        _and: [
          { startTime: { _gt: $startDate, _lte: $endDate } }
          { providerId: { _in: $providers } }
          { medspa: { id: { _eq: $medspaId } } }
          { status: { _in: $status } }
          $serviceDevicesExp
          $gfeStatusesExp
        ]
      }
      orderBy: { startTime: ASC }
    ) {
      ...VisitFields
      ...VisitDropdownFields
      ...TelehealthIconFields
    }
  }
  ${AvailabilityEventListFieldsFragmentDoc}
  ${VisitFieldsFragmentDoc}
  ${VisitDropdownFieldsFragmentDoc}
  ${TelehealthIconFieldsFragmentDoc}
`;

/**
 * __useVisitsAndAvailabilityEventsWithDateRangeQuery__
 *
 * To run a query within a React component, call `useVisitsAndAvailabilityEventsWithDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitsAndAvailabilityEventsWithDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitsAndAvailabilityEventsWithDateRangeQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *      providers: // value for 'providers'
 *      gfeStatusesExp: // value for 'gfeStatusesExp'
 *      serviceDevicesExp: // value for 'serviceDevicesExp'
 *   },
 * });
 */
export function useVisitsAndAvailabilityEventsWithDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitsAndAvailabilityEventsWithDateRangeQuery,
    VisitsAndAvailabilityEventsWithDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VisitsAndAvailabilityEventsWithDateRangeQuery,
    VisitsAndAvailabilityEventsWithDateRangeQueryVariables
  >(VisitsAndAvailabilityEventsWithDateRangeDocument, options);
}
export function useVisitsAndAvailabilityEventsWithDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitsAndAvailabilityEventsWithDateRangeQuery,
    VisitsAndAvailabilityEventsWithDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VisitsAndAvailabilityEventsWithDateRangeQuery,
    VisitsAndAvailabilityEventsWithDateRangeQueryVariables
  >(VisitsAndAvailabilityEventsWithDateRangeDocument, options);
}
export function useVisitsAndAvailabilityEventsWithDateRangeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    VisitsAndAvailabilityEventsWithDateRangeQuery,
    VisitsAndAvailabilityEventsWithDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VisitsAndAvailabilityEventsWithDateRangeQuery,
    VisitsAndAvailabilityEventsWithDateRangeQueryVariables
  >(VisitsAndAvailabilityEventsWithDateRangeDocument, options);
}
export type VisitsAndAvailabilityEventsWithDateRangeQueryHookResult =
  ReturnType<typeof useVisitsAndAvailabilityEventsWithDateRangeQuery>;
export type VisitsAndAvailabilityEventsWithDateRangeLazyQueryHookResult =
  ReturnType<typeof useVisitsAndAvailabilityEventsWithDateRangeLazyQuery>;
export type VisitsAndAvailabilityEventsWithDateRangeSuspenseQueryHookResult =
  ReturnType<typeof useVisitsAndAvailabilityEventsWithDateRangeSuspenseQuery>;
export type VisitsAndAvailabilityEventsWithDateRangeQueryResult =
  Apollo.QueryResult<
    VisitsAndAvailabilityEventsWithDateRangeQuery,
    VisitsAndAvailabilityEventsWithDateRangeQueryVariables
  >;
