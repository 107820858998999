import { useCallback } from "react";
import { useIsMoxieMobile } from "./useIsMoxieMobile";

export const useMoxieMobileMessageEmitter = () => {
  const { isMoxieMobile } = useIsMoxieMobile();

  const emit = useCallback(
    (type: string, payload?: unknown) => {
      if (!isMoxieMobile) {
        return;
      }

      emitMoxieMobileMessage(type, payload);
    },
    [isMoxieMobile]
  );

  return { emit };
};

export const emitMoxieMobileMessage = (type: string, payload?: unknown) => {
  if (typeof window === "undefined") {
    return;
  }
  const message = JSON.stringify({ type, payload });
  window.ReactNativeWebView?.postMessage(message);
};
