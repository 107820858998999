import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { GREY, WHITE } from "@/config/mui/colorPalette";
import { TOP_BAR_HEIGHT } from "./topBar";

export default function TopBarWrapper({
  sx,
  children,
}: {
  sx?: SxProps<Theme>;
  children?: ReactNode | ReactNode[];
}) {
  return (
    <Box
      sx={{
        py: 1,
        px: 3,
        height: TOP_BAR_HEIGHT,
        backgroundColor: WHITE,
        borderBottom: `1px solid ${GREY[20]}`,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
