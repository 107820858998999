import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { TelehealthDetailsInfoBox } from "@/components/booking/createVisitDrawer/createVisitDrawerForm";
import LoadingButton from "@/components/common/buttons/loadingButton";
import { CrossIcon } from "@/components/common/icons";
import TelehealthDetailsForm from "@/components/serviceFlow/visits/telehealthDetailsForm";
import { PRIMARY } from "@/config/mui/colorPalette";
import useTelehealthDetails, {
  TelehealthDetails,
} from "@/hooks/visits/useTelehealthDetails";
import useErrorLogger from "@/utils/useErrorLogger";

type TelehealthDetailsDialogProps = {
  formType: "add" | "edit";
  visitId: string;
  initData?: TelehealthDetails;
  isOpen: boolean;
  onClose: () => void;
};

export default function TelehealthDetailsDialog({
  formType,
  visitId,
  initData,
  isOpen,
  onClose,
}: TelehealthDetailsDialogProps) {
  const logError = useErrorLogger();

  const {
    telehealthType,
    setTelehealthType,
    telehealthVideoLink,
    isTelehealthVisitDetailsValid,
    setTelehealthVideoLink,
    telehealthAdditionalDetails,
    setTelehealthAdditionalDetails,
    setIsTelehealthVisitDetailsValid,
    handleAddTelehealthDetails,
    handleEditTelehealthDetails,
    resetValues,
  } = useTelehealthDetails(visitId, initData);

  const getSubmitAction = () => {
    switch (formType) {
      case "add":
        return handleAddTelehealthDetails;
      case "edit":
        return handleEditTelehealthDetails;
    }
  };

  const handleSubmit = async () => {
    const action = getSubmitAction();
    const toastId = toast.loading(
      `${formType === "add" ? "Adding" : "Updating"} telehealth details...`
    );

    try {
      await action();

      toast.success(
        `Telehealth details have been ${formType === "add" ? "added" : "updated"} successfully`,
        {
          id: toastId,
        }
      );
      onClose();
    } catch (e) {
      logError(e);
      toast.error(
        `An error occurred while ${formType === "add" ? "adding" : "updating"} telehealth details`,
        {
          id: toastId,
        }
      );
    }
  };

  const handleClose = () => {
    onClose();
    resetValues();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",

          pb: 2,
        },
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
            }}
          >
            {formType === "add"
              ? "Add telehealth details"
              : "Edit telehealth details"}
          </Typography>
          <IconButton onClick={handleClose} sx={{ mr: -1 }}>
            <CrossIcon color={PRIMARY} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: "text.secondary",
            mb: 2,
          }}
        >
          Add the necessary information to telehealth appointment.
        </Typography>
        <TelehealthDetailsForm
          telehealthType={telehealthType}
          telehealthVideoLink={telehealthVideoLink}
          telehealthAdditionalDetails={telehealthAdditionalDetails}
          onChangeTelehealthType={setTelehealthType}
          onChangeTelehealthVideoLink={setTelehealthVideoLink}
          onChangeTelehealthAdditionalDetails={setTelehealthAdditionalDetails}
          onChangeIsTelehealthVisitDetailsValid={
            setIsTelehealthVisitDetailsValid
          }
        />
        <TelehealthDetailsInfoBox sx={{ maxWidth: "unset" }} />
      </DialogContent>
      <DialogActions>
        <Controls
          onSubmit={handleSubmit}
          onCancel={handleClose}
          isSubmitDisabled={!isTelehealthVisitDetailsValid}
        />
      </DialogActions>
    </Dialog>
  );
}

const Controls = ({
  onSubmit,
  onCancel,
  isSubmitDisabled,
}: {
  onSubmit: () => void;
  onCancel: () => void;
  isSubmitDisabled?: boolean;
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        gap: 1,
        width: "100%",
        px: 2,
      }}
    >
      <Button
        variant="outlined"
        onClick={onCancel}
        fullWidth
        sx={{
          borderRadius: "16px",
          py: 2,
        }}
      >
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        onClick={onSubmit}
        disabled={isSubmitDisabled}
        fullWidth
        sx={{
          borderRadius: "16px",
          py: 2,
        }}
      >
        Save changes
      </LoadingButton>
    </Stack>
  );
};
