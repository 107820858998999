import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { CamIcon, CheckCircleIcon } from "@/components/common/icons";
import { GREY, SUCCESS_PRIMARY } from "@/config/mui/colorPalette";
import { useOnMount } from "@/hooks/misc/useEffectOnMount";
import { TelehealthType } from "@/types";
import { urlValidator } from "@/utils";

export type TelehealthDetailsFormProps = {
  telehealthType: TelehealthType;
  telehealthVideoLink: string;
  telehealthAdditionalDetails: string;
  onChangeTelehealthType: (telehealthType: TelehealthType) => void;
  onChangeTelehealthVideoLink: (telehealthVideoLink: string) => void;
  onChangeTelehealthAdditionalDetails: (
    telehealthAdditionalDetails: string
  ) => void;
  onChangeIsTelehealthVisitDetailsValid: (status: boolean) => void;
};

export default function TelehealthDetailsForm({
  telehealthType,
  telehealthVideoLink,
  telehealthAdditionalDetails,
  onChangeTelehealthType,
  onChangeTelehealthVideoLink,
  onChangeTelehealthAdditionalDetails,
  onChangeIsTelehealthVisitDetailsValid,
}: TelehealthDetailsFormProps) {
  const [isUrlValid, setIsUrlValid] = useState<boolean | undefined>(undefined);

  const validateUrl = async (url: string): Promise<void> => {
    try {
      await urlValidator.validate(url);
      setIsUrlValid(true);
    } catch (e) {
      setIsUrlValid(false);
    }
  };

  // validate once on mount only when telehealthVideoLink is defined
  useOnMount(() => {
    if (telehealthVideoLink) validateUrl(telehealthVideoLink);
  });

  const isTelehealthVisitDetailsValid = useMemo(() => {
    return telehealthType === TelehealthType.PHONE || isUrlValid;
  }, [telehealthType, isUrlValid]);

  useEffect(() => {
    onChangeIsTelehealthVisitDetailsValid(isTelehealthVisitDetailsValid);
  }, [onChangeIsTelehealthVisitDetailsValid, isTelehealthVisitDetailsValid]);

  return (
    <Stack spacing={2}>
      <FormControl component="fieldset" required>
        <RadioGroup
          value={telehealthType}
          onChange={(e) => {
            onChangeTelehealthType(e.target.value as TelehealthType);
          }}
          sx={{
            height: "24px",
            flexDirection: "row",
            alignItems: "center",
            gap: 3,
          }}
        >
          <FormControlLabel
            value={TelehealthType.VIDEO}
            control={<Radio />}
            label="Video call"
            sx={{
              height: "24px",
            }}
          />
          <FormControlLabel
            value={TelehealthType.PHONE}
            control={<Radio />}
            label="Phone call"
            sx={{
              height: "24px",
            }}
          />
        </RadioGroup>
      </FormControl>
      {telehealthType === TelehealthType.VIDEO && (
        <TextField
          value={telehealthVideoLink}
          required={telehealthType === TelehealthType.VIDEO}
          placeholder="Paste video link here"
          fullWidth
          type="text"
          error={isUrlValid === false}
          helperText={
            isUrlValid === false && "The URL entered above is not valid."
          }
          onChange={(e) => {
            onChangeTelehealthVideoLink(e.target.value);
          }}
          onBlur={(e) => {
            validateUrl(e.target.value);
          }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <CamIcon size={20} color={GREY[40]} />
                </InputAdornment>
              ),
              ...(isUrlValid && {
                endAdornment: (
                  <InputAdornment position="end">
                    <CheckCircleIcon size={20} color={SUCCESS_PRIMARY} />
                  </InputAdornment>
                ),
              }),
              size: "small",
            },
          }}
        />
      )}
      <TextField
        value={telehealthAdditionalDetails}
        placeholder="Add additional details here"
        fullWidth
        multiline
        maxRows={5}
        type="text"
        onChange={(e) => {
          onChangeTelehealthAdditionalDetails(e.target.value);
        }}
        slotProps={{
          input: {
            size: "small",
          },
        }}
      />
    </Stack>
  );
}
