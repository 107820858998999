import {
  Client,
  ConnectionState,
  Conversation,
  JSONValue,
  Message,
} from "@twilio/conversations";

export type LastMessage = {
  isSentByClient: boolean;
  italic: boolean;
  body: string;
  dateCreated: Date;
};

export const getLastMessage = async (conversation: Conversation) => {
  if (conversation.lastMessage) {
    const lastMessage = await conversation.getMessages(1);
    const message = lastMessage.items[0];
    const isSentByClient = message.author.startsWith("+");
    const isMediaMessage = !message.body;
    const body = isMediaMessage ? "Sent a photo/video" : message.body;
    return {
      isSentByClient,
      italic: isMediaMessage,
      body: body,
      dateCreated: message.dateCreated,
    };
  } else {
    return {
      isSentByClient: false,
      italic: true,
      body: "Send your first message",
      dateCreated: null,
    };
  }
};

export async function getSubscribedConversations(
  client: Client
): Promise<Conversation[]> {
  let subscribedConversations = await client.getSubscribedConversations();
  let conversations = subscribedConversations.items;

  while (subscribedConversations.hasNextPage) {
    subscribedConversations = await subscribedConversations.nextPage();
    conversations = [...conversations, ...subscribedConversations.items];
  }

  return conversations;
}

export type ConversationData = {
  lastMessage?: LastMessage;
  unreadMessagesCount?: number;
};

export function getMessageAuthor(message: Message) {
  const hasAuthor = (attributes: JSONValue): attributes is { author: string } =>
    typeof attributes === "object" &&
    "author" in attributes &&
    !!attributes.author;

  // message.attributes.author will be present in every new message sent by provider and it's their displayName
  // for older messages / sent by system or client we use message.author which could be medspa name, "system" or client's number
  return hasAuthor(message.attributes)
    ? message.attributes.author
    : message.author;
}

export function isMessageSentByClient(message: Message) {
  const messageAuthor = getMessageAuthor(message);
  return messageAuthor.startsWith("+");
}

// There are too many requests to log error of every possible one
// instead we count how many errors we got
export const getNotificationsCount = async (
  conversations: Conversation[],
  setConversationData: (sid: string, data: ConversationData) => void
) => {
  let notificationsCount = 0;
  let getLastMessageErrorsCount = 0;
  let getUnreadMessagesCountErrorsCount = 0;

  await Promise.all(
    conversations
      // we use map to return promises but fill values in outer scope
      // instead of returning array that would need reducing afterwards
      .map(async (conversation) => {
        try {
          const lastMessage = await getLastMessage(conversation);
          setConversationData(conversation.sid, { lastMessage });
        } catch (e) {
          getLastMessageErrorsCount += 1;
          return;
        }

        try {
          let unreadMessagesCount = await conversation.getUnreadMessagesCount();

          if (unreadMessagesCount > 0) {
            // Twilio treats automated messages as unread so we need to query messages to see who is the author
            // and filter out messages sent by system
            const messages =
              await conversation.getMessages(unreadMessagesCount);

            unreadMessagesCount = messages.items.filter(
              isMessageSentByClient
            ).length;
          }

          setConversationData(conversation.sid, { unreadMessagesCount });
          notificationsCount += unreadMessagesCount;
        } catch (e) {
          getUnreadMessagesCountErrorsCount += 1;
        }
      })
  );

  return {
    notificationsCount,
    getLastMessageErrorsCount,
    getUnreadMessagesCountErrorsCount,
  };
};

export function isErrorConnectionState(connectionState: ConnectionState) {
  return ["unknown", "disconnected", "denied", "error"].includes(
    connectionState
  );
}

export function getMutipleRequestsError(
  target: string,
  getLastMessageErrorsCount: number,
  getUnreadMessagesCountErrorsCount: number
) {
  return new Error(
    `${target} threw ` +
      [
        getLastMessageErrorsCount &&
          `${getLastMessageErrorsCount} getLastMessage() errors`,
        getUnreadMessagesCountErrorsCount &&
          `${getUnreadMessagesCountErrorsCount} getUnreadMessagesCount() errors`,
      ]
        .filter((message) => !!message)
        .join(" and ")
  );
}

export function sortConversations(conversations: Conversation[]) {
  conversations.sort((a, b) => {
    const aDate = a.lastMessage?.dateCreated;
    const bDate = b.lastMessage?.dateCreated;
    if (!aDate && !bDate) return 0;
    if (!aDate) return 1;
    if (!bDate) return -1;
    return +bDate - +aDate;
  });
}
