// @ts-nocheck
import { gql } from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

export type ComplianceTaskFieldsFragment = {
  __typename?: "ComplianceTask";
  id: string;
  name: string;
  expiresAt?: string | null;
  required: boolean;
  status: string;
  providerId?: string | null;
  complianceTaskTemplate: {
    __typename?: "ComplianceTaskTemplate";
    id: string;
    canMarkNotRequired: boolean;
    expires: boolean;
    isMsoTask: boolean;
    name: string;
    requiresDocument: boolean;
  };
  provider?: {
    __typename?: "userMedspa";
    id: string;
    user: { __typename?: "user"; fullName?: string | null; id: string };
  } | null;
  complianceTaskDocuments: Array<{
    __typename?: "ComplianceTaskDocument";
    id: string;
    pdfFilePath?: string | null;
    status: string;
    archivedAt?: string | null;
    archivedById?: string | null;
    boldsignDocumentId: string;
  }>;
};

export const ComplianceTaskFieldsFragmentDoc = gql`
  fragment ComplianceTaskFields on ComplianceTask {
    id
    name
    expiresAt
    required
    status
    complianceTaskTemplate {
      id
      canMarkNotRequired
      expires
      isMsoTask
      name
      requiresDocument
    }
    providerId
    provider {
      user {
        fullName
        id
      }
      id
    }
    complianceTaskDocuments(orderBy: { created: DESC }) {
      id
      pdfFilePath
      status
      archivedAt
      archivedById
      boldsignDocumentId
    }
  }
`;
