import { Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";
import { ERROR_PRIMARY } from "@/config/mui/colorPalette";

export default function RequiredLabel({
  label,
  ...props
}: {
  label: ReactNode;
  props?: TypographyProps;
} & TypographyProps) {
  return (
    <Typography sx={{ fontSize: "unset" }} {...props}>
      {label}
      <span style={{ color: ERROR_PRIMARY }}> * </span>
    </Typography>
  );
}
