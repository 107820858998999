// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SetFollowupVisitBookedMutationVariables = Types.Exact<{
  parentVisitId: Types.Scalars["bigint"]["input"];
}>;

export type SetFollowupVisitBookedMutation = {
  __typename?: "mutation_root";
  updateVisitByPk?: {
    __typename?: "visit";
    id: string;
    followupVisitBooked: boolean;
  } | null;
};

export const SetFollowupVisitBookedDocument = gql`
  mutation setFollowupVisitBooked($parentVisitId: bigint!) {
    updateVisitByPk(
      pkColumns: { id: $parentVisitId }
      _set: { followupVisitBooked: true }
    ) {
      id
      followupVisitBooked
    }
  }
`;
export type SetFollowupVisitBookedMutationFn = Apollo.MutationFunction<
  SetFollowupVisitBookedMutation,
  SetFollowupVisitBookedMutationVariables
>;

/**
 * __useSetFollowupVisitBookedMutation__
 *
 * To run a mutation, you first call `useSetFollowupVisitBookedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFollowupVisitBookedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFollowupVisitBookedMutation, { data, loading, error }] = useSetFollowupVisitBookedMutation({
 *   variables: {
 *      parentVisitId: // value for 'parentVisitId'
 *   },
 * });
 */
export function useSetFollowupVisitBookedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetFollowupVisitBookedMutation,
    SetFollowupVisitBookedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetFollowupVisitBookedMutation,
    SetFollowupVisitBookedMutationVariables
  >(SetFollowupVisitBookedDocument, options);
}
export type SetFollowupVisitBookedMutationHookResult = ReturnType<
  typeof useSetFollowupVisitBookedMutation
>;
export type SetFollowupVisitBookedMutationResult =
  Apollo.MutationResult<SetFollowupVisitBookedMutation>;
export type SetFollowupVisitBookedMutationOptions = Apollo.BaseMutationOptions<
  SetFollowupVisitBookedMutation,
  SetFollowupVisitBookedMutationVariables
>;
