import { MINT } from "@/config/mui/colorPalette";

type Props = {
  size?: string;
};

export default function SuccessCircleIconSvg({ size = "200" }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 200 200"
    >
      <path
        fill={MINT[40]}
        d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100Z"
      />
      <path
        fill="#3C9"
        d="M138.187 77.153A7.692 7.692 0 0 0 132.784 64h14.601a7.692 7.692 0 0 1 5.417 13.153l-58.153 57.693a7.671 7.671 0 0 1-5.418 2.231H73.923l-.018-.033a7.676 7.676 0 0 0 6.128-2.199l58.154-57.692ZM49.298 96.977c.124-.006.249-.01.373-.01h-.363l-.01.01Zm.416-.01a7.668 7.668 0 0 1 5.396 2.231l19.506 19.351 7.307-7.25-12.198-12.1a7.669 7.669 0 0 0-5.417-2.232H49.714ZM132.754 64H132l-.033.042a7.67 7.67 0 0 1 .787-.042Z"
      />
      <path
        fill="#fff"
        d="M138.23 66.275a7.692 7.692 0 0 1-.043 10.878l-58.154 57.693a7.693 7.693 0 0 1-10.835 0L44.275 110.12A7.692 7.692 0 1 1 55.11 99.198l19.506 19.351 52.736-52.318a7.692 7.692 0 0 1 10.878.044Z"
      />
      <path
        stroke={MINT[100]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M49.714 96.967a7.668 7.668 0 0 1 5.396 2.231l19.506 19.351 7.307-7.25M49.714 96.967a7.692 7.692 0 0 0-5.44 13.153l24.924 24.726a7.671 7.671 0 0 0 5.372 2.231m-24.856-40.11h14.594c1.958 0 3.917.743 5.417 2.231L81.923 111.3M49.714 96.967c-.143 0 .127 0 0 0Zm32.21 14.332 45.428-45.068A7.666 7.666 0 0 1 132.754 64M74.57 137.077a7.672 7.672 0 0 0 5.463-2.231l58.154-57.693A7.692 7.692 0 0 0 132.754 64M74.57 137.077h14.661a7.67 7.67 0 0 0 5.418-2.231l58.153-57.693A7.692 7.692 0 0 0 147.385 64h-14.631"
      />
    </svg>
  );
}
