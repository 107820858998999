import { useMemo } from "react";
import { VisitsQuery } from "@/graphql/queries/visit/visits.graphql.types";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import { InvoiceStatus, ReviewStatus, VisitStatus } from "@/types";
import { getUserCalendarColor } from "@/utils";
import { hasClientFormsToFill, hasIncompleteGFE } from "@/utils/forms";
import {
  GfeForm,
  clientHasWalletItems,
  getClientMembershipsList,
  getEventColorsByState,
  getGeneralGfeVisitStatus,
  getGeneralGfeVisitStatusOld,
  getGeneralGfeVisitStatusTemp,
} from "@/utils/visit";
import useFeatureFlags from "../common/useFeatureFlags";
import useProviderIndex from "./useProviderIndex";

export default function useVisitEvents(visits: VisitsQuery["visit"]) {
  const { newGfeFlowV1 } = useFeatureFlags();
  const { utcToZonedTimeIso } = useMedspaTimezone();

  const getProviderIndex = useProviderIndex();

  return useMemo(() => {
    if (!visits) return [];

    return visits.map((visit) => {
      const start = utcToZonedTimeIso(
        visit.appointment?.startTime || visit.startTime
      );
      const end = utcToZonedTimeIso(visit.appointment?.endTime);

      const providerIndex = getProviderIndex(visit.provider.id);
      const { colorObjectByIndex } = getUserCalendarColor({
        ...visit.provider,
        userIndex: providerIndex,
      });

      const eventColorByStateObject = getEventColorsByState(
        visit.status as VisitStatus,
        colorObjectByIndex
      );

      return {
        id: visit.id,
        start,
        end,
        title: `${visit.client.firstName} ${visit.client.lastName}`,
        ...eventColorByStateObject,
        extendedProps: {
          isTimeBlock: false,
          isSpecialShift: false,
          status: visit.status as VisitStatus,
          reviewStatus: visit.reviewStatus as ReviewStatus,
          invoiceStatus: visit.invoice?.status as InvoiceStatus,
          services: visit.serviceMenuItemLines
            .map(
              (serviceMenuItemLine) => serviceMenuItemLine.serviceMenuItem.name
            )
            .join(" • "),
          isNewClient:
            visit.client.visits.length > 0 &&
            visit.client.visits[0].id === visit.id,
          gfeNeeded: hasIncompleteGFE(visit.client),
          gfeGeneralStatusOld: newGfeFlowV1 // TODO: Remove when newGfeFlowV2 is enabled for all
            ? getGeneralGfeVisitStatusTemp(visit.client)
            : getGeneralGfeVisitStatusOld(
                visit.client.formSubmissions as GfeForm[]
              ),
          gfeGeneralStatus: getGeneralGfeVisitStatus(
            visit.serviceMenuItemLines
          ),
          hasFormsToFill: hasClientFormsToFill(visit.client, visit.id),
          hasWalletItems: clientHasWalletItems(visit.client),
          clientMembershipsList: getClientMembershipsList(visit.client),
          eventColorPalette: colorObjectByIndex,
          isVisitTelehealth: visit.isVisitTelehealth,
          telehealthVisitDetails: visit.telehealthVisitDetails,
        },
      } as const;
    });
  }, [visits, utcToZonedTimeIso, newGfeFlowV1, getProviderIndex]);
}
