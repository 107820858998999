// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type CancelAppointmentMutationVariables = Types.Exact<{
  appointmentId: Types.Scalars["BigInt"]["input"];
  sendCommunication: Types.Scalars["Boolean"]["input"];
}>;

export type CancelAppointmentMutation = {
  __typename?: "mutation_root";
  cancelAppointment?: {
    __typename?: "CancelAppointment";
    message?: string | null;
    ok?: boolean | null;
  } | null;
};

export const CancelAppointmentDocument = gql`
  mutation CancelAppointment(
    $appointmentId: BigInt!
    $sendCommunication: Boolean!
  ) {
    cancelAppointment(
      id: $appointmentId
      sendCommunication: $sendCommunication
    ) {
      message
      ok
    }
  }
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      sendCommunication: // value for 'sendCommunication'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >(CancelAppointmentDocument, options);
}
export type CancelAppointmentMutationHookResult = ReturnType<
  typeof useCancelAppointmentMutation
>;
export type CancelAppointmentMutationResult =
  Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
