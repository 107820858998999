// @ts-nocheck
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: string; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Decimal: { input: string; output: string };
  FormattedDecimal: { input: string; output: string };
  JSONString: { input: string; output: string };
  Time: { input: string; output: string };
  bigint: { input: string; output: string };
  bytea: { input: string; output: string };
  date: { input: string; output: string };
  inet: { input: string; output: string };
  jsonb: { input: string; output: string };
  numeric: { input: string; output: string };
  time: { input: string; output: string };
  timestamptz: { input: string; output: string };
  tstzrange: { input: string; output: string };
};

export type AddMobileStripeTerminal = {
  __typename?: "AddMobileStripeTerminal";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  reader?: Maybe<StripeTerminalType>;
};

export type AddSmartStripeTerminal = {
  __typename?: "AddSmartStripeTerminal";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  reader?: Maybe<StripeTerminalType>;
};

export type AddWalletTransaction = {
  __typename?: "AddWalletTransaction";
  message?: Maybe<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
};

export type AddressPublicInfoType = {
  __typename?: "AddressPublicInfoType";
  addressLine1: Scalars["String"]["output"];
  addressLine2: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
  zipCode: Scalars["String"]["output"];
};

/** Table that represents administrative divisions that have their own law regulations.Administrative division could be a state, province or even a whole country. This allows us to represent top level admin divisions in an extendable way. */
export type AdministrativeDivision = {
  __typename?: "AdministrativeDivision";
  /** Short abbreviation of the administrative division name */
  abbreviation: Scalars["String"]["output"];
  /** Type of administrative division, e.g., state, province */
  adminDivisionType: Scalars["String"]["output"];
  /** Country to which the administrative division belongs */
  countryId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Full name of the administrative division */
  name: Scalars["String"]["output"];
};

/** aggregated selection of "administrative_division" */
export type AdministrativeDivisionAggregate = {
  __typename?: "AdministrativeDivisionAggregate";
  aggregate?: Maybe<AdministrativeDivisionAggregateFields>;
  nodes: Array<AdministrativeDivision>;
};

/** aggregate fields of "administrative_division" */
export type AdministrativeDivisionAggregateFields = {
  __typename?: "AdministrativeDivisionAggregateFields";
  avg?: Maybe<AdministrativeDivisionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<AdministrativeDivisionMaxFields>;
  min?: Maybe<AdministrativeDivisionMinFields>;
  stddev?: Maybe<AdministrativeDivisionStddevFields>;
  stddevPop?: Maybe<AdministrativeDivisionStddevPopFields>;
  stddevSamp?: Maybe<AdministrativeDivisionStddevSampFields>;
  sum?: Maybe<AdministrativeDivisionSumFields>;
  varPop?: Maybe<AdministrativeDivisionVarPopFields>;
  varSamp?: Maybe<AdministrativeDivisionVarSampFields>;
  variance?: Maybe<AdministrativeDivisionVarianceFields>;
};

/** aggregate fields of "administrative_division" */
export type AdministrativeDivisionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AdministrativeDivisionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type AdministrativeDivisionAvgFields = {
  __typename?: "AdministrativeDivisionAvgFields";
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "administrative_division". All fields are combined with a logical 'AND'. */
export type AdministrativeDivisionBoolExp = {
  _and?: InputMaybe<Array<AdministrativeDivisionBoolExp>>;
  _not?: InputMaybe<AdministrativeDivisionBoolExp>;
  _or?: InputMaybe<Array<AdministrativeDivisionBoolExp>>;
  abbreviation?: InputMaybe<StringComparisonExp>;
  adminDivisionType?: InputMaybe<StringComparisonExp>;
  countryId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "administrative_division" */
export enum AdministrativeDivisionConstraint {
  /** unique or primary key constraint on columns "id" */
  AdministrativeDivisionPkey = "administrative_division_pkey",
}

/** input type for incrementing numeric columns in table "administrative_division" */
export type AdministrativeDivisionIncInput = {
  /** Country to which the administrative division belongs */
  countryId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "administrative_division" */
export type AdministrativeDivisionInsertInput = {
  /** Short abbreviation of the administrative division name */
  abbreviation?: InputMaybe<Scalars["String"]["input"]>;
  /** Type of administrative division, e.g., state, province */
  adminDivisionType?: InputMaybe<Scalars["String"]["input"]>;
  /** Country to which the administrative division belongs */
  countryId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Full name of the administrative division */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type AdministrativeDivisionMaxFields = {
  __typename?: "AdministrativeDivisionMaxFields";
  /** Short abbreviation of the administrative division name */
  abbreviation?: Maybe<Scalars["String"]["output"]>;
  /** Type of administrative division, e.g., state, province */
  adminDivisionType?: Maybe<Scalars["String"]["output"]>;
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Full name of the administrative division */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type AdministrativeDivisionMinFields = {
  __typename?: "AdministrativeDivisionMinFields";
  /** Short abbreviation of the administrative division name */
  abbreviation?: Maybe<Scalars["String"]["output"]>;
  /** Type of administrative division, e.g., state, province */
  adminDivisionType?: Maybe<Scalars["String"]["output"]>;
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Full name of the administrative division */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "administrative_division" */
export type AdministrativeDivisionMutationResponse = {
  __typename?: "AdministrativeDivisionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<AdministrativeDivision>;
};

/** input type for inserting object relation for remote table "administrative_division" */
export type AdministrativeDivisionObjRelInsertInput = {
  data: AdministrativeDivisionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AdministrativeDivisionOnConflict>;
};

/** on_conflict condition type for table "administrative_division" */
export type AdministrativeDivisionOnConflict = {
  constraint: AdministrativeDivisionConstraint;
  updateColumns?: Array<AdministrativeDivisionUpdateColumn>;
  where?: InputMaybe<AdministrativeDivisionBoolExp>;
};

/** Ordering options when selecting data from "administrative_division". */
export type AdministrativeDivisionOrderBy = {
  abbreviation?: InputMaybe<OrderBy>;
  adminDivisionType?: InputMaybe<OrderBy>;
  countryId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: administrative_division */
export type AdministrativeDivisionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "administrative_division" */
export enum AdministrativeDivisionSelectColumn {
  /** column name */
  Abbreviation = "abbreviation",
  /** column name */
  AdminDivisionType = "adminDivisionType",
  /** column name */
  CountryId = "countryId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "administrative_division" */
export type AdministrativeDivisionSetInput = {
  /** Short abbreviation of the administrative division name */
  abbreviation?: InputMaybe<Scalars["String"]["input"]>;
  /** Type of administrative division, e.g., state, province */
  adminDivisionType?: InputMaybe<Scalars["String"]["input"]>;
  /** Country to which the administrative division belongs */
  countryId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Full name of the administrative division */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type AdministrativeDivisionStddevFields = {
  __typename?: "AdministrativeDivisionStddevFields";
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type AdministrativeDivisionStddevPopFields = {
  __typename?: "AdministrativeDivisionStddevPopFields";
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type AdministrativeDivisionStddevSampFields = {
  __typename?: "AdministrativeDivisionStddevSampFields";
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "administrative_division" */
export type AdministrativeDivisionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AdministrativeDivisionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AdministrativeDivisionStreamCursorValueInput = {
  /** Short abbreviation of the administrative division name */
  abbreviation?: InputMaybe<Scalars["String"]["input"]>;
  /** Type of administrative division, e.g., state, province */
  adminDivisionType?: InputMaybe<Scalars["String"]["input"]>;
  /** Country to which the administrative division belongs */
  countryId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Full name of the administrative division */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type AdministrativeDivisionSumFields = {
  __typename?: "AdministrativeDivisionSumFields";
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "administrative_division" */
export enum AdministrativeDivisionUpdateColumn {
  /** column name */
  Abbreviation = "abbreviation",
  /** column name */
  AdminDivisionType = "adminDivisionType",
  /** column name */
  CountryId = "countryId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type AdministrativeDivisionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AdministrativeDivisionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AdministrativeDivisionSetInput>;
  /** filter the rows which have to be updated */
  where: AdministrativeDivisionBoolExp;
};

/** aggregate varPop on columns */
export type AdministrativeDivisionVarPopFields = {
  __typename?: "AdministrativeDivisionVarPopFields";
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type AdministrativeDivisionVarSampFields = {
  __typename?: "AdministrativeDivisionVarSampFields";
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type AdministrativeDivisionVarianceFields = {
  __typename?: "AdministrativeDivisionVarianceFields";
  /** Country to which the administrative division belongs */
  countryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Contains Adverse Reaction reports that happened during the visit. Reports are stored as aJSONForm and linked to original template containing schema and ui schema. */
export type AdverseReaction = {
  __typename?: "AdverseReaction";
  /** An array relationship */
  changeLog: Array<VisitsAdversereactionchangelog>;
  /** An aggregate relationship */
  changeLogAggregate: VisitsAdversereactionchangelogAggregate;
  /** An object relationship */
  client: Client;
  /** Client that had the adverse reaction */
  clientId: Scalars["bigint"]["output"];
  /** Client requests a call from the MD */
  clientRequestsCall: Scalars["Boolean"]["output"];
  /** Content of the report. */
  content: Scalars["jsonb"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** Date when the last reminder to the MD was sent. */
  lastReminderSentAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  /** Medspa in which the adverse reaction occurred. */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Date when the next reminder to the MD should be sent. */
  nextReminderSendDate?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  notes: Array<Note>;
  /** An aggregate relationship */
  notesAggregate: NoteAggregate;
  /** An object relationship */
  provider: UserMedspa;
  /** Provider that reported the adverse reaction. */
  reportedById: Scalars["bigint"]["output"];
  /** Timestamp of when the report was marked as resolved. Can be null for unresolved reactions. */
  resolvedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  resolvedBy?: Maybe<UserMedspa>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Status of adverse reaction report */
  status: Scalars["String"]["output"];
  /** An object relationship */
  template: AdverseReactionTemplate;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId: Scalars["bigint"]["output"];
  /** An object relationship */
  visit: Visit;
  /** Visit for which the adverse reaction occurred. */
  visitId: Scalars["bigint"]["output"];
};

/** Contains Adverse Reaction reports that happened during the visit. Reports are stored as aJSONForm and linked to original template containing schema and ui schema. */
export type AdverseReactionChangeLogArgs = {
  distinctOn?: InputMaybe<Array<VisitsAdversereactionchangelogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsAdversereactionchangelogOrderBy>>;
  where?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
};

/** Contains Adverse Reaction reports that happened during the visit. Reports are stored as aJSONForm and linked to original template containing schema and ui schema. */
export type AdverseReactionChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitsAdversereactionchangelogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsAdversereactionchangelogOrderBy>>;
  where?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
};

/** Contains Adverse Reaction reports that happened during the visit. Reports are stored as aJSONForm and linked to original template containing schema and ui schema. */
export type AdverseReactionContentArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** Contains Adverse Reaction reports that happened during the visit. Reports are stored as aJSONForm and linked to original template containing schema and ui schema. */
export type AdverseReactionNotesArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

/** Contains Adverse Reaction reports that happened during the visit. Reports are stored as aJSONForm and linked to original template containing schema and ui schema. */
export type AdverseReactionNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

/** aggregated selection of "adverse_reaction" */
export type AdverseReactionAggregate = {
  __typename?: "AdverseReactionAggregate";
  aggregate?: Maybe<AdverseReactionAggregateFields>;
  nodes: Array<AdverseReaction>;
};

export type AdverseReactionAggregateBoolExp = {
  bool_and?: InputMaybe<AdverseReactionAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<AdverseReactionAggregateBoolExpBool_Or>;
  count?: InputMaybe<AdverseReactionAggregateBoolExpCount>;
};

/** aggregate fields of "adverse_reaction" */
export type AdverseReactionAggregateFields = {
  __typename?: "AdverseReactionAggregateFields";
  avg?: Maybe<AdverseReactionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<AdverseReactionMaxFields>;
  min?: Maybe<AdverseReactionMinFields>;
  stddev?: Maybe<AdverseReactionStddevFields>;
  stddevPop?: Maybe<AdverseReactionStddevPopFields>;
  stddevSamp?: Maybe<AdverseReactionStddevSampFields>;
  sum?: Maybe<AdverseReactionSumFields>;
  varPop?: Maybe<AdverseReactionVarPopFields>;
  varSamp?: Maybe<AdverseReactionVarSampFields>;
  variance?: Maybe<AdverseReactionVarianceFields>;
};

/** aggregate fields of "adverse_reaction" */
export type AdverseReactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "adverse_reaction" */
export type AdverseReactionAggregateOrderBy = {
  avg?: InputMaybe<AdverseReactionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AdverseReactionMaxOrderBy>;
  min?: InputMaybe<AdverseReactionMinOrderBy>;
  stddev?: InputMaybe<AdverseReactionStddevOrderBy>;
  stddevPop?: InputMaybe<AdverseReactionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<AdverseReactionStddevSampOrderBy>;
  sum?: InputMaybe<AdverseReactionSumOrderBy>;
  varPop?: InputMaybe<AdverseReactionVarPopOrderBy>;
  varSamp?: InputMaybe<AdverseReactionVarSampOrderBy>;
  variance?: InputMaybe<AdverseReactionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AdverseReactionAppendInput = {
  /** Content of the report. */
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "adverse_reaction" */
export type AdverseReactionArrRelInsertInput = {
  data: Array<AdverseReactionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AdverseReactionOnConflict>;
};

/** aggregate avg on columns */
export type AdverseReactionAvgFields = {
  __typename?: "AdverseReactionAvgFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["Float"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "adverse_reaction" */
export type AdverseReactionAvgOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "adverse_reaction". All fields are combined with a logical 'AND'. */
export type AdverseReactionBoolExp = {
  _and?: InputMaybe<Array<AdverseReactionBoolExp>>;
  _not?: InputMaybe<AdverseReactionBoolExp>;
  _or?: InputMaybe<Array<AdverseReactionBoolExp>>;
  changeLog?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
  changeLogAggregate?: InputMaybe<VisitsAdversereactionchangelogAggregateBoolExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  clientRequestsCall?: InputMaybe<BooleanComparisonExp>;
  content?: InputMaybe<JsonbComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  lastReminderSentAt?: InputMaybe<TimestamptzComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  nextReminderSendDate?: InputMaybe<TimestamptzComparisonExp>;
  notes?: InputMaybe<NoteBoolExp>;
  notesAggregate?: InputMaybe<NoteAggregateBoolExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  reportedById?: InputMaybe<BigintComparisonExp>;
  resolvedAt?: InputMaybe<TimestamptzComparisonExp>;
  resolvedBy?: InputMaybe<UserMedspaBoolExp>;
  resolvedById?: InputMaybe<BigintComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  template?: InputMaybe<AdverseReactionTemplateBoolExp>;
  templateId?: InputMaybe<BigintComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "adverse_reaction" */
export enum AdverseReactionConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsAdversereactionPkey = "visits_adversereaction_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AdverseReactionDeleteAtPathInput = {
  /** Content of the report. */
  content?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AdverseReactionDeleteElemInput = {
  /** Content of the report. */
  content?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AdverseReactionDeleteKeyInput = {
  /** Content of the report. */
  content?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "adverse_reaction" */
export type AdverseReactionIncInput = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "adverse_reaction" */
export type AdverseReactionInsertInput = {
  changeLog?: InputMaybe<VisitsAdversereactionchangelogArrRelInsertInput>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Client requests a call from the MD */
  clientRequestsCall?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Content of the report. */
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date when the last reminder to the MD was sent. */
  lastReminderSentAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Date when the next reminder to the MD should be sent. */
  nextReminderSendDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
  notes?: InputMaybe<NoteArrRelInsertInput>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Timestamp of when the report was marked as resolved. Can be null for unresolved reactions. */
  resolvedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  resolvedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Status of adverse reaction report */
  status?: InputMaybe<Scalars["String"]["input"]>;
  template?: InputMaybe<AdverseReactionTemplateObjRelInsertInput>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type AdverseReactionMaxFields = {
  __typename?: "AdverseReactionMaxFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Date when the last reminder to the MD was sent. */
  lastReminderSentAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Date when the next reminder to the MD should be sent. */
  nextReminderSendDate?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Timestamp of when the report was marked as resolved. Can be null for unresolved reactions. */
  resolvedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Status of adverse reaction report */
  status?: Maybe<Scalars["String"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "adverse_reaction" */
export type AdverseReactionMaxOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Date when the last reminder to the MD was sent. */
  lastReminderSentAt?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Date when the next reminder to the MD should be sent. */
  nextReminderSendDate?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Timestamp of when the report was marked as resolved. Can be null for unresolved reactions. */
  resolvedAt?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** Status of adverse reaction report */
  status?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AdverseReactionMinFields = {
  __typename?: "AdverseReactionMinFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Date when the last reminder to the MD was sent. */
  lastReminderSentAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Date when the next reminder to the MD should be sent. */
  nextReminderSendDate?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Timestamp of when the report was marked as resolved. Can be null for unresolved reactions. */
  resolvedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Status of adverse reaction report */
  status?: Maybe<Scalars["String"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "adverse_reaction" */
export type AdverseReactionMinOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Date when the last reminder to the MD was sent. */
  lastReminderSentAt?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Date when the next reminder to the MD should be sent. */
  nextReminderSendDate?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Timestamp of when the report was marked as resolved. Can be null for unresolved reactions. */
  resolvedAt?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** Status of adverse reaction report */
  status?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "adverse_reaction" */
export type AdverseReactionMutationResponse = {
  __typename?: "AdverseReactionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<AdverseReaction>;
};

/** input type for inserting object relation for remote table "adverse_reaction" */
export type AdverseReactionObjRelInsertInput = {
  data: AdverseReactionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AdverseReactionOnConflict>;
};

/** on_conflict condition type for table "adverse_reaction" */
export type AdverseReactionOnConflict = {
  constraint: AdverseReactionConstraint;
  updateColumns?: Array<AdverseReactionUpdateColumn>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

/** Ordering options when selecting data from "adverse_reaction". */
export type AdverseReactionOrderBy = {
  changeLogAggregate?: InputMaybe<VisitsAdversereactionchangelogAggregateOrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  clientRequestsCall?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastReminderSentAt?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  nextReminderSendDate?: InputMaybe<OrderBy>;
  notesAggregate?: InputMaybe<NoteAggregateOrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  reportedById?: InputMaybe<OrderBy>;
  resolvedAt?: InputMaybe<OrderBy>;
  resolvedBy?: InputMaybe<UserMedspaOrderBy>;
  resolvedById?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  template?: InputMaybe<AdverseReactionTemplateOrderBy>;
  templateId?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: adverse_reaction */
export type AdverseReactionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AdverseReactionPrependInput = {
  /** Content of the report. */
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "adverse_reaction" */
export enum AdverseReactionSelectColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  ClientRequestsCall = "clientRequestsCall",
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  LastReminderSentAt = "lastReminderSentAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  NextReminderSendDate = "nextReminderSendDate",
  /** column name */
  ReportedById = "reportedById",
  /** column name */
  ResolvedAt = "resolvedAt",
  /** column name */
  ResolvedById = "resolvedById",
  /** column name */
  Status = "status",
  /** column name */
  TemplateId = "templateId",
  /** column name */
  VisitId = "visitId",
}

/** select "adverseReactionAggregateBoolExpBool_andArgumentsColumns" columns of table "adverse_reaction" */
export enum AdverseReactionSelectColumnAdverseReactionAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  ClientRequestsCall = "clientRequestsCall",
}

/** select "adverseReactionAggregateBoolExpBool_orArgumentsColumns" columns of table "adverse_reaction" */
export enum AdverseReactionSelectColumnAdverseReactionAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  ClientRequestsCall = "clientRequestsCall",
}

/** input type for updating data in table "adverse_reaction" */
export type AdverseReactionSetInput = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Client requests a call from the MD */
  clientRequestsCall?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Content of the report. */
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date when the last reminder to the MD was sent. */
  lastReminderSentAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Date when the next reminder to the MD should be sent. */
  nextReminderSendDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Timestamp of when the report was marked as resolved. Can be null for unresolved reactions. */
  resolvedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Status of adverse reaction report */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type AdverseReactionStddevFields = {
  __typename?: "AdverseReactionStddevFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["Float"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "adverse_reaction" */
export type AdverseReactionStddevOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type AdverseReactionStddevPopFields = {
  __typename?: "AdverseReactionStddevPopFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["Float"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "adverse_reaction" */
export type AdverseReactionStddevPopOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type AdverseReactionStddevSampFields = {
  __typename?: "AdverseReactionStddevSampFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["Float"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "adverse_reaction" */
export type AdverseReactionStddevSampOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "adverse_reaction" */
export type AdverseReactionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AdverseReactionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AdverseReactionStreamCursorValueInput = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Client requests a call from the MD */
  clientRequestsCall?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Content of the report. */
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date when the last reminder to the MD was sent. */
  lastReminderSentAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Date when the next reminder to the MD should be sent. */
  nextReminderSendDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Timestamp of when the report was marked as resolved. Can be null for unresolved reactions. */
  resolvedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Status of adverse reaction report */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type AdverseReactionSumFields = {
  __typename?: "AdverseReactionSumFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["bigint"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "adverse_reaction" */
export type AdverseReactionSumOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** Contains JSONForm schemas for adverse reaction forms. */
export type AdverseReactionTemplate = {
  __typename?: "AdverseReactionTemplate";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  isDefaultTemplate: Scalars["Boolean"]["output"];
  modified: Scalars["timestamptz"]["output"];
  schema: Scalars["jsonb"]["output"];
  uiSchema: Scalars["jsonb"]["output"];
};

/** Contains JSONForm schemas for adverse reaction forms. */
export type AdverseReactionTemplateSchemaArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** Contains JSONForm schemas for adverse reaction forms. */
export type AdverseReactionTemplateUiSchemaArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "adverse_reaction_template" */
export type AdverseReactionTemplateAggregate = {
  __typename?: "AdverseReactionTemplateAggregate";
  aggregate?: Maybe<AdverseReactionTemplateAggregateFields>;
  nodes: Array<AdverseReactionTemplate>;
};

/** aggregate fields of "adverse_reaction_template" */
export type AdverseReactionTemplateAggregateFields = {
  __typename?: "AdverseReactionTemplateAggregateFields";
  avg?: Maybe<AdverseReactionTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<AdverseReactionTemplateMaxFields>;
  min?: Maybe<AdverseReactionTemplateMinFields>;
  stddev?: Maybe<AdverseReactionTemplateStddevFields>;
  stddevPop?: Maybe<AdverseReactionTemplateStddevPopFields>;
  stddevSamp?: Maybe<AdverseReactionTemplateStddevSampFields>;
  sum?: Maybe<AdverseReactionTemplateSumFields>;
  varPop?: Maybe<AdverseReactionTemplateVarPopFields>;
  varSamp?: Maybe<AdverseReactionTemplateVarSampFields>;
  variance?: Maybe<AdverseReactionTemplateVarianceFields>;
};

/** aggregate fields of "adverse_reaction_template" */
export type AdverseReactionTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AdverseReactionTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AdverseReactionTemplateAppendInput = {
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate avg on columns */
export type AdverseReactionTemplateAvgFields = {
  __typename?: "AdverseReactionTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "adverse_reaction_template". All fields are combined with a logical 'AND'. */
export type AdverseReactionTemplateBoolExp = {
  _and?: InputMaybe<Array<AdverseReactionTemplateBoolExp>>;
  _not?: InputMaybe<AdverseReactionTemplateBoolExp>;
  _or?: InputMaybe<Array<AdverseReactionTemplateBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isDefaultTemplate?: InputMaybe<BooleanComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  schema?: InputMaybe<JsonbComparisonExp>;
  uiSchema?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "adverse_reaction_template" */
export enum AdverseReactionTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  AdverseReactionTemplatePkey = "adverse_reaction_template_pkey",
  /** unique or primary key constraint on columns "is_default_template" */
  UniqueAdverseReactionIsDefaultTemplate = "unique_adverse_reaction_is_default_template",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AdverseReactionTemplateDeleteAtPathInput = {
  schema?: InputMaybe<Array<Scalars["String"]["input"]>>;
  uiSchema?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AdverseReactionTemplateDeleteElemInput = {
  schema?: InputMaybe<Scalars["Int"]["input"]>;
  uiSchema?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AdverseReactionTemplateDeleteKeyInput = {
  schema?: InputMaybe<Scalars["String"]["input"]>;
  uiSchema?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "adverse_reaction_template" */
export type AdverseReactionTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "adverse_reaction_template" */
export type AdverseReactionTemplateInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isDefaultTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate max on columns */
export type AdverseReactionTemplateMaxFields = {
  __typename?: "AdverseReactionTemplateMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type AdverseReactionTemplateMinFields = {
  __typename?: "AdverseReactionTemplateMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "adverse_reaction_template" */
export type AdverseReactionTemplateMutationResponse = {
  __typename?: "AdverseReactionTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<AdverseReactionTemplate>;
};

/** input type for inserting object relation for remote table "adverse_reaction_template" */
export type AdverseReactionTemplateObjRelInsertInput = {
  data: AdverseReactionTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AdverseReactionTemplateOnConflict>;
};

/** on_conflict condition type for table "adverse_reaction_template" */
export type AdverseReactionTemplateOnConflict = {
  constraint: AdverseReactionTemplateConstraint;
  updateColumns?: Array<AdverseReactionTemplateUpdateColumn>;
  where?: InputMaybe<AdverseReactionTemplateBoolExp>;
};

/** Ordering options when selecting data from "adverse_reaction_template". */
export type AdverseReactionTemplateOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDefaultTemplate?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  schema?: InputMaybe<OrderBy>;
  uiSchema?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: adverse_reaction_template */
export type AdverseReactionTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AdverseReactionTemplatePrependInput = {
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "adverse_reaction_template" */
export enum AdverseReactionTemplateSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IsDefaultTemplate = "isDefaultTemplate",
  /** column name */
  Modified = "modified",
  /** column name */
  Schema = "schema",
  /** column name */
  UiSchema = "uiSchema",
}

/** input type for updating data in table "adverse_reaction_template" */
export type AdverseReactionTemplateSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isDefaultTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate stddev on columns */
export type AdverseReactionTemplateStddevFields = {
  __typename?: "AdverseReactionTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type AdverseReactionTemplateStddevPopFields = {
  __typename?: "AdverseReactionTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type AdverseReactionTemplateStddevSampFields = {
  __typename?: "AdverseReactionTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "adverse_reaction_template" */
export type AdverseReactionTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AdverseReactionTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AdverseReactionTemplateStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isDefaultTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate sum on columns */
export type AdverseReactionTemplateSumFields = {
  __typename?: "AdverseReactionTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "adverse_reaction_template" */
export enum AdverseReactionTemplateUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IsDefaultTemplate = "isDefaultTemplate",
  /** column name */
  Modified = "modified",
  /** column name */
  Schema = "schema",
  /** column name */
  UiSchema = "uiSchema",
}

export type AdverseReactionTemplateUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AdverseReactionTemplateAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<AdverseReactionTemplateDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<AdverseReactionTemplateDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<AdverseReactionTemplateDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AdverseReactionTemplateIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AdverseReactionTemplatePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AdverseReactionTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: AdverseReactionTemplateBoolExp;
};

/** aggregate varPop on columns */
export type AdverseReactionTemplateVarPopFields = {
  __typename?: "AdverseReactionTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type AdverseReactionTemplateVarSampFields = {
  __typename?: "AdverseReactionTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type AdverseReactionTemplateVarianceFields = {
  __typename?: "AdverseReactionTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** update columns of table "adverse_reaction" */
export enum AdverseReactionUpdateColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  ClientRequestsCall = "clientRequestsCall",
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  LastReminderSentAt = "lastReminderSentAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  NextReminderSendDate = "nextReminderSendDate",
  /** column name */
  ReportedById = "reportedById",
  /** column name */
  ResolvedAt = "resolvedAt",
  /** column name */
  ResolvedById = "resolvedById",
  /** column name */
  Status = "status",
  /** column name */
  TemplateId = "templateId",
  /** column name */
  VisitId = "visitId",
}

export type AdverseReactionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AdverseReactionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<AdverseReactionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<AdverseReactionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<AdverseReactionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AdverseReactionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AdverseReactionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AdverseReactionSetInput>;
  /** filter the rows which have to be updated */
  where: AdverseReactionBoolExp;
};

/** aggregate varPop on columns */
export type AdverseReactionVarPopFields = {
  __typename?: "AdverseReactionVarPopFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["Float"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "adverse_reaction" */
export type AdverseReactionVarPopOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type AdverseReactionVarSampFields = {
  __typename?: "AdverseReactionVarSampFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["Float"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "adverse_reaction" */
export type AdverseReactionVarSampOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AdverseReactionVarianceFields = {
  __typename?: "AdverseReactionVarianceFields";
  /** Client that had the adverse reaction */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that reported the adverse reaction. */
  reportedById?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: Maybe<Scalars["Float"]["output"]>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "adverse_reaction" */
export type AdverseReactionVarianceOrderBy = {
  /** Client that had the adverse reaction */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa in which the adverse reaction occurred. */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider that reported the adverse reaction. */
  reportedById?: InputMaybe<OrderBy>;
  /** Medical Director or Provider who marked the adverse reaction report as resolved. Can be null for unresolved reactions. */
  resolvedById?: InputMaybe<OrderBy>;
  /** This field contains the template id that holds form schema and ui schema. */
  templateId?: InputMaybe<OrderBy>;
  /** Visit for which the adverse reaction occurred. */
  visitId?: InputMaybe<OrderBy>;
};

export type AppointmentPublicInfoType = {
  __typename?: "AppointmentPublicInfoType";
  accessToken: Scalars["String"]["output"];
  client?: Maybe<ClientPublicInfoType>;
  clientAccessToken?: Maybe<Scalars["String"]["output"]>;
  clientHasCardsOnFile?: Maybe<Scalars["Boolean"]["output"]>;
  endTime?: Maybe<Scalars["DateTime"]["output"]>;
  isVisitTelehealth?: Maybe<Scalars["Boolean"]["output"]>;
  medspa?: Maybe<MedspaPublicInfoType>;
  provider?: Maybe<UserMedSpaType>;
  serviceMenuItems: Array<MedSpaServiceMenuItemPublicInfoType>;
  startTime?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  telehealthVisitDetails?: Maybe<TelehealthVisitDetailsType>;
};

export type AppointmentType = {
  __typename?: "AppointmentType";
  created: Scalars["DateTime"]["output"];
  customerEmail?: Maybe<Scalars["String"]["output"]>;
  customerPhone?: Maybe<Scalars["String"]["output"]>;
  endTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  modified: Scalars["DateTime"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  startTime?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AttributionSourceType = {
  __typename?: "AttributionSourceType";
  campaign?: Maybe<Scalars["String"]["output"]>;
  campaignId?: Maybe<Scalars["String"]["output"]>;
  dclid?: Maybe<Scalars["String"]["output"]>;
  fbEventId?: Maybe<Scalars["String"]["output"]>;
  fbc?: Maybe<Scalars["String"]["output"]>;
  fbclid?: Maybe<Scalars["String"]["output"]>;
  fbp?: Maybe<Scalars["String"]["output"]>;
  gclid?: Maybe<Scalars["String"]["output"]>;
  ip?: Maybe<Scalars["String"]["output"]>;
  medium?: Maybe<Scalars["String"]["output"]>;
  mediumId?: Maybe<Scalars["String"]["output"]>;
  msclkid?: Maybe<Scalars["String"]["output"]>;
  referrer?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
  utmContent?: Maybe<Scalars["String"]["output"]>;
  utmMedium?: Maybe<Scalars["String"]["output"]>;
  utmSource?: Maybe<Scalars["String"]["output"]>;
};

export type AvailabilityEventType = {
  __typename?: "AvailabilityEventType";
  endTime?: Maybe<Scalars["DateTime"]["output"]>;
  eventType: SchedulingAvailabilityEventEventTypeChoices;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  startTime?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AvailableResourcesType = {
  __typename?: "AvailableResourcesType";
  medspaRooms?: Maybe<RoomType>;
  serviceDevices?: Maybe<Array<Maybe<ServiceDeviceType>>>;
};

export type AvailableTimeSlotsPerDateType = {
  __typename?: "AvailableTimeSlotsPerDateType";
  date?: Maybe<Scalars["Date"]["output"]>;
  slots?: Maybe<Array<Maybe<TimeSlotType>>>;
};

export type AvailableTimeSlotsType = {
  __typename?: "AvailableTimeSlotsType";
  dates?: Maybe<Array<Maybe<AvailableTimeSlotsPerDateType>>>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars["bigint"]["input"]>;
  _gt?: InputMaybe<Scalars["bigint"]["input"]>;
  _gte?: InputMaybe<Scalars["bigint"]["input"]>;
  _in?: InputMaybe<Array<Scalars["bigint"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["bigint"]["input"]>;
  _lte?: InputMaybe<Scalars["bigint"]["input"]>;
  _neq?: InputMaybe<Scalars["bigint"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["bigint"]["input"]>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _neq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type ByteaComparisonExp = {
  _eq?: InputMaybe<Scalars["bytea"]["input"]>;
  _gt?: InputMaybe<Scalars["bytea"]["input"]>;
  _gte?: InputMaybe<Scalars["bytea"]["input"]>;
  _in?: InputMaybe<Array<Scalars["bytea"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["bytea"]["input"]>;
  _lte?: InputMaybe<Scalars["bytea"]["input"]>;
  _neq?: InputMaybe<Scalars["bytea"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["bytea"]["input"]>>;
};

export type CancelAffirmPayment = {
  __typename?: "CancelAffirmPayment";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CancelAppointment = {
  __typename?: "CancelAppointment";
  canceledAppointment?: Maybe<AppointmentType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CancelAppointmentByClient = {
  __typename?: "CancelAppointmentByClient";
  canceledAppointment?: Maybe<AppointmentType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CancelClientMembership = {
  __typename?: "CancelClientMembership";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CancelMdMeeting = {
  __typename?: "CancelMDMeeting";
  mdMeeting?: Maybe<MdMeetingType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CancelTerminalAction = {
  __typename?: "CancelTerminalAction";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CancelVisitWithoutAppointment = {
  __typename?: "CancelVisitWithoutAppointment";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ChargeNoShowFee = {
  __typename?: "ChargeNoShowFee";
  invoice?: Maybe<InvoiceType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
  paymentIntent?: Maybe<PaymentIntentType>;
  selectedCardId?: Maybe<Scalars["String"]["output"]>;
};

export type ChartSignatureType = {
  __typename?: "ChartSignatureType";
  id: Scalars["ID"]["output"];
  signatureUrl?: Maybe<Scalars["String"]["output"]>;
};

export type CherryAccountBalanceInfo = {
  __typename?: "CherryAccountBalanceInfo";
  amount?: Maybe<Scalars["String"]["output"]>;
  expireAt?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type CherryAccountInfo = {
  __typename?: "CherryAccountInfo";
  balance?: Maybe<CherryAccountBalanceInfo>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type ClientCherryAccountType = {
  __typename?: "ClientCherryAccountType";
  cherryAccountInfo?: Maybe<CherryAccountInfo>;
  id: Scalars["ID"]["output"];
};

export type ClientData = {
  __typename?: "ClientData";
  card?: Maybe<MoxieCardType>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type ClientFileType = {
  __typename?: "ClientFileType";
  created: Scalars["DateTime"]["output"];
  fileName?: Maybe<Scalars["String"]["output"]>;
  fileUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imgFileSmallUrl?: Maybe<Scalars["String"]["output"]>;
  isImage: Scalars["Boolean"]["output"];
};

/** columns and relationships of "client_membership" */
export type ClientMembership = {
  __typename?: "ClientMembership";
  additionalInfo?: Maybe<ClientMembershipType>;
  /** Stores the datetime when the Membership will be/was effectively canceled */
  cancelAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the datetime when the cancelation of the Membership was requested */
  canceledAt?: Maybe<Scalars["timestamptz"]["output"]>;
  cancellationContext?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  client: Client;
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  ipAddress?: Maybe<Scalars["inet"]["output"]>;
  /** An object relationship */
  membership: Membership;
  membershipId: Scalars["bigint"]["output"];
  /** An array relationship */
  membershipLines: Array<MembershipLine>;
  /** An aggregate relationship */
  membershipLinesAggregate: MembershipLineAggregate;
  /** An array relationship */
  membershipPerkLines: Array<MembershipPerkLine>;
  /** An aggregate relationship */
  membershipPerkLinesAggregate: MembershipPerkLineAggregate;
  modified: Scalars["timestamptz"]["output"];
  pdfPrintout?: Maybe<Scalars["String"]["output"]>;
  purchaseSource?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  /** Stores the content for the Agreement that was signed by a Client upon purchasing a Membership */
  signedAgreementContent: Scalars["String"]["output"];
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the datetime when the stripe subscription started. Could be in future, past or now. */
  startedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  status: Scalars["String"]["output"];
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionScheduleId?: Maybe<Scalars["String"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "client_membership" */
export type ClientMembershipMembershipLinesArgs = {
  distinctOn?: InputMaybe<Array<MembershipLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipLineOrderBy>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

/** columns and relationships of "client_membership" */
export type ClientMembershipMembershipLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipLineOrderBy>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

/** columns and relationships of "client_membership" */
export type ClientMembershipMembershipPerkLinesArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** columns and relationships of "client_membership" */
export type ClientMembershipMembershipPerkLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** aggregated selection of "client_membership" */
export type ClientMembershipAggregate = {
  __typename?: "ClientMembershipAggregate";
  aggregate?: Maybe<ClientMembershipAggregateFields>;
  nodes: Array<ClientMembership>;
};

export type ClientMembershipAggregateBoolExp = {
  count?: InputMaybe<ClientMembershipAggregateBoolExpCount>;
};

/** aggregate fields of "client_membership" */
export type ClientMembershipAggregateFields = {
  __typename?: "ClientMembershipAggregateFields";
  avg?: Maybe<ClientMembershipAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ClientMembershipMaxFields>;
  min?: Maybe<ClientMembershipMinFields>;
  stddev?: Maybe<ClientMembershipStddevFields>;
  stddevPop?: Maybe<ClientMembershipStddevPopFields>;
  stddevSamp?: Maybe<ClientMembershipStddevSampFields>;
  sum?: Maybe<ClientMembershipSumFields>;
  varPop?: Maybe<ClientMembershipVarPopFields>;
  varSamp?: Maybe<ClientMembershipVarSampFields>;
  variance?: Maybe<ClientMembershipVarianceFields>;
};

/** aggregate fields of "client_membership" */
export type ClientMembershipAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "client_membership" */
export type ClientMembershipAggregateOrderBy = {
  avg?: InputMaybe<ClientMembershipAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClientMembershipMaxOrderBy>;
  min?: InputMaybe<ClientMembershipMinOrderBy>;
  stddev?: InputMaybe<ClientMembershipStddevOrderBy>;
  stddevPop?: InputMaybe<ClientMembershipStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ClientMembershipStddevSampOrderBy>;
  sum?: InputMaybe<ClientMembershipSumOrderBy>;
  varPop?: InputMaybe<ClientMembershipVarPopOrderBy>;
  varSamp?: InputMaybe<ClientMembershipVarSampOrderBy>;
  variance?: InputMaybe<ClientMembershipVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "client_membership" */
export type ClientMembershipArrRelInsertInput = {
  data: Array<ClientMembershipInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClientMembershipOnConflict>;
};

/** aggregate avg on columns */
export type ClientMembershipAvgFields = {
  __typename?: "ClientMembershipAvgFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "client_membership" */
export type ClientMembershipAvgOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "client_membership". All fields are combined with a logical 'AND'. */
export type ClientMembershipBoolExp = {
  _and?: InputMaybe<Array<ClientMembershipBoolExp>>;
  _not?: InputMaybe<ClientMembershipBoolExp>;
  _or?: InputMaybe<Array<ClientMembershipBoolExp>>;
  cancelAt?: InputMaybe<TimestamptzComparisonExp>;
  canceledAt?: InputMaybe<TimestamptzComparisonExp>;
  cancellationContext?: InputMaybe<StringComparisonExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  ipAddress?: InputMaybe<InetComparisonExp>;
  membership?: InputMaybe<MembershipBoolExp>;
  membershipId?: InputMaybe<BigintComparisonExp>;
  membershipLines?: InputMaybe<MembershipLineBoolExp>;
  membershipLinesAggregate?: InputMaybe<MembershipLineAggregateBoolExp>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineBoolExp>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  pdfPrintout?: InputMaybe<StringComparisonExp>;
  purchaseSource?: InputMaybe<StringComparisonExp>;
  signature?: InputMaybe<StringComparisonExp>;
  signedAgreementContent?: InputMaybe<StringComparisonExp>;
  signedAt?: InputMaybe<TimestamptzComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  stripeSubscriptionId?: InputMaybe<StringComparisonExp>;
  stripeSubscriptionScheduleId?: InputMaybe<StringComparisonExp>;
  userAgent?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "client_membership" */
export enum ClientMembershipConstraint {
  /** unique or primary key constraint on columns "id" */
  ClientMembershipPkey = "client_membership_pkey",
}

/** input type for incrementing numeric columns in table "client_membership" */
export type ClientMembershipIncInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "client_membership" */
export type ClientMembershipInsertInput = {
  /** Stores the datetime when the Membership will be/was effectively canceled */
  cancelAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the datetime when the cancelation of the Membership was requested */
  canceledAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  cancellationContext?: InputMaybe<Scalars["String"]["input"]>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  membership?: InputMaybe<MembershipObjRelInsertInput>;
  membershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipLines?: InputMaybe<MembershipLineArrRelInsertInput>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  pdfPrintout?: InputMaybe<Scalars["String"]["input"]>;
  purchaseSource?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores the content for the Agreement that was signed by a Client upon purchasing a Membership */
  signedAgreementContent?: InputMaybe<Scalars["String"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the datetime when the stripe subscription started. Could be in future, past or now. */
  startedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionScheduleId?: InputMaybe<Scalars["String"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ClientMembershipMaxFields = {
  __typename?: "ClientMembershipMaxFields";
  /** Stores the datetime when the Membership will be/was effectively canceled */
  cancelAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the datetime when the cancelation of the Membership was requested */
  canceledAt?: Maybe<Scalars["timestamptz"]["output"]>;
  cancellationContext?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  membershipId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  pdfPrintout?: Maybe<Scalars["String"]["output"]>;
  purchaseSource?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  /** Stores the content for the Agreement that was signed by a Client upon purchasing a Membership */
  signedAgreementContent?: Maybe<Scalars["String"]["output"]>;
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the datetime when the stripe subscription started. Could be in future, past or now. */
  startedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionScheduleId?: Maybe<Scalars["String"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "client_membership" */
export type ClientMembershipMaxOrderBy = {
  /** Stores the datetime when the Membership will be/was effectively canceled */
  cancelAt?: InputMaybe<OrderBy>;
  /** Stores the datetime when the cancelation of the Membership was requested */
  canceledAt?: InputMaybe<OrderBy>;
  cancellationContext?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfPrintout?: InputMaybe<OrderBy>;
  purchaseSource?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  /** Stores the content for the Agreement that was signed by a Client upon purchasing a Membership */
  signedAgreementContent?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  /** Stores the datetime when the stripe subscription started. Could be in future, past or now. */
  startedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
  stripeSubscriptionScheduleId?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClientMembershipMinFields = {
  __typename?: "ClientMembershipMinFields";
  /** Stores the datetime when the Membership will be/was effectively canceled */
  cancelAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the datetime when the cancelation of the Membership was requested */
  canceledAt?: Maybe<Scalars["timestamptz"]["output"]>;
  cancellationContext?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  membershipId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  pdfPrintout?: Maybe<Scalars["String"]["output"]>;
  purchaseSource?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  /** Stores the content for the Agreement that was signed by a Client upon purchasing a Membership */
  signedAgreementContent?: Maybe<Scalars["String"]["output"]>;
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the datetime when the stripe subscription started. Could be in future, past or now. */
  startedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionScheduleId?: Maybe<Scalars["String"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "client_membership" */
export type ClientMembershipMinOrderBy = {
  /** Stores the datetime when the Membership will be/was effectively canceled */
  cancelAt?: InputMaybe<OrderBy>;
  /** Stores the datetime when the cancelation of the Membership was requested */
  canceledAt?: InputMaybe<OrderBy>;
  cancellationContext?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfPrintout?: InputMaybe<OrderBy>;
  purchaseSource?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  /** Stores the content for the Agreement that was signed by a Client upon purchasing a Membership */
  signedAgreementContent?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  /** Stores the datetime when the stripe subscription started. Could be in future, past or now. */
  startedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
  stripeSubscriptionScheduleId?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "client_membership" */
export type ClientMembershipMutationResponse = {
  __typename?: "ClientMembershipMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ClientMembership>;
};

/** input type for inserting object relation for remote table "client_membership" */
export type ClientMembershipObjRelInsertInput = {
  data: ClientMembershipInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClientMembershipOnConflict>;
};

/** on_conflict condition type for table "client_membership" */
export type ClientMembershipOnConflict = {
  constraint: ClientMembershipConstraint;
  updateColumns?: Array<ClientMembershipUpdateColumn>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

/** Ordering options when selecting data from "client_membership". */
export type ClientMembershipOrderBy = {
  cancelAt?: InputMaybe<OrderBy>;
  canceledAt?: InputMaybe<OrderBy>;
  cancellationContext?: InputMaybe<OrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  membership?: InputMaybe<MembershipOrderBy>;
  membershipId?: InputMaybe<OrderBy>;
  membershipLinesAggregate?: InputMaybe<MembershipLineAggregateOrderBy>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfPrintout?: InputMaybe<OrderBy>;
  purchaseSource?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  signedAgreementContent?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
  stripeSubscriptionScheduleId?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: client_membership */
export type ClientMembershipPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "client_membership" */
export enum ClientMembershipSelectColumn {
  /** column name */
  CancelAt = "cancelAt",
  /** column name */
  CanceledAt = "canceledAt",
  /** column name */
  CancellationContext = "cancellationContext",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  MembershipId = "membershipId",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfPrintout = "pdfPrintout",
  /** column name */
  PurchaseSource = "purchaseSource",
  /** column name */
  Signature = "signature",
  /** column name */
  SignedAgreementContent = "signedAgreementContent",
  /** column name */
  SignedAt = "signedAt",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  Status = "status",
  /** column name */
  StripeSubscriptionId = "stripeSubscriptionId",
  /** column name */
  StripeSubscriptionScheduleId = "stripeSubscriptionScheduleId",
  /** column name */
  UserAgent = "userAgent",
}

/** input type for updating data in table "client_membership" */
export type ClientMembershipSetInput = {
  /** Stores the datetime when the Membership will be/was effectively canceled */
  cancelAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the datetime when the cancelation of the Membership was requested */
  canceledAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  cancellationContext?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  membershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  pdfPrintout?: InputMaybe<Scalars["String"]["input"]>;
  purchaseSource?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores the content for the Agreement that was signed by a Client upon purchasing a Membership */
  signedAgreementContent?: InputMaybe<Scalars["String"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the datetime when the stripe subscription started. Could be in future, past or now. */
  startedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionScheduleId?: InputMaybe<Scalars["String"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ClientMembershipStddevFields = {
  __typename?: "ClientMembershipStddevFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "client_membership" */
export type ClientMembershipStddevOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ClientMembershipStddevPopFields = {
  __typename?: "ClientMembershipStddevPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "client_membership" */
export type ClientMembershipStddevPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ClientMembershipStddevSampFields = {
  __typename?: "ClientMembershipStddevSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "client_membership" */
export type ClientMembershipStddevSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "client_membership" */
export type ClientMembershipStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClientMembershipStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientMembershipStreamCursorValueInput = {
  /** Stores the datetime when the Membership will be/was effectively canceled */
  cancelAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the datetime when the cancelation of the Membership was requested */
  canceledAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  cancellationContext?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  membershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  pdfPrintout?: InputMaybe<Scalars["String"]["input"]>;
  purchaseSource?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores the content for the Agreement that was signed by a Client upon purchasing a Membership */
  signedAgreementContent?: InputMaybe<Scalars["String"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the datetime when the stripe subscription started. Could be in future, past or now. */
  startedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionScheduleId?: InputMaybe<Scalars["String"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ClientMembershipSumFields = {
  __typename?: "ClientMembershipSumFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  membershipId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "client_membership" */
export type ClientMembershipSumOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

export type ClientMembershipType = {
  __typename?: "ClientMembershipType";
  clientSignatureUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  shouldChargeEarlyCancellationFee?: Maybe<Scalars["Boolean"]["output"]>;
};

/** update columns of table "client_membership" */
export enum ClientMembershipUpdateColumn {
  /** column name */
  CancelAt = "cancelAt",
  /** column name */
  CanceledAt = "canceledAt",
  /** column name */
  CancellationContext = "cancellationContext",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  MembershipId = "membershipId",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfPrintout = "pdfPrintout",
  /** column name */
  PurchaseSource = "purchaseSource",
  /** column name */
  Signature = "signature",
  /** column name */
  SignedAgreementContent = "signedAgreementContent",
  /** column name */
  SignedAt = "signedAt",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  Status = "status",
  /** column name */
  StripeSubscriptionId = "stripeSubscriptionId",
  /** column name */
  StripeSubscriptionScheduleId = "stripeSubscriptionScheduleId",
  /** column name */
  UserAgent = "userAgent",
}

export type ClientMembershipUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClientMembershipIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientMembershipSetInput>;
  /** filter the rows which have to be updated */
  where: ClientMembershipBoolExp;
};

/** aggregate varPop on columns */
export type ClientMembershipVarPopFields = {
  __typename?: "ClientMembershipVarPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "client_membership" */
export type ClientMembershipVarPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ClientMembershipVarSampFields = {
  __typename?: "ClientMembershipVarSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "client_membership" */
export type ClientMembershipVarSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClientMembershipVarianceFields = {
  __typename?: "ClientMembershipVarianceFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "client_membership" */
export type ClientMembershipVarianceOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

export type ClientPublicInfoType = {
  __typename?: "ClientPublicInfoType";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type ClientStripeType = {
  __typename?: "ClientStripeType";
  id: Scalars["ID"]["output"];
  stripeCardsList: Array<Maybe<MoxieCardType>>;
};

export type ClientType = {
  __typename?: "ClientType";
  files: Array<ClientFileType>;
  id: Scalars["ID"]["output"];
};

export type ClientWalletType = {
  __typename?: "ClientWalletType";
  currentSums: WalletSumsType;
};

/** This table stores compliance tasks. They are used to track compliance tasks for MedSpas and Providers. */
export type ComplianceTask = {
  __typename?: "ComplianceTask";
  /** An array relationship */
  complianceTaskDocuments: Array<ComplianceTaskDocument>;
  /** An aggregate relationship */
  complianceTaskDocumentsAggregate: ComplianceTaskDocumentAggregate;
  /** An object relationship */
  complianceTaskTemplate: ComplianceTaskTemplate;
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** Detailed description of the compliance task created from template. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Date in which the task expires. */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  medspa?: Maybe<Medspa>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** Name of the compliance task created from template. */
  name: Scalars["String"]["output"];
  /** An object relationship */
  professionalCorporation?: Maybe<MedspasProfessionalcorporation>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  provider?: Maybe<UserMedspa>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Indicates if the task is required (given that it can be mark as not required). */
  required: Scalars["Boolean"]["output"];
  /** Current status of the compliance task. */
  status: Scalars["String"]["output"];
};

/** This table stores compliance tasks. They are used to track compliance tasks for MedSpas and Providers. */
export type ComplianceTaskComplianceTaskDocumentsArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskDocumentOrderBy>>;
  where?: InputMaybe<ComplianceTaskDocumentBoolExp>;
};

/** This table stores compliance tasks. They are used to track compliance tasks for MedSpas and Providers. */
export type ComplianceTaskComplianceTaskDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskDocumentOrderBy>>;
  where?: InputMaybe<ComplianceTaskDocumentBoolExp>;
};

/** aggregated selection of "compliance_task" */
export type ComplianceTaskAggregate = {
  __typename?: "ComplianceTaskAggregate";
  aggregate?: Maybe<ComplianceTaskAggregateFields>;
  nodes: Array<ComplianceTask>;
};

export type ComplianceTaskAggregateBoolExp = {
  bool_and?: InputMaybe<ComplianceTaskAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ComplianceTaskAggregateBoolExpBool_Or>;
  count?: InputMaybe<ComplianceTaskAggregateBoolExpCount>;
};

/** aggregate fields of "compliance_task" */
export type ComplianceTaskAggregateFields = {
  __typename?: "ComplianceTaskAggregateFields";
  avg?: Maybe<ComplianceTaskAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ComplianceTaskMaxFields>;
  min?: Maybe<ComplianceTaskMinFields>;
  stddev?: Maybe<ComplianceTaskStddevFields>;
  stddevPop?: Maybe<ComplianceTaskStddevPopFields>;
  stddevSamp?: Maybe<ComplianceTaskStddevSampFields>;
  sum?: Maybe<ComplianceTaskSumFields>;
  varPop?: Maybe<ComplianceTaskVarPopFields>;
  varSamp?: Maybe<ComplianceTaskVarSampFields>;
  variance?: Maybe<ComplianceTaskVarianceFields>;
};

/** aggregate fields of "compliance_task" */
export type ComplianceTaskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ComplianceTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "compliance_task" */
export type ComplianceTaskAggregateOrderBy = {
  avg?: InputMaybe<ComplianceTaskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ComplianceTaskMaxOrderBy>;
  min?: InputMaybe<ComplianceTaskMinOrderBy>;
  stddev?: InputMaybe<ComplianceTaskStddevOrderBy>;
  stddevPop?: InputMaybe<ComplianceTaskStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ComplianceTaskStddevSampOrderBy>;
  sum?: InputMaybe<ComplianceTaskSumOrderBy>;
  varPop?: InputMaybe<ComplianceTaskVarPopOrderBy>;
  varSamp?: InputMaybe<ComplianceTaskVarSampOrderBy>;
  variance?: InputMaybe<ComplianceTaskVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "compliance_task" */
export type ComplianceTaskArrRelInsertInput = {
  data: Array<ComplianceTaskInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ComplianceTaskOnConflict>;
};

/** aggregate avg on columns */
export type ComplianceTaskAvgFields = {
  __typename?: "ComplianceTaskAvgFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "compliance_task" */
export type ComplianceTaskAvgOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "compliance_task". All fields are combined with a logical 'AND'. */
export type ComplianceTaskBoolExp = {
  _and?: InputMaybe<Array<ComplianceTaskBoolExp>>;
  _not?: InputMaybe<ComplianceTaskBoolExp>;
  _or?: InputMaybe<Array<ComplianceTaskBoolExp>>;
  complianceTaskDocuments?: InputMaybe<ComplianceTaskDocumentBoolExp>;
  complianceTaskDocumentsAggregate?: InputMaybe<ComplianceTaskDocumentAggregateBoolExp>;
  complianceTaskTemplate?: InputMaybe<ComplianceTaskTemplateBoolExp>;
  complianceTaskTemplateId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  markedNotRequiredById?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  professionalCorporation?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
  professionalCorporationId?: InputMaybe<BigintComparisonExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  required?: InputMaybe<BooleanComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "compliance_task" */
export enum ComplianceTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  ComplianceTaskPkey = "compliance_task_pkey",
  /** unique or primary key constraint on columns "compliance_task_template_id", "medspa_id" */
  UniqueComplianceTaskForMedspa = "unique_compliance_task_for_medspa",
  /** unique or primary key constraint on columns "compliance_task_template_id", "professional_corporation_id", "medspa_id" */
  UniqueComplianceTaskForMedspaOrPc = "unique_compliance_task_for_medspa_or_pc",
  /** unique or primary key constraint on columns "compliance_task_template_id", "medspa_id", "provider_id" */
  UniqueComplianceTaskForProvider = "unique_compliance_task_for_provider",
}

/** This table stores documents related to compliance tasks. */
export type ComplianceTaskDocument = {
  __typename?: "ComplianceTaskDocument";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId: Scalars["String"]["output"];
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  complianceTask: ComplianceTask;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource: Scalars["String"]["output"];
  /** Document current status */
  status: Scalars["String"]["output"];
};

/** aggregated selection of "compliance_task_document" */
export type ComplianceTaskDocumentAggregate = {
  __typename?: "ComplianceTaskDocumentAggregate";
  aggregate?: Maybe<ComplianceTaskDocumentAggregateFields>;
  nodes: Array<ComplianceTaskDocument>;
};

export type ComplianceTaskDocumentAggregateBoolExp = {
  count?: InputMaybe<ComplianceTaskDocumentAggregateBoolExpCount>;
};

/** aggregate fields of "compliance_task_document" */
export type ComplianceTaskDocumentAggregateFields = {
  __typename?: "ComplianceTaskDocumentAggregateFields";
  avg?: Maybe<ComplianceTaskDocumentAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ComplianceTaskDocumentMaxFields>;
  min?: Maybe<ComplianceTaskDocumentMinFields>;
  stddev?: Maybe<ComplianceTaskDocumentStddevFields>;
  stddevPop?: Maybe<ComplianceTaskDocumentStddevPopFields>;
  stddevSamp?: Maybe<ComplianceTaskDocumentStddevSampFields>;
  sum?: Maybe<ComplianceTaskDocumentSumFields>;
  varPop?: Maybe<ComplianceTaskDocumentVarPopFields>;
  varSamp?: Maybe<ComplianceTaskDocumentVarSampFields>;
  variance?: Maybe<ComplianceTaskDocumentVarianceFields>;
};

/** aggregate fields of "compliance_task_document" */
export type ComplianceTaskDocumentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ComplianceTaskDocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "compliance_task_document" */
export type ComplianceTaskDocumentAggregateOrderBy = {
  avg?: InputMaybe<ComplianceTaskDocumentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ComplianceTaskDocumentMaxOrderBy>;
  min?: InputMaybe<ComplianceTaskDocumentMinOrderBy>;
  stddev?: InputMaybe<ComplianceTaskDocumentStddevOrderBy>;
  stddevPop?: InputMaybe<ComplianceTaskDocumentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ComplianceTaskDocumentStddevSampOrderBy>;
  sum?: InputMaybe<ComplianceTaskDocumentSumOrderBy>;
  varPop?: InputMaybe<ComplianceTaskDocumentVarPopOrderBy>;
  varSamp?: InputMaybe<ComplianceTaskDocumentVarSampOrderBy>;
  variance?: InputMaybe<ComplianceTaskDocumentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "compliance_task_document" */
export type ComplianceTaskDocumentArrRelInsertInput = {
  data: Array<ComplianceTaskDocumentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ComplianceTaskDocumentOnConflict>;
};

/** aggregate avg on columns */
export type ComplianceTaskDocumentAvgFields = {
  __typename?: "ComplianceTaskDocumentAvgFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["Float"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentAvgOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "compliance_task_document". All fields are combined with a logical 'AND'. */
export type ComplianceTaskDocumentBoolExp = {
  _and?: InputMaybe<Array<ComplianceTaskDocumentBoolExp>>;
  _not?: InputMaybe<ComplianceTaskDocumentBoolExp>;
  _or?: InputMaybe<Array<ComplianceTaskDocumentBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  archivedById?: InputMaybe<BigintComparisonExp>;
  boldsignDocumentId?: InputMaybe<StringComparisonExp>;
  completedAt?: InputMaybe<TimestamptzComparisonExp>;
  complianceTask?: InputMaybe<ComplianceTaskBoolExp>;
  complianceTaskId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  pdfFilePath?: InputMaybe<StringComparisonExp>;
  pdfFileSource?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "compliance_task_document" */
export enum ComplianceTaskDocumentConstraint {
  /** unique or primary key constraint on columns "id" */
  ComplianceTaskDocumentPkey = "compliance_task_document_pkey",
}

/** input type for incrementing numeric columns in table "compliance_task_document" */
export type ComplianceTaskDocumentIncInput = {
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "compliance_task_document" */
export type ComplianceTaskDocumentInsertInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  complianceTask?: InputMaybe<ComplianceTaskObjRelInsertInput>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ComplianceTaskDocumentMaxFields = {
  __typename?: "ComplianceTaskDocumentMaxFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: Maybe<Scalars["String"]["output"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: Maybe<Scalars["String"]["output"]>;
  /** Document current status */
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<OrderBy>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<OrderBy>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<OrderBy>;
  /** Document current status */
  status?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ComplianceTaskDocumentMinFields = {
  __typename?: "ComplianceTaskDocumentMinFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: Maybe<Scalars["String"]["output"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: Maybe<Scalars["String"]["output"]>;
  /** Document current status */
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<OrderBy>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<OrderBy>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<OrderBy>;
  /** Document current status */
  status?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "compliance_task_document" */
export type ComplianceTaskDocumentMutationResponse = {
  __typename?: "ComplianceTaskDocumentMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ComplianceTaskDocument>;
};

/** on_conflict condition type for table "compliance_task_document" */
export type ComplianceTaskDocumentOnConflict = {
  constraint: ComplianceTaskDocumentConstraint;
  updateColumns?: Array<ComplianceTaskDocumentUpdateColumn>;
  where?: InputMaybe<ComplianceTaskDocumentBoolExp>;
};

/** Ordering options when selecting data from "compliance_task_document". */
export type ComplianceTaskDocumentOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  boldsignDocumentId?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  complianceTask?: InputMaybe<ComplianceTaskOrderBy>;
  complianceTaskId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfFilePath?: InputMaybe<OrderBy>;
  pdfFileSource?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: compliance_task_document */
export type ComplianceTaskDocumentPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "compliance_task_document" */
export enum ComplianceTaskDocumentSelectColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  BoldsignDocumentId = "boldsignDocumentId",
  /** column name */
  CompletedAt = "completedAt",
  /** column name */
  ComplianceTaskId = "complianceTaskId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfFilePath = "pdfFilePath",
  /** column name */
  PdfFileSource = "pdfFileSource",
  /** column name */
  Status = "status",
}

/** input type for updating data in table "compliance_task_document" */
export type ComplianceTaskDocumentSetInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ComplianceTaskDocumentStddevFields = {
  __typename?: "ComplianceTaskDocumentStddevFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["Float"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentStddevOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ComplianceTaskDocumentStddevPopFields = {
  __typename?: "ComplianceTaskDocumentStddevPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["Float"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentStddevPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ComplianceTaskDocumentStddevSampFields = {
  __typename?: "ComplianceTaskDocumentStddevSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["Float"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentStddevSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "compliance_task_document" */
export type ComplianceTaskDocumentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ComplianceTaskDocumentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ComplianceTaskDocumentStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ComplianceTaskDocumentSumFields = {
  __typename?: "ComplianceTaskDocumentSumFields";
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["bigint"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentSumOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "compliance_task_document" */
export enum ComplianceTaskDocumentUpdateColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  BoldsignDocumentId = "boldsignDocumentId",
  /** column name */
  CompletedAt = "completedAt",
  /** column name */
  ComplianceTaskId = "complianceTaskId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfFilePath = "pdfFilePath",
  /** column name */
  PdfFileSource = "pdfFileSource",
  /** column name */
  Status = "status",
}

export type ComplianceTaskDocumentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ComplianceTaskDocumentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ComplianceTaskDocumentSetInput>;
  /** filter the rows which have to be updated */
  where: ComplianceTaskDocumentBoolExp;
};

/** aggregate varPop on columns */
export type ComplianceTaskDocumentVarPopFields = {
  __typename?: "ComplianceTaskDocumentVarPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["Float"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentVarPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ComplianceTaskDocumentVarSampFields = {
  __typename?: "ComplianceTaskDocumentVarSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["Float"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentVarSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ComplianceTaskDocumentVarianceFields = {
  __typename?: "ComplianceTaskDocumentVarianceFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: Maybe<Scalars["Float"]["output"]>;
  /** User that uploaded the document. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "compliance_task_document" */
export type ComplianceTaskDocumentVarianceOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** Reference to the compliance task that this document is related to. */
  complianceTaskId?: InputMaybe<OrderBy>;
  /** User that uploaded the document. */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "compliance_task" */
export type ComplianceTaskIncInput = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "compliance_task" */
export type ComplianceTaskInsertInput = {
  complianceTaskDocuments?: InputMaybe<ComplianceTaskDocumentArrRelInsertInput>;
  complianceTaskTemplate?: InputMaybe<ComplianceTaskTemplateObjRelInsertInput>;
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the compliance task created from template. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the task expires. */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the compliance task created from template. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  professionalCorporation?: InputMaybe<MedspasProfessionalcorporationObjRelInsertInput>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<Scalars["bigint"]["input"]>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the task is required (given that it can be mark as not required). */
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Current status of the compliance task. */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ComplianceTaskMaxFields = {
  __typename?: "ComplianceTaskMaxFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the compliance task created from template. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Date in which the task expires. */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the compliance task created from template. */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["bigint"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current status of the compliance task. */
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "compliance_task" */
export type ComplianceTaskMaxOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Detailed description of the compliance task created from template. */
  description?: InputMaybe<OrderBy>;
  /** Date in which the task expires. */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Name of the compliance task created from template. */
  name?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
  /** Current status of the compliance task. */
  status?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ComplianceTaskMinFields = {
  __typename?: "ComplianceTaskMinFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the compliance task created from template. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Date in which the task expires. */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the compliance task created from template. */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["bigint"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current status of the compliance task. */
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "compliance_task" */
export type ComplianceTaskMinOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Detailed description of the compliance task created from template. */
  description?: InputMaybe<OrderBy>;
  /** Date in which the task expires. */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Name of the compliance task created from template. */
  name?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
  /** Current status of the compliance task. */
  status?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "compliance_task" */
export type ComplianceTaskMutationResponse = {
  __typename?: "ComplianceTaskMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ComplianceTask>;
};

/** input type for inserting object relation for remote table "compliance_task" */
export type ComplianceTaskObjRelInsertInput = {
  data: ComplianceTaskInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ComplianceTaskOnConflict>;
};

/** on_conflict condition type for table "compliance_task" */
export type ComplianceTaskOnConflict = {
  constraint: ComplianceTaskConstraint;
  updateColumns?: Array<ComplianceTaskUpdateColumn>;
  where?: InputMaybe<ComplianceTaskBoolExp>;
};

/** Ordering options when selecting data from "compliance_task". */
export type ComplianceTaskOrderBy = {
  complianceTaskDocumentsAggregate?: InputMaybe<ComplianceTaskDocumentAggregateOrderBy>;
  complianceTaskTemplate?: InputMaybe<ComplianceTaskTemplateOrderBy>;
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markedNotRequiredById?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  professionalCorporation?: InputMaybe<MedspasProfessionalcorporationOrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  required?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: compliance_task */
export type ComplianceTaskPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "compliance_task" */
export enum ComplianceTaskSelectColumn {
  /** column name */
  ComplianceTaskTemplateId = "complianceTaskTemplateId",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  MarkedNotRequiredById = "markedNotRequiredById",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ProfessionalCorporationId = "professionalCorporationId",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  Required = "required",
  /** column name */
  Status = "status",
}

/** select "complianceTaskAggregateBoolExpBool_andArgumentsColumns" columns of table "compliance_task" */
export enum ComplianceTaskSelectColumnComplianceTaskAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Required = "required",
}

/** select "complianceTaskAggregateBoolExpBool_orArgumentsColumns" columns of table "compliance_task" */
export enum ComplianceTaskSelectColumnComplianceTaskAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Required = "required",
}

/** input type for updating data in table "compliance_task" */
export type ComplianceTaskSetInput = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the compliance task created from template. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the task expires. */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the compliance task created from template. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the task is required (given that it can be mark as not required). */
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Current status of the compliance task. */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ComplianceTaskStddevFields = {
  __typename?: "ComplianceTaskStddevFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "compliance_task" */
export type ComplianceTaskStddevOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ComplianceTaskStddevPopFields = {
  __typename?: "ComplianceTaskStddevPopFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "compliance_task" */
export type ComplianceTaskStddevPopOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ComplianceTaskStddevSampFields = {
  __typename?: "ComplianceTaskStddevSampFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "compliance_task" */
export type ComplianceTaskStddevSampOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "compliance_task" */
export type ComplianceTaskStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ComplianceTaskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ComplianceTaskStreamCursorValueInput = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the compliance task created from template. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the task expires. */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the compliance task created from template. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the task is required (given that it can be mark as not required). */
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Current status of the compliance task. */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ComplianceTaskSumFields = {
  __typename?: "ComplianceTaskSumFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["bigint"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "compliance_task" */
export type ComplianceTaskSumOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
};

/** This table stores compliance task templates. They are used to create compliance tasks for MedSpas and Providers. */
export type ComplianceTaskTemplate = {
  __typename?: "ComplianceTaskTemplate";
  /** If true, tasks created from this template can be marked as not required. */
  canMarkNotRequired: Scalars["Boolean"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** Detailed description of the compliance task template. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** If true, tasks created from this template will have a expires_at field. */
  expires: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** Indicates if tasks created from this template are assigned to MSO MedSpas. */
  isMsoTask: Scalars["Boolean"]["output"];
  /** Indicates if tasks created from this template are assigned to providers. */
  isProviderTask: Scalars["Boolean"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Name of the compliance task template. */
  name: Scalars["String"]["output"];
  /** Indicates if tasks created from this template require a document to be uploaded. */
  requiresDocument: Scalars["Boolean"]["output"];
};

/** aggregated selection of "compliance_task_template" */
export type ComplianceTaskTemplateAggregate = {
  __typename?: "ComplianceTaskTemplateAggregate";
  aggregate?: Maybe<ComplianceTaskTemplateAggregateFields>;
  nodes: Array<ComplianceTaskTemplate>;
};

/** aggregate fields of "compliance_task_template" */
export type ComplianceTaskTemplateAggregateFields = {
  __typename?: "ComplianceTaskTemplateAggregateFields";
  avg?: Maybe<ComplianceTaskTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ComplianceTaskTemplateMaxFields>;
  min?: Maybe<ComplianceTaskTemplateMinFields>;
  stddev?: Maybe<ComplianceTaskTemplateStddevFields>;
  stddevPop?: Maybe<ComplianceTaskTemplateStddevPopFields>;
  stddevSamp?: Maybe<ComplianceTaskTemplateStddevSampFields>;
  sum?: Maybe<ComplianceTaskTemplateSumFields>;
  varPop?: Maybe<ComplianceTaskTemplateVarPopFields>;
  varSamp?: Maybe<ComplianceTaskTemplateVarSampFields>;
  variance?: Maybe<ComplianceTaskTemplateVarianceFields>;
};

/** aggregate fields of "compliance_task_template" */
export type ComplianceTaskTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ComplianceTaskTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type ComplianceTaskTemplateAvgFields = {
  __typename?: "ComplianceTaskTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "compliance_task_template". All fields are combined with a logical 'AND'. */
export type ComplianceTaskTemplateBoolExp = {
  _and?: InputMaybe<Array<ComplianceTaskTemplateBoolExp>>;
  _not?: InputMaybe<ComplianceTaskTemplateBoolExp>;
  _or?: InputMaybe<Array<ComplianceTaskTemplateBoolExp>>;
  canMarkNotRequired?: InputMaybe<BooleanComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  expires?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isMsoTask?: InputMaybe<BooleanComparisonExp>;
  isProviderTask?: InputMaybe<BooleanComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  requiresDocument?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "compliance_task_template" */
export enum ComplianceTaskTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  ComplianceTaskTemplatePkey = "compliance_task_template_pkey",
}

/** input type for incrementing numeric columns in table "compliance_task_template" */
export type ComplianceTaskTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "compliance_task_template" */
export type ComplianceTaskTemplateInsertInput = {
  /** If true, tasks created from this template can be marked as not required. */
  canMarkNotRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the compliance task template. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** If true, tasks created from this template will have a expires_at field. */
  expires?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if tasks created from this template are assigned to MSO MedSpas. */
  isMsoTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if tasks created from this template are assigned to providers. */
  isProviderTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the compliance task template. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates if tasks created from this template require a document to be uploaded. */
  requiresDocument?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type ComplianceTaskTemplateMaxFields = {
  __typename?: "ComplianceTaskTemplateMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the compliance task template. */
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the compliance task template. */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type ComplianceTaskTemplateMinFields = {
  __typename?: "ComplianceTaskTemplateMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the compliance task template. */
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the compliance task template. */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "compliance_task_template" */
export type ComplianceTaskTemplateMutationResponse = {
  __typename?: "ComplianceTaskTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ComplianceTaskTemplate>;
};

/** input type for inserting object relation for remote table "compliance_task_template" */
export type ComplianceTaskTemplateObjRelInsertInput = {
  data: ComplianceTaskTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ComplianceTaskTemplateOnConflict>;
};

/** on_conflict condition type for table "compliance_task_template" */
export type ComplianceTaskTemplateOnConflict = {
  constraint: ComplianceTaskTemplateConstraint;
  updateColumns?: Array<ComplianceTaskTemplateUpdateColumn>;
  where?: InputMaybe<ComplianceTaskTemplateBoolExp>;
};

/** Ordering options when selecting data from "compliance_task_template". */
export type ComplianceTaskTemplateOrderBy = {
  canMarkNotRequired?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  expires?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isMsoTask?: InputMaybe<OrderBy>;
  isProviderTask?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  requiresDocument?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: compliance_task_template */
export type ComplianceTaskTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "compliance_task_template" */
export enum ComplianceTaskTemplateSelectColumn {
  /** column name */
  CanMarkNotRequired = "canMarkNotRequired",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  Expires = "expires",
  /** column name */
  Id = "id",
  /** column name */
  IsMsoTask = "isMsoTask",
  /** column name */
  IsProviderTask = "isProviderTask",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  RequiresDocument = "requiresDocument",
}

/** input type for updating data in table "compliance_task_template" */
export type ComplianceTaskTemplateSetInput = {
  /** If true, tasks created from this template can be marked as not required. */
  canMarkNotRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the compliance task template. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** If true, tasks created from this template will have a expires_at field. */
  expires?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if tasks created from this template are assigned to MSO MedSpas. */
  isMsoTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if tasks created from this template are assigned to providers. */
  isProviderTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the compliance task template. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates if tasks created from this template require a document to be uploaded. */
  requiresDocument?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate stddev on columns */
export type ComplianceTaskTemplateStddevFields = {
  __typename?: "ComplianceTaskTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type ComplianceTaskTemplateStddevPopFields = {
  __typename?: "ComplianceTaskTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type ComplianceTaskTemplateStddevSampFields = {
  __typename?: "ComplianceTaskTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "compliance_task_template" */
export type ComplianceTaskTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ComplianceTaskTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ComplianceTaskTemplateStreamCursorValueInput = {
  /** If true, tasks created from this template can be marked as not required. */
  canMarkNotRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the compliance task template. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** If true, tasks created from this template will have a expires_at field. */
  expires?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if tasks created from this template are assigned to MSO MedSpas. */
  isMsoTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if tasks created from this template are assigned to providers. */
  isProviderTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the compliance task template. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates if tasks created from this template require a document to be uploaded. */
  requiresDocument?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate sum on columns */
export type ComplianceTaskTemplateSumFields = {
  __typename?: "ComplianceTaskTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

export type ComplianceTaskTemplateType = {
  __typename?: "ComplianceTaskTemplateType";
  canMarkNotRequired?: Maybe<Scalars["Boolean"]["output"]>;
  expires?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["BigInt"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** update columns of table "compliance_task_template" */
export enum ComplianceTaskTemplateUpdateColumn {
  /** column name */
  CanMarkNotRequired = "canMarkNotRequired",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  Expires = "expires",
  /** column name */
  Id = "id",
  /** column name */
  IsMsoTask = "isMsoTask",
  /** column name */
  IsProviderTask = "isProviderTask",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  RequiresDocument = "requiresDocument",
}

export type ComplianceTaskTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ComplianceTaskTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ComplianceTaskTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: ComplianceTaskTemplateBoolExp;
};

/** aggregate varPop on columns */
export type ComplianceTaskTemplateVarPopFields = {
  __typename?: "ComplianceTaskTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type ComplianceTaskTemplateVarSampFields = {
  __typename?: "ComplianceTaskTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type ComplianceTaskTemplateVarianceFields = {
  __typename?: "ComplianceTaskTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type ComplianceTaskType = {
  __typename?: "ComplianceTaskType";
  complianceTaskTemplate?: Maybe<ComplianceTaskTemplateType>;
  id?: Maybe<Scalars["BigInt"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** update columns of table "compliance_task" */
export enum ComplianceTaskUpdateColumn {
  /** column name */
  ComplianceTaskTemplateId = "complianceTaskTemplateId",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  MarkedNotRequiredById = "markedNotRequiredById",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ProfessionalCorporationId = "professionalCorporationId",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  Required = "required",
  /** column name */
  Status = "status",
}

export type ComplianceTaskUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ComplianceTaskIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ComplianceTaskSetInput>;
  /** filter the rows which have to be updated */
  where: ComplianceTaskBoolExp;
};

/** aggregate varPop on columns */
export type ComplianceTaskVarPopFields = {
  __typename?: "ComplianceTaskVarPopFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "compliance_task" */
export type ComplianceTaskVarPopOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ComplianceTaskVarSampFields = {
  __typename?: "ComplianceTaskVarSampFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "compliance_task" */
export type ComplianceTaskVarSampOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ComplianceTaskVarianceFields = {
  __typename?: "ComplianceTaskVarianceFields";
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the compliance task */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider for which this task applies to */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "compliance_task" */
export type ComplianceTaskVarianceOrderBy = {
  /** Compliance task template used to create this task. */
  complianceTaskTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** User that marked the task as not required. */
  markedNotRequiredById?: InputMaybe<OrderBy>;
  /** MedSpa that owns the compliance task */
  medspaId?: InputMaybe<OrderBy>;
  /** Professional Corporation that owns the compliance task (if not owned by MedSpa) */
  professionalCorporationId?: InputMaybe<OrderBy>;
  /** Provider for which this task applies to */
  providerId?: InputMaybe<OrderBy>;
};

/** This table stores compliant notes for Visits. They are created from compliant note templates                             managed by Moxie Product team, and they help providers chart in a compliant manner. */
export type CompliantNote = {
  __typename?: "CompliantNote";
  /** An object relationship */
  compliantNoteTemplate?: Maybe<CompliantNoteTemplate>;
  /** Template used to create this compliant note. */
  compliantNoteTemplateId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaServiceOffering?: Maybe<MedspaServiceOffering>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** Form schema for this compliant note. */
  schema: Scalars["jsonb"]["output"];
  /** Section of the Chart for this compliant note. */
  section: Scalars["String"]["output"];
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData: Scalars["jsonb"]["output"];
  /** Title of the compliant note. */
  title: Scalars["String"]["output"];
  /** Form UI schema for this compliant note. */
  uiSchema: Scalars["jsonb"]["output"];
  /** An object relationship */
  visit: Visit;
  /** Visit to which this compliant note belongs. */
  visitId: Scalars["bigint"]["output"];
};

/** This table stores compliant notes for Visits. They are created from compliant note templates                             managed by Moxie Product team, and they help providers chart in a compliant manner. */
export type CompliantNoteSchemaArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** This table stores compliant notes for Visits. They are created from compliant note templates                             managed by Moxie Product team, and they help providers chart in a compliant manner. */
export type CompliantNoteSubmittedDataArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** This table stores compliant notes for Visits. They are created from compliant note templates                             managed by Moxie Product team, and they help providers chart in a compliant manner. */
export type CompliantNoteUiSchemaArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "compliant_note" */
export type CompliantNoteAggregate = {
  __typename?: "CompliantNoteAggregate";
  aggregate?: Maybe<CompliantNoteAggregateFields>;
  nodes: Array<CompliantNote>;
};

/** aggregate fields of "compliant_note" */
export type CompliantNoteAggregateFields = {
  __typename?: "CompliantNoteAggregateFields";
  avg?: Maybe<CompliantNoteAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<CompliantNoteMaxFields>;
  min?: Maybe<CompliantNoteMinFields>;
  stddev?: Maybe<CompliantNoteStddevFields>;
  stddevPop?: Maybe<CompliantNoteStddevPopFields>;
  stddevSamp?: Maybe<CompliantNoteStddevSampFields>;
  sum?: Maybe<CompliantNoteSumFields>;
  varPop?: Maybe<CompliantNoteVarPopFields>;
  varSamp?: Maybe<CompliantNoteVarSampFields>;
  variance?: Maybe<CompliantNoteVarianceFields>;
};

/** aggregate fields of "compliant_note" */
export type CompliantNoteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CompliantNoteSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CompliantNoteAppendInput = {
  /** Form schema for this compliant note. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Form UI schema for this compliant note. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate avg on columns */
export type CompliantNoteAvgFields = {
  __typename?: "CompliantNoteAvgFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "compliant_note". All fields are combined with a logical 'AND'. */
export type CompliantNoteBoolExp = {
  _and?: InputMaybe<Array<CompliantNoteBoolExp>>;
  _not?: InputMaybe<CompliantNoteBoolExp>;
  _or?: InputMaybe<Array<CompliantNoteBoolExp>>;
  compliantNoteTemplate?: InputMaybe<CompliantNoteTemplateBoolExp>;
  compliantNoteTemplateId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaServiceOfferingId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  schema?: InputMaybe<JsonbComparisonExp>;
  section?: InputMaybe<StringComparisonExp>;
  submittedData?: InputMaybe<JsonbComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  uiSchema?: InputMaybe<JsonbComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "compliant_note" */
export enum CompliantNoteConstraint {
  /** unique or primary key constraint on columns "id" */
  CompliantNotePkey = "compliant_note_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CompliantNoteDeleteAtPathInput = {
  /** Form schema for this compliant note. */
  schema?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Form UI schema for this compliant note. */
  uiSchema?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CompliantNoteDeleteElemInput = {
  /** Form schema for this compliant note. */
  schema?: InputMaybe<Scalars["Int"]["input"]>;
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData?: InputMaybe<Scalars["Int"]["input"]>;
  /** Form UI schema for this compliant note. */
  uiSchema?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CompliantNoteDeleteKeyInput = {
  /** Form schema for this compliant note. */
  schema?: InputMaybe<Scalars["String"]["input"]>;
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData?: InputMaybe<Scalars["String"]["input"]>;
  /** Form UI schema for this compliant note. */
  uiSchema?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "compliant_note" */
export type CompliantNoteIncInput = {
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "compliant_note" */
export type CompliantNoteInsertInput = {
  compliantNoteTemplate?: InputMaybe<CompliantNoteTemplateObjRelInsertInput>;
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Form schema for this compliant note. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Section of the Chart for this compliant note. */
  section?: InputMaybe<Scalars["String"]["input"]>;
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Title of the compliant note. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Form UI schema for this compliant note. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Visit to which this compliant note belongs. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type CompliantNoteMaxFields = {
  __typename?: "CompliantNoteMaxFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Section of the Chart for this compliant note. */
  section?: Maybe<Scalars["String"]["output"]>;
  /** Title of the compliant note. */
  title?: Maybe<Scalars["String"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregate min on columns */
export type CompliantNoteMinFields = {
  __typename?: "CompliantNoteMinFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Section of the Chart for this compliant note. */
  section?: Maybe<Scalars["String"]["output"]>;
  /** Title of the compliant note. */
  title?: Maybe<Scalars["String"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** response of any mutation on the table "compliant_note" */
export type CompliantNoteMutationResponse = {
  __typename?: "CompliantNoteMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<CompliantNote>;
};

/** on_conflict condition type for table "compliant_note" */
export type CompliantNoteOnConflict = {
  constraint: CompliantNoteConstraint;
  updateColumns?: Array<CompliantNoteUpdateColumn>;
  where?: InputMaybe<CompliantNoteBoolExp>;
};

/** Ordering options when selecting data from "compliant_note". */
export type CompliantNoteOrderBy = {
  compliantNoteTemplate?: InputMaybe<CompliantNoteTemplateOrderBy>;
  compliantNoteTemplateId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  schema?: InputMaybe<OrderBy>;
  section?: InputMaybe<OrderBy>;
  submittedData?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  uiSchema?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: compliant_note */
export type CompliantNotePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CompliantNotePrependInput = {
  /** Form schema for this compliant note. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Form UI schema for this compliant note. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "compliant_note" */
export enum CompliantNoteSelectColumn {
  /** column name */
  CompliantNoteTemplateId = "compliantNoteTemplateId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  Schema = "schema",
  /** column name */
  Section = "section",
  /** column name */
  SubmittedData = "submittedData",
  /** column name */
  Title = "title",
  /** column name */
  UiSchema = "uiSchema",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "compliant_note" */
export type CompliantNoteSetInput = {
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Form schema for this compliant note. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Section of the Chart for this compliant note. */
  section?: InputMaybe<Scalars["String"]["input"]>;
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Title of the compliant note. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Form UI schema for this compliant note. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type CompliantNoteStddevFields = {
  __typename?: "CompliantNoteStddevFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type CompliantNoteStddevPopFields = {
  __typename?: "CompliantNoteStddevPopFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type CompliantNoteStddevSampFields = {
  __typename?: "CompliantNoteStddevSampFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "compliant_note" */
export type CompliantNoteStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CompliantNoteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CompliantNoteStreamCursorValueInput = {
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Form schema for this compliant note. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Section of the Chart for this compliant note. */
  section?: InputMaybe<Scalars["String"]["input"]>;
  /** Data submitted by the provider for this note when completing the chart. */
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Title of the compliant note. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Form UI schema for this compliant note. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type CompliantNoteSumFields = {
  __typename?: "CompliantNoteSumFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** This table stores compliant note templates. Their content is managed by                             Moxie Product team and they are used to create compliant notes for                             Visits, which help providers chart in a compliant manner. */
export type CompliantNoteTemplate = {
  __typename?: "CompliantNoteTemplate";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Form schema for this compliant note template. */
  schema: Scalars["jsonb"]["output"];
  /** Section of the Chart for this compliant note template. */
  section: Scalars["String"]["output"];
  /** Title of the compliant note template. */
  title: Scalars["String"]["output"];
  /** Form UI schema for this compliant note template. */
  uiSchema: Scalars["jsonb"]["output"];
};

/** This table stores compliant note templates. Their content is managed by                             Moxie Product team and they are used to create compliant notes for                             Visits, which help providers chart in a compliant manner. */
export type CompliantNoteTemplateSchemaArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** This table stores compliant note templates. Their content is managed by                             Moxie Product team and they are used to create compliant notes for                             Visits, which help providers chart in a compliant manner. */
export type CompliantNoteTemplateUiSchemaArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "compliant_note_template" */
export type CompliantNoteTemplateAggregate = {
  __typename?: "CompliantNoteTemplateAggregate";
  aggregate?: Maybe<CompliantNoteTemplateAggregateFields>;
  nodes: Array<CompliantNoteTemplate>;
};

/** aggregate fields of "compliant_note_template" */
export type CompliantNoteTemplateAggregateFields = {
  __typename?: "CompliantNoteTemplateAggregateFields";
  avg?: Maybe<CompliantNoteTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<CompliantNoteTemplateMaxFields>;
  min?: Maybe<CompliantNoteTemplateMinFields>;
  stddev?: Maybe<CompliantNoteTemplateStddevFields>;
  stddevPop?: Maybe<CompliantNoteTemplateStddevPopFields>;
  stddevSamp?: Maybe<CompliantNoteTemplateStddevSampFields>;
  sum?: Maybe<CompliantNoteTemplateSumFields>;
  varPop?: Maybe<CompliantNoteTemplateVarPopFields>;
  varSamp?: Maybe<CompliantNoteTemplateVarSampFields>;
  variance?: Maybe<CompliantNoteTemplateVarianceFields>;
};

/** aggregate fields of "compliant_note_template" */
export type CompliantNoteTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CompliantNoteTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CompliantNoteTemplateAppendInput = {
  /** Form schema for this compliant note template. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Form UI schema for this compliant note template. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate avg on columns */
export type CompliantNoteTemplateAvgFields = {
  __typename?: "CompliantNoteTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "compliant_note_template". All fields are combined with a logical 'AND'. */
export type CompliantNoteTemplateBoolExp = {
  _and?: InputMaybe<Array<CompliantNoteTemplateBoolExp>>;
  _not?: InputMaybe<CompliantNoteTemplateBoolExp>;
  _or?: InputMaybe<Array<CompliantNoteTemplateBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  schema?: InputMaybe<JsonbComparisonExp>;
  section?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  uiSchema?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "compliant_note_template" */
export enum CompliantNoteTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  CompliantNoteTemplatePkey = "compliant_note_template_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CompliantNoteTemplateDeleteAtPathInput = {
  /** Form schema for this compliant note template. */
  schema?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Form UI schema for this compliant note template. */
  uiSchema?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CompliantNoteTemplateDeleteElemInput = {
  /** Form schema for this compliant note template. */
  schema?: InputMaybe<Scalars["Int"]["input"]>;
  /** Form UI schema for this compliant note template. */
  uiSchema?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CompliantNoteTemplateDeleteKeyInput = {
  /** Form schema for this compliant note template. */
  schema?: InputMaybe<Scalars["String"]["input"]>;
  /** Form UI schema for this compliant note template. */
  uiSchema?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "compliant_note_template" */
export type CompliantNoteTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "compliant_note_template" */
export type CompliantNoteTemplateInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Form schema for this compliant note template. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Section of the Chart for this compliant note template. */
  section?: InputMaybe<Scalars["String"]["input"]>;
  /** Title of the compliant note template. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Form UI schema for this compliant note template. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate max on columns */
export type CompliantNoteTemplateMaxFields = {
  __typename?: "CompliantNoteTemplateMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Section of the Chart for this compliant note template. */
  section?: Maybe<Scalars["String"]["output"]>;
  /** Title of the compliant note template. */
  title?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type CompliantNoteTemplateMinFields = {
  __typename?: "CompliantNoteTemplateMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Section of the Chart for this compliant note template. */
  section?: Maybe<Scalars["String"]["output"]>;
  /** Title of the compliant note template. */
  title?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "compliant_note_template" */
export type CompliantNoteTemplateMutationResponse = {
  __typename?: "CompliantNoteTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<CompliantNoteTemplate>;
};

/** input type for inserting object relation for remote table "compliant_note_template" */
export type CompliantNoteTemplateObjRelInsertInput = {
  data: CompliantNoteTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CompliantNoteTemplateOnConflict>;
};

/** on_conflict condition type for table "compliant_note_template" */
export type CompliantNoteTemplateOnConflict = {
  constraint: CompliantNoteTemplateConstraint;
  updateColumns?: Array<CompliantNoteTemplateUpdateColumn>;
  where?: InputMaybe<CompliantNoteTemplateBoolExp>;
};

/** Ordering options when selecting data from "compliant_note_template". */
export type CompliantNoteTemplateOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  schema?: InputMaybe<OrderBy>;
  section?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  uiSchema?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: compliant_note_template */
export type CompliantNoteTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CompliantNoteTemplatePrependInput = {
  /** Form schema for this compliant note template. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Form UI schema for this compliant note template. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "compliant_note_template" */
export enum CompliantNoteTemplateSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Schema = "schema",
  /** column name */
  Section = "section",
  /** column name */
  Title = "title",
  /** column name */
  UiSchema = "uiSchema",
}

/** input type for updating data in table "compliant_note_template" */
export type CompliantNoteTemplateSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Form schema for this compliant note template. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Section of the Chart for this compliant note template. */
  section?: InputMaybe<Scalars["String"]["input"]>;
  /** Title of the compliant note template. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Form UI schema for this compliant note template. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate stddev on columns */
export type CompliantNoteTemplateStddevFields = {
  __typename?: "CompliantNoteTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type CompliantNoteTemplateStddevPopFields = {
  __typename?: "CompliantNoteTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type CompliantNoteTemplateStddevSampFields = {
  __typename?: "CompliantNoteTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "compliant_note_template" */
export type CompliantNoteTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CompliantNoteTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CompliantNoteTemplateStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Form schema for this compliant note template. */
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Section of the Chart for this compliant note template. */
  section?: InputMaybe<Scalars["String"]["input"]>;
  /** Title of the compliant note template. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Form UI schema for this compliant note template. */
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate sum on columns */
export type CompliantNoteTemplateSumFields = {
  __typename?: "CompliantNoteTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "compliant_note_template" */
export enum CompliantNoteTemplateUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Schema = "schema",
  /** column name */
  Section = "section",
  /** column name */
  Title = "title",
  /** column name */
  UiSchema = "uiSchema",
}

export type CompliantNoteTemplateUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CompliantNoteTemplateAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<CompliantNoteTemplateDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<CompliantNoteTemplateDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<CompliantNoteTemplateDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompliantNoteTemplateIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CompliantNoteTemplatePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompliantNoteTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: CompliantNoteTemplateBoolExp;
};

/** aggregate varPop on columns */
export type CompliantNoteTemplateVarPopFields = {
  __typename?: "CompliantNoteTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type CompliantNoteTemplateVarSampFields = {
  __typename?: "CompliantNoteTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type CompliantNoteTemplateVarianceFields = {
  __typename?: "CompliantNoteTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** update columns of table "compliant_note" */
export enum CompliantNoteUpdateColumn {
  /** column name */
  CompliantNoteTemplateId = "compliantNoteTemplateId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  Schema = "schema",
  /** column name */
  Section = "section",
  /** column name */
  SubmittedData = "submittedData",
  /** column name */
  Title = "title",
  /** column name */
  UiSchema = "uiSchema",
  /** column name */
  VisitId = "visitId",
}

export type CompliantNoteUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CompliantNoteAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<CompliantNoteDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<CompliantNoteDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<CompliantNoteDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompliantNoteIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CompliantNotePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompliantNoteSetInput>;
  /** filter the rows which have to be updated */
  where: CompliantNoteBoolExp;
};

/** aggregate varPop on columns */
export type CompliantNoteVarPopFields = {
  __typename?: "CompliantNoteVarPopFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type CompliantNoteVarSampFields = {
  __typename?: "CompliantNoteVarSampFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type CompliantNoteVarianceFields = {
  __typename?: "CompliantNoteVarianceFields";
  /** Template used to create this compliant note. */
  compliantNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that this compliant note is associated with. */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit to which this compliant note belongs. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

export type ConfirmAppointment = {
  __typename?: "ConfirmAppointment";
  confirmedAppointment?: Maybe<AppointmentType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ConsentFormSignatureType = {
  __typename?: "ConsentFormSignatureType";
  id: Scalars["ID"]["output"];
  pdfPrintoutUrl?: Maybe<Scalars["String"]["output"]>;
  signatureUrl?: Maybe<Scalars["String"]["output"]>;
};

export type ContactType = {
  __typename?: "ContactType";
  additionalEmails?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  additionalPhones?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  address1?: Maybe<Scalars["String"]["output"]>;
  assignedTo?: Maybe<Scalars["String"]["output"]>;
  attachments?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  attributionSource?: Maybe<AttributionSourceType>;
  businessId?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  customFields?: Maybe<Array<Maybe<CustomFieldType>>>;
  dateAdded?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["String"]["output"]>;
  dateUpdated?: Maybe<Scalars["String"]["output"]>;
  dnd?: Maybe<Scalars["Boolean"]["output"]>;
  dndSettings?: Maybe<DndSettingsType>;
  email?: Maybe<Scalars["String"]["output"]>;
  emailLowerCase?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameLowerCase?: Maybe<Scalars["String"]["output"]>;
  fullNameLowerCase?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  keyword?: Maybe<Scalars["String"]["output"]>;
  lastActivity?: Maybe<Scalars["String"]["output"]>;
  lastAttributionSource?: Maybe<AttributionSourceType>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameLowerCase?: Maybe<Scalars["String"]["output"]>;
  locationId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  ssn?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  timeZone?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  website?: Maybe<Scalars["String"]["output"]>;
};

export enum ConversationLastMessageTypeValues {
  TypeActivity = "TYPE_ACTIVITY",
  TypeActivityAppointment = "TYPE_ACTIVITY_APPOINTMENT",
  TypeActivityContact = "TYPE_ACTIVITY_CONTACT",
  TypeActivityInvoice = "TYPE_ACTIVITY_INVOICE",
  TypeActivityOpportunity = "TYPE_ACTIVITY_OPPORTUNITY",
  TypeActivityPayment = "TYPE_ACTIVITY_PAYMENT",
  TypeCall = "TYPE_CALL",
  TypeCampaignCall = "TYPE_CAMPAIGN_CALL",
  TypeCampaignEmail = "TYPE_CAMPAIGN_EMAIL",
  TypeCampaignFacebook = "TYPE_CAMPAIGN_FACEBOOK",
  TypeCampaignGmb = "TYPE_CAMPAIGN_GMB",
  TypeCampaignManualCall = "TYPE_CAMPAIGN_MANUAL_CALL",
  TypeCampaignManualSms = "TYPE_CAMPAIGN_MANUAL_SMS",
  TypeCampaignSms = "TYPE_CAMPAIGN_SMS",
  TypeCampaignVoicemail = "TYPE_CAMPAIGN_VOICEMAIL",
  TypeCustomCall = "TYPE_CUSTOM_CALL",
  TypeCustomEmail = "TYPE_CUSTOM_EMAIL",
  TypeCustomProviderEmail = "TYPE_CUSTOM_PROVIDER_EMAIL",
  TypeCustomProviderSms = "TYPE_CUSTOM_PROVIDER_SMS",
  TypeCustomSms = "TYPE_CUSTOM_SMS",
  TypeEmail = "TYPE_EMAIL",
  TypeFacebook = "TYPE_FACEBOOK",
  TypeFacebookComment = "TYPE_FACEBOOK_COMMENT",
  TypeGmb = "TYPE_GMB",
  TypeInstagram = "TYPE_INSTAGRAM",
  TypeInstagramComment = "TYPE_INSTAGRAM_COMMENT",
  TypeIvrCall = "TYPE_IVR_CALL",
  TypeLiveChat = "TYPE_LIVE_CHAT",
  TypeLiveChatInfoMessage = "TYPE_LIVE_CHAT_INFO_MESSAGE",
  TypeNoShow = "TYPE_NO_SHOW",
  TypeReview = "TYPE_REVIEW",
  TypeSms = "TYPE_SMS",
  TypeSmsNoShowRequest = "TYPE_SMS_NO_SHOW_REQUEST",
  TypeSmsReviewRequest = "TYPE_SMS_REVIEW_REQUEST",
  TypeWebchat = "TYPE_WEBCHAT",
  TypeWhatsapp = "TYPE_WHATSAPP",
}

export type ConversationType = {
  __typename?: "ConversationType";
  contactId?: Maybe<Scalars["String"]["output"]>;
  contactName?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastMessageBody?: Maybe<Scalars["String"]["output"]>;
  lastMessageType: ConversationLastMessageTypeValues;
  locationId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  type: ConversationTypeValues;
  unreadCount: Scalars["Int"]["output"];
};

export enum ConversationTypeValues {
  TypeEmail = "TYPE_EMAIL",
  TypeFbMessenger = "TYPE_FB_MESSENGER",
  TypePhone = "TYPE_PHONE",
  TypeReview = "TYPE_REVIEW",
}

export type ConversationsType = {
  __typename?: "ConversationsType";
  conversations?: Maybe<Array<Maybe<ConversationType>>>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type CreateAdverseReaction = {
  __typename?: "CreateAdverseReaction";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateAppointment = {
  __typename?: "CreateAppointment";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  schedulingAppointment?: Maybe<AppointmentType>;
  visitId?: Maybe<Scalars["ID"]["output"]>;
};

export type CreateAppointmentByClient = {
  __typename?: "CreateAppointmentByClient";
  clientAccessToken?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  scheduledAppointment?: Maybe<AppointmentType>;
  visitId?: Maybe<Scalars["ID"]["output"]>;
};

export type CreateAvailabilityEvent = {
  __typename?: "CreateAvailabilityEvent";
  availabilityEvent?: Maybe<AvailabilityEventType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateCherryPatientAccount = {
  __typename?: "CreateCherryPatientAccount";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateClient = {
  __typename?: "CreateClient";
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateClientByProvider = {
  __typename?: "CreateClientByProvider";
  clientId?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateClientMembership = {
  __typename?: "CreateClientMembership";
  clientMembershipId?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateDiagram = {
  __typename?: "CreateDiagram";
  diagram?: Maybe<DiagramType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateDocuments = {
  __typename?: "CreateDocuments";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateFormSubmissions = {
  __typename?: "CreateFormSubmissions";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateInvoice = {
  __typename?: "CreateInvoice";
  invoice?: Maybe<InvoiceType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateMdMeeting = {
  __typename?: "CreateMDMeeting";
  mdMeeting?: Maybe<MdMeetingType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateMembership = {
  __typename?: "CreateMembership";
  membershipId?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateOrUpdateDiscountLines = {
  __typename?: "CreateOrUpdateDiscountLines";
  discountLines?: Maybe<Array<Maybe<DiscountLineType>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreatePackageCheckout = {
  __typename?: "CreatePackageCheckout";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CreatePackageGiftCheckout = {
  __typename?: "CreatePackageGiftCheckout";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CreatePackageLines = {
  __typename?: "CreatePackageLines";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateRecurringAvailability = {
  __typename?: "CreateRecurringAvailability";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  recurringAvailability?: Maybe<RecurringAvailabilityType>;
};

export type CreateShortMoxieUrl = {
  __typename?: "CreateShortMoxieUrl";
  fullUrl?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type CreateStripeCard = {
  __typename?: "CreateStripeCard";
  card?: Maybe<StripeIssuingCardType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateStripeCardholder = {
  __typename?: "CreateStripeCardholder";
  cardholder?: Maybe<StripeIssuingCardholderType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreateStripeOutboundPayment = {
  __typename?: "CreateStripeOutboundPayment";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  outboundPayment?: Maybe<StripeTreasuryTransactionFlowDetailsOutboundPaymentType>;
};

export type CreateTelehealthDetails = {
  __typename?: "CreateTelehealthDetails";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CreditSumType = {
  __typename?: "CreditSumType";
  creditType: Scalars["String"]["output"];
  sum: Scalars["Decimal"]["output"];
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

export type CustomFieldType = {
  __typename?: "CustomFieldType";
  id?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type DndChannelSettingsType = {
  __typename?: "DNDChannelSettingsType";
  code?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<DndStatusType>;
};

export type DndSettingsType = {
  __typename?: "DNDSettingsType";
  call?: Maybe<DndChannelSettingsType>;
  email?: Maybe<DndChannelSettingsType>;
  fb?: Maybe<DndChannelSettingsType>;
  gmb?: Maybe<DndChannelSettingsType>;
  sms?: Maybe<DndChannelSettingsType>;
  whatsApp?: Maybe<DndChannelSettingsType>;
};

export enum DndStatusType {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Permanent = "PERMANENT",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars["date"]["input"]>;
  _gt?: InputMaybe<Scalars["date"]["input"]>;
  _gte?: InputMaybe<Scalars["date"]["input"]>;
  _in?: InputMaybe<Array<Scalars["date"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["date"]["input"]>;
  _lte?: InputMaybe<Scalars["date"]["input"]>;
  _neq?: InputMaybe<Scalars["date"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["date"]["input"]>>;
};

export type DeleteCard = {
  __typename?: "DeleteCard";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeletePackageLineWithItemLines = {
  __typename?: "DeletePackageLineWithItemLines";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Stores details of deposits required for visits. */
export type DepositLine = {
  __typename?: "DepositLine";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount: Scalars["numeric"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** Indicates whether the credit has been issued for this deposit */
  creditIssued: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** The value of the deposit. */
  price: Scalars["numeric"]["output"];
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** Visit associated with this deposit */
  visitId: Scalars["bigint"]["output"];
};

/** aggregated selection of "deposit_line" */
export type DepositLineAggregate = {
  __typename?: "DepositLineAggregate";
  aggregate?: Maybe<DepositLineAggregateFields>;
  nodes: Array<DepositLine>;
};

/** aggregate fields of "deposit_line" */
export type DepositLineAggregateFields = {
  __typename?: "DepositLineAggregateFields";
  avg?: Maybe<DepositLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<DepositLineMaxFields>;
  min?: Maybe<DepositLineMinFields>;
  stddev?: Maybe<DepositLineStddevFields>;
  stddevPop?: Maybe<DepositLineStddevPopFields>;
  stddevSamp?: Maybe<DepositLineStddevSampFields>;
  sum?: Maybe<DepositLineSumFields>;
  varPop?: Maybe<DepositLineVarPopFields>;
  varSamp?: Maybe<DepositLineVarSampFields>;
  variance?: Maybe<DepositLineVarianceFields>;
};

/** aggregate fields of "deposit_line" */
export type DepositLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DepositLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type DepositLineAvgFields = {
  __typename?: "DepositLineAvgFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "deposit_line". All fields are combined with a logical 'AND'. */
export type DepositLineBoolExp = {
  _and?: InputMaybe<Array<DepositLineBoolExp>>;
  _not?: InputMaybe<DepositLineBoolExp>;
  _or?: InputMaybe<Array<DepositLineBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  creditIssued?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  tax?: InputMaybe<NumericComparisonExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "deposit_line" */
export enum DepositLineConstraint {
  /** unique or primary key constraint on columns "id" */
  DepositLinePkey = "deposit_line_pkey",
  /** unique or primary key constraint on columns "visit_id" */
  DepositLineVisitIdKey = "deposit_line_visit_id_key",
}

/** input type for incrementing numeric columns in table "deposit_line" */
export type DepositLineIncInput = {
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice associated with this deposit */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The value of the deposit. */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Visit associated with this deposit */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "deposit_line" */
export type DepositLineInsertInput = {
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Indicates whether the credit has been issued for this deposit */
  creditIssued?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  /** Invoice associated with this deposit */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The value of the deposit. */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Visit associated with this deposit */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type DepositLineMaxFields = {
  __typename?: "DepositLineMaxFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregate min on columns */
export type DepositLineMinFields = {
  __typename?: "DepositLineMinFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** response of any mutation on the table "deposit_line" */
export type DepositLineMutationResponse = {
  __typename?: "DepositLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<DepositLine>;
};

/** on_conflict condition type for table "deposit_line" */
export type DepositLineOnConflict = {
  constraint: DepositLineConstraint;
  updateColumns?: Array<DepositLineUpdateColumn>;
  where?: InputMaybe<DepositLineBoolExp>;
};

/** Ordering options when selecting data from "deposit_line". */
export type DepositLineOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  creditIssued?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: deposit_line */
export type DepositLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "deposit_line" */
export enum DepositLineSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  Created = "created",
  /** column name */
  CreditIssued = "creditIssued",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  Price = "price",
  /** column name */
  Tax = "tax",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "deposit_line" */
export type DepositLineSetInput = {
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Indicates whether the credit has been issued for this deposit */
  creditIssued?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice associated with this deposit */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The value of the deposit. */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Visit associated with this deposit */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type DepositLineStddevFields = {
  __typename?: "DepositLineStddevFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type DepositLineStddevPopFields = {
  __typename?: "DepositLineStddevPopFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type DepositLineStddevSampFields = {
  __typename?: "DepositLineStddevSampFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "deposit_line" */
export type DepositLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DepositLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DepositLineStreamCursorValueInput = {
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Indicates whether the credit has been issued for this deposit */
  creditIssued?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice associated with this deposit */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The value of the deposit. */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Visit associated with this deposit */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type DepositLineSumFields = {
  __typename?: "DepositLineSumFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "deposit_line" */
export enum DepositLineUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  Created = "created",
  /** column name */
  CreditIssued = "creditIssued",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  Price = "price",
  /** column name */
  Tax = "tax",
  /** column name */
  VisitId = "visitId",
}

export type DepositLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DepositLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepositLineSetInput>;
  /** filter the rows which have to be updated */
  where: DepositLineBoolExp;
};

/** aggregate varPop on columns */
export type DepositLineVarPopFields = {
  __typename?: "DepositLineVarPopFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type DepositLineVarSampFields = {
  __typename?: "DepositLineVarSampFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type DepositLineVarianceFields = {
  __typename?: "DepositLineVarianceFields";
  /** It will be always 1 but this field is added just to keep consistency with other models that extend from TaxableMixin. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice associated with this deposit */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** The value of the deposit. */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Tax percentage applicable to the deposit. */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit associated with this deposit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

export type DiagramTemplateType = {
  __typename?: "DiagramTemplateType";
  id: Scalars["ID"]["output"];
  photo?: Maybe<Scalars["String"]["output"]>;
  photoSmall?: Maybe<Scalars["String"]["output"]>;
};

export type DiagramType = {
  __typename?: "DiagramType";
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  original?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  photoSmall?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type DiscountLinePricesType = {
  __typename?: "DiscountLinePricesType";
  discountTotal?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
};

export type DiscountLinePricesWithoutWalletCreditsType = {
  __typename?: "DiscountLinePricesWithoutWalletCreditsType";
  discountTotal?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
};

export type DiscountLineType = {
  __typename?: "DiscountLineType";
  created: Scalars["DateTime"]["output"];
  discount?: Maybe<DiscountType>;
  discountType: VisitsDiscountLineDiscountTypeChoices;
  id: Scalars["ID"]["output"];
  invoice: InvoiceAdditionalInfoWithoutWalletCreditsType;
  isInvoiceLevel: Scalars["Boolean"]["output"];
  modified: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  productLine?: Maybe<ProductLinePricesWithoutWalletCreditsType>;
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  quantityUsed: Scalars["Int"]["output"];
  serviceMenuItemLine?: Maybe<ServiceMenuItemLinePricesWithoutWalletCreditsType>;
  serviceProductLine?: Maybe<ServiceProductLinePricesWithoutWalletCreditsType>;
  value: Scalars["Decimal"]["output"];
};

export type DiscountType = {
  __typename?: "DiscountType";
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  discountType: ResourcesDiscountDiscountTypeChoices;
  id: Scalars["ID"]["output"];
  medspa: MedspaPublicInfoType;
  name: Scalars["String"]["output"];
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  serviceMenuItems: Array<MedSpaServiceMenuItemPublicInfoType>;
  serviceProducts: Array<MedSpaServiceProductType>;
  value: Scalars["Decimal"]["output"];
};

export type DocumentByCurrentStatusType = {
  __typename?: "DocumentByCurrentStatusType";
  complete?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
  waitingForMe?: Maybe<Scalars["Int"]["output"]>;
  waitingForOthers?: Maybe<Scalars["Int"]["output"]>;
};

export type DocumentInputType = {
  recipients?: InputMaybe<Array<InputMaybe<DocumentRecipientsInputType>>>;
  serviceProtocolTemplateId: Scalars["BigInt"]["input"];
};

export type DocumentRecipientsInputType = {
  providerId: Scalars["BigInt"]["input"];
};

export type DuplicateDiagram = {
  __typename?: "DuplicateDiagram";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DuplicateMembership = {
  __typename?: "DuplicateMembership";
  message?: Maybe<Scalars["String"]["output"]>;
  newMembershipId?: Maybe<Scalars["ID"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DuplicateNote = {
  __typename?: "DuplicateNote";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EditAdverseReaction = {
  __typename?: "EditAdverseReaction";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EditPersonalData = {
  __typename?: "EditPersonalData";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EditTelehealthDetails = {
  __typename?: "EditTelehealthDetails";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum EmailReplyModeEnum {
  Reply = "REPLY",
  ReplyAll = "REPLY_ALL",
}

export type ExperimentType = {
  __typename?: "ExperimentType";
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  variant?: Maybe<ExperimentVariantType>;
};

export type ExperimentVariantType = {
  __typename?: "ExperimentVariantType";
  code?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ExpireClientAccessToken = {
  __typename?: "ExpireClientAccessToken";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ExpireConsentForms = {
  __typename?: "ExpireConsentForms";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ExpireFormSubmissions = {
  __typename?: "ExpireFormSubmissions";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Table for storing more general permissions, related to specific functionalities within the system. These permissions consist of one or more model-level permissions. */
export type FeaturePermission = {
  __typename?: "FeaturePermission";
  /** Unique codename of the permission */
  codename: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** Indicates whether this permission should be treated as assigned to all roles */
  isCommon: Scalars["Boolean"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Human-readable name of the permission */
  name: Scalars["String"]["output"];
  /** An array relationship */
  userMedspas: Array<UserMedspaFeaturePermissions>;
  /** An aggregate relationship */
  userMedspasAggregate: UserMedspaFeaturePermissionsAggregate;
};

/** Table for storing more general permissions, related to specific functionalities within the system. These permissions consist of one or more model-level permissions. */
export type FeaturePermissionUserMedspasArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaFeaturePermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaFeaturePermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
};

/** Table for storing more general permissions, related to specific functionalities within the system. These permissions consist of one or more model-level permissions. */
export type FeaturePermissionUserMedspasAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaFeaturePermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaFeaturePermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
};

/** aggregated selection of "feature_permission" */
export type FeaturePermissionAggregate = {
  __typename?: "FeaturePermissionAggregate";
  aggregate?: Maybe<FeaturePermissionAggregateFields>;
  nodes: Array<FeaturePermission>;
};

/** aggregate fields of "feature_permission" */
export type FeaturePermissionAggregateFields = {
  __typename?: "FeaturePermissionAggregateFields";
  avg?: Maybe<FeaturePermissionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FeaturePermissionMaxFields>;
  min?: Maybe<FeaturePermissionMinFields>;
  stddev?: Maybe<FeaturePermissionStddevFields>;
  stddevPop?: Maybe<FeaturePermissionStddevPopFields>;
  stddevSamp?: Maybe<FeaturePermissionStddevSampFields>;
  sum?: Maybe<FeaturePermissionSumFields>;
  varPop?: Maybe<FeaturePermissionVarPopFields>;
  varSamp?: Maybe<FeaturePermissionVarSampFields>;
  variance?: Maybe<FeaturePermissionVarianceFields>;
};

/** aggregate fields of "feature_permission" */
export type FeaturePermissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FeaturePermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type FeaturePermissionAvgFields = {
  __typename?: "FeaturePermissionAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "feature_permission". All fields are combined with a logical 'AND'. */
export type FeaturePermissionBoolExp = {
  _and?: InputMaybe<Array<FeaturePermissionBoolExp>>;
  _not?: InputMaybe<FeaturePermissionBoolExp>;
  _or?: InputMaybe<Array<FeaturePermissionBoolExp>>;
  codename?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isCommon?: InputMaybe<BooleanComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  userMedspas?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
  userMedspasAggregate?: InputMaybe<UserMedspaFeaturePermissionsAggregateBoolExp>;
};

/** unique or primary key constraints on table "feature_permission" */
export enum FeaturePermissionConstraint {
  /** unique or primary key constraint on columns "codename" */
  FeaturePermissionCodenameKey = "feature_permission_codename_key",
  /** unique or primary key constraint on columns "id" */
  FeaturePermissionPkey = "feature_permission_pkey",
}

/** input type for incrementing numeric columns in table "feature_permission" */
export type FeaturePermissionIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "feature_permission" */
export type FeaturePermissionInsertInput = {
  /** Unique codename of the permission */
  codename?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether this permission should be treated as assigned to all roles */
  isCommon?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable name of the permission */
  name?: InputMaybe<Scalars["String"]["input"]>;
  userMedspas?: InputMaybe<UserMedspaFeaturePermissionsArrRelInsertInput>;
};

/** aggregate max on columns */
export type FeaturePermissionMaxFields = {
  __typename?: "FeaturePermissionMaxFields";
  /** Unique codename of the permission */
  codename?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Human-readable name of the permission */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type FeaturePermissionMinFields = {
  __typename?: "FeaturePermissionMinFields";
  /** Unique codename of the permission */
  codename?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Human-readable name of the permission */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "feature_permission" */
export type FeaturePermissionMutationResponse = {
  __typename?: "FeaturePermissionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<FeaturePermission>;
};

/** input type for inserting object relation for remote table "feature_permission" */
export type FeaturePermissionObjRelInsertInput = {
  data: FeaturePermissionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<FeaturePermissionOnConflict>;
};

/** on_conflict condition type for table "feature_permission" */
export type FeaturePermissionOnConflict = {
  constraint: FeaturePermissionConstraint;
  updateColumns?: Array<FeaturePermissionUpdateColumn>;
  where?: InputMaybe<FeaturePermissionBoolExp>;
};

/** Ordering options when selecting data from "feature_permission". */
export type FeaturePermissionOrderBy = {
  codename?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isCommon?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  userMedspasAggregate?: InputMaybe<UserMedspaFeaturePermissionsAggregateOrderBy>;
};

/** primary key columns input for table: feature_permission */
export type FeaturePermissionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "feature_permission" */
export enum FeaturePermissionSelectColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IsCommon = "isCommon",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "feature_permission" */
export type FeaturePermissionSetInput = {
  /** Unique codename of the permission */
  codename?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether this permission should be treated as assigned to all roles */
  isCommon?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable name of the permission */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type FeaturePermissionStddevFields = {
  __typename?: "FeaturePermissionStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type FeaturePermissionStddevPopFields = {
  __typename?: "FeaturePermissionStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type FeaturePermissionStddevSampFields = {
  __typename?: "FeaturePermissionStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "feature_permission" */
export type FeaturePermissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FeaturePermissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FeaturePermissionStreamCursorValueInput = {
  /** Unique codename of the permission */
  codename?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether this permission should be treated as assigned to all roles */
  isCommon?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable name of the permission */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type FeaturePermissionSumFields = {
  __typename?: "FeaturePermissionSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "feature_permission" */
export enum FeaturePermissionUpdateColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IsCommon = "isCommon",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type FeaturePermissionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FeaturePermissionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FeaturePermissionSetInput>;
  /** filter the rows which have to be updated */
  where: FeaturePermissionBoolExp;
};

/** aggregate varPop on columns */
export type FeaturePermissionVarPopFields = {
  __typename?: "FeaturePermissionVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type FeaturePermissionVarSampFields = {
  __typename?: "FeaturePermissionVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type FeaturePermissionVarianceFields = {
  __typename?: "FeaturePermissionVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type FeeLinePricesType = {
  __typename?: "FeeLinePricesType";
  feeTotal?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
};

export type FeeLinePricesWithoutWalletCreditsType = {
  __typename?: "FeeLinePricesWithoutWalletCreditsType";
  feeTotal?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
};

export type FinalizePaymentWithWalletCredits = {
  __typename?: "FinalizePaymentWithWalletCredits";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
};

export type FormPhotoType = {
  __typename?: "FormPhotoType";
  id: Scalars["ID"]["output"];
  isProcessed: Scalars["Boolean"]["output"];
  photoUrl?: Maybe<Scalars["String"]["output"]>;
  photoUrlSmall?: Maybe<Scalars["String"]["output"]>;
};

export type FormSubmissionVersionType = {
  __typename?: "FormSubmissionVersionType";
  clientSignatureUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  photos: Array<FormPhotoType>;
};

/** columns and relationships of "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferings = {
  __typename?: "FormsFormMedspaServiceOfferings";
  /** An object relationship */
  form: Form;
  formId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaServiceOffering: MedspaServiceOffering;
  medspaserviceofferingId: Scalars["bigint"]["output"];
};

/** aggregated selection of "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsAggregate = {
  __typename?: "FormsFormMedspaServiceOfferingsAggregate";
  aggregate?: Maybe<FormsFormMedspaServiceOfferingsAggregateFields>;
  nodes: Array<FormsFormMedspaServiceOfferings>;
};

export type FormsFormMedspaServiceOfferingsAggregateBoolExp = {
  count?: InputMaybe<FormsFormMedspaServiceOfferingsAggregateBoolExpCount>;
};

/** aggregate fields of "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsAggregateFields = {
  __typename?: "FormsFormMedspaServiceOfferingsAggregateFields";
  avg?: Maybe<FormsFormMedspaServiceOfferingsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FormsFormMedspaServiceOfferingsMaxFields>;
  min?: Maybe<FormsFormMedspaServiceOfferingsMinFields>;
  stddev?: Maybe<FormsFormMedspaServiceOfferingsStddevFields>;
  stddevPop?: Maybe<FormsFormMedspaServiceOfferingsStddevPopFields>;
  stddevSamp?: Maybe<FormsFormMedspaServiceOfferingsStddevSampFields>;
  sum?: Maybe<FormsFormMedspaServiceOfferingsSumFields>;
  varPop?: Maybe<FormsFormMedspaServiceOfferingsVarPopFields>;
  varSamp?: Maybe<FormsFormMedspaServiceOfferingsVarSampFields>;
  variance?: Maybe<FormsFormMedspaServiceOfferingsVarianceFields>;
};

/** aggregate fields of "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsAggregateOrderBy = {
  avg?: InputMaybe<FormsFormMedspaServiceOfferingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FormsFormMedspaServiceOfferingsMaxOrderBy>;
  min?: InputMaybe<FormsFormMedspaServiceOfferingsMinOrderBy>;
  stddev?: InputMaybe<FormsFormMedspaServiceOfferingsStddevOrderBy>;
  stddevPop?: InputMaybe<FormsFormMedspaServiceOfferingsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FormsFormMedspaServiceOfferingsStddevSampOrderBy>;
  sum?: InputMaybe<FormsFormMedspaServiceOfferingsSumOrderBy>;
  varPop?: InputMaybe<FormsFormMedspaServiceOfferingsVarPopOrderBy>;
  varSamp?: InputMaybe<FormsFormMedspaServiceOfferingsVarSampOrderBy>;
  variance?: InputMaybe<FormsFormMedspaServiceOfferingsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsArrRelInsertInput = {
  data: Array<FormsFormMedspaServiceOfferingsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FormsFormMedspaServiceOfferingsOnConflict>;
};

/** aggregate avg on columns */
export type FormsFormMedspaServiceOfferingsAvgFields = {
  __typename?: "FormsFormMedspaServiceOfferingsAvgFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsAvgOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "forms_form_medspa_service_offerings". All fields are combined with a logical 'AND'. */
export type FormsFormMedspaServiceOfferingsBoolExp = {
  _and?: InputMaybe<Array<FormsFormMedspaServiceOfferingsBoolExp>>;
  _not?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
  _or?: InputMaybe<Array<FormsFormMedspaServiceOfferingsBoolExp>>;
  form?: InputMaybe<FormBoolExp>;
  formId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaserviceofferingId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "forms_form_medspa_service_offerings" */
export enum FormsFormMedspaServiceOfferingsConstraint {
  /** unique or primary key constraint on columns "form_id", "medspaserviceoffering_id" */
  FormsFormMedspaServicFormIdMedspaserviceoff_05239edfUniq = "forms_form_medspa_servic_form_id_medspaserviceoff_05239edf_uniq",
  /** unique or primary key constraint on columns "id" */
  FormsFormMedspaServiceOfferingsPkey = "forms_form_medspa_service_offerings_pkey",
}

/** input type for incrementing numeric columns in table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsIncInput = {
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsInsertInput = {
  form?: InputMaybe<FormObjRelInsertInput>;
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type FormsFormMedspaServiceOfferingsMaxFields = {
  __typename?: "FormsFormMedspaServiceOfferingsMaxFields";
  formId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsMaxOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FormsFormMedspaServiceOfferingsMinFields = {
  __typename?: "FormsFormMedspaServiceOfferingsMinFields";
  formId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsMinOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsMutationResponse = {
  __typename?: "FormsFormMedspaServiceOfferingsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<FormsFormMedspaServiceOfferings>;
};

/** on_conflict condition type for table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsOnConflict = {
  constraint: FormsFormMedspaServiceOfferingsConstraint;
  updateColumns?: Array<FormsFormMedspaServiceOfferingsUpdateColumn>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

/** Ordering options when selecting data from "forms_form_medspa_service_offerings". */
export type FormsFormMedspaServiceOfferingsOrderBy = {
  form?: InputMaybe<FormOrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: forms_form_medspa_service_offerings */
export type FormsFormMedspaServiceOfferingsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "forms_form_medspa_service_offerings" */
export enum FormsFormMedspaServiceOfferingsSelectColumn {
  /** column name */
  FormId = "formId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaserviceofferingId = "medspaserviceofferingId",
}

/** input type for updating data in table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsSetInput = {
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type FormsFormMedspaServiceOfferingsStddevFields = {
  __typename?: "FormsFormMedspaServiceOfferingsStddevFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsStddevOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FormsFormMedspaServiceOfferingsStddevPopFields = {
  __typename?: "FormsFormMedspaServiceOfferingsStddevPopFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsStddevPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FormsFormMedspaServiceOfferingsStddevSampFields = {
  __typename?: "FormsFormMedspaServiceOfferingsStddevSampFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsStddevSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FormsFormMedspaServiceOfferingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormsFormMedspaServiceOfferingsStreamCursorValueInput = {
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type FormsFormMedspaServiceOfferingsSumFields = {
  __typename?: "FormsFormMedspaServiceOfferingsSumFields";
  formId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsSumOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** update columns of table "forms_form_medspa_service_offerings" */
export enum FormsFormMedspaServiceOfferingsUpdateColumn {
  /** column name */
  FormId = "formId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaserviceofferingId = "medspaserviceofferingId",
}

export type FormsFormMedspaServiceOfferingsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormsFormMedspaServiceOfferingsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormsFormMedspaServiceOfferingsSetInput>;
  /** filter the rows which have to be updated */
  where: FormsFormMedspaServiceOfferingsBoolExp;
};

/** aggregate varPop on columns */
export type FormsFormMedspaServiceOfferingsVarPopFields = {
  __typename?: "FormsFormMedspaServiceOfferingsVarPopFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsVarPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FormsFormMedspaServiceOfferingsVarSampFields = {
  __typename?: "FormsFormMedspaServiceOfferingsVarSampFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsVarSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FormsFormMedspaServiceOfferingsVarianceFields = {
  __typename?: "FormsFormMedspaServiceOfferingsVarianceFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "forms_form_medspa_service_offerings" */
export type FormsFormMedspaServiceOfferingsVarianceOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "forms_formsubmissionversion" */
export type FormsFormsubmissionversion = {
  __typename?: "FormsFormsubmissionversion";
  additionalInfo?: Maybe<FormSubmissionVersionType>;
  clientSignature?: Maybe<Scalars["String"]["output"]>;
  consentToMarketing: Scalars["Boolean"]["output"];
  consentToTerms: Scalars["Boolean"]["output"];
  created: Scalars["timestamptz"]["output"];
  createdByClientId?: Maybe<Scalars["bigint"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  formSubmission: FormSubmission;
  formSubmissionId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  ipAddress?: Maybe<Scalars["inet"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  submittedData: Scalars["jsonb"]["output"];
  userAgent?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  usermedspa?: Maybe<UserMedspa>;
  version: Scalars["Int"]["output"];
};

/** columns and relationships of "forms_formsubmissionversion" */
export type FormsFormsubmissionversionSubmittedDataArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "forms_formsubmissionversion" */
export type FormsFormsubmissionversionAggregate = {
  __typename?: "FormsFormsubmissionversionAggregate";
  aggregate?: Maybe<FormsFormsubmissionversionAggregateFields>;
  nodes: Array<FormsFormsubmissionversion>;
};

export type FormsFormsubmissionversionAggregateBoolExp = {
  bool_and?: InputMaybe<FormsFormsubmissionversionAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<FormsFormsubmissionversionAggregateBoolExpBool_Or>;
  count?: InputMaybe<FormsFormsubmissionversionAggregateBoolExpCount>;
};

/** aggregate fields of "forms_formsubmissionversion" */
export type FormsFormsubmissionversionAggregateFields = {
  __typename?: "FormsFormsubmissionversionAggregateFields";
  avg?: Maybe<FormsFormsubmissionversionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FormsFormsubmissionversionMaxFields>;
  min?: Maybe<FormsFormsubmissionversionMinFields>;
  stddev?: Maybe<FormsFormsubmissionversionStddevFields>;
  stddevPop?: Maybe<FormsFormsubmissionversionStddevPopFields>;
  stddevSamp?: Maybe<FormsFormsubmissionversionStddevSampFields>;
  sum?: Maybe<FormsFormsubmissionversionSumFields>;
  varPop?: Maybe<FormsFormsubmissionversionVarPopFields>;
  varSamp?: Maybe<FormsFormsubmissionversionVarSampFields>;
  variance?: Maybe<FormsFormsubmissionversionVarianceFields>;
};

/** aggregate fields of "forms_formsubmissionversion" */
export type FormsFormsubmissionversionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormsFormsubmissionversionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionAggregateOrderBy = {
  avg?: InputMaybe<FormsFormsubmissionversionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FormsFormsubmissionversionMaxOrderBy>;
  min?: InputMaybe<FormsFormsubmissionversionMinOrderBy>;
  stddev?: InputMaybe<FormsFormsubmissionversionStddevOrderBy>;
  stddevPop?: InputMaybe<FormsFormsubmissionversionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FormsFormsubmissionversionStddevSampOrderBy>;
  sum?: InputMaybe<FormsFormsubmissionversionSumOrderBy>;
  varPop?: InputMaybe<FormsFormsubmissionversionVarPopOrderBy>;
  varSamp?: InputMaybe<FormsFormsubmissionversionVarSampOrderBy>;
  variance?: InputMaybe<FormsFormsubmissionversionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type FormsFormsubmissionversionAppendInput = {
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionArrRelInsertInput = {
  data: Array<FormsFormsubmissionversionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FormsFormsubmissionversionOnConflict>;
};

/** aggregate avg on columns */
export type FormsFormsubmissionversionAvgFields = {
  __typename?: "FormsFormsubmissionversionAvgFields";
  createdByClientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionAvgOrderBy = {
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "forms_formsubmissionversion". All fields are combined with a logical 'AND'. */
export type FormsFormsubmissionversionBoolExp = {
  _and?: InputMaybe<Array<FormsFormsubmissionversionBoolExp>>;
  _not?: InputMaybe<FormsFormsubmissionversionBoolExp>;
  _or?: InputMaybe<Array<FormsFormsubmissionversionBoolExp>>;
  clientSignature?: InputMaybe<StringComparisonExp>;
  consentToMarketing?: InputMaybe<BooleanComparisonExp>;
  consentToTerms?: InputMaybe<BooleanComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdByClientId?: InputMaybe<BigintComparisonExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  formSubmission?: InputMaybe<FormSubmissionBoolExp>;
  formSubmissionId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  ipAddress?: InputMaybe<InetComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  signedAt?: InputMaybe<TimestamptzComparisonExp>;
  submittedData?: InputMaybe<JsonbComparisonExp>;
  userAgent?: InputMaybe<StringComparisonExp>;
  usermedspa?: InputMaybe<UserMedspaBoolExp>;
  version?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "forms_formsubmissionversion" */
export enum FormsFormsubmissionversionConstraint {
  /** unique or primary key constraint on columns "form_submission_id", "version" */
  FormsFormsubmissionversFormSubmissionIdVersiF4edf346Uniq = "forms_formsubmissionvers_form_submission_id_versi_f4edf346_uniq",
  /** unique or primary key constraint on columns "id" */
  FormsFormsubmissionversionPkey = "forms_formsubmissionversion_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type FormsFormsubmissionversionDeleteAtPathInput = {
  submittedData?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type FormsFormsubmissionversionDeleteElemInput = {
  submittedData?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type FormsFormsubmissionversionDeleteKeyInput = {
  submittedData?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionIncInput = {
  createdByClientId?: InputMaybe<Scalars["bigint"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  formSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionInsertInput = {
  clientSignature?: InputMaybe<Scalars["String"]["input"]>;
  consentToMarketing?: InputMaybe<Scalars["Boolean"]["input"]>;
  consentToTerms?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdByClientId?: InputMaybe<Scalars["bigint"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  formSubmission?: InputMaybe<FormSubmissionObjRelInsertInput>;
  formSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  usermedspa?: InputMaybe<UserMedspaObjRelInsertInput>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type FormsFormsubmissionversionMaxFields = {
  __typename?: "FormsFormsubmissionversionMaxFields";
  clientSignature?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdByClientId?: Maybe<Scalars["bigint"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  formSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** order by max() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionMaxOrderBy = {
  clientSignature?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FormsFormsubmissionversionMinFields = {
  __typename?: "FormsFormsubmissionversionMinFields";
  clientSignature?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdByClientId?: Maybe<Scalars["bigint"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  formSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** order by min() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionMinOrderBy = {
  clientSignature?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionMutationResponse = {
  __typename?: "FormsFormsubmissionversionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<FormsFormsubmissionversion>;
};

/** on_conflict condition type for table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionOnConflict = {
  constraint: FormsFormsubmissionversionConstraint;
  updateColumns?: Array<FormsFormsubmissionversionUpdateColumn>;
  where?: InputMaybe<FormsFormsubmissionversionBoolExp>;
};

/** Ordering options when selecting data from "forms_formsubmissionversion". */
export type FormsFormsubmissionversionOrderBy = {
  clientSignature?: InputMaybe<OrderBy>;
  consentToMarketing?: InputMaybe<OrderBy>;
  consentToTerms?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmission?: InputMaybe<FormSubmissionOrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  submittedData?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
  usermedspa?: InputMaybe<UserMedspaOrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: forms_formsubmissionversion */
export type FormsFormsubmissionversionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type FormsFormsubmissionversionPrependInput = {
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "forms_formsubmissionversion" */
export enum FormsFormsubmissionversionSelectColumn {
  /** column name */
  ClientSignature = "clientSignature",
  /** column name */
  ConsentToMarketing = "consentToMarketing",
  /** column name */
  ConsentToTerms = "consentToTerms",
  /** column name */
  Created = "created",
  /** column name */
  CreatedByClientId = "createdByClientId",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  FormSubmissionId = "formSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  Modified = "modified",
  /** column name */
  SignedAt = "signedAt",
  /** column name */
  SubmittedData = "submittedData",
  /** column name */
  UserAgent = "userAgent",
  /** column name */
  Version = "version",
}

/** select "formsFormsubmissionversionAggregateBoolExpBool_andArgumentsColumns" columns of table "forms_formsubmissionversion" */
export enum FormsFormsubmissionversionSelectColumnFormsFormsubmissionversionAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  ConsentToMarketing = "consentToMarketing",
  /** column name */
  ConsentToTerms = "consentToTerms",
}

/** select "formsFormsubmissionversionAggregateBoolExpBool_orArgumentsColumns" columns of table "forms_formsubmissionversion" */
export enum FormsFormsubmissionversionSelectColumnFormsFormsubmissionversionAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  ConsentToMarketing = "consentToMarketing",
  /** column name */
  ConsentToTerms = "consentToTerms",
}

/** input type for updating data in table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionSetInput = {
  clientSignature?: InputMaybe<Scalars["String"]["input"]>;
  consentToMarketing?: InputMaybe<Scalars["Boolean"]["input"]>;
  consentToTerms?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdByClientId?: InputMaybe<Scalars["bigint"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  formSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type FormsFormsubmissionversionStddevFields = {
  __typename?: "FormsFormsubmissionversionStddevFields";
  createdByClientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionStddevOrderBy = {
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FormsFormsubmissionversionStddevPopFields = {
  __typename?: "FormsFormsubmissionversionStddevPopFields";
  createdByClientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionStddevPopOrderBy = {
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FormsFormsubmissionversionStddevSampFields = {
  __typename?: "FormsFormsubmissionversionStddevSampFields";
  createdByClientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionStddevSampOrderBy = {
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FormsFormsubmissionversionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormsFormsubmissionversionStreamCursorValueInput = {
  clientSignature?: InputMaybe<Scalars["String"]["input"]>;
  consentToMarketing?: InputMaybe<Scalars["Boolean"]["input"]>;
  consentToTerms?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdByClientId?: InputMaybe<Scalars["bigint"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  formSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  submittedData?: InputMaybe<Scalars["jsonb"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type FormsFormsubmissionversionSumFields = {
  __typename?: "FormsFormsubmissionversionSumFields";
  createdByClientId?: Maybe<Scalars["bigint"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  formSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionSumOrderBy = {
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** update columns of table "forms_formsubmissionversion" */
export enum FormsFormsubmissionversionUpdateColumn {
  /** column name */
  ClientSignature = "clientSignature",
  /** column name */
  ConsentToMarketing = "consentToMarketing",
  /** column name */
  ConsentToTerms = "consentToTerms",
  /** column name */
  Created = "created",
  /** column name */
  CreatedByClientId = "createdByClientId",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  FormSubmissionId = "formSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  Modified = "modified",
  /** column name */
  SignedAt = "signedAt",
  /** column name */
  SubmittedData = "submittedData",
  /** column name */
  UserAgent = "userAgent",
  /** column name */
  Version = "version",
}

export type FormsFormsubmissionversionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<FormsFormsubmissionversionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<FormsFormsubmissionversionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<FormsFormsubmissionversionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<FormsFormsubmissionversionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormsFormsubmissionversionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<FormsFormsubmissionversionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormsFormsubmissionversionSetInput>;
  /** filter the rows which have to be updated */
  where: FormsFormsubmissionversionBoolExp;
};

/** aggregate varPop on columns */
export type FormsFormsubmissionversionVarPopFields = {
  __typename?: "FormsFormsubmissionversionVarPopFields";
  createdByClientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionVarPopOrderBy = {
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FormsFormsubmissionversionVarSampFields = {
  __typename?: "FormsFormsubmissionversionVarSampFields";
  createdByClientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionVarSampOrderBy = {
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FormsFormsubmissionversionVarianceFields = {
  __typename?: "FormsFormsubmissionversionVarianceFields";
  createdByClientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "forms_formsubmissionversion" */
export type FormsFormsubmissionversionVarianceOrderBy = {
  createdByClientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

export type GenerateBooking2FaForClient = {
  __typename?: "GenerateBooking2FAForClient";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type GenerateCardSetupIntent = {
  __typename?: "GenerateCardSetupIntent";
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type GenerateClientAccessTokenWithOtp = {
  __typename?: "GenerateClientAccessTokenWithOTP";
  clientAccessToken?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type GenerateClientPortalOtp = {
  __typename?: "GenerateClientPortalOTP";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type GenerateSyncDocUploadUrl = {
  __typename?: "GenerateSyncDocUploadUrl";
  blobName?: Maybe<Scalars["String"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["ID"]["output"]>;
  gfeSyncDocumentId?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  uploadUrl?: Maybe<Scalars["String"]["output"]>;
};

export type GetLayerBusinessAccessToken = {
  __typename?: "GetLayerBusinessAccessToken";
  businessAccessToken?: Maybe<Scalars["String"]["output"]>;
  expiresIn?: Maybe<Scalars["Int"]["output"]>;
  tokenType?: Maybe<Scalars["String"]["output"]>;
};

/** Table to store all possible diagnoses for a GFE review, They are used globally, by all MedSpas */
export type GfeDiagnosis = {
  __typename?: "GfeDiagnosis";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  gfeReviewDiagnoses: Array<GfeReviewDiagnosis>;
  /** An aggregate relationship */
  gfeReviewDiagnosesAggregate: GfeReviewDiagnosisAggregate;
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Name of the diagnosis */
  name: Scalars["String"]["output"];
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order: Scalars["Int"]["output"];
  /** Whether to include an additional note when adding this diagnosis */
  shouldIncludeNote: Scalars["Boolean"]["output"];
};

/** Table to store all possible diagnoses for a GFE review, They are used globally, by all MedSpas */
export type GfeDiagnosisGfeReviewDiagnosesArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

/** Table to store all possible diagnoses for a GFE review, They are used globally, by all MedSpas */
export type GfeDiagnosisGfeReviewDiagnosesAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

/** aggregated selection of "gfe_diagnosis" */
export type GfeDiagnosisAggregate = {
  __typename?: "GfeDiagnosisAggregate";
  aggregate?: Maybe<GfeDiagnosisAggregateFields>;
  nodes: Array<GfeDiagnosis>;
};

/** aggregate fields of "gfe_diagnosis" */
export type GfeDiagnosisAggregateFields = {
  __typename?: "GfeDiagnosisAggregateFields";
  avg?: Maybe<GfeDiagnosisAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<GfeDiagnosisMaxFields>;
  min?: Maybe<GfeDiagnosisMinFields>;
  stddev?: Maybe<GfeDiagnosisStddevFields>;
  stddevPop?: Maybe<GfeDiagnosisStddevPopFields>;
  stddevSamp?: Maybe<GfeDiagnosisStddevSampFields>;
  sum?: Maybe<GfeDiagnosisSumFields>;
  varPop?: Maybe<GfeDiagnosisVarPopFields>;
  varSamp?: Maybe<GfeDiagnosisVarSampFields>;
  variance?: Maybe<GfeDiagnosisVarianceFields>;
};

/** aggregate fields of "gfe_diagnosis" */
export type GfeDiagnosisAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GfeDiagnosisSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type GfeDiagnosisAvgFields = {
  __typename?: "GfeDiagnosisAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "gfe_diagnosis". All fields are combined with a logical 'AND'. */
export type GfeDiagnosisBoolExp = {
  _and?: InputMaybe<Array<GfeDiagnosisBoolExp>>;
  _not?: InputMaybe<GfeDiagnosisBoolExp>;
  _or?: InputMaybe<Array<GfeDiagnosisBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  gfeReviewDiagnoses?: InputMaybe<GfeReviewDiagnosisBoolExp>;
  gfeReviewDiagnosesAggregate?: InputMaybe<GfeReviewDiagnosisAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  shouldIncludeNote?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "gfe_diagnosis" */
export enum GfeDiagnosisConstraint {
  /** unique or primary key constraint on columns "id" */
  GfeDiagnosisPkey = "gfe_diagnosis_pkey",
}

/** input type for incrementing numeric columns in table "gfe_diagnosis" */
export type GfeDiagnosisIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "gfe_diagnosis" */
export type GfeDiagnosisInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeReviewDiagnoses?: InputMaybe<GfeReviewDiagnosisArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the diagnosis */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  /** Whether to include an additional note when adding this diagnosis */
  shouldIncludeNote?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type GfeDiagnosisMaxFields = {
  __typename?: "GfeDiagnosisMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the diagnosis */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate min on columns */
export type GfeDiagnosisMinFields = {
  __typename?: "GfeDiagnosisMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the diagnosis */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** response of any mutation on the table "gfe_diagnosis" */
export type GfeDiagnosisMutationResponse = {
  __typename?: "GfeDiagnosisMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GfeDiagnosis>;
};

/** input type for inserting object relation for remote table "gfe_diagnosis" */
export type GfeDiagnosisObjRelInsertInput = {
  data: GfeDiagnosisInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<GfeDiagnosisOnConflict>;
};

/** on_conflict condition type for table "gfe_diagnosis" */
export type GfeDiagnosisOnConflict = {
  constraint: GfeDiagnosisConstraint;
  updateColumns?: Array<GfeDiagnosisUpdateColumn>;
  where?: InputMaybe<GfeDiagnosisBoolExp>;
};

/** Ordering options when selecting data from "gfe_diagnosis". */
export type GfeDiagnosisOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gfeReviewDiagnosesAggregate?: InputMaybe<GfeReviewDiagnosisAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  shouldIncludeNote?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gfe_diagnosis */
export type GfeDiagnosisPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "gfe_diagnosis" */
export enum GfeDiagnosisSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  ShouldIncludeNote = "shouldIncludeNote",
}

/** input type for updating data in table "gfe_diagnosis" */
export type GfeDiagnosisSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the diagnosis */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  /** Whether to include an additional note when adding this diagnosis */
  shouldIncludeNote?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate stddev on columns */
export type GfeDiagnosisStddevFields = {
  __typename?: "GfeDiagnosisStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type GfeDiagnosisStddevPopFields = {
  __typename?: "GfeDiagnosisStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type GfeDiagnosisStddevSampFields = {
  __typename?: "GfeDiagnosisStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "gfe_diagnosis" */
export type GfeDiagnosisStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GfeDiagnosisStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GfeDiagnosisStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the diagnosis */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  /** Whether to include an additional note when adding this diagnosis */
  shouldIncludeNote?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate sum on columns */
export type GfeDiagnosisSumFields = {
  __typename?: "GfeDiagnosisSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "gfe_diagnosis" */
export enum GfeDiagnosisUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  ShouldIncludeNote = "shouldIncludeNote",
}

export type GfeDiagnosisUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GfeDiagnosisIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GfeDiagnosisSetInput>;
  /** filter the rows which have to be updated */
  where: GfeDiagnosisBoolExp;
};

/** aggregate varPop on columns */
export type GfeDiagnosisVarPopFields = {
  __typename?: "GfeDiagnosisVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type GfeDiagnosisVarSampFields = {
  __typename?: "GfeDiagnosisVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type GfeDiagnosisVarianceFields = {
  __typename?: "GfeDiagnosisVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the Gfe diagnoses should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** An enumeration. */
export enum GfeGfeReviewRequestReviewTypeChoices {
  /** Async */
  Async = "ASYNC",
  /** Sync */
  Sync = "SYNC",
}

/** An enumeration. */
export enum GfeGfeReviewSubmissionReviewTypeChoices {
  /** Async */
  Async = "ASYNC",
  /** Sync */
  Sync = "SYNC",
}

/** An enumeration. */
export enum GfeGfeServiceProductIndicationIndicationTypeChoices {
  /** Contraindicated */
  Contraindicated = "CONTRAINDICATED",
  /** Indicated */
  Indicated = "INDICATED",
  /** Not indicated */
  NotIndicated = "NOT_INDICATED",
}

/** An enumeration. */
export enum GfeGfeSynchronousReviewDocumentDeviceChoices {
  /** Other */
  Other = "OTHER",
  /** Provider */
  Provider = "PROVIDER",
}

/** An enumeration. */
export enum GfeGfeSynchronousReviewDocumentStatusChoices {
  /** Expired */
  Expired = "EXPIRED",
  /** Pending */
  Pending = "PENDING",
  /** Uploaded */
  Uploaded = "UPLOADED",
}

/** An enumeration. */
export enum GfeGfeTreatmentIndicationIndicationTypeChoices {
  /** Contraindicated */
  Contraindicated = "CONTRAINDICATED",
  /** Indicated */
  Indicated = "INDICATED",
  /** Not indicated */
  NotIndicated = "NOT_INDICATED",
  /** Review requested */
  ReviewRequested = "REVIEW_REQUESTED",
}

export enum GfeInputIndicationTypeEnum {
  Contraindicated = "CONTRAINDICATED",
  Indicated = "INDICATED",
  NotIndicated = "NOT_INDICATED",
}

/** Table for storing client specific diagnoses added to a GFE review request/submission. */
export type GfeReviewDiagnosis = {
  __typename?: "GfeReviewDiagnosis";
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  createdBy: UserMedspa;
  /** Gfe reviewer who added this diagnosis */
  createdById: Scalars["bigint"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  deletedBy?: Maybe<UserMedspa>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId: Scalars["bigint"]["output"];
  /** An object relationship */
  gfeDiagnosis: GfeDiagnosis;
  /** An object relationship */
  gfeReviewRequest: GfeReviewRequest;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId: Scalars["bigint"]["output"];
  /** An object relationship */
  gfeReviewSubmission: GfeReviewSubmission;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Additional note, filled only if diagnosis.should_include_note is True */
  note: Scalars["String"]["output"];
};

/** aggregated selection of "gfe_review_diagnosis" */
export type GfeReviewDiagnosisAggregate = {
  __typename?: "GfeReviewDiagnosisAggregate";
  aggregate?: Maybe<GfeReviewDiagnosisAggregateFields>;
  nodes: Array<GfeReviewDiagnosis>;
};

export type GfeReviewDiagnosisAggregateBoolExp = {
  count?: InputMaybe<GfeReviewDiagnosisAggregateBoolExpCount>;
};

/** aggregate fields of "gfe_review_diagnosis" */
export type GfeReviewDiagnosisAggregateFields = {
  __typename?: "GfeReviewDiagnosisAggregateFields";
  avg?: Maybe<GfeReviewDiagnosisAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<GfeReviewDiagnosisMaxFields>;
  min?: Maybe<GfeReviewDiagnosisMinFields>;
  stddev?: Maybe<GfeReviewDiagnosisStddevFields>;
  stddevPop?: Maybe<GfeReviewDiagnosisStddevPopFields>;
  stddevSamp?: Maybe<GfeReviewDiagnosisStddevSampFields>;
  sum?: Maybe<GfeReviewDiagnosisSumFields>;
  varPop?: Maybe<GfeReviewDiagnosisVarPopFields>;
  varSamp?: Maybe<GfeReviewDiagnosisVarSampFields>;
  variance?: Maybe<GfeReviewDiagnosisVarianceFields>;
};

/** aggregate fields of "gfe_review_diagnosis" */
export type GfeReviewDiagnosisAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisAggregateOrderBy = {
  avg?: InputMaybe<GfeReviewDiagnosisAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GfeReviewDiagnosisMaxOrderBy>;
  min?: InputMaybe<GfeReviewDiagnosisMinOrderBy>;
  stddev?: InputMaybe<GfeReviewDiagnosisStddevOrderBy>;
  stddevPop?: InputMaybe<GfeReviewDiagnosisStddevPopOrderBy>;
  stddevSamp?: InputMaybe<GfeReviewDiagnosisStddevSampOrderBy>;
  sum?: InputMaybe<GfeReviewDiagnosisSumOrderBy>;
  varPop?: InputMaybe<GfeReviewDiagnosisVarPopOrderBy>;
  varSamp?: InputMaybe<GfeReviewDiagnosisVarSampOrderBy>;
  variance?: InputMaybe<GfeReviewDiagnosisVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisArrRelInsertInput = {
  data: Array<GfeReviewDiagnosisInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GfeReviewDiagnosisOnConflict>;
};

/** aggregate avg on columns */
export type GfeReviewDiagnosisAvgFields = {
  __typename?: "GfeReviewDiagnosisAvgFields";
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisAvgOrderBy = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "gfe_review_diagnosis". All fields are combined with a logical 'AND'. */
export type GfeReviewDiagnosisBoolExp = {
  _and?: InputMaybe<Array<GfeReviewDiagnosisBoolExp>>;
  _not?: InputMaybe<GfeReviewDiagnosisBoolExp>;
  _or?: InputMaybe<Array<GfeReviewDiagnosisBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<UserMedspaBoolExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedBy?: InputMaybe<UserMedspaBoolExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  diagnosisId?: InputMaybe<BigintComparisonExp>;
  gfeDiagnosis?: InputMaybe<GfeDiagnosisBoolExp>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestBoolExp>;
  gfeReviewRequestId?: InputMaybe<BigintComparisonExp>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionBoolExp>;
  gfeReviewSubmissionId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "gfe_review_diagnosis" */
export enum GfeReviewDiagnosisConstraint {
  /** unique or primary key constraint on columns "id" */
  GfeReviewDiagnosisPkey = "gfe_review_diagnosis_pkey",
  /** unique or primary key constraint on columns "gfe_review_request_id", "diagnosis_id" */
  GfeReviewDiagnosisUniqueByDiagnosisReviewRequest = "gfe_review_diagnosis_unique_by_diagnosis_review_request",
}

/** input type for incrementing numeric columns in table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisIncInput = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

export type GfeReviewDiagnosisInput = {
  diagnosisId: Scalars["BigInt"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for inserting data into table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeDiagnosis?: InputMaybe<GfeDiagnosisObjRelInsertInput>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestObjRelInsertInput>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionObjRelInsertInput>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note, filled only if diagnosis.should_include_note is True */
  note?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type GfeReviewDiagnosisMaxFields = {
  __typename?: "GfeReviewDiagnosisMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Additional note, filled only if diagnosis.should_include_note is True */
  note?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Additional note, filled only if diagnosis.should_include_note is True */
  note?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GfeReviewDiagnosisMinFields = {
  __typename?: "GfeReviewDiagnosisMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Additional note, filled only if diagnosis.should_include_note is True */
  note?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Additional note, filled only if diagnosis.should_include_note is True */
  note?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisMutationResponse = {
  __typename?: "GfeReviewDiagnosisMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GfeReviewDiagnosis>;
};

/** on_conflict condition type for table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisOnConflict = {
  constraint: GfeReviewDiagnosisConstraint;
  updateColumns?: Array<GfeReviewDiagnosisUpdateColumn>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

/** Ordering options when selecting data from "gfe_review_diagnosis". */
export type GfeReviewDiagnosisOrderBy = {
  created?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserMedspaOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UserMedspaOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  diagnosisId?: InputMaybe<OrderBy>;
  gfeDiagnosis?: InputMaybe<GfeDiagnosisOrderBy>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestOrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionOrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gfe_review_diagnosis */
export type GfeReviewDiagnosisPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "gfe_review_diagnosis" */
export enum GfeReviewDiagnosisSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  DiagnosisId = "diagnosisId",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  GfeReviewSubmissionId = "gfeReviewSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
}

/** input type for updating data in table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note, filled only if diagnosis.should_include_note is True */
  note?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type GfeReviewDiagnosisStddevFields = {
  __typename?: "GfeReviewDiagnosisStddevFields";
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisStddevOrderBy = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type GfeReviewDiagnosisStddevPopFields = {
  __typename?: "GfeReviewDiagnosisStddevPopFields";
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisStddevPopOrderBy = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type GfeReviewDiagnosisStddevSampFields = {
  __typename?: "GfeReviewDiagnosisStddevSampFields";
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisStddevSampOrderBy = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GfeReviewDiagnosisStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GfeReviewDiagnosisStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note, filled only if diagnosis.should_include_note is True */
  note?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type GfeReviewDiagnosisSumFields = {
  __typename?: "GfeReviewDiagnosisSumFields";
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisSumOrderBy = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

export type GfeReviewDiagnosisType = {
  __typename?: "GfeReviewDiagnosisType";
  created: Scalars["DateTime"]["output"];
  createdBy: UserMedSpaProviderType;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<UserMedSpaProviderType>;
  gfeReviewRequest: GfeReviewRequestType;
  gfeReviewSubmission: GfeReviewSubmissionType;
  id: Scalars["ID"]["output"];
  modified: Scalars["DateTime"]["output"];
  note: Scalars["String"]["output"];
};

/** update columns of table "gfe_review_diagnosis" */
export enum GfeReviewDiagnosisUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  DiagnosisId = "diagnosisId",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  GfeReviewSubmissionId = "gfeReviewSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
}

export type GfeReviewDiagnosisUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GfeReviewDiagnosisIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GfeReviewDiagnosisSetInput>;
  /** filter the rows which have to be updated */
  where: GfeReviewDiagnosisBoolExp;
};

/** aggregate varPop on columns */
export type GfeReviewDiagnosisVarPopFields = {
  __typename?: "GfeReviewDiagnosisVarPopFields";
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisVarPopOrderBy = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type GfeReviewDiagnosisVarSampFields = {
  __typename?: "GfeReviewDiagnosisVarSampFields";
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisVarSampOrderBy = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type GfeReviewDiagnosisVarianceFields = {
  __typename?: "GfeReviewDiagnosisVarianceFields";
  /** Gfe reviewer who added this diagnosis */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** A diagnosis that was selected */
  diagnosisId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "gfe_review_diagnosis" */
export type GfeReviewDiagnosisVarianceOrderBy = {
  /** Gfe reviewer who added this diagnosis */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this diagnosis (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** A diagnosis that was selected */
  diagnosisId?: InputMaybe<OrderBy>;
  /** Gfe review request that this diagnosis was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this diagnosis was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Table to store GFE review request records that are main action points for GFE reviewers. */
export type GfeReviewRequest = {
  __typename?: "GfeReviewRequest";
  /** Timestamp when the async review was completed. Used mostly as reviewed_at replacement for GFE reviewers that dont care about synchronous part of review at all. */
  asyncReviewedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  client: Client;
  /** A client that this Gfe review was requested for */
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  gfeReviewDiagnoses: Array<GfeReviewDiagnosis>;
  /** An aggregate relationship */
  gfeReviewDiagnosesAggregate: GfeReviewDiagnosisAggregate;
  /** An array relationship */
  gfeReviewSubmissions: Array<GfeReviewSubmission>;
  /** An aggregate relationship */
  gfeReviewSubmissionsAggregate: GfeReviewSubmissionAggregate;
  /** An array relationship */
  gfeTreatmentIndications: Array<GfeTreatmentIndication>;
  /** An aggregate relationship */
  gfeTreatmentIndicationsAggregate: GfeTreatmentIndicationAggregate;
  /** A computed field, executes function "has_async_offering" */
  hasAsyncOffering?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** MedSpa in which the Gfe review is taking place */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  requestedBy?: Maybe<UserMedspa>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of the review: sync or async. */
  reviewType?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp when the review was completed. Indicates if it is pending or completed. */
  reviewedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** Table to store GFE review request records that are main action points for GFE reviewers. */
export type GfeReviewRequestGfeReviewDiagnosesArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

/** Table to store GFE review request records that are main action points for GFE reviewers. */
export type GfeReviewRequestGfeReviewDiagnosesAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

/** Table to store GFE review request records that are main action points for GFE reviewers. */
export type GfeReviewRequestGfeReviewSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewSubmissionOrderBy>>;
  where?: InputMaybe<GfeReviewSubmissionBoolExp>;
};

/** Table to store GFE review request records that are main action points for GFE reviewers. */
export type GfeReviewRequestGfeReviewSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewSubmissionOrderBy>>;
  where?: InputMaybe<GfeReviewSubmissionBoolExp>;
};

/** Table to store GFE review request records that are main action points for GFE reviewers. */
export type GfeReviewRequestGfeTreatmentIndicationsArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

/** Table to store GFE review request records that are main action points for GFE reviewers. */
export type GfeReviewRequestGfeTreatmentIndicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

/** aggregated selection of "gfe_review_request" */
export type GfeReviewRequestAggregate = {
  __typename?: "GfeReviewRequestAggregate";
  aggregate?: Maybe<GfeReviewRequestAggregateFields>;
  nodes: Array<GfeReviewRequest>;
};

export type GfeReviewRequestAggregateBoolExp = {
  count?: InputMaybe<GfeReviewRequestAggregateBoolExpCount>;
};

/** aggregate fields of "gfe_review_request" */
export type GfeReviewRequestAggregateFields = {
  __typename?: "GfeReviewRequestAggregateFields";
  avg?: Maybe<GfeReviewRequestAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<GfeReviewRequestMaxFields>;
  min?: Maybe<GfeReviewRequestMinFields>;
  stddev?: Maybe<GfeReviewRequestStddevFields>;
  stddevPop?: Maybe<GfeReviewRequestStddevPopFields>;
  stddevSamp?: Maybe<GfeReviewRequestStddevSampFields>;
  sum?: Maybe<GfeReviewRequestSumFields>;
  varPop?: Maybe<GfeReviewRequestVarPopFields>;
  varSamp?: Maybe<GfeReviewRequestVarSampFields>;
  variance?: Maybe<GfeReviewRequestVarianceFields>;
};

/** aggregate fields of "gfe_review_request" */
export type GfeReviewRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "gfe_review_request" */
export type GfeReviewRequestAggregateOrderBy = {
  avg?: InputMaybe<GfeReviewRequestAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GfeReviewRequestMaxOrderBy>;
  min?: InputMaybe<GfeReviewRequestMinOrderBy>;
  stddev?: InputMaybe<GfeReviewRequestStddevOrderBy>;
  stddevPop?: InputMaybe<GfeReviewRequestStddevPopOrderBy>;
  stddevSamp?: InputMaybe<GfeReviewRequestStddevSampOrderBy>;
  sum?: InputMaybe<GfeReviewRequestSumOrderBy>;
  varPop?: InputMaybe<GfeReviewRequestVarPopOrderBy>;
  varSamp?: InputMaybe<GfeReviewRequestVarSampOrderBy>;
  variance?: InputMaybe<GfeReviewRequestVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "gfe_review_request" */
export type GfeReviewRequestArrRelInsertInput = {
  data: Array<GfeReviewRequestInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GfeReviewRequestOnConflict>;
};

/** aggregate avg on columns */
export type GfeReviewRequestAvgFields = {
  __typename?: "GfeReviewRequestAvgFields";
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "gfe_review_request" */
export type GfeReviewRequestAvgOrderBy = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "gfe_review_request". All fields are combined with a logical 'AND'. */
export type GfeReviewRequestBoolExp = {
  _and?: InputMaybe<Array<GfeReviewRequestBoolExp>>;
  _not?: InputMaybe<GfeReviewRequestBoolExp>;
  _or?: InputMaybe<Array<GfeReviewRequestBoolExp>>;
  asyncReviewedAt?: InputMaybe<TimestamptzComparisonExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  gfeReviewDiagnoses?: InputMaybe<GfeReviewDiagnosisBoolExp>;
  gfeReviewDiagnosesAggregate?: InputMaybe<GfeReviewDiagnosisAggregateBoolExp>;
  gfeReviewSubmissions?: InputMaybe<GfeReviewSubmissionBoolExp>;
  gfeReviewSubmissionsAggregate?: InputMaybe<GfeReviewSubmissionAggregateBoolExp>;
  gfeTreatmentIndications?: InputMaybe<GfeTreatmentIndicationBoolExp>;
  gfeTreatmentIndicationsAggregate?: InputMaybe<GfeTreatmentIndicationAggregateBoolExp>;
  hasAsyncOffering?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  requestedBy?: InputMaybe<UserMedspaBoolExp>;
  requestedById?: InputMaybe<BigintComparisonExp>;
  reviewType?: InputMaybe<StringComparisonExp>;
  reviewedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "gfe_review_request" */
export enum GfeReviewRequestConstraint {
  /** unique or primary key constraint on columns "id" */
  GfeReviewRequestPkey = "gfe_review_request_pkey",
  /** unique or primary key constraint on columns "client_id" */
  GfeReviewRequestUniqueByClientForPendingOnes = "gfe_review_request_unique_by_client_for_pending_ones",
}

/** input type for incrementing numeric columns in table "gfe_review_request" */
export type GfeReviewRequestIncInput = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "gfe_review_request" */
export type GfeReviewRequestInsertInput = {
  /** Timestamp when the async review was completed. Used mostly as reviewed_at replacement for GFE reviewers that dont care about synchronous part of review at all. */
  asyncReviewedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeReviewDiagnoses?: InputMaybe<GfeReviewDiagnosisArrRelInsertInput>;
  gfeReviewSubmissions?: InputMaybe<GfeReviewSubmissionArrRelInsertInput>;
  gfeTreatmentIndications?: InputMaybe<GfeTreatmentIndicationArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  requestedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of the review: sync or async. */
  reviewType?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when the review was completed. Indicates if it is pending or completed. */
  reviewedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type GfeReviewRequestMaxFields = {
  __typename?: "GfeReviewRequestMaxFields";
  /** Timestamp when the async review was completed. Used mostly as reviewed_at replacement for GFE reviewers that dont care about synchronous part of review at all. */
  asyncReviewedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of the review: sync or async. */
  reviewType?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp when the review was completed. Indicates if it is pending or completed. */
  reviewedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "gfe_review_request" */
export type GfeReviewRequestMaxOrderBy = {
  /** Timestamp when the async review was completed. Used mostly as reviewed_at replacement for GFE reviewers that dont care about synchronous part of review at all. */
  asyncReviewedAt?: InputMaybe<OrderBy>;
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
  /** Type of the review: sync or async. */
  reviewType?: InputMaybe<OrderBy>;
  /** Timestamp when the review was completed. Indicates if it is pending or completed. */
  reviewedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GfeReviewRequestMinFields = {
  __typename?: "GfeReviewRequestMinFields";
  /** Timestamp when the async review was completed. Used mostly as reviewed_at replacement for GFE reviewers that dont care about synchronous part of review at all. */
  asyncReviewedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of the review: sync or async. */
  reviewType?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp when the review was completed. Indicates if it is pending or completed. */
  reviewedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "gfe_review_request" */
export type GfeReviewRequestMinOrderBy = {
  /** Timestamp when the async review was completed. Used mostly as reviewed_at replacement for GFE reviewers that dont care about synchronous part of review at all. */
  asyncReviewedAt?: InputMaybe<OrderBy>;
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
  /** Type of the review: sync or async. */
  reviewType?: InputMaybe<OrderBy>;
  /** Timestamp when the review was completed. Indicates if it is pending or completed. */
  reviewedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "gfe_review_request" */
export type GfeReviewRequestMutationResponse = {
  __typename?: "GfeReviewRequestMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GfeReviewRequest>;
};

/** input type for inserting object relation for remote table "gfe_review_request" */
export type GfeReviewRequestObjRelInsertInput = {
  data: GfeReviewRequestInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<GfeReviewRequestOnConflict>;
};

/** on_conflict condition type for table "gfe_review_request" */
export type GfeReviewRequestOnConflict = {
  constraint: GfeReviewRequestConstraint;
  updateColumns?: Array<GfeReviewRequestUpdateColumn>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

/** Ordering options when selecting data from "gfe_review_request". */
export type GfeReviewRequestOrderBy = {
  asyncReviewedAt?: InputMaybe<OrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gfeReviewDiagnosesAggregate?: InputMaybe<GfeReviewDiagnosisAggregateOrderBy>;
  gfeReviewSubmissionsAggregate?: InputMaybe<GfeReviewSubmissionAggregateOrderBy>;
  gfeTreatmentIndicationsAggregate?: InputMaybe<GfeTreatmentIndicationAggregateOrderBy>;
  hasAsyncOffering?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  requestedBy?: InputMaybe<UserMedspaOrderBy>;
  requestedById?: InputMaybe<OrderBy>;
  reviewType?: InputMaybe<OrderBy>;
  reviewedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gfe_review_request */
export type GfeReviewRequestPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "gfe_review_request" */
export enum GfeReviewRequestSelectColumn {
  /** column name */
  AsyncReviewedAt = "asyncReviewedAt",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  RequestedById = "requestedById",
  /** column name */
  ReviewType = "reviewType",
  /** column name */
  ReviewedAt = "reviewedAt",
}

/** input type for updating data in table "gfe_review_request" */
export type GfeReviewRequestSetInput = {
  /** Timestamp when the async review was completed. Used mostly as reviewed_at replacement for GFE reviewers that dont care about synchronous part of review at all. */
  asyncReviewedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of the review: sync or async. */
  reviewType?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when the review was completed. Indicates if it is pending or completed. */
  reviewedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type GfeReviewRequestStddevFields = {
  __typename?: "GfeReviewRequestStddevFields";
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "gfe_review_request" */
export type GfeReviewRequestStddevOrderBy = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type GfeReviewRequestStddevPopFields = {
  __typename?: "GfeReviewRequestStddevPopFields";
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "gfe_review_request" */
export type GfeReviewRequestStddevPopOrderBy = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type GfeReviewRequestStddevSampFields = {
  __typename?: "GfeReviewRequestStddevSampFields";
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "gfe_review_request" */
export type GfeReviewRequestStddevSampOrderBy = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "gfe_review_request" */
export type GfeReviewRequestStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GfeReviewRequestStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GfeReviewRequestStreamCursorValueInput = {
  /** Timestamp when the async review was completed. Used mostly as reviewed_at replacement for GFE reviewers that dont care about synchronous part of review at all. */
  asyncReviewedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of the review: sync or async. */
  reviewType?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when the review was completed. Indicates if it is pending or completed. */
  reviewedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type GfeReviewRequestSumFields = {
  __typename?: "GfeReviewRequestSumFields";
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "gfe_review_request" */
export type GfeReviewRequestSumOrderBy = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
};

export type GfeReviewRequestType = {
  __typename?: "GfeReviewRequestType";
  asyncReviewedAt?: Maybe<Scalars["DateTime"]["output"]>;
  client: ClientPublicInfoType;
  created: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  gfeReviewDiagnoses: Array<GfeReviewDiagnosisType>;
  gfeReviewSubmissions: Array<GfeReviewSubmissionType>;
  gfeSynchronousReviewDocuments: Array<GfeSynchronousReviewDocumentType>;
  gfeTreatmentIndications: Array<GfeTreatmentIndicationType>;
  id: Scalars["ID"]["output"];
  medspa: MedspaPublicInfoType;
  modified: Scalars["DateTime"]["output"];
  requestedBy?: Maybe<UserMedSpaProviderType>;
  reviewType?: Maybe<GfeGfeReviewRequestReviewTypeChoices>;
  reviewedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** update columns of table "gfe_review_request" */
export enum GfeReviewRequestUpdateColumn {
  /** column name */
  AsyncReviewedAt = "asyncReviewedAt",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  RequestedById = "requestedById",
  /** column name */
  ReviewType = "reviewType",
  /** column name */
  ReviewedAt = "reviewedAt",
}

export type GfeReviewRequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GfeReviewRequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GfeReviewRequestSetInput>;
  /** filter the rows which have to be updated */
  where: GfeReviewRequestBoolExp;
};

/** aggregate varPop on columns */
export type GfeReviewRequestVarPopFields = {
  __typename?: "GfeReviewRequestVarPopFields";
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "gfe_review_request" */
export type GfeReviewRequestVarPopOrderBy = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type GfeReviewRequestVarSampFields = {
  __typename?: "GfeReviewRequestVarSampFields";
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "gfe_review_request" */
export type GfeReviewRequestVarSampOrderBy = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type GfeReviewRequestVarianceFields = {
  __typename?: "GfeReviewRequestVarianceFields";
  /** A client that this Gfe review was requested for */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "gfe_review_request" */
export type GfeReviewRequestVarianceOrderBy = {
  /** A client that this Gfe review was requested for */
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa in which the Gfe review is taking place */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider/GFE reviewer that requested this review. Null value means it was requested automatically. */
  requestedById?: InputMaybe<OrderBy>;
};

/** Table for storing individual Gfe review submissions with timestamps and signatures. Useful for reporting, i.e. who performed the review and how long it took. */
export type GfeReviewSubmission = {
  __typename?: "GfeReviewSubmission";
  created: Scalars["timestamptz"]["output"];
  /** An array relationship */
  gfeReviewDiagnoses: Array<GfeReviewDiagnosis>;
  /** An aggregate relationship */
  gfeReviewDiagnosesAggregate: GfeReviewDiagnosisAggregate;
  /** An object relationship */
  gfeReviewRequest: GfeReviewRequest;
  /** Gfe review request that this submission is for */
  gfeReviewRequestId: Scalars["bigint"]["output"];
  /** An array relationship */
  gfeTreatmentIndications: Array<GfeTreatmentIndication>;
  /** An aggregate relationship */
  gfeTreatmentIndicationsAggregate: GfeTreatmentIndicationAggregate;
  id: Scalars["bigint"]["output"];
  /** IP address of the person submitting stored along with signature */
  ipAddress?: Maybe<Scalars["inet"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** Timestamp when the review submission was finished. It also can be used as a signed_at timestamp. */
  reviewFinishedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the review process was first started */
  reviewFirstStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the review process was last started, i.e. when the Start GFE Review button was last clicked before submitting the review. */
  reviewLastStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of the submission: sync or async. */
  reviewType?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  reviewedBy: UserMedspa;
  /** Gfe reviewer who submitted this review submission */
  reviewedById: Scalars["bigint"]["output"];
  /** Signature file that is required when submitting a Gfe review */
  signature?: Maybe<Scalars["String"]["output"]>;
  /** User agent of the person submitting stored along with signature */
  userAgent?: Maybe<Scalars["String"]["output"]>;
};

/** Table for storing individual Gfe review submissions with timestamps and signatures. Useful for reporting, i.e. who performed the review and how long it took. */
export type GfeReviewSubmissionGfeReviewDiagnosesArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

/** Table for storing individual Gfe review submissions with timestamps and signatures. Useful for reporting, i.e. who performed the review and how long it took. */
export type GfeReviewSubmissionGfeReviewDiagnosesAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

/** Table for storing individual Gfe review submissions with timestamps and signatures. Useful for reporting, i.e. who performed the review and how long it took. */
export type GfeReviewSubmissionGfeTreatmentIndicationsArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

/** Table for storing individual Gfe review submissions with timestamps and signatures. Useful for reporting, i.e. who performed the review and how long it took. */
export type GfeReviewSubmissionGfeTreatmentIndicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

/** aggregated selection of "gfe_review_submission" */
export type GfeReviewSubmissionAggregate = {
  __typename?: "GfeReviewSubmissionAggregate";
  aggregate?: Maybe<GfeReviewSubmissionAggregateFields>;
  nodes: Array<GfeReviewSubmission>;
};

export type GfeReviewSubmissionAggregateBoolExp = {
  count?: InputMaybe<GfeReviewSubmissionAggregateBoolExpCount>;
};

/** aggregate fields of "gfe_review_submission" */
export type GfeReviewSubmissionAggregateFields = {
  __typename?: "GfeReviewSubmissionAggregateFields";
  avg?: Maybe<GfeReviewSubmissionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<GfeReviewSubmissionMaxFields>;
  min?: Maybe<GfeReviewSubmissionMinFields>;
  stddev?: Maybe<GfeReviewSubmissionStddevFields>;
  stddevPop?: Maybe<GfeReviewSubmissionStddevPopFields>;
  stddevSamp?: Maybe<GfeReviewSubmissionStddevSampFields>;
  sum?: Maybe<GfeReviewSubmissionSumFields>;
  varPop?: Maybe<GfeReviewSubmissionVarPopFields>;
  varSamp?: Maybe<GfeReviewSubmissionVarSampFields>;
  variance?: Maybe<GfeReviewSubmissionVarianceFields>;
};

/** aggregate fields of "gfe_review_submission" */
export type GfeReviewSubmissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GfeReviewSubmissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "gfe_review_submission" */
export type GfeReviewSubmissionAggregateOrderBy = {
  avg?: InputMaybe<GfeReviewSubmissionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GfeReviewSubmissionMaxOrderBy>;
  min?: InputMaybe<GfeReviewSubmissionMinOrderBy>;
  stddev?: InputMaybe<GfeReviewSubmissionStddevOrderBy>;
  stddevPop?: InputMaybe<GfeReviewSubmissionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<GfeReviewSubmissionStddevSampOrderBy>;
  sum?: InputMaybe<GfeReviewSubmissionSumOrderBy>;
  varPop?: InputMaybe<GfeReviewSubmissionVarPopOrderBy>;
  varSamp?: InputMaybe<GfeReviewSubmissionVarSampOrderBy>;
  variance?: InputMaybe<GfeReviewSubmissionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "gfe_review_submission" */
export type GfeReviewSubmissionArrRelInsertInput = {
  data: Array<GfeReviewSubmissionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GfeReviewSubmissionOnConflict>;
};

/** aggregate avg on columns */
export type GfeReviewSubmissionAvgFields = {
  __typename?: "GfeReviewSubmissionAvgFields";
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionAvgOrderBy = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "gfe_review_submission". All fields are combined with a logical 'AND'. */
export type GfeReviewSubmissionBoolExp = {
  _and?: InputMaybe<Array<GfeReviewSubmissionBoolExp>>;
  _not?: InputMaybe<GfeReviewSubmissionBoolExp>;
  _or?: InputMaybe<Array<GfeReviewSubmissionBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  gfeReviewDiagnoses?: InputMaybe<GfeReviewDiagnosisBoolExp>;
  gfeReviewDiagnosesAggregate?: InputMaybe<GfeReviewDiagnosisAggregateBoolExp>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestBoolExp>;
  gfeReviewRequestId?: InputMaybe<BigintComparisonExp>;
  gfeTreatmentIndications?: InputMaybe<GfeTreatmentIndicationBoolExp>;
  gfeTreatmentIndicationsAggregate?: InputMaybe<GfeTreatmentIndicationAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  ipAddress?: InputMaybe<InetComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  reviewFinishedAt?: InputMaybe<TimestamptzComparisonExp>;
  reviewFirstStartedAt?: InputMaybe<TimestamptzComparisonExp>;
  reviewLastStartedAt?: InputMaybe<TimestamptzComparisonExp>;
  reviewType?: InputMaybe<StringComparisonExp>;
  reviewedBy?: InputMaybe<UserMedspaBoolExp>;
  reviewedById?: InputMaybe<BigintComparisonExp>;
  signature?: InputMaybe<StringComparisonExp>;
  userAgent?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "gfe_review_submission" */
export enum GfeReviewSubmissionConstraint {
  /** unique or primary key constraint on columns "id" */
  GfeReviewSubmissionPkey = "gfe_review_submission_pkey",
  /** unique or primary key constraint on columns "gfe_review_request_id", "review_type", "reviewed_by_id" */
  GfeReviewSubmissionUniqueByReviewRequestReviewedByFor = "gfe_review_submission_unique_by_review_request_reviewed_by_for_",
}

/** input type for incrementing numeric columns in table "gfe_review_submission" */
export type GfeReviewSubmissionIncInput = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "gfe_review_submission" */
export type GfeReviewSubmissionInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeReviewDiagnoses?: InputMaybe<GfeReviewDiagnosisArrRelInsertInput>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestObjRelInsertInput>;
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeTreatmentIndications?: InputMaybe<GfeTreatmentIndicationArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** IP address of the person submitting stored along with signature */
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review submission was finished. It also can be used as a signed_at timestamp. */
  reviewFinishedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review process was first started */
  reviewFirstStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review process was last started, i.e. when the Start GFE Review button was last clicked before submitting the review. */
  reviewLastStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of the submission: sync or async. */
  reviewType?: InputMaybe<Scalars["String"]["input"]>;
  reviewedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signature file that is required when submitting a Gfe review */
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** User agent of the person submitting stored along with signature */
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type GfeReviewSubmissionMaxFields = {
  __typename?: "GfeReviewSubmissionMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the review submission was finished. It also can be used as a signed_at timestamp. */
  reviewFinishedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the review process was first started */
  reviewFirstStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the review process was last started, i.e. when the Start GFE Review button was last clicked before submitting the review. */
  reviewLastStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of the submission: sync or async. */
  reviewType?: Maybe<Scalars["String"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signature file that is required when submitting a Gfe review */
  signature?: Maybe<Scalars["String"]["output"]>;
  /** User agent of the person submitting stored along with signature */
  userAgent?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Timestamp when the review submission was finished. It also can be used as a signed_at timestamp. */
  reviewFinishedAt?: InputMaybe<OrderBy>;
  /** Timestamp when the review process was first started */
  reviewFirstStartedAt?: InputMaybe<OrderBy>;
  /** Timestamp when the review process was last started, i.e. when the Start GFE Review button was last clicked before submitting the review. */
  reviewLastStartedAt?: InputMaybe<OrderBy>;
  /** Type of the submission: sync or async. */
  reviewType?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
  /** Signature file that is required when submitting a Gfe review */
  signature?: InputMaybe<OrderBy>;
  /** User agent of the person submitting stored along with signature */
  userAgent?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GfeReviewSubmissionMinFields = {
  __typename?: "GfeReviewSubmissionMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the review submission was finished. It also can be used as a signed_at timestamp. */
  reviewFinishedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the review process was first started */
  reviewFirstStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the review process was last started, i.e. when the Start GFE Review button was last clicked before submitting the review. */
  reviewLastStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of the submission: sync or async. */
  reviewType?: Maybe<Scalars["String"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signature file that is required when submitting a Gfe review */
  signature?: Maybe<Scalars["String"]["output"]>;
  /** User agent of the person submitting stored along with signature */
  userAgent?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Timestamp when the review submission was finished. It also can be used as a signed_at timestamp. */
  reviewFinishedAt?: InputMaybe<OrderBy>;
  /** Timestamp when the review process was first started */
  reviewFirstStartedAt?: InputMaybe<OrderBy>;
  /** Timestamp when the review process was last started, i.e. when the Start GFE Review button was last clicked before submitting the review. */
  reviewLastStartedAt?: InputMaybe<OrderBy>;
  /** Type of the submission: sync or async. */
  reviewType?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
  /** Signature file that is required when submitting a Gfe review */
  signature?: InputMaybe<OrderBy>;
  /** User agent of the person submitting stored along with signature */
  userAgent?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "gfe_review_submission" */
export type GfeReviewSubmissionMutationResponse = {
  __typename?: "GfeReviewSubmissionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GfeReviewSubmission>;
};

/** input type for inserting object relation for remote table "gfe_review_submission" */
export type GfeReviewSubmissionObjRelInsertInput = {
  data: GfeReviewSubmissionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<GfeReviewSubmissionOnConflict>;
};

/** on_conflict condition type for table "gfe_review_submission" */
export type GfeReviewSubmissionOnConflict = {
  constraint: GfeReviewSubmissionConstraint;
  updateColumns?: Array<GfeReviewSubmissionUpdateColumn>;
  where?: InputMaybe<GfeReviewSubmissionBoolExp>;
};

/** Ordering options when selecting data from "gfe_review_submission". */
export type GfeReviewSubmissionOrderBy = {
  created?: InputMaybe<OrderBy>;
  gfeReviewDiagnosesAggregate?: InputMaybe<GfeReviewDiagnosisAggregateOrderBy>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestOrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeTreatmentIndicationsAggregate?: InputMaybe<GfeTreatmentIndicationAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  reviewFinishedAt?: InputMaybe<OrderBy>;
  reviewFirstStartedAt?: InputMaybe<OrderBy>;
  reviewLastStartedAt?: InputMaybe<OrderBy>;
  reviewType?: InputMaybe<OrderBy>;
  reviewedBy?: InputMaybe<UserMedspaOrderBy>;
  reviewedById?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gfe_review_submission */
export type GfeReviewSubmissionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "gfe_review_submission" */
export enum GfeReviewSubmissionSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  Modified = "modified",
  /** column name */
  ReviewFinishedAt = "reviewFinishedAt",
  /** column name */
  ReviewFirstStartedAt = "reviewFirstStartedAt",
  /** column name */
  ReviewLastStartedAt = "reviewLastStartedAt",
  /** column name */
  ReviewType = "reviewType",
  /** column name */
  ReviewedById = "reviewedById",
  /** column name */
  Signature = "signature",
  /** column name */
  UserAgent = "userAgent",
}

/** input type for updating data in table "gfe_review_submission" */
export type GfeReviewSubmissionSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** IP address of the person submitting stored along with signature */
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review submission was finished. It also can be used as a signed_at timestamp. */
  reviewFinishedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review process was first started */
  reviewFirstStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review process was last started, i.e. when the Start GFE Review button was last clicked before submitting the review. */
  reviewLastStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of the submission: sync or async. */
  reviewType?: InputMaybe<Scalars["String"]["input"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signature file that is required when submitting a Gfe review */
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** User agent of the person submitting stored along with signature */
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type GfeReviewSubmissionStddevFields = {
  __typename?: "GfeReviewSubmissionStddevFields";
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionStddevOrderBy = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type GfeReviewSubmissionStddevPopFields = {
  __typename?: "GfeReviewSubmissionStddevPopFields";
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionStddevPopOrderBy = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type GfeReviewSubmissionStddevSampFields = {
  __typename?: "GfeReviewSubmissionStddevSampFields";
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionStddevSampOrderBy = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "gfe_review_submission" */
export type GfeReviewSubmissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GfeReviewSubmissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GfeReviewSubmissionStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** IP address of the person submitting stored along with signature */
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review submission was finished. It also can be used as a signed_at timestamp. */
  reviewFinishedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review process was first started */
  reviewFirstStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the review process was last started, i.e. when the Start GFE Review button was last clicked before submitting the review. */
  reviewLastStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of the submission: sync or async. */
  reviewType?: InputMaybe<Scalars["String"]["input"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signature file that is required when submitting a Gfe review */
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** User agent of the person submitting stored along with signature */
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type GfeReviewSubmissionSumFields = {
  __typename?: "GfeReviewSubmissionSumFields";
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionSumOrderBy = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
};

export type GfeReviewSubmissionType = {
  __typename?: "GfeReviewSubmissionType";
  created: Scalars["DateTime"]["output"];
  gfeReviewDiagnoses: Array<GfeReviewDiagnosisType>;
  gfeReviewRequest: GfeReviewRequestType;
  gfeSynchronousReviewDocuments: Array<GfeSynchronousReviewDocumentType>;
  gfeTreatmentIndications: Array<GfeTreatmentIndicationType>;
  id: Scalars["ID"]["output"];
  ipAddress?: Maybe<Scalars["String"]["output"]>;
  modified: Scalars["DateTime"]["output"];
  reviewFinishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  reviewFirstStartedAt?: Maybe<Scalars["DateTime"]["output"]>;
  reviewLastStartedAt?: Maybe<Scalars["DateTime"]["output"]>;
  reviewType?: Maybe<GfeGfeReviewSubmissionReviewTypeChoices>;
  reviewedBy: UserMedSpaProviderType;
  signature?: Maybe<Scalars["String"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
};

/** update columns of table "gfe_review_submission" */
export enum GfeReviewSubmissionUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  Modified = "modified",
  /** column name */
  ReviewFinishedAt = "reviewFinishedAt",
  /** column name */
  ReviewFirstStartedAt = "reviewFirstStartedAt",
  /** column name */
  ReviewLastStartedAt = "reviewLastStartedAt",
  /** column name */
  ReviewType = "reviewType",
  /** column name */
  ReviewedById = "reviewedById",
  /** column name */
  Signature = "signature",
  /** column name */
  UserAgent = "userAgent",
}

export type GfeReviewSubmissionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GfeReviewSubmissionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GfeReviewSubmissionSetInput>;
  /** filter the rows which have to be updated */
  where: GfeReviewSubmissionBoolExp;
};

/** aggregate varPop on columns */
export type GfeReviewSubmissionVarPopFields = {
  __typename?: "GfeReviewSubmissionVarPopFields";
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionVarPopOrderBy = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type GfeReviewSubmissionVarSampFields = {
  __typename?: "GfeReviewSubmissionVarSampFields";
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionVarSampOrderBy = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type GfeReviewSubmissionVarianceFields = {
  __typename?: "GfeReviewSubmissionVarianceFields";
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "gfe_review_submission" */
export type GfeReviewSubmissionVarianceOrderBy = {
  /** Gfe review request that this submission is for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Gfe reviewer who submitted this review submission */
  reviewedById?: InputMaybe<OrderBy>;
};

/** Table for storing service product indications related to a Treatment indication. Service product indication is a decision made by a GFE reviewer for a specific productfor a client, explaining if the client can (not) be treated with this service product. */
export type GfeServiceProductIndication = {
  __typename?: "GfeServiceProductIndication";
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  createdBy: UserMedspa;
  /** Gfe reviewer who created this service product indication. */
  createdById: Scalars["bigint"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  deletedBy?: Maybe<UserMedspa>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  gfeTreatmentIndication: GfeTreatmentIndication;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  /** Determines the type and purpose of this service product indication record */
  indicationType: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note: Scalars["String"]["output"];
  /** An object relationship */
  serviceProductType: ServiceProductType;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId: Scalars["bigint"]["output"];
};

/** aggregated selection of "gfe_service_product_indication" */
export type GfeServiceProductIndicationAggregate = {
  __typename?: "GfeServiceProductIndicationAggregate";
  aggregate?: Maybe<GfeServiceProductIndicationAggregateFields>;
  nodes: Array<GfeServiceProductIndication>;
};

export type GfeServiceProductIndicationAggregateBoolExp = {
  count?: InputMaybe<GfeServiceProductIndicationAggregateBoolExpCount>;
};

/** aggregate fields of "gfe_service_product_indication" */
export type GfeServiceProductIndicationAggregateFields = {
  __typename?: "GfeServiceProductIndicationAggregateFields";
  avg?: Maybe<GfeServiceProductIndicationAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<GfeServiceProductIndicationMaxFields>;
  min?: Maybe<GfeServiceProductIndicationMinFields>;
  stddev?: Maybe<GfeServiceProductIndicationStddevFields>;
  stddevPop?: Maybe<GfeServiceProductIndicationStddevPopFields>;
  stddevSamp?: Maybe<GfeServiceProductIndicationStddevSampFields>;
  sum?: Maybe<GfeServiceProductIndicationSumFields>;
  varPop?: Maybe<GfeServiceProductIndicationVarPopFields>;
  varSamp?: Maybe<GfeServiceProductIndicationVarSampFields>;
  variance?: Maybe<GfeServiceProductIndicationVarianceFields>;
};

/** aggregate fields of "gfe_service_product_indication" */
export type GfeServiceProductIndicationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationAggregateOrderBy = {
  avg?: InputMaybe<GfeServiceProductIndicationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GfeServiceProductIndicationMaxOrderBy>;
  min?: InputMaybe<GfeServiceProductIndicationMinOrderBy>;
  stddev?: InputMaybe<GfeServiceProductIndicationStddevOrderBy>;
  stddevPop?: InputMaybe<GfeServiceProductIndicationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<GfeServiceProductIndicationStddevSampOrderBy>;
  sum?: InputMaybe<GfeServiceProductIndicationSumOrderBy>;
  varPop?: InputMaybe<GfeServiceProductIndicationVarPopOrderBy>;
  varSamp?: InputMaybe<GfeServiceProductIndicationVarSampOrderBy>;
  variance?: InputMaybe<GfeServiceProductIndicationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "gfe_service_product_indication" */
export type GfeServiceProductIndicationArrRelInsertInput = {
  data: Array<GfeServiceProductIndicationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GfeServiceProductIndicationOnConflict>;
};

/** aggregate avg on columns */
export type GfeServiceProductIndicationAvgFields = {
  __typename?: "GfeServiceProductIndicationAvgFields";
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationAvgOrderBy = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "gfe_service_product_indication". All fields are combined with a logical 'AND'. */
export type GfeServiceProductIndicationBoolExp = {
  _and?: InputMaybe<Array<GfeServiceProductIndicationBoolExp>>;
  _not?: InputMaybe<GfeServiceProductIndicationBoolExp>;
  _or?: InputMaybe<Array<GfeServiceProductIndicationBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<UserMedspaBoolExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedBy?: InputMaybe<UserMedspaBoolExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  gfeTreatmentIndication?: InputMaybe<GfeTreatmentIndicationBoolExp>;
  gfeTreatmentIndicationId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  indicationType?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  serviceProductType?: InputMaybe<ServiceProductTypeBoolExp>;
  serviceProductTypeId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "gfe_service_product_indication" */
export enum GfeServiceProductIndicationConstraint {
  /** unique or primary key constraint on columns "id" */
  GfeServiceProductIndicationPkey = "gfe_service_product_indication_pkey",
}

/** input type for incrementing numeric columns in table "gfe_service_product_indication" */
export type GfeServiceProductIndicationIncInput = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

export type GfeServiceProductIndicationInput = {
  indicationType: GfeInputIndicationTypeEnum;
  medspaServiceOfferingId: Scalars["BigInt"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  serviceProductTypeId: Scalars["BigInt"]["input"];
};

/** input type for inserting data into table "gfe_service_product_indication" */
export type GfeServiceProductIndicationInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeTreatmentIndication?: InputMaybe<GfeTreatmentIndicationObjRelInsertInput>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Determines the type and purpose of this service product indication record */
  indicationType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<Scalars["String"]["input"]>;
  serviceProductType?: InputMaybe<ServiceProductTypeObjRelInsertInput>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type GfeServiceProductIndicationMaxFields = {
  __typename?: "GfeServiceProductIndicationMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Determines the type and purpose of this service product indication record */
  indicationType?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: Maybe<Scalars["String"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Determines the type and purpose of this service product indication record */
  indicationType?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GfeServiceProductIndicationMinFields = {
  __typename?: "GfeServiceProductIndicationMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Determines the type and purpose of this service product indication record */
  indicationType?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: Maybe<Scalars["String"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Determines the type and purpose of this service product indication record */
  indicationType?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "gfe_service_product_indication" */
export type GfeServiceProductIndicationMutationResponse = {
  __typename?: "GfeServiceProductIndicationMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GfeServiceProductIndication>;
};

/** on_conflict condition type for table "gfe_service_product_indication" */
export type GfeServiceProductIndicationOnConflict = {
  constraint: GfeServiceProductIndicationConstraint;
  updateColumns?: Array<GfeServiceProductIndicationUpdateColumn>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

/** Ordering options when selecting data from "gfe_service_product_indication". */
export type GfeServiceProductIndicationOrderBy = {
  created?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserMedspaOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UserMedspaOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  gfeTreatmentIndication?: InputMaybe<GfeTreatmentIndicationOrderBy>;
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  indicationType?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  serviceProductType?: InputMaybe<ServiceProductTypeOrderBy>;
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gfe_service_product_indication */
export type GfeServiceProductIndicationPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "gfe_service_product_indication" */
export enum GfeServiceProductIndicationSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  GfeTreatmentIndicationId = "gfeTreatmentIndicationId",
  /** column name */
  Id = "id",
  /** column name */
  IndicationType = "indicationType",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  ServiceProductTypeId = "serviceProductTypeId",
}

/** input type for updating data in table "gfe_service_product_indication" */
export type GfeServiceProductIndicationSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Determines the type and purpose of this service product indication record */
  indicationType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<Scalars["String"]["input"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type GfeServiceProductIndicationStddevFields = {
  __typename?: "GfeServiceProductIndicationStddevFields";
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationStddevOrderBy = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type GfeServiceProductIndicationStddevPopFields = {
  __typename?: "GfeServiceProductIndicationStddevPopFields";
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationStddevPopOrderBy = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type GfeServiceProductIndicationStddevSampFields = {
  __typename?: "GfeServiceProductIndicationStddevSampFields";
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationStddevSampOrderBy = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "gfe_service_product_indication" */
export type GfeServiceProductIndicationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GfeServiceProductIndicationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GfeServiceProductIndicationStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Determines the type and purpose of this service product indication record */
  indicationType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<Scalars["String"]["input"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type GfeServiceProductIndicationSumFields = {
  __typename?: "GfeServiceProductIndicationSumFields";
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationSumOrderBy = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

export type GfeServiceProductIndicationType = {
  __typename?: "GfeServiceProductIndicationType";
  created: Scalars["DateTime"]["output"];
  createdBy: UserMedSpaProviderType;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<UserMedSpaProviderType>;
  gfeTreatmentIndication: GfeTreatmentIndicationType;
  id: Scalars["ID"]["output"];
  indicationType: GfeGfeServiceProductIndicationIndicationTypeChoices;
  modified: Scalars["DateTime"]["output"];
  note: Scalars["String"]["output"];
};

/** update columns of table "gfe_service_product_indication" */
export enum GfeServiceProductIndicationUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  GfeTreatmentIndicationId = "gfeTreatmentIndicationId",
  /** column name */
  Id = "id",
  /** column name */
  IndicationType = "indicationType",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  ServiceProductTypeId = "serviceProductTypeId",
}

export type GfeServiceProductIndicationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GfeServiceProductIndicationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GfeServiceProductIndicationSetInput>;
  /** filter the rows which have to be updated */
  where: GfeServiceProductIndicationBoolExp;
};

/** aggregate varPop on columns */
export type GfeServiceProductIndicationVarPopFields = {
  __typename?: "GfeServiceProductIndicationVarPopFields";
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationVarPopOrderBy = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type GfeServiceProductIndicationVarSampFields = {
  __typename?: "GfeServiceProductIndicationVarSampFields";
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationVarSampOrderBy = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type GfeServiceProductIndicationVarianceFields = {
  __typename?: "GfeServiceProductIndicationVarianceFields";
  /** Gfe reviewer who created this service product indication. */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "gfe_service_product_indication" */
export type GfeServiceProductIndicationVarianceOrderBy = {
  /** Gfe reviewer who created this service product indication. */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this service product indication  (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe treatment indication that this service product indication was added under */
  gfeTreatmentIndicationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service product type that the Gfe reviewer performs the review for */
  serviceProductTypeId?: InputMaybe<OrderBy>;
};

/** Table for storing review documents that are submitted for sync GFE reviews. */
export type GfeSynchronousReviewDocument = {
  __typename?: "GfeSynchronousReviewDocument";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Represents on which device the review was performed */
  device: Scalars["String"]["output"];
  /** Date when the document expires and is no longer valid (by default one year from creation) */
  expirationDate: Scalars["date"]["output"];
  /** Timestamp when the document was marked as expired */
  expiredAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document PDF file */
  file?: Maybe<Scalars["String"]["output"]>;
  fileUrl?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  gfeReviewRequest: GfeReviewRequest;
  /** Gfe review request that this document is for */
  gfeReviewRequestId: Scalars["bigint"]["output"];
  /** An object relationship */
  gfeReviewSubmission: GfeReviewSubmission;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  /** Email to which the review invitation link was sent */
  linkSentToEmail: Scalars["String"]["output"];
  /** Phone number to which the review invitation link was sent */
  linkSentToPhoneNumber: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Qualiphy team member who performed the exam */
  qualiphyExamBy?: Maybe<Scalars["String"]["output"]>;
  /** Qualiphy exam ID */
  qualiphyExamId?: Maybe<Scalars["String"]["output"]>;
  /** Status of the document */
  status: Scalars["String"]["output"];
  /** Timestamp when the synchronous review was started */
  syncReviewDate: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentAggregate = {
  __typename?: "GfeSynchronousReviewDocumentAggregate";
  aggregate?: Maybe<GfeSynchronousReviewDocumentAggregateFields>;
  nodes: Array<GfeSynchronousReviewDocument>;
};

/** aggregate fields of "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentAggregateFields = {
  __typename?: "GfeSynchronousReviewDocumentAggregateFields";
  avg?: Maybe<GfeSynchronousReviewDocumentAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<GfeSynchronousReviewDocumentMaxFields>;
  min?: Maybe<GfeSynchronousReviewDocumentMinFields>;
  stddev?: Maybe<GfeSynchronousReviewDocumentStddevFields>;
  stddevPop?: Maybe<GfeSynchronousReviewDocumentStddevPopFields>;
  stddevSamp?: Maybe<GfeSynchronousReviewDocumentStddevSampFields>;
  sum?: Maybe<GfeSynchronousReviewDocumentSumFields>;
  varPop?: Maybe<GfeSynchronousReviewDocumentVarPopFields>;
  varSamp?: Maybe<GfeSynchronousReviewDocumentVarSampFields>;
  variance?: Maybe<GfeSynchronousReviewDocumentVarianceFields>;
};

/** aggregate fields of "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GfeSynchronousReviewDocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type GfeSynchronousReviewDocumentAvgFields = {
  __typename?: "GfeSynchronousReviewDocumentAvgFields";
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "gfe_synchronous_review_document". All fields are combined with a logical 'AND'. */
export type GfeSynchronousReviewDocumentBoolExp = {
  _and?: InputMaybe<Array<GfeSynchronousReviewDocumentBoolExp>>;
  _not?: InputMaybe<GfeSynchronousReviewDocumentBoolExp>;
  _or?: InputMaybe<Array<GfeSynchronousReviewDocumentBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  device?: InputMaybe<StringComparisonExp>;
  expirationDate?: InputMaybe<DateComparisonExp>;
  expiredAt?: InputMaybe<TimestamptzComparisonExp>;
  file?: InputMaybe<StringComparisonExp>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestBoolExp>;
  gfeReviewRequestId?: InputMaybe<BigintComparisonExp>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionBoolExp>;
  gfeReviewSubmissionId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  linkSentToEmail?: InputMaybe<StringComparisonExp>;
  linkSentToPhoneNumber?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  qualiphyExamBy?: InputMaybe<StringComparisonExp>;
  qualiphyExamId?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  syncReviewDate?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "gfe_synchronous_review_document" */
export enum GfeSynchronousReviewDocumentConstraint {
  /** unique or primary key constraint on columns "id" */
  GfeSynchronousReviewDocumentPkey = "gfe_synchronous_review_document_pkey",
  /** unique or primary key constraint on columns "file", "gfe_review_request_id" */
  UniqueGfeSynchronousReviewDocument = "unique_gfe_synchronous_review_document",
}

/** input type for incrementing numeric columns in table "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentIncInput = {
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Represents on which device the review was performed */
  device?: InputMaybe<Scalars["String"]["input"]>;
  /** Date when the document expires and is no longer valid (by default one year from creation) */
  expirationDate?: InputMaybe<Scalars["date"]["input"]>;
  /** Timestamp when the document was marked as expired */
  expiredAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document PDF file */
  file?: InputMaybe<Scalars["String"]["input"]>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestObjRelInsertInput>;
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionObjRelInsertInput>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Email to which the review invitation link was sent */
  linkSentToEmail?: InputMaybe<Scalars["String"]["input"]>;
  /** Phone number to which the review invitation link was sent */
  linkSentToPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Qualiphy team member who performed the exam */
  qualiphyExamBy?: InputMaybe<Scalars["String"]["input"]>;
  /** Qualiphy exam ID */
  qualiphyExamId?: InputMaybe<Scalars["String"]["input"]>;
  /** Status of the document */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when the synchronous review was started */
  syncReviewDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type GfeSynchronousReviewDocumentMaxFields = {
  __typename?: "GfeSynchronousReviewDocumentMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Represents on which device the review was performed */
  device?: Maybe<Scalars["String"]["output"]>;
  /** Date when the document expires and is no longer valid (by default one year from creation) */
  expirationDate?: Maybe<Scalars["date"]["output"]>;
  /** Timestamp when the document was marked as expired */
  expiredAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document PDF file */
  file?: Maybe<Scalars["String"]["output"]>;
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Email to which the review invitation link was sent */
  linkSentToEmail?: Maybe<Scalars["String"]["output"]>;
  /** Phone number to which the review invitation link was sent */
  linkSentToPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Qualiphy team member who performed the exam */
  qualiphyExamBy?: Maybe<Scalars["String"]["output"]>;
  /** Qualiphy exam ID */
  qualiphyExamId?: Maybe<Scalars["String"]["output"]>;
  /** Status of the document */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp when the synchronous review was started */
  syncReviewDate?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type GfeSynchronousReviewDocumentMinFields = {
  __typename?: "GfeSynchronousReviewDocumentMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Represents on which device the review was performed */
  device?: Maybe<Scalars["String"]["output"]>;
  /** Date when the document expires and is no longer valid (by default one year from creation) */
  expirationDate?: Maybe<Scalars["date"]["output"]>;
  /** Timestamp when the document was marked as expired */
  expiredAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document PDF file */
  file?: Maybe<Scalars["String"]["output"]>;
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Email to which the review invitation link was sent */
  linkSentToEmail?: Maybe<Scalars["String"]["output"]>;
  /** Phone number to which the review invitation link was sent */
  linkSentToPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Qualiphy team member who performed the exam */
  qualiphyExamBy?: Maybe<Scalars["String"]["output"]>;
  /** Qualiphy exam ID */
  qualiphyExamId?: Maybe<Scalars["String"]["output"]>;
  /** Status of the document */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp when the synchronous review was started */
  syncReviewDate?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentMutationResponse = {
  __typename?: "GfeSynchronousReviewDocumentMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GfeSynchronousReviewDocument>;
};

/** on_conflict condition type for table "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentOnConflict = {
  constraint: GfeSynchronousReviewDocumentConstraint;
  updateColumns?: Array<GfeSynchronousReviewDocumentUpdateColumn>;
  where?: InputMaybe<GfeSynchronousReviewDocumentBoolExp>;
};

/** Ordering options when selecting data from "gfe_synchronous_review_document". */
export type GfeSynchronousReviewDocumentOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  device?: InputMaybe<OrderBy>;
  expirationDate?: InputMaybe<OrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  file?: InputMaybe<OrderBy>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestOrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionOrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkSentToEmail?: InputMaybe<OrderBy>;
  linkSentToPhoneNumber?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  qualiphyExamBy?: InputMaybe<OrderBy>;
  qualiphyExamId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  syncReviewDate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gfe_synchronous_review_document */
export type GfeSynchronousReviewDocumentPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "gfe_synchronous_review_document" */
export enum GfeSynchronousReviewDocumentSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Device = "device",
  /** column name */
  ExpirationDate = "expirationDate",
  /** column name */
  ExpiredAt = "expiredAt",
  /** column name */
  File = "file",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  GfeReviewSubmissionId = "gfeReviewSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  LinkSentToEmail = "linkSentToEmail",
  /** column name */
  LinkSentToPhoneNumber = "linkSentToPhoneNumber",
  /** column name */
  Modified = "modified",
  /** column name */
  QualiphyExamBy = "qualiphyExamBy",
  /** column name */
  QualiphyExamId = "qualiphyExamId",
  /** column name */
  Status = "status",
  /** column name */
  SyncReviewDate = "syncReviewDate",
}

/** input type for updating data in table "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Represents on which device the review was performed */
  device?: InputMaybe<Scalars["String"]["input"]>;
  /** Date when the document expires and is no longer valid (by default one year from creation) */
  expirationDate?: InputMaybe<Scalars["date"]["input"]>;
  /** Timestamp when the document was marked as expired */
  expiredAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document PDF file */
  file?: InputMaybe<Scalars["String"]["input"]>;
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Email to which the review invitation link was sent */
  linkSentToEmail?: InputMaybe<Scalars["String"]["input"]>;
  /** Phone number to which the review invitation link was sent */
  linkSentToPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Qualiphy team member who performed the exam */
  qualiphyExamBy?: InputMaybe<Scalars["String"]["input"]>;
  /** Qualiphy exam ID */
  qualiphyExamId?: InputMaybe<Scalars["String"]["input"]>;
  /** Status of the document */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when the synchronous review was started */
  syncReviewDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type GfeSynchronousReviewDocumentStddevFields = {
  __typename?: "GfeSynchronousReviewDocumentStddevFields";
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type GfeSynchronousReviewDocumentStddevPopFields = {
  __typename?: "GfeSynchronousReviewDocumentStddevPopFields";
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type GfeSynchronousReviewDocumentStddevSampFields = {
  __typename?: "GfeSynchronousReviewDocumentStddevSampFields";
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "gfe_synchronous_review_document" */
export type GfeSynchronousReviewDocumentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GfeSynchronousReviewDocumentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GfeSynchronousReviewDocumentStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Represents on which device the review was performed */
  device?: InputMaybe<Scalars["String"]["input"]>;
  /** Date when the document expires and is no longer valid (by default one year from creation) */
  expirationDate?: InputMaybe<Scalars["date"]["input"]>;
  /** Timestamp when the document was marked as expired */
  expiredAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document PDF file */
  file?: InputMaybe<Scalars["String"]["input"]>;
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Email to which the review invitation link was sent */
  linkSentToEmail?: InputMaybe<Scalars["String"]["input"]>;
  /** Phone number to which the review invitation link was sent */
  linkSentToPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Qualiphy team member who performed the exam */
  qualiphyExamBy?: InputMaybe<Scalars["String"]["input"]>;
  /** Qualiphy exam ID */
  qualiphyExamId?: InputMaybe<Scalars["String"]["input"]>;
  /** Status of the document */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when the synchronous review was started */
  syncReviewDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type GfeSynchronousReviewDocumentSumFields = {
  __typename?: "GfeSynchronousReviewDocumentSumFields";
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
};

export type GfeSynchronousReviewDocumentType = {
  __typename?: "GfeSynchronousReviewDocumentType";
  created: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  device: GfeGfeSynchronousReviewDocumentDeviceChoices;
  expirationDate: Scalars["Date"]["output"];
  expiredAt?: Maybe<Scalars["DateTime"]["output"]>;
  file?: Maybe<Scalars["String"]["output"]>;
  gfeReviewRequest: GfeReviewRequestType;
  gfeReviewSubmission: GfeReviewSubmissionType;
  id: Scalars["ID"]["output"];
  linkSentToEmail: Scalars["String"]["output"];
  linkSentToPhoneNumber: Scalars["String"]["output"];
  modified: Scalars["DateTime"]["output"];
  qualiphyExamBy?: Maybe<Scalars["String"]["output"]>;
  qualiphyExamId?: Maybe<Scalars["String"]["output"]>;
  status: GfeGfeSynchronousReviewDocumentStatusChoices;
  syncReviewDate: Scalars["DateTime"]["output"];
};

/** update columns of table "gfe_synchronous_review_document" */
export enum GfeSynchronousReviewDocumentUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Device = "device",
  /** column name */
  ExpirationDate = "expirationDate",
  /** column name */
  ExpiredAt = "expiredAt",
  /** column name */
  File = "file",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  GfeReviewSubmissionId = "gfeReviewSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  LinkSentToEmail = "linkSentToEmail",
  /** column name */
  LinkSentToPhoneNumber = "linkSentToPhoneNumber",
  /** column name */
  Modified = "modified",
  /** column name */
  QualiphyExamBy = "qualiphyExamBy",
  /** column name */
  QualiphyExamId = "qualiphyExamId",
  /** column name */
  Status = "status",
  /** column name */
  SyncReviewDate = "syncReviewDate",
}

export type GfeSynchronousReviewDocumentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GfeSynchronousReviewDocumentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GfeSynchronousReviewDocumentSetInput>;
  /** filter the rows which have to be updated */
  where: GfeSynchronousReviewDocumentBoolExp;
};

/** aggregate varPop on columns */
export type GfeSynchronousReviewDocumentVarPopFields = {
  __typename?: "GfeSynchronousReviewDocumentVarPopFields";
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type GfeSynchronousReviewDocumentVarSampFields = {
  __typename?: "GfeSynchronousReviewDocumentVarSampFields";
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type GfeSynchronousReviewDocumentVarianceFields = {
  __typename?: "GfeSynchronousReviewDocumentVarianceFields";
  /** Gfe review request that this document is for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this document was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Table for storing treatment indications related to a Gfe review request. Treatment indication is a decision made by a GFE reviewer for a specific service offering for a client, explaining if the client should (not) be treated with this service. */
export type GfeTreatmentIndication = {
  __typename?: "GfeTreatmentIndication";
  /** An object relationship */
  client: Client;
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  createdBy?: Maybe<UserMedspa>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  deletedBy?: Maybe<UserMedspa>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  gfeReviewRequest: GfeReviewRequest;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId: Scalars["bigint"]["output"];
  /** An object relationship */
  gfeReviewSubmission?: Maybe<GfeReviewSubmission>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  gfeServiceProductIndications: Array<GfeServiceProductIndication>;
  /** An aggregate relationship */
  gfeServiceProductIndicationsAggregate: GfeServiceProductIndicationAggregate;
  id: Scalars["bigint"]["output"];
  /** Determines the type and purpose of this treatment indication record */
  indicationType: Scalars["String"]["output"];
  /** An object relationship */
  medspaServiceOffering: MedspaServiceOffering;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note: Scalars["String"]["output"];
};

/** Table for storing treatment indications related to a Gfe review request. Treatment indication is a decision made by a GFE reviewer for a specific service offering for a client, explaining if the client should (not) be treated with this service. */
export type GfeTreatmentIndicationGfeServiceProductIndicationsArgs = {
  distinctOn?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeServiceProductIndicationOrderBy>>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

/** Table for storing treatment indications related to a Gfe review request. Treatment indication is a decision made by a GFE reviewer for a specific service offering for a client, explaining if the client should (not) be treated with this service. */
export type GfeTreatmentIndicationGfeServiceProductIndicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeServiceProductIndicationOrderBy>>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

/** aggregated selection of "gfe_treatment_indication" */
export type GfeTreatmentIndicationAggregate = {
  __typename?: "GfeTreatmentIndicationAggregate";
  aggregate?: Maybe<GfeTreatmentIndicationAggregateFields>;
  nodes: Array<GfeTreatmentIndication>;
};

export type GfeTreatmentIndicationAggregateBoolExp = {
  count?: InputMaybe<GfeTreatmentIndicationAggregateBoolExpCount>;
};

/** aggregate fields of "gfe_treatment_indication" */
export type GfeTreatmentIndicationAggregateFields = {
  __typename?: "GfeTreatmentIndicationAggregateFields";
  avg?: Maybe<GfeTreatmentIndicationAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<GfeTreatmentIndicationMaxFields>;
  min?: Maybe<GfeTreatmentIndicationMinFields>;
  stddev?: Maybe<GfeTreatmentIndicationStddevFields>;
  stddevPop?: Maybe<GfeTreatmentIndicationStddevPopFields>;
  stddevSamp?: Maybe<GfeTreatmentIndicationStddevSampFields>;
  sum?: Maybe<GfeTreatmentIndicationSumFields>;
  varPop?: Maybe<GfeTreatmentIndicationVarPopFields>;
  varSamp?: Maybe<GfeTreatmentIndicationVarSampFields>;
  variance?: Maybe<GfeTreatmentIndicationVarianceFields>;
};

/** aggregate fields of "gfe_treatment_indication" */
export type GfeTreatmentIndicationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationAggregateOrderBy = {
  avg?: InputMaybe<GfeTreatmentIndicationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GfeTreatmentIndicationMaxOrderBy>;
  min?: InputMaybe<GfeTreatmentIndicationMinOrderBy>;
  stddev?: InputMaybe<GfeTreatmentIndicationStddevOrderBy>;
  stddevPop?: InputMaybe<GfeTreatmentIndicationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<GfeTreatmentIndicationStddevSampOrderBy>;
  sum?: InputMaybe<GfeTreatmentIndicationSumOrderBy>;
  varPop?: InputMaybe<GfeTreatmentIndicationVarPopOrderBy>;
  varSamp?: InputMaybe<GfeTreatmentIndicationVarSampOrderBy>;
  variance?: InputMaybe<GfeTreatmentIndicationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "gfe_treatment_indication" */
export type GfeTreatmentIndicationArrRelInsertInput = {
  data: Array<GfeTreatmentIndicationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GfeTreatmentIndicationOnConflict>;
};

/** aggregate avg on columns */
export type GfeTreatmentIndicationAvgFields = {
  __typename?: "GfeTreatmentIndicationAvgFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationAvgOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "gfe_treatment_indication". All fields are combined with a logical 'AND'. */
export type GfeTreatmentIndicationBoolExp = {
  _and?: InputMaybe<Array<GfeTreatmentIndicationBoolExp>>;
  _not?: InputMaybe<GfeTreatmentIndicationBoolExp>;
  _or?: InputMaybe<Array<GfeTreatmentIndicationBoolExp>>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<UserMedspaBoolExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedBy?: InputMaybe<UserMedspaBoolExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestBoolExp>;
  gfeReviewRequestId?: InputMaybe<BigintComparisonExp>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionBoolExp>;
  gfeReviewSubmissionId?: InputMaybe<BigintComparisonExp>;
  gfeServiceProductIndications?: InputMaybe<GfeServiceProductIndicationBoolExp>;
  gfeServiceProductIndicationsAggregate?: InputMaybe<GfeServiceProductIndicationAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  indicationType?: InputMaybe<StringComparisonExp>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaServiceOfferingId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "gfe_treatment_indication" */
export enum GfeTreatmentIndicationConstraint {
  /** unique or primary key constraint on columns "gfe_review_request_id", "medspa_service_offering_id", "indication_type" */
  GfeReviewTreatmentIndicationUniqueByIndicationTypeRevie = "gfe_review_treatment_indication_unique_by_indication_type_revie",
  /** unique or primary key constraint on columns "id" */
  GfeTreatmentIndicationPkey = "gfe_treatment_indication_pkey",
}

/** input type for incrementing numeric columns in table "gfe_treatment_indication" */
export type GfeTreatmentIndicationIncInput = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
};

export type GfeTreatmentIndicationInput = {
  indicationType: GfeInputIndicationTypeEnum;
  medspaServiceOfferingId: Scalars["BigInt"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for inserting data into table "gfe_treatment_indication" */
export type GfeTreatmentIndicationInsertInput = {
  client?: InputMaybe<ClientObjRelInsertInput>;
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestObjRelInsertInput>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionObjRelInsertInput>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeServiceProductIndications?: InputMaybe<GfeServiceProductIndicationArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Determines the type and purpose of this treatment indication record */
  indicationType?: InputMaybe<Scalars["String"]["input"]>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type GfeTreatmentIndicationMaxFields = {
  __typename?: "GfeTreatmentIndicationMaxFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Determines the type and purpose of this treatment indication record */
  indicationType?: Maybe<Scalars["String"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationMaxOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Determines the type and purpose of this treatment indication record */
  indicationType?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GfeTreatmentIndicationMinFields = {
  __typename?: "GfeTreatmentIndicationMinFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Determines the type and purpose of this treatment indication record */
  indicationType?: Maybe<Scalars["String"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationMinOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Determines the type and purpose of this treatment indication record */
  indicationType?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "gfe_treatment_indication" */
export type GfeTreatmentIndicationMutationResponse = {
  __typename?: "GfeTreatmentIndicationMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GfeTreatmentIndication>;
};

/** input type for inserting object relation for remote table "gfe_treatment_indication" */
export type GfeTreatmentIndicationObjRelInsertInput = {
  data: GfeTreatmentIndicationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<GfeTreatmentIndicationOnConflict>;
};

/** on_conflict condition type for table "gfe_treatment_indication" */
export type GfeTreatmentIndicationOnConflict = {
  constraint: GfeTreatmentIndicationConstraint;
  updateColumns?: Array<GfeTreatmentIndicationUpdateColumn>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

/** Ordering options when selecting data from "gfe_treatment_indication". */
export type GfeTreatmentIndicationOrderBy = {
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserMedspaOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UserMedspaOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestOrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionOrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  gfeServiceProductIndicationsAggregate?: InputMaybe<GfeServiceProductIndicationAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  indicationType?: InputMaybe<OrderBy>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gfe_treatment_indication */
export type GfeTreatmentIndicationPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "gfe_treatment_indication" */
export enum GfeTreatmentIndicationSelectColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  GfeReviewSubmissionId = "gfeReviewSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  IndicationType = "indicationType",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
}

/** input type for updating data in table "gfe_treatment_indication" */
export type GfeTreatmentIndicationSetInput = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Determines the type and purpose of this treatment indication record */
  indicationType?: InputMaybe<Scalars["String"]["input"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type GfeTreatmentIndicationStddevFields = {
  __typename?: "GfeTreatmentIndicationStddevFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationStddevOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type GfeTreatmentIndicationStddevPopFields = {
  __typename?: "GfeTreatmentIndicationStddevPopFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationStddevPopOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type GfeTreatmentIndicationStddevSampFields = {
  __typename?: "GfeTreatmentIndicationStddevSampFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationStddevSampOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "gfe_treatment_indication" */
export type GfeTreatmentIndicationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GfeTreatmentIndicationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GfeTreatmentIndicationStreamCursorValueInput = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Determines the type and purpose of this treatment indication record */
  indicationType?: InputMaybe<Scalars["String"]["input"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional note explaining a Gfe reviewer decision. Required when indicationType is Contraindicated or Not indicated */
  note?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type GfeTreatmentIndicationSumFields = {
  __typename?: "GfeTreatmentIndicationSumFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationSumOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

export type GfeTreatmentIndicationType = {
  __typename?: "GfeTreatmentIndicationType";
  client: ClientPublicInfoType;
  created: Scalars["DateTime"]["output"];
  createdBy?: Maybe<UserMedSpaProviderType>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<UserMedSpaProviderType>;
  gfeReviewRequest: GfeReviewRequestType;
  gfeReviewSubmission?: Maybe<GfeReviewSubmissionType>;
  gfeServiceProductIndications: Array<GfeServiceProductIndicationType>;
  id: Scalars["ID"]["output"];
  indicationType: GfeGfeTreatmentIndicationIndicationTypeChoices;
  modified: Scalars["DateTime"]["output"];
  note: Scalars["String"]["output"];
};

/** update columns of table "gfe_treatment_indication" */
export enum GfeTreatmentIndicationUpdateColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  GfeReviewSubmissionId = "gfeReviewSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  IndicationType = "indicationType",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
}

export type GfeTreatmentIndicationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GfeTreatmentIndicationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GfeTreatmentIndicationSetInput>;
  /** filter the rows which have to be updated */
  where: GfeTreatmentIndicationBoolExp;
};

/** aggregate varPop on columns */
export type GfeTreatmentIndicationVarPopFields = {
  __typename?: "GfeTreatmentIndicationVarPopFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationVarPopOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type GfeTreatmentIndicationVarSampFields = {
  __typename?: "GfeTreatmentIndicationVarSampFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationVarSampOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type GfeTreatmentIndicationVarianceFields = {
  __typename?: "GfeTreatmentIndicationVarianceFields";
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "gfe_treatment_indication" */
export type GfeTreatmentIndicationVarianceOrderBy = {
  /** Client that this indication is for. The same as in the GfeReviewRequest, also added here to simplify queries and logic */
  clientId?: InputMaybe<OrderBy>;
  /** Gfe reviewer who created this treatment indication. Can be null for review_requested indications */
  createdById?: InputMaybe<OrderBy>;
  /** Gfe reviewer who deleted this treatment indication (can be different than created_by) */
  deletedById?: InputMaybe<OrderBy>;
  /** Gfe review request that this treatment indication was selected for */
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  /** Gfe review submission during which this treatment indication was added */
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa service offering that the Gfe reviewer performs the review for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** Table to store banner images for the gifting email builder feature. */
export type GiftBuilderBanner = {
  __typename?: "GiftBuilderBanner";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** Image associated with a banner, intended to be displayed in an email message. */
  image: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** The name of the banner, used for identification in the admin panel. */
  name: Scalars["String"]["output"];
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order: Scalars["Int"]["output"];
};

/** aggregated selection of "gift_builder_banner" */
export type GiftBuilderBannerAggregate = {
  __typename?: "GiftBuilderBannerAggregate";
  aggregate?: Maybe<GiftBuilderBannerAggregateFields>;
  nodes: Array<GiftBuilderBanner>;
};

/** aggregate fields of "gift_builder_banner" */
export type GiftBuilderBannerAggregateFields = {
  __typename?: "GiftBuilderBannerAggregateFields";
  avg?: Maybe<GiftBuilderBannerAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<GiftBuilderBannerMaxFields>;
  min?: Maybe<GiftBuilderBannerMinFields>;
  stddev?: Maybe<GiftBuilderBannerStddevFields>;
  stddevPop?: Maybe<GiftBuilderBannerStddevPopFields>;
  stddevSamp?: Maybe<GiftBuilderBannerStddevSampFields>;
  sum?: Maybe<GiftBuilderBannerSumFields>;
  varPop?: Maybe<GiftBuilderBannerVarPopFields>;
  varSamp?: Maybe<GiftBuilderBannerVarSampFields>;
  variance?: Maybe<GiftBuilderBannerVarianceFields>;
};

/** aggregate fields of "gift_builder_banner" */
export type GiftBuilderBannerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GiftBuilderBannerSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type GiftBuilderBannerAvgFields = {
  __typename?: "GiftBuilderBannerAvgFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "gift_builder_banner". All fields are combined with a logical 'AND'. */
export type GiftBuilderBannerBoolExp = {
  _and?: InputMaybe<Array<GiftBuilderBannerBoolExp>>;
  _not?: InputMaybe<GiftBuilderBannerBoolExp>;
  _or?: InputMaybe<Array<GiftBuilderBannerBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  archivedById?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "gift_builder_banner" */
export enum GiftBuilderBannerConstraint {
  /** unique or primary key constraint on columns "id" */
  GiftBuilderBannerPkey = "gift_builder_banner_pkey",
}

/** input type for incrementing numeric columns in table "gift_builder_banner" */
export type GiftBuilderBannerIncInput = {
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "gift_builder_banner" */
export type GiftBuilderBannerInsertInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Image associated with a banner, intended to be displayed in an email message. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The name of the banner, used for identification in the admin panel. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type GiftBuilderBannerMaxFields = {
  __typename?: "GiftBuilderBannerMaxFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Image associated with a banner, intended to be displayed in an email message. */
  image?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The name of the banner, used for identification in the admin panel. */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate min on columns */
export type GiftBuilderBannerMinFields = {
  __typename?: "GiftBuilderBannerMinFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Image associated with a banner, intended to be displayed in an email message. */
  image?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The name of the banner, used for identification in the admin panel. */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** response of any mutation on the table "gift_builder_banner" */
export type GiftBuilderBannerMutationResponse = {
  __typename?: "GiftBuilderBannerMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<GiftBuilderBanner>;
};

/** on_conflict condition type for table "gift_builder_banner" */
export type GiftBuilderBannerOnConflict = {
  constraint: GiftBuilderBannerConstraint;
  updateColumns?: Array<GiftBuilderBannerUpdateColumn>;
  where?: InputMaybe<GiftBuilderBannerBoolExp>;
};

/** Ordering options when selecting data from "gift_builder_banner". */
export type GiftBuilderBannerOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gift_builder_banner */
export type GiftBuilderBannerPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "gift_builder_banner" */
export enum GiftBuilderBannerSelectColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Image = "image",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
}

/** input type for updating data in table "gift_builder_banner" */
export type GiftBuilderBannerSetInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Image associated with a banner, intended to be displayed in an email message. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The name of the banner, used for identification in the admin panel. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type GiftBuilderBannerStddevFields = {
  __typename?: "GiftBuilderBannerStddevFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type GiftBuilderBannerStddevPopFields = {
  __typename?: "GiftBuilderBannerStddevPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type GiftBuilderBannerStddevSampFields = {
  __typename?: "GiftBuilderBannerStddevSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "gift_builder_banner" */
export type GiftBuilderBannerStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GiftBuilderBannerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GiftBuilderBannerStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Image associated with a banner, intended to be displayed in an email message. */
  image?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The name of the banner, used for identification in the admin panel. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type GiftBuilderBannerSumFields = {
  __typename?: "GiftBuilderBannerSumFields";
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "gift_builder_banner" */
export enum GiftBuilderBannerUpdateColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Image = "image",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
}

export type GiftBuilderBannerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GiftBuilderBannerIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GiftBuilderBannerSetInput>;
  /** filter the rows which have to be updated */
  where: GiftBuilderBannerBoolExp;
};

/** aggregate varPop on columns */
export type GiftBuilderBannerVarPopFields = {
  __typename?: "GiftBuilderBannerVarPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type GiftBuilderBannerVarSampFields = {
  __typename?: "GiftBuilderBannerVarSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type GiftBuilderBannerVarianceFields = {
  __typename?: "GiftBuilderBannerVarianceFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the banners should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

export type HardDeleteClient = {
  __typename?: "HardDeleteClient";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type HydratedStripeFinancialAccountType = {
  __typename?: "HydratedStripeFinancialAccountType";
  cardholders?: Maybe<StripeIssuingCardholderListResponseType>;
  cards?: Maybe<StripeIssuingCardListResponseType>;
  financialAccount?: Maybe<StripeFinancialAccountType>;
  transactions?: Maybe<StripeTreasuryTransactionListResponseType>;
};

export type HydratedStripeFinancialAccountTypeCardholdersArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  startingAfterId?: InputMaybe<Scalars["String"]["input"]>;
};

export type HydratedStripeFinancialAccountTypeCardsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  startingAfterId?: InputMaybe<Scalars["String"]["input"]>;
};

export type HydratedStripeFinancialAccountTypeTransactionsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  startingAfterId?: InputMaybe<Scalars["String"]["input"]>;
};

export type HydratedStripeIssuingCardType = {
  __typename?: "HydratedStripeIssuingCardType";
  card?: Maybe<StripeIssuingCardType>;
  transactions?: Maybe<StripeIssuingTransactionListResponseType>;
};

export type HydratedStripeIssuingCardTypeTransactionsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  startingAfterId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type InetComparisonExp = {
  _eq?: InputMaybe<Scalars["inet"]["input"]>;
  _gt?: InputMaybe<Scalars["inet"]["input"]>;
  _gte?: InputMaybe<Scalars["inet"]["input"]>;
  _in?: InputMaybe<Array<Scalars["inet"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["inet"]["input"]>;
  _lte?: InputMaybe<Scalars["inet"]["input"]>;
  _neq?: InputMaybe<Scalars["inet"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["inet"]["input"]>>;
};

export type InitMobilePayment = {
  __typename?: "InitMobilePayment";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
  paymentIntent?: Maybe<PaymentIntentType>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars["Int"]["input"]>;
  _gt?: InputMaybe<Scalars["Int"]["input"]>;
  _gte?: InputMaybe<Scalars["Int"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Int"]["input"]>;
  _lte?: InputMaybe<Scalars["Int"]["input"]>;
  _neq?: InputMaybe<Scalars["Int"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

/** Table to store Inventory Lots, representing a batch of a specific service product within a medspa, with a lot number assigned */
export type InventoryLot = {
  __typename?: "InventoryLot";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  archivedBy?: Maybe<User>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  deletedBy?: Maybe<UserMedspa>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Date on which this Inventory Lot expires */
  expiresAt: Scalars["date"]["output"];
  id: Scalars["bigint"]["output"];
  /** Lot number assigned to this Inventory Lot */
  lotNumber: Scalars["String"]["output"];
  /** An object relationship */
  medspaServiceProduct: MedspaServiceProduct;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Int"]["output"]>;
  /** An array relationship */
  serviceProductUsages: Array<ServiceProductUsage>;
  /** An aggregate relationship */
  serviceProductUsagesAggregate: ServiceProductUsageAggregate;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Int"]["output"]>;
};

/** Table to store Inventory Lots, representing a batch of a specific service product within a medspa, with a lot number assigned */
export type InventoryLotServiceProductUsagesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductUsageOrderBy>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

/** Table to store Inventory Lots, representing a batch of a specific service product within a medspa, with a lot number assigned */
export type InventoryLotServiceProductUsagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductUsageOrderBy>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

/** aggregated selection of "inventory_lot" */
export type InventoryLotAggregate = {
  __typename?: "InventoryLotAggregate";
  aggregate?: Maybe<InventoryLotAggregateFields>;
  nodes: Array<InventoryLot>;
};

export type InventoryLotAggregateBoolExp = {
  count?: InputMaybe<InventoryLotAggregateBoolExpCount>;
};

/** aggregate fields of "inventory_lot" */
export type InventoryLotAggregateFields = {
  __typename?: "InventoryLotAggregateFields";
  avg?: Maybe<InventoryLotAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<InventoryLotMaxFields>;
  min?: Maybe<InventoryLotMinFields>;
  stddev?: Maybe<InventoryLotStddevFields>;
  stddevPop?: Maybe<InventoryLotStddevPopFields>;
  stddevSamp?: Maybe<InventoryLotStddevSampFields>;
  sum?: Maybe<InventoryLotSumFields>;
  varPop?: Maybe<InventoryLotVarPopFields>;
  varSamp?: Maybe<InventoryLotVarSampFields>;
  variance?: Maybe<InventoryLotVarianceFields>;
};

/** aggregate fields of "inventory_lot" */
export type InventoryLotAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InventoryLotSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "inventory_lot" */
export type InventoryLotAggregateOrderBy = {
  avg?: InputMaybe<InventoryLotAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InventoryLotMaxOrderBy>;
  min?: InputMaybe<InventoryLotMinOrderBy>;
  stddev?: InputMaybe<InventoryLotStddevOrderBy>;
  stddevPop?: InputMaybe<InventoryLotStddevPopOrderBy>;
  stddevSamp?: InputMaybe<InventoryLotStddevSampOrderBy>;
  sum?: InputMaybe<InventoryLotSumOrderBy>;
  varPop?: InputMaybe<InventoryLotVarPopOrderBy>;
  varSamp?: InputMaybe<InventoryLotVarSampOrderBy>;
  variance?: InputMaybe<InventoryLotVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "inventory_lot" */
export type InventoryLotArrRelInsertInput = {
  data: Array<InventoryLotInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<InventoryLotOnConflict>;
};

/** aggregate avg on columns */
export type InventoryLotAvgFields = {
  __typename?: "InventoryLotAvgFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Float"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "inventory_lot" */
export type InventoryLotAvgOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "inventory_lot". All fields are combined with a logical 'AND'. */
export type InventoryLotBoolExp = {
  _and?: InputMaybe<Array<InventoryLotBoolExp>>;
  _not?: InputMaybe<InventoryLotBoolExp>;
  _or?: InputMaybe<Array<InventoryLotBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  archivedBy?: InputMaybe<UserBoolExp>;
  archivedById?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedBy?: InputMaybe<UserMedspaBoolExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  expiresAt?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  lotNumber?: InputMaybe<StringComparisonExp>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductBoolExp>;
  medspaServiceProductId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  numberOfContainers?: InputMaybe<IntComparisonExp>;
  serviceProductUsages?: InputMaybe<ServiceProductUsageBoolExp>;
  serviceProductUsagesAggregate?: InputMaybe<ServiceProductUsageAggregateBoolExp>;
  unitsPerContainer?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "inventory_lot" */
export enum InventoryLotConstraint {
  /** unique or primary key constraint on columns "id" */
  InventoryLotPkey = "inventory_lot_pkey",
}

/** input type for incrementing numeric columns in table "inventory_lot" */
export type InventoryLotIncInput = {
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "inventory_lot" */
export type InventoryLotInsertInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedBy?: InputMaybe<UserObjRelInsertInput>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date on which this Inventory Lot expires */
  expiresAt?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Lot number assigned to this Inventory Lot */
  lotNumber?: InputMaybe<Scalars["String"]["input"]>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductObjRelInsertInput>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<Scalars["Int"]["input"]>;
  serviceProductUsages?: InputMaybe<ServiceProductUsageArrRelInsertInput>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type InventoryLotMaxFields = {
  __typename?: "InventoryLotMaxFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Date on which this Inventory Lot expires */
  expiresAt?: Maybe<Scalars["date"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Lot number assigned to this Inventory Lot */
  lotNumber?: Maybe<Scalars["String"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Int"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Int"]["output"]>;
};

/** order by max() on columns of table "inventory_lot" */
export type InventoryLotMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  /** Date on which this Inventory Lot expires */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Lot number assigned to this Inventory Lot */
  lotNumber?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InventoryLotMinFields = {
  __typename?: "InventoryLotMinFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Date on which this Inventory Lot expires */
  expiresAt?: Maybe<Scalars["date"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Lot number assigned to this Inventory Lot */
  lotNumber?: Maybe<Scalars["String"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Int"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Int"]["output"]>;
};

/** order by min() on columns of table "inventory_lot" */
export type InventoryLotMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  /** Date on which this Inventory Lot expires */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Lot number assigned to this Inventory Lot */
  lotNumber?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "inventory_lot" */
export type InventoryLotMutationResponse = {
  __typename?: "InventoryLotMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<InventoryLot>;
};

/** input type for inserting object relation for remote table "inventory_lot" */
export type InventoryLotObjRelInsertInput = {
  data: InventoryLotInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<InventoryLotOnConflict>;
};

/** on_conflict condition type for table "inventory_lot" */
export type InventoryLotOnConflict = {
  constraint: InventoryLotConstraint;
  updateColumns?: Array<InventoryLotUpdateColumn>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

/** Ordering options when selecting data from "inventory_lot". */
export type InventoryLotOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedBy?: InputMaybe<UserOrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UserMedspaOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lotNumber?: InputMaybe<OrderBy>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductOrderBy>;
  medspaServiceProductId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  numberOfContainers?: InputMaybe<OrderBy>;
  serviceProductUsagesAggregate?: InputMaybe<ServiceProductUsageAggregateOrderBy>;
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: inventory_lot */
export type InventoryLotPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "inventory_lot" */
export enum InventoryLotSelectColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  LotNumber = "lotNumber",
  /** column name */
  MedspaServiceProductId = "medspaServiceProductId",
  /** column name */
  Modified = "modified",
  /** column name */
  NumberOfContainers = "numberOfContainers",
  /** column name */
  UnitsPerContainer = "unitsPerContainer",
}

/** input type for updating data in table "inventory_lot" */
export type InventoryLotSetInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date on which this Inventory Lot expires */
  expiresAt?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Lot number assigned to this Inventory Lot */
  lotNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type InventoryLotStddevFields = {
  __typename?: "InventoryLotStddevFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Float"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "inventory_lot" */
export type InventoryLotStddevOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type InventoryLotStddevPopFields = {
  __typename?: "InventoryLotStddevPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Float"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "inventory_lot" */
export type InventoryLotStddevPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type InventoryLotStddevSampFields = {
  __typename?: "InventoryLotStddevSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Float"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "inventory_lot" */
export type InventoryLotStddevSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "inventory_lot" */
export type InventoryLotStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InventoryLotStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InventoryLotStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date on which this Inventory Lot expires */
  expiresAt?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Lot number assigned to this Inventory Lot */
  lotNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type InventoryLotSumFields = {
  __typename?: "InventoryLotSumFields";
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Int"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "inventory_lot" */
export type InventoryLotSumOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** update columns of table "inventory_lot" */
export enum InventoryLotUpdateColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  LotNumber = "lotNumber",
  /** column name */
  MedspaServiceProductId = "medspaServiceProductId",
  /** column name */
  Modified = "modified",
  /** column name */
  NumberOfContainers = "numberOfContainers",
  /** column name */
  UnitsPerContainer = "unitsPerContainer",
}

export type InventoryLotUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InventoryLotIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InventoryLotSetInput>;
  /** filter the rows which have to be updated */
  where: InventoryLotBoolExp;
};

/** aggregate varPop on columns */
export type InventoryLotVarPopFields = {
  __typename?: "InventoryLotVarPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Float"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "inventory_lot" */
export type InventoryLotVarPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type InventoryLotVarSampFields = {
  __typename?: "InventoryLotVarSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Float"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "inventory_lot" */
export type InventoryLotVarSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type InventoryLotVarianceFields = {
  __typename?: "InventoryLotVarianceFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: Maybe<Scalars["Float"]["output"]>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "inventory_lot" */
export type InventoryLotVarianceOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Lot */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product to which this Inventory Lot belongs to */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Number of containers of the product in this Inventory Lot */
  numberOfContainers?: InputMaybe<OrderBy>;
  /** Number of units of the product per container in this Inventory Lot */
  unitsPerContainer?: InputMaybe<OrderBy>;
};

/** Table to store Inventory Transactions, representing a change in stock of a specific service product within a medspa */
export type InventoryTransaction = {
  __typename?: "InventoryTransaction";
  /** An object relationship */
  client?: Maybe<Client>;
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  deletedBy?: Maybe<UserMedspa>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaServiceProduct: MedspaServiceProduct;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  productChartingNote?: Maybe<ServiceProductChartingNote>;
  /** Additional reasoning for the stock change represented by this Inventory Transaction, if any */
  reasonNote: Scalars["String"]["output"];
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  serviceProductUsage: ServiceProductUsage;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId: Scalars["bigint"]["output"];
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** aggregated selection of "inventory_transaction" */
export type InventoryTransactionAggregate = {
  __typename?: "InventoryTransactionAggregate";
  aggregate?: Maybe<InventoryTransactionAggregateFields>;
  nodes: Array<InventoryTransaction>;
};

export type InventoryTransactionAggregateBoolExp = {
  count?: InputMaybe<InventoryTransactionAggregateBoolExpCount>;
};

/** aggregate fields of "inventory_transaction" */
export type InventoryTransactionAggregateFields = {
  __typename?: "InventoryTransactionAggregateFields";
  avg?: Maybe<InventoryTransactionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<InventoryTransactionMaxFields>;
  min?: Maybe<InventoryTransactionMinFields>;
  stddev?: Maybe<InventoryTransactionStddevFields>;
  stddevPop?: Maybe<InventoryTransactionStddevPopFields>;
  stddevSamp?: Maybe<InventoryTransactionStddevSampFields>;
  sum?: Maybe<InventoryTransactionSumFields>;
  varPop?: Maybe<InventoryTransactionVarPopFields>;
  varSamp?: Maybe<InventoryTransactionVarSampFields>;
  variance?: Maybe<InventoryTransactionVarianceFields>;
};

/** aggregate fields of "inventory_transaction" */
export type InventoryTransactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "inventory_transaction" */
export type InventoryTransactionAggregateOrderBy = {
  avg?: InputMaybe<InventoryTransactionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InventoryTransactionMaxOrderBy>;
  min?: InputMaybe<InventoryTransactionMinOrderBy>;
  stddev?: InputMaybe<InventoryTransactionStddevOrderBy>;
  stddevPop?: InputMaybe<InventoryTransactionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<InventoryTransactionStddevSampOrderBy>;
  sum?: InputMaybe<InventoryTransactionSumOrderBy>;
  varPop?: InputMaybe<InventoryTransactionVarPopOrderBy>;
  varSamp?: InputMaybe<InventoryTransactionVarSampOrderBy>;
  variance?: InputMaybe<InventoryTransactionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "inventory_transaction" */
export type InventoryTransactionArrRelInsertInput = {
  data: Array<InventoryTransactionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<InventoryTransactionOnConflict>;
};

/** aggregate avg on columns */
export type InventoryTransactionAvgFields = {
  __typename?: "InventoryTransactionAvgFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by avg() on columns of table "inventory_transaction" */
export type InventoryTransactionAvgOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "inventory_transaction". All fields are combined with a logical 'AND'. */
export type InventoryTransactionBoolExp = {
  _and?: InputMaybe<Array<InventoryTransactionBoolExp>>;
  _not?: InputMaybe<InventoryTransactionBoolExp>;
  _or?: InputMaybe<Array<InventoryTransactionBoolExp>>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedBy?: InputMaybe<UserMedspaBoolExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductBoolExp>;
  medspaServiceProductId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  productChartingNote?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  reasonNote?: InputMaybe<StringComparisonExp>;
  serviceProductChartingNoteId?: InputMaybe<BigintComparisonExp>;
  serviceProductUsage?: InputMaybe<ServiceProductUsageBoolExp>;
  serviceProductUsageId?: InputMaybe<BigintComparisonExp>;
  stockDelta?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "inventory_transaction" */
export enum InventoryTransactionConstraint {
  /** unique or primary key constraint on columns "id" */
  InventoryTransactionPkey = "inventory_transaction_pkey",
  /** unique or primary key constraint on columns "service_product_usage_id" */
  InventoryTransactionServiceProductUsageIdKey = "inventory_transaction_service_product_usage_id_key",
  /** unique or primary key constraint on columns "service_product_charting_note_id" */
  UniqueInventoryTransaction = "unique_inventory_transaction",
}

/** input type for incrementing numeric columns in table "inventory_transaction" */
export type InventoryTransactionIncInput = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "inventory_transaction" */
export type InventoryTransactionInsertInput = {
  client?: InputMaybe<ClientObjRelInsertInput>;
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductObjRelInsertInput>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  productChartingNote?: InputMaybe<ServiceProductChartingNoteObjRelInsertInput>;
  /** Additional reasoning for the stock change represented by this Inventory Transaction, if any */
  reasonNote?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductUsage?: InputMaybe<ServiceProductUsageObjRelInsertInput>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type InventoryTransactionMaxFields = {
  __typename?: "InventoryTransactionMaxFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Additional reasoning for the stock change represented by this Inventory Transaction, if any */
  reasonNote?: Maybe<Scalars["String"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["bigint"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by max() on columns of table "inventory_transaction" */
export type InventoryTransactionMaxOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Additional reasoning for the stock change represented by this Inventory Transaction, if any */
  reasonNote?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InventoryTransactionMinFields = {
  __typename?: "InventoryTransactionMinFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Additional reasoning for the stock change represented by this Inventory Transaction, if any */
  reasonNote?: Maybe<Scalars["String"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["bigint"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by min() on columns of table "inventory_transaction" */
export type InventoryTransactionMinOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Additional reasoning for the stock change represented by this Inventory Transaction, if any */
  reasonNote?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "inventory_transaction" */
export type InventoryTransactionMutationResponse = {
  __typename?: "InventoryTransactionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<InventoryTransaction>;
};

/** input type for inserting object relation for remote table "inventory_transaction" */
export type InventoryTransactionObjRelInsertInput = {
  data: InventoryTransactionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<InventoryTransactionOnConflict>;
};

/** on_conflict condition type for table "inventory_transaction" */
export type InventoryTransactionOnConflict = {
  constraint: InventoryTransactionConstraint;
  updateColumns?: Array<InventoryTransactionUpdateColumn>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** Ordering options when selecting data from "inventory_transaction". */
export type InventoryTransactionOrderBy = {
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UserMedspaOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductOrderBy>;
  medspaServiceProductId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  productChartingNote?: InputMaybe<ServiceProductChartingNoteOrderBy>;
  reasonNote?: InputMaybe<OrderBy>;
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  serviceProductUsage?: InputMaybe<ServiceProductUsageOrderBy>;
  serviceProductUsageId?: InputMaybe<OrderBy>;
  stockDelta?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: inventory_transaction */
export type InventoryTransactionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "inventory_transaction" */
export enum InventoryTransactionSelectColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  Id = "id",
  /** column name */
  MedspaServiceProductId = "medspaServiceProductId",
  /** column name */
  Modified = "modified",
  /** column name */
  ReasonNote = "reasonNote",
  /** column name */
  ServiceProductChartingNoteId = "serviceProductChartingNoteId",
  /** column name */
  ServiceProductUsageId = "serviceProductUsageId",
}

/** input type for updating data in table "inventory_transaction" */
export type InventoryTransactionSetInput = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional reasoning for the stock change represented by this Inventory Transaction, if any */
  reasonNote?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type InventoryTransactionStddevFields = {
  __typename?: "InventoryTransactionStddevFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by stddev() on columns of table "inventory_transaction" */
export type InventoryTransactionStddevOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type InventoryTransactionStddevPopFields = {
  __typename?: "InventoryTransactionStddevPopFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by stddevPop() on columns of table "inventory_transaction" */
export type InventoryTransactionStddevPopOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type InventoryTransactionStddevSampFields = {
  __typename?: "InventoryTransactionStddevSampFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by stddevSamp() on columns of table "inventory_transaction" */
export type InventoryTransactionStddevSampOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "inventory_transaction" */
export type InventoryTransactionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InventoryTransactionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InventoryTransactionStreamCursorValueInput = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Additional reasoning for the stock change represented by this Inventory Transaction, if any */
  reasonNote?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type InventoryTransactionSumFields = {
  __typename?: "InventoryTransactionSumFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["bigint"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "inventory_transaction" */
export type InventoryTransactionSumOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** update columns of table "inventory_transaction" */
export enum InventoryTransactionUpdateColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  Id = "id",
  /** column name */
  MedspaServiceProductId = "medspaServiceProductId",
  /** column name */
  Modified = "modified",
  /** column name */
  ReasonNote = "reasonNote",
  /** column name */
  ServiceProductChartingNoteId = "serviceProductChartingNoteId",
  /** column name */
  ServiceProductUsageId = "serviceProductUsageId",
}

export type InventoryTransactionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InventoryTransactionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InventoryTransactionSetInput>;
  /** filter the rows which have to be updated */
  where: InventoryTransactionBoolExp;
};

/** aggregate varPop on columns */
export type InventoryTransactionVarPopFields = {
  __typename?: "InventoryTransactionVarPopFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by varPop() on columns of table "inventory_transaction" */
export type InventoryTransactionVarPopOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type InventoryTransactionVarSampFields = {
  __typename?: "InventoryTransactionVarSampFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by varSamp() on columns of table "inventory_transaction" */
export type InventoryTransactionVarSampOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type InventoryTransactionVarianceFields = {
  __typename?: "InventoryTransactionVarianceFields";
  /** Client for whom this Inventory Transaction was created */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** A computed field, executes function "inventory_transaction_stock_delta" */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by variance() on columns of table "inventory_transaction" */
export type InventoryTransactionVarianceOrderBy = {
  /** Client for whom this Inventory Transaction was created */
  clientId?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Inventory Transaction */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MedSpa Service Product which stock level is changed by this Inventory Transaction */
  medspaServiceProductId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which caused creation of this Inventory Transaction */
  serviceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Inventory Transaction */
  serviceProductUsageId?: InputMaybe<OrderBy>;
};

export type InvoiceAdditionalInfoType = {
  __typename?: "InvoiceAdditionalInfoType";
  amountRemaining?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  creditLinesTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  discountLinesTotalPrices?: Maybe<Array<Maybe<DiscountLinePricesType>>>;
  discountTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  feeLinesTotalPrices?: Maybe<Array<Maybe<FeeLinePricesType>>>;
  feeTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  id: Scalars["ID"]["output"];
  lineItemsDiscountTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  packageLinePrices?: Maybe<Array<Maybe<PackageLinePricesType>>>;
  packageLinesTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  productDiscounts?: Maybe<Array<Maybe<DiscountType>>>;
  retailProductLinePrices?: Maybe<Array<Maybe<ProductLinePricesType>>>;
  retailProductsSubtotalWithoutCredits?: Maybe<
    Scalars["FormattedDecimal"]["output"]
  >;
  serviceLineItemsDiscountTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  serviceMenuItemDiscounts?: Maybe<Array<Maybe<DiscountType>>>;
  serviceMenuItemLinesPrices?: Maybe<
    Array<Maybe<ServiceMenuItemLinePricesType>>
  >;
  serviceSubtotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  servicesAndProductsSubtotalWithoutCredits?: Maybe<
    Scalars["FormattedDecimal"]["output"]
  >;
  subtotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  subtotalWithoutCredits?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  taxTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  total?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  totalInclDiscount?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  totalInclTax?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  totalItemCreditsDeduction?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  totalToPay?: Maybe<Scalars["FormattedDecimal"]["output"]>;
};

export type InvoiceAdditionalInfoWithoutWalletCreditsType = {
  __typename?: "InvoiceAdditionalInfoWithoutWalletCreditsType";
  amountRemaining?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  creditLinesTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  discountLinesTotalPrices?: Maybe<
    Array<Maybe<DiscountLinePricesWithoutWalletCreditsType>>
  >;
  discountTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  feeLinesTotalPrices?: Maybe<
    Array<Maybe<FeeLinePricesWithoutWalletCreditsType>>
  >;
  feeTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  id: Scalars["ID"]["output"];
  lineItemsDiscountTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  packageLinePrices?: Maybe<Array<Maybe<PackageLinePricesType>>>;
  packageLinesTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  productDiscounts?: Maybe<Array<Maybe<DiscountType>>>;
  retailProductLinePrices?: Maybe<
    Array<Maybe<ProductLinePricesWithoutWalletCreditsType>>
  >;
  retailProductsSubtotalWithoutCredits?: Maybe<
    Scalars["FormattedDecimal"]["output"]
  >;
  serviceLineItemsDiscountTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  serviceMenuItemDiscounts?: Maybe<Array<Maybe<DiscountType>>>;
  serviceMenuItemLinesPrices?: Maybe<
    Array<Maybe<ServiceMenuItemLinePricesWithoutWalletCreditsType>>
  >;
  serviceSubtotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  servicesAndProductsSubtotalWithoutCredits?: Maybe<
    Scalars["FormattedDecimal"]["output"]
  >;
  subtotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  subtotalWithoutCredits?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  taxTotal?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  total?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  totalInclDiscount?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  totalInclTax?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  totalItemCreditsDeduction?: Maybe<Scalars["FormattedDecimal"]["output"]>;
  totalToPay?: Maybe<Scalars["FormattedDecimal"]["output"]>;
};

export type InvoiceType = {
  __typename?: "InvoiceType";
  DiscountTotal?: Maybe<Scalars["Decimal"]["output"]>;
  FeeTotal?: Maybe<Scalars["Decimal"]["output"]>;
  Subtotal?: Maybe<Scalars["Decimal"]["output"]>;
  SubtotalInclLineItemsDiscount?: Maybe<Scalars["Decimal"]["output"]>;
  TaxTotal?: Maybe<Scalars["Decimal"]["output"]>;
  Total?: Maybe<Scalars["Decimal"]["output"]>;
  TotalInclDiscount?: Maybe<Scalars["Decimal"]["output"]>;
  TotalInclTax?: Maybe<Scalars["Decimal"]["output"]>;
  client: ClientPublicInfoType;
  created: Scalars["DateTime"]["output"];
  discountLines: Array<DiscountLinePricesWithoutWalletCreditsType>;
  /** Indicates when the invoice object was created (as a draft) in DB. Useful in case of visit invoices which are created along with the visit, but are only considered issued once the visit is completed. When the invoice is issued we update 'created' timestamp to reflect this. */
  draftCreatedAt: Scalars["DateTime"]["output"];
  feeLines: Array<FeeLinePricesWithoutWalletCreditsType>;
  id: Scalars["ID"]["output"];
  layerfiId: Scalars["String"]["output"];
  medspa: MedspaPublicInfoType;
  modified: Scalars["DateTime"]["output"];
  packageLines: Array<PackageLinePricesType>;
  payments: Array<PaymentAdditionalInfoType>;
  productLines: Array<ProductLinePricesWithoutWalletCreditsType>;
  purchaseSource?: Maybe<Scalars["String"]["output"]>;
  serviceMenuItemLines: Array<ServiceMenuItemLinePricesWithoutWalletCreditsType>;
  serviceProductLines: Array<ServiceProductLinePricesWithoutWalletCreditsType>;
  status: Scalars["String"]["output"];
  tip?: Maybe<Scalars["Decimal"]["output"]>;
  uuid: Scalars["String"]["output"];
  visit?: Maybe<VisitType>;
};

export type JoinTwilioConversation = {
  __typename?: "JoinTwilioConversation";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]["input"]>;
  _eq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gte?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars["String"]["input"]>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _lte?: InputMaybe<Scalars["jsonb"]["input"]>;
  _neq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
};

/** columns and relationships of "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndication = {
  __typename?: "LastGfeReviewTreatmentIndication";
  /** An object relationship */
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  createdBy?: Maybe<UserMedspa>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  gfeReviewRequest?: Maybe<GfeReviewRequest>;
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  gfeReviewSubmission?: Maybe<GfeReviewSubmission>;
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  gfeServiceProductIndications: Array<GfeServiceProductIndication>;
  /** An aggregate relationship */
  gfeServiceProductIndicationsAggregate: GfeServiceProductIndicationAggregate;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  medspaServiceOffering?: Maybe<MedspaServiceOffering>;
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationGfeServiceProductIndicationsArgs = {
  distinctOn?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeServiceProductIndicationOrderBy>>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

/** columns and relationships of "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationGfeServiceProductIndicationsAggregateArgs =
  {
    distinctOn?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<GfeServiceProductIndicationOrderBy>>;
    where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
  };

/** aggregated selection of "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationAggregate = {
  __typename?: "LastGfeReviewTreatmentIndicationAggregate";
  aggregate?: Maybe<LastGfeReviewTreatmentIndicationAggregateFields>;
  nodes: Array<LastGfeReviewTreatmentIndication>;
};

export type LastGfeReviewTreatmentIndicationAggregateBoolExp = {
  count?: InputMaybe<LastGfeReviewTreatmentIndicationAggregateBoolExpCount>;
};

/** aggregate fields of "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationAggregateFields = {
  __typename?: "LastGfeReviewTreatmentIndicationAggregateFields";
  avg?: Maybe<LastGfeReviewTreatmentIndicationAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<LastGfeReviewTreatmentIndicationMaxFields>;
  min?: Maybe<LastGfeReviewTreatmentIndicationMinFields>;
  stddev?: Maybe<LastGfeReviewTreatmentIndicationStddevFields>;
  stddevPop?: Maybe<LastGfeReviewTreatmentIndicationStddevPopFields>;
  stddevSamp?: Maybe<LastGfeReviewTreatmentIndicationStddevSampFields>;
  sum?: Maybe<LastGfeReviewTreatmentIndicationSumFields>;
  varPop?: Maybe<LastGfeReviewTreatmentIndicationVarPopFields>;
  varSamp?: Maybe<LastGfeReviewTreatmentIndicationVarSampFields>;
  variance?: Maybe<LastGfeReviewTreatmentIndicationVarianceFields>;
};

/** aggregate fields of "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LastGfeReviewTreatmentIndicationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationAggregateOrderBy = {
  avg?: InputMaybe<LastGfeReviewTreatmentIndicationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LastGfeReviewTreatmentIndicationMaxOrderBy>;
  min?: InputMaybe<LastGfeReviewTreatmentIndicationMinOrderBy>;
  stddev?: InputMaybe<LastGfeReviewTreatmentIndicationStddevOrderBy>;
  stddevPop?: InputMaybe<LastGfeReviewTreatmentIndicationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<LastGfeReviewTreatmentIndicationStddevSampOrderBy>;
  sum?: InputMaybe<LastGfeReviewTreatmentIndicationSumOrderBy>;
  varPop?: InputMaybe<LastGfeReviewTreatmentIndicationVarPopOrderBy>;
  varSamp?: InputMaybe<LastGfeReviewTreatmentIndicationVarSampOrderBy>;
  variance?: InputMaybe<LastGfeReviewTreatmentIndicationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationArrRelInsertInput = {
  data: Array<LastGfeReviewTreatmentIndicationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<LastGfeReviewTreatmentIndicationOnConflict>;
};

/** aggregate avg on columns */
export type LastGfeReviewTreatmentIndicationAvgFields = {
  __typename?: "LastGfeReviewTreatmentIndicationAvgFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationAvgOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "last_gfe_review_treatment_indication". All fields are combined with a logical 'AND'. */
export type LastGfeReviewTreatmentIndicationBoolExp = {
  _and?: InputMaybe<Array<LastGfeReviewTreatmentIndicationBoolExp>>;
  _not?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
  _or?: InputMaybe<Array<LastGfeReviewTreatmentIndicationBoolExp>>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<UserMedspaBoolExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestBoolExp>;
  gfeReviewRequestId?: InputMaybe<BigintComparisonExp>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionBoolExp>;
  gfeReviewSubmissionId?: InputMaybe<BigintComparisonExp>;
  gfeServiceProductIndications?: InputMaybe<GfeServiceProductIndicationBoolExp>;
  gfeServiceProductIndicationsAggregate?: InputMaybe<GfeServiceProductIndicationAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaServiceOfferingId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "last_gfe_review_treatment_indication" */
export enum LastGfeReviewTreatmentIndicationConstraint {
  /** unique or primary key constraint on columns "client_id", "medspa_service_offering_id" */
  IdxLastGfeReviewTreatmentIndicationUnique = "idx_last_gfe_review_treatment_indication_unique",
}

/** input type for inserting data into table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationInsertInput = {
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestObjRelInsertInput>;
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionObjRelInsertInput>;
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeServiceProductIndications?: InputMaybe<GfeServiceProductIndicationArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type LastGfeReviewTreatmentIndicationMaxFields = {
  __typename?: "LastGfeReviewTreatmentIndicationMaxFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationMaxOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LastGfeReviewTreatmentIndicationMinFields = {
  __typename?: "LastGfeReviewTreatmentIndicationMinFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationMinOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** on_conflict condition type for table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationOnConflict = {
  constraint: LastGfeReviewTreatmentIndicationConstraint;
  updateColumns?: Array<LastGfeReviewTreatmentIndicationUpdateColumn>;
  where?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
};

/** Ordering options when selecting data from "last_gfe_review_treatment_indication". */
export type LastGfeReviewTreatmentIndicationOrderBy = {
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserMedspaOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequest?: InputMaybe<GfeReviewRequestOrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmission?: InputMaybe<GfeReviewSubmissionOrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  gfeServiceProductIndicationsAggregate?: InputMaybe<GfeServiceProductIndicationAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** select columns of table "last_gfe_review_treatment_indication" */
export enum LastGfeReviewTreatmentIndicationSelectColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  GfeReviewSubmissionId = "gfeReviewSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  Status = "status",
}

/** aggregate stddev on columns */
export type LastGfeReviewTreatmentIndicationStddevFields = {
  __typename?: "LastGfeReviewTreatmentIndicationStddevFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationStddevOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type LastGfeReviewTreatmentIndicationStddevPopFields = {
  __typename?: "LastGfeReviewTreatmentIndicationStddevPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationStddevPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type LastGfeReviewTreatmentIndicationStddevSampFields = {
  __typename?: "LastGfeReviewTreatmentIndicationStddevSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationStddevSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LastGfeReviewTreatmentIndicationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LastGfeReviewTreatmentIndicationStreamCursorValueInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeReviewRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeReviewSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type LastGfeReviewTreatmentIndicationSumFields = {
  __typename?: "LastGfeReviewTreatmentIndicationSumFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["bigint"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationSumOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** update columns of table "last_gfe_review_treatment_indication" */
export enum LastGfeReviewTreatmentIndicationUpdateColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  GfeReviewRequestId = "gfeReviewRequestId",
  /** column name */
  GfeReviewSubmissionId = "gfeReviewSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  Status = "status",
}

/** aggregate varPop on columns */
export type LastGfeReviewTreatmentIndicationVarPopFields = {
  __typename?: "LastGfeReviewTreatmentIndicationVarPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationVarPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type LastGfeReviewTreatmentIndicationVarSampFields = {
  __typename?: "LastGfeReviewTreatmentIndicationVarSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationVarSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LastGfeReviewTreatmentIndicationVarianceFields = {
  __typename?: "LastGfeReviewTreatmentIndicationVarianceFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewRequestId?: Maybe<Scalars["Float"]["output"]>;
  gfeReviewSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "last_gfe_review_treatment_indication" */
export type LastGfeReviewTreatmentIndicationVarianceOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  gfeReviewRequestId?: InputMaybe<OrderBy>;
  gfeReviewSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
};

export type LeaveTwilioConversation = {
  __typename?: "LeaveTwilioConversation";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type MdMeetingType = {
  __typename?: "MDMeetingType";
  created: Scalars["DateTime"]["output"];
  endTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  modified: Scalars["DateTime"]["output"];
  startTime?: Maybe<Scalars["DateTime"]["output"]>;
};

export type MakeRefund = {
  __typename?: "MakeRefund";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  refundId?: Maybe<Scalars["ID"]["output"]>;
};

export type MarkInvoiceAsPaid = {
  __typename?: "MarkInvoiceAsPaid";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
};

/** Canva template that a medspa can use to create social media posts, email campaigns, and other marketing materials */
export type MarketingTemplate = {
  __typename?: "MarketingTemplate";
  additionalInfo?: Maybe<MarketingTemplateType>;
  /** The label shown in MS on the button. E.g. "Create Post" */
  buttonLabel: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Description of the template that is displayed in Moxie Suite */
  description: Scalars["String"]["output"];
  /** Canva URL for the template */
  externalUrl: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Name of the template that is displayed in Moxie Suite */
  name: Scalars["String"]["output"];
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order: Scalars["Int"]["output"];
  previewImage?: Maybe<Scalars["String"]["output"]>;
};

/** aggregated selection of "marketing_template" */
export type MarketingTemplateAggregate = {
  __typename?: "MarketingTemplateAggregate";
  aggregate?: Maybe<MarketingTemplateAggregateFields>;
  nodes: Array<MarketingTemplate>;
};

/** aggregate fields of "marketing_template" */
export type MarketingTemplateAggregateFields = {
  __typename?: "MarketingTemplateAggregateFields";
  avg?: Maybe<MarketingTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MarketingTemplateMaxFields>;
  min?: Maybe<MarketingTemplateMinFields>;
  stddev?: Maybe<MarketingTemplateStddevFields>;
  stddevPop?: Maybe<MarketingTemplateStddevPopFields>;
  stddevSamp?: Maybe<MarketingTemplateStddevSampFields>;
  sum?: Maybe<MarketingTemplateSumFields>;
  varPop?: Maybe<MarketingTemplateVarPopFields>;
  varSamp?: Maybe<MarketingTemplateVarSampFields>;
  variance?: Maybe<MarketingTemplateVarianceFields>;
};

/** aggregate fields of "marketing_template" */
export type MarketingTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MarketingTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type MarketingTemplateAvgFields = {
  __typename?: "MarketingTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "marketing_template". All fields are combined with a logical 'AND'. */
export type MarketingTemplateBoolExp = {
  _and?: InputMaybe<Array<MarketingTemplateBoolExp>>;
  _not?: InputMaybe<MarketingTemplateBoolExp>;
  _or?: InputMaybe<Array<MarketingTemplateBoolExp>>;
  buttonLabel?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  externalUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  previewImage?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "marketing_template" */
export enum MarketingTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  MarketingTemplatePkey = "marketing_template_pkey",
}

/** input type for incrementing numeric columns in table "marketing_template" */
export type MarketingTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "marketing_template" */
export type MarketingTemplateInsertInput = {
  /** The label shown in MS on the button. E.g. "Create Post" */
  buttonLabel?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Description of the template that is displayed in Moxie Suite */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Canva URL for the template */
  externalUrl?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the template that is displayed in Moxie Suite */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  previewImage?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MarketingTemplateMaxFields = {
  __typename?: "MarketingTemplateMaxFields";
  /** The label shown in MS on the button. E.g. "Create Post" */
  buttonLabel?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Description of the template that is displayed in Moxie Suite */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Canva URL for the template */
  externalUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the template that is displayed in Moxie Suite */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Int"]["output"]>;
  previewImage?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type MarketingTemplateMinFields = {
  __typename?: "MarketingTemplateMinFields";
  /** The label shown in MS on the button. E.g. "Create Post" */
  buttonLabel?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Description of the template that is displayed in Moxie Suite */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Canva URL for the template */
  externalUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the template that is displayed in Moxie Suite */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Int"]["output"]>;
  previewImage?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "marketing_template" */
export type MarketingTemplateMutationResponse = {
  __typename?: "MarketingTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MarketingTemplate>;
};

/** on_conflict condition type for table "marketing_template" */
export type MarketingTemplateOnConflict = {
  constraint: MarketingTemplateConstraint;
  updateColumns?: Array<MarketingTemplateUpdateColumn>;
  where?: InputMaybe<MarketingTemplateBoolExp>;
};

/** Ordering options when selecting data from "marketing_template". */
export type MarketingTemplateOrderBy = {
  buttonLabel?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  externalUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  previewImage?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: marketing_template */
export type MarketingTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "marketing_template" */
export enum MarketingTemplateSelectColumn {
  /** column name */
  ButtonLabel = "buttonLabel",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  ExternalUrl = "externalUrl",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  PreviewImage = "previewImage",
}

/** input type for updating data in table "marketing_template" */
export type MarketingTemplateSetInput = {
  /** The label shown in MS on the button. E.g. "Create Post" */
  buttonLabel?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Description of the template that is displayed in Moxie Suite */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Canva URL for the template */
  externalUrl?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the template that is displayed in Moxie Suite */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  previewImage?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MarketingTemplateStddevFields = {
  __typename?: "MarketingTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type MarketingTemplateStddevPopFields = {
  __typename?: "MarketingTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type MarketingTemplateStddevSampFields = {
  __typename?: "MarketingTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "marketing_template" */
export type MarketingTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MarketingTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MarketingTemplateStreamCursorValueInput = {
  /** The label shown in MS on the button. E.g. "Create Post" */
  buttonLabel?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Description of the template that is displayed in Moxie Suite */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Canva URL for the template */
  externalUrl?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the template that is displayed in Moxie Suite */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  previewImage?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MarketingTemplateSumFields = {
  __typename?: "MarketingTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Int"]["output"]>;
};

export type MarketingTemplateType = {
  __typename?: "MarketingTemplateType";
  id: Scalars["ID"]["output"];
  previewImageUrl?: Maybe<Scalars["String"]["output"]>;
};

/** update columns of table "marketing_template" */
export enum MarketingTemplateUpdateColumn {
  /** column name */
  ButtonLabel = "buttonLabel",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  ExternalUrl = "externalUrl",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  PreviewImage = "previewImage",
}

export type MarketingTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MarketingTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MarketingTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: MarketingTemplateBoolExp;
};

/** aggregate varPop on columns */
export type MarketingTemplateVarPopFields = {
  __typename?: "MarketingTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type MarketingTemplateVarSampFields = {
  __typename?: "MarketingTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type MarketingTemplateVarianceFields = {
  __typename?: "MarketingTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Numberic value that is used to sort data in MS. Lower numbers show closer to top. */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Stores regular meetings between providers and their overseeing Medical Directors. */
export type MdMeeting = {
  __typename?: "MdMeeting";
  /** An array relationship */
  attendees: Array<MdMeetingAttendees>;
  /** An aggregate relationship */
  attendeesAggregate: MdMeetingAttendeesAggregate;
  /** Provider or MD who booked the meeting */
  bookedById: Scalars["bigint"]["output"];
  /** User/flow used to book the meeting */
  bookingFlow: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** Details of the meeting, ie location */
  details: Scalars["String"]["output"];
  /** End time of the meeting */
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Format of the meeting, ie in person or video/phone call */
  format: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** MedSpa that the meeting is associated with */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An array relationship */
  notes: Array<MdMeetingNote>;
  /** An aggregate relationship */
  notesAggregate: MdMeetingNoteAggregate;
  /** Start time of the meeting */
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Current status of the meeting */
  status: Scalars["String"]["output"];
  /** Time span of the meeting */
  timeSpan: Scalars["tstzrange"]["output"];
};

/** Stores regular meetings between providers and their overseeing Medical Directors. */
export type MdMeetingAttendeesArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingAttendeesOrderBy>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

/** Stores regular meetings between providers and their overseeing Medical Directors. */
export type MdMeetingAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingAttendeesOrderBy>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

/** Stores regular meetings between providers and their overseeing Medical Directors. */
export type MdMeetingNotesArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteOrderBy>>;
  where?: InputMaybe<MdMeetingNoteBoolExp>;
};

/** Stores regular meetings between providers and their overseeing Medical Directors. */
export type MdMeetingNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteOrderBy>>;
  where?: InputMaybe<MdMeetingNoteBoolExp>;
};

/** aggregated selection of "md_meeting" */
export type MdMeetingAggregate = {
  __typename?: "MdMeetingAggregate";
  aggregate?: Maybe<MdMeetingAggregateFields>;
  nodes: Array<MdMeeting>;
};

/** aggregate fields of "md_meeting" */
export type MdMeetingAggregateFields = {
  __typename?: "MdMeetingAggregateFields";
  avg?: Maybe<MdMeetingAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MdMeetingMaxFields>;
  min?: Maybe<MdMeetingMinFields>;
  stddev?: Maybe<MdMeetingStddevFields>;
  stddevPop?: Maybe<MdMeetingStddevPopFields>;
  stddevSamp?: Maybe<MdMeetingStddevSampFields>;
  sum?: Maybe<MdMeetingSumFields>;
  varPop?: Maybe<MdMeetingVarPopFields>;
  varSamp?: Maybe<MdMeetingVarSampFields>;
  variance?: Maybe<MdMeetingVarianceFields>;
};

/** aggregate fields of "md_meeting" */
export type MdMeetingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MdMeetingSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** columns and relationships of "md_meeting_attendees" */
export type MdMeetingAttendees = {
  __typename?: "MdMeetingAttendees";
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  mdMeeting: MdMeeting;
  mdmeetingId: Scalars["bigint"]["output"];
  /** An object relationship */
  userMedspa: UserMedspa;
  usermedspaId: Scalars["bigint"]["output"];
};

/** aggregated selection of "md_meeting_attendees" */
export type MdMeetingAttendeesAggregate = {
  __typename?: "MdMeetingAttendeesAggregate";
  aggregate?: Maybe<MdMeetingAttendeesAggregateFields>;
  nodes: Array<MdMeetingAttendees>;
};

export type MdMeetingAttendeesAggregateBoolExp = {
  count?: InputMaybe<MdMeetingAttendeesAggregateBoolExpCount>;
};

/** aggregate fields of "md_meeting_attendees" */
export type MdMeetingAttendeesAggregateFields = {
  __typename?: "MdMeetingAttendeesAggregateFields";
  avg?: Maybe<MdMeetingAttendeesAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MdMeetingAttendeesMaxFields>;
  min?: Maybe<MdMeetingAttendeesMinFields>;
  stddev?: Maybe<MdMeetingAttendeesStddevFields>;
  stddevPop?: Maybe<MdMeetingAttendeesStddevPopFields>;
  stddevSamp?: Maybe<MdMeetingAttendeesStddevSampFields>;
  sum?: Maybe<MdMeetingAttendeesSumFields>;
  varPop?: Maybe<MdMeetingAttendeesVarPopFields>;
  varSamp?: Maybe<MdMeetingAttendeesVarSampFields>;
  variance?: Maybe<MdMeetingAttendeesVarianceFields>;
};

/** aggregate fields of "md_meeting_attendees" */
export type MdMeetingAttendeesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "md_meeting_attendees" */
export type MdMeetingAttendeesAggregateOrderBy = {
  avg?: InputMaybe<MdMeetingAttendeesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MdMeetingAttendeesMaxOrderBy>;
  min?: InputMaybe<MdMeetingAttendeesMinOrderBy>;
  stddev?: InputMaybe<MdMeetingAttendeesStddevOrderBy>;
  stddevPop?: InputMaybe<MdMeetingAttendeesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MdMeetingAttendeesStddevSampOrderBy>;
  sum?: InputMaybe<MdMeetingAttendeesSumOrderBy>;
  varPop?: InputMaybe<MdMeetingAttendeesVarPopOrderBy>;
  varSamp?: InputMaybe<MdMeetingAttendeesVarSampOrderBy>;
  variance?: InputMaybe<MdMeetingAttendeesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "md_meeting_attendees" */
export type MdMeetingAttendeesArrRelInsertInput = {
  data: Array<MdMeetingAttendeesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MdMeetingAttendeesOnConflict>;
};

/** aggregate avg on columns */
export type MdMeetingAttendeesAvgFields = {
  __typename?: "MdMeetingAttendeesAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  mdmeetingId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "md_meeting_attendees". All fields are combined with a logical 'AND'. */
export type MdMeetingAttendeesBoolExp = {
  _and?: InputMaybe<Array<MdMeetingAttendeesBoolExp>>;
  _not?: InputMaybe<MdMeetingAttendeesBoolExp>;
  _or?: InputMaybe<Array<MdMeetingAttendeesBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  mdMeeting?: InputMaybe<MdMeetingBoolExp>;
  mdmeetingId?: InputMaybe<BigintComparisonExp>;
  userMedspa?: InputMaybe<UserMedspaBoolExp>;
  usermedspaId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "md_meeting_attendees" */
export enum MdMeetingAttendeesConstraint {
  /** unique or primary key constraint on columns "mdmeeting_id", "usermedspa_id" */
  MdMeetingAttendeesMdmeetingIdUsermedspaIdB8a3972eUniq = "md_meeting_attendees_mdmeeting_id_usermedspa_id_b8a3972e_uniq",
  /** unique or primary key constraint on columns "id" */
  MdMeetingAttendeesPkey = "md_meeting_attendees_pkey",
}

/** input type for incrementing numeric columns in table "md_meeting_attendees" */
export type MdMeetingAttendeesIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  mdmeetingId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "md_meeting_attendees" */
export type MdMeetingAttendeesInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  mdMeeting?: InputMaybe<MdMeetingObjRelInsertInput>;
  mdmeetingId?: InputMaybe<Scalars["bigint"]["input"]>;
  userMedspa?: InputMaybe<UserMedspaObjRelInsertInput>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MdMeetingAttendeesMaxFields = {
  __typename?: "MdMeetingAttendeesMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  mdmeetingId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MdMeetingAttendeesMinFields = {
  __typename?: "MdMeetingAttendeesMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  mdmeetingId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "md_meeting_attendees" */
export type MdMeetingAttendeesMutationResponse = {
  __typename?: "MdMeetingAttendeesMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MdMeetingAttendees>;
};

/** on_conflict condition type for table "md_meeting_attendees" */
export type MdMeetingAttendeesOnConflict = {
  constraint: MdMeetingAttendeesConstraint;
  updateColumns?: Array<MdMeetingAttendeesUpdateColumn>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

/** Ordering options when selecting data from "md_meeting_attendees". */
export type MdMeetingAttendeesOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdMeeting?: InputMaybe<MdMeetingOrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  userMedspa?: InputMaybe<UserMedspaOrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: md_meeting_attendees */
export type MdMeetingAttendeesPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "md_meeting_attendees" */
export enum MdMeetingAttendeesSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MdmeetingId = "mdmeetingId",
  /** column name */
  UsermedspaId = "usermedspaId",
}

/** input type for updating data in table "md_meeting_attendees" */
export type MdMeetingAttendeesSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  mdmeetingId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MdMeetingAttendeesStddevFields = {
  __typename?: "MdMeetingAttendeesStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  mdmeetingId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MdMeetingAttendeesStddevPopFields = {
  __typename?: "MdMeetingAttendeesStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  mdmeetingId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MdMeetingAttendeesStddevSampFields = {
  __typename?: "MdMeetingAttendeesStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  mdmeetingId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "md_meeting_attendees" */
export type MdMeetingAttendeesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MdMeetingAttendeesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MdMeetingAttendeesStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  mdmeetingId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MdMeetingAttendeesSumFields = {
  __typename?: "MdMeetingAttendeesSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  mdmeetingId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "md_meeting_attendees" */
export enum MdMeetingAttendeesUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MdmeetingId = "mdmeetingId",
  /** column name */
  UsermedspaId = "usermedspaId",
}

export type MdMeetingAttendeesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MdMeetingAttendeesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MdMeetingAttendeesSetInput>;
  /** filter the rows which have to be updated */
  where: MdMeetingAttendeesBoolExp;
};

/** aggregate varPop on columns */
export type MdMeetingAttendeesVarPopFields = {
  __typename?: "MdMeetingAttendeesVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  mdmeetingId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MdMeetingAttendeesVarSampFields = {
  __typename?: "MdMeetingAttendeesVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  mdmeetingId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MdMeetingAttendeesVarianceFields = {
  __typename?: "MdMeetingAttendeesVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  mdmeetingId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "md_meeting_attendees" */
export type MdMeetingAttendeesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  mdmeetingId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate avg on columns */
export type MdMeetingAvgFields = {
  __typename?: "MdMeetingAvgFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "md_meeting". All fields are combined with a logical 'AND'. */
export type MdMeetingBoolExp = {
  _and?: InputMaybe<Array<MdMeetingBoolExp>>;
  _not?: InputMaybe<MdMeetingBoolExp>;
  _or?: InputMaybe<Array<MdMeetingBoolExp>>;
  attendees?: InputMaybe<MdMeetingAttendeesBoolExp>;
  attendeesAggregate?: InputMaybe<MdMeetingAttendeesAggregateBoolExp>;
  bookedById?: InputMaybe<BigintComparisonExp>;
  bookingFlow?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  details?: InputMaybe<StringComparisonExp>;
  endTime?: InputMaybe<TimestamptzComparisonExp>;
  format?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  notes?: InputMaybe<MdMeetingNoteBoolExp>;
  notesAggregate?: InputMaybe<MdMeetingNoteAggregateBoolExp>;
  startTime?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  timeSpan?: InputMaybe<TstzrangeComparisonExp>;
};

/** unique or primary key constraints on table "md_meeting" */
export enum MdMeetingConstraint {
  /** unique or primary key constraint on columns "id" */
  MdMeetingPkey = "md_meeting_pkey",
}

/** input type for incrementing numeric columns in table "md_meeting" */
export type MdMeetingIncInput = {
  /** Provider or MD who booked the meeting */
  bookedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "md_meeting" */
export type MdMeetingInsertInput = {
  attendees?: InputMaybe<MdMeetingAttendeesArrRelInsertInput>;
  /** Provider or MD who booked the meeting */
  bookedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User/flow used to book the meeting */
  bookingFlow?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Details of the meeting, ie location */
  details?: InputMaybe<Scalars["String"]["input"]>;
  /** Format of the meeting, ie in person or video/phone call */
  format?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** MedSpa that the meeting is associated with */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  notes?: InputMaybe<MdMeetingNoteArrRelInsertInput>;
  /** Current status of the meeting */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Time span of the meeting */
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
};

/** aggregate max on columns */
export type MdMeetingMaxFields = {
  __typename?: "MdMeetingMaxFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["bigint"]["output"]>;
  /** User/flow used to book the meeting */
  bookingFlow?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Details of the meeting, ie location */
  details?: Maybe<Scalars["String"]["output"]>;
  /** End time of the meeting */
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Format of the meeting, ie in person or video/phone call */
  format?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Start time of the meeting */
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Current status of the meeting */
  status?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type MdMeetingMinFields = {
  __typename?: "MdMeetingMinFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["bigint"]["output"]>;
  /** User/flow used to book the meeting */
  bookingFlow?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Details of the meeting, ie location */
  details?: Maybe<Scalars["String"]["output"]>;
  /** End time of the meeting */
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Format of the meeting, ie in person or video/phone call */
  format?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Start time of the meeting */
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Current status of the meeting */
  status?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "md_meeting" */
export type MdMeetingMutationResponse = {
  __typename?: "MdMeetingMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MdMeeting>;
};

/** Stores notes for usage in MD Meetings between Medical Directors and Providers */
export type MdMeetingNote = {
  __typename?: "MdMeetingNote";
  /** An array relationship */
  attendees: Array<MdMeetingAttendees>;
  /** An aggregate relationship */
  attendeesAggregate: MdMeetingAttendeesAggregate;
  /** Content of the MD meeting note */
  content: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  mdMeeting?: Maybe<MdMeeting>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  mdMeetingNoteTemplate: MdMeetingNoteTemplate;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Title of the MD meeting note */
  title: Scalars["String"]["output"];
};

/** Stores notes for usage in MD Meetings between Medical Directors and Providers */
export type MdMeetingNoteAttendeesArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingAttendeesOrderBy>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

/** Stores notes for usage in MD Meetings between Medical Directors and Providers */
export type MdMeetingNoteAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingAttendeesOrderBy>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

/** aggregated selection of "md_meeting_note" */
export type MdMeetingNoteAggregate = {
  __typename?: "MdMeetingNoteAggregate";
  aggregate?: Maybe<MdMeetingNoteAggregateFields>;
  nodes: Array<MdMeetingNote>;
};

export type MdMeetingNoteAggregateBoolExp = {
  count?: InputMaybe<MdMeetingNoteAggregateBoolExpCount>;
};

/** aggregate fields of "md_meeting_note" */
export type MdMeetingNoteAggregateFields = {
  __typename?: "MdMeetingNoteAggregateFields";
  avg?: Maybe<MdMeetingNoteAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MdMeetingNoteMaxFields>;
  min?: Maybe<MdMeetingNoteMinFields>;
  stddev?: Maybe<MdMeetingNoteStddevFields>;
  stddevPop?: Maybe<MdMeetingNoteStddevPopFields>;
  stddevSamp?: Maybe<MdMeetingNoteStddevSampFields>;
  sum?: Maybe<MdMeetingNoteSumFields>;
  varPop?: Maybe<MdMeetingNoteVarPopFields>;
  varSamp?: Maybe<MdMeetingNoteVarSampFields>;
  variance?: Maybe<MdMeetingNoteVarianceFields>;
};

/** aggregate fields of "md_meeting_note" */
export type MdMeetingNoteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MdMeetingNoteSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "md_meeting_note" */
export type MdMeetingNoteAggregateOrderBy = {
  avg?: InputMaybe<MdMeetingNoteAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MdMeetingNoteMaxOrderBy>;
  min?: InputMaybe<MdMeetingNoteMinOrderBy>;
  stddev?: InputMaybe<MdMeetingNoteStddevOrderBy>;
  stddevPop?: InputMaybe<MdMeetingNoteStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MdMeetingNoteStddevSampOrderBy>;
  sum?: InputMaybe<MdMeetingNoteSumOrderBy>;
  varPop?: InputMaybe<MdMeetingNoteVarPopOrderBy>;
  varSamp?: InputMaybe<MdMeetingNoteVarSampOrderBy>;
  variance?: InputMaybe<MdMeetingNoteVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "md_meeting_note" */
export type MdMeetingNoteArrRelInsertInput = {
  data: Array<MdMeetingNoteInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MdMeetingNoteOnConflict>;
};

/** aggregate avg on columns */
export type MdMeetingNoteAvgFields = {
  __typename?: "MdMeetingNoteAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["Float"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "md_meeting_note" */
export type MdMeetingNoteAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "md_meeting_note". All fields are combined with a logical 'AND'. */
export type MdMeetingNoteBoolExp = {
  _and?: InputMaybe<Array<MdMeetingNoteBoolExp>>;
  _not?: InputMaybe<MdMeetingNoteBoolExp>;
  _or?: InputMaybe<Array<MdMeetingNoteBoolExp>>;
  attendees?: InputMaybe<MdMeetingAttendeesBoolExp>;
  attendeesAggregate?: InputMaybe<MdMeetingAttendeesAggregateBoolExp>;
  content?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  mdMeeting?: InputMaybe<MdMeetingBoolExp>;
  mdMeetingId?: InputMaybe<BigintComparisonExp>;
  mdMeetingNoteTemplate?: InputMaybe<MdMeetingNoteTemplateBoolExp>;
  mdMeetingNoteTemplateId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "md_meeting_note" */
export enum MdMeetingNoteConstraint {
  /** unique or primary key constraint on columns "id" */
  MdMeetingNotePkey = "md_meeting_note_pkey",
}

/** input type for incrementing numeric columns in table "md_meeting_note" */
export type MdMeetingNoteIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "md_meeting_note" */
export type MdMeetingNoteInsertInput = {
  attendees?: InputMaybe<MdMeetingAttendeesArrRelInsertInput>;
  /** Content of the MD meeting note */
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  mdMeeting?: InputMaybe<MdMeetingObjRelInsertInput>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<Scalars["bigint"]["input"]>;
  mdMeetingNoteTemplate?: InputMaybe<MdMeetingNoteTemplateObjRelInsertInput>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Title of the MD meeting note */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MdMeetingNoteMaxFields = {
  __typename?: "MdMeetingNoteMaxFields";
  /** Content of the MD meeting note */
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["bigint"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Title of the MD meeting note */
  title?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "md_meeting_note" */
export type MdMeetingNoteMaxOrderBy = {
  /** Content of the MD meeting note */
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Title of the MD meeting note */
  title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MdMeetingNoteMinFields = {
  __typename?: "MdMeetingNoteMinFields";
  /** Content of the MD meeting note */
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["bigint"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Title of the MD meeting note */
  title?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "md_meeting_note" */
export type MdMeetingNoteMinOrderBy = {
  /** Content of the MD meeting note */
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Title of the MD meeting note */
  title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "md_meeting_note" */
export type MdMeetingNoteMutationResponse = {
  __typename?: "MdMeetingNoteMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MdMeetingNote>;
};

/** on_conflict condition type for table "md_meeting_note" */
export type MdMeetingNoteOnConflict = {
  constraint: MdMeetingNoteConstraint;
  updateColumns?: Array<MdMeetingNoteUpdateColumn>;
  where?: InputMaybe<MdMeetingNoteBoolExp>;
};

/** Ordering options when selecting data from "md_meeting_note". */
export type MdMeetingNoteOrderBy = {
  attendeesAggregate?: InputMaybe<MdMeetingAttendeesAggregateOrderBy>;
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mdMeeting?: InputMaybe<MdMeetingOrderBy>;
  mdMeetingId?: InputMaybe<OrderBy>;
  mdMeetingNoteTemplate?: InputMaybe<MdMeetingNoteTemplateOrderBy>;
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: md_meeting_note */
export type MdMeetingNotePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "md_meeting_note" */
export enum MdMeetingNoteSelectColumn {
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MdMeetingId = "mdMeetingId",
  /** column name */
  MdMeetingNoteTemplateId = "mdMeetingNoteTemplateId",
  /** column name */
  Modified = "modified",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "md_meeting_note" */
export type MdMeetingNoteSetInput = {
  /** Content of the MD meeting note */
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Title of the MD meeting note */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MdMeetingNoteStddevFields = {
  __typename?: "MdMeetingNoteStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["Float"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "md_meeting_note" */
export type MdMeetingNoteStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MdMeetingNoteStddevPopFields = {
  __typename?: "MdMeetingNoteStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["Float"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "md_meeting_note" */
export type MdMeetingNoteStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MdMeetingNoteStddevSampFields = {
  __typename?: "MdMeetingNoteStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["Float"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "md_meeting_note" */
export type MdMeetingNoteStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "md_meeting_note" */
export type MdMeetingNoteStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MdMeetingNoteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MdMeetingNoteStreamCursorValueInput = {
  /** Content of the MD meeting note */
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Title of the MD meeting note */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MdMeetingNoteSumFields = {
  __typename?: "MdMeetingNoteSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["bigint"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "md_meeting_note" */
export type MdMeetingNoteSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
};

/** Stores note templates for usage in MD Meetings between Medical Directors and Providers */
export type MdMeetingNoteTemplate = {
  __typename?: "MdMeetingNoteTemplate";
  /** Content of the note template */
  content: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** MedSpa that the note template is associated with */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Title of the note template */
  title: Scalars["String"]["output"];
};

/** aggregated selection of "md_meeting_note_template" */
export type MdMeetingNoteTemplateAggregate = {
  __typename?: "MdMeetingNoteTemplateAggregate";
  aggregate?: Maybe<MdMeetingNoteTemplateAggregateFields>;
  nodes: Array<MdMeetingNoteTemplate>;
};

/** aggregate fields of "md_meeting_note_template" */
export type MdMeetingNoteTemplateAggregateFields = {
  __typename?: "MdMeetingNoteTemplateAggregateFields";
  avg?: Maybe<MdMeetingNoteTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MdMeetingNoteTemplateMaxFields>;
  min?: Maybe<MdMeetingNoteTemplateMinFields>;
  stddev?: Maybe<MdMeetingNoteTemplateStddevFields>;
  stddevPop?: Maybe<MdMeetingNoteTemplateStddevPopFields>;
  stddevSamp?: Maybe<MdMeetingNoteTemplateStddevSampFields>;
  sum?: Maybe<MdMeetingNoteTemplateSumFields>;
  varPop?: Maybe<MdMeetingNoteTemplateVarPopFields>;
  varSamp?: Maybe<MdMeetingNoteTemplateVarSampFields>;
  variance?: Maybe<MdMeetingNoteTemplateVarianceFields>;
};

/** aggregate fields of "md_meeting_note_template" */
export type MdMeetingNoteTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MdMeetingNoteTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type MdMeetingNoteTemplateAvgFields = {
  __typename?: "MdMeetingNoteTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "md_meeting_note_template". All fields are combined with a logical 'AND'. */
export type MdMeetingNoteTemplateBoolExp = {
  _and?: InputMaybe<Array<MdMeetingNoteTemplateBoolExp>>;
  _not?: InputMaybe<MdMeetingNoteTemplateBoolExp>;
  _or?: InputMaybe<Array<MdMeetingNoteTemplateBoolExp>>;
  content?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "md_meeting_note_template" */
export enum MdMeetingNoteTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  MdMeetingNoteTemplatePkey = "md_meeting_note_template_pkey",
}

/** input type for incrementing numeric columns in table "md_meeting_note_template" */
export type MdMeetingNoteTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "md_meeting_note_template" */
export type MdMeetingNoteTemplateInsertInput = {
  /** Content of the note template */
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Title of the note template */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MdMeetingNoteTemplateMaxFields = {
  __typename?: "MdMeetingNoteTemplateMaxFields";
  /** Content of the note template */
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Title of the note template */
  title?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type MdMeetingNoteTemplateMinFields = {
  __typename?: "MdMeetingNoteTemplateMinFields";
  /** Content of the note template */
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Title of the note template */
  title?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "md_meeting_note_template" */
export type MdMeetingNoteTemplateMutationResponse = {
  __typename?: "MdMeetingNoteTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MdMeetingNoteTemplate>;
};

/** input type for inserting object relation for remote table "md_meeting_note_template" */
export type MdMeetingNoteTemplateObjRelInsertInput = {
  data: MdMeetingNoteTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MdMeetingNoteTemplateOnConflict>;
};

/** on_conflict condition type for table "md_meeting_note_template" */
export type MdMeetingNoteTemplateOnConflict = {
  constraint: MdMeetingNoteTemplateConstraint;
  updateColumns?: Array<MdMeetingNoteTemplateUpdateColumn>;
  where?: InputMaybe<MdMeetingNoteTemplateBoolExp>;
};

/** Ordering options when selecting data from "md_meeting_note_template". */
export type MdMeetingNoteTemplateOrderBy = {
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: md_meeting_note_template */
export type MdMeetingNoteTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "md_meeting_note_template" */
export enum MdMeetingNoteTemplateSelectColumn {
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "md_meeting_note_template" */
export type MdMeetingNoteTemplateSetInput = {
  /** Content of the note template */
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Title of the note template */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MdMeetingNoteTemplateStddevFields = {
  __typename?: "MdMeetingNoteTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type MdMeetingNoteTemplateStddevPopFields = {
  __typename?: "MdMeetingNoteTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type MdMeetingNoteTemplateStddevSampFields = {
  __typename?: "MdMeetingNoteTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "md_meeting_note_template" */
export type MdMeetingNoteTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MdMeetingNoteTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MdMeetingNoteTemplateStreamCursorValueInput = {
  /** Content of the note template */
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Title of the note template */
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MdMeetingNoteTemplateSumFields = {
  __typename?: "MdMeetingNoteTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "md_meeting_note_template" */
export enum MdMeetingNoteTemplateUpdateColumn {
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Title = "title",
}

export type MdMeetingNoteTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MdMeetingNoteTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MdMeetingNoteTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: MdMeetingNoteTemplateBoolExp;
};

/** aggregate varPop on columns */
export type MdMeetingNoteTemplateVarPopFields = {
  __typename?: "MdMeetingNoteTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type MdMeetingNoteTemplateVarSampFields = {
  __typename?: "MdMeetingNoteTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type MdMeetingNoteTemplateVarianceFields = {
  __typename?: "MdMeetingNoteTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the note template is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** update columns of table "md_meeting_note" */
export enum MdMeetingNoteUpdateColumn {
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MdMeetingId = "mdMeetingId",
  /** column name */
  MdMeetingNoteTemplateId = "mdMeetingNoteTemplateId",
  /** column name */
  Modified = "modified",
  /** column name */
  Title = "title",
}

export type MdMeetingNoteUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MdMeetingNoteIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MdMeetingNoteSetInput>;
  /** filter the rows which have to be updated */
  where: MdMeetingNoteBoolExp;
};

/** aggregate varPop on columns */
export type MdMeetingNoteVarPopFields = {
  __typename?: "MdMeetingNoteVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["Float"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "md_meeting_note" */
export type MdMeetingNoteVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MdMeetingNoteVarSampFields = {
  __typename?: "MdMeetingNoteVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["Float"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "md_meeting_note" */
export type MdMeetingNoteVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MdMeetingNoteVarianceFields = {
  __typename?: "MdMeetingNoteVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MD meeting that the note is created for */
  mdMeetingId?: Maybe<Scalars["Float"]["output"]>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "md_meeting_note" */
export type MdMeetingNoteVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** MD meeting that the note is created for */
  mdMeetingId?: InputMaybe<OrderBy>;
  /** MD Meeting note template used to create the note */
  mdMeetingNoteTemplateId?: InputMaybe<OrderBy>;
};

/** input type for inserting object relation for remote table "md_meeting" */
export type MdMeetingObjRelInsertInput = {
  data: MdMeetingInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MdMeetingOnConflict>;
};

/** on_conflict condition type for table "md_meeting" */
export type MdMeetingOnConflict = {
  constraint: MdMeetingConstraint;
  updateColumns?: Array<MdMeetingUpdateColumn>;
  where?: InputMaybe<MdMeetingBoolExp>;
};

/** Ordering options when selecting data from "md_meeting". */
export type MdMeetingOrderBy = {
  attendeesAggregate?: InputMaybe<MdMeetingAttendeesAggregateOrderBy>;
  bookedById?: InputMaybe<OrderBy>;
  bookingFlow?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  details?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  notesAggregate?: InputMaybe<MdMeetingNoteAggregateOrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  timeSpan?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: md_meeting */
export type MdMeetingPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "md_meeting" */
export enum MdMeetingSelectColumn {
  /** column name */
  BookedById = "bookedById",
  /** column name */
  BookingFlow = "bookingFlow",
  /** column name */
  Created = "created",
  /** column name */
  Details = "details",
  /** column name */
  EndTime = "endTime",
  /** column name */
  Format = "format",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  StartTime = "startTime",
  /** column name */
  Status = "status",
  /** column name */
  TimeSpan = "timeSpan",
}

/** input type for updating data in table "md_meeting" */
export type MdMeetingSetInput = {
  /** Provider or MD who booked the meeting */
  bookedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User/flow used to book the meeting */
  bookingFlow?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Details of the meeting, ie location */
  details?: InputMaybe<Scalars["String"]["input"]>;
  /** Format of the meeting, ie in person or video/phone call */
  format?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Current status of the meeting */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Time span of the meeting */
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
};

/** aggregate stddev on columns */
export type MdMeetingStddevFields = {
  __typename?: "MdMeetingStddevFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type MdMeetingStddevPopFields = {
  __typename?: "MdMeetingStddevPopFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type MdMeetingStddevSampFields = {
  __typename?: "MdMeetingStddevSampFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "md_meeting" */
export type MdMeetingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MdMeetingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MdMeetingStreamCursorValueInput = {
  /** Provider or MD who booked the meeting */
  bookedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User/flow used to book the meeting */
  bookingFlow?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Details of the meeting, ie location */
  details?: InputMaybe<Scalars["String"]["input"]>;
  /** End time of the meeting */
  endTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Format of the meeting, ie in person or video/phone call */
  format?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Start time of the meeting */
  startTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Current status of the meeting */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Time span of the meeting */
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
};

/** aggregate sum on columns */
export type MdMeetingSumFields = {
  __typename?: "MdMeetingSumFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "md_meeting" */
export enum MdMeetingUpdateColumn {
  /** column name */
  BookedById = "bookedById",
  /** column name */
  BookingFlow = "bookingFlow",
  /** column name */
  Created = "created",
  /** column name */
  Details = "details",
  /** column name */
  Format = "format",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Status = "status",
  /** column name */
  TimeSpan = "timeSpan",
}

export type MdMeetingUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MdMeetingIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MdMeetingSetInput>;
  /** filter the rows which have to be updated */
  where: MdMeetingBoolExp;
};

/** aggregate varPop on columns */
export type MdMeetingVarPopFields = {
  __typename?: "MdMeetingVarPopFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type MdMeetingVarSampFields = {
  __typename?: "MdMeetingVarSampFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type MdMeetingVarianceFields = {
  __typename?: "MdMeetingVarianceFields";
  /** Provider or MD who booked the meeting */
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that the meeting is associated with */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

export type MedSpaComplianceTasksType = {
  __typename?: "MedSpaComplianceTasksType";
  msoTasks?: Maybe<Array<Maybe<ComplianceTaskType>>>;
  pcTasks?: Maybe<Array<Maybe<ComplianceTaskType>>>;
  providerTasks?: Maybe<ProviderTasksType>;
};

export type MedSpaConfigurationType = {
  __typename?: "MedSpaConfigurationType";
  lastEditedNoShowFeeAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type MedSpaDocumentsType = {
  __typename?: "MedSpaDocumentsType";
  medspaId?: Maybe<Scalars["ID"]["output"]>;
  medspaName?: Maybe<Scalars["String"]["output"]>;
  serviceProtocols?: Maybe<DocumentByCurrentStatusType>;
  standingOrders?: Maybe<DocumentByCurrentStatusType>;
};

export type MedSpaServiceMenuItemPublicInfoType = {
  __typename?: "MedSpaServiceMenuItemPublicInfoType";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  offeringsIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type MedSpaServiceProductType = {
  __typename?: "MedSpaServiceProductType";
  hasNoLotInventoryTransactions?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
};

export type MedSpaType = {
  __typename?: "MedSpaType";
  hasAffirmActive?: Maybe<Scalars["Boolean"]["output"]>;
  hasQualiphyApiKey?: Maybe<Scalars["Boolean"]["output"]>;
  hasStripeTerminal?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  stripeBusinessProfileUrl?: Maybe<Scalars["String"]["output"]>;
  stripeFinancialAccount?: Maybe<HydratedStripeFinancialAccountType>;
  stripePayouts: Array<Maybe<StripePayoutType>>;
  stripeTerminals: Array<Maybe<StripeTerminalType>>;
  stripeUrl?: Maybe<Scalars["String"]["output"]>;
  twilioConversationsToken?: Maybe<Scalars["String"]["output"]>;
};

export type MedicalDirectorSubscriptionInvoiceType = {
  __typename?: "MedicalDirectorSubscriptionInvoiceType";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  dueDate?: Maybe<Scalars["Date"]["output"]>;
  paymentDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<SubscriptionInvoiceStatusEnum>;
};

export type MedicalDirectorSubscriptionType = {
  __typename?: "MedicalDirectorSubscriptionType";
  amountOwed?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
  invoices?: Maybe<Array<Maybe<MedicalDirectorSubscriptionInvoiceType>>>;
  isMdCurrentlyOverseeing?: Maybe<Scalars["Boolean"]["output"]>;
  nextPaymentDueDate?: Maybe<Scalars["Date"]["output"]>;
  paymentStatus?: Maybe<SubscriptionPaymentStatusEnum>;
  subscriptionAmountWithoutFees?: Maybe<Scalars["Decimal"]["output"]>;
};

export type MedspaPublicInfoType = {
  __typename?: "MedspaPublicInfoType";
  address?: Maybe<AddressPublicInfoType>;
  collectCardOnBooking?: Maybe<Scalars["Boolean"]["output"]>;
  contactEmail: Scalars["String"]["output"];
  contactPhone: Scalars["String"]["output"];
  enabledFeatures: Array<Maybe<Scalars["String"]["output"]>>;
  id: Scalars["ID"]["output"];
  lateCancellationAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  noShowFeeValue?: Maybe<Scalars["Int"]["output"]>;
  onlineStoreDesktopBanner?: Maybe<Scalars["String"]["output"]>;
  onlineStoreMobileBanner?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "medspa_rooms_appointments" */
export type MedspaRoomsAppointments = {
  __typename?: "MedspaRoomsAppointments";
  /** An object relationship */
  appointment: SchedulingAppointment;
  appointmentId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaRoom: ResourcesMedspaRoom;
  medsparoomId: Scalars["bigint"]["output"];
};

/** aggregated selection of "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsAggregate = {
  __typename?: "MedspaRoomsAppointmentsAggregate";
  aggregate?: Maybe<MedspaRoomsAppointmentsAggregateFields>;
  nodes: Array<MedspaRoomsAppointments>;
};

export type MedspaRoomsAppointmentsAggregateBoolExp = {
  count?: InputMaybe<MedspaRoomsAppointmentsAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsAggregateFields = {
  __typename?: "MedspaRoomsAppointmentsAggregateFields";
  avg?: Maybe<MedspaRoomsAppointmentsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaRoomsAppointmentsMaxFields>;
  min?: Maybe<MedspaRoomsAppointmentsMinFields>;
  stddev?: Maybe<MedspaRoomsAppointmentsStddevFields>;
  stddevPop?: Maybe<MedspaRoomsAppointmentsStddevPopFields>;
  stddevSamp?: Maybe<MedspaRoomsAppointmentsStddevSampFields>;
  sum?: Maybe<MedspaRoomsAppointmentsSumFields>;
  varPop?: Maybe<MedspaRoomsAppointmentsVarPopFields>;
  varSamp?: Maybe<MedspaRoomsAppointmentsVarSampFields>;
  variance?: Maybe<MedspaRoomsAppointmentsVarianceFields>;
};

/** aggregate fields of "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsAggregateOrderBy = {
  avg?: InputMaybe<MedspaRoomsAppointmentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaRoomsAppointmentsMaxOrderBy>;
  min?: InputMaybe<MedspaRoomsAppointmentsMinOrderBy>;
  stddev?: InputMaybe<MedspaRoomsAppointmentsStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaRoomsAppointmentsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaRoomsAppointmentsStddevSampOrderBy>;
  sum?: InputMaybe<MedspaRoomsAppointmentsSumOrderBy>;
  varPop?: InputMaybe<MedspaRoomsAppointmentsVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaRoomsAppointmentsVarSampOrderBy>;
  variance?: InputMaybe<MedspaRoomsAppointmentsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsArrRelInsertInput = {
  data: Array<MedspaRoomsAppointmentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaRoomsAppointmentsOnConflict>;
};

/** aggregate avg on columns */
export type MedspaRoomsAppointmentsAvgFields = {
  __typename?: "MedspaRoomsAppointmentsAvgFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medsparoomId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsAvgOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_rooms_appointments". All fields are combined with a logical 'AND'. */
export type MedspaRoomsAppointmentsBoolExp = {
  _and?: InputMaybe<Array<MedspaRoomsAppointmentsBoolExp>>;
  _not?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
  _or?: InputMaybe<Array<MedspaRoomsAppointmentsBoolExp>>;
  appointment?: InputMaybe<SchedulingAppointmentBoolExp>;
  appointmentId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaRoom?: InputMaybe<ResourcesMedspaRoomBoolExp>;
  medsparoomId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspa_rooms_appointments" */
export enum MedspaRoomsAppointmentsConstraint {
  /** unique or primary key constraint on columns "medsparoom_id", "appointment_id" */
  MedspaRoomsAppointmentMedsparoomIdAppointmen_66576167Uniq = "medspa_rooms_appointment_medsparoom_id_appointmen_66576167_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspaRoomsAppointmentsPkey = "medspa_rooms_appointments_pkey",
}

/** input type for incrementing numeric columns in table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsIncInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medsparoomId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsInsertInput = {
  appointment?: InputMaybe<SchedulingAppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaRoom?: InputMaybe<ResourcesMedspaRoomObjRelInsertInput>;
  medsparoomId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspaRoomsAppointmentsMaxFields = {
  __typename?: "MedspaRoomsAppointmentsMaxFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medsparoomId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsMaxOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaRoomsAppointmentsMinFields = {
  __typename?: "MedspaRoomsAppointmentsMinFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medsparoomId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsMinOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsMutationResponse = {
  __typename?: "MedspaRoomsAppointmentsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaRoomsAppointments>;
};

/** on_conflict condition type for table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsOnConflict = {
  constraint: MedspaRoomsAppointmentsConstraint;
  updateColumns?: Array<MedspaRoomsAppointmentsUpdateColumn>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

/** Ordering options when selecting data from "medspa_rooms_appointments". */
export type MedspaRoomsAppointmentsOrderBy = {
  appointment?: InputMaybe<SchedulingAppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaRoom?: InputMaybe<ResourcesMedspaRoomOrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_rooms_appointments */
export type MedspaRoomsAppointmentsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_rooms_appointments" */
export enum MedspaRoomsAppointmentsSelectColumn {
  /** column name */
  AppointmentId = "appointmentId",
  /** column name */
  Id = "id",
  /** column name */
  MedsparoomId = "medsparoomId",
}

/** input type for updating data in table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsSetInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medsparoomId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaRoomsAppointmentsStddevFields = {
  __typename?: "MedspaRoomsAppointmentsStddevFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medsparoomId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsStddevOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaRoomsAppointmentsStddevPopFields = {
  __typename?: "MedspaRoomsAppointmentsStddevPopFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medsparoomId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsStddevPopOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaRoomsAppointmentsStddevSampFields = {
  __typename?: "MedspaRoomsAppointmentsStddevSampFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medsparoomId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsStddevSampOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaRoomsAppointmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaRoomsAppointmentsStreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medsparoomId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaRoomsAppointmentsSumFields = {
  __typename?: "MedspaRoomsAppointmentsSumFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medsparoomId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsSumOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_rooms_appointments" */
export enum MedspaRoomsAppointmentsUpdateColumn {
  /** column name */
  AppointmentId = "appointmentId",
  /** column name */
  Id = "id",
  /** column name */
  MedsparoomId = "medsparoomId",
}

export type MedspaRoomsAppointmentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaRoomsAppointmentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaRoomsAppointmentsSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaRoomsAppointmentsBoolExp;
};

/** aggregate varPop on columns */
export type MedspaRoomsAppointmentsVarPopFields = {
  __typename?: "MedspaRoomsAppointmentsVarPopFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medsparoomId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsVarPopOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaRoomsAppointmentsVarSampFields = {
  __typename?: "MedspaRoomsAppointmentsVarSampFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medsparoomId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsVarSampOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaRoomsAppointmentsVarianceFields = {
  __typename?: "MedspaRoomsAppointmentsVarianceFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medsparoomId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_rooms_appointments" */
export type MedspaRoomsAppointmentsVarianceOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medsparoomId?: InputMaybe<OrderBy>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItem = {
  __typename?: "MedspaServiceMenuItem";
  /** Indicates if the service is performed in person or during telehealth call */
  appointmentType: Scalars["String"]["output"];
  /** An array relationship */
  careFormServiceMenuItems: Array<CareFormServiceMenuItems>;
  /** An aggregate relationship */
  careFormServiceMenuItemsAggregate: CareFormServiceMenuItemsAggregate;
  /** A computed field, executes function "client_service_menu_item_gfe_status" */
  clientGfeIndication?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  consentFormServiceMenuItems: Array<ConsentFormServiceMenuItems>;
  /** An aggregate relationship */
  consentFormServiceMenuItemsAggregate: ConsentFormServiceMenuItemsAggregate;
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Description of the service menu item that is displayed while booking */
  description: Scalars["String"]["output"];
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes: Scalars["Int"]["output"];
  /** An array relationship */
  eligibleProviders: Array<MedspaServiceMenuItemProviders>;
  /** An aggregate relationship */
  eligibleProvidersAggregate: MedspaServiceMenuItemProvidersAggregate;
  id: Scalars["bigint"]["output"];
  /** Indicates if the service can be booked online by clients or only added to appointment by providers */
  isOnlineBookingEnabled: Scalars["Boolean"]["output"];
  /** Indicates if the service requires a room to be performed */
  isRoomUsed: Scalars["Boolean"]["output"];
  /** Indicates if the service price is calculated based on the used service products */
  isVariablePricing: Scalars["Boolean"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** A medspa that offers this service */
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaServiceOfferings: Array<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** An aggregate relationship */
  medspaServiceOfferingsAggregate: MedspaServiceMenuItemMedspaServiceOfferingsAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** Name of the service menu item that is displayed while booking */
  name: Scalars["String"]["output"];
  /** An object relationship */
  oldCategory?: Maybe<ServiceCategory>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  oldServiceMenuItemProducts: Array<MedspaServiceMenuItemOldProducts>;
  /** An aggregate relationship */
  oldServiceMenuItemProductsAggregate: MedspaServiceMenuItemOldProductsAggregate;
  /** Base price of the service menu item (in USD) */
  price: Scalars["numeric"]["output"];
  /** Indicates if a service menu item should have at least one connected consent form. */
  requiresConsentForms: Scalars["Boolean"]["output"];
  /** Indicates if a service menu item should have at least one connected intake form (via medspa service offerings). */
  requiresIntakeForms: Scalars["Boolean"]["output"];
  /** Indicates if a service menu item should have at least one connected post care form. */
  requiresPostCareForms: Scalars["Boolean"]["output"];
  /** Indicates if a service menu item should have at least one connected pre care form. */
  requiresPreCareForms: Scalars["Boolean"]["output"];
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate: Scalars["numeric"]["output"];
  /** An array relationship */
  serviceMenuItemLines: Array<ServiceMenuItemLine>;
  /** An aggregate relationship */
  serviceMenuItemLinesAggregate: ServiceMenuItemLineAggregate;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemCareFormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemCareFormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemClientGfeIndicationArgs = {
  args: ClientGfeIndication_Medspa_Service_Menu_Item_Args;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemConsentFormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemConsentFormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemEligibleProvidersArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemProvidersOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemEligibleProvidersAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemProvidersOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsOrderBy>
  >;
  where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemMedspaServiceOfferingsAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsOrderBy>
  >;
  where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemOldServiceMenuItemProductsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOldProductsOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemOldServiceMenuItemProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOldProductsOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemServiceMenuItemLinesArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

/** Medspa informal services that Client can book. Mostly used for marketing, booking, invoicing.They must be linked to the official offerings under the hood (to represent their legal side) */
export type MedspaServiceMenuItemServiceMenuItemLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

/** aggregated selection of "medspa_service_menu_item" */
export type MedspaServiceMenuItemAggregate = {
  __typename?: "MedspaServiceMenuItemAggregate";
  aggregate?: Maybe<MedspaServiceMenuItemAggregateFields>;
  nodes: Array<MedspaServiceMenuItem>;
};

export type MedspaServiceMenuItemAggregateBoolExp = {
  bool_and?: InputMaybe<MedspaServiceMenuItemAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MedspaServiceMenuItemAggregateBoolExpBool_Or>;
  count?: InputMaybe<MedspaServiceMenuItemAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_menu_item" */
export type MedspaServiceMenuItemAggregateFields = {
  __typename?: "MedspaServiceMenuItemAggregateFields";
  avg?: Maybe<MedspaServiceMenuItemAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceMenuItemMaxFields>;
  min?: Maybe<MedspaServiceMenuItemMinFields>;
  stddev?: Maybe<MedspaServiceMenuItemStddevFields>;
  stddevPop?: Maybe<MedspaServiceMenuItemStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceMenuItemStddevSampFields>;
  sum?: Maybe<MedspaServiceMenuItemSumFields>;
  varPop?: Maybe<MedspaServiceMenuItemVarPopFields>;
  varSamp?: Maybe<MedspaServiceMenuItemVarSampFields>;
  variance?: Maybe<MedspaServiceMenuItemVarianceFields>;
};

/** aggregate fields of "medspa_service_menu_item" */
export type MedspaServiceMenuItemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceMenuItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceMenuItemMaxOrderBy>;
  min?: InputMaybe<MedspaServiceMenuItemMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceMenuItemStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceMenuItemStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceMenuItemStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceMenuItemSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceMenuItemVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceMenuItemVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceMenuItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_menu_item" */
export type MedspaServiceMenuItemArrRelInsertInput = {
  data: Array<MedspaServiceMenuItemInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceMenuItemOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceMenuItemAvgFields = {
  __typename?: "MedspaServiceMenuItemAvgFields";
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemAvgOrderBy = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_menu_item". All fields are combined with a logical 'AND'. */
export type MedspaServiceMenuItemBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceMenuItemBoolExp>>;
  _not?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceMenuItemBoolExp>>;
  appointmentType?: InputMaybe<StringComparisonExp>;
  careFormServiceMenuItems?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
  careFormServiceMenuItemsAggregate?: InputMaybe<CareFormServiceMenuItemsAggregateBoolExp>;
  consentFormServiceMenuItems?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
  consentFormServiceMenuItemsAggregate?: InputMaybe<ConsentFormServiceMenuItemsAggregateBoolExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  durationInMinutes?: InputMaybe<IntComparisonExp>;
  eligibleProviders?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
  eligibleProvidersAggregate?: InputMaybe<MedspaServiceMenuItemProvidersAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isOnlineBookingEnabled?: InputMaybe<BooleanComparisonExp>;
  isRoomUsed?: InputMaybe<BooleanComparisonExp>;
  isVariablePricing?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaServiceOfferings?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
  medspaServiceOfferingsAggregate?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  oldCategory?: InputMaybe<ServiceCategoryBoolExp>;
  oldCategoryId?: InputMaybe<BigintComparisonExp>;
  oldServiceMenuItemProducts?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
  oldServiceMenuItemProductsAggregate?: InputMaybe<MedspaServiceMenuItemOldProductsAggregateBoolExp>;
  price?: InputMaybe<NumericComparisonExp>;
  requiresConsentForms?: InputMaybe<BooleanComparisonExp>;
  requiresIntakeForms?: InputMaybe<BooleanComparisonExp>;
  requiresPostCareForms?: InputMaybe<BooleanComparisonExp>;
  requiresPreCareForms?: InputMaybe<BooleanComparisonExp>;
  salesTaxRate?: InputMaybe<NumericComparisonExp>;
  serviceMenuItemLines?: InputMaybe<ServiceMenuItemLineBoolExp>;
  serviceMenuItemLinesAggregate?: InputMaybe<ServiceMenuItemLineAggregateBoolExp>;
};

/** unique or primary key constraints on table "medspa_service_menu_item" */
export enum MedspaServiceMenuItemConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspaServiceMenuItemPkey = "medspa_service_menu_item_pkey",
}

/** input type for incrementing numeric columns in table "medspa_service_menu_item" */
export type MedspaServiceMenuItemIncInput = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "medspa_service_menu_item" */
export type MedspaServiceMenuItemInsertInput = {
  /** Indicates if the service is performed in person or during telehealth call */
  appointmentType?: InputMaybe<Scalars["String"]["input"]>;
  careFormServiceMenuItems?: InputMaybe<CareFormServiceMenuItemsArrRelInsertInput>;
  consentFormServiceMenuItems?: InputMaybe<ConsentFormServiceMenuItemsArrRelInsertInput>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Description of the service menu item that is displayed while booking */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  eligibleProviders?: InputMaybe<MedspaServiceMenuItemProvidersArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service can be booked online by clients or only added to appointment by providers */
  isOnlineBookingEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if the service requires a room to be performed */
  isRoomUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if the service price is calculated based on the used service products */
  isVariablePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOfferings?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service menu item that is displayed while booking */
  name?: InputMaybe<Scalars["String"]["input"]>;
  oldCategory?: InputMaybe<ServiceCategoryObjRelInsertInput>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  oldServiceMenuItemProducts?: InputMaybe<MedspaServiceMenuItemOldProductsArrRelInsertInput>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Indicates if a service menu item should have at least one connected consent form. */
  requiresConsentForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected intake form (via medspa service offerings). */
  requiresIntakeForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected post care form. */
  requiresPostCareForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected pre care form. */
  requiresPreCareForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<Scalars["numeric"]["input"]>;
  serviceMenuItemLines?: InputMaybe<ServiceMenuItemLineArrRelInsertInput>;
};

/** aggregate max on columns */
export type MedspaServiceMenuItemMaxFields = {
  __typename?: "MedspaServiceMenuItemMaxFields";
  /** Indicates if the service is performed in person or during telehealth call */
  appointmentType?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "client_service_menu_item_gfe_status" */
  clientGfeIndication?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Description of the service menu item that is displayed while booking */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the service menu item that is displayed while booking */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["bigint"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["numeric"]["output"]>;
};

/** aggregate max on columns */
export type MedspaServiceMenuItemMaxFieldsClientGfeIndicationArgs = {
  args: ClientGfeIndication_Medspa_Service_Menu_Item_Args;
};

/** order by max() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemMaxOrderBy = {
  /** Indicates if the service is performed in person or during telehealth call */
  appointmentType?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Description of the service menu item that is displayed while booking */
  description?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Name of the service menu item that is displayed while booking */
  name?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferings = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferings";
  id: Scalars["bigint"]["output"];
  medspaservicemenuitemId: Scalars["bigint"]["output"];
  medspaserviceofferingId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceMenuItem: MedspaServiceMenuItem;
  /** An object relationship */
  serviceOffering: MedspaServiceOffering;
};

/** aggregated selection of "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsAggregate = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsAggregate";
  aggregate?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsAggregateFields>;
  nodes: Array<MedspaServiceMenuItemMedspaServiceOfferings>;
};

export type MedspaServiceMenuItemMedspaServiceOfferingsAggregateBoolExp = {
  count?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsAggregateFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsAggregateFields";
  avg?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsMaxFields>;
  min?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsMinFields>;
  stddev?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsStddevFields>;
  stddevPop?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsStddevSampFields>;
  sum?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsSumFields>;
  varPop?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsVarPopFields>;
  varSamp?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsVarSampFields>;
  variance?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsVarianceFields>;
};

/** aggregate fields of "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsAggregateFieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
    >;
    distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

/** order by aggregate values of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsMaxOrderBy>;
  min?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsArrRelInsertInput = {
  data: Array<MedspaServiceMenuItemMedspaServiceOfferingsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsAvgFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_menu_item_medspa_service_offerings". All fields are combined with a logical 'AND'. */
export type MedspaServiceMenuItemMedspaServiceOfferingsBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>>;
  _not?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaservicemenuitemId?: InputMaybe<BigintComparisonExp>;
  medspaserviceofferingId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  serviceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

/** unique or primary key constraints on table "medspa_service_menu_item_medspa_service_offerings" */
export enum MedspaServiceMenuItemMedspaServiceOfferingsConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspaServiceMenuItemMedspaServiceOfferingsPkey = "medspa_service_menu_item_medspa_service_offerings_pkey",
  /** unique or primary key constraint on columns "medspaservicemenuitem_id", "medspaserviceoffering_id" */
  MedspaServiceMenuItemMedspaservicemenuitemIdF641c725Uniq = "medspa_service_menu_item_medspaservicemenuitem_id_f641c725_uniq",
}

/** input type for incrementing numeric columns in table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
  serviceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
};

/** aggregate max on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsMaxFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsMinFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsMutationResponse = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceMenuItemMedspaServiceOfferings>;
};

/** on_conflict condition type for table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsOnConflict = {
  constraint: MedspaServiceMenuItemMedspaServiceOfferingsConstraint;
  updateColumns?: Array<MedspaServiceMenuItemMedspaServiceOfferingsUpdateColumn>;
  where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_menu_item_medspa_service_offerings". */
export type MedspaServiceMenuItemMedspaServiceOfferingsOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
  serviceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
};

/** primary key columns input for table: medspa_service_menu_item_medspa_service_offerings */
export type MedspaServiceMenuItemMedspaServiceOfferingsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_service_menu_item_medspa_service_offerings" */
export enum MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
  /** column name */
  MedspaserviceofferingId = "medspaserviceofferingId",
}

/** input type for updating data in table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsStddevFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsStddevPopFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsStddevSampFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceMenuItemMedspaServiceOfferingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceMenuItemMedspaServiceOfferingsStreamCursorValueInput =
  {
    id?: InputMaybe<Scalars["bigint"]["input"]>;
    medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
    medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  };

/** aggregate sum on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsSumFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_menu_item_medspa_service_offerings" */
export enum MedspaServiceMenuItemMedspaServiceOfferingsUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
  /** column name */
  MedspaserviceofferingId = "medspaserviceofferingId",
}

export type MedspaServiceMenuItemMedspaServiceOfferingsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceMenuItemMedspaServiceOfferingsBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsVarPopFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsVarSampFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceMenuItemMedspaServiceOfferingsVarianceFields = {
  __typename?: "MedspaServiceMenuItemMedspaServiceOfferingsVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_menu_item_medspa_service_offerings" */
export type MedspaServiceMenuItemMedspaServiceOfferingsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceMenuItemMinFields = {
  __typename?: "MedspaServiceMenuItemMinFields";
  /** Indicates if the service is performed in person or during telehealth call */
  appointmentType?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "client_service_menu_item_gfe_status" */
  clientGfeIndication?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Description of the service menu item that is displayed while booking */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the service menu item that is displayed while booking */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["bigint"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["numeric"]["output"]>;
};

/** aggregate min on columns */
export type MedspaServiceMenuItemMinFieldsClientGfeIndicationArgs = {
  args: ClientGfeIndication_Medspa_Service_Menu_Item_Args;
};

/** order by min() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemMinOrderBy = {
  /** Indicates if the service is performed in person or during telehealth call */
  appointmentType?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Description of the service menu item that is displayed while booking */
  description?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Name of the service menu item that is displayed while booking */
  name?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_menu_item" */
export type MedspaServiceMenuItemMutationResponse = {
  __typename?: "MedspaServiceMenuItemMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceMenuItem>;
};

/** input type for inserting object relation for remote table "medspa_service_menu_item" */
export type MedspaServiceMenuItemObjRelInsertInput = {
  data: MedspaServiceMenuItemInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceMenuItemOnConflict>;
};

/** columns and relationships of "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProducts = {
  __typename?: "MedspaServiceMenuItemOldProducts";
  id: Scalars["bigint"]["output"];
  medspaservicemenuitemId: Scalars["bigint"]["output"];
  /** An object relationship */
  product: Product;
  productId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceMenuItem: MedspaServiceMenuItem;
};

/** aggregated selection of "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsAggregate = {
  __typename?: "MedspaServiceMenuItemOldProductsAggregate";
  aggregate?: Maybe<MedspaServiceMenuItemOldProductsAggregateFields>;
  nodes: Array<MedspaServiceMenuItemOldProducts>;
};

export type MedspaServiceMenuItemOldProductsAggregateBoolExp = {
  count?: InputMaybe<MedspaServiceMenuItemOldProductsAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsAggregateFields = {
  __typename?: "MedspaServiceMenuItemOldProductsAggregateFields";
  avg?: Maybe<MedspaServiceMenuItemOldProductsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceMenuItemOldProductsMaxFields>;
  min?: Maybe<MedspaServiceMenuItemOldProductsMinFields>;
  stddev?: Maybe<MedspaServiceMenuItemOldProductsStddevFields>;
  stddevPop?: Maybe<MedspaServiceMenuItemOldProductsStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceMenuItemOldProductsStddevSampFields>;
  sum?: Maybe<MedspaServiceMenuItemOldProductsSumFields>;
  varPop?: Maybe<MedspaServiceMenuItemOldProductsVarPopFields>;
  varSamp?: Maybe<MedspaServiceMenuItemOldProductsVarSampFields>;
  variance?: Maybe<MedspaServiceMenuItemOldProductsVarianceFields>;
};

/** aggregate fields of "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceMenuItemOldProductsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceMenuItemOldProductsMaxOrderBy>;
  min?: InputMaybe<MedspaServiceMenuItemOldProductsMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceMenuItemOldProductsStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceMenuItemOldProductsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceMenuItemOldProductsStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceMenuItemOldProductsSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceMenuItemOldProductsVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceMenuItemOldProductsVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceMenuItemOldProductsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsArrRelInsertInput = {
  data: Array<MedspaServiceMenuItemOldProductsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceMenuItemOldProductsOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceMenuItemOldProductsAvgFields = {
  __typename?: "MedspaServiceMenuItemOldProductsAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_menu_item_old_products". All fields are combined with a logical 'AND'. */
export type MedspaServiceMenuItemOldProductsBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceMenuItemOldProductsBoolExp>>;
  _not?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceMenuItemOldProductsBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaservicemenuitemId?: InputMaybe<BigintComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** unique or primary key constraints on table "medspa_service_menu_item_old_products" */
export enum MedspaServiceMenuItemOldProductsConstraint {
  /** unique or primary key constraint on columns "medspaservicemenuitem_id", "product_id" */
  MedspaServiceMenuItemMedspaservicemenuitemIdE8161a82Uniq = "medspa_service_menu_item_medspaservicemenuitem_id_e8161a82_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspaServiceMenuItemOldProductsPkey = "medspa_service_menu_item_old_products_pkey",
}

/** input type for incrementing numeric columns in table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
};

/** aggregate max on columns */
export type MedspaServiceMenuItemOldProductsMaxFields = {
  __typename?: "MedspaServiceMenuItemOldProductsMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceMenuItemOldProductsMinFields = {
  __typename?: "MedspaServiceMenuItemOldProductsMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsMutationResponse = {
  __typename?: "MedspaServiceMenuItemOldProductsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceMenuItemOldProducts>;
};

/** on_conflict condition type for table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsOnConflict = {
  constraint: MedspaServiceMenuItemOldProductsConstraint;
  updateColumns?: Array<MedspaServiceMenuItemOldProductsUpdateColumn>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_menu_item_old_products". */
export type MedspaServiceMenuItemOldProductsOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
};

/** primary key columns input for table: medspa_service_menu_item_old_products */
export type MedspaServiceMenuItemOldProductsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_service_menu_item_old_products" */
export enum MedspaServiceMenuItemOldProductsSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
  /** column name */
  ProductId = "productId",
}

/** input type for updating data in table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceMenuItemOldProductsStddevFields = {
  __typename?: "MedspaServiceMenuItemOldProductsStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceMenuItemOldProductsStddevPopFields = {
  __typename?: "MedspaServiceMenuItemOldProductsStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceMenuItemOldProductsStddevSampFields = {
  __typename?: "MedspaServiceMenuItemOldProductsStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceMenuItemOldProductsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceMenuItemOldProductsStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaServiceMenuItemOldProductsSumFields = {
  __typename?: "MedspaServiceMenuItemOldProductsSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_menu_item_old_products" */
export enum MedspaServiceMenuItemOldProductsUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
  /** column name */
  ProductId = "productId",
}

export type MedspaServiceMenuItemOldProductsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceMenuItemOldProductsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceMenuItemOldProductsSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceMenuItemOldProductsBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceMenuItemOldProductsVarPopFields = {
  __typename?: "MedspaServiceMenuItemOldProductsVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceMenuItemOldProductsVarSampFields = {
  __typename?: "MedspaServiceMenuItemOldProductsVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceMenuItemOldProductsVarianceFields = {
  __typename?: "MedspaServiceMenuItemOldProductsVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_menu_item_old_products" */
export type MedspaServiceMenuItemOldProductsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** on_conflict condition type for table "medspa_service_menu_item" */
export type MedspaServiceMenuItemOnConflict = {
  constraint: MedspaServiceMenuItemConstraint;
  updateColumns?: Array<MedspaServiceMenuItemUpdateColumn>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_menu_item". */
export type MedspaServiceMenuItemOrderBy = {
  appointmentType?: InputMaybe<OrderBy>;
  careFormServiceMenuItemsAggregate?: InputMaybe<CareFormServiceMenuItemsAggregateOrderBy>;
  consentFormServiceMenuItemsAggregate?: InputMaybe<ConsentFormServiceMenuItemsAggregateOrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  durationInMinutes?: InputMaybe<OrderBy>;
  eligibleProvidersAggregate?: InputMaybe<MedspaServiceMenuItemProvidersAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isOnlineBookingEnabled?: InputMaybe<OrderBy>;
  isRoomUsed?: InputMaybe<OrderBy>;
  isVariablePricing?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaServiceOfferingsAggregate?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  oldCategory?: InputMaybe<ServiceCategoryOrderBy>;
  oldCategoryId?: InputMaybe<OrderBy>;
  oldServiceMenuItemProductsAggregate?: InputMaybe<MedspaServiceMenuItemOldProductsAggregateOrderBy>;
  price?: InputMaybe<OrderBy>;
  requiresConsentForms?: InputMaybe<OrderBy>;
  requiresIntakeForms?: InputMaybe<OrderBy>;
  requiresPostCareForms?: InputMaybe<OrderBy>;
  requiresPreCareForms?: InputMaybe<OrderBy>;
  salesTaxRate?: InputMaybe<OrderBy>;
  serviceMenuItemLinesAggregate?: InputMaybe<ServiceMenuItemLineAggregateOrderBy>;
};

/** primary key columns input for table: medspa_service_menu_item */
export type MedspaServiceMenuItemPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** columns and relationships of "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProviders = {
  __typename?: "MedspaServiceMenuItemProviders";
  id: Scalars["bigint"]["output"];
  medspaservicemenuitemId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceMenuItem: MedspaServiceMenuItem;
  /** An object relationship */
  userMedspa: UserMedspa;
  usermedspaId: Scalars["bigint"]["output"];
};

/** aggregated selection of "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersAggregate = {
  __typename?: "MedspaServiceMenuItemProvidersAggregate";
  aggregate?: Maybe<MedspaServiceMenuItemProvidersAggregateFields>;
  nodes: Array<MedspaServiceMenuItemProviders>;
};

export type MedspaServiceMenuItemProvidersAggregateBoolExp = {
  count?: InputMaybe<MedspaServiceMenuItemProvidersAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersAggregateFields = {
  __typename?: "MedspaServiceMenuItemProvidersAggregateFields";
  avg?: Maybe<MedspaServiceMenuItemProvidersAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceMenuItemProvidersMaxFields>;
  min?: Maybe<MedspaServiceMenuItemProvidersMinFields>;
  stddev?: Maybe<MedspaServiceMenuItemProvidersStddevFields>;
  stddevPop?: Maybe<MedspaServiceMenuItemProvidersStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceMenuItemProvidersStddevSampFields>;
  sum?: Maybe<MedspaServiceMenuItemProvidersSumFields>;
  varPop?: Maybe<MedspaServiceMenuItemProvidersVarPopFields>;
  varSamp?: Maybe<MedspaServiceMenuItemProvidersVarSampFields>;
  variance?: Maybe<MedspaServiceMenuItemProvidersVarianceFields>;
};

/** aggregate fields of "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceMenuItemProvidersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceMenuItemProvidersMaxOrderBy>;
  min?: InputMaybe<MedspaServiceMenuItemProvidersMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceMenuItemProvidersStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceMenuItemProvidersStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceMenuItemProvidersStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceMenuItemProvidersSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceMenuItemProvidersVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceMenuItemProvidersVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceMenuItemProvidersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersArrRelInsertInput = {
  data: Array<MedspaServiceMenuItemProvidersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceMenuItemProvidersOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceMenuItemProvidersAvgFields = {
  __typename?: "MedspaServiceMenuItemProvidersAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_menu_item_providers". All fields are combined with a logical 'AND'. */
export type MedspaServiceMenuItemProvidersBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceMenuItemProvidersBoolExp>>;
  _not?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceMenuItemProvidersBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaservicemenuitemId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  userMedspa?: InputMaybe<UserMedspaBoolExp>;
  usermedspaId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspa_service_menu_item_providers" */
export enum MedspaServiceMenuItemProvidersConstraint {
  /** unique or primary key constraint on columns "medspaservicemenuitem_id", "usermedspa_id" */
  MedspaServiceMenuItemMedspaservicemenuitemId_74c1ca64Uniq = "medspa_service_menu_item_medspaservicemenuitem_id_74c1ca64_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspaServiceMenuItemProvidersPkey = "medspa_service_menu_item_providers_pkey",
}

/** input type for incrementing numeric columns in table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
  userMedspa?: InputMaybe<UserMedspaObjRelInsertInput>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspaServiceMenuItemProvidersMaxFields = {
  __typename?: "MedspaServiceMenuItemProvidersMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceMenuItemProvidersMinFields = {
  __typename?: "MedspaServiceMenuItemProvidersMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersMutationResponse = {
  __typename?: "MedspaServiceMenuItemProvidersMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceMenuItemProviders>;
};

/** on_conflict condition type for table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersOnConflict = {
  constraint: MedspaServiceMenuItemProvidersConstraint;
  updateColumns?: Array<MedspaServiceMenuItemProvidersUpdateColumn>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_menu_item_providers". */
export type MedspaServiceMenuItemProvidersOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
  userMedspa?: InputMaybe<UserMedspaOrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_service_menu_item_providers */
export type MedspaServiceMenuItemProvidersPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_service_menu_item_providers" */
export enum MedspaServiceMenuItemProvidersSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
  /** column name */
  UsermedspaId = "usermedspaId",
}

/** input type for updating data in table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceMenuItemProvidersStddevFields = {
  __typename?: "MedspaServiceMenuItemProvidersStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceMenuItemProvidersStddevPopFields = {
  __typename?: "MedspaServiceMenuItemProvidersStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceMenuItemProvidersStddevSampFields = {
  __typename?: "MedspaServiceMenuItemProvidersStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceMenuItemProvidersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceMenuItemProvidersStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaServiceMenuItemProvidersSumFields = {
  __typename?: "MedspaServiceMenuItemProvidersSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_menu_item_providers" */
export enum MedspaServiceMenuItemProvidersUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
  /** column name */
  UsermedspaId = "usermedspaId",
}

export type MedspaServiceMenuItemProvidersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceMenuItemProvidersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceMenuItemProvidersSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceMenuItemProvidersBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceMenuItemProvidersVarPopFields = {
  __typename?: "MedspaServiceMenuItemProvidersVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceMenuItemProvidersVarSampFields = {
  __typename?: "MedspaServiceMenuItemProvidersVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceMenuItemProvidersVarianceFields = {
  __typename?: "MedspaServiceMenuItemProvidersVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_menu_item_providers" */
export type MedspaServiceMenuItemProvidersVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** select columns of table "medspa_service_menu_item" */
export enum MedspaServiceMenuItemSelectColumn {
  /** column name */
  AppointmentType = "appointmentType",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  Description = "description",
  /** column name */
  DurationInMinutes = "durationInMinutes",
  /** column name */
  Id = "id",
  /** column name */
  IsOnlineBookingEnabled = "isOnlineBookingEnabled",
  /** column name */
  IsRoomUsed = "isRoomUsed",
  /** column name */
  IsVariablePricing = "isVariablePricing",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  OldCategoryId = "oldCategoryId",
  /** column name */
  Price = "price",
  /** column name */
  RequiresConsentForms = "requiresConsentForms",
  /** column name */
  RequiresIntakeForms = "requiresIntakeForms",
  /** column name */
  RequiresPostCareForms = "requiresPostCareForms",
  /** column name */
  RequiresPreCareForms = "requiresPreCareForms",
  /** column name */
  SalesTaxRate = "salesTaxRate",
}

/** select "medspaServiceMenuItemAggregateBoolExpBool_andArgumentsColumns" columns of table "medspa_service_menu_item" */
export enum MedspaServiceMenuItemSelectColumnMedspaServiceMenuItemAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsOnlineBookingEnabled = "isOnlineBookingEnabled",
  /** column name */
  IsRoomUsed = "isRoomUsed",
  /** column name */
  IsVariablePricing = "isVariablePricing",
  /** column name */
  RequiresConsentForms = "requiresConsentForms",
  /** column name */
  RequiresIntakeForms = "requiresIntakeForms",
  /** column name */
  RequiresPostCareForms = "requiresPostCareForms",
  /** column name */
  RequiresPreCareForms = "requiresPreCareForms",
}

/** select "medspaServiceMenuItemAggregateBoolExpBool_orArgumentsColumns" columns of table "medspa_service_menu_item" */
export enum MedspaServiceMenuItemSelectColumnMedspaServiceMenuItemAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsOnlineBookingEnabled = "isOnlineBookingEnabled",
  /** column name */
  IsRoomUsed = "isRoomUsed",
  /** column name */
  IsVariablePricing = "isVariablePricing",
  /** column name */
  RequiresConsentForms = "requiresConsentForms",
  /** column name */
  RequiresIntakeForms = "requiresIntakeForms",
  /** column name */
  RequiresPostCareForms = "requiresPostCareForms",
  /** column name */
  RequiresPreCareForms = "requiresPreCareForms",
}

/** input type for updating data in table "medspa_service_menu_item" */
export type MedspaServiceMenuItemSetInput = {
  /** Indicates if the service is performed in person or during telehealth call */
  appointmentType?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Description of the service menu item that is displayed while booking */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service can be booked online by clients or only added to appointment by providers */
  isOnlineBookingEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if the service requires a room to be performed */
  isRoomUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if the service price is calculated based on the used service products */
  isVariablePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service menu item that is displayed while booking */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Indicates if a service menu item should have at least one connected consent form. */
  requiresConsentForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected intake form (via medspa service offerings). */
  requiresIntakeForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected post care form. */
  requiresPostCareForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected pre care form. */
  requiresPreCareForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceMenuItemStddevFields = {
  __typename?: "MedspaServiceMenuItemStddevFields";
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemStddevOrderBy = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceMenuItemStddevPopFields = {
  __typename?: "MedspaServiceMenuItemStddevPopFields";
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemStddevPopOrderBy = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceMenuItemStddevSampFields = {
  __typename?: "MedspaServiceMenuItemStddevSampFields";
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemStddevSampOrderBy = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_menu_item" */
export type MedspaServiceMenuItemStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceMenuItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceMenuItemStreamCursorValueInput = {
  /** Indicates if the service is performed in person or during telehealth call */
  appointmentType?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Description of the service menu item that is displayed while booking */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service can be booked online by clients or only added to appointment by providers */
  isOnlineBookingEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if the service requires a room to be performed */
  isRoomUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if the service price is calculated based on the used service products */
  isVariablePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service menu item that is displayed while booking */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Indicates if a service menu item should have at least one connected consent form. */
  requiresConsentForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected intake form (via medspa service offerings). */
  requiresIntakeForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected post care form. */
  requiresPostCareForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if a service menu item should have at least one connected pre care form. */
  requiresPreCareForms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaServiceMenuItemSumFields = {
  __typename?: "MedspaServiceMenuItemSumFields";
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["bigint"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemSumOrderBy = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_menu_item" */
export enum MedspaServiceMenuItemUpdateColumn {
  /** column name */
  AppointmentType = "appointmentType",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  Description = "description",
  /** column name */
  DurationInMinutes = "durationInMinutes",
  /** column name */
  Id = "id",
  /** column name */
  IsOnlineBookingEnabled = "isOnlineBookingEnabled",
  /** column name */
  IsRoomUsed = "isRoomUsed",
  /** column name */
  IsVariablePricing = "isVariablePricing",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  OldCategoryId = "oldCategoryId",
  /** column name */
  Price = "price",
  /** column name */
  RequiresConsentForms = "requiresConsentForms",
  /** column name */
  RequiresIntakeForms = "requiresIntakeForms",
  /** column name */
  RequiresPostCareForms = "requiresPostCareForms",
  /** column name */
  RequiresPreCareForms = "requiresPreCareForms",
  /** column name */
  SalesTaxRate = "salesTaxRate",
}

export type MedspaServiceMenuItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceMenuItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceMenuItemSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceMenuItemBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceMenuItemVarPopFields = {
  __typename?: "MedspaServiceMenuItemVarPopFields";
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemVarPopOrderBy = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceMenuItemVarSampFields = {
  __typename?: "MedspaServiceMenuItemVarSampFields";
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemVarSampOrderBy = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceMenuItemVarianceFields = {
  __typename?: "MedspaServiceMenuItemVarianceFields";
  /** UserMedSpa who deleted the service menu item */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers this service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Custom category of the service menu item */
  oldCategoryId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service menu item (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_menu_item" */
export type MedspaServiceMenuItemVarianceOrderBy = {
  /** UserMedSpa who deleted the service menu item */
  deletedById?: InputMaybe<OrderBy>;
  /** Determines how long the service is expected to take (in minutes) */
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers this service */
  medspaId?: InputMaybe<OrderBy>;
  /** Custom category of the service menu item */
  oldCategoryId?: InputMaybe<OrderBy>;
  /** Base price of the service menu item (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Sales tax rate for this service and its service products (in %) */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOffering = {
  __typename?: "MedspaServiceOffering";
  /** An object relationship */
  complianceRequirements?: Maybe<MedspaServiceOfferingComplianceRequirements>;
  created: Scalars["timestamptz"]["output"];
  /** The GFE review method can only be set by the provider and takes priority over compliance. To include default compliance rules, use get_medspa_offering_gfe_method SQL fn. */
  defaultGfeReviewType?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  forms: Array<FormsFormMedspaServiceOfferings>;
  /** An aggregate relationship */
  formsAggregate: FormsFormMedspaServiceOfferingsAggregate;
  /** A computed field, executes function "get_medspa_offering_gfe_method" */
  gfeMethod?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** Indicates whether the service is currently offered (can be changed to false at any time) */
  isEnabled: Scalars["Boolean"]["output"];
  /** A computed field, executes function "is_offering_medical_service" */
  isMedicalService?: Maybe<Scalars["Boolean"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  /** A medspa that offers the service */
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaServiceMenuItems: Array<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** An aggregate relationship */
  medspaServiceMenuItemsAggregate: MedspaServiceMenuItemMedspaServiceOfferingsAggregate;
  /** An array relationship */
  medspaServiceOfferingForms: Array<FormsFormMedspaServiceOfferings>;
  /** An aggregate relationship */
  medspaServiceOfferingFormsAggregate: FormsFormMedspaServiceOfferingsAggregate;
  /** An array relationship */
  medspaServiceProducts: Array<MedspaServiceProduct>;
  /** An aggregate relationship */
  medspaServiceProductsAggregate: MedspaServiceProductAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** An array relationship */
  providerNotRequireDocuments: Array<ProviderNotRequireDocuments>;
  /** An aggregate relationship */
  providerNotRequireDocumentsAggregate: ProviderNotRequireDocumentsAggregate;
  /** An object relationship */
  serviceType: ServiceType;
  /** A formal service offered by the medspa */
  serviceTypeId: Scalars["bigint"]["output"];
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingFormsArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingMedspaServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsOrderBy>
  >;
  where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingMedspaServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsOrderBy>
  >;
  where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingMedspaServiceOfferingFormsArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingMedspaServiceOfferingFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingMedspaServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingMedspaServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingProviderNotRequireDocumentsArgs = {
  distinctOn?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderNotRequireDocumentsOrderBy>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

/** List of formal services offered by a MedSpa. */
export type MedspaServiceOfferingProviderNotRequireDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderNotRequireDocumentsOrderBy>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

/** aggregated selection of "medspa_service_offering" */
export type MedspaServiceOfferingAggregate = {
  __typename?: "MedspaServiceOfferingAggregate";
  aggregate?: Maybe<MedspaServiceOfferingAggregateFields>;
  nodes: Array<MedspaServiceOffering>;
};

export type MedspaServiceOfferingAggregateBoolExp = {
  bool_and?: InputMaybe<MedspaServiceOfferingAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MedspaServiceOfferingAggregateBoolExpBool_Or>;
  count?: InputMaybe<MedspaServiceOfferingAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_offering" */
export type MedspaServiceOfferingAggregateFields = {
  __typename?: "MedspaServiceOfferingAggregateFields";
  avg?: Maybe<MedspaServiceOfferingAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceOfferingMaxFields>;
  min?: Maybe<MedspaServiceOfferingMinFields>;
  stddev?: Maybe<MedspaServiceOfferingStddevFields>;
  stddevPop?: Maybe<MedspaServiceOfferingStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceOfferingStddevSampFields>;
  sum?: Maybe<MedspaServiceOfferingSumFields>;
  varPop?: Maybe<MedspaServiceOfferingVarPopFields>;
  varSamp?: Maybe<MedspaServiceOfferingVarSampFields>;
  variance?: Maybe<MedspaServiceOfferingVarianceFields>;
};

/** aggregate fields of "medspa_service_offering" */
export type MedspaServiceOfferingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_service_offering" */
export type MedspaServiceOfferingAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceOfferingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceOfferingMaxOrderBy>;
  min?: InputMaybe<MedspaServiceOfferingMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceOfferingStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceOfferingStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceOfferingStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceOfferingSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceOfferingVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceOfferingVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceOfferingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_offering" */
export type MedspaServiceOfferingArrRelInsertInput = {
  data: Array<MedspaServiceOfferingInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceOfferingOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceOfferingAvgFields = {
  __typename?: "MedspaServiceOfferingAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_offering". All fields are combined with a logical 'AND'. */
export type MedspaServiceOfferingBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceOfferingBoolExp>>;
  _not?: InputMaybe<MedspaServiceOfferingBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceOfferingBoolExp>>;
  complianceRequirements?: InputMaybe<MedspaServiceOfferingComplianceRequirementsBoolExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  defaultGfeReviewType?: InputMaybe<StringComparisonExp>;
  forms?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
  formsAggregate?: InputMaybe<FormsFormMedspaServiceOfferingsAggregateBoolExp>;
  gfeMethod?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isEnabled?: InputMaybe<BooleanComparisonExp>;
  isMedicalService?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaServiceMenuItems?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
  medspaServiceMenuItemsAggregate?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsAggregateBoolExp>;
  medspaServiceOfferingForms?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
  medspaServiceOfferingFormsAggregate?: InputMaybe<FormsFormMedspaServiceOfferingsAggregateBoolExp>;
  medspaServiceProducts?: InputMaybe<MedspaServiceProductBoolExp>;
  medspaServiceProductsAggregate?: InputMaybe<MedspaServiceProductAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  providerNotRequireDocuments?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
  providerNotRequireDocumentsAggregate?: InputMaybe<ProviderNotRequireDocumentsAggregateBoolExp>;
  serviceType?: InputMaybe<ServiceTypeBoolExp>;
  serviceTypeId?: InputMaybe<BigintComparisonExp>;
};

/** columns and relationships of "medspa_service_offering_compliance_requirements" */
export type MedspaServiceOfferingComplianceRequirements = {
  __typename?: "MedspaServiceOfferingComplianceRequirements";
  complianceRequirementsId?: Maybe<Scalars["bigint"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  requiresChartReview?: Maybe<Scalars["Boolean"]["output"]>;
  requiresConsent?: Maybe<Scalars["Boolean"]["output"]>;
  requiresGfe?: Maybe<Scalars["Boolean"]["output"]>;
  requiresLabWork?: Maybe<Scalars["Boolean"]["output"]>;
  requiresServiceProtocol?: Maybe<Scalars["Boolean"]["output"]>;
  requiresStandingOrder?: Maybe<Scalars["Boolean"]["output"]>;
  requiresSynchronousGfe?: Maybe<Scalars["Boolean"]["output"]>;
};

/** aggregated selection of "medspa_service_offering_compliance_requirements" */
export type MedspaServiceOfferingComplianceRequirementsAggregate = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsAggregate";
  aggregate?: Maybe<MedspaServiceOfferingComplianceRequirementsAggregateFields>;
  nodes: Array<MedspaServiceOfferingComplianceRequirements>;
};

/** aggregate fields of "medspa_service_offering_compliance_requirements" */
export type MedspaServiceOfferingComplianceRequirementsAggregateFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsAggregateFields";
  avg?: Maybe<MedspaServiceOfferingComplianceRequirementsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceOfferingComplianceRequirementsMaxFields>;
  min?: Maybe<MedspaServiceOfferingComplianceRequirementsMinFields>;
  stddev?: Maybe<MedspaServiceOfferingComplianceRequirementsStddevFields>;
  stddevPop?: Maybe<MedspaServiceOfferingComplianceRequirementsStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceOfferingComplianceRequirementsStddevSampFields>;
  sum?: Maybe<MedspaServiceOfferingComplianceRequirementsSumFields>;
  varPop?: Maybe<MedspaServiceOfferingComplianceRequirementsVarPopFields>;
  varSamp?: Maybe<MedspaServiceOfferingComplianceRequirementsVarSampFields>;
  variance?: Maybe<MedspaServiceOfferingComplianceRequirementsVarianceFields>;
};

/** aggregate fields of "medspa_service_offering_compliance_requirements" */
export type MedspaServiceOfferingComplianceRequirementsAggregateFieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<MedspaServiceOfferingComplianceRequirementsSelectColumn>
    >;
    distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

/** aggregate avg on columns */
export type MedspaServiceOfferingComplianceRequirementsAvgFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsAvgFields";
  complianceRequirementsId?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "medspa_service_offering_compliance_requirements". All fields are combined with a logical 'AND'. */
export type MedspaServiceOfferingComplianceRequirementsBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceOfferingComplianceRequirementsBoolExp>>;
  _not?: InputMaybe<MedspaServiceOfferingComplianceRequirementsBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceOfferingComplianceRequirementsBoolExp>>;
  complianceRequirementsId?: InputMaybe<BigintComparisonExp>;
  medspaServiceOfferingId?: InputMaybe<BigintComparisonExp>;
  requiresChartReview?: InputMaybe<BooleanComparisonExp>;
  requiresConsent?: InputMaybe<BooleanComparisonExp>;
  requiresGfe?: InputMaybe<BooleanComparisonExp>;
  requiresLabWork?: InputMaybe<BooleanComparisonExp>;
  requiresServiceProtocol?: InputMaybe<BooleanComparisonExp>;
  requiresStandingOrder?: InputMaybe<BooleanComparisonExp>;
  requiresSynchronousGfe?: InputMaybe<BooleanComparisonExp>;
};

/** input type for inserting data into table "medspa_service_offering_compliance_requirements" */
export type MedspaServiceOfferingComplianceRequirementsInsertInput = {
  complianceRequirementsId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  requiresChartReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresLabWork?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresServiceProtocol?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresStandingOrder?: InputMaybe<Scalars["Boolean"]["input"]>;
  requiresSynchronousGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type MedspaServiceOfferingComplianceRequirementsMaxFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsMaxFields";
  complianceRequirementsId?: Maybe<Scalars["bigint"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregate min on columns */
export type MedspaServiceOfferingComplianceRequirementsMinFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsMinFields";
  complianceRequirementsId?: Maybe<Scalars["bigint"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** input type for inserting object relation for remote table "medspa_service_offering_compliance_requirements" */
export type MedspaServiceOfferingComplianceRequirementsObjRelInsertInput = {
  data: MedspaServiceOfferingComplianceRequirementsInsertInput;
};

/** Ordering options when selecting data from "medspa_service_offering_compliance_requirements". */
export type MedspaServiceOfferingComplianceRequirementsOrderBy = {
  complianceRequirementsId?: InputMaybe<OrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  requiresChartReview?: InputMaybe<OrderBy>;
  requiresConsent?: InputMaybe<OrderBy>;
  requiresGfe?: InputMaybe<OrderBy>;
  requiresLabWork?: InputMaybe<OrderBy>;
  requiresServiceProtocol?: InputMaybe<OrderBy>;
  requiresStandingOrder?: InputMaybe<OrderBy>;
  requiresSynchronousGfe?: InputMaybe<OrderBy>;
};

/** select columns of table "medspa_service_offering_compliance_requirements" */
export enum MedspaServiceOfferingComplianceRequirementsSelectColumn {
  /** column name */
  ComplianceRequirementsId = "complianceRequirementsId",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  RequiresChartReview = "requiresChartReview",
  /** column name */
  RequiresConsent = "requiresConsent",
  /** column name */
  RequiresGfe = "requiresGfe",
  /** column name */
  RequiresLabWork = "requiresLabWork",
  /** column name */
  RequiresServiceProtocol = "requiresServiceProtocol",
  /** column name */
  RequiresStandingOrder = "requiresStandingOrder",
  /** column name */
  RequiresSynchronousGfe = "requiresSynchronousGfe",
}

/** aggregate stddev on columns */
export type MedspaServiceOfferingComplianceRequirementsStddevFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsStddevFields";
  complianceRequirementsId?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceOfferingComplianceRequirementsStddevPopFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsStddevPopFields";
  complianceRequirementsId?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceOfferingComplianceRequirementsStddevSampFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsStddevSampFields";
  complianceRequirementsId?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "medspa_service_offering_compliance_requirements" */
export type MedspaServiceOfferingComplianceRequirementsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceOfferingComplianceRequirementsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceOfferingComplianceRequirementsStreamCursorValueInput =
  {
    complianceRequirementsId?: InputMaybe<Scalars["bigint"]["input"]>;
    medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
    requiresChartReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    requiresConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
    requiresGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
    requiresLabWork?: InputMaybe<Scalars["Boolean"]["input"]>;
    requiresServiceProtocol?: InputMaybe<Scalars["Boolean"]["input"]>;
    requiresStandingOrder?: InputMaybe<Scalars["Boolean"]["input"]>;
    requiresSynchronousGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

/** aggregate sum on columns */
export type MedspaServiceOfferingComplianceRequirementsSumFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsSumFields";
  complianceRequirementsId?: Maybe<Scalars["bigint"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregate varPop on columns */
export type MedspaServiceOfferingComplianceRequirementsVarPopFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsVarPopFields";
  complianceRequirementsId?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type MedspaServiceOfferingComplianceRequirementsVarSampFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsVarSampFields";
  complianceRequirementsId?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type MedspaServiceOfferingComplianceRequirementsVarianceFields = {
  __typename?: "MedspaServiceOfferingComplianceRequirementsVarianceFields";
  complianceRequirementsId?: Maybe<Scalars["Float"]["output"]>;
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
};

/** unique or primary key constraints on table "medspa_service_offering" */
export enum MedspaServiceOfferingConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspaServiceOfferingPkey = "medspa_service_offering_pkey",
  /** unique or primary key constraint on columns "medspa_id", "service_type_id" */
  UniqueMedspaServiceOffering = "unique_medspa_service_offering",
}

/** input type for incrementing numeric columns in table "medspa_service_offering" */
export type MedspaServiceOfferingIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_service_offering" */
export type MedspaServiceOfferingInsertInput = {
  complianceRequirements?: InputMaybe<MedspaServiceOfferingComplianceRequirementsObjRelInsertInput>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The GFE review method can only be set by the provider and takes priority over compliance. To include default compliance rules, use get_medspa_offering_gfe_method SQL fn. */
  defaultGfeReviewType?: InputMaybe<Scalars["String"]["input"]>;
  forms?: InputMaybe<FormsFormMedspaServiceOfferingsArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether the service is currently offered (can be changed to false at any time) */
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceMenuItems?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsArrRelInsertInput>;
  medspaServiceOfferingForms?: InputMaybe<FormsFormMedspaServiceOfferingsArrRelInsertInput>;
  medspaServiceProducts?: InputMaybe<MedspaServiceProductArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  providerNotRequireDocuments?: InputMaybe<ProviderNotRequireDocumentsArrRelInsertInput>;
  serviceType?: InputMaybe<ServiceTypeObjRelInsertInput>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspaServiceOfferingMaxFields = {
  __typename?: "MedspaServiceOfferingMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The GFE review method can only be set by the provider and takes priority over compliance. To include default compliance rules, use get_medspa_offering_gfe_method SQL fn. */
  defaultGfeReviewType?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "get_medspa_offering_gfe_method" */
  gfeMethod?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** The GFE review method can only be set by the provider and takes priority over compliance. To include default compliance rules, use get_medspa_offering_gfe_method SQL fn. */
  defaultGfeReviewType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceOfferingMinFields = {
  __typename?: "MedspaServiceOfferingMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The GFE review method can only be set by the provider and takes priority over compliance. To include default compliance rules, use get_medspa_offering_gfe_method SQL fn. */
  defaultGfeReviewType?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "get_medspa_offering_gfe_method" */
  gfeMethod?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** The GFE review method can only be set by the provider and takes priority over compliance. To include default compliance rules, use get_medspa_offering_gfe_method SQL fn. */
  defaultGfeReviewType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_offering" */
export type MedspaServiceOfferingMutationResponse = {
  __typename?: "MedspaServiceOfferingMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceOffering>;
};

/** columns and relationships of "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplates = {
  __typename?: "MedspaServiceOfferingNoteTemplates";
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaServiceOffering: MedspaServiceOffering;
  medspaserviceofferingId: Scalars["bigint"]["output"];
  /** An object relationship */
  noteTemplate: NoteTemplate;
  notetemplateId: Scalars["bigint"]["output"];
};

/** aggregated selection of "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesAggregate = {
  __typename?: "MedspaServiceOfferingNoteTemplatesAggregate";
  aggregate?: Maybe<MedspaServiceOfferingNoteTemplatesAggregateFields>;
  nodes: Array<MedspaServiceOfferingNoteTemplates>;
};

export type MedspaServiceOfferingNoteTemplatesAggregateBoolExp = {
  count?: InputMaybe<MedspaServiceOfferingNoteTemplatesAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesAggregateFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesAggregateFields";
  avg?: Maybe<MedspaServiceOfferingNoteTemplatesAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceOfferingNoteTemplatesMaxFields>;
  min?: Maybe<MedspaServiceOfferingNoteTemplatesMinFields>;
  stddev?: Maybe<MedspaServiceOfferingNoteTemplatesStddevFields>;
  stddevPop?: Maybe<MedspaServiceOfferingNoteTemplatesStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceOfferingNoteTemplatesStddevSampFields>;
  sum?: Maybe<MedspaServiceOfferingNoteTemplatesSumFields>;
  varPop?: Maybe<MedspaServiceOfferingNoteTemplatesVarPopFields>;
  varSamp?: Maybe<MedspaServiceOfferingNoteTemplatesVarSampFields>;
  variance?: Maybe<MedspaServiceOfferingNoteTemplatesVarianceFields>;
};

/** aggregate fields of "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceOfferingNoteTemplatesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceOfferingNoteTemplatesMaxOrderBy>;
  min?: InputMaybe<MedspaServiceOfferingNoteTemplatesMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceOfferingNoteTemplatesStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceOfferingNoteTemplatesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceOfferingNoteTemplatesStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceOfferingNoteTemplatesSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceOfferingNoteTemplatesVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceOfferingNoteTemplatesVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceOfferingNoteTemplatesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesArrRelInsertInput = {
  data: Array<MedspaServiceOfferingNoteTemplatesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceOfferingNoteTemplatesOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceOfferingNoteTemplatesAvgFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  notetemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_offering_note_templates". All fields are combined with a logical 'AND'. */
export type MedspaServiceOfferingNoteTemplatesBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesBoolExp>>;
  _not?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaserviceofferingId?: InputMaybe<BigintComparisonExp>;
  noteTemplate?: InputMaybe<NoteTemplateBoolExp>;
  notetemplateId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspa_service_offering_note_templates" */
export enum MedspaServiceOfferingNoteTemplatesConstraint {
  /** unique or primary key constraint on columns "medspaserviceoffering_id", "notetemplate_id" */
  MedspaServiceOfferingNotetemplateIdMedspaseEec3b4caUniq = "medspa_service_offering__notetemplate_id_medspase_eec3b4ca_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspaServiceOfferingNoteTemplatesPkey = "medspa_service_offering_note_templates_pkey",
}

/** input type for incrementing numeric columns in table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  notetemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  noteTemplate?: InputMaybe<NoteTemplateObjRelInsertInput>;
  notetemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspaServiceOfferingNoteTemplatesMaxFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
  notetemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceOfferingNoteTemplatesMinFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
  notetemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesMutationResponse = {
  __typename?: "MedspaServiceOfferingNoteTemplatesMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceOfferingNoteTemplates>;
};

/** on_conflict condition type for table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesOnConflict = {
  constraint: MedspaServiceOfferingNoteTemplatesConstraint;
  updateColumns?: Array<MedspaServiceOfferingNoteTemplatesUpdateColumn>;
  where?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_offering_note_templates". */
export type MedspaServiceOfferingNoteTemplatesOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteTemplate?: InputMaybe<NoteTemplateOrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_service_offering_note_templates */
export type MedspaServiceOfferingNoteTemplatesPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_service_offering_note_templates" */
export enum MedspaServiceOfferingNoteTemplatesSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaserviceofferingId = "medspaserviceofferingId",
  /** column name */
  NotetemplateId = "notetemplateId",
}

/** input type for updating data in table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  notetemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceOfferingNoteTemplatesStddevFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  notetemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceOfferingNoteTemplatesStddevPopFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  notetemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceOfferingNoteTemplatesStddevSampFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  notetemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceOfferingNoteTemplatesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceOfferingNoteTemplatesStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  notetemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaServiceOfferingNoteTemplatesSumFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
  notetemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_offering_note_templates" */
export enum MedspaServiceOfferingNoteTemplatesUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaserviceofferingId = "medspaserviceofferingId",
  /** column name */
  NotetemplateId = "notetemplateId",
}

export type MedspaServiceOfferingNoteTemplatesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceOfferingNoteTemplatesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceOfferingNoteTemplatesSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceOfferingNoteTemplatesBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceOfferingNoteTemplatesVarPopFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  notetemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceOfferingNoteTemplatesVarSampFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  notetemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceOfferingNoteTemplatesVarianceFields = {
  __typename?: "MedspaServiceOfferingNoteTemplatesVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  notetemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_offering_note_templates" */
export type MedspaServiceOfferingNoteTemplatesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  notetemplateId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotes = {
  __typename?: "MedspaServiceOfferingNotes";
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaServiceOffering: MedspaServiceOffering;
  medspaserviceofferingId: Scalars["bigint"]["output"];
  /** An object relationship */
  note: Note;
  noteId: Scalars["bigint"]["output"];
};

/** aggregated selection of "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesAggregate = {
  __typename?: "MedspaServiceOfferingNotesAggregate";
  aggregate?: Maybe<MedspaServiceOfferingNotesAggregateFields>;
  nodes: Array<MedspaServiceOfferingNotes>;
};

export type MedspaServiceOfferingNotesAggregateBoolExp = {
  count?: InputMaybe<MedspaServiceOfferingNotesAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesAggregateFields = {
  __typename?: "MedspaServiceOfferingNotesAggregateFields";
  avg?: Maybe<MedspaServiceOfferingNotesAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceOfferingNotesMaxFields>;
  min?: Maybe<MedspaServiceOfferingNotesMinFields>;
  stddev?: Maybe<MedspaServiceOfferingNotesStddevFields>;
  stddevPop?: Maybe<MedspaServiceOfferingNotesStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceOfferingNotesStddevSampFields>;
  sum?: Maybe<MedspaServiceOfferingNotesSumFields>;
  varPop?: Maybe<MedspaServiceOfferingNotesVarPopFields>;
  varSamp?: Maybe<MedspaServiceOfferingNotesVarSampFields>;
  variance?: Maybe<MedspaServiceOfferingNotesVarianceFields>;
};

/** aggregate fields of "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaServiceOfferingNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceOfferingNotesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceOfferingNotesMaxOrderBy>;
  min?: InputMaybe<MedspaServiceOfferingNotesMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceOfferingNotesStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceOfferingNotesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceOfferingNotesStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceOfferingNotesSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceOfferingNotesVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceOfferingNotesVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceOfferingNotesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesArrRelInsertInput = {
  data: Array<MedspaServiceOfferingNotesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceOfferingNotesOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceOfferingNotesAvgFields = {
  __typename?: "MedspaServiceOfferingNotesAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_offering_notes". All fields are combined with a logical 'AND'. */
export type MedspaServiceOfferingNotesBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceOfferingNotesBoolExp>>;
  _not?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceOfferingNotesBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaserviceofferingId?: InputMaybe<BigintComparisonExp>;
  note?: InputMaybe<NoteBoolExp>;
  noteId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspa_service_offering_notes" */
export enum MedspaServiceOfferingNotesConstraint {
  /** unique or primary key constraint on columns "note_id", "medspaserviceoffering_id" */
  MedspaServiceOfferingNoteIdMedspaserviceoffCabc3220Uniq = "medspa_service_offering__note_id_medspaserviceoff_cabc3220_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspaServiceOfferingNotesPkey = "medspa_service_offering_notes_pkey",
}

/** input type for incrementing numeric columns in table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  note?: InputMaybe<NoteObjRelInsertInput>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspaServiceOfferingNotesMaxFields = {
  __typename?: "MedspaServiceOfferingNotesMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceOfferingNotesMinFields = {
  __typename?: "MedspaServiceOfferingNotesMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesMutationResponse = {
  __typename?: "MedspaServiceOfferingNotesMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceOfferingNotes>;
};

/** on_conflict condition type for table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesOnConflict = {
  constraint: MedspaServiceOfferingNotesConstraint;
  updateColumns?: Array<MedspaServiceOfferingNotesUpdateColumn>;
  where?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_offering_notes". */
export type MedspaServiceOfferingNotesOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  note?: InputMaybe<NoteOrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_service_offering_notes */
export type MedspaServiceOfferingNotesPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_service_offering_notes" */
export enum MedspaServiceOfferingNotesSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaserviceofferingId = "medspaserviceofferingId",
  /** column name */
  NoteId = "noteId",
}

/** input type for updating data in table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceOfferingNotesStddevFields = {
  __typename?: "MedspaServiceOfferingNotesStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceOfferingNotesStddevPopFields = {
  __typename?: "MedspaServiceOfferingNotesStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceOfferingNotesStddevSampFields = {
  __typename?: "MedspaServiceOfferingNotesStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceOfferingNotesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceOfferingNotesStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceofferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaServiceOfferingNotesSumFields = {
  __typename?: "MedspaServiceOfferingNotesSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["bigint"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_offering_notes" */
export enum MedspaServiceOfferingNotesUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaserviceofferingId = "medspaserviceofferingId",
  /** column name */
  NoteId = "noteId",
}

export type MedspaServiceOfferingNotesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceOfferingNotesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceOfferingNotesSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceOfferingNotesBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceOfferingNotesVarPopFields = {
  __typename?: "MedspaServiceOfferingNotesVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceOfferingNotesVarSampFields = {
  __typename?: "MedspaServiceOfferingNotesVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceOfferingNotesVarianceFields = {
  __typename?: "MedspaServiceOfferingNotesVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceofferingId?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_offering_notes" */
export type MedspaServiceOfferingNotesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaserviceofferingId?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** input type for inserting object relation for remote table "medspa_service_offering" */
export type MedspaServiceOfferingObjRelInsertInput = {
  data: MedspaServiceOfferingInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceOfferingOnConflict>;
};

/** on_conflict condition type for table "medspa_service_offering" */
export type MedspaServiceOfferingOnConflict = {
  constraint: MedspaServiceOfferingConstraint;
  updateColumns?: Array<MedspaServiceOfferingUpdateColumn>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_offering". */
export type MedspaServiceOfferingOrderBy = {
  complianceRequirements?: InputMaybe<MedspaServiceOfferingComplianceRequirementsOrderBy>;
  created?: InputMaybe<OrderBy>;
  defaultGfeReviewType?: InputMaybe<OrderBy>;
  formsAggregate?: InputMaybe<FormsFormMedspaServiceOfferingsAggregateOrderBy>;
  gfeMethod?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isEnabled?: InputMaybe<OrderBy>;
  isMedicalService?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaServiceMenuItemsAggregate?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsAggregateOrderBy>;
  medspaServiceOfferingFormsAggregate?: InputMaybe<FormsFormMedspaServiceOfferingsAggregateOrderBy>;
  medspaServiceProductsAggregate?: InputMaybe<MedspaServiceProductAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerNotRequireDocumentsAggregate?: InputMaybe<ProviderNotRequireDocumentsAggregateOrderBy>;
  serviceType?: InputMaybe<ServiceTypeOrderBy>;
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_service_offering */
export type MedspaServiceOfferingPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_service_offering" */
export enum MedspaServiceOfferingSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DefaultGfeReviewType = "defaultGfeReviewType",
  /** column name */
  Id = "id",
  /** column name */
  IsEnabled = "isEnabled",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  ServiceTypeId = "serviceTypeId",
}

/** select "medspaServiceOfferingAggregateBoolExpBool_andArgumentsColumns" columns of table "medspa_service_offering" */
export enum MedspaServiceOfferingSelectColumnMedspaServiceOfferingAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsEnabled = "isEnabled",
}

/** select "medspaServiceOfferingAggregateBoolExpBool_orArgumentsColumns" columns of table "medspa_service_offering" */
export enum MedspaServiceOfferingSelectColumnMedspaServiceOfferingAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsEnabled = "isEnabled",
}

/** input type for updating data in table "medspa_service_offering" */
export type MedspaServiceOfferingSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The GFE review method can only be set by the provider and takes priority over compliance. To include default compliance rules, use get_medspa_offering_gfe_method SQL fn. */
  defaultGfeReviewType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether the service is currently offered (can be changed to false at any time) */
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceOfferingStddevFields = {
  __typename?: "MedspaServiceOfferingStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceOfferingStddevPopFields = {
  __typename?: "MedspaServiceOfferingStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceOfferingStddevSampFields = {
  __typename?: "MedspaServiceOfferingStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_offering" */
export type MedspaServiceOfferingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceOfferingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceOfferingStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The GFE review method can only be set by the provider and takes priority over compliance. To include default compliance rules, use get_medspa_offering_gfe_method SQL fn. */
  defaultGfeReviewType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether the service is currently offered (can be changed to false at any time) */
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaServiceOfferingSumFields = {
  __typename?: "MedspaServiceOfferingSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_offering" */
export enum MedspaServiceOfferingUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DefaultGfeReviewType = "defaultGfeReviewType",
  /** column name */
  Id = "id",
  /** column name */
  IsEnabled = "isEnabled",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  ServiceTypeId = "serviceTypeId",
}

export type MedspaServiceOfferingUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceOfferingIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceOfferingSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceOfferingBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceOfferingVarPopFields = {
  __typename?: "MedspaServiceOfferingVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceOfferingVarSampFields = {
  __typename?: "MedspaServiceOfferingVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceOfferingVarianceFields = {
  __typename?: "MedspaServiceOfferingVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that offers the service */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by the medspa */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_offering" */
export type MedspaServiceOfferingVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A medspa that offers the service */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by the medspa */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProduct = {
  __typename?: "MedspaServiceProduct";
  additionalInfo?: Maybe<MedSpaServiceProductType>;
  created: Scalars["timestamptz"]["output"];
  /** An array relationship */
  discountServiceProducts: Array<DiscountServiceProducts>;
  /** An aggregate relationship */
  discountServiceProductsAggregate: DiscountServiceProductsAggregate;
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["numeric"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An array relationship */
  inventoryLots: Array<InventoryLot>;
  /** An aggregate relationship */
  inventoryLotsAggregate: InventoryLotAggregate;
  /** An array relationship */
  inventoryTransactions: Array<InventoryTransaction>;
  /** An aggregate relationship */
  inventoryTransactionsAggregate: InventoryTransactionAggregate;
  /** Indicates whether the service product is currently used for the service (can be changed to false at any time) */
  isEnabled: Scalars["Boolean"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** A medspa that uses the service product for their services */
  medspaId: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaServiceOffering: MedspaServiceOffering;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Base price per unit of the service product (in USD) */
  price: Scalars["numeric"]["output"];
  /** An object relationship */
  serviceProduct: ServiceProduct;
  /** A service product that is used by the medspa for their services */
  serviceProductId: Scalars["bigint"]["output"];
  /** An array relationship */
  serviceProductLines: Array<ServiceProductLine>;
  /** An aggregate relationship */
  serviceProductLinesAggregate: ServiceProductLineAggregate;
  /** Indicates whether revenue should be shown on the inventory page */
  showRevenueInInventory?: Maybe<Scalars["Boolean"]["output"]>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProductDiscountServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceProductsOrderBy>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProductDiscountServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceProductsOrderBy>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProductInventoryLotsArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProductInventoryLotsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProductInventoryTransactionsArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProductInventoryTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProductServiceProductLinesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** List of service products that are being used by a MedSpa for their services. */
export type MedspaServiceProductServiceProductLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** aggregated selection of "medspa_service_product" */
export type MedspaServiceProductAggregate = {
  __typename?: "MedspaServiceProductAggregate";
  aggregate?: Maybe<MedspaServiceProductAggregateFields>;
  nodes: Array<MedspaServiceProduct>;
};

export type MedspaServiceProductAggregateBoolExp = {
  bool_and?: InputMaybe<MedspaServiceProductAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MedspaServiceProductAggregateBoolExpBool_Or>;
  count?: InputMaybe<MedspaServiceProductAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_product" */
export type MedspaServiceProductAggregateFields = {
  __typename?: "MedspaServiceProductAggregateFields";
  avg?: Maybe<MedspaServiceProductAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceProductMaxFields>;
  min?: Maybe<MedspaServiceProductMinFields>;
  stddev?: Maybe<MedspaServiceProductStddevFields>;
  stddevPop?: Maybe<MedspaServiceProductStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceProductStddevSampFields>;
  sum?: Maybe<MedspaServiceProductSumFields>;
  varPop?: Maybe<MedspaServiceProductVarPopFields>;
  varSamp?: Maybe<MedspaServiceProductVarSampFields>;
  variance?: Maybe<MedspaServiceProductVarianceFields>;
};

/** aggregate fields of "medspa_service_product" */
export type MedspaServiceProductAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_service_product" */
export type MedspaServiceProductAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceProductAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceProductMaxOrderBy>;
  min?: InputMaybe<MedspaServiceProductMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceProductStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceProductStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceProductStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceProductSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceProductVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceProductVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceProductVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_product" */
export type MedspaServiceProductArrRelInsertInput = {
  data: Array<MedspaServiceProductInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceProductOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceProductAvgFields = {
  __typename?: "MedspaServiceProductAvgFields";
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_product" */
export type MedspaServiceProductAvgOrderBy = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_product". All fields are combined with a logical 'AND'. */
export type MedspaServiceProductBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceProductBoolExp>>;
  _not?: InputMaybe<MedspaServiceProductBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceProductBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  discountServiceProducts?: InputMaybe<DiscountServiceProductsBoolExp>;
  discountServiceProductsAggregate?: InputMaybe<DiscountServiceProductsAggregateBoolExp>;
  estimatedCost?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  inventoryLots?: InputMaybe<InventoryLotBoolExp>;
  inventoryLotsAggregate?: InputMaybe<InventoryLotAggregateBoolExp>;
  inventoryTransactions?: InputMaybe<InventoryTransactionBoolExp>;
  inventoryTransactionsAggregate?: InputMaybe<InventoryTransactionAggregateBoolExp>;
  isEnabled?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaServiceOfferingId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  serviceProduct?: InputMaybe<ServiceProductBoolExp>;
  serviceProductId?: InputMaybe<BigintComparisonExp>;
  serviceProductLines?: InputMaybe<ServiceProductLineBoolExp>;
  serviceProductLinesAggregate?: InputMaybe<ServiceProductLineAggregateBoolExp>;
  showRevenueInInventory?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "medspa_service_product" */
export enum MedspaServiceProductConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspaServiceProductPkey = "medspa_service_product_pkey",
  /** unique or primary key constraint on columns "medspa_service_offering_id", "service_product_id" */
  UniqueMedspaServiceProduct = "unique_medspa_service_product",
}

/** input type for incrementing numeric columns in table "medspa_service_product" */
export type MedspaServiceProductIncInput = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_service_product" */
export type MedspaServiceProductInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discountServiceProducts?: InputMaybe<DiscountServiceProductsArrRelInsertInput>;
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  inventoryLots?: InputMaybe<InventoryLotArrRelInsertInput>;
  inventoryTransactions?: InputMaybe<InventoryTransactionArrRelInsertInput>;
  /** Indicates whether the service product is currently used for the service (can be changed to false at any time) */
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  serviceProduct?: InputMaybe<ServiceProductObjRelInsertInput>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductLines?: InputMaybe<ServiceProductLineArrRelInsertInput>;
  /** Indicates whether revenue should be shown on the inventory page */
  showRevenueInInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type MedspaServiceProductMaxFields = {
  __typename?: "MedspaServiceProductMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_service_product" */
export type MedspaServiceProductMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceProductMinFields = {
  __typename?: "MedspaServiceProductMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_service_product" */
export type MedspaServiceProductMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_product" */
export type MedspaServiceProductMutationResponse = {
  __typename?: "MedspaServiceProductMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceProduct>;
};

/** input type for inserting object relation for remote table "medspa_service_product" */
export type MedspaServiceProductObjRelInsertInput = {
  data: MedspaServiceProductInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceProductOnConflict>;
};

/** on_conflict condition type for table "medspa_service_product" */
export type MedspaServiceProductOnConflict = {
  constraint: MedspaServiceProductConstraint;
  updateColumns?: Array<MedspaServiceProductUpdateColumn>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_product". */
export type MedspaServiceProductOrderBy = {
  created?: InputMaybe<OrderBy>;
  discountServiceProductsAggregate?: InputMaybe<DiscountServiceProductsAggregateOrderBy>;
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inventoryLotsAggregate?: InputMaybe<InventoryLotAggregateOrderBy>;
  inventoryTransactionsAggregate?: InputMaybe<InventoryTransactionAggregateOrderBy>;
  isEnabled?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serviceProduct?: InputMaybe<ServiceProductOrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  serviceProductLinesAggregate?: InputMaybe<ServiceProductLineAggregateOrderBy>;
  showRevenueInInventory?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_service_product */
export type MedspaServiceProductPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_service_product" */
export enum MedspaServiceProductSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  EstimatedCost = "estimatedCost",
  /** column name */
  Id = "id",
  /** column name */
  IsEnabled = "isEnabled",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  Price = "price",
  /** column name */
  ServiceProductId = "serviceProductId",
  /** column name */
  ShowRevenueInInventory = "showRevenueInInventory",
}

/** select "medspaServiceProductAggregateBoolExpBool_andArgumentsColumns" columns of table "medspa_service_product" */
export enum MedspaServiceProductSelectColumnMedspaServiceProductAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsEnabled = "isEnabled",
  /** column name */
  ShowRevenueInInventory = "showRevenueInInventory",
}

/** select "medspaServiceProductAggregateBoolExpBool_orArgumentsColumns" columns of table "medspa_service_product" */
export enum MedspaServiceProductSelectColumnMedspaServiceProductAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsEnabled = "isEnabled",
  /** column name */
  ShowRevenueInInventory = "showRevenueInInventory",
}

/** input type for updating data in table "medspa_service_product" */
export type MedspaServiceProductSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether the service product is currently used for the service (can be changed to false at any time) */
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether revenue should be shown on the inventory page */
  showRevenueInInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceProductStddevFields = {
  __typename?: "MedspaServiceProductStddevFields";
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_product" */
export type MedspaServiceProductStddevOrderBy = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceProductStddevPopFields = {
  __typename?: "MedspaServiceProductStddevPopFields";
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_product" */
export type MedspaServiceProductStddevPopOrderBy = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceProductStddevSampFields = {
  __typename?: "MedspaServiceProductStddevSampFields";
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_product" */
export type MedspaServiceProductStddevSampOrderBy = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_product" */
export type MedspaServiceProductStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceProductStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceProductStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether the service product is currently used for the service (can be changed to false at any time) */
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether revenue should be shown on the inventory page */
  showRevenueInInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaServiceProductSumFields = {
  __typename?: "MedspaServiceProductSumFields";
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_product" */
export type MedspaServiceProductSumOrderBy = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_product" */
export enum MedspaServiceProductUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  EstimatedCost = "estimatedCost",
  /** column name */
  Id = "id",
  /** column name */
  IsEnabled = "isEnabled",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  Price = "price",
  /** column name */
  ServiceProductId = "serviceProductId",
  /** column name */
  ShowRevenueInInventory = "showRevenueInInventory",
}

export type MedspaServiceProductUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceProductIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceProductSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceProductBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceProductVarPopFields = {
  __typename?: "MedspaServiceProductVarPopFields";
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_product" */
export type MedspaServiceProductVarPopOrderBy = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceProductVarSampFields = {
  __typename?: "MedspaServiceProductVarSampFields";
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_product" */
export type MedspaServiceProductVarSampOrderBy = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceProductVarianceFields = {
  __typename?: "MedspaServiceProductVarianceFields";
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A medspa that uses the service product for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price per unit of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_product" */
export type MedspaServiceProductVarianceOrderBy = {
  /** Estimated cost (in USD) of the service product per unit set by the provider */
  estimatedCost?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A medspa that uses the service product for their services */
  medspaId?: InputMaybe<OrderBy>;
  /** A formal service offered by MedSpa that the service product is used for */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** Base price per unit of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** A service product that is used by the medspa for their services */
  serviceProductId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplate = {
  __typename?: "MedspaServiceProtocolTemplate";
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceProtocolTemplate: ServiceProtocolTemplate;
  serviceprotocoltemplateId: Scalars["bigint"]["output"];
};

/** aggregated selection of "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateAggregate = {
  __typename?: "MedspaServiceProtocolTemplateAggregate";
  aggregate?: Maybe<MedspaServiceProtocolTemplateAggregateFields>;
  nodes: Array<MedspaServiceProtocolTemplate>;
};

export type MedspaServiceProtocolTemplateAggregateBoolExp = {
  count?: InputMaybe<MedspaServiceProtocolTemplateAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateAggregateFields = {
  __typename?: "MedspaServiceProtocolTemplateAggregateFields";
  avg?: Maybe<MedspaServiceProtocolTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaServiceProtocolTemplateMaxFields>;
  min?: Maybe<MedspaServiceProtocolTemplateMinFields>;
  stddev?: Maybe<MedspaServiceProtocolTemplateStddevFields>;
  stddevPop?: Maybe<MedspaServiceProtocolTemplateStddevPopFields>;
  stddevSamp?: Maybe<MedspaServiceProtocolTemplateStddevSampFields>;
  sum?: Maybe<MedspaServiceProtocolTemplateSumFields>;
  varPop?: Maybe<MedspaServiceProtocolTemplateVarPopFields>;
  varSamp?: Maybe<MedspaServiceProtocolTemplateVarSampFields>;
  variance?: Maybe<MedspaServiceProtocolTemplateVarianceFields>;
};

/** aggregate fields of "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaServiceProtocolTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateAggregateOrderBy = {
  avg?: InputMaybe<MedspaServiceProtocolTemplateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaServiceProtocolTemplateMaxOrderBy>;
  min?: InputMaybe<MedspaServiceProtocolTemplateMinOrderBy>;
  stddev?: InputMaybe<MedspaServiceProtocolTemplateStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaServiceProtocolTemplateStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaServiceProtocolTemplateStddevSampOrderBy>;
  sum?: InputMaybe<MedspaServiceProtocolTemplateSumOrderBy>;
  varPop?: InputMaybe<MedspaServiceProtocolTemplateVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaServiceProtocolTemplateVarSampOrderBy>;
  variance?: InputMaybe<MedspaServiceProtocolTemplateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateArrRelInsertInput = {
  data: Array<MedspaServiceProtocolTemplateInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaServiceProtocolTemplateOnConflict>;
};

/** aggregate avg on columns */
export type MedspaServiceProtocolTemplateAvgFields = {
  __typename?: "MedspaServiceProtocolTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_service_protocol_template". All fields are combined with a logical 'AND'. */
export type MedspaServiceProtocolTemplateBoolExp = {
  _and?: InputMaybe<Array<MedspaServiceProtocolTemplateBoolExp>>;
  _not?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
  _or?: InputMaybe<Array<MedspaServiceProtocolTemplateBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  serviceProtocolTemplate?: InputMaybe<ServiceProtocolTemplateBoolExp>;
  serviceprotocoltemplateId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspa_service_protocol_template" */
export enum MedspaServiceProtocolTemplateConstraint {
  /** unique or primary key constraint on columns "medspa_id", "serviceprotocoltemplate_id" */
  MedspaServiceProtocolServiceprotocoltemplate_362d3f78Uniq = "medspa_service_protocol__serviceprotocoltemplate__362d3f78_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspaServiceProtocolTemplatePkey = "medspa_service_protocol_template_pkey",
}

/** input type for incrementing numeric columns in table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceprotocoltemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProtocolTemplate?: InputMaybe<ServiceProtocolTemplateObjRelInsertInput>;
  serviceprotocoltemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspaServiceProtocolTemplateMaxFields = {
  __typename?: "MedspaServiceProtocolTemplateMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaServiceProtocolTemplateMinFields = {
  __typename?: "MedspaServiceProtocolTemplateMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateMutationResponse = {
  __typename?: "MedspaServiceProtocolTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaServiceProtocolTemplate>;
};

/** on_conflict condition type for table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateOnConflict = {
  constraint: MedspaServiceProtocolTemplateConstraint;
  updateColumns?: Array<MedspaServiceProtocolTemplateUpdateColumn>;
  where?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
};

/** Ordering options when selecting data from "medspa_service_protocol_template". */
export type MedspaServiceProtocolTemplateOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceProtocolTemplate?: InputMaybe<ServiceProtocolTemplateOrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_service_protocol_template */
export type MedspaServiceProtocolTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_service_protocol_template" */
export enum MedspaServiceProtocolTemplateSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  ServiceprotocoltemplateId = "serviceprotocoltemplateId",
}

/** input type for updating data in table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceprotocoltemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaServiceProtocolTemplateStddevFields = {
  __typename?: "MedspaServiceProtocolTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaServiceProtocolTemplateStddevPopFields = {
  __typename?: "MedspaServiceProtocolTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaServiceProtocolTemplateStddevSampFields = {
  __typename?: "MedspaServiceProtocolTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaServiceProtocolTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaServiceProtocolTemplateStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceprotocoltemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaServiceProtocolTemplateSumFields = {
  __typename?: "MedspaServiceProtocolTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_service_protocol_template" */
export enum MedspaServiceProtocolTemplateUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  ServiceprotocoltemplateId = "serviceprotocoltemplateId",
}

export type MedspaServiceProtocolTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaServiceProtocolTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaServiceProtocolTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaServiceProtocolTemplateBoolExp;
};

/** aggregate varPop on columns */
export type MedspaServiceProtocolTemplateVarPopFields = {
  __typename?: "MedspaServiceProtocolTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaServiceProtocolTemplateVarSampFields = {
  __typename?: "MedspaServiceProtocolTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaServiceProtocolTemplateVarianceFields = {
  __typename?: "MedspaServiceProtocolTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  serviceprotocoltemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_service_protocol_template" */
export type MedspaServiceProtocolTemplateVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  serviceprotocoltemplateId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspa_standing_order_template" */
export type MedspaStandingOrderTemplate = {
  __typename?: "MedspaStandingOrderTemplate";
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An object relationship */
  standingOrderTemplate: StandingOrderTemplate;
  standingordertemplateId: Scalars["bigint"]["output"];
};

/** aggregated selection of "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateAggregate = {
  __typename?: "MedspaStandingOrderTemplateAggregate";
  aggregate?: Maybe<MedspaStandingOrderTemplateAggregateFields>;
  nodes: Array<MedspaStandingOrderTemplate>;
};

export type MedspaStandingOrderTemplateAggregateBoolExp = {
  count?: InputMaybe<MedspaStandingOrderTemplateAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateAggregateFields = {
  __typename?: "MedspaStandingOrderTemplateAggregateFields";
  avg?: Maybe<MedspaStandingOrderTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaStandingOrderTemplateMaxFields>;
  min?: Maybe<MedspaStandingOrderTemplateMinFields>;
  stddev?: Maybe<MedspaStandingOrderTemplateStddevFields>;
  stddevPop?: Maybe<MedspaStandingOrderTemplateStddevPopFields>;
  stddevSamp?: Maybe<MedspaStandingOrderTemplateStddevSampFields>;
  sum?: Maybe<MedspaStandingOrderTemplateSumFields>;
  varPop?: Maybe<MedspaStandingOrderTemplateVarPopFields>;
  varSamp?: Maybe<MedspaStandingOrderTemplateVarSampFields>;
  variance?: Maybe<MedspaStandingOrderTemplateVarianceFields>;
};

/** aggregate fields of "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaStandingOrderTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateAggregateOrderBy = {
  avg?: InputMaybe<MedspaStandingOrderTemplateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaStandingOrderTemplateMaxOrderBy>;
  min?: InputMaybe<MedspaStandingOrderTemplateMinOrderBy>;
  stddev?: InputMaybe<MedspaStandingOrderTemplateStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaStandingOrderTemplateStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaStandingOrderTemplateStddevSampOrderBy>;
  sum?: InputMaybe<MedspaStandingOrderTemplateSumOrderBy>;
  varPop?: InputMaybe<MedspaStandingOrderTemplateVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaStandingOrderTemplateVarSampOrderBy>;
  variance?: InputMaybe<MedspaStandingOrderTemplateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateArrRelInsertInput = {
  data: Array<MedspaStandingOrderTemplateInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaStandingOrderTemplateOnConflict>;
};

/** aggregate avg on columns */
export type MedspaStandingOrderTemplateAvgFields = {
  __typename?: "MedspaStandingOrderTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_standing_order_template". All fields are combined with a logical 'AND'. */
export type MedspaStandingOrderTemplateBoolExp = {
  _and?: InputMaybe<Array<MedspaStandingOrderTemplateBoolExp>>;
  _not?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
  _or?: InputMaybe<Array<MedspaStandingOrderTemplateBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  standingOrderTemplate?: InputMaybe<StandingOrderTemplateBoolExp>;
  standingordertemplateId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspa_standing_order_template" */
export enum MedspaStandingOrderTemplateConstraint {
  /** unique or primary key constraint on columns "standingordertemplate_id", "medspa_id" */
  MedspaStandingOrderTeStandingordertemplateIdC9a2f4ceUniq = "medspa_standing_order_te_standingordertemplate_id_c9a2f4ce_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspaStandingOrderTemplatePkey = "medspa_standing_order_template_pkey",
}

/** input type for incrementing numeric columns in table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingordertemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingOrderTemplate?: InputMaybe<StandingOrderTemplateObjRelInsertInput>;
  standingordertemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspaStandingOrderTemplateMaxFields = {
  __typename?: "MedspaStandingOrderTemplateMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaStandingOrderTemplateMinFields = {
  __typename?: "MedspaStandingOrderTemplateMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateMutationResponse = {
  __typename?: "MedspaStandingOrderTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaStandingOrderTemplate>;
};

/** on_conflict condition type for table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateOnConflict = {
  constraint: MedspaStandingOrderTemplateConstraint;
  updateColumns?: Array<MedspaStandingOrderTemplateUpdateColumn>;
  where?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
};

/** Ordering options when selecting data from "medspa_standing_order_template". */
export type MedspaStandingOrderTemplateOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingOrderTemplate?: InputMaybe<StandingOrderTemplateOrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_standing_order_template */
export type MedspaStandingOrderTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_standing_order_template" */
export enum MedspaStandingOrderTemplateSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  StandingordertemplateId = "standingordertemplateId",
}

/** input type for updating data in table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingordertemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaStandingOrderTemplateStddevFields = {
  __typename?: "MedspaStandingOrderTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaStandingOrderTemplateStddevPopFields = {
  __typename?: "MedspaStandingOrderTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaStandingOrderTemplateStddevSampFields = {
  __typename?: "MedspaStandingOrderTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaStandingOrderTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaStandingOrderTemplateStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingordertemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaStandingOrderTemplateSumFields = {
  __typename?: "MedspaStandingOrderTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_standing_order_template" */
export enum MedspaStandingOrderTemplateUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  StandingordertemplateId = "standingordertemplateId",
}

export type MedspaStandingOrderTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaStandingOrderTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaStandingOrderTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaStandingOrderTemplateBoolExp;
};

/** aggregate varPop on columns */
export type MedspaStandingOrderTemplateVarPopFields = {
  __typename?: "MedspaStandingOrderTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaStandingOrderTemplateVarSampFields = {
  __typename?: "MedspaStandingOrderTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaStandingOrderTemplateVarianceFields = {
  __typename?: "MedspaStandingOrderTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  standingordertemplateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_standing_order_template" */
export type MedspaStandingOrderTemplateVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  standingordertemplateId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspa_wallet_credit_type" */
export type MedspaWalletCreditType = {
  __typename?: "MedspaWalletCreditType";
  /** An object relationship */
  creditType: WalletCreditType;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  walletcredittypeId: Scalars["bigint"]["output"];
};

/** aggregated selection of "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeAggregate = {
  __typename?: "MedspaWalletCreditTypeAggregate";
  aggregate?: Maybe<MedspaWalletCreditTypeAggregateFields>;
  nodes: Array<MedspaWalletCreditType>;
};

export type MedspaWalletCreditTypeAggregateBoolExp = {
  count?: InputMaybe<MedspaWalletCreditTypeAggregateBoolExpCount>;
};

/** aggregate fields of "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeAggregateFields = {
  __typename?: "MedspaWalletCreditTypeAggregateFields";
  avg?: Maybe<MedspaWalletCreditTypeAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaWalletCreditTypeMaxFields>;
  min?: Maybe<MedspaWalletCreditTypeMinFields>;
  stddev?: Maybe<MedspaWalletCreditTypeStddevFields>;
  stddevPop?: Maybe<MedspaWalletCreditTypeStddevPopFields>;
  stddevSamp?: Maybe<MedspaWalletCreditTypeStddevSampFields>;
  sum?: Maybe<MedspaWalletCreditTypeSumFields>;
  varPop?: Maybe<MedspaWalletCreditTypeVarPopFields>;
  varSamp?: Maybe<MedspaWalletCreditTypeVarSampFields>;
  variance?: Maybe<MedspaWalletCreditTypeVarianceFields>;
};

/** aggregate fields of "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeAggregateOrderBy = {
  avg?: InputMaybe<MedspaWalletCreditTypeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaWalletCreditTypeMaxOrderBy>;
  min?: InputMaybe<MedspaWalletCreditTypeMinOrderBy>;
  stddev?: InputMaybe<MedspaWalletCreditTypeStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaWalletCreditTypeStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaWalletCreditTypeStddevSampOrderBy>;
  sum?: InputMaybe<MedspaWalletCreditTypeSumOrderBy>;
  varPop?: InputMaybe<MedspaWalletCreditTypeVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaWalletCreditTypeVarSampOrderBy>;
  variance?: InputMaybe<MedspaWalletCreditTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeArrRelInsertInput = {
  data: Array<MedspaWalletCreditTypeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaWalletCreditTypeOnConflict>;
};

/** aggregate avg on columns */
export type MedspaWalletCreditTypeAvgFields = {
  __typename?: "MedspaWalletCreditTypeAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspa_wallet_credit_type". All fields are combined with a logical 'AND'. */
export type MedspaWalletCreditTypeBoolExp = {
  _and?: InputMaybe<Array<MedspaWalletCreditTypeBoolExp>>;
  _not?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
  _or?: InputMaybe<Array<MedspaWalletCreditTypeBoolExp>>;
  creditType?: InputMaybe<WalletCreditTypeBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  walletcredittypeId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspa_wallet_credit_type" */
export enum MedspaWalletCreditTypeConstraint {
  /** unique or primary key constraint on columns "medspa_id", "walletcredittype_id" */
  MedspaWalletCreditTypWalletcredittypeIdMeds_98001ae0Uniq = "medspa_wallet_credit_typ_walletcredittype_id_meds_98001ae0_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspaWalletCreditTypePkey = "medspa_wallet_credit_type_pkey",
}

/** input type for incrementing numeric columns in table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletcredittypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeInsertInput = {
  creditType?: InputMaybe<WalletCreditTypeObjRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletcredittypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspaWalletCreditTypeMaxFields = {
  __typename?: "MedspaWalletCreditTypeMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaWalletCreditTypeMinFields = {
  __typename?: "MedspaWalletCreditTypeMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeMutationResponse = {
  __typename?: "MedspaWalletCreditTypeMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspaWalletCreditType>;
};

/** on_conflict condition type for table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeOnConflict = {
  constraint: MedspaWalletCreditTypeConstraint;
  updateColumns?: Array<MedspaWalletCreditTypeUpdateColumn>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

/** Ordering options when selecting data from "medspa_wallet_credit_type". */
export type MedspaWalletCreditTypeOrderBy = {
  creditType?: InputMaybe<WalletCreditTypeOrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspa_wallet_credit_type */
export type MedspaWalletCreditTypePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspa_wallet_credit_type" */
export enum MedspaWalletCreditTypeSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  WalletcredittypeId = "walletcredittypeId",
}

/** input type for updating data in table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletcredittypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspaWalletCreditTypeStddevFields = {
  __typename?: "MedspaWalletCreditTypeStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaWalletCreditTypeStddevPopFields = {
  __typename?: "MedspaWalletCreditTypeStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaWalletCreditTypeStddevSampFields = {
  __typename?: "MedspaWalletCreditTypeStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaWalletCreditTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaWalletCreditTypeStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletcredittypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaWalletCreditTypeSumFields = {
  __typename?: "MedspaWalletCreditTypeSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspa_wallet_credit_type" */
export enum MedspaWalletCreditTypeUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  WalletcredittypeId = "walletcredittypeId",
}

export type MedspaWalletCreditTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaWalletCreditTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaWalletCreditTypeSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaWalletCreditTypeBoolExp;
};

/** aggregate varPop on columns */
export type MedspaWalletCreditTypeVarPopFields = {
  __typename?: "MedspaWalletCreditTypeVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaWalletCreditTypeVarSampFields = {
  __typename?: "MedspaWalletCreditTypeVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaWalletCreditTypeVarianceFields = {
  __typename?: "MedspaWalletCreditTypeVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  walletcredittypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspa_wallet_credit_type" */
export type MedspaWalletCreditTypeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  walletcredittypeId?: InputMaybe<OrderBy>;
};

/** Table to store feature flags that are used to control enablement of certain features on a per-MedSpa basis. */
export type MedspasFeatureflag = {
  __typename?: "MedspasFeatureflag";
  /** Used in conjunction with the m2m relationship to MedSpa to determine for which medspas the feature flag is enabled */
  appliesToAll: Scalars["Boolean"]["output"];
  /** Unique identifier for the feature flag, meant to be used when querying for it */
  code: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  description: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Human-readable and descriptive name of the feature flag */
  name: Scalars["String"]["output"];
  /** Summary of actions that need to be taken before enabling the feature flag, either for a specific medspa or globally */
  preEnablementRequirements: Scalars["String"]["output"];
};

/** aggregated selection of "medspas_featureflag" */
export type MedspasFeatureflagAggregate = {
  __typename?: "MedspasFeatureflagAggregate";
  aggregate?: Maybe<MedspasFeatureflagAggregateFields>;
  nodes: Array<MedspasFeatureflag>;
};

/** aggregate fields of "medspas_featureflag" */
export type MedspasFeatureflagAggregateFields = {
  __typename?: "MedspasFeatureflagAggregateFields";
  avg?: Maybe<MedspasFeatureflagAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspasFeatureflagMaxFields>;
  min?: Maybe<MedspasFeatureflagMinFields>;
  stddev?: Maybe<MedspasFeatureflagStddevFields>;
  stddevPop?: Maybe<MedspasFeatureflagStddevPopFields>;
  stddevSamp?: Maybe<MedspasFeatureflagStddevSampFields>;
  sum?: Maybe<MedspasFeatureflagSumFields>;
  varPop?: Maybe<MedspasFeatureflagVarPopFields>;
  varSamp?: Maybe<MedspasFeatureflagVarSampFields>;
  variance?: Maybe<MedspasFeatureflagVarianceFields>;
};

/** aggregate fields of "medspas_featureflag" */
export type MedspasFeatureflagAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspasFeatureflagSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type MedspasFeatureflagAvgFields = {
  __typename?: "MedspasFeatureflagAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "medspas_featureflag". All fields are combined with a logical 'AND'. */
export type MedspasFeatureflagBoolExp = {
  _and?: InputMaybe<Array<MedspasFeatureflagBoolExp>>;
  _not?: InputMaybe<MedspasFeatureflagBoolExp>;
  _or?: InputMaybe<Array<MedspasFeatureflagBoolExp>>;
  appliesToAll?: InputMaybe<BooleanComparisonExp>;
  code?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  preEnablementRequirements?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "medspas_featureflag" */
export enum MedspasFeatureflagConstraint {
  /** unique or primary key constraint on columns "code" */
  MedspasFeatureflagCodeKey = "medspas_featureflag_code_key",
  /** unique or primary key constraint on columns "name" */
  MedspasFeatureflagNameKey = "medspas_featureflag_name_key",
  /** unique or primary key constraint on columns "id" */
  MedspasFeatureflagPkey = "medspas_featureflag_pkey",
}

/** input type for incrementing numeric columns in table "medspas_featureflag" */
export type MedspasFeatureflagIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_featureflag" */
export type MedspasFeatureflagInsertInput = {
  /** Used in conjunction with the m2m relationship to MedSpa to determine for which medspas the feature flag is enabled */
  appliesToAll?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Unique identifier for the feature flag, meant to be used when querying for it */
  code?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable and descriptive name of the feature flag */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Summary of actions that need to be taken before enabling the feature flag, either for a specific medspa or globally */
  preEnablementRequirements?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MedspasFeatureflagMaxFields = {
  __typename?: "MedspasFeatureflagMaxFields";
  /** Unique identifier for the feature flag, meant to be used when querying for it */
  code?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Human-readable and descriptive name of the feature flag */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Summary of actions that need to be taken before enabling the feature flag, either for a specific medspa or globally */
  preEnablementRequirements?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type MedspasFeatureflagMinFields = {
  __typename?: "MedspasFeatureflagMinFields";
  /** Unique identifier for the feature flag, meant to be used when querying for it */
  code?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Human-readable and descriptive name of the feature flag */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Summary of actions that need to be taken before enabling the feature flag, either for a specific medspa or globally */
  preEnablementRequirements?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "medspas_featureflag" */
export type MedspasFeatureflagMutationResponse = {
  __typename?: "MedspasFeatureflagMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspasFeatureflag>;
};

/** input type for inserting object relation for remote table "medspas_featureflag" */
export type MedspasFeatureflagObjRelInsertInput = {
  data: MedspasFeatureflagInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedspasFeatureflagOnConflict>;
};

/** on_conflict condition type for table "medspas_featureflag" */
export type MedspasFeatureflagOnConflict = {
  constraint: MedspasFeatureflagConstraint;
  updateColumns?: Array<MedspasFeatureflagUpdateColumn>;
  where?: InputMaybe<MedspasFeatureflagBoolExp>;
};

/** Ordering options when selecting data from "medspas_featureflag". */
export type MedspasFeatureflagOrderBy = {
  appliesToAll?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  enabled?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  preEnablementRequirements?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_featureflag */
export type MedspasFeatureflagPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_featureflag" */
export enum MedspasFeatureflagSelectColumn {
  /** column name */
  AppliesToAll = "appliesToAll",
  /** column name */
  Code = "code",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  Enabled = "enabled",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  PreEnablementRequirements = "preEnablementRequirements",
}

/** input type for updating data in table "medspas_featureflag" */
export type MedspasFeatureflagSetInput = {
  /** Used in conjunction with the m2m relationship to MedSpa to determine for which medspas the feature flag is enabled */
  appliesToAll?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Unique identifier for the feature flag, meant to be used when querying for it */
  code?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable and descriptive name of the feature flag */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Summary of actions that need to be taken before enabling the feature flag, either for a specific medspa or globally */
  preEnablementRequirements?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspasFeatureflagStddevFields = {
  __typename?: "MedspasFeatureflagStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type MedspasFeatureflagStddevPopFields = {
  __typename?: "MedspasFeatureflagStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type MedspasFeatureflagStddevSampFields = {
  __typename?: "MedspasFeatureflagStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "medspas_featureflag" */
export type MedspasFeatureflagStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspasFeatureflagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspasFeatureflagStreamCursorValueInput = {
  /** Used in conjunction with the m2m relationship to MedSpa to determine for which medspas the feature flag is enabled */
  appliesToAll?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Unique identifier for the feature flag, meant to be used when querying for it */
  code?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable and descriptive name of the feature flag */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Summary of actions that need to be taken before enabling the feature flag, either for a specific medspa or globally */
  preEnablementRequirements?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MedspasFeatureflagSumFields = {
  __typename?: "MedspasFeatureflagSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "medspas_featureflag" */
export enum MedspasFeatureflagUpdateColumn {
  /** column name */
  AppliesToAll = "appliesToAll",
  /** column name */
  Code = "code",
  /** column name */
  Created = "created",
  /** column name */
  Description = "description",
  /** column name */
  Enabled = "enabled",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  PreEnablementRequirements = "preEnablementRequirements",
}

export type MedspasFeatureflagUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspasFeatureflagIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspasFeatureflagSetInput>;
  /** filter the rows which have to be updated */
  where: MedspasFeatureflagBoolExp;
};

/** aggregate varPop on columns */
export type MedspasFeatureflagVarPopFields = {
  __typename?: "MedspasFeatureflagVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type MedspasFeatureflagVarSampFields = {
  __typename?: "MedspasFeatureflagVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type MedspasFeatureflagVarianceFields = {
  __typename?: "MedspasFeatureflagVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "medspas_medicallicense" */
export type MedspasMedicallicense = {
  __typename?: "MedspasMedicallicense";
  abbreviation: Scalars["String"]["output"];
  allowsForSelfGfe: Scalars["Boolean"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  /** An array relationship */
  userMedspas: Array<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** An aggregate relationship */
  userMedspasAggregate: MedspasUsermedspaAcquiredMedicalLicensesAggregate;
};

/** columns and relationships of "medspas_medicallicense" */
export type MedspasMedicallicenseUserMedspasArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesOrderBy>>;
  where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
};

/** columns and relationships of "medspas_medicallicense" */
export type MedspasMedicallicenseUserMedspasAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesOrderBy>>;
  where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
};

/** aggregated selection of "medspas_medicallicense" */
export type MedspasMedicallicenseAggregate = {
  __typename?: "MedspasMedicallicenseAggregate";
  aggregate?: Maybe<MedspasMedicallicenseAggregateFields>;
  nodes: Array<MedspasMedicallicense>;
};

/** aggregate fields of "medspas_medicallicense" */
export type MedspasMedicallicenseAggregateFields = {
  __typename?: "MedspasMedicallicenseAggregateFields";
  avg?: Maybe<MedspasMedicallicenseAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspasMedicallicenseMaxFields>;
  min?: Maybe<MedspasMedicallicenseMinFields>;
  stddev?: Maybe<MedspasMedicallicenseStddevFields>;
  stddevPop?: Maybe<MedspasMedicallicenseStddevPopFields>;
  stddevSamp?: Maybe<MedspasMedicallicenseStddevSampFields>;
  sum?: Maybe<MedspasMedicallicenseSumFields>;
  varPop?: Maybe<MedspasMedicallicenseVarPopFields>;
  varSamp?: Maybe<MedspasMedicallicenseVarSampFields>;
  variance?: Maybe<MedspasMedicallicenseVarianceFields>;
};

/** aggregate fields of "medspas_medicallicense" */
export type MedspasMedicallicenseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspasMedicallicenseSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type MedspasMedicallicenseAvgFields = {
  __typename?: "MedspasMedicallicenseAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "medspas_medicallicense". All fields are combined with a logical 'AND'. */
export type MedspasMedicallicenseBoolExp = {
  _and?: InputMaybe<Array<MedspasMedicallicenseBoolExp>>;
  _not?: InputMaybe<MedspasMedicallicenseBoolExp>;
  _or?: InputMaybe<Array<MedspasMedicallicenseBoolExp>>;
  abbreviation?: InputMaybe<StringComparisonExp>;
  allowsForSelfGfe?: InputMaybe<BooleanComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  userMedspas?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
  userMedspasAggregate?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesAggregateBoolExp>;
};

/** unique or primary key constraints on table "medspas_medicallicense" */
export enum MedspasMedicallicenseConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasMedicallicensePkey = "medspas_medicallicense_pkey",
}

/** input type for incrementing numeric columns in table "medspas_medicallicense" */
export type MedspasMedicallicenseIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_medicallicense" */
export type MedspasMedicallicenseInsertInput = {
  abbreviation?: InputMaybe<Scalars["String"]["input"]>;
  allowsForSelfGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  userMedspas?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesArrRelInsertInput>;
};

/** aggregate max on columns */
export type MedspasMedicallicenseMaxFields = {
  __typename?: "MedspasMedicallicenseMaxFields";
  abbreviation?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type MedspasMedicallicenseMinFields = {
  __typename?: "MedspasMedicallicenseMinFields";
  abbreviation?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "medspas_medicallicense" */
export type MedspasMedicallicenseMutationResponse = {
  __typename?: "MedspasMedicallicenseMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspasMedicallicense>;
};

/** input type for inserting object relation for remote table "medspas_medicallicense" */
export type MedspasMedicallicenseObjRelInsertInput = {
  data: MedspasMedicallicenseInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedspasMedicallicenseOnConflict>;
};

/** on_conflict condition type for table "medspas_medicallicense" */
export type MedspasMedicallicenseOnConflict = {
  constraint: MedspasMedicallicenseConstraint;
  updateColumns?: Array<MedspasMedicallicenseUpdateColumn>;
  where?: InputMaybe<MedspasMedicallicenseBoolExp>;
};

/** Ordering options when selecting data from "medspas_medicallicense". */
export type MedspasMedicallicenseOrderBy = {
  abbreviation?: InputMaybe<OrderBy>;
  allowsForSelfGfe?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  userMedspasAggregate?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesAggregateOrderBy>;
};

/** primary key columns input for table: medspas_medicallicense */
export type MedspasMedicallicensePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_medicallicense" */
export enum MedspasMedicallicenseSelectColumn {
  /** column name */
  Abbreviation = "abbreviation",
  /** column name */
  AllowsForSelfGfe = "allowsForSelfGfe",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "medspas_medicallicense" */
export type MedspasMedicallicenseSetInput = {
  abbreviation?: InputMaybe<Scalars["String"]["input"]>;
  allowsForSelfGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspasMedicallicenseStddevFields = {
  __typename?: "MedspasMedicallicenseStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type MedspasMedicallicenseStddevPopFields = {
  __typename?: "MedspasMedicallicenseStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type MedspasMedicallicenseStddevSampFields = {
  __typename?: "MedspasMedicallicenseStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "medspas_medicallicense" */
export type MedspasMedicallicenseStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspasMedicallicenseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspasMedicallicenseStreamCursorValueInput = {
  abbreviation?: InputMaybe<Scalars["String"]["input"]>;
  allowsForSelfGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MedspasMedicallicenseSumFields = {
  __typename?: "MedspasMedicallicenseSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "medspas_medicallicense" */
export enum MedspasMedicallicenseUpdateColumn {
  /** column name */
  Abbreviation = "abbreviation",
  /** column name */
  AllowsForSelfGfe = "allowsForSelfGfe",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type MedspasMedicallicenseUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspasMedicallicenseIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspasMedicallicenseSetInput>;
  /** filter the rows which have to be updated */
  where: MedspasMedicallicenseBoolExp;
};

/** aggregate varPop on columns */
export type MedspasMedicallicenseVarPopFields = {
  __typename?: "MedspasMedicallicenseVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type MedspasMedicallicenseVarSampFields = {
  __typename?: "MedspasMedicallicenseVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type MedspasMedicallicenseVarianceFields = {
  __typename?: "MedspasMedicallicenseVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "medspas_medspaconfiguration" */
export type MedspasMedspaconfiguration = {
  __typename?: "MedspasMedspaconfiguration";
  additionalInfo?: Maybe<MedSpaConfigurationType>;
  /** An object relationship */
  adverseReactionTemplate?: Maybe<AdverseReactionTemplate>;
  adverseReactionTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  afterAppointmentMailAdditionalText: Scalars["String"]["output"];
  afterAppointmentSmsAdditionalText: Scalars["String"]["output"];
  afterPaymentReceiptMailAdditionalText: Scalars["String"]["output"];
  afterPaymentReceiptSmsAdditionalText: Scalars["String"]["output"];
  /** Indicates if deposits are allowed for this MedSpa. */
  allowDeposits: Scalars["Boolean"]["output"];
  appointmentConfirmedSmsAdditionalText: Scalars["String"]["output"];
  bookingConfirmationMailAdditionalText: Scalars["String"]["output"];
  bookingConfirmationSmsAdditionalText: Scalars["String"]["output"];
  chartOverdueHours: Scalars["Int"]["output"];
  /** Client affirmation text associated with this medspa configuration. */
  clientAffirmation: Scalars["String"]["output"];
  collectCardOnBooking: Scalars["Boolean"]["output"];
  collectTipsOnStripeTerminal: Scalars["Boolean"]["output"];
  confirmationReminderAdvanceHours: Scalars["Int"]["output"];
  confirmationReminderMailAdditionalText: Scalars["String"]["output"];
  confirmationReminderSmsAdditionalText: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** Booking link customization is enabled */
  customizationEnabled: Scalars["Boolean"]["output"];
  /** Stores the color in RGB format used in booking link customization */
  customizationPrimaryColor: Scalars["String"]["output"];
  /** Stores the text color depending on the primary color for booking link */
  customizationTextColor: Scalars["String"]["output"];
  /** An object relationship */
  defaultDiagramTemplate: DiagramTemplate;
  defaultDiagramTemplateId: Scalars["bigint"]["output"];
  /** An object relationship */
  defaultNoteTemplate?: Maybe<NoteTemplate>;
  defaultNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  defaultSaveCardAfterPayment: Scalars["Boolean"]["output"];
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount: Scalars["numeric"]["output"];
  /** Featured deals section is enabled */
  featuredDealsSectionEnabled: Scalars["Boolean"]["output"];
  followupNoteDelayHours: Scalars["Int"]["output"];
  followupNoteMailAdditionalText: Scalars["String"]["output"];
  followupNoteSmsText: Scalars["String"]["output"];
  /** Phone number to forward incoming from Twilio calls to (if enabled) */
  forwardIncomingCallsToPhone: Scalars["String"]["output"];
  /** Categories grouping for service menu items is enabled */
  groupServiceMenuItemsByCategoriesEnabled: Scalars["Boolean"]["output"];
  lateCancellationAdvanceHours: Scalars["Int"]["output"];
  maximumAdvanceBookingHours: Scalars["Int"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  minimumBookingNoticeHours: Scalars["Int"]["output"];
  modified: Scalars["timestamptz"]["output"];
  noShowFeeValue: Scalars["Int"]["output"];
  onlineStoreDesktopBanner?: Maybe<Scalars["String"]["output"]>;
  onlineStoreMobileBanner?: Maybe<Scalars["String"]["output"]>;
  privacyTelehealthTerms: Scalars["String"]["output"];
  secondReminderAdvanceHours: Scalars["Int"]["output"];
  secondReminderMailAdditionalText: Scalars["String"]["output"];
  secondReminderSmsAdditionalText: Scalars["String"]["output"];
  sendAfterAppointmentNote: Scalars["Boolean"]["output"];
  sendAfterPaymentReceipt: Scalars["Boolean"]["output"];
  sendBookingConfirmation: Scalars["Boolean"]["output"];
  sendConfirmationReminder: Scalars["Boolean"]["output"];
  sendFollowupNote: Scalars["Boolean"]["output"];
  sendOnRescheduleConfirmation: Scalars["Boolean"]["output"];
  sendSecondReminder: Scalars["Boolean"]["output"];
  showServicePriceOnBooking: Scalars["Boolean"]["output"];
};

/** aggregated selection of "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationAggregate = {
  __typename?: "MedspasMedspaconfigurationAggregate";
  aggregate?: Maybe<MedspasMedspaconfigurationAggregateFields>;
  nodes: Array<MedspasMedspaconfiguration>;
};

export type MedspasMedspaconfigurationAggregateBoolExp = {
  bool_and?: InputMaybe<MedspasMedspaconfigurationAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MedspasMedspaconfigurationAggregateBoolExpBool_Or>;
  count?: InputMaybe<MedspasMedspaconfigurationAggregateBoolExpCount>;
};

/** aggregate fields of "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationAggregateFields = {
  __typename?: "MedspasMedspaconfigurationAggregateFields";
  avg?: Maybe<MedspasMedspaconfigurationAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspasMedspaconfigurationMaxFields>;
  min?: Maybe<MedspasMedspaconfigurationMinFields>;
  stddev?: Maybe<MedspasMedspaconfigurationStddevFields>;
  stddevPop?: Maybe<MedspasMedspaconfigurationStddevPopFields>;
  stddevSamp?: Maybe<MedspasMedspaconfigurationStddevSampFields>;
  sum?: Maybe<MedspasMedspaconfigurationSumFields>;
  varPop?: Maybe<MedspasMedspaconfigurationVarPopFields>;
  varSamp?: Maybe<MedspasMedspaconfigurationVarSampFields>;
  variance?: Maybe<MedspasMedspaconfigurationVarianceFields>;
};

/** aggregate fields of "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationAggregateOrderBy = {
  avg?: InputMaybe<MedspasMedspaconfigurationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspasMedspaconfigurationMaxOrderBy>;
  min?: InputMaybe<MedspasMedspaconfigurationMinOrderBy>;
  stddev?: InputMaybe<MedspasMedspaconfigurationStddevOrderBy>;
  stddevPop?: InputMaybe<MedspasMedspaconfigurationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspasMedspaconfigurationStddevSampOrderBy>;
  sum?: InputMaybe<MedspasMedspaconfigurationSumOrderBy>;
  varPop?: InputMaybe<MedspasMedspaconfigurationVarPopOrderBy>;
  varSamp?: InputMaybe<MedspasMedspaconfigurationVarSampOrderBy>;
  variance?: InputMaybe<MedspasMedspaconfigurationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationArrRelInsertInput = {
  data: Array<MedspasMedspaconfigurationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspasMedspaconfigurationOnConflict>;
};

/** aggregate avg on columns */
export type MedspasMedspaconfigurationAvgFields = {
  __typename?: "MedspasMedspaconfigurationAvgFields";
  adverseReactionTemplateId?: Maybe<Scalars["Float"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Float"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["Float"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["Float"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Float"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Float"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Float"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationAvgOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_medspaconfiguration". All fields are combined with a logical 'AND'. */
export type MedspasMedspaconfigurationBoolExp = {
  _and?: InputMaybe<Array<MedspasMedspaconfigurationBoolExp>>;
  _not?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
  _or?: InputMaybe<Array<MedspasMedspaconfigurationBoolExp>>;
  adverseReactionTemplate?: InputMaybe<AdverseReactionTemplateBoolExp>;
  adverseReactionTemplateId?: InputMaybe<BigintComparisonExp>;
  afterAppointmentMailAdditionalText?: InputMaybe<StringComparisonExp>;
  afterAppointmentSmsAdditionalText?: InputMaybe<StringComparisonExp>;
  afterPaymentReceiptMailAdditionalText?: InputMaybe<StringComparisonExp>;
  afterPaymentReceiptSmsAdditionalText?: InputMaybe<StringComparisonExp>;
  allowDeposits?: InputMaybe<BooleanComparisonExp>;
  appointmentConfirmedSmsAdditionalText?: InputMaybe<StringComparisonExp>;
  bookingConfirmationMailAdditionalText?: InputMaybe<StringComparisonExp>;
  bookingConfirmationSmsAdditionalText?: InputMaybe<StringComparisonExp>;
  chartOverdueHours?: InputMaybe<IntComparisonExp>;
  clientAffirmation?: InputMaybe<StringComparisonExp>;
  collectCardOnBooking?: InputMaybe<BooleanComparisonExp>;
  collectTipsOnStripeTerminal?: InputMaybe<BooleanComparisonExp>;
  confirmationReminderAdvanceHours?: InputMaybe<IntComparisonExp>;
  confirmationReminderMailAdditionalText?: InputMaybe<StringComparisonExp>;
  confirmationReminderSmsAdditionalText?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  customizationEnabled?: InputMaybe<BooleanComparisonExp>;
  customizationPrimaryColor?: InputMaybe<StringComparisonExp>;
  customizationTextColor?: InputMaybe<StringComparisonExp>;
  defaultDiagramTemplate?: InputMaybe<DiagramTemplateBoolExp>;
  defaultDiagramTemplateId?: InputMaybe<BigintComparisonExp>;
  defaultNoteTemplate?: InputMaybe<NoteTemplateBoolExp>;
  defaultNoteTemplateId?: InputMaybe<BigintComparisonExp>;
  defaultSaveCardAfterPayment?: InputMaybe<BooleanComparisonExp>;
  depositAmount?: InputMaybe<NumericComparisonExp>;
  featuredDealsSectionEnabled?: InputMaybe<BooleanComparisonExp>;
  followupNoteDelayHours?: InputMaybe<IntComparisonExp>;
  followupNoteMailAdditionalText?: InputMaybe<StringComparisonExp>;
  followupNoteSmsText?: InputMaybe<StringComparisonExp>;
  forwardIncomingCallsToPhone?: InputMaybe<StringComparisonExp>;
  groupServiceMenuItemsByCategoriesEnabled?: InputMaybe<BooleanComparisonExp>;
  lateCancellationAdvanceHours?: InputMaybe<IntComparisonExp>;
  maximumAdvanceBookingHours?: InputMaybe<IntComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  minimumBookingNoticeHours?: InputMaybe<IntComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  noShowFeeValue?: InputMaybe<IntComparisonExp>;
  onlineStoreDesktopBanner?: InputMaybe<StringComparisonExp>;
  onlineStoreMobileBanner?: InputMaybe<StringComparisonExp>;
  privacyTelehealthTerms?: InputMaybe<StringComparisonExp>;
  secondReminderAdvanceHours?: InputMaybe<IntComparisonExp>;
  secondReminderMailAdditionalText?: InputMaybe<StringComparisonExp>;
  secondReminderSmsAdditionalText?: InputMaybe<StringComparisonExp>;
  sendAfterAppointmentNote?: InputMaybe<BooleanComparisonExp>;
  sendAfterPaymentReceipt?: InputMaybe<BooleanComparisonExp>;
  sendBookingConfirmation?: InputMaybe<BooleanComparisonExp>;
  sendConfirmationReminder?: InputMaybe<BooleanComparisonExp>;
  sendFollowupNote?: InputMaybe<BooleanComparisonExp>;
  sendOnRescheduleConfirmation?: InputMaybe<BooleanComparisonExp>;
  sendSecondReminder?: InputMaybe<BooleanComparisonExp>;
  showServicePriceOnBooking?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "medspas_medspaconfiguration" */
export enum MedspasMedspaconfigurationConstraint {
  /** unique or primary key constraint on columns "medspa_id" */
  MedspasMedspaconfigurationPkey = "medspas_medspaconfiguration_pkey",
}

/** input type for incrementing numeric columns in table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationIncInput = {
  adverseReactionTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  chartOverdueHours?: InputMaybe<Scalars["Int"]["input"]>;
  confirmationReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  defaultDiagramTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  defaultNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  followupNoteDelayHours?: InputMaybe<Scalars["Int"]["input"]>;
  lateCancellationAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  maximumAdvanceBookingHours?: InputMaybe<Scalars["Int"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  minimumBookingNoticeHours?: InputMaybe<Scalars["Int"]["input"]>;
  noShowFeeValue?: InputMaybe<Scalars["Int"]["input"]>;
  secondReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationInsertInput = {
  adverseReactionTemplate?: InputMaybe<AdverseReactionTemplateObjRelInsertInput>;
  adverseReactionTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  afterAppointmentMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  afterAppointmentSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  afterPaymentReceiptMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  afterPaymentReceiptSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates if deposits are allowed for this MedSpa. */
  allowDeposits?: InputMaybe<Scalars["Boolean"]["input"]>;
  appointmentConfirmedSmsAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  bookingConfirmationMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  bookingConfirmationSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  chartOverdueHours?: InputMaybe<Scalars["Int"]["input"]>;
  /** Client affirmation text associated with this medspa configuration. */
  clientAffirmation?: InputMaybe<Scalars["String"]["input"]>;
  collectCardOnBooking?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectTipsOnStripeTerminal?: InputMaybe<Scalars["Boolean"]["input"]>;
  confirmationReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  confirmationReminderMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  confirmationReminderSmsAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Booking link customization is enabled */
  customizationEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Stores the color in RGB format used in booking link customization */
  customizationPrimaryColor?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores the text color depending on the primary color for booking link */
  customizationTextColor?: InputMaybe<Scalars["String"]["input"]>;
  defaultDiagramTemplate?: InputMaybe<DiagramTemplateObjRelInsertInput>;
  defaultDiagramTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  defaultNoteTemplate?: InputMaybe<NoteTemplateObjRelInsertInput>;
  defaultNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  defaultSaveCardAfterPayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Featured deals section is enabled */
  featuredDealsSectionEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  followupNoteDelayHours?: InputMaybe<Scalars["Int"]["input"]>;
  followupNoteMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  followupNoteSmsText?: InputMaybe<Scalars["String"]["input"]>;
  /** Phone number to forward incoming from Twilio calls to (if enabled) */
  forwardIncomingCallsToPhone?: InputMaybe<Scalars["String"]["input"]>;
  /** Categories grouping for service menu items is enabled */
  groupServiceMenuItemsByCategoriesEnabled?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  lateCancellationAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  maximumAdvanceBookingHours?: InputMaybe<Scalars["Int"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  minimumBookingNoticeHours?: InputMaybe<Scalars["Int"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noShowFeeValue?: InputMaybe<Scalars["Int"]["input"]>;
  onlineStoreDesktopBanner?: InputMaybe<Scalars["String"]["input"]>;
  onlineStoreMobileBanner?: InputMaybe<Scalars["String"]["input"]>;
  privacyTelehealthTerms?: InputMaybe<Scalars["String"]["input"]>;
  secondReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  secondReminderMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  secondReminderSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  sendAfterAppointmentNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendAfterPaymentReceipt?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendBookingConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendConfirmationReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendFollowupNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendOnRescheduleConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendSecondReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  showServicePriceOnBooking?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type MedspasMedspaconfigurationMaxFields = {
  __typename?: "MedspasMedspaconfigurationMaxFields";
  adverseReactionTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  afterAppointmentMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  afterAppointmentSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  afterPaymentReceiptMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  afterPaymentReceiptSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  appointmentConfirmedSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  bookingConfirmationMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  bookingConfirmationSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Int"]["output"]>;
  /** Client affirmation text associated with this medspa configuration. */
  clientAffirmation?: Maybe<Scalars["String"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  confirmationReminderMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  confirmationReminderSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the color in RGB format used in booking link customization */
  customizationPrimaryColor?: Maybe<Scalars["String"]["output"]>;
  /** Stores the text color depending on the primary color for booking link */
  customizationTextColor?: Maybe<Scalars["String"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["numeric"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Int"]["output"]>;
  followupNoteMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  followupNoteSmsText?: Maybe<Scalars["String"]["output"]>;
  /** Phone number to forward incoming from Twilio calls to (if enabled) */
  forwardIncomingCallsToPhone?: Maybe<Scalars["String"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Int"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Int"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Int"]["output"]>;
  onlineStoreDesktopBanner?: Maybe<Scalars["String"]["output"]>;
  onlineStoreMobileBanner?: Maybe<Scalars["String"]["output"]>;
  privacyTelehealthTerms?: Maybe<Scalars["String"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  secondReminderMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  secondReminderSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationMaxOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  afterAppointmentMailAdditionalText?: InputMaybe<OrderBy>;
  afterAppointmentSmsAdditionalText?: InputMaybe<OrderBy>;
  afterPaymentReceiptMailAdditionalText?: InputMaybe<OrderBy>;
  afterPaymentReceiptSmsAdditionalText?: InputMaybe<OrderBy>;
  appointmentConfirmedSmsAdditionalText?: InputMaybe<OrderBy>;
  bookingConfirmationMailAdditionalText?: InputMaybe<OrderBy>;
  bookingConfirmationSmsAdditionalText?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  /** Client affirmation text associated with this medspa configuration. */
  clientAffirmation?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  confirmationReminderMailAdditionalText?: InputMaybe<OrderBy>;
  confirmationReminderSmsAdditionalText?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Stores the color in RGB format used in booking link customization */
  customizationPrimaryColor?: InputMaybe<OrderBy>;
  /** Stores the text color depending on the primary color for booking link */
  customizationTextColor?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  followupNoteMailAdditionalText?: InputMaybe<OrderBy>;
  followupNoteSmsText?: InputMaybe<OrderBy>;
  /** Phone number to forward incoming from Twilio calls to (if enabled) */
  forwardIncomingCallsToPhone?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  onlineStoreDesktopBanner?: InputMaybe<OrderBy>;
  onlineStoreMobileBanner?: InputMaybe<OrderBy>;
  privacyTelehealthTerms?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
  secondReminderMailAdditionalText?: InputMaybe<OrderBy>;
  secondReminderSmsAdditionalText?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspasMedspaconfigurationMinFields = {
  __typename?: "MedspasMedspaconfigurationMinFields";
  adverseReactionTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  afterAppointmentMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  afterAppointmentSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  afterPaymentReceiptMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  afterPaymentReceiptSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  appointmentConfirmedSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  bookingConfirmationMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  bookingConfirmationSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Int"]["output"]>;
  /** Client affirmation text associated with this medspa configuration. */
  clientAffirmation?: Maybe<Scalars["String"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  confirmationReminderMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  confirmationReminderSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the color in RGB format used in booking link customization */
  customizationPrimaryColor?: Maybe<Scalars["String"]["output"]>;
  /** Stores the text color depending on the primary color for booking link */
  customizationTextColor?: Maybe<Scalars["String"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["numeric"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Int"]["output"]>;
  followupNoteMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  followupNoteSmsText?: Maybe<Scalars["String"]["output"]>;
  /** Phone number to forward incoming from Twilio calls to (if enabled) */
  forwardIncomingCallsToPhone?: Maybe<Scalars["String"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Int"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Int"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Int"]["output"]>;
  onlineStoreDesktopBanner?: Maybe<Scalars["String"]["output"]>;
  onlineStoreMobileBanner?: Maybe<Scalars["String"]["output"]>;
  privacyTelehealthTerms?: Maybe<Scalars["String"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  secondReminderMailAdditionalText?: Maybe<Scalars["String"]["output"]>;
  secondReminderSmsAdditionalText?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationMinOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  afterAppointmentMailAdditionalText?: InputMaybe<OrderBy>;
  afterAppointmentSmsAdditionalText?: InputMaybe<OrderBy>;
  afterPaymentReceiptMailAdditionalText?: InputMaybe<OrderBy>;
  afterPaymentReceiptSmsAdditionalText?: InputMaybe<OrderBy>;
  appointmentConfirmedSmsAdditionalText?: InputMaybe<OrderBy>;
  bookingConfirmationMailAdditionalText?: InputMaybe<OrderBy>;
  bookingConfirmationSmsAdditionalText?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  /** Client affirmation text associated with this medspa configuration. */
  clientAffirmation?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  confirmationReminderMailAdditionalText?: InputMaybe<OrderBy>;
  confirmationReminderSmsAdditionalText?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Stores the color in RGB format used in booking link customization */
  customizationPrimaryColor?: InputMaybe<OrderBy>;
  /** Stores the text color depending on the primary color for booking link */
  customizationTextColor?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  followupNoteMailAdditionalText?: InputMaybe<OrderBy>;
  followupNoteSmsText?: InputMaybe<OrderBy>;
  /** Phone number to forward incoming from Twilio calls to (if enabled) */
  forwardIncomingCallsToPhone?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  onlineStoreDesktopBanner?: InputMaybe<OrderBy>;
  onlineStoreMobileBanner?: InputMaybe<OrderBy>;
  privacyTelehealthTerms?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
  secondReminderMailAdditionalText?: InputMaybe<OrderBy>;
  secondReminderSmsAdditionalText?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationMutationResponse = {
  __typename?: "MedspasMedspaconfigurationMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspasMedspaconfiguration>;
};

/** input type for inserting object relation for remote table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationObjRelInsertInput = {
  data: MedspasMedspaconfigurationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedspasMedspaconfigurationOnConflict>;
};

/** on_conflict condition type for table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationOnConflict = {
  constraint: MedspasMedspaconfigurationConstraint;
  updateColumns?: Array<MedspasMedspaconfigurationUpdateColumn>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

/** Ordering options when selecting data from "medspas_medspaconfiguration". */
export type MedspasMedspaconfigurationOrderBy = {
  adverseReactionTemplate?: InputMaybe<AdverseReactionTemplateOrderBy>;
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  afterAppointmentMailAdditionalText?: InputMaybe<OrderBy>;
  afterAppointmentSmsAdditionalText?: InputMaybe<OrderBy>;
  afterPaymentReceiptMailAdditionalText?: InputMaybe<OrderBy>;
  afterPaymentReceiptSmsAdditionalText?: InputMaybe<OrderBy>;
  allowDeposits?: InputMaybe<OrderBy>;
  appointmentConfirmedSmsAdditionalText?: InputMaybe<OrderBy>;
  bookingConfirmationMailAdditionalText?: InputMaybe<OrderBy>;
  bookingConfirmationSmsAdditionalText?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  clientAffirmation?: InputMaybe<OrderBy>;
  collectCardOnBooking?: InputMaybe<OrderBy>;
  collectTipsOnStripeTerminal?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  confirmationReminderMailAdditionalText?: InputMaybe<OrderBy>;
  confirmationReminderSmsAdditionalText?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  customizationEnabled?: InputMaybe<OrderBy>;
  customizationPrimaryColor?: InputMaybe<OrderBy>;
  customizationTextColor?: InputMaybe<OrderBy>;
  defaultDiagramTemplate?: InputMaybe<DiagramTemplateOrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplate?: InputMaybe<NoteTemplateOrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  defaultSaveCardAfterPayment?: InputMaybe<OrderBy>;
  depositAmount?: InputMaybe<OrderBy>;
  featuredDealsSectionEnabled?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  followupNoteMailAdditionalText?: InputMaybe<OrderBy>;
  followupNoteSmsText?: InputMaybe<OrderBy>;
  forwardIncomingCallsToPhone?: InputMaybe<OrderBy>;
  groupServiceMenuItemsByCategoriesEnabled?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  onlineStoreDesktopBanner?: InputMaybe<OrderBy>;
  onlineStoreMobileBanner?: InputMaybe<OrderBy>;
  privacyTelehealthTerms?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
  secondReminderMailAdditionalText?: InputMaybe<OrderBy>;
  secondReminderSmsAdditionalText?: InputMaybe<OrderBy>;
  sendAfterAppointmentNote?: InputMaybe<OrderBy>;
  sendAfterPaymentReceipt?: InputMaybe<OrderBy>;
  sendBookingConfirmation?: InputMaybe<OrderBy>;
  sendConfirmationReminder?: InputMaybe<OrderBy>;
  sendFollowupNote?: InputMaybe<OrderBy>;
  sendOnRescheduleConfirmation?: InputMaybe<OrderBy>;
  sendSecondReminder?: InputMaybe<OrderBy>;
  showServicePriceOnBooking?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_medspaconfiguration */
export type MedspasMedspaconfigurationPkColumnsInput = {
  medspaId: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_medspaconfiguration" */
export enum MedspasMedspaconfigurationSelectColumn {
  /** column name */
  AdverseReactionTemplateId = "adverseReactionTemplateId",
  /** column name */
  AfterAppointmentMailAdditionalText = "afterAppointmentMailAdditionalText",
  /** column name */
  AfterAppointmentSmsAdditionalText = "afterAppointmentSmsAdditionalText",
  /** column name */
  AfterPaymentReceiptMailAdditionalText = "afterPaymentReceiptMailAdditionalText",
  /** column name */
  AfterPaymentReceiptSmsAdditionalText = "afterPaymentReceiptSmsAdditionalText",
  /** column name */
  AllowDeposits = "allowDeposits",
  /** column name */
  AppointmentConfirmedSmsAdditionalText = "appointmentConfirmedSmsAdditionalText",
  /** column name */
  BookingConfirmationMailAdditionalText = "bookingConfirmationMailAdditionalText",
  /** column name */
  BookingConfirmationSmsAdditionalText = "bookingConfirmationSmsAdditionalText",
  /** column name */
  ChartOverdueHours = "chartOverdueHours",
  /** column name */
  ClientAffirmation = "clientAffirmation",
  /** column name */
  CollectCardOnBooking = "collectCardOnBooking",
  /** column name */
  CollectTipsOnStripeTerminal = "collectTipsOnStripeTerminal",
  /** column name */
  ConfirmationReminderAdvanceHours = "confirmationReminderAdvanceHours",
  /** column name */
  ConfirmationReminderMailAdditionalText = "confirmationReminderMailAdditionalText",
  /** column name */
  ConfirmationReminderSmsAdditionalText = "confirmationReminderSmsAdditionalText",
  /** column name */
  Created = "created",
  /** column name */
  CustomizationEnabled = "customizationEnabled",
  /** column name */
  CustomizationPrimaryColor = "customizationPrimaryColor",
  /** column name */
  CustomizationTextColor = "customizationTextColor",
  /** column name */
  DefaultDiagramTemplateId = "defaultDiagramTemplateId",
  /** column name */
  DefaultNoteTemplateId = "defaultNoteTemplateId",
  /** column name */
  DefaultSaveCardAfterPayment = "defaultSaveCardAfterPayment",
  /** column name */
  DepositAmount = "depositAmount",
  /** column name */
  FeaturedDealsSectionEnabled = "featuredDealsSectionEnabled",
  /** column name */
  FollowupNoteDelayHours = "followupNoteDelayHours",
  /** column name */
  FollowupNoteMailAdditionalText = "followupNoteMailAdditionalText",
  /** column name */
  FollowupNoteSmsText = "followupNoteSmsText",
  /** column name */
  ForwardIncomingCallsToPhone = "forwardIncomingCallsToPhone",
  /** column name */
  GroupServiceMenuItemsByCategoriesEnabled = "groupServiceMenuItemsByCategoriesEnabled",
  /** column name */
  LateCancellationAdvanceHours = "lateCancellationAdvanceHours",
  /** column name */
  MaximumAdvanceBookingHours = "maximumAdvanceBookingHours",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  MinimumBookingNoticeHours = "minimumBookingNoticeHours",
  /** column name */
  Modified = "modified",
  /** column name */
  NoShowFeeValue = "noShowFeeValue",
  /** column name */
  OnlineStoreDesktopBanner = "onlineStoreDesktopBanner",
  /** column name */
  OnlineStoreMobileBanner = "onlineStoreMobileBanner",
  /** column name */
  PrivacyTelehealthTerms = "privacyTelehealthTerms",
  /** column name */
  SecondReminderAdvanceHours = "secondReminderAdvanceHours",
  /** column name */
  SecondReminderMailAdditionalText = "secondReminderMailAdditionalText",
  /** column name */
  SecondReminderSmsAdditionalText = "secondReminderSmsAdditionalText",
  /** column name */
  SendAfterAppointmentNote = "sendAfterAppointmentNote",
  /** column name */
  SendAfterPaymentReceipt = "sendAfterPaymentReceipt",
  /** column name */
  SendBookingConfirmation = "sendBookingConfirmation",
  /** column name */
  SendConfirmationReminder = "sendConfirmationReminder",
  /** column name */
  SendFollowupNote = "sendFollowupNote",
  /** column name */
  SendOnRescheduleConfirmation = "sendOnRescheduleConfirmation",
  /** column name */
  SendSecondReminder = "sendSecondReminder",
  /** column name */
  ShowServicePriceOnBooking = "showServicePriceOnBooking",
}

/** select "medspasMedspaconfigurationAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_medspaconfiguration" */
export enum MedspasMedspaconfigurationSelectColumnMedspasMedspaconfigurationAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  AllowDeposits = "allowDeposits",
  /** column name */
  CollectCardOnBooking = "collectCardOnBooking",
  /** column name */
  CollectTipsOnStripeTerminal = "collectTipsOnStripeTerminal",
  /** column name */
  CustomizationEnabled = "customizationEnabled",
  /** column name */
  DefaultSaveCardAfterPayment = "defaultSaveCardAfterPayment",
  /** column name */
  FeaturedDealsSectionEnabled = "featuredDealsSectionEnabled",
  /** column name */
  GroupServiceMenuItemsByCategoriesEnabled = "groupServiceMenuItemsByCategoriesEnabled",
  /** column name */
  SendAfterAppointmentNote = "sendAfterAppointmentNote",
  /** column name */
  SendAfterPaymentReceipt = "sendAfterPaymentReceipt",
  /** column name */
  SendBookingConfirmation = "sendBookingConfirmation",
  /** column name */
  SendConfirmationReminder = "sendConfirmationReminder",
  /** column name */
  SendFollowupNote = "sendFollowupNote",
  /** column name */
  SendOnRescheduleConfirmation = "sendOnRescheduleConfirmation",
  /** column name */
  SendSecondReminder = "sendSecondReminder",
  /** column name */
  ShowServicePriceOnBooking = "showServicePriceOnBooking",
}

/** select "medspasMedspaconfigurationAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_medspaconfiguration" */
export enum MedspasMedspaconfigurationSelectColumnMedspasMedspaconfigurationAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  AllowDeposits = "allowDeposits",
  /** column name */
  CollectCardOnBooking = "collectCardOnBooking",
  /** column name */
  CollectTipsOnStripeTerminal = "collectTipsOnStripeTerminal",
  /** column name */
  CustomizationEnabled = "customizationEnabled",
  /** column name */
  DefaultSaveCardAfterPayment = "defaultSaveCardAfterPayment",
  /** column name */
  FeaturedDealsSectionEnabled = "featuredDealsSectionEnabled",
  /** column name */
  GroupServiceMenuItemsByCategoriesEnabled = "groupServiceMenuItemsByCategoriesEnabled",
  /** column name */
  SendAfterAppointmentNote = "sendAfterAppointmentNote",
  /** column name */
  SendAfterPaymentReceipt = "sendAfterPaymentReceipt",
  /** column name */
  SendBookingConfirmation = "sendBookingConfirmation",
  /** column name */
  SendConfirmationReminder = "sendConfirmationReminder",
  /** column name */
  SendFollowupNote = "sendFollowupNote",
  /** column name */
  SendOnRescheduleConfirmation = "sendOnRescheduleConfirmation",
  /** column name */
  SendSecondReminder = "sendSecondReminder",
  /** column name */
  ShowServicePriceOnBooking = "showServicePriceOnBooking",
}

/** input type for updating data in table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationSetInput = {
  adverseReactionTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  afterAppointmentMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  afterAppointmentSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  afterPaymentReceiptMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  afterPaymentReceiptSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates if deposits are allowed for this MedSpa. */
  allowDeposits?: InputMaybe<Scalars["Boolean"]["input"]>;
  appointmentConfirmedSmsAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  bookingConfirmationMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  bookingConfirmationSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  chartOverdueHours?: InputMaybe<Scalars["Int"]["input"]>;
  /** Client affirmation text associated with this medspa configuration. */
  clientAffirmation?: InputMaybe<Scalars["String"]["input"]>;
  collectCardOnBooking?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectTipsOnStripeTerminal?: InputMaybe<Scalars["Boolean"]["input"]>;
  confirmationReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  confirmationReminderMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  confirmationReminderSmsAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Booking link customization is enabled */
  customizationEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Stores the color in RGB format used in booking link customization */
  customizationPrimaryColor?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores the text color depending on the primary color for booking link */
  customizationTextColor?: InputMaybe<Scalars["String"]["input"]>;
  defaultDiagramTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  defaultNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  defaultSaveCardAfterPayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Featured deals section is enabled */
  featuredDealsSectionEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  followupNoteDelayHours?: InputMaybe<Scalars["Int"]["input"]>;
  followupNoteMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  followupNoteSmsText?: InputMaybe<Scalars["String"]["input"]>;
  /** Phone number to forward incoming from Twilio calls to (if enabled) */
  forwardIncomingCallsToPhone?: InputMaybe<Scalars["String"]["input"]>;
  /** Categories grouping for service menu items is enabled */
  groupServiceMenuItemsByCategoriesEnabled?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  lateCancellationAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  maximumAdvanceBookingHours?: InputMaybe<Scalars["Int"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  minimumBookingNoticeHours?: InputMaybe<Scalars["Int"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noShowFeeValue?: InputMaybe<Scalars["Int"]["input"]>;
  onlineStoreDesktopBanner?: InputMaybe<Scalars["String"]["input"]>;
  onlineStoreMobileBanner?: InputMaybe<Scalars["String"]["input"]>;
  privacyTelehealthTerms?: InputMaybe<Scalars["String"]["input"]>;
  secondReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  secondReminderMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  secondReminderSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  sendAfterAppointmentNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendAfterPaymentReceipt?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendBookingConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendConfirmationReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendFollowupNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendOnRescheduleConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendSecondReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  showServicePriceOnBooking?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspasMedspaconfigurationStddevFields = {
  __typename?: "MedspasMedspaconfigurationStddevFields";
  adverseReactionTemplateId?: Maybe<Scalars["Float"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Float"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["Float"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["Float"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Float"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Float"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Float"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationStddevOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspasMedspaconfigurationStddevPopFields = {
  __typename?: "MedspasMedspaconfigurationStddevPopFields";
  adverseReactionTemplateId?: Maybe<Scalars["Float"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Float"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["Float"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["Float"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Float"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Float"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Float"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationStddevPopOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspasMedspaconfigurationStddevSampFields = {
  __typename?: "MedspasMedspaconfigurationStddevSampFields";
  adverseReactionTemplateId?: Maybe<Scalars["Float"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Float"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["Float"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["Float"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Float"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Float"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Float"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationStddevSampOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspasMedspaconfigurationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspasMedspaconfigurationStreamCursorValueInput = {
  adverseReactionTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  afterAppointmentMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  afterAppointmentSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  afterPaymentReceiptMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  afterPaymentReceiptSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  /** Indicates if deposits are allowed for this MedSpa. */
  allowDeposits?: InputMaybe<Scalars["Boolean"]["input"]>;
  appointmentConfirmedSmsAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  bookingConfirmationMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  bookingConfirmationSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  chartOverdueHours?: InputMaybe<Scalars["Int"]["input"]>;
  /** Client affirmation text associated with this medspa configuration. */
  clientAffirmation?: InputMaybe<Scalars["String"]["input"]>;
  collectCardOnBooking?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectTipsOnStripeTerminal?: InputMaybe<Scalars["Boolean"]["input"]>;
  confirmationReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  confirmationReminderMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  confirmationReminderSmsAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Booking link customization is enabled */
  customizationEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Stores the color in RGB format used in booking link customization */
  customizationPrimaryColor?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores the text color depending on the primary color for booking link */
  customizationTextColor?: InputMaybe<Scalars["String"]["input"]>;
  defaultDiagramTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  defaultNoteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  defaultSaveCardAfterPayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Featured deals section is enabled */
  featuredDealsSectionEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  followupNoteDelayHours?: InputMaybe<Scalars["Int"]["input"]>;
  followupNoteMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  followupNoteSmsText?: InputMaybe<Scalars["String"]["input"]>;
  /** Phone number to forward incoming from Twilio calls to (if enabled) */
  forwardIncomingCallsToPhone?: InputMaybe<Scalars["String"]["input"]>;
  /** Categories grouping for service menu items is enabled */
  groupServiceMenuItemsByCategoriesEnabled?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  lateCancellationAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  maximumAdvanceBookingHours?: InputMaybe<Scalars["Int"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  minimumBookingNoticeHours?: InputMaybe<Scalars["Int"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noShowFeeValue?: InputMaybe<Scalars["Int"]["input"]>;
  onlineStoreDesktopBanner?: InputMaybe<Scalars["String"]["input"]>;
  onlineStoreMobileBanner?: InputMaybe<Scalars["String"]["input"]>;
  privacyTelehealthTerms?: InputMaybe<Scalars["String"]["input"]>;
  secondReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  secondReminderMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  secondReminderSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  sendAfterAppointmentNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendAfterPaymentReceipt?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendBookingConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendConfirmationReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendFollowupNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendOnRescheduleConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendSecondReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  showServicePriceOnBooking?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate sum on columns */
export type MedspasMedspaconfigurationSumFields = {
  __typename?: "MedspasMedspaconfigurationSumFields";
  adverseReactionTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Int"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["numeric"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Int"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Int"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Int"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Int"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationSumOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_medspaconfiguration" */
export enum MedspasMedspaconfigurationUpdateColumn {
  /** column name */
  AdverseReactionTemplateId = "adverseReactionTemplateId",
  /** column name */
  AfterAppointmentMailAdditionalText = "afterAppointmentMailAdditionalText",
  /** column name */
  AfterAppointmentSmsAdditionalText = "afterAppointmentSmsAdditionalText",
  /** column name */
  AfterPaymentReceiptMailAdditionalText = "afterPaymentReceiptMailAdditionalText",
  /** column name */
  AfterPaymentReceiptSmsAdditionalText = "afterPaymentReceiptSmsAdditionalText",
  /** column name */
  AllowDeposits = "allowDeposits",
  /** column name */
  AppointmentConfirmedSmsAdditionalText = "appointmentConfirmedSmsAdditionalText",
  /** column name */
  BookingConfirmationMailAdditionalText = "bookingConfirmationMailAdditionalText",
  /** column name */
  BookingConfirmationSmsAdditionalText = "bookingConfirmationSmsAdditionalText",
  /** column name */
  ChartOverdueHours = "chartOverdueHours",
  /** column name */
  ClientAffirmation = "clientAffirmation",
  /** column name */
  CollectCardOnBooking = "collectCardOnBooking",
  /** column name */
  CollectTipsOnStripeTerminal = "collectTipsOnStripeTerminal",
  /** column name */
  ConfirmationReminderAdvanceHours = "confirmationReminderAdvanceHours",
  /** column name */
  ConfirmationReminderMailAdditionalText = "confirmationReminderMailAdditionalText",
  /** column name */
  ConfirmationReminderSmsAdditionalText = "confirmationReminderSmsAdditionalText",
  /** column name */
  Created = "created",
  /** column name */
  CustomizationEnabled = "customizationEnabled",
  /** column name */
  CustomizationPrimaryColor = "customizationPrimaryColor",
  /** column name */
  CustomizationTextColor = "customizationTextColor",
  /** column name */
  DefaultDiagramTemplateId = "defaultDiagramTemplateId",
  /** column name */
  DefaultNoteTemplateId = "defaultNoteTemplateId",
  /** column name */
  DefaultSaveCardAfterPayment = "defaultSaveCardAfterPayment",
  /** column name */
  DepositAmount = "depositAmount",
  /** column name */
  FeaturedDealsSectionEnabled = "featuredDealsSectionEnabled",
  /** column name */
  FollowupNoteDelayHours = "followupNoteDelayHours",
  /** column name */
  FollowupNoteMailAdditionalText = "followupNoteMailAdditionalText",
  /** column name */
  FollowupNoteSmsText = "followupNoteSmsText",
  /** column name */
  ForwardIncomingCallsToPhone = "forwardIncomingCallsToPhone",
  /** column name */
  GroupServiceMenuItemsByCategoriesEnabled = "groupServiceMenuItemsByCategoriesEnabled",
  /** column name */
  LateCancellationAdvanceHours = "lateCancellationAdvanceHours",
  /** column name */
  MaximumAdvanceBookingHours = "maximumAdvanceBookingHours",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  MinimumBookingNoticeHours = "minimumBookingNoticeHours",
  /** column name */
  Modified = "modified",
  /** column name */
  NoShowFeeValue = "noShowFeeValue",
  /** column name */
  OnlineStoreDesktopBanner = "onlineStoreDesktopBanner",
  /** column name */
  OnlineStoreMobileBanner = "onlineStoreMobileBanner",
  /** column name */
  PrivacyTelehealthTerms = "privacyTelehealthTerms",
  /** column name */
  SecondReminderAdvanceHours = "secondReminderAdvanceHours",
  /** column name */
  SecondReminderMailAdditionalText = "secondReminderMailAdditionalText",
  /** column name */
  SecondReminderSmsAdditionalText = "secondReminderSmsAdditionalText",
  /** column name */
  SendAfterAppointmentNote = "sendAfterAppointmentNote",
  /** column name */
  SendAfterPaymentReceipt = "sendAfterPaymentReceipt",
  /** column name */
  SendBookingConfirmation = "sendBookingConfirmation",
  /** column name */
  SendConfirmationReminder = "sendConfirmationReminder",
  /** column name */
  SendFollowupNote = "sendFollowupNote",
  /** column name */
  SendOnRescheduleConfirmation = "sendOnRescheduleConfirmation",
  /** column name */
  SendSecondReminder = "sendSecondReminder",
  /** column name */
  ShowServicePriceOnBooking = "showServicePriceOnBooking",
}

export type MedspasMedspaconfigurationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspasMedspaconfigurationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspasMedspaconfigurationSetInput>;
  /** filter the rows which have to be updated */
  where: MedspasMedspaconfigurationBoolExp;
};

/** aggregate varPop on columns */
export type MedspasMedspaconfigurationVarPopFields = {
  __typename?: "MedspasMedspaconfigurationVarPopFields";
  adverseReactionTemplateId?: Maybe<Scalars["Float"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Float"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["Float"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["Float"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Float"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Float"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Float"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationVarPopOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspasMedspaconfigurationVarSampFields = {
  __typename?: "MedspasMedspaconfigurationVarSampFields";
  adverseReactionTemplateId?: Maybe<Scalars["Float"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Float"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["Float"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["Float"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Float"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Float"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Float"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationVarSampOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspasMedspaconfigurationVarianceFields = {
  __typename?: "MedspasMedspaconfigurationVarianceFields";
  adverseReactionTemplateId?: Maybe<Scalars["Float"]["output"]>;
  chartOverdueHours?: Maybe<Scalars["Float"]["output"]>;
  confirmationReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  defaultDiagramTemplateId?: Maybe<Scalars["Float"]["output"]>;
  defaultNoteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: Maybe<Scalars["Float"]["output"]>;
  followupNoteDelayHours?: Maybe<Scalars["Float"]["output"]>;
  lateCancellationAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
  maximumAdvanceBookingHours?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBookingNoticeHours?: Maybe<Scalars["Float"]["output"]>;
  noShowFeeValue?: Maybe<Scalars["Float"]["output"]>;
  secondReminderAdvanceHours?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_medspaconfiguration" */
export type MedspasMedspaconfigurationVarianceOrderBy = {
  adverseReactionTemplateId?: InputMaybe<OrderBy>;
  chartOverdueHours?: InputMaybe<OrderBy>;
  confirmationReminderAdvanceHours?: InputMaybe<OrderBy>;
  defaultDiagramTemplateId?: InputMaybe<OrderBy>;
  defaultNoteTemplateId?: InputMaybe<OrderBy>;
  /** Amount of the deposit required, if deposits are allowed. */
  depositAmount?: InputMaybe<OrderBy>;
  followupNoteDelayHours?: InputMaybe<OrderBy>;
  lateCancellationAdvanceHours?: InputMaybe<OrderBy>;
  maximumAdvanceBookingHours?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBookingNoticeHours?: InputMaybe<OrderBy>;
  noShowFeeValue?: InputMaybe<OrderBy>;
  secondReminderAdvanceHours?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspas_professionalcorporation" */
export type MedspasProfessionalcorporation = {
  __typename?: "MedspasProfessionalcorporation";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  companyName: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  employerIdentificationNumber: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  isMoxiePc: Scalars["Boolean"]["output"];
  /** Whether the Compliance Tasks for the MedSpas associated to this PC are managed by the PC. */
  managesComplianceTasks: Scalars["Boolean"]["output"];
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  medspaProfessionalCorporation: Array<Medspa>;
  /** An aggregate relationship */
  medspaProfessionalCorporationAggregate: MedspaAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Relationship/type of PC relative to Moxie */
  pcType: Scalars["String"]["output"];
};

/** columns and relationships of "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationMedspaProfessionalCorporationArgs = {
  distinctOn?: InputMaybe<Array<MedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaOrderBy>>;
  where?: InputMaybe<MedspaBoolExp>;
};

/** columns and relationships of "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationMedspaProfessionalCorporationAggregateArgs =
  {
    distinctOn?: InputMaybe<Array<MedspaSelectColumn>>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<MedspaOrderBy>>;
    where?: InputMaybe<MedspaBoolExp>;
  };

/** aggregated selection of "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationAggregate = {
  __typename?: "MedspasProfessionalcorporationAggregate";
  aggregate?: Maybe<MedspasProfessionalcorporationAggregateFields>;
  nodes: Array<MedspasProfessionalcorporation>;
};

export type MedspasProfessionalcorporationAggregateBoolExp = {
  bool_and?: InputMaybe<MedspasProfessionalcorporationAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MedspasProfessionalcorporationAggregateBoolExpBool_Or>;
  count?: InputMaybe<MedspasProfessionalcorporationAggregateBoolExpCount>;
};

/** aggregate fields of "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationAggregateFields = {
  __typename?: "MedspasProfessionalcorporationAggregateFields";
  avg?: Maybe<MedspasProfessionalcorporationAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspasProfessionalcorporationMaxFields>;
  min?: Maybe<MedspasProfessionalcorporationMinFields>;
  stddev?: Maybe<MedspasProfessionalcorporationStddevFields>;
  stddevPop?: Maybe<MedspasProfessionalcorporationStddevPopFields>;
  stddevSamp?: Maybe<MedspasProfessionalcorporationStddevSampFields>;
  sum?: Maybe<MedspasProfessionalcorporationSumFields>;
  varPop?: Maybe<MedspasProfessionalcorporationVarPopFields>;
  varSamp?: Maybe<MedspasProfessionalcorporationVarSampFields>;
  variance?: Maybe<MedspasProfessionalcorporationVarianceFields>;
};

/** aggregate fields of "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspasProfessionalcorporationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationAggregateOrderBy = {
  avg?: InputMaybe<MedspasProfessionalcorporationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspasProfessionalcorporationMaxOrderBy>;
  min?: InputMaybe<MedspasProfessionalcorporationMinOrderBy>;
  stddev?: InputMaybe<MedspasProfessionalcorporationStddevOrderBy>;
  stddevPop?: InputMaybe<MedspasProfessionalcorporationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspasProfessionalcorporationStddevSampOrderBy>;
  sum?: InputMaybe<MedspasProfessionalcorporationSumOrderBy>;
  varPop?: InputMaybe<MedspasProfessionalcorporationVarPopOrderBy>;
  varSamp?: InputMaybe<MedspasProfessionalcorporationVarSampOrderBy>;
  variance?: InputMaybe<MedspasProfessionalcorporationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationArrRelInsertInput = {
  data: Array<MedspasProfessionalcorporationInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspasProfessionalcorporationOnConflict>;
};

/** aggregate avg on columns */
export type MedspasProfessionalcorporationAvgFields = {
  __typename?: "MedspasProfessionalcorporationAvgFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  ownerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationAvgOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_professionalcorporation". All fields are combined with a logical 'AND'. */
export type MedspasProfessionalcorporationBoolExp = {
  _and?: InputMaybe<Array<MedspasProfessionalcorporationBoolExp>>;
  _not?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
  _or?: InputMaybe<Array<MedspasProfessionalcorporationBoolExp>>;
  addressId?: InputMaybe<BigintComparisonExp>;
  companyName?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  employerIdentificationNumber?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isMoxiePc?: InputMaybe<BooleanComparisonExp>;
  managesComplianceTasks?: InputMaybe<BooleanComparisonExp>;
  medicalDirectorId?: InputMaybe<BigintComparisonExp>;
  medspaProfessionalCorporation?: InputMaybe<MedspaBoolExp>;
  medspaProfessionalCorporationAggregate?: InputMaybe<MedspaAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  owner?: InputMaybe<UserBoolExp>;
  ownerId?: InputMaybe<BigintComparisonExp>;
  pcType?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "medspas_professionalcorporation" */
export enum MedspasProfessionalcorporationConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasProfessionalcorporationPkey = "medspas_professionalcorporation_pkey",
}

/** input type for incrementing numeric columns in table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationIncInput = {
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  ownerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationInsertInput = {
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  employerIdentificationNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isMoxiePc?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Whether the Compliance Tasks for the MedSpas associated to this PC are managed by the PC. */
  managesComplianceTasks?: InputMaybe<Scalars["Boolean"]["input"]>;
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaProfessionalCorporation?: InputMaybe<MedspaArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  owner?: InputMaybe<UserObjRelInsertInput>;
  ownerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Relationship/type of PC relative to Moxie */
  pcType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MedspasProfessionalcorporationMaxFields = {
  __typename?: "MedspasProfessionalcorporationMaxFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  employerIdentificationNumber?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  ownerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Relationship/type of PC relative to Moxie */
  pcType?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationMaxOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  employerIdentificationNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  /** Relationship/type of PC relative to Moxie */
  pcType?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspasProfessionalcorporationMinFields = {
  __typename?: "MedspasProfessionalcorporationMinFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  employerIdentificationNumber?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  ownerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Relationship/type of PC relative to Moxie */
  pcType?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationMinOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  employerIdentificationNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  /** Relationship/type of PC relative to Moxie */
  pcType?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationMutationResponse = {
  __typename?: "MedspasProfessionalcorporationMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspasProfessionalcorporation>;
};

/** input type for inserting object relation for remote table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationObjRelInsertInput = {
  data: MedspasProfessionalcorporationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedspasProfessionalcorporationOnConflict>;
};

/** on_conflict condition type for table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationOnConflict = {
  constraint: MedspasProfessionalcorporationConstraint;
  updateColumns?: Array<MedspasProfessionalcorporationUpdateColumn>;
  where?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
};

/** Ordering options when selecting data from "medspas_professionalcorporation". */
export type MedspasProfessionalcorporationOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  employerIdentificationNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isMoxiePc?: InputMaybe<OrderBy>;
  managesComplianceTasks?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaProfessionalCorporationAggregate?: InputMaybe<MedspaAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  owner?: InputMaybe<UserOrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  pcType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_professionalcorporation */
export type MedspasProfessionalcorporationPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_professionalcorporation" */
export enum MedspasProfessionalcorporationSelectColumn {
  /** column name */
  AddressId = "addressId",
  /** column name */
  CompanyName = "companyName",
  /** column name */
  Created = "created",
  /** column name */
  EmployerIdentificationNumber = "employerIdentificationNumber",
  /** column name */
  Id = "id",
  /** column name */
  IsMoxiePc = "isMoxiePc",
  /** column name */
  ManagesComplianceTasks = "managesComplianceTasks",
  /** column name */
  MedicalDirectorId = "medicalDirectorId",
  /** column name */
  Modified = "modified",
  /** column name */
  OwnerId = "ownerId",
  /** column name */
  PcType = "pcType",
}

/** select "medspasProfessionalcorporationAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_professionalcorporation" */
export enum MedspasProfessionalcorporationSelectColumnMedspasProfessionalcorporationAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsMoxiePc = "isMoxiePc",
  /** column name */
  ManagesComplianceTasks = "managesComplianceTasks",
}

/** select "medspasProfessionalcorporationAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_professionalcorporation" */
export enum MedspasProfessionalcorporationSelectColumnMedspasProfessionalcorporationAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsMoxiePc = "isMoxiePc",
  /** column name */
  ManagesComplianceTasks = "managesComplianceTasks",
}

/** input type for updating data in table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationSetInput = {
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  employerIdentificationNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isMoxiePc?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Whether the Compliance Tasks for the MedSpas associated to this PC are managed by the PC. */
  managesComplianceTasks?: InputMaybe<Scalars["Boolean"]["input"]>;
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  ownerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Relationship/type of PC relative to Moxie */
  pcType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspasProfessionalcorporationStddevFields = {
  __typename?: "MedspasProfessionalcorporationStddevFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  ownerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationStddevOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspasProfessionalcorporationStddevPopFields = {
  __typename?: "MedspasProfessionalcorporationStddevPopFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  ownerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationStddevPopOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspasProfessionalcorporationStddevSampFields = {
  __typename?: "MedspasProfessionalcorporationStddevSampFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  ownerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationStddevSampOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspasProfessionalcorporationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspasProfessionalcorporationStreamCursorValueInput = {
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  employerIdentificationNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isMoxiePc?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Whether the Compliance Tasks for the MedSpas associated to this PC are managed by the PC. */
  managesComplianceTasks?: InputMaybe<Scalars["Boolean"]["input"]>;
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  ownerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Relationship/type of PC relative to Moxie */
  pcType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MedspasProfessionalcorporationSumFields = {
  __typename?: "MedspasProfessionalcorporationSumFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  ownerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationSumOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_professionalcorporation" */
export enum MedspasProfessionalcorporationUpdateColumn {
  /** column name */
  AddressId = "addressId",
  /** column name */
  CompanyName = "companyName",
  /** column name */
  Created = "created",
  /** column name */
  EmployerIdentificationNumber = "employerIdentificationNumber",
  /** column name */
  Id = "id",
  /** column name */
  IsMoxiePc = "isMoxiePc",
  /** column name */
  ManagesComplianceTasks = "managesComplianceTasks",
  /** column name */
  MedicalDirectorId = "medicalDirectorId",
  /** column name */
  Modified = "modified",
  /** column name */
  OwnerId = "ownerId",
  /** column name */
  PcType = "pcType",
}

export type MedspasProfessionalcorporationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspasProfessionalcorporationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspasProfessionalcorporationSetInput>;
  /** filter the rows which have to be updated */
  where: MedspasProfessionalcorporationBoolExp;
};

/** aggregate varPop on columns */
export type MedspasProfessionalcorporationVarPopFields = {
  __typename?: "MedspasProfessionalcorporationVarPopFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  ownerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationVarPopOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspasProfessionalcorporationVarSampFields = {
  __typename?: "MedspasProfessionalcorporationVarSampFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  ownerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationVarSampOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspasProfessionalcorporationVarianceFields = {
  __typename?: "MedspasProfessionalcorporationVarianceFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  ownerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_professionalcorporation" */
export type MedspasProfessionalcorporationVarianceOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
};

/** An enumeration. */
export enum MedspasStripeTerminalTypeChoices {
  /** BBPOS WisePOS E */
  Bbpos = "BBPOS",
  /** M2 terminal */
  M2 = "M2",
  /** Tap 2 Pay */
  Tap2Pay = "TAP2PAY",
}

/** columns and relationships of "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethod = {
  __typename?: "MedspasStripeoutboundpaymentmethod";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  stripeCustomerId: Scalars["String"]["output"];
  stripeData?: Maybe<StripeOutboundPaymentMethodType>;
  stripePaymentMethodId: Scalars["String"]["output"];
};

/** aggregated selection of "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodAggregate = {
  __typename?: "MedspasStripeoutboundpaymentmethodAggregate";
  aggregate?: Maybe<MedspasStripeoutboundpaymentmethodAggregateFields>;
  nodes: Array<MedspasStripeoutboundpaymentmethod>;
};

/** aggregate fields of "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodAggregateFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodAggregateFields";
  avg?: Maybe<MedspasStripeoutboundpaymentmethodAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspasStripeoutboundpaymentmethodMaxFields>;
  min?: Maybe<MedspasStripeoutboundpaymentmethodMinFields>;
  stddev?: Maybe<MedspasStripeoutboundpaymentmethodStddevFields>;
  stddevPop?: Maybe<MedspasStripeoutboundpaymentmethodStddevPopFields>;
  stddevSamp?: Maybe<MedspasStripeoutboundpaymentmethodStddevSampFields>;
  sum?: Maybe<MedspasStripeoutboundpaymentmethodSumFields>;
  varPop?: Maybe<MedspasStripeoutboundpaymentmethodVarPopFields>;
  varSamp?: Maybe<MedspasStripeoutboundpaymentmethodVarSampFields>;
  variance?: Maybe<MedspasStripeoutboundpaymentmethodVarianceFields>;
};

/** aggregate fields of "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspasStripeoutboundpaymentmethodSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type MedspasStripeoutboundpaymentmethodAvgFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "medspas_stripeoutboundpaymentmethod". All fields are combined with a logical 'AND'. */
export type MedspasStripeoutboundpaymentmethodBoolExp = {
  _and?: InputMaybe<Array<MedspasStripeoutboundpaymentmethodBoolExp>>;
  _not?: InputMaybe<MedspasStripeoutboundpaymentmethodBoolExp>;
  _or?: InputMaybe<Array<MedspasStripeoutboundpaymentmethodBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  stripeCustomerId?: InputMaybe<StringComparisonExp>;
  stripePaymentMethodId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "medspas_stripeoutboundpaymentmethod" */
export enum MedspasStripeoutboundpaymentmethodConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasStripeoutboundpaymentmethodPkey = "medspas_stripeoutboundpaymentmethod_pkey",
}

/** input type for incrementing numeric columns in table "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripePaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MedspasStripeoutboundpaymentmethodMaxFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  stripePaymentMethodId?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type MedspasStripeoutboundpaymentmethodMinFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  stripePaymentMethodId?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodMutationResponse = {
  __typename?: "MedspasStripeoutboundpaymentmethodMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspasStripeoutboundpaymentmethod>;
};

/** on_conflict condition type for table "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodOnConflict = {
  constraint: MedspasStripeoutboundpaymentmethodConstraint;
  updateColumns?: Array<MedspasStripeoutboundpaymentmethodUpdateColumn>;
  where?: InputMaybe<MedspasStripeoutboundpaymentmethodBoolExp>;
};

/** Ordering options when selecting data from "medspas_stripeoutboundpaymentmethod". */
export type MedspasStripeoutboundpaymentmethodOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  stripeCustomerId?: InputMaybe<OrderBy>;
  stripePaymentMethodId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_stripeoutboundpaymentmethod */
export type MedspasStripeoutboundpaymentmethodPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_stripeoutboundpaymentmethod" */
export enum MedspasStripeoutboundpaymentmethodSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  StripeCustomerId = "stripeCustomerId",
  /** column name */
  StripePaymentMethodId = "stripePaymentMethodId",
}

/** input type for updating data in table "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripePaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspasStripeoutboundpaymentmethodStddevFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type MedspasStripeoutboundpaymentmethodStddevPopFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type MedspasStripeoutboundpaymentmethodStddevSampFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "medspas_stripeoutboundpaymentmethod" */
export type MedspasStripeoutboundpaymentmethodStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspasStripeoutboundpaymentmethodStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspasStripeoutboundpaymentmethodStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripePaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MedspasStripeoutboundpaymentmethodSumFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "medspas_stripeoutboundpaymentmethod" */
export enum MedspasStripeoutboundpaymentmethodUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  StripeCustomerId = "stripeCustomerId",
  /** column name */
  StripePaymentMethodId = "stripePaymentMethodId",
}

export type MedspasStripeoutboundpaymentmethodUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspasStripeoutboundpaymentmethodIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspasStripeoutboundpaymentmethodSetInput>;
  /** filter the rows which have to be updated */
  where: MedspasStripeoutboundpaymentmethodBoolExp;
};

/** aggregate varPop on columns */
export type MedspasStripeoutboundpaymentmethodVarPopFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type MedspasStripeoutboundpaymentmethodVarSampFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type MedspasStripeoutboundpaymentmethodVarianceFields = {
  __typename?: "MedspasStripeoutboundpaymentmethodVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** An enumeration. */
export enum MedspasUserMedSpaRoleChoices {
  /** Front Desk */
  FrontDesk = "FRONT_DESK",
  /** Medical Director */
  MedicalDirector = "MEDICAL_DIRECTOR",
  /** Provider */
  Provider = "PROVIDER",
}

/** columns and relationships of "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicenses = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicenses";
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medicalLicense: MedspasMedicallicense;
  medicallicenseId: Scalars["bigint"]["output"];
  /** An object relationship */
  usermedspa: UserMedspa;
  usermedspaId: Scalars["bigint"]["output"];
};

/** aggregated selection of "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesAggregate = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesAggregate";
  aggregate?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesAggregateFields>;
  nodes: Array<MedspasUsermedspaAcquiredMedicalLicenses>;
};

export type MedspasUsermedspaAcquiredMedicalLicensesAggregateBoolExp = {
  count?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesAggregateBoolExpCount>;
};

/** aggregate fields of "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesAggregateFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesAggregateFields";
  avg?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesMaxFields>;
  min?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesMinFields>;
  stddev?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesStddevFields>;
  stddevPop?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesStddevPopFields>;
  stddevSamp?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesStddevSampFields>;
  sum?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesSumFields>;
  varPop?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesVarPopFields>;
  varSamp?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesVarSampFields>;
  variance?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesVarianceFields>;
};

/** aggregate fields of "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesAggregateFieldsCountArgs = {
  columns?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesAggregateOrderBy = {
  avg?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesMaxOrderBy>;
  min?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesMinOrderBy>;
  stddev?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesStddevOrderBy>;
  stddevPop?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesStddevSampOrderBy>;
  sum?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesSumOrderBy>;
  varPop?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesVarPopOrderBy>;
  varSamp?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesVarSampOrderBy>;
  variance?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesArrRelInsertInput = {
  data: Array<MedspasUsermedspaAcquiredMedicalLicensesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesOnConflict>;
};

/** aggregate avg on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesAvgFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicallicenseId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_usermedspa_acquired_medical_licenses". All fields are combined with a logical 'AND'. */
export type MedspasUsermedspaAcquiredMedicalLicensesBoolExp = {
  _and?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>>;
  _not?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
  _or?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  medicalLicense?: InputMaybe<MedspasMedicallicenseBoolExp>;
  medicallicenseId?: InputMaybe<BigintComparisonExp>;
  usermedspa?: InputMaybe<UserMedspaBoolExp>;
  usermedspaId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspas_usermedspa_acquired_medical_licenses" */
export enum MedspasUsermedspaAcquiredMedicalLicensesConstraint {
  /** unique or primary key constraint on columns "usermedspa_id", "medicallicense_id" */
  MedspasUsermedspaAcquiUsermedspaIdMedicallic_26622a80Uniq = "medspas_usermedspa_acqui_usermedspa_id_medicallic_26622a80_uniq",
  /** unique or primary key constraint on columns "id" */
  MedspasUsermedspaAcquiredMedicalLicensesPkey = "medspas_usermedspa_acquired_medical_licenses_pkey",
}

/** input type for incrementing numeric columns in table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicallicenseId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicalLicense?: InputMaybe<MedspasMedicallicenseObjRelInsertInput>;
  medicallicenseId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspa?: InputMaybe<UserMedspaObjRelInsertInput>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesMaxFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicallicenseId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesMinFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicallicenseId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesMutationResponse = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedspasUsermedspaAcquiredMedicalLicenses>;
};

/** on_conflict condition type for table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesOnConflict = {
  constraint: MedspasUsermedspaAcquiredMedicalLicensesConstraint;
  updateColumns?: Array<MedspasUsermedspaAcquiredMedicalLicensesUpdateColumn>;
  where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
};

/** Ordering options when selecting data from "medspas_usermedspa_acquired_medical_licenses". */
export type MedspasUsermedspaAcquiredMedicalLicensesOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalLicense?: InputMaybe<MedspasMedicallicenseOrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspa?: InputMaybe<UserMedspaOrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_usermedspa_acquired_medical_licenses */
export type MedspasUsermedspaAcquiredMedicalLicensesPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_usermedspa_acquired_medical_licenses" */
export enum MedspasUsermedspaAcquiredMedicalLicensesSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedicallicenseId = "medicallicenseId",
  /** column name */
  UsermedspaId = "usermedspaId",
}

/** input type for updating data in table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicallicenseId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesStddevFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicallicenseId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesStddevPopFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicallicenseId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesStddevSampFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicallicenseId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspasUsermedspaAcquiredMedicalLicensesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspasUsermedspaAcquiredMedicalLicensesStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicallicenseId?: InputMaybe<Scalars["bigint"]["input"]>;
  usermedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesSumFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicallicenseId?: Maybe<Scalars["bigint"]["output"]>;
  usermedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_usermedspa_acquired_medical_licenses" */
export enum MedspasUsermedspaAcquiredMedicalLicensesUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  MedicallicenseId = "medicallicenseId",
  /** column name */
  UsermedspaId = "usermedspaId",
}

export type MedspasUsermedspaAcquiredMedicalLicensesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesSetInput>;
  /** filter the rows which have to be updated */
  where: MedspasUsermedspaAcquiredMedicalLicensesBoolExp;
};

/** aggregate varPop on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesVarPopFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicallicenseId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesVarSampFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicallicenseId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspasUsermedspaAcquiredMedicalLicensesVarianceFields = {
  __typename?: "MedspasUsermedspaAcquiredMedicalLicensesVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicallicenseId?: Maybe<Scalars["Float"]["output"]>;
  usermedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_usermedspa_acquired_medical_licenses" */
export type MedspasUsermedspaAcquiredMedicalLicensesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicallicenseId?: InputMaybe<OrderBy>;
  usermedspaId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "membership" */
export type Membership = {
  __typename?: "Membership";
  /** Stores the content for the Agreement that will be assigned to a Client Membership upon purchase */
  agreementContent: Scalars["String"]["output"];
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  billingInterval: Scalars["String"]["output"];
  billingIntervalCount: Scalars["Int"]["output"];
  cancellationFeeValue?: Maybe<Scalars["numeric"]["output"]>;
  /** An object relationship */
  category: MembershipCategory;
  categoryId: Scalars["bigint"]["output"];
  /** An array relationship */
  clientMemberships: Array<ClientMembership>;
  /** An aggregate relationship */
  clientMembershipsAggregate: ClientMembershipAggregate;
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  initiationFeeValue?: Maybe<Scalars["numeric"]["output"]>;
  isOnlinePurchaseEnabled: Scalars["Boolean"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  membershipPerks: Array<MembershipPerk>;
  /** An aggregate relationship */
  membershipPerksAggregate: MembershipPerkAggregate;
  minimumBillingCycles?: Maybe<Scalars["Int"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order: Scalars["Int"]["output"];
  pricePerBillingPeriod?: Maybe<Scalars["numeric"]["output"]>;
  stripeProductId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

/** columns and relationships of "membership" */
export type MembershipClientMembershipsArgs = {
  distinctOn?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientMembershipOrderBy>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

/** columns and relationships of "membership" */
export type MembershipClientMembershipsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientMembershipOrderBy>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

/** columns and relationships of "membership" */
export type MembershipMembershipPerksArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkOrderBy>>;
  where?: InputMaybe<MembershipPerkBoolExp>;
};

/** columns and relationships of "membership" */
export type MembershipMembershipPerksAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkOrderBy>>;
  where?: InputMaybe<MembershipPerkBoolExp>;
};

/** aggregated selection of "membership" */
export type MembershipAggregate = {
  __typename?: "MembershipAggregate";
  aggregate?: Maybe<MembershipAggregateFields>;
  nodes: Array<Membership>;
};

export type MembershipAggregateBoolExp = {
  bool_and?: InputMaybe<MembershipAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MembershipAggregateBoolExpBool_Or>;
  count?: InputMaybe<MembershipAggregateBoolExpCount>;
};

/** aggregate fields of "membership" */
export type MembershipAggregateFields = {
  __typename?: "MembershipAggregateFields";
  avg?: Maybe<MembershipAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MembershipMaxFields>;
  min?: Maybe<MembershipMinFields>;
  stddev?: Maybe<MembershipStddevFields>;
  stddevPop?: Maybe<MembershipStddevPopFields>;
  stddevSamp?: Maybe<MembershipStddevSampFields>;
  sum?: Maybe<MembershipSumFields>;
  varPop?: Maybe<MembershipVarPopFields>;
  varSamp?: Maybe<MembershipVarSampFields>;
  variance?: Maybe<MembershipVarianceFields>;
};

/** aggregate fields of "membership" */
export type MembershipAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MembershipSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "membership" */
export type MembershipAggregateOrderBy = {
  avg?: InputMaybe<MembershipAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MembershipMaxOrderBy>;
  min?: InputMaybe<MembershipMinOrderBy>;
  stddev?: InputMaybe<MembershipStddevOrderBy>;
  stddevPop?: InputMaybe<MembershipStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MembershipStddevSampOrderBy>;
  sum?: InputMaybe<MembershipSumOrderBy>;
  varPop?: InputMaybe<MembershipVarPopOrderBy>;
  varSamp?: InputMaybe<MembershipVarSampOrderBy>;
  variance?: InputMaybe<MembershipVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "membership" */
export type MembershipArrRelInsertInput = {
  data: Array<MembershipInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipOnConflict>;
};

/** aggregate avg on columns */
export type MembershipAvgFields = {
  __typename?: "MembershipAvgFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Float"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  categoryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "membership" */
export type MembershipAvgOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "membership". All fields are combined with a logical 'AND'. */
export type MembershipBoolExp = {
  _and?: InputMaybe<Array<MembershipBoolExp>>;
  _not?: InputMaybe<MembershipBoolExp>;
  _or?: InputMaybe<Array<MembershipBoolExp>>;
  agreementContent?: InputMaybe<StringComparisonExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  archivedById?: InputMaybe<BigintComparisonExp>;
  billingInterval?: InputMaybe<StringComparisonExp>;
  billingIntervalCount?: InputMaybe<IntComparisonExp>;
  cancellationFeeValue?: InputMaybe<NumericComparisonExp>;
  category?: InputMaybe<MembershipCategoryBoolExp>;
  categoryId?: InputMaybe<BigintComparisonExp>;
  clientMemberships?: InputMaybe<ClientMembershipBoolExp>;
  clientMembershipsAggregate?: InputMaybe<ClientMembershipAggregateBoolExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  initiationFeeValue?: InputMaybe<NumericComparisonExp>;
  isOnlinePurchaseEnabled?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  membershipPerks?: InputMaybe<MembershipPerkBoolExp>;
  membershipPerksAggregate?: InputMaybe<MembershipPerkAggregateBoolExp>;
  minimumBillingCycles?: InputMaybe<IntComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  pricePerBillingPeriod?: InputMaybe<NumericComparisonExp>;
  stripeProductId?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "membership_category" */
export type MembershipCategory = {
  __typename?: "MembershipCategory";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "membership_category" */
export type MembershipCategoryAggregate = {
  __typename?: "MembershipCategoryAggregate";
  aggregate?: Maybe<MembershipCategoryAggregateFields>;
  nodes: Array<MembershipCategory>;
};

/** aggregate fields of "membership_category" */
export type MembershipCategoryAggregateFields = {
  __typename?: "MembershipCategoryAggregateFields";
  avg?: Maybe<MembershipCategoryAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MembershipCategoryMaxFields>;
  min?: Maybe<MembershipCategoryMinFields>;
  stddev?: Maybe<MembershipCategoryStddevFields>;
  stddevPop?: Maybe<MembershipCategoryStddevPopFields>;
  stddevSamp?: Maybe<MembershipCategoryStddevSampFields>;
  sum?: Maybe<MembershipCategorySumFields>;
  varPop?: Maybe<MembershipCategoryVarPopFields>;
  varSamp?: Maybe<MembershipCategoryVarSampFields>;
  variance?: Maybe<MembershipCategoryVarianceFields>;
};

/** aggregate fields of "membership_category" */
export type MembershipCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MembershipCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type MembershipCategoryAvgFields = {
  __typename?: "MembershipCategoryAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "membership_category". All fields are combined with a logical 'AND'. */
export type MembershipCategoryBoolExp = {
  _and?: InputMaybe<Array<MembershipCategoryBoolExp>>;
  _not?: InputMaybe<MembershipCategoryBoolExp>;
  _or?: InputMaybe<Array<MembershipCategoryBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "membership_category" */
export enum MembershipCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  MembershipCategoryPkey = "membership_category_pkey",
}

/** input type for incrementing numeric columns in table "membership_category" */
export type MembershipCategoryIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "membership_category" */
export type MembershipCategoryInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MembershipCategoryMaxFields = {
  __typename?: "MembershipCategoryMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type MembershipCategoryMinFields = {
  __typename?: "MembershipCategoryMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "membership_category" */
export type MembershipCategoryMutationResponse = {
  __typename?: "MembershipCategoryMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipCategory>;
};

/** input type for inserting object relation for remote table "membership_category" */
export type MembershipCategoryObjRelInsertInput = {
  data: MembershipCategoryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipCategoryOnConflict>;
};

/** on_conflict condition type for table "membership_category" */
export type MembershipCategoryOnConflict = {
  constraint: MembershipCategoryConstraint;
  updateColumns?: Array<MembershipCategoryUpdateColumn>;
  where?: InputMaybe<MembershipCategoryBoolExp>;
};

/** Ordering options when selecting data from "membership_category". */
export type MembershipCategoryOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: membership_category */
export type MembershipCategoryPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "membership_category" */
export enum MembershipCategorySelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "membership_category" */
export type MembershipCategorySetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MembershipCategoryStddevFields = {
  __typename?: "MembershipCategoryStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type MembershipCategoryStddevPopFields = {
  __typename?: "MembershipCategoryStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type MembershipCategoryStddevSampFields = {
  __typename?: "MembershipCategoryStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "membership_category" */
export type MembershipCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MembershipCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipCategoryStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MembershipCategorySumFields = {
  __typename?: "MembershipCategorySumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "membership_category" */
export enum MembershipCategoryUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type MembershipCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MembershipCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipCategorySetInput>;
  /** filter the rows which have to be updated */
  where: MembershipCategoryBoolExp;
};

/** aggregate varPop on columns */
export type MembershipCategoryVarPopFields = {
  __typename?: "MembershipCategoryVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type MembershipCategoryVarSampFields = {
  __typename?: "MembershipCategoryVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type MembershipCategoryVarianceFields = {
  __typename?: "MembershipCategoryVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** unique or primary key constraints on table "membership" */
export enum MembershipConstraint {
  /** unique or primary key constraint on columns "id" */
  MembershipPkey = "membership_pkey",
}

/** input type for incrementing numeric columns in table "membership" */
export type MembershipIncInput = {
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  billingIntervalCount?: InputMaybe<Scalars["Int"]["input"]>;
  cancellationFeeValue?: InputMaybe<Scalars["numeric"]["input"]>;
  categoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  initiationFeeValue?: InputMaybe<Scalars["numeric"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  minimumBillingCycles?: InputMaybe<Scalars["Int"]["input"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  pricePerBillingPeriod?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "membership" */
export type MembershipInsertInput = {
  /** Stores the content for the Agreement that will be assigned to a Client Membership upon purchase */
  agreementContent?: InputMaybe<Scalars["String"]["input"]>;
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  billingInterval?: InputMaybe<Scalars["String"]["input"]>;
  billingIntervalCount?: InputMaybe<Scalars["Int"]["input"]>;
  cancellationFeeValue?: InputMaybe<Scalars["numeric"]["input"]>;
  category?: InputMaybe<MembershipCategoryObjRelInsertInput>;
  categoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  clientMemberships?: InputMaybe<ClientMembershipArrRelInsertInput>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  initiationFeeValue?: InputMaybe<Scalars["numeric"]["input"]>;
  isOnlinePurchaseEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipPerks?: InputMaybe<MembershipPerkArrRelInsertInput>;
  minimumBillingCycles?: InputMaybe<Scalars["Int"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  pricePerBillingPeriod?: InputMaybe<Scalars["numeric"]["input"]>;
  stripeProductId?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "membership_line" */
export type MembershipLine = {
  __typename?: "MembershipLine";
  /** An object relationship */
  clientMembership: ClientMembership;
  clientMembershipId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  price: Scalars["numeric"]["output"];
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate: Scalars["numeric"]["output"];
  /** An array relationship */
  walletTransactionSources: Array<WalletTransactionSourceTmp>;
  /** An aggregate relationship */
  walletTransactionSourcesAggregate: WalletTransactionSourceTmpAggregate;
};

/** columns and relationships of "membership_line" */
export type MembershipLineWalletTransactionSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** columns and relationships of "membership_line" */
export type MembershipLineWalletTransactionSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** aggregated selection of "membership_line" */
export type MembershipLineAggregate = {
  __typename?: "MembershipLineAggregate";
  aggregate?: Maybe<MembershipLineAggregateFields>;
  nodes: Array<MembershipLine>;
};

export type MembershipLineAggregateBoolExp = {
  count?: InputMaybe<MembershipLineAggregateBoolExpCount>;
};

/** aggregate fields of "membership_line" */
export type MembershipLineAggregateFields = {
  __typename?: "MembershipLineAggregateFields";
  avg?: Maybe<MembershipLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MembershipLineMaxFields>;
  min?: Maybe<MembershipLineMinFields>;
  stddev?: Maybe<MembershipLineStddevFields>;
  stddevPop?: Maybe<MembershipLineStddevPopFields>;
  stddevSamp?: Maybe<MembershipLineStddevSampFields>;
  sum?: Maybe<MembershipLineSumFields>;
  varPop?: Maybe<MembershipLineVarPopFields>;
  varSamp?: Maybe<MembershipLineVarSampFields>;
  variance?: Maybe<MembershipLineVarianceFields>;
};

/** aggregate fields of "membership_line" */
export type MembershipLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MembershipLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "membership_line" */
export type MembershipLineAggregateOrderBy = {
  avg?: InputMaybe<MembershipLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MembershipLineMaxOrderBy>;
  min?: InputMaybe<MembershipLineMinOrderBy>;
  stddev?: InputMaybe<MembershipLineStddevOrderBy>;
  stddevPop?: InputMaybe<MembershipLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MembershipLineStddevSampOrderBy>;
  sum?: InputMaybe<MembershipLineSumOrderBy>;
  varPop?: InputMaybe<MembershipLineVarPopOrderBy>;
  varSamp?: InputMaybe<MembershipLineVarSampOrderBy>;
  variance?: InputMaybe<MembershipLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "membership_line" */
export type MembershipLineArrRelInsertInput = {
  data: Array<MembershipLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipLineOnConflict>;
};

/** aggregate avg on columns */
export type MembershipLineAvgFields = {
  __typename?: "MembershipLineAvgFields";
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "membership_line" */
export type MembershipLineAvgOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "membership_line". All fields are combined with a logical 'AND'. */
export type MembershipLineBoolExp = {
  _and?: InputMaybe<Array<MembershipLineBoolExp>>;
  _not?: InputMaybe<MembershipLineBoolExp>;
  _or?: InputMaybe<Array<MembershipLineBoolExp>>;
  clientMembership?: InputMaybe<ClientMembershipBoolExp>;
  clientMembershipId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  salesTaxRate?: InputMaybe<NumericComparisonExp>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateBoolExp>;
};

/** unique or primary key constraints on table "membership_line" */
export enum MembershipLineConstraint {
  /** unique or primary key constraint on columns "id" */
  MembershipLinePkey = "membership_line_pkey",
}

/** input type for incrementing numeric columns in table "membership_line" */
export type MembershipLineIncInput = {
  clientMembershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "membership_line" */
export type MembershipLineInsertInput = {
  clientMembership?: InputMaybe<ClientMembershipObjRelInsertInput>;
  clientMembershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<Scalars["numeric"]["input"]>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpArrRelInsertInput>;
};

/** aggregate max on columns */
export type MembershipLineMaxFields = {
  __typename?: "MembershipLineMaxFields";
  clientMembershipId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by max() on columns of table "membership_line" */
export type MembershipLineMaxOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MembershipLineMinFields = {
  __typename?: "MembershipLineMinFields";
  clientMembershipId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by min() on columns of table "membership_line" */
export type MembershipLineMinOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "membership_line" */
export type MembershipLineMutationResponse = {
  __typename?: "MembershipLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipLine>;
};

/** input type for inserting object relation for remote table "membership_line" */
export type MembershipLineObjRelInsertInput = {
  data: MembershipLineInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipLineOnConflict>;
};

/** on_conflict condition type for table "membership_line" */
export type MembershipLineOnConflict = {
  constraint: MembershipLineConstraint;
  updateColumns?: Array<MembershipLineUpdateColumn>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

/** Ordering options when selecting data from "membership_line". */
export type MembershipLineOrderBy = {
  clientMembership?: InputMaybe<ClientMembershipOrderBy>;
  clientMembershipId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  salesTaxRate?: InputMaybe<OrderBy>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateOrderBy>;
};

/** primary key columns input for table: membership_line */
export type MembershipLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "membership_line" */
export enum MembershipLineSelectColumn {
  /** column name */
  ClientMembershipId = "clientMembershipId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  Price = "price",
  /** column name */
  SalesTaxRate = "salesTaxRate",
}

/** input type for updating data in table "membership_line" */
export type MembershipLineSetInput = {
  clientMembershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate stddev on columns */
export type MembershipLineStddevFields = {
  __typename?: "MembershipLineStddevFields";
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "membership_line" */
export type MembershipLineStddevOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MembershipLineStddevPopFields = {
  __typename?: "MembershipLineStddevPopFields";
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "membership_line" */
export type MembershipLineStddevPopOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MembershipLineStddevSampFields = {
  __typename?: "MembershipLineStddevSampFields";
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "membership_line" */
export type MembershipLineStddevSampOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "membership_line" */
export type MembershipLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MembershipLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipLineStreamCursorValueInput = {
  clientMembershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate sum on columns */
export type MembershipLineSumFields = {
  __typename?: "MembershipLineSumFields";
  clientMembershipId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "membership_line" */
export type MembershipLineSumOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** update columns of table "membership_line" */
export enum MembershipLineUpdateColumn {
  /** column name */
  ClientMembershipId = "clientMembershipId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  Price = "price",
  /** column name */
  SalesTaxRate = "salesTaxRate",
}

export type MembershipLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MembershipLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipLineSetInput>;
  /** filter the rows which have to be updated */
  where: MembershipLineBoolExp;
};

/** aggregate varPop on columns */
export type MembershipLineVarPopFields = {
  __typename?: "MembershipLineVarPopFields";
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "membership_line" */
export type MembershipLineVarPopOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MembershipLineVarSampFields = {
  __typename?: "MembershipLineVarSampFields";
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "membership_line" */
export type MembershipLineVarSampOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MembershipLineVarianceFields = {
  __typename?: "MembershipLineVarianceFields";
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "membership_line" */
export type MembershipLineVarianceOrderBy = {
  clientMembershipId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  /** Not used in the current implementation, but included for consistency with the otherLine Models. Please remove this comment if it is used in the future. */
  salesTaxRate?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type MembershipMaxFields = {
  __typename?: "MembershipMaxFields";
  /** Stores the content for the Agreement that will be assigned to a Client Membership upon purchase */
  agreementContent?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  billingInterval?: Maybe<Scalars["String"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Int"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["numeric"]["output"]>;
  categoryId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["numeric"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Int"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["numeric"]["output"]>;
  stripeProductId?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "membership" */
export type MembershipMaxOrderBy = {
  /** Stores the content for the Agreement that will be assigned to a Client Membership upon purchase */
  agreementContent?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  billingInterval?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
  stripeProductId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MembershipMinFields = {
  __typename?: "MembershipMinFields";
  /** Stores the content for the Agreement that will be assigned to a Client Membership upon purchase */
  agreementContent?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  billingInterval?: Maybe<Scalars["String"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Int"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["numeric"]["output"]>;
  categoryId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["numeric"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Int"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["numeric"]["output"]>;
  stripeProductId?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "membership" */
export type MembershipMinOrderBy = {
  /** Stores the content for the Agreement that will be assigned to a Client Membership upon purchase */
  agreementContent?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  billingInterval?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
  stripeProductId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "membership" */
export type MembershipMutationResponse = {
  __typename?: "MembershipMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Membership>;
};

/** input type for inserting object relation for remote table "membership" */
export type MembershipObjRelInsertInput = {
  data: MembershipInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipOnConflict>;
};

/** on_conflict condition type for table "membership" */
export type MembershipOnConflict = {
  constraint: MembershipConstraint;
  updateColumns?: Array<MembershipUpdateColumn>;
  where?: InputMaybe<MembershipBoolExp>;
};

/** Ordering options when selecting data from "membership". */
export type MembershipOrderBy = {
  agreementContent?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  billingInterval?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  category?: InputMaybe<MembershipCategoryOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  clientMembershipsAggregate?: InputMaybe<ClientMembershipAggregateOrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  isOnlinePurchaseEnabled?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  membershipPerksAggregate?: InputMaybe<MembershipPerkAggregateOrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
  stripeProductId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** columns and relationships of "membership_perk" */
export type MembershipPerk = {
  __typename?: "MembershipPerk";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  discount?: Maybe<Discount>;
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  membership: Membership;
  membershipId: Scalars["bigint"]["output"];
  /** An array relationship */
  membershipPerkLines: Array<MembershipPerkLine>;
  /** An aggregate relationship */
  membershipPerkLinesAggregate: MembershipPerkLineAggregate;
  modified: Scalars["timestamptz"]["output"];
  perkType: Scalars["String"]["output"];
};

/** columns and relationships of "membership_perk" */
export type MembershipPerkMembershipPerkLinesArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** columns and relationships of "membership_perk" */
export type MembershipPerkMembershipPerkLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** aggregated selection of "membership_perk" */
export type MembershipPerkAggregate = {
  __typename?: "MembershipPerkAggregate";
  aggregate?: Maybe<MembershipPerkAggregateFields>;
  nodes: Array<MembershipPerk>;
};

export type MembershipPerkAggregateBoolExp = {
  count?: InputMaybe<MembershipPerkAggregateBoolExpCount>;
};

/** aggregate fields of "membership_perk" */
export type MembershipPerkAggregateFields = {
  __typename?: "MembershipPerkAggregateFields";
  avg?: Maybe<MembershipPerkAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MembershipPerkMaxFields>;
  min?: Maybe<MembershipPerkMinFields>;
  stddev?: Maybe<MembershipPerkStddevFields>;
  stddevPop?: Maybe<MembershipPerkStddevPopFields>;
  stddevSamp?: Maybe<MembershipPerkStddevSampFields>;
  sum?: Maybe<MembershipPerkSumFields>;
  varPop?: Maybe<MembershipPerkVarPopFields>;
  varSamp?: Maybe<MembershipPerkVarSampFields>;
  variance?: Maybe<MembershipPerkVarianceFields>;
};

/** aggregate fields of "membership_perk" */
export type MembershipPerkAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MembershipPerkSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "membership_perk" */
export type MembershipPerkAggregateOrderBy = {
  avg?: InputMaybe<MembershipPerkAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MembershipPerkMaxOrderBy>;
  min?: InputMaybe<MembershipPerkMinOrderBy>;
  stddev?: InputMaybe<MembershipPerkStddevOrderBy>;
  stddevPop?: InputMaybe<MembershipPerkStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MembershipPerkStddevSampOrderBy>;
  sum?: InputMaybe<MembershipPerkSumOrderBy>;
  varPop?: InputMaybe<MembershipPerkVarPopOrderBy>;
  varSamp?: InputMaybe<MembershipPerkVarSampOrderBy>;
  variance?: InputMaybe<MembershipPerkVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "membership_perk" */
export type MembershipPerkArrRelInsertInput = {
  data: Array<MembershipPerkInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipPerkOnConflict>;
};

/** aggregate avg on columns */
export type MembershipPerkAvgFields = {
  __typename?: "MembershipPerkAvgFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "membership_perk" */
export type MembershipPerkAvgOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "membership_perk". All fields are combined with a logical 'AND'. */
export type MembershipPerkBoolExp = {
  _and?: InputMaybe<Array<MembershipPerkBoolExp>>;
  _not?: InputMaybe<MembershipPerkBoolExp>;
  _or?: InputMaybe<Array<MembershipPerkBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  discount?: InputMaybe<DiscountBoolExp>;
  discountId?: InputMaybe<BigintComparisonExp>;
  dollarCreditAmount?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  membership?: InputMaybe<MembershipBoolExp>;
  membershipId?: InputMaybe<BigintComparisonExp>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineBoolExp>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  perkType?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "membership_perk" */
export enum MembershipPerkConstraint {
  /** unique or primary key constraint on columns "id" */
  MembershipPerkPkey = "membership_perk_pkey",
}

/** input type for incrementing numeric columns in table "membership_perk" */
export type MembershipPerkIncInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "membership_perk" */
export type MembershipPerkInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discount?: InputMaybe<DiscountObjRelInsertInput>;
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  membership?: InputMaybe<MembershipObjRelInsertInput>;
  membershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  perkType?: InputMaybe<Scalars["String"]["input"]>;
};

/** Lines for membership perks that were used in the context of a visit or an invoice */
export type MembershipPerkLine = {
  __typename?: "MembershipPerkLine";
  /** An object relationship */
  clientMembership: ClientMembership;
  /** Client membership that was used to create this line */
  clientMembershipId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  discount?: Maybe<Discount>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  membershipPerk: MembershipPerk;
  /** Membership perk that was used to create this line */
  membershipPerkId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Type of the associated membership perk */
  perkType: Scalars["String"]["output"];
  /** An object relationship */
  visit?: Maybe<Visit>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  walletTransactionSources: Array<WalletTransactionSourceTmp>;
  /** An aggregate relationship */
  walletTransactionSourcesAggregate: WalletTransactionSourceTmpAggregate;
};

/** Lines for membership perks that were used in the context of a visit or an invoice */
export type MembershipPerkLineWalletTransactionSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** Lines for membership perks that were used in the context of a visit or an invoice */
export type MembershipPerkLineWalletTransactionSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** aggregated selection of "membership_perk_line" */
export type MembershipPerkLineAggregate = {
  __typename?: "MembershipPerkLineAggregate";
  aggregate?: Maybe<MembershipPerkLineAggregateFields>;
  nodes: Array<MembershipPerkLine>;
};

export type MembershipPerkLineAggregateBoolExp = {
  count?: InputMaybe<MembershipPerkLineAggregateBoolExpCount>;
};

/** aggregate fields of "membership_perk_line" */
export type MembershipPerkLineAggregateFields = {
  __typename?: "MembershipPerkLineAggregateFields";
  avg?: Maybe<MembershipPerkLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MembershipPerkLineMaxFields>;
  min?: Maybe<MembershipPerkLineMinFields>;
  stddev?: Maybe<MembershipPerkLineStddevFields>;
  stddevPop?: Maybe<MembershipPerkLineStddevPopFields>;
  stddevSamp?: Maybe<MembershipPerkLineStddevSampFields>;
  sum?: Maybe<MembershipPerkLineSumFields>;
  varPop?: Maybe<MembershipPerkLineVarPopFields>;
  varSamp?: Maybe<MembershipPerkLineVarSampFields>;
  variance?: Maybe<MembershipPerkLineVarianceFields>;
};

/** aggregate fields of "membership_perk_line" */
export type MembershipPerkLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "membership_perk_line" */
export type MembershipPerkLineAggregateOrderBy = {
  avg?: InputMaybe<MembershipPerkLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MembershipPerkLineMaxOrderBy>;
  min?: InputMaybe<MembershipPerkLineMinOrderBy>;
  stddev?: InputMaybe<MembershipPerkLineStddevOrderBy>;
  stddevPop?: InputMaybe<MembershipPerkLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MembershipPerkLineStddevSampOrderBy>;
  sum?: InputMaybe<MembershipPerkLineSumOrderBy>;
  varPop?: InputMaybe<MembershipPerkLineVarPopOrderBy>;
  varSamp?: InputMaybe<MembershipPerkLineVarSampOrderBy>;
  variance?: InputMaybe<MembershipPerkLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "membership_perk_line" */
export type MembershipPerkLineArrRelInsertInput = {
  data: Array<MembershipPerkLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipPerkLineOnConflict>;
};

/** aggregate avg on columns */
export type MembershipPerkLineAvgFields = {
  __typename?: "MembershipPerkLineAvgFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["Float"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "membership_perk_line" */
export type MembershipPerkLineAvgOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "membership_perk_line". All fields are combined with a logical 'AND'. */
export type MembershipPerkLineBoolExp = {
  _and?: InputMaybe<Array<MembershipPerkLineBoolExp>>;
  _not?: InputMaybe<MembershipPerkLineBoolExp>;
  _or?: InputMaybe<Array<MembershipPerkLineBoolExp>>;
  clientMembership?: InputMaybe<ClientMembershipBoolExp>;
  clientMembershipId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  discount?: InputMaybe<DiscountBoolExp>;
  discountId?: InputMaybe<BigintComparisonExp>;
  dollarCreditAmount?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  membershipPerk?: InputMaybe<MembershipPerkBoolExp>;
  membershipPerkId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  perkType?: InputMaybe<StringComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateBoolExp>;
};

/** unique or primary key constraints on table "membership_perk_line" */
export enum MembershipPerkLineConstraint {
  /** unique or primary key constraint on columns "id" */
  MembershipPerkLinePkey = "membership_perk_line_pkey",
}

/** input type for incrementing numeric columns in table "membership_perk_line" */
export type MembershipPerkLineIncInput = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "membership_perk_line" */
export type MembershipPerkLineInsertInput = {
  clientMembership?: InputMaybe<ClientMembershipObjRelInsertInput>;
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discount?: InputMaybe<DiscountObjRelInsertInput>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipPerk?: InputMaybe<MembershipPerkObjRelInsertInput>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of the associated membership perk */
  perkType?: InputMaybe<Scalars["String"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpArrRelInsertInput>;
};

/** aggregate max on columns */
export type MembershipPerkLineMaxFields = {
  __typename?: "MembershipPerkLineMaxFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of the associated membership perk */
  perkType?: Maybe<Scalars["String"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "membership_perk_line" */
export type MembershipPerkLineMaxOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Type of the associated membership perk */
  perkType?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MembershipPerkLineMinFields = {
  __typename?: "MembershipPerkLineMinFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of the associated membership perk */
  perkType?: Maybe<Scalars["String"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "membership_perk_line" */
export type MembershipPerkLineMinOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Type of the associated membership perk */
  perkType?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "membership_perk_line" */
export type MembershipPerkLineMutationResponse = {
  __typename?: "MembershipPerkLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipPerkLine>;
};

/** input type for inserting object relation for remote table "membership_perk_line" */
export type MembershipPerkLineObjRelInsertInput = {
  data: MembershipPerkLineInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipPerkLineOnConflict>;
};

/** on_conflict condition type for table "membership_perk_line" */
export type MembershipPerkLineOnConflict = {
  constraint: MembershipPerkLineConstraint;
  updateColumns?: Array<MembershipPerkLineUpdateColumn>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** Ordering options when selecting data from "membership_perk_line". */
export type MembershipPerkLineOrderBy = {
  clientMembership?: InputMaybe<ClientMembershipOrderBy>;
  clientMembershipId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  discount?: InputMaybe<DiscountOrderBy>;
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  membershipPerk?: InputMaybe<MembershipPerkOrderBy>;
  membershipPerkId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  perkType?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateOrderBy>;
};

/** primary key columns input for table: membership_perk_line */
export type MembershipPerkLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "membership_perk_line" */
export enum MembershipPerkLineSelectColumn {
  /** column name */
  ClientMembershipId = "clientMembershipId",
  /** column name */
  Created = "created",
  /** column name */
  DiscountId = "discountId",
  /** column name */
  DollarCreditAmount = "dollarCreditAmount",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  MembershipPerkId = "membershipPerkId",
  /** column name */
  Modified = "modified",
  /** column name */
  PerkType = "perkType",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "membership_perk_line" */
export type MembershipPerkLineSetInput = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of the associated membership perk */
  perkType?: InputMaybe<Scalars["String"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MembershipPerkLineStddevFields = {
  __typename?: "MembershipPerkLineStddevFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["Float"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "membership_perk_line" */
export type MembershipPerkLineStddevOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MembershipPerkLineStddevPopFields = {
  __typename?: "MembershipPerkLineStddevPopFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["Float"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "membership_perk_line" */
export type MembershipPerkLineStddevPopOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MembershipPerkLineStddevSampFields = {
  __typename?: "MembershipPerkLineStddevSampFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["Float"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "membership_perk_line" */
export type MembershipPerkLineStddevSampOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "membership_perk_line" */
export type MembershipPerkLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MembershipPerkLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipPerkLineStreamCursorValueInput = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of the associated membership perk */
  perkType?: InputMaybe<Scalars["String"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type MembershipPerkLineSumFields = {
  __typename?: "MembershipPerkLineSumFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["bigint"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "membership_perk_line" */
export type MembershipPerkLineSumOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "membership_perk_line" */
export enum MembershipPerkLineUpdateColumn {
  /** column name */
  ClientMembershipId = "clientMembershipId",
  /** column name */
  Created = "created",
  /** column name */
  DiscountId = "discountId",
  /** column name */
  DollarCreditAmount = "dollarCreditAmount",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  MembershipPerkId = "membershipPerkId",
  /** column name */
  Modified = "modified",
  /** column name */
  PerkType = "perkType",
  /** column name */
  VisitId = "visitId",
}

export type MembershipPerkLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MembershipPerkLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipPerkLineSetInput>;
  /** filter the rows which have to be updated */
  where: MembershipPerkLineBoolExp;
};

/** aggregate varPop on columns */
export type MembershipPerkLineVarPopFields = {
  __typename?: "MembershipPerkLineVarPopFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["Float"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "membership_perk_line" */
export type MembershipPerkLineVarPopOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MembershipPerkLineVarSampFields = {
  __typename?: "MembershipPerkLineVarSampFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["Float"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "membership_perk_line" */
export type MembershipPerkLineVarSampOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MembershipPerkLineVarianceFields = {
  __typename?: "MembershipPerkLineVarianceFields";
  /** Client membership that was used to create this line */
  clientMembershipId?: Maybe<Scalars["Float"]["output"]>;
  /** Discount granted by the associated membership perk */
  discountId?: Maybe<Scalars["Float"]["output"]>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "membership_perk_line" */
export type MembershipPerkLineVarianceOrderBy = {
  /** Client membership that was used to create this line */
  clientMembershipId?: InputMaybe<OrderBy>;
  /** Discount granted by the associated membership perk */
  discountId?: InputMaybe<OrderBy>;
  /** Dollar credit amount granted by the associated membership perk */
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Membership perk that was used to create this line */
  membershipPerkId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type MembershipPerkMaxFields = {
  __typename?: "MembershipPerkMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  membershipId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  perkType?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "membership_perk" */
export type MembershipPerkMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  perkType?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MembershipPerkMinFields = {
  __typename?: "MembershipPerkMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  membershipId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  perkType?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "membership_perk" */
export type MembershipPerkMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  perkType?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "membership_perk" */
export type MembershipPerkMutationResponse = {
  __typename?: "MembershipPerkMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipPerk>;
};

/** input type for inserting object relation for remote table "membership_perk" */
export type MembershipPerkObjRelInsertInput = {
  data: MembershipPerkInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MembershipPerkOnConflict>;
};

/** on_conflict condition type for table "membership_perk" */
export type MembershipPerkOnConflict = {
  constraint: MembershipPerkConstraint;
  updateColumns?: Array<MembershipPerkUpdateColumn>;
  where?: InputMaybe<MembershipPerkBoolExp>;
};

/** Ordering options when selecting data from "membership_perk". */
export type MembershipPerkOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  discount?: InputMaybe<DiscountOrderBy>;
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membership?: InputMaybe<MembershipOrderBy>;
  membershipId?: InputMaybe<OrderBy>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  perkType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: membership_perk */
export type MembershipPerkPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "membership_perk" */
export enum MembershipPerkSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DiscountId = "discountId",
  /** column name */
  DollarCreditAmount = "dollarCreditAmount",
  /** column name */
  Id = "id",
  /** column name */
  MembershipId = "membershipId",
  /** column name */
  Modified = "modified",
  /** column name */
  PerkType = "perkType",
}

/** input type for updating data in table "membership_perk" */
export type MembershipPerkSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  perkType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MembershipPerkStddevFields = {
  __typename?: "MembershipPerkStddevFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "membership_perk" */
export type MembershipPerkStddevOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MembershipPerkStddevPopFields = {
  __typename?: "MembershipPerkStddevPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "membership_perk" */
export type MembershipPerkStddevPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MembershipPerkStddevSampFields = {
  __typename?: "MembershipPerkStddevSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "membership_perk" */
export type MembershipPerkStddevSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "membership_perk" */
export type MembershipPerkStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MembershipPerkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipPerkStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  perkType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MembershipPerkSumFields = {
  __typename?: "MembershipPerkSumFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  membershipId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "membership_perk" */
export type MembershipPerkSumOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** update columns of table "membership_perk" */
export enum MembershipPerkUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DiscountId = "discountId",
  /** column name */
  DollarCreditAmount = "dollarCreditAmount",
  /** column name */
  Id = "id",
  /** column name */
  MembershipId = "membershipId",
  /** column name */
  Modified = "modified",
  /** column name */
  PerkType = "perkType",
}

export type MembershipPerkUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MembershipPerkIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipPerkSetInput>;
  /** filter the rows which have to be updated */
  where: MembershipPerkBoolExp;
};

/** aggregate varPop on columns */
export type MembershipPerkVarPopFields = {
  __typename?: "MembershipPerkVarPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "membership_perk" */
export type MembershipPerkVarPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MembershipPerkVarSampFields = {
  __typename?: "MembershipPerkVarSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "membership_perk" */
export type MembershipPerkVarSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MembershipPerkVarianceFields = {
  __typename?: "MembershipPerkVarianceFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  membershipId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "membership_perk" */
export type MembershipPerkVarianceOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: membership */
export type MembershipPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "membership" */
export enum MembershipSelectColumn {
  /** column name */
  AgreementContent = "agreementContent",
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  BillingInterval = "billingInterval",
  /** column name */
  BillingIntervalCount = "billingIntervalCount",
  /** column name */
  CancellationFeeValue = "cancellationFeeValue",
  /** column name */
  CategoryId = "categoryId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  InitiationFeeValue = "initiationFeeValue",
  /** column name */
  IsOnlinePurchaseEnabled = "isOnlinePurchaseEnabled",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  MinimumBillingCycles = "minimumBillingCycles",
  /** column name */
  Modified = "modified",
  /** column name */
  Order = "order",
  /** column name */
  PricePerBillingPeriod = "pricePerBillingPeriod",
  /** column name */
  StripeProductId = "stripeProductId",
  /** column name */
  Title = "title",
}

/** select "membershipAggregateBoolExpBool_andArgumentsColumns" columns of table "membership" */
export enum MembershipSelectColumnMembershipAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsOnlinePurchaseEnabled = "isOnlinePurchaseEnabled",
}

/** select "membershipAggregateBoolExpBool_orArgumentsColumns" columns of table "membership" */
export enum MembershipSelectColumnMembershipAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsOnlinePurchaseEnabled = "isOnlinePurchaseEnabled",
}

/** input type for updating data in table "membership" */
export type MembershipSetInput = {
  /** Stores the content for the Agreement that will be assigned to a Client Membership upon purchase */
  agreementContent?: InputMaybe<Scalars["String"]["input"]>;
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  billingInterval?: InputMaybe<Scalars["String"]["input"]>;
  billingIntervalCount?: InputMaybe<Scalars["Int"]["input"]>;
  cancellationFeeValue?: InputMaybe<Scalars["numeric"]["input"]>;
  categoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  initiationFeeValue?: InputMaybe<Scalars["numeric"]["input"]>;
  isOnlinePurchaseEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  minimumBillingCycles?: InputMaybe<Scalars["Int"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  pricePerBillingPeriod?: InputMaybe<Scalars["numeric"]["input"]>;
  stripeProductId?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MembershipStddevFields = {
  __typename?: "MembershipStddevFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Float"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  categoryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "membership" */
export type MembershipStddevOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MembershipStddevPopFields = {
  __typename?: "MembershipStddevPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Float"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  categoryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "membership" */
export type MembershipStddevPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MembershipStddevSampFields = {
  __typename?: "MembershipStddevSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Float"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  categoryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "membership" */
export type MembershipStddevSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "membership" */
export type MembershipStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MembershipStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipStreamCursorValueInput = {
  /** Stores the content for the Agreement that will be assigned to a Client Membership upon purchase */
  agreementContent?: InputMaybe<Scalars["String"]["input"]>;
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  billingInterval?: InputMaybe<Scalars["String"]["input"]>;
  billingIntervalCount?: InputMaybe<Scalars["Int"]["input"]>;
  cancellationFeeValue?: InputMaybe<Scalars["numeric"]["input"]>;
  categoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  initiationFeeValue?: InputMaybe<Scalars["numeric"]["input"]>;
  isOnlinePurchaseEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  minimumBillingCycles?: InputMaybe<Scalars["Int"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  pricePerBillingPeriod?: InputMaybe<Scalars["numeric"]["input"]>;
  stripeProductId?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MembershipSumFields = {
  __typename?: "MembershipSumFields";
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Int"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["numeric"]["output"]>;
  categoryId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["numeric"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Int"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "membership" */
export type MembershipSumOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
};

/** update columns of table "membership" */
export enum MembershipUpdateColumn {
  /** column name */
  AgreementContent = "agreementContent",
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  BillingInterval = "billingInterval",
  /** column name */
  BillingIntervalCount = "billingIntervalCount",
  /** column name */
  CancellationFeeValue = "cancellationFeeValue",
  /** column name */
  CategoryId = "categoryId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  InitiationFeeValue = "initiationFeeValue",
  /** column name */
  IsOnlinePurchaseEnabled = "isOnlinePurchaseEnabled",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  MinimumBillingCycles = "minimumBillingCycles",
  /** column name */
  Modified = "modified",
  /** column name */
  Order = "order",
  /** column name */
  PricePerBillingPeriod = "pricePerBillingPeriod",
  /** column name */
  StripeProductId = "stripeProductId",
  /** column name */
  Title = "title",
}

export type MembershipUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MembershipIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipSetInput>;
  /** filter the rows which have to be updated */
  where: MembershipBoolExp;
};

/** aggregate varPop on columns */
export type MembershipVarPopFields = {
  __typename?: "MembershipVarPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Float"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  categoryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "membership" */
export type MembershipVarPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MembershipVarSampFields = {
  __typename?: "MembershipVarSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Float"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  categoryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "membership" */
export type MembershipVarSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MembershipVarianceFields = {
  __typename?: "MembershipVarianceFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  billingIntervalCount?: Maybe<Scalars["Float"]["output"]>;
  cancellationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  categoryId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  initiationFeeValue?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  minimumBillingCycles?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  pricePerBillingPeriod?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "membership" */
export type MembershipVarianceOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  billingIntervalCount?: InputMaybe<OrderBy>;
  cancellationFeeValue?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  initiationFeeValue?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  minimumBillingCycles?: InputMaybe<OrderBy>;
  /** Order in which the client memberships should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  pricePerBillingPeriod?: InputMaybe<OrderBy>;
};

export enum MessageDirectionType {
  Inbound = "INBOUND",
  Outbound = "OUTBOUND",
  Unknown = "UNKNOWN",
}

export enum MessageStatusType {
  Completed = "COMPLETED",
  Delivered = "DELIVERED",
  Failed = "FAILED",
  Opened = "OPENED",
  Pending = "PENDING",
  Queued = "QUEUED",
  Scheduled = "SCHEDULED",
  Sending = "SENDING",
  Sent = "SENT",
  Undelivered = "UNDELIVERED",
  Voicemail = "VOICEMAIL",
}

export type MessageType = {
  __typename?: "MessageType";
  attachments?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  body?: Maybe<Scalars["String"]["output"]>;
  contactId: Scalars["String"]["output"];
  conversationId: Scalars["String"]["output"];
  dateAdded?: Maybe<Scalars["String"]["output"]>;
  direction: MessageDirectionType;
  id: Scalars["String"]["output"];
  locationId: Scalars["String"]["output"];
  messageType?: Maybe<MessageTypeType>;
  meta?: Maybe<Scalars["JSONString"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<MessageStatusType>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export enum MessageTypeType {
  TypeActivity = "TYPE_ACTIVITY",
  TypeActivityAppointment = "TYPE_ACTIVITY_APPOINTMENT",
  TypeActivityContact = "TYPE_ACTIVITY_CONTACT",
  TypeActivityInvoice = "TYPE_ACTIVITY_INVOICE",
  TypeActivityOpportunity = "TYPE_ACTIVITY_OPPORTUNITY",
  TypeActivityPayment = "TYPE_ACTIVITY_PAYMENT",
  TypeCall = "TYPE_CALL",
  TypeCampaignCall = "TYPE_CAMPAIGN_CALL",
  TypeCampaignEmail = "TYPE_CAMPAIGN_EMAIL",
  TypeCampaignFacebook = "TYPE_CAMPAIGN_FACEBOOK",
  TypeCampaignGmb = "TYPE_CAMPAIGN_GMB",
  TypeCampaignManualCall = "TYPE_CAMPAIGN_MANUAL_CALL",
  TypeCampaignManualSms = "TYPE_CAMPAIGN_MANUAL_SMS",
  TypeCampaignSms = "TYPE_CAMPAIGN_SMS",
  TypeCampaignVoicemail = "TYPE_CAMPAIGN_VOICEMAIL",
  TypeCustomCall = "TYPE_CUSTOM_CALL",
  TypeCustomEmail = "TYPE_CUSTOM_EMAIL",
  TypeCustomProviderEmail = "TYPE_CUSTOM_PROVIDER_EMAIL",
  TypeCustomProviderSms = "TYPE_CUSTOM_PROVIDER_SMS",
  TypeCustomSms = "TYPE_CUSTOM_SMS",
  TypeEmail = "TYPE_EMAIL",
  TypeFacebook = "TYPE_FACEBOOK",
  TypeFacebookComment = "TYPE_FACEBOOK_COMMENT",
  TypeGmb = "TYPE_GMB",
  TypeInstagram = "TYPE_INSTAGRAM",
  TypeInstagramComment = "TYPE_INSTAGRAM_COMMENT",
  TypeIvrCall = "TYPE_IVR_CALL",
  TypeLiveChat = "TYPE_LIVE_CHAT",
  TypeLiveChatInfoMessage = "TYPE_LIVE_CHAT_INFO_MESSAGE",
  TypeNoShow = "TYPE_NO_SHOW",
  TypeReview = "TYPE_REVIEW",
  TypeSms = "TYPE_SMS",
  TypeSmsNoShowRequest = "TYPE_SMS_NO_SHOW_REQUEST",
  TypeSmsReviewRequest = "TYPE_SMS_REVIEW_REQUEST",
  TypeWebchat = "TYPE_WEBCHAT",
  TypeWhatsapp = "TYPE_WHATSAPP",
}

export type MessagesType = {
  __typename?: "MessagesType";
  lastMessageId: Scalars["String"]["output"];
  messages: Array<Maybe<MessageType>>;
  nextPage: Scalars["Boolean"]["output"];
};

/** Table for storing model-level permissions related to actions that can be performed */
export type ModelPermission = {
  __typename?: "ModelPermission";
  /** Unique codename of the permission, based on the model and the action */
  codename: Scalars["String"]["output"];
  /** Content type (model) to which the permission applies */
  contentTypeId: Scalars["Int"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Human-readable name of the permission */
  name: Scalars["String"]["output"];
  /** An array relationship */
  userMedspas: Array<UserMedspaModelPermissions>;
  /** An aggregate relationship */
  userMedspasAggregate: UserMedspaModelPermissionsAggregate;
};

/** Table for storing model-level permissions related to actions that can be performed */
export type ModelPermissionUserMedspasArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaModelPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

/** Table for storing model-level permissions related to actions that can be performed */
export type ModelPermissionUserMedspasAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaModelPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

/** aggregated selection of "model_permission" */
export type ModelPermissionAggregate = {
  __typename?: "ModelPermissionAggregate";
  aggregate?: Maybe<ModelPermissionAggregateFields>;
  nodes: Array<ModelPermission>;
};

/** aggregate fields of "model_permission" */
export type ModelPermissionAggregateFields = {
  __typename?: "ModelPermissionAggregateFields";
  avg?: Maybe<ModelPermissionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ModelPermissionMaxFields>;
  min?: Maybe<ModelPermissionMinFields>;
  stddev?: Maybe<ModelPermissionStddevFields>;
  stddevPop?: Maybe<ModelPermissionStddevPopFields>;
  stddevSamp?: Maybe<ModelPermissionStddevSampFields>;
  sum?: Maybe<ModelPermissionSumFields>;
  varPop?: Maybe<ModelPermissionVarPopFields>;
  varSamp?: Maybe<ModelPermissionVarSampFields>;
  variance?: Maybe<ModelPermissionVarianceFields>;
};

/** aggregate fields of "model_permission" */
export type ModelPermissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ModelPermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type ModelPermissionAvgFields = {
  __typename?: "ModelPermissionAvgFields";
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "model_permission". All fields are combined with a logical 'AND'. */
export type ModelPermissionBoolExp = {
  _and?: InputMaybe<Array<ModelPermissionBoolExp>>;
  _not?: InputMaybe<ModelPermissionBoolExp>;
  _or?: InputMaybe<Array<ModelPermissionBoolExp>>;
  codename?: InputMaybe<StringComparisonExp>;
  contentTypeId?: InputMaybe<IntComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  userMedspas?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
  userMedspasAggregate?: InputMaybe<UserMedspaModelPermissionsAggregateBoolExp>;
};

/** unique or primary key constraints on table "model_permission" */
export enum ModelPermissionConstraint {
  /** unique or primary key constraint on columns "codename" */
  ModelPermissionCodenameKey = "model_permission_codename_key",
  /** unique or primary key constraint on columns "id" */
  ModelPermissionPkey = "model_permission_pkey",
}

/** input type for incrementing numeric columns in table "model_permission" */
export type ModelPermissionIncInput = {
  /** Content type (model) to which the permission applies */
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "model_permission" */
export type ModelPermissionInsertInput = {
  /** Unique codename of the permission, based on the model and the action */
  codename?: InputMaybe<Scalars["String"]["input"]>;
  /** Content type (model) to which the permission applies */
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable name of the permission */
  name?: InputMaybe<Scalars["String"]["input"]>;
  userMedspas?: InputMaybe<UserMedspaModelPermissionsArrRelInsertInput>;
};

/** aggregate max on columns */
export type ModelPermissionMaxFields = {
  __typename?: "ModelPermissionMaxFields";
  /** Unique codename of the permission, based on the model and the action */
  codename?: Maybe<Scalars["String"]["output"]>;
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Human-readable name of the permission */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type ModelPermissionMinFields = {
  __typename?: "ModelPermissionMinFields";
  /** Unique codename of the permission, based on the model and the action */
  codename?: Maybe<Scalars["String"]["output"]>;
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Human-readable name of the permission */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "model_permission" */
export type ModelPermissionMutationResponse = {
  __typename?: "ModelPermissionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ModelPermission>;
};

/** input type for inserting object relation for remote table "model_permission" */
export type ModelPermissionObjRelInsertInput = {
  data: ModelPermissionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ModelPermissionOnConflict>;
};

/** on_conflict condition type for table "model_permission" */
export type ModelPermissionOnConflict = {
  constraint: ModelPermissionConstraint;
  updateColumns?: Array<ModelPermissionUpdateColumn>;
  where?: InputMaybe<ModelPermissionBoolExp>;
};

/** Ordering options when selecting data from "model_permission". */
export type ModelPermissionOrderBy = {
  codename?: InputMaybe<OrderBy>;
  contentTypeId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  userMedspasAggregate?: InputMaybe<UserMedspaModelPermissionsAggregateOrderBy>;
};

/** primary key columns input for table: model_permission */
export type ModelPermissionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "model_permission" */
export enum ModelPermissionSelectColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  ContentTypeId = "contentTypeId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "model_permission" */
export type ModelPermissionSetInput = {
  /** Unique codename of the permission, based on the model and the action */
  codename?: InputMaybe<Scalars["String"]["input"]>;
  /** Content type (model) to which the permission applies */
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable name of the permission */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ModelPermissionStddevFields = {
  __typename?: "ModelPermissionStddevFields";
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type ModelPermissionStddevPopFields = {
  __typename?: "ModelPermissionStddevPopFields";
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type ModelPermissionStddevSampFields = {
  __typename?: "ModelPermissionStddevSampFields";
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "model_permission" */
export type ModelPermissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ModelPermissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ModelPermissionStreamCursorValueInput = {
  /** Unique codename of the permission, based on the model and the action */
  codename?: InputMaybe<Scalars["String"]["input"]>;
  /** Content type (model) to which the permission applies */
  contentTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Human-readable name of the permission */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ModelPermissionSumFields = {
  __typename?: "ModelPermissionSumFields";
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "model_permission" */
export enum ModelPermissionUpdateColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  ContentTypeId = "contentTypeId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type ModelPermissionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ModelPermissionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ModelPermissionSetInput>;
  /** filter the rows which have to be updated */
  where: ModelPermissionBoolExp;
};

/** aggregate varPop on columns */
export type ModelPermissionVarPopFields = {
  __typename?: "ModelPermissionVarPopFields";
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type ModelPermissionVarSampFields = {
  __typename?: "ModelPermissionVarSampFields";
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type ModelPermissionVarianceFields = {
  __typename?: "ModelPermissionVarianceFields";
  /** Content type (model) to which the permission applies */
  contentTypeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type MoxieCardType = {
  __typename?: "MoxieCardType";
  brand?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["String"]["output"]>;
  expMonth?: Maybe<Scalars["Int"]["output"]>;
  expYear?: Maybe<Scalars["Int"]["output"]>;
  funding?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  last4?: Maybe<Scalars["String"]["output"]>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars["numeric"]["input"]>;
  _gt?: InputMaybe<Scalars["numeric"]["input"]>;
  _gte?: InputMaybe<Scalars["numeric"]["input"]>;
  _in?: InputMaybe<Array<Scalars["numeric"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["numeric"]["input"]>;
  _lte?: InputMaybe<Scalars["numeric"]["input"]>;
  _neq?: InputMaybe<Scalars["numeric"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["numeric"]["input"]>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = "ASC",
  /** in ascending order, nulls first */
  AscNullsFirst = "ASC_NULLS_FIRST",
  /** in ascending order, nulls last */
  AscNullsLast = "ASC_NULLS_LAST",
  /** in descending order, nulls first */
  Desc = "DESC",
  /** in descending order, nulls first */
  DescNullsFirst = "DESC_NULLS_FIRST",
  /** in descending order, nulls last */
  DescNullsLast = "DESC_NULLS_LAST",
}

export enum OutboundMessageTypeValues {
  Email = "EMAIL",
  Fb = "FB",
  Ig = "IG",
  Sms = "SMS",
}

export type PackageInputType = {
  packageId?: InputMaybe<Scalars["BigInt"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Lines for package items that were used in the context of a visit or an invoice */
export type PackageItemLine = {
  __typename?: "PackageItemLine";
  created: Scalars["timestamptz"]["output"];
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount of the associated package item */
  itemAmount: Scalars["Int"]["output"];
  /** Type of the associated package item */
  itemType: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  packageItem: PackageItem;
  /** Package item that was used to create this line */
  packageItemId: Scalars["bigint"]["output"];
  /** An object relationship */
  product?: Maybe<Product>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  serviceMenuItem?: Maybe<MedspaServiceMenuItem>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  serviceProduct?: Maybe<MedspaServiceProduct>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  visit?: Maybe<Visit>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  walletTransactionSources: Array<WalletTransactionSourceTmp>;
  /** An aggregate relationship */
  walletTransactionSourcesAggregate: WalletTransactionSourceTmpAggregate;
};

/** Lines for package items that were used in the context of a visit or an invoice */
export type PackageItemLineWalletTransactionSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** Lines for package items that were used in the context of a visit or an invoice */
export type PackageItemLineWalletTransactionSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** aggregated selection of "package_item_line" */
export type PackageItemLineAggregate = {
  __typename?: "PackageItemLineAggregate";
  aggregate?: Maybe<PackageItemLineAggregateFields>;
  nodes: Array<PackageItemLine>;
};

/** aggregate fields of "package_item_line" */
export type PackageItemLineAggregateFields = {
  __typename?: "PackageItemLineAggregateFields";
  avg?: Maybe<PackageItemLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<PackageItemLineMaxFields>;
  min?: Maybe<PackageItemLineMinFields>;
  stddev?: Maybe<PackageItemLineStddevFields>;
  stddevPop?: Maybe<PackageItemLineStddevPopFields>;
  stddevSamp?: Maybe<PackageItemLineStddevSampFields>;
  sum?: Maybe<PackageItemLineSumFields>;
  varPop?: Maybe<PackageItemLineVarPopFields>;
  varSamp?: Maybe<PackageItemLineVarSampFields>;
  variance?: Maybe<PackageItemLineVarianceFields>;
};

/** aggregate fields of "package_item_line" */
export type PackageItemLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PackageItemLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type PackageItemLineAvgFields = {
  __typename?: "PackageItemLineAvgFields";
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "package_item_line". All fields are combined with a logical 'AND'. */
export type PackageItemLineBoolExp = {
  _and?: InputMaybe<Array<PackageItemLineBoolExp>>;
  _not?: InputMaybe<PackageItemLineBoolExp>;
  _or?: InputMaybe<Array<PackageItemLineBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  dollarCreditAmount?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  itemAmount?: InputMaybe<IntComparisonExp>;
  itemType?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  packageItem?: InputMaybe<PackageItemBoolExp>;
  packageItemId?: InputMaybe<BigintComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  serviceMenuItemId?: InputMaybe<BigintComparisonExp>;
  serviceProduct?: InputMaybe<MedspaServiceProductBoolExp>;
  serviceProductId?: InputMaybe<BigintComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateBoolExp>;
};

/** unique or primary key constraints on table "package_item_line" */
export enum PackageItemLineConstraint {
  /** unique or primary key constraint on columns "id" */
  PackageItemLinePkey = "package_item_line_pkey",
}

/** input type for incrementing numeric columns in table "package_item_line" */
export type PackageItemLineIncInput = {
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount of the associated package item */
  itemAmount?: InputMaybe<Scalars["Int"]["input"]>;
  /** Package item that was used to create this line */
  packageItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Product that this line is associated with */
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service product that this line is associated with */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "package_item_line" */
export type PackageItemLineInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount of the associated package item */
  itemAmount?: InputMaybe<Scalars["Int"]["input"]>;
  /** Type of the associated package item */
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  packageItem?: InputMaybe<PackageItemObjRelInsertInput>;
  /** Package item that was used to create this line */
  packageItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  /** Product that this line is associated with */
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProduct?: InputMaybe<MedspaServiceProductObjRelInsertInput>;
  /** Service product that this line is associated with */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpArrRelInsertInput>;
};

/** aggregate max on columns */
export type PackageItemLineMaxFields = {
  __typename?: "PackageItemLineMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Int"]["output"]>;
  /** Type of the associated package item */
  itemType?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregate min on columns */
export type PackageItemLineMinFields = {
  __typename?: "PackageItemLineMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Int"]["output"]>;
  /** Type of the associated package item */
  itemType?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** response of any mutation on the table "package_item_line" */
export type PackageItemLineMutationResponse = {
  __typename?: "PackageItemLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<PackageItemLine>;
};

/** input type for inserting object relation for remote table "package_item_line" */
export type PackageItemLineObjRelInsertInput = {
  data: PackageItemLineInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PackageItemLineOnConflict>;
};

/** on_conflict condition type for table "package_item_line" */
export type PackageItemLineOnConflict = {
  constraint: PackageItemLineConstraint;
  updateColumns?: Array<PackageItemLineUpdateColumn>;
  where?: InputMaybe<PackageItemLineBoolExp>;
};

/** Ordering options when selecting data from "package_item_line". */
export type PackageItemLineOrderBy = {
  created?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  itemType?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  packageItem?: InputMaybe<PackageItemOrderBy>;
  packageItemId?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProduct?: InputMaybe<MedspaServiceProductOrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateOrderBy>;
};

/** primary key columns input for table: package_item_line */
export type PackageItemLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "package_item_line" */
export enum PackageItemLineSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DollarCreditAmount = "dollarCreditAmount",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  ItemAmount = "itemAmount",
  /** column name */
  ItemType = "itemType",
  /** column name */
  Modified = "modified",
  /** column name */
  PackageItemId = "packageItemId",
  /** column name */
  ProductId = "productId",
  /** column name */
  ServiceMenuItemId = "serviceMenuItemId",
  /** column name */
  ServiceProductId = "serviceProductId",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "package_item_line" */
export type PackageItemLineSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount of the associated package item */
  itemAmount?: InputMaybe<Scalars["Int"]["input"]>;
  /** Type of the associated package item */
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Package item that was used to create this line */
  packageItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Product that this line is associated with */
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service product that this line is associated with */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type PackageItemLineStddevFields = {
  __typename?: "PackageItemLineStddevFields";
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type PackageItemLineStddevPopFields = {
  __typename?: "PackageItemLineStddevPopFields";
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type PackageItemLineStddevSampFields = {
  __typename?: "PackageItemLineStddevSampFields";
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "package_item_line" */
export type PackageItemLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PackageItemLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PackageItemLineStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount of the associated package item */
  itemAmount?: InputMaybe<Scalars["Int"]["input"]>;
  /** Type of the associated package item */
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Package item that was used to create this line */
  packageItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Product that this line is associated with */
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service product that this line is associated with */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type PackageItemLineSumFields = {
  __typename?: "PackageItemLineSumFields";
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Int"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "package_item_line" */
export enum PackageItemLineUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DollarCreditAmount = "dollarCreditAmount",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  ItemAmount = "itemAmount",
  /** column name */
  ItemType = "itemType",
  /** column name */
  Modified = "modified",
  /** column name */
  PackageItemId = "packageItemId",
  /** column name */
  ProductId = "productId",
  /** column name */
  ServiceMenuItemId = "serviceMenuItemId",
  /** column name */
  ServiceProductId = "serviceProductId",
  /** column name */
  VisitId = "visitId",
}

export type PackageItemLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PackageItemLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PackageItemLineSetInput>;
  /** filter the rows which have to be updated */
  where: PackageItemLineBoolExp;
};

/** aggregate varPop on columns */
export type PackageItemLineVarPopFields = {
  __typename?: "PackageItemLineVarPopFields";
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type PackageItemLineVarSampFields = {
  __typename?: "PackageItemLineVarSampFields";
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type PackageItemLineVarianceFields = {
  __typename?: "PackageItemLineVarianceFields";
  /** Dollar credit amount granted by the associated package item */
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of the associated package item */
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Package item that was used to create this line */
  packageItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that this line is associated with */
  productId?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that this line is associated with */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that this line is associated with */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

export type PackageLinePricesType = {
  __typename?: "PackageLinePricesType";
  id: Scalars["ID"]["output"];
  totalPrice?: Maybe<Scalars["Decimal"]["output"]>;
  totalTax?: Maybe<Scalars["Decimal"]["output"]>;
};

export type PackageLineType = {
  amount: Scalars["Decimal"]["input"];
  invoiceId?: InputMaybe<Scalars["String"]["input"]>;
  packageId: Scalars["String"]["input"];
  price: Scalars["Decimal"]["input"];
  tax?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type PackageType = {
  __typename?: "PackageType";
  id: Scalars["ID"]["output"];
  thumbnail?: Maybe<Scalars["String"]["output"]>;
};

export type PayAffirm = {
  __typename?: "PayAffirm";
  clientCheckoutUrl?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
  paymentIntent?: Maybe<PaymentIntentType>;
};

export type PayCard = {
  __typename?: "PayCard";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
  paymentIntent?: Maybe<PaymentIntentType>;
};

export type PayExternalMethod = {
  __typename?: "PayExternalMethod";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
};

export type PayTerminal = {
  __typename?: "PayTerminal";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
};

export type PayWallet = {
  __typename?: "PayWallet";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
};

export type PaymentAdditionalInfoType = {
  __typename?: "PaymentAdditionalInfoType";
  alreadyRefunded?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
  leftToRefund?: Maybe<Scalars["Decimal"]["output"]>;
};

export type PaymentIntentType = {
  __typename?: "PaymentIntentType";
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentType = {
  __typename?: "PaymentType";
  id: Scalars["ID"]["output"];
};

export type PhotoType = {
  __typename?: "PhotoType";
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  editMetadata: Scalars["JSONString"]["output"];
  id: Scalars["ID"]["output"];
  isProcessed: Scalars["Boolean"]["output"];
  isUploaded: Scalars["Boolean"]["output"];
  label: VisitsPhotoLabelChoices;
  original?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  photoSmall?: Maybe<Scalars["String"]["output"]>;
};

export type ProductDetailsInput = {
  amount: Scalars["String"]["input"];
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lotId?: InputMaybe<Scalars["String"]["input"]>;
  lotNumber?: InputMaybe<Scalars["String"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductLinePricesType = {
  __typename?: "ProductLinePricesType";
  id: Scalars["ID"]["output"];
  totalPrice?: Maybe<Scalars["Decimal"]["output"]>;
  totalPriceWithoutCredits?: Maybe<Scalars["Decimal"]["output"]>;
  totalTax?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ProductLinePricesWithoutWalletCreditsType = {
  __typename?: "ProductLinePricesWithoutWalletCreditsType";
  id: Scalars["ID"]["output"];
  totalPrice?: Maybe<Scalars["Decimal"]["output"]>;
  totalPriceWithoutCredits?: Maybe<Scalars["Decimal"]["output"]>;
  totalTax?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ProductSumType = {
  __typename?: "ProductSumType";
  productId: Scalars["String"]["output"];
  sum: Scalars["Decimal"]["output"];
};

export type ProviderComplianceTaskType = {
  __typename?: "ProviderComplianceTaskType";
  complianceTasks?: Maybe<Array<Maybe<ComplianceTaskType>>>;
  providerFullName?: Maybe<Scalars["String"]["output"]>;
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** Records in this table indicate that a provider does not require a specific document (like standing order or service protocol) for a specific medspa service offering. This information is mostly used in the compliance hub. */
export type ProviderNotRequireDocuments = {
  __typename?: "ProviderNotRequireDocuments";
  created: Scalars["timestamptz"]["output"];
  /** Type of document that is not required for the provider and the medspa service offering */
  documentType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaServiceOffering: MedspaServiceOffering;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  provider: UserMedspa;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId: Scalars["bigint"]["output"];
};

/** aggregated selection of "provider_not_require_documents" */
export type ProviderNotRequireDocumentsAggregate = {
  __typename?: "ProviderNotRequireDocumentsAggregate";
  aggregate?: Maybe<ProviderNotRequireDocumentsAggregateFields>;
  nodes: Array<ProviderNotRequireDocuments>;
};

export type ProviderNotRequireDocumentsAggregateBoolExp = {
  count?: InputMaybe<ProviderNotRequireDocumentsAggregateBoolExpCount>;
};

/** aggregate fields of "provider_not_require_documents" */
export type ProviderNotRequireDocumentsAggregateFields = {
  __typename?: "ProviderNotRequireDocumentsAggregateFields";
  avg?: Maybe<ProviderNotRequireDocumentsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ProviderNotRequireDocumentsMaxFields>;
  min?: Maybe<ProviderNotRequireDocumentsMinFields>;
  stddev?: Maybe<ProviderNotRequireDocumentsStddevFields>;
  stddevPop?: Maybe<ProviderNotRequireDocumentsStddevPopFields>;
  stddevSamp?: Maybe<ProviderNotRequireDocumentsStddevSampFields>;
  sum?: Maybe<ProviderNotRequireDocumentsSumFields>;
  varPop?: Maybe<ProviderNotRequireDocumentsVarPopFields>;
  varSamp?: Maybe<ProviderNotRequireDocumentsVarSampFields>;
  variance?: Maybe<ProviderNotRequireDocumentsVarianceFields>;
};

/** aggregate fields of "provider_not_require_documents" */
export type ProviderNotRequireDocumentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsAggregateOrderBy = {
  avg?: InputMaybe<ProviderNotRequireDocumentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProviderNotRequireDocumentsMaxOrderBy>;
  min?: InputMaybe<ProviderNotRequireDocumentsMinOrderBy>;
  stddev?: InputMaybe<ProviderNotRequireDocumentsStddevOrderBy>;
  stddevPop?: InputMaybe<ProviderNotRequireDocumentsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ProviderNotRequireDocumentsStddevSampOrderBy>;
  sum?: InputMaybe<ProviderNotRequireDocumentsSumOrderBy>;
  varPop?: InputMaybe<ProviderNotRequireDocumentsVarPopOrderBy>;
  varSamp?: InputMaybe<ProviderNotRequireDocumentsVarSampOrderBy>;
  variance?: InputMaybe<ProviderNotRequireDocumentsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsArrRelInsertInput = {
  data: Array<ProviderNotRequireDocumentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProviderNotRequireDocumentsOnConflict>;
};

/** aggregate avg on columns */
export type ProviderNotRequireDocumentsAvgFields = {
  __typename?: "ProviderNotRequireDocumentsAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "provider_not_require_documents". All fields are combined with a logical 'AND'. */
export type ProviderNotRequireDocumentsBoolExp = {
  _and?: InputMaybe<Array<ProviderNotRequireDocumentsBoolExp>>;
  _not?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
  _or?: InputMaybe<Array<ProviderNotRequireDocumentsBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  documentType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaServiceOfferingId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "provider_not_require_documents" */
export enum ProviderNotRequireDocumentsConstraint {
  /** unique or primary key constraint on columns "medspa_service_offering_id", "document_type", "provider_id" */
  ProviderNotRequireDocProviderIdDocumentTyp_0c49c880Uniq = "provider_not_require_doc_provider_id_document_typ_0c49c880_uniq",
  /** unique or primary key constraint on columns "id" */
  ProviderNotRequireDocumentsPkey = "provider_not_require_documents_pkey",
}

/** input type for incrementing numeric columns in table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of document that is not required for the provider and the medspa service offering */
  documentType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingObjRelInsertInput>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ProviderNotRequireDocumentsMaxFields = {
  __typename?: "ProviderNotRequireDocumentsMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of document that is not required for the provider and the medspa service offering */
  documentType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Type of document that is not required for the provider and the medspa service offering */
  documentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProviderNotRequireDocumentsMinFields = {
  __typename?: "ProviderNotRequireDocumentsMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of document that is not required for the provider and the medspa service offering */
  documentType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  /** Type of document that is not required for the provider and the medspa service offering */
  documentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsMutationResponse = {
  __typename?: "ProviderNotRequireDocumentsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ProviderNotRequireDocuments>;
};

/** on_conflict condition type for table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsOnConflict = {
  constraint: ProviderNotRequireDocumentsConstraint;
  updateColumns?: Array<ProviderNotRequireDocumentsUpdateColumn>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

/** Ordering options when selecting data from "provider_not_require_documents". */
export type ProviderNotRequireDocumentsOrderBy = {
  created?: InputMaybe<OrderBy>;
  documentType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceOffering?: InputMaybe<MedspaServiceOfferingOrderBy>;
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: provider_not_require_documents */
export type ProviderNotRequireDocumentsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "provider_not_require_documents" */
export enum ProviderNotRequireDocumentsSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DocumentType = "documentType",
  /** column name */
  Id = "id",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderId = "providerId",
}

/** input type for updating data in table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of document that is not required for the provider and the medspa service offering */
  documentType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ProviderNotRequireDocumentsStddevFields = {
  __typename?: "ProviderNotRequireDocumentsStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProviderNotRequireDocumentsStddevPopFields = {
  __typename?: "ProviderNotRequireDocumentsStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProviderNotRequireDocumentsStddevSampFields = {
  __typename?: "ProviderNotRequireDocumentsStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProviderNotRequireDocumentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProviderNotRequireDocumentsStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of document that is not required for the provider and the medspa service offering */
  documentType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ProviderNotRequireDocumentsSumFields = {
  __typename?: "ProviderNotRequireDocumentsSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["bigint"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** update columns of table "provider_not_require_documents" */
export enum ProviderNotRequireDocumentsUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DocumentType = "documentType",
  /** column name */
  Id = "id",
  /** column name */
  MedspaServiceOfferingId = "medspaServiceOfferingId",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderId = "providerId",
}

export type ProviderNotRequireDocumentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProviderNotRequireDocumentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProviderNotRequireDocumentsSetInput>;
  /** filter the rows which have to be updated */
  where: ProviderNotRequireDocumentsBoolExp;
};

/** aggregate varPop on columns */
export type ProviderNotRequireDocumentsVarPopFields = {
  __typename?: "ProviderNotRequireDocumentsVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProviderNotRequireDocumentsVarSampFields = {
  __typename?: "ProviderNotRequireDocumentsVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProviderNotRequireDocumentsVarianceFields = {
  __typename?: "ProviderNotRequireDocumentsVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: Maybe<Scalars["Float"]["output"]>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "provider_not_require_documents" */
export type ProviderNotRequireDocumentsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** The MedSpa Service Offering that does not require a document for the provider */
  medspaServiceOfferingId?: InputMaybe<OrderBy>;
  /** A provider that does not require documents for a specific medspa service offering */
  providerId?: InputMaybe<OrderBy>;
};

export type ProviderTasksType = {
  __typename?: "ProviderTasksType";
  complianceTaskTemplates?: Maybe<Array<Maybe<ComplianceTaskTemplateType>>>;
  providers?: Maybe<Array<Maybe<ProviderComplianceTaskType>>>;
};

export type RecurringAvailabilityType = {
  __typename?: "RecurringAvailabilityType";
  allDay: Scalars["Boolean"]["output"];
  endTime: Scalars["Time"]["output"];
  eventType: SchedulingRecurringAvailabilityEventTypeChoices;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  startTime: Scalars["Time"]["output"];
  weekday: SchedulingRecurringAvailabilityWeekdayChoices;
};

export type RefreshClientAccessToken = {
  __typename?: "RefreshClientAccessToken";
  clientAccessToken?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RefreshServiceMenuItemLineGfeStatus = {
  __typename?: "RefreshServiceMenuItemLineGfeStatus";
  message?: Maybe<Scalars["String"]["output"]>;
  newGfeStatus?: Maybe<ServiceMenuItemGfeStatus>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RegisterReaderForMerchant = {
  __typename?: "RegisterReaderForMerchant";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RemoveAdverseReaction = {
  __typename?: "RemoveAdverseReaction";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RemoveAvailabilityEvent = {
  __typename?: "RemoveAvailabilityEvent";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RemoveDiagramTemplate = {
  __typename?: "RemoveDiagramTemplate";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RemoveRecurringAvailability = {
  __typename?: "RemoveRecurringAvailability";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RemoveStripeTerminal = {
  __typename?: "RemoveStripeTerminal";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RequestConsentForms = {
  __typename?: "RequestConsentForms";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RequestGfePartialReReview = {
  __typename?: "RequestGfePartialReReview";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  reviewRequest?: Maybe<GfeReviewRequestType>;
};

export type RequestGfeReview = {
  __typename?: "RequestGfeReview";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  reviewRequest?: Maybe<GfeReviewRequestType>;
};

export type RequestSyncGfeInvite = {
  __typename?: "RequestSyncGfeInvite";
  meetingUrl?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RescheduleAppointment = {
  __typename?: "RescheduleAppointment";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  rescheduledAppointment?: Maybe<AppointmentType>;
  visitId?: Maybe<Scalars["ID"]["output"]>;
};

export type RescheduleAppointmentByClient = {
  __typename?: "RescheduleAppointmentByClient";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  rescheduledAppointment?: Maybe<AppointmentType>;
  visitId?: Maybe<Scalars["ID"]["output"]>;
};

export type RescheduleAvailabilityEvent = {
  __typename?: "RescheduleAvailabilityEvent";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  rescheduledAvailabilityEvent?: Maybe<AvailabilityEventType>;
};

export type RescheduleMdMeeting = {
  __typename?: "RescheduleMDMeeting";
  mdMeeting?: Maybe<MdMeetingType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

/** An enumeration. */
export enum ResourcesDiscountDiscountTypeChoices {
  /** Fixed value */
  FixedValue = "FIXED_VALUE",
  /** Percent */
  Percent = "PERCENT",
}

/** Table to store rooms available for booking appointments in a MedSpa. */
export type ResourcesMedspaRoom = {
  __typename?: "ResourcesMedspaRoom";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  deletedBy?: Maybe<UserMedspa>;
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** A MedSpa this room belongs to */
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaRoomsAppointments: Array<MedspaRoomsAppointments>;
  /** An aggregate relationship */
  medspaRoomsAppointmentsAggregate: MedspaRoomsAppointmentsAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** Name of the room */
  name: Scalars["String"]["output"];
};

/** Table to store rooms available for booking appointments in a MedSpa. */
export type ResourcesMedspaRoomMedspaRoomsAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaRoomsAppointmentsOrderBy>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

/** Table to store rooms available for booking appointments in a MedSpa. */
export type ResourcesMedspaRoomMedspaRoomsAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaRoomsAppointmentsOrderBy>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

/** aggregated selection of "resources_medspa_room" */
export type ResourcesMedspaRoomAggregate = {
  __typename?: "ResourcesMedspaRoomAggregate";
  aggregate?: Maybe<ResourcesMedspaRoomAggregateFields>;
  nodes: Array<ResourcesMedspaRoom>;
};

export type ResourcesMedspaRoomAggregateBoolExp = {
  count?: InputMaybe<ResourcesMedspaRoomAggregateBoolExpCount>;
};

/** aggregate fields of "resources_medspa_room" */
export type ResourcesMedspaRoomAggregateFields = {
  __typename?: "ResourcesMedspaRoomAggregateFields";
  avg?: Maybe<ResourcesMedspaRoomAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ResourcesMedspaRoomMaxFields>;
  min?: Maybe<ResourcesMedspaRoomMinFields>;
  stddev?: Maybe<ResourcesMedspaRoomStddevFields>;
  stddevPop?: Maybe<ResourcesMedspaRoomStddevPopFields>;
  stddevSamp?: Maybe<ResourcesMedspaRoomStddevSampFields>;
  sum?: Maybe<ResourcesMedspaRoomSumFields>;
  varPop?: Maybe<ResourcesMedspaRoomVarPopFields>;
  varSamp?: Maybe<ResourcesMedspaRoomVarSampFields>;
  variance?: Maybe<ResourcesMedspaRoomVarianceFields>;
};

/** aggregate fields of "resources_medspa_room" */
export type ResourcesMedspaRoomAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_medspa_room" */
export type ResourcesMedspaRoomAggregateOrderBy = {
  avg?: InputMaybe<ResourcesMedspaRoomAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ResourcesMedspaRoomMaxOrderBy>;
  min?: InputMaybe<ResourcesMedspaRoomMinOrderBy>;
  stddev?: InputMaybe<ResourcesMedspaRoomStddevOrderBy>;
  stddevPop?: InputMaybe<ResourcesMedspaRoomStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ResourcesMedspaRoomStddevSampOrderBy>;
  sum?: InputMaybe<ResourcesMedspaRoomSumOrderBy>;
  varPop?: InputMaybe<ResourcesMedspaRoomVarPopOrderBy>;
  varSamp?: InputMaybe<ResourcesMedspaRoomVarSampOrderBy>;
  variance?: InputMaybe<ResourcesMedspaRoomVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_medspa_room" */
export type ResourcesMedspaRoomArrRelInsertInput = {
  data: Array<ResourcesMedspaRoomInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ResourcesMedspaRoomOnConflict>;
};

/** aggregate avg on columns */
export type ResourcesMedspaRoomAvgFields = {
  __typename?: "ResourcesMedspaRoomAvgFields";
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomAvgOrderBy = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_medspa_room". All fields are combined with a logical 'AND'. */
export type ResourcesMedspaRoomBoolExp = {
  _and?: InputMaybe<Array<ResourcesMedspaRoomBoolExp>>;
  _not?: InputMaybe<ResourcesMedspaRoomBoolExp>;
  _or?: InputMaybe<Array<ResourcesMedspaRoomBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedBy?: InputMaybe<UserMedspaBoolExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaRoomsAppointments?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
  medspaRoomsAppointmentsAggregate?: InputMaybe<MedspaRoomsAppointmentsAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "resources_medspa_room" */
export enum ResourcesMedspaRoomConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesMedspaRoomPkey = "resources_medspa_room_pkey",
}

/** input type for incrementing numeric columns in table "resources_medspa_room" */
export type ResourcesMedspaRoomIncInput = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_medspa_room" */
export type ResourcesMedspaRoomInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaRoomsAppointments?: InputMaybe<MedspaRoomsAppointmentsArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the room */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ResourcesMedspaRoomMaxFields = {
  __typename?: "ResourcesMedspaRoomMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the room */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Name of the room */
  name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ResourcesMedspaRoomMinFields = {
  __typename?: "ResourcesMedspaRoomMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the room */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Name of the room */
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_medspa_room" */
export type ResourcesMedspaRoomMutationResponse = {
  __typename?: "ResourcesMedspaRoomMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ResourcesMedspaRoom>;
};

/** input type for inserting object relation for remote table "resources_medspa_room" */
export type ResourcesMedspaRoomObjRelInsertInput = {
  data: ResourcesMedspaRoomInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ResourcesMedspaRoomOnConflict>;
};

/** on_conflict condition type for table "resources_medspa_room" */
export type ResourcesMedspaRoomOnConflict = {
  constraint: ResourcesMedspaRoomConstraint;
  updateColumns?: Array<ResourcesMedspaRoomUpdateColumn>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

/** Ordering options when selecting data from "resources_medspa_room". */
export type ResourcesMedspaRoomOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UserMedspaOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaRoomsAppointmentsAggregate?: InputMaybe<MedspaRoomsAppointmentsAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_medspa_room */
export type ResourcesMedspaRoomPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_medspa_room" */
export enum ResourcesMedspaRoomSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "resources_medspa_room" */
export type ResourcesMedspaRoomSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the room */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ResourcesMedspaRoomStddevFields = {
  __typename?: "ResourcesMedspaRoomStddevFields";
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomStddevOrderBy = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ResourcesMedspaRoomStddevPopFields = {
  __typename?: "ResourcesMedspaRoomStddevPopFields";
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomStddevPopOrderBy = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ResourcesMedspaRoomStddevSampFields = {
  __typename?: "ResourcesMedspaRoomStddevSampFields";
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomStddevSampOrderBy = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "resources_medspa_room" */
export type ResourcesMedspaRoomStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ResourcesMedspaRoomStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ResourcesMedspaRoomStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the room */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ResourcesMedspaRoomSumFields = {
  __typename?: "ResourcesMedspaRoomSumFields";
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomSumOrderBy = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_medspa_room" */
export enum ResourcesMedspaRoomUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type ResourcesMedspaRoomUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ResourcesMedspaRoomIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResourcesMedspaRoomSetInput>;
  /** filter the rows which have to be updated */
  where: ResourcesMedspaRoomBoolExp;
};

/** aggregate varPop on columns */
export type ResourcesMedspaRoomVarPopFields = {
  __typename?: "ResourcesMedspaRoomVarPopFields";
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomVarPopOrderBy = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ResourcesMedspaRoomVarSampFields = {
  __typename?: "ResourcesMedspaRoomVarSampFields";
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomVarSampOrderBy = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ResourcesMedspaRoomVarianceFields = {
  __typename?: "ResourcesMedspaRoomVarianceFields";
  /** UserMedSpa who deleted the room */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A MedSpa this room belongs to */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_medspa_room" */
export type ResourcesMedspaRoomVarianceOrderBy = {
  /** UserMedSpa who deleted the room */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** A MedSpa this room belongs to */
  medspaId?: InputMaybe<OrderBy>;
};

export type RoomType = {
  __typename?: "RoomType";
  availableRooms?: Maybe<Scalars["Int"]["output"]>;
  isRoomRequired?: Maybe<Scalars["Boolean"]["output"]>;
};

/** columns and relationships of "scheduling_appointment" */
export type SchedulingAppointment = {
  __typename?: "SchedulingAppointment";
  accessToken: Scalars["String"]["output"];
  bookedById?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  booked_by?: Maybe<UserMedspa>;
  bookingFlow: Scalars["String"]["output"];
  /** An object relationship */
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  customerEmail?: Maybe<Scalars["String"]["output"]>;
  customerPhone?: Maybe<Scalars["String"]["output"]>;
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["bigint"]["output"];
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaRoomsAppointments: Array<MedspaRoomsAppointments>;
  /** An aggregate relationship */
  medspaRoomsAppointmentsAggregate: MedspaRoomsAppointmentsAggregate;
  modified: Scalars["timestamptz"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  provider: User;
  providerId: Scalars["bigint"]["output"];
  /** An array relationship */
  serviceDevicesAppointments: Array<ServiceDevicesAppointments>;
  /** An aggregate relationship */
  serviceDevicesAppointmentsAggregate: ServiceDevicesAppointmentsAggregate;
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
  timeSpan: Scalars["tstzrange"]["output"];
  /** An object relationship */
  visit?: Maybe<Visit>;
};

/** columns and relationships of "scheduling_appointment" */
export type SchedulingAppointmentMedspaRoomsAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaRoomsAppointmentsOrderBy>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

/** columns and relationships of "scheduling_appointment" */
export type SchedulingAppointmentMedspaRoomsAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaRoomsAppointmentsOrderBy>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

/** columns and relationships of "scheduling_appointment" */
export type SchedulingAppointmentServiceDevicesAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesAppointmentsOrderBy>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

/** columns and relationships of "scheduling_appointment" */
export type SchedulingAppointmentServiceDevicesAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesAppointmentsOrderBy>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

/** aggregated selection of "scheduling_appointment" */
export type SchedulingAppointmentAggregate = {
  __typename?: "SchedulingAppointmentAggregate";
  aggregate?: Maybe<SchedulingAppointmentAggregateFields>;
  nodes: Array<SchedulingAppointment>;
};

/** aggregate fields of "scheduling_appointment" */
export type SchedulingAppointmentAggregateFields = {
  __typename?: "SchedulingAppointmentAggregateFields";
  avg?: Maybe<SchedulingAppointmentAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<SchedulingAppointmentMaxFields>;
  min?: Maybe<SchedulingAppointmentMinFields>;
  stddev?: Maybe<SchedulingAppointmentStddevFields>;
  stddevPop?: Maybe<SchedulingAppointmentStddevPopFields>;
  stddevSamp?: Maybe<SchedulingAppointmentStddevSampFields>;
  sum?: Maybe<SchedulingAppointmentSumFields>;
  varPop?: Maybe<SchedulingAppointmentVarPopFields>;
  varSamp?: Maybe<SchedulingAppointmentVarSampFields>;
  variance?: Maybe<SchedulingAppointmentVarianceFields>;
};

/** aggregate fields of "scheduling_appointment" */
export type SchedulingAppointmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SchedulingAppointmentSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type SchedulingAppointmentAvgFields = {
  __typename?: "SchedulingAppointmentAvgFields";
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "scheduling_appointment". All fields are combined with a logical 'AND'. */
export type SchedulingAppointmentBoolExp = {
  _and?: InputMaybe<Array<SchedulingAppointmentBoolExp>>;
  _not?: InputMaybe<SchedulingAppointmentBoolExp>;
  _or?: InputMaybe<Array<SchedulingAppointmentBoolExp>>;
  accessToken?: InputMaybe<StringComparisonExp>;
  bookedById?: InputMaybe<BigintComparisonExp>;
  booked_by?: InputMaybe<UserMedspaBoolExp>;
  bookingFlow?: InputMaybe<StringComparisonExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  customerEmail?: InputMaybe<StringComparisonExp>;
  customerPhone?: InputMaybe<StringComparisonExp>;
  endTime?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaRoomsAppointments?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
  medspaRoomsAppointmentsAggregate?: InputMaybe<MedspaRoomsAppointmentsAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  provider?: InputMaybe<UserBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  serviceDevicesAppointments?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
  serviceDevicesAppointmentsAggregate?: InputMaybe<ServiceDevicesAppointmentsAggregateBoolExp>;
  startTime?: InputMaybe<TimestamptzComparisonExp>;
  timeSpan?: InputMaybe<TstzrangeComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
};

/** unique or primary key constraints on table "scheduling_appointment" */
export enum SchedulingAppointmentConstraint {
  /** unique or primary key constraint on columns "access_token" */
  SchedulingAppointmentAccessTokenD573eda3Uniq = "scheduling_appointment_access_token_d573eda3_uniq",
  /** unique or primary key constraint on columns "id" */
  SchedulingAppointmentPkey = "scheduling_appointment_pkey",
}

/** input type for incrementing numeric columns in table "scheduling_appointment" */
export type SchedulingAppointmentIncInput = {
  bookedById?: InputMaybe<Scalars["bigint"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "scheduling_appointment" */
export type SchedulingAppointmentInsertInput = {
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
  bookedById?: InputMaybe<Scalars["bigint"]["input"]>;
  booked_by?: InputMaybe<UserMedspaObjRelInsertInput>;
  bookingFlow?: InputMaybe<Scalars["String"]["input"]>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  customerEmail?: InputMaybe<Scalars["String"]["input"]>;
  customerPhone?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaRoomsAppointments?: InputMaybe<MedspaRoomsAppointmentsArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<UserObjRelInsertInput>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceDevicesAppointments?: InputMaybe<ServiceDevicesAppointmentsArrRelInsertInput>;
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
};

/** aggregate max on columns */
export type SchedulingAppointmentMaxFields = {
  __typename?: "SchedulingAppointmentMaxFields";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  bookedById?: Maybe<Scalars["bigint"]["output"]>;
  bookingFlow?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  customerEmail?: Maybe<Scalars["String"]["output"]>;
  customerPhone?: Maybe<Scalars["String"]["output"]>;
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type SchedulingAppointmentMinFields = {
  __typename?: "SchedulingAppointmentMinFields";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  bookedById?: Maybe<Scalars["bigint"]["output"]>;
  bookingFlow?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  customerEmail?: Maybe<Scalars["String"]["output"]>;
  customerPhone?: Maybe<Scalars["String"]["output"]>;
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "scheduling_appointment" */
export type SchedulingAppointmentMutationResponse = {
  __typename?: "SchedulingAppointmentMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<SchedulingAppointment>;
};

/** input type for inserting object relation for remote table "scheduling_appointment" */
export type SchedulingAppointmentObjRelInsertInput = {
  data: SchedulingAppointmentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SchedulingAppointmentOnConflict>;
};

/** on_conflict condition type for table "scheduling_appointment" */
export type SchedulingAppointmentOnConflict = {
  constraint: SchedulingAppointmentConstraint;
  updateColumns?: Array<SchedulingAppointmentUpdateColumn>;
  where?: InputMaybe<SchedulingAppointmentBoolExp>;
};

/** Ordering options when selecting data from "scheduling_appointment". */
export type SchedulingAppointmentOrderBy = {
  accessToken?: InputMaybe<OrderBy>;
  bookedById?: InputMaybe<OrderBy>;
  booked_by?: InputMaybe<UserMedspaOrderBy>;
  bookingFlow?: InputMaybe<OrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  customerEmail?: InputMaybe<OrderBy>;
  customerPhone?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaRoomsAppointmentsAggregate?: InputMaybe<MedspaRoomsAppointmentsAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  serviceDevicesAppointmentsAggregate?: InputMaybe<ServiceDevicesAppointmentsAggregateOrderBy>;
  startTime?: InputMaybe<OrderBy>;
  timeSpan?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
};

/** primary key columns input for table: scheduling_appointment */
export type SchedulingAppointmentPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "scheduling_appointment" */
export enum SchedulingAppointmentSelectColumn {
  /** column name */
  AccessToken = "accessToken",
  /** column name */
  BookedById = "bookedById",
  /** column name */
  BookingFlow = "bookingFlow",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CustomerEmail = "customerEmail",
  /** column name */
  CustomerPhone = "customerPhone",
  /** column name */
  EndTime = "endTime",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  StartTime = "startTime",
  /** column name */
  TimeSpan = "timeSpan",
}

/** input type for updating data in table "scheduling_appointment" */
export type SchedulingAppointmentSetInput = {
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
  bookedById?: InputMaybe<Scalars["bigint"]["input"]>;
  bookingFlow?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  customerEmail?: InputMaybe<Scalars["String"]["input"]>;
  customerPhone?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
};

/** aggregate stddev on columns */
export type SchedulingAppointmentStddevFields = {
  __typename?: "SchedulingAppointmentStddevFields";
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type SchedulingAppointmentStddevPopFields = {
  __typename?: "SchedulingAppointmentStddevPopFields";
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type SchedulingAppointmentStddevSampFields = {
  __typename?: "SchedulingAppointmentStddevSampFields";
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "scheduling_appointment" */
export type SchedulingAppointmentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SchedulingAppointmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SchedulingAppointmentStreamCursorValueInput = {
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
  bookedById?: InputMaybe<Scalars["bigint"]["input"]>;
  bookingFlow?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  customerEmail?: InputMaybe<Scalars["String"]["input"]>;
  customerPhone?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  startTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
};

/** aggregate sum on columns */
export type SchedulingAppointmentSumFields = {
  __typename?: "SchedulingAppointmentSumFields";
  bookedById?: Maybe<Scalars["bigint"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "scheduling_appointment" */
export enum SchedulingAppointmentUpdateColumn {
  /** column name */
  AccessToken = "accessToken",
  /** column name */
  BookedById = "bookedById",
  /** column name */
  BookingFlow = "bookingFlow",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CustomerEmail = "customerEmail",
  /** column name */
  CustomerPhone = "customerPhone",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  TimeSpan = "timeSpan",
}

export type SchedulingAppointmentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SchedulingAppointmentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SchedulingAppointmentSetInput>;
  /** filter the rows which have to be updated */
  where: SchedulingAppointmentBoolExp;
};

/** aggregate varPop on columns */
export type SchedulingAppointmentVarPopFields = {
  __typename?: "SchedulingAppointmentVarPopFields";
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type SchedulingAppointmentVarSampFields = {
  __typename?: "SchedulingAppointmentVarSampFields";
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type SchedulingAppointmentVarianceFields = {
  __typename?: "SchedulingAppointmentVarianceFields";
  bookedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** An enumeration. */
export enum SchedulingAvailabilityEventEventTypeChoices {
  /** Allow period */
  AllowPeriod = "ALLOW_PERIOD",
  /** Block period */
  BlockPeriod = "BLOCK_PERIOD",
}

/** An enumeration. */
export enum SchedulingRecurringAvailabilityEventTypeChoices {
  /** Allow period */
  AllowPeriod = "ALLOW_PERIOD",
  /** Block period */
  BlockPeriod = "BLOCK_PERIOD",
}

/** An enumeration. */
export enum SchedulingRecurringAvailabilityWeekdayChoices {
  /** Friday */
  Friday = "FRIDAY",
  /** Monday */
  Monday = "MONDAY",
  /** Saturday */
  Saturday = "SATURDAY",
  /** Sunday */
  Sunday = "SUNDAY",
  /** Thursday */
  Thursday = "THURSDAY",
  /** Tuesday */
  Tuesday = "TUESDAY",
  /** Wednesday */
  Wednesday = "WEDNESDAY",
}

/** Table to store only script products that can be prescribed to clients. */
export type ScriptProduct = {
  __typename?: "ScriptProduct";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "script_product" */
export type ScriptProductAggregate = {
  __typename?: "ScriptProductAggregate";
  aggregate?: Maybe<ScriptProductAggregateFields>;
  nodes: Array<ScriptProduct>;
};

/** aggregate fields of "script_product" */
export type ScriptProductAggregateFields = {
  __typename?: "ScriptProductAggregateFields";
  avg?: Maybe<ScriptProductAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ScriptProductMaxFields>;
  min?: Maybe<ScriptProductMinFields>;
  stddev?: Maybe<ScriptProductStddevFields>;
  stddevPop?: Maybe<ScriptProductStddevPopFields>;
  stddevSamp?: Maybe<ScriptProductStddevSampFields>;
  sum?: Maybe<ScriptProductSumFields>;
  varPop?: Maybe<ScriptProductVarPopFields>;
  varSamp?: Maybe<ScriptProductVarSampFields>;
  variance?: Maybe<ScriptProductVarianceFields>;
};

/** aggregate fields of "script_product" */
export type ScriptProductAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScriptProductSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type ScriptProductAvgFields = {
  __typename?: "ScriptProductAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "script_product". All fields are combined with a logical 'AND'. */
export type ScriptProductBoolExp = {
  _and?: InputMaybe<Array<ScriptProductBoolExp>>;
  _not?: InputMaybe<ScriptProductBoolExp>;
  _or?: InputMaybe<Array<ScriptProductBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "script_product" */
export enum ScriptProductConstraint {
  /** unique or primary key constraint on columns "id" */
  ScriptProductPkey = "script_product_pkey",
}

/** input type for incrementing numeric columns in table "script_product" */
export type ScriptProductIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "script_product" */
export type ScriptProductInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ScriptProductMaxFields = {
  __typename?: "ScriptProductMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type ScriptProductMinFields = {
  __typename?: "ScriptProductMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "script_product" */
export type ScriptProductMutationResponse = {
  __typename?: "ScriptProductMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ScriptProduct>;
};

/** input type for inserting object relation for remote table "script_product" */
export type ScriptProductObjRelInsertInput = {
  data: ScriptProductInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ScriptProductOnConflict>;
};

/** on_conflict condition type for table "script_product" */
export type ScriptProductOnConflict = {
  constraint: ScriptProductConstraint;
  updateColumns?: Array<ScriptProductUpdateColumn>;
  where?: InputMaybe<ScriptProductBoolExp>;
};

/** Ordering options when selecting data from "script_product". */
export type ScriptProductOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: script_product */
export type ScriptProductPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "script_product" */
export enum ScriptProductSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "script_product" */
export type ScriptProductSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ScriptProductStddevFields = {
  __typename?: "ScriptProductStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type ScriptProductStddevPopFields = {
  __typename?: "ScriptProductStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type ScriptProductStddevSampFields = {
  __typename?: "ScriptProductStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "script_product" */
export type ScriptProductStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ScriptProductStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScriptProductStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ScriptProductSumFields = {
  __typename?: "ScriptProductSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "script_product" */
export enum ScriptProductUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type ScriptProductUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ScriptProductIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScriptProductSetInput>;
  /** filter the rows which have to be updated */
  where: ScriptProductBoolExp;
};

/** aggregate varPop on columns */
export type ScriptProductVarPopFields = {
  __typename?: "ScriptProductVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type ScriptProductVarSampFields = {
  __typename?: "ScriptProductVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type ScriptProductVarianceFields = {
  __typename?: "ScriptProductVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Table to store script requests by providers, for clients and that will be fulfilled by a MD/GFE reviewer. */
export type ScriptRequest = {
  __typename?: "ScriptRequest";
  /** Optional details that can be added to the script request. */
  additionalDetails: Scalars["String"]["output"];
  /** An object relationship */
  client: Client;
  /** Client for which the script is being requested. */
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** If declined, the reason why the MD/GFE reviewer declined the script request. */
  declineReason: Scalars["String"]["output"];
  /** Whether the script product will be delivered to provider or client. */
  deliveryMethod: Scalars["String"]["output"];
  /** Dosage notes for returning weight loss client. */
  dosageNotes: Scalars["String"]["output"];
  /** A checkbox for the MD/GFE reviewer to confirm the script request was sent to the recipient (provider or client). */
  fulfillmentConfirmation: Scalars["Boolean"]["output"];
  /** Details that the MD/GFE reviewer adds when fulfilling the script request, like confirmation and tracking numbers. */
  fulfillmentDetails: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** MedSpa in which the script is being requested. */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss: Scalars["Int"]["output"];
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["numeric"]["output"]>;
  /** An object relationship */
  provider: UserMedspa;
  /** Provider who is requesting the script. */
  providerId: Scalars["bigint"]["output"];
  /** An object relationship */
  scriptProduct?: Maybe<ScriptProduct>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["numeric"]["output"]>;
  /** An object relationship */
  shippingAddress: Address;
  /** Address to which the script product will be shipped. */
  shippingAddressId: Scalars["bigint"]["output"];
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Current status of the script request. */
  status: Scalars["String"]["output"];
  /** An array relationship */
  statusChangeLogs: Array<ScriptRequestStatusChangeLog>;
  /** An aggregate relationship */
  statusChangeLogsAggregate: ScriptRequestStatusChangeLogAggregate;
  /** Type of script being requested. */
  type: Scalars["String"]["output"];
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** Table to store script requests by providers, for clients and that will be fulfilled by a MD/GFE reviewer. */
export type ScriptRequestStatusChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestStatusChangeLogOrderBy>>;
  where?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
};

/** Table to store script requests by providers, for clients and that will be fulfilled by a MD/GFE reviewer. */
export type ScriptRequestStatusChangeLogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestStatusChangeLogOrderBy>>;
  where?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
};

/** aggregated selection of "script_request" */
export type ScriptRequestAggregate = {
  __typename?: "ScriptRequestAggregate";
  aggregate?: Maybe<ScriptRequestAggregateFields>;
  nodes: Array<ScriptRequest>;
};

/** aggregate fields of "script_request" */
export type ScriptRequestAggregateFields = {
  __typename?: "ScriptRequestAggregateFields";
  avg?: Maybe<ScriptRequestAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ScriptRequestMaxFields>;
  min?: Maybe<ScriptRequestMinFields>;
  stddev?: Maybe<ScriptRequestStddevFields>;
  stddevPop?: Maybe<ScriptRequestStddevPopFields>;
  stddevSamp?: Maybe<ScriptRequestStddevSampFields>;
  sum?: Maybe<ScriptRequestSumFields>;
  varPop?: Maybe<ScriptRequestVarPopFields>;
  varSamp?: Maybe<ScriptRequestVarSampFields>;
  variance?: Maybe<ScriptRequestVarianceFields>;
};

/** aggregate fields of "script_request" */
export type ScriptRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScriptRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type ScriptRequestAvgFields = {
  __typename?: "ScriptRequestAvgFields";
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["Float"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["Float"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["Float"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "script_request". All fields are combined with a logical 'AND'. */
export type ScriptRequestBoolExp = {
  _and?: InputMaybe<Array<ScriptRequestBoolExp>>;
  _not?: InputMaybe<ScriptRequestBoolExp>;
  _or?: InputMaybe<Array<ScriptRequestBoolExp>>;
  additionalDetails?: InputMaybe<StringComparisonExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  declineReason?: InputMaybe<StringComparisonExp>;
  deliveryMethod?: InputMaybe<StringComparisonExp>;
  dosageNotes?: InputMaybe<StringComparisonExp>;
  fulfillmentConfirmation?: InputMaybe<BooleanComparisonExp>;
  fulfillmentDetails?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  monthOfWeightLoss?: InputMaybe<IntComparisonExp>;
  productCost?: InputMaybe<NumericComparisonExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  scriptProduct?: InputMaybe<ScriptProductBoolExp>;
  scriptProductId?: InputMaybe<BigintComparisonExp>;
  serviceCost?: InputMaybe<NumericComparisonExp>;
  shippingAddress?: InputMaybe<AddressBoolExp>;
  shippingAddressId?: InputMaybe<BigintComparisonExp>;
  shippingCost?: InputMaybe<NumericComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  statusChangeLogs?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
  statusChangeLogsAggregate?: InputMaybe<ScriptRequestStatusChangeLogAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "script_request" */
export enum ScriptRequestConstraint {
  /** unique or primary key constraint on columns "id" */
  ScriptRequestPkey = "script_request_pkey",
}

/** input type for incrementing numeric columns in table "script_request" */
export type ScriptRequestIncInput = {
  /** Client for which the script is being requested. */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: InputMaybe<Scalars["Int"]["input"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Provider who is requesting the script. */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "script_request" */
export type ScriptRequestInsertInput = {
  /** Optional details that can be added to the script request. */
  additionalDetails?: InputMaybe<Scalars["String"]["input"]>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  /** Client for which the script is being requested. */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** If declined, the reason why the MD/GFE reviewer declined the script request. */
  declineReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Whether the script product will be delivered to provider or client. */
  deliveryMethod?: InputMaybe<Scalars["String"]["input"]>;
  /** Dosage notes for returning weight loss client. */
  dosageNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** A checkbox for the MD/GFE reviewer to confirm the script request was sent to the recipient (provider or client). */
  fulfillmentConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Details that the MD/GFE reviewer adds when fulfilling the script request, like confirmation and tracking numbers. */
  fulfillmentDetails?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** MedSpa in which the script is being requested. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: InputMaybe<Scalars["Int"]["input"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: InputMaybe<Scalars["numeric"]["input"]>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Provider who is requesting the script. */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  scriptProduct?: InputMaybe<ScriptProductObjRelInsertInput>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: InputMaybe<Scalars["numeric"]["input"]>;
  shippingAddress?: InputMaybe<AddressObjRelInsertInput>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Current status of the script request. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  statusChangeLogs?: InputMaybe<ScriptRequestStatusChangeLogArrRelInsertInput>;
  /** Type of script being requested. */
  type?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ScriptRequestMaxFields = {
  __typename?: "ScriptRequestMaxFields";
  /** Optional details that can be added to the script request. */
  additionalDetails?: Maybe<Scalars["String"]["output"]>;
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** If declined, the reason why the MD/GFE reviewer declined the script request. */
  declineReason?: Maybe<Scalars["String"]["output"]>;
  /** Whether the script product will be delivered to provider or client. */
  deliveryMethod?: Maybe<Scalars["String"]["output"]>;
  /** Dosage notes for returning weight loss client. */
  dosageNotes?: Maybe<Scalars["String"]["output"]>;
  /** Details that the MD/GFE reviewer adds when fulfilling the script request, like confirmation and tracking numbers. */
  fulfillmentDetails?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Int"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["bigint"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Current status of the script request. */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Type of script being requested. */
  type?: Maybe<Scalars["String"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregate min on columns */
export type ScriptRequestMinFields = {
  __typename?: "ScriptRequestMinFields";
  /** Optional details that can be added to the script request. */
  additionalDetails?: Maybe<Scalars["String"]["output"]>;
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** If declined, the reason why the MD/GFE reviewer declined the script request. */
  declineReason?: Maybe<Scalars["String"]["output"]>;
  /** Whether the script product will be delivered to provider or client. */
  deliveryMethod?: Maybe<Scalars["String"]["output"]>;
  /** Dosage notes for returning weight loss client. */
  dosageNotes?: Maybe<Scalars["String"]["output"]>;
  /** Details that the MD/GFE reviewer adds when fulfilling the script request, like confirmation and tracking numbers. */
  fulfillmentDetails?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Int"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["bigint"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Current status of the script request. */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Type of script being requested. */
  type?: Maybe<Scalars["String"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** response of any mutation on the table "script_request" */
export type ScriptRequestMutationResponse = {
  __typename?: "ScriptRequestMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ScriptRequest>;
};

/** input type for inserting object relation for remote table "script_request" */
export type ScriptRequestObjRelInsertInput = {
  data: ScriptRequestInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ScriptRequestOnConflict>;
};

/** on_conflict condition type for table "script_request" */
export type ScriptRequestOnConflict = {
  constraint: ScriptRequestConstraint;
  updateColumns?: Array<ScriptRequestUpdateColumn>;
  where?: InputMaybe<ScriptRequestBoolExp>;
};

/** Ordering options when selecting data from "script_request". */
export type ScriptRequestOrderBy = {
  additionalDetails?: InputMaybe<OrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  declineReason?: InputMaybe<OrderBy>;
  deliveryMethod?: InputMaybe<OrderBy>;
  dosageNotes?: InputMaybe<OrderBy>;
  fulfillmentConfirmation?: InputMaybe<OrderBy>;
  fulfillmentDetails?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  monthOfWeightLoss?: InputMaybe<OrderBy>;
  productCost?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  scriptProduct?: InputMaybe<ScriptProductOrderBy>;
  scriptProductId?: InputMaybe<OrderBy>;
  serviceCost?: InputMaybe<OrderBy>;
  shippingAddress?: InputMaybe<AddressOrderBy>;
  shippingAddressId?: InputMaybe<OrderBy>;
  shippingCost?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  statusChangeLogsAggregate?: InputMaybe<ScriptRequestStatusChangeLogAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: script_request */
export type ScriptRequestPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "script_request" */
export enum ScriptRequestSelectColumn {
  /** column name */
  AdditionalDetails = "additionalDetails",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DeclineReason = "declineReason",
  /** column name */
  DeliveryMethod = "deliveryMethod",
  /** column name */
  DosageNotes = "dosageNotes",
  /** column name */
  FulfillmentConfirmation = "fulfillmentConfirmation",
  /** column name */
  FulfillmentDetails = "fulfillmentDetails",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  MonthOfWeightLoss = "monthOfWeightLoss",
  /** column name */
  ProductCost = "productCost",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  ScriptProductId = "scriptProductId",
  /** column name */
  ServiceCost = "serviceCost",
  /** column name */
  ShippingAddressId = "shippingAddressId",
  /** column name */
  ShippingCost = "shippingCost",
  /** column name */
  Status = "status",
  /** column name */
  Type = "type",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "script_request" */
export type ScriptRequestSetInput = {
  /** Optional details that can be added to the script request. */
  additionalDetails?: InputMaybe<Scalars["String"]["input"]>;
  /** Client for which the script is being requested. */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** If declined, the reason why the MD/GFE reviewer declined the script request. */
  declineReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Whether the script product will be delivered to provider or client. */
  deliveryMethod?: InputMaybe<Scalars["String"]["input"]>;
  /** Dosage notes for returning weight loss client. */
  dosageNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** A checkbox for the MD/GFE reviewer to confirm the script request was sent to the recipient (provider or client). */
  fulfillmentConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Details that the MD/GFE reviewer adds when fulfilling the script request, like confirmation and tracking numbers. */
  fulfillmentDetails?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: InputMaybe<Scalars["Int"]["input"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Provider who is requesting the script. */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Current status of the script request. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Type of script being requested. */
  type?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** Table to store logs of status changes for script requests. */
export type ScriptRequestStatusChangeLog = {
  __typename?: "ScriptRequestStatusChangeLog";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  newStatus: Scalars["String"]["output"];
  oldStatus: Scalars["String"]["output"];
  /** An object relationship */
  scriptRequest: ScriptRequest;
  scriptRequestId: Scalars["bigint"]["output"];
  /** An object relationship */
  user?: Maybe<User>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogAggregate = {
  __typename?: "ScriptRequestStatusChangeLogAggregate";
  aggregate?: Maybe<ScriptRequestStatusChangeLogAggregateFields>;
  nodes: Array<ScriptRequestStatusChangeLog>;
};

export type ScriptRequestStatusChangeLogAggregateBoolExp = {
  count?: InputMaybe<ScriptRequestStatusChangeLogAggregateBoolExpCount>;
};

/** aggregate fields of "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogAggregateFields = {
  __typename?: "ScriptRequestStatusChangeLogAggregateFields";
  avg?: Maybe<ScriptRequestStatusChangeLogAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ScriptRequestStatusChangeLogMaxFields>;
  min?: Maybe<ScriptRequestStatusChangeLogMinFields>;
  stddev?: Maybe<ScriptRequestStatusChangeLogStddevFields>;
  stddevPop?: Maybe<ScriptRequestStatusChangeLogStddevPopFields>;
  stddevSamp?: Maybe<ScriptRequestStatusChangeLogStddevSampFields>;
  sum?: Maybe<ScriptRequestStatusChangeLogSumFields>;
  varPop?: Maybe<ScriptRequestStatusChangeLogVarPopFields>;
  varSamp?: Maybe<ScriptRequestStatusChangeLogVarSampFields>;
  variance?: Maybe<ScriptRequestStatusChangeLogVarianceFields>;
};

/** aggregate fields of "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ScriptRequestStatusChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogAggregateOrderBy = {
  avg?: InputMaybe<ScriptRequestStatusChangeLogAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ScriptRequestStatusChangeLogMaxOrderBy>;
  min?: InputMaybe<ScriptRequestStatusChangeLogMinOrderBy>;
  stddev?: InputMaybe<ScriptRequestStatusChangeLogStddevOrderBy>;
  stddevPop?: InputMaybe<ScriptRequestStatusChangeLogStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ScriptRequestStatusChangeLogStddevSampOrderBy>;
  sum?: InputMaybe<ScriptRequestStatusChangeLogSumOrderBy>;
  varPop?: InputMaybe<ScriptRequestStatusChangeLogVarPopOrderBy>;
  varSamp?: InputMaybe<ScriptRequestStatusChangeLogVarSampOrderBy>;
  variance?: InputMaybe<ScriptRequestStatusChangeLogVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogArrRelInsertInput = {
  data: Array<ScriptRequestStatusChangeLogInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ScriptRequestStatusChangeLogOnConflict>;
};

/** aggregate avg on columns */
export type ScriptRequestStatusChangeLogAvgFields = {
  __typename?: "ScriptRequestStatusChangeLogAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  scriptRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "script_request_status_change_log". All fields are combined with a logical 'AND'. */
export type ScriptRequestStatusChangeLogBoolExp = {
  _and?: InputMaybe<Array<ScriptRequestStatusChangeLogBoolExp>>;
  _not?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
  _or?: InputMaybe<Array<ScriptRequestStatusChangeLogBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  newStatus?: InputMaybe<StringComparisonExp>;
  oldStatus?: InputMaybe<StringComparisonExp>;
  scriptRequest?: InputMaybe<ScriptRequestBoolExp>;
  scriptRequestId?: InputMaybe<BigintComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "script_request_status_change_log" */
export enum ScriptRequestStatusChangeLogConstraint {
  /** unique or primary key constraint on columns "id" */
  ScriptRequestStatusChangeLogPkey = "script_request_status_change_log_pkey",
}

/** input type for incrementing numeric columns in table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  scriptRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  scriptRequest?: InputMaybe<ScriptRequestObjRelInsertInput>;
  scriptRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  user?: InputMaybe<UserObjRelInsertInput>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ScriptRequestStatusChangeLogMaxFields = {
  __typename?: "ScriptRequestStatusChangeLogMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  newStatus?: Maybe<Scalars["String"]["output"]>;
  oldStatus?: Maybe<Scalars["String"]["output"]>;
  scriptRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ScriptRequestStatusChangeLogMinFields = {
  __typename?: "ScriptRequestStatusChangeLogMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  newStatus?: Maybe<Scalars["String"]["output"]>;
  oldStatus?: Maybe<Scalars["String"]["output"]>;
  scriptRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogMutationResponse = {
  __typename?: "ScriptRequestStatusChangeLogMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ScriptRequestStatusChangeLog>;
};

/** on_conflict condition type for table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogOnConflict = {
  constraint: ScriptRequestStatusChangeLogConstraint;
  updateColumns?: Array<ScriptRequestStatusChangeLogUpdateColumn>;
  where?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
};

/** Ordering options when selecting data from "script_request_status_change_log". */
export type ScriptRequestStatusChangeLogOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  scriptRequest?: InputMaybe<ScriptRequestOrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: script_request_status_change_log */
export type ScriptRequestStatusChangeLogPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "script_request_status_change_log" */
export enum ScriptRequestStatusChangeLogSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewStatus = "newStatus",
  /** column name */
  OldStatus = "oldStatus",
  /** column name */
  ScriptRequestId = "scriptRequestId",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  scriptRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ScriptRequestStatusChangeLogStddevFields = {
  __typename?: "ScriptRequestStatusChangeLogStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  scriptRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ScriptRequestStatusChangeLogStddevPopFields = {
  __typename?: "ScriptRequestStatusChangeLogStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  scriptRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ScriptRequestStatusChangeLogStddevSampFields = {
  __typename?: "ScriptRequestStatusChangeLogStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  scriptRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ScriptRequestStatusChangeLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScriptRequestStatusChangeLogStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  scriptRequestId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ScriptRequestStatusChangeLogSumFields = {
  __typename?: "ScriptRequestStatusChangeLogSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  scriptRequestId?: Maybe<Scalars["bigint"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** update columns of table "script_request_status_change_log" */
export enum ScriptRequestStatusChangeLogUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewStatus = "newStatus",
  /** column name */
  OldStatus = "oldStatus",
  /** column name */
  ScriptRequestId = "scriptRequestId",
  /** column name */
  UserId = "userId",
}

export type ScriptRequestStatusChangeLogUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ScriptRequestStatusChangeLogIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScriptRequestStatusChangeLogSetInput>;
  /** filter the rows which have to be updated */
  where: ScriptRequestStatusChangeLogBoolExp;
};

/** aggregate varPop on columns */
export type ScriptRequestStatusChangeLogVarPopFields = {
  __typename?: "ScriptRequestStatusChangeLogVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  scriptRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ScriptRequestStatusChangeLogVarSampFields = {
  __typename?: "ScriptRequestStatusChangeLogVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  scriptRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ScriptRequestStatusChangeLogVarianceFields = {
  __typename?: "ScriptRequestStatusChangeLogVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  scriptRequestId?: Maybe<Scalars["Float"]["output"]>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "script_request_status_change_log" */
export type ScriptRequestStatusChangeLogVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  scriptRequestId?: InputMaybe<OrderBy>;
  /** User (provider or MD/GFE Reviewer) that made the last status change to a script request. */
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type ScriptRequestStddevFields = {
  __typename?: "ScriptRequestStddevFields";
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["Float"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["Float"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["Float"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type ScriptRequestStddevPopFields = {
  __typename?: "ScriptRequestStddevPopFields";
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["Float"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["Float"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["Float"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type ScriptRequestStddevSampFields = {
  __typename?: "ScriptRequestStddevSampFields";
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["Float"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["Float"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["Float"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "script_request" */
export type ScriptRequestStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ScriptRequestStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ScriptRequestStreamCursorValueInput = {
  /** Optional details that can be added to the script request. */
  additionalDetails?: InputMaybe<Scalars["String"]["input"]>;
  /** Client for which the script is being requested. */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** If declined, the reason why the MD/GFE reviewer declined the script request. */
  declineReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Whether the script product will be delivered to provider or client. */
  deliveryMethod?: InputMaybe<Scalars["String"]["input"]>;
  /** Dosage notes for returning weight loss client. */
  dosageNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** A checkbox for the MD/GFE reviewer to confirm the script request was sent to the recipient (provider or client). */
  fulfillmentConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Details that the MD/GFE reviewer adds when fulfilling the script request, like confirmation and tracking numbers. */
  fulfillmentDetails?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: InputMaybe<Scalars["Int"]["input"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Provider who is requesting the script. */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Current status of the script request. */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Type of script being requested. */
  type?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ScriptRequestSumFields = {
  __typename?: "ScriptRequestSumFields";
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Int"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["bigint"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["numeric"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "script_request" */
export enum ScriptRequestUpdateColumn {
  /** column name */
  AdditionalDetails = "additionalDetails",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DeclineReason = "declineReason",
  /** column name */
  DeliveryMethod = "deliveryMethod",
  /** column name */
  DosageNotes = "dosageNotes",
  /** column name */
  FulfillmentConfirmation = "fulfillmentConfirmation",
  /** column name */
  FulfillmentDetails = "fulfillmentDetails",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  MonthOfWeightLoss = "monthOfWeightLoss",
  /** column name */
  ProductCost = "productCost",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  ScriptProductId = "scriptProductId",
  /** column name */
  ServiceCost = "serviceCost",
  /** column name */
  ShippingAddressId = "shippingAddressId",
  /** column name */
  ShippingCost = "shippingCost",
  /** column name */
  Status = "status",
  /** column name */
  Type = "type",
  /** column name */
  VisitId = "visitId",
}

export type ScriptRequestUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ScriptRequestIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScriptRequestSetInput>;
  /** filter the rows which have to be updated */
  where: ScriptRequestBoolExp;
};

/** aggregate varPop on columns */
export type ScriptRequestVarPopFields = {
  __typename?: "ScriptRequestVarPopFields";
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["Float"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["Float"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["Float"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type ScriptRequestVarSampFields = {
  __typename?: "ScriptRequestVarSampFields";
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["Float"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["Float"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["Float"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type ScriptRequestVarianceFields = {
  __typename?: "ScriptRequestVarianceFields";
  /** Client for which the script is being requested. */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the script is being requested. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Month of weight loss for which the script is being requested (new patients start with 0). */
  monthOfWeightLoss?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the product that the MD/GFE reviewer informs when fulfilling the script request. */
  productCost?: Maybe<Scalars["Float"]["output"]>;
  /** Provider who is requesting the script. */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Product that the MD/GFE reviewer chooses when fulfilling the script request. */
  scriptProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of the service that the MD/GFE reviewer informs when fulfilling the script request. */
  serviceCost?: Maybe<Scalars["Float"]["output"]>;
  /** Address to which the script product will be shipped. */
  shippingAddressId?: Maybe<Scalars["Float"]["output"]>;
  /** Cost of shipping the product to the provider or client that the MD/GFE reviewer informs when fulfilling the script request. */
  shippingCost?: Maybe<Scalars["Float"]["output"]>;
  /** Optional Visit during which the script is being requested. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

export type SendClientLoginLink = {
  __typename?: "SendClientLoginLink";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SendClientPortalLink = {
  __typename?: "SendClientPortalLink";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SendForms = {
  __typename?: "SendForms";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SendFormsToGfeReview = {
  __typename?: "SendFormsToGFEReview";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SendGfeReviewRequestMailToMd = {
  __typename?: "SendGfeReviewRequestMailToMD";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SendMessageDataInputType = {
  appointmentId?: InputMaybe<Scalars["String"]["input"]>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  conversationProviderId?: InputMaybe<Scalars["String"]["input"]>;
  emailCc?: InputMaybe<Scalars["String"]["input"]>;
  emailFrom?: InputMaybe<Scalars["String"]["input"]>;
  emailReplyMode?: InputMaybe<EmailReplyModeEnum>;
  emailTo?: InputMaybe<Scalars["String"]["input"]>;
  fromNumber?: InputMaybe<Scalars["String"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  replyMessageId?: InputMaybe<Scalars["String"]["input"]>;
  scheduledTime?: InputMaybe<Scalars["Int"]["input"]>;
  subject?: InputMaybe<Scalars["String"]["input"]>;
  templateId?: InputMaybe<Scalars["String"]["input"]>;
  toNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type SendMessageResultType = {
  __typename?: "SendMessageResultType";
  conversationId?: Maybe<Scalars["String"]["output"]>;
  emailMessageId?: Maybe<Scalars["String"]["output"]>;
  messageId?: Maybe<Scalars["String"]["output"]>;
  messageIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  msg?: Maybe<Scalars["String"]["output"]>;
};

export type SendNoShowFeeCommunication = {
  __typename?: "SendNoShowFeeCommunication";
  invoice?: Maybe<InvoiceType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  payment?: Maybe<PaymentType>;
  paymentIntent?: Maybe<PaymentIntentType>;
  selectedCardId?: Maybe<Scalars["String"]["output"]>;
};

export type SendReceipt = {
  __typename?: "SendReceipt";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SendVisitToReview = {
  __typename?: "SendVisitToReview";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

/** List of devices that are being used by a MedSpa for their services */
export type ServiceDevice = {
  __typename?: "ServiceDevice";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  deletedBy?: Maybe<UserMedspa>;
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** The MedSpa that uses the service device for their services */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** The official name of the device */
  name: Scalars["String"]["output"];
  /** An array relationship */
  serviceDevicesAppointments: Array<ServiceDevicesAppointments>;
  /** An aggregate relationship */
  serviceDevicesAppointmentsAggregate: ServiceDevicesAppointmentsAggregate;
  /** An array relationship */
  serviceDevicesServiceTypes: Array<ServiceDevicesServiceTypes>;
  /** An aggregate relationship */
  serviceDevicesServiceTypesAggregate: ServiceDevicesServiceTypesAggregate;
};

/** List of devices that are being used by a MedSpa for their services */
export type ServiceDeviceServiceDevicesAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesAppointmentsOrderBy>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

/** List of devices that are being used by a MedSpa for their services */
export type ServiceDeviceServiceDevicesAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesAppointmentsOrderBy>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

/** List of devices that are being used by a MedSpa for their services */
export type ServiceDeviceServiceDevicesServiceTypesArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesServiceTypesOrderBy>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

/** List of devices that are being used by a MedSpa for their services */
export type ServiceDeviceServiceDevicesServiceTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesServiceTypesOrderBy>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

/** aggregated selection of "service_device" */
export type ServiceDeviceAggregate = {
  __typename?: "ServiceDeviceAggregate";
  aggregate?: Maybe<ServiceDeviceAggregateFields>;
  nodes: Array<ServiceDevice>;
};

export type ServiceDeviceAggregateBoolExp = {
  count?: InputMaybe<ServiceDeviceAggregateBoolExpCount>;
};

/** aggregate fields of "service_device" */
export type ServiceDeviceAggregateFields = {
  __typename?: "ServiceDeviceAggregateFields";
  avg?: Maybe<ServiceDeviceAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceDeviceMaxFields>;
  min?: Maybe<ServiceDeviceMinFields>;
  stddev?: Maybe<ServiceDeviceStddevFields>;
  stddevPop?: Maybe<ServiceDeviceStddevPopFields>;
  stddevSamp?: Maybe<ServiceDeviceStddevSampFields>;
  sum?: Maybe<ServiceDeviceSumFields>;
  varPop?: Maybe<ServiceDeviceVarPopFields>;
  varSamp?: Maybe<ServiceDeviceVarSampFields>;
  variance?: Maybe<ServiceDeviceVarianceFields>;
};

/** aggregate fields of "service_device" */
export type ServiceDeviceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceDeviceSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_device" */
export type ServiceDeviceAggregateOrderBy = {
  avg?: InputMaybe<ServiceDeviceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceDeviceMaxOrderBy>;
  min?: InputMaybe<ServiceDeviceMinOrderBy>;
  stddev?: InputMaybe<ServiceDeviceStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceDeviceStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceDeviceStddevSampOrderBy>;
  sum?: InputMaybe<ServiceDeviceSumOrderBy>;
  varPop?: InputMaybe<ServiceDeviceVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceDeviceVarSampOrderBy>;
  variance?: InputMaybe<ServiceDeviceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_device" */
export type ServiceDeviceArrRelInsertInput = {
  data: Array<ServiceDeviceInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceDeviceOnConflict>;
};

/** aggregate avg on columns */
export type ServiceDeviceAvgFields = {
  __typename?: "ServiceDeviceAvgFields";
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_device" */
export type ServiceDeviceAvgOrderBy = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_device". All fields are combined with a logical 'AND'. */
export type ServiceDeviceBoolExp = {
  _and?: InputMaybe<Array<ServiceDeviceBoolExp>>;
  _not?: InputMaybe<ServiceDeviceBoolExp>;
  _or?: InputMaybe<Array<ServiceDeviceBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedBy?: InputMaybe<UserMedspaBoolExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serviceDevicesAppointments?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
  serviceDevicesAppointmentsAggregate?: InputMaybe<ServiceDevicesAppointmentsAggregateBoolExp>;
  serviceDevicesServiceTypes?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
  serviceDevicesServiceTypesAggregate?: InputMaybe<ServiceDevicesServiceTypesAggregateBoolExp>;
};

/** unique or primary key constraints on table "service_device" */
export enum ServiceDeviceConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceDevicePkey = "service_device_pkey",
}

/** input type for incrementing numeric columns in table "service_device" */
export type ServiceDeviceIncInput = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_device" */
export type ServiceDeviceInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The official name of the device */
  name?: InputMaybe<Scalars["String"]["input"]>;
  serviceDevicesAppointments?: InputMaybe<ServiceDevicesAppointmentsArrRelInsertInput>;
  serviceDevicesServiceTypes?: InputMaybe<ServiceDevicesServiceTypesArrRelInsertInput>;
};

/** aggregate max on columns */
export type ServiceDeviceMaxFields = {
  __typename?: "ServiceDeviceMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The official name of the device */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "service_device" */
export type ServiceDeviceMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** The official name of the device */
  name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceDeviceMinFields = {
  __typename?: "ServiceDeviceMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The official name of the device */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "service_device" */
export type ServiceDeviceMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** The official name of the device */
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_device" */
export type ServiceDeviceMutationResponse = {
  __typename?: "ServiceDeviceMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceDevice>;
};

/** input type for inserting object relation for remote table "service_device" */
export type ServiceDeviceObjRelInsertInput = {
  data: ServiceDeviceInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceDeviceOnConflict>;
};

/** on_conflict condition type for table "service_device" */
export type ServiceDeviceOnConflict = {
  constraint: ServiceDeviceConstraint;
  updateColumns?: Array<ServiceDeviceUpdateColumn>;
  where?: InputMaybe<ServiceDeviceBoolExp>;
};

/** Ordering options when selecting data from "service_device". */
export type ServiceDeviceOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UserMedspaOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serviceDevicesAppointmentsAggregate?: InputMaybe<ServiceDevicesAppointmentsAggregateOrderBy>;
  serviceDevicesServiceTypesAggregate?: InputMaybe<ServiceDevicesServiceTypesAggregateOrderBy>;
};

/** primary key columns input for table: service_device */
export type ServiceDevicePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_device" */
export enum ServiceDeviceSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "service_device" */
export type ServiceDeviceSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The official name of the device */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceDeviceStddevFields = {
  __typename?: "ServiceDeviceStddevFields";
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_device" */
export type ServiceDeviceStddevOrderBy = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceDeviceStddevPopFields = {
  __typename?: "ServiceDeviceStddevPopFields";
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_device" */
export type ServiceDeviceStddevPopOrderBy = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceDeviceStddevSampFields = {
  __typename?: "ServiceDeviceStddevSampFields";
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_device" */
export type ServiceDeviceStddevSampOrderBy = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_device" */
export type ServiceDeviceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceDeviceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceDeviceStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The official name of the device */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceDeviceSumFields = {
  __typename?: "ServiceDeviceSumFields";
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_device" */
export type ServiceDeviceSumOrderBy = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
};

export type ServiceDeviceType = {
  __typename?: "ServiceDeviceType";
  availableDevices: Scalars["Int"]["output"];
  serviceTypeId: Scalars["Int"]["output"];
  serviceTypeName: Scalars["String"]["output"];
};

/** update columns of table "service_device" */
export enum ServiceDeviceUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type ServiceDeviceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceDeviceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceDeviceSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceDeviceBoolExp;
};

/** aggregate varPop on columns */
export type ServiceDeviceVarPopFields = {
  __typename?: "ServiceDeviceVarPopFields";
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_device" */
export type ServiceDeviceVarPopOrderBy = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceDeviceVarSampFields = {
  __typename?: "ServiceDeviceVarSampFields";
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_device" */
export type ServiceDeviceVarSampOrderBy = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceDeviceVarianceFields = {
  __typename?: "ServiceDeviceVarianceFields";
  /** UserMedSpa who deleted the service device */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_device" */
export type ServiceDeviceVarianceOrderBy = {
  /** UserMedSpa who deleted the service device */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** The MedSpa that uses the service device for their services */
  medspaId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "service_devices_appointments" */
export type ServiceDevicesAppointments = {
  __typename?: "ServiceDevicesAppointments";
  /** An object relationship */
  appointment: SchedulingAppointment;
  appointmentId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceDevice: ServiceDevice;
  servicedeviceId: Scalars["bigint"]["output"];
};

/** aggregated selection of "service_devices_appointments" */
export type ServiceDevicesAppointmentsAggregate = {
  __typename?: "ServiceDevicesAppointmentsAggregate";
  aggregate?: Maybe<ServiceDevicesAppointmentsAggregateFields>;
  nodes: Array<ServiceDevicesAppointments>;
};

export type ServiceDevicesAppointmentsAggregateBoolExp = {
  count?: InputMaybe<ServiceDevicesAppointmentsAggregateBoolExpCount>;
};

/** aggregate fields of "service_devices_appointments" */
export type ServiceDevicesAppointmentsAggregateFields = {
  __typename?: "ServiceDevicesAppointmentsAggregateFields";
  avg?: Maybe<ServiceDevicesAppointmentsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceDevicesAppointmentsMaxFields>;
  min?: Maybe<ServiceDevicesAppointmentsMinFields>;
  stddev?: Maybe<ServiceDevicesAppointmentsStddevFields>;
  stddevPop?: Maybe<ServiceDevicesAppointmentsStddevPopFields>;
  stddevSamp?: Maybe<ServiceDevicesAppointmentsStddevSampFields>;
  sum?: Maybe<ServiceDevicesAppointmentsSumFields>;
  varPop?: Maybe<ServiceDevicesAppointmentsVarPopFields>;
  varSamp?: Maybe<ServiceDevicesAppointmentsVarSampFields>;
  variance?: Maybe<ServiceDevicesAppointmentsVarianceFields>;
};

/** aggregate fields of "service_devices_appointments" */
export type ServiceDevicesAppointmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsAggregateOrderBy = {
  avg?: InputMaybe<ServiceDevicesAppointmentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceDevicesAppointmentsMaxOrderBy>;
  min?: InputMaybe<ServiceDevicesAppointmentsMinOrderBy>;
  stddev?: InputMaybe<ServiceDevicesAppointmentsStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceDevicesAppointmentsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceDevicesAppointmentsStddevSampOrderBy>;
  sum?: InputMaybe<ServiceDevicesAppointmentsSumOrderBy>;
  varPop?: InputMaybe<ServiceDevicesAppointmentsVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceDevicesAppointmentsVarSampOrderBy>;
  variance?: InputMaybe<ServiceDevicesAppointmentsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_devices_appointments" */
export type ServiceDevicesAppointmentsArrRelInsertInput = {
  data: Array<ServiceDevicesAppointmentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceDevicesAppointmentsOnConflict>;
};

/** aggregate avg on columns */
export type ServiceDevicesAppointmentsAvgFields = {
  __typename?: "ServiceDevicesAppointmentsAvgFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsAvgOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_devices_appointments". All fields are combined with a logical 'AND'. */
export type ServiceDevicesAppointmentsBoolExp = {
  _and?: InputMaybe<Array<ServiceDevicesAppointmentsBoolExp>>;
  _not?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
  _or?: InputMaybe<Array<ServiceDevicesAppointmentsBoolExp>>;
  appointment?: InputMaybe<SchedulingAppointmentBoolExp>;
  appointmentId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  serviceDevice?: InputMaybe<ServiceDeviceBoolExp>;
  servicedeviceId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "service_devices_appointments" */
export enum ServiceDevicesAppointmentsConstraint {
  /** unique or primary key constraint on columns "servicedevice_id", "appointment_id" */
  ServiceDevicesAppointmServicedeviceIdAppoint_13f6b91aUniq = "service_devices_appointm_servicedevice_id_appoint_13f6b91a_uniq",
  /** unique or primary key constraint on columns "id" */
  ServiceDevicesAppointmentsPkey = "service_devices_appointments_pkey",
}

/** input type for incrementing numeric columns in table "service_devices_appointments" */
export type ServiceDevicesAppointmentsIncInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicedeviceId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_devices_appointments" */
export type ServiceDevicesAppointmentsInsertInput = {
  appointment?: InputMaybe<SchedulingAppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceDevice?: InputMaybe<ServiceDeviceObjRelInsertInput>;
  servicedeviceId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ServiceDevicesAppointmentsMaxFields = {
  __typename?: "ServiceDevicesAppointmentsMaxFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicedeviceId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsMaxOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceDevicesAppointmentsMinFields = {
  __typename?: "ServiceDevicesAppointmentsMinFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicedeviceId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsMinOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_devices_appointments" */
export type ServiceDevicesAppointmentsMutationResponse = {
  __typename?: "ServiceDevicesAppointmentsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceDevicesAppointments>;
};

/** on_conflict condition type for table "service_devices_appointments" */
export type ServiceDevicesAppointmentsOnConflict = {
  constraint: ServiceDevicesAppointmentsConstraint;
  updateColumns?: Array<ServiceDevicesAppointmentsUpdateColumn>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

/** Ordering options when selecting data from "service_devices_appointments". */
export type ServiceDevicesAppointmentsOrderBy = {
  appointment?: InputMaybe<SchedulingAppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  serviceDevice?: InputMaybe<ServiceDeviceOrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_devices_appointments */
export type ServiceDevicesAppointmentsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_devices_appointments" */
export enum ServiceDevicesAppointmentsSelectColumn {
  /** column name */
  AppointmentId = "appointmentId",
  /** column name */
  Id = "id",
  /** column name */
  ServicedeviceId = "servicedeviceId",
}

/** input type for updating data in table "service_devices_appointments" */
export type ServiceDevicesAppointmentsSetInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicedeviceId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceDevicesAppointmentsStddevFields = {
  __typename?: "ServiceDevicesAppointmentsStddevFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsStddevOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceDevicesAppointmentsStddevPopFields = {
  __typename?: "ServiceDevicesAppointmentsStddevPopFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsStddevPopOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceDevicesAppointmentsStddevSampFields = {
  __typename?: "ServiceDevicesAppointmentsStddevSampFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsStddevSampOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_devices_appointments" */
export type ServiceDevicesAppointmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceDevicesAppointmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceDevicesAppointmentsStreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicedeviceId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceDevicesAppointmentsSumFields = {
  __typename?: "ServiceDevicesAppointmentsSumFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicedeviceId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsSumOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_devices_appointments" */
export enum ServiceDevicesAppointmentsUpdateColumn {
  /** column name */
  AppointmentId = "appointmentId",
  /** column name */
  Id = "id",
  /** column name */
  ServicedeviceId = "servicedeviceId",
}

export type ServiceDevicesAppointmentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceDevicesAppointmentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceDevicesAppointmentsSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceDevicesAppointmentsBoolExp;
};

/** aggregate varPop on columns */
export type ServiceDevicesAppointmentsVarPopFields = {
  __typename?: "ServiceDevicesAppointmentsVarPopFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsVarPopOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceDevicesAppointmentsVarSampFields = {
  __typename?: "ServiceDevicesAppointmentsVarSampFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsVarSampOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceDevicesAppointmentsVarianceFields = {
  __typename?: "ServiceDevicesAppointmentsVarianceFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_devices_appointments" */
export type ServiceDevicesAppointmentsVarianceOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "service_devices_service_types" */
export type ServiceDevicesServiceTypes = {
  __typename?: "ServiceDevicesServiceTypes";
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceDevice: ServiceDevice;
  /** An object relationship */
  serviceType: ServiceType;
  servicedeviceId: Scalars["bigint"]["output"];
  servicetypeId: Scalars["bigint"]["output"];
};

/** aggregated selection of "service_devices_service_types" */
export type ServiceDevicesServiceTypesAggregate = {
  __typename?: "ServiceDevicesServiceTypesAggregate";
  aggregate?: Maybe<ServiceDevicesServiceTypesAggregateFields>;
  nodes: Array<ServiceDevicesServiceTypes>;
};

export type ServiceDevicesServiceTypesAggregateBoolExp = {
  count?: InputMaybe<ServiceDevicesServiceTypesAggregateBoolExpCount>;
};

/** aggregate fields of "service_devices_service_types" */
export type ServiceDevicesServiceTypesAggregateFields = {
  __typename?: "ServiceDevicesServiceTypesAggregateFields";
  avg?: Maybe<ServiceDevicesServiceTypesAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceDevicesServiceTypesMaxFields>;
  min?: Maybe<ServiceDevicesServiceTypesMinFields>;
  stddev?: Maybe<ServiceDevicesServiceTypesStddevFields>;
  stddevPop?: Maybe<ServiceDevicesServiceTypesStddevPopFields>;
  stddevSamp?: Maybe<ServiceDevicesServiceTypesStddevSampFields>;
  sum?: Maybe<ServiceDevicesServiceTypesSumFields>;
  varPop?: Maybe<ServiceDevicesServiceTypesVarPopFields>;
  varSamp?: Maybe<ServiceDevicesServiceTypesVarSampFields>;
  variance?: Maybe<ServiceDevicesServiceTypesVarianceFields>;
};

/** aggregate fields of "service_devices_service_types" */
export type ServiceDevicesServiceTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesAggregateOrderBy = {
  avg?: InputMaybe<ServiceDevicesServiceTypesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceDevicesServiceTypesMaxOrderBy>;
  min?: InputMaybe<ServiceDevicesServiceTypesMinOrderBy>;
  stddev?: InputMaybe<ServiceDevicesServiceTypesStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceDevicesServiceTypesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceDevicesServiceTypesStddevSampOrderBy>;
  sum?: InputMaybe<ServiceDevicesServiceTypesSumOrderBy>;
  varPop?: InputMaybe<ServiceDevicesServiceTypesVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceDevicesServiceTypesVarSampOrderBy>;
  variance?: InputMaybe<ServiceDevicesServiceTypesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_devices_service_types" */
export type ServiceDevicesServiceTypesArrRelInsertInput = {
  data: Array<ServiceDevicesServiceTypesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceDevicesServiceTypesOnConflict>;
};

/** aggregate avg on columns */
export type ServiceDevicesServiceTypesAvgFields = {
  __typename?: "ServiceDevicesServiceTypesAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
  servicetypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_devices_service_types". All fields are combined with a logical 'AND'. */
export type ServiceDevicesServiceTypesBoolExp = {
  _and?: InputMaybe<Array<ServiceDevicesServiceTypesBoolExp>>;
  _not?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
  _or?: InputMaybe<Array<ServiceDevicesServiceTypesBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  serviceDevice?: InputMaybe<ServiceDeviceBoolExp>;
  serviceType?: InputMaybe<ServiceTypeBoolExp>;
  servicedeviceId?: InputMaybe<BigintComparisonExp>;
  servicetypeId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "service_devices_service_types" */
export enum ServiceDevicesServiceTypesConstraint {
  /** unique or primary key constraint on columns "servicedevice_id", "servicetype_id" */
  ServiceDevicesServiceServicedeviceIdServiceDa51cd4fUniq = "service_devices_service__servicedevice_id_service_da51cd4f_uniq",
  /** unique or primary key constraint on columns "id" */
  ServiceDevicesServiceTypesPkey = "service_devices_service_types_pkey",
}

/** input type for incrementing numeric columns in table "service_devices_service_types" */
export type ServiceDevicesServiceTypesIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicedeviceId?: InputMaybe<Scalars["bigint"]["input"]>;
  servicetypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_devices_service_types" */
export type ServiceDevicesServiceTypesInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceDevice?: InputMaybe<ServiceDeviceObjRelInsertInput>;
  serviceType?: InputMaybe<ServiceTypeObjRelInsertInput>;
  servicedeviceId?: InputMaybe<Scalars["bigint"]["input"]>;
  servicetypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ServiceDevicesServiceTypesMaxFields = {
  __typename?: "ServiceDevicesServiceTypesMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicedeviceId?: Maybe<Scalars["bigint"]["output"]>;
  servicetypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceDevicesServiceTypesMinFields = {
  __typename?: "ServiceDevicesServiceTypesMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicedeviceId?: Maybe<Scalars["bigint"]["output"]>;
  servicetypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_devices_service_types" */
export type ServiceDevicesServiceTypesMutationResponse = {
  __typename?: "ServiceDevicesServiceTypesMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceDevicesServiceTypes>;
};

/** on_conflict condition type for table "service_devices_service_types" */
export type ServiceDevicesServiceTypesOnConflict = {
  constraint: ServiceDevicesServiceTypesConstraint;
  updateColumns?: Array<ServiceDevicesServiceTypesUpdateColumn>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

/** Ordering options when selecting data from "service_devices_service_types". */
export type ServiceDevicesServiceTypesOrderBy = {
  id?: InputMaybe<OrderBy>;
  serviceDevice?: InputMaybe<ServiceDeviceOrderBy>;
  serviceType?: InputMaybe<ServiceTypeOrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_devices_service_types */
export type ServiceDevicesServiceTypesPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_devices_service_types" */
export enum ServiceDevicesServiceTypesSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  ServicedeviceId = "servicedeviceId",
  /** column name */
  ServicetypeId = "servicetypeId",
}

/** input type for updating data in table "service_devices_service_types" */
export type ServiceDevicesServiceTypesSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicedeviceId?: InputMaybe<Scalars["bigint"]["input"]>;
  servicetypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceDevicesServiceTypesStddevFields = {
  __typename?: "ServiceDevicesServiceTypesStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
  servicetypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceDevicesServiceTypesStddevPopFields = {
  __typename?: "ServiceDevicesServiceTypesStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
  servicetypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceDevicesServiceTypesStddevSampFields = {
  __typename?: "ServiceDevicesServiceTypesStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
  servicetypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_devices_service_types" */
export type ServiceDevicesServiceTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceDevicesServiceTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceDevicesServiceTypesStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicedeviceId?: InputMaybe<Scalars["bigint"]["input"]>;
  servicetypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceDevicesServiceTypesSumFields = {
  __typename?: "ServiceDevicesServiceTypesSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicedeviceId?: Maybe<Scalars["bigint"]["output"]>;
  servicetypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_devices_service_types" */
export enum ServiceDevicesServiceTypesUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  ServicedeviceId = "servicedeviceId",
  /** column name */
  ServicetypeId = "servicetypeId",
}

export type ServiceDevicesServiceTypesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceDevicesServiceTypesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceDevicesServiceTypesSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceDevicesServiceTypesBoolExp;
};

/** aggregate varPop on columns */
export type ServiceDevicesServiceTypesVarPopFields = {
  __typename?: "ServiceDevicesServiceTypesVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
  servicetypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceDevicesServiceTypesVarSampFields = {
  __typename?: "ServiceDevicesServiceTypesVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
  servicetypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceDevicesServiceTypesVarianceFields = {
  __typename?: "ServiceDevicesServiceTypesVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicedeviceId?: Maybe<Scalars["Float"]["output"]>;
  servicetypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_devices_service_types" */
export type ServiceDevicesServiceTypesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicedeviceId?: InputMaybe<OrderBy>;
  servicetypeId?: InputMaybe<OrderBy>;
};

/** An enumeration. */
export enum ServiceMenuItemGfeStatus {
  Contraindicated = "CONTRAINDICATED",
  FormsIncomplete = "FORMS_INCOMPLETE",
  Indicated = "INDICATED",
  IndicatedWithExceptions = "INDICATED_WITH_EXCEPTIONS",
  NotIndicated = "NOT_INDICATED",
  NotNeeded = "NOT_NEEDED",
  PendingReviewer = "PENDING_REVIEWER",
}

/** Lines for the Service Menu Items added to a Visit or Invoice. Used mainly for attaching the forms to the Client and invoicing. */
export type ServiceMenuItemLine = {
  __typename?: "ServiceMenuItemLine";
  /** Indicates the number of times the service was performed (usually 1) */
  amount: Scalars["numeric"]["output"];
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge: Scalars["numeric"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** GFE status for a service menu item line that is being persisted once appointment is complete. It is calculated based on a client service type / service product indications (new GFE flow) and client form submissions statuses. Used mostly for record keeping purposes. */
  finalGfeStatus: Scalars["String"]["output"];
  /** While the invoice is in DRAFT state, it indicates if the service should be funded from the client's wallet */
  fundFromWallet: Scalars["Boolean"]["output"];
  /** A computed field, executes function "service_menu_item_line_gfe_status" */
  gfeStatus?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Indicates if the service menu item line price is based on used service products */
  isVariablePricing: Scalars["Boolean"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Base price of the service (in USD) */
  price: Scalars["numeric"]["output"];
  priceSummary?: Maybe<ServiceMenuItemLinePricesType>;
  /** An object relationship */
  serviceMenuItem: MedspaServiceMenuItem;
  /** Service menu item that was used to create this line */
  serviceMenuItemId: Scalars["bigint"]["output"];
  /** An array relationship */
  serviceMenuItemLineStandingOrders: Array<ServiceMenuItemLineStandingOrders>;
  /** An aggregate relationship */
  serviceMenuItemLineStandingOrdersAggregate: ServiceMenuItemLineStandingOrdersAggregate;
  /** An array relationship */
  serviceProductLines: Array<ServiceProductLine>;
  /** An aggregate relationship */
  serviceProductLinesAggregate: ServiceProductLineAggregate;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax: Scalars["numeric"]["output"];
  /** An object relationship */
  visit?: Maybe<Visit>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** Lines for the Service Menu Items added to a Visit or Invoice. Used mainly for attaching the forms to the Client and invoicing. */
export type ServiceMenuItemLineServiceMenuItemLineStandingOrdersArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
};

/** Lines for the Service Menu Items added to a Visit or Invoice. Used mainly for attaching the forms to the Client and invoicing. */
export type ServiceMenuItemLineServiceMenuItemLineStandingOrdersAggregateArgs =
  {
    distinctOn?: InputMaybe<
      Array<ServiceMenuItemLineStandingOrdersSelectColumn>
    >;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersOrderBy>>;
    where?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
  };

/** Lines for the Service Menu Items added to a Visit or Invoice. Used mainly for attaching the forms to the Client and invoicing. */
export type ServiceMenuItemLineServiceProductLinesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** Lines for the Service Menu Items added to a Visit or Invoice. Used mainly for attaching the forms to the Client and invoicing. */
export type ServiceMenuItemLineServiceProductLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** aggregated selection of "service_menu_item_line" */
export type ServiceMenuItemLineAggregate = {
  __typename?: "ServiceMenuItemLineAggregate";
  aggregate?: Maybe<ServiceMenuItemLineAggregateFields>;
  nodes: Array<ServiceMenuItemLine>;
};

export type ServiceMenuItemLineAggregateBoolExp = {
  bool_and?: InputMaybe<ServiceMenuItemLineAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ServiceMenuItemLineAggregateBoolExpBool_Or>;
  count?: InputMaybe<ServiceMenuItemLineAggregateBoolExpCount>;
};

/** aggregate fields of "service_menu_item_line" */
export type ServiceMenuItemLineAggregateFields = {
  __typename?: "ServiceMenuItemLineAggregateFields";
  avg?: Maybe<ServiceMenuItemLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceMenuItemLineMaxFields>;
  min?: Maybe<ServiceMenuItemLineMinFields>;
  stddev?: Maybe<ServiceMenuItemLineStddevFields>;
  stddevPop?: Maybe<ServiceMenuItemLineStddevPopFields>;
  stddevSamp?: Maybe<ServiceMenuItemLineStddevSampFields>;
  sum?: Maybe<ServiceMenuItemLineSumFields>;
  varPop?: Maybe<ServiceMenuItemLineVarPopFields>;
  varSamp?: Maybe<ServiceMenuItemLineVarSampFields>;
  variance?: Maybe<ServiceMenuItemLineVarianceFields>;
};

/** aggregate fields of "service_menu_item_line" */
export type ServiceMenuItemLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_menu_item_line" */
export type ServiceMenuItemLineAggregateOrderBy = {
  avg?: InputMaybe<ServiceMenuItemLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceMenuItemLineMaxOrderBy>;
  min?: InputMaybe<ServiceMenuItemLineMinOrderBy>;
  stddev?: InputMaybe<ServiceMenuItemLineStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceMenuItemLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceMenuItemLineStddevSampOrderBy>;
  sum?: InputMaybe<ServiceMenuItemLineSumOrderBy>;
  varPop?: InputMaybe<ServiceMenuItemLineVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceMenuItemLineVarSampOrderBy>;
  variance?: InputMaybe<ServiceMenuItemLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_menu_item_line" */
export type ServiceMenuItemLineArrRelInsertInput = {
  data: Array<ServiceMenuItemLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceMenuItemLineOnConflict>;
};

/** aggregate avg on columns */
export type ServiceMenuItemLineAvgFields = {
  __typename?: "ServiceMenuItemLineAvgFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineAvgOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_menu_item_line". All fields are combined with a logical 'AND'. */
export type ServiceMenuItemLineBoolExp = {
  _and?: InputMaybe<Array<ServiceMenuItemLineBoolExp>>;
  _not?: InputMaybe<ServiceMenuItemLineBoolExp>;
  _or?: InputMaybe<Array<ServiceMenuItemLineBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  amountFreeOfCharge?: InputMaybe<NumericComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  finalGfeStatus?: InputMaybe<StringComparisonExp>;
  fundFromWallet?: InputMaybe<BooleanComparisonExp>;
  gfeStatus?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  isVariablePricing?: InputMaybe<BooleanComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  serviceMenuItemId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItemLineStandingOrders?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
  serviceMenuItemLineStandingOrdersAggregate?: InputMaybe<ServiceMenuItemLineStandingOrdersAggregateBoolExp>;
  serviceProductLines?: InputMaybe<ServiceProductLineBoolExp>;
  serviceProductLinesAggregate?: InputMaybe<ServiceProductLineAggregateBoolExp>;
  tax?: InputMaybe<NumericComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "service_menu_item_line" */
export enum ServiceMenuItemLineConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceMenuItemLinePkey = "service_menu_item_line_pkey",
}

/** input type for incrementing numeric columns in table "service_menu_item_line" */
export type ServiceMenuItemLineIncInput = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_menu_item_line" */
export type ServiceMenuItemLineInsertInput = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** GFE status for a service menu item line that is being persisted once appointment is complete. It is calculated based on a client service type / service product indications (new GFE flow) and client form submissions statuses. Used mostly for record keeping purposes. */
  finalGfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** While the invoice is in DRAFT state, it indicates if the service should be funded from the client's wallet */
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service menu item line price is based on used service products */
  isVariablePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItemLineStandingOrders?: InputMaybe<ServiceMenuItemLineStandingOrdersArrRelInsertInput>;
  serviceProductLines?: InputMaybe<ServiceProductLineArrRelInsertInput>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ServiceMenuItemLineMaxFields = {
  __typename?: "ServiceMenuItemLineMaxFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** GFE status for a service menu item line that is being persisted once appointment is complete. It is calculated based on a client service type / service product indications (new GFE flow) and client form submissions statuses. Used mostly for record keeping purposes. */
  finalGfeStatus?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "service_menu_item_line_gfe_status" */
  gfeStatus?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineMaxOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** GFE status for a service menu item line that is being persisted once appointment is complete. It is calculated based on a client service type / service product indications (new GFE flow) and client form submissions statuses. Used mostly for record keeping purposes. */
  finalGfeStatus?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceMenuItemLineMinFields = {
  __typename?: "ServiceMenuItemLineMinFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** GFE status for a service menu item line that is being persisted once appointment is complete. It is calculated based on a client service type / service product indications (new GFE flow) and client form submissions statuses. Used mostly for record keeping purposes. */
  finalGfeStatus?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "service_menu_item_line_gfe_status" */
  gfeStatus?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineMinOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** GFE status for a service menu item line that is being persisted once appointment is complete. It is calculated based on a client service type / service product indications (new GFE flow) and client form submissions statuses. Used mostly for record keeping purposes. */
  finalGfeStatus?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_menu_item_line" */
export type ServiceMenuItemLineMutationResponse = {
  __typename?: "ServiceMenuItemLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceMenuItemLine>;
};

/** input type for inserting object relation for remote table "service_menu_item_line" */
export type ServiceMenuItemLineObjRelInsertInput = {
  data: ServiceMenuItemLineInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceMenuItemLineOnConflict>;
};

/** on_conflict condition type for table "service_menu_item_line" */
export type ServiceMenuItemLineOnConflict = {
  constraint: ServiceMenuItemLineConstraint;
  updateColumns?: Array<ServiceMenuItemLineUpdateColumn>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

/** Ordering options when selecting data from "service_menu_item_line". */
export type ServiceMenuItemLineOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  finalGfeStatus?: InputMaybe<OrderBy>;
  fundFromWallet?: InputMaybe<OrderBy>;
  gfeStatus?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  isVariablePricing?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceMenuItemLineStandingOrdersAggregate?: InputMaybe<ServiceMenuItemLineStandingOrdersAggregateOrderBy>;
  serviceProductLinesAggregate?: InputMaybe<ServiceProductLineAggregateOrderBy>;
  tax?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_menu_item_line */
export type ServiceMenuItemLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

export type ServiceMenuItemLinePricesType = {
  __typename?: "ServiceMenuItemLinePricesType";
  id: Scalars["ID"]["output"];
  priceAfterDiscount?: Maybe<Scalars["Decimal"]["output"]>;
  priceWithoutProducts?: Maybe<Scalars["Decimal"]["output"]>;
  priceWithoutProductsAndCredits?: Maybe<Scalars["Decimal"]["output"]>;
  serviceProductLinePrices?: Maybe<Array<Maybe<ServiceProductLinePricesType>>>;
  totalPrice?: Maybe<Scalars["Decimal"]["output"]>;
  totalPriceWithoutCredits?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ServiceMenuItemLinePricesWithoutWalletCreditsType = {
  __typename?: "ServiceMenuItemLinePricesWithoutWalletCreditsType";
  id: Scalars["ID"]["output"];
  priceAfterDiscount?: Maybe<Scalars["Decimal"]["output"]>;
  priceWithoutProducts?: Maybe<Scalars["Decimal"]["output"]>;
  priceWithoutProductsAndCredits?: Maybe<Scalars["Decimal"]["output"]>;
  serviceProductLinePrices?: Maybe<
    Array<Maybe<ServiceProductLinePricesWithoutWalletCreditsType>>
  >;
  totalPrice?: Maybe<Scalars["Decimal"]["output"]>;
  totalPriceWithoutCredits?: Maybe<Scalars["Decimal"]["output"]>;
};

/** select columns of table "service_menu_item_line" */
export enum ServiceMenuItemLineSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  AmountFreeOfCharge = "amountFreeOfCharge",
  /** column name */
  Created = "created",
  /** column name */
  FinalGfeStatus = "finalGfeStatus",
  /** column name */
  FundFromWallet = "fundFromWallet",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  IsVariablePricing = "isVariablePricing",
  /** column name */
  Modified = "modified",
  /** column name */
  Price = "price",
  /** column name */
  ServiceMenuItemId = "serviceMenuItemId",
  /** column name */
  Tax = "tax",
  /** column name */
  VisitId = "visitId",
}

/** select "serviceMenuItemLineAggregateBoolExpBool_andArgumentsColumns" columns of table "service_menu_item_line" */
export enum ServiceMenuItemLineSelectColumnServiceMenuItemLineAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  FundFromWallet = "fundFromWallet",
  /** column name */
  IsVariablePricing = "isVariablePricing",
}

/** select "serviceMenuItemLineAggregateBoolExpBool_orArgumentsColumns" columns of table "service_menu_item_line" */
export enum ServiceMenuItemLineSelectColumnServiceMenuItemLineAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  FundFromWallet = "fundFromWallet",
  /** column name */
  IsVariablePricing = "isVariablePricing",
}

/** input type for updating data in table "service_menu_item_line" */
export type ServiceMenuItemLineSetInput = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** GFE status for a service menu item line that is being persisted once appointment is complete. It is calculated based on a client service type / service product indications (new GFE flow) and client form submissions statuses. Used mostly for record keeping purposes. */
  finalGfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** While the invoice is in DRAFT state, it indicates if the service should be funded from the client's wallet */
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service menu item line price is based on used service products */
  isVariablePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** columns and relationships of "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrders = {
  __typename?: "ServiceMenuItemLineStandingOrders";
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceMenuItemLine: ServiceMenuItemLine;
  servicemenuitemlineId: Scalars["bigint"]["output"];
  /** An object relationship */
  standingOrder: TmpStandingOrder;
  standingorderId: Scalars["bigint"]["output"];
};

/** aggregated selection of "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersAggregate = {
  __typename?: "ServiceMenuItemLineStandingOrdersAggregate";
  aggregate?: Maybe<ServiceMenuItemLineStandingOrdersAggregateFields>;
  nodes: Array<ServiceMenuItemLineStandingOrders>;
};

export type ServiceMenuItemLineStandingOrdersAggregateBoolExp = {
  count?: InputMaybe<ServiceMenuItemLineStandingOrdersAggregateBoolExpCount>;
};

/** aggregate fields of "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersAggregateFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersAggregateFields";
  avg?: Maybe<ServiceMenuItemLineStandingOrdersAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceMenuItemLineStandingOrdersMaxFields>;
  min?: Maybe<ServiceMenuItemLineStandingOrdersMinFields>;
  stddev?: Maybe<ServiceMenuItemLineStandingOrdersStddevFields>;
  stddevPop?: Maybe<ServiceMenuItemLineStandingOrdersStddevPopFields>;
  stddevSamp?: Maybe<ServiceMenuItemLineStandingOrdersStddevSampFields>;
  sum?: Maybe<ServiceMenuItemLineStandingOrdersSumFields>;
  varPop?: Maybe<ServiceMenuItemLineStandingOrdersVarPopFields>;
  varSamp?: Maybe<ServiceMenuItemLineStandingOrdersVarSampFields>;
  variance?: Maybe<ServiceMenuItemLineStandingOrdersVarianceFields>;
};

/** aggregate fields of "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersAggregateOrderBy = {
  avg?: InputMaybe<ServiceMenuItemLineStandingOrdersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceMenuItemLineStandingOrdersMaxOrderBy>;
  min?: InputMaybe<ServiceMenuItemLineStandingOrdersMinOrderBy>;
  stddev?: InputMaybe<ServiceMenuItemLineStandingOrdersStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceMenuItemLineStandingOrdersStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceMenuItemLineStandingOrdersStddevSampOrderBy>;
  sum?: InputMaybe<ServiceMenuItemLineStandingOrdersSumOrderBy>;
  varPop?: InputMaybe<ServiceMenuItemLineStandingOrdersVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceMenuItemLineStandingOrdersVarSampOrderBy>;
  variance?: InputMaybe<ServiceMenuItemLineStandingOrdersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersArrRelInsertInput = {
  data: Array<ServiceMenuItemLineStandingOrdersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceMenuItemLineStandingOrdersOnConflict>;
};

/** aggregate avg on columns */
export type ServiceMenuItemLineStandingOrdersAvgFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["Float"]["output"]>;
  standingorderId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_menu_item_line_standing_orders". All fields are combined with a logical 'AND'. */
export type ServiceMenuItemLineStandingOrdersBoolExp = {
  _and?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersBoolExp>>;
  _not?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
  _or?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  serviceMenuItemLine?: InputMaybe<ServiceMenuItemLineBoolExp>;
  servicemenuitemlineId?: InputMaybe<BigintComparisonExp>;
  standingOrder?: InputMaybe<TmpStandingOrderBoolExp>;
  standingorderId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "service_menu_item_line_standing_orders" */
export enum ServiceMenuItemLineStandingOrdersConstraint {
  /** unique or primary key constraint on columns "standingorder_id", "servicemenuitemline_id" */
  ServiceMenuItemLineSServicemenuitemlineIdS_277cdf63Uniq = "service_menu_item_line_s_servicemenuitemline_id_s_277cdf63_uniq",
  /** unique or primary key constraint on columns "id" */
  ServiceMenuItemLineStandingOrdersPkey = "service_menu_item_line_standing_orders_pkey",
}

/** input type for incrementing numeric columns in table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicemenuitemlineId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingorderId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersInsertInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItemLine?: InputMaybe<ServiceMenuItemLineObjRelInsertInput>;
  servicemenuitemlineId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingOrder?: InputMaybe<TmpStandingOrderObjRelInsertInput>;
  standingorderId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ServiceMenuItemLineStandingOrdersMaxFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersMaxFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["bigint"]["output"]>;
  standingorderId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceMenuItemLineStandingOrdersMinFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersMinFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["bigint"]["output"]>;
  standingorderId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersMutationResponse = {
  __typename?: "ServiceMenuItemLineStandingOrdersMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceMenuItemLineStandingOrders>;
};

/** on_conflict condition type for table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersOnConflict = {
  constraint: ServiceMenuItemLineStandingOrdersConstraint;
  updateColumns?: Array<ServiceMenuItemLineStandingOrdersUpdateColumn>;
  where?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
};

/** Ordering options when selecting data from "service_menu_item_line_standing_orders". */
export type ServiceMenuItemLineStandingOrdersOrderBy = {
  id?: InputMaybe<OrderBy>;
  serviceMenuItemLine?: InputMaybe<ServiceMenuItemLineOrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingOrder?: InputMaybe<TmpStandingOrderOrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_menu_item_line_standing_orders */
export type ServiceMenuItemLineStandingOrdersPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_menu_item_line_standing_orders" */
export enum ServiceMenuItemLineStandingOrdersSelectColumn {
  /** column name */
  Id = "id",
  /** column name */
  ServicemenuitemlineId = "servicemenuitemlineId",
  /** column name */
  StandingorderId = "standingorderId",
}

/** input type for updating data in table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersSetInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicemenuitemlineId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingorderId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceMenuItemLineStandingOrdersStddevFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["Float"]["output"]>;
  standingorderId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceMenuItemLineStandingOrdersStddevPopFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["Float"]["output"]>;
  standingorderId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceMenuItemLineStandingOrdersStddevSampFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["Float"]["output"]>;
  standingorderId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceMenuItemLineStandingOrdersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceMenuItemLineStandingOrdersStreamCursorValueInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  servicemenuitemlineId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingorderId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceMenuItemLineStandingOrdersSumFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["bigint"]["output"]>;
  standingorderId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_menu_item_line_standing_orders" */
export enum ServiceMenuItemLineStandingOrdersUpdateColumn {
  /** column name */
  Id = "id",
  /** column name */
  ServicemenuitemlineId = "servicemenuitemlineId",
  /** column name */
  StandingorderId = "standingorderId",
}

export type ServiceMenuItemLineStandingOrdersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceMenuItemLineStandingOrdersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceMenuItemLineStandingOrdersSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceMenuItemLineStandingOrdersBoolExp;
};

/** aggregate varPop on columns */
export type ServiceMenuItemLineStandingOrdersVarPopFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["Float"]["output"]>;
  standingorderId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceMenuItemLineStandingOrdersVarSampFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["Float"]["output"]>;
  standingorderId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceMenuItemLineStandingOrdersVarianceFields = {
  __typename?: "ServiceMenuItemLineStandingOrdersVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  servicemenuitemlineId?: Maybe<Scalars["Float"]["output"]>;
  standingorderId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_menu_item_line_standing_orders" */
export type ServiceMenuItemLineStandingOrdersVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  servicemenuitemlineId?: InputMaybe<OrderBy>;
  standingorderId?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type ServiceMenuItemLineStddevFields = {
  __typename?: "ServiceMenuItemLineStddevFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineStddevOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceMenuItemLineStddevPopFields = {
  __typename?: "ServiceMenuItemLineStddevPopFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineStddevPopOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceMenuItemLineStddevSampFields = {
  __typename?: "ServiceMenuItemLineStddevSampFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineStddevSampOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_menu_item_line" */
export type ServiceMenuItemLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceMenuItemLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceMenuItemLineStreamCursorValueInput = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** GFE status for a service menu item line that is being persisted once appointment is complete. It is calculated based on a client service type / service product indications (new GFE flow) and client form submissions statuses. Used mostly for record keeping purposes. */
  finalGfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  /** While the invoice is in DRAFT state, it indicates if the service should be funded from the client's wallet */
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service menu item line price is based on used service products */
  isVariablePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceMenuItemLineSumFields = {
  __typename?: "ServiceMenuItemLineSumFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineSumOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_menu_item_line" */
export enum ServiceMenuItemLineUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  AmountFreeOfCharge = "amountFreeOfCharge",
  /** column name */
  Created = "created",
  /** column name */
  FinalGfeStatus = "finalGfeStatus",
  /** column name */
  FundFromWallet = "fundFromWallet",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  IsVariablePricing = "isVariablePricing",
  /** column name */
  Modified = "modified",
  /** column name */
  Price = "price",
  /** column name */
  ServiceMenuItemId = "serviceMenuItemId",
  /** column name */
  Tax = "tax",
  /** column name */
  VisitId = "visitId",
}

export type ServiceMenuItemLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceMenuItemLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceMenuItemLineSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceMenuItemLineBoolExp;
};

/** aggregate varPop on columns */
export type ServiceMenuItemLineVarPopFields = {
  __typename?: "ServiceMenuItemLineVarPopFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineVarPopOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceMenuItemLineVarSampFields = {
  __typename?: "ServiceMenuItemLineVarSampFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineVarSampOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceMenuItemLineVarianceFields = {
  __typename?: "ServiceMenuItemLineVarianceFields";
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_menu_item_line" */
export type ServiceMenuItemLineVarianceOrderBy = {
  /** Indicates the number of times the service was performed (usually 1) */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item that was used to create this line */
  serviceMenuItemId?: InputMaybe<OrderBy>;
  /** Sales tax rate for this line and its service products lines (in %) */
  tax?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** List of products available on the market required to perform a service. This table holds all information required for charting & inventory. Providers can choose what service products they offer at their MedSpa */
export type ServiceProduct = {
  __typename?: "ServiceProduct";
  /** Unit used for charting purposes. Usually the same as the unit of measurement, but not always */
  chartingUnit: Scalars["String"]["output"];
  /** Type of container used for inventory. Can be the same as the unit of measurement. */
  containerType: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** Indicates whether this product can be diluted */
  isDilutable: Scalars["Boolean"]["output"];
  /** Indicates whether this product is enabled for inventory. If this is false, there will be no inventory lots/transactions for this product */
  isInventoryEnabled: Scalars["Boolean"]["output"];
  /** Indicates whether this product can be used partially */
  isPartiallyUsable: Scalars["Boolean"]["output"];
  /** Indicates whether this product is a sample product */
  isSample: Scalars["Boolean"]["output"];
  /** An array relationship */
  medspaServiceProducts: Array<MedspaServiceProduct>;
  /** An aggregate relationship */
  medspaServiceProductsAggregate: MedspaServiceProductAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** A common name of the product (may include size, e.g. "Juvederm Volbella .5mL") */
  name: Scalars["String"]["output"];
  /** An object relationship */
  serviceProductType: ServiceProductType;
  /** Formal/legal identity of this product */
  serviceProductTypeId: Scalars["bigint"]["output"];
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["numeric"]["output"]>;
  /** An object relationship */
  supplier?: Maybe<SupplierInformation>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["bigint"]["output"]>;
  /** A unit used for charting and inventory */
  unitOfMeasurement: Scalars["String"]["output"];
};

/** List of products available on the market required to perform a service. This table holds all information required for charting & inventory. Providers can choose what service products they offer at their MedSpa */
export type ServiceProductMedspaServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

/** List of products available on the market required to perform a service. This table holds all information required for charting & inventory. Providers can choose what service products they offer at their MedSpa */
export type ServiceProductMedspaServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

/** aggregated selection of "service_product" */
export type ServiceProductAggregate = {
  __typename?: "ServiceProductAggregate";
  aggregate?: Maybe<ServiceProductAggregateFields>;
  nodes: Array<ServiceProduct>;
};

export type ServiceProductAggregateBoolExp = {
  bool_and?: InputMaybe<ServiceProductAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ServiceProductAggregateBoolExpBool_Or>;
  count?: InputMaybe<ServiceProductAggregateBoolExpCount>;
};

/** aggregate fields of "service_product" */
export type ServiceProductAggregateFields = {
  __typename?: "ServiceProductAggregateFields";
  avg?: Maybe<ServiceProductAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceProductMaxFields>;
  min?: Maybe<ServiceProductMinFields>;
  stddev?: Maybe<ServiceProductStddevFields>;
  stddevPop?: Maybe<ServiceProductStddevPopFields>;
  stddevSamp?: Maybe<ServiceProductStddevSampFields>;
  sum?: Maybe<ServiceProductSumFields>;
  varPop?: Maybe<ServiceProductVarPopFields>;
  varSamp?: Maybe<ServiceProductVarSampFields>;
  variance?: Maybe<ServiceProductVarianceFields>;
};

/** aggregate fields of "service_product" */
export type ServiceProductAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceProductSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_product" */
export type ServiceProductAggregateOrderBy = {
  avg?: InputMaybe<ServiceProductAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceProductMaxOrderBy>;
  min?: InputMaybe<ServiceProductMinOrderBy>;
  stddev?: InputMaybe<ServiceProductStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceProductStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceProductStddevSampOrderBy>;
  sum?: InputMaybe<ServiceProductSumOrderBy>;
  varPop?: InputMaybe<ServiceProductVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceProductVarSampOrderBy>;
  variance?: InputMaybe<ServiceProductVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_product" */
export type ServiceProductArrRelInsertInput = {
  data: Array<ServiceProductInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductOnConflict>;
};

/** aggregate avg on columns */
export type ServiceProductAvgFields = {
  __typename?: "ServiceProductAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["Float"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_product" */
export type ServiceProductAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_product". All fields are combined with a logical 'AND'. */
export type ServiceProductBoolExp = {
  _and?: InputMaybe<Array<ServiceProductBoolExp>>;
  _not?: InputMaybe<ServiceProductBoolExp>;
  _or?: InputMaybe<Array<ServiceProductBoolExp>>;
  chartingUnit?: InputMaybe<StringComparisonExp>;
  containerType?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isDilutable?: InputMaybe<BooleanComparisonExp>;
  isInventoryEnabled?: InputMaybe<BooleanComparisonExp>;
  isPartiallyUsable?: InputMaybe<BooleanComparisonExp>;
  isSample?: InputMaybe<BooleanComparisonExp>;
  medspaServiceProducts?: InputMaybe<MedspaServiceProductBoolExp>;
  medspaServiceProductsAggregate?: InputMaybe<MedspaServiceProductAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serviceProductType?: InputMaybe<ServiceProductTypeBoolExp>;
  serviceProductTypeId?: InputMaybe<BigintComparisonExp>;
  size?: InputMaybe<NumericComparisonExp>;
  supplier?: InputMaybe<SupplierInformationBoolExp>;
  supplierId?: InputMaybe<BigintComparisonExp>;
  unitOfMeasurement?: InputMaybe<StringComparisonExp>;
};

/** Table to store Service Product Charting Notes, representing a record of a service product usage in the context of a visit */
export type ServiceProductChartingNote = {
  __typename?: "ServiceProductChartingNote";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  deletedBy?: Maybe<UserMedspa>;
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Date on which product used in this Service Product Charting Note expires */
  expiresAt?: Maybe<Scalars["date"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An array relationship */
  inventoryTransactions: Array<InventoryTransaction>;
  /** An aggregate relationship */
  inventoryTransactionsAggregate: InventoryTransactionAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** Note added by the provider to this Service Product Charting Note */
  providerNote: Scalars["String"]["output"];
  /** An object relationship */
  serviceProductLine: ServiceProductLine;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceProductUsage: ServiceProductUsage;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId: Scalars["bigint"]["output"];
  /** An object relationship */
  sourceProductChartingNote?: Maybe<ServiceProductChartingNote>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  splitProductChartingNotes: Array<ServiceProductChartingNote>;
  /** An aggregate relationship */
  splitProductChartingNotesAggregate: ServiceProductChartingNoteAggregate;
  /** An object relationship */
  visit: Visit;
  /** Visit for which this Service Product Charting Note was created */
  visitId: Scalars["bigint"]["output"];
};

/** Table to store Service Product Charting Notes, representing a record of a service product usage in the context of a visit */
export type ServiceProductChartingNoteInventoryTransactionsArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** Table to store Service Product Charting Notes, representing a record of a service product usage in the context of a visit */
export type ServiceProductChartingNoteInventoryTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** Table to store Service Product Charting Notes, representing a record of a service product usage in the context of a visit */
export type ServiceProductChartingNoteSplitProductChartingNotesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** Table to store Service Product Charting Notes, representing a record of a service product usage in the context of a visit */
export type ServiceProductChartingNoteSplitProductChartingNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** aggregated selection of "service_product_charting_note" */
export type ServiceProductChartingNoteAggregate = {
  __typename?: "ServiceProductChartingNoteAggregate";
  aggregate?: Maybe<ServiceProductChartingNoteAggregateFields>;
  nodes: Array<ServiceProductChartingNote>;
};

export type ServiceProductChartingNoteAggregateBoolExp = {
  count?: InputMaybe<ServiceProductChartingNoteAggregateBoolExpCount>;
};

/** aggregate fields of "service_product_charting_note" */
export type ServiceProductChartingNoteAggregateFields = {
  __typename?: "ServiceProductChartingNoteAggregateFields";
  avg?: Maybe<ServiceProductChartingNoteAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceProductChartingNoteMaxFields>;
  min?: Maybe<ServiceProductChartingNoteMinFields>;
  stddev?: Maybe<ServiceProductChartingNoteStddevFields>;
  stddevPop?: Maybe<ServiceProductChartingNoteStddevPopFields>;
  stddevSamp?: Maybe<ServiceProductChartingNoteStddevSampFields>;
  sum?: Maybe<ServiceProductChartingNoteSumFields>;
  varPop?: Maybe<ServiceProductChartingNoteVarPopFields>;
  varSamp?: Maybe<ServiceProductChartingNoteVarSampFields>;
  variance?: Maybe<ServiceProductChartingNoteVarianceFields>;
};

/** aggregate fields of "service_product_charting_note" */
export type ServiceProductChartingNoteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_product_charting_note" */
export type ServiceProductChartingNoteAggregateOrderBy = {
  avg?: InputMaybe<ServiceProductChartingNoteAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceProductChartingNoteMaxOrderBy>;
  min?: InputMaybe<ServiceProductChartingNoteMinOrderBy>;
  stddev?: InputMaybe<ServiceProductChartingNoteStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceProductChartingNoteStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceProductChartingNoteStddevSampOrderBy>;
  sum?: InputMaybe<ServiceProductChartingNoteSumOrderBy>;
  varPop?: InputMaybe<ServiceProductChartingNoteVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceProductChartingNoteVarSampOrderBy>;
  variance?: InputMaybe<ServiceProductChartingNoteVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_product_charting_note" */
export type ServiceProductChartingNoteArrRelInsertInput = {
  data: Array<ServiceProductChartingNoteInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductChartingNoteOnConflict>;
};

/** aggregate avg on columns */
export type ServiceProductChartingNoteAvgFields = {
  __typename?: "ServiceProductChartingNoteAvgFields";
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteAvgOrderBy = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_product_charting_note". All fields are combined with a logical 'AND'. */
export type ServiceProductChartingNoteBoolExp = {
  _and?: InputMaybe<Array<ServiceProductChartingNoteBoolExp>>;
  _not?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  _or?: InputMaybe<Array<ServiceProductChartingNoteBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedBy?: InputMaybe<UserMedspaBoolExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  expiresAt?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  inventoryTransactions?: InputMaybe<InventoryTransactionBoolExp>;
  inventoryTransactionsAggregate?: InputMaybe<InventoryTransactionAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  providerNote?: InputMaybe<StringComparisonExp>;
  serviceProductLine?: InputMaybe<ServiceProductLineBoolExp>;
  serviceProductLineId?: InputMaybe<BigintComparisonExp>;
  serviceProductUsage?: InputMaybe<ServiceProductUsageBoolExp>;
  serviceProductUsageId?: InputMaybe<BigintComparisonExp>;
  sourceProductChartingNote?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  sourceProductChartingNoteId?: InputMaybe<BigintComparisonExp>;
  splitProductChartingNotes?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  splitProductChartingNotesAggregate?: InputMaybe<ServiceProductChartingNoteAggregateBoolExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "service_product_charting_note" */
export enum ServiceProductChartingNoteConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceProductChartingNotePkey = "service_product_charting_note_pkey",
  /** unique or primary key constraint on columns "service_product_usage_id" */
  ServiceProductChartingNoteServiceProductUsageIdKey = "service_product_charting_note_service_product_usage_id_key",
}

/** input type for incrementing numeric columns in table "service_product_charting_note" */
export type ServiceProductChartingNoteIncInput = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_product_charting_note" */
export type ServiceProductChartingNoteInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date on which product used in this Service Product Charting Note expires */
  expiresAt?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  inventoryTransactions?: InputMaybe<InventoryTransactionArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Note added by the provider to this Service Product Charting Note */
  providerNote?: InputMaybe<Scalars["String"]["input"]>;
  serviceProductLine?: InputMaybe<ServiceProductLineObjRelInsertInput>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductUsage?: InputMaybe<ServiceProductUsageObjRelInsertInput>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<Scalars["bigint"]["input"]>;
  sourceProductChartingNote?: InputMaybe<ServiceProductChartingNoteObjRelInsertInput>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<Scalars["bigint"]["input"]>;
  splitProductChartingNotes?: InputMaybe<ServiceProductChartingNoteArrRelInsertInput>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ServiceProductChartingNoteMaxFields = {
  __typename?: "ServiceProductChartingNoteMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Date on which product used in this Service Product Charting Note expires */
  expiresAt?: Maybe<Scalars["date"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Note added by the provider to this Service Product Charting Note */
  providerNote?: Maybe<Scalars["String"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  /** Date on which product used in this Service Product Charting Note expires */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Note added by the provider to this Service Product Charting Note */
  providerNote?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceProductChartingNoteMinFields = {
  __typename?: "ServiceProductChartingNoteMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Date on which product used in this Service Product Charting Note expires */
  expiresAt?: Maybe<Scalars["date"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Note added by the provider to this Service Product Charting Note */
  providerNote?: Maybe<Scalars["String"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  /** Date on which product used in this Service Product Charting Note expires */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Note added by the provider to this Service Product Charting Note */
  providerNote?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_product_charting_note" */
export type ServiceProductChartingNoteMutationResponse = {
  __typename?: "ServiceProductChartingNoteMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceProductChartingNote>;
};

/** input type for inserting object relation for remote table "service_product_charting_note" */
export type ServiceProductChartingNoteObjRelInsertInput = {
  data: ServiceProductChartingNoteInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductChartingNoteOnConflict>;
};

/** on_conflict condition type for table "service_product_charting_note" */
export type ServiceProductChartingNoteOnConflict = {
  constraint: ServiceProductChartingNoteConstraint;
  updateColumns?: Array<ServiceProductChartingNoteUpdateColumn>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** Ordering options when selecting data from "service_product_charting_note". */
export type ServiceProductChartingNoteOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UserMedspaOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inventoryTransactionsAggregate?: InputMaybe<InventoryTransactionAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerNote?: InputMaybe<OrderBy>;
  serviceProductLine?: InputMaybe<ServiceProductLineOrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  serviceProductUsage?: InputMaybe<ServiceProductUsageOrderBy>;
  serviceProductUsageId?: InputMaybe<OrderBy>;
  sourceProductChartingNote?: InputMaybe<ServiceProductChartingNoteOrderBy>;
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  splitProductChartingNotesAggregate?: InputMaybe<ServiceProductChartingNoteAggregateOrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_product_charting_note */
export type ServiceProductChartingNotePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_product_charting_note" */
export enum ServiceProductChartingNoteSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderNote = "providerNote",
  /** column name */
  ServiceProductLineId = "serviceProductLineId",
  /** column name */
  ServiceProductUsageId = "serviceProductUsageId",
  /** column name */
  SourceProductChartingNoteId = "sourceProductChartingNoteId",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "service_product_charting_note" */
export type ServiceProductChartingNoteSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date on which product used in this Service Product Charting Note expires */
  expiresAt?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Note added by the provider to this Service Product Charting Note */
  providerNote?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceProductChartingNoteStddevFields = {
  __typename?: "ServiceProductChartingNoteStddevFields";
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteStddevOrderBy = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceProductChartingNoteStddevPopFields = {
  __typename?: "ServiceProductChartingNoteStddevPopFields";
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteStddevPopOrderBy = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceProductChartingNoteStddevSampFields = {
  __typename?: "ServiceProductChartingNoteStddevSampFields";
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteStddevSampOrderBy = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_product_charting_note" */
export type ServiceProductChartingNoteStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceProductChartingNoteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceProductChartingNoteStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date on which product used in this Service Product Charting Note expires */
  expiresAt?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Note added by the provider to this Service Product Charting Note */
  providerNote?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceProductChartingNoteSumFields = {
  __typename?: "ServiceProductChartingNoteSumFields";
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteSumOrderBy = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_product_charting_note" */
export enum ServiceProductChartingNoteUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderNote = "providerNote",
  /** column name */
  ServiceProductLineId = "serviceProductLineId",
  /** column name */
  ServiceProductUsageId = "serviceProductUsageId",
  /** column name */
  SourceProductChartingNoteId = "sourceProductChartingNoteId",
  /** column name */
  VisitId = "visitId",
}

export type ServiceProductChartingNoteUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceProductChartingNoteIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceProductChartingNoteSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceProductChartingNoteBoolExp;
};

/** aggregate varPop on columns */
export type ServiceProductChartingNoteVarPopFields = {
  __typename?: "ServiceProductChartingNoteVarPopFields";
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteVarPopOrderBy = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceProductChartingNoteVarSampFields = {
  __typename?: "ServiceProductChartingNoteVarSampFields";
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteVarSampOrderBy = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceProductChartingNoteVarianceFields = {
  __typename?: "ServiceProductChartingNoteVarianceFields";
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_product_charting_note" */
export type ServiceProductChartingNoteVarianceOrderBy = {
  /** UserMedSpa who deleted this Service Product Charting Note */
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Service Product Line which this Service Product Charting Note was created for */
  serviceProductLineId?: InputMaybe<OrderBy>;
  /** Service Product Usage which holds details about product usage of this Service Product Charting Note */
  serviceProductUsageId?: InputMaybe<OrderBy>;
  /** Service Product Charting Note which was split into multiple ones, including this one */
  sourceProductChartingNoteId?: InputMaybe<OrderBy>;
  /** Visit for which this Service Product Charting Note was created */
  visitId?: InputMaybe<OrderBy>;
};

/** unique or primary key constraints on table "service_product" */
export enum ServiceProductConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceProductPkey = "service_product_pkey",
}

/** input type for incrementing numeric columns in table "service_product" */
export type ServiceProductIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<Scalars["numeric"]["input"]>;
  /** A supplier of this product */
  supplierId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_product" */
export type ServiceProductInsertInput = {
  /** Unit used for charting purposes. Usually the same as the unit of measurement, but not always */
  chartingUnit?: InputMaybe<Scalars["String"]["input"]>;
  /** Type of container used for inventory. Can be the same as the unit of measurement. */
  containerType?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether this product can be diluted */
  isDilutable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product is enabled for inventory. If this is false, there will be no inventory lots/transactions for this product */
  isInventoryEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product can be used partially */
  isPartiallyUsable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product is a sample product */
  isSample?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaServiceProducts?: InputMaybe<MedspaServiceProductArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A common name of the product (may include size, e.g. "Juvederm Volbella .5mL") */
  name?: InputMaybe<Scalars["String"]["input"]>;
  serviceProductType?: InputMaybe<ServiceProductTypeObjRelInsertInput>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<Scalars["numeric"]["input"]>;
  supplier?: InputMaybe<SupplierInformationObjRelInsertInput>;
  /** A supplier of this product */
  supplierId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A unit used for charting and inventory */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
};

/** Lines for the Service Products added to a Visit or Invoice under a specific Service Menu Line Item. Used mainly for invoicing, charting and inventory. */
export type ServiceProductLine = {
  __typename?: "ServiceProductLine";
  /** Amount of the service product that was used for the service. */
  amount: Scalars["numeric"]["output"];
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge: Scalars["numeric"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** An array relationship */
  discountLine: Array<DiscountLine>;
  /** An aggregate relationship */
  discountLineAggregate: DiscountLineAggregate;
  /** While the invoice is in DRAFT state, it indicates if the service product should be funded from the client's wallet */
  fundFromWallet: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** Name of the service product */
  name: Scalars["String"]["output"];
  /** Base price of the service product (in USD) */
  price: Scalars["numeric"]["output"];
  /** An object relationship */
  serviceMenuItemLine: ServiceMenuItemLine;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceProduct: MedspaServiceProduct;
  /** An array relationship */
  serviceProductChartingNotes: Array<ServiceProductChartingNote>;
  /** An aggregate relationship */
  serviceProductChartingNotesAggregate: ServiceProductChartingNoteAggregate;
  /** Service product that was used to create this line */
  serviceProductId: Scalars["bigint"]["output"];
  /** Unit of measurement for the service product. Derived from the service product. */
  unitOfMeasurement: Scalars["String"]["output"];
  /** An object relationship */
  visit?: Maybe<Visit>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** Lines for the Service Products added to a Visit or Invoice under a specific Service Menu Line Item. Used mainly for invoicing, charting and inventory. */
export type ServiceProductLineDiscountLineArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

/** Lines for the Service Products added to a Visit or Invoice under a specific Service Menu Line Item. Used mainly for invoicing, charting and inventory. */
export type ServiceProductLineDiscountLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

/** Lines for the Service Products added to a Visit or Invoice under a specific Service Menu Line Item. Used mainly for invoicing, charting and inventory. */
export type ServiceProductLineServiceProductChartingNotesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** Lines for the Service Products added to a Visit or Invoice under a specific Service Menu Line Item. Used mainly for invoicing, charting and inventory. */
export type ServiceProductLineServiceProductChartingNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** aggregated selection of "service_product_line" */
export type ServiceProductLineAggregate = {
  __typename?: "ServiceProductLineAggregate";
  aggregate?: Maybe<ServiceProductLineAggregateFields>;
  nodes: Array<ServiceProductLine>;
};

export type ServiceProductLineAggregateBoolExp = {
  bool_and?: InputMaybe<ServiceProductLineAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ServiceProductLineAggregateBoolExpBool_Or>;
  count?: InputMaybe<ServiceProductLineAggregateBoolExpCount>;
};

/** aggregate fields of "service_product_line" */
export type ServiceProductLineAggregateFields = {
  __typename?: "ServiceProductLineAggregateFields";
  avg?: Maybe<ServiceProductLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceProductLineMaxFields>;
  min?: Maybe<ServiceProductLineMinFields>;
  stddev?: Maybe<ServiceProductLineStddevFields>;
  stddevPop?: Maybe<ServiceProductLineStddevPopFields>;
  stddevSamp?: Maybe<ServiceProductLineStddevSampFields>;
  sum?: Maybe<ServiceProductLineSumFields>;
  varPop?: Maybe<ServiceProductLineVarPopFields>;
  varSamp?: Maybe<ServiceProductLineVarSampFields>;
  variance?: Maybe<ServiceProductLineVarianceFields>;
};

/** aggregate fields of "service_product_line" */
export type ServiceProductLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_product_line" */
export type ServiceProductLineAggregateOrderBy = {
  avg?: InputMaybe<ServiceProductLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceProductLineMaxOrderBy>;
  min?: InputMaybe<ServiceProductLineMinOrderBy>;
  stddev?: InputMaybe<ServiceProductLineStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceProductLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceProductLineStddevSampOrderBy>;
  sum?: InputMaybe<ServiceProductLineSumOrderBy>;
  varPop?: InputMaybe<ServiceProductLineVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceProductLineVarSampOrderBy>;
  variance?: InputMaybe<ServiceProductLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_product_line" */
export type ServiceProductLineArrRelInsertInput = {
  data: Array<ServiceProductLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductLineOnConflict>;
};

/** aggregate avg on columns */
export type ServiceProductLineAvgFields = {
  __typename?: "ServiceProductLineAvgFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_product_line" */
export type ServiceProductLineAvgOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_product_line". All fields are combined with a logical 'AND'. */
export type ServiceProductLineBoolExp = {
  _and?: InputMaybe<Array<ServiceProductLineBoolExp>>;
  _not?: InputMaybe<ServiceProductLineBoolExp>;
  _or?: InputMaybe<Array<ServiceProductLineBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  amountFreeOfCharge?: InputMaybe<NumericComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  discountLine?: InputMaybe<DiscountLineBoolExp>;
  discountLineAggregate?: InputMaybe<DiscountLineAggregateBoolExp>;
  fundFromWallet?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  serviceMenuItemLine?: InputMaybe<ServiceMenuItemLineBoolExp>;
  serviceMenuItemLineId?: InputMaybe<BigintComparisonExp>;
  serviceProduct?: InputMaybe<MedspaServiceProductBoolExp>;
  serviceProductChartingNotes?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  serviceProductChartingNotesAggregate?: InputMaybe<ServiceProductChartingNoteAggregateBoolExp>;
  serviceProductId?: InputMaybe<BigintComparisonExp>;
  unitOfMeasurement?: InputMaybe<StringComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "service_product_line" */
export enum ServiceProductLineConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceProductLinePkey = "service_product_line_pkey",
}

/** input type for incrementing numeric columns in table "service_product_line" */
export type ServiceProductLineIncInput = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_product_line" */
export type ServiceProductLineInsertInput = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discountLine?: InputMaybe<DiscountLineArrRelInsertInput>;
  /** While the invoice is in DRAFT state, it indicates if the service product should be funded from the client's wallet */
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service product */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  serviceMenuItemLine?: InputMaybe<ServiceMenuItemLineObjRelInsertInput>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProduct?: InputMaybe<MedspaServiceProductObjRelInsertInput>;
  serviceProductChartingNotes?: InputMaybe<ServiceProductChartingNoteArrRelInsertInput>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unit of measurement for the service product. Derived from the service product. */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ServiceProductLineMaxFields = {
  __typename?: "ServiceProductLineMaxFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the service product */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Unit of measurement for the service product. Derived from the service product. */
  unitOfMeasurement?: Maybe<Scalars["String"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_product_line" */
export type ServiceProductLineMaxOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Name of the service product */
  name?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Unit of measurement for the service product. Derived from the service product. */
  unitOfMeasurement?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceProductLineMinFields = {
  __typename?: "ServiceProductLineMinFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the service product */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Unit of measurement for the service product. Derived from the service product. */
  unitOfMeasurement?: Maybe<Scalars["String"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_product_line" */
export type ServiceProductLineMinOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Name of the service product */
  name?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Unit of measurement for the service product. Derived from the service product. */
  unitOfMeasurement?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_product_line" */
export type ServiceProductLineMutationResponse = {
  __typename?: "ServiceProductLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceProductLine>;
};

/** input type for inserting object relation for remote table "service_product_line" */
export type ServiceProductLineObjRelInsertInput = {
  data: ServiceProductLineInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductLineOnConflict>;
};

/** on_conflict condition type for table "service_product_line" */
export type ServiceProductLineOnConflict = {
  constraint: ServiceProductLineConstraint;
  updateColumns?: Array<ServiceProductLineUpdateColumn>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** Ordering options when selecting data from "service_product_line". */
export type ServiceProductLineOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  discountLineAggregate?: InputMaybe<DiscountLineAggregateOrderBy>;
  fundFromWallet?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serviceMenuItemLine?: InputMaybe<ServiceMenuItemLineOrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProduct?: InputMaybe<MedspaServiceProductOrderBy>;
  serviceProductChartingNotesAggregate?: InputMaybe<ServiceProductChartingNoteAggregateOrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  unitOfMeasurement?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_product_line */
export type ServiceProductLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

export type ServiceProductLinePricesType = {
  __typename?: "ServiceProductLinePricesType";
  id: Scalars["ID"]["output"];
  totalPrice?: Maybe<Scalars["Decimal"]["output"]>;
  totalPriceWithoutCredits?: Maybe<Scalars["Decimal"]["output"]>;
  totalTax?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ServiceProductLinePricesWithoutWalletCreditsType = {
  __typename?: "ServiceProductLinePricesWithoutWalletCreditsType";
  id: Scalars["ID"]["output"];
  totalPrice?: Maybe<Scalars["Decimal"]["output"]>;
  totalPriceWithoutCredits?: Maybe<Scalars["Decimal"]["output"]>;
  totalTax?: Maybe<Scalars["Decimal"]["output"]>;
};

/** select columns of table "service_product_line" */
export enum ServiceProductLineSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  AmountFreeOfCharge = "amountFreeOfCharge",
  /** column name */
  Created = "created",
  /** column name */
  FundFromWallet = "fundFromWallet",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  ServiceMenuItemLineId = "serviceMenuItemLineId",
  /** column name */
  ServiceProductId = "serviceProductId",
  /** column name */
  UnitOfMeasurement = "unitOfMeasurement",
  /** column name */
  VisitId = "visitId",
}

/** select "serviceProductLineAggregateBoolExpBool_andArgumentsColumns" columns of table "service_product_line" */
export enum ServiceProductLineSelectColumnServiceProductLineAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  FundFromWallet = "fundFromWallet",
}

/** select "serviceProductLineAggregateBoolExpBool_orArgumentsColumns" columns of table "service_product_line" */
export enum ServiceProductLineSelectColumnServiceProductLineAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  FundFromWallet = "fundFromWallet",
}

/** input type for updating data in table "service_product_line" */
export type ServiceProductLineSetInput = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** While the invoice is in DRAFT state, it indicates if the service product should be funded from the client's wallet */
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service product */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unit of measurement for the service product. Derived from the service product. */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceProductLineStddevFields = {
  __typename?: "ServiceProductLineStddevFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_product_line" */
export type ServiceProductLineStddevOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceProductLineStddevPopFields = {
  __typename?: "ServiceProductLineStddevPopFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_product_line" */
export type ServiceProductLineStddevPopOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceProductLineStddevSampFields = {
  __typename?: "ServiceProductLineStddevSampFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_product_line" */
export type ServiceProductLineStddevSampOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_product_line" */
export type ServiceProductLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceProductLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceProductLineStreamCursorValueInput = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** While the invoice is in DRAFT state, it indicates if the service product should be funded from the client's wallet */
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service product */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unit of measurement for the service product. Derived from the service product. */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceProductLineSumFields = {
  __typename?: "ServiceProductLineSumFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["numeric"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_product_line" */
export type ServiceProductLineSumOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_product_line" */
export enum ServiceProductLineUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  AmountFreeOfCharge = "amountFreeOfCharge",
  /** column name */
  Created = "created",
  /** column name */
  FundFromWallet = "fundFromWallet",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  ServiceMenuItemLineId = "serviceMenuItemLineId",
  /** column name */
  ServiceProductId = "serviceProductId",
  /** column name */
  UnitOfMeasurement = "unitOfMeasurement",
  /** column name */
  VisitId = "visitId",
}

export type ServiceProductLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceProductLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceProductLineSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceProductLineBoolExp;
};

/** aggregate varPop on columns */
export type ServiceProductLineVarPopFields = {
  __typename?: "ServiceProductLineVarPopFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_product_line" */
export type ServiceProductLineVarPopOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceProductLineVarSampFields = {
  __typename?: "ServiceProductLineVarSampFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_product_line" */
export type ServiceProductLineVarSampOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceProductLineVarianceFields = {
  __typename?: "ServiceProductLineVarianceFields";
  /** Amount of the service product that was used for the service. */
  amount?: Maybe<Scalars["Float"]["output"]>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Invoice that this line is associated with */
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** Base price of the service product (in USD) */
  price?: Maybe<Scalars["Float"]["output"]>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Service product that was used to create this line */
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit that this line is associated with */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_product_line" */
export type ServiceProductLineVarianceOrderBy = {
  /** Amount of the service product that was used for the service. */
  amount?: InputMaybe<OrderBy>;
  /** After the Invoice is marked as UNPAID and related wallet transactions are created, it indicates how much of the service product was redeemed from the client's wallet. */
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Invoice that this line is associated with */
  invoiceId?: InputMaybe<OrderBy>;
  /** Base price of the service product (in USD) */
  price?: InputMaybe<OrderBy>;
  /** Service menu item line that this service product was used for and created this line. */
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  /** Service product that was used to create this line */
  serviceProductId?: InputMaybe<OrderBy>;
  /** Visit that this line is associated with */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type ServiceProductMaxFields = {
  __typename?: "ServiceProductMaxFields";
  /** Unit used for charting purposes. Usually the same as the unit of measurement, but not always */
  chartingUnit?: Maybe<Scalars["String"]["output"]>;
  /** Type of container used for inventory. Can be the same as the unit of measurement. */
  containerType?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A common name of the product (may include size, e.g. "Juvederm Volbella .5mL") */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["numeric"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["bigint"]["output"]>;
  /** A unit used for charting and inventory */
  unitOfMeasurement?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "service_product" */
export type ServiceProductMaxOrderBy = {
  /** Unit used for charting purposes. Usually the same as the unit of measurement, but not always */
  chartingUnit?: InputMaybe<OrderBy>;
  /** Type of container used for inventory. Can be the same as the unit of measurement. */
  containerType?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** A common name of the product (may include size, e.g. "Juvederm Volbella .5mL") */
  name?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
  /** A unit used for charting and inventory */
  unitOfMeasurement?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceProductMinFields = {
  __typename?: "ServiceProductMinFields";
  /** Unit used for charting purposes. Usually the same as the unit of measurement, but not always */
  chartingUnit?: Maybe<Scalars["String"]["output"]>;
  /** Type of container used for inventory. Can be the same as the unit of measurement. */
  containerType?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A common name of the product (may include size, e.g. "Juvederm Volbella .5mL") */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["numeric"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["bigint"]["output"]>;
  /** A unit used for charting and inventory */
  unitOfMeasurement?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "service_product" */
export type ServiceProductMinOrderBy = {
  /** Unit used for charting purposes. Usually the same as the unit of measurement, but not always */
  chartingUnit?: InputMaybe<OrderBy>;
  /** Type of container used for inventory. Can be the same as the unit of measurement. */
  containerType?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** A common name of the product (may include size, e.g. "Juvederm Volbella .5mL") */
  name?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
  /** A unit used for charting and inventory */
  unitOfMeasurement?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_product" */
export type ServiceProductMutationResponse = {
  __typename?: "ServiceProductMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceProduct>;
};

/** input type for inserting object relation for remote table "service_product" */
export type ServiceProductObjRelInsertInput = {
  data: ServiceProductInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductOnConflict>;
};

/** on_conflict condition type for table "service_product" */
export type ServiceProductOnConflict = {
  constraint: ServiceProductConstraint;
  updateColumns?: Array<ServiceProductUpdateColumn>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

/** Ordering options when selecting data from "service_product". */
export type ServiceProductOrderBy = {
  chartingUnit?: InputMaybe<OrderBy>;
  containerType?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDilutable?: InputMaybe<OrderBy>;
  isInventoryEnabled?: InputMaybe<OrderBy>;
  isPartiallyUsable?: InputMaybe<OrderBy>;
  isSample?: InputMaybe<OrderBy>;
  medspaServiceProductsAggregate?: InputMaybe<MedspaServiceProductAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serviceProductType?: InputMaybe<ServiceProductTypeOrderBy>;
  serviceProductTypeId?: InputMaybe<OrderBy>;
  size?: InputMaybe<OrderBy>;
  supplier?: InputMaybe<SupplierInformationOrderBy>;
  supplierId?: InputMaybe<OrderBy>;
  unitOfMeasurement?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_product */
export type ServiceProductPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_product" */
export enum ServiceProductSelectColumn {
  /** column name */
  ChartingUnit = "chartingUnit",
  /** column name */
  ContainerType = "containerType",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IsDilutable = "isDilutable",
  /** column name */
  IsInventoryEnabled = "isInventoryEnabled",
  /** column name */
  IsPartiallyUsable = "isPartiallyUsable",
  /** column name */
  IsSample = "isSample",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ServiceProductTypeId = "serviceProductTypeId",
  /** column name */
  Size = "size",
  /** column name */
  SupplierId = "supplierId",
  /** column name */
  UnitOfMeasurement = "unitOfMeasurement",
}

/** select "serviceProductAggregateBoolExpBool_andArgumentsColumns" columns of table "service_product" */
export enum ServiceProductSelectColumnServiceProductAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDilutable = "isDilutable",
  /** column name */
  IsInventoryEnabled = "isInventoryEnabled",
  /** column name */
  IsPartiallyUsable = "isPartiallyUsable",
  /** column name */
  IsSample = "isSample",
}

/** select "serviceProductAggregateBoolExpBool_orArgumentsColumns" columns of table "service_product" */
export enum ServiceProductSelectColumnServiceProductAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDilutable = "isDilutable",
  /** column name */
  IsInventoryEnabled = "isInventoryEnabled",
  /** column name */
  IsPartiallyUsable = "isPartiallyUsable",
  /** column name */
  IsSample = "isSample",
}

/** input type for updating data in table "service_product" */
export type ServiceProductSetInput = {
  /** Unit used for charting purposes. Usually the same as the unit of measurement, but not always */
  chartingUnit?: InputMaybe<Scalars["String"]["input"]>;
  /** Type of container used for inventory. Can be the same as the unit of measurement. */
  containerType?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether this product can be diluted */
  isDilutable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product is enabled for inventory. If this is false, there will be no inventory lots/transactions for this product */
  isInventoryEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product can be used partially */
  isPartiallyUsable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product is a sample product */
  isSample?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A common name of the product (may include size, e.g. "Juvederm Volbella .5mL") */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<Scalars["numeric"]["input"]>;
  /** A supplier of this product */
  supplierId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A unit used for charting and inventory */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceProductStddevFields = {
  __typename?: "ServiceProductStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["Float"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_product" */
export type ServiceProductStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceProductStddevPopFields = {
  __typename?: "ServiceProductStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["Float"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_product" */
export type ServiceProductStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceProductStddevSampFields = {
  __typename?: "ServiceProductStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["Float"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_product" */
export type ServiceProductStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_product" */
export type ServiceProductStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceProductStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceProductStreamCursorValueInput = {
  /** Unit used for charting purposes. Usually the same as the unit of measurement, but not always */
  chartingUnit?: InputMaybe<Scalars["String"]["input"]>;
  /** Type of container used for inventory. Can be the same as the unit of measurement. */
  containerType?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates whether this product can be diluted */
  isDilutable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product is enabled for inventory. If this is false, there will be no inventory lots/transactions for this product */
  isInventoryEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product can be used partially */
  isPartiallyUsable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether this product is a sample product */
  isSample?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** A common name of the product (may include size, e.g. "Juvederm Volbella .5mL") */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<Scalars["numeric"]["input"]>;
  /** A supplier of this product */
  supplierId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A unit used for charting and inventory */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceProductSumFields = {
  __typename?: "ServiceProductSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["numeric"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_product" */
export type ServiceProductSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
};

export type ServiceProductSumType = {
  __typename?: "ServiceProductSumType";
  serviceProductId: Scalars["String"]["output"];
  sum: Scalars["Decimal"]["output"];
};

/** This table is a legal identity of service products. All compliance related tables should refer to this table. */
export type ServiceProductType = {
  __typename?: "ServiceProductType";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** The formal/legal name of this product (without size etc.) */
  name: Scalars["String"]["output"];
  /** An array relationship */
  serviceProducts: Array<ServiceProduct>;
  /** An aggregate relationship */
  serviceProductsAggregate: ServiceProductAggregate;
  /** An object relationship */
  serviceType: ServiceType;
  /** A service type that this product is meant to be used for */
  serviceTypeId: Scalars["bigint"]["output"];
};

/** This table is a legal identity of service products. All compliance related tables should refer to this table. */
export type ServiceProductTypeServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductOrderBy>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

/** This table is a legal identity of service products. All compliance related tables should refer to this table. */
export type ServiceProductTypeServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductOrderBy>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

/** aggregated selection of "service_product_type" */
export type ServiceProductTypeAggregate = {
  __typename?: "ServiceProductTypeAggregate";
  aggregate?: Maybe<ServiceProductTypeAggregateFields>;
  nodes: Array<ServiceProductType>;
};

export type ServiceProductTypeAggregateBoolExp = {
  count?: InputMaybe<ServiceProductTypeAggregateBoolExpCount>;
};

/** aggregate fields of "service_product_type" */
export type ServiceProductTypeAggregateFields = {
  __typename?: "ServiceProductTypeAggregateFields";
  avg?: Maybe<ServiceProductTypeAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceProductTypeMaxFields>;
  min?: Maybe<ServiceProductTypeMinFields>;
  stddev?: Maybe<ServiceProductTypeStddevFields>;
  stddevPop?: Maybe<ServiceProductTypeStddevPopFields>;
  stddevSamp?: Maybe<ServiceProductTypeStddevSampFields>;
  sum?: Maybe<ServiceProductTypeSumFields>;
  varPop?: Maybe<ServiceProductTypeVarPopFields>;
  varSamp?: Maybe<ServiceProductTypeVarSampFields>;
  variance?: Maybe<ServiceProductTypeVarianceFields>;
};

/** aggregate fields of "service_product_type" */
export type ServiceProductTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceProductTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_product_type" */
export type ServiceProductTypeAggregateOrderBy = {
  avg?: InputMaybe<ServiceProductTypeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceProductTypeMaxOrderBy>;
  min?: InputMaybe<ServiceProductTypeMinOrderBy>;
  stddev?: InputMaybe<ServiceProductTypeStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceProductTypeStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceProductTypeStddevSampOrderBy>;
  sum?: InputMaybe<ServiceProductTypeSumOrderBy>;
  varPop?: InputMaybe<ServiceProductTypeVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceProductTypeVarSampOrderBy>;
  variance?: InputMaybe<ServiceProductTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_product_type" */
export type ServiceProductTypeArrRelInsertInput = {
  data: Array<ServiceProductTypeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductTypeOnConflict>;
};

/** aggregate avg on columns */
export type ServiceProductTypeAvgFields = {
  __typename?: "ServiceProductTypeAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_product_type" */
export type ServiceProductTypeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_product_type". All fields are combined with a logical 'AND'. */
export type ServiceProductTypeBoolExp = {
  _and?: InputMaybe<Array<ServiceProductTypeBoolExp>>;
  _not?: InputMaybe<ServiceProductTypeBoolExp>;
  _or?: InputMaybe<Array<ServiceProductTypeBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serviceProducts?: InputMaybe<ServiceProductBoolExp>;
  serviceProductsAggregate?: InputMaybe<ServiceProductAggregateBoolExp>;
  serviceType?: InputMaybe<ServiceTypeBoolExp>;
  serviceTypeId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "service_product_type" */
export enum ServiceProductTypeConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceProductTypePkey = "service_product_type_pkey",
}

/** input type for incrementing numeric columns in table "service_product_type" */
export type ServiceProductTypeIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_product_type" */
export type ServiceProductTypeInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The formal/legal name of this product (without size etc.) */
  name?: InputMaybe<Scalars["String"]["input"]>;
  serviceProducts?: InputMaybe<ServiceProductArrRelInsertInput>;
  serviceType?: InputMaybe<ServiceTypeObjRelInsertInput>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ServiceProductTypeMaxFields = {
  __typename?: "ServiceProductTypeMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The formal/legal name of this product (without size etc.) */
  name?: Maybe<Scalars["String"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_product_type" */
export type ServiceProductTypeMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** The formal/legal name of this product (without size etc.) */
  name?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceProductTypeMinFields = {
  __typename?: "ServiceProductTypeMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The formal/legal name of this product (without size etc.) */
  name?: Maybe<Scalars["String"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_product_type" */
export type ServiceProductTypeMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** The formal/legal name of this product (without size etc.) */
  name?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_product_type" */
export type ServiceProductTypeMutationResponse = {
  __typename?: "ServiceProductTypeMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceProductType>;
};

/** input type for inserting object relation for remote table "service_product_type" */
export type ServiceProductTypeObjRelInsertInput = {
  data: ServiceProductTypeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductTypeOnConflict>;
};

/** on_conflict condition type for table "service_product_type" */
export type ServiceProductTypeOnConflict = {
  constraint: ServiceProductTypeConstraint;
  updateColumns?: Array<ServiceProductTypeUpdateColumn>;
  where?: InputMaybe<ServiceProductTypeBoolExp>;
};

/** Ordering options when selecting data from "service_product_type". */
export type ServiceProductTypeOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serviceProductsAggregate?: InputMaybe<ServiceProductAggregateOrderBy>;
  serviceType?: InputMaybe<ServiceTypeOrderBy>;
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_product_type */
export type ServiceProductTypePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_product_type" */
export enum ServiceProductTypeSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ServiceTypeId = "serviceTypeId",
}

/** input type for updating data in table "service_product_type" */
export type ServiceProductTypeSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The formal/legal name of this product (without size etc.) */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceProductTypeStddevFields = {
  __typename?: "ServiceProductTypeStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_product_type" */
export type ServiceProductTypeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceProductTypeStddevPopFields = {
  __typename?: "ServiceProductTypeStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_product_type" */
export type ServiceProductTypeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceProductTypeStddevSampFields = {
  __typename?: "ServiceProductTypeStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_product_type" */
export type ServiceProductTypeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_product_type" */
export type ServiceProductTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceProductTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceProductTypeStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The formal/legal name of this product (without size etc.) */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceProductTypeSumFields = {
  __typename?: "ServiceProductTypeSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_product_type" */
export type ServiceProductTypeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_product_type" */
export enum ServiceProductTypeUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ServiceTypeId = "serviceTypeId",
}

export type ServiceProductTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceProductTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceProductTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceProductTypeBoolExp;
};

/** aggregate varPop on columns */
export type ServiceProductTypeVarPopFields = {
  __typename?: "ServiceProductTypeVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_product_type" */
export type ServiceProductTypeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceProductTypeVarSampFields = {
  __typename?: "ServiceProductTypeVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_product_type" */
export type ServiceProductTypeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceProductTypeVarianceFields = {
  __typename?: "ServiceProductTypeVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_product_type" */
export type ServiceProductTypeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** A service type that this product is meant to be used for */
  serviceTypeId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_product" */
export enum ServiceProductUpdateColumn {
  /** column name */
  ChartingUnit = "chartingUnit",
  /** column name */
  ContainerType = "containerType",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IsDilutable = "isDilutable",
  /** column name */
  IsInventoryEnabled = "isInventoryEnabled",
  /** column name */
  IsPartiallyUsable = "isPartiallyUsable",
  /** column name */
  IsSample = "isSample",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ServiceProductTypeId = "serviceProductTypeId",
  /** column name */
  Size = "size",
  /** column name */
  SupplierId = "supplierId",
  /** column name */
  UnitOfMeasurement = "unitOfMeasurement",
}

export type ServiceProductUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceProductIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceProductSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceProductBoolExp;
};

/** Table to store Service Product Usages, representing just the usage of a service product, excluding the context (like charting or keeping track of inventory levels) */
export type ServiceProductUsage = {
  __typename?: "ServiceProductUsage";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  inventoryLot?: Maybe<InventoryLot>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  inventoryTransaction?: Maybe<InventoryTransaction>;
  /** Lot number of an inventory lot from which the product was used, in case we need to denote the usage itself, but not keep track of inventory for a given product */
  lotNumber: Scalars["String"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** MedSpa in which the product was used */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  serviceProductChartingNote?: Maybe<ServiceProductChartingNote>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta: Scalars["numeric"]["output"];
  /** Unit of measurement for the stock delta */
  unitOfMeasurement: Scalars["String"]["output"];
};

/** aggregated selection of "service_product_usage" */
export type ServiceProductUsageAggregate = {
  __typename?: "ServiceProductUsageAggregate";
  aggregate?: Maybe<ServiceProductUsageAggregateFields>;
  nodes: Array<ServiceProductUsage>;
};

export type ServiceProductUsageAggregateBoolExp = {
  count?: InputMaybe<ServiceProductUsageAggregateBoolExpCount>;
};

/** aggregate fields of "service_product_usage" */
export type ServiceProductUsageAggregateFields = {
  __typename?: "ServiceProductUsageAggregateFields";
  avg?: Maybe<ServiceProductUsageAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceProductUsageMaxFields>;
  min?: Maybe<ServiceProductUsageMinFields>;
  stddev?: Maybe<ServiceProductUsageStddevFields>;
  stddevPop?: Maybe<ServiceProductUsageStddevPopFields>;
  stddevSamp?: Maybe<ServiceProductUsageStddevSampFields>;
  sum?: Maybe<ServiceProductUsageSumFields>;
  varPop?: Maybe<ServiceProductUsageVarPopFields>;
  varSamp?: Maybe<ServiceProductUsageVarSampFields>;
  variance?: Maybe<ServiceProductUsageVarianceFields>;
};

/** aggregate fields of "service_product_usage" */
export type ServiceProductUsageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_product_usage" */
export type ServiceProductUsageAggregateOrderBy = {
  avg?: InputMaybe<ServiceProductUsageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceProductUsageMaxOrderBy>;
  min?: InputMaybe<ServiceProductUsageMinOrderBy>;
  stddev?: InputMaybe<ServiceProductUsageStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceProductUsageStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceProductUsageStddevSampOrderBy>;
  sum?: InputMaybe<ServiceProductUsageSumOrderBy>;
  varPop?: InputMaybe<ServiceProductUsageVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceProductUsageVarSampOrderBy>;
  variance?: InputMaybe<ServiceProductUsageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_product_usage" */
export type ServiceProductUsageArrRelInsertInput = {
  data: Array<ServiceProductUsageInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductUsageOnConflict>;
};

/** aggregate avg on columns */
export type ServiceProductUsageAvgFields = {
  __typename?: "ServiceProductUsageAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_product_usage" */
export type ServiceProductUsageAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_product_usage". All fields are combined with a logical 'AND'. */
export type ServiceProductUsageBoolExp = {
  _and?: InputMaybe<Array<ServiceProductUsageBoolExp>>;
  _not?: InputMaybe<ServiceProductUsageBoolExp>;
  _or?: InputMaybe<Array<ServiceProductUsageBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  inventoryLot?: InputMaybe<InventoryLotBoolExp>;
  inventoryLotId?: InputMaybe<BigintComparisonExp>;
  inventoryTransaction?: InputMaybe<InventoryTransactionBoolExp>;
  lotNumber?: InputMaybe<StringComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  serviceProductChartingNote?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  stockDelta?: InputMaybe<NumericComparisonExp>;
  unitOfMeasurement?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "service_product_usage" */
export enum ServiceProductUsageConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceProductUsagePkey = "service_product_usage_pkey",
}

/** input type for incrementing numeric columns in table "service_product_usage" */
export type ServiceProductUsageIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "service_product_usage" */
export type ServiceProductUsageInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  inventoryLot?: InputMaybe<InventoryLotObjRelInsertInput>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<Scalars["bigint"]["input"]>;
  inventoryTransaction?: InputMaybe<InventoryTransactionObjRelInsertInput>;
  /** Lot number of an inventory lot from which the product was used, in case we need to denote the usage itself, but not keep track of inventory for a given product */
  lotNumber?: InputMaybe<Scalars["String"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  serviceProductChartingNote?: InputMaybe<ServiceProductChartingNoteObjRelInsertInput>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Unit of measurement for the stock delta */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ServiceProductUsageMaxFields = {
  __typename?: "ServiceProductUsageMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["bigint"]["output"]>;
  /** Lot number of an inventory lot from which the product was used, in case we need to denote the usage itself, but not keep track of inventory for a given product */
  lotNumber?: Maybe<Scalars["String"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
  /** Unit of measurement for the stock delta */
  unitOfMeasurement?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "service_product_usage" */
export type ServiceProductUsageMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** Lot number of an inventory lot from which the product was used, in case we need to denote the usage itself, but not keep track of inventory for a given product */
  lotNumber?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
  /** Unit of measurement for the stock delta */
  unitOfMeasurement?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceProductUsageMinFields = {
  __typename?: "ServiceProductUsageMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["bigint"]["output"]>;
  /** Lot number of an inventory lot from which the product was used, in case we need to denote the usage itself, but not keep track of inventory for a given product */
  lotNumber?: Maybe<Scalars["String"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
  /** Unit of measurement for the stock delta */
  unitOfMeasurement?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "service_product_usage" */
export type ServiceProductUsageMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** Lot number of an inventory lot from which the product was used, in case we need to denote the usage itself, but not keep track of inventory for a given product */
  lotNumber?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
  /** Unit of measurement for the stock delta */
  unitOfMeasurement?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_product_usage" */
export type ServiceProductUsageMutationResponse = {
  __typename?: "ServiceProductUsageMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceProductUsage>;
};

/** input type for inserting object relation for remote table "service_product_usage" */
export type ServiceProductUsageObjRelInsertInput = {
  data: ServiceProductUsageInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProductUsageOnConflict>;
};

/** on_conflict condition type for table "service_product_usage" */
export type ServiceProductUsageOnConflict = {
  constraint: ServiceProductUsageConstraint;
  updateColumns?: Array<ServiceProductUsageUpdateColumn>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

/** Ordering options when selecting data from "service_product_usage". */
export type ServiceProductUsageOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inventoryLot?: InputMaybe<InventoryLotOrderBy>;
  inventoryLotId?: InputMaybe<OrderBy>;
  inventoryTransaction?: InputMaybe<InventoryTransactionOrderBy>;
  lotNumber?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  serviceProductChartingNote?: InputMaybe<ServiceProductChartingNoteOrderBy>;
  stockDelta?: InputMaybe<OrderBy>;
  unitOfMeasurement?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_product_usage */
export type ServiceProductUsagePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_product_usage" */
export enum ServiceProductUsageSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  InventoryLotId = "inventoryLotId",
  /** column name */
  LotNumber = "lotNumber",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  StockDelta = "stockDelta",
  /** column name */
  UnitOfMeasurement = "unitOfMeasurement",
}

/** input type for updating data in table "service_product_usage" */
export type ServiceProductUsageSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Lot number of an inventory lot from which the product was used, in case we need to denote the usage itself, but not keep track of inventory for a given product */
  lotNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Unit of measurement for the stock delta */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceProductUsageStddevFields = {
  __typename?: "ServiceProductUsageStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_product_usage" */
export type ServiceProductUsageStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceProductUsageStddevPopFields = {
  __typename?: "ServiceProductUsageStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_product_usage" */
export type ServiceProductUsageStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceProductUsageStddevSampFields = {
  __typename?: "ServiceProductUsageStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_product_usage" */
export type ServiceProductUsageStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_product_usage" */
export type ServiceProductUsageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceProductUsageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceProductUsageStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Lot number of an inventory lot from which the product was used, in case we need to denote the usage itself, but not keep track of inventory for a given product */
  lotNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Unit of measurement for the stock delta */
  unitOfMeasurement?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceProductUsageSumFields = {
  __typename?: "ServiceProductUsageSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "service_product_usage" */
export type ServiceProductUsageSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
};

/** update columns of table "service_product_usage" */
export enum ServiceProductUsageUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  InventoryLotId = "inventoryLotId",
  /** column name */
  LotNumber = "lotNumber",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  StockDelta = "stockDelta",
  /** column name */
  UnitOfMeasurement = "unitOfMeasurement",
}

export type ServiceProductUsageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceProductUsageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceProductUsageSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceProductUsageBoolExp;
};

/** aggregate varPop on columns */
export type ServiceProductUsageVarPopFields = {
  __typename?: "ServiceProductUsageVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_product_usage" */
export type ServiceProductUsageVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceProductUsageVarSampFields = {
  __typename?: "ServiceProductUsageVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_product_usage" */
export type ServiceProductUsageVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceProductUsageVarianceFields = {
  __typename?: "ServiceProductUsageVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa in which the product was used */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_product_usage" */
export type ServiceProductUsageVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Inventory Lot from which the product was used, if any */
  inventoryLotId?: InputMaybe<OrderBy>;
  /** MedSpa in which the product was used */
  medspaId?: InputMaybe<OrderBy>;
  /** Amount of product used if negative, or added if positive, in the unit of measurement specified */
  stockDelta?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ServiceProductVarPopFields = {
  __typename?: "ServiceProductVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["Float"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_product" */
export type ServiceProductVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceProductVarSampFields = {
  __typename?: "ServiceProductVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["Float"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_product" */
export type ServiceProductVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceProductVarianceFields = {
  __typename?: "ServiceProductVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: Maybe<Scalars["Float"]["output"]>;
  /** A supplier of this product */
  supplierId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_product" */
export type ServiceProductVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Formal/legal identity of this product */
  serviceProductTypeId?: InputMaybe<OrderBy>;
  /** Size of the product, in the unit of measurement (e.g. 0.5) */
  size?: InputMaybe<OrderBy>;
  /** A supplier of this product */
  supplierId?: InputMaybe<OrderBy>;
};

/** Table to store Service Protocols, extending the documents_document table withspecific fields */
export type ServiceProtocol = {
  __typename?: "ServiceProtocol";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId: Scalars["String"]["output"];
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Reason for expiration of the Service Protocol */
  expirationReason: Scalars["String"]["output"];
  /** Date in which the Service Protocol expires (365 days after first signature) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Date in which the first signature was completed */
  firstSignatureAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** Whether a standing order was requested when the Service Protocol was created */
  isStandingOrderRequested: Scalars["Boolean"]["output"];
  /** An object relationship */
  medicalDirector?: Maybe<UserMedspa>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** Date in which the Medical Director signed the Service Protocol */
  medicalDirectorSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  /** MedSpa that owns the Service Protocol */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource: Scalars["String"]["output"];
  /** An object relationship */
  provider: UserMedspa;
  /** Provider assigned to the Service Protocol */
  providerId: Scalars["bigint"]["output"];
  /** Date in which the Provider signed the Service Protocol */
  providerSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  serviceType?: Maybe<ServiceType>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  standingOrders: Array<TmpStandingOrder>;
  /** An aggregate relationship */
  standingOrdersAggregate: TmpStandingOrderAggregate;
  /** Document current status */
  status: Scalars["String"]["output"];
  /** An object relationship */
  template?: Maybe<ServiceProtocolTemplate>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** Table to store Service Protocols, extending the documents_document table withspecific fields */
export type ServiceProtocolStandingOrdersArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

/** Table to store Service Protocols, extending the documents_document table withspecific fields */
export type ServiceProtocolStandingOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

/** aggregated selection of "service_protocol" */
export type ServiceProtocolAggregate = {
  __typename?: "ServiceProtocolAggregate";
  aggregate?: Maybe<ServiceProtocolAggregateFields>;
  nodes: Array<ServiceProtocol>;
};

export type ServiceProtocolAggregateBoolExp = {
  bool_and?: InputMaybe<ServiceProtocolAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ServiceProtocolAggregateBoolExpBool_Or>;
  count?: InputMaybe<ServiceProtocolAggregateBoolExpCount>;
};

/** aggregate fields of "service_protocol" */
export type ServiceProtocolAggregateFields = {
  __typename?: "ServiceProtocolAggregateFields";
  avg?: Maybe<ServiceProtocolAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceProtocolMaxFields>;
  min?: Maybe<ServiceProtocolMinFields>;
  stddev?: Maybe<ServiceProtocolStddevFields>;
  stddevPop?: Maybe<ServiceProtocolStddevPopFields>;
  stddevSamp?: Maybe<ServiceProtocolStddevSampFields>;
  sum?: Maybe<ServiceProtocolSumFields>;
  varPop?: Maybe<ServiceProtocolVarPopFields>;
  varSamp?: Maybe<ServiceProtocolVarSampFields>;
  variance?: Maybe<ServiceProtocolVarianceFields>;
};

/** aggregate fields of "service_protocol" */
export type ServiceProtocolAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_protocol" */
export type ServiceProtocolAggregateOrderBy = {
  avg?: InputMaybe<ServiceProtocolAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceProtocolMaxOrderBy>;
  min?: InputMaybe<ServiceProtocolMinOrderBy>;
  stddev?: InputMaybe<ServiceProtocolStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceProtocolStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceProtocolStddevSampOrderBy>;
  sum?: InputMaybe<ServiceProtocolSumOrderBy>;
  varPop?: InputMaybe<ServiceProtocolVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceProtocolVarSampOrderBy>;
  variance?: InputMaybe<ServiceProtocolVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_protocol" */
export type ServiceProtocolArrRelInsertInput = {
  data: Array<ServiceProtocolInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProtocolOnConflict>;
};

/** aggregate avg on columns */
export type ServiceProtocolAvgFields = {
  __typename?: "ServiceProtocolAvgFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_protocol" */
export type ServiceProtocolAvgOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_protocol". All fields are combined with a logical 'AND'. */
export type ServiceProtocolBoolExp = {
  _and?: InputMaybe<Array<ServiceProtocolBoolExp>>;
  _not?: InputMaybe<ServiceProtocolBoolExp>;
  _or?: InputMaybe<Array<ServiceProtocolBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  archivedById?: InputMaybe<BigintComparisonExp>;
  boldsignDocumentId?: InputMaybe<StringComparisonExp>;
  completedAt?: InputMaybe<TimestamptzComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  expirationReason?: InputMaybe<StringComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  firstSignatureAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isStandingOrderRequested?: InputMaybe<BooleanComparisonExp>;
  medicalDirector?: InputMaybe<UserMedspaBoolExp>;
  medicalDirectorId?: InputMaybe<BigintComparisonExp>;
  medicalDirectorSignedAt?: InputMaybe<TimestamptzComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  pdfFilePath?: InputMaybe<StringComparisonExp>;
  pdfFileSource?: InputMaybe<StringComparisonExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  providerSignedAt?: InputMaybe<TimestamptzComparisonExp>;
  serviceType?: InputMaybe<ServiceTypeBoolExp>;
  serviceTypeId?: InputMaybe<BigintComparisonExp>;
  standingOrders?: InputMaybe<TmpStandingOrderBoolExp>;
  standingOrdersAggregate?: InputMaybe<TmpStandingOrderAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
  template?: InputMaybe<ServiceProtocolTemplateBoolExp>;
  templateId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "service_protocol" */
export enum ServiceProtocolConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceProtocolPkey = "service_protocol_pkey",
}

/** input type for incrementing numeric columns in table "service_protocol" */
export type ServiceProtocolIncInput = {
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_protocol" */
export type ServiceProtocolInsertInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Reason for expiration of the Service Protocol */
  expirationReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the Service Protocol expires (365 days after first signature) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Date in which the first signature was completed */
  firstSignatureAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Whether a standing order was requested when the Service Protocol was created */
  isStandingOrderRequested?: InputMaybe<Scalars["Boolean"]["input"]>;
  medicalDirector?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Medical Director signed the Service Protocol */
  medicalDirectorSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Provider signed the Service Protocol */
  providerSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  serviceType?: InputMaybe<ServiceTypeObjRelInsertInput>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingOrders?: InputMaybe<TmpStandingOrderArrRelInsertInput>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
  template?: InputMaybe<ServiceProtocolTemplateObjRelInsertInput>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ServiceProtocolMaxFields = {
  __typename?: "ServiceProtocolMaxFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: Maybe<Scalars["String"]["output"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Reason for expiration of the Service Protocol */
  expirationReason?: Maybe<Scalars["String"]["output"]>;
  /** Date in which the Service Protocol expires (365 days after first signature) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Date in which the first signature was completed */
  firstSignatureAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** Date in which the Medical Director signed the Service Protocol */
  medicalDirectorSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: Maybe<Scalars["String"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Date in which the Provider signed the Service Protocol */
  providerSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Document current status */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_protocol" */
export type ServiceProtocolMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<OrderBy>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  /** Reason for expiration of the Service Protocol */
  expirationReason?: InputMaybe<OrderBy>;
  /** Date in which the Service Protocol expires (365 days after first signature) */
  expiresAt?: InputMaybe<OrderBy>;
  /** Date in which the first signature was completed */
  firstSignatureAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** Date in which the Medical Director signed the Service Protocol */
  medicalDirectorSignedAt?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<OrderBy>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Date in which the Provider signed the Service Protocol */
  providerSignedAt?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Document current status */
  status?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceProtocolMinFields = {
  __typename?: "ServiceProtocolMinFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: Maybe<Scalars["String"]["output"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Reason for expiration of the Service Protocol */
  expirationReason?: Maybe<Scalars["String"]["output"]>;
  /** Date in which the Service Protocol expires (365 days after first signature) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Date in which the first signature was completed */
  firstSignatureAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** Date in which the Medical Director signed the Service Protocol */
  medicalDirectorSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: Maybe<Scalars["String"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Date in which the Provider signed the Service Protocol */
  providerSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Document current status */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_protocol" */
export type ServiceProtocolMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<OrderBy>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  /** Reason for expiration of the Service Protocol */
  expirationReason?: InputMaybe<OrderBy>;
  /** Date in which the Service Protocol expires (365 days after first signature) */
  expiresAt?: InputMaybe<OrderBy>;
  /** Date in which the first signature was completed */
  firstSignatureAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** Date in which the Medical Director signed the Service Protocol */
  medicalDirectorSignedAt?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<OrderBy>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Date in which the Provider signed the Service Protocol */
  providerSignedAt?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Document current status */
  status?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_protocol" */
export type ServiceProtocolMutationResponse = {
  __typename?: "ServiceProtocolMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceProtocol>;
};

/** input type for inserting object relation for remote table "service_protocol" */
export type ServiceProtocolObjRelInsertInput = {
  data: ServiceProtocolInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProtocolOnConflict>;
};

/** on_conflict condition type for table "service_protocol" */
export type ServiceProtocolOnConflict = {
  constraint: ServiceProtocolConstraint;
  updateColumns?: Array<ServiceProtocolUpdateColumn>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

/** Ordering options when selecting data from "service_protocol". */
export type ServiceProtocolOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  boldsignDocumentId?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  expirationReason?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  firstSignatureAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isStandingOrderRequested?: InputMaybe<OrderBy>;
  medicalDirector?: InputMaybe<UserMedspaOrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medicalDirectorSignedAt?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfFilePath?: InputMaybe<OrderBy>;
  pdfFileSource?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  providerSignedAt?: InputMaybe<OrderBy>;
  serviceType?: InputMaybe<ServiceTypeOrderBy>;
  serviceTypeId?: InputMaybe<OrderBy>;
  standingOrdersAggregate?: InputMaybe<TmpStandingOrderAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  template?: InputMaybe<ServiceProtocolTemplateOrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_protocol */
export type ServiceProtocolPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_protocol" */
export enum ServiceProtocolSelectColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  BoldsignDocumentId = "boldsignDocumentId",
  /** column name */
  CompletedAt = "completedAt",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  ExpirationReason = "expirationReason",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  FirstSignatureAt = "firstSignatureAt",
  /** column name */
  Id = "id",
  /** column name */
  IsStandingOrderRequested = "isStandingOrderRequested",
  /** column name */
  MedicalDirectorId = "medicalDirectorId",
  /** column name */
  MedicalDirectorSignedAt = "medicalDirectorSignedAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfFilePath = "pdfFilePath",
  /** column name */
  PdfFileSource = "pdfFileSource",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  ProviderSignedAt = "providerSignedAt",
  /** column name */
  ServiceTypeId = "serviceTypeId",
  /** column name */
  Status = "status",
  /** column name */
  TemplateId = "templateId",
}

/** select "serviceProtocolAggregateBoolExpBool_andArgumentsColumns" columns of table "service_protocol" */
export enum ServiceProtocolSelectColumnServiceProtocolAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsStandingOrderRequested = "isStandingOrderRequested",
}

/** select "serviceProtocolAggregateBoolExpBool_orArgumentsColumns" columns of table "service_protocol" */
export enum ServiceProtocolSelectColumnServiceProtocolAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsStandingOrderRequested = "isStandingOrderRequested",
}

/** input type for updating data in table "service_protocol" */
export type ServiceProtocolSetInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Reason for expiration of the Service Protocol */
  expirationReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the Service Protocol expires (365 days after first signature) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Date in which the first signature was completed */
  firstSignatureAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Whether a standing order was requested when the Service Protocol was created */
  isStandingOrderRequested?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Medical Director signed the Service Protocol */
  medicalDirectorSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Provider signed the Service Protocol */
  providerSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceProtocolStddevFields = {
  __typename?: "ServiceProtocolStddevFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_protocol" */
export type ServiceProtocolStddevOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceProtocolStddevPopFields = {
  __typename?: "ServiceProtocolStddevPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_protocol" */
export type ServiceProtocolStddevPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceProtocolStddevSampFields = {
  __typename?: "ServiceProtocolStddevSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_protocol" */
export type ServiceProtocolStddevSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_protocol" */
export type ServiceProtocolStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceProtocolStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceProtocolStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Reason for expiration of the Service Protocol */
  expirationReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the Service Protocol expires (365 days after first signature) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Date in which the first signature was completed */
  firstSignatureAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Whether a standing order was requested when the Service Protocol was created */
  isStandingOrderRequested?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Medical Director signed the Service Protocol */
  medicalDirectorSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Provider signed the Service Protocol */
  providerSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceProtocolSumFields = {
  __typename?: "ServiceProtocolSumFields";
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_protocol" */
export type ServiceProtocolSumOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** Table to store Service Protocol Templates */
export type ServiceProtocolTemplate = {
  __typename?: "ServiceProtocolTemplate";
  /** Signing vendor template id */
  boldsignTemplateId: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the Service Protocol Template */
  description: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An array relationship */
  medspas: Array<MedspaServiceProtocolTemplate>;
  /** An aggregate relationship */
  medspasAggregate: MedspaServiceProtocolTemplateAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** Name/title of the Service Protocol Template */
  name: Scalars["String"]["output"];
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version: Scalars["Int"]["output"];
};

/** Table to store Service Protocol Templates */
export type ServiceProtocolTemplateMedspasArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
};

/** Table to store Service Protocol Templates */
export type ServiceProtocolTemplateMedspasAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
};

/** aggregated selection of "service_protocol_template" */
export type ServiceProtocolTemplateAggregate = {
  __typename?: "ServiceProtocolTemplateAggregate";
  aggregate?: Maybe<ServiceProtocolTemplateAggregateFields>;
  nodes: Array<ServiceProtocolTemplate>;
};

/** aggregate fields of "service_protocol_template" */
export type ServiceProtocolTemplateAggregateFields = {
  __typename?: "ServiceProtocolTemplateAggregateFields";
  avg?: Maybe<ServiceProtocolTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceProtocolTemplateMaxFields>;
  min?: Maybe<ServiceProtocolTemplateMinFields>;
  stddev?: Maybe<ServiceProtocolTemplateStddevFields>;
  stddevPop?: Maybe<ServiceProtocolTemplateStddevPopFields>;
  stddevSamp?: Maybe<ServiceProtocolTemplateStddevSampFields>;
  sum?: Maybe<ServiceProtocolTemplateSumFields>;
  varPop?: Maybe<ServiceProtocolTemplateVarPopFields>;
  varSamp?: Maybe<ServiceProtocolTemplateVarSampFields>;
  variance?: Maybe<ServiceProtocolTemplateVarianceFields>;
};

/** aggregate fields of "service_protocol_template" */
export type ServiceProtocolTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceProtocolTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type ServiceProtocolTemplateAvgFields = {
  __typename?: "ServiceProtocolTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "service_protocol_template". All fields are combined with a logical 'AND'. */
export type ServiceProtocolTemplateBoolExp = {
  _and?: InputMaybe<Array<ServiceProtocolTemplateBoolExp>>;
  _not?: InputMaybe<ServiceProtocolTemplateBoolExp>;
  _or?: InputMaybe<Array<ServiceProtocolTemplateBoolExp>>;
  boldsignTemplateId?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspas?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
  medspasAggregate?: InputMaybe<MedspaServiceProtocolTemplateAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serviceTypeId?: InputMaybe<BigintComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "service_protocol_template" */
export enum ServiceProtocolTemplateConstraint {
  /** unique or primary key constraint on columns "boldsign_template_id" */
  ServiceProtocolTemplateBoldsignTemplateIdKey = "service_protocol_template_boldsign_template_id_key",
  /** unique or primary key constraint on columns "id" */
  ServiceProtocolTemplatePkey = "service_protocol_template_pkey",
}

/** input type for incrementing numeric columns in table "service_protocol_template" */
export type ServiceProtocolTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Current version of the Service Protocol Template. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "service_protocol_template" */
export type ServiceProtocolTemplateInsertInput = {
  /** Signing vendor template id */
  boldsignTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the Service Protocol Template */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspas?: InputMaybe<MedspaServiceProtocolTemplateArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the Service Protocol Template */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Current version of the Service Protocol Template. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type ServiceProtocolTemplateMaxFields = {
  __typename?: "ServiceProtocolTemplateMaxFields";
  /** Signing vendor template id */
  boldsignTemplateId?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the Service Protocol Template */
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name/title of the Service Protocol Template */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate min on columns */
export type ServiceProtocolTemplateMinFields = {
  __typename?: "ServiceProtocolTemplateMinFields";
  /** Signing vendor template id */
  boldsignTemplateId?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the Service Protocol Template */
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name/title of the Service Protocol Template */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** response of any mutation on the table "service_protocol_template" */
export type ServiceProtocolTemplateMutationResponse = {
  __typename?: "ServiceProtocolTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceProtocolTemplate>;
};

/** input type for inserting object relation for remote table "service_protocol_template" */
export type ServiceProtocolTemplateObjRelInsertInput = {
  data: ServiceProtocolTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceProtocolTemplateOnConflict>;
};

/** on_conflict condition type for table "service_protocol_template" */
export type ServiceProtocolTemplateOnConflict = {
  constraint: ServiceProtocolTemplateConstraint;
  updateColumns?: Array<ServiceProtocolTemplateUpdateColumn>;
  where?: InputMaybe<ServiceProtocolTemplateBoolExp>;
};

/** Ordering options when selecting data from "service_protocol_template". */
export type ServiceProtocolTemplateOrderBy = {
  boldsignTemplateId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspasAggregate?: InputMaybe<MedspaServiceProtocolTemplateAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serviceTypeId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: service_protocol_template */
export type ServiceProtocolTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_protocol_template" */
export enum ServiceProtocolTemplateSelectColumn {
  /** column name */
  BoldsignTemplateId = "boldsignTemplateId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ServiceTypeId = "serviceTypeId",
  /** column name */
  Version = "version",
}

/** input type for updating data in table "service_protocol_template" */
export type ServiceProtocolTemplateSetInput = {
  /** Signing vendor template id */
  boldsignTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the Service Protocol Template */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the Service Protocol Template */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Current version of the Service Protocol Template. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceProtocolTemplateStddevFields = {
  __typename?: "ServiceProtocolTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type ServiceProtocolTemplateStddevPopFields = {
  __typename?: "ServiceProtocolTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type ServiceProtocolTemplateStddevSampFields = {
  __typename?: "ServiceProtocolTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "service_protocol_template" */
export type ServiceProtocolTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceProtocolTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceProtocolTemplateStreamCursorValueInput = {
  /** Signing vendor template id */
  boldsignTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the Service Protocol Template */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the Service Protocol Template */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Current version of the Service Protocol Template. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceProtocolTemplateSumFields = {
  __typename?: "ServiceProtocolTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "service_protocol_template" */
export enum ServiceProtocolTemplateUpdateColumn {
  /** column name */
  BoldsignTemplateId = "boldsignTemplateId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ServiceTypeId = "serviceTypeId",
  /** column name */
  Version = "version",
}

export type ServiceProtocolTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceProtocolTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceProtocolTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceProtocolTemplateBoolExp;
};

/** aggregate varPop on columns */
export type ServiceProtocolTemplateVarPopFields = {
  __typename?: "ServiceProtocolTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type ServiceProtocolTemplateVarSampFields = {
  __typename?: "ServiceProtocolTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type ServiceProtocolTemplateVarianceFields = {
  __typename?: "ServiceProtocolTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Service Protocol Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** update columns of table "service_protocol" */
export enum ServiceProtocolUpdateColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  BoldsignDocumentId = "boldsignDocumentId",
  /** column name */
  CompletedAt = "completedAt",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  ExpirationReason = "expirationReason",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  FirstSignatureAt = "firstSignatureAt",
  /** column name */
  Id = "id",
  /** column name */
  IsStandingOrderRequested = "isStandingOrderRequested",
  /** column name */
  MedicalDirectorId = "medicalDirectorId",
  /** column name */
  MedicalDirectorSignedAt = "medicalDirectorSignedAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfFilePath = "pdfFilePath",
  /** column name */
  PdfFileSource = "pdfFileSource",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  ProviderSignedAt = "providerSignedAt",
  /** column name */
  ServiceTypeId = "serviceTypeId",
  /** column name */
  Status = "status",
  /** column name */
  TemplateId = "templateId",
}

export type ServiceProtocolUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceProtocolIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceProtocolSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceProtocolBoolExp;
};

/** aggregate varPop on columns */
export type ServiceProtocolVarPopFields = {
  __typename?: "ServiceProtocolVarPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_protocol" */
export type ServiceProtocolVarPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceProtocolVarSampFields = {
  __typename?: "ServiceProtocolVarSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_protocol" */
export type ServiceProtocolVarSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceProtocolVarianceFields = {
  __typename?: "ServiceProtocolVarianceFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Service Protocol */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_protocol" */
export type ServiceProtocolVarianceOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Service Protocol */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Service Protocol */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Service Protocol */
  providerId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Service Protocol */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Service Protocol Template used */
  templateId?: InputMaybe<OrderBy>;
};

export type ServiceSumType = {
  __typename?: "ServiceSumType";
  serviceId: Scalars["String"]["output"];
  sum: Scalars["Decimal"]["output"];
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceType = {
  __typename?: "ServiceType";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** Indicates if the service type is a "bucket" for all old, informal services that were created in MS before introducing the formal service types structure */
  isDeprecated: Scalars["Boolean"]["output"];
  /** Indicates if this service type is considered medical from compliance point of view */
  isMedicalService: Scalars["Boolean"]["output"];
  /** Indicates if this service type is a collection of service menu items that do not belong to any other, more accurate service type */
  isOtherCollection: Scalars["Boolean"]["output"];
  /** An array relationship */
  medspaServiceOfferings: Array<MedspaServiceOffering>;
  /** An aggregate relationship */
  medspaServiceOfferingsAggregate: MedspaServiceOfferingAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** Formal name of this service type */
  name: Scalars["String"]["output"];
  /** An extra information about the service for the providers */
  noteForProviders: Scalars["String"]["output"];
  /** Qualiphy exam ID for this service type */
  qualiphyExamId: Scalars["String"]["output"];
  /** An array relationship */
  serviceDevicesServiceTypes: Array<ServiceDevicesServiceTypes>;
  /** An aggregate relationship */
  serviceDevicesServiceTypesAggregate: ServiceDevicesServiceTypesAggregate;
  /** An array relationship */
  serviceProductTypes: Array<ServiceProductType>;
  /** An aggregate relationship */
  serviceProductTypesAggregate: ServiceProductTypeAggregate;
  /** An array relationship */
  serviceProtocols: Array<ServiceProtocol>;
  /** An aggregate relationship */
  serviceProtocolsAggregate: ServiceProtocolAggregate;
  /** An object relationship */
  serviceTypeCategory: ServiceTypeCategory;
  /** Category of the service type */
  serviceTypeCategoryId: Scalars["bigint"]["output"];
  /** An array relationship */
  standingOrders: Array<TmpStandingOrder>;
  /** An aggregate relationship */
  standingOrdersAggregate: TmpStandingOrderAggregate;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeMedspaServiceOfferingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeServiceDevicesServiceTypesArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesServiceTypesOrderBy>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeServiceDevicesServiceTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesServiceTypesOrderBy>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeServiceProductTypesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductTypeOrderBy>>;
  where?: InputMaybe<ServiceProductTypeBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeServiceProductTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductTypeOrderBy>>;
  where?: InputMaybe<ServiceProductTypeBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeServiceProtocolsArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolOrderBy>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeServiceProtocolsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolOrderBy>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeStandingOrdersArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

/** A list of formal services offered on the market. Providers can choose which service types they offer at their MedSpa. */
export type ServiceTypeStandingOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

/** aggregated selection of "service_type" */
export type ServiceTypeAggregate = {
  __typename?: "ServiceTypeAggregate";
  aggregate?: Maybe<ServiceTypeAggregateFields>;
  nodes: Array<ServiceType>;
};

export type ServiceTypeAggregateBoolExp = {
  bool_and?: InputMaybe<ServiceTypeAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ServiceTypeAggregateBoolExpBool_Or>;
  count?: InputMaybe<ServiceTypeAggregateBoolExpCount>;
};

/** aggregate fields of "service_type" */
export type ServiceTypeAggregateFields = {
  __typename?: "ServiceTypeAggregateFields";
  avg?: Maybe<ServiceTypeAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceTypeMaxFields>;
  min?: Maybe<ServiceTypeMinFields>;
  stddev?: Maybe<ServiceTypeStddevFields>;
  stddevPop?: Maybe<ServiceTypeStddevPopFields>;
  stddevSamp?: Maybe<ServiceTypeStddevSampFields>;
  sum?: Maybe<ServiceTypeSumFields>;
  varPop?: Maybe<ServiceTypeVarPopFields>;
  varSamp?: Maybe<ServiceTypeVarSampFields>;
  variance?: Maybe<ServiceTypeVarianceFields>;
};

/** aggregate fields of "service_type" */
export type ServiceTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "service_type" */
export type ServiceTypeAggregateOrderBy = {
  avg?: InputMaybe<ServiceTypeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceTypeMaxOrderBy>;
  min?: InputMaybe<ServiceTypeMinOrderBy>;
  stddev?: InputMaybe<ServiceTypeStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceTypeStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceTypeStddevSampOrderBy>;
  sum?: InputMaybe<ServiceTypeSumOrderBy>;
  varPop?: InputMaybe<ServiceTypeVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceTypeVarSampOrderBy>;
  variance?: InputMaybe<ServiceTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "service_type" */
export type ServiceTypeArrRelInsertInput = {
  data: Array<ServiceTypeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceTypeOnConflict>;
};

/** aggregate avg on columns */
export type ServiceTypeAvgFields = {
  __typename?: "ServiceTypeAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "service_type" */
export type ServiceTypeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "service_type". All fields are combined with a logical 'AND'. */
export type ServiceTypeBoolExp = {
  _and?: InputMaybe<Array<ServiceTypeBoolExp>>;
  _not?: InputMaybe<ServiceTypeBoolExp>;
  _or?: InputMaybe<Array<ServiceTypeBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isDeprecated?: InputMaybe<BooleanComparisonExp>;
  isMedicalService?: InputMaybe<BooleanComparisonExp>;
  isOtherCollection?: InputMaybe<BooleanComparisonExp>;
  medspaServiceOfferings?: InputMaybe<MedspaServiceOfferingBoolExp>;
  medspaServiceOfferingsAggregate?: InputMaybe<MedspaServiceOfferingAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  noteForProviders?: InputMaybe<StringComparisonExp>;
  qualiphyExamId?: InputMaybe<StringComparisonExp>;
  serviceDevicesServiceTypes?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
  serviceDevicesServiceTypesAggregate?: InputMaybe<ServiceDevicesServiceTypesAggregateBoolExp>;
  serviceProductTypes?: InputMaybe<ServiceProductTypeBoolExp>;
  serviceProductTypesAggregate?: InputMaybe<ServiceProductTypeAggregateBoolExp>;
  serviceProtocols?: InputMaybe<ServiceProtocolBoolExp>;
  serviceProtocolsAggregate?: InputMaybe<ServiceProtocolAggregateBoolExp>;
  serviceTypeCategory?: InputMaybe<ServiceTypeCategoryBoolExp>;
  serviceTypeCategoryId?: InputMaybe<BigintComparisonExp>;
  standingOrders?: InputMaybe<TmpStandingOrderBoolExp>;
  standingOrdersAggregate?: InputMaybe<TmpStandingOrderAggregateBoolExp>;
};

/** Table to store service type categories. Mostly used for UI display purposes. */
export type ServiceTypeCategory = {
  __typename?: "ServiceTypeCategory";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Name of the service type category */
  name: Scalars["String"]["output"];
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order: Scalars["Int"]["output"];
  /** An array relationship */
  serviceTypesInformation: Array<ServiceType>;
  /** An aggregate relationship */
  serviceTypesInformationAggregate: ServiceTypeAggregate;
};

/** Table to store service type categories. Mostly used for UI display purposes. */
export type ServiceTypeCategoryServiceTypesInformationArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

/** Table to store service type categories. Mostly used for UI display purposes. */
export type ServiceTypeCategoryServiceTypesInformationAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

/** aggregated selection of "service_type_category" */
export type ServiceTypeCategoryAggregate = {
  __typename?: "ServiceTypeCategoryAggregate";
  aggregate?: Maybe<ServiceTypeCategoryAggregateFields>;
  nodes: Array<ServiceTypeCategory>;
};

/** aggregate fields of "service_type_category" */
export type ServiceTypeCategoryAggregateFields = {
  __typename?: "ServiceTypeCategoryAggregateFields";
  avg?: Maybe<ServiceTypeCategoryAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceTypeCategoryMaxFields>;
  min?: Maybe<ServiceTypeCategoryMinFields>;
  stddev?: Maybe<ServiceTypeCategoryStddevFields>;
  stddevPop?: Maybe<ServiceTypeCategoryStddevPopFields>;
  stddevSamp?: Maybe<ServiceTypeCategoryStddevSampFields>;
  sum?: Maybe<ServiceTypeCategorySumFields>;
  varPop?: Maybe<ServiceTypeCategoryVarPopFields>;
  varSamp?: Maybe<ServiceTypeCategoryVarSampFields>;
  variance?: Maybe<ServiceTypeCategoryVarianceFields>;
};

/** aggregate fields of "service_type_category" */
export type ServiceTypeCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceTypeCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type ServiceTypeCategoryAvgFields = {
  __typename?: "ServiceTypeCategoryAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "service_type_category". All fields are combined with a logical 'AND'. */
export type ServiceTypeCategoryBoolExp = {
  _and?: InputMaybe<Array<ServiceTypeCategoryBoolExp>>;
  _not?: InputMaybe<ServiceTypeCategoryBoolExp>;
  _or?: InputMaybe<Array<ServiceTypeCategoryBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  serviceTypesInformation?: InputMaybe<ServiceTypeBoolExp>;
  serviceTypesInformationAggregate?: InputMaybe<ServiceTypeAggregateBoolExp>;
};

/** unique or primary key constraints on table "service_type_category" */
export enum ServiceTypeCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceCategoryPkey = "service_category_pkey",
}

/** input type for incrementing numeric columns in table "service_type_category" */
export type ServiceTypeCategoryIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "service_type_category" */
export type ServiceTypeCategoryInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service type category */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  serviceTypesInformation?: InputMaybe<ServiceTypeArrRelInsertInput>;
};

/** aggregate max on columns */
export type ServiceTypeCategoryMaxFields = {
  __typename?: "ServiceTypeCategoryMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the service type category */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate min on columns */
export type ServiceTypeCategoryMinFields = {
  __typename?: "ServiceTypeCategoryMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the service type category */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** response of any mutation on the table "service_type_category" */
export type ServiceTypeCategoryMutationResponse = {
  __typename?: "ServiceTypeCategoryMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceTypeCategory>;
};

/** input type for inserting object relation for remote table "service_type_category" */
export type ServiceTypeCategoryObjRelInsertInput = {
  data: ServiceTypeCategoryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceTypeCategoryOnConflict>;
};

/** on_conflict condition type for table "service_type_category" */
export type ServiceTypeCategoryOnConflict = {
  constraint: ServiceTypeCategoryConstraint;
  updateColumns?: Array<ServiceTypeCategoryUpdateColumn>;
  where?: InputMaybe<ServiceTypeCategoryBoolExp>;
};

/** Ordering options when selecting data from "service_type_category". */
export type ServiceTypeCategoryOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  serviceTypesInformationAggregate?: InputMaybe<ServiceTypeAggregateOrderBy>;
};

/** primary key columns input for table: service_type_category */
export type ServiceTypeCategoryPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_type_category" */
export enum ServiceTypeCategorySelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
}

/** input type for updating data in table "service_type_category" */
export type ServiceTypeCategorySetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service type category */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceTypeCategoryStddevFields = {
  __typename?: "ServiceTypeCategoryStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type ServiceTypeCategoryStddevPopFields = {
  __typename?: "ServiceTypeCategoryStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type ServiceTypeCategoryStddevSampFields = {
  __typename?: "ServiceTypeCategoryStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "service_type_category" */
export type ServiceTypeCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceTypeCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceTypeCategoryStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the service type category */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceTypeCategorySumFields = {
  __typename?: "ServiceTypeCategorySumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "service_type_category" */
export enum ServiceTypeCategoryUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
}

export type ServiceTypeCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceTypeCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceTypeCategorySetInput>;
  /** filter the rows which have to be updated */
  where: ServiceTypeCategoryBoolExp;
};

/** aggregate varPop on columns */
export type ServiceTypeCategoryVarPopFields = {
  __typename?: "ServiceTypeCategoryVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type ServiceTypeCategoryVarSampFields = {
  __typename?: "ServiceTypeCategoryVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type ServiceTypeCategoryVarianceFields = {
  __typename?: "ServiceTypeCategoryVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the service type categories should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** unique or primary key constraints on table "service_type" */
export enum ServiceTypeConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTypePkey = "service_type_pkey",
  /** unique or primary key constraint on columns "is_deprecated" */
  UniqueDeprecatedServiceType = "unique_deprecated_service_type",
}

/** input type for incrementing numeric columns in table "service_type" */
export type ServiceTypeIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "service_type" */
export type ServiceTypeInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service type is a "bucket" for all old, informal services that were created in MS before introducing the formal service types structure */
  isDeprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if this service type is considered medical from compliance point of view */
  isMedicalService?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if this service type is a collection of service menu items that do not belong to any other, more accurate service type */
  isOtherCollection?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaServiceOfferings?: InputMaybe<MedspaServiceOfferingArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Formal name of this service type */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** An extra information about the service for the providers */
  noteForProviders?: InputMaybe<Scalars["String"]["input"]>;
  /** Qualiphy exam ID for this service type */
  qualiphyExamId?: InputMaybe<Scalars["String"]["input"]>;
  serviceDevicesServiceTypes?: InputMaybe<ServiceDevicesServiceTypesArrRelInsertInput>;
  serviceProductTypes?: InputMaybe<ServiceProductTypeArrRelInsertInput>;
  serviceProtocols?: InputMaybe<ServiceProtocolArrRelInsertInput>;
  serviceTypeCategory?: InputMaybe<ServiceTypeCategoryObjRelInsertInput>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingOrders?: InputMaybe<TmpStandingOrderArrRelInsertInput>;
};

/** aggregate max on columns */
export type ServiceTypeMaxFields = {
  __typename?: "ServiceTypeMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Formal name of this service type */
  name?: Maybe<Scalars["String"]["output"]>;
  /** An extra information about the service for the providers */
  noteForProviders?: Maybe<Scalars["String"]["output"]>;
  /** Qualiphy exam ID for this service type */
  qualiphyExamId?: Maybe<Scalars["String"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "service_type" */
export type ServiceTypeMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Formal name of this service type */
  name?: InputMaybe<OrderBy>;
  /** An extra information about the service for the providers */
  noteForProviders?: InputMaybe<OrderBy>;
  /** Qualiphy exam ID for this service type */
  qualiphyExamId?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceTypeMinFields = {
  __typename?: "ServiceTypeMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Formal name of this service type */
  name?: Maybe<Scalars["String"]["output"]>;
  /** An extra information about the service for the providers */
  noteForProviders?: Maybe<Scalars["String"]["output"]>;
  /** Qualiphy exam ID for this service type */
  qualiphyExamId?: Maybe<Scalars["String"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "service_type" */
export type ServiceTypeMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Formal name of this service type */
  name?: InputMaybe<OrderBy>;
  /** An extra information about the service for the providers */
  noteForProviders?: InputMaybe<OrderBy>;
  /** Qualiphy exam ID for this service type */
  qualiphyExamId?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "service_type" */
export type ServiceTypeMutationResponse = {
  __typename?: "ServiceTypeMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceType>;
};

/** input type for inserting object relation for remote table "service_type" */
export type ServiceTypeObjRelInsertInput = {
  data: ServiceTypeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceTypeOnConflict>;
};

/** on_conflict condition type for table "service_type" */
export type ServiceTypeOnConflict = {
  constraint: ServiceTypeConstraint;
  updateColumns?: Array<ServiceTypeUpdateColumn>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

/** Ordering options when selecting data from "service_type". */
export type ServiceTypeOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeprecated?: InputMaybe<OrderBy>;
  isMedicalService?: InputMaybe<OrderBy>;
  isOtherCollection?: InputMaybe<OrderBy>;
  medspaServiceOfferingsAggregate?: InputMaybe<MedspaServiceOfferingAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  noteForProviders?: InputMaybe<OrderBy>;
  qualiphyExamId?: InputMaybe<OrderBy>;
  serviceDevicesServiceTypesAggregate?: InputMaybe<ServiceDevicesServiceTypesAggregateOrderBy>;
  serviceProductTypesAggregate?: InputMaybe<ServiceProductTypeAggregateOrderBy>;
  serviceProtocolsAggregate?: InputMaybe<ServiceProtocolAggregateOrderBy>;
  serviceTypeCategory?: InputMaybe<ServiceTypeCategoryOrderBy>;
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
  standingOrdersAggregate?: InputMaybe<TmpStandingOrderAggregateOrderBy>;
};

/** primary key columns input for table: service_type */
export type ServiceTypePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "service_type" */
export enum ServiceTypeSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IsDeprecated = "isDeprecated",
  /** column name */
  IsMedicalService = "isMedicalService",
  /** column name */
  IsOtherCollection = "isOtherCollection",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  NoteForProviders = "noteForProviders",
  /** column name */
  QualiphyExamId = "qualiphyExamId",
  /** column name */
  ServiceTypeCategoryId = "serviceTypeCategoryId",
}

/** select "serviceTypeAggregateBoolExpBool_andArgumentsColumns" columns of table "service_type" */
export enum ServiceTypeSelectColumnServiceTypeAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDeprecated = "isDeprecated",
  /** column name */
  IsMedicalService = "isMedicalService",
  /** column name */
  IsOtherCollection = "isOtherCollection",
}

/** select "serviceTypeAggregateBoolExpBool_orArgumentsColumns" columns of table "service_type" */
export enum ServiceTypeSelectColumnServiceTypeAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDeprecated = "isDeprecated",
  /** column name */
  IsMedicalService = "isMedicalService",
  /** column name */
  IsOtherCollection = "isOtherCollection",
}

/** input type for updating data in table "service_type" */
export type ServiceTypeSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service type is a "bucket" for all old, informal services that were created in MS before introducing the formal service types structure */
  isDeprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if this service type is considered medical from compliance point of view */
  isMedicalService?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if this service type is a collection of service menu items that do not belong to any other, more accurate service type */
  isOtherCollection?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Formal name of this service type */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** An extra information about the service for the providers */
  noteForProviders?: InputMaybe<Scalars["String"]["input"]>;
  /** Qualiphy exam ID for this service type */
  qualiphyExamId?: InputMaybe<Scalars["String"]["input"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceTypeStddevFields = {
  __typename?: "ServiceTypeStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "service_type" */
export type ServiceTypeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceTypeStddevPopFields = {
  __typename?: "ServiceTypeStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "service_type" */
export type ServiceTypeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceTypeStddevSampFields = {
  __typename?: "ServiceTypeStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "service_type" */
export type ServiceTypeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "service_type" */
export type ServiceTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceTypeStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if the service type is a "bucket" for all old, informal services that were created in MS before introducing the formal service types structure */
  isDeprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if this service type is considered medical from compliance point of view */
  isMedicalService?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if this service type is a collection of service menu items that do not belong to any other, more accurate service type */
  isOtherCollection?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Formal name of this service type */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** An extra information about the service for the providers */
  noteForProviders?: InputMaybe<Scalars["String"]["input"]>;
  /** Qualiphy exam ID for this service type */
  qualiphyExamId?: InputMaybe<Scalars["String"]["input"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceTypeSumFields = {
  __typename?: "ServiceTypeSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "service_type" */
export type ServiceTypeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** update columns of table "service_type" */
export enum ServiceTypeUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  IsDeprecated = "isDeprecated",
  /** column name */
  IsMedicalService = "isMedicalService",
  /** column name */
  IsOtherCollection = "isOtherCollection",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  NoteForProviders = "noteForProviders",
  /** column name */
  QualiphyExamId = "qualiphyExamId",
  /** column name */
  ServiceTypeCategoryId = "serviceTypeCategoryId",
}

export type ServiceTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceTypeBoolExp;
};

/** aggregate varPop on columns */
export type ServiceTypeVarPopFields = {
  __typename?: "ServiceTypeVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "service_type" */
export type ServiceTypeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceTypeVarSampFields = {
  __typename?: "ServiceTypeVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "service_type" */
export type ServiceTypeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceTypeVarianceFields = {
  __typename?: "ServiceTypeVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Category of the service type */
  serviceTypeCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "service_type" */
export type ServiceTypeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Category of the service type */
  serviceTypeCategoryId?: InputMaybe<OrderBy>;
};

export type SignChart = {
  __typename?: "SignChart";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SplitChartingNote = {
  __typename?: "SplitChartingNote";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Table to store Standing Order Templates */
export type StandingOrderTemplate = {
  __typename?: "StandingOrderTemplate";
  /** Signing vendor template id */
  boldsignTemplateId: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the Standing Order Template */
  description: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An array relationship */
  medspas: Array<MedspaStandingOrderTemplate>;
  /** An aggregate relationship */
  medspasAggregate: MedspaStandingOrderTemplateAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** Name/title of the Standing Order Template */
  name: Scalars["String"]["output"];
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId: Scalars["bigint"]["output"];
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current version of the Standing Order Template. */
  version: Scalars["Int"]["output"];
};

/** Table to store Standing Order Templates */
export type StandingOrderTemplateMedspasArgs = {
  distinctOn?: InputMaybe<Array<MedspaStandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaStandingOrderTemplateOrderBy>>;
  where?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
};

/** Table to store Standing Order Templates */
export type StandingOrderTemplateMedspasAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaStandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaStandingOrderTemplateOrderBy>>;
  where?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
};

/** aggregated selection of "standing_order_template" */
export type StandingOrderTemplateAggregate = {
  __typename?: "StandingOrderTemplateAggregate";
  aggregate?: Maybe<StandingOrderTemplateAggregateFields>;
  nodes: Array<StandingOrderTemplate>;
};

/** aggregate fields of "standing_order_template" */
export type StandingOrderTemplateAggregateFields = {
  __typename?: "StandingOrderTemplateAggregateFields";
  avg?: Maybe<StandingOrderTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<StandingOrderTemplateMaxFields>;
  min?: Maybe<StandingOrderTemplateMinFields>;
  stddev?: Maybe<StandingOrderTemplateStddevFields>;
  stddevPop?: Maybe<StandingOrderTemplateStddevPopFields>;
  stddevSamp?: Maybe<StandingOrderTemplateStddevSampFields>;
  sum?: Maybe<StandingOrderTemplateSumFields>;
  varPop?: Maybe<StandingOrderTemplateVarPopFields>;
  varSamp?: Maybe<StandingOrderTemplateVarSampFields>;
  variance?: Maybe<StandingOrderTemplateVarianceFields>;
};

/** aggregate fields of "standing_order_template" */
export type StandingOrderTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StandingOrderTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type StandingOrderTemplateAvgFields = {
  __typename?: "StandingOrderTemplateAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "standing_order_template". All fields are combined with a logical 'AND'. */
export type StandingOrderTemplateBoolExp = {
  _and?: InputMaybe<Array<StandingOrderTemplateBoolExp>>;
  _not?: InputMaybe<StandingOrderTemplateBoolExp>;
  _or?: InputMaybe<Array<StandingOrderTemplateBoolExp>>;
  boldsignTemplateId?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspas?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
  medspasAggregate?: InputMaybe<MedspaStandingOrderTemplateAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serviceProtocolTemplateId?: InputMaybe<BigintComparisonExp>;
  serviceTypeId?: InputMaybe<BigintComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "standing_order_template" */
export enum StandingOrderTemplateConstraint {
  /** unique or primary key constraint on columns "boldsign_template_id" */
  StandingOrderTemplateBoldsignTemplateIdKey = "standing_order_template_boldsign_template_id_key",
  /** unique or primary key constraint on columns "id" */
  StandingOrderTemplatePkey = "standing_order_template_pkey",
  /** unique or primary key constraint on columns "service_protocol_template_id" */
  StandingOrderTemplateServiceProtocolTemplateIdKey = "standing_order_template_service_protocol_template_id_key",
}

/** input type for incrementing numeric columns in table "standing_order_template" */
export type StandingOrderTemplateIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Current version of the Standing Order Template. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "standing_order_template" */
export type StandingOrderTemplateInsertInput = {
  /** Signing vendor template id */
  boldsignTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the Standing Order Template */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspas?: InputMaybe<MedspaStandingOrderTemplateArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the Standing Order Template */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Current version of the Standing Order Template. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type StandingOrderTemplateMaxFields = {
  __typename?: "StandingOrderTemplateMaxFields";
  /** Signing vendor template id */
  boldsignTemplateId?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the Standing Order Template */
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name/title of the Standing Order Template */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate min on columns */
export type StandingOrderTemplateMinFields = {
  __typename?: "StandingOrderTemplateMinFields";
  /** Signing vendor template id */
  boldsignTemplateId?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Detailed description of the Standing Order Template */
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name/title of the Standing Order Template */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** response of any mutation on the table "standing_order_template" */
export type StandingOrderTemplateMutationResponse = {
  __typename?: "StandingOrderTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<StandingOrderTemplate>;
};

/** input type for inserting object relation for remote table "standing_order_template" */
export type StandingOrderTemplateObjRelInsertInput = {
  data: StandingOrderTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<StandingOrderTemplateOnConflict>;
};

/** on_conflict condition type for table "standing_order_template" */
export type StandingOrderTemplateOnConflict = {
  constraint: StandingOrderTemplateConstraint;
  updateColumns?: Array<StandingOrderTemplateUpdateColumn>;
  where?: InputMaybe<StandingOrderTemplateBoolExp>;
};

/** Ordering options when selecting data from "standing_order_template". */
export type StandingOrderTemplateOrderBy = {
  boldsignTemplateId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspasAggregate?: InputMaybe<MedspaStandingOrderTemplateAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serviceProtocolTemplateId?: InputMaybe<OrderBy>;
  serviceTypeId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: standing_order_template */
export type StandingOrderTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "standing_order_template" */
export enum StandingOrderTemplateSelectColumn {
  /** column name */
  BoldsignTemplateId = "boldsignTemplateId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ServiceProtocolTemplateId = "serviceProtocolTemplateId",
  /** column name */
  ServiceTypeId = "serviceTypeId",
  /** column name */
  Version = "version",
}

/** input type for updating data in table "standing_order_template" */
export type StandingOrderTemplateSetInput = {
  /** Signing vendor template id */
  boldsignTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the Standing Order Template */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the Standing Order Template */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Current version of the Standing Order Template. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type StandingOrderTemplateStddevFields = {
  __typename?: "StandingOrderTemplateStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type StandingOrderTemplateStddevPopFields = {
  __typename?: "StandingOrderTemplateStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type StandingOrderTemplateStddevSampFields = {
  __typename?: "StandingOrderTemplateStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "standing_order_template" */
export type StandingOrderTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StandingOrderTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StandingOrderTemplateStreamCursorValueInput = {
  /** Signing vendor template id */
  boldsignTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Detailed description of the Standing Order Template */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the Standing Order Template */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Current version of the Standing Order Template. */
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type StandingOrderTemplateSumFields = {
  __typename?: "StandingOrderTemplateSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "standing_order_template" */
export enum StandingOrderTemplateUpdateColumn {
  /** column name */
  BoldsignTemplateId = "boldsignTemplateId",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ServiceProtocolTemplateId = "serviceProtocolTemplateId",
  /** column name */
  ServiceTypeId = "serviceTypeId",
  /** column name */
  Version = "version",
}

export type StandingOrderTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StandingOrderTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StandingOrderTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: StandingOrderTemplateBoolExp;
};

/** aggregate varPop on columns */
export type StandingOrderTemplateVarPopFields = {
  __typename?: "StandingOrderTemplateVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type StandingOrderTemplateVarSampFields = {
  __typename?: "StandingOrderTemplateVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type StandingOrderTemplateVarianceFields = {
  __typename?: "StandingOrderTemplateVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Service Protocol Template associated with the Standing Order Template */
  serviceProtocolTemplateId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order Template */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Current version of the Standing Order Template. */
  version?: Maybe<Scalars["Float"]["output"]>;
};

export type StandingOrderType = {
  __typename?: "StandingOrderType";
  file: Scalars["String"]["output"];
  fileName?: Maybe<Scalars["String"]["output"]>;
  fileUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  providerUser?: Maybe<UserType>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars["String"]["input"]>;
  _gt?: InputMaybe<Scalars["String"]["input"]>;
  _gte?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]["input"]>;
  _in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]["input"]>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]["input"]>;
  _lt?: InputMaybe<Scalars["String"]["input"]>;
  _lte?: InputMaybe<Scalars["String"]["input"]>;
  _neq?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]["input"]>;
};

export type StripeAddressType = {
  __typename?: "StripeAddressType";
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  line1?: Maybe<Scalars["String"]["output"]>;
  line2?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
};

export type StripeBalanceTransactionFeeDetailsType = {
  __typename?: "StripeBalanceTransactionFeeDetailsType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  application?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type StripeBalanceTransactionType = {
  __typename?: "StripeBalanceTransactionType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  availableOn?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  exchangeRate?: Maybe<Scalars["Float"]["output"]>;
  fee?: Maybe<Scalars["Int"]["output"]>;
  feeDetails?: Maybe<StripeBalanceTransactionFeeDetailsType>;
  id?: Maybe<Scalars["String"]["output"]>;
  net?: Maybe<Scalars["Int"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  reportingCategory?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type StripeDestinationType = {
  __typename?: "StripeDestinationType";
  bankName?: Maybe<Scalars["String"]["output"]>;
  brand?: Maybe<Scalars["String"]["output"]>;
  last4?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
};

export type StripeFinancialAccountAddressAbaSubtype = {
  __typename?: "StripeFinancialAccountAddressAbaSubtype";
  accountHolderLast4?: Maybe<Scalars["String"]["output"]>;
  accountHolderName?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  bankName?: Maybe<Scalars["String"]["output"]>;
  routingNumber?: Maybe<Scalars["String"]["output"]>;
};

export type StripeFinancialAccountAddressType = {
  __typename?: "StripeFinancialAccountAddressType";
  aba?: Maybe<StripeFinancialAccountAddressAbaSubtype>;
  supportedNetworks?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type StripeFinancialAccountBalanceSubType = {
  __typename?: "StripeFinancialAccountBalanceSubType";
  usd?: Maybe<Scalars["Int"]["output"]>;
};

export type StripeFinancialAccountBalanceType = {
  __typename?: "StripeFinancialAccountBalanceType";
  cash?: Maybe<StripeFinancialAccountBalanceSubType>;
  inboundPending?: Maybe<StripeFinancialAccountBalanceSubType>;
  outboundPending?: Maybe<StripeFinancialAccountBalanceSubType>;
};

export type StripeFinancialAccountPlatformRestrictionsType = {
  __typename?: "StripeFinancialAccountPlatformRestrictionsType";
  inboundFlows?: Maybe<Scalars["String"]["output"]>;
  outboundFlows?: Maybe<Scalars["String"]["output"]>;
};

export type StripeFinancialAccountStatusDetailsClosedType = {
  __typename?: "StripeFinancialAccountStatusDetailsClosedType";
  reasons?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type StripeFinancialAccountStatusDetailsType = {
  __typename?: "StripeFinancialAccountStatusDetailsType";
  closed?: Maybe<StripeFinancialAccountStatusDetailsClosedType>;
};

export type StripeFinancialAccountType = {
  __typename?: "StripeFinancialAccountType";
  activeFeatures?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  balance?: Maybe<StripeFinancialAccountBalanceType>;
  country?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  financialAddresses?: Maybe<Array<Maybe<StripeFinancialAccountAddressType>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  pendingFeatures?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  platformRestrictions?: Maybe<StripeFinancialAccountPlatformRestrictionsType>;
  restrictedFeatures?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusDetails?: Maybe<StripeFinancialAccountStatusDetailsType>;
  supportedCurrencies?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type StripeIssuingAmountDetailsType = {
  __typename?: "StripeIssuingAmountDetailsType";
  atmFee?: Maybe<Scalars["Int"]["output"]>;
};

export type StripeIssuingAuthorizationNetworkDataType = {
  __typename?: "StripeIssuingAuthorizationNetworkDataType";
  acquiringInsititutionId?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingAuthorizationPendingRequestType = {
  __typename?: "StripeIssuingAuthorizationPendingRequestType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  amountDetails?: Maybe<StripeIssuingAmountDetailsType>;
  currency?: Maybe<Scalars["String"]["output"]>;
  isAmountControllable?: Maybe<Scalars["Boolean"]["output"]>;
  merchantAmount?: Maybe<Scalars["Int"]["output"]>;
  merchantCurrency?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingAuthorizationRequestHistoryType = {
  __typename?: "StripeIssuingAuthorizationRequestHistoryType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  amountDetails?: Maybe<StripeIssuingAmountDetailsType>;
  approved?: Maybe<Scalars["Boolean"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  merchantAmount?: Maybe<Scalars["Int"]["output"]>;
  merchantCurrency?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
  reasonMessage?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingAuthorizationType = {
  __typename?: "StripeIssuingAuthorizationType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  amountDetails?: Maybe<StripeIssuingAmountDetailsType>;
  approved?: Maybe<Scalars["Boolean"]["output"]>;
  authorizationMethod?: Maybe<Scalars["String"]["output"]>;
  balanceTransactions?: Maybe<Array<Maybe<StripeBalanceTransactionType>>>;
  card?: Maybe<StripeIssuingCardType>;
  cardholder?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  merchantAmount?: Maybe<Scalars["Int"]["output"]>;
  merchantCurrency?: Maybe<Scalars["String"]["output"]>;
  merchantData?: Maybe<StripeIssuingMerchantDataType>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  networkData?: Maybe<StripeIssuingAuthorizationNetworkDataType>;
  object?: Maybe<Scalars["String"]["output"]>;
  pendingRequest?: Maybe<StripeIssuingAuthorizationPendingRequestType>;
  requestHistory?: Maybe<
    Array<Maybe<StripeIssuingAuthorizationRequestHistoryType>>
  >;
  status?: Maybe<Scalars["String"]["output"]>;
  transactions?: Maybe<Array<Maybe<StripeIssuingTransactionType>>>;
  verificationData?: Maybe<StripeIssuingAuthorizationVerificationDataType>;
  wallet?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingAuthorizationVerificationDataType = {
  __typename?: "StripeIssuingAuthorizationVerificationDataType";
  addressLine1Check?: Maybe<Scalars["String"]["output"]>;
  addressPostalCodeCheck?: Maybe<Scalars["String"]["output"]>;
  cvcCheck?: Maybe<Scalars["String"]["output"]>;
  expiryCheck?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingCardListResponseType = {
  __typename?: "StripeIssuingCardListResponseType";
  hasMore?: Maybe<Scalars["Boolean"]["output"]>;
  list?: Maybe<Array<Maybe<StripeIssuingCardType>>>;
};

export type StripeIssuingCardShippingCustomsType = {
  __typename?: "StripeIssuingCardShippingCustomsType";
  eoriNumber?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingCardShippingType = {
  __typename?: "StripeIssuingCardShippingType";
  address?: Maybe<StripeAddressType>;
  carrier?: Maybe<Scalars["String"]["output"]>;
  customs?: Maybe<StripeIssuingCardShippingCustomsType>;
  eta?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  requireSignature?: Maybe<Scalars["Boolean"]["output"]>;
  service?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  trackingNumber?: Maybe<Scalars["String"]["output"]>;
  trackingUrl?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingCardType = {
  __typename?: "StripeIssuingCardType";
  brand?: Maybe<Scalars["String"]["output"]>;
  cancellationReason?: Maybe<Scalars["String"]["output"]>;
  cardholder?: Maybe<StripeIssuingCardholderType>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  cvc?: Maybe<Scalars["String"]["output"]>;
  expMonth?: Maybe<Scalars["Int"]["output"]>;
  expYear?: Maybe<Scalars["Int"]["output"]>;
  financialAccount?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  last4?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  replacedBy?: Maybe<Scalars["String"]["output"]>;
  replacementFor?: Maybe<Scalars["String"]["output"]>;
  replacementReason?: Maybe<Scalars["String"]["output"]>;
  shipping?: Maybe<StripeIssuingCardShippingType>;
  spendingControls?: Maybe<StripeIssuingSpendingControlsType>;
  status?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  wallets?: Maybe<StripeIssuingCardWalletsType>;
};

export type StripeIssuingCardWalletsThirdPartyPayType = {
  __typename?: "StripeIssuingCardWalletsThirdPartyPayType";
  eligible?: Maybe<Scalars["Boolean"]["output"]>;
  ineligibleReason?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingCardWalletsType = {
  __typename?: "StripeIssuingCardWalletsType";
  applePay?: Maybe<StripeIssuingCardWalletsThirdPartyPayType>;
  googlePay?: Maybe<StripeIssuingCardWalletsThirdPartyPayType>;
  primaryAccountIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingCardholderBillingType = {
  __typename?: "StripeIssuingCardholderBillingType";
  address?: Maybe<StripeAddressType>;
};

export type StripeIssuingCardholderCompanyType = {
  __typename?: "StripeIssuingCardholderCompanyType";
  taxIdProvided?: Maybe<Scalars["Boolean"]["output"]>;
};

export type StripeIssuingCardholderIndividualCardIssuingType = {
  __typename?: "StripeIssuingCardholderIndividualCardIssuingType";
  userTermsAcceptance?: Maybe<StripeIssuingCardholderIndividualCardIssuingUserTermsAcceptanceType>;
};

export type StripeIssuingCardholderIndividualCardIssuingUserTermsAcceptanceType =
  {
    __typename?: "StripeIssuingCardholderIndividualCardIssuingUserTermsAcceptanceType";
    date?: Maybe<Scalars["Int"]["output"]>;
    ip?: Maybe<Scalars["String"]["output"]>;
    userAgent?: Maybe<Scalars["String"]["output"]>;
  };

export type StripeIssuingCardholderIndividualDobType = {
  __typename?: "StripeIssuingCardholderIndividualDobType";
  day?: Maybe<Scalars["Int"]["output"]>;
  month?: Maybe<Scalars["Int"]["output"]>;
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type StripeIssuingCardholderIndividualType = {
  __typename?: "StripeIssuingCardholderIndividualType";
  cardIssuing?: Maybe<StripeIssuingCardholderIndividualCardIssuingType>;
  dob?: Maybe<StripeIssuingCardholderIndividualDobType>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  verification?: Maybe<StripeIssuingCardholderIndividualVerificationType>;
};

export type StripeIssuingCardholderIndividualVerificationType = {
  __typename?: "StripeIssuingCardholderIndividualVerificationType";
  back?: Maybe<Scalars["String"]["output"]>;
  front?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingCardholderListResponseType = {
  __typename?: "StripeIssuingCardholderListResponseType";
  hasMore?: Maybe<Scalars["Boolean"]["output"]>;
  list?: Maybe<Array<Maybe<StripeIssuingCardholderType>>>;
};

export type StripeIssuingCardholderMetadataType = {
  __typename?: "StripeIssuingCardholderMetadataType";
  moxieUserId?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingCardholderType = {
  __typename?: "StripeIssuingCardholderType";
  billing?: Maybe<StripeIssuingCardholderBillingType>;
  company?: Maybe<StripeIssuingCardholderCompanyType>;
  created?: Maybe<Scalars["Int"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  individual?: Maybe<StripeIssuingCardholderIndividualType>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<StripeIssuingCardholderMetadataType>;
  name?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  preferredLocales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  spendingControls?: Maybe<StripeIssuingSpendingControlsType>;
  status?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingMerchantDataType = {
  __typename?: "StripeIssuingMerchantDataType";
  category?: Maybe<Scalars["String"]["output"]>;
  categoryCode?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  networkId?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  terminalId?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingSpendingControlsSpendingLimitsType = {
  __typename?: "StripeIssuingSpendingControlsSpendingLimitsType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  interval?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingSpendingControlsType = {
  __typename?: "StripeIssuingSpendingControlsType";
  allowedCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  blockedCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  spendingLimits?: Maybe<
    Array<Maybe<StripeIssuingSpendingControlsSpendingLimitsType>>
  >;
  spendingLimitsCurrency?: Maybe<Scalars["String"]["output"]>;
};

export type StripeIssuingTransactionListResponseType = {
  __typename?: "StripeIssuingTransactionListResponseType";
  hasMore?: Maybe<Scalars["Boolean"]["output"]>;
  list?: Maybe<Array<Maybe<StripeIssuingTransactionType>>>;
};

export type StripeIssuingTransactionType = {
  __typename?: "StripeIssuingTransactionType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  amountDetails?: Maybe<StripeIssuingAmountDetailsType>;
  authorization?: Maybe<Scalars["String"]["output"]>;
  balanceTransaction?: Maybe<Scalars["String"]["output"]>;
  card?: Maybe<Scalars["String"]["output"]>;
  cardholder?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  dispute?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  merchantAmount?: Maybe<Scalars["Int"]["output"]>;
  merchantCurrency?: Maybe<Scalars["String"]["output"]>;
  merchantData?: Maybe<StripeIssuingMerchantDataType>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  wallet?: Maybe<Scalars["String"]["output"]>;
};

export type StripeOutboundPaymentMethodType = {
  __typename?: "StripeOutboundPaymentMethodType";
  bankName?: Maybe<Scalars["String"]["output"]>;
  last4?: Maybe<Scalars["String"]["output"]>;
};

export type StripePayoutType = {
  __typename?: "StripePayoutType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  arrivalDate?: Maybe<Scalars["Int"]["output"]>;
  automatic?: Maybe<Scalars["Boolean"]["output"]>;
  balanceTransaction?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  destination?: Maybe<StripeDestinationType>;
  failureBalanceTransaction?: Maybe<Scalars["String"]["output"]>;
  failureCode?: Maybe<Scalars["String"]["output"]>;
  failureMessage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  method?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  reconciliationStatus?: Maybe<Scalars["String"]["output"]>;
  sourceType?: Maybe<Scalars["String"]["output"]>;
  statementDescriptor?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTerminalType = {
  __typename?: "StripeTerminalType";
  id: Scalars["ID"]["output"];
  lastConnectedAt: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  serialNumber: Scalars["String"]["output"];
  type: MedspasStripeTerminalTypeChoices;
};

export type StripeTreasuryTransactionBalanceImpactType = {
  __typename?: "StripeTreasuryTransactionBalanceImpactType";
  cash?: Maybe<Scalars["Int"]["output"]>;
  inboundPending?: Maybe<Scalars["Int"]["output"]>;
  outboundPending?: Maybe<Scalars["Int"]["output"]>;
};

export type StripeTreasuryTransactionEntriesType = {
  __typename?: "StripeTreasuryTransactionEntriesType";
  data?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  hasMore?: Maybe<Scalars["Boolean"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsCreditReversalType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsCreditReversalType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  financialAccount?: Maybe<Scalars["String"]["output"]>;
  hostedRegulatoryReceiptUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  network?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  receivedCredit?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusTransitions?: Maybe<StripeTreasuryTransactionStatusTransitionsType>;
  transaction?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsDebitReversalType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsDebitReversalType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  financialAccount?: Maybe<Scalars["String"]["output"]>;
  hostedRegulatoryReceiptUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  linkedFlows?: Maybe<StripeTreasuryTransactionFlowDetailsLinkedFlowsType>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  network?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  receivedDebit?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusTransitions?: Maybe<StripeTreasuryTransactionStatusTransitionsType>;
  transaction?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsEndUserDetailsType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsEndUserDetailsType";
  ipAddress?: Maybe<Scalars["String"]["output"]>;
  present?: Maybe<Scalars["Boolean"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsInboundTransferFailureDetailsType =
  {
    __typename?: "StripeTreasuryTransactionFlowDetailsInboundTransferFailureDetailsType";
    code?: Maybe<Scalars["String"]["output"]>;
  };

export type StripeTreasuryTransactionFlowDetailsInboundTransferOriginPaymentMethodDetailsBillingDetailsType =
  {
    __typename?: "StripeTreasuryTransactionFlowDetailsInboundTransferOriginPaymentMethodDetailsBillingDetailsType";
    address?: Maybe<StripeAddressType>;
    email?: Maybe<Scalars["String"]["output"]>;
    name?: Maybe<Scalars["String"]["output"]>;
  };

export type StripeTreasuryTransactionFlowDetailsInboundTransferOriginPaymentMethodDetailsUsBankAccountType =
  {
    __typename?: "StripeTreasuryTransactionFlowDetailsInboundTransferOriginPaymentMethodDetailsUsBankAccountType";
    accountHolderType?: Maybe<Scalars["String"]["output"]>;
    accountType?: Maybe<Scalars["String"]["output"]>;
    bankName?: Maybe<Scalars["String"]["output"]>;
    fingerprint?: Maybe<Scalars["String"]["output"]>;
    last4?: Maybe<Scalars["String"]["output"]>;
    network?: Maybe<Scalars["String"]["output"]>;
    routingNumber?: Maybe<Scalars["String"]["output"]>;
  };

export type StripeTreasuryTransactionFlowDetailsInboundTransferType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsInboundTransferType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  cancelable?: Maybe<Scalars["Boolean"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  failureDetails?: Maybe<StripeTreasuryTransactionFlowDetailsInboundTransferFailureDetailsType>;
  financialAccount?: Maybe<Scalars["String"]["output"]>;
  hostedRegulatoryReceiptUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  linkedFlows?: Maybe<StripeTreasuryTransactionFlowDetailsLinkedFlowsType>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  originPaymentMethod?: Maybe<Scalars["String"]["output"]>;
  originPaymentMethodDetails?: Maybe<StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsType>;
  returned?: Maybe<Scalars["Boolean"]["output"]>;
  statementDescriptor?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusTransitions?: Maybe<StripeTreasuryTransactionStatusTransitionsType>;
  transaction?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsLinkedFlowsType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsLinkedFlowsType";
  creditReversal?: Maybe<Scalars["String"]["output"]>;
  issuingAuthorization?: Maybe<Scalars["String"]["output"]>;
  issuingDispute?: Maybe<Scalars["String"]["output"]>;
  issuingTranaction?: Maybe<Scalars["String"]["output"]>;
  receivedDebit?: Maybe<Scalars["String"]["output"]>;
  sourceFlow?: Maybe<Scalars["String"]["output"]>;
  sourceFlowType?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsOutboundPaymentType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsOutboundPaymentType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  cancelable?: Maybe<Scalars["Boolean"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  customer?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  destination?: Maybe<Scalars["String"]["output"]>;
  destinationPaymentMethodDetails?: Maybe<StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsType>;
  endUserDetails?: Maybe<StripeTreasuryTransactionFlowDetailsEndUserDetailsType>;
  expectedArrivalDate?: Maybe<Scalars["Int"]["output"]>;
  financialAccount?: Maybe<Scalars["String"]["output"]>;
  hostedRegulatoryReceiptUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  returnedDetails?: Maybe<StripeTreasuryTransactionFlowDetailsReturnedDetailsType>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusTransitions?: Maybe<StripeTreasuryTransactionStatusTransitionsType>;
  transaction?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsOutboundTransferType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsOutboundTransferType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  cancelable?: Maybe<Scalars["Boolean"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  destinationPaymentMethod?: Maybe<Scalars["String"]["output"]>;
  destinationPaymentMethodDetails?: Maybe<StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsType>;
  expectedArrivalDate?: Maybe<Scalars["Int"]["output"]>;
  financialAccount?: Maybe<Scalars["String"]["output"]>;
  hostedRegulatoryReceiptUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  metadata?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  returnedDetails?: Maybe<StripeTreasuryTransactionFlowDetailsReturnedDetailsType>;
  statementDescriptor?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusTransitions?: Maybe<StripeTreasuryTransactionStatusTransitionsType>;
  transaction?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsFinancialAccountType =
  {
    __typename?: "StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsFinancialAccountType";
    id?: Maybe<Scalars["String"]["output"]>;
    network?: Maybe<Scalars["String"]["output"]>;
  };

export type StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsType";
  billingDetails?: Maybe<StripeTreasuryTransactionFlowDetailsInboundTransferOriginPaymentMethodDetailsBillingDetailsType>;
  financialAccount?: Maybe<StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsFinancialAccountType>;
  type?: Maybe<Scalars["String"]["output"]>;
  usBankAccount?: Maybe<StripeTreasuryTransactionFlowDetailsInboundTransferOriginPaymentMethodDetailsUsBankAccountType>;
};

export type StripeTreasuryTransactionFlowDetailsReceivedCreditOrDebitType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsReceivedCreditOrDebitType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  failureCode?: Maybe<Scalars["String"]["output"]>;
  financialAccount?: Maybe<Scalars["String"]["output"]>;
  hostedRegulatoryReceiptUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  initiatingPaymentMethodDetails?: Maybe<StripeTreasuryTransactionFlowDetailsPaymentMethodDetailsType>;
  linkedFlows?: Maybe<StripeTreasuryTransactionFlowDetailsLinkedFlowsType>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  network?: Maybe<Scalars["String"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  reversalDetails?: Maybe<StripeTreasuryTransactionFlowDetailsReceivedCreditReversalDetailsType>;
  status?: Maybe<Scalars["String"]["output"]>;
  transaction?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsReceivedCreditReversalDetailsType =
  {
    __typename?: "StripeTreasuryTransactionFlowDetailsReceivedCreditReversalDetailsType";
    deadline?: Maybe<Scalars["Int"]["output"]>;
    restrictedReason?: Maybe<Scalars["String"]["output"]>;
  };

export type StripeTreasuryTransactionFlowDetailsReturnedDetailsType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsReturnedDetailsType";
  code?: Maybe<Scalars["String"]["output"]>;
  transaction?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionFlowDetailsType = {
  __typename?: "StripeTreasuryTransactionFlowDetailsType";
  creditReversal?: Maybe<StripeTreasuryTransactionFlowDetailsCreditReversalType>;
  debitReversal?: Maybe<StripeTreasuryTransactionFlowDetailsDebitReversalType>;
  inboundTransfer?: Maybe<StripeTreasuryTransactionFlowDetailsInboundTransferType>;
  issuingAuthorization?: Maybe<StripeIssuingAuthorizationType>;
  outboundPayment?: Maybe<StripeTreasuryTransactionFlowDetailsOutboundPaymentType>;
  outboundTransfer?: Maybe<StripeTreasuryTransactionFlowDetailsOutboundTransferType>;
  receivedCredit?: Maybe<StripeTreasuryTransactionFlowDetailsReceivedCreditOrDebitType>;
  receivedDebit?: Maybe<StripeTreasuryTransactionFlowDetailsReceivedCreditOrDebitType>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type StripeTreasuryTransactionListResponseType = {
  __typename?: "StripeTreasuryTransactionListResponseType";
  hasMore?: Maybe<Scalars["Boolean"]["output"]>;
  list?: Maybe<Array<Maybe<StripeTreasuryTransactionType>>>;
};

export type StripeTreasuryTransactionStatusTransitionsType = {
  __typename?: "StripeTreasuryTransactionStatusTransitionsType";
  canceledAt?: Maybe<Scalars["Int"]["output"]>;
  completedAt?: Maybe<Scalars["Int"]["output"]>;
  failedAt?: Maybe<Scalars["Int"]["output"]>;
  postedAt?: Maybe<Scalars["Int"]["output"]>;
  returnedAt?: Maybe<Scalars["Int"]["output"]>;
  succeededAt?: Maybe<Scalars["Int"]["output"]>;
  voidAt?: Maybe<Scalars["Int"]["output"]>;
};

export type StripeTreasuryTransactionType = {
  __typename?: "StripeTreasuryTransactionType";
  amount?: Maybe<Scalars["Int"]["output"]>;
  balanceImpact?: Maybe<StripeTreasuryTransactionBalanceImpactType>;
  created?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  entries?: Maybe<StripeTreasuryTransactionEntriesType>;
  financialAccount?: Maybe<Scalars["String"]["output"]>;
  flow?: Maybe<Scalars["String"]["output"]>;
  flowDetails?: Maybe<StripeTreasuryTransactionFlowDetailsType>;
  flowType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  livemode?: Maybe<Scalars["Boolean"]["output"]>;
  object?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  statusTransitions?: Maybe<StripeTreasuryTransactionStatusTransitionsType>;
};

export type SubmitGfeReview = {
  __typename?: "SubmitGfeReview";
  createdGfeReviewDiagnoses?: Maybe<Array<Maybe<GfeReviewDiagnosisType>>>;
  createdGfeReviewServiceProductIndications?: Maybe<
    Array<Maybe<GfeServiceProductIndicationType>>
  >;
  createdGfeReviewTreatmentIndications?: Maybe<
    Array<Maybe<GfeTreatmentIndicationType>>
  >;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  updatedGfeReviewRequest?: Maybe<GfeReviewRequestType>;
  updatedGfeReviewSubmission?: Maybe<GfeReviewSubmissionType>;
};

export type SubmitSyncGfeReview = {
  __typename?: "SubmitSyncGfeReview";
  createdGfeReviewDiagnoses?: Maybe<Array<Maybe<GfeReviewDiagnosisType>>>;
  createdGfeReviewServiceProductIndications?: Maybe<
    Array<Maybe<GfeServiceProductIndicationType>>
  >;
  createdGfeReviewTreatmentIndications?: Maybe<
    Array<Maybe<GfeTreatmentIndicationType>>
  >;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
  updatedGfeReviewRequest?: Maybe<GfeReviewRequestType>;
  updatedGfeReviewSubmission?: Maybe<GfeReviewSubmissionType>;
  updatedGfeSyncDocument?: Maybe<GfeSynchronousReviewDocumentType>;
};

export enum SubscriptionInvoiceStatusEnum {
  Draft = "DRAFT",
  Open = "OPEN",
  Paid = "PAID",
  PaymentProcessing = "PAYMENT_PROCESSING",
  Uncollectible = "UNCOLLECTIBLE",
  Void = "VOID",
}

export enum SubscriptionPaymentStatusEnum {
  InvoiceSent = "INVOICE_SENT",
  PastDue = "PAST_DUE",
  PaymentProcessing = "PAYMENT_PROCESSING",
  SubscriptionPending = "SUBSCRIPTION_PENDING",
  UpToDate = "UP_TO_DATE",
}

/** A list of products/devices suppliers available on the market */
export type SupplierInformation = {
  __typename?: "SupplierInformation";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Name of the supplier */
  name: Scalars["String"]["output"];
  /** An array relationship */
  serviceProducts: Array<ServiceProduct>;
  /** An aggregate relationship */
  serviceProductsAggregate: ServiceProductAggregate;
};

/** A list of products/devices suppliers available on the market */
export type SupplierInformationServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductOrderBy>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

/** A list of products/devices suppliers available on the market */
export type SupplierInformationServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductOrderBy>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

/** aggregated selection of "supplier_information" */
export type SupplierInformationAggregate = {
  __typename?: "SupplierInformationAggregate";
  aggregate?: Maybe<SupplierInformationAggregateFields>;
  nodes: Array<SupplierInformation>;
};

/** aggregate fields of "supplier_information" */
export type SupplierInformationAggregateFields = {
  __typename?: "SupplierInformationAggregateFields";
  avg?: Maybe<SupplierInformationAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<SupplierInformationMaxFields>;
  min?: Maybe<SupplierInformationMinFields>;
  stddev?: Maybe<SupplierInformationStddevFields>;
  stddevPop?: Maybe<SupplierInformationStddevPopFields>;
  stddevSamp?: Maybe<SupplierInformationStddevSampFields>;
  sum?: Maybe<SupplierInformationSumFields>;
  varPop?: Maybe<SupplierInformationVarPopFields>;
  varSamp?: Maybe<SupplierInformationVarSampFields>;
  variance?: Maybe<SupplierInformationVarianceFields>;
};

/** aggregate fields of "supplier_information" */
export type SupplierInformationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SupplierInformationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type SupplierInformationAvgFields = {
  __typename?: "SupplierInformationAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "supplier_information". All fields are combined with a logical 'AND'. */
export type SupplierInformationBoolExp = {
  _and?: InputMaybe<Array<SupplierInformationBoolExp>>;
  _not?: InputMaybe<SupplierInformationBoolExp>;
  _or?: InputMaybe<Array<SupplierInformationBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serviceProducts?: InputMaybe<ServiceProductBoolExp>;
  serviceProductsAggregate?: InputMaybe<ServiceProductAggregateBoolExp>;
};

/** unique or primary key constraints on table "supplier_information" */
export enum SupplierInformationConstraint {
  /** unique or primary key constraint on columns "id" */
  SupplierInformationPkey = "supplier_information_pkey",
}

/** input type for incrementing numeric columns in table "supplier_information" */
export type SupplierInformationIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "supplier_information" */
export type SupplierInformationInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the supplier */
  name?: InputMaybe<Scalars["String"]["input"]>;
  serviceProducts?: InputMaybe<ServiceProductArrRelInsertInput>;
};

/** aggregate max on columns */
export type SupplierInformationMaxFields = {
  __typename?: "SupplierInformationMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the supplier */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type SupplierInformationMinFields = {
  __typename?: "SupplierInformationMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name of the supplier */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "supplier_information" */
export type SupplierInformationMutationResponse = {
  __typename?: "SupplierInformationMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<SupplierInformation>;
};

/** input type for inserting object relation for remote table "supplier_information" */
export type SupplierInformationObjRelInsertInput = {
  data: SupplierInformationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SupplierInformationOnConflict>;
};

/** on_conflict condition type for table "supplier_information" */
export type SupplierInformationOnConflict = {
  constraint: SupplierInformationConstraint;
  updateColumns?: Array<SupplierInformationUpdateColumn>;
  where?: InputMaybe<SupplierInformationBoolExp>;
};

/** Ordering options when selecting data from "supplier_information". */
export type SupplierInformationOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serviceProductsAggregate?: InputMaybe<ServiceProductAggregateOrderBy>;
};

/** primary key columns input for table: supplier_information */
export type SupplierInformationPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "supplier_information" */
export enum SupplierInformationSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "supplier_information" */
export type SupplierInformationSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the supplier */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type SupplierInformationStddevFields = {
  __typename?: "SupplierInformationStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type SupplierInformationStddevPopFields = {
  __typename?: "SupplierInformationStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type SupplierInformationStddevSampFields = {
  __typename?: "SupplierInformationStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "supplier_information" */
export type SupplierInformationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SupplierInformationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SupplierInformationStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name of the supplier */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type SupplierInformationSumFields = {
  __typename?: "SupplierInformationSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "supplier_information" */
export enum SupplierInformationUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type SupplierInformationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SupplierInformationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SupplierInformationSetInput>;
  /** filter the rows which have to be updated */
  where: SupplierInformationBoolExp;
};

/** aggregate varPop on columns */
export type SupplierInformationVarPopFields = {
  __typename?: "SupplierInformationVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type SupplierInformationVarSampFields = {
  __typename?: "SupplierInformationVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type SupplierInformationVarianceFields = {
  __typename?: "SupplierInformationVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** An enumeration. */
export enum TelehealthType {
  Phone = "PHONE",
  Video = "VIDEO",
}

/** Stores additional details for a visit that is performed during phone or video call */
export type TelehealthVisitDetails = {
  __typename?: "TelehealthVisitDetails";
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** Optional field, stores the visit url when it is a video visit */
  videoLink?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  visit: Visit;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetails?: Maybe<Scalars["String"]["output"]>;
  /** Related telehealth visit */
  visitId: Scalars["bigint"]["output"];
  /** Stores telehealth visit type */
  visitType: Scalars["String"]["output"];
};

/** aggregated selection of "telehealth_visit_details" */
export type TelehealthVisitDetailsAggregate = {
  __typename?: "TelehealthVisitDetailsAggregate";
  aggregate?: Maybe<TelehealthVisitDetailsAggregateFields>;
  nodes: Array<TelehealthVisitDetails>;
};

/** aggregate fields of "telehealth_visit_details" */
export type TelehealthVisitDetailsAggregateFields = {
  __typename?: "TelehealthVisitDetailsAggregateFields";
  avg?: Maybe<TelehealthVisitDetailsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<TelehealthVisitDetailsMaxFields>;
  min?: Maybe<TelehealthVisitDetailsMinFields>;
  stddev?: Maybe<TelehealthVisitDetailsStddevFields>;
  stddevPop?: Maybe<TelehealthVisitDetailsStddevPopFields>;
  stddevSamp?: Maybe<TelehealthVisitDetailsStddevSampFields>;
  sum?: Maybe<TelehealthVisitDetailsSumFields>;
  varPop?: Maybe<TelehealthVisitDetailsVarPopFields>;
  varSamp?: Maybe<TelehealthVisitDetailsVarSampFields>;
  variance?: Maybe<TelehealthVisitDetailsVarianceFields>;
};

/** aggregate fields of "telehealth_visit_details" */
export type TelehealthVisitDetailsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TelehealthVisitDetailsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type TelehealthVisitDetailsAvgFields = {
  __typename?: "TelehealthVisitDetailsAvgFields";
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "telehealth_visit_details". All fields are combined with a logical 'AND'. */
export type TelehealthVisitDetailsBoolExp = {
  _and?: InputMaybe<Array<TelehealthVisitDetailsBoolExp>>;
  _not?: InputMaybe<TelehealthVisitDetailsBoolExp>;
  _or?: InputMaybe<Array<TelehealthVisitDetailsBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<UserBoolExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  videoLink?: InputMaybe<StringComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitDetails?: InputMaybe<StringComparisonExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
  visitType?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "telehealth_visit_details" */
export enum TelehealthVisitDetailsConstraint {
  /** unique or primary key constraint on columns "visit_id" */
  TelehealthVisitDetailsPkey = "telehealth_visit_details_pkey",
}

/** input type for incrementing numeric columns in table "telehealth_visit_details" */
export type TelehealthVisitDetailsIncInput = {
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Related telehealth visit */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "telehealth_visit_details" */
export type TelehealthVisitDetailsInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLink?: InputMaybe<Scalars["String"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetails?: InputMaybe<Scalars["String"]["input"]>;
  /** Related telehealth visit */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Stores telehealth visit type */
  visitType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type TelehealthVisitDetailsMaxFields = {
  __typename?: "TelehealthVisitDetailsMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLink?: Maybe<Scalars["String"]["output"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetails?: Maybe<Scalars["String"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
  /** Stores telehealth visit type */
  visitType?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type TelehealthVisitDetailsMinFields = {
  __typename?: "TelehealthVisitDetailsMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLink?: Maybe<Scalars["String"]["output"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetails?: Maybe<Scalars["String"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
  /** Stores telehealth visit type */
  visitType?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "telehealth_visit_details" */
export type TelehealthVisitDetailsMutationResponse = {
  __typename?: "TelehealthVisitDetailsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<TelehealthVisitDetails>;
};

/** input type for inserting object relation for remote table "telehealth_visit_details" */
export type TelehealthVisitDetailsObjRelInsertInput = {
  data: TelehealthVisitDetailsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TelehealthVisitDetailsOnConflict>;
};

/** on_conflict condition type for table "telehealth_visit_details" */
export type TelehealthVisitDetailsOnConflict = {
  constraint: TelehealthVisitDetailsConstraint;
  updateColumns?: Array<TelehealthVisitDetailsUpdateColumn>;
  where?: InputMaybe<TelehealthVisitDetailsBoolExp>;
};

/** Ordering options when selecting data from "telehealth_visit_details". */
export type TelehealthVisitDetailsOrderBy = {
  created?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  videoLink?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitDetails?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
  visitType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: telehealth_visit_details */
export type TelehealthVisitDetailsPkColumnsInput = {
  /** Related telehealth visit */
  visitId: Scalars["bigint"]["input"];
};

/** select columns of table "telehealth_visit_details" */
export enum TelehealthVisitDetailsSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  Modified = "modified",
  /** column name */
  VideoLink = "videoLink",
  /** column name */
  VisitDetails = "visitDetails",
  /** column name */
  VisitId = "visitId",
  /** column name */
  VisitType = "visitType",
}

/** input type for updating data in table "telehealth_visit_details" */
export type TelehealthVisitDetailsSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLink?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetails?: InputMaybe<Scalars["String"]["input"]>;
  /** Related telehealth visit */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Stores telehealth visit type */
  visitType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type TelehealthVisitDetailsStddevFields = {
  __typename?: "TelehealthVisitDetailsStddevFields";
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type TelehealthVisitDetailsStddevPopFields = {
  __typename?: "TelehealthVisitDetailsStddevPopFields";
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type TelehealthVisitDetailsStddevSampFields = {
  __typename?: "TelehealthVisitDetailsStddevSampFields";
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "telehealth_visit_details" */
export type TelehealthVisitDetailsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TelehealthVisitDetailsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TelehealthVisitDetailsStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLink?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetails?: InputMaybe<Scalars["String"]["input"]>;
  /** Related telehealth visit */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Stores telehealth visit type */
  visitType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type TelehealthVisitDetailsSumFields = {
  __typename?: "TelehealthVisitDetailsSumFields";
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

export type TelehealthVisitDetailsType = {
  __typename?: "TelehealthVisitDetailsType";
  videoLink?: Maybe<Scalars["String"]["output"]>;
  visitDetails?: Maybe<Scalars["String"]["output"]>;
  visitType: VisitsTelehealthVisitDetailsVisitTypeChoices;
};

/** update columns of table "telehealth_visit_details" */
export enum TelehealthVisitDetailsUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  Modified = "modified",
  /** column name */
  VideoLink = "videoLink",
  /** column name */
  VisitDetails = "visitDetails",
  /** column name */
  VisitId = "visitId",
  /** column name */
  VisitType = "visitType",
}

export type TelehealthVisitDetailsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TelehealthVisitDetailsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TelehealthVisitDetailsSetInput>;
  /** filter the rows which have to be updated */
  where: TelehealthVisitDetailsBoolExp;
};

/** aggregate varPop on columns */
export type TelehealthVisitDetailsVarPopFields = {
  __typename?: "TelehealthVisitDetailsVarPopFields";
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type TelehealthVisitDetailsVarSampFields = {
  __typename?: "TelehealthVisitDetailsVarSampFields";
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type TelehealthVisitDetailsVarianceFields = {
  __typename?: "TelehealthVisitDetailsVarianceFields";
  /** Creator of the details. May be empty if created from outside the app */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Related telehealth visit */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** An enumeration. */
export enum TerminalType {
  Bbpos = "BBPOS",
  M2 = "M2",
  Tap2Pay = "TAP2PAY",
}

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars["time"]["input"]>;
  _gt?: InputMaybe<Scalars["time"]["input"]>;
  _gte?: InputMaybe<Scalars["time"]["input"]>;
  _in?: InputMaybe<Array<Scalars["time"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["time"]["input"]>;
  _lte?: InputMaybe<Scalars["time"]["input"]>;
  _neq?: InputMaybe<Scalars["time"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["time"]["input"]>>;
};

export type TimeSlotType = {
  __typename?: "TimeSlotType";
  end?: Maybe<Scalars["DateTime"]["output"]>;
  start?: Maybe<Scalars["DateTime"]["output"]>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
};

/** Boolean expression to compare columns of type "tstzrange". All fields are combined with logical 'AND'. */
export type TstzrangeComparisonExp = {
  _eq?: InputMaybe<Scalars["tstzrange"]["input"]>;
  _gt?: InputMaybe<Scalars["tstzrange"]["input"]>;
  _gte?: InputMaybe<Scalars["tstzrange"]["input"]>;
  _in?: InputMaybe<Array<Scalars["tstzrange"]["input"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["tstzrange"]["input"]>;
  _lte?: InputMaybe<Scalars["tstzrange"]["input"]>;
  _neq?: InputMaybe<Scalars["tstzrange"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["tstzrange"]["input"]>>;
};

export type UnreadHighlevelMessagesCountType = {
  __typename?: "UnreadHighlevelMessagesCountType";
  count?: Maybe<Scalars["Int"]["output"]>;
};

export type UpdateCherryApiKeyMutation = {
  __typename?: "UpdateCherryAPIKeyMutation";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UpdateDefaultCardForClient = {
  __typename?: "UpdateDefaultCardForClient";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UpdateHighLevelUnreadMessagesCount = {
  __typename?: "UpdateHighLevelUnreadMessagesCount";
  count?: Maybe<Scalars["Int"]["output"]>;
};

export type UpdateInvoiceStatus = {
  __typename?: "UpdateInvoiceStatus";
  invoice?: Maybe<InvoiceType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UpdateMdStripeOnboardingDone = {
  __typename?: "UpdateMDStripeOnboardingDone";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UpdateMedSpaClientCommunicationSettings = {
  __typename?: "UpdateMedSpaClientCommunicationSettings";
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UpdateStripeCard = {
  __typename?: "UpdateStripeCard";
  card?: Maybe<StripeIssuingCardType>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Table for storing permissions related to specific URL patterns within the system. These permissions are used to control access to specific pages. */
export type UrlPatternPermission = {
  __typename?: "UrlPatternPermission";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Unique codename for the url */
  urlCodename: Scalars["String"]["output"];
  /** URL pattern that the permission allow to access, represented by next.js file-system routing */
  urlPattern: Scalars["String"]["output"];
  /** An array relationship */
  userMedspas: Array<UserMedspaUrlPermissions>;
  /** An aggregate relationship */
  userMedspasAggregate: UserMedspaUrlPermissionsAggregate;
};

/** Table for storing permissions related to specific URL patterns within the system. These permissions are used to control access to specific pages. */
export type UrlPatternPermissionUserMedspasArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaUrlPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

/** Table for storing permissions related to specific URL patterns within the system. These permissions are used to control access to specific pages. */
export type UrlPatternPermissionUserMedspasAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaUrlPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

/** aggregated selection of "url_pattern_permission" */
export type UrlPatternPermissionAggregate = {
  __typename?: "UrlPatternPermissionAggregate";
  aggregate?: Maybe<UrlPatternPermissionAggregateFields>;
  nodes: Array<UrlPatternPermission>;
};

/** aggregate fields of "url_pattern_permission" */
export type UrlPatternPermissionAggregateFields = {
  __typename?: "UrlPatternPermissionAggregateFields";
  avg?: Maybe<UrlPatternPermissionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<UrlPatternPermissionMaxFields>;
  min?: Maybe<UrlPatternPermissionMinFields>;
  stddev?: Maybe<UrlPatternPermissionStddevFields>;
  stddevPop?: Maybe<UrlPatternPermissionStddevPopFields>;
  stddevSamp?: Maybe<UrlPatternPermissionStddevSampFields>;
  sum?: Maybe<UrlPatternPermissionSumFields>;
  varPop?: Maybe<UrlPatternPermissionVarPopFields>;
  varSamp?: Maybe<UrlPatternPermissionVarSampFields>;
  variance?: Maybe<UrlPatternPermissionVarianceFields>;
};

/** aggregate fields of "url_pattern_permission" */
export type UrlPatternPermissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UrlPatternPermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type UrlPatternPermissionAvgFields = {
  __typename?: "UrlPatternPermissionAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "url_pattern_permission". All fields are combined with a logical 'AND'. */
export type UrlPatternPermissionBoolExp = {
  _and?: InputMaybe<Array<UrlPatternPermissionBoolExp>>;
  _not?: InputMaybe<UrlPatternPermissionBoolExp>;
  _or?: InputMaybe<Array<UrlPatternPermissionBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  urlCodename?: InputMaybe<StringComparisonExp>;
  urlPattern?: InputMaybe<StringComparisonExp>;
  userMedspas?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
  userMedspasAggregate?: InputMaybe<UserMedspaUrlPermissionsAggregateBoolExp>;
};

/** unique or primary key constraints on table "url_pattern_permission" */
export enum UrlPatternPermissionConstraint {
  /** unique or primary key constraint on columns "id" */
  UrlPatternPermissionPkey = "url_pattern_permission_pkey",
  /** unique or primary key constraint on columns "url_pattern", "url_codename" */
  UrlPatternPermissionUrlCodenameUrlPatternEed5d154Uniq = "url_pattern_permission_url_codename_url_pattern_eed5d154_uniq",
}

/** input type for incrementing numeric columns in table "url_pattern_permission" */
export type UrlPatternPermissionIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "url_pattern_permission" */
export type UrlPatternPermissionInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Unique codename for the url */
  urlCodename?: InputMaybe<Scalars["String"]["input"]>;
  /** URL pattern that the permission allow to access, represented by next.js file-system routing */
  urlPattern?: InputMaybe<Scalars["String"]["input"]>;
  userMedspas?: InputMaybe<UserMedspaUrlPermissionsArrRelInsertInput>;
};

/** aggregate max on columns */
export type UrlPatternPermissionMaxFields = {
  __typename?: "UrlPatternPermissionMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Unique codename for the url */
  urlCodename?: Maybe<Scalars["String"]["output"]>;
  /** URL pattern that the permission allow to access, represented by next.js file-system routing */
  urlPattern?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type UrlPatternPermissionMinFields = {
  __typename?: "UrlPatternPermissionMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Unique codename for the url */
  urlCodename?: Maybe<Scalars["String"]["output"]>;
  /** URL pattern that the permission allow to access, represented by next.js file-system routing */
  urlPattern?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "url_pattern_permission" */
export type UrlPatternPermissionMutationResponse = {
  __typename?: "UrlPatternPermissionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<UrlPatternPermission>;
};

/** input type for inserting object relation for remote table "url_pattern_permission" */
export type UrlPatternPermissionObjRelInsertInput = {
  data: UrlPatternPermissionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UrlPatternPermissionOnConflict>;
};

/** on_conflict condition type for table "url_pattern_permission" */
export type UrlPatternPermissionOnConflict = {
  constraint: UrlPatternPermissionConstraint;
  updateColumns?: Array<UrlPatternPermissionUpdateColumn>;
  where?: InputMaybe<UrlPatternPermissionBoolExp>;
};

/** Ordering options when selecting data from "url_pattern_permission". */
export type UrlPatternPermissionOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  urlCodename?: InputMaybe<OrderBy>;
  urlPattern?: InputMaybe<OrderBy>;
  userMedspasAggregate?: InputMaybe<UserMedspaUrlPermissionsAggregateOrderBy>;
};

/** primary key columns input for table: url_pattern_permission */
export type UrlPatternPermissionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "url_pattern_permission" */
export enum UrlPatternPermissionSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  UrlCodename = "urlCodename",
  /** column name */
  UrlPattern = "urlPattern",
}

/** input type for updating data in table "url_pattern_permission" */
export type UrlPatternPermissionSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Unique codename for the url */
  urlCodename?: InputMaybe<Scalars["String"]["input"]>;
  /** URL pattern that the permission allow to access, represented by next.js file-system routing */
  urlPattern?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type UrlPatternPermissionStddevFields = {
  __typename?: "UrlPatternPermissionStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type UrlPatternPermissionStddevPopFields = {
  __typename?: "UrlPatternPermissionStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type UrlPatternPermissionStddevSampFields = {
  __typename?: "UrlPatternPermissionStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "url_pattern_permission" */
export type UrlPatternPermissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UrlPatternPermissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UrlPatternPermissionStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Unique codename for the url */
  urlCodename?: InputMaybe<Scalars["String"]["input"]>;
  /** URL pattern that the permission allow to access, represented by next.js file-system routing */
  urlPattern?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type UrlPatternPermissionSumFields = {
  __typename?: "UrlPatternPermissionSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "url_pattern_permission" */
export enum UrlPatternPermissionUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  UrlCodename = "urlCodename",
  /** column name */
  UrlPattern = "urlPattern",
}

export type UrlPatternPermissionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UrlPatternPermissionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UrlPatternPermissionSetInput>;
  /** filter the rows which have to be updated */
  where: UrlPatternPermissionBoolExp;
};

/** aggregate varPop on columns */
export type UrlPatternPermissionVarPopFields = {
  __typename?: "UrlPatternPermissionVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type UrlPatternPermissionVarSampFields = {
  __typename?: "UrlPatternPermissionVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type UrlPatternPermissionVarianceFields = {
  __typename?: "UrlPatternPermissionVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type UserMedSpaProviderType = {
  __typename?: "UserMedSpaProviderType";
  id: Scalars["ID"]["output"];
  /** Setting this to Yes/No will override permissions granted by acquired licenses */
  isEligibleForSelfGfe?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserMedSpaType = {
  __typename?: "UserMedSpaType";
  id: Scalars["ID"]["output"];
  role: MedspasUserMedSpaRoleChoices;
  user?: Maybe<UserType>;
};

/** columns and relationships of "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissions = {
  __typename?: "UserMedspaFeaturePermissions";
  codename?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  featurePermission?: Maybe<FeaturePermission>;
  featurePermissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  userMedspas?: Maybe<UserMedspa>;
};

/** aggregated selection of "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsAggregate = {
  __typename?: "UserMedspaFeaturePermissionsAggregate";
  aggregate?: Maybe<UserMedspaFeaturePermissionsAggregateFields>;
  nodes: Array<UserMedspaFeaturePermissions>;
};

export type UserMedspaFeaturePermissionsAggregateBoolExp = {
  count?: InputMaybe<UserMedspaFeaturePermissionsAggregateBoolExpCount>;
};

/** aggregate fields of "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsAggregateFields = {
  __typename?: "UserMedspaFeaturePermissionsAggregateFields";
  avg?: Maybe<UserMedspaFeaturePermissionsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<UserMedspaFeaturePermissionsMaxFields>;
  min?: Maybe<UserMedspaFeaturePermissionsMinFields>;
  stddev?: Maybe<UserMedspaFeaturePermissionsStddevFields>;
  stddevPop?: Maybe<UserMedspaFeaturePermissionsStddevPopFields>;
  stddevSamp?: Maybe<UserMedspaFeaturePermissionsStddevSampFields>;
  sum?: Maybe<UserMedspaFeaturePermissionsSumFields>;
  varPop?: Maybe<UserMedspaFeaturePermissionsVarPopFields>;
  varSamp?: Maybe<UserMedspaFeaturePermissionsVarSampFields>;
  variance?: Maybe<UserMedspaFeaturePermissionsVarianceFields>;
};

/** aggregate fields of "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserMedspaFeaturePermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsAggregateOrderBy = {
  avg?: InputMaybe<UserMedspaFeaturePermissionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserMedspaFeaturePermissionsMaxOrderBy>;
  min?: InputMaybe<UserMedspaFeaturePermissionsMinOrderBy>;
  stddev?: InputMaybe<UserMedspaFeaturePermissionsStddevOrderBy>;
  stddevPop?: InputMaybe<UserMedspaFeaturePermissionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<UserMedspaFeaturePermissionsStddevSampOrderBy>;
  sum?: InputMaybe<UserMedspaFeaturePermissionsSumOrderBy>;
  varPop?: InputMaybe<UserMedspaFeaturePermissionsVarPopOrderBy>;
  varSamp?: InputMaybe<UserMedspaFeaturePermissionsVarSampOrderBy>;
  variance?: InputMaybe<UserMedspaFeaturePermissionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsArrRelInsertInput = {
  data: Array<UserMedspaFeaturePermissionsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<UserMedspaFeaturePermissionsOnConflict>;
};

/** aggregate avg on columns */
export type UserMedspaFeaturePermissionsAvgFields = {
  __typename?: "UserMedspaFeaturePermissionsAvgFields";
  featurePermissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsAvgOrderBy = {
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user_medspa_feature_permissions". All fields are combined with a logical 'AND'. */
export type UserMedspaFeaturePermissionsBoolExp = {
  _and?: InputMaybe<Array<UserMedspaFeaturePermissionsBoolExp>>;
  _not?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
  _or?: InputMaybe<Array<UserMedspaFeaturePermissionsBoolExp>>;
  codename?: InputMaybe<StringComparisonExp>;
  featurePermission?: InputMaybe<FeaturePermissionBoolExp>;
  featurePermissionId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  userMedspaId?: InputMaybe<BigintComparisonExp>;
  userMedspas?: InputMaybe<UserMedspaBoolExp>;
};

/** unique or primary key constraints on table "user_medspa_feature_permissions" */
export enum UserMedspaFeaturePermissionsConstraint {
  /** unique or primary key constraint on columns "user_medspa_id", "feature_permission_id" */
  IdxUserMedspaFeaturePermissionsUnique = "idx_user_medspa_feature_permissions_unique",
}

/** input type for inserting data into table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsInsertInput = {
  codename?: InputMaybe<Scalars["String"]["input"]>;
  featurePermission?: InputMaybe<FeaturePermissionObjRelInsertInput>;
  featurePermissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  userMedspas?: InputMaybe<UserMedspaObjRelInsertInput>;
};

/** aggregate max on columns */
export type UserMedspaFeaturePermissionsMaxFields = {
  __typename?: "UserMedspaFeaturePermissionsMaxFields";
  codename?: Maybe<Scalars["String"]["output"]>;
  featurePermissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsMaxOrderBy = {
  codename?: InputMaybe<OrderBy>;
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMedspaFeaturePermissionsMinFields = {
  __typename?: "UserMedspaFeaturePermissionsMinFields";
  codename?: Maybe<Scalars["String"]["output"]>;
  featurePermissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsMinOrderBy = {
  codename?: InputMaybe<OrderBy>;
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** on_conflict condition type for table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsOnConflict = {
  constraint: UserMedspaFeaturePermissionsConstraint;
  updateColumns?: Array<UserMedspaFeaturePermissionsUpdateColumn>;
  where?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
};

/** Ordering options when selecting data from "user_medspa_feature_permissions". */
export type UserMedspaFeaturePermissionsOrderBy = {
  codename?: InputMaybe<OrderBy>;
  featurePermission?: InputMaybe<FeaturePermissionOrderBy>;
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
  userMedspas?: InputMaybe<UserMedspaOrderBy>;
};

/** select columns of table "user_medspa_feature_permissions" */
export enum UserMedspaFeaturePermissionsSelectColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  FeaturePermissionId = "featurePermissionId",
  /** column name */
  Id = "id",
  /** column name */
  UserMedspaId = "userMedspaId",
}

/** aggregate stddev on columns */
export type UserMedspaFeaturePermissionsStddevFields = {
  __typename?: "UserMedspaFeaturePermissionsStddevFields";
  featurePermissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsStddevOrderBy = {
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type UserMedspaFeaturePermissionsStddevPopFields = {
  __typename?: "UserMedspaFeaturePermissionsStddevPopFields";
  featurePermissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsStddevPopOrderBy = {
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type UserMedspaFeaturePermissionsStddevSampFields = {
  __typename?: "UserMedspaFeaturePermissionsStddevSampFields";
  featurePermissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsStddevSampOrderBy = {
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserMedspaFeaturePermissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserMedspaFeaturePermissionsStreamCursorValueInput = {
  codename?: InputMaybe<Scalars["String"]["input"]>;
  featurePermissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type UserMedspaFeaturePermissionsSumFields = {
  __typename?: "UserMedspaFeaturePermissionsSumFields";
  featurePermissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsSumOrderBy = {
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "user_medspa_feature_permissions" */
export enum UserMedspaFeaturePermissionsUpdateColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  FeaturePermissionId = "featurePermissionId",
  /** column name */
  Id = "id",
  /** column name */
  UserMedspaId = "userMedspaId",
}

/** aggregate varPop on columns */
export type UserMedspaFeaturePermissionsVarPopFields = {
  __typename?: "UserMedspaFeaturePermissionsVarPopFields";
  featurePermissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsVarPopOrderBy = {
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type UserMedspaFeaturePermissionsVarSampFields = {
  __typename?: "UserMedspaFeaturePermissionsVarSampFields";
  featurePermissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsVarSampOrderBy = {
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserMedspaFeaturePermissionsVarianceFields = {
  __typename?: "UserMedspaFeaturePermissionsVarianceFields";
  featurePermissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "user_medspa_feature_permissions" */
export type UserMedspaFeaturePermissionsVarianceOrderBy = {
  featurePermissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "user_medspa_model_permissions" */
export type UserMedspaModelPermissions = {
  __typename?: "UserMedspaModelPermissions";
  codename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  modelPermission?: Maybe<ModelPermission>;
  modelPermissionId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  userMedspa?: Maybe<UserMedspa>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsAggregate = {
  __typename?: "UserMedspaModelPermissionsAggregate";
  aggregate?: Maybe<UserMedspaModelPermissionsAggregateFields>;
  nodes: Array<UserMedspaModelPermissions>;
};

export type UserMedspaModelPermissionsAggregateBoolExp = {
  count?: InputMaybe<UserMedspaModelPermissionsAggregateBoolExpCount>;
};

/** aggregate fields of "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsAggregateFields = {
  __typename?: "UserMedspaModelPermissionsAggregateFields";
  avg?: Maybe<UserMedspaModelPermissionsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<UserMedspaModelPermissionsMaxFields>;
  min?: Maybe<UserMedspaModelPermissionsMinFields>;
  stddev?: Maybe<UserMedspaModelPermissionsStddevFields>;
  stddevPop?: Maybe<UserMedspaModelPermissionsStddevPopFields>;
  stddevSamp?: Maybe<UserMedspaModelPermissionsStddevSampFields>;
  sum?: Maybe<UserMedspaModelPermissionsSumFields>;
  varPop?: Maybe<UserMedspaModelPermissionsVarPopFields>;
  varSamp?: Maybe<UserMedspaModelPermissionsVarSampFields>;
  variance?: Maybe<UserMedspaModelPermissionsVarianceFields>;
};

/** aggregate fields of "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsAggregateOrderBy = {
  avg?: InputMaybe<UserMedspaModelPermissionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserMedspaModelPermissionsMaxOrderBy>;
  min?: InputMaybe<UserMedspaModelPermissionsMinOrderBy>;
  stddev?: InputMaybe<UserMedspaModelPermissionsStddevOrderBy>;
  stddevPop?: InputMaybe<UserMedspaModelPermissionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<UserMedspaModelPermissionsStddevSampOrderBy>;
  sum?: InputMaybe<UserMedspaModelPermissionsSumOrderBy>;
  varPop?: InputMaybe<UserMedspaModelPermissionsVarPopOrderBy>;
  varSamp?: InputMaybe<UserMedspaModelPermissionsVarSampOrderBy>;
  variance?: InputMaybe<UserMedspaModelPermissionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsArrRelInsertInput = {
  data: Array<UserMedspaModelPermissionsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<UserMedspaModelPermissionsOnConflict>;
};

/** aggregate avg on columns */
export type UserMedspaModelPermissionsAvgFields = {
  __typename?: "UserMedspaModelPermissionsAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  modelPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user_medspa_model_permissions". All fields are combined with a logical 'AND'. */
export type UserMedspaModelPermissionsBoolExp = {
  _and?: InputMaybe<Array<UserMedspaModelPermissionsBoolExp>>;
  _not?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
  _or?: InputMaybe<Array<UserMedspaModelPermissionsBoolExp>>;
  codename?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modelPermission?: InputMaybe<ModelPermissionBoolExp>;
  modelPermissionId?: InputMaybe<BigintComparisonExp>;
  userMedspa?: InputMaybe<UserMedspaBoolExp>;
  userMedspaId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "user_medspa_model_permissions" */
export enum UserMedspaModelPermissionsConstraint {
  /** unique or primary key constraint on columns "model_permission_id", "user_medspa_id" */
  IdxUserMedspaModelPermissionsUnique = "idx_user_medspa_model_permissions_unique",
}

/** input type for inserting data into table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsInsertInput = {
  codename?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modelPermission?: InputMaybe<ModelPermissionObjRelInsertInput>;
  modelPermissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  userMedspa?: InputMaybe<UserMedspaObjRelInsertInput>;
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type UserMedspaModelPermissionsMaxFields = {
  __typename?: "UserMedspaModelPermissionsMaxFields";
  codename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modelPermissionId?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsMaxOrderBy = {
  codename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMedspaModelPermissionsMinFields = {
  __typename?: "UserMedspaModelPermissionsMinFields";
  codename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modelPermissionId?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsMinOrderBy = {
  codename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** on_conflict condition type for table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsOnConflict = {
  constraint: UserMedspaModelPermissionsConstraint;
  updateColumns?: Array<UserMedspaModelPermissionsUpdateColumn>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

/** Ordering options when selecting data from "user_medspa_model_permissions". */
export type UserMedspaModelPermissionsOrderBy = {
  codename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modelPermission?: InputMaybe<ModelPermissionOrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspa?: InputMaybe<UserMedspaOrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** select columns of table "user_medspa_model_permissions" */
export enum UserMedspaModelPermissionsSelectColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  Id = "id",
  /** column name */
  ModelPermissionId = "modelPermissionId",
  /** column name */
  UserMedspaId = "userMedspaId",
}

/** aggregate stddev on columns */
export type UserMedspaModelPermissionsStddevFields = {
  __typename?: "UserMedspaModelPermissionsStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  modelPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type UserMedspaModelPermissionsStddevPopFields = {
  __typename?: "UserMedspaModelPermissionsStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  modelPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type UserMedspaModelPermissionsStddevSampFields = {
  __typename?: "UserMedspaModelPermissionsStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  modelPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserMedspaModelPermissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserMedspaModelPermissionsStreamCursorValueInput = {
  codename?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modelPermissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type UserMedspaModelPermissionsSumFields = {
  __typename?: "UserMedspaModelPermissionsSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  modelPermissionId?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "user_medspa_model_permissions" */
export enum UserMedspaModelPermissionsUpdateColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  Id = "id",
  /** column name */
  ModelPermissionId = "modelPermissionId",
  /** column name */
  UserMedspaId = "userMedspaId",
}

/** aggregate varPop on columns */
export type UserMedspaModelPermissionsVarPopFields = {
  __typename?: "UserMedspaModelPermissionsVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  modelPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type UserMedspaModelPermissionsVarSampFields = {
  __typename?: "UserMedspaModelPermissionsVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  modelPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserMedspaModelPermissionsVarianceFields = {
  __typename?: "UserMedspaModelPermissionsVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  modelPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "user_medspa_model_permissions" */
export type UserMedspaModelPermissionsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  modelPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "user_medspa_url_permissions" */
export type UserMedspaUrlPermissions = {
  __typename?: "UserMedspaUrlPermissions";
  codename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  urlPattern?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  urlPermission?: Maybe<UrlPatternPermission>;
  urlPermissionId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  userMedspa?: Maybe<UserMedspa>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsAggregate = {
  __typename?: "UserMedspaUrlPermissionsAggregate";
  aggregate?: Maybe<UserMedspaUrlPermissionsAggregateFields>;
  nodes: Array<UserMedspaUrlPermissions>;
};

export type UserMedspaUrlPermissionsAggregateBoolExp = {
  count?: InputMaybe<UserMedspaUrlPermissionsAggregateBoolExpCount>;
};

/** aggregate fields of "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsAggregateFields = {
  __typename?: "UserMedspaUrlPermissionsAggregateFields";
  avg?: Maybe<UserMedspaUrlPermissionsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<UserMedspaUrlPermissionsMaxFields>;
  min?: Maybe<UserMedspaUrlPermissionsMinFields>;
  stddev?: Maybe<UserMedspaUrlPermissionsStddevFields>;
  stddevPop?: Maybe<UserMedspaUrlPermissionsStddevPopFields>;
  stddevSamp?: Maybe<UserMedspaUrlPermissionsStddevSampFields>;
  sum?: Maybe<UserMedspaUrlPermissionsSumFields>;
  varPop?: Maybe<UserMedspaUrlPermissionsVarPopFields>;
  varSamp?: Maybe<UserMedspaUrlPermissionsVarSampFields>;
  variance?: Maybe<UserMedspaUrlPermissionsVarianceFields>;
};

/** aggregate fields of "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsAggregateOrderBy = {
  avg?: InputMaybe<UserMedspaUrlPermissionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserMedspaUrlPermissionsMaxOrderBy>;
  min?: InputMaybe<UserMedspaUrlPermissionsMinOrderBy>;
  stddev?: InputMaybe<UserMedspaUrlPermissionsStddevOrderBy>;
  stddevPop?: InputMaybe<UserMedspaUrlPermissionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<UserMedspaUrlPermissionsStddevSampOrderBy>;
  sum?: InputMaybe<UserMedspaUrlPermissionsSumOrderBy>;
  varPop?: InputMaybe<UserMedspaUrlPermissionsVarPopOrderBy>;
  varSamp?: InputMaybe<UserMedspaUrlPermissionsVarSampOrderBy>;
  variance?: InputMaybe<UserMedspaUrlPermissionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsArrRelInsertInput = {
  data: Array<UserMedspaUrlPermissionsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<UserMedspaUrlPermissionsOnConflict>;
};

/** aggregate avg on columns */
export type UserMedspaUrlPermissionsAvgFields = {
  __typename?: "UserMedspaUrlPermissionsAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  urlPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user_medspa_url_permissions". All fields are combined with a logical 'AND'. */
export type UserMedspaUrlPermissionsBoolExp = {
  _and?: InputMaybe<Array<UserMedspaUrlPermissionsBoolExp>>;
  _not?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
  _or?: InputMaybe<Array<UserMedspaUrlPermissionsBoolExp>>;
  codename?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  urlPattern?: InputMaybe<StringComparisonExp>;
  urlPermission?: InputMaybe<UrlPatternPermissionBoolExp>;
  urlPermissionId?: InputMaybe<BigintComparisonExp>;
  userMedspa?: InputMaybe<UserMedspaBoolExp>;
  userMedspaId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "user_medspa_url_permissions" */
export enum UserMedspaUrlPermissionsConstraint {
  /** unique or primary key constraint on columns "user_medspa_id", "url_permission_id" */
  IdxUserMedspaUrlPermissionsUnique = "idx_user_medspa_url_permissions_unique",
}

/** input type for inserting data into table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsInsertInput = {
  codename?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  urlPattern?: InputMaybe<Scalars["String"]["input"]>;
  urlPermission?: InputMaybe<UrlPatternPermissionObjRelInsertInput>;
  urlPermissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  userMedspa?: InputMaybe<UserMedspaObjRelInsertInput>;
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type UserMedspaUrlPermissionsMaxFields = {
  __typename?: "UserMedspaUrlPermissionsMaxFields";
  codename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  urlPattern?: Maybe<Scalars["String"]["output"]>;
  urlPermissionId?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsMaxOrderBy = {
  codename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  urlPattern?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMedspaUrlPermissionsMinFields = {
  __typename?: "UserMedspaUrlPermissionsMinFields";
  codename?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  urlPattern?: Maybe<Scalars["String"]["output"]>;
  urlPermissionId?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsMinOrderBy = {
  codename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  urlPattern?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** on_conflict condition type for table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsOnConflict = {
  constraint: UserMedspaUrlPermissionsConstraint;
  updateColumns?: Array<UserMedspaUrlPermissionsUpdateColumn>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

/** Ordering options when selecting data from "user_medspa_url_permissions". */
export type UserMedspaUrlPermissionsOrderBy = {
  codename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  urlPattern?: InputMaybe<OrderBy>;
  urlPermission?: InputMaybe<UrlPatternPermissionOrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspa?: InputMaybe<UserMedspaOrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** select columns of table "user_medspa_url_permissions" */
export enum UserMedspaUrlPermissionsSelectColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  Id = "id",
  /** column name */
  UrlPattern = "urlPattern",
  /** column name */
  UrlPermissionId = "urlPermissionId",
  /** column name */
  UserMedspaId = "userMedspaId",
}

/** aggregate stddev on columns */
export type UserMedspaUrlPermissionsStddevFields = {
  __typename?: "UserMedspaUrlPermissionsStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  urlPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type UserMedspaUrlPermissionsStddevPopFields = {
  __typename?: "UserMedspaUrlPermissionsStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  urlPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type UserMedspaUrlPermissionsStddevSampFields = {
  __typename?: "UserMedspaUrlPermissionsStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  urlPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserMedspaUrlPermissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserMedspaUrlPermissionsStreamCursorValueInput = {
  codename?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  urlPattern?: InputMaybe<Scalars["String"]["input"]>;
  urlPermissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type UserMedspaUrlPermissionsSumFields = {
  __typename?: "UserMedspaUrlPermissionsSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  urlPermissionId?: Maybe<Scalars["bigint"]["output"]>;
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "user_medspa_url_permissions" */
export enum UserMedspaUrlPermissionsUpdateColumn {
  /** column name */
  Codename = "codename",
  /** column name */
  Id = "id",
  /** column name */
  UrlPattern = "urlPattern",
  /** column name */
  UrlPermissionId = "urlPermissionId",
  /** column name */
  UserMedspaId = "userMedspaId",
}

/** aggregate varPop on columns */
export type UserMedspaUrlPermissionsVarPopFields = {
  __typename?: "UserMedspaUrlPermissionsVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  urlPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type UserMedspaUrlPermissionsVarSampFields = {
  __typename?: "UserMedspaUrlPermissionsVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  urlPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserMedspaUrlPermissionsVarianceFields = {
  __typename?: "UserMedspaUrlPermissionsVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  urlPermissionId?: Maybe<Scalars["Float"]["output"]>;
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "user_medspa_url_permissions" */
export type UserMedspaUrlPermissionsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  urlPermissionId?: InputMaybe<OrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
};

export type UserType = {
  __typename?: "UserType";
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
};

export type VerifyClient2FaCode = {
  __typename?: "VerifyClient2FACode";
  clientData?: Maybe<ClientData>;
  message?: Maybe<Scalars["String"]["output"]>;
  ok?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum VisitReviewStatusEnum {
  Complete = "COMPLETE",
  Reviewed = "REVIEWED",
}

export type VisitToReviewType = {
  __typename?: "VisitToReviewType";
  id: Scalars["ID"]["output"];
  visit?: Maybe<Visit>;
};

export type VisitType = {
  __typename?: "VisitType";
  id: Scalars["ID"]["output"];
  photos?: Maybe<Array<Maybe<PhotoType>>>;
};

export type VisitTypePhotosArgs = {
  excludeDiagrams?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** columns and relationships of "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelog = {
  __typename?: "VisitsAdversereactionchangelog";
  /** An object relationship */
  adverseReaction: AdverseReaction;
  adverseReactionId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  newContent: Scalars["jsonb"]["output"];
  oldContent: Scalars["jsonb"]["output"];
  /** An object relationship */
  provider: UserMedspa;
  providerId: Scalars["bigint"]["output"];
};

/** columns and relationships of "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogNewContentArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogOldContentArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogAggregate = {
  __typename?: "VisitsAdversereactionchangelogAggregate";
  aggregate?: Maybe<VisitsAdversereactionchangelogAggregateFields>;
  nodes: Array<VisitsAdversereactionchangelog>;
};

export type VisitsAdversereactionchangelogAggregateBoolExp = {
  count?: InputMaybe<VisitsAdversereactionchangelogAggregateBoolExpCount>;
};

/** aggregate fields of "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogAggregateFields = {
  __typename?: "VisitsAdversereactionchangelogAggregateFields";
  avg?: Maybe<VisitsAdversereactionchangelogAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<VisitsAdversereactionchangelogMaxFields>;
  min?: Maybe<VisitsAdversereactionchangelogMinFields>;
  stddev?: Maybe<VisitsAdversereactionchangelogStddevFields>;
  stddevPop?: Maybe<VisitsAdversereactionchangelogStddevPopFields>;
  stddevSamp?: Maybe<VisitsAdversereactionchangelogStddevSampFields>;
  sum?: Maybe<VisitsAdversereactionchangelogSumFields>;
  varPop?: Maybe<VisitsAdversereactionchangelogVarPopFields>;
  varSamp?: Maybe<VisitsAdversereactionchangelogVarSampFields>;
  variance?: Maybe<VisitsAdversereactionchangelogVarianceFields>;
};

/** aggregate fields of "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitsAdversereactionchangelogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogAggregateOrderBy = {
  avg?: InputMaybe<VisitsAdversereactionchangelogAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VisitsAdversereactionchangelogMaxOrderBy>;
  min?: InputMaybe<VisitsAdversereactionchangelogMinOrderBy>;
  stddev?: InputMaybe<VisitsAdversereactionchangelogStddevOrderBy>;
  stddevPop?: InputMaybe<VisitsAdversereactionchangelogStddevPopOrderBy>;
  stddevSamp?: InputMaybe<VisitsAdversereactionchangelogStddevSampOrderBy>;
  sum?: InputMaybe<VisitsAdversereactionchangelogSumOrderBy>;
  varPop?: InputMaybe<VisitsAdversereactionchangelogVarPopOrderBy>;
  varSamp?: InputMaybe<VisitsAdversereactionchangelogVarSampOrderBy>;
  variance?: InputMaybe<VisitsAdversereactionchangelogVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type VisitsAdversereactionchangelogAppendInput = {
  newContent?: InputMaybe<Scalars["jsonb"]["input"]>;
  oldContent?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogArrRelInsertInput = {
  data: Array<VisitsAdversereactionchangelogInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VisitsAdversereactionchangelogOnConflict>;
};

/** aggregate avg on columns */
export type VisitsAdversereactionchangelogAvgFields = {
  __typename?: "VisitsAdversereactionchangelogAvgFields";
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogAvgOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_adversereactionchangelog". All fields are combined with a logical 'AND'. */
export type VisitsAdversereactionchangelogBoolExp = {
  _and?: InputMaybe<Array<VisitsAdversereactionchangelogBoolExp>>;
  _not?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
  _or?: InputMaybe<Array<VisitsAdversereactionchangelogBoolExp>>;
  adverseReaction?: InputMaybe<AdverseReactionBoolExp>;
  adverseReactionId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  newContent?: InputMaybe<JsonbComparisonExp>;
  oldContent?: InputMaybe<JsonbComparisonExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_adversereactionchangelog" */
export enum VisitsAdversereactionchangelogConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsAdversereactionchangelogPkey = "visits_adversereactionchangelog_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type VisitsAdversereactionchangelogDeleteAtPathInput = {
  newContent?: InputMaybe<Array<Scalars["String"]["input"]>>;
  oldContent?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type VisitsAdversereactionchangelogDeleteElemInput = {
  newContent?: InputMaybe<Scalars["Int"]["input"]>;
  oldContent?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type VisitsAdversereactionchangelogDeleteKeyInput = {
  newContent?: InputMaybe<Scalars["String"]["input"]>;
  oldContent?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogIncInput = {
  adverseReactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogInsertInput = {
  adverseReaction?: InputMaybe<AdverseReactionObjRelInsertInput>;
  adverseReactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newContent?: InputMaybe<Scalars["jsonb"]["input"]>;
  oldContent?: InputMaybe<Scalars["jsonb"]["input"]>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type VisitsAdversereactionchangelogMaxFields = {
  __typename?: "VisitsAdversereactionchangelogMaxFields";
  adverseReactionId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogMaxOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type VisitsAdversereactionchangelogMinFields = {
  __typename?: "VisitsAdversereactionchangelogMinFields";
  adverseReactionId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogMinOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogMutationResponse = {
  __typename?: "VisitsAdversereactionchangelogMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<VisitsAdversereactionchangelog>;
};

/** on_conflict condition type for table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogOnConflict = {
  constraint: VisitsAdversereactionchangelogConstraint;
  updateColumns?: Array<VisitsAdversereactionchangelogUpdateColumn>;
  where?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
};

/** Ordering options when selecting data from "visits_adversereactionchangelog". */
export type VisitsAdversereactionchangelogOrderBy = {
  adverseReaction?: InputMaybe<AdverseReactionOrderBy>;
  adverseReactionId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newContent?: InputMaybe<OrderBy>;
  oldContent?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_adversereactionchangelog */
export type VisitsAdversereactionchangelogPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type VisitsAdversereactionchangelogPrependInput = {
  newContent?: InputMaybe<Scalars["jsonb"]["input"]>;
  oldContent?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "visits_adversereactionchangelog" */
export enum VisitsAdversereactionchangelogSelectColumn {
  /** column name */
  AdverseReactionId = "adverseReactionId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewContent = "newContent",
  /** column name */
  OldContent = "oldContent",
  /** column name */
  ProviderId = "providerId",
}

/** input type for updating data in table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogSetInput = {
  adverseReactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newContent?: InputMaybe<Scalars["jsonb"]["input"]>;
  oldContent?: InputMaybe<Scalars["jsonb"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type VisitsAdversereactionchangelogStddevFields = {
  __typename?: "VisitsAdversereactionchangelogStddevFields";
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogStddevOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type VisitsAdversereactionchangelogStddevPopFields = {
  __typename?: "VisitsAdversereactionchangelogStddevPopFields";
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogStddevPopOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type VisitsAdversereactionchangelogStddevSampFields = {
  __typename?: "VisitsAdversereactionchangelogStddevSampFields";
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogStddevSampOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitsAdversereactionchangelogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitsAdversereactionchangelogStreamCursorValueInput = {
  adverseReactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newContent?: InputMaybe<Scalars["jsonb"]["input"]>;
  oldContent?: InputMaybe<Scalars["jsonb"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type VisitsAdversereactionchangelogSumFields = {
  __typename?: "VisitsAdversereactionchangelogSumFields";
  adverseReactionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogSumOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_adversereactionchangelog" */
export enum VisitsAdversereactionchangelogUpdateColumn {
  /** column name */
  AdverseReactionId = "adverseReactionId",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewContent = "newContent",
  /** column name */
  OldContent = "oldContent",
  /** column name */
  ProviderId = "providerId",
}

export type VisitsAdversereactionchangelogUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<VisitsAdversereactionchangelogAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<VisitsAdversereactionchangelogDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<VisitsAdversereactionchangelogDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<VisitsAdversereactionchangelogDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitsAdversereactionchangelogIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<VisitsAdversereactionchangelogPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitsAdversereactionchangelogSetInput>;
  /** filter the rows which have to be updated */
  where: VisitsAdversereactionchangelogBoolExp;
};

/** aggregate varPop on columns */
export type VisitsAdversereactionchangelogVarPopFields = {
  __typename?: "VisitsAdversereactionchangelogVarPopFields";
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogVarPopOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type VisitsAdversereactionchangelogVarSampFields = {
  __typename?: "VisitsAdversereactionchangelogVarSampFields";
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogVarSampOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type VisitsAdversereactionchangelogVarianceFields = {
  __typename?: "VisitsAdversereactionchangelogVarianceFields";
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_adversereactionchangelog" */
export type VisitsAdversereactionchangelogVarianceOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** This table stores signature (and additional information) for signing charts by provider.              When providers re-sends charts for additional review old signature should be soft deleted */
export type VisitsChartsignature = {
  __typename?: "VisitsChartsignature";
  additionalInfo?: Maybe<ChartSignatureType>;
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** Ip address of provider signing chart. */
  ipAddress?: Maybe<Scalars["inet"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  /** Medspa at which visits take place. */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Image of the signature. */
  signature?: Maybe<Scalars["String"]["output"]>;
  /** E.g. "I have treated the patient in accordance with the standing order(s)." */
  signatureAdditionalText?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp when charts were signed. */
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** User agent of provider signing chart. */
  userAgent?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  userMedspa?: Maybe<UserMedspa>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  visit: Visit;
  /** Visit related to charts providers are signing. */
  visitId: Scalars["bigint"]["output"];
};

/** aggregated selection of "visits_chartsignature" */
export type VisitsChartsignatureAggregate = {
  __typename?: "VisitsChartsignatureAggregate";
  aggregate?: Maybe<VisitsChartsignatureAggregateFields>;
  nodes: Array<VisitsChartsignature>;
};

export type VisitsChartsignatureAggregateBoolExp = {
  count?: InputMaybe<VisitsChartsignatureAggregateBoolExpCount>;
};

/** aggregate fields of "visits_chartsignature" */
export type VisitsChartsignatureAggregateFields = {
  __typename?: "VisitsChartsignatureAggregateFields";
  avg?: Maybe<VisitsChartsignatureAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<VisitsChartsignatureMaxFields>;
  min?: Maybe<VisitsChartsignatureMinFields>;
  stddev?: Maybe<VisitsChartsignatureStddevFields>;
  stddevPop?: Maybe<VisitsChartsignatureStddevPopFields>;
  stddevSamp?: Maybe<VisitsChartsignatureStddevSampFields>;
  sum?: Maybe<VisitsChartsignatureSumFields>;
  varPop?: Maybe<VisitsChartsignatureVarPopFields>;
  varSamp?: Maybe<VisitsChartsignatureVarSampFields>;
  variance?: Maybe<VisitsChartsignatureVarianceFields>;
};

/** aggregate fields of "visits_chartsignature" */
export type VisitsChartsignatureAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitsChartsignatureSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_chartsignature" */
export type VisitsChartsignatureAggregateOrderBy = {
  avg?: InputMaybe<VisitsChartsignatureAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VisitsChartsignatureMaxOrderBy>;
  min?: InputMaybe<VisitsChartsignatureMinOrderBy>;
  stddev?: InputMaybe<VisitsChartsignatureStddevOrderBy>;
  stddevPop?: InputMaybe<VisitsChartsignatureStddevPopOrderBy>;
  stddevSamp?: InputMaybe<VisitsChartsignatureStddevSampOrderBy>;
  sum?: InputMaybe<VisitsChartsignatureSumOrderBy>;
  varPop?: InputMaybe<VisitsChartsignatureVarPopOrderBy>;
  varSamp?: InputMaybe<VisitsChartsignatureVarSampOrderBy>;
  variance?: InputMaybe<VisitsChartsignatureVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_chartsignature" */
export type VisitsChartsignatureArrRelInsertInput = {
  data: Array<VisitsChartsignatureInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VisitsChartsignatureOnConflict>;
};

/** aggregate avg on columns */
export type VisitsChartsignatureAvgFields = {
  __typename?: "VisitsChartsignatureAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_chartsignature". All fields are combined with a logical 'AND'. */
export type VisitsChartsignatureBoolExp = {
  _and?: InputMaybe<Array<VisitsChartsignatureBoolExp>>;
  _not?: InputMaybe<VisitsChartsignatureBoolExp>;
  _or?: InputMaybe<Array<VisitsChartsignatureBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  ipAddress?: InputMaybe<InetComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  signature?: InputMaybe<StringComparisonExp>;
  signatureAdditionalText?: InputMaybe<StringComparisonExp>;
  signedAt?: InputMaybe<TimestamptzComparisonExp>;
  userAgent?: InputMaybe<StringComparisonExp>;
  userMedspa?: InputMaybe<UserMedspaBoolExp>;
  userMedspaId?: InputMaybe<BigintComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_chartsignature" */
export enum VisitsChartsignatureConstraint {
  /** unique or primary key constraint on columns "user_medspa_id", "visit_id" */
  UniqueChartSignaturePerUserMedspaVisit = "unique_chart_signature_per_user_medspa_visit",
  /** unique or primary key constraint on columns "id" */
  VisitsChartsignaturePkey = "visits_chartsignature_pkey",
}

/** input type for incrementing numeric columns in table "visits_chartsignature" */
export type VisitsChartsignatureIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_chartsignature" */
export type VisitsChartsignatureInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Ip address of provider signing chart. */
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Image of the signature. */
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** E.g. "I have treated the patient in accordance with the standing order(s)." */
  signatureAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when charts were signed. */
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** User agent of provider signing chart. */
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  userMedspa?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type VisitsChartsignatureMaxFields = {
  __typename?: "VisitsChartsignatureMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Image of the signature. */
  signature?: Maybe<Scalars["String"]["output"]>;
  /** E.g. "I have treated the patient in accordance with the standing order(s)." */
  signatureAdditionalText?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp when charts were signed. */
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** User agent of provider signing chart. */
  userAgent?: Maybe<Scalars["String"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Image of the signature. */
  signature?: InputMaybe<OrderBy>;
  /** E.g. "I have treated the patient in accordance with the standing order(s)." */
  signatureAdditionalText?: InputMaybe<OrderBy>;
  /** Timestamp when charts were signed. */
  signedAt?: InputMaybe<OrderBy>;
  /** User agent of provider signing chart. */
  userAgent?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type VisitsChartsignatureMinFields = {
  __typename?: "VisitsChartsignatureMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Image of the signature. */
  signature?: Maybe<Scalars["String"]["output"]>;
  /** E.g. "I have treated the patient in accordance with the standing order(s)." */
  signatureAdditionalText?: Maybe<Scalars["String"]["output"]>;
  /** Timestamp when charts were signed. */
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** User agent of provider signing chart. */
  userAgent?: Maybe<Scalars["String"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Image of the signature. */
  signature?: InputMaybe<OrderBy>;
  /** E.g. "I have treated the patient in accordance with the standing order(s)." */
  signatureAdditionalText?: InputMaybe<OrderBy>;
  /** Timestamp when charts were signed. */
  signedAt?: InputMaybe<OrderBy>;
  /** User agent of provider signing chart. */
  userAgent?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_chartsignature" */
export type VisitsChartsignatureMutationResponse = {
  __typename?: "VisitsChartsignatureMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<VisitsChartsignature>;
};

/** on_conflict condition type for table "visits_chartsignature" */
export type VisitsChartsignatureOnConflict = {
  constraint: VisitsChartsignatureConstraint;
  updateColumns?: Array<VisitsChartsignatureUpdateColumn>;
  where?: InputMaybe<VisitsChartsignatureBoolExp>;
};

/** Ordering options when selecting data from "visits_chartsignature". */
export type VisitsChartsignatureOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  signatureAdditionalText?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
  userMedspa?: InputMaybe<UserMedspaOrderBy>;
  userMedspaId?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_chartsignature */
export type VisitsChartsignaturePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_chartsignature" */
export enum VisitsChartsignatureSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Signature = "signature",
  /** column name */
  SignatureAdditionalText = "signatureAdditionalText",
  /** column name */
  SignedAt = "signedAt",
  /** column name */
  UserAgent = "userAgent",
  /** column name */
  UserMedspaId = "userMedspaId",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "visits_chartsignature" */
export type VisitsChartsignatureSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Ip address of provider signing chart. */
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Image of the signature. */
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** E.g. "I have treated the patient in accordance with the standing order(s)." */
  signatureAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when charts were signed. */
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** User agent of provider signing chart. */
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type VisitsChartsignatureStddevFields = {
  __typename?: "VisitsChartsignatureStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type VisitsChartsignatureStddevPopFields = {
  __typename?: "VisitsChartsignatureStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type VisitsChartsignatureStddevSampFields = {
  __typename?: "VisitsChartsignatureStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "visits_chartsignature" */
export type VisitsChartsignatureStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitsChartsignatureStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitsChartsignatureStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Ip address of provider signing chart. */
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Image of the signature. */
  signature?: InputMaybe<Scalars["String"]["input"]>;
  /** E.g. "I have treated the patient in accordance with the standing order(s)." */
  signatureAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  /** Timestamp when charts were signed. */
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** User agent of provider signing chart. */
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type VisitsChartsignatureSumFields = {
  __typename?: "VisitsChartsignatureSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_chartsignature" */
export enum VisitsChartsignatureUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Signature = "signature",
  /** column name */
  SignatureAdditionalText = "signatureAdditionalText",
  /** column name */
  SignedAt = "signedAt",
  /** column name */
  UserAgent = "userAgent",
  /** column name */
  UserMedspaId = "userMedspaId",
  /** column name */
  VisitId = "visitId",
}

export type VisitsChartsignatureUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitsChartsignatureIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitsChartsignatureSetInput>;
  /** filter the rows which have to be updated */
  where: VisitsChartsignatureBoolExp;
};

/** aggregate varPop on columns */
export type VisitsChartsignatureVarPopFields = {
  __typename?: "VisitsChartsignatureVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type VisitsChartsignatureVarSampFields = {
  __typename?: "VisitsChartsignatureVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type VisitsChartsignatureVarianceFields = {
  __typename?: "VisitsChartsignatureVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medspa at which visits take place. */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Visit related to charts providers are signing. */
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_chartsignature" */
export type VisitsChartsignatureVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Medspa at which visits take place. */
  medspaId?: InputMaybe<OrderBy>;
  /** UserMedSpa instance of the user who signed the chart. */
  userMedspaId?: InputMaybe<OrderBy>;
  /** Visit related to charts providers are signing. */
  visitId?: InputMaybe<OrderBy>;
};

/** An enumeration. */
export enum VisitsDiscountLineDiscountTypeChoices {
  /** Fixed value */
  FixedValue = "FIXED_VALUE",
  /** Percent */
  Percent = "PERCENT",
}

/** An enumeration. */
export enum VisitsPhotoLabelChoices {
  /** After */
  After = "AFTER",
  /** Before */
  Before = "BEFORE",
  /** Diagram */
  Diagram = "DIAGRAM",
  /** Other */
  Other = "OTHER",
}

/** An enumeration. */
export enum VisitsTelehealthVisitDetailsVisitTypeChoices {
  /** Phone */
  Phone = "PHONE",
  /** Video */
  Video = "VIDEO",
}

/** columns and relationships of "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelog = {
  __typename?: "VisitsTelehealthvisitdetailschangelog";
  created: Scalars["timestamptz"]["output"];
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["bigint"]["output"]>;
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  telehealthVisitDetails: TelehealthVisitDetails;
  telehealthVisitDetailsId: Scalars["bigint"]["output"];
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkNew?: Maybe<Scalars["String"]["output"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkOld?: Maybe<Scalars["String"]["output"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsNew?: Maybe<Scalars["String"]["output"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsOld?: Maybe<Scalars["String"]["output"]>;
  /** Stores telehealth visit type */
  visitTypeNew: Scalars["String"]["output"];
  /** Stores telehealth visit type */
  visitTypeOld: Scalars["String"]["output"];
};

/** aggregated selection of "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogAggregate = {
  __typename?: "VisitsTelehealthvisitdetailschangelogAggregate";
  aggregate?: Maybe<VisitsTelehealthvisitdetailschangelogAggregateFields>;
  nodes: Array<VisitsTelehealthvisitdetailschangelog>;
};

/** aggregate fields of "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogAggregateFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogAggregateFields";
  avg?: Maybe<VisitsTelehealthvisitdetailschangelogAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<VisitsTelehealthvisitdetailschangelogMaxFields>;
  min?: Maybe<VisitsTelehealthvisitdetailschangelogMinFields>;
  stddev?: Maybe<VisitsTelehealthvisitdetailschangelogStddevFields>;
  stddevPop?: Maybe<VisitsTelehealthvisitdetailschangelogStddevPopFields>;
  stddevSamp?: Maybe<VisitsTelehealthvisitdetailschangelogStddevSampFields>;
  sum?: Maybe<VisitsTelehealthvisitdetailschangelogSumFields>;
  varPop?: Maybe<VisitsTelehealthvisitdetailschangelogVarPopFields>;
  varSamp?: Maybe<VisitsTelehealthvisitdetailschangelogVarSampFields>;
  variance?: Maybe<VisitsTelehealthvisitdetailschangelogVarianceFields>;
};

/** aggregate fields of "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogAggregateFieldsCountArgs = {
  columns?: InputMaybe<
    Array<VisitsTelehealthvisitdetailschangelogSelectColumn>
  >;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type VisitsTelehealthvisitdetailschangelogAvgFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogAvgFields";
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "visits_telehealthvisitdetailschangelog". All fields are combined with a logical 'AND'. */
export type VisitsTelehealthvisitdetailschangelogBoolExp = {
  _and?: InputMaybe<Array<VisitsTelehealthvisitdetailschangelogBoolExp>>;
  _not?: InputMaybe<VisitsTelehealthvisitdetailschangelogBoolExp>;
  _or?: InputMaybe<Array<VisitsTelehealthvisitdetailschangelogBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  editedById?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  telehealthVisitDetails?: InputMaybe<TelehealthVisitDetailsBoolExp>;
  telehealthVisitDetailsId?: InputMaybe<BigintComparisonExp>;
  videoLinkNew?: InputMaybe<StringComparisonExp>;
  videoLinkOld?: InputMaybe<StringComparisonExp>;
  visitDetailsNew?: InputMaybe<StringComparisonExp>;
  visitDetailsOld?: InputMaybe<StringComparisonExp>;
  visitTypeNew?: InputMaybe<StringComparisonExp>;
  visitTypeOld?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "visits_telehealthvisitdetailschangelog" */
export enum VisitsTelehealthvisitdetailschangelogConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsTelehealthvisitdetailschangelogPkey = "visits_telehealthvisitdetailschangelog_pkey",
}

/** input type for incrementing numeric columns in table "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogIncInput = {
  /** Can be empty if updated from outside the app */
  editedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  telehealthVisitDetailsId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Can be empty if updated from outside the app */
  editedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  telehealthVisitDetails?: InputMaybe<TelehealthVisitDetailsObjRelInsertInput>;
  telehealthVisitDetailsId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkOld?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsOld?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores telehealth visit type */
  visitTypeNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores telehealth visit type */
  visitTypeOld?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type VisitsTelehealthvisitdetailschangelogMaxFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["bigint"]["output"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkNew?: Maybe<Scalars["String"]["output"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkOld?: Maybe<Scalars["String"]["output"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsNew?: Maybe<Scalars["String"]["output"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsOld?: Maybe<Scalars["String"]["output"]>;
  /** Stores telehealth visit type */
  visitTypeNew?: Maybe<Scalars["String"]["output"]>;
  /** Stores telehealth visit type */
  visitTypeOld?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type VisitsTelehealthvisitdetailschangelogMinFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["bigint"]["output"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkNew?: Maybe<Scalars["String"]["output"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkOld?: Maybe<Scalars["String"]["output"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsNew?: Maybe<Scalars["String"]["output"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsOld?: Maybe<Scalars["String"]["output"]>;
  /** Stores telehealth visit type */
  visitTypeNew?: Maybe<Scalars["String"]["output"]>;
  /** Stores telehealth visit type */
  visitTypeOld?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogMutationResponse = {
  __typename?: "VisitsTelehealthvisitdetailschangelogMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<VisitsTelehealthvisitdetailschangelog>;
};

/** on_conflict condition type for table "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogOnConflict = {
  constraint: VisitsTelehealthvisitdetailschangelogConstraint;
  updateColumns?: Array<VisitsTelehealthvisitdetailschangelogUpdateColumn>;
  where?: InputMaybe<VisitsTelehealthvisitdetailschangelogBoolExp>;
};

/** Ordering options when selecting data from "visits_telehealthvisitdetailschangelog". */
export type VisitsTelehealthvisitdetailschangelogOrderBy = {
  created?: InputMaybe<OrderBy>;
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  telehealthVisitDetails?: InputMaybe<TelehealthVisitDetailsOrderBy>;
  telehealthVisitDetailsId?: InputMaybe<OrderBy>;
  videoLinkNew?: InputMaybe<OrderBy>;
  videoLinkOld?: InputMaybe<OrderBy>;
  visitDetailsNew?: InputMaybe<OrderBy>;
  visitDetailsOld?: InputMaybe<OrderBy>;
  visitTypeNew?: InputMaybe<OrderBy>;
  visitTypeOld?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_telehealthvisitdetailschangelog */
export type VisitsTelehealthvisitdetailschangelogPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_telehealthvisitdetailschangelog" */
export enum VisitsTelehealthvisitdetailschangelogSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  EditedById = "editedById",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  TelehealthVisitDetailsId = "telehealthVisitDetailsId",
  /** column name */
  VideoLinkNew = "videoLinkNew",
  /** column name */
  VideoLinkOld = "videoLinkOld",
  /** column name */
  VisitDetailsNew = "visitDetailsNew",
  /** column name */
  VisitDetailsOld = "visitDetailsOld",
  /** column name */
  VisitTypeNew = "visitTypeNew",
  /** column name */
  VisitTypeOld = "visitTypeOld",
}

/** input type for updating data in table "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Can be empty if updated from outside the app */
  editedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  telehealthVisitDetailsId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkOld?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsOld?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores telehealth visit type */
  visitTypeNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores telehealth visit type */
  visitTypeOld?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type VisitsTelehealthvisitdetailschangelogStddevFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogStddevFields";
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type VisitsTelehealthvisitdetailschangelogStddevPopFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogStddevPopFields";
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type VisitsTelehealthvisitdetailschangelogStddevSampFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogStddevSampFields";
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "visits_telehealthvisitdetailschangelog" */
export type VisitsTelehealthvisitdetailschangelogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitsTelehealthvisitdetailschangelogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitsTelehealthvisitdetailschangelogStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Can be empty if updated from outside the app */
  editedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  telehealthVisitDetailsId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional field, stores the visit url when it is a video visit */
  videoLinkOld?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores optional telehealth visit details that providers may want to share with client */
  visitDetailsOld?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores telehealth visit type */
  visitTypeNew?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores telehealth visit type */
  visitTypeOld?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type VisitsTelehealthvisitdetailschangelogSumFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogSumFields";
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "visits_telehealthvisitdetailschangelog" */
export enum VisitsTelehealthvisitdetailschangelogUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  EditedById = "editedById",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  TelehealthVisitDetailsId = "telehealthVisitDetailsId",
  /** column name */
  VideoLinkNew = "videoLinkNew",
  /** column name */
  VideoLinkOld = "videoLinkOld",
  /** column name */
  VisitDetailsNew = "visitDetailsNew",
  /** column name */
  VisitDetailsOld = "visitDetailsOld",
  /** column name */
  VisitTypeNew = "visitTypeNew",
  /** column name */
  VisitTypeOld = "visitTypeOld",
}

export type VisitsTelehealthvisitdetailschangelogUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitsTelehealthvisitdetailschangelogIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitsTelehealthvisitdetailschangelogSetInput>;
  /** filter the rows which have to be updated */
  where: VisitsTelehealthvisitdetailschangelogBoolExp;
};

/** aggregate varPop on columns */
export type VisitsTelehealthvisitdetailschangelogVarPopFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogVarPopFields";
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type VisitsTelehealthvisitdetailschangelogVarSampFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogVarSampFields";
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type VisitsTelehealthvisitdetailschangelogVarianceFields = {
  __typename?: "VisitsTelehealthvisitdetailschangelogVarianceFields";
  /** Can be empty if updated from outside the app */
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  telehealthVisitDetailsId?: Maybe<Scalars["Float"]["output"]>;
};

export type VisitsToReviewType = {
  __typename?: "VisitsToReviewType";
  totalCompletedVisits?: Maybe<Scalars["Int"]["output"]>;
  totalVisits?: Maybe<Scalars["Int"]["output"]>;
  totalVisitsToReview?: Maybe<Scalars["Int"]["output"]>;
  visitsToReview?: Maybe<Array<Maybe<VisitToReviewType>>>;
};

/** Table to store information about wallet credits used to pay for the invoice. */
export type WalletCreditLine = {
  __typename?: "WalletCreditLine";
  /** Amount of credits used to pay for the invoice */
  amount: Scalars["numeric"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** Type of credit used to pay for the invoice */
  creditType?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  payment?: Maybe<Payment>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  walletCreditType?: Maybe<WalletCreditType>;
  walletCreditTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  walletTransaction?: Maybe<WalletTransaction>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "wallet_credit_line" */
export type WalletCreditLineAggregate = {
  __typename?: "WalletCreditLineAggregate";
  aggregate?: Maybe<WalletCreditLineAggregateFields>;
  nodes: Array<WalletCreditLine>;
};

export type WalletCreditLineAggregateBoolExp = {
  count?: InputMaybe<WalletCreditLineAggregateBoolExpCount>;
};

/** aggregate fields of "wallet_credit_line" */
export type WalletCreditLineAggregateFields = {
  __typename?: "WalletCreditLineAggregateFields";
  avg?: Maybe<WalletCreditLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<WalletCreditLineMaxFields>;
  min?: Maybe<WalletCreditLineMinFields>;
  stddev?: Maybe<WalletCreditLineStddevFields>;
  stddevPop?: Maybe<WalletCreditLineStddevPopFields>;
  stddevSamp?: Maybe<WalletCreditLineStddevSampFields>;
  sum?: Maybe<WalletCreditLineSumFields>;
  varPop?: Maybe<WalletCreditLineVarPopFields>;
  varSamp?: Maybe<WalletCreditLineVarSampFields>;
  variance?: Maybe<WalletCreditLineVarianceFields>;
};

/** aggregate fields of "wallet_credit_line" */
export type WalletCreditLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WalletCreditLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "wallet_credit_line" */
export type WalletCreditLineAggregateOrderBy = {
  avg?: InputMaybe<WalletCreditLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WalletCreditLineMaxOrderBy>;
  min?: InputMaybe<WalletCreditLineMinOrderBy>;
  stddev?: InputMaybe<WalletCreditLineStddevOrderBy>;
  stddevPop?: InputMaybe<WalletCreditLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<WalletCreditLineStddevSampOrderBy>;
  sum?: InputMaybe<WalletCreditLineSumOrderBy>;
  varPop?: InputMaybe<WalletCreditLineVarPopOrderBy>;
  varSamp?: InputMaybe<WalletCreditLineVarSampOrderBy>;
  variance?: InputMaybe<WalletCreditLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "wallet_credit_line" */
export type WalletCreditLineArrRelInsertInput = {
  data: Array<WalletCreditLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WalletCreditLineOnConflict>;
};

/** aggregate avg on columns */
export type WalletCreditLineAvgFields = {
  __typename?: "WalletCreditLineAvgFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "wallet_credit_line" */
export type WalletCreditLineAvgOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "wallet_credit_line". All fields are combined with a logical 'AND'. */
export type WalletCreditLineBoolExp = {
  _and?: InputMaybe<Array<WalletCreditLineBoolExp>>;
  _not?: InputMaybe<WalletCreditLineBoolExp>;
  _or?: InputMaybe<Array<WalletCreditLineBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  creditType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  payment?: InputMaybe<PaymentBoolExp>;
  paymentId?: InputMaybe<BigintComparisonExp>;
  walletCreditType?: InputMaybe<WalletCreditTypeBoolExp>;
  walletCreditTypeId?: InputMaybe<BigintComparisonExp>;
  walletTransaction?: InputMaybe<WalletTransactionBoolExp>;
  walletTransactionId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "wallet_credit_line" */
export enum WalletCreditLineConstraint {
  /** unique or primary key constraint on columns "invoice_id", "wallet_credit_type_id" */
  UniqueCreditTypeInvoice = "unique_credit_type_invoice",
  /** unique or primary key constraint on columns "payment_id" */
  WalletCreditLinePaymentIdKey = "wallet_credit_line_payment_id_key",
  /** unique or primary key constraint on columns "id" */
  WalletCreditLinePkey = "wallet_credit_line_pkey",
  /** unique or primary key constraint on columns "wallet_transaction_id" */
  WalletCreditLineWalletTransactionIdKey = "wallet_credit_line_wallet_transaction_id_key",
}

/** input type for incrementing numeric columns in table "wallet_credit_line" */
export type WalletCreditLineIncInput = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletCreditTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "wallet_credit_line" */
export type WalletCreditLineInsertInput = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of credit used to pay for the invoice */
  creditType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  payment?: InputMaybe<PaymentObjRelInsertInput>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletCreditType?: InputMaybe<WalletCreditTypeObjRelInsertInput>;
  walletCreditTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletTransaction?: InputMaybe<WalletTransactionObjRelInsertInput>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type WalletCreditLineMaxFields = {
  __typename?: "WalletCreditLineMaxFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of credit used to pay for the invoice */
  creditType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "wallet_credit_line" */
export type WalletCreditLineMaxOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Type of credit used to pay for the invoice */
  creditType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WalletCreditLineMinFields = {
  __typename?: "WalletCreditLineMinFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Type of credit used to pay for the invoice */
  creditType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "wallet_credit_line" */
export type WalletCreditLineMinOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Type of credit used to pay for the invoice */
  creditType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "wallet_credit_line" */
export type WalletCreditLineMutationResponse = {
  __typename?: "WalletCreditLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<WalletCreditLine>;
};

/** input type for inserting object relation for remote table "wallet_credit_line" */
export type WalletCreditLineObjRelInsertInput = {
  data: WalletCreditLineInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WalletCreditLineOnConflict>;
};

/** on_conflict condition type for table "wallet_credit_line" */
export type WalletCreditLineOnConflict = {
  constraint: WalletCreditLineConstraint;
  updateColumns?: Array<WalletCreditLineUpdateColumn>;
  where?: InputMaybe<WalletCreditLineBoolExp>;
};

/** Ordering options when selecting data from "wallet_credit_line". */
export type WalletCreditLineOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  creditType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  payment?: InputMaybe<PaymentOrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  walletCreditType?: InputMaybe<WalletCreditTypeOrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  walletTransaction?: InputMaybe<WalletTransactionOrderBy>;
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: wallet_credit_line */
export type WalletCreditLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "wallet_credit_line" */
export enum WalletCreditLineSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  Created = "created",
  /** column name */
  CreditType = "creditType",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  PaymentId = "paymentId",
  /** column name */
  WalletCreditTypeId = "walletCreditTypeId",
  /** column name */
  WalletTransactionId = "walletTransactionId",
}

/** input type for updating data in table "wallet_credit_line" */
export type WalletCreditLineSetInput = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of credit used to pay for the invoice */
  creditType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletCreditTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type WalletCreditLineStddevFields = {
  __typename?: "WalletCreditLineStddevFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "wallet_credit_line" */
export type WalletCreditLineStddevOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type WalletCreditLineStddevPopFields = {
  __typename?: "WalletCreditLineStddevPopFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "wallet_credit_line" */
export type WalletCreditLineStddevPopOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type WalletCreditLineStddevSampFields = {
  __typename?: "WalletCreditLineStddevSampFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "wallet_credit_line" */
export type WalletCreditLineStddevSampOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "wallet_credit_line" */
export type WalletCreditLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WalletCreditLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WalletCreditLineStreamCursorValueInput = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Type of credit used to pay for the invoice */
  creditType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletCreditTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type WalletCreditLineSumFields = {
  __typename?: "WalletCreditLineSumFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "wallet_credit_line" */
export type WalletCreditLineSumOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** update columns of table "wallet_credit_line" */
export enum WalletCreditLineUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  Created = "created",
  /** column name */
  CreditType = "creditType",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  PaymentId = "paymentId",
  /** column name */
  WalletCreditTypeId = "walletCreditTypeId",
  /** column name */
  WalletTransactionId = "walletTransactionId",
}

export type WalletCreditLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WalletCreditLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WalletCreditLineSetInput>;
  /** filter the rows which have to be updated */
  where: WalletCreditLineBoolExp;
};

/** aggregate varPop on columns */
export type WalletCreditLineVarPopFields = {
  __typename?: "WalletCreditLineVarPopFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "wallet_credit_line" */
export type WalletCreditLineVarPopOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type WalletCreditLineVarSampFields = {
  __typename?: "WalletCreditLineVarSampFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "wallet_credit_line" */
export type WalletCreditLineVarSampOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WalletCreditLineVarianceFields = {
  __typename?: "WalletCreditLineVarianceFields";
  /** Amount of credits used to pay for the invoice */
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "wallet_credit_line" */
export type WalletCreditLineVarianceOrderBy = {
  /** Amount of credits used to pay for the invoice */
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** This field will be populated when payment for invoice credit line is made */
  paymentId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  /** Wallet transaction associated with the credit line */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** Table to store all credit types available in the wallet. This will be used to track the credits of the clients. */
export type WalletCreditType = {
  __typename?: "WalletCreditType";
  created: Scalars["timestamptz"]["output"];
  /** Name/title of the wallet credit type. This will be displayed to end users. */
  creditName: Scalars["String"]["output"];
  /** Optional description of the wallet credit type. Will be displayed in a tooltip on the UI */
  description: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaWalletCreditTypes: Array<MedspaWalletCreditType>;
  /** An aggregate relationship */
  medspaWalletCreditTypesAggregate: MedspaWalletCreditTypeAggregate;
  modified: Scalars["timestamptz"]["output"];
};

/** Table to store all credit types available in the wallet. This will be used to track the credits of the clients. */
export type WalletCreditTypeMedspaWalletCreditTypesArgs = {
  distinctOn?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaWalletCreditTypeOrderBy>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

/** Table to store all credit types available in the wallet. This will be used to track the credits of the clients. */
export type WalletCreditTypeMedspaWalletCreditTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaWalletCreditTypeOrderBy>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

/** aggregated selection of "wallet_credit_type" */
export type WalletCreditTypeAggregate = {
  __typename?: "WalletCreditTypeAggregate";
  aggregate?: Maybe<WalletCreditTypeAggregateFields>;
  nodes: Array<WalletCreditType>;
};

/** aggregate fields of "wallet_credit_type" */
export type WalletCreditTypeAggregateFields = {
  __typename?: "WalletCreditTypeAggregateFields";
  avg?: Maybe<WalletCreditTypeAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<WalletCreditTypeMaxFields>;
  min?: Maybe<WalletCreditTypeMinFields>;
  stddev?: Maybe<WalletCreditTypeStddevFields>;
  stddevPop?: Maybe<WalletCreditTypeStddevPopFields>;
  stddevSamp?: Maybe<WalletCreditTypeStddevSampFields>;
  sum?: Maybe<WalletCreditTypeSumFields>;
  varPop?: Maybe<WalletCreditTypeVarPopFields>;
  varSamp?: Maybe<WalletCreditTypeVarSampFields>;
  variance?: Maybe<WalletCreditTypeVarianceFields>;
};

/** aggregate fields of "wallet_credit_type" */
export type WalletCreditTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WalletCreditTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type WalletCreditTypeAvgFields = {
  __typename?: "WalletCreditTypeAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "wallet_credit_type". All fields are combined with a logical 'AND'. */
export type WalletCreditTypeBoolExp = {
  _and?: InputMaybe<Array<WalletCreditTypeBoolExp>>;
  _not?: InputMaybe<WalletCreditTypeBoolExp>;
  _or?: InputMaybe<Array<WalletCreditTypeBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  creditName?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaWalletCreditTypes?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
  medspaWalletCreditTypesAggregate?: InputMaybe<MedspaWalletCreditTypeAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "wallet_credit_type" */
export enum WalletCreditTypeConstraint {
  /** unique or primary key constraint on columns "id" */
  WalletCreditTypePkey = "wallet_credit_type_pkey",
}

/** input type for incrementing numeric columns in table "wallet_credit_type" */
export type WalletCreditTypeIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "wallet_credit_type" */
export type WalletCreditTypeInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the wallet credit type. This will be displayed to end users. */
  creditName?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional description of the wallet credit type. Will be displayed in a tooltip on the UI */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaWalletCreditTypes?: InputMaybe<MedspaWalletCreditTypeArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type WalletCreditTypeMaxFields = {
  __typename?: "WalletCreditTypeMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name/title of the wallet credit type. This will be displayed to end users. */
  creditName?: Maybe<Scalars["String"]["output"]>;
  /** Optional description of the wallet credit type. Will be displayed in a tooltip on the UI */
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type WalletCreditTypeMinFields = {
  __typename?: "WalletCreditTypeMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Name/title of the wallet credit type. This will be displayed to end users. */
  creditName?: Maybe<Scalars["String"]["output"]>;
  /** Optional description of the wallet credit type. Will be displayed in a tooltip on the UI */
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "wallet_credit_type" */
export type WalletCreditTypeMutationResponse = {
  __typename?: "WalletCreditTypeMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<WalletCreditType>;
};

/** input type for inserting object relation for remote table "wallet_credit_type" */
export type WalletCreditTypeObjRelInsertInput = {
  data: WalletCreditTypeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WalletCreditTypeOnConflict>;
};

/** on_conflict condition type for table "wallet_credit_type" */
export type WalletCreditTypeOnConflict = {
  constraint: WalletCreditTypeConstraint;
  updateColumns?: Array<WalletCreditTypeUpdateColumn>;
  where?: InputMaybe<WalletCreditTypeBoolExp>;
};

/** Ordering options when selecting data from "wallet_credit_type". */
export type WalletCreditTypeOrderBy = {
  created?: InputMaybe<OrderBy>;
  creditName?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaWalletCreditTypesAggregate?: InputMaybe<MedspaWalletCreditTypeAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: wallet_credit_type */
export type WalletCreditTypePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "wallet_credit_type" */
export enum WalletCreditTypeSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  CreditName = "creditName",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
}

/** input type for updating data in table "wallet_credit_type" */
export type WalletCreditTypeSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the wallet credit type. This will be displayed to end users. */
  creditName?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional description of the wallet credit type. Will be displayed in a tooltip on the UI */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type WalletCreditTypeStddevFields = {
  __typename?: "WalletCreditTypeStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type WalletCreditTypeStddevPopFields = {
  __typename?: "WalletCreditTypeStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type WalletCreditTypeStddevSampFields = {
  __typename?: "WalletCreditTypeStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "wallet_credit_type" */
export type WalletCreditTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WalletCreditTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WalletCreditTypeStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Name/title of the wallet credit type. This will be displayed to end users. */
  creditName?: InputMaybe<Scalars["String"]["input"]>;
  /** Optional description of the wallet credit type. Will be displayed in a tooltip on the UI */
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type WalletCreditTypeSumFields = {
  __typename?: "WalletCreditTypeSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "wallet_credit_type" */
export enum WalletCreditTypeUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  CreditName = "creditName",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
}

export type WalletCreditTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WalletCreditTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WalletCreditTypeSetInput>;
  /** filter the rows which have to be updated */
  where: WalletCreditTypeBoolExp;
};

/** aggregate varPop on columns */
export type WalletCreditTypeVarPopFields = {
  __typename?: "WalletCreditTypeVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type WalletCreditTypeVarSampFields = {
  __typename?: "WalletCreditTypeVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type WalletCreditTypeVarianceFields = {
  __typename?: "WalletCreditTypeVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type WalletSumsType = {
  __typename?: "WalletSumsType";
  credits: Array<Maybe<CreditSumType>>;
  products: Array<Maybe<ProductSumType>>;
  serviceProducts: Array<Maybe<ServiceProductSumType>>;
  services: Array<Maybe<ServiceSumType>>;
};

/** Table to track sources of wallet transactions, including memberships, packages, deposits, etc. */
export type WalletTransactionSourceTmp = {
  __typename?: "WalletTransactionSourceTmp";
  /** An object relationship */
  client: Client;
  /** Client associated with the wallet transaction source */
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["bigint"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  membershipLine?: Maybe<MembershipLine>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  membershipPerkLine?: Maybe<MembershipPerkLine>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** Custom note for capturing additional context about a wallet transaction source, mostly used for transactions manually created by a provider */
  note: Scalars["String"]["output"];
  /** An object relationship */
  packageItemLine?: Maybe<PackageItemLine>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  packageLine?: Maybe<PackageLine>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of the source of the wallet transaction, associated with how the wallet transaction was created */
  sourceType: Scalars["String"]["output"];
  /** An array relationship */
  walletTransactionsSources: Array<WalletTransactionsSources>;
  /** An aggregate relationship */
  walletTransactionsSourcesAggregate: WalletTransactionsSourcesAggregate;
};

/** Table to track sources of wallet transactions, including memberships, packages, deposits, etc. */
export type WalletTransactionSourceTmpWalletTransactionsSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionsSourcesOrderBy>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

/** Table to track sources of wallet transactions, including memberships, packages, deposits, etc. */
export type WalletTransactionSourceTmpWalletTransactionsSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionsSourcesOrderBy>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

/** aggregated selection of "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpAggregate = {
  __typename?: "WalletTransactionSourceTmpAggregate";
  aggregate?: Maybe<WalletTransactionSourceTmpAggregateFields>;
  nodes: Array<WalletTransactionSourceTmp>;
};

export type WalletTransactionSourceTmpAggregateBoolExp = {
  count?: InputMaybe<WalletTransactionSourceTmpAggregateBoolExpCount>;
};

/** aggregate fields of "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpAggregateFields = {
  __typename?: "WalletTransactionSourceTmpAggregateFields";
  avg?: Maybe<WalletTransactionSourceTmpAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<WalletTransactionSourceTmpMaxFields>;
  min?: Maybe<WalletTransactionSourceTmpMinFields>;
  stddev?: Maybe<WalletTransactionSourceTmpStddevFields>;
  stddevPop?: Maybe<WalletTransactionSourceTmpStddevPopFields>;
  stddevSamp?: Maybe<WalletTransactionSourceTmpStddevSampFields>;
  sum?: Maybe<WalletTransactionSourceTmpSumFields>;
  varPop?: Maybe<WalletTransactionSourceTmpVarPopFields>;
  varSamp?: Maybe<WalletTransactionSourceTmpVarSampFields>;
  variance?: Maybe<WalletTransactionSourceTmpVarianceFields>;
};

/** aggregate fields of "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpAggregateOrderBy = {
  avg?: InputMaybe<WalletTransactionSourceTmpAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WalletTransactionSourceTmpMaxOrderBy>;
  min?: InputMaybe<WalletTransactionSourceTmpMinOrderBy>;
  stddev?: InputMaybe<WalletTransactionSourceTmpStddevOrderBy>;
  stddevPop?: InputMaybe<WalletTransactionSourceTmpStddevPopOrderBy>;
  stddevSamp?: InputMaybe<WalletTransactionSourceTmpStddevSampOrderBy>;
  sum?: InputMaybe<WalletTransactionSourceTmpSumOrderBy>;
  varPop?: InputMaybe<WalletTransactionSourceTmpVarPopOrderBy>;
  varSamp?: InputMaybe<WalletTransactionSourceTmpVarSampOrderBy>;
  variance?: InputMaybe<WalletTransactionSourceTmpVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpArrRelInsertInput = {
  data: Array<WalletTransactionSourceTmpInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WalletTransactionSourceTmpOnConflict>;
};

/** aggregate avg on columns */
export type WalletTransactionSourceTmpAvgFields = {
  __typename?: "WalletTransactionSourceTmpAvgFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpAvgOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "wallet_transaction_source_tmp". All fields are combined with a logical 'AND'. */
export type WalletTransactionSourceTmpBoolExp = {
  _and?: InputMaybe<Array<WalletTransactionSourceTmpBoolExp>>;
  _not?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
  _or?: InputMaybe<Array<WalletTransactionSourceTmpBoolExp>>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<UserBoolExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  depositLineId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  membershipLine?: InputMaybe<MembershipLineBoolExp>;
  membershipLineId?: InputMaybe<BigintComparisonExp>;
  membershipPerkLine?: InputMaybe<MembershipPerkLineBoolExp>;
  membershipPerkLineId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  packageItemLine?: InputMaybe<PackageItemLineBoolExp>;
  packageItemLineId?: InputMaybe<BigintComparisonExp>;
  packageLine?: InputMaybe<PackageLineBoolExp>;
  packageLineId?: InputMaybe<BigintComparisonExp>;
  sourceType?: InputMaybe<StringComparisonExp>;
  walletTransactionsSources?: InputMaybe<WalletTransactionsSourcesBoolExp>;
  walletTransactionsSourcesAggregate?: InputMaybe<WalletTransactionsSourcesAggregateBoolExp>;
};

/** unique or primary key constraints on table "wallet_transaction_source_tmp" */
export enum WalletTransactionSourceTmpConstraint {
  /** unique or primary key constraint on columns "id" */
  WalletTransactionSourceTmpPkey = "wallet_transaction_source_tmp_pkey",
}

/** input type for incrementing numeric columns in table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpIncInput = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpInsertInput = {
  client?: InputMaybe<ClientObjRelInsertInput>;
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipLine?: InputMaybe<MembershipLineObjRelInsertInput>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipPerkLine?: InputMaybe<MembershipPerkLineObjRelInsertInput>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Custom note for capturing additional context about a wallet transaction source, mostly used for transactions manually created by a provider */
  note?: InputMaybe<Scalars["String"]["input"]>;
  packageItemLine?: InputMaybe<PackageItemLineObjRelInsertInput>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  packageLine?: InputMaybe<PackageLineObjRelInsertInput>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of the source of the wallet transaction, associated with how the wallet transaction was created */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
  walletTransactionsSources?: InputMaybe<WalletTransactionsSourcesArrRelInsertInput>;
};

/** aggregate max on columns */
export type WalletTransactionSourceTmpMaxFields = {
  __typename?: "WalletTransactionSourceTmpMaxFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Custom note for capturing additional context about a wallet transaction source, mostly used for transactions manually created by a provider */
  note?: Maybe<Scalars["String"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of the source of the wallet transaction, associated with how the wallet transaction was created */
  sourceType?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpMaxOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Custom note for capturing additional context about a wallet transaction source, mostly used for transactions manually created by a provider */
  note?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
  /** Type of the source of the wallet transaction, associated with how the wallet transaction was created */
  sourceType?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WalletTransactionSourceTmpMinFields = {
  __typename?: "WalletTransactionSourceTmpMinFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Custom note for capturing additional context about a wallet transaction source, mostly used for transactions manually created by a provider */
  note?: Maybe<Scalars["String"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of the source of the wallet transaction, associated with how the wallet transaction was created */
  sourceType?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpMinOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Custom note for capturing additional context about a wallet transaction source, mostly used for transactions manually created by a provider */
  note?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
  /** Type of the source of the wallet transaction, associated with how the wallet transaction was created */
  sourceType?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpMutationResponse = {
  __typename?: "WalletTransactionSourceTmpMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<WalletTransactionSourceTmp>;
};

/** input type for inserting object relation for remote table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpObjRelInsertInput = {
  data: WalletTransactionSourceTmpInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WalletTransactionSourceTmpOnConflict>;
};

/** on_conflict condition type for table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpOnConflict = {
  constraint: WalletTransactionSourceTmpConstraint;
  updateColumns?: Array<WalletTransactionSourceTmpUpdateColumn>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** Ordering options when selecting data from "wallet_transaction_source_tmp". */
export type WalletTransactionSourceTmpOrderBy = {
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipLine?: InputMaybe<MembershipLineOrderBy>;
  membershipLineId?: InputMaybe<OrderBy>;
  membershipPerkLine?: InputMaybe<MembershipPerkLineOrderBy>;
  membershipPerkLineId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  packageItemLine?: InputMaybe<PackageItemLineOrderBy>;
  packageItemLineId?: InputMaybe<OrderBy>;
  packageLine?: InputMaybe<PackageLineOrderBy>;
  packageLineId?: InputMaybe<OrderBy>;
  sourceType?: InputMaybe<OrderBy>;
  walletTransactionsSourcesAggregate?: InputMaybe<WalletTransactionsSourcesAggregateOrderBy>;
};

/** primary key columns input for table: wallet_transaction_source_tmp */
export type WalletTransactionSourceTmpPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "wallet_transaction_source_tmp" */
export enum WalletTransactionSourceTmpSelectColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  DepositLineId = "depositLineId",
  /** column name */
  Id = "id",
  /** column name */
  MembershipLineId = "membershipLineId",
  /** column name */
  MembershipPerkLineId = "membershipPerkLineId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  PackageItemLineId = "packageItemLineId",
  /** column name */
  PackageLineId = "packageLineId",
  /** column name */
  SourceType = "sourceType",
}

/** input type for updating data in table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpSetInput = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Custom note for capturing additional context about a wallet transaction source, mostly used for transactions manually created by a provider */
  note?: InputMaybe<Scalars["String"]["input"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of the source of the wallet transaction, associated with how the wallet transaction was created */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type WalletTransactionSourceTmpStddevFields = {
  __typename?: "WalletTransactionSourceTmpStddevFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpStddevOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type WalletTransactionSourceTmpStddevPopFields = {
  __typename?: "WalletTransactionSourceTmpStddevPopFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpStddevPopOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type WalletTransactionSourceTmpStddevSampFields = {
  __typename?: "WalletTransactionSourceTmpStddevSampFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpStddevSampOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WalletTransactionSourceTmpStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WalletTransactionSourceTmpStreamCursorValueInput = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Custom note for capturing additional context about a wallet transaction source, mostly used for transactions manually created by a provider */
  note?: InputMaybe<Scalars["String"]["input"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of the source of the wallet transaction, associated with how the wallet transaction was created */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type WalletTransactionSourceTmpSumFields = {
  __typename?: "WalletTransactionSourceTmpSumFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpSumOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
};

/** update columns of table "wallet_transaction_source_tmp" */
export enum WalletTransactionSourceTmpUpdateColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  DepositLineId = "depositLineId",
  /** column name */
  Id = "id",
  /** column name */
  MembershipLineId = "membershipLineId",
  /** column name */
  MembershipPerkLineId = "membershipPerkLineId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  PackageItemLineId = "packageItemLineId",
  /** column name */
  PackageLineId = "packageLineId",
  /** column name */
  SourceType = "sourceType",
}

export type WalletTransactionSourceTmpUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WalletTransactionSourceTmpIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WalletTransactionSourceTmpSetInput>;
  /** filter the rows which have to be updated */
  where: WalletTransactionSourceTmpBoolExp;
};

/** aggregate varPop on columns */
export type WalletTransactionSourceTmpVarPopFields = {
  __typename?: "WalletTransactionSourceTmpVarPopFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpVarPopOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type WalletTransactionSourceTmpVarSampFields = {
  __typename?: "WalletTransactionSourceTmpVarSampFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpVarSampOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WalletTransactionSourceTmpVarianceFields = {
  __typename?: "WalletTransactionSourceTmpVarianceFields";
  /** Client associated with the wallet transaction source */
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** User who created this wallet transaction source */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: Maybe<Scalars["Float"]["output"]>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "wallet_transaction_source_tmp" */
export type WalletTransactionSourceTmpVarianceOrderBy = {
  /** Client associated with the wallet transaction source */
  clientId?: InputMaybe<OrderBy>;
  /** User who created this wallet transaction source */
  createdById?: InputMaybe<OrderBy>;
  /** Deposit line that was used to create this wallet transaction source */
  depositLineId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Membership line that was used to create this wallet transaction source */
  membershipLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a membership perk that was used to create this wallet transaction source */
  membershipPerkLineId?: InputMaybe<OrderBy>;
  /** Snapshot of a package item that was used to create this wallet transaction source */
  packageItemLineId?: InputMaybe<OrderBy>;
  /** Package line that was used to create this wallet transaction source */
  packageLineId?: InputMaybe<OrderBy>;
};

/** Join table for a m2m relationship between wallet transactions and transaction sources. */
export type WalletTransactionsSources = {
  __typename?: "WalletTransactionsSources";
  /** Custom note for capturing additional context, only used for transactions with negative balance manually created by a provider. */
  deductionNote: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** Source transaction for wallet transaction */
  transactionSourceId: Scalars["bigint"]["output"];
  /** Amount used by transaction from source transaction */
  walletAmountDelta: Scalars["numeric"]["output"];
  /** An object relationship */
  walletTransaction: WalletTransaction;
  /** Wallet transaction that used the source transaction */
  walletTransactionId: Scalars["bigint"]["output"];
  /** An object relationship */
  walletTransactionSourceTmp: WalletTransactionSourceTmp;
};

/** aggregated selection of "wallet_transactions_sources" */
export type WalletTransactionsSourcesAggregate = {
  __typename?: "WalletTransactionsSourcesAggregate";
  aggregate?: Maybe<WalletTransactionsSourcesAggregateFields>;
  nodes: Array<WalletTransactionsSources>;
};

export type WalletTransactionsSourcesAggregateBoolExp = {
  count?: InputMaybe<WalletTransactionsSourcesAggregateBoolExpCount>;
};

/** aggregate fields of "wallet_transactions_sources" */
export type WalletTransactionsSourcesAggregateFields = {
  __typename?: "WalletTransactionsSourcesAggregateFields";
  avg?: Maybe<WalletTransactionsSourcesAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<WalletTransactionsSourcesMaxFields>;
  min?: Maybe<WalletTransactionsSourcesMinFields>;
  stddev?: Maybe<WalletTransactionsSourcesStddevFields>;
  stddevPop?: Maybe<WalletTransactionsSourcesStddevPopFields>;
  stddevSamp?: Maybe<WalletTransactionsSourcesStddevSampFields>;
  sum?: Maybe<WalletTransactionsSourcesSumFields>;
  varPop?: Maybe<WalletTransactionsSourcesVarPopFields>;
  varSamp?: Maybe<WalletTransactionsSourcesVarSampFields>;
  variance?: Maybe<WalletTransactionsSourcesVarianceFields>;
};

/** aggregate fields of "wallet_transactions_sources" */
export type WalletTransactionsSourcesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesAggregateOrderBy = {
  avg?: InputMaybe<WalletTransactionsSourcesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WalletTransactionsSourcesMaxOrderBy>;
  min?: InputMaybe<WalletTransactionsSourcesMinOrderBy>;
  stddev?: InputMaybe<WalletTransactionsSourcesStddevOrderBy>;
  stddevPop?: InputMaybe<WalletTransactionsSourcesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<WalletTransactionsSourcesStddevSampOrderBy>;
  sum?: InputMaybe<WalletTransactionsSourcesSumOrderBy>;
  varPop?: InputMaybe<WalletTransactionsSourcesVarPopOrderBy>;
  varSamp?: InputMaybe<WalletTransactionsSourcesVarSampOrderBy>;
  variance?: InputMaybe<WalletTransactionsSourcesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "wallet_transactions_sources" */
export type WalletTransactionsSourcesArrRelInsertInput = {
  data: Array<WalletTransactionsSourcesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WalletTransactionsSourcesOnConflict>;
};

/** aggregate avg on columns */
export type WalletTransactionsSourcesAvgFields = {
  __typename?: "WalletTransactionsSourcesAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "wallet_transactions_sources". All fields are combined with a logical 'AND'. */
export type WalletTransactionsSourcesBoolExp = {
  _and?: InputMaybe<Array<WalletTransactionsSourcesBoolExp>>;
  _not?: InputMaybe<WalletTransactionsSourcesBoolExp>;
  _or?: InputMaybe<Array<WalletTransactionsSourcesBoolExp>>;
  deductionNote?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  transactionSourceId?: InputMaybe<BigintComparisonExp>;
  walletAmountDelta?: InputMaybe<NumericComparisonExp>;
  walletTransaction?: InputMaybe<WalletTransactionBoolExp>;
  walletTransactionId?: InputMaybe<BigintComparisonExp>;
  walletTransactionSourceTmp?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** unique or primary key constraints on table "wallet_transactions_sources" */
export enum WalletTransactionsSourcesConstraint {
  /** unique or primary key constraint on columns "wallet_transaction_id", "transaction_source_id" */
  UniqueWalletTransactionSource = "unique_wallet_transaction_source",
  /** unique or primary key constraint on columns "id" */
  WalletTransactionsSourcesPkey = "wallet_transactions_sources_pkey",
}

/** input type for incrementing numeric columns in table "wallet_transactions_sources" */
export type WalletTransactionsSourcesIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "wallet_transactions_sources" */
export type WalletTransactionsSourcesInsertInput = {
  /** Custom note for capturing additional context, only used for transactions with negative balance manually created by a provider. */
  deductionNote?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<Scalars["numeric"]["input"]>;
  walletTransaction?: InputMaybe<WalletTransactionObjRelInsertInput>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletTransactionSourceTmp?: InputMaybe<WalletTransactionSourceTmpObjRelInsertInput>;
};

/** aggregate max on columns */
export type WalletTransactionsSourcesMaxFields = {
  __typename?: "WalletTransactionsSourcesMaxFields";
  /** Custom note for capturing additional context, only used for transactions with negative balance manually created by a provider. */
  deductionNote?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["numeric"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesMaxOrderBy = {
  /** Custom note for capturing additional context, only used for transactions with negative balance manually created by a provider. */
  deductionNote?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WalletTransactionsSourcesMinFields = {
  __typename?: "WalletTransactionsSourcesMinFields";
  /** Custom note for capturing additional context, only used for transactions with negative balance manually created by a provider. */
  deductionNote?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["numeric"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesMinOrderBy = {
  /** Custom note for capturing additional context, only used for transactions with negative balance manually created by a provider. */
  deductionNote?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "wallet_transactions_sources" */
export type WalletTransactionsSourcesMutationResponse = {
  __typename?: "WalletTransactionsSourcesMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<WalletTransactionsSources>;
};

/** on_conflict condition type for table "wallet_transactions_sources" */
export type WalletTransactionsSourcesOnConflict = {
  constraint: WalletTransactionsSourcesConstraint;
  updateColumns?: Array<WalletTransactionsSourcesUpdateColumn>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

/** Ordering options when selecting data from "wallet_transactions_sources". */
export type WalletTransactionsSourcesOrderBy = {
  deductionNote?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transactionSourceId?: InputMaybe<OrderBy>;
  walletAmountDelta?: InputMaybe<OrderBy>;
  walletTransaction?: InputMaybe<WalletTransactionOrderBy>;
  walletTransactionId?: InputMaybe<OrderBy>;
  walletTransactionSourceTmp?: InputMaybe<WalletTransactionSourceTmpOrderBy>;
};

/** primary key columns input for table: wallet_transactions_sources */
export type WalletTransactionsSourcesPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "wallet_transactions_sources" */
export enum WalletTransactionsSourcesSelectColumn {
  /** column name */
  DeductionNote = "deductionNote",
  /** column name */
  Id = "id",
  /** column name */
  TransactionSourceId = "transactionSourceId",
  /** column name */
  WalletAmountDelta = "walletAmountDelta",
  /** column name */
  WalletTransactionId = "walletTransactionId",
}

/** input type for updating data in table "wallet_transactions_sources" */
export type WalletTransactionsSourcesSetInput = {
  /** Custom note for capturing additional context, only used for transactions with negative balance manually created by a provider. */
  deductionNote?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type WalletTransactionsSourcesStddevFields = {
  __typename?: "WalletTransactionsSourcesStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type WalletTransactionsSourcesStddevPopFields = {
  __typename?: "WalletTransactionsSourcesStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type WalletTransactionsSourcesStddevSampFields = {
  __typename?: "WalletTransactionsSourcesStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "wallet_transactions_sources" */
export type WalletTransactionsSourcesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WalletTransactionsSourcesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WalletTransactionsSourcesStreamCursorValueInput = {
  /** Custom note for capturing additional context, only used for transactions with negative balance manually created by a provider. */
  deductionNote?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<Scalars["numeric"]["input"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type WalletTransactionsSourcesSumFields = {
  __typename?: "WalletTransactionsSourcesSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["numeric"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** update columns of table "wallet_transactions_sources" */
export enum WalletTransactionsSourcesUpdateColumn {
  /** column name */
  DeductionNote = "deductionNote",
  /** column name */
  Id = "id",
  /** column name */
  TransactionSourceId = "transactionSourceId",
  /** column name */
  WalletAmountDelta = "walletAmountDelta",
  /** column name */
  WalletTransactionId = "walletTransactionId",
}

export type WalletTransactionsSourcesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WalletTransactionsSourcesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WalletTransactionsSourcesSetInput>;
  /** filter the rows which have to be updated */
  where: WalletTransactionsSourcesBoolExp;
};

/** aggregate varPop on columns */
export type WalletTransactionsSourcesVarPopFields = {
  __typename?: "WalletTransactionsSourcesVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type WalletTransactionsSourcesVarSampFields = {
  __typename?: "WalletTransactionsSourcesVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WalletTransactionsSourcesVarianceFields = {
  __typename?: "WalletTransactionsSourcesVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: Maybe<Scalars["Float"]["output"]>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: Maybe<Scalars["Float"]["output"]>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "wallet_transactions_sources" */
export type WalletTransactionsSourcesVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  /** Source transaction for wallet transaction */
  transactionSourceId?: InputMaybe<OrderBy>;
  /** Amount used by transaction from source transaction */
  walletAmountDelta?: InputMaybe<OrderBy>;
  /** Wallet transaction that used the source transaction */
  walletTransactionId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "utils_address" */
export type Address = {
  __typename?: "address";
  addressLine1: Scalars["String"]["output"];
  addressLine2: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  /** An array relationship */
  clients: Array<Client>;
  /** An aggregate relationship */
  clientsAggregate: ClientAggregate;
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** An array relationship */
  medicalDirectors: Array<MedicalDirector>;
  /** An aggregate relationship */
  medicalDirectorsAggregate: MedicalDirectorAggregate;
  modified: Scalars["timestamptz"]["output"];
  state: Scalars["String"]["output"];
  zipCode: Scalars["String"]["output"];
};

/** columns and relationships of "utils_address" */
export type AddressClientsArgs = {
  distinctOn?: InputMaybe<Array<ClientSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientOrderBy>>;
  where?: InputMaybe<ClientBoolExp>;
};

/** columns and relationships of "utils_address" */
export type AddressClientsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientOrderBy>>;
  where?: InputMaybe<ClientBoolExp>;
};

/** columns and relationships of "utils_address" */
export type AddressMedicalDirectorsArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorOrderBy>>;
  where?: InputMaybe<MedicalDirectorBoolExp>;
};

/** columns and relationships of "utils_address" */
export type AddressMedicalDirectorsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorOrderBy>>;
  where?: InputMaybe<MedicalDirectorBoolExp>;
};

/** aggregated selection of "utils_address" */
export type AddressAggregate = {
  __typename?: "addressAggregate";
  aggregate?: Maybe<AddressAggregateFields>;
  nodes: Array<Address>;
};

/** aggregate fields of "utils_address" */
export type AddressAggregateFields = {
  __typename?: "addressAggregateFields";
  avg?: Maybe<AddressAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<AddressMaxFields>;
  min?: Maybe<AddressMinFields>;
  stddev?: Maybe<AddressStddevFields>;
  stddevPop?: Maybe<AddressStddevPopFields>;
  stddevSamp?: Maybe<AddressStddevSampFields>;
  sum?: Maybe<AddressSumFields>;
  varPop?: Maybe<AddressVarPopFields>;
  varSamp?: Maybe<AddressVarSampFields>;
  variance?: Maybe<AddressVarianceFields>;
};

/** aggregate fields of "utils_address" */
export type AddressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AddressSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type AddressAvgFields = {
  __typename?: "addressAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "utils_address". All fields are combined with a logical 'AND'. */
export type AddressBoolExp = {
  _and?: InputMaybe<Array<AddressBoolExp>>;
  _not?: InputMaybe<AddressBoolExp>;
  _or?: InputMaybe<Array<AddressBoolExp>>;
  addressLine1?: InputMaybe<StringComparisonExp>;
  addressLine2?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  clients?: InputMaybe<ClientBoolExp>;
  clientsAggregate?: InputMaybe<ClientAggregateBoolExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medicalDirectors?: InputMaybe<MedicalDirectorBoolExp>;
  medicalDirectorsAggregate?: InputMaybe<MedicalDirectorAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  state?: InputMaybe<StringComparisonExp>;
  zipCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "utils_address" */
export enum AddressConstraint {
  /** unique or primary key constraint on columns "id" */
  UtilsAddressPkey = "utils_address_pkey",
}

/** input type for incrementing numeric columns in table "utils_address" */
export type AddressIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "utils_address" */
export type AddressInsertInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  clients?: InputMaybe<ClientArrRelInsertInput>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicalDirectors?: InputMaybe<MedicalDirectorArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type AddressMaxFields = {
  __typename?: "addressMaxFields";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type AddressMinFields = {
  __typename?: "addressMinFields";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "utils_address" */
export type AddressMutationResponse = {
  __typename?: "addressMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "utils_address" */
export type AddressObjRelInsertInput = {
  data: AddressInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AddressOnConflict>;
};

/** on_conflict condition type for table "utils_address" */
export type AddressOnConflict = {
  constraint: AddressConstraint;
  updateColumns?: Array<AddressUpdateColumn>;
  where?: InputMaybe<AddressBoolExp>;
};

/** Ordering options when selecting data from "utils_address". */
export type AddressOrderBy = {
  addressLine1?: InputMaybe<OrderBy>;
  addressLine2?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  clientsAggregate?: InputMaybe<ClientAggregateOrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorsAggregate?: InputMaybe<MedicalDirectorAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  zipCode?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: utils_address */
export type AddressPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "utils_address" */
export enum AddressSelectColumn {
  /** column name */
  AddressLine1 = "addressLine1",
  /** column name */
  AddressLine2 = "addressLine2",
  /** column name */
  City = "city",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  State = "state",
  /** column name */
  ZipCode = "zipCode",
}

/** input type for updating data in table "utils_address" */
export type AddressSetInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type AddressStddevFields = {
  __typename?: "addressStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type AddressStddevPopFields = {
  __typename?: "addressStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type AddressStddevSampFields = {
  __typename?: "addressStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "address" */
export type AddressStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AddressStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AddressStreamCursorValueInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type AddressSumFields = {
  __typename?: "addressSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "utils_address" */
export enum AddressUpdateColumn {
  /** column name */
  AddressLine1 = "addressLine1",
  /** column name */
  AddressLine2 = "addressLine2",
  /** column name */
  City = "city",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  State = "state",
  /** column name */
  ZipCode = "zipCode",
}

export type AddressUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AddressIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AddressSetInput>;
  /** filter the rows which have to be updated */
  where: AddressBoolExp;
};

/** aggregate varPop on columns */
export type AddressVarPopFields = {
  __typename?: "addressVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type AddressVarSampFields = {
  __typename?: "addressVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type AddressVarianceFields = {
  __typename?: "addressVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type AdverseReactionAggregateBoolExpBool_And = {
  arguments: AdverseReactionSelectColumnAdverseReactionAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<AdverseReactionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AdverseReactionAggregateBoolExpBool_Or = {
  arguments: AdverseReactionSelectColumnAdverseReactionAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<AdverseReactionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AdverseReactionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<AdverseReactionBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "scheduling_availabilityevent" */
export type AvailabilityEvent = {
  __typename?: "availabilityEvent";
  allDay: Scalars["Boolean"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  eventType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  label: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  providerId: Scalars["bigint"]["output"];
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
  timeSpan: Scalars["tstzrange"]["output"];
  /** An object relationship */
  userMedspa: UserMedspa;
};

/** aggregated selection of "scheduling_availabilityevent" */
export type AvailabilityEventAggregate = {
  __typename?: "availabilityEventAggregate";
  aggregate?: Maybe<AvailabilityEventAggregateFields>;
  nodes: Array<AvailabilityEvent>;
};

export type AvailabilityEventAggregateBoolExp = {
  bool_and?: InputMaybe<AvailabilityEventAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<AvailabilityEventAggregateBoolExpBool_Or>;
  count?: InputMaybe<AvailabilityEventAggregateBoolExpCount>;
};

export type AvailabilityEventAggregateBoolExpBool_And = {
  arguments: AvailabilityEventSelectColumnAvailabilityEventAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<AvailabilityEventBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AvailabilityEventAggregateBoolExpBool_Or = {
  arguments: AvailabilityEventSelectColumnAvailabilityEventAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<AvailabilityEventBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AvailabilityEventAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AvailabilityEventSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<AvailabilityEventBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "scheduling_availabilityevent" */
export type AvailabilityEventAggregateFields = {
  __typename?: "availabilityEventAggregateFields";
  avg?: Maybe<AvailabilityEventAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<AvailabilityEventMaxFields>;
  min?: Maybe<AvailabilityEventMinFields>;
  stddev?: Maybe<AvailabilityEventStddevFields>;
  stddevPop?: Maybe<AvailabilityEventStddevPopFields>;
  stddevSamp?: Maybe<AvailabilityEventStddevSampFields>;
  sum?: Maybe<AvailabilityEventSumFields>;
  varPop?: Maybe<AvailabilityEventVarPopFields>;
  varSamp?: Maybe<AvailabilityEventVarSampFields>;
  variance?: Maybe<AvailabilityEventVarianceFields>;
};

/** aggregate fields of "scheduling_availabilityevent" */
export type AvailabilityEventAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AvailabilityEventSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "scheduling_availabilityevent" */
export type AvailabilityEventAggregateOrderBy = {
  avg?: InputMaybe<AvailabilityEventAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AvailabilityEventMaxOrderBy>;
  min?: InputMaybe<AvailabilityEventMinOrderBy>;
  stddev?: InputMaybe<AvailabilityEventStddevOrderBy>;
  stddevPop?: InputMaybe<AvailabilityEventStddevPopOrderBy>;
  stddevSamp?: InputMaybe<AvailabilityEventStddevSampOrderBy>;
  sum?: InputMaybe<AvailabilityEventSumOrderBy>;
  varPop?: InputMaybe<AvailabilityEventVarPopOrderBy>;
  varSamp?: InputMaybe<AvailabilityEventVarSampOrderBy>;
  variance?: InputMaybe<AvailabilityEventVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "scheduling_availabilityevent" */
export type AvailabilityEventArrRelInsertInput = {
  data: Array<AvailabilityEventInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AvailabilityEventOnConflict>;
};

/** aggregate avg on columns */
export type AvailabilityEventAvgFields = {
  __typename?: "availabilityEventAvgFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventAvgOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "scheduling_availabilityevent". All fields are combined with a logical 'AND'. */
export type AvailabilityEventBoolExp = {
  _and?: InputMaybe<Array<AvailabilityEventBoolExp>>;
  _not?: InputMaybe<AvailabilityEventBoolExp>;
  _or?: InputMaybe<Array<AvailabilityEventBoolExp>>;
  allDay?: InputMaybe<BooleanComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  endTime?: InputMaybe<TimestamptzComparisonExp>;
  eventType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  startTime?: InputMaybe<TimestamptzComparisonExp>;
  timeSpan?: InputMaybe<TstzrangeComparisonExp>;
  userMedspa?: InputMaybe<UserMedspaBoolExp>;
};

/** unique or primary key constraints on table "scheduling_availabilityevent" */
export enum AvailabilityEventConstraint {
  /** unique or primary key constraint on columns "id" */
  SchedulingAvailabilityeventPkey = "scheduling_availabilityevent_pkey",
}

/** input type for incrementing numeric columns in table "scheduling_availabilityevent" */
export type AvailabilityEventIncInput = {
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "scheduling_availabilityevent" */
export type AvailabilityEventInsertInput = {
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  eventType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
  userMedspa?: InputMaybe<UserMedspaObjRelInsertInput>;
};

/** aggregate max on columns */
export type AvailabilityEventMaxFields = {
  __typename?: "availabilityEventMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  eventType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  eventType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AvailabilityEventMinFields = {
  __typename?: "availabilityEventMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  endTime?: Maybe<Scalars["timestamptz"]["output"]>;
  eventType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  eventType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "scheduling_availabilityevent" */
export type AvailabilityEventMutationResponse = {
  __typename?: "availabilityEventMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<AvailabilityEvent>;
};

/** on_conflict condition type for table "scheduling_availabilityevent" */
export type AvailabilityEventOnConflict = {
  constraint: AvailabilityEventConstraint;
  updateColumns?: Array<AvailabilityEventUpdateColumn>;
  where?: InputMaybe<AvailabilityEventBoolExp>;
};

/** Ordering options when selecting data from "scheduling_availabilityevent". */
export type AvailabilityEventOrderBy = {
  allDay?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  eventType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  timeSpan?: InputMaybe<OrderBy>;
  userMedspa?: InputMaybe<UserMedspaOrderBy>;
};

/** primary key columns input for table: scheduling_availabilityevent */
export type AvailabilityEventPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "scheduling_availabilityevent" */
export enum AvailabilityEventSelectColumn {
  /** column name */
  AllDay = "allDay",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  EndTime = "endTime",
  /** column name */
  EventType = "eventType",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  StartTime = "startTime",
  /** column name */
  TimeSpan = "timeSpan",
}

/** select "availabilityEventAggregateBoolExpBool_andArgumentsColumns" columns of table "scheduling_availabilityevent" */
export enum AvailabilityEventSelectColumnAvailabilityEventAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  AllDay = "allDay",
}

/** select "availabilityEventAggregateBoolExpBool_orArgumentsColumns" columns of table "scheduling_availabilityevent" */
export enum AvailabilityEventSelectColumnAvailabilityEventAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  AllDay = "allDay",
}

/** input type for updating data in table "scheduling_availabilityevent" */
export type AvailabilityEventSetInput = {
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  eventType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
};

/** aggregate stddev on columns */
export type AvailabilityEventStddevFields = {
  __typename?: "availabilityEventStddevFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventStddevOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type AvailabilityEventStddevPopFields = {
  __typename?: "availabilityEventStddevPopFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventStddevPopOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type AvailabilityEventStddevSampFields = {
  __typename?: "availabilityEventStddevSampFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventStddevSampOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "availabilityEvent" */
export type AvailabilityEventStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AvailabilityEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AvailabilityEventStreamCursorValueInput = {
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  endTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  eventType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  startTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  timeSpan?: InputMaybe<Scalars["tstzrange"]["input"]>;
};

/** aggregate sum on columns */
export type AvailabilityEventSumFields = {
  __typename?: "availabilityEventSumFields";
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventSumOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** update columns of table "scheduling_availabilityevent" */
export enum AvailabilityEventUpdateColumn {
  /** column name */
  AllDay = "allDay",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  EventType = "eventType",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  TimeSpan = "timeSpan",
}

export type AvailabilityEventUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AvailabilityEventIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AvailabilityEventSetInput>;
  /** filter the rows which have to be updated */
  where: AvailabilityEventBoolExp;
};

/** aggregate varPop on columns */
export type AvailabilityEventVarPopFields = {
  __typename?: "availabilityEventVarPopFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventVarPopOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type AvailabilityEventVarSampFields = {
  __typename?: "availabilityEventVarSampFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventVarSampOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AvailabilityEventVarianceFields = {
  __typename?: "availabilityEventVarianceFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "scheduling_availabilityevent" */
export type AvailabilityEventVarianceOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** These 'forms' are actually just instructions and not meant to be signed by the patients (don't require a submission step). It is not the same kind of 'Form' as your regular ones. */
export type CareForm = {
  __typename?: "careForm";
  created: Scalars["timestamptz"]["output"];
  deleted: Scalars["Boolean"]["output"];
  formType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  /** An array relationship */
  serviceMenuItems: Array<CareFormServiceMenuItems>;
  /** An aggregate relationship */
  serviceMenuItemsAggregate: CareFormServiceMenuItemsAggregate;
  text: Scalars["String"]["output"];
};

/** These 'forms' are actually just instructions and not meant to be signed by the patients (don't require a submission step). It is not the same kind of 'Form' as your regular ones. */
export type CareFormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

/** These 'forms' are actually just instructions and not meant to be signed by the patients (don't require a submission step). It is not the same kind of 'Form' as your regular ones. */
export type CareFormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

/** aggregated selection of "resources_careform" */
export type CareFormAggregate = {
  __typename?: "careFormAggregate";
  aggregate?: Maybe<CareFormAggregateFields>;
  nodes: Array<CareForm>;
};

export type CareFormAggregateBoolExp = {
  bool_and?: InputMaybe<CareFormAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CareFormAggregateBoolExpBool_Or>;
  count?: InputMaybe<CareFormAggregateBoolExpCount>;
};

export type CareFormAggregateBoolExpBool_And = {
  arguments: CareFormSelectColumnCareFormAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<CareFormBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CareFormAggregateBoolExpBool_Or = {
  arguments: CareFormSelectColumnCareFormAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<CareFormBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CareFormAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CareFormSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<CareFormBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_careform" */
export type CareFormAggregateFields = {
  __typename?: "careFormAggregateFields";
  avg?: Maybe<CareFormAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<CareFormMaxFields>;
  min?: Maybe<CareFormMinFields>;
  stddev?: Maybe<CareFormStddevFields>;
  stddevPop?: Maybe<CareFormStddevPopFields>;
  stddevSamp?: Maybe<CareFormStddevSampFields>;
  sum?: Maybe<CareFormSumFields>;
  varPop?: Maybe<CareFormVarPopFields>;
  varSamp?: Maybe<CareFormVarSampFields>;
  variance?: Maybe<CareFormVarianceFields>;
};

/** aggregate fields of "resources_careform" */
export type CareFormAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CareFormSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_careform" */
export type CareFormAggregateOrderBy = {
  avg?: InputMaybe<CareFormAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CareFormMaxOrderBy>;
  min?: InputMaybe<CareFormMinOrderBy>;
  stddev?: InputMaybe<CareFormStddevOrderBy>;
  stddevPop?: InputMaybe<CareFormStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CareFormStddevSampOrderBy>;
  sum?: InputMaybe<CareFormSumOrderBy>;
  varPop?: InputMaybe<CareFormVarPopOrderBy>;
  varSamp?: InputMaybe<CareFormVarSampOrderBy>;
  variance?: InputMaybe<CareFormVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_careform" */
export type CareFormArrRelInsertInput = {
  data: Array<CareFormInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CareFormOnConflict>;
};

/** aggregate avg on columns */
export type CareFormAvgFields = {
  __typename?: "careFormAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_careform" */
export type CareFormAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_careform". All fields are combined with a logical 'AND'. */
export type CareFormBoolExp = {
  _and?: InputMaybe<Array<CareFormBoolExp>>;
  _not?: InputMaybe<CareFormBoolExp>;
  _or?: InputMaybe<Array<CareFormBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  formType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serviceMenuItems?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
  serviceMenuItemsAggregate?: InputMaybe<CareFormServiceMenuItemsAggregateBoolExp>;
  text?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "resources_careform" */
export enum CareFormConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesCareformPkey = "resources_careform_pkey",
}

/** input type for incrementing numeric columns in table "resources_careform" */
export type CareFormIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_careform" */
export type CareFormInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  formType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  serviceMenuItems?: InputMaybe<CareFormServiceMenuItemsArrRelInsertInput>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type CareFormMaxFields = {
  __typename?: "careFormMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  formType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "resources_careform" */
export type CareFormMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  formType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CareFormMinFields = {
  __typename?: "careFormMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  formType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "resources_careform" */
export type CareFormMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  formType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_careform" */
export type CareFormMutationResponse = {
  __typename?: "careFormMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<CareForm>;
};

/** input type for inserting object relation for remote table "resources_careform" */
export type CareFormObjRelInsertInput = {
  data: CareFormInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CareFormOnConflict>;
};

/** on_conflict condition type for table "resources_careform" */
export type CareFormOnConflict = {
  constraint: CareFormConstraint;
  updateColumns?: Array<CareFormUpdateColumn>;
  where?: InputMaybe<CareFormBoolExp>;
};

/** Ordering options when selecting data from "resources_careform". */
export type CareFormOrderBy = {
  created?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  formType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serviceMenuItemsAggregate?: InputMaybe<CareFormServiceMenuItemsAggregateOrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_careform */
export type CareFormPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_careform" */
export enum CareFormSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  FormType = "formType",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Text = "text",
}

/** select "careFormAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_careform" */
export enum CareFormSelectColumnCareFormAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** select "careFormAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_careform" */
export enum CareFormSelectColumnCareFormAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** columns and relationships of "resources_careform_service_menu_items" */
export type CareFormServiceMenuItems = {
  __typename?: "careFormServiceMenuItems";
  /** An object relationship */
  careform: CareForm;
  careformId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  medspaservicemenuitemId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceMenuItem: MedspaServiceMenuItem;
};

/** aggregated selection of "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsAggregate = {
  __typename?: "careFormServiceMenuItemsAggregate";
  aggregate?: Maybe<CareFormServiceMenuItemsAggregateFields>;
  nodes: Array<CareFormServiceMenuItems>;
};

export type CareFormServiceMenuItemsAggregateBoolExp = {
  count?: InputMaybe<CareFormServiceMenuItemsAggregateBoolExpCount>;
};

export type CareFormServiceMenuItemsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsAggregateFields = {
  __typename?: "careFormServiceMenuItemsAggregateFields";
  avg?: Maybe<CareFormServiceMenuItemsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<CareFormServiceMenuItemsMaxFields>;
  min?: Maybe<CareFormServiceMenuItemsMinFields>;
  stddev?: Maybe<CareFormServiceMenuItemsStddevFields>;
  stddevPop?: Maybe<CareFormServiceMenuItemsStddevPopFields>;
  stddevSamp?: Maybe<CareFormServiceMenuItemsStddevSampFields>;
  sum?: Maybe<CareFormServiceMenuItemsSumFields>;
  varPop?: Maybe<CareFormServiceMenuItemsVarPopFields>;
  varSamp?: Maybe<CareFormServiceMenuItemsVarSampFields>;
  variance?: Maybe<CareFormServiceMenuItemsVarianceFields>;
};

/** aggregate fields of "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsAggregateOrderBy = {
  avg?: InputMaybe<CareFormServiceMenuItemsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CareFormServiceMenuItemsMaxOrderBy>;
  min?: InputMaybe<CareFormServiceMenuItemsMinOrderBy>;
  stddev?: InputMaybe<CareFormServiceMenuItemsStddevOrderBy>;
  stddevPop?: InputMaybe<CareFormServiceMenuItemsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CareFormServiceMenuItemsStddevSampOrderBy>;
  sum?: InputMaybe<CareFormServiceMenuItemsSumOrderBy>;
  varPop?: InputMaybe<CareFormServiceMenuItemsVarPopOrderBy>;
  varSamp?: InputMaybe<CareFormServiceMenuItemsVarSampOrderBy>;
  variance?: InputMaybe<CareFormServiceMenuItemsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsArrRelInsertInput = {
  data: Array<CareFormServiceMenuItemsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CareFormServiceMenuItemsOnConflict>;
};

/** aggregate avg on columns */
export type CareFormServiceMenuItemsAvgFields = {
  __typename?: "careFormServiceMenuItemsAvgFields";
  careformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsAvgOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_careform_service_menu_items". All fields are combined with a logical 'AND'. */
export type CareFormServiceMenuItemsBoolExp = {
  _and?: InputMaybe<Array<CareFormServiceMenuItemsBoolExp>>;
  _not?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
  _or?: InputMaybe<Array<CareFormServiceMenuItemsBoolExp>>;
  careform?: InputMaybe<CareFormBoolExp>;
  careformId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaservicemenuitemId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** unique or primary key constraints on table "resources_careform_service_menu_items" */
export enum CareFormServiceMenuItemsConstraint {
  /** unique or primary key constraint on columns "medspaservicemenuitem_id", "careform_id" */
  ResourcesCareformServiCareformIdMedspaservicE63c14e6Uniq = "resources_careform_servi_careform_id_medspaservic_e63c14e6_uniq",
  /** unique or primary key constraint on columns "id" */
  ResourcesCareformServiceMenuItemsPkey = "resources_careform_service_menu_items_pkey",
}

/** input type for incrementing numeric columns in table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsIncInput = {
  careformId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsInsertInput = {
  careform?: InputMaybe<CareFormObjRelInsertInput>;
  careformId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
};

/** aggregate max on columns */
export type CareFormServiceMenuItemsMaxFields = {
  __typename?: "careFormServiceMenuItemsMaxFields";
  careformId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsMaxOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CareFormServiceMenuItemsMinFields = {
  __typename?: "careFormServiceMenuItemsMinFields";
  careformId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsMinOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsMutationResponse = {
  __typename?: "careFormServiceMenuItemsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<CareFormServiceMenuItems>;
};

/** on_conflict condition type for table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsOnConflict = {
  constraint: CareFormServiceMenuItemsConstraint;
  updateColumns?: Array<CareFormServiceMenuItemsUpdateColumn>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

/** Ordering options when selecting data from "resources_careform_service_menu_items". */
export type CareFormServiceMenuItemsOrderBy = {
  careform?: InputMaybe<CareFormOrderBy>;
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
};

/** primary key columns input for table: resources_careform_service_menu_items */
export type CareFormServiceMenuItemsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_careform_service_menu_items" */
export enum CareFormServiceMenuItemsSelectColumn {
  /** column name */
  CareformId = "careformId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
}

/** input type for updating data in table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsSetInput = {
  careformId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type CareFormServiceMenuItemsStddevFields = {
  __typename?: "careFormServiceMenuItemsStddevFields";
  careformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsStddevOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CareFormServiceMenuItemsStddevPopFields = {
  __typename?: "careFormServiceMenuItemsStddevPopFields";
  careformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsStddevPopOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CareFormServiceMenuItemsStddevSampFields = {
  __typename?: "careFormServiceMenuItemsStddevSampFields";
  careformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsStddevSampOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "careFormServiceMenuItems" */
export type CareFormServiceMenuItemsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CareFormServiceMenuItemsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CareFormServiceMenuItemsStreamCursorValueInput = {
  careformId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type CareFormServiceMenuItemsSumFields = {
  __typename?: "careFormServiceMenuItemsSumFields";
  careformId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsSumOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_careform_service_menu_items" */
export enum CareFormServiceMenuItemsUpdateColumn {
  /** column name */
  CareformId = "careformId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
}

export type CareFormServiceMenuItemsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CareFormServiceMenuItemsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CareFormServiceMenuItemsSetInput>;
  /** filter the rows which have to be updated */
  where: CareFormServiceMenuItemsBoolExp;
};

/** aggregate varPop on columns */
export type CareFormServiceMenuItemsVarPopFields = {
  __typename?: "careFormServiceMenuItemsVarPopFields";
  careformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsVarPopOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CareFormServiceMenuItemsVarSampFields = {
  __typename?: "careFormServiceMenuItemsVarSampFields";
  careformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsVarSampOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CareFormServiceMenuItemsVarianceFields = {
  __typename?: "careFormServiceMenuItemsVarianceFields";
  careformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_careform_service_menu_items" */
export type CareFormServiceMenuItemsVarianceOrderBy = {
  careformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** input type for updating data in table "resources_careform" */
export type CareFormSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  formType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type CareFormStddevFields = {
  __typename?: "careFormStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_careform" */
export type CareFormStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CareFormStddevPopFields = {
  __typename?: "careFormStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_careform" */
export type CareFormStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CareFormStddevSampFields = {
  __typename?: "careFormStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_careform" */
export type CareFormStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "careForm" */
export type CareFormStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CareFormStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CareFormStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  formType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type CareFormSumFields = {
  __typename?: "careFormSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_careform" */
export type CareFormSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_careform" */
export enum CareFormUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  FormType = "formType",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Text = "text",
}

export type CareFormUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CareFormIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CareFormSetInput>;
  /** filter the rows which have to be updated */
  where: CareFormBoolExp;
};

/** aggregate varPop on columns */
export type CareFormVarPopFields = {
  __typename?: "careFormVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_careform" */
export type CareFormVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CareFormVarSampFields = {
  __typename?: "careFormVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_careform" */
export type CareFormVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CareFormVarianceFields = {
  __typename?: "careFormVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_careform" */
export type CareFormVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspas_client" */
export type Client = {
  __typename?: "client";
  active: Scalars["Boolean"]["output"];
  /** An object relationship */
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  adverseReactions: Array<AdverseReaction>;
  /** An aggregate relationship */
  adverseReactionsAggregate: AdverseReactionAggregate;
  birthdate?: Maybe<Scalars["date"]["output"]>;
  /** An array relationship */
  clientAccessTokens: Array<ClientAccessToken>;
  /** An aggregate relationship */
  clientAccessTokensAggregate: ClientAccessTokenAggregate;
  clientFiles: Array<ClientFileType>;
  /** An array relationship */
  clientMemberships: Array<ClientMembership>;
  /** An aggregate relationship */
  clientMembershipsAggregate: ClientMembershipAggregate;
  /** An array relationship */
  consentFormSignatures: Array<ConsentFormSignature>;
  /** An aggregate relationship */
  consentFormSignaturesAggregate: ConsentFormSignatureAggregate;
  created: Scalars["timestamptz"]["output"];
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  /** An array relationship */
  formSubmissions: Array<FormSubmission>;
  /** An aggregate relationship */
  formSubmissionsAggregate: FormSubmissionAggregate;
  /** A computed field, executes function "client_full_name" */
  fullName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  gfeTreatmentIndications: Array<GfeTreatmentIndication>;
  /** An aggregate relationship */
  gfeTreatmentIndicationsAggregate: GfeTreatmentIndicationAggregate;
  /** Stores information if at least one Adverse Reaction has ever been reported for this client. */
  hadAdverseReaction: Scalars["Boolean"]["output"];
  highLevelContactId: Scalars["String"]["output"];
  highLevelLastMessageBody: Scalars["String"]["output"];
  highLevelLastMessageDate?: Maybe<Scalars["timestamptz"]["output"]>;
  highLevelLastMessageType: Scalars["String"]["output"];
  highLevelSyncStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the status of the HighLevel sync for the client */
  highLevelSyncStatus: Scalars["String"]["output"];
  highLevelUnreadMessagesCount?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["bigint"]["output"];
  intakeFormPhotos?: Maybe<Array<Maybe<FormSubmissionVersionType>>>;
  /** An array relationship */
  inventoryTransactions: Array<InventoryTransaction>;
  /** An aggregate relationship */
  inventoryTransactionsAggregate: InventoryTransactionAggregate;
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoicesAggregate: InvoiceAggregate;
  knowsMedspaFrom?: Maybe<Scalars["String"]["output"]>;
  /** When GFE review request has both synchronous and asynchronous treatments, the reviewed_at timestamp is only set when full review is done. This computed field is used for async GFE reviewers that don't care about the synchronous part. */
  lastAsyncGfeReview?: Maybe<Array<GfeReviewRequest>>;
  /** A computed field, executes function "client_last_gfe_review" */
  lastGfeReview?: Maybe<Array<GfeReviewRequest>>;
  /** An array relationship */
  lastGfeTreatmentIndications: Array<LastGfeReviewTreatmentIndication>;
  /** An aggregate relationship */
  lastGfeTreatmentIndicationsAggregate: LastGfeReviewTreatmentIndicationAggregate;
  lastName: Scalars["String"]["output"];
  /** A computed field, executes function "client_last_no_show_visit" */
  lastNoShowVisit?: Maybe<Array<Visit>>;
  lastSentFormsAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** A computed field, executes function "client_most_recent_visit" */
  mostRecentVisit?: Maybe<Array<Visit>>;
  /** A computed field, executes function "client_next_visit" */
  nextVisit?: Maybe<Array<Visit>>;
  note: Scalars["String"]["output"];
  /** A computed field, executes function "client_pending_gfe_review" */
  pendingGfeReview?: Maybe<Array<GfeReviewRequest>>;
  phone: Scalars["String"]["output"];
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  stripeData?: Maybe<ClientStripeType>;
  twilioConversationId: Scalars["String"]["output"];
  twilioLastMessage?: Maybe<Scalars["String"]["output"]>;
  twilioLastMessageTimestamp?: Maybe<Scalars["timestamptz"]["output"]>;
  twilioOptedOutOfSms: Scalars["Boolean"]["output"];
  /** An array relationship */
  visits: Array<Visit>;
  /** An aggregate relationship */
  visitsAggregate: VisitAggregate;
  wallet?: Maybe<ClientWalletType>;
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot: Scalars["jsonb"]["output"];
  /** Stores the date when the wallet balance snapshot was created */
  walletSnapshotCreatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  walletTransactionSources: Array<WalletTransactionSourceTmp>;
  /** An aggregate relationship */
  walletTransactionSourcesAggregate: WalletTransactionSourceTmpAggregate;
  /** An array relationship */
  walletTransactions: Array<WalletTransaction>;
  /** An aggregate relationship */
  walletTransactionsAggregate: WalletTransactionAggregate;
};

/** columns and relationships of "medspas_client" */
export type ClientAdverseReactionsArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionOrderBy>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientAdverseReactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionOrderBy>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientClientAccessTokensArgs = {
  distinctOn?: InputMaybe<Array<ClientAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientAccessTokenOrderBy>>;
  where?: InputMaybe<ClientAccessTokenBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientClientAccessTokensAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientAccessTokenOrderBy>>;
  where?: InputMaybe<ClientAccessTokenBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientClientMembershipsArgs = {
  distinctOn?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientMembershipOrderBy>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientClientMembershipsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientMembershipOrderBy>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientConsentFormSignaturesArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientConsentFormSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientFormSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientFormSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientGfeTreatmentIndicationsArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientGfeTreatmentIndicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientInventoryTransactionsArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientInventoryTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientInvoicesArgs = {
  distinctOn?: InputMaybe<Array<InvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceOrderBy>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientInvoicesAggregateArgs = {
  distinctOn?: InputMaybe<Array<InvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceOrderBy>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientLastAsyncGfeReviewArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientLastGfeReviewArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientLastGfeTreatmentIndicationsArgs = {
  distinctOn?: InputMaybe<Array<LastGfeReviewTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LastGfeReviewTreatmentIndicationOrderBy>>;
  where?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientLastGfeTreatmentIndicationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LastGfeReviewTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LastGfeReviewTreatmentIndicationOrderBy>>;
  where?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientLastNoShowVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientMostRecentVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientNextVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientPendingGfeReviewArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientVisitsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientWalletBalanceSnapshotArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "medspas_client" */
export type ClientWalletTransactionSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientWalletTransactionSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientWalletTransactionsArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionOrderBy>>;
  where?: InputMaybe<WalletTransactionBoolExp>;
};

/** columns and relationships of "medspas_client" */
export type ClientWalletTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionOrderBy>>;
  where?: InputMaybe<WalletTransactionBoolExp>;
};

/** columns and relationships of "medspas_clientaccesstoken" */
export type ClientAccessToken = {
  __typename?: "clientAccessToken";
  /** An object relationship */
  client: Client;
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  expiresAt: Scalars["timestamptz"]["output"];
  modified: Scalars["timestamptz"]["output"];
  token: Scalars["String"]["output"];
};

/** aggregated selection of "medspas_clientaccesstoken" */
export type ClientAccessTokenAggregate = {
  __typename?: "clientAccessTokenAggregate";
  aggregate?: Maybe<ClientAccessTokenAggregateFields>;
  nodes: Array<ClientAccessToken>;
};

export type ClientAccessTokenAggregateBoolExp = {
  count?: InputMaybe<ClientAccessTokenAggregateBoolExpCount>;
};

export type ClientAccessTokenAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClientAccessTokenSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ClientAccessTokenBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_clientaccesstoken" */
export type ClientAccessTokenAggregateFields = {
  __typename?: "clientAccessTokenAggregateFields";
  avg?: Maybe<ClientAccessTokenAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ClientAccessTokenMaxFields>;
  min?: Maybe<ClientAccessTokenMinFields>;
  stddev?: Maybe<ClientAccessTokenStddevFields>;
  stddevPop?: Maybe<ClientAccessTokenStddevPopFields>;
  stddevSamp?: Maybe<ClientAccessTokenStddevSampFields>;
  sum?: Maybe<ClientAccessTokenSumFields>;
  varPop?: Maybe<ClientAccessTokenVarPopFields>;
  varSamp?: Maybe<ClientAccessTokenVarSampFields>;
  variance?: Maybe<ClientAccessTokenVarianceFields>;
};

/** aggregate fields of "medspas_clientaccesstoken" */
export type ClientAccessTokenAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClientAccessTokenSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_clientaccesstoken" */
export type ClientAccessTokenAggregateOrderBy = {
  avg?: InputMaybe<ClientAccessTokenAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClientAccessTokenMaxOrderBy>;
  min?: InputMaybe<ClientAccessTokenMinOrderBy>;
  stddev?: InputMaybe<ClientAccessTokenStddevOrderBy>;
  stddevPop?: InputMaybe<ClientAccessTokenStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ClientAccessTokenStddevSampOrderBy>;
  sum?: InputMaybe<ClientAccessTokenSumOrderBy>;
  varPop?: InputMaybe<ClientAccessTokenVarPopOrderBy>;
  varSamp?: InputMaybe<ClientAccessTokenVarSampOrderBy>;
  variance?: InputMaybe<ClientAccessTokenVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_clientaccesstoken" */
export type ClientAccessTokenArrRelInsertInput = {
  data: Array<ClientAccessTokenInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClientAccessTokenOnConflict>;
};

/** aggregate avg on columns */
export type ClientAccessTokenAvgFields = {
  __typename?: "clientAccessTokenAvgFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenAvgOrderBy = {
  clientId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_clientaccesstoken". All fields are combined with a logical 'AND'. */
export type ClientAccessTokenBoolExp = {
  _and?: InputMaybe<Array<ClientAccessTokenBoolExp>>;
  _not?: InputMaybe<ClientAccessTokenBoolExp>;
  _or?: InputMaybe<Array<ClientAccessTokenBoolExp>>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  token?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "medspas_clientaccesstoken" */
export enum ClientAccessTokenConstraint {
  /** unique or primary key constraint on columns "token" */
  MedspasPatientaccesstokenPkey = "medspas_patientaccesstoken_pkey",
}

/** input type for incrementing numeric columns in table "medspas_clientaccesstoken" */
export type ClientAccessTokenIncInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_clientaccesstoken" */
export type ClientAccessTokenInsertInput = {
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ClientAccessTokenMaxFields = {
  __typename?: "clientAccessTokenMaxFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenMaxOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClientAccessTokenMinFields = {
  __typename?: "clientAccessTokenMinFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenMinOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_clientaccesstoken" */
export type ClientAccessTokenMutationResponse = {
  __typename?: "clientAccessTokenMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ClientAccessToken>;
};

/** on_conflict condition type for table "medspas_clientaccesstoken" */
export type ClientAccessTokenOnConflict = {
  constraint: ClientAccessTokenConstraint;
  updateColumns?: Array<ClientAccessTokenUpdateColumn>;
  where?: InputMaybe<ClientAccessTokenBoolExp>;
};

/** Ordering options when selecting data from "medspas_clientaccesstoken". */
export type ClientAccessTokenOrderBy = {
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_clientaccesstoken */
export type ClientAccessTokenPkColumnsInput = {
  token: Scalars["String"]["input"];
};

/** select columns of table "medspas_clientaccesstoken" */
export enum ClientAccessTokenSelectColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Modified = "modified",
  /** column name */
  Token = "token",
}

/** input type for updating data in table "medspas_clientaccesstoken" */
export type ClientAccessTokenSetInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ClientAccessTokenStddevFields = {
  __typename?: "clientAccessTokenStddevFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenStddevOrderBy = {
  clientId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ClientAccessTokenStddevPopFields = {
  __typename?: "clientAccessTokenStddevPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenStddevPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ClientAccessTokenStddevSampFields = {
  __typename?: "clientAccessTokenStddevSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenStddevSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "clientAccessToken" */
export type ClientAccessTokenStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClientAccessTokenStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientAccessTokenStreamCursorValueInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ClientAccessTokenSumFields = {
  __typename?: "clientAccessTokenSumFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenSumOrderBy = {
  clientId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_clientaccesstoken" */
export enum ClientAccessTokenUpdateColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Modified = "modified",
  /** column name */
  Token = "token",
}

export type ClientAccessTokenUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClientAccessTokenIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientAccessTokenSetInput>;
  /** filter the rows which have to be updated */
  where: ClientAccessTokenBoolExp;
};

/** aggregate varPop on columns */
export type ClientAccessTokenVarPopFields = {
  __typename?: "clientAccessTokenVarPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenVarPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ClientAccessTokenVarSampFields = {
  __typename?: "clientAccessTokenVarSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenVarSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClientAccessTokenVarianceFields = {
  __typename?: "clientAccessTokenVarianceFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_clientaccesstoken" */
export type ClientAccessTokenVarianceOrderBy = {
  clientId?: InputMaybe<OrderBy>;
};

/** aggregated selection of "medspas_client" */
export type ClientAggregate = {
  __typename?: "clientAggregate";
  aggregate?: Maybe<ClientAggregateFields>;
  nodes: Array<Client>;
};

export type ClientAggregateBoolExp = {
  bool_and?: InputMaybe<ClientAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ClientAggregateBoolExpBool_Or>;
  count?: InputMaybe<ClientAggregateBoolExpCount>;
};

export type ClientAggregateBoolExpBool_And = {
  arguments: ClientSelectColumnClientAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ClientBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClientAggregateBoolExpBool_Or = {
  arguments: ClientSelectColumnClientAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ClientBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClientAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClientSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ClientBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_client" */
export type ClientAggregateFields = {
  __typename?: "clientAggregateFields";
  avg?: Maybe<ClientAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ClientMaxFields>;
  min?: Maybe<ClientMinFields>;
  stddev?: Maybe<ClientStddevFields>;
  stddevPop?: Maybe<ClientStddevPopFields>;
  stddevSamp?: Maybe<ClientStddevSampFields>;
  sum?: Maybe<ClientSumFields>;
  varPop?: Maybe<ClientVarPopFields>;
  varSamp?: Maybe<ClientVarSampFields>;
  variance?: Maybe<ClientVarianceFields>;
};

/** aggregate fields of "medspas_client" */
export type ClientAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClientSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_client" */
export type ClientAggregateOrderBy = {
  avg?: InputMaybe<ClientAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClientMaxOrderBy>;
  min?: InputMaybe<ClientMinOrderBy>;
  stddev?: InputMaybe<ClientStddevOrderBy>;
  stddevPop?: InputMaybe<ClientStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ClientStddevSampOrderBy>;
  sum?: InputMaybe<ClientSumOrderBy>;
  varPop?: InputMaybe<ClientVarPopOrderBy>;
  varSamp?: InputMaybe<ClientVarSampOrderBy>;
  variance?: InputMaybe<ClientVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClientAppendInput = {
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "medspas_client" */
export type ClientArrRelInsertInput = {
  data: Array<ClientInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClientOnConflict>;
};

/** aggregate avg on columns */
export type ClientAvgFields = {
  __typename?: "clientAvgFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_client" */
export type ClientAvgOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_client". All fields are combined with a logical 'AND'. */
export type ClientBoolExp = {
  _and?: InputMaybe<Array<ClientBoolExp>>;
  _not?: InputMaybe<ClientBoolExp>;
  _or?: InputMaybe<Array<ClientBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  address?: InputMaybe<AddressBoolExp>;
  addressId?: InputMaybe<BigintComparisonExp>;
  adverseReactions?: InputMaybe<AdverseReactionBoolExp>;
  adverseReactionsAggregate?: InputMaybe<AdverseReactionAggregateBoolExp>;
  birthdate?: InputMaybe<DateComparisonExp>;
  clientAccessTokens?: InputMaybe<ClientAccessTokenBoolExp>;
  clientAccessTokensAggregate?: InputMaybe<ClientAccessTokenAggregateBoolExp>;
  clientMemberships?: InputMaybe<ClientMembershipBoolExp>;
  clientMembershipsAggregate?: InputMaybe<ClientMembershipAggregateBoolExp>;
  consentFormSignatures?: InputMaybe<ConsentFormSignatureBoolExp>;
  consentFormSignaturesAggregate?: InputMaybe<ConsentFormSignatureAggregateBoolExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  formSubmissions?: InputMaybe<FormSubmissionBoolExp>;
  formSubmissionsAggregate?: InputMaybe<FormSubmissionAggregateBoolExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  gender?: InputMaybe<StringComparisonExp>;
  gfeTreatmentIndications?: InputMaybe<GfeTreatmentIndicationBoolExp>;
  gfeTreatmentIndicationsAggregate?: InputMaybe<GfeTreatmentIndicationAggregateBoolExp>;
  hadAdverseReaction?: InputMaybe<BooleanComparisonExp>;
  highLevelContactId?: InputMaybe<StringComparisonExp>;
  highLevelLastMessageBody?: InputMaybe<StringComparisonExp>;
  highLevelLastMessageDate?: InputMaybe<TimestamptzComparisonExp>;
  highLevelLastMessageType?: InputMaybe<StringComparisonExp>;
  highLevelSyncStartedAt?: InputMaybe<TimestamptzComparisonExp>;
  highLevelSyncStatus?: InputMaybe<StringComparisonExp>;
  highLevelUnreadMessagesCount?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  inventoryTransactions?: InputMaybe<InventoryTransactionBoolExp>;
  inventoryTransactionsAggregate?: InputMaybe<InventoryTransactionAggregateBoolExp>;
  invoices?: InputMaybe<InvoiceBoolExp>;
  invoicesAggregate?: InputMaybe<InvoiceAggregateBoolExp>;
  knowsMedspaFrom?: InputMaybe<StringComparisonExp>;
  lastAsyncGfeReview?: InputMaybe<GfeReviewRequestBoolExp>;
  lastGfeReview?: InputMaybe<GfeReviewRequestBoolExp>;
  lastGfeTreatmentIndications?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
  lastGfeTreatmentIndicationsAggregate?: InputMaybe<LastGfeReviewTreatmentIndicationAggregateBoolExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  lastNoShowVisit?: InputMaybe<VisitBoolExp>;
  lastSentFormsAt?: InputMaybe<TimestamptzComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  mostRecentVisit?: InputMaybe<VisitBoolExp>;
  nextVisit?: InputMaybe<VisitBoolExp>;
  note?: InputMaybe<StringComparisonExp>;
  pendingGfeReview?: InputMaybe<GfeReviewRequestBoolExp>;
  phone?: InputMaybe<StringComparisonExp>;
  stripeCustomerId?: InputMaybe<StringComparisonExp>;
  twilioConversationId?: InputMaybe<StringComparisonExp>;
  twilioLastMessage?: InputMaybe<StringComparisonExp>;
  twilioLastMessageTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  twilioOptedOutOfSms?: InputMaybe<BooleanComparisonExp>;
  visits?: InputMaybe<VisitBoolExp>;
  visitsAggregate?: InputMaybe<VisitAggregateBoolExp>;
  walletBalanceSnapshot?: InputMaybe<JsonbComparisonExp>;
  walletSnapshotCreatedAt?: InputMaybe<TimestamptzComparisonExp>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateBoolExp>;
  walletTransactions?: InputMaybe<WalletTransactionBoolExp>;
  walletTransactionsAggregate?: InputMaybe<WalletTransactionAggregateBoolExp>;
};

/** unique or primary key constraints on table "medspas_client" */
export enum ClientConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasClientPkey = "medspas_client_pkey",
  /** unique or primary key constraint on columns "email", "medspa_id" */
  UniqueClientPerMedspa = "unique_client_per_medspa",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClientDeleteAtPathInput = {
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClientDeleteElemInput = {
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClientDeleteKeyInput = {
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot?: InputMaybe<Scalars["String"]["input"]>;
};

export type ClientGfeIndication_Medspa_Service_Menu_Item_Args = {
  arg_client_id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for incrementing numeric columns in table "medspas_client" */
export type ClientIncInput = {
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  highLevelUnreadMessagesCount?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_client" */
export type ClientInsertInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  address?: InputMaybe<AddressObjRelInsertInput>;
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  adverseReactions?: InputMaybe<AdverseReactionArrRelInsertInput>;
  birthdate?: InputMaybe<Scalars["date"]["input"]>;
  clientAccessTokens?: InputMaybe<ClientAccessTokenArrRelInsertInput>;
  clientMemberships?: InputMaybe<ClientMembershipArrRelInsertInput>;
  consentFormSignatures?: InputMaybe<ConsentFormSignatureArrRelInsertInput>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  formSubmissions?: InputMaybe<FormSubmissionArrRelInsertInput>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  gfeTreatmentIndications?: InputMaybe<GfeTreatmentIndicationArrRelInsertInput>;
  /** Stores information if at least one Adverse Reaction has ever been reported for this client. */
  hadAdverseReaction?: InputMaybe<Scalars["Boolean"]["input"]>;
  highLevelContactId?: InputMaybe<Scalars["String"]["input"]>;
  highLevelLastMessageBody?: InputMaybe<Scalars["String"]["input"]>;
  highLevelLastMessageDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
  highLevelLastMessageType?: InputMaybe<Scalars["String"]["input"]>;
  highLevelSyncStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the status of the HighLevel sync for the client */
  highLevelSyncStatus?: InputMaybe<Scalars["String"]["input"]>;
  highLevelUnreadMessagesCount?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  inventoryTransactions?: InputMaybe<InventoryTransactionArrRelInsertInput>;
  invoices?: InputMaybe<InvoiceArrRelInsertInput>;
  knowsMedspaFrom?: InputMaybe<Scalars["String"]["input"]>;
  lastGfeTreatmentIndications?: InputMaybe<LastGfeReviewTreatmentIndicationArrRelInsertInput>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastSentFormsAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationId?: InputMaybe<Scalars["String"]["input"]>;
  twilioLastMessage?: InputMaybe<Scalars["String"]["input"]>;
  twilioLastMessageTimestamp?: InputMaybe<Scalars["timestamptz"]["input"]>;
  twilioOptedOutOfSms?: InputMaybe<Scalars["Boolean"]["input"]>;
  visits?: InputMaybe<VisitArrRelInsertInput>;
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Stores the date when the wallet balance snapshot was created */
  walletSnapshotCreatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpArrRelInsertInput>;
  walletTransactions?: InputMaybe<WalletTransactionArrRelInsertInput>;
};

/** aggregate max on columns */
export type ClientMaxFields = {
  __typename?: "clientMaxFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  birthdate?: Maybe<Scalars["date"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "client_full_name" */
  fullName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  highLevelContactId?: Maybe<Scalars["String"]["output"]>;
  highLevelLastMessageBody?: Maybe<Scalars["String"]["output"]>;
  highLevelLastMessageDate?: Maybe<Scalars["timestamptz"]["output"]>;
  highLevelLastMessageType?: Maybe<Scalars["String"]["output"]>;
  highLevelSyncStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the status of the HighLevel sync for the client */
  highLevelSyncStatus?: Maybe<Scalars["String"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  knowsMedspaFrom?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastSentFormsAt?: Maybe<Scalars["timestamptz"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  twilioConversationId?: Maybe<Scalars["String"]["output"]>;
  twilioLastMessage?: Maybe<Scalars["String"]["output"]>;
  twilioLastMessageTimestamp?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the date when the wallet balance snapshot was created */
  walletSnapshotCreatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "medspas_client" */
export type ClientMaxOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  birthdate?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  highLevelContactId?: InputMaybe<OrderBy>;
  highLevelLastMessageBody?: InputMaybe<OrderBy>;
  highLevelLastMessageDate?: InputMaybe<OrderBy>;
  highLevelLastMessageType?: InputMaybe<OrderBy>;
  highLevelSyncStartedAt?: InputMaybe<OrderBy>;
  /** Stores the status of the HighLevel sync for the client */
  highLevelSyncStatus?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  knowsMedspaFrom?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastSentFormsAt?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  stripeCustomerId?: InputMaybe<OrderBy>;
  twilioConversationId?: InputMaybe<OrderBy>;
  twilioLastMessage?: InputMaybe<OrderBy>;
  twilioLastMessageTimestamp?: InputMaybe<OrderBy>;
  /** Stores the date when the wallet balance snapshot was created */
  walletSnapshotCreatedAt?: InputMaybe<OrderBy>;
};

export type ClientMembershipAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ClientMembershipBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate min on columns */
export type ClientMinFields = {
  __typename?: "clientMinFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  birthdate?: Maybe<Scalars["date"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "client_full_name" */
  fullName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  highLevelContactId?: Maybe<Scalars["String"]["output"]>;
  highLevelLastMessageBody?: Maybe<Scalars["String"]["output"]>;
  highLevelLastMessageDate?: Maybe<Scalars["timestamptz"]["output"]>;
  highLevelLastMessageType?: Maybe<Scalars["String"]["output"]>;
  highLevelSyncStartedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the status of the HighLevel sync for the client */
  highLevelSyncStatus?: Maybe<Scalars["String"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  knowsMedspaFrom?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastSentFormsAt?: Maybe<Scalars["timestamptz"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  twilioConversationId?: Maybe<Scalars["String"]["output"]>;
  twilioLastMessage?: Maybe<Scalars["String"]["output"]>;
  twilioLastMessageTimestamp?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Stores the date when the wallet balance snapshot was created */
  walletSnapshotCreatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "medspas_client" */
export type ClientMinOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  birthdate?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  highLevelContactId?: InputMaybe<OrderBy>;
  highLevelLastMessageBody?: InputMaybe<OrderBy>;
  highLevelLastMessageDate?: InputMaybe<OrderBy>;
  highLevelLastMessageType?: InputMaybe<OrderBy>;
  highLevelSyncStartedAt?: InputMaybe<OrderBy>;
  /** Stores the status of the HighLevel sync for the client */
  highLevelSyncStatus?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  knowsMedspaFrom?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastSentFormsAt?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  stripeCustomerId?: InputMaybe<OrderBy>;
  twilioConversationId?: InputMaybe<OrderBy>;
  twilioLastMessage?: InputMaybe<OrderBy>;
  twilioLastMessageTimestamp?: InputMaybe<OrderBy>;
  /** Stores the date when the wallet balance snapshot was created */
  walletSnapshotCreatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_client" */
export type ClientMutationResponse = {
  __typename?: "clientMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Client>;
};

/** input type for inserting object relation for remote table "medspas_client" */
export type ClientObjRelInsertInput = {
  data: ClientInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClientOnConflict>;
};

/** on_conflict condition type for table "medspas_client" */
export type ClientOnConflict = {
  constraint: ClientConstraint;
  updateColumns?: Array<ClientUpdateColumn>;
  where?: InputMaybe<ClientBoolExp>;
};

/** Ordering options when selecting data from "medspas_client". */
export type ClientOrderBy = {
  active?: InputMaybe<OrderBy>;
  address?: InputMaybe<AddressOrderBy>;
  addressId?: InputMaybe<OrderBy>;
  adverseReactionsAggregate?: InputMaybe<AdverseReactionAggregateOrderBy>;
  birthdate?: InputMaybe<OrderBy>;
  clientAccessTokensAggregate?: InputMaybe<ClientAccessTokenAggregateOrderBy>;
  clientMembershipsAggregate?: InputMaybe<ClientMembershipAggregateOrderBy>;
  consentFormSignaturesAggregate?: InputMaybe<ConsentFormSignatureAggregateOrderBy>;
  created?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  formSubmissionsAggregate?: InputMaybe<FormSubmissionAggregateOrderBy>;
  fullName?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  gfeTreatmentIndicationsAggregate?: InputMaybe<GfeTreatmentIndicationAggregateOrderBy>;
  hadAdverseReaction?: InputMaybe<OrderBy>;
  highLevelContactId?: InputMaybe<OrderBy>;
  highLevelLastMessageBody?: InputMaybe<OrderBy>;
  highLevelLastMessageDate?: InputMaybe<OrderBy>;
  highLevelLastMessageType?: InputMaybe<OrderBy>;
  highLevelSyncStartedAt?: InputMaybe<OrderBy>;
  highLevelSyncStatus?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inventoryTransactionsAggregate?: InputMaybe<InventoryTransactionAggregateOrderBy>;
  invoicesAggregate?: InputMaybe<InvoiceAggregateOrderBy>;
  knowsMedspaFrom?: InputMaybe<OrderBy>;
  lastAsyncGfeReviewAggregate?: InputMaybe<GfeReviewRequestAggregateOrderBy>;
  lastGfeReviewAggregate?: InputMaybe<GfeReviewRequestAggregateOrderBy>;
  lastGfeTreatmentIndicationsAggregate?: InputMaybe<LastGfeReviewTreatmentIndicationAggregateOrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastNoShowVisitAggregate?: InputMaybe<VisitAggregateOrderBy>;
  lastSentFormsAt?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  mostRecentVisitAggregate?: InputMaybe<VisitAggregateOrderBy>;
  nextVisitAggregate?: InputMaybe<VisitAggregateOrderBy>;
  note?: InputMaybe<OrderBy>;
  pendingGfeReviewAggregate?: InputMaybe<GfeReviewRequestAggregateOrderBy>;
  phone?: InputMaybe<OrderBy>;
  stripeCustomerId?: InputMaybe<OrderBy>;
  twilioConversationId?: InputMaybe<OrderBy>;
  twilioLastMessage?: InputMaybe<OrderBy>;
  twilioLastMessageTimestamp?: InputMaybe<OrderBy>;
  twilioOptedOutOfSms?: InputMaybe<OrderBy>;
  visitsAggregate?: InputMaybe<VisitAggregateOrderBy>;
  walletBalanceSnapshot?: InputMaybe<OrderBy>;
  walletSnapshotCreatedAt?: InputMaybe<OrderBy>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateOrderBy>;
  walletTransactionsAggregate?: InputMaybe<WalletTransactionAggregateOrderBy>;
};

/** primary key columns input for table: medspas_client */
export type ClientPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClientPrependInput = {
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "medspas_client" */
export enum ClientSelectColumn {
  /** column name */
  Active = "active",
  /** column name */
  AddressId = "addressId",
  /** column name */
  Birthdate = "birthdate",
  /** column name */
  Created = "created",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "firstName",
  /** column name */
  Gender = "gender",
  /** column name */
  HadAdverseReaction = "hadAdverseReaction",
  /** column name */
  HighLevelContactId = "highLevelContactId",
  /** column name */
  HighLevelLastMessageBody = "highLevelLastMessageBody",
  /** column name */
  HighLevelLastMessageDate = "highLevelLastMessageDate",
  /** column name */
  HighLevelLastMessageType = "highLevelLastMessageType",
  /** column name */
  HighLevelSyncStartedAt = "highLevelSyncStartedAt",
  /** column name */
  HighLevelSyncStatus = "highLevelSyncStatus",
  /** column name */
  HighLevelUnreadMessagesCount = "highLevelUnreadMessagesCount",
  /** column name */
  Id = "id",
  /** column name */
  KnowsMedspaFrom = "knowsMedspaFrom",
  /** column name */
  LastName = "lastName",
  /** column name */
  LastSentFormsAt = "lastSentFormsAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  Phone = "phone",
  /** column name */
  StripeCustomerId = "stripeCustomerId",
  /** column name */
  TwilioConversationId = "twilioConversationId",
  /** column name */
  TwilioLastMessage = "twilioLastMessage",
  /** column name */
  TwilioLastMessageTimestamp = "twilioLastMessageTimestamp",
  /** column name */
  TwilioOptedOutOfSms = "twilioOptedOutOfSms",
  /** column name */
  WalletBalanceSnapshot = "walletBalanceSnapshot",
  /** column name */
  WalletSnapshotCreatedAt = "walletSnapshotCreatedAt",
}

/** select "clientAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_client" */
export enum ClientSelectColumnClientAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Active = "active",
  /** column name */
  HadAdverseReaction = "hadAdverseReaction",
  /** column name */
  TwilioOptedOutOfSms = "twilioOptedOutOfSms",
}

/** select "clientAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_client" */
export enum ClientSelectColumnClientAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Active = "active",
  /** column name */
  HadAdverseReaction = "hadAdverseReaction",
  /** column name */
  TwilioOptedOutOfSms = "twilioOptedOutOfSms",
}

/** input type for updating data in table "medspas_client" */
export type ClientSetInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  birthdate?: InputMaybe<Scalars["date"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores information if at least one Adverse Reaction has ever been reported for this client. */
  hadAdverseReaction?: InputMaybe<Scalars["Boolean"]["input"]>;
  highLevelContactId?: InputMaybe<Scalars["String"]["input"]>;
  highLevelLastMessageBody?: InputMaybe<Scalars["String"]["input"]>;
  highLevelLastMessageDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
  highLevelLastMessageType?: InputMaybe<Scalars["String"]["input"]>;
  highLevelSyncStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the status of the HighLevel sync for the client */
  highLevelSyncStatus?: InputMaybe<Scalars["String"]["input"]>;
  highLevelUnreadMessagesCount?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  knowsMedspaFrom?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastSentFormsAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationId?: InputMaybe<Scalars["String"]["input"]>;
  twilioLastMessage?: InputMaybe<Scalars["String"]["input"]>;
  twilioLastMessageTimestamp?: InputMaybe<Scalars["timestamptz"]["input"]>;
  twilioOptedOutOfSms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Stores the date when the wallet balance snapshot was created */
  walletSnapshotCreatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type ClientStddevFields = {
  __typename?: "clientStddevFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_client" */
export type ClientStddevOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ClientStddevPopFields = {
  __typename?: "clientStddevPopFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_client" */
export type ClientStddevPopOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ClientStddevSampFields = {
  __typename?: "clientStddevSampFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_client" */
export type ClientStddevSampOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "client" */
export type ClientStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClientStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientStreamCursorValueInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  birthdate?: InputMaybe<Scalars["date"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  /** Stores information if at least one Adverse Reaction has ever been reported for this client. */
  hadAdverseReaction?: InputMaybe<Scalars["Boolean"]["input"]>;
  highLevelContactId?: InputMaybe<Scalars["String"]["input"]>;
  highLevelLastMessageBody?: InputMaybe<Scalars["String"]["input"]>;
  highLevelLastMessageDate?: InputMaybe<Scalars["timestamptz"]["input"]>;
  highLevelLastMessageType?: InputMaybe<Scalars["String"]["input"]>;
  highLevelSyncStartedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Stores the status of the HighLevel sync for the client */
  highLevelSyncStatus?: InputMaybe<Scalars["String"]["input"]>;
  highLevelUnreadMessagesCount?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  knowsMedspaFrom?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastSentFormsAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationId?: InputMaybe<Scalars["String"]["input"]>;
  twilioLastMessage?: InputMaybe<Scalars["String"]["input"]>;
  twilioLastMessageTimestamp?: InputMaybe<Scalars["timestamptz"]["input"]>;
  twilioOptedOutOfSms?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Stores a snapshot of wallet balance before 'formal-services-v2' migration. It is only used for migration purpose & providers' peace of mind */
  walletBalanceSnapshot?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** Stores the date when the wallet balance snapshot was created */
  walletSnapshotCreatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type ClientSumFields = {
  __typename?: "clientSumFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_client" */
export type ClientSumOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_client" */
export enum ClientUpdateColumn {
  /** column name */
  Active = "active",
  /** column name */
  AddressId = "addressId",
  /** column name */
  Birthdate = "birthdate",
  /** column name */
  Created = "created",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "firstName",
  /** column name */
  Gender = "gender",
  /** column name */
  HadAdverseReaction = "hadAdverseReaction",
  /** column name */
  HighLevelContactId = "highLevelContactId",
  /** column name */
  HighLevelLastMessageBody = "highLevelLastMessageBody",
  /** column name */
  HighLevelLastMessageDate = "highLevelLastMessageDate",
  /** column name */
  HighLevelLastMessageType = "highLevelLastMessageType",
  /** column name */
  HighLevelSyncStartedAt = "highLevelSyncStartedAt",
  /** column name */
  HighLevelSyncStatus = "highLevelSyncStatus",
  /** column name */
  HighLevelUnreadMessagesCount = "highLevelUnreadMessagesCount",
  /** column name */
  Id = "id",
  /** column name */
  KnowsMedspaFrom = "knowsMedspaFrom",
  /** column name */
  LastName = "lastName",
  /** column name */
  LastSentFormsAt = "lastSentFormsAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  Phone = "phone",
  /** column name */
  StripeCustomerId = "stripeCustomerId",
  /** column name */
  TwilioConversationId = "twilioConversationId",
  /** column name */
  TwilioLastMessage = "twilioLastMessage",
  /** column name */
  TwilioLastMessageTimestamp = "twilioLastMessageTimestamp",
  /** column name */
  TwilioOptedOutOfSms = "twilioOptedOutOfSms",
  /** column name */
  WalletBalanceSnapshot = "walletBalanceSnapshot",
  /** column name */
  WalletSnapshotCreatedAt = "walletSnapshotCreatedAt",
}

export type ClientUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ClientAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<ClientDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<ClientDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<ClientDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClientIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ClientPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientSetInput>;
  /** filter the rows which have to be updated */
  where: ClientBoolExp;
};

/** aggregate varPop on columns */
export type ClientVarPopFields = {
  __typename?: "clientVarPopFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_client" */
export type ClientVarPopOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ClientVarSampFields = {
  __typename?: "clientVarSampFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_client" */
export type ClientVarSampOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClientVarianceFields = {
  __typename?: "clientVarianceFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  highLevelUnreadMessagesCount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_client" */
export type ClientVarianceOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  highLevelUnreadMessagesCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

export type ComplianceTaskAggregateBoolExpBool_And = {
  arguments: ComplianceTaskSelectColumnComplianceTaskAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ComplianceTaskBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ComplianceTaskAggregateBoolExpBool_Or = {
  arguments: ComplianceTaskSelectColumnComplianceTaskAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ComplianceTaskBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ComplianceTaskAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ComplianceTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ComplianceTaskBoolExp>;
  predicate: IntComparisonExp;
};

export type ComplianceTaskDocumentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ComplianceTaskDocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ComplianceTaskDocumentBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "resources_consentform" */
export type ConsentForm = {
  __typename?: "consentForm";
  allServices: Scalars["Boolean"]["output"];
  allowOptOut: Scalars["Boolean"]["output"];
  /** An array relationship */
  consentFormSignatures: Array<ConsentFormSignature>;
  /** An aggregate relationship */
  consentFormSignaturesAggregate: ConsentFormSignatureAggregate;
  created: Scalars["timestamptz"]["output"];
  deleted: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  sendOnce: Scalars["Boolean"]["output"];
  /** An array relationship */
  serviceMenuItems: Array<ConsentFormServiceMenuItems>;
  /** An aggregate relationship */
  serviceMenuItemsAggregate: ConsentFormServiceMenuItemsAggregate;
  text: Scalars["String"]["output"];
};

/** columns and relationships of "resources_consentform" */
export type ConsentFormConsentFormSignaturesArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

/** columns and relationships of "resources_consentform" */
export type ConsentFormConsentFormSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

/** columns and relationships of "resources_consentform" */
export type ConsentFormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

/** columns and relationships of "resources_consentform" */
export type ConsentFormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

/** aggregated selection of "resources_consentform" */
export type ConsentFormAggregate = {
  __typename?: "consentFormAggregate";
  aggregate?: Maybe<ConsentFormAggregateFields>;
  nodes: Array<ConsentForm>;
};

export type ConsentFormAggregateBoolExp = {
  bool_and?: InputMaybe<ConsentFormAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ConsentFormAggregateBoolExpBool_Or>;
  count?: InputMaybe<ConsentFormAggregateBoolExpCount>;
};

export type ConsentFormAggregateBoolExpBool_And = {
  arguments: ConsentFormSelectColumnConsentFormAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ConsentFormBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ConsentFormAggregateBoolExpBool_Or = {
  arguments: ConsentFormSelectColumnConsentFormAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ConsentFormBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ConsentFormAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ConsentFormSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ConsentFormBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_consentform" */
export type ConsentFormAggregateFields = {
  __typename?: "consentFormAggregateFields";
  avg?: Maybe<ConsentFormAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ConsentFormMaxFields>;
  min?: Maybe<ConsentFormMinFields>;
  stddev?: Maybe<ConsentFormStddevFields>;
  stddevPop?: Maybe<ConsentFormStddevPopFields>;
  stddevSamp?: Maybe<ConsentFormStddevSampFields>;
  sum?: Maybe<ConsentFormSumFields>;
  varPop?: Maybe<ConsentFormVarPopFields>;
  varSamp?: Maybe<ConsentFormVarSampFields>;
  variance?: Maybe<ConsentFormVarianceFields>;
};

/** aggregate fields of "resources_consentform" */
export type ConsentFormAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConsentFormSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_consentform" */
export type ConsentFormAggregateOrderBy = {
  avg?: InputMaybe<ConsentFormAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConsentFormMaxOrderBy>;
  min?: InputMaybe<ConsentFormMinOrderBy>;
  stddev?: InputMaybe<ConsentFormStddevOrderBy>;
  stddevPop?: InputMaybe<ConsentFormStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ConsentFormStddevSampOrderBy>;
  sum?: InputMaybe<ConsentFormSumOrderBy>;
  varPop?: InputMaybe<ConsentFormVarPopOrderBy>;
  varSamp?: InputMaybe<ConsentFormVarSampOrderBy>;
  variance?: InputMaybe<ConsentFormVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_consentform" */
export type ConsentFormArrRelInsertInput = {
  data: Array<ConsentFormInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ConsentFormOnConflict>;
};

/** aggregate avg on columns */
export type ConsentFormAvgFields = {
  __typename?: "consentFormAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_consentform" */
export type ConsentFormAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_consentform". All fields are combined with a logical 'AND'. */
export type ConsentFormBoolExp = {
  _and?: InputMaybe<Array<ConsentFormBoolExp>>;
  _not?: InputMaybe<ConsentFormBoolExp>;
  _or?: InputMaybe<Array<ConsentFormBoolExp>>;
  allServices?: InputMaybe<BooleanComparisonExp>;
  allowOptOut?: InputMaybe<BooleanComparisonExp>;
  consentFormSignatures?: InputMaybe<ConsentFormSignatureBoolExp>;
  consentFormSignaturesAggregate?: InputMaybe<ConsentFormSignatureAggregateBoolExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sendOnce?: InputMaybe<BooleanComparisonExp>;
  serviceMenuItems?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
  serviceMenuItemsAggregate?: InputMaybe<ConsentFormServiceMenuItemsAggregateBoolExp>;
  text?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "resources_consentform" */
export enum ConsentFormConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesConsentformPkey = "resources_consentform_pkey",
}

/** input type for incrementing numeric columns in table "resources_consentform" */
export type ConsentFormIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_consentform" */
export type ConsentFormInsertInput = {
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowOptOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  consentFormSignatures?: InputMaybe<ConsentFormSignatureArrRelInsertInput>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  sendOnce?: InputMaybe<Scalars["Boolean"]["input"]>;
  serviceMenuItems?: InputMaybe<ConsentFormServiceMenuItemsArrRelInsertInput>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ConsentFormMaxFields = {
  __typename?: "consentFormMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "resources_consentform" */
export type ConsentFormMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ConsentFormMinFields = {
  __typename?: "consentFormMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "resources_consentform" */
export type ConsentFormMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_consentform" */
export type ConsentFormMutationResponse = {
  __typename?: "consentFormMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ConsentForm>;
};

/** input type for inserting object relation for remote table "resources_consentform" */
export type ConsentFormObjRelInsertInput = {
  data: ConsentFormInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ConsentFormOnConflict>;
};

/** on_conflict condition type for table "resources_consentform" */
export type ConsentFormOnConflict = {
  constraint: ConsentFormConstraint;
  updateColumns?: Array<ConsentFormUpdateColumn>;
  where?: InputMaybe<ConsentFormBoolExp>;
};

/** Ordering options when selecting data from "resources_consentform". */
export type ConsentFormOrderBy = {
  allServices?: InputMaybe<OrderBy>;
  allowOptOut?: InputMaybe<OrderBy>;
  consentFormSignaturesAggregate?: InputMaybe<ConsentFormSignatureAggregateOrderBy>;
  created?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sendOnce?: InputMaybe<OrderBy>;
  serviceMenuItemsAggregate?: InputMaybe<ConsentFormServiceMenuItemsAggregateOrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_consentform */
export type ConsentFormPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_consentform" */
export enum ConsentFormSelectColumn {
  /** column name */
  AllServices = "allServices",
  /** column name */
  AllowOptOut = "allowOptOut",
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  SendOnce = "sendOnce",
  /** column name */
  Text = "text",
}

/** select "consentFormAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_consentform" */
export enum ConsentFormSelectColumnConsentFormAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  AllServices = "allServices",
  /** column name */
  AllowOptOut = "allowOptOut",
  /** column name */
  Deleted = "deleted",
  /** column name */
  SendOnce = "sendOnce",
}

/** select "consentFormAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_consentform" */
export enum ConsentFormSelectColumnConsentFormAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  AllServices = "allServices",
  /** column name */
  AllowOptOut = "allowOptOut",
  /** column name */
  Deleted = "deleted",
  /** column name */
  SendOnce = "sendOnce",
}

/** columns and relationships of "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItems = {
  __typename?: "consentFormServiceMenuItems";
  /** An object relationship */
  consentForm: ConsentForm;
  consentformId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  medspaservicemenuitemId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceMenuItem: MedspaServiceMenuItem;
};

/** aggregated selection of "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsAggregate = {
  __typename?: "consentFormServiceMenuItemsAggregate";
  aggregate?: Maybe<ConsentFormServiceMenuItemsAggregateFields>;
  nodes: Array<ConsentFormServiceMenuItems>;
};

export type ConsentFormServiceMenuItemsAggregateBoolExp = {
  count?: InputMaybe<ConsentFormServiceMenuItemsAggregateBoolExpCount>;
};

export type ConsentFormServiceMenuItemsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsAggregateFields = {
  __typename?: "consentFormServiceMenuItemsAggregateFields";
  avg?: Maybe<ConsentFormServiceMenuItemsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ConsentFormServiceMenuItemsMaxFields>;
  min?: Maybe<ConsentFormServiceMenuItemsMinFields>;
  stddev?: Maybe<ConsentFormServiceMenuItemsStddevFields>;
  stddevPop?: Maybe<ConsentFormServiceMenuItemsStddevPopFields>;
  stddevSamp?: Maybe<ConsentFormServiceMenuItemsStddevSampFields>;
  sum?: Maybe<ConsentFormServiceMenuItemsSumFields>;
  varPop?: Maybe<ConsentFormServiceMenuItemsVarPopFields>;
  varSamp?: Maybe<ConsentFormServiceMenuItemsVarSampFields>;
  variance?: Maybe<ConsentFormServiceMenuItemsVarianceFields>;
};

/** aggregate fields of "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsAggregateOrderBy = {
  avg?: InputMaybe<ConsentFormServiceMenuItemsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConsentFormServiceMenuItemsMaxOrderBy>;
  min?: InputMaybe<ConsentFormServiceMenuItemsMinOrderBy>;
  stddev?: InputMaybe<ConsentFormServiceMenuItemsStddevOrderBy>;
  stddevPop?: InputMaybe<ConsentFormServiceMenuItemsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ConsentFormServiceMenuItemsStddevSampOrderBy>;
  sum?: InputMaybe<ConsentFormServiceMenuItemsSumOrderBy>;
  varPop?: InputMaybe<ConsentFormServiceMenuItemsVarPopOrderBy>;
  varSamp?: InputMaybe<ConsentFormServiceMenuItemsVarSampOrderBy>;
  variance?: InputMaybe<ConsentFormServiceMenuItemsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsArrRelInsertInput = {
  data: Array<ConsentFormServiceMenuItemsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ConsentFormServiceMenuItemsOnConflict>;
};

/** aggregate avg on columns */
export type ConsentFormServiceMenuItemsAvgFields = {
  __typename?: "consentFormServiceMenuItemsAvgFields";
  consentformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsAvgOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_consentform_service_menu_items". All fields are combined with a logical 'AND'. */
export type ConsentFormServiceMenuItemsBoolExp = {
  _and?: InputMaybe<Array<ConsentFormServiceMenuItemsBoolExp>>;
  _not?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
  _or?: InputMaybe<Array<ConsentFormServiceMenuItemsBoolExp>>;
  consentForm?: InputMaybe<ConsentFormBoolExp>;
  consentformId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaservicemenuitemId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** unique or primary key constraints on table "resources_consentform_service_menu_items" */
export enum ConsentFormServiceMenuItemsConstraint {
  /** unique or primary key constraint on columns "medspaservicemenuitem_id", "consentform_id" */
  ResourcesConsentformSeConsentformIdMedspaser_6a8005e3Uniq = "resources_consentform_se_consentform_id_medspaser_6a8005e3_uniq",
  /** unique or primary key constraint on columns "id" */
  ResourcesConsentformServiceMenuItemsPkey = "resources_consentform_service_menu_items_pkey",
}

/** input type for incrementing numeric columns in table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsIncInput = {
  consentformId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsInsertInput = {
  consentForm?: InputMaybe<ConsentFormObjRelInsertInput>;
  consentformId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
};

/** aggregate max on columns */
export type ConsentFormServiceMenuItemsMaxFields = {
  __typename?: "consentFormServiceMenuItemsMaxFields";
  consentformId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsMaxOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ConsentFormServiceMenuItemsMinFields = {
  __typename?: "consentFormServiceMenuItemsMinFields";
  consentformId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsMinOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsMutationResponse = {
  __typename?: "consentFormServiceMenuItemsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ConsentFormServiceMenuItems>;
};

/** on_conflict condition type for table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsOnConflict = {
  constraint: ConsentFormServiceMenuItemsConstraint;
  updateColumns?: Array<ConsentFormServiceMenuItemsUpdateColumn>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

/** Ordering options when selecting data from "resources_consentform_service_menu_items". */
export type ConsentFormServiceMenuItemsOrderBy = {
  consentForm?: InputMaybe<ConsentFormOrderBy>;
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
};

/** primary key columns input for table: resources_consentform_service_menu_items */
export type ConsentFormServiceMenuItemsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_consentform_service_menu_items" */
export enum ConsentFormServiceMenuItemsSelectColumn {
  /** column name */
  ConsentformId = "consentformId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
}

/** input type for updating data in table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsSetInput = {
  consentformId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ConsentFormServiceMenuItemsStddevFields = {
  __typename?: "consentFormServiceMenuItemsStddevFields";
  consentformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsStddevOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ConsentFormServiceMenuItemsStddevPopFields = {
  __typename?: "consentFormServiceMenuItemsStddevPopFields";
  consentformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsStddevPopOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ConsentFormServiceMenuItemsStddevSampFields = {
  __typename?: "consentFormServiceMenuItemsStddevSampFields";
  consentformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsStddevSampOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "consentFormServiceMenuItems" */
export type ConsentFormServiceMenuItemsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ConsentFormServiceMenuItemsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsentFormServiceMenuItemsStreamCursorValueInput = {
  consentformId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ConsentFormServiceMenuItemsSumFields = {
  __typename?: "consentFormServiceMenuItemsSumFields";
  consentformId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsSumOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_consentform_service_menu_items" */
export enum ConsentFormServiceMenuItemsUpdateColumn {
  /** column name */
  ConsentformId = "consentformId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
}

export type ConsentFormServiceMenuItemsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConsentFormServiceMenuItemsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsentFormServiceMenuItemsSetInput>;
  /** filter the rows which have to be updated */
  where: ConsentFormServiceMenuItemsBoolExp;
};

/** aggregate varPop on columns */
export type ConsentFormServiceMenuItemsVarPopFields = {
  __typename?: "consentFormServiceMenuItemsVarPopFields";
  consentformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsVarPopOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ConsentFormServiceMenuItemsVarSampFields = {
  __typename?: "consentFormServiceMenuItemsVarSampFields";
  consentformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsVarSampOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ConsentFormServiceMenuItemsVarianceFields = {
  __typename?: "consentFormServiceMenuItemsVarianceFields";
  consentformId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_consentform_service_menu_items" */
export type ConsentFormServiceMenuItemsVarianceOrderBy = {
  consentformId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** input type for updating data in table "resources_consentform" */
export type ConsentFormSetInput = {
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowOptOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  sendOnce?: InputMaybe<Scalars["Boolean"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "medspas_consentformsignature" */
export type ConsentFormSignature = {
  __typename?: "consentFormSignature";
  additionalInfo?: Maybe<ConsentFormSignatureType>;
  /** An object relationship */
  client: Client;
  clientId: Scalars["bigint"]["output"];
  /** An object relationship */
  consentForm: ConsentForm;
  consentFormId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** Timestamp when the signature was marked as expired */
  expiredAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the signature expires (by default 1 year after creation) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["bigint"]["output"];
  ipAddress?: Maybe<Scalars["inet"]["output"]>;
  isOptOut?: Maybe<Scalars["Boolean"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  pdfPrintout?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  signedConsentFormText: Scalars["String"]["output"];
  userAgent?: Maybe<Scalars["String"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "medspas_consentformsignature" */
export type ConsentFormSignatureAggregate = {
  __typename?: "consentFormSignatureAggregate";
  aggregate?: Maybe<ConsentFormSignatureAggregateFields>;
  nodes: Array<ConsentFormSignature>;
};

export type ConsentFormSignatureAggregateBoolExp = {
  bool_and?: InputMaybe<ConsentFormSignatureAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ConsentFormSignatureAggregateBoolExpBool_Or>;
  count?: InputMaybe<ConsentFormSignatureAggregateBoolExpCount>;
};

export type ConsentFormSignatureAggregateBoolExpBool_And = {
  arguments: ConsentFormSignatureSelectColumnConsentFormSignatureAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ConsentFormSignatureBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ConsentFormSignatureAggregateBoolExpBool_Or = {
  arguments: ConsentFormSignatureSelectColumnConsentFormSignatureAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ConsentFormSignatureBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ConsentFormSignatureAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ConsentFormSignatureBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_consentformsignature" */
export type ConsentFormSignatureAggregateFields = {
  __typename?: "consentFormSignatureAggregateFields";
  avg?: Maybe<ConsentFormSignatureAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ConsentFormSignatureMaxFields>;
  min?: Maybe<ConsentFormSignatureMinFields>;
  stddev?: Maybe<ConsentFormSignatureStddevFields>;
  stddevPop?: Maybe<ConsentFormSignatureStddevPopFields>;
  stddevSamp?: Maybe<ConsentFormSignatureStddevSampFields>;
  sum?: Maybe<ConsentFormSignatureSumFields>;
  varPop?: Maybe<ConsentFormSignatureVarPopFields>;
  varSamp?: Maybe<ConsentFormSignatureVarSampFields>;
  variance?: Maybe<ConsentFormSignatureVarianceFields>;
};

/** aggregate fields of "medspas_consentformsignature" */
export type ConsentFormSignatureAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_consentformsignature" */
export type ConsentFormSignatureAggregateOrderBy = {
  avg?: InputMaybe<ConsentFormSignatureAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConsentFormSignatureMaxOrderBy>;
  min?: InputMaybe<ConsentFormSignatureMinOrderBy>;
  stddev?: InputMaybe<ConsentFormSignatureStddevOrderBy>;
  stddevPop?: InputMaybe<ConsentFormSignatureStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ConsentFormSignatureStddevSampOrderBy>;
  sum?: InputMaybe<ConsentFormSignatureSumOrderBy>;
  varPop?: InputMaybe<ConsentFormSignatureVarPopOrderBy>;
  varSamp?: InputMaybe<ConsentFormSignatureVarSampOrderBy>;
  variance?: InputMaybe<ConsentFormSignatureVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_consentformsignature" */
export type ConsentFormSignatureArrRelInsertInput = {
  data: Array<ConsentFormSignatureInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ConsentFormSignatureOnConflict>;
};

/** aggregate avg on columns */
export type ConsentFormSignatureAvgFields = {
  __typename?: "consentFormSignatureAvgFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  consentFormId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureAvgOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_consentformsignature". All fields are combined with a logical 'AND'. */
export type ConsentFormSignatureBoolExp = {
  _and?: InputMaybe<Array<ConsentFormSignatureBoolExp>>;
  _not?: InputMaybe<ConsentFormSignatureBoolExp>;
  _or?: InputMaybe<Array<ConsentFormSignatureBoolExp>>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  consentForm?: InputMaybe<ConsentFormBoolExp>;
  consentFormId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  expiredAt?: InputMaybe<TimestamptzComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  ipAddress?: InputMaybe<InetComparisonExp>;
  isOptOut?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  pdfPrintout?: InputMaybe<StringComparisonExp>;
  signature?: InputMaybe<StringComparisonExp>;
  signedAt?: InputMaybe<TimestamptzComparisonExp>;
  signedConsentFormText?: InputMaybe<StringComparisonExp>;
  userAgent?: InputMaybe<StringComparisonExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspas_consentformsignature" */
export enum ConsentFormSignatureConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasConsentformsignaturePkey = "medspas_consentformsignature_pkey",
  /** unique or primary key constraint on columns "client_id", "consent_form_id", "visit_id" */
  UniqueConsentFormSignaturePerVisitAndClient = "unique_consent_form_signature_per_visit_and_client",
}

/** input type for incrementing numeric columns in table "medspas_consentformsignature" */
export type ConsentFormSignatureIncInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  consentFormId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_consentformsignature" */
export type ConsentFormSignatureInsertInput = {
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  consentForm?: InputMaybe<ConsentFormObjRelInsertInput>;
  consentFormId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the signature was marked as expired */
  expiredAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the signature expires (by default 1 year after creation) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  isOptOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  pdfPrintout?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  signedConsentFormText?: InputMaybe<Scalars["String"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ConsentFormSignatureMaxFields = {
  __typename?: "consentFormSignatureMaxFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  consentFormId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the signature was marked as expired */
  expiredAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the signature expires (by default 1 year after creation) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  pdfPrintout?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  signedConsentFormText?: Maybe<Scalars["String"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureMaxOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Timestamp when the signature was marked as expired */
  expiredAt?: InputMaybe<OrderBy>;
  /** Timestamp when the signature expires (by default 1 year after creation) */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfPrintout?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  signedConsentFormText?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ConsentFormSignatureMinFields = {
  __typename?: "consentFormSignatureMinFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  consentFormId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the signature was marked as expired */
  expiredAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Timestamp when the signature expires (by default 1 year after creation) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  pdfPrintout?: Maybe<Scalars["String"]["output"]>;
  signature?: Maybe<Scalars["String"]["output"]>;
  signedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  signedConsentFormText?: Maybe<Scalars["String"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureMinOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Timestamp when the signature was marked as expired */
  expiredAt?: InputMaybe<OrderBy>;
  /** Timestamp when the signature expires (by default 1 year after creation) */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfPrintout?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  signedConsentFormText?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_consentformsignature" */
export type ConsentFormSignatureMutationResponse = {
  __typename?: "consentFormSignatureMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ConsentFormSignature>;
};

/** on_conflict condition type for table "medspas_consentformsignature" */
export type ConsentFormSignatureOnConflict = {
  constraint: ConsentFormSignatureConstraint;
  updateColumns?: Array<ConsentFormSignatureUpdateColumn>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

/** Ordering options when selecting data from "medspas_consentformsignature". */
export type ConsentFormSignatureOrderBy = {
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  consentForm?: InputMaybe<ConsentFormOrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  isOptOut?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfPrintout?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  signedAt?: InputMaybe<OrderBy>;
  signedConsentFormText?: InputMaybe<OrderBy>;
  userAgent?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_consentformsignature */
export type ConsentFormSignaturePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_consentformsignature" */
export enum ConsentFormSignatureSelectColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  ConsentFormId = "consentFormId",
  /** column name */
  Created = "created",
  /** column name */
  ExpiredAt = "expiredAt",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  IsOptOut = "isOptOut",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfPrintout = "pdfPrintout",
  /** column name */
  Signature = "signature",
  /** column name */
  SignedAt = "signedAt",
  /** column name */
  SignedConsentFormText = "signedConsentFormText",
  /** column name */
  UserAgent = "userAgent",
  /** column name */
  VisitId = "visitId",
}

/** select "consentFormSignatureAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_consentformsignature" */
export enum ConsentFormSignatureSelectColumnConsentFormSignatureAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsOptOut = "isOptOut",
}

/** select "consentFormSignatureAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_consentformsignature" */
export enum ConsentFormSignatureSelectColumnConsentFormSignatureAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsOptOut = "isOptOut",
}

/** input type for updating data in table "medspas_consentformsignature" */
export type ConsentFormSignatureSetInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  consentFormId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the signature was marked as expired */
  expiredAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the signature expires (by default 1 year after creation) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  isOptOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  pdfPrintout?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  signedConsentFormText?: InputMaybe<Scalars["String"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ConsentFormSignatureStddevFields = {
  __typename?: "consentFormSignatureStddevFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  consentFormId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureStddevOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ConsentFormSignatureStddevPopFields = {
  __typename?: "consentFormSignatureStddevPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  consentFormId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureStddevPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ConsentFormSignatureStddevSampFields = {
  __typename?: "consentFormSignatureStddevSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  consentFormId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureStddevSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "consentFormSignature" */
export type ConsentFormSignatureStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ConsentFormSignatureStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsentFormSignatureStreamCursorValueInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  consentFormId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the signature was marked as expired */
  expiredAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Timestamp when the signature expires (by default 1 year after creation) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  ipAddress?: InputMaybe<Scalars["inet"]["input"]>;
  isOptOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  pdfPrintout?: InputMaybe<Scalars["String"]["input"]>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
  signedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  signedConsentFormText?: InputMaybe<Scalars["String"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ConsentFormSignatureSumFields = {
  __typename?: "consentFormSignatureSumFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  consentFormId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureSumOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_consentformsignature" */
export enum ConsentFormSignatureUpdateColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  ConsentFormId = "consentFormId",
  /** column name */
  Created = "created",
  /** column name */
  ExpiredAt = "expiredAt",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  IsOptOut = "isOptOut",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfPrintout = "pdfPrintout",
  /** column name */
  Signature = "signature",
  /** column name */
  SignedAt = "signedAt",
  /** column name */
  SignedConsentFormText = "signedConsentFormText",
  /** column name */
  UserAgent = "userAgent",
  /** column name */
  VisitId = "visitId",
}

export type ConsentFormSignatureUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConsentFormSignatureIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsentFormSignatureSetInput>;
  /** filter the rows which have to be updated */
  where: ConsentFormSignatureBoolExp;
};

/** aggregate varPop on columns */
export type ConsentFormSignatureVarPopFields = {
  __typename?: "consentFormSignatureVarPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  consentFormId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureVarPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ConsentFormSignatureVarSampFields = {
  __typename?: "consentFormSignatureVarSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  consentFormId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureVarSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ConsentFormSignatureVarianceFields = {
  __typename?: "consentFormSignatureVarianceFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  consentFormId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_consentformsignature" */
export type ConsentFormSignatureVarianceOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  consentFormId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type ConsentFormStddevFields = {
  __typename?: "consentFormStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_consentform" */
export type ConsentFormStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ConsentFormStddevPopFields = {
  __typename?: "consentFormStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_consentform" */
export type ConsentFormStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ConsentFormStddevSampFields = {
  __typename?: "consentFormStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_consentform" */
export type ConsentFormStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "consentForm" */
export type ConsentFormStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ConsentFormStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsentFormStreamCursorValueInput = {
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowOptOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  sendOnce?: InputMaybe<Scalars["Boolean"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ConsentFormSumFields = {
  __typename?: "consentFormSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_consentform" */
export type ConsentFormSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_consentform" */
export enum ConsentFormUpdateColumn {
  /** column name */
  AllServices = "allServices",
  /** column name */
  AllowOptOut = "allowOptOut",
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  SendOnce = "sendOnce",
  /** column name */
  Text = "text",
}

export type ConsentFormUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConsentFormIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsentFormSetInput>;
  /** filter the rows which have to be updated */
  where: ConsentFormBoolExp;
};

/** aggregate varPop on columns */
export type ConsentFormVarPopFields = {
  __typename?: "consentFormVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_consentform" */
export type ConsentFormVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ConsentFormVarSampFields = {
  __typename?: "consentFormVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_consentform" */
export type ConsentFormVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ConsentFormVarianceFields = {
  __typename?: "consentFormVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_consentform" */
export type ConsentFormVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "visits_diagram" */
export type Diagram = {
  __typename?: "diagram";
  additionalInfo?: Maybe<DiagramType>;
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  diagramTemplate: DiagramTemplate;
  editMetadata: Scalars["jsonb"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  original: Scalars["String"]["output"];
  photo: Scalars["String"]["output"];
  templateId: Scalars["bigint"]["output"];
  /** An object relationship */
  uploadedBy: User;
  uploadedById: Scalars["bigint"]["output"];
  /** An object relationship */
  visit: Visit;
  visitId: Scalars["bigint"]["output"];
};

/** columns and relationships of "visits_diagram" */
export type DiagramEditMetadataArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "visits_diagram" */
export type DiagramAggregate = {
  __typename?: "diagramAggregate";
  aggregate?: Maybe<DiagramAggregateFields>;
  nodes: Array<Diagram>;
};

export type DiagramAggregateBoolExp = {
  count?: InputMaybe<DiagramAggregateBoolExpCount>;
};

export type DiagramAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DiagramSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiagramBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_diagram" */
export type DiagramAggregateFields = {
  __typename?: "diagramAggregateFields";
  avg?: Maybe<DiagramAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<DiagramMaxFields>;
  min?: Maybe<DiagramMinFields>;
  stddev?: Maybe<DiagramStddevFields>;
  stddevPop?: Maybe<DiagramStddevPopFields>;
  stddevSamp?: Maybe<DiagramStddevSampFields>;
  sum?: Maybe<DiagramSumFields>;
  varPop?: Maybe<DiagramVarPopFields>;
  varSamp?: Maybe<DiagramVarSampFields>;
  variance?: Maybe<DiagramVarianceFields>;
};

/** aggregate fields of "visits_diagram" */
export type DiagramAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiagramSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_diagram" */
export type DiagramAggregateOrderBy = {
  avg?: InputMaybe<DiagramAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DiagramMaxOrderBy>;
  min?: InputMaybe<DiagramMinOrderBy>;
  stddev?: InputMaybe<DiagramStddevOrderBy>;
  stddevPop?: InputMaybe<DiagramStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DiagramStddevSampOrderBy>;
  sum?: InputMaybe<DiagramSumOrderBy>;
  varPop?: InputMaybe<DiagramVarPopOrderBy>;
  varSamp?: InputMaybe<DiagramVarSampOrderBy>;
  variance?: InputMaybe<DiagramVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DiagramAppendInput = {
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "visits_diagram" */
export type DiagramArrRelInsertInput = {
  data: Array<DiagramInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DiagramOnConflict>;
};

/** aggregate avg on columns */
export type DiagramAvgFields = {
  __typename?: "diagramAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_diagram" */
export type DiagramAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_diagram". All fields are combined with a logical 'AND'. */
export type DiagramBoolExp = {
  _and?: InputMaybe<Array<DiagramBoolExp>>;
  _not?: InputMaybe<DiagramBoolExp>;
  _or?: InputMaybe<Array<DiagramBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  diagramTemplate?: InputMaybe<DiagramTemplateBoolExp>;
  editMetadata?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  original?: InputMaybe<StringComparisonExp>;
  photo?: InputMaybe<StringComparisonExp>;
  templateId?: InputMaybe<BigintComparisonExp>;
  uploadedBy?: InputMaybe<UserBoolExp>;
  uploadedById?: InputMaybe<BigintComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_diagram" */
export enum DiagramConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsDiagramPkey = "visits_diagram_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DiagramDeleteAtPathInput = {
  editMetadata?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DiagramDeleteElemInput = {
  editMetadata?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DiagramDeleteKeyInput = {
  editMetadata?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "visits_diagram" */
export type DiagramIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_diagram" */
export type DiagramInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  diagramTemplate?: InputMaybe<DiagramTemplateObjRelInsertInput>;
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  original?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedBy?: InputMaybe<UserObjRelInsertInput>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type DiagramMaxFields = {
  __typename?: "diagramMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  original?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_diagram" */
export type DiagramMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  original?: InputMaybe<OrderBy>;
  photo?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DiagramMinFields = {
  __typename?: "diagramMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  original?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_diagram" */
export type DiagramMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  original?: InputMaybe<OrderBy>;
  photo?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_diagram" */
export type DiagramMutationResponse = {
  __typename?: "diagramMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Diagram>;
};

/** on_conflict condition type for table "visits_diagram" */
export type DiagramOnConflict = {
  constraint: DiagramConstraint;
  updateColumns?: Array<DiagramUpdateColumn>;
  where?: InputMaybe<DiagramBoolExp>;
};

/** Ordering options when selecting data from "visits_diagram". */
export type DiagramOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  diagramTemplate?: InputMaybe<DiagramTemplateOrderBy>;
  editMetadata?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  original?: InputMaybe<OrderBy>;
  photo?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedBy?: InputMaybe<UserOrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_diagram */
export type DiagramPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DiagramPrependInput = {
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "visits_diagram" */
export enum DiagramSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  EditMetadata = "editMetadata",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Original = "original",
  /** column name */
  Photo = "photo",
  /** column name */
  TemplateId = "templateId",
  /** column name */
  UploadedById = "uploadedById",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "visits_diagram" */
export type DiagramSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  original?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type DiagramStddevFields = {
  __typename?: "diagramStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_diagram" */
export type DiagramStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DiagramStddevPopFields = {
  __typename?: "diagramStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_diagram" */
export type DiagramStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DiagramStddevSampFields = {
  __typename?: "diagramStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_diagram" */
export type DiagramStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "diagram" */
export type DiagramStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DiagramStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiagramStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  original?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type DiagramSumFields = {
  __typename?: "diagramSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_diagram" */
export type DiagramSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "resources_diagramtemplate" */
export type DiagramTemplate = {
  __typename?: "diagramTemplate";
  additionalInfo?: Maybe<DiagramTemplateType>;
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  diagrams: Array<Diagram>;
  /** An aggregate relationship */
  diagramsAggregate: DiagramAggregate;
  height: Scalars["Int"]["output"];
  id: Scalars["bigint"]["output"];
  isDefaultFallback: Scalars["Boolean"]["output"];
  /** An object relationship */
  medspa?: Maybe<Medspa>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  medspaconfigurations: Array<MedspasMedspaconfiguration>;
  /** An aggregate relationship */
  medspaconfigurationsAggregate: MedspasMedspaconfigurationAggregate;
  modified: Scalars["timestamptz"]["output"];
  photo: Scalars["String"]["output"];
  /** An object relationship */
  uploadedBy?: Maybe<User>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  width: Scalars["Int"]["output"];
};

/** columns and relationships of "resources_diagramtemplate" */
export type DiagramTemplateDiagramsArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

/** columns and relationships of "resources_diagramtemplate" */
export type DiagramTemplateDiagramsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

/** columns and relationships of "resources_diagramtemplate" */
export type DiagramTemplateMedspaconfigurationsArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedspaconfigurationOrderBy>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

/** columns and relationships of "resources_diagramtemplate" */
export type DiagramTemplateMedspaconfigurationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedspaconfigurationOrderBy>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

/** aggregated selection of "resources_diagramtemplate" */
export type DiagramTemplateAggregate = {
  __typename?: "diagramTemplateAggregate";
  aggregate?: Maybe<DiagramTemplateAggregateFields>;
  nodes: Array<DiagramTemplate>;
};

export type DiagramTemplateAggregateBoolExp = {
  bool_and?: InputMaybe<DiagramTemplateAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<DiagramTemplateAggregateBoolExpBool_Or>;
  count?: InputMaybe<DiagramTemplateAggregateBoolExpCount>;
};

export type DiagramTemplateAggregateBoolExpBool_And = {
  arguments: DiagramTemplateSelectColumnDiagramTemplateAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiagramTemplateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DiagramTemplateAggregateBoolExpBool_Or = {
  arguments: DiagramTemplateSelectColumnDiagramTemplateAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiagramTemplateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DiagramTemplateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiagramTemplateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_diagramtemplate" */
export type DiagramTemplateAggregateFields = {
  __typename?: "diagramTemplateAggregateFields";
  avg?: Maybe<DiagramTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<DiagramTemplateMaxFields>;
  min?: Maybe<DiagramTemplateMinFields>;
  stddev?: Maybe<DiagramTemplateStddevFields>;
  stddevPop?: Maybe<DiagramTemplateStddevPopFields>;
  stddevSamp?: Maybe<DiagramTemplateStddevSampFields>;
  sum?: Maybe<DiagramTemplateSumFields>;
  varPop?: Maybe<DiagramTemplateVarPopFields>;
  varSamp?: Maybe<DiagramTemplateVarSampFields>;
  variance?: Maybe<DiagramTemplateVarianceFields>;
};

/** aggregate fields of "resources_diagramtemplate" */
export type DiagramTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_diagramtemplate" */
export type DiagramTemplateAggregateOrderBy = {
  avg?: InputMaybe<DiagramTemplateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DiagramTemplateMaxOrderBy>;
  min?: InputMaybe<DiagramTemplateMinOrderBy>;
  stddev?: InputMaybe<DiagramTemplateStddevOrderBy>;
  stddevPop?: InputMaybe<DiagramTemplateStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DiagramTemplateStddevSampOrderBy>;
  sum?: InputMaybe<DiagramTemplateSumOrderBy>;
  varPop?: InputMaybe<DiagramTemplateVarPopOrderBy>;
  varSamp?: InputMaybe<DiagramTemplateVarSampOrderBy>;
  variance?: InputMaybe<DiagramTemplateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_diagramtemplate" */
export type DiagramTemplateArrRelInsertInput = {
  data: Array<DiagramTemplateInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DiagramTemplateOnConflict>;
};

/** aggregate avg on columns */
export type DiagramTemplateAvgFields = {
  __typename?: "diagramTemplateAvgFields";
  height?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateAvgOrderBy = {
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_diagramtemplate". All fields are combined with a logical 'AND'. */
export type DiagramTemplateBoolExp = {
  _and?: InputMaybe<Array<DiagramTemplateBoolExp>>;
  _not?: InputMaybe<DiagramTemplateBoolExp>;
  _or?: InputMaybe<Array<DiagramTemplateBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  diagrams?: InputMaybe<DiagramBoolExp>;
  diagramsAggregate?: InputMaybe<DiagramAggregateBoolExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isDefaultFallback?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaconfigurations?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
  medspaconfigurationsAggregate?: InputMaybe<MedspasMedspaconfigurationAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  photo?: InputMaybe<StringComparisonExp>;
  uploadedBy?: InputMaybe<UserBoolExp>;
  uploadedById?: InputMaybe<BigintComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "resources_diagramtemplate" */
export enum DiagramTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesDiagramtemplatePkey = "resources_diagramtemplate_pkey",
  /** unique or primary key constraint on columns "is_default_fallback" */
  UniqueDefaultFallbackTemplate = "unique_default_fallback_template",
}

/** input type for incrementing numeric columns in table "resources_diagramtemplate" */
export type DiagramTemplateIncInput = {
  height?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "resources_diagramtemplate" */
export type DiagramTemplateInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  diagrams?: InputMaybe<DiagramArrRelInsertInput>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isDefaultFallback?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaconfigurations?: InputMaybe<MedspasMedspaconfigurationArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  uploadedBy?: InputMaybe<UserObjRelInsertInput>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type DiagramTemplateMaxFields = {
  __typename?: "diagramTemplateMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

/** order by max() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  photo?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DiagramTemplateMinFields = {
  __typename?: "diagramTemplateMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

/** order by min() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  photo?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_diagramtemplate" */
export type DiagramTemplateMutationResponse = {
  __typename?: "diagramTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<DiagramTemplate>;
};

/** input type for inserting object relation for remote table "resources_diagramtemplate" */
export type DiagramTemplateObjRelInsertInput = {
  data: DiagramTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<DiagramTemplateOnConflict>;
};

/** on_conflict condition type for table "resources_diagramtemplate" */
export type DiagramTemplateOnConflict = {
  constraint: DiagramTemplateConstraint;
  updateColumns?: Array<DiagramTemplateUpdateColumn>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

/** Ordering options when selecting data from "resources_diagramtemplate". */
export type DiagramTemplateOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  diagramsAggregate?: InputMaybe<DiagramAggregateOrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDefaultFallback?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaconfigurationsAggregate?: InputMaybe<MedspasMedspaconfigurationAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  photo?: InputMaybe<OrderBy>;
  uploadedBy?: InputMaybe<UserOrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_diagramtemplate */
export type DiagramTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_diagramtemplate" */
export enum DiagramTemplateSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Height = "height",
  /** column name */
  Id = "id",
  /** column name */
  IsDefaultFallback = "isDefaultFallback",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Photo = "photo",
  /** column name */
  UploadedById = "uploadedById",
  /** column name */
  Width = "width",
}

/** select "diagramTemplateAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_diagramtemplate" */
export enum DiagramTemplateSelectColumnDiagramTemplateAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsDefaultFallback = "isDefaultFallback",
}

/** select "diagramTemplateAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_diagramtemplate" */
export enum DiagramTemplateSelectColumnDiagramTemplateAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsDefaultFallback = "isDefaultFallback",
}

/** input type for updating data in table "resources_diagramtemplate" */
export type DiagramTemplateSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isDefaultFallback?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type DiagramTemplateStddevFields = {
  __typename?: "diagramTemplateStddevFields";
  height?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateStddevOrderBy = {
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DiagramTemplateStddevPopFields = {
  __typename?: "diagramTemplateStddevPopFields";
  height?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateStddevPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DiagramTemplateStddevSampFields = {
  __typename?: "diagramTemplateStddevSampFields";
  height?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateStddevSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "diagramTemplate" */
export type DiagramTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DiagramTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiagramTemplateStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isDefaultFallback?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type DiagramTemplateSumFields = {
  __typename?: "diagramTemplateSumFields";
  height?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateSumOrderBy = {
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_diagramtemplate" */
export enum DiagramTemplateUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Height = "height",
  /** column name */
  Id = "id",
  /** column name */
  IsDefaultFallback = "isDefaultFallback",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Photo = "photo",
  /** column name */
  UploadedById = "uploadedById",
  /** column name */
  Width = "width",
}

export type DiagramTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiagramTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiagramTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: DiagramTemplateBoolExp;
};

/** aggregate varPop on columns */
export type DiagramTemplateVarPopFields = {
  __typename?: "diagramTemplateVarPopFields";
  height?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateVarPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DiagramTemplateVarSampFields = {
  __typename?: "diagramTemplateVarSampFields";
  height?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateVarSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DiagramTemplateVarianceFields = {
  __typename?: "diagramTemplateVarianceFields";
  height?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_diagramtemplate" */
export type DiagramTemplateVarianceOrderBy = {
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_diagram" */
export enum DiagramUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  EditMetadata = "editMetadata",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Original = "original",
  /** column name */
  Photo = "photo",
  /** column name */
  TemplateId = "templateId",
  /** column name */
  UploadedById = "uploadedById",
  /** column name */
  VisitId = "visitId",
}

export type DiagramUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DiagramAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<DiagramDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<DiagramDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<DiagramDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiagramIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DiagramPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiagramSetInput>;
  /** filter the rows which have to be updated */
  where: DiagramBoolExp;
};

/** aggregate varPop on columns */
export type DiagramVarPopFields = {
  __typename?: "diagramVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_diagram" */
export type DiagramVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DiagramVarSampFields = {
  __typename?: "diagramVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_diagram" */
export type DiagramVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DiagramVarianceFields = {
  __typename?: "diagramVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_diagram" */
export type DiagramVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "resources_discount" */
export type Discount = {
  __typename?: "discount";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  discountLines: Array<DiscountLine>;
  /** An aggregate relationship */
  discountLinesAggregate: DiscountLineAggregate;
  /** An array relationship */
  discountRetailProducts: Array<DiscountRetailProducts>;
  /** An aggregate relationship */
  discountRetailProductsAggregate: DiscountRetailProductsAggregate;
  /** An array relationship */
  discountServiceMenuItems: Array<DiscountServiceMenuItems>;
  /** An aggregate relationship */
  discountServiceMenuItemsAggregate: DiscountServiceMenuItemsAggregate;
  /** An array relationship */
  discountServiceProducts: Array<DiscountServiceProducts>;
  /** An aggregate relationship */
  discountServiceProductsAggregate: DiscountServiceProductsAggregate;
  discountType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** Indicates if a discount can be used only for memberships */
  isMembership: Scalars["Boolean"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  membershipPerkLines: Array<MembershipPerkLine>;
  /** An aggregate relationship */
  membershipPerkLinesAggregate: MembershipPerkLineAggregate;
  modified: Scalars["timestamptz"]["output"];
  modifiedById?: Maybe<Scalars["bigint"]["output"]>;
  name: Scalars["String"]["output"];
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  value: Scalars["numeric"]["output"];
};

/** columns and relationships of "resources_discount" */
export type DiscountDiscountLinesArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountDiscountLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountDiscountRetailProductsArgs = {
  distinctOn?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountRetailProductsOrderBy>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountDiscountRetailProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountRetailProductsOrderBy>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountDiscountServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceMenuItemsOrderBy>>;
  where?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountDiscountServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceMenuItemsOrderBy>>;
  where?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountDiscountServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceProductsOrderBy>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountDiscountServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceProductsOrderBy>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountMembershipPerkLinesArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** columns and relationships of "resources_discount" */
export type DiscountMembershipPerkLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** aggregated selection of "resources_discount" */
export type DiscountAggregate = {
  __typename?: "discountAggregate";
  aggregate?: Maybe<DiscountAggregateFields>;
  nodes: Array<Discount>;
};

export type DiscountAggregateBoolExp = {
  bool_and?: InputMaybe<DiscountAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<DiscountAggregateBoolExpBool_Or>;
  count?: InputMaybe<DiscountAggregateBoolExpCount>;
};

export type DiscountAggregateBoolExpBool_And = {
  arguments: DiscountSelectColumnDiscountAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DiscountAggregateBoolExpBool_Or = {
  arguments: DiscountSelectColumnDiscountAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DiscountAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DiscountSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_discount" */
export type DiscountAggregateFields = {
  __typename?: "discountAggregateFields";
  avg?: Maybe<DiscountAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<DiscountMaxFields>;
  min?: Maybe<DiscountMinFields>;
  stddev?: Maybe<DiscountStddevFields>;
  stddevPop?: Maybe<DiscountStddevPopFields>;
  stddevSamp?: Maybe<DiscountStddevSampFields>;
  sum?: Maybe<DiscountSumFields>;
  varPop?: Maybe<DiscountVarPopFields>;
  varSamp?: Maybe<DiscountVarSampFields>;
  variance?: Maybe<DiscountVarianceFields>;
};

/** aggregate fields of "resources_discount" */
export type DiscountAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiscountSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_discount" */
export type DiscountAggregateOrderBy = {
  avg?: InputMaybe<DiscountAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DiscountMaxOrderBy>;
  min?: InputMaybe<DiscountMinOrderBy>;
  stddev?: InputMaybe<DiscountStddevOrderBy>;
  stddevPop?: InputMaybe<DiscountStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DiscountStddevSampOrderBy>;
  sum?: InputMaybe<DiscountSumOrderBy>;
  varPop?: InputMaybe<DiscountVarPopOrderBy>;
  varSamp?: InputMaybe<DiscountVarSampOrderBy>;
  variance?: InputMaybe<DiscountVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_discount" */
export type DiscountArrRelInsertInput = {
  data: Array<DiscountInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DiscountOnConflict>;
};

/** aggregate avg on columns */
export type DiscountAvgFields = {
  __typename?: "discountAvgFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  modifiedById?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_discount" */
export type DiscountAvgOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_discount". All fields are combined with a logical 'AND'. */
export type DiscountBoolExp = {
  _and?: InputMaybe<Array<DiscountBoolExp>>;
  _not?: InputMaybe<DiscountBoolExp>;
  _or?: InputMaybe<Array<DiscountBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  discountLines?: InputMaybe<DiscountLineBoolExp>;
  discountLinesAggregate?: InputMaybe<DiscountLineAggregateBoolExp>;
  discountRetailProducts?: InputMaybe<DiscountRetailProductsBoolExp>;
  discountRetailProductsAggregate?: InputMaybe<DiscountRetailProductsAggregateBoolExp>;
  discountServiceMenuItems?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
  discountServiceMenuItemsAggregate?: InputMaybe<DiscountServiceMenuItemsAggregateBoolExp>;
  discountServiceProducts?: InputMaybe<DiscountServiceProductsBoolExp>;
  discountServiceProductsAggregate?: InputMaybe<DiscountServiceProductsAggregateBoolExp>;
  discountType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isMembership?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineBoolExp>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  modifiedById?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  quantityLimit?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "resources_discount" */
export enum DiscountConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesDiscountPkey = "resources_discount_pkey",
}

/** input type for incrementing numeric columns in table "resources_discount" */
export type DiscountIncInput = {
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modifiedById?: InputMaybe<Scalars["bigint"]["input"]>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "resources_discount" */
export type DiscountInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  discountLines?: InputMaybe<DiscountLineArrRelInsertInput>;
  discountRetailProducts?: InputMaybe<DiscountRetailProductsArrRelInsertInput>;
  discountServiceMenuItems?: InputMaybe<DiscountServiceMenuItemsArrRelInsertInput>;
  discountServiceProducts?: InputMaybe<DiscountServiceProductsArrRelInsertInput>;
  discountType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if a discount can be used only for memberships */
  isMembership?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  modifiedById?: InputMaybe<Scalars["bigint"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** columns and relationships of "visits_discountline" */
export type DiscountLine = {
  __typename?: "discountLine";
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  discount?: Maybe<Discount>;
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  discountType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars["bigint"]["output"];
  isInvoiceLevel: Scalars["Boolean"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  /** An object relationship */
  productLine?: Maybe<ProductLine>;
  productLineId?: Maybe<Scalars["bigint"]["output"]>;
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  quantityUsed: Scalars["Int"]["output"];
  serviceMenuItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  serviceProductLine?: Maybe<ServiceProductLine>;
  serviceProductLineId?: Maybe<Scalars["bigint"]["output"]>;
  value: Scalars["numeric"]["output"];
};

/** aggregated selection of "visits_discountline" */
export type DiscountLineAggregate = {
  __typename?: "discountLineAggregate";
  aggregate?: Maybe<DiscountLineAggregateFields>;
  nodes: Array<DiscountLine>;
};

export type DiscountLineAggregateBoolExp = {
  bool_and?: InputMaybe<DiscountLineAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<DiscountLineAggregateBoolExpBool_Or>;
  count?: InputMaybe<DiscountLineAggregateBoolExpCount>;
};

export type DiscountLineAggregateBoolExpBool_And = {
  arguments: DiscountLineSelectColumnDiscountLineAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DiscountLineAggregateBoolExpBool_Or = {
  arguments: DiscountLineSelectColumnDiscountLineAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DiscountLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DiscountLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountLineBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_discountline" */
export type DiscountLineAggregateFields = {
  __typename?: "discountLineAggregateFields";
  avg?: Maybe<DiscountLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<DiscountLineMaxFields>;
  min?: Maybe<DiscountLineMinFields>;
  stddev?: Maybe<DiscountLineStddevFields>;
  stddevPop?: Maybe<DiscountLineStddevPopFields>;
  stddevSamp?: Maybe<DiscountLineStddevSampFields>;
  sum?: Maybe<DiscountLineSumFields>;
  varPop?: Maybe<DiscountLineVarPopFields>;
  varSamp?: Maybe<DiscountLineVarSampFields>;
  variance?: Maybe<DiscountLineVarianceFields>;
};

/** aggregate fields of "visits_discountline" */
export type DiscountLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiscountLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_discountline" */
export type DiscountLineAggregateOrderBy = {
  avg?: InputMaybe<DiscountLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DiscountLineMaxOrderBy>;
  min?: InputMaybe<DiscountLineMinOrderBy>;
  stddev?: InputMaybe<DiscountLineStddevOrderBy>;
  stddevPop?: InputMaybe<DiscountLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DiscountLineStddevSampOrderBy>;
  sum?: InputMaybe<DiscountLineSumOrderBy>;
  varPop?: InputMaybe<DiscountLineVarPopOrderBy>;
  varSamp?: InputMaybe<DiscountLineVarSampOrderBy>;
  variance?: InputMaybe<DiscountLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_discountline" */
export type DiscountLineArrRelInsertInput = {
  data: Array<DiscountLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DiscountLineOnConflict>;
};

/** aggregate avg on columns */
export type DiscountLineAvgFields = {
  __typename?: "discountLineAvgFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  productLineId?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  quantityUsed?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_discountline" */
export type DiscountLineAvgOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_discountline". All fields are combined with a logical 'AND'. */
export type DiscountLineBoolExp = {
  _and?: InputMaybe<Array<DiscountLineBoolExp>>;
  _not?: InputMaybe<DiscountLineBoolExp>;
  _or?: InputMaybe<Array<DiscountLineBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  discount?: InputMaybe<DiscountBoolExp>;
  discountId?: InputMaybe<BigintComparisonExp>;
  discountType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  isInvoiceLevel?: InputMaybe<BooleanComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  productLine?: InputMaybe<ProductLineBoolExp>;
  productLineId?: InputMaybe<BigintComparisonExp>;
  quantityLimit?: InputMaybe<IntComparisonExp>;
  quantityUsed?: InputMaybe<IntComparisonExp>;
  serviceMenuItemLineId?: InputMaybe<BigintComparisonExp>;
  serviceProductLine?: InputMaybe<ServiceProductLineBoolExp>;
  serviceProductLineId?: InputMaybe<BigintComparisonExp>;
  value?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "visits_discountline" */
export enum DiscountLineConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsDiscountlinePkey = "visits_discountline_pkey",
}

/** input type for incrementing numeric columns in table "visits_discountline" */
export type DiscountLineIncInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  productLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  quantityUsed?: InputMaybe<Scalars["Int"]["input"]>;
  serviceMenuItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "visits_discountline" */
export type DiscountLineInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discount?: InputMaybe<DiscountObjRelInsertInput>;
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  discountType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  isInvoiceLevel?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  productLine?: InputMaybe<ProductLineObjRelInsertInput>;
  productLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  quantityUsed?: InputMaybe<Scalars["Int"]["input"]>;
  serviceMenuItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductLine?: InputMaybe<ServiceProductLineObjRelInsertInput>;
  serviceProductLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate max on columns */
export type DiscountLineMaxFields = {
  __typename?: "discountLineMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  discountType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  productLineId?: Maybe<Scalars["bigint"]["output"]>;
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  quantityUsed?: Maybe<Scalars["Int"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["bigint"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by max() on columns of table "visits_discountline" */
export type DiscountLineMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  discountId?: InputMaybe<OrderBy>;
  discountType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DiscountLineMinFields = {
  __typename?: "discountLineMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  discountType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  productLineId?: Maybe<Scalars["bigint"]["output"]>;
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  quantityUsed?: Maybe<Scalars["Int"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["bigint"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by min() on columns of table "visits_discountline" */
export type DiscountLineMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  discountId?: InputMaybe<OrderBy>;
  discountType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_discountline" */
export type DiscountLineMutationResponse = {
  __typename?: "discountLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<DiscountLine>;
};

/** on_conflict condition type for table "visits_discountline" */
export type DiscountLineOnConflict = {
  constraint: DiscountLineConstraint;
  updateColumns?: Array<DiscountLineUpdateColumn>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

/** Ordering options when selecting data from "visits_discountline". */
export type DiscountLineOrderBy = {
  created?: InputMaybe<OrderBy>;
  discount?: InputMaybe<DiscountOrderBy>;
  discountId?: InputMaybe<OrderBy>;
  discountType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  isInvoiceLevel?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  productLine?: InputMaybe<ProductLineOrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLine?: InputMaybe<ServiceProductLineOrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_discountline */
export type DiscountLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_discountline" */
export enum DiscountLineSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DiscountId = "discountId",
  /** column name */
  DiscountType = "discountType",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  IsInvoiceLevel = "isInvoiceLevel",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ProductLineId = "productLineId",
  /** column name */
  QuantityLimit = "quantityLimit",
  /** column name */
  QuantityUsed = "quantityUsed",
  /** column name */
  ServiceMenuItemLineId = "serviceMenuItemLineId",
  /** column name */
  ServiceProductLineId = "serviceProductLineId",
  /** column name */
  Value = "value",
}

/** select "discountLineAggregateBoolExpBool_andArgumentsColumns" columns of table "visits_discountline" */
export enum DiscountLineSelectColumnDiscountLineAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsInvoiceLevel = "isInvoiceLevel",
}

/** select "discountLineAggregateBoolExpBool_orArgumentsColumns" columns of table "visits_discountline" */
export enum DiscountLineSelectColumnDiscountLineAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsInvoiceLevel = "isInvoiceLevel",
}

/** input type for updating data in table "visits_discountline" */
export type DiscountLineSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  discountType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  isInvoiceLevel?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  productLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  quantityUsed?: InputMaybe<Scalars["Int"]["input"]>;
  serviceMenuItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate stddev on columns */
export type DiscountLineStddevFields = {
  __typename?: "discountLineStddevFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  productLineId?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  quantityUsed?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_discountline" */
export type DiscountLineStddevOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DiscountLineStddevPopFields = {
  __typename?: "discountLineStddevPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  productLineId?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  quantityUsed?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_discountline" */
export type DiscountLineStddevPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DiscountLineStddevSampFields = {
  __typename?: "discountLineStddevSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  productLineId?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  quantityUsed?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_discountline" */
export type DiscountLineStddevSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "discountLine" */
export type DiscountLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DiscountLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiscountLineStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  discountType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  isInvoiceLevel?: InputMaybe<Scalars["Boolean"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  productLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  quantityUsed?: InputMaybe<Scalars["Int"]["input"]>;
  serviceMenuItemLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductLineId?: InputMaybe<Scalars["bigint"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate sum on columns */
export type DiscountLineSumFields = {
  __typename?: "discountLineSumFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  productLineId?: Maybe<Scalars["bigint"]["output"]>;
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  quantityUsed?: Maybe<Scalars["Int"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["bigint"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "visits_discountline" */
export type DiscountLineSumOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_discountline" */
export enum DiscountLineUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DiscountId = "discountId",
  /** column name */
  DiscountType = "discountType",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  IsInvoiceLevel = "isInvoiceLevel",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  ProductLineId = "productLineId",
  /** column name */
  QuantityLimit = "quantityLimit",
  /** column name */
  QuantityUsed = "quantityUsed",
  /** column name */
  ServiceMenuItemLineId = "serviceMenuItemLineId",
  /** column name */
  ServiceProductLineId = "serviceProductLineId",
  /** column name */
  Value = "value",
}

export type DiscountLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiscountLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiscountLineSetInput>;
  /** filter the rows which have to be updated */
  where: DiscountLineBoolExp;
};

/** aggregate varPop on columns */
export type DiscountLineVarPopFields = {
  __typename?: "discountLineVarPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  productLineId?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  quantityUsed?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_discountline" */
export type DiscountLineVarPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DiscountLineVarSampFields = {
  __typename?: "discountLineVarSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  productLineId?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  quantityUsed?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_discountline" */
export type DiscountLineVarSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DiscountLineVarianceFields = {
  __typename?: "discountLineVarianceFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  productLineId?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  quantityUsed?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemLineId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductLineId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_discountline" */
export type DiscountLineVarianceOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  productLineId?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  quantityUsed?: InputMaybe<OrderBy>;
  serviceMenuItemLineId?: InputMaybe<OrderBy>;
  serviceProductLineId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type DiscountMaxFields = {
  __typename?: "discountMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  discountType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  modifiedById?: Maybe<Scalars["bigint"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by max() on columns of table "resources_discount" */
export type DiscountMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  discountType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DiscountMinFields = {
  __typename?: "discountMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  discountType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  modifiedById?: Maybe<Scalars["bigint"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by min() on columns of table "resources_discount" */
export type DiscountMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  discountType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_discount" */
export type DiscountMutationResponse = {
  __typename?: "discountMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Discount>;
};

/** input type for inserting object relation for remote table "resources_discount" */
export type DiscountObjRelInsertInput = {
  data: DiscountInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<DiscountOnConflict>;
};

/** on_conflict condition type for table "resources_discount" */
export type DiscountOnConflict = {
  constraint: DiscountConstraint;
  updateColumns?: Array<DiscountUpdateColumn>;
  where?: InputMaybe<DiscountBoolExp>;
};

/** Ordering options when selecting data from "resources_discount". */
export type DiscountOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  discountLinesAggregate?: InputMaybe<DiscountLineAggregateOrderBy>;
  discountRetailProductsAggregate?: InputMaybe<DiscountRetailProductsAggregateOrderBy>;
  discountServiceMenuItemsAggregate?: InputMaybe<DiscountServiceMenuItemsAggregateOrderBy>;
  discountServiceProductsAggregate?: InputMaybe<DiscountServiceProductsAggregateOrderBy>;
  discountType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isMembership?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_discount */
export type DiscountPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** columns and relationships of "discount_product" */
export type DiscountRetailProducts = {
  __typename?: "discountRetailProducts";
  /** An object relationship */
  discount: Discount;
  discountId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  product: Product;
  productId: Scalars["bigint"]["output"];
};

/** aggregated selection of "discount_product" */
export type DiscountRetailProductsAggregate = {
  __typename?: "discountRetailProductsAggregate";
  aggregate?: Maybe<DiscountRetailProductsAggregateFields>;
  nodes: Array<DiscountRetailProducts>;
};

export type DiscountRetailProductsAggregateBoolExp = {
  count?: InputMaybe<DiscountRetailProductsAggregateBoolExpCount>;
};

export type DiscountRetailProductsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountRetailProductsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "discount_product" */
export type DiscountRetailProductsAggregateFields = {
  __typename?: "discountRetailProductsAggregateFields";
  avg?: Maybe<DiscountRetailProductsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<DiscountRetailProductsMaxFields>;
  min?: Maybe<DiscountRetailProductsMinFields>;
  stddev?: Maybe<DiscountRetailProductsStddevFields>;
  stddevPop?: Maybe<DiscountRetailProductsStddevPopFields>;
  stddevSamp?: Maybe<DiscountRetailProductsStddevSampFields>;
  sum?: Maybe<DiscountRetailProductsSumFields>;
  varPop?: Maybe<DiscountRetailProductsVarPopFields>;
  varSamp?: Maybe<DiscountRetailProductsVarSampFields>;
  variance?: Maybe<DiscountRetailProductsVarianceFields>;
};

/** aggregate fields of "discount_product" */
export type DiscountRetailProductsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "discount_product" */
export type DiscountRetailProductsAggregateOrderBy = {
  avg?: InputMaybe<DiscountRetailProductsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DiscountRetailProductsMaxOrderBy>;
  min?: InputMaybe<DiscountRetailProductsMinOrderBy>;
  stddev?: InputMaybe<DiscountRetailProductsStddevOrderBy>;
  stddevPop?: InputMaybe<DiscountRetailProductsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DiscountRetailProductsStddevSampOrderBy>;
  sum?: InputMaybe<DiscountRetailProductsSumOrderBy>;
  varPop?: InputMaybe<DiscountRetailProductsVarPopOrderBy>;
  varSamp?: InputMaybe<DiscountRetailProductsVarSampOrderBy>;
  variance?: InputMaybe<DiscountRetailProductsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "discount_product" */
export type DiscountRetailProductsArrRelInsertInput = {
  data: Array<DiscountRetailProductsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DiscountRetailProductsOnConflict>;
};

/** aggregate avg on columns */
export type DiscountRetailProductsAvgFields = {
  __typename?: "discountRetailProductsAvgFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "discount_product" */
export type DiscountRetailProductsAvgOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "discount_product". All fields are combined with a logical 'AND'. */
export type DiscountRetailProductsBoolExp = {
  _and?: InputMaybe<Array<DiscountRetailProductsBoolExp>>;
  _not?: InputMaybe<DiscountRetailProductsBoolExp>;
  _or?: InputMaybe<Array<DiscountRetailProductsBoolExp>>;
  discount?: InputMaybe<DiscountBoolExp>;
  discountId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "discount_product" */
export enum DiscountRetailProductsConstraint {
  /** unique or primary key constraint on columns "product_id", "discount_id" */
  DiscountProductDiscountIdProductId_1b20df13Uniq = "discount_product_discount_id_product_id_1b20df13_uniq",
  /** unique or primary key constraint on columns "id" */
  DiscountProductPkey = "discount_product_pkey",
}

/** input type for incrementing numeric columns in table "discount_product" */
export type DiscountRetailProductsIncInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "discount_product" */
export type DiscountRetailProductsInsertInput = {
  discount?: InputMaybe<DiscountObjRelInsertInput>;
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type DiscountRetailProductsMaxFields = {
  __typename?: "discountRetailProductsMaxFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "discount_product" */
export type DiscountRetailProductsMaxOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DiscountRetailProductsMinFields = {
  __typename?: "discountRetailProductsMinFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "discount_product" */
export type DiscountRetailProductsMinOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "discount_product" */
export type DiscountRetailProductsMutationResponse = {
  __typename?: "discountRetailProductsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<DiscountRetailProducts>;
};

/** on_conflict condition type for table "discount_product" */
export type DiscountRetailProductsOnConflict = {
  constraint: DiscountRetailProductsConstraint;
  updateColumns?: Array<DiscountRetailProductsUpdateColumn>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

/** Ordering options when selecting data from "discount_product". */
export type DiscountRetailProductsOrderBy = {
  discount?: InputMaybe<DiscountOrderBy>;
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: discount_product */
export type DiscountRetailProductsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "discount_product" */
export enum DiscountRetailProductsSelectColumn {
  /** column name */
  DiscountId = "discountId",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "productId",
}

/** input type for updating data in table "discount_product" */
export type DiscountRetailProductsSetInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type DiscountRetailProductsStddevFields = {
  __typename?: "discountRetailProductsStddevFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "discount_product" */
export type DiscountRetailProductsStddevOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DiscountRetailProductsStddevPopFields = {
  __typename?: "discountRetailProductsStddevPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "discount_product" */
export type DiscountRetailProductsStddevPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DiscountRetailProductsStddevSampFields = {
  __typename?: "discountRetailProductsStddevSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "discount_product" */
export type DiscountRetailProductsStddevSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "discountRetailProducts" */
export type DiscountRetailProductsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DiscountRetailProductsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiscountRetailProductsStreamCursorValueInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type DiscountRetailProductsSumFields = {
  __typename?: "discountRetailProductsSumFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "discount_product" */
export type DiscountRetailProductsSumOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** update columns of table "discount_product" */
export enum DiscountRetailProductsUpdateColumn {
  /** column name */
  DiscountId = "discountId",
  /** column name */
  Id = "id",
  /** column name */
  ProductId = "productId",
}

export type DiscountRetailProductsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiscountRetailProductsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiscountRetailProductsSetInput>;
  /** filter the rows which have to be updated */
  where: DiscountRetailProductsBoolExp;
};

/** aggregate varPop on columns */
export type DiscountRetailProductsVarPopFields = {
  __typename?: "discountRetailProductsVarPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "discount_product" */
export type DiscountRetailProductsVarPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DiscountRetailProductsVarSampFields = {
  __typename?: "discountRetailProductsVarSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "discount_product" */
export type DiscountRetailProductsVarSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DiscountRetailProductsVarianceFields = {
  __typename?: "discountRetailProductsVarianceFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "discount_product" */
export type DiscountRetailProductsVarianceOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
};

/** select columns of table "resources_discount" */
export enum DiscountSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  DiscountType = "discountType",
  /** column name */
  Id = "id",
  /** column name */
  IsMembership = "isMembership",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  ModifiedById = "modifiedById",
  /** column name */
  Name = "name",
  /** column name */
  QuantityLimit = "quantityLimit",
  /** column name */
  Value = "value",
}

/** select "discountAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_discount" */
export enum DiscountSelectColumnDiscountAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsMembership = "isMembership",
}

/** select "discountAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_discount" */
export enum DiscountSelectColumnDiscountAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsMembership = "isMembership",
}

/** columns and relationships of "resources_discount_service_menu_items" */
export type DiscountServiceMenuItems = {
  __typename?: "discountServiceMenuItems";
  /** An object relationship */
  discount: Discount;
  discountId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  medspaservicemenuitemId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceMenuItem: MedspaServiceMenuItem;
};

/** aggregated selection of "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsAggregate = {
  __typename?: "discountServiceMenuItemsAggregate";
  aggregate?: Maybe<DiscountServiceMenuItemsAggregateFields>;
  nodes: Array<DiscountServiceMenuItems>;
};

export type DiscountServiceMenuItemsAggregateBoolExp = {
  count?: InputMaybe<DiscountServiceMenuItemsAggregateBoolExpCount>;
};

export type DiscountServiceMenuItemsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DiscountServiceMenuItemsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsAggregateFields = {
  __typename?: "discountServiceMenuItemsAggregateFields";
  avg?: Maybe<DiscountServiceMenuItemsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<DiscountServiceMenuItemsMaxFields>;
  min?: Maybe<DiscountServiceMenuItemsMinFields>;
  stddev?: Maybe<DiscountServiceMenuItemsStddevFields>;
  stddevPop?: Maybe<DiscountServiceMenuItemsStddevPopFields>;
  stddevSamp?: Maybe<DiscountServiceMenuItemsStddevSampFields>;
  sum?: Maybe<DiscountServiceMenuItemsSumFields>;
  varPop?: Maybe<DiscountServiceMenuItemsVarPopFields>;
  varSamp?: Maybe<DiscountServiceMenuItemsVarSampFields>;
  variance?: Maybe<DiscountServiceMenuItemsVarianceFields>;
};

/** aggregate fields of "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiscountServiceMenuItemsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsAggregateOrderBy = {
  avg?: InputMaybe<DiscountServiceMenuItemsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DiscountServiceMenuItemsMaxOrderBy>;
  min?: InputMaybe<DiscountServiceMenuItemsMinOrderBy>;
  stddev?: InputMaybe<DiscountServiceMenuItemsStddevOrderBy>;
  stddevPop?: InputMaybe<DiscountServiceMenuItemsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DiscountServiceMenuItemsStddevSampOrderBy>;
  sum?: InputMaybe<DiscountServiceMenuItemsSumOrderBy>;
  varPop?: InputMaybe<DiscountServiceMenuItemsVarPopOrderBy>;
  varSamp?: InputMaybe<DiscountServiceMenuItemsVarSampOrderBy>;
  variance?: InputMaybe<DiscountServiceMenuItemsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsArrRelInsertInput = {
  data: Array<DiscountServiceMenuItemsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DiscountServiceMenuItemsOnConflict>;
};

/** aggregate avg on columns */
export type DiscountServiceMenuItemsAvgFields = {
  __typename?: "discountServiceMenuItemsAvgFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsAvgOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_discount_service_menu_items". All fields are combined with a logical 'AND'. */
export type DiscountServiceMenuItemsBoolExp = {
  _and?: InputMaybe<Array<DiscountServiceMenuItemsBoolExp>>;
  _not?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
  _or?: InputMaybe<Array<DiscountServiceMenuItemsBoolExp>>;
  discount?: InputMaybe<DiscountBoolExp>;
  discountId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaservicemenuitemId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** unique or primary key constraints on table "resources_discount_service_menu_items" */
export enum DiscountServiceMenuItemsConstraint {
  /** unique or primary key constraint on columns "medspaservicemenuitem_id", "discount_id" */
  ResourcesDiscountServiDiscountIdMedspaservic_2397cc2bUniq = "resources_discount_servi_discount_id_medspaservic_2397cc2b_uniq",
  /** unique or primary key constraint on columns "id" */
  ResourcesDiscountServiceMenuItemsPkey = "resources_discount_service_menu_items_pkey",
}

/** input type for incrementing numeric columns in table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsIncInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsInsertInput = {
  discount?: InputMaybe<DiscountObjRelInsertInput>;
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
};

/** aggregate max on columns */
export type DiscountServiceMenuItemsMaxFields = {
  __typename?: "discountServiceMenuItemsMaxFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsMaxOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DiscountServiceMenuItemsMinFields = {
  __typename?: "discountServiceMenuItemsMinFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsMinOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsMutationResponse = {
  __typename?: "discountServiceMenuItemsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<DiscountServiceMenuItems>;
};

/** on_conflict condition type for table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsOnConflict = {
  constraint: DiscountServiceMenuItemsConstraint;
  updateColumns?: Array<DiscountServiceMenuItemsUpdateColumn>;
  where?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
};

/** Ordering options when selecting data from "resources_discount_service_menu_items". */
export type DiscountServiceMenuItemsOrderBy = {
  discount?: InputMaybe<DiscountOrderBy>;
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
};

/** primary key columns input for table: resources_discount_service_menu_items */
export type DiscountServiceMenuItemsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_discount_service_menu_items" */
export enum DiscountServiceMenuItemsSelectColumn {
  /** column name */
  DiscountId = "discountId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
}

/** input type for updating data in table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsSetInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type DiscountServiceMenuItemsStddevFields = {
  __typename?: "discountServiceMenuItemsStddevFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsStddevOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DiscountServiceMenuItemsStddevPopFields = {
  __typename?: "discountServiceMenuItemsStddevPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsStddevPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DiscountServiceMenuItemsStddevSampFields = {
  __typename?: "discountServiceMenuItemsStddevSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsStddevSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "discountServiceMenuItems" */
export type DiscountServiceMenuItemsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DiscountServiceMenuItemsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiscountServiceMenuItemsStreamCursorValueInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type DiscountServiceMenuItemsSumFields = {
  __typename?: "discountServiceMenuItemsSumFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsSumOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_discount_service_menu_items" */
export enum DiscountServiceMenuItemsUpdateColumn {
  /** column name */
  DiscountId = "discountId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
}

export type DiscountServiceMenuItemsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiscountServiceMenuItemsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiscountServiceMenuItemsSetInput>;
  /** filter the rows which have to be updated */
  where: DiscountServiceMenuItemsBoolExp;
};

/** aggregate varPop on columns */
export type DiscountServiceMenuItemsVarPopFields = {
  __typename?: "discountServiceMenuItemsVarPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsVarPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DiscountServiceMenuItemsVarSampFields = {
  __typename?: "discountServiceMenuItemsVarSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsVarSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DiscountServiceMenuItemsVarianceFields = {
  __typename?: "discountServiceMenuItemsVarianceFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_discount_service_menu_items" */
export type DiscountServiceMenuItemsVarianceOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "resources_discount_service_products" */
export type DiscountServiceProducts = {
  __typename?: "discountServiceProducts";
  discountId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspaServiceProduct: MedspaServiceProduct;
  medspaserviceproductId: Scalars["bigint"]["output"];
  /** An object relationship */
  resourcesDiscount: Discount;
};

/** aggregated selection of "resources_discount_service_products" */
export type DiscountServiceProductsAggregate = {
  __typename?: "discountServiceProductsAggregate";
  aggregate?: Maybe<DiscountServiceProductsAggregateFields>;
  nodes: Array<DiscountServiceProducts>;
};

export type DiscountServiceProductsAggregateBoolExp = {
  count?: InputMaybe<DiscountServiceProductsAggregateBoolExpCount>;
};

export type DiscountServiceProductsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<DiscountServiceProductsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_discount_service_products" */
export type DiscountServiceProductsAggregateFields = {
  __typename?: "discountServiceProductsAggregateFields";
  avg?: Maybe<DiscountServiceProductsAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<DiscountServiceProductsMaxFields>;
  min?: Maybe<DiscountServiceProductsMinFields>;
  stddev?: Maybe<DiscountServiceProductsStddevFields>;
  stddevPop?: Maybe<DiscountServiceProductsStddevPopFields>;
  stddevSamp?: Maybe<DiscountServiceProductsStddevSampFields>;
  sum?: Maybe<DiscountServiceProductsSumFields>;
  varPop?: Maybe<DiscountServiceProductsVarPopFields>;
  varSamp?: Maybe<DiscountServiceProductsVarSampFields>;
  variance?: Maybe<DiscountServiceProductsVarianceFields>;
};

/** aggregate fields of "resources_discount_service_products" */
export type DiscountServiceProductsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_discount_service_products" */
export type DiscountServiceProductsAggregateOrderBy = {
  avg?: InputMaybe<DiscountServiceProductsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DiscountServiceProductsMaxOrderBy>;
  min?: InputMaybe<DiscountServiceProductsMinOrderBy>;
  stddev?: InputMaybe<DiscountServiceProductsStddevOrderBy>;
  stddevPop?: InputMaybe<DiscountServiceProductsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DiscountServiceProductsStddevSampOrderBy>;
  sum?: InputMaybe<DiscountServiceProductsSumOrderBy>;
  varPop?: InputMaybe<DiscountServiceProductsVarPopOrderBy>;
  varSamp?: InputMaybe<DiscountServiceProductsVarSampOrderBy>;
  variance?: InputMaybe<DiscountServiceProductsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_discount_service_products" */
export type DiscountServiceProductsArrRelInsertInput = {
  data: Array<DiscountServiceProductsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<DiscountServiceProductsOnConflict>;
};

/** aggregate avg on columns */
export type DiscountServiceProductsAvgFields = {
  __typename?: "discountServiceProductsAvgFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsAvgOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_discount_service_products". All fields are combined with a logical 'AND'. */
export type DiscountServiceProductsBoolExp = {
  _and?: InputMaybe<Array<DiscountServiceProductsBoolExp>>;
  _not?: InputMaybe<DiscountServiceProductsBoolExp>;
  _or?: InputMaybe<Array<DiscountServiceProductsBoolExp>>;
  discountId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductBoolExp>;
  medspaserviceproductId?: InputMaybe<BigintComparisonExp>;
  resourcesDiscount?: InputMaybe<DiscountBoolExp>;
};

/** unique or primary key constraints on table "resources_discount_service_products" */
export enum DiscountServiceProductsConstraint {
  /** unique or primary key constraint on columns "medspaserviceproduct_id", "discount_id" */
  ResourcesDiscountServiDiscountIdMedspaservicE10c9a74Uniq = "resources_discount_servi_discount_id_medspaservic_e10c9a74_uniq",
  /** unique or primary key constraint on columns "id" */
  ResourcesDiscountServiceProductsPkey = "resources_discount_service_products_pkey",
}

/** input type for incrementing numeric columns in table "resources_discount_service_products" */
export type DiscountServiceProductsIncInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceproductId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_discount_service_products" */
export type DiscountServiceProductsInsertInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductObjRelInsertInput>;
  medspaserviceproductId?: InputMaybe<Scalars["bigint"]["input"]>;
  resourcesDiscount?: InputMaybe<DiscountObjRelInsertInput>;
};

/** aggregate max on columns */
export type DiscountServiceProductsMaxFields = {
  __typename?: "discountServiceProductsMaxFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsMaxOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DiscountServiceProductsMinFields = {
  __typename?: "discountServiceProductsMinFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsMinOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_discount_service_products" */
export type DiscountServiceProductsMutationResponse = {
  __typename?: "discountServiceProductsMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<DiscountServiceProducts>;
};

/** on_conflict condition type for table "resources_discount_service_products" */
export type DiscountServiceProductsOnConflict = {
  constraint: DiscountServiceProductsConstraint;
  updateColumns?: Array<DiscountServiceProductsUpdateColumn>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

/** Ordering options when selecting data from "resources_discount_service_products". */
export type DiscountServiceProductsOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaServiceProduct?: InputMaybe<MedspaServiceProductOrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
  resourcesDiscount?: InputMaybe<DiscountOrderBy>;
};

/** primary key columns input for table: resources_discount_service_products */
export type DiscountServiceProductsPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_discount_service_products" */
export enum DiscountServiceProductsSelectColumn {
  /** column name */
  DiscountId = "discountId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaserviceproductId = "medspaserviceproductId",
}

/** input type for updating data in table "resources_discount_service_products" */
export type DiscountServiceProductsSetInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceproductId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type DiscountServiceProductsStddevFields = {
  __typename?: "discountServiceProductsStddevFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsStddevOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DiscountServiceProductsStddevPopFields = {
  __typename?: "discountServiceProductsStddevPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsStddevPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DiscountServiceProductsStddevSampFields = {
  __typename?: "discountServiceProductsStddevSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsStddevSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "discountServiceProducts" */
export type DiscountServiceProductsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DiscountServiceProductsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiscountServiceProductsStreamCursorValueInput = {
  discountId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaserviceproductId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type DiscountServiceProductsSumFields = {
  __typename?: "discountServiceProductsSumFields";
  discountId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsSumOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_discount_service_products" */
export enum DiscountServiceProductsUpdateColumn {
  /** column name */
  DiscountId = "discountId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaserviceproductId = "medspaserviceproductId",
}

export type DiscountServiceProductsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiscountServiceProductsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiscountServiceProductsSetInput>;
  /** filter the rows which have to be updated */
  where: DiscountServiceProductsBoolExp;
};

/** aggregate varPop on columns */
export type DiscountServiceProductsVarPopFields = {
  __typename?: "discountServiceProductsVarPopFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsVarPopOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DiscountServiceProductsVarSampFields = {
  __typename?: "discountServiceProductsVarSampFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsVarSampOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DiscountServiceProductsVarianceFields = {
  __typename?: "discountServiceProductsVarianceFields";
  discountId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaserviceproductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_discount_service_products" */
export type DiscountServiceProductsVarianceOrderBy = {
  discountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaserviceproductId?: InputMaybe<OrderBy>;
};

/** input type for updating data in table "resources_discount" */
export type DiscountSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  discountType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if a discount can be used only for memberships */
  isMembership?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  modifiedById?: InputMaybe<Scalars["bigint"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate stddev on columns */
export type DiscountStddevFields = {
  __typename?: "discountStddevFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  modifiedById?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_discount" */
export type DiscountStddevOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DiscountStddevPopFields = {
  __typename?: "discountStddevPopFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  modifiedById?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_discount" */
export type DiscountStddevPopOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DiscountStddevSampFields = {
  __typename?: "discountStddevSampFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  modifiedById?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_discount" */
export type DiscountStddevSampOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "discount" */
export type DiscountStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DiscountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiscountStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  discountType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Indicates if a discount can be used only for memberships */
  isMembership?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  modifiedById?: InputMaybe<Scalars["bigint"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate sum on columns */
export type DiscountSumFields = {
  __typename?: "discountSumFields";
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modifiedById?: Maybe<Scalars["bigint"]["output"]>;
  quantityLimit?: Maybe<Scalars["Int"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "resources_discount" */
export type DiscountSumOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_discount" */
export enum DiscountUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  DiscountType = "discountType",
  /** column name */
  Id = "id",
  /** column name */
  IsMembership = "isMembership",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  ModifiedById = "modifiedById",
  /** column name */
  Name = "name",
  /** column name */
  QuantityLimit = "quantityLimit",
  /** column name */
  Value = "value",
}

export type DiscountUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiscountIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiscountSetInput>;
  /** filter the rows which have to be updated */
  where: DiscountBoolExp;
};

/** aggregate varPop on columns */
export type DiscountVarPopFields = {
  __typename?: "discountVarPopFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  modifiedById?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_discount" */
export type DiscountVarPopOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DiscountVarSampFields = {
  __typename?: "discountVarSampFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  modifiedById?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_discount" */
export type DiscountVarSampOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DiscountVarianceFields = {
  __typename?: "discountVarianceFields";
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  modifiedById?: Maybe<Scalars["Float"]["output"]>;
  quantityLimit?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_discount" */
export type DiscountVarianceOrderBy = {
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modifiedById?: InputMaybe<OrderBy>;
  quantityLimit?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** columns and relationships of "resources_fee" */
export type Fee = {
  __typename?: "fee";
  created: Scalars["timestamptz"]["output"];
  deleted: Scalars["Boolean"]["output"];
  /** An array relationship */
  feeLines: Array<FeeLine>;
  /** An aggregate relationship */
  feeLinesAggregate: FeeLineAggregate;
  feeType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["numeric"]["output"];
};

/** columns and relationships of "resources_fee" */
export type FeeFeeLinesArgs = {
  distinctOn?: InputMaybe<Array<FeeLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeLineOrderBy>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

/** columns and relationships of "resources_fee" */
export type FeeFeeLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeeLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeLineOrderBy>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

/** aggregated selection of "resources_fee" */
export type FeeAggregate = {
  __typename?: "feeAggregate";
  aggregate?: Maybe<FeeAggregateFields>;
  nodes: Array<Fee>;
};

export type FeeAggregateBoolExp = {
  bool_and?: InputMaybe<FeeAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<FeeAggregateBoolExpBool_Or>;
  count?: InputMaybe<FeeAggregateBoolExpCount>;
};

export type FeeAggregateBoolExpBool_And = {
  arguments: FeeSelectColumnFeeAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FeeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FeeAggregateBoolExpBool_Or = {
  arguments: FeeSelectColumnFeeAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FeeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FeeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FeeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FeeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_fee" */
export type FeeAggregateFields = {
  __typename?: "feeAggregateFields";
  avg?: Maybe<FeeAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FeeMaxFields>;
  min?: Maybe<FeeMinFields>;
  stddev?: Maybe<FeeStddevFields>;
  stddevPop?: Maybe<FeeStddevPopFields>;
  stddevSamp?: Maybe<FeeStddevSampFields>;
  sum?: Maybe<FeeSumFields>;
  varPop?: Maybe<FeeVarPopFields>;
  varSamp?: Maybe<FeeVarSampFields>;
  variance?: Maybe<FeeVarianceFields>;
};

/** aggregate fields of "resources_fee" */
export type FeeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FeeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_fee" */
export type FeeAggregateOrderBy = {
  avg?: InputMaybe<FeeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FeeMaxOrderBy>;
  min?: InputMaybe<FeeMinOrderBy>;
  stddev?: InputMaybe<FeeStddevOrderBy>;
  stddevPop?: InputMaybe<FeeStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FeeStddevSampOrderBy>;
  sum?: InputMaybe<FeeSumOrderBy>;
  varPop?: InputMaybe<FeeVarPopOrderBy>;
  varSamp?: InputMaybe<FeeVarSampOrderBy>;
  variance?: InputMaybe<FeeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_fee" */
export type FeeArrRelInsertInput = {
  data: Array<FeeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FeeOnConflict>;
};

/** aggregate avg on columns */
export type FeeAvgFields = {
  __typename?: "feeAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_fee" */
export type FeeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_fee". All fields are combined with a logical 'AND'. */
export type FeeBoolExp = {
  _and?: InputMaybe<Array<FeeBoolExp>>;
  _not?: InputMaybe<FeeBoolExp>;
  _or?: InputMaybe<Array<FeeBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  feeLines?: InputMaybe<FeeLineBoolExp>;
  feeLinesAggregate?: InputMaybe<FeeLineAggregateBoolExp>;
  feeType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "resources_fee" */
export enum FeeConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesFeePkey = "resources_fee_pkey",
}

/** input type for incrementing numeric columns in table "resources_fee" */
export type FeeIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "resources_fee" */
export type FeeInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  feeLines?: InputMaybe<FeeLineArrRelInsertInput>;
  feeType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** columns and relationships of "visits_feeline" */
export type FeeLine = {
  __typename?: "feeLine";
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  fee?: Maybe<Fee>;
  feeId?: Maybe<Scalars["bigint"]["output"]>;
  feeType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["numeric"]["output"];
};

/** aggregated selection of "visits_feeline" */
export type FeeLineAggregate = {
  __typename?: "feeLineAggregate";
  aggregate?: Maybe<FeeLineAggregateFields>;
  nodes: Array<FeeLine>;
};

export type FeeLineAggregateBoolExp = {
  count?: InputMaybe<FeeLineAggregateBoolExpCount>;
};

export type FeeLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FeeLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FeeLineBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_feeline" */
export type FeeLineAggregateFields = {
  __typename?: "feeLineAggregateFields";
  avg?: Maybe<FeeLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FeeLineMaxFields>;
  min?: Maybe<FeeLineMinFields>;
  stddev?: Maybe<FeeLineStddevFields>;
  stddevPop?: Maybe<FeeLineStddevPopFields>;
  stddevSamp?: Maybe<FeeLineStddevSampFields>;
  sum?: Maybe<FeeLineSumFields>;
  varPop?: Maybe<FeeLineVarPopFields>;
  varSamp?: Maybe<FeeLineVarSampFields>;
  variance?: Maybe<FeeLineVarianceFields>;
};

/** aggregate fields of "visits_feeline" */
export type FeeLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FeeLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_feeline" */
export type FeeLineAggregateOrderBy = {
  avg?: InputMaybe<FeeLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FeeLineMaxOrderBy>;
  min?: InputMaybe<FeeLineMinOrderBy>;
  stddev?: InputMaybe<FeeLineStddevOrderBy>;
  stddevPop?: InputMaybe<FeeLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FeeLineStddevSampOrderBy>;
  sum?: InputMaybe<FeeLineSumOrderBy>;
  varPop?: InputMaybe<FeeLineVarPopOrderBy>;
  varSamp?: InputMaybe<FeeLineVarSampOrderBy>;
  variance?: InputMaybe<FeeLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_feeline" */
export type FeeLineArrRelInsertInput = {
  data: Array<FeeLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FeeLineOnConflict>;
};

/** aggregate avg on columns */
export type FeeLineAvgFields = {
  __typename?: "feeLineAvgFields";
  feeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_feeline" */
export type FeeLineAvgOrderBy = {
  feeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_feeline". All fields are combined with a logical 'AND'. */
export type FeeLineBoolExp = {
  _and?: InputMaybe<Array<FeeLineBoolExp>>;
  _not?: InputMaybe<FeeLineBoolExp>;
  _or?: InputMaybe<Array<FeeLineBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  fee?: InputMaybe<FeeBoolExp>;
  feeId?: InputMaybe<BigintComparisonExp>;
  feeType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "visits_feeline" */
export enum FeeLineConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsFeelinePkey = "visits_feeline_pkey",
}

/** input type for incrementing numeric columns in table "visits_feeline" */
export type FeeLineIncInput = {
  feeId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "visits_feeline" */
export type FeeLineInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fee?: InputMaybe<FeeObjRelInsertInput>;
  feeId?: InputMaybe<Scalars["bigint"]["input"]>;
  feeType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate max on columns */
export type FeeLineMaxFields = {
  __typename?: "feeLineMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  feeId?: Maybe<Scalars["bigint"]["output"]>;
  feeType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by max() on columns of table "visits_feeline" */
export type FeeLineMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  feeId?: InputMaybe<OrderBy>;
  feeType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FeeLineMinFields = {
  __typename?: "feeLineMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  feeId?: Maybe<Scalars["bigint"]["output"]>;
  feeType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by min() on columns of table "visits_feeline" */
export type FeeLineMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  feeId?: InputMaybe<OrderBy>;
  feeType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_feeline" */
export type FeeLineMutationResponse = {
  __typename?: "feeLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<FeeLine>;
};

/** on_conflict condition type for table "visits_feeline" */
export type FeeLineOnConflict = {
  constraint: FeeLineConstraint;
  updateColumns?: Array<FeeLineUpdateColumn>;
  where?: InputMaybe<FeeLineBoolExp>;
};

/** Ordering options when selecting data from "visits_feeline". */
export type FeeLineOrderBy = {
  created?: InputMaybe<OrderBy>;
  fee?: InputMaybe<FeeOrderBy>;
  feeId?: InputMaybe<OrderBy>;
  feeType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_feeline */
export type FeeLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_feeline" */
export enum FeeLineSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  FeeId = "feeId",
  /** column name */
  FeeType = "feeType",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "visits_feeline" */
export type FeeLineSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  feeId?: InputMaybe<Scalars["bigint"]["input"]>;
  feeType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate stddev on columns */
export type FeeLineStddevFields = {
  __typename?: "feeLineStddevFields";
  feeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_feeline" */
export type FeeLineStddevOrderBy = {
  feeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FeeLineStddevPopFields = {
  __typename?: "feeLineStddevPopFields";
  feeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_feeline" */
export type FeeLineStddevPopOrderBy = {
  feeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FeeLineStddevSampFields = {
  __typename?: "feeLineStddevSampFields";
  feeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_feeline" */
export type FeeLineStddevSampOrderBy = {
  feeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "feeLine" */
export type FeeLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FeeLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FeeLineStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  feeId?: InputMaybe<Scalars["bigint"]["input"]>;
  feeType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate sum on columns */
export type FeeLineSumFields = {
  __typename?: "feeLineSumFields";
  feeId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "visits_feeline" */
export type FeeLineSumOrderBy = {
  feeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_feeline" */
export enum FeeLineUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  FeeId = "feeId",
  /** column name */
  FeeType = "feeType",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Value = "value",
}

export type FeeLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FeeLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FeeLineSetInput>;
  /** filter the rows which have to be updated */
  where: FeeLineBoolExp;
};

/** aggregate varPop on columns */
export type FeeLineVarPopFields = {
  __typename?: "feeLineVarPopFields";
  feeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_feeline" */
export type FeeLineVarPopOrderBy = {
  feeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FeeLineVarSampFields = {
  __typename?: "feeLineVarSampFields";
  feeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_feeline" */
export type FeeLineVarSampOrderBy = {
  feeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FeeLineVarianceFields = {
  __typename?: "feeLineVarianceFields";
  feeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_feeline" */
export type FeeLineVarianceOrderBy = {
  feeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type FeeMaxFields = {
  __typename?: "feeMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  feeType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by max() on columns of table "resources_fee" */
export type FeeMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  feeType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FeeMinFields = {
  __typename?: "feeMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  feeType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by min() on columns of table "resources_fee" */
export type FeeMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  feeType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_fee" */
export type FeeMutationResponse = {
  __typename?: "feeMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Fee>;
};

/** input type for inserting object relation for remote table "resources_fee" */
export type FeeObjRelInsertInput = {
  data: FeeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<FeeOnConflict>;
};

/** on_conflict condition type for table "resources_fee" */
export type FeeOnConflict = {
  constraint: FeeConstraint;
  updateColumns?: Array<FeeUpdateColumn>;
  where?: InputMaybe<FeeBoolExp>;
};

/** Ordering options when selecting data from "resources_fee". */
export type FeeOrderBy = {
  created?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  feeLinesAggregate?: InputMaybe<FeeLineAggregateOrderBy>;
  feeType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_fee */
export type FeePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_fee" */
export enum FeeSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  FeeType = "feeType",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Value = "value",
}

/** select "feeAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_fee" */
export enum FeeSelectColumnFeeAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** select "feeAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_fee" */
export enum FeeSelectColumnFeeAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** input type for updating data in table "resources_fee" */
export type FeeSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  feeType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate stddev on columns */
export type FeeStddevFields = {
  __typename?: "feeStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_fee" */
export type FeeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FeeStddevPopFields = {
  __typename?: "feeStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_fee" */
export type FeeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FeeStddevSampFields = {
  __typename?: "feeStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_fee" */
export type FeeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "fee" */
export type FeeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FeeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FeeStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  feeType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** aggregate sum on columns */
export type FeeSumFields = {
  __typename?: "feeSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  value?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "resources_fee" */
export type FeeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_fee" */
export enum FeeUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  FeeType = "feeType",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Value = "value",
}

export type FeeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FeeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FeeSetInput>;
  /** filter the rows which have to be updated */
  where: FeeBoolExp;
};

/** aggregate varPop on columns */
export type FeeVarPopFields = {
  __typename?: "feeVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_fee" */
export type FeeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FeeVarSampFields = {
  __typename?: "feeVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_fee" */
export type FeeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FeeVarianceFields = {
  __typename?: "feeVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  value?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_fee" */
export type FeeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** columns and relationships of "forms_form" */
export type Form = {
  __typename?: "form";
  allServices: Scalars["Boolean"]["output"];
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  archivedBy?: Maybe<User>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  gfe: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  jotformId: Scalars["String"]["output"];
  /** An object relationship */
  medspa?: Maybe<Medspa>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  medspaServiceOfferings: Array<FormsFormMedspaServiceOfferings>;
  /** An aggregate relationship */
  medspaServiceOfferingsAggregate: FormsFormMedspaServiceOfferingsAggregate;
  modified: Scalars["timestamptz"]["output"];
  schema: Scalars["jsonb"]["output"];
  /** An array relationship */
  serviceMenuItems: Array<FormServiceMenuItem>;
  /** An aggregate relationship */
  serviceMenuItemsAggregate: FormServiceMenuItemAggregate;
  /** An array relationship */
  submissions: Array<FormSubmission>;
  /** An aggregate relationship */
  submissionsAggregate: FormSubmissionAggregate;
  /** An object relationship */
  template?: Maybe<Form>;
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  title: Scalars["String"]["output"];
  uiSchema: Scalars["jsonb"]["output"];
};

/** columns and relationships of "forms_form" */
export type FormMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

/** columns and relationships of "forms_form" */
export type FormMedspaServiceOfferingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

/** columns and relationships of "forms_form" */
export type FormSchemaArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "forms_form" */
export type FormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<FormServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormServiceMenuItemOrderBy>>;
  where?: InputMaybe<FormServiceMenuItemBoolExp>;
};

/** columns and relationships of "forms_form" */
export type FormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormServiceMenuItemOrderBy>>;
  where?: InputMaybe<FormServiceMenuItemBoolExp>;
};

/** columns and relationships of "forms_form" */
export type FormSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

/** columns and relationships of "forms_form" */
export type FormSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

/** columns and relationships of "forms_form" */
export type FormUiSchemaArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "forms_form" */
export type FormAggregate = {
  __typename?: "formAggregate";
  aggregate?: Maybe<FormAggregateFields>;
  nodes: Array<Form>;
};

export type FormAggregateBoolExp = {
  bool_and?: InputMaybe<FormAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<FormAggregateBoolExpBool_Or>;
  count?: InputMaybe<FormAggregateBoolExpCount>;
};

export type FormAggregateBoolExpBool_And = {
  arguments: FormSelectColumnFormAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FormAggregateBoolExpBool_Or = {
  arguments: FormSelectColumnFormAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FormAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FormSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "forms_form" */
export type FormAggregateFields = {
  __typename?: "formAggregateFields";
  avg?: Maybe<FormAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FormMaxFields>;
  min?: Maybe<FormMinFields>;
  stddev?: Maybe<FormStddevFields>;
  stddevPop?: Maybe<FormStddevPopFields>;
  stddevSamp?: Maybe<FormStddevSampFields>;
  sum?: Maybe<FormSumFields>;
  varPop?: Maybe<FormVarPopFields>;
  varSamp?: Maybe<FormVarSampFields>;
  variance?: Maybe<FormVarianceFields>;
};

/** aggregate fields of "forms_form" */
export type FormAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "forms_form" */
export type FormAggregateOrderBy = {
  avg?: InputMaybe<FormAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FormMaxOrderBy>;
  min?: InputMaybe<FormMinOrderBy>;
  stddev?: InputMaybe<FormStddevOrderBy>;
  stddevPop?: InputMaybe<FormStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FormStddevSampOrderBy>;
  sum?: InputMaybe<FormSumOrderBy>;
  varPop?: InputMaybe<FormVarPopOrderBy>;
  varSamp?: InputMaybe<FormVarSampOrderBy>;
  variance?: InputMaybe<FormVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type FormAppendInput = {
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "forms_form" */
export type FormArrRelInsertInput = {
  data: Array<FormInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FormOnConflict>;
};

/** aggregate avg on columns */
export type FormAvgFields = {
  __typename?: "formAvgFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "forms_form" */
export type FormAvgOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "forms_form". All fields are combined with a logical 'AND'. */
export type FormBoolExp = {
  _and?: InputMaybe<Array<FormBoolExp>>;
  _not?: InputMaybe<FormBoolExp>;
  _or?: InputMaybe<Array<FormBoolExp>>;
  allServices?: InputMaybe<BooleanComparisonExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  archivedBy?: InputMaybe<UserBoolExp>;
  archivedById?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  gfe?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  jotformId?: InputMaybe<StringComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaServiceOfferings?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
  medspaServiceOfferingsAggregate?: InputMaybe<FormsFormMedspaServiceOfferingsAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  schema?: InputMaybe<JsonbComparisonExp>;
  serviceMenuItems?: InputMaybe<FormServiceMenuItemBoolExp>;
  serviceMenuItemsAggregate?: InputMaybe<FormServiceMenuItemAggregateBoolExp>;
  submissions?: InputMaybe<FormSubmissionBoolExp>;
  submissionsAggregate?: InputMaybe<FormSubmissionAggregateBoolExp>;
  template?: InputMaybe<FormBoolExp>;
  templateId?: InputMaybe<BigintComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  uiSchema?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "forms_form" */
export enum FormConstraint {
  /** unique or primary key constraint on columns "id" */
  FormsFormPkey = "forms_form_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type FormDeleteAtPathInput = {
  schema?: InputMaybe<Array<Scalars["String"]["input"]>>;
  uiSchema?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type FormDeleteElemInput = {
  schema?: InputMaybe<Scalars["Int"]["input"]>;
  uiSchema?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type FormDeleteKeyInput = {
  schema?: InputMaybe<Scalars["String"]["input"]>;
  uiSchema?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "forms_form" */
export type FormIncInput = {
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "forms_form" */
export type FormInsertInput = {
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedBy?: InputMaybe<UserObjRelInsertInput>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  jotformId?: InputMaybe<Scalars["String"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOfferings?: InputMaybe<FormsFormMedspaServiceOfferingsArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  serviceMenuItems?: InputMaybe<FormServiceMenuItemArrRelInsertInput>;
  submissions?: InputMaybe<FormSubmissionArrRelInsertInput>;
  template?: InputMaybe<FormObjRelInsertInput>;
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate max on columns */
export type FormMaxFields = {
  __typename?: "formMaxFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  jotformId?: Maybe<Scalars["String"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "forms_form" */
export type FormMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  jotformId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FormMinFields = {
  __typename?: "formMinFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  jotformId?: Maybe<Scalars["String"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  templateId?: Maybe<Scalars["bigint"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "forms_form" */
export type FormMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  jotformId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "forms_form" */
export type FormMutationResponse = {
  __typename?: "formMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Form>;
};

/** input type for inserting object relation for remote table "forms_form" */
export type FormObjRelInsertInput = {
  data: FormInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<FormOnConflict>;
};

/** on_conflict condition type for table "forms_form" */
export type FormOnConflict = {
  constraint: FormConstraint;
  updateColumns?: Array<FormUpdateColumn>;
  where?: InputMaybe<FormBoolExp>;
};

/** Ordering options when selecting data from "forms_form". */
export type FormOrderBy = {
  allServices?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  archivedBy?: InputMaybe<UserOrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  gfe?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  jotformId?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaServiceOfferingsAggregate?: InputMaybe<FormsFormMedspaServiceOfferingsAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  schema?: InputMaybe<OrderBy>;
  serviceMenuItemsAggregate?: InputMaybe<FormServiceMenuItemAggregateOrderBy>;
  submissionsAggregate?: InputMaybe<FormSubmissionAggregateOrderBy>;
  template?: InputMaybe<FormOrderBy>;
  templateId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  uiSchema?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: forms_form */
export type FormPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type FormPrependInput = {
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "forms_form" */
export enum FormSelectColumn {
  /** column name */
  AllServices = "allServices",
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  Created = "created",
  /** column name */
  Gfe = "gfe",
  /** column name */
  Id = "id",
  /** column name */
  JotformId = "jotformId",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Schema = "schema",
  /** column name */
  TemplateId = "templateId",
  /** column name */
  Title = "title",
  /** column name */
  UiSchema = "uiSchema",
}

/** select "formAggregateBoolExpBool_andArgumentsColumns" columns of table "forms_form" */
export enum FormSelectColumnFormAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  AllServices = "allServices",
  /** column name */
  Gfe = "gfe",
}

/** select "formAggregateBoolExpBool_orArgumentsColumns" columns of table "forms_form" */
export enum FormSelectColumnFormAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  AllServices = "allServices",
  /** column name */
  Gfe = "gfe",
}

/** columns and relationships of "forms_form_service_menu_items" */
export type FormServiceMenuItem = {
  __typename?: "formServiceMenuItem";
  /** An object relationship */
  form: Form;
  formId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  medspaservicemenuitemId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceMenuItem: MedspaServiceMenuItem;
};

/** aggregated selection of "forms_form_service_menu_items" */
export type FormServiceMenuItemAggregate = {
  __typename?: "formServiceMenuItemAggregate";
  aggregate?: Maybe<FormServiceMenuItemAggregateFields>;
  nodes: Array<FormServiceMenuItem>;
};

export type FormServiceMenuItemAggregateBoolExp = {
  count?: InputMaybe<FormServiceMenuItemAggregateBoolExpCount>;
};

export type FormServiceMenuItemAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FormServiceMenuItemSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormServiceMenuItemBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "forms_form_service_menu_items" */
export type FormServiceMenuItemAggregateFields = {
  __typename?: "formServiceMenuItemAggregateFields";
  avg?: Maybe<FormServiceMenuItemAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FormServiceMenuItemMaxFields>;
  min?: Maybe<FormServiceMenuItemMinFields>;
  stddev?: Maybe<FormServiceMenuItemStddevFields>;
  stddevPop?: Maybe<FormServiceMenuItemStddevPopFields>;
  stddevSamp?: Maybe<FormServiceMenuItemStddevSampFields>;
  sum?: Maybe<FormServiceMenuItemSumFields>;
  varPop?: Maybe<FormServiceMenuItemVarPopFields>;
  varSamp?: Maybe<FormServiceMenuItemVarSampFields>;
  variance?: Maybe<FormServiceMenuItemVarianceFields>;
};

/** aggregate fields of "forms_form_service_menu_items" */
export type FormServiceMenuItemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormServiceMenuItemSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "forms_form_service_menu_items" */
export type FormServiceMenuItemAggregateOrderBy = {
  avg?: InputMaybe<FormServiceMenuItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FormServiceMenuItemMaxOrderBy>;
  min?: InputMaybe<FormServiceMenuItemMinOrderBy>;
  stddev?: InputMaybe<FormServiceMenuItemStddevOrderBy>;
  stddevPop?: InputMaybe<FormServiceMenuItemStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FormServiceMenuItemStddevSampOrderBy>;
  sum?: InputMaybe<FormServiceMenuItemSumOrderBy>;
  varPop?: InputMaybe<FormServiceMenuItemVarPopOrderBy>;
  varSamp?: InputMaybe<FormServiceMenuItemVarSampOrderBy>;
  variance?: InputMaybe<FormServiceMenuItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "forms_form_service_menu_items" */
export type FormServiceMenuItemArrRelInsertInput = {
  data: Array<FormServiceMenuItemInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FormServiceMenuItemOnConflict>;
};

/** aggregate avg on columns */
export type FormServiceMenuItemAvgFields = {
  __typename?: "formServiceMenuItemAvgFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemAvgOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "forms_form_service_menu_items". All fields are combined with a logical 'AND'. */
export type FormServiceMenuItemBoolExp = {
  _and?: InputMaybe<Array<FormServiceMenuItemBoolExp>>;
  _not?: InputMaybe<FormServiceMenuItemBoolExp>;
  _or?: InputMaybe<Array<FormServiceMenuItemBoolExp>>;
  form?: InputMaybe<FormBoolExp>;
  formId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspaservicemenuitemId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** unique or primary key constraints on table "forms_form_service_menu_items" */
export enum FormServiceMenuItemConstraint {
  /** unique or primary key constraint on columns "medspaservicemenuitem_id", "form_id" */
  FormsFormServiceMenuFormIdMedspaservicemenEc3f5913Uniq = "forms_form_service_menu__form_id_medspaservicemen_ec3f5913_uniq",
  /** unique or primary key constraint on columns "id" */
  FormsFormServiceMenuItemsPkey = "forms_form_service_menu_items_pkey",
}

/** input type for incrementing numeric columns in table "forms_form_service_menu_items" */
export type FormServiceMenuItemIncInput = {
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "forms_form_service_menu_items" */
export type FormServiceMenuItemInsertInput = {
  form?: InputMaybe<FormObjRelInsertInput>;
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
};

/** aggregate max on columns */
export type FormServiceMenuItemMaxFields = {
  __typename?: "formServiceMenuItemMaxFields";
  formId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemMaxOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FormServiceMenuItemMinFields = {
  __typename?: "formServiceMenuItemMinFields";
  formId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemMinOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "forms_form_service_menu_items" */
export type FormServiceMenuItemMutationResponse = {
  __typename?: "formServiceMenuItemMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<FormServiceMenuItem>;
};

/** on_conflict condition type for table "forms_form_service_menu_items" */
export type FormServiceMenuItemOnConflict = {
  constraint: FormServiceMenuItemConstraint;
  updateColumns?: Array<FormServiceMenuItemUpdateColumn>;
  where?: InputMaybe<FormServiceMenuItemBoolExp>;
};

/** Ordering options when selecting data from "forms_form_service_menu_items". */
export type FormServiceMenuItemOrderBy = {
  form?: InputMaybe<FormOrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
};

/** primary key columns input for table: forms_form_service_menu_items */
export type FormServiceMenuItemPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "forms_form_service_menu_items" */
export enum FormServiceMenuItemSelectColumn {
  /** column name */
  FormId = "formId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
}

/** input type for updating data in table "forms_form_service_menu_items" */
export type FormServiceMenuItemSetInput = {
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type FormServiceMenuItemStddevFields = {
  __typename?: "formServiceMenuItemStddevFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemStddevOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FormServiceMenuItemStddevPopFields = {
  __typename?: "formServiceMenuItemStddevPopFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemStddevPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FormServiceMenuItemStddevSampFields = {
  __typename?: "formServiceMenuItemStddevSampFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemStddevSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "formServiceMenuItem" */
export type FormServiceMenuItemStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FormServiceMenuItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormServiceMenuItemStreamCursorValueInput = {
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaservicemenuitemId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type FormServiceMenuItemSumFields = {
  __typename?: "formServiceMenuItemSumFields";
  formId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemSumOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** update columns of table "forms_form_service_menu_items" */
export enum FormServiceMenuItemUpdateColumn {
  /** column name */
  FormId = "formId",
  /** column name */
  Id = "id",
  /** column name */
  MedspaservicemenuitemId = "medspaservicemenuitemId",
}

export type FormServiceMenuItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormServiceMenuItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormServiceMenuItemSetInput>;
  /** filter the rows which have to be updated */
  where: FormServiceMenuItemBoolExp;
};

/** aggregate varPop on columns */
export type FormServiceMenuItemVarPopFields = {
  __typename?: "formServiceMenuItemVarPopFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemVarPopOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FormServiceMenuItemVarSampFields = {
  __typename?: "formServiceMenuItemVarSampFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemVarSampOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FormServiceMenuItemVarianceFields = {
  __typename?: "formServiceMenuItemVarianceFields";
  formId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaservicemenuitemId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "forms_form_service_menu_items" */
export type FormServiceMenuItemVarianceOrderBy = {
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaservicemenuitemId?: InputMaybe<OrderBy>;
};

/** input type for updating data in table "forms_form" */
export type FormSetInput = {
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  jotformId?: InputMaybe<Scalars["String"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate stddev on columns */
export type FormStddevFields = {
  __typename?: "formStddevFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "forms_form" */
export type FormStddevOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FormStddevPopFields = {
  __typename?: "formStddevPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "forms_form" */
export type FormStddevPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FormStddevSampFields = {
  __typename?: "formStddevSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "forms_form" */
export type FormStddevSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "form" */
export type FormStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FormStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormStreamCursorValueInput = {
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  jotformId?: InputMaybe<Scalars["String"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  schema?: InputMaybe<Scalars["jsonb"]["input"]>;
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  uiSchema?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** columns and relationships of "forms_formsubmission" */
export type FormSubmission = {
  __typename?: "formSubmission";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  client: Client;
  clientId: Scalars["bigint"]["output"];
  content: Scalars["jsonb"]["output"];
  created: Scalars["timestamptz"]["output"];
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  externalSubmissionId: Scalars["String"]["output"];
  /** An object relationship */
  form: Form;
  formId: Scalars["bigint"]["output"];
  gfeCompletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["bigint"]["output"]>;
  gfeSentAt?: Maybe<Scalars["timestamptz"]["output"]>;
  gfeStatus: Scalars["String"]["output"];
  /** An array relationship */
  gfeStatusChangeLogs: Array<FormSubmissionGfeStatusChangeLog>;
  /** An aggregate relationship */
  gfeStatusChangeLogsAggregate: FormSubmissionGfeStatusChangeLogAggregate;
  /** An object relationship */
  gfe_completed_by_user?: Maybe<User>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An array relationship */
  noteFormSubmissions: Array<NoteFormSubmission>;
  /** An aggregate relationship */
  noteFormSubmissionsAggregate: NoteFormSubmissionAggregate;
  submittedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  versions: Array<FormsFormsubmissionversion>;
  /** An aggregate relationship */
  versionsAggregate: FormsFormsubmissionversionAggregate;
};

/** columns and relationships of "forms_formsubmission" */
export type FormSubmissionContentArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "forms_formsubmission" */
export type FormSubmissionGfeStatusChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogOrderBy>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

/** columns and relationships of "forms_formsubmission" */
export type FormSubmissionGfeStatusChangeLogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogOrderBy>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

/** columns and relationships of "forms_formsubmission" */
export type FormSubmissionNoteFormSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteFormSubmissionOrderBy>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

/** columns and relationships of "forms_formsubmission" */
export type FormSubmissionNoteFormSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteFormSubmissionOrderBy>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

/** columns and relationships of "forms_formsubmission" */
export type FormSubmissionVersionsArgs = {
  distinctOn?: InputMaybe<Array<FormsFormsubmissionversionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormsubmissionversionOrderBy>>;
  where?: InputMaybe<FormsFormsubmissionversionBoolExp>;
};

/** columns and relationships of "forms_formsubmission" */
export type FormSubmissionVersionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormsFormsubmissionversionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormsubmissionversionOrderBy>>;
  where?: InputMaybe<FormsFormsubmissionversionBoolExp>;
};

/** aggregated selection of "forms_formsubmission" */
export type FormSubmissionAggregate = {
  __typename?: "formSubmissionAggregate";
  aggregate?: Maybe<FormSubmissionAggregateFields>;
  nodes: Array<FormSubmission>;
};

export type FormSubmissionAggregateBoolExp = {
  count?: InputMaybe<FormSubmissionAggregateBoolExpCount>;
};

export type FormSubmissionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormSubmissionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "forms_formsubmission" */
export type FormSubmissionAggregateFields = {
  __typename?: "formSubmissionAggregateFields";
  avg?: Maybe<FormSubmissionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FormSubmissionMaxFields>;
  min?: Maybe<FormSubmissionMinFields>;
  stddev?: Maybe<FormSubmissionStddevFields>;
  stddevPop?: Maybe<FormSubmissionStddevPopFields>;
  stddevSamp?: Maybe<FormSubmissionStddevSampFields>;
  sum?: Maybe<FormSubmissionSumFields>;
  varPop?: Maybe<FormSubmissionVarPopFields>;
  varSamp?: Maybe<FormSubmissionVarSampFields>;
  variance?: Maybe<FormSubmissionVarianceFields>;
};

/** aggregate fields of "forms_formsubmission" */
export type FormSubmissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "forms_formsubmission" */
export type FormSubmissionAggregateOrderBy = {
  avg?: InputMaybe<FormSubmissionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FormSubmissionMaxOrderBy>;
  min?: InputMaybe<FormSubmissionMinOrderBy>;
  stddev?: InputMaybe<FormSubmissionStddevOrderBy>;
  stddevPop?: InputMaybe<FormSubmissionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FormSubmissionStddevSampOrderBy>;
  sum?: InputMaybe<FormSubmissionSumOrderBy>;
  varPop?: InputMaybe<FormSubmissionVarPopOrderBy>;
  varSamp?: InputMaybe<FormSubmissionVarSampOrderBy>;
  variance?: InputMaybe<FormSubmissionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type FormSubmissionAppendInput = {
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "forms_formsubmission" */
export type FormSubmissionArrRelInsertInput = {
  data: Array<FormSubmissionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FormSubmissionOnConflict>;
};

/** aggregate avg on columns */
export type FormSubmissionAvgFields = {
  __typename?: "formSubmissionAvgFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formId?: Maybe<Scalars["Float"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "forms_formsubmission" */
export type FormSubmissionAvgOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "forms_formsubmission". All fields are combined with a logical 'AND'. */
export type FormSubmissionBoolExp = {
  _and?: InputMaybe<Array<FormSubmissionBoolExp>>;
  _not?: InputMaybe<FormSubmissionBoolExp>;
  _or?: InputMaybe<Array<FormSubmissionBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  archivedById?: InputMaybe<BigintComparisonExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  content?: InputMaybe<JsonbComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  externalSubmissionId?: InputMaybe<StringComparisonExp>;
  form?: InputMaybe<FormBoolExp>;
  formId?: InputMaybe<BigintComparisonExp>;
  gfeCompletedAt?: InputMaybe<TimestamptzComparisonExp>;
  gfeCompletedById?: InputMaybe<BigintComparisonExp>;
  gfeSentAt?: InputMaybe<TimestamptzComparisonExp>;
  gfeStatus?: InputMaybe<StringComparisonExp>;
  gfeStatusChangeLogs?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
  gfeStatusChangeLogsAggregate?: InputMaybe<FormSubmissionGfeStatusChangeLogAggregateBoolExp>;
  gfe_completed_by_user?: InputMaybe<UserBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  noteFormSubmissions?: InputMaybe<NoteFormSubmissionBoolExp>;
  noteFormSubmissionsAggregate?: InputMaybe<NoteFormSubmissionAggregateBoolExp>;
  submittedAt?: InputMaybe<TimestamptzComparisonExp>;
  versions?: InputMaybe<FormsFormsubmissionversionBoolExp>;
  versionsAggregate?: InputMaybe<FormsFormsubmissionversionAggregateBoolExp>;
};

/** unique or primary key constraints on table "forms_formsubmission" */
export enum FormSubmissionConstraint {
  /** unique or primary key constraint on columns "id" */
  FormsFormsubmissionPkey = "forms_formsubmission_pkey",
  /** unique or primary key constraint on columns "client_id", "form_id", "medspa_id" */
  UniqueFormSubmissionClient = "unique_form_submission_client",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type FormSubmissionDeleteAtPathInput = {
  content?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type FormSubmissionDeleteElemInput = {
  content?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type FormSubmissionDeleteKeyInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLog = {
  __typename?: "formSubmissionGFEStatusChangeLog";
  /** An object relationship */
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  formSubmission: FormSubmission;
  formSubmissionId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  newStatus: Scalars["String"]["output"];
  oldStatus: Scalars["String"]["output"];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogAggregate = {
  __typename?: "formSubmissionGFEStatusChangeLogAggregate";
  aggregate?: Maybe<FormSubmissionGfeStatusChangeLogAggregateFields>;
  nodes: Array<FormSubmissionGfeStatusChangeLog>;
};

export type FormSubmissionGfeStatusChangeLogAggregateBoolExp = {
  count?: InputMaybe<FormSubmissionGfeStatusChangeLogAggregateBoolExpCount>;
};

export type FormSubmissionGfeStatusChangeLogAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogAggregateFields = {
  __typename?: "formSubmissionGFEStatusChangeLogAggregateFields";
  avg?: Maybe<FormSubmissionGfeStatusChangeLogAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<FormSubmissionGfeStatusChangeLogMaxFields>;
  min?: Maybe<FormSubmissionGfeStatusChangeLogMinFields>;
  stddev?: Maybe<FormSubmissionGfeStatusChangeLogStddevFields>;
  stddevPop?: Maybe<FormSubmissionGfeStatusChangeLogStddevPopFields>;
  stddevSamp?: Maybe<FormSubmissionGfeStatusChangeLogStddevSampFields>;
  sum?: Maybe<FormSubmissionGfeStatusChangeLogSumFields>;
  varPop?: Maybe<FormSubmissionGfeStatusChangeLogVarPopFields>;
  varSamp?: Maybe<FormSubmissionGfeStatusChangeLogVarSampFields>;
  variance?: Maybe<FormSubmissionGfeStatusChangeLogVarianceFields>;
};

/** aggregate fields of "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogAggregateOrderBy = {
  avg?: InputMaybe<FormSubmissionGfeStatusChangeLogAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FormSubmissionGfeStatusChangeLogMaxOrderBy>;
  min?: InputMaybe<FormSubmissionGfeStatusChangeLogMinOrderBy>;
  stddev?: InputMaybe<FormSubmissionGfeStatusChangeLogStddevOrderBy>;
  stddevPop?: InputMaybe<FormSubmissionGfeStatusChangeLogStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FormSubmissionGfeStatusChangeLogStddevSampOrderBy>;
  sum?: InputMaybe<FormSubmissionGfeStatusChangeLogSumOrderBy>;
  varPop?: InputMaybe<FormSubmissionGfeStatusChangeLogVarPopOrderBy>;
  varSamp?: InputMaybe<FormSubmissionGfeStatusChangeLogVarSampOrderBy>;
  variance?: InputMaybe<FormSubmissionGfeStatusChangeLogVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogArrRelInsertInput = {
  data: Array<FormSubmissionGfeStatusChangeLogInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FormSubmissionGfeStatusChangeLogOnConflict>;
};

/** aggregate avg on columns */
export type FormSubmissionGfeStatusChangeLogAvgFields = {
  __typename?: "formSubmissionGFEStatusChangeLogAvgFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogAvgOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "forms_formsubmissiongfestatuschangelog". All fields are combined with a logical 'AND'. */
export type FormSubmissionGfeStatusChangeLogBoolExp = {
  _and?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogBoolExp>>;
  _not?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
  _or?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogBoolExp>>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  formSubmission?: InputMaybe<FormSubmissionBoolExp>;
  formSubmissionId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  newStatus?: InputMaybe<StringComparisonExp>;
  oldStatus?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "forms_formsubmissiongfestatuschangelog" */
export enum FormSubmissionGfeStatusChangeLogConstraint {
  /** unique or primary key constraint on columns "id" */
  FormsFormsubmissiongfestatuschangelogPkey = "forms_formsubmissiongfestatuschangelog_pkey",
}

/** input type for incrementing numeric columns in table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogIncInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  formSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogInsertInput = {
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  formSubmission?: InputMaybe<FormSubmissionObjRelInsertInput>;
  formSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type FormSubmissionGfeStatusChangeLogMaxFields = {
  __typename?: "formSubmissionGFEStatusChangeLogMaxFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  formSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  newStatus?: Maybe<Scalars["String"]["output"]>;
  oldStatus?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogMaxOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FormSubmissionGfeStatusChangeLogMinFields = {
  __typename?: "formSubmissionGFEStatusChangeLogMinFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  formSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  newStatus?: Maybe<Scalars["String"]["output"]>;
  oldStatus?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogMinOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogMutationResponse = {
  __typename?: "formSubmissionGFEStatusChangeLogMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<FormSubmissionGfeStatusChangeLog>;
};

/** on_conflict condition type for table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogOnConflict = {
  constraint: FormSubmissionGfeStatusChangeLogConstraint;
  updateColumns?: Array<FormSubmissionGfeStatusChangeLogUpdateColumn>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

/** Ordering options when selecting data from "forms_formsubmissiongfestatuschangelog". */
export type FormSubmissionGfeStatusChangeLogOrderBy = {
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  formSubmission?: InputMaybe<FormSubmissionOrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: forms_formsubmissiongfestatuschangelog */
export type FormSubmissionGfeStatusChangeLogPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "forms_formsubmissiongfestatuschangelog" */
export enum FormSubmissionGfeStatusChangeLogSelectColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  FormSubmissionId = "formSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewStatus = "newStatus",
  /** column name */
  OldStatus = "oldStatus",
  /** column name */
  UserId = "userId",
}

/** input type for updating data in table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogSetInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  formSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type FormSubmissionGfeStatusChangeLogStddevFields = {
  __typename?: "formSubmissionGFEStatusChangeLogStddevFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogStddevOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FormSubmissionGfeStatusChangeLogStddevPopFields = {
  __typename?: "formSubmissionGFEStatusChangeLogStddevPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogStddevPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FormSubmissionGfeStatusChangeLogStddevSampFields = {
  __typename?: "formSubmissionGFEStatusChangeLogStddevSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogStddevSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "formSubmissionGFEStatusChangeLog" */
export type FormSubmissionGfeStatusChangeLogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FormSubmissionGfeStatusChangeLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormSubmissionGfeStatusChangeLogStreamCursorValueInput = {
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  formSubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type FormSubmissionGfeStatusChangeLogSumFields = {
  __typename?: "formSubmissionGFEStatusChangeLogSumFields";
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  formSubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogSumOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** update columns of table "forms_formsubmissiongfestatuschangelog" */
export enum FormSubmissionGfeStatusChangeLogUpdateColumn {
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  FormSubmissionId = "formSubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewStatus = "newStatus",
  /** column name */
  OldStatus = "oldStatus",
  /** column name */
  UserId = "userId",
}

export type FormSubmissionGfeStatusChangeLogUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormSubmissionGfeStatusChangeLogIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormSubmissionGfeStatusChangeLogSetInput>;
  /** filter the rows which have to be updated */
  where: FormSubmissionGfeStatusChangeLogBoolExp;
};

/** aggregate varPop on columns */
export type FormSubmissionGfeStatusChangeLogVarPopFields = {
  __typename?: "formSubmissionGFEStatusChangeLogVarPopFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogVarPopOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FormSubmissionGfeStatusChangeLogVarSampFields = {
  __typename?: "formSubmissionGFEStatusChangeLogVarSampFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogVarSampOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FormSubmissionGfeStatusChangeLogVarianceFields = {
  __typename?: "formSubmissionGFEStatusChangeLogVarianceFields";
  clientId?: Maybe<Scalars["Float"]["output"]>;
  formSubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "forms_formsubmissiongfestatuschangelog" */
export type FormSubmissionGfeStatusChangeLogVarianceOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  formSubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "forms_formsubmission" */
export type FormSubmissionIncInput = {
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeCompletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "forms_formsubmission" */
export type FormSubmissionInsertInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  externalSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  form?: InputMaybe<FormObjRelInsertInput>;
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeCompletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeCompletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeSentAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  gfeStatusChangeLogs?: InputMaybe<FormSubmissionGfeStatusChangeLogArrRelInsertInput>;
  gfe_completed_by_user?: InputMaybe<UserObjRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noteFormSubmissions?: InputMaybe<NoteFormSubmissionArrRelInsertInput>;
  submittedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  versions?: InputMaybe<FormsFormsubmissionversionArrRelInsertInput>;
};

/** aggregate max on columns */
export type FormSubmissionMaxFields = {
  __typename?: "formSubmissionMaxFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  externalSubmissionId?: Maybe<Scalars["String"]["output"]>;
  formId?: Maybe<Scalars["bigint"]["output"]>;
  gfeCompletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["bigint"]["output"]>;
  gfeSentAt?: Maybe<Scalars["timestamptz"]["output"]>;
  gfeStatus?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  submittedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "forms_formsubmission" */
export type FormSubmissionMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  externalSubmissionId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedAt?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  gfeSentAt?: InputMaybe<OrderBy>;
  gfeStatus?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  submittedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FormSubmissionMinFields = {
  __typename?: "formSubmissionMinFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  externalSubmissionId?: Maybe<Scalars["String"]["output"]>;
  formId?: Maybe<Scalars["bigint"]["output"]>;
  gfeCompletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["bigint"]["output"]>;
  gfeSentAt?: Maybe<Scalars["timestamptz"]["output"]>;
  gfeStatus?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  submittedAt?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "forms_formsubmission" */
export type FormSubmissionMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  externalSubmissionId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedAt?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  gfeSentAt?: InputMaybe<OrderBy>;
  gfeStatus?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  submittedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "forms_formsubmission" */
export type FormSubmissionMutationResponse = {
  __typename?: "formSubmissionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<FormSubmission>;
};

/** input type for inserting object relation for remote table "forms_formsubmission" */
export type FormSubmissionObjRelInsertInput = {
  data: FormSubmissionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<FormSubmissionOnConflict>;
};

/** on_conflict condition type for table "forms_formsubmission" */
export type FormSubmissionOnConflict = {
  constraint: FormSubmissionConstraint;
  updateColumns?: Array<FormSubmissionUpdateColumn>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

/** Ordering options when selecting data from "forms_formsubmission". */
export type FormSubmissionOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  externalSubmissionId?: InputMaybe<OrderBy>;
  form?: InputMaybe<FormOrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedAt?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  gfeSentAt?: InputMaybe<OrderBy>;
  gfeStatus?: InputMaybe<OrderBy>;
  gfeStatusChangeLogsAggregate?: InputMaybe<FormSubmissionGfeStatusChangeLogAggregateOrderBy>;
  gfe_completed_by_user?: InputMaybe<UserOrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  noteFormSubmissionsAggregate?: InputMaybe<NoteFormSubmissionAggregateOrderBy>;
  submittedAt?: InputMaybe<OrderBy>;
  versionsAggregate?: InputMaybe<FormsFormsubmissionversionAggregateOrderBy>;
};

/** primary key columns input for table: forms_formsubmission */
export type FormSubmissionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type FormSubmissionPrependInput = {
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "forms_formsubmission" */
export enum FormSubmissionSelectColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  ExternalSubmissionId = "externalSubmissionId",
  /** column name */
  FormId = "formId",
  /** column name */
  GfeCompletedAt = "gfeCompletedAt",
  /** column name */
  GfeCompletedById = "gfeCompletedById",
  /** column name */
  GfeSentAt = "gfeSentAt",
  /** column name */
  GfeStatus = "gfeStatus",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  SubmittedAt = "submittedAt",
}

/** input type for updating data in table "forms_formsubmission" */
export type FormSubmissionSetInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  externalSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeCompletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeCompletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeSentAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  submittedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type FormSubmissionStddevFields = {
  __typename?: "formSubmissionStddevFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formId?: Maybe<Scalars["Float"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "forms_formsubmission" */
export type FormSubmissionStddevOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FormSubmissionStddevPopFields = {
  __typename?: "formSubmissionStddevPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formId?: Maybe<Scalars["Float"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "forms_formsubmission" */
export type FormSubmissionStddevPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FormSubmissionStddevSampFields = {
  __typename?: "formSubmissionStddevSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formId?: Maybe<Scalars["Float"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "forms_formsubmission" */
export type FormSubmissionStddevSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "formSubmission" */
export type FormSubmissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FormSubmissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormSubmissionStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  content?: InputMaybe<Scalars["jsonb"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  externalSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  formId?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeCompletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeCompletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  gfeSentAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  gfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  submittedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type FormSubmissionSumFields = {
  __typename?: "formSubmissionSumFields";
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  formId?: Maybe<Scalars["bigint"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "forms_formsubmission" */
export type FormSubmissionSumOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "forms_formsubmission" */
export enum FormSubmissionUpdateColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  ExternalSubmissionId = "externalSubmissionId",
  /** column name */
  FormId = "formId",
  /** column name */
  GfeCompletedAt = "gfeCompletedAt",
  /** column name */
  GfeCompletedById = "gfeCompletedById",
  /** column name */
  GfeSentAt = "gfeSentAt",
  /** column name */
  GfeStatus = "gfeStatus",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  SubmittedAt = "submittedAt",
}

export type FormSubmissionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<FormSubmissionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<FormSubmissionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<FormSubmissionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<FormSubmissionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormSubmissionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<FormSubmissionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormSubmissionSetInput>;
  /** filter the rows which have to be updated */
  where: FormSubmissionBoolExp;
};

/** aggregate varPop on columns */
export type FormSubmissionVarPopFields = {
  __typename?: "formSubmissionVarPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formId?: Maybe<Scalars["Float"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "forms_formsubmission" */
export type FormSubmissionVarPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FormSubmissionVarSampFields = {
  __typename?: "formSubmissionVarSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formId?: Maybe<Scalars["Float"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "forms_formsubmission" */
export type FormSubmissionVarSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FormSubmissionVarianceFields = {
  __typename?: "formSubmissionVarianceFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  formId?: Maybe<Scalars["Float"]["output"]>;
  gfeCompletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "forms_formsubmission" */
export type FormSubmissionVarianceOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  gfeCompletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type FormSumFields = {
  __typename?: "formSumFields";
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "forms_form" */
export type FormSumOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** update columns of table "forms_form" */
export enum FormUpdateColumn {
  /** column name */
  AllServices = "allServices",
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  Created = "created",
  /** column name */
  Gfe = "gfe",
  /** column name */
  Id = "id",
  /** column name */
  JotformId = "jotformId",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Schema = "schema",
  /** column name */
  TemplateId = "templateId",
  /** column name */
  Title = "title",
  /** column name */
  UiSchema = "uiSchema",
}

export type FormUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<FormAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<FormDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<FormDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<FormDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<FormPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormSetInput>;
  /** filter the rows which have to be updated */
  where: FormBoolExp;
};

/** aggregate varPop on columns */
export type FormVarPopFields = {
  __typename?: "formVarPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "forms_form" */
export type FormVarPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FormVarSampFields = {
  __typename?: "formVarSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "forms_form" */
export type FormVarSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FormVarianceFields = {
  __typename?: "formVarianceFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "forms_form" */
export type FormVarianceOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

export type FormsFormMedspaServiceOfferingsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
  predicate: IntComparisonExp;
};

export type FormsFormsubmissionversionAggregateBoolExpBool_And = {
  arguments: FormsFormsubmissionversionSelectColumnFormsFormsubmissionversionAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormsFormsubmissionversionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FormsFormsubmissionversionAggregateBoolExpBool_Or = {
  arguments: FormsFormsubmissionversionSelectColumnFormsFormsubmissionversionAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormsFormsubmissionversionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FormsFormsubmissionversionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FormsFormsubmissionversionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<FormsFormsubmissionversionBoolExp>;
  predicate: IntComparisonExp;
};

export type GfeReviewDiagnosisAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<GfeReviewDiagnosisBoolExp>;
  predicate: IntComparisonExp;
};

export type GfeReviewRequestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<GfeReviewRequestBoolExp>;
  predicate: IntComparisonExp;
};

export type GfeReviewSubmissionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GfeReviewSubmissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<GfeReviewSubmissionBoolExp>;
  predicate: IntComparisonExp;
};

export type GfeServiceProductIndicationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<GfeServiceProductIndicationBoolExp>;
  predicate: IntComparisonExp;
};

export type GfeTreatmentIndicationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<GfeTreatmentIndicationBoolExp>;
  predicate: IntComparisonExp;
};

export type InventoryLotAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InventoryLotSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<InventoryLotBoolExp>;
  predicate: IntComparisonExp;
};

export type InventoryTransactionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<InventoryTransactionBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "visits_invoice" */
export type Invoice = {
  __typename?: "invoice";
  _discountTotal?: Maybe<Scalars["numeric"]["output"]>;
  _feeTotal?: Maybe<Scalars["numeric"]["output"]>;
  _subtotal?: Maybe<Scalars["numeric"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["numeric"]["output"]>;
  _taxTotal?: Maybe<Scalars["numeric"]["output"]>;
  _total?: Maybe<Scalars["numeric"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["numeric"]["output"]>;
  _totalInclTax?: Maybe<Scalars["numeric"]["output"]>;
  /** An object relationship */
  client: Client;
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** An array relationship */
  creditLines: Array<WalletCreditLine>;
  /** An aggregate relationship */
  creditLinesAggregate: WalletCreditLineAggregate;
  /** An array relationship */
  discountLines: Array<DiscountLine>;
  /** An aggregate relationship */
  discountLinesAggregate: DiscountLineAggregate;
  /** Indicates when the invoice object was initially created in DB. This is different than 'created' that indicates when the visit was marked as completed and the checkout flow started. */
  draftCreatedAt: Scalars["timestamptz"]["output"];
  /** An array relationship */
  feeLines: Array<FeeLine>;
  /** An aggregate relationship */
  feeLinesAggregate: FeeLineAggregate;
  id: Scalars["bigint"]["output"];
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId: Scalars["String"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  membershipLines: Array<MembershipLine>;
  /** An aggregate relationship */
  membershipLinesAggregate: MembershipLineAggregate;
  /** An array relationship */
  membershipPerkLines: Array<MembershipPerkLine>;
  /** An aggregate relationship */
  membershipPerkLinesAggregate: MembershipPerkLineAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** An array relationship */
  packageLines: Array<PackageLine>;
  /** An aggregate relationship */
  packageLinesAggregate: PackageLineAggregate;
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  paymentsAggregate: PaymentAggregate;
  priceSummary?: Maybe<InvoiceAdditionalInfoType>;
  priceSummaryExcludingWalletCredits?: Maybe<InvoiceAdditionalInfoWithoutWalletCreditsType>;
  /** An array relationship */
  productLines: Array<ProductLine>;
  /** An aggregate relationship */
  productLinesAggregate: ProductLineAggregate;
  /** Indicates the source of the purchase. Used for debugging/reporting purposes. */
  purchaseSource?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  serviceMenuItemLines: Array<ServiceMenuItemLine>;
  /** An aggregate relationship */
  serviceMenuItemLinesAggregate: ServiceMenuItemLineAggregate;
  /** An array relationship */
  serviceProductLines: Array<ServiceProductLine>;
  /** An aggregate relationship */
  serviceProductLinesAggregate: ServiceProductLineAggregate;
  status: Scalars["String"]["output"];
  tip?: Maybe<Scalars["numeric"]["output"]>;
  uuid: Scalars["String"]["output"];
  /** An object relationship */
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceCreditLinesArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditLineOrderBy>>;
  where?: InputMaybe<WalletCreditLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceCreditLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditLineOrderBy>>;
  where?: InputMaybe<WalletCreditLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceDiscountLinesArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceDiscountLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceFeeLinesArgs = {
  distinctOn?: InputMaybe<Array<FeeLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeLineOrderBy>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceFeeLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeeLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeLineOrderBy>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceMembershipLinesArgs = {
  distinctOn?: InputMaybe<Array<MembershipLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipLineOrderBy>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceMembershipLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipLineOrderBy>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceMembershipPerkLinesArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceMembershipPerkLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoicePackageLinesArgs = {
  distinctOn?: InputMaybe<Array<PackageLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageLineOrderBy>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoicePackageLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageLineOrderBy>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoicePaymentsArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoicePaymentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceProductLinesArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceProductLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceServiceMenuItemLinesArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceServiceMenuItemLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceServiceProductLinesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** columns and relationships of "visits_invoice" */
export type InvoiceServiceProductLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** aggregated selection of "visits_invoice" */
export type InvoiceAggregate = {
  __typename?: "invoiceAggregate";
  aggregate?: Maybe<InvoiceAggregateFields>;
  nodes: Array<Invoice>;
};

export type InvoiceAggregateBoolExp = {
  count?: InputMaybe<InvoiceAggregateBoolExpCount>;
};

export type InvoiceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InvoiceSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<InvoiceBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_invoice" */
export type InvoiceAggregateFields = {
  __typename?: "invoiceAggregateFields";
  avg?: Maybe<InvoiceAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<InvoiceMaxFields>;
  min?: Maybe<InvoiceMinFields>;
  stddev?: Maybe<InvoiceStddevFields>;
  stddevPop?: Maybe<InvoiceStddevPopFields>;
  stddevSamp?: Maybe<InvoiceStddevSampFields>;
  sum?: Maybe<InvoiceSumFields>;
  varPop?: Maybe<InvoiceVarPopFields>;
  varSamp?: Maybe<InvoiceVarSampFields>;
  variance?: Maybe<InvoiceVarianceFields>;
};

/** aggregate fields of "visits_invoice" */
export type InvoiceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_invoice" */
export type InvoiceAggregateOrderBy = {
  avg?: InputMaybe<InvoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InvoiceMaxOrderBy>;
  min?: InputMaybe<InvoiceMinOrderBy>;
  stddev?: InputMaybe<InvoiceStddevOrderBy>;
  stddevPop?: InputMaybe<InvoiceStddevPopOrderBy>;
  stddevSamp?: InputMaybe<InvoiceStddevSampOrderBy>;
  sum?: InputMaybe<InvoiceSumOrderBy>;
  varPop?: InputMaybe<InvoiceVarPopOrderBy>;
  varSamp?: InputMaybe<InvoiceVarSampOrderBy>;
  variance?: InputMaybe<InvoiceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_invoice" */
export type InvoiceArrRelInsertInput = {
  data: Array<InvoiceInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<InvoiceOnConflict>;
};

/** aggregate avg on columns */
export type InvoiceAvgFields = {
  __typename?: "invoiceAvgFields";
  _discountTotal?: Maybe<Scalars["Float"]["output"]>;
  _feeTotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["Float"]["output"]>;
  _taxTotal?: Maybe<Scalars["Float"]["output"]>;
  _total?: Maybe<Scalars["Float"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["Float"]["output"]>;
  _totalInclTax?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  tip?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_invoice" */
export type InvoiceAvgOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_invoice". All fields are combined with a logical 'AND'. */
export type InvoiceBoolExp = {
  _and?: InputMaybe<Array<InvoiceBoolExp>>;
  _discountTotal?: InputMaybe<NumericComparisonExp>;
  _feeTotal?: InputMaybe<NumericComparisonExp>;
  _not?: InputMaybe<InvoiceBoolExp>;
  _or?: InputMaybe<Array<InvoiceBoolExp>>;
  _subtotal?: InputMaybe<NumericComparisonExp>;
  _subtotalInclLineItemsDiscount?: InputMaybe<NumericComparisonExp>;
  _taxTotal?: InputMaybe<NumericComparisonExp>;
  _total?: InputMaybe<NumericComparisonExp>;
  _totalInclDiscount?: InputMaybe<NumericComparisonExp>;
  _totalInclTax?: InputMaybe<NumericComparisonExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  creditLines?: InputMaybe<WalletCreditLineBoolExp>;
  creditLinesAggregate?: InputMaybe<WalletCreditLineAggregateBoolExp>;
  discountLines?: InputMaybe<DiscountLineBoolExp>;
  discountLinesAggregate?: InputMaybe<DiscountLineAggregateBoolExp>;
  draftCreatedAt?: InputMaybe<TimestamptzComparisonExp>;
  feeLines?: InputMaybe<FeeLineBoolExp>;
  feeLinesAggregate?: InputMaybe<FeeLineAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  layerfiId?: InputMaybe<StringComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  membershipLines?: InputMaybe<MembershipLineBoolExp>;
  membershipLinesAggregate?: InputMaybe<MembershipLineAggregateBoolExp>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineBoolExp>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  packageLines?: InputMaybe<PackageLineBoolExp>;
  packageLinesAggregate?: InputMaybe<PackageLineAggregateBoolExp>;
  payments?: InputMaybe<PaymentBoolExp>;
  paymentsAggregate?: InputMaybe<PaymentAggregateBoolExp>;
  productLines?: InputMaybe<ProductLineBoolExp>;
  productLinesAggregate?: InputMaybe<ProductLineAggregateBoolExp>;
  purchaseSource?: InputMaybe<StringComparisonExp>;
  serviceMenuItemLines?: InputMaybe<ServiceMenuItemLineBoolExp>;
  serviceMenuItemLinesAggregate?: InputMaybe<ServiceMenuItemLineAggregateBoolExp>;
  serviceProductLines?: InputMaybe<ServiceProductLineBoolExp>;
  serviceProductLinesAggregate?: InputMaybe<ServiceProductLineAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
  tip?: InputMaybe<NumericComparisonExp>;
  uuid?: InputMaybe<StringComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_invoice" */
export enum InvoiceConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsInvoicePkey = "visits_invoice_pkey",
  /** unique or primary key constraint on columns "uuid" */
  VisitsInvoiceUuidKey = "visits_invoice_uuid_key",
  /** unique or primary key constraint on columns "visit_id" */
  VisitsInvoiceVisitIdKey = "visits_invoice_visit_id_key",
}

/** input type for incrementing numeric columns in table "visits_invoice" */
export type InvoiceIncInput = {
  _discountTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _feeTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _subtotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _subtotalInclLineItemsDiscount?: InputMaybe<Scalars["numeric"]["input"]>;
  _taxTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _total?: InputMaybe<Scalars["numeric"]["input"]>;
  _totalInclDiscount?: InputMaybe<Scalars["numeric"]["input"]>;
  _totalInclTax?: InputMaybe<Scalars["numeric"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  tip?: InputMaybe<Scalars["numeric"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_invoice" */
export type InvoiceInsertInput = {
  _discountTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _feeTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _subtotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _subtotalInclLineItemsDiscount?: InputMaybe<Scalars["numeric"]["input"]>;
  _taxTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _total?: InputMaybe<Scalars["numeric"]["input"]>;
  _totalInclDiscount?: InputMaybe<Scalars["numeric"]["input"]>;
  _totalInclTax?: InputMaybe<Scalars["numeric"]["input"]>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  creditLines?: InputMaybe<WalletCreditLineArrRelInsertInput>;
  discountLines?: InputMaybe<DiscountLineArrRelInsertInput>;
  /** Indicates when the invoice object was initially created in DB. This is different than 'created' that indicates when the visit was marked as completed and the checkout flow started. */
  draftCreatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  feeLines?: InputMaybe<FeeLineArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipLines?: InputMaybe<MembershipLineArrRelInsertInput>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  packageLines?: InputMaybe<PackageLineArrRelInsertInput>;
  payments?: InputMaybe<PaymentArrRelInsertInput>;
  productLines?: InputMaybe<ProductLineArrRelInsertInput>;
  /** Indicates the source of the purchase. Used for debugging/reporting purposes. */
  purchaseSource?: InputMaybe<Scalars["String"]["input"]>;
  serviceMenuItemLines?: InputMaybe<ServiceMenuItemLineArrRelInsertInput>;
  serviceProductLines?: InputMaybe<ServiceProductLineArrRelInsertInput>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  tip?: InputMaybe<Scalars["numeric"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type InvoiceMaxFields = {
  __typename?: "invoiceMaxFields";
  _discountTotal?: Maybe<Scalars["numeric"]["output"]>;
  _feeTotal?: Maybe<Scalars["numeric"]["output"]>;
  _subtotal?: Maybe<Scalars["numeric"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["numeric"]["output"]>;
  _taxTotal?: Maybe<Scalars["numeric"]["output"]>;
  _total?: Maybe<Scalars["numeric"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["numeric"]["output"]>;
  _totalInclTax?: Maybe<Scalars["numeric"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Indicates when the invoice object was initially created in DB. This is different than 'created' that indicates when the visit was marked as completed and the checkout flow started. */
  draftCreatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: Maybe<Scalars["String"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Indicates the source of the purchase. Used for debugging/reporting purposes. */
  purchaseSource?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  tip?: Maybe<Scalars["numeric"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_invoice" */
export type InvoiceMaxOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Indicates when the invoice object was initially created in DB. This is different than 'created' that indicates when the visit was marked as completed and the checkout flow started. */
  draftCreatedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Indicates the source of the purchase. Used for debugging/reporting purposes. */
  purchaseSource?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InvoiceMinFields = {
  __typename?: "invoiceMinFields";
  _discountTotal?: Maybe<Scalars["numeric"]["output"]>;
  _feeTotal?: Maybe<Scalars["numeric"]["output"]>;
  _subtotal?: Maybe<Scalars["numeric"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["numeric"]["output"]>;
  _taxTotal?: Maybe<Scalars["numeric"]["output"]>;
  _total?: Maybe<Scalars["numeric"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["numeric"]["output"]>;
  _totalInclTax?: Maybe<Scalars["numeric"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Indicates when the invoice object was initially created in DB. This is different than 'created' that indicates when the visit was marked as completed and the checkout flow started. */
  draftCreatedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: Maybe<Scalars["String"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Indicates the source of the purchase. Used for debugging/reporting purposes. */
  purchaseSource?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  tip?: Maybe<Scalars["numeric"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_invoice" */
export type InvoiceMinOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Indicates when the invoice object was initially created in DB. This is different than 'created' that indicates when the visit was marked as completed and the checkout flow started. */
  draftCreatedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Indicates the source of the purchase. Used for debugging/reporting purposes. */
  purchaseSource?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_invoice" */
export type InvoiceMutationResponse = {
  __typename?: "invoiceMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice>;
};

/** input type for inserting object relation for remote table "visits_invoice" */
export type InvoiceObjRelInsertInput = {
  data: InvoiceInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<InvoiceOnConflict>;
};

/** on_conflict condition type for table "visits_invoice" */
export type InvoiceOnConflict = {
  constraint: InvoiceConstraint;
  updateColumns?: Array<InvoiceUpdateColumn>;
  where?: InputMaybe<InvoiceBoolExp>;
};

/** Ordering options when selecting data from "visits_invoice". */
export type InvoiceOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  creditLinesAggregate?: InputMaybe<WalletCreditLineAggregateOrderBy>;
  discountLinesAggregate?: InputMaybe<DiscountLineAggregateOrderBy>;
  draftCreatedAt?: InputMaybe<OrderBy>;
  feeLinesAggregate?: InputMaybe<FeeLineAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  layerfiId?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  membershipLinesAggregate?: InputMaybe<MembershipLineAggregateOrderBy>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  packageLinesAggregate?: InputMaybe<PackageLineAggregateOrderBy>;
  paymentsAggregate?: InputMaybe<PaymentAggregateOrderBy>;
  productLinesAggregate?: InputMaybe<ProductLineAggregateOrderBy>;
  purchaseSource?: InputMaybe<OrderBy>;
  serviceMenuItemLinesAggregate?: InputMaybe<ServiceMenuItemLineAggregateOrderBy>;
  serviceProductLinesAggregate?: InputMaybe<ServiceProductLineAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_invoice */
export type InvoicePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_invoice" */
export enum InvoiceSelectColumn {
  /** column name */
  DiscountTotal = "_discountTotal",
  /** column name */
  FeeTotal = "_feeTotal",
  /** column name */
  Subtotal = "_subtotal",
  /** column name */
  SubtotalInclLineItemsDiscount = "_subtotalInclLineItemsDiscount",
  /** column name */
  TaxTotal = "_taxTotal",
  /** column name */
  Total = "_total",
  /** column name */
  TotalInclDiscount = "_totalInclDiscount",
  /** column name */
  TotalInclTax = "_totalInclTax",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DraftCreatedAt = "draftCreatedAt",
  /** column name */
  Id = "id",
  /** column name */
  LayerfiId = "layerfiId",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PurchaseSource = "purchaseSource",
  /** column name */
  Status = "status",
  /** column name */
  Tip = "tip",
  /** column name */
  Uuid = "uuid",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "visits_invoice" */
export type InvoiceSetInput = {
  _discountTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _feeTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _subtotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _subtotalInclLineItemsDiscount?: InputMaybe<Scalars["numeric"]["input"]>;
  _taxTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _total?: InputMaybe<Scalars["numeric"]["input"]>;
  _totalInclDiscount?: InputMaybe<Scalars["numeric"]["input"]>;
  _totalInclTax?: InputMaybe<Scalars["numeric"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Indicates when the invoice object was initially created in DB. This is different than 'created' that indicates when the visit was marked as completed and the checkout flow started. */
  draftCreatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Indicates the source of the purchase. Used for debugging/reporting purposes. */
  purchaseSource?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  tip?: InputMaybe<Scalars["numeric"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type InvoiceStddevFields = {
  __typename?: "invoiceStddevFields";
  _discountTotal?: Maybe<Scalars["Float"]["output"]>;
  _feeTotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["Float"]["output"]>;
  _taxTotal?: Maybe<Scalars["Float"]["output"]>;
  _total?: Maybe<Scalars["Float"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["Float"]["output"]>;
  _totalInclTax?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  tip?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_invoice" */
export type InvoiceStddevOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type InvoiceStddevPopFields = {
  __typename?: "invoiceStddevPopFields";
  _discountTotal?: Maybe<Scalars["Float"]["output"]>;
  _feeTotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["Float"]["output"]>;
  _taxTotal?: Maybe<Scalars["Float"]["output"]>;
  _total?: Maybe<Scalars["Float"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["Float"]["output"]>;
  _totalInclTax?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  tip?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_invoice" */
export type InvoiceStddevPopOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type InvoiceStddevSampFields = {
  __typename?: "invoiceStddevSampFields";
  _discountTotal?: Maybe<Scalars["Float"]["output"]>;
  _feeTotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["Float"]["output"]>;
  _taxTotal?: Maybe<Scalars["Float"]["output"]>;
  _total?: Maybe<Scalars["Float"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["Float"]["output"]>;
  _totalInclTax?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  tip?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_invoice" */
export type InvoiceStddevSampOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "invoice" */
export type InvoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InvoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceStreamCursorValueInput = {
  _discountTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _feeTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _subtotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _subtotalInclLineItemsDiscount?: InputMaybe<Scalars["numeric"]["input"]>;
  _taxTotal?: InputMaybe<Scalars["numeric"]["input"]>;
  _total?: InputMaybe<Scalars["numeric"]["input"]>;
  _totalInclDiscount?: InputMaybe<Scalars["numeric"]["input"]>;
  _totalInclTax?: InputMaybe<Scalars["numeric"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Indicates when the invoice object was initially created in DB. This is different than 'created' that indicates when the visit was marked as completed and the checkout flow started. */
  draftCreatedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Indicates the source of the purchase. Used for debugging/reporting purposes. */
  purchaseSource?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  tip?: InputMaybe<Scalars["numeric"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type InvoiceSumFields = {
  __typename?: "invoiceSumFields";
  _discountTotal?: Maybe<Scalars["numeric"]["output"]>;
  _feeTotal?: Maybe<Scalars["numeric"]["output"]>;
  _subtotal?: Maybe<Scalars["numeric"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["numeric"]["output"]>;
  _taxTotal?: Maybe<Scalars["numeric"]["output"]>;
  _total?: Maybe<Scalars["numeric"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["numeric"]["output"]>;
  _totalInclTax?: Maybe<Scalars["numeric"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  tip?: Maybe<Scalars["numeric"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_invoice" */
export type InvoiceSumOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_invoice" */
export enum InvoiceUpdateColumn {
  /** column name */
  DiscountTotal = "_discountTotal",
  /** column name */
  FeeTotal = "_feeTotal",
  /** column name */
  Subtotal = "_subtotal",
  /** column name */
  SubtotalInclLineItemsDiscount = "_subtotalInclLineItemsDiscount",
  /** column name */
  TaxTotal = "_taxTotal",
  /** column name */
  Total = "_total",
  /** column name */
  TotalInclDiscount = "_totalInclDiscount",
  /** column name */
  TotalInclTax = "_totalInclTax",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DraftCreatedAt = "draftCreatedAt",
  /** column name */
  Id = "id",
  /** column name */
  LayerfiId = "layerfiId",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PurchaseSource = "purchaseSource",
  /** column name */
  Status = "status",
  /** column name */
  Tip = "tip",
  /** column name */
  Uuid = "uuid",
  /** column name */
  VisitId = "visitId",
}

export type InvoiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceSetInput>;
  /** filter the rows which have to be updated */
  where: InvoiceBoolExp;
};

/** aggregate varPop on columns */
export type InvoiceVarPopFields = {
  __typename?: "invoiceVarPopFields";
  _discountTotal?: Maybe<Scalars["Float"]["output"]>;
  _feeTotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["Float"]["output"]>;
  _taxTotal?: Maybe<Scalars["Float"]["output"]>;
  _total?: Maybe<Scalars["Float"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["Float"]["output"]>;
  _totalInclTax?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  tip?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_invoice" */
export type InvoiceVarPopOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type InvoiceVarSampFields = {
  __typename?: "invoiceVarSampFields";
  _discountTotal?: Maybe<Scalars["Float"]["output"]>;
  _feeTotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["Float"]["output"]>;
  _taxTotal?: Maybe<Scalars["Float"]["output"]>;
  _total?: Maybe<Scalars["Float"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["Float"]["output"]>;
  _totalInclTax?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  tip?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_invoice" */
export type InvoiceVarSampOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type InvoiceVarianceFields = {
  __typename?: "invoiceVarianceFields";
  _discountTotal?: Maybe<Scalars["Float"]["output"]>;
  _feeTotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotal?: Maybe<Scalars["Float"]["output"]>;
  _subtotalInclLineItemsDiscount?: Maybe<Scalars["Float"]["output"]>;
  _taxTotal?: Maybe<Scalars["Float"]["output"]>;
  _total?: Maybe<Scalars["Float"]["output"]>;
  _totalInclDiscount?: Maybe<Scalars["Float"]["output"]>;
  _totalInclTax?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  tip?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_invoice" */
export type InvoiceVarianceOrderBy = {
  _discountTotal?: InputMaybe<OrderBy>;
  _feeTotal?: InputMaybe<OrderBy>;
  _subtotal?: InputMaybe<OrderBy>;
  _subtotalInclLineItemsDiscount?: InputMaybe<OrderBy>;
  _taxTotal?: InputMaybe<OrderBy>;
  _total?: InputMaybe<OrderBy>;
  _totalInclDiscount?: InputMaybe<OrderBy>;
  _totalInclTax?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  tip?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

export type LastGfeReviewTreatmentIndicationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LastGfeReviewTreatmentIndicationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
  predicate: IntComparisonExp;
};

export type MdMeetingAttendeesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MdMeetingAttendeesBoolExp>;
  predicate: IntComparisonExp;
};

export type MdMeetingNoteAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MdMeetingNoteSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MdMeetingNoteBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "medspas_medicaldirector" */
export type MedicalDirector = {
  __typename?: "medicalDirector";
  /** An object relationship */
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  /** Internal value for the "Medical Director" property in HubSpot */
  hubspotMedicalDirectorInternalValue: Scalars["String"]["output"];
  /** An array relationship */
  medicalDirectorSubscriptions: Array<MedicalDirectorSubscription>;
  /** An aggregate relationship */
  medicalDirectorSubscriptionsAggregate: MedicalDirectorSubscriptionAggregate;
  modified: Scalars["timestamptz"]["output"];
  stripeAccountId: Scalars["String"]["output"];
  stripeAccountType: Scalars["String"]["output"];
  stripeOnboardingDone: Scalars["Boolean"]["output"];
  /** An object relationship */
  user: User;
  userId: Scalars["bigint"]["output"];
};

/** columns and relationships of "medspas_medicaldirector" */
export type MedicalDirectorMedicalDirectorSubscriptionsArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorSubscriptionOrderBy>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

/** columns and relationships of "medspas_medicaldirector" */
export type MedicalDirectorMedicalDirectorSubscriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorSubscriptionOrderBy>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

/** aggregated selection of "medspas_medicaldirector" */
export type MedicalDirectorAggregate = {
  __typename?: "medicalDirectorAggregate";
  aggregate?: Maybe<MedicalDirectorAggregateFields>;
  nodes: Array<MedicalDirector>;
};

export type MedicalDirectorAggregateBoolExp = {
  bool_and?: InputMaybe<MedicalDirectorAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MedicalDirectorAggregateBoolExpBool_Or>;
  count?: InputMaybe<MedicalDirectorAggregateBoolExpCount>;
};

export type MedicalDirectorAggregateBoolExpBool_And = {
  arguments: MedicalDirectorSelectColumnMedicalDirectorAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedicalDirectorBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedicalDirectorAggregateBoolExpBool_Or = {
  arguments: MedicalDirectorSelectColumnMedicalDirectorAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedicalDirectorBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedicalDirectorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedicalDirectorSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedicalDirectorBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_medicaldirector" */
export type MedicalDirectorAggregateFields = {
  __typename?: "medicalDirectorAggregateFields";
  avg?: Maybe<MedicalDirectorAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedicalDirectorMaxFields>;
  min?: Maybe<MedicalDirectorMinFields>;
  stddev?: Maybe<MedicalDirectorStddevFields>;
  stddevPop?: Maybe<MedicalDirectorStddevPopFields>;
  stddevSamp?: Maybe<MedicalDirectorStddevSampFields>;
  sum?: Maybe<MedicalDirectorSumFields>;
  varPop?: Maybe<MedicalDirectorVarPopFields>;
  varSamp?: Maybe<MedicalDirectorVarSampFields>;
  variance?: Maybe<MedicalDirectorVarianceFields>;
};

/** aggregate fields of "medspas_medicaldirector" */
export type MedicalDirectorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedicalDirectorSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_medicaldirector" */
export type MedicalDirectorAggregateOrderBy = {
  avg?: InputMaybe<MedicalDirectorAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedicalDirectorMaxOrderBy>;
  min?: InputMaybe<MedicalDirectorMinOrderBy>;
  stddev?: InputMaybe<MedicalDirectorStddevOrderBy>;
  stddevPop?: InputMaybe<MedicalDirectorStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedicalDirectorStddevSampOrderBy>;
  sum?: InputMaybe<MedicalDirectorSumOrderBy>;
  varPop?: InputMaybe<MedicalDirectorVarPopOrderBy>;
  varSamp?: InputMaybe<MedicalDirectorVarSampOrderBy>;
  variance?: InputMaybe<MedicalDirectorVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_medicaldirector" */
export type MedicalDirectorArrRelInsertInput = {
  data: Array<MedicalDirectorInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedicalDirectorOnConflict>;
};

/** aggregate avg on columns */
export type MedicalDirectorAvgFields = {
  __typename?: "medicalDirectorAvgFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorAvgOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_medicaldirector". All fields are combined with a logical 'AND'. */
export type MedicalDirectorBoolExp = {
  _and?: InputMaybe<Array<MedicalDirectorBoolExp>>;
  _not?: InputMaybe<MedicalDirectorBoolExp>;
  _or?: InputMaybe<Array<MedicalDirectorBoolExp>>;
  address?: InputMaybe<AddressBoolExp>;
  addressId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  hubspotMedicalDirectorInternalValue?: InputMaybe<StringComparisonExp>;
  medicalDirectorSubscriptions?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
  medicalDirectorSubscriptionsAggregate?: InputMaybe<MedicalDirectorSubscriptionAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  stripeAccountId?: InputMaybe<StringComparisonExp>;
  stripeAccountType?: InputMaybe<StringComparisonExp>;
  stripeOnboardingDone?: InputMaybe<BooleanComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspas_medicaldirector" */
export enum MedicalDirectorConstraint {
  /** unique or primary key constraint on columns "user_id" */
  MedspasMedicaldirectorPkey = "medspas_medicaldirector_pkey",
}

/** input type for incrementing numeric columns in table "medspas_medicaldirector" */
export type MedicalDirectorIncInput = {
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_medicaldirector" */
export type MedicalDirectorInsertInput = {
  address?: InputMaybe<AddressObjRelInsertInput>;
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Internal value for the "Medical Director" property in HubSpot */
  hubspotMedicalDirectorInternalValue?: InputMaybe<Scalars["String"]["input"]>;
  medicalDirectorSubscriptions?: InputMaybe<MedicalDirectorSubscriptionArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountType?: InputMaybe<Scalars["String"]["input"]>;
  stripeOnboardingDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type MedicalDirectorMaxFields = {
  __typename?: "medicalDirectorMaxFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Internal value for the "Medical Director" property in HubSpot */
  hubspotMedicalDirectorInternalValue?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  stripeAccountId?: Maybe<Scalars["String"]["output"]>;
  stripeAccountType?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorMaxOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Internal value for the "Medical Director" property in HubSpot */
  hubspotMedicalDirectorInternalValue?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  stripeAccountId?: InputMaybe<OrderBy>;
  stripeAccountType?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedicalDirectorMinFields = {
  __typename?: "medicalDirectorMinFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Internal value for the "Medical Director" property in HubSpot */
  hubspotMedicalDirectorInternalValue?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  stripeAccountId?: Maybe<Scalars["String"]["output"]>;
  stripeAccountType?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorMinOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Internal value for the "Medical Director" property in HubSpot */
  hubspotMedicalDirectorInternalValue?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  stripeAccountId?: InputMaybe<OrderBy>;
  stripeAccountType?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_medicaldirector" */
export type MedicalDirectorMutationResponse = {
  __typename?: "medicalDirectorMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedicalDirector>;
};

/** input type for inserting object relation for remote table "medspas_medicaldirector" */
export type MedicalDirectorObjRelInsertInput = {
  data: MedicalDirectorInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedicalDirectorOnConflict>;
};

/** on_conflict condition type for table "medspas_medicaldirector" */
export type MedicalDirectorOnConflict = {
  constraint: MedicalDirectorConstraint;
  updateColumns?: Array<MedicalDirectorUpdateColumn>;
  where?: InputMaybe<MedicalDirectorBoolExp>;
};

/** Ordering options when selecting data from "medspas_medicaldirector". */
export type MedicalDirectorOrderBy = {
  address?: InputMaybe<AddressOrderBy>;
  addressId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  hubspotMedicalDirectorInternalValue?: InputMaybe<OrderBy>;
  medicalDirectorSubscriptionsAggregate?: InputMaybe<MedicalDirectorSubscriptionAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  stripeAccountId?: InputMaybe<OrderBy>;
  stripeAccountType?: InputMaybe<OrderBy>;
  stripeOnboardingDone?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_medicaldirector */
export type MedicalDirectorPkColumnsInput = {
  userId: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_medicaldirector" */
export enum MedicalDirectorSelectColumn {
  /** column name */
  AddressId = "addressId",
  /** column name */
  Created = "created",
  /** column name */
  HubspotMedicalDirectorInternalValue = "hubspotMedicalDirectorInternalValue",
  /** column name */
  Modified = "modified",
  /** column name */
  StripeAccountId = "stripeAccountId",
  /** column name */
  StripeAccountType = "stripeAccountType",
  /** column name */
  StripeOnboardingDone = "stripeOnboardingDone",
  /** column name */
  UserId = "userId",
}

/** select "medicalDirectorAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_medicaldirector" */
export enum MedicalDirectorSelectColumnMedicalDirectorAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  StripeOnboardingDone = "stripeOnboardingDone",
}

/** select "medicalDirectorAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_medicaldirector" */
export enum MedicalDirectorSelectColumnMedicalDirectorAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  StripeOnboardingDone = "stripeOnboardingDone",
}

/** input type for updating data in table "medspas_medicaldirector" */
export type MedicalDirectorSetInput = {
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Internal value for the "Medical Director" property in HubSpot */
  hubspotMedicalDirectorInternalValue?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountType?: InputMaybe<Scalars["String"]["input"]>;
  stripeOnboardingDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type MedicalDirectorStddevFields = {
  __typename?: "medicalDirectorStddevFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorStddevOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedicalDirectorStddevPopFields = {
  __typename?: "medicalDirectorStddevPopFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorStddevPopOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedicalDirectorStddevSampFields = {
  __typename?: "medicalDirectorStddevSampFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorStddevSampOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medicalDirector" */
export type MedicalDirectorStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedicalDirectorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedicalDirectorStreamCursorValueInput = {
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Internal value for the "Medical Director" property in HubSpot */
  hubspotMedicalDirectorInternalValue?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountType?: InputMaybe<Scalars["String"]["input"]>;
  stripeOnboardingDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** columns and relationships of "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscription = {
  __typename?: "medicalDirectorSubscription";
  additionalInfo?: Maybe<MedicalDirectorSubscriptionType>;
  autopay: Scalars["Boolean"]["output"];
  cancelDate?: Maybe<Scalars["date"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medicalDirector: MedicalDirector;
  medicalDirectorId: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  monthlyFee: Scalars["numeric"]["output"];
  moxieFee: Scalars["numeric"]["output"];
  note: Scalars["String"]["output"];
  pastDueReason: Scalars["String"]["output"];
  primaryPaymentMethodId: Scalars["String"]["output"];
  primaryPaymentMethodType: Scalars["String"]["output"];
  secondaryPaymentMethodId: Scalars["String"]["output"];
  secondaryPaymentMethodType: Scalars["String"]["output"];
  startDate: Scalars["date"]["output"];
  status: Scalars["String"]["output"];
  stripeCustomerAccountId: Scalars["String"]["output"];
  stripePaymentType: Scalars["String"]["output"];
  stripeSubscriptionId: Scalars["String"]["output"];
};

/** aggregated selection of "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionAggregate = {
  __typename?: "medicalDirectorSubscriptionAggregate";
  aggregate?: Maybe<MedicalDirectorSubscriptionAggregateFields>;
  nodes: Array<MedicalDirectorSubscription>;
};

export type MedicalDirectorSubscriptionAggregateBoolExp = {
  bool_and?: InputMaybe<MedicalDirectorSubscriptionAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MedicalDirectorSubscriptionAggregateBoolExpBool_Or>;
  count?: InputMaybe<MedicalDirectorSubscriptionAggregateBoolExpCount>;
};

export type MedicalDirectorSubscriptionAggregateBoolExpBool_And = {
  arguments: MedicalDirectorSubscriptionSelectColumnMedicalDirectorSubscriptionAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedicalDirectorSubscriptionAggregateBoolExpBool_Or = {
  arguments: MedicalDirectorSubscriptionSelectColumnMedicalDirectorSubscriptionAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedicalDirectorSubscriptionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionAggregateFields = {
  __typename?: "medicalDirectorSubscriptionAggregateFields";
  avg?: Maybe<MedicalDirectorSubscriptionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedicalDirectorSubscriptionMaxFields>;
  min?: Maybe<MedicalDirectorSubscriptionMinFields>;
  stddev?: Maybe<MedicalDirectorSubscriptionStddevFields>;
  stddevPop?: Maybe<MedicalDirectorSubscriptionStddevPopFields>;
  stddevSamp?: Maybe<MedicalDirectorSubscriptionStddevSampFields>;
  sum?: Maybe<MedicalDirectorSubscriptionSumFields>;
  varPop?: Maybe<MedicalDirectorSubscriptionVarPopFields>;
  varSamp?: Maybe<MedicalDirectorSubscriptionVarSampFields>;
  variance?: Maybe<MedicalDirectorSubscriptionVarianceFields>;
};

/** aggregate fields of "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionAggregateOrderBy = {
  avg?: InputMaybe<MedicalDirectorSubscriptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedicalDirectorSubscriptionMaxOrderBy>;
  min?: InputMaybe<MedicalDirectorSubscriptionMinOrderBy>;
  stddev?: InputMaybe<MedicalDirectorSubscriptionStddevOrderBy>;
  stddevPop?: InputMaybe<MedicalDirectorSubscriptionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedicalDirectorSubscriptionStddevSampOrderBy>;
  sum?: InputMaybe<MedicalDirectorSubscriptionSumOrderBy>;
  varPop?: InputMaybe<MedicalDirectorSubscriptionVarPopOrderBy>;
  varSamp?: InputMaybe<MedicalDirectorSubscriptionVarSampOrderBy>;
  variance?: InputMaybe<MedicalDirectorSubscriptionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionArrRelInsertInput = {
  data: Array<MedicalDirectorSubscriptionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedicalDirectorSubscriptionOnConflict>;
};

/** aggregate avg on columns */
export type MedicalDirectorSubscriptionAvgFields = {
  __typename?: "medicalDirectorSubscriptionAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  monthlyFee?: Maybe<Scalars["Float"]["output"]>;
  moxieFee?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_medicaldirectorsubscription". All fields are combined with a logical 'AND'. */
export type MedicalDirectorSubscriptionBoolExp = {
  _and?: InputMaybe<Array<MedicalDirectorSubscriptionBoolExp>>;
  _not?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
  _or?: InputMaybe<Array<MedicalDirectorSubscriptionBoolExp>>;
  autopay?: InputMaybe<BooleanComparisonExp>;
  cancelDate?: InputMaybe<DateComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medicalDirector?: InputMaybe<MedicalDirectorBoolExp>;
  medicalDirectorId?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  monthlyFee?: InputMaybe<NumericComparisonExp>;
  moxieFee?: InputMaybe<NumericComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  pastDueReason?: InputMaybe<StringComparisonExp>;
  primaryPaymentMethodId?: InputMaybe<StringComparisonExp>;
  primaryPaymentMethodType?: InputMaybe<StringComparisonExp>;
  secondaryPaymentMethodId?: InputMaybe<StringComparisonExp>;
  secondaryPaymentMethodType?: InputMaybe<StringComparisonExp>;
  startDate?: InputMaybe<DateComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  stripeCustomerAccountId?: InputMaybe<StringComparisonExp>;
  stripePaymentType?: InputMaybe<StringComparisonExp>;
  stripeSubscriptionId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "medspas_medicaldirectorsubscription" */
export enum MedicalDirectorSubscriptionConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasMedicaldirectorsubscriptionPkey = "medspas_medicaldirectorsubscription_pkey",
  /** unique or primary key constraint on columns "stripe_subscription_id" */
  UniqueMedicalDirectorSubscriptionStripeSubscriptionId = "unique_medical_director_subscription_stripe_subscription_id",
}

/** input type for incrementing numeric columns in table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  monthlyFee?: InputMaybe<Scalars["numeric"]["input"]>;
  moxieFee?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionInsertInput = {
  autopay?: InputMaybe<Scalars["Boolean"]["input"]>;
  cancelDate?: InputMaybe<Scalars["date"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicalDirector?: InputMaybe<MedicalDirectorObjRelInsertInput>;
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  monthlyFee?: InputMaybe<Scalars["numeric"]["input"]>;
  moxieFee?: InputMaybe<Scalars["numeric"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  pastDueReason?: InputMaybe<Scalars["String"]["input"]>;
  primaryPaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
  primaryPaymentMethodType?: InputMaybe<Scalars["String"]["input"]>;
  secondaryPaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
  secondaryPaymentMethodType?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripePaymentType?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MedicalDirectorSubscriptionMaxFields = {
  __typename?: "medicalDirectorSubscriptionMaxFields";
  cancelDate?: Maybe<Scalars["date"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  monthlyFee?: Maybe<Scalars["numeric"]["output"]>;
  moxieFee?: Maybe<Scalars["numeric"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  pastDueReason?: Maybe<Scalars["String"]["output"]>;
  primaryPaymentMethodId?: Maybe<Scalars["String"]["output"]>;
  primaryPaymentMethodType?: Maybe<Scalars["String"]["output"]>;
  secondaryPaymentMethodId?: Maybe<Scalars["String"]["output"]>;
  secondaryPaymentMethodType?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerAccountId?: Maybe<Scalars["String"]["output"]>;
  stripePaymentType?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionMaxOrderBy = {
  cancelDate?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  pastDueReason?: InputMaybe<OrderBy>;
  primaryPaymentMethodId?: InputMaybe<OrderBy>;
  primaryPaymentMethodType?: InputMaybe<OrderBy>;
  secondaryPaymentMethodId?: InputMaybe<OrderBy>;
  secondaryPaymentMethodType?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeCustomerAccountId?: InputMaybe<OrderBy>;
  stripePaymentType?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedicalDirectorSubscriptionMinFields = {
  __typename?: "medicalDirectorSubscriptionMinFields";
  cancelDate?: Maybe<Scalars["date"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  monthlyFee?: Maybe<Scalars["numeric"]["output"]>;
  moxieFee?: Maybe<Scalars["numeric"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  pastDueReason?: Maybe<Scalars["String"]["output"]>;
  primaryPaymentMethodId?: Maybe<Scalars["String"]["output"]>;
  primaryPaymentMethodType?: Maybe<Scalars["String"]["output"]>;
  secondaryPaymentMethodId?: Maybe<Scalars["String"]["output"]>;
  secondaryPaymentMethodType?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerAccountId?: Maybe<Scalars["String"]["output"]>;
  stripePaymentType?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionMinOrderBy = {
  cancelDate?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  pastDueReason?: InputMaybe<OrderBy>;
  primaryPaymentMethodId?: InputMaybe<OrderBy>;
  primaryPaymentMethodType?: InputMaybe<OrderBy>;
  secondaryPaymentMethodId?: InputMaybe<OrderBy>;
  secondaryPaymentMethodType?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeCustomerAccountId?: InputMaybe<OrderBy>;
  stripePaymentType?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionMutationResponse = {
  __typename?: "medicalDirectorSubscriptionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<MedicalDirectorSubscription>;
};

/** on_conflict condition type for table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionOnConflict = {
  constraint: MedicalDirectorSubscriptionConstraint;
  updateColumns?: Array<MedicalDirectorSubscriptionUpdateColumn>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

/** Ordering options when selecting data from "medspas_medicaldirectorsubscription". */
export type MedicalDirectorSubscriptionOrderBy = {
  autopay?: InputMaybe<OrderBy>;
  cancelDate?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirector?: InputMaybe<MedicalDirectorOrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  pastDueReason?: InputMaybe<OrderBy>;
  primaryPaymentMethodId?: InputMaybe<OrderBy>;
  primaryPaymentMethodType?: InputMaybe<OrderBy>;
  secondaryPaymentMethodId?: InputMaybe<OrderBy>;
  secondaryPaymentMethodType?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeCustomerAccountId?: InputMaybe<OrderBy>;
  stripePaymentType?: InputMaybe<OrderBy>;
  stripeSubscriptionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_medicaldirectorsubscription */
export type MedicalDirectorSubscriptionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_medicaldirectorsubscription" */
export enum MedicalDirectorSubscriptionSelectColumn {
  /** column name */
  Autopay = "autopay",
  /** column name */
  CancelDate = "cancelDate",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedicalDirectorId = "medicalDirectorId",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  MonthlyFee = "monthlyFee",
  /** column name */
  MoxieFee = "moxieFee",
  /** column name */
  Note = "note",
  /** column name */
  PastDueReason = "pastDueReason",
  /** column name */
  PrimaryPaymentMethodId = "primaryPaymentMethodId",
  /** column name */
  PrimaryPaymentMethodType = "primaryPaymentMethodType",
  /** column name */
  SecondaryPaymentMethodId = "secondaryPaymentMethodId",
  /** column name */
  SecondaryPaymentMethodType = "secondaryPaymentMethodType",
  /** column name */
  StartDate = "startDate",
  /** column name */
  Status = "status",
  /** column name */
  StripeCustomerAccountId = "stripeCustomerAccountId",
  /** column name */
  StripePaymentType = "stripePaymentType",
  /** column name */
  StripeSubscriptionId = "stripeSubscriptionId",
}

/** select "medicalDirectorSubscriptionAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_medicaldirectorsubscription" */
export enum MedicalDirectorSubscriptionSelectColumnMedicalDirectorSubscriptionAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Autopay = "autopay",
}

/** select "medicalDirectorSubscriptionAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_medicaldirectorsubscription" */
export enum MedicalDirectorSubscriptionSelectColumnMedicalDirectorSubscriptionAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Autopay = "autopay",
}

/** input type for updating data in table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionSetInput = {
  autopay?: InputMaybe<Scalars["Boolean"]["input"]>;
  cancelDate?: InputMaybe<Scalars["date"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  monthlyFee?: InputMaybe<Scalars["numeric"]["input"]>;
  moxieFee?: InputMaybe<Scalars["numeric"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  pastDueReason?: InputMaybe<Scalars["String"]["input"]>;
  primaryPaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
  primaryPaymentMethodType?: InputMaybe<Scalars["String"]["input"]>;
  secondaryPaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
  secondaryPaymentMethodType?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripePaymentType?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type MedicalDirectorSubscriptionStddevFields = {
  __typename?: "medicalDirectorSubscriptionStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  monthlyFee?: Maybe<Scalars["Float"]["output"]>;
  moxieFee?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedicalDirectorSubscriptionStddevPopFields = {
  __typename?: "medicalDirectorSubscriptionStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  monthlyFee?: Maybe<Scalars["Float"]["output"]>;
  moxieFee?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedicalDirectorSubscriptionStddevSampFields = {
  __typename?: "medicalDirectorSubscriptionStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  monthlyFee?: Maybe<Scalars["Float"]["output"]>;
  moxieFee?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medicalDirectorSubscription" */
export type MedicalDirectorSubscriptionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedicalDirectorSubscriptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedicalDirectorSubscriptionStreamCursorValueInput = {
  autopay?: InputMaybe<Scalars["Boolean"]["input"]>;
  cancelDate?: InputMaybe<Scalars["date"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  monthlyFee?: InputMaybe<Scalars["numeric"]["input"]>;
  moxieFee?: InputMaybe<Scalars["numeric"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  pastDueReason?: InputMaybe<Scalars["String"]["input"]>;
  primaryPaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
  primaryPaymentMethodType?: InputMaybe<Scalars["String"]["input"]>;
  secondaryPaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
  secondaryPaymentMethodType?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripePaymentType?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MedicalDirectorSubscriptionSumFields = {
  __typename?: "medicalDirectorSubscriptionSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  monthlyFee?: Maybe<Scalars["numeric"]["output"]>;
  moxieFee?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_medicaldirectorsubscription" */
export enum MedicalDirectorSubscriptionUpdateColumn {
  /** column name */
  Autopay = "autopay",
  /** column name */
  CancelDate = "cancelDate",
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  MedicalDirectorId = "medicalDirectorId",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  MonthlyFee = "monthlyFee",
  /** column name */
  MoxieFee = "moxieFee",
  /** column name */
  Note = "note",
  /** column name */
  PastDueReason = "pastDueReason",
  /** column name */
  PrimaryPaymentMethodId = "primaryPaymentMethodId",
  /** column name */
  PrimaryPaymentMethodType = "primaryPaymentMethodType",
  /** column name */
  SecondaryPaymentMethodId = "secondaryPaymentMethodId",
  /** column name */
  SecondaryPaymentMethodType = "secondaryPaymentMethodType",
  /** column name */
  StartDate = "startDate",
  /** column name */
  Status = "status",
  /** column name */
  StripeCustomerAccountId = "stripeCustomerAccountId",
  /** column name */
  StripePaymentType = "stripePaymentType",
  /** column name */
  StripeSubscriptionId = "stripeSubscriptionId",
}

export type MedicalDirectorSubscriptionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedicalDirectorSubscriptionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedicalDirectorSubscriptionSetInput>;
  /** filter the rows which have to be updated */
  where: MedicalDirectorSubscriptionBoolExp;
};

/** aggregate varPop on columns */
export type MedicalDirectorSubscriptionVarPopFields = {
  __typename?: "medicalDirectorSubscriptionVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  monthlyFee?: Maybe<Scalars["Float"]["output"]>;
  moxieFee?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedicalDirectorSubscriptionVarSampFields = {
  __typename?: "medicalDirectorSubscriptionVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  monthlyFee?: Maybe<Scalars["Float"]["output"]>;
  moxieFee?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedicalDirectorSubscriptionVarianceFields = {
  __typename?: "medicalDirectorSubscriptionVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  monthlyFee?: Maybe<Scalars["Float"]["output"]>;
  moxieFee?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_medicaldirectorsubscription" */
export type MedicalDirectorSubscriptionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  monthlyFee?: InputMaybe<OrderBy>;
  moxieFee?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type MedicalDirectorSumFields = {
  __typename?: "medicalDirectorSumFields";
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorSumOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_medicaldirector" */
export enum MedicalDirectorUpdateColumn {
  /** column name */
  AddressId = "addressId",
  /** column name */
  Created = "created",
  /** column name */
  HubspotMedicalDirectorInternalValue = "hubspotMedicalDirectorInternalValue",
  /** column name */
  Modified = "modified",
  /** column name */
  StripeAccountId = "stripeAccountId",
  /** column name */
  StripeAccountType = "stripeAccountType",
  /** column name */
  StripeOnboardingDone = "stripeOnboardingDone",
  /** column name */
  UserId = "userId",
}

export type MedicalDirectorUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedicalDirectorIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedicalDirectorSetInput>;
  /** filter the rows which have to be updated */
  where: MedicalDirectorBoolExp;
};

/** aggregate varPop on columns */
export type MedicalDirectorVarPopFields = {
  __typename?: "medicalDirectorVarPopFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorVarPopOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedicalDirectorVarSampFields = {
  __typename?: "medicalDirectorVarSampFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorVarSampOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedicalDirectorVarianceFields = {
  __typename?: "medicalDirectorVarianceFields";
  addressId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_medicaldirector" */
export type MedicalDirectorVarianceOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspas_medspa" */
export type Medspa = {
  __typename?: "medspa";
  additionalApplicationFeePercentage: Scalars["numeric"]["output"];
  additionalInfo?: Maybe<MedSpaType>;
  additionalPublicInfo?: Maybe<MedspaPublicInfoType>;
  /** An object relationship */
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  administrativeDivision?: Maybe<AdministrativeDivision>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["bigint"]["output"]>;
  businessType: Scalars["String"]["output"];
  /** An array relationship */
  careForms: Array<CareForm>;
  /** An aggregate relationship */
  careFormsAggregate: CareFormAggregate;
  cherryApiKey: Scalars["bytea"]["output"];
  /** An array relationship */
  clients: Array<Client>;
  /** An aggregate relationship */
  clientsAggregate: ClientAggregate;
  /** An object relationship */
  configuration?: Maybe<MedspasMedspaconfiguration>;
  /** An array relationship */
  consentFormSignatures: Array<ConsentFormSignature>;
  /** An aggregate relationship */
  consentFormSignaturesAggregate: ConsentFormSignatureAggregate;
  /** An array relationship */
  consentForms: Array<ConsentForm>;
  /** An aggregate relationship */
  consentFormsAggregate: ConsentFormAggregate;
  contactEmail: Scalars["String"]["output"];
  contactPhone: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  devices: Array<ServiceDevice>;
  /** An aggregate relationship */
  devicesAggregate: ServiceDeviceAggregate;
  /** An array relationship */
  diagramTemplates: Array<DiagramTemplate>;
  /** An aggregate relationship */
  diagramTemplatesAggregate: DiagramTemplateAggregate;
  /** An array relationship */
  discounts: Array<Discount>;
  /** An aggregate relationship */
  discountsAggregate: DiscountAggregate;
  ein: Scalars["String"]["output"];
  /** An array relationship */
  fees: Array<Fee>;
  /** An aggregate relationship */
  feesAggregate: FeeAggregate;
  /** An array relationship */
  formSubmissions: Array<FormSubmission>;
  /** An aggregate relationship */
  formSubmissionsAggregate: FormSubmissionAggregate;
  /** An array relationship */
  forms: Array<Form>;
  /** An aggregate relationship */
  formsAggregate: FormAggregate;
  /** An array relationship */
  gfeReviewRequests: Array<GfeReviewRequest>;
  /** An aggregate relationship */
  gfeReviewRequestsAggregate: GfeReviewRequestAggregate;
  highLevelLocationId: Scalars["String"]["output"];
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoicesAggregate: InvoiceAggregate;
  isMso: Scalars["Boolean"]["output"];
  isTestMedspa: Scalars["Boolean"]["output"];
  /** Unique identifier for the medspa in LayerFi (third party accounting platform) */
  layerfiBusinessId: Scalars["String"]["output"];
  legalAddressId?: Maybe<Scalars["bigint"]["output"]>;
  legalBusinessName: Scalars["String"]["output"];
  legalRepresentativeEmail: Scalars["String"]["output"];
  legalRepresentativeFirstName: Scalars["String"]["output"];
  legalRepresentativeJobPosition: Scalars["String"]["output"];
  legalRepresentativeLastName: Scalars["String"]["output"];
  legalRepresentativePhoneNumber: Scalars["String"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  medicalDirectorSubscriptions: Array<MedicalDirectorSubscription>;
  /** An aggregate relationship */
  medicalDirectorSubscriptionsAggregate: MedicalDirectorSubscriptionAggregate;
  /** An array relationship */
  memberships: Array<Membership>;
  /** An aggregate relationship */
  membershipsAggregate: MembershipAggregate;
  metaPixelId: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  moxieTier: Scalars["String"]["output"];
  msoAdminId?: Maybe<Scalars["bigint"]["output"]>;
  name: Scalars["String"]["output"];
  /** An array relationship */
  noteTemplates: Array<NoteTemplate>;
  /** An aggregate relationship */
  noteTemplatesAggregate: NoteTemplateAggregate;
  onboardingManagerId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  packages: Array<Package>;
  /** An aggregate relationship */
  packagesAggregate: PackageAggregate;
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  productsAggregate: ProductAggregate;
  /** An object relationship */
  professionalCorporation?: Maybe<MedspasProfessionalcorporation>;
  professionalCorporationId?: Maybe<Scalars["bigint"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["bigint"]["output"]>;
  qualiphyApiKey: Scalars["bytea"]["output"];
  /** An array relationship */
  rooms: Array<ResourcesMedspaRoom>;
  /** An aggregate relationship */
  roomsAggregate: ResourcesMedspaRoomAggregate;
  /** An array relationship */
  serviceCategories: Array<ServiceCategory>;
  /** An aggregate relationship */
  serviceCategoriesAggregate: ServiceCategoryAggregate;
  /** An array relationship */
  serviceMenuItems: Array<MedspaServiceMenuItem>;
  /** An aggregate relationship */
  serviceMenuItemsAggregate: MedspaServiceMenuItemAggregate;
  /** An array relationship */
  serviceOfferings: Array<MedspaServiceOffering>;
  /** An aggregate relationship */
  serviceOfferingsAggregate: MedspaServiceOfferingAggregate;
  /** An array relationship */
  serviceProductUsages: Array<ServiceProductUsage>;
  /** An aggregate relationship */
  serviceProductUsagesAggregate: ServiceProductUsageAggregate;
  /** An array relationship */
  serviceProducts: Array<MedspaServiceProduct>;
  /** An aggregate relationship */
  serviceProductsAggregate: MedspaServiceProductAggregate;
  slug: Scalars["String"]["output"];
  socialMediaProfileUrls: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  stripeAccountId?: Maybe<Scalars["String"]["output"]>;
  stripeAccountType: Scalars["String"]["output"];
  stripeFinancialAccountId?: Maybe<Scalars["String"]["output"]>;
  stripeLocationId?: Maybe<Scalars["String"]["output"]>;
  stripeOnboardingDone: Scalars["Boolean"]["output"];
  stripeRestricted: Scalars["Boolean"]["output"];
  suppliesAllerganId: Scalars["String"]["output"];
  suppliesCosmofranceId: Scalars["String"]["output"];
  suppliesCrownAestheticsId: Scalars["String"]["output"];
  suppliesJeuveauId: Scalars["String"]["output"];
  suppliesMerzId: Scalars["String"]["output"];
  suppliesMkid: Scalars["String"]["output"];
  suppliesProlleniumId: Scalars["String"]["output"];
  suppliesRevanceId: Scalars["String"]["output"];
  /** An object relationship */
  suppliesShippingAddress?: Maybe<Address>;
  suppliesShippingAddressId?: Maybe<Scalars["bigint"]["output"]>;
  suppliesStriveOfficeUseId: Scalars["String"]["output"];
  timezone: Scalars["String"]["output"];
  twilioA2pSinkSid: Scalars["String"]["output"];
  twilioA2pSubscriptionSid: Scalars["String"]["output"];
  twilioAddressSid: Scalars["String"]["output"];
  twilioApiKeySecret: Scalars["bytea"]["output"];
  twilioApiKeySid: Scalars["String"]["output"];
  twilioAuthRepSid: Scalars["String"]["output"];
  twilioBrandRegistrationFailureReason: Scalars["String"]["output"];
  twilioBrandRegistrationSid: Scalars["String"]["output"];
  twilioBrandRegistrationStatus: Scalars["String"]["output"];
  twilioBusinessInformationSid: Scalars["String"]["output"];
  twilioConversationMigratedToSubaccount: Scalars["Boolean"]["output"];
  twilioConversationsServiceSid: Scalars["String"]["output"];
  twilioConversationsUser: Scalars["String"]["output"];
  twilioCustomerProfileSid: Scalars["String"]["output"];
  twilioCustomerProfileStatus: Scalars["String"]["output"];
  twilioEndUserSid: Scalars["String"]["output"];
  twilioHighlevelCampaignFailureReason: Scalars["String"]["output"];
  twilioHighlevelCampaignSid: Scalars["String"]["output"];
  twilioHighlevelCampaignStatus: Scalars["String"]["output"];
  twilioHighlevelNumber: Scalars["String"]["output"];
  twilioHighlevelServiceSid: Scalars["String"]["output"];
  twilioMsCampaignFailureReason: Scalars["String"]["output"];
  twilioMsCampaignSid: Scalars["String"]["output"];
  twilioMsCampaignStatus: Scalars["String"]["output"];
  twilioMsServiceSid: Scalars["String"]["output"];
  twilioNumber: Scalars["String"]["output"];
  twilioNumberSid: Scalars["String"]["output"];
  twilioSubaccountSid: Scalars["String"]["output"];
  twilioSupportingDocumentSid: Scalars["String"]["output"];
  twilioTollfreeHighlevelNumber: Scalars["String"]["output"];
  twilioTollfreeHighlevelNumberSid: Scalars["String"]["output"];
  twilioTollfreeHighlevelNumberVerificationSid: Scalars["String"]["output"];
  twilioTollfreeHighlevelNumberVerificationStatus: Scalars["String"]["output"];
  twilioTollfreeNumber: Scalars["String"]["output"];
  twilioTollfreeNumberSid: Scalars["String"]["output"];
  twilioTollfreeNumberVerificationSid: Scalars["String"]["output"];
  twilioTollfreeNumberVerificationStatus: Scalars["String"]["output"];
  twilioTrustProductSid: Scalars["String"]["output"];
  twilioTrustProductStatus: Scalars["String"]["output"];
  /** An array relationship */
  userMedspas: Array<UserMedspa>;
  /** An aggregate relationship */
  userMedspasAggregate: UserMedspaAggregate;
  /** An array relationship */
  visits: Array<Visit>;
  /** An aggregate relationship */
  visitsAggregate: VisitAggregate;
  /** An array relationship */
  walletCreditTypes: Array<MedspaWalletCreditType>;
  /** An aggregate relationship */
  walletCreditTypesAggregate: MedspaWalletCreditTypeAggregate;
  websiteUrl: Scalars["String"]["output"];
};

/** columns and relationships of "medspas_medspa" */
export type MedspaCareFormsArgs = {
  distinctOn?: InputMaybe<Array<CareFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormOrderBy>>;
  where?: InputMaybe<CareFormBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaCareFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CareFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormOrderBy>>;
  where?: InputMaybe<CareFormBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaClientsArgs = {
  distinctOn?: InputMaybe<Array<ClientSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientOrderBy>>;
  where?: InputMaybe<ClientBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaClientsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientOrderBy>>;
  where?: InputMaybe<ClientBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaConsentFormSignaturesArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaConsentFormSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaConsentFormsArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormOrderBy>>;
  where?: InputMaybe<ConsentFormBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaConsentFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormOrderBy>>;
  where?: InputMaybe<ConsentFormBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaDevicesArgs = {
  distinctOn?: InputMaybe<Array<ServiceDeviceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDeviceOrderBy>>;
  where?: InputMaybe<ServiceDeviceBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaDevicesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDeviceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDeviceOrderBy>>;
  where?: InputMaybe<ServiceDeviceBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaDiagramTemplatesArgs = {
  distinctOn?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramTemplateOrderBy>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaDiagramTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramTemplateOrderBy>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaDiscountsArgs = {
  distinctOn?: InputMaybe<Array<DiscountSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountOrderBy>>;
  where?: InputMaybe<DiscountBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaDiscountsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountOrderBy>>;
  where?: InputMaybe<DiscountBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaFeesArgs = {
  distinctOn?: InputMaybe<Array<FeeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeOrderBy>>;
  where?: InputMaybe<FeeBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaFeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeOrderBy>>;
  where?: InputMaybe<FeeBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaFormSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaFormSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaFormsArgs = {
  distinctOn?: InputMaybe<Array<FormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormOrderBy>>;
  where?: InputMaybe<FormBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaFormsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormOrderBy>>;
  where?: InputMaybe<FormBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaGfeReviewRequestsArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaGfeReviewRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaInvoicesArgs = {
  distinctOn?: InputMaybe<Array<InvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceOrderBy>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaInvoicesAggregateArgs = {
  distinctOn?: InputMaybe<Array<InvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceOrderBy>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaMedicalDirectorSubscriptionsArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorSubscriptionOrderBy>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaMedicalDirectorSubscriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorSubscriptionOrderBy>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaMembershipsArgs = {
  distinctOn?: InputMaybe<Array<MembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipOrderBy>>;
  where?: InputMaybe<MembershipBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaMembershipsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipOrderBy>>;
  where?: InputMaybe<MembershipBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaNoteTemplatesArgs = {
  distinctOn?: InputMaybe<Array<NoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteTemplateOrderBy>>;
  where?: InputMaybe<NoteTemplateBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaNoteTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteTemplateOrderBy>>;
  where?: InputMaybe<NoteTemplateBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaPackagesArgs = {
  distinctOn?: InputMaybe<Array<PackageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageOrderBy>>;
  where?: InputMaybe<PackageBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaPackagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageOrderBy>>;
  where?: InputMaybe<PackageBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaProductsArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaRoomsArgs = {
  distinctOn?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ResourcesMedspaRoomOrderBy>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaRoomsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ResourcesMedspaRoomOrderBy>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceCategoriesArgs = {
  distinctOn?: InputMaybe<Array<ServiceCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceCategoryOrderBy>>;
  where?: InputMaybe<ServiceCategoryBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceCategoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceCategoryOrderBy>>;
  where?: InputMaybe<ServiceCategoryBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceOfferingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceProductUsagesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductUsageOrderBy>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceProductUsagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductUsageOrderBy>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaUserMedspasArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaOrderBy>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaUserMedspasAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaOrderBy>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaVisitsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaWalletCreditTypesArgs = {
  distinctOn?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaWalletCreditTypeOrderBy>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

/** columns and relationships of "medspas_medspa" */
export type MedspaWalletCreditTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaWalletCreditTypeOrderBy>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

/** aggregated selection of "medspas_medspa" */
export type MedspaAggregate = {
  __typename?: "medspaAggregate";
  aggregate?: Maybe<MedspaAggregateFields>;
  nodes: Array<Medspa>;
};

export type MedspaAggregateBoolExp = {
  bool_and?: InputMaybe<MedspaAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<MedspaAggregateBoolExpBool_Or>;
  count?: InputMaybe<MedspaAggregateBoolExpCount>;
};

export type MedspaAggregateBoolExpBool_And = {
  arguments: MedspaSelectColumnMedspaAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspaAggregateBoolExpBool_Or = {
  arguments: MedspaSelectColumnMedspaAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspaAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_medspa" */
export type MedspaAggregateFields = {
  __typename?: "medspaAggregateFields";
  avg?: Maybe<MedspaAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<MedspaMaxFields>;
  min?: Maybe<MedspaMinFields>;
  stddev?: Maybe<MedspaStddevFields>;
  stddevPop?: Maybe<MedspaStddevPopFields>;
  stddevSamp?: Maybe<MedspaStddevSampFields>;
  sum?: Maybe<MedspaSumFields>;
  varPop?: Maybe<MedspaVarPopFields>;
  varSamp?: Maybe<MedspaVarSampFields>;
  variance?: Maybe<MedspaVarianceFields>;
};

/** aggregate fields of "medspas_medspa" */
export type MedspaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MedspaSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_medspa" */
export type MedspaAggregateOrderBy = {
  avg?: InputMaybe<MedspaAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MedspaMaxOrderBy>;
  min?: InputMaybe<MedspaMinOrderBy>;
  stddev?: InputMaybe<MedspaStddevOrderBy>;
  stddevPop?: InputMaybe<MedspaStddevPopOrderBy>;
  stddevSamp?: InputMaybe<MedspaStddevSampOrderBy>;
  sum?: InputMaybe<MedspaSumOrderBy>;
  varPop?: InputMaybe<MedspaVarPopOrderBy>;
  varSamp?: InputMaybe<MedspaVarSampOrderBy>;
  variance?: InputMaybe<MedspaVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_medspa" */
export type MedspaArrRelInsertInput = {
  data: Array<MedspaInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaOnConflict>;
};

/** aggregate avg on columns */
export type MedspaAvgFields = {
  __typename?: "medspaAvgFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["Float"]["output"]>;
  addressId?: Maybe<Scalars["Float"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  legalAddressId?: Maybe<Scalars["Float"]["output"]>;
  msoAdminId?: Maybe<Scalars["Float"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["Float"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_medspa" */
export type MedspaAvgOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_medspa". All fields are combined with a logical 'AND'. */
export type MedspaBoolExp = {
  _and?: InputMaybe<Array<MedspaBoolExp>>;
  _not?: InputMaybe<MedspaBoolExp>;
  _or?: InputMaybe<Array<MedspaBoolExp>>;
  additionalApplicationFeePercentage?: InputMaybe<NumericComparisonExp>;
  address?: InputMaybe<AddressBoolExp>;
  addressId?: InputMaybe<BigintComparisonExp>;
  administrativeDivision?: InputMaybe<AdministrativeDivisionBoolExp>;
  administrativeDivisionId?: InputMaybe<BigintComparisonExp>;
  businessType?: InputMaybe<StringComparisonExp>;
  careForms?: InputMaybe<CareFormBoolExp>;
  careFormsAggregate?: InputMaybe<CareFormAggregateBoolExp>;
  cherryApiKey?: InputMaybe<ByteaComparisonExp>;
  clients?: InputMaybe<ClientBoolExp>;
  clientsAggregate?: InputMaybe<ClientAggregateBoolExp>;
  configuration?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
  consentFormSignatures?: InputMaybe<ConsentFormSignatureBoolExp>;
  consentFormSignaturesAggregate?: InputMaybe<ConsentFormSignatureAggregateBoolExp>;
  consentForms?: InputMaybe<ConsentFormBoolExp>;
  consentFormsAggregate?: InputMaybe<ConsentFormAggregateBoolExp>;
  contactEmail?: InputMaybe<StringComparisonExp>;
  contactPhone?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  devices?: InputMaybe<ServiceDeviceBoolExp>;
  devicesAggregate?: InputMaybe<ServiceDeviceAggregateBoolExp>;
  diagramTemplates?: InputMaybe<DiagramTemplateBoolExp>;
  diagramTemplatesAggregate?: InputMaybe<DiagramTemplateAggregateBoolExp>;
  discounts?: InputMaybe<DiscountBoolExp>;
  discountsAggregate?: InputMaybe<DiscountAggregateBoolExp>;
  ein?: InputMaybe<StringComparisonExp>;
  fees?: InputMaybe<FeeBoolExp>;
  feesAggregate?: InputMaybe<FeeAggregateBoolExp>;
  formSubmissions?: InputMaybe<FormSubmissionBoolExp>;
  formSubmissionsAggregate?: InputMaybe<FormSubmissionAggregateBoolExp>;
  forms?: InputMaybe<FormBoolExp>;
  formsAggregate?: InputMaybe<FormAggregateBoolExp>;
  gfeReviewRequests?: InputMaybe<GfeReviewRequestBoolExp>;
  gfeReviewRequestsAggregate?: InputMaybe<GfeReviewRequestAggregateBoolExp>;
  highLevelLocationId?: InputMaybe<StringComparisonExp>;
  hubspotContactId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoices?: InputMaybe<InvoiceBoolExp>;
  invoicesAggregate?: InputMaybe<InvoiceAggregateBoolExp>;
  isMso?: InputMaybe<BooleanComparisonExp>;
  isTestMedspa?: InputMaybe<BooleanComparisonExp>;
  layerfiBusinessId?: InputMaybe<StringComparisonExp>;
  legalAddressId?: InputMaybe<BigintComparisonExp>;
  legalBusinessName?: InputMaybe<StringComparisonExp>;
  legalRepresentativeEmail?: InputMaybe<StringComparisonExp>;
  legalRepresentativeFirstName?: InputMaybe<StringComparisonExp>;
  legalRepresentativeJobPosition?: InputMaybe<StringComparisonExp>;
  legalRepresentativeLastName?: InputMaybe<StringComparisonExp>;
  legalRepresentativePhoneNumber?: InputMaybe<StringComparisonExp>;
  logo?: InputMaybe<StringComparisonExp>;
  medicalDirectorSubscriptions?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
  medicalDirectorSubscriptionsAggregate?: InputMaybe<MedicalDirectorSubscriptionAggregateBoolExp>;
  memberships?: InputMaybe<MembershipBoolExp>;
  membershipsAggregate?: InputMaybe<MembershipAggregateBoolExp>;
  metaPixelId?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  moxieTier?: InputMaybe<StringComparisonExp>;
  msoAdminId?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  noteTemplates?: InputMaybe<NoteTemplateBoolExp>;
  noteTemplatesAggregate?: InputMaybe<NoteTemplateAggregateBoolExp>;
  onboardingManagerId?: InputMaybe<BigintComparisonExp>;
  packages?: InputMaybe<PackageBoolExp>;
  packagesAggregate?: InputMaybe<PackageAggregateBoolExp>;
  products?: InputMaybe<ProductBoolExp>;
  productsAggregate?: InputMaybe<ProductAggregateBoolExp>;
  professionalCorporation?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
  professionalCorporationId?: InputMaybe<BigintComparisonExp>;
  providerSuccessManagerId?: InputMaybe<BigintComparisonExp>;
  qualiphyApiKey?: InputMaybe<ByteaComparisonExp>;
  rooms?: InputMaybe<ResourcesMedspaRoomBoolExp>;
  roomsAggregate?: InputMaybe<ResourcesMedspaRoomAggregateBoolExp>;
  serviceCategories?: InputMaybe<ServiceCategoryBoolExp>;
  serviceCategoriesAggregate?: InputMaybe<ServiceCategoryAggregateBoolExp>;
  serviceMenuItems?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  serviceMenuItemsAggregate?: InputMaybe<MedspaServiceMenuItemAggregateBoolExp>;
  serviceOfferings?: InputMaybe<MedspaServiceOfferingBoolExp>;
  serviceOfferingsAggregate?: InputMaybe<MedspaServiceOfferingAggregateBoolExp>;
  serviceProductUsages?: InputMaybe<ServiceProductUsageBoolExp>;
  serviceProductUsagesAggregate?: InputMaybe<ServiceProductUsageAggregateBoolExp>;
  serviceProducts?: InputMaybe<MedspaServiceProductBoolExp>;
  serviceProductsAggregate?: InputMaybe<MedspaServiceProductAggregateBoolExp>;
  slug?: InputMaybe<StringComparisonExp>;
  socialMediaProfileUrls?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  stripeAccountId?: InputMaybe<StringComparisonExp>;
  stripeAccountType?: InputMaybe<StringComparisonExp>;
  stripeFinancialAccountId?: InputMaybe<StringComparisonExp>;
  stripeLocationId?: InputMaybe<StringComparisonExp>;
  stripeOnboardingDone?: InputMaybe<BooleanComparisonExp>;
  stripeRestricted?: InputMaybe<BooleanComparisonExp>;
  suppliesAllerganId?: InputMaybe<StringComparisonExp>;
  suppliesCosmofranceId?: InputMaybe<StringComparisonExp>;
  suppliesCrownAestheticsId?: InputMaybe<StringComparisonExp>;
  suppliesJeuveauId?: InputMaybe<StringComparisonExp>;
  suppliesMerzId?: InputMaybe<StringComparisonExp>;
  suppliesMkid?: InputMaybe<StringComparisonExp>;
  suppliesProlleniumId?: InputMaybe<StringComparisonExp>;
  suppliesRevanceId?: InputMaybe<StringComparisonExp>;
  suppliesShippingAddress?: InputMaybe<AddressBoolExp>;
  suppliesShippingAddressId?: InputMaybe<BigintComparisonExp>;
  suppliesStriveOfficeUseId?: InputMaybe<StringComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  twilioA2pSinkSid?: InputMaybe<StringComparisonExp>;
  twilioA2pSubscriptionSid?: InputMaybe<StringComparisonExp>;
  twilioAddressSid?: InputMaybe<StringComparisonExp>;
  twilioApiKeySecret?: InputMaybe<ByteaComparisonExp>;
  twilioApiKeySid?: InputMaybe<StringComparisonExp>;
  twilioAuthRepSid?: InputMaybe<StringComparisonExp>;
  twilioBrandRegistrationFailureReason?: InputMaybe<StringComparisonExp>;
  twilioBrandRegistrationSid?: InputMaybe<StringComparisonExp>;
  twilioBrandRegistrationStatus?: InputMaybe<StringComparisonExp>;
  twilioBusinessInformationSid?: InputMaybe<StringComparisonExp>;
  twilioConversationMigratedToSubaccount?: InputMaybe<BooleanComparisonExp>;
  twilioConversationsServiceSid?: InputMaybe<StringComparisonExp>;
  twilioConversationsUser?: InputMaybe<StringComparisonExp>;
  twilioCustomerProfileSid?: InputMaybe<StringComparisonExp>;
  twilioCustomerProfileStatus?: InputMaybe<StringComparisonExp>;
  twilioEndUserSid?: InputMaybe<StringComparisonExp>;
  twilioHighlevelCampaignFailureReason?: InputMaybe<StringComparisonExp>;
  twilioHighlevelCampaignSid?: InputMaybe<StringComparisonExp>;
  twilioHighlevelCampaignStatus?: InputMaybe<StringComparisonExp>;
  twilioHighlevelNumber?: InputMaybe<StringComparisonExp>;
  twilioHighlevelServiceSid?: InputMaybe<StringComparisonExp>;
  twilioMsCampaignFailureReason?: InputMaybe<StringComparisonExp>;
  twilioMsCampaignSid?: InputMaybe<StringComparisonExp>;
  twilioMsCampaignStatus?: InputMaybe<StringComparisonExp>;
  twilioMsServiceSid?: InputMaybe<StringComparisonExp>;
  twilioNumber?: InputMaybe<StringComparisonExp>;
  twilioNumberSid?: InputMaybe<StringComparisonExp>;
  twilioSubaccountSid?: InputMaybe<StringComparisonExp>;
  twilioSupportingDocumentSid?: InputMaybe<StringComparisonExp>;
  twilioTollfreeHighlevelNumber?: InputMaybe<StringComparisonExp>;
  twilioTollfreeHighlevelNumberSid?: InputMaybe<StringComparisonExp>;
  twilioTollfreeHighlevelNumberVerificationSid?: InputMaybe<StringComparisonExp>;
  twilioTollfreeHighlevelNumberVerificationStatus?: InputMaybe<StringComparisonExp>;
  twilioTollfreeNumber?: InputMaybe<StringComparisonExp>;
  twilioTollfreeNumberSid?: InputMaybe<StringComparisonExp>;
  twilioTollfreeNumberVerificationSid?: InputMaybe<StringComparisonExp>;
  twilioTollfreeNumberVerificationStatus?: InputMaybe<StringComparisonExp>;
  twilioTrustProductSid?: InputMaybe<StringComparisonExp>;
  twilioTrustProductStatus?: InputMaybe<StringComparisonExp>;
  userMedspas?: InputMaybe<UserMedspaBoolExp>;
  userMedspasAggregate?: InputMaybe<UserMedspaAggregateBoolExp>;
  visits?: InputMaybe<VisitBoolExp>;
  visitsAggregate?: InputMaybe<VisitAggregateBoolExp>;
  walletCreditTypes?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
  walletCreditTypesAggregate?: InputMaybe<MedspaWalletCreditTypeAggregateBoolExp>;
  websiteUrl?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "medspas_medspa" */
export enum MedspaConstraint {
  /** unique or primary key constraint on columns "address_id" */
  MedspasMedspaAddressIdD41e6bc7Uniq = "medspas_medspa_address_id_d41e6bc7_uniq",
  /** unique or primary key constraint on columns "legal_address_id" */
  MedspasMedspaLegalAddressIdKey = "medspas_medspa_legal_address_id_key",
  /** unique or primary key constraint on columns "id" */
  MedspasMedspaPkey = "medspas_medspa_pkey",
  /** unique or primary key constraint on columns "slug" */
  MedspasMedspaSlugEbeabf42Uniq = "medspas_medspa_slug_ebeabf42_uniq",
  /** unique or primary key constraint on columns "supplies_shipping_address_id" */
  MedspasMedspaSuppliesShippingAddressIdKey = "medspas_medspa_supplies_shipping_address_id_key",
}

/** input type for incrementing numeric columns in table "medspas_medspa" */
export type MedspaIncInput = {
  additionalApplicationFeePercentage?: InputMaybe<Scalars["numeric"]["input"]>;
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  legalAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  msoAdminId?: InputMaybe<Scalars["bigint"]["input"]>;
  onboardingManagerId?: InputMaybe<Scalars["bigint"]["input"]>;
  professionalCorporationId?: InputMaybe<Scalars["bigint"]["input"]>;
  providerSuccessManagerId?: InputMaybe<Scalars["bigint"]["input"]>;
  suppliesShippingAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_medspa" */
export type MedspaInsertInput = {
  additionalApplicationFeePercentage?: InputMaybe<Scalars["numeric"]["input"]>;
  address?: InputMaybe<AddressObjRelInsertInput>;
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  administrativeDivision?: InputMaybe<AdministrativeDivisionObjRelInsertInput>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<Scalars["bigint"]["input"]>;
  businessType?: InputMaybe<Scalars["String"]["input"]>;
  careForms?: InputMaybe<CareFormArrRelInsertInput>;
  cherryApiKey?: InputMaybe<Scalars["bytea"]["input"]>;
  clients?: InputMaybe<ClientArrRelInsertInput>;
  configuration?: InputMaybe<MedspasMedspaconfigurationObjRelInsertInput>;
  consentFormSignatures?: InputMaybe<ConsentFormSignatureArrRelInsertInput>;
  consentForms?: InputMaybe<ConsentFormArrRelInsertInput>;
  contactEmail?: InputMaybe<Scalars["String"]["input"]>;
  contactPhone?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  devices?: InputMaybe<ServiceDeviceArrRelInsertInput>;
  diagramTemplates?: InputMaybe<DiagramTemplateArrRelInsertInput>;
  discounts?: InputMaybe<DiscountArrRelInsertInput>;
  ein?: InputMaybe<Scalars["String"]["input"]>;
  fees?: InputMaybe<FeeArrRelInsertInput>;
  formSubmissions?: InputMaybe<FormSubmissionArrRelInsertInput>;
  forms?: InputMaybe<FormArrRelInsertInput>;
  gfeReviewRequests?: InputMaybe<GfeReviewRequestArrRelInsertInput>;
  highLevelLocationId?: InputMaybe<Scalars["String"]["input"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoices?: InputMaybe<InvoiceArrRelInsertInput>;
  isMso?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTestMedspa?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Unique identifier for the medspa in LayerFi (third party accounting platform) */
  layerfiBusinessId?: InputMaybe<Scalars["String"]["input"]>;
  legalAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  legalBusinessName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeEmail?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeFirstName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeJobPosition?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeLastName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  medicalDirectorSubscriptions?: InputMaybe<MedicalDirectorSubscriptionArrRelInsertInput>;
  memberships?: InputMaybe<MembershipArrRelInsertInput>;
  metaPixelId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  moxieTier?: InputMaybe<Scalars["String"]["input"]>;
  msoAdminId?: InputMaybe<Scalars["bigint"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  noteTemplates?: InputMaybe<NoteTemplateArrRelInsertInput>;
  onboardingManagerId?: InputMaybe<Scalars["bigint"]["input"]>;
  packages?: InputMaybe<PackageArrRelInsertInput>;
  products?: InputMaybe<ProductArrRelInsertInput>;
  professionalCorporation?: InputMaybe<MedspasProfessionalcorporationObjRelInsertInput>;
  professionalCorporationId?: InputMaybe<Scalars["bigint"]["input"]>;
  providerSuccessManagerId?: InputMaybe<Scalars["bigint"]["input"]>;
  qualiphyApiKey?: InputMaybe<Scalars["bytea"]["input"]>;
  rooms?: InputMaybe<ResourcesMedspaRoomArrRelInsertInput>;
  serviceCategories?: InputMaybe<ServiceCategoryArrRelInsertInput>;
  serviceMenuItems?: InputMaybe<MedspaServiceMenuItemArrRelInsertInput>;
  serviceOfferings?: InputMaybe<MedspaServiceOfferingArrRelInsertInput>;
  serviceProductUsages?: InputMaybe<ServiceProductUsageArrRelInsertInput>;
  serviceProducts?: InputMaybe<MedspaServiceProductArrRelInsertInput>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  socialMediaProfileUrls?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountType?: InputMaybe<Scalars["String"]["input"]>;
  stripeFinancialAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeLocationId?: InputMaybe<Scalars["String"]["input"]>;
  stripeOnboardingDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  stripeRestricted?: InputMaybe<Scalars["Boolean"]["input"]>;
  suppliesAllerganId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesCosmofranceId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesCrownAestheticsId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesJeuveauId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesMerzId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesMkid?: InputMaybe<Scalars["String"]["input"]>;
  suppliesProlleniumId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesRevanceId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesShippingAddress?: InputMaybe<AddressObjRelInsertInput>;
  suppliesShippingAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  suppliesStriveOfficeUseId?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  twilioA2pSinkSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioA2pSubscriptionSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioAddressSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioApiKeySecret?: InputMaybe<Scalars["bytea"]["input"]>;
  twilioApiKeySid?: InputMaybe<Scalars["String"]["input"]>;
  twilioAuthRepSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioBusinessInformationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationMigratedToSubaccount?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  twilioConversationsServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationsUser?: InputMaybe<Scalars["String"]["input"]>;
  twilioCustomerProfileSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioCustomerProfileStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioEndUserSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioSubaccountSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioSupportingDocumentSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumberVerificationSid?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTollfreeHighlevelNumberVerificationStatus?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTollfreeNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberVerificationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberVerificationStatus?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTrustProductSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTrustProductStatus?: InputMaybe<Scalars["String"]["input"]>;
  userMedspas?: InputMaybe<UserMedspaArrRelInsertInput>;
  visits?: InputMaybe<VisitArrRelInsertInput>;
  walletCreditTypes?: InputMaybe<MedspaWalletCreditTypeArrRelInsertInput>;
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type MedspaMaxFields = {
  __typename?: "medspaMaxFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["numeric"]["output"]>;
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["bigint"]["output"]>;
  businessType?: Maybe<Scalars["String"]["output"]>;
  contactEmail?: Maybe<Scalars["String"]["output"]>;
  contactPhone?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  ein?: Maybe<Scalars["String"]["output"]>;
  highLevelLocationId?: Maybe<Scalars["String"]["output"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Unique identifier for the medspa in LayerFi (third party accounting platform) */
  layerfiBusinessId?: Maybe<Scalars["String"]["output"]>;
  legalAddressId?: Maybe<Scalars["bigint"]["output"]>;
  legalBusinessName?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativeEmail?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativeFirstName?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativeJobPosition?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativeLastName?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativePhoneNumber?: Maybe<Scalars["String"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  metaPixelId?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  moxieTier?: Maybe<Scalars["String"]["output"]>;
  msoAdminId?: Maybe<Scalars["bigint"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["bigint"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["bigint"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["bigint"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  socialMediaProfileUrls?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  stripeAccountId?: Maybe<Scalars["String"]["output"]>;
  stripeAccountType?: Maybe<Scalars["String"]["output"]>;
  stripeFinancialAccountId?: Maybe<Scalars["String"]["output"]>;
  stripeLocationId?: Maybe<Scalars["String"]["output"]>;
  suppliesAllerganId?: Maybe<Scalars["String"]["output"]>;
  suppliesCosmofranceId?: Maybe<Scalars["String"]["output"]>;
  suppliesCrownAestheticsId?: Maybe<Scalars["String"]["output"]>;
  suppliesJeuveauId?: Maybe<Scalars["String"]["output"]>;
  suppliesMerzId?: Maybe<Scalars["String"]["output"]>;
  suppliesMkid?: Maybe<Scalars["String"]["output"]>;
  suppliesProlleniumId?: Maybe<Scalars["String"]["output"]>;
  suppliesRevanceId?: Maybe<Scalars["String"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["bigint"]["output"]>;
  suppliesStriveOfficeUseId?: Maybe<Scalars["String"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  twilioA2pSinkSid?: Maybe<Scalars["String"]["output"]>;
  twilioA2pSubscriptionSid?: Maybe<Scalars["String"]["output"]>;
  twilioAddressSid?: Maybe<Scalars["String"]["output"]>;
  twilioApiKeySid?: Maybe<Scalars["String"]["output"]>;
  twilioAuthRepSid?: Maybe<Scalars["String"]["output"]>;
  twilioBrandRegistrationFailureReason?: Maybe<Scalars["String"]["output"]>;
  twilioBrandRegistrationSid?: Maybe<Scalars["String"]["output"]>;
  twilioBrandRegistrationStatus?: Maybe<Scalars["String"]["output"]>;
  twilioBusinessInformationSid?: Maybe<Scalars["String"]["output"]>;
  twilioConversationsServiceSid?: Maybe<Scalars["String"]["output"]>;
  twilioConversationsUser?: Maybe<Scalars["String"]["output"]>;
  twilioCustomerProfileSid?: Maybe<Scalars["String"]["output"]>;
  twilioCustomerProfileStatus?: Maybe<Scalars["String"]["output"]>;
  twilioEndUserSid?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelCampaignFailureReason?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelCampaignSid?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelCampaignStatus?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelNumber?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelServiceSid?: Maybe<Scalars["String"]["output"]>;
  twilioMsCampaignFailureReason?: Maybe<Scalars["String"]["output"]>;
  twilioMsCampaignSid?: Maybe<Scalars["String"]["output"]>;
  twilioMsCampaignStatus?: Maybe<Scalars["String"]["output"]>;
  twilioMsServiceSid?: Maybe<Scalars["String"]["output"]>;
  twilioNumber?: Maybe<Scalars["String"]["output"]>;
  twilioNumberSid?: Maybe<Scalars["String"]["output"]>;
  twilioSubaccountSid?: Maybe<Scalars["String"]["output"]>;
  twilioSupportingDocumentSid?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeHighlevelNumber?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeHighlevelNumberSid?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeHighlevelNumberVerificationSid?: Maybe<
    Scalars["String"]["output"]
  >;
  twilioTollfreeHighlevelNumberVerificationStatus?: Maybe<
    Scalars["String"]["output"]
  >;
  twilioTollfreeNumber?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeNumberSid?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeNumberVerificationSid?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeNumberVerificationStatus?: Maybe<Scalars["String"]["output"]>;
  twilioTrustProductSid?: Maybe<Scalars["String"]["output"]>;
  twilioTrustProductStatus?: Maybe<Scalars["String"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "medspas_medspa" */
export type MedspaMaxOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  businessType?: InputMaybe<OrderBy>;
  contactEmail?: InputMaybe<OrderBy>;
  contactPhone?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  ein?: InputMaybe<OrderBy>;
  highLevelLocationId?: InputMaybe<OrderBy>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Unique identifier for the medspa in LayerFi (third party accounting platform) */
  layerfiBusinessId?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  legalBusinessName?: InputMaybe<OrderBy>;
  legalRepresentativeEmail?: InputMaybe<OrderBy>;
  legalRepresentativeFirstName?: InputMaybe<OrderBy>;
  legalRepresentativeJobPosition?: InputMaybe<OrderBy>;
  legalRepresentativeLastName?: InputMaybe<OrderBy>;
  legalRepresentativePhoneNumber?: InputMaybe<OrderBy>;
  logo?: InputMaybe<OrderBy>;
  metaPixelId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  moxieTier?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  socialMediaProfileUrls?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeAccountId?: InputMaybe<OrderBy>;
  stripeAccountType?: InputMaybe<OrderBy>;
  stripeFinancialAccountId?: InputMaybe<OrderBy>;
  stripeLocationId?: InputMaybe<OrderBy>;
  suppliesAllerganId?: InputMaybe<OrderBy>;
  suppliesCosmofranceId?: InputMaybe<OrderBy>;
  suppliesCrownAestheticsId?: InputMaybe<OrderBy>;
  suppliesJeuveauId?: InputMaybe<OrderBy>;
  suppliesMerzId?: InputMaybe<OrderBy>;
  suppliesMkid?: InputMaybe<OrderBy>;
  suppliesProlleniumId?: InputMaybe<OrderBy>;
  suppliesRevanceId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
  suppliesStriveOfficeUseId?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  twilioA2pSinkSid?: InputMaybe<OrderBy>;
  twilioA2pSubscriptionSid?: InputMaybe<OrderBy>;
  twilioAddressSid?: InputMaybe<OrderBy>;
  twilioApiKeySid?: InputMaybe<OrderBy>;
  twilioAuthRepSid?: InputMaybe<OrderBy>;
  twilioBrandRegistrationFailureReason?: InputMaybe<OrderBy>;
  twilioBrandRegistrationSid?: InputMaybe<OrderBy>;
  twilioBrandRegistrationStatus?: InputMaybe<OrderBy>;
  twilioBusinessInformationSid?: InputMaybe<OrderBy>;
  twilioConversationsServiceSid?: InputMaybe<OrderBy>;
  twilioConversationsUser?: InputMaybe<OrderBy>;
  twilioCustomerProfileSid?: InputMaybe<OrderBy>;
  twilioCustomerProfileStatus?: InputMaybe<OrderBy>;
  twilioEndUserSid?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignFailureReason?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignSid?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignStatus?: InputMaybe<OrderBy>;
  twilioHighlevelNumber?: InputMaybe<OrderBy>;
  twilioHighlevelServiceSid?: InputMaybe<OrderBy>;
  twilioMsCampaignFailureReason?: InputMaybe<OrderBy>;
  twilioMsCampaignSid?: InputMaybe<OrderBy>;
  twilioMsCampaignStatus?: InputMaybe<OrderBy>;
  twilioMsServiceSid?: InputMaybe<OrderBy>;
  twilioNumber?: InputMaybe<OrderBy>;
  twilioNumberSid?: InputMaybe<OrderBy>;
  twilioSubaccountSid?: InputMaybe<OrderBy>;
  twilioSupportingDocumentSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumber?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberVerificationSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberVerificationStatus?: InputMaybe<OrderBy>;
  twilioTollfreeNumber?: InputMaybe<OrderBy>;
  twilioTollfreeNumberSid?: InputMaybe<OrderBy>;
  twilioTollfreeNumberVerificationSid?: InputMaybe<OrderBy>;
  twilioTollfreeNumberVerificationStatus?: InputMaybe<OrderBy>;
  twilioTrustProductSid?: InputMaybe<OrderBy>;
  twilioTrustProductStatus?: InputMaybe<OrderBy>;
  websiteUrl?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MedspaMinFields = {
  __typename?: "medspaMinFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["numeric"]["output"]>;
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["bigint"]["output"]>;
  businessType?: Maybe<Scalars["String"]["output"]>;
  contactEmail?: Maybe<Scalars["String"]["output"]>;
  contactPhone?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  ein?: Maybe<Scalars["String"]["output"]>;
  highLevelLocationId?: Maybe<Scalars["String"]["output"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Unique identifier for the medspa in LayerFi (third party accounting platform) */
  layerfiBusinessId?: Maybe<Scalars["String"]["output"]>;
  legalAddressId?: Maybe<Scalars["bigint"]["output"]>;
  legalBusinessName?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativeEmail?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativeFirstName?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativeJobPosition?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativeLastName?: Maybe<Scalars["String"]["output"]>;
  legalRepresentativePhoneNumber?: Maybe<Scalars["String"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  metaPixelId?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  moxieTier?: Maybe<Scalars["String"]["output"]>;
  msoAdminId?: Maybe<Scalars["bigint"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["bigint"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["bigint"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["bigint"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  socialMediaProfileUrls?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  stripeAccountId?: Maybe<Scalars["String"]["output"]>;
  stripeAccountType?: Maybe<Scalars["String"]["output"]>;
  stripeFinancialAccountId?: Maybe<Scalars["String"]["output"]>;
  stripeLocationId?: Maybe<Scalars["String"]["output"]>;
  suppliesAllerganId?: Maybe<Scalars["String"]["output"]>;
  suppliesCosmofranceId?: Maybe<Scalars["String"]["output"]>;
  suppliesCrownAestheticsId?: Maybe<Scalars["String"]["output"]>;
  suppliesJeuveauId?: Maybe<Scalars["String"]["output"]>;
  suppliesMerzId?: Maybe<Scalars["String"]["output"]>;
  suppliesMkid?: Maybe<Scalars["String"]["output"]>;
  suppliesProlleniumId?: Maybe<Scalars["String"]["output"]>;
  suppliesRevanceId?: Maybe<Scalars["String"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["bigint"]["output"]>;
  suppliesStriveOfficeUseId?: Maybe<Scalars["String"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  twilioA2pSinkSid?: Maybe<Scalars["String"]["output"]>;
  twilioA2pSubscriptionSid?: Maybe<Scalars["String"]["output"]>;
  twilioAddressSid?: Maybe<Scalars["String"]["output"]>;
  twilioApiKeySid?: Maybe<Scalars["String"]["output"]>;
  twilioAuthRepSid?: Maybe<Scalars["String"]["output"]>;
  twilioBrandRegistrationFailureReason?: Maybe<Scalars["String"]["output"]>;
  twilioBrandRegistrationSid?: Maybe<Scalars["String"]["output"]>;
  twilioBrandRegistrationStatus?: Maybe<Scalars["String"]["output"]>;
  twilioBusinessInformationSid?: Maybe<Scalars["String"]["output"]>;
  twilioConversationsServiceSid?: Maybe<Scalars["String"]["output"]>;
  twilioConversationsUser?: Maybe<Scalars["String"]["output"]>;
  twilioCustomerProfileSid?: Maybe<Scalars["String"]["output"]>;
  twilioCustomerProfileStatus?: Maybe<Scalars["String"]["output"]>;
  twilioEndUserSid?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelCampaignFailureReason?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelCampaignSid?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelCampaignStatus?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelNumber?: Maybe<Scalars["String"]["output"]>;
  twilioHighlevelServiceSid?: Maybe<Scalars["String"]["output"]>;
  twilioMsCampaignFailureReason?: Maybe<Scalars["String"]["output"]>;
  twilioMsCampaignSid?: Maybe<Scalars["String"]["output"]>;
  twilioMsCampaignStatus?: Maybe<Scalars["String"]["output"]>;
  twilioMsServiceSid?: Maybe<Scalars["String"]["output"]>;
  twilioNumber?: Maybe<Scalars["String"]["output"]>;
  twilioNumberSid?: Maybe<Scalars["String"]["output"]>;
  twilioSubaccountSid?: Maybe<Scalars["String"]["output"]>;
  twilioSupportingDocumentSid?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeHighlevelNumber?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeHighlevelNumberSid?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeHighlevelNumberVerificationSid?: Maybe<
    Scalars["String"]["output"]
  >;
  twilioTollfreeHighlevelNumberVerificationStatus?: Maybe<
    Scalars["String"]["output"]
  >;
  twilioTollfreeNumber?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeNumberSid?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeNumberVerificationSid?: Maybe<Scalars["String"]["output"]>;
  twilioTollfreeNumberVerificationStatus?: Maybe<Scalars["String"]["output"]>;
  twilioTrustProductSid?: Maybe<Scalars["String"]["output"]>;
  twilioTrustProductStatus?: Maybe<Scalars["String"]["output"]>;
  websiteUrl?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "medspas_medspa" */
export type MedspaMinOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  businessType?: InputMaybe<OrderBy>;
  contactEmail?: InputMaybe<OrderBy>;
  contactPhone?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  ein?: InputMaybe<OrderBy>;
  highLevelLocationId?: InputMaybe<OrderBy>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Unique identifier for the medspa in LayerFi (third party accounting platform) */
  layerfiBusinessId?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  legalBusinessName?: InputMaybe<OrderBy>;
  legalRepresentativeEmail?: InputMaybe<OrderBy>;
  legalRepresentativeFirstName?: InputMaybe<OrderBy>;
  legalRepresentativeJobPosition?: InputMaybe<OrderBy>;
  legalRepresentativeLastName?: InputMaybe<OrderBy>;
  legalRepresentativePhoneNumber?: InputMaybe<OrderBy>;
  logo?: InputMaybe<OrderBy>;
  metaPixelId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  moxieTier?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  socialMediaProfileUrls?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeAccountId?: InputMaybe<OrderBy>;
  stripeAccountType?: InputMaybe<OrderBy>;
  stripeFinancialAccountId?: InputMaybe<OrderBy>;
  stripeLocationId?: InputMaybe<OrderBy>;
  suppliesAllerganId?: InputMaybe<OrderBy>;
  suppliesCosmofranceId?: InputMaybe<OrderBy>;
  suppliesCrownAestheticsId?: InputMaybe<OrderBy>;
  suppliesJeuveauId?: InputMaybe<OrderBy>;
  suppliesMerzId?: InputMaybe<OrderBy>;
  suppliesMkid?: InputMaybe<OrderBy>;
  suppliesProlleniumId?: InputMaybe<OrderBy>;
  suppliesRevanceId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
  suppliesStriveOfficeUseId?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  twilioA2pSinkSid?: InputMaybe<OrderBy>;
  twilioA2pSubscriptionSid?: InputMaybe<OrderBy>;
  twilioAddressSid?: InputMaybe<OrderBy>;
  twilioApiKeySid?: InputMaybe<OrderBy>;
  twilioAuthRepSid?: InputMaybe<OrderBy>;
  twilioBrandRegistrationFailureReason?: InputMaybe<OrderBy>;
  twilioBrandRegistrationSid?: InputMaybe<OrderBy>;
  twilioBrandRegistrationStatus?: InputMaybe<OrderBy>;
  twilioBusinessInformationSid?: InputMaybe<OrderBy>;
  twilioConversationsServiceSid?: InputMaybe<OrderBy>;
  twilioConversationsUser?: InputMaybe<OrderBy>;
  twilioCustomerProfileSid?: InputMaybe<OrderBy>;
  twilioCustomerProfileStatus?: InputMaybe<OrderBy>;
  twilioEndUserSid?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignFailureReason?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignSid?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignStatus?: InputMaybe<OrderBy>;
  twilioHighlevelNumber?: InputMaybe<OrderBy>;
  twilioHighlevelServiceSid?: InputMaybe<OrderBy>;
  twilioMsCampaignFailureReason?: InputMaybe<OrderBy>;
  twilioMsCampaignSid?: InputMaybe<OrderBy>;
  twilioMsCampaignStatus?: InputMaybe<OrderBy>;
  twilioMsServiceSid?: InputMaybe<OrderBy>;
  twilioNumber?: InputMaybe<OrderBy>;
  twilioNumberSid?: InputMaybe<OrderBy>;
  twilioSubaccountSid?: InputMaybe<OrderBy>;
  twilioSupportingDocumentSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumber?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberVerificationSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberVerificationStatus?: InputMaybe<OrderBy>;
  twilioTollfreeNumber?: InputMaybe<OrderBy>;
  twilioTollfreeNumberSid?: InputMaybe<OrderBy>;
  twilioTollfreeNumberVerificationSid?: InputMaybe<OrderBy>;
  twilioTollfreeNumberVerificationStatus?: InputMaybe<OrderBy>;
  twilioTrustProductSid?: InputMaybe<OrderBy>;
  twilioTrustProductStatus?: InputMaybe<OrderBy>;
  websiteUrl?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_medspa" */
export type MedspaMutationResponse = {
  __typename?: "medspaMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Medspa>;
};

/** input type for inserting object relation for remote table "medspas_medspa" */
export type MedspaObjRelInsertInput = {
  data: MedspaInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MedspaOnConflict>;
};

/** on_conflict condition type for table "medspas_medspa" */
export type MedspaOnConflict = {
  constraint: MedspaConstraint;
  updateColumns?: Array<MedspaUpdateColumn>;
  where?: InputMaybe<MedspaBoolExp>;
};

/** Ordering options when selecting data from "medspas_medspa". */
export type MedspaOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  address?: InputMaybe<AddressOrderBy>;
  addressId?: InputMaybe<OrderBy>;
  administrativeDivision?: InputMaybe<AdministrativeDivisionOrderBy>;
  administrativeDivisionId?: InputMaybe<OrderBy>;
  businessType?: InputMaybe<OrderBy>;
  careFormsAggregate?: InputMaybe<CareFormAggregateOrderBy>;
  cherryApiKey?: InputMaybe<OrderBy>;
  clientsAggregate?: InputMaybe<ClientAggregateOrderBy>;
  configuration?: InputMaybe<MedspasMedspaconfigurationOrderBy>;
  consentFormSignaturesAggregate?: InputMaybe<ConsentFormSignatureAggregateOrderBy>;
  consentFormsAggregate?: InputMaybe<ConsentFormAggregateOrderBy>;
  contactEmail?: InputMaybe<OrderBy>;
  contactPhone?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  devicesAggregate?: InputMaybe<ServiceDeviceAggregateOrderBy>;
  diagramTemplatesAggregate?: InputMaybe<DiagramTemplateAggregateOrderBy>;
  discountsAggregate?: InputMaybe<DiscountAggregateOrderBy>;
  ein?: InputMaybe<OrderBy>;
  feesAggregate?: InputMaybe<FeeAggregateOrderBy>;
  formSubmissionsAggregate?: InputMaybe<FormSubmissionAggregateOrderBy>;
  formsAggregate?: InputMaybe<FormAggregateOrderBy>;
  gfeReviewRequestsAggregate?: InputMaybe<GfeReviewRequestAggregateOrderBy>;
  highLevelLocationId?: InputMaybe<OrderBy>;
  hubspotContactId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoicesAggregate?: InputMaybe<InvoiceAggregateOrderBy>;
  isMso?: InputMaybe<OrderBy>;
  isTestMedspa?: InputMaybe<OrderBy>;
  layerfiBusinessId?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  legalBusinessName?: InputMaybe<OrderBy>;
  legalRepresentativeEmail?: InputMaybe<OrderBy>;
  legalRepresentativeFirstName?: InputMaybe<OrderBy>;
  legalRepresentativeJobPosition?: InputMaybe<OrderBy>;
  legalRepresentativeLastName?: InputMaybe<OrderBy>;
  legalRepresentativePhoneNumber?: InputMaybe<OrderBy>;
  logo?: InputMaybe<OrderBy>;
  medicalDirectorSubscriptionsAggregate?: InputMaybe<MedicalDirectorSubscriptionAggregateOrderBy>;
  membershipsAggregate?: InputMaybe<MembershipAggregateOrderBy>;
  metaPixelId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  moxieTier?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  noteTemplatesAggregate?: InputMaybe<NoteTemplateAggregateOrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  packagesAggregate?: InputMaybe<PackageAggregateOrderBy>;
  productsAggregate?: InputMaybe<ProductAggregateOrderBy>;
  professionalCorporation?: InputMaybe<MedspasProfessionalcorporationOrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  qualiphyApiKey?: InputMaybe<OrderBy>;
  roomsAggregate?: InputMaybe<ResourcesMedspaRoomAggregateOrderBy>;
  serviceCategoriesAggregate?: InputMaybe<ServiceCategoryAggregateOrderBy>;
  serviceMenuItemsAggregate?: InputMaybe<MedspaServiceMenuItemAggregateOrderBy>;
  serviceOfferingsAggregate?: InputMaybe<MedspaServiceOfferingAggregateOrderBy>;
  serviceProductUsagesAggregate?: InputMaybe<ServiceProductUsageAggregateOrderBy>;
  serviceProductsAggregate?: InputMaybe<MedspaServiceProductAggregateOrderBy>;
  slug?: InputMaybe<OrderBy>;
  socialMediaProfileUrls?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeAccountId?: InputMaybe<OrderBy>;
  stripeAccountType?: InputMaybe<OrderBy>;
  stripeFinancialAccountId?: InputMaybe<OrderBy>;
  stripeLocationId?: InputMaybe<OrderBy>;
  stripeOnboardingDone?: InputMaybe<OrderBy>;
  stripeRestricted?: InputMaybe<OrderBy>;
  suppliesAllerganId?: InputMaybe<OrderBy>;
  suppliesCosmofranceId?: InputMaybe<OrderBy>;
  suppliesCrownAestheticsId?: InputMaybe<OrderBy>;
  suppliesJeuveauId?: InputMaybe<OrderBy>;
  suppliesMerzId?: InputMaybe<OrderBy>;
  suppliesMkid?: InputMaybe<OrderBy>;
  suppliesProlleniumId?: InputMaybe<OrderBy>;
  suppliesRevanceId?: InputMaybe<OrderBy>;
  suppliesShippingAddress?: InputMaybe<AddressOrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
  suppliesStriveOfficeUseId?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  twilioA2pSinkSid?: InputMaybe<OrderBy>;
  twilioA2pSubscriptionSid?: InputMaybe<OrderBy>;
  twilioAddressSid?: InputMaybe<OrderBy>;
  twilioApiKeySecret?: InputMaybe<OrderBy>;
  twilioApiKeySid?: InputMaybe<OrderBy>;
  twilioAuthRepSid?: InputMaybe<OrderBy>;
  twilioBrandRegistrationFailureReason?: InputMaybe<OrderBy>;
  twilioBrandRegistrationSid?: InputMaybe<OrderBy>;
  twilioBrandRegistrationStatus?: InputMaybe<OrderBy>;
  twilioBusinessInformationSid?: InputMaybe<OrderBy>;
  twilioConversationMigratedToSubaccount?: InputMaybe<OrderBy>;
  twilioConversationsServiceSid?: InputMaybe<OrderBy>;
  twilioConversationsUser?: InputMaybe<OrderBy>;
  twilioCustomerProfileSid?: InputMaybe<OrderBy>;
  twilioCustomerProfileStatus?: InputMaybe<OrderBy>;
  twilioEndUserSid?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignFailureReason?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignSid?: InputMaybe<OrderBy>;
  twilioHighlevelCampaignStatus?: InputMaybe<OrderBy>;
  twilioHighlevelNumber?: InputMaybe<OrderBy>;
  twilioHighlevelServiceSid?: InputMaybe<OrderBy>;
  twilioMsCampaignFailureReason?: InputMaybe<OrderBy>;
  twilioMsCampaignSid?: InputMaybe<OrderBy>;
  twilioMsCampaignStatus?: InputMaybe<OrderBy>;
  twilioMsServiceSid?: InputMaybe<OrderBy>;
  twilioNumber?: InputMaybe<OrderBy>;
  twilioNumberSid?: InputMaybe<OrderBy>;
  twilioSubaccountSid?: InputMaybe<OrderBy>;
  twilioSupportingDocumentSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumber?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberVerificationSid?: InputMaybe<OrderBy>;
  twilioTollfreeHighlevelNumberVerificationStatus?: InputMaybe<OrderBy>;
  twilioTollfreeNumber?: InputMaybe<OrderBy>;
  twilioTollfreeNumberSid?: InputMaybe<OrderBy>;
  twilioTollfreeNumberVerificationSid?: InputMaybe<OrderBy>;
  twilioTollfreeNumberVerificationStatus?: InputMaybe<OrderBy>;
  twilioTrustProductSid?: InputMaybe<OrderBy>;
  twilioTrustProductStatus?: InputMaybe<OrderBy>;
  userMedspasAggregate?: InputMaybe<UserMedspaAggregateOrderBy>;
  visitsAggregate?: InputMaybe<VisitAggregateOrderBy>;
  walletCreditTypesAggregate?: InputMaybe<MedspaWalletCreditTypeAggregateOrderBy>;
  websiteUrl?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_medspa */
export type MedspaPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

export type MedspaRoomsAppointmentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
  predicate: IntComparisonExp;
};

/** select columns of table "medspas_medspa" */
export enum MedspaSelectColumn {
  /** column name */
  AdditionalApplicationFeePercentage = "additionalApplicationFeePercentage",
  /** column name */
  AddressId = "addressId",
  /** column name */
  AdministrativeDivisionId = "administrativeDivisionId",
  /** column name */
  BusinessType = "businessType",
  /** column name */
  CherryApiKey = "cherryApiKey",
  /** column name */
  ContactEmail = "contactEmail",
  /** column name */
  ContactPhone = "contactPhone",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Ein = "ein",
  /** column name */
  HighLevelLocationId = "highLevelLocationId",
  /** column name */
  HubspotContactId = "hubspotContactId",
  /** column name */
  Id = "id",
  /** column name */
  IsMso = "isMso",
  /** column name */
  IsTestMedspa = "isTestMedspa",
  /** column name */
  LayerfiBusinessId = "layerfiBusinessId",
  /** column name */
  LegalAddressId = "legalAddressId",
  /** column name */
  LegalBusinessName = "legalBusinessName",
  /** column name */
  LegalRepresentativeEmail = "legalRepresentativeEmail",
  /** column name */
  LegalRepresentativeFirstName = "legalRepresentativeFirstName",
  /** column name */
  LegalRepresentativeJobPosition = "legalRepresentativeJobPosition",
  /** column name */
  LegalRepresentativeLastName = "legalRepresentativeLastName",
  /** column name */
  LegalRepresentativePhoneNumber = "legalRepresentativePhoneNumber",
  /** column name */
  Logo = "logo",
  /** column name */
  MetaPixelId = "metaPixelId",
  /** column name */
  Modified = "modified",
  /** column name */
  MoxieTier = "moxieTier",
  /** column name */
  MsoAdminId = "msoAdminId",
  /** column name */
  Name = "name",
  /** column name */
  OnboardingManagerId = "onboardingManagerId",
  /** column name */
  ProfessionalCorporationId = "professionalCorporationId",
  /** column name */
  ProviderSuccessManagerId = "providerSuccessManagerId",
  /** column name */
  QualiphyApiKey = "qualiphyApiKey",
  /** column name */
  Slug = "slug",
  /** column name */
  SocialMediaProfileUrls = "socialMediaProfileUrls",
  /** column name */
  Status = "status",
  /** column name */
  StripeAccountId = "stripeAccountId",
  /** column name */
  StripeAccountType = "stripeAccountType",
  /** column name */
  StripeFinancialAccountId = "stripeFinancialAccountId",
  /** column name */
  StripeLocationId = "stripeLocationId",
  /** column name */
  StripeOnboardingDone = "stripeOnboardingDone",
  /** column name */
  StripeRestricted = "stripeRestricted",
  /** column name */
  SuppliesAllerganId = "suppliesAllerganId",
  /** column name */
  SuppliesCosmofranceId = "suppliesCosmofranceId",
  /** column name */
  SuppliesCrownAestheticsId = "suppliesCrownAestheticsId",
  /** column name */
  SuppliesJeuveauId = "suppliesJeuveauId",
  /** column name */
  SuppliesMerzId = "suppliesMerzId",
  /** column name */
  SuppliesMkid = "suppliesMkid",
  /** column name */
  SuppliesProlleniumId = "suppliesProlleniumId",
  /** column name */
  SuppliesRevanceId = "suppliesRevanceId",
  /** column name */
  SuppliesShippingAddressId = "suppliesShippingAddressId",
  /** column name */
  SuppliesStriveOfficeUseId = "suppliesStriveOfficeUseId",
  /** column name */
  Timezone = "timezone",
  /** column name */
  TwilioA2pSinkSid = "twilioA2pSinkSid",
  /** column name */
  TwilioA2pSubscriptionSid = "twilioA2pSubscriptionSid",
  /** column name */
  TwilioAddressSid = "twilioAddressSid",
  /** column name */
  TwilioApiKeySecret = "twilioApiKeySecret",
  /** column name */
  TwilioApiKeySid = "twilioApiKeySid",
  /** column name */
  TwilioAuthRepSid = "twilioAuthRepSid",
  /** column name */
  TwilioBrandRegistrationFailureReason = "twilioBrandRegistrationFailureReason",
  /** column name */
  TwilioBrandRegistrationSid = "twilioBrandRegistrationSid",
  /** column name */
  TwilioBrandRegistrationStatus = "twilioBrandRegistrationStatus",
  /** column name */
  TwilioBusinessInformationSid = "twilioBusinessInformationSid",
  /** column name */
  TwilioConversationMigratedToSubaccount = "twilioConversationMigratedToSubaccount",
  /** column name */
  TwilioConversationsServiceSid = "twilioConversationsServiceSid",
  /** column name */
  TwilioConversationsUser = "twilioConversationsUser",
  /** column name */
  TwilioCustomerProfileSid = "twilioCustomerProfileSid",
  /** column name */
  TwilioCustomerProfileStatus = "twilioCustomerProfileStatus",
  /** column name */
  TwilioEndUserSid = "twilioEndUserSid",
  /** column name */
  TwilioHighlevelCampaignFailureReason = "twilioHighlevelCampaignFailureReason",
  /** column name */
  TwilioHighlevelCampaignSid = "twilioHighlevelCampaignSid",
  /** column name */
  TwilioHighlevelCampaignStatus = "twilioHighlevelCampaignStatus",
  /** column name */
  TwilioHighlevelNumber = "twilioHighlevelNumber",
  /** column name */
  TwilioHighlevelServiceSid = "twilioHighlevelServiceSid",
  /** column name */
  TwilioMsCampaignFailureReason = "twilioMsCampaignFailureReason",
  /** column name */
  TwilioMsCampaignSid = "twilioMsCampaignSid",
  /** column name */
  TwilioMsCampaignStatus = "twilioMsCampaignStatus",
  /** column name */
  TwilioMsServiceSid = "twilioMsServiceSid",
  /** column name */
  TwilioNumber = "twilioNumber",
  /** column name */
  TwilioNumberSid = "twilioNumberSid",
  /** column name */
  TwilioSubaccountSid = "twilioSubaccountSid",
  /** column name */
  TwilioSupportingDocumentSid = "twilioSupportingDocumentSid",
  /** column name */
  TwilioTollfreeHighlevelNumber = "twilioTollfreeHighlevelNumber",
  /** column name */
  TwilioTollfreeHighlevelNumberSid = "twilioTollfreeHighlevelNumberSid",
  /** column name */
  TwilioTollfreeHighlevelNumberVerificationSid = "twilioTollfreeHighlevelNumberVerificationSid",
  /** column name */
  TwilioTollfreeHighlevelNumberVerificationStatus = "twilioTollfreeHighlevelNumberVerificationStatus",
  /** column name */
  TwilioTollfreeNumber = "twilioTollfreeNumber",
  /** column name */
  TwilioTollfreeNumberSid = "twilioTollfreeNumberSid",
  /** column name */
  TwilioTollfreeNumberVerificationSid = "twilioTollfreeNumberVerificationSid",
  /** column name */
  TwilioTollfreeNumberVerificationStatus = "twilioTollfreeNumberVerificationStatus",
  /** column name */
  TwilioTrustProductSid = "twilioTrustProductSid",
  /** column name */
  TwilioTrustProductStatus = "twilioTrustProductStatus",
  /** column name */
  WebsiteUrl = "websiteUrl",
}

/** select "medspaAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_medspa" */
export enum MedspaSelectColumnMedspaAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsMso = "isMso",
  /** column name */
  IsTestMedspa = "isTestMedspa",
  /** column name */
  StripeOnboardingDone = "stripeOnboardingDone",
  /** column name */
  StripeRestricted = "stripeRestricted",
  /** column name */
  TwilioConversationMigratedToSubaccount = "twilioConversationMigratedToSubaccount",
}

/** select "medspaAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_medspa" */
export enum MedspaSelectColumnMedspaAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsMso = "isMso",
  /** column name */
  IsTestMedspa = "isTestMedspa",
  /** column name */
  StripeOnboardingDone = "stripeOnboardingDone",
  /** column name */
  StripeRestricted = "stripeRestricted",
  /** column name */
  TwilioConversationMigratedToSubaccount = "twilioConversationMigratedToSubaccount",
}

export type MedspaServiceMenuItemAggregateBoolExpBool_And = {
  arguments: MedspaServiceMenuItemSelectColumnMedspaServiceMenuItemAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspaServiceMenuItemAggregateBoolExpBool_Or = {
  arguments: MedspaServiceMenuItemSelectColumnMedspaServiceMenuItemAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspaServiceMenuItemAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspaServiceMenuItemMedspaServiceOfferingsAggregateBoolExpCount = {
  arguments?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
  >;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspaServiceMenuItemOldProductsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspaServiceMenuItemProvidersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspaServiceOfferingAggregateBoolExpBool_And = {
  arguments: MedspaServiceOfferingSelectColumnMedspaServiceOfferingAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceOfferingBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspaServiceOfferingAggregateBoolExpBool_Or = {
  arguments: MedspaServiceOfferingSelectColumnMedspaServiceOfferingAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceOfferingBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspaServiceOfferingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceOfferingBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspaServiceOfferingNoteTemplatesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspaServiceOfferingNotesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaServiceOfferingNotesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspaServiceProductAggregateBoolExpBool_And = {
  arguments: MedspaServiceProductSelectColumnMedspaServiceProductAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceProductBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspaServiceProductAggregateBoolExpBool_Or = {
  arguments: MedspaServiceProductSelectColumnMedspaServiceProductAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceProductBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspaServiceProductAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceProductBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspaServiceProtocolTemplateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaServiceProtocolTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
  predicate: IntComparisonExp;
};

/** input type for updating data in table "medspas_medspa" */
export type MedspaSetInput = {
  additionalApplicationFeePercentage?: InputMaybe<Scalars["numeric"]["input"]>;
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<Scalars["bigint"]["input"]>;
  businessType?: InputMaybe<Scalars["String"]["input"]>;
  cherryApiKey?: InputMaybe<Scalars["bytea"]["input"]>;
  contactEmail?: InputMaybe<Scalars["String"]["input"]>;
  contactPhone?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  ein?: InputMaybe<Scalars["String"]["input"]>;
  highLevelLocationId?: InputMaybe<Scalars["String"]["input"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isMso?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTestMedspa?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Unique identifier for the medspa in LayerFi (third party accounting platform) */
  layerfiBusinessId?: InputMaybe<Scalars["String"]["input"]>;
  legalAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  legalBusinessName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeEmail?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeFirstName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeJobPosition?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeLastName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  metaPixelId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  moxieTier?: InputMaybe<Scalars["String"]["input"]>;
  msoAdminId?: InputMaybe<Scalars["bigint"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  onboardingManagerId?: InputMaybe<Scalars["bigint"]["input"]>;
  professionalCorporationId?: InputMaybe<Scalars["bigint"]["input"]>;
  providerSuccessManagerId?: InputMaybe<Scalars["bigint"]["input"]>;
  qualiphyApiKey?: InputMaybe<Scalars["bytea"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  socialMediaProfileUrls?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountType?: InputMaybe<Scalars["String"]["input"]>;
  stripeFinancialAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeLocationId?: InputMaybe<Scalars["String"]["input"]>;
  stripeOnboardingDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  stripeRestricted?: InputMaybe<Scalars["Boolean"]["input"]>;
  suppliesAllerganId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesCosmofranceId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesCrownAestheticsId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesJeuveauId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesMerzId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesMkid?: InputMaybe<Scalars["String"]["input"]>;
  suppliesProlleniumId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesRevanceId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesShippingAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  suppliesStriveOfficeUseId?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  twilioA2pSinkSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioA2pSubscriptionSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioAddressSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioApiKeySecret?: InputMaybe<Scalars["bytea"]["input"]>;
  twilioApiKeySid?: InputMaybe<Scalars["String"]["input"]>;
  twilioAuthRepSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioBusinessInformationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationMigratedToSubaccount?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  twilioConversationsServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationsUser?: InputMaybe<Scalars["String"]["input"]>;
  twilioCustomerProfileSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioCustomerProfileStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioEndUserSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioSubaccountSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioSupportingDocumentSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumberVerificationSid?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTollfreeHighlevelNumberVerificationStatus?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTollfreeNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberVerificationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberVerificationStatus?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTrustProductSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTrustProductStatus?: InputMaybe<Scalars["String"]["input"]>;
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MedspaStandingOrderTemplateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaStandingOrderTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate stddev on columns */
export type MedspaStddevFields = {
  __typename?: "medspaStddevFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["Float"]["output"]>;
  addressId?: Maybe<Scalars["Float"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  legalAddressId?: Maybe<Scalars["Float"]["output"]>;
  msoAdminId?: Maybe<Scalars["Float"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["Float"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_medspa" */
export type MedspaStddevOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MedspaStddevPopFields = {
  __typename?: "medspaStddevPopFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["Float"]["output"]>;
  addressId?: Maybe<Scalars["Float"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  legalAddressId?: Maybe<Scalars["Float"]["output"]>;
  msoAdminId?: Maybe<Scalars["Float"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["Float"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_medspa" */
export type MedspaStddevPopOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MedspaStddevSampFields = {
  __typename?: "medspaStddevSampFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["Float"]["output"]>;
  addressId?: Maybe<Scalars["Float"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  legalAddressId?: Maybe<Scalars["Float"]["output"]>;
  msoAdminId?: Maybe<Scalars["Float"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["Float"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_medspa" */
export type MedspaStddevSampOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "medspa" */
export type MedspaStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MedspaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MedspaStreamCursorValueInput = {
  additionalApplicationFeePercentage?: InputMaybe<Scalars["numeric"]["input"]>;
  addressId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<Scalars["bigint"]["input"]>;
  businessType?: InputMaybe<Scalars["String"]["input"]>;
  cherryApiKey?: InputMaybe<Scalars["bytea"]["input"]>;
  contactEmail?: InputMaybe<Scalars["String"]["input"]>;
  contactPhone?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  ein?: InputMaybe<Scalars["String"]["input"]>;
  highLevelLocationId?: InputMaybe<Scalars["String"]["input"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isMso?: InputMaybe<Scalars["Boolean"]["input"]>;
  isTestMedspa?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Unique identifier for the medspa in LayerFi (third party accounting platform) */
  layerfiBusinessId?: InputMaybe<Scalars["String"]["input"]>;
  legalAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  legalBusinessName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeEmail?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeFirstName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeJobPosition?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativeLastName?: InputMaybe<Scalars["String"]["input"]>;
  legalRepresentativePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  metaPixelId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  moxieTier?: InputMaybe<Scalars["String"]["input"]>;
  msoAdminId?: InputMaybe<Scalars["bigint"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  onboardingManagerId?: InputMaybe<Scalars["bigint"]["input"]>;
  professionalCorporationId?: InputMaybe<Scalars["bigint"]["input"]>;
  providerSuccessManagerId?: InputMaybe<Scalars["bigint"]["input"]>;
  qualiphyApiKey?: InputMaybe<Scalars["bytea"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  socialMediaProfileUrls?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountType?: InputMaybe<Scalars["String"]["input"]>;
  stripeFinancialAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeLocationId?: InputMaybe<Scalars["String"]["input"]>;
  stripeOnboardingDone?: InputMaybe<Scalars["Boolean"]["input"]>;
  stripeRestricted?: InputMaybe<Scalars["Boolean"]["input"]>;
  suppliesAllerganId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesCosmofranceId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesCrownAestheticsId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesJeuveauId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesMerzId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesMkid?: InputMaybe<Scalars["String"]["input"]>;
  suppliesProlleniumId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesRevanceId?: InputMaybe<Scalars["String"]["input"]>;
  suppliesShippingAddressId?: InputMaybe<Scalars["bigint"]["input"]>;
  suppliesStriveOfficeUseId?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  twilioA2pSinkSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioA2pSubscriptionSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioAddressSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioApiKeySecret?: InputMaybe<Scalars["bytea"]["input"]>;
  twilioApiKeySid?: InputMaybe<Scalars["String"]["input"]>;
  twilioAuthRepSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioBrandRegistrationStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioBusinessInformationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationMigratedToSubaccount?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  twilioConversationsServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioConversationsUser?: InputMaybe<Scalars["String"]["input"]>;
  twilioCustomerProfileSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioCustomerProfileStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioEndUserSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelCampaignStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioHighlevelServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignFailureReason?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsCampaignStatus?: InputMaybe<Scalars["String"]["input"]>;
  twilioMsServiceSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioSubaccountSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioSupportingDocumentSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeHighlevelNumberVerificationSid?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTollfreeHighlevelNumberVerificationStatus?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTollfreeNumber?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberVerificationSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTollfreeNumberVerificationStatus?: InputMaybe<
    Scalars["String"]["input"]
  >;
  twilioTrustProductSid?: InputMaybe<Scalars["String"]["input"]>;
  twilioTrustProductStatus?: InputMaybe<Scalars["String"]["input"]>;
  websiteUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type MedspaSumFields = {
  __typename?: "medspaSumFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["numeric"]["output"]>;
  addressId?: Maybe<Scalars["bigint"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  legalAddressId?: Maybe<Scalars["bigint"]["output"]>;
  msoAdminId?: Maybe<Scalars["bigint"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["bigint"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["bigint"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["bigint"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_medspa" */
export type MedspaSumOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_medspa" */
export enum MedspaUpdateColumn {
  /** column name */
  AdditionalApplicationFeePercentage = "additionalApplicationFeePercentage",
  /** column name */
  AddressId = "addressId",
  /** column name */
  AdministrativeDivisionId = "administrativeDivisionId",
  /** column name */
  BusinessType = "businessType",
  /** column name */
  CherryApiKey = "cherryApiKey",
  /** column name */
  ContactEmail = "contactEmail",
  /** column name */
  ContactPhone = "contactPhone",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Ein = "ein",
  /** column name */
  HighLevelLocationId = "highLevelLocationId",
  /** column name */
  HubspotContactId = "hubspotContactId",
  /** column name */
  Id = "id",
  /** column name */
  IsMso = "isMso",
  /** column name */
  IsTestMedspa = "isTestMedspa",
  /** column name */
  LayerfiBusinessId = "layerfiBusinessId",
  /** column name */
  LegalAddressId = "legalAddressId",
  /** column name */
  LegalBusinessName = "legalBusinessName",
  /** column name */
  LegalRepresentativeEmail = "legalRepresentativeEmail",
  /** column name */
  LegalRepresentativeFirstName = "legalRepresentativeFirstName",
  /** column name */
  LegalRepresentativeJobPosition = "legalRepresentativeJobPosition",
  /** column name */
  LegalRepresentativeLastName = "legalRepresentativeLastName",
  /** column name */
  LegalRepresentativePhoneNumber = "legalRepresentativePhoneNumber",
  /** column name */
  Logo = "logo",
  /** column name */
  MetaPixelId = "metaPixelId",
  /** column name */
  Modified = "modified",
  /** column name */
  MoxieTier = "moxieTier",
  /** column name */
  MsoAdminId = "msoAdminId",
  /** column name */
  Name = "name",
  /** column name */
  OnboardingManagerId = "onboardingManagerId",
  /** column name */
  ProfessionalCorporationId = "professionalCorporationId",
  /** column name */
  ProviderSuccessManagerId = "providerSuccessManagerId",
  /** column name */
  QualiphyApiKey = "qualiphyApiKey",
  /** column name */
  Slug = "slug",
  /** column name */
  SocialMediaProfileUrls = "socialMediaProfileUrls",
  /** column name */
  Status = "status",
  /** column name */
  StripeAccountId = "stripeAccountId",
  /** column name */
  StripeAccountType = "stripeAccountType",
  /** column name */
  StripeFinancialAccountId = "stripeFinancialAccountId",
  /** column name */
  StripeLocationId = "stripeLocationId",
  /** column name */
  StripeOnboardingDone = "stripeOnboardingDone",
  /** column name */
  StripeRestricted = "stripeRestricted",
  /** column name */
  SuppliesAllerganId = "suppliesAllerganId",
  /** column name */
  SuppliesCosmofranceId = "suppliesCosmofranceId",
  /** column name */
  SuppliesCrownAestheticsId = "suppliesCrownAestheticsId",
  /** column name */
  SuppliesJeuveauId = "suppliesJeuveauId",
  /** column name */
  SuppliesMerzId = "suppliesMerzId",
  /** column name */
  SuppliesMkid = "suppliesMkid",
  /** column name */
  SuppliesProlleniumId = "suppliesProlleniumId",
  /** column name */
  SuppliesRevanceId = "suppliesRevanceId",
  /** column name */
  SuppliesShippingAddressId = "suppliesShippingAddressId",
  /** column name */
  SuppliesStriveOfficeUseId = "suppliesStriveOfficeUseId",
  /** column name */
  Timezone = "timezone",
  /** column name */
  TwilioA2pSinkSid = "twilioA2pSinkSid",
  /** column name */
  TwilioA2pSubscriptionSid = "twilioA2pSubscriptionSid",
  /** column name */
  TwilioAddressSid = "twilioAddressSid",
  /** column name */
  TwilioApiKeySecret = "twilioApiKeySecret",
  /** column name */
  TwilioApiKeySid = "twilioApiKeySid",
  /** column name */
  TwilioAuthRepSid = "twilioAuthRepSid",
  /** column name */
  TwilioBrandRegistrationFailureReason = "twilioBrandRegistrationFailureReason",
  /** column name */
  TwilioBrandRegistrationSid = "twilioBrandRegistrationSid",
  /** column name */
  TwilioBrandRegistrationStatus = "twilioBrandRegistrationStatus",
  /** column name */
  TwilioBusinessInformationSid = "twilioBusinessInformationSid",
  /** column name */
  TwilioConversationMigratedToSubaccount = "twilioConversationMigratedToSubaccount",
  /** column name */
  TwilioConversationsServiceSid = "twilioConversationsServiceSid",
  /** column name */
  TwilioConversationsUser = "twilioConversationsUser",
  /** column name */
  TwilioCustomerProfileSid = "twilioCustomerProfileSid",
  /** column name */
  TwilioCustomerProfileStatus = "twilioCustomerProfileStatus",
  /** column name */
  TwilioEndUserSid = "twilioEndUserSid",
  /** column name */
  TwilioHighlevelCampaignFailureReason = "twilioHighlevelCampaignFailureReason",
  /** column name */
  TwilioHighlevelCampaignSid = "twilioHighlevelCampaignSid",
  /** column name */
  TwilioHighlevelCampaignStatus = "twilioHighlevelCampaignStatus",
  /** column name */
  TwilioHighlevelNumber = "twilioHighlevelNumber",
  /** column name */
  TwilioHighlevelServiceSid = "twilioHighlevelServiceSid",
  /** column name */
  TwilioMsCampaignFailureReason = "twilioMsCampaignFailureReason",
  /** column name */
  TwilioMsCampaignSid = "twilioMsCampaignSid",
  /** column name */
  TwilioMsCampaignStatus = "twilioMsCampaignStatus",
  /** column name */
  TwilioMsServiceSid = "twilioMsServiceSid",
  /** column name */
  TwilioNumber = "twilioNumber",
  /** column name */
  TwilioNumberSid = "twilioNumberSid",
  /** column name */
  TwilioSubaccountSid = "twilioSubaccountSid",
  /** column name */
  TwilioSupportingDocumentSid = "twilioSupportingDocumentSid",
  /** column name */
  TwilioTollfreeHighlevelNumber = "twilioTollfreeHighlevelNumber",
  /** column name */
  TwilioTollfreeHighlevelNumberSid = "twilioTollfreeHighlevelNumberSid",
  /** column name */
  TwilioTollfreeHighlevelNumberVerificationSid = "twilioTollfreeHighlevelNumberVerificationSid",
  /** column name */
  TwilioTollfreeHighlevelNumberVerificationStatus = "twilioTollfreeHighlevelNumberVerificationStatus",
  /** column name */
  TwilioTollfreeNumber = "twilioTollfreeNumber",
  /** column name */
  TwilioTollfreeNumberSid = "twilioTollfreeNumberSid",
  /** column name */
  TwilioTollfreeNumberVerificationSid = "twilioTollfreeNumberVerificationSid",
  /** column name */
  TwilioTollfreeNumberVerificationStatus = "twilioTollfreeNumberVerificationStatus",
  /** column name */
  TwilioTrustProductSid = "twilioTrustProductSid",
  /** column name */
  TwilioTrustProductStatus = "twilioTrustProductStatus",
  /** column name */
  WebsiteUrl = "websiteUrl",
}

export type MedspaUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MedspaIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MedspaSetInput>;
  /** filter the rows which have to be updated */
  where: MedspaBoolExp;
};

/** aggregate varPop on columns */
export type MedspaVarPopFields = {
  __typename?: "medspaVarPopFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["Float"]["output"]>;
  addressId?: Maybe<Scalars["Float"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  legalAddressId?: Maybe<Scalars["Float"]["output"]>;
  msoAdminId?: Maybe<Scalars["Float"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["Float"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_medspa" */
export type MedspaVarPopOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MedspaVarSampFields = {
  __typename?: "medspaVarSampFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["Float"]["output"]>;
  addressId?: Maybe<Scalars["Float"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  legalAddressId?: Maybe<Scalars["Float"]["output"]>;
  msoAdminId?: Maybe<Scalars["Float"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["Float"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_medspa" */
export type MedspaVarSampOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MedspaVarianceFields = {
  __typename?: "medspaVarianceFields";
  additionalApplicationFeePercentage?: Maybe<Scalars["Float"]["output"]>;
  addressId?: Maybe<Scalars["Float"]["output"]>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  legalAddressId?: Maybe<Scalars["Float"]["output"]>;
  msoAdminId?: Maybe<Scalars["Float"]["output"]>;
  onboardingManagerId?: Maybe<Scalars["Float"]["output"]>;
  professionalCorporationId?: Maybe<Scalars["Float"]["output"]>;
  providerSuccessManagerId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShippingAddressId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_medspa" */
export type MedspaVarianceOrderBy = {
  additionalApplicationFeePercentage?: InputMaybe<OrderBy>;
  addressId?: InputMaybe<OrderBy>;
  /** The administrative division where the medspa is located, used mostly to determine proper compliance requirements. */
  administrativeDivisionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legalAddressId?: InputMaybe<OrderBy>;
  msoAdminId?: InputMaybe<OrderBy>;
  onboardingManagerId?: InputMaybe<OrderBy>;
  professionalCorporationId?: InputMaybe<OrderBy>;
  providerSuccessManagerId?: InputMaybe<OrderBy>;
  suppliesShippingAddressId?: InputMaybe<OrderBy>;
};

export type MedspaWalletCreditTypeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspasMedspaconfigurationAggregateBoolExpBool_And = {
  arguments: MedspasMedspaconfigurationSelectColumnMedspasMedspaconfigurationAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspasMedspaconfigurationAggregateBoolExpBool_Or = {
  arguments: MedspasMedspaconfigurationSelectColumnMedspasMedspaconfigurationAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspasMedspaconfigurationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspasProfessionalcorporationAggregateBoolExpBool_And = {
  arguments: MedspasProfessionalcorporationSelectColumnMedspasProfessionalcorporationAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspasProfessionalcorporationAggregateBoolExpBool_Or = {
  arguments: MedspasProfessionalcorporationSelectColumnMedspasProfessionalcorporationAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MedspasProfessionalcorporationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MedspasProfessionalcorporationSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
  predicate: IntComparisonExp;
};

export type MedspasUsermedspaAcquiredMedicalLicensesAggregateBoolExpCount = {
  arguments?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
  predicate: IntComparisonExp;
};

export type MembershipAggregateBoolExpBool_And = {
  arguments: MembershipSelectColumnMembershipAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MembershipBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MembershipAggregateBoolExpBool_Or = {
  arguments: MembershipSelectColumnMembershipAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MembershipBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MembershipAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MembershipSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MembershipBoolExp>;
  predicate: IntComparisonExp;
};

export type MembershipLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MembershipLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MembershipLineBoolExp>;
  predicate: IntComparisonExp;
};

export type MembershipPerkAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MembershipPerkSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MembershipPerkBoolExp>;
  predicate: IntComparisonExp;
};

export type MembershipPerkLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<MembershipPerkLineBoolExp>;
  predicate: IntComparisonExp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  addMobileStripeTerminal?: Maybe<AddMobileStripeTerminal>;
  addSmartStripeTerminal?: Maybe<AddSmartStripeTerminal>;
  addWalletTransaction?: Maybe<AddWalletTransaction>;
  cancelAffirmPayment?: Maybe<CancelAffirmPayment>;
  cancelAppointment?: Maybe<CancelAppointment>;
  cancelAppointmentByClient?: Maybe<CancelAppointmentByClient>;
  cancelClientMembership?: Maybe<CancelClientMembership>;
  cancelMdMeeting?: Maybe<CancelMdMeeting>;
  cancelTerminalAction?: Maybe<CancelTerminalAction>;
  cancelVisitWithoutAppointment?: Maybe<CancelVisitWithoutAppointment>;
  chargeNoShowFee?: Maybe<ChargeNoShowFee>;
  confirmAppointment?: Maybe<ConfirmAppointment>;
  createAdverseReaction?: Maybe<CreateAdverseReaction>;
  createAvailabilityEvent?: Maybe<CreateAvailabilityEvent>;
  createCherryPatientAccount?: Maybe<CreateCherryPatientAccount>;
  createClient?: Maybe<CreateClient>;
  createClientByProvider?: Maybe<CreateClientByProvider>;
  createClientMembership?: Maybe<CreateClientMembership>;
  createDiagram?: Maybe<CreateDiagram>;
  createDocuments?: Maybe<CreateDocuments>;
  createFormSubmissions?: Maybe<CreateFormSubmissions>;
  createInvoice?: Maybe<CreateInvoice>;
  createMdMeeting?: Maybe<CreateMdMeeting>;
  createMembership?: Maybe<CreateMembership>;
  createOrUpdateDiscountLines?: Maybe<CreateOrUpdateDiscountLines>;
  createPackageCheckout?: Maybe<CreatePackageCheckout>;
  createPackageGiftCheckout?: Maybe<CreatePackageGiftCheckout>;
  createPackageLines?: Maybe<CreatePackageLines>;
  createRecurringAvailability?: Maybe<CreateRecurringAvailability>;
  createSchedulingAppointment?: Maybe<CreateAppointment>;
  createSchedulingAppointmentByClient?: Maybe<CreateAppointmentByClient>;
  createShortMoxieUrl?: Maybe<CreateShortMoxieUrl>;
  createStripeCard?: Maybe<CreateStripeCard>;
  createStripeCardholder?: Maybe<CreateStripeCardholder>;
  createStripeOutboundPayment?: Maybe<CreateStripeOutboundPayment>;
  createTelehealthDetails?: Maybe<CreateTelehealthDetails>;
  /** delete data from the table: "utils_address" */
  deleteAddress?: Maybe<AddressMutationResponse>;
  /** delete single row from the table: "utils_address" */
  deleteAddressByPk?: Maybe<Address>;
  /** delete data from the table: "administrative_division" */
  deleteAdministrativeDivision?: Maybe<AdministrativeDivisionMutationResponse>;
  /** delete single row from the table: "administrative_division" */
  deleteAdministrativeDivisionByPk?: Maybe<AdministrativeDivision>;
  /** delete data from the table: "adverse_reaction" */
  deleteAdverseReaction?: Maybe<AdverseReactionMutationResponse>;
  /** delete single row from the table: "adverse_reaction" */
  deleteAdverseReactionByPk?: Maybe<AdverseReaction>;
  /** delete data from the table: "adverse_reaction_template" */
  deleteAdverseReactionTemplate?: Maybe<AdverseReactionTemplateMutationResponse>;
  /** delete single row from the table: "adverse_reaction_template" */
  deleteAdverseReactionTemplateByPk?: Maybe<AdverseReactionTemplate>;
  /** delete data from the table: "scheduling_availabilityevent" */
  deleteAvailabilityEvent?: Maybe<AvailabilityEventMutationResponse>;
  /** delete single row from the table: "scheduling_availabilityevent" */
  deleteAvailabilityEventByPk?: Maybe<AvailabilityEvent>;
  deleteCard?: Maybe<DeleteCard>;
  /** delete data from the table: "resources_careform" */
  deleteCareForm?: Maybe<CareFormMutationResponse>;
  /** delete single row from the table: "resources_careform" */
  deleteCareFormByPk?: Maybe<CareForm>;
  /** delete data from the table: "resources_careform_service_menu_items" */
  deleteCareFormServiceMenuItems?: Maybe<CareFormServiceMenuItemsMutationResponse>;
  /** delete single row from the table: "resources_careform_service_menu_items" */
  deleteCareFormServiceMenuItemsByPk?: Maybe<CareFormServiceMenuItems>;
  /** delete data from the table: "medspas_client" */
  deleteClient?: Maybe<ClientMutationResponse>;
  /** delete data from the table: "medspas_clientaccesstoken" */
  deleteClientAccessToken?: Maybe<ClientAccessTokenMutationResponse>;
  /** delete single row from the table: "medspas_clientaccesstoken" */
  deleteClientAccessTokenByPk?: Maybe<ClientAccessToken>;
  /** delete single row from the table: "medspas_client" */
  deleteClientByPk?: Maybe<Client>;
  /** delete data from the table: "client_membership" */
  deleteClientMembership?: Maybe<ClientMembershipMutationResponse>;
  /** delete single row from the table: "client_membership" */
  deleteClientMembershipByPk?: Maybe<ClientMembership>;
  /** delete data from the table: "compliance_task" */
  deleteComplianceTask?: Maybe<ComplianceTaskMutationResponse>;
  /** delete single row from the table: "compliance_task" */
  deleteComplianceTaskByPk?: Maybe<ComplianceTask>;
  /** delete data from the table: "compliance_task_document" */
  deleteComplianceTaskDocument?: Maybe<ComplianceTaskDocumentMutationResponse>;
  /** delete single row from the table: "compliance_task_document" */
  deleteComplianceTaskDocumentByPk?: Maybe<ComplianceTaskDocument>;
  /** delete data from the table: "compliance_task_template" */
  deleteComplianceTaskTemplate?: Maybe<ComplianceTaskTemplateMutationResponse>;
  /** delete single row from the table: "compliance_task_template" */
  deleteComplianceTaskTemplateByPk?: Maybe<ComplianceTaskTemplate>;
  /** delete data from the table: "compliant_note" */
  deleteCompliantNote?: Maybe<CompliantNoteMutationResponse>;
  /** delete single row from the table: "compliant_note" */
  deleteCompliantNoteByPk?: Maybe<CompliantNote>;
  /** delete data from the table: "compliant_note_template" */
  deleteCompliantNoteTemplate?: Maybe<CompliantNoteTemplateMutationResponse>;
  /** delete single row from the table: "compliant_note_template" */
  deleteCompliantNoteTemplateByPk?: Maybe<CompliantNoteTemplate>;
  /** delete data from the table: "resources_consentform" */
  deleteConsentForm?: Maybe<ConsentFormMutationResponse>;
  /** delete single row from the table: "resources_consentform" */
  deleteConsentFormByPk?: Maybe<ConsentForm>;
  /** delete data from the table: "resources_consentform_service_menu_items" */
  deleteConsentFormServiceMenuItems?: Maybe<ConsentFormServiceMenuItemsMutationResponse>;
  /** delete single row from the table: "resources_consentform_service_menu_items" */
  deleteConsentFormServiceMenuItemsByPk?: Maybe<ConsentFormServiceMenuItems>;
  /** delete data from the table: "medspas_consentformsignature" */
  deleteConsentFormSignature?: Maybe<ConsentFormSignatureMutationResponse>;
  /** delete single row from the table: "medspas_consentformsignature" */
  deleteConsentFormSignatureByPk?: Maybe<ConsentFormSignature>;
  /** delete data from the table: "deposit_line" */
  deleteDepositLine?: Maybe<DepositLineMutationResponse>;
  /** delete single row from the table: "deposit_line" */
  deleteDepositLineByPk?: Maybe<DepositLine>;
  /** delete data from the table: "visits_diagram" */
  deleteDiagram?: Maybe<DiagramMutationResponse>;
  /** delete single row from the table: "visits_diagram" */
  deleteDiagramByPk?: Maybe<Diagram>;
  /** delete data from the table: "resources_diagramtemplate" */
  deleteDiagramTemplate?: Maybe<DiagramTemplateMutationResponse>;
  /** delete single row from the table: "resources_diagramtemplate" */
  deleteDiagramTemplateByPk?: Maybe<DiagramTemplate>;
  /** delete data from the table: "resources_discount" */
  deleteDiscount?: Maybe<DiscountMutationResponse>;
  /** delete single row from the table: "resources_discount" */
  deleteDiscountByPk?: Maybe<Discount>;
  /** delete data from the table: "visits_discountline" */
  deleteDiscountLine?: Maybe<DiscountLineMutationResponse>;
  /** delete single row from the table: "visits_discountline" */
  deleteDiscountLineByPk?: Maybe<DiscountLine>;
  /** delete data from the table: "discount_product" */
  deleteDiscountRetailProducts?: Maybe<DiscountRetailProductsMutationResponse>;
  /** delete single row from the table: "discount_product" */
  deleteDiscountRetailProductsByPk?: Maybe<DiscountRetailProducts>;
  /** delete data from the table: "resources_discount_service_menu_items" */
  deleteDiscountServiceMenuItems?: Maybe<DiscountServiceMenuItemsMutationResponse>;
  /** delete single row from the table: "resources_discount_service_menu_items" */
  deleteDiscountServiceMenuItemsByPk?: Maybe<DiscountServiceMenuItems>;
  /** delete data from the table: "resources_discount_service_products" */
  deleteDiscountServiceProducts?: Maybe<DiscountServiceProductsMutationResponse>;
  /** delete single row from the table: "resources_discount_service_products" */
  deleteDiscountServiceProductsByPk?: Maybe<DiscountServiceProducts>;
  /** delete data from the table: "feature_permission" */
  deleteFeaturePermission?: Maybe<FeaturePermissionMutationResponse>;
  /** delete single row from the table: "feature_permission" */
  deleteFeaturePermissionByPk?: Maybe<FeaturePermission>;
  /** delete data from the table: "resources_fee" */
  deleteFee?: Maybe<FeeMutationResponse>;
  /** delete single row from the table: "resources_fee" */
  deleteFeeByPk?: Maybe<Fee>;
  /** delete data from the table: "visits_feeline" */
  deleteFeeLine?: Maybe<FeeLineMutationResponse>;
  /** delete single row from the table: "visits_feeline" */
  deleteFeeLineByPk?: Maybe<FeeLine>;
  /** delete data from the table: "forms_form" */
  deleteForm?: Maybe<FormMutationResponse>;
  /** delete single row from the table: "forms_form" */
  deleteFormByPk?: Maybe<Form>;
  /** delete data from the table: "forms_form_service_menu_items" */
  deleteFormServiceMenuItem?: Maybe<FormServiceMenuItemMutationResponse>;
  /** delete single row from the table: "forms_form_service_menu_items" */
  deleteFormServiceMenuItemByPk?: Maybe<FormServiceMenuItem>;
  /** delete data from the table: "forms_formsubmission" */
  deleteFormSubmission?: Maybe<FormSubmissionMutationResponse>;
  /** delete single row from the table: "forms_formsubmission" */
  deleteFormSubmissionByPk?: Maybe<FormSubmission>;
  /** delete data from the table: "forms_formsubmissiongfestatuschangelog" */
  deleteFormSubmissionGFEStatusChangeLog?: Maybe<FormSubmissionGfeStatusChangeLogMutationResponse>;
  /** delete single row from the table: "forms_formsubmissiongfestatuschangelog" */
  deleteFormSubmissionGFEStatusChangeLogByPk?: Maybe<FormSubmissionGfeStatusChangeLog>;
  /** delete data from the table: "forms_form_medspa_service_offerings" */
  deleteFormsFormMedspaServiceOfferings?: Maybe<FormsFormMedspaServiceOfferingsMutationResponse>;
  /** delete single row from the table: "forms_form_medspa_service_offerings" */
  deleteFormsFormMedspaServiceOfferingsByPk?: Maybe<FormsFormMedspaServiceOfferings>;
  /** delete data from the table: "forms_formsubmissionversion" */
  deleteFormsFormsubmissionversion?: Maybe<FormsFormsubmissionversionMutationResponse>;
  /** delete single row from the table: "forms_formsubmissionversion" */
  deleteFormsFormsubmissionversionByPk?: Maybe<FormsFormsubmissionversion>;
  /** delete data from the table: "gfe_diagnosis" */
  deleteGfeDiagnosis?: Maybe<GfeDiagnosisMutationResponse>;
  /** delete single row from the table: "gfe_diagnosis" */
  deleteGfeDiagnosisByPk?: Maybe<GfeDiagnosis>;
  /** delete data from the table: "gfe_review_diagnosis" */
  deleteGfeReviewDiagnosis?: Maybe<GfeReviewDiagnosisMutationResponse>;
  /** delete single row from the table: "gfe_review_diagnosis" */
  deleteGfeReviewDiagnosisByPk?: Maybe<GfeReviewDiagnosis>;
  /** delete data from the table: "gfe_review_request" */
  deleteGfeReviewRequest?: Maybe<GfeReviewRequestMutationResponse>;
  /** delete single row from the table: "gfe_review_request" */
  deleteGfeReviewRequestByPk?: Maybe<GfeReviewRequest>;
  /** delete data from the table: "gfe_review_submission" */
  deleteGfeReviewSubmission?: Maybe<GfeReviewSubmissionMutationResponse>;
  /** delete single row from the table: "gfe_review_submission" */
  deleteGfeReviewSubmissionByPk?: Maybe<GfeReviewSubmission>;
  /** delete data from the table: "gfe_service_product_indication" */
  deleteGfeServiceProductIndication?: Maybe<GfeServiceProductIndicationMutationResponse>;
  /** delete single row from the table: "gfe_service_product_indication" */
  deleteGfeServiceProductIndicationByPk?: Maybe<GfeServiceProductIndication>;
  /** delete data from the table: "gfe_synchronous_review_document" */
  deleteGfeSynchronousReviewDocument?: Maybe<GfeSynchronousReviewDocumentMutationResponse>;
  /** delete single row from the table: "gfe_synchronous_review_document" */
  deleteGfeSynchronousReviewDocumentByPk?: Maybe<GfeSynchronousReviewDocument>;
  /** delete data from the table: "gfe_treatment_indication" */
  deleteGfeTreatmentIndication?: Maybe<GfeTreatmentIndicationMutationResponse>;
  /** delete single row from the table: "gfe_treatment_indication" */
  deleteGfeTreatmentIndicationByPk?: Maybe<GfeTreatmentIndication>;
  /** delete data from the table: "gift_builder_banner" */
  deleteGiftBuilderBanner?: Maybe<GiftBuilderBannerMutationResponse>;
  /** delete single row from the table: "gift_builder_banner" */
  deleteGiftBuilderBannerByPk?: Maybe<GiftBuilderBanner>;
  /** delete data from the table: "inventory_lot" */
  deleteInventoryLot?: Maybe<InventoryLotMutationResponse>;
  /** delete single row from the table: "inventory_lot" */
  deleteInventoryLotByPk?: Maybe<InventoryLot>;
  /** delete data from the table: "inventory_transaction" */
  deleteInventoryTransaction?: Maybe<InventoryTransactionMutationResponse>;
  /** delete single row from the table: "inventory_transaction" */
  deleteInventoryTransactionByPk?: Maybe<InventoryTransaction>;
  /** delete data from the table: "visits_invoice" */
  deleteInvoice?: Maybe<InvoiceMutationResponse>;
  /** delete single row from the table: "visits_invoice" */
  deleteInvoiceByPk?: Maybe<Invoice>;
  /** delete data from the table: "marketing_template" */
  deleteMarketingTemplate?: Maybe<MarketingTemplateMutationResponse>;
  /** delete single row from the table: "marketing_template" */
  deleteMarketingTemplateByPk?: Maybe<MarketingTemplate>;
  /** delete data from the table: "md_meeting" */
  deleteMdMeeting?: Maybe<MdMeetingMutationResponse>;
  /** delete data from the table: "md_meeting_attendees" */
  deleteMdMeetingAttendees?: Maybe<MdMeetingAttendeesMutationResponse>;
  /** delete single row from the table: "md_meeting_attendees" */
  deleteMdMeetingAttendeesByPk?: Maybe<MdMeetingAttendees>;
  /** delete single row from the table: "md_meeting" */
  deleteMdMeetingByPk?: Maybe<MdMeeting>;
  /** delete data from the table: "md_meeting_note" */
  deleteMdMeetingNote?: Maybe<MdMeetingNoteMutationResponse>;
  /** delete single row from the table: "md_meeting_note" */
  deleteMdMeetingNoteByPk?: Maybe<MdMeetingNote>;
  /** delete data from the table: "md_meeting_note_template" */
  deleteMdMeetingNoteTemplate?: Maybe<MdMeetingNoteTemplateMutationResponse>;
  /** delete single row from the table: "md_meeting_note_template" */
  deleteMdMeetingNoteTemplateByPk?: Maybe<MdMeetingNoteTemplate>;
  /** delete data from the table: "medspas_medicaldirector" */
  deleteMedicalDirector?: Maybe<MedicalDirectorMutationResponse>;
  /** delete single row from the table: "medspas_medicaldirector" */
  deleteMedicalDirectorByPk?: Maybe<MedicalDirector>;
  /** delete data from the table: "medspas_medicaldirectorsubscription" */
  deleteMedicalDirectorSubscription?: Maybe<MedicalDirectorSubscriptionMutationResponse>;
  /** delete single row from the table: "medspas_medicaldirectorsubscription" */
  deleteMedicalDirectorSubscriptionByPk?: Maybe<MedicalDirectorSubscription>;
  /** delete data from the table: "medspas_medspa" */
  deleteMedspa?: Maybe<MedspaMutationResponse>;
  /** delete single row from the table: "medspas_medspa" */
  deleteMedspaByPk?: Maybe<Medspa>;
  /** delete data from the table: "medspa_rooms_appointments" */
  deleteMedspaRoomsAppointments?: Maybe<MedspaRoomsAppointmentsMutationResponse>;
  /** delete single row from the table: "medspa_rooms_appointments" */
  deleteMedspaRoomsAppointmentsByPk?: Maybe<MedspaRoomsAppointments>;
  /** delete data from the table: "medspa_service_menu_item" */
  deleteMedspaServiceMenuItem?: Maybe<MedspaServiceMenuItemMutationResponse>;
  /** delete single row from the table: "medspa_service_menu_item" */
  deleteMedspaServiceMenuItemByPk?: Maybe<MedspaServiceMenuItem>;
  /** delete data from the table: "medspa_service_menu_item_medspa_service_offerings" */
  deleteMedspaServiceMenuItemMedspaServiceOfferings?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsMutationResponse>;
  /** delete single row from the table: "medspa_service_menu_item_medspa_service_offerings" */
  deleteMedspaServiceMenuItemMedspaServiceOfferingsByPk?: Maybe<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** delete data from the table: "medspa_service_menu_item_old_products" */
  deleteMedspaServiceMenuItemOldProducts?: Maybe<MedspaServiceMenuItemOldProductsMutationResponse>;
  /** delete single row from the table: "medspa_service_menu_item_old_products" */
  deleteMedspaServiceMenuItemOldProductsByPk?: Maybe<MedspaServiceMenuItemOldProducts>;
  /** delete data from the table: "medspa_service_menu_item_providers" */
  deleteMedspaServiceMenuItemProviders?: Maybe<MedspaServiceMenuItemProvidersMutationResponse>;
  /** delete single row from the table: "medspa_service_menu_item_providers" */
  deleteMedspaServiceMenuItemProvidersByPk?: Maybe<MedspaServiceMenuItemProviders>;
  /** delete data from the table: "medspa_service_offering" */
  deleteMedspaServiceOffering?: Maybe<MedspaServiceOfferingMutationResponse>;
  /** delete single row from the table: "medspa_service_offering" */
  deleteMedspaServiceOfferingByPk?: Maybe<MedspaServiceOffering>;
  /** delete data from the table: "medspa_service_offering_note_templates" */
  deleteMedspaServiceOfferingNoteTemplates?: Maybe<MedspaServiceOfferingNoteTemplatesMutationResponse>;
  /** delete single row from the table: "medspa_service_offering_note_templates" */
  deleteMedspaServiceOfferingNoteTemplatesByPk?: Maybe<MedspaServiceOfferingNoteTemplates>;
  /** delete data from the table: "medspa_service_offering_notes" */
  deleteMedspaServiceOfferingNotes?: Maybe<MedspaServiceOfferingNotesMutationResponse>;
  /** delete single row from the table: "medspa_service_offering_notes" */
  deleteMedspaServiceOfferingNotesByPk?: Maybe<MedspaServiceOfferingNotes>;
  /** delete data from the table: "medspa_service_product" */
  deleteMedspaServiceProduct?: Maybe<MedspaServiceProductMutationResponse>;
  /** delete single row from the table: "medspa_service_product" */
  deleteMedspaServiceProductByPk?: Maybe<MedspaServiceProduct>;
  /** delete data from the table: "medspa_service_protocol_template" */
  deleteMedspaServiceProtocolTemplate?: Maybe<MedspaServiceProtocolTemplateMutationResponse>;
  /** delete single row from the table: "medspa_service_protocol_template" */
  deleteMedspaServiceProtocolTemplateByPk?: Maybe<MedspaServiceProtocolTemplate>;
  /** delete data from the table: "medspa_standing_order_template" */
  deleteMedspaStandingOrderTemplate?: Maybe<MedspaStandingOrderTemplateMutationResponse>;
  /** delete single row from the table: "medspa_standing_order_template" */
  deleteMedspaStandingOrderTemplateByPk?: Maybe<MedspaStandingOrderTemplate>;
  /** delete data from the table: "medspa_wallet_credit_type" */
  deleteMedspaWalletCreditType?: Maybe<MedspaWalletCreditTypeMutationResponse>;
  /** delete single row from the table: "medspa_wallet_credit_type" */
  deleteMedspaWalletCreditTypeByPk?: Maybe<MedspaWalletCreditType>;
  /** delete data from the table: "medspas_featureflag" */
  deleteMedspasFeatureflag?: Maybe<MedspasFeatureflagMutationResponse>;
  /** delete single row from the table: "medspas_featureflag" */
  deleteMedspasFeatureflagByPk?: Maybe<MedspasFeatureflag>;
  /** delete data from the table: "medspas_medicallicense" */
  deleteMedspasMedicallicense?: Maybe<MedspasMedicallicenseMutationResponse>;
  /** delete single row from the table: "medspas_medicallicense" */
  deleteMedspasMedicallicenseByPk?: Maybe<MedspasMedicallicense>;
  /** delete data from the table: "medspas_medspaconfiguration" */
  deleteMedspasMedspaconfiguration?: Maybe<MedspasMedspaconfigurationMutationResponse>;
  /** delete single row from the table: "medspas_medspaconfiguration" */
  deleteMedspasMedspaconfigurationByPk?: Maybe<MedspasMedspaconfiguration>;
  /** delete data from the table: "medspas_professionalcorporation" */
  deleteMedspasProfessionalcorporation?: Maybe<MedspasProfessionalcorporationMutationResponse>;
  /** delete single row from the table: "medspas_professionalcorporation" */
  deleteMedspasProfessionalcorporationByPk?: Maybe<MedspasProfessionalcorporation>;
  /** delete data from the table: "medspas_stripeoutboundpaymentmethod" */
  deleteMedspasStripeoutboundpaymentmethod?: Maybe<MedspasStripeoutboundpaymentmethodMutationResponse>;
  /** delete single row from the table: "medspas_stripeoutboundpaymentmethod" */
  deleteMedspasStripeoutboundpaymentmethodByPk?: Maybe<MedspasStripeoutboundpaymentmethod>;
  /** delete data from the table: "medspas_usermedspa_acquired_medical_licenses" */
  deleteMedspasUsermedspaAcquiredMedicalLicenses?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesMutationResponse>;
  /** delete single row from the table: "medspas_usermedspa_acquired_medical_licenses" */
  deleteMedspasUsermedspaAcquiredMedicalLicensesByPk?: Maybe<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** delete data from the table: "membership" */
  deleteMembership?: Maybe<MembershipMutationResponse>;
  /** delete single row from the table: "membership" */
  deleteMembershipByPk?: Maybe<Membership>;
  /** delete data from the table: "membership_category" */
  deleteMembershipCategory?: Maybe<MembershipCategoryMutationResponse>;
  /** delete single row from the table: "membership_category" */
  deleteMembershipCategoryByPk?: Maybe<MembershipCategory>;
  /** delete data from the table: "membership_line" */
  deleteMembershipLine?: Maybe<MembershipLineMutationResponse>;
  /** delete single row from the table: "membership_line" */
  deleteMembershipLineByPk?: Maybe<MembershipLine>;
  /** delete data from the table: "membership_perk" */
  deleteMembershipPerk?: Maybe<MembershipPerkMutationResponse>;
  /** delete single row from the table: "membership_perk" */
  deleteMembershipPerkByPk?: Maybe<MembershipPerk>;
  /** delete data from the table: "membership_perk_line" */
  deleteMembershipPerkLine?: Maybe<MembershipPerkLineMutationResponse>;
  /** delete single row from the table: "membership_perk_line" */
  deleteMembershipPerkLineByPk?: Maybe<MembershipPerkLine>;
  /** delete data from the table: "model_permission" */
  deleteModelPermission?: Maybe<ModelPermissionMutationResponse>;
  /** delete single row from the table: "model_permission" */
  deleteModelPermissionByPk?: Maybe<ModelPermission>;
  /** delete data from the table: "visits_note" */
  deleteNote?: Maybe<NoteMutationResponse>;
  /** delete single row from the table: "visits_note" */
  deleteNoteByPk?: Maybe<Note>;
  /** delete data from the table: "visits_notechangelog" */
  deleteNoteChangeLog?: Maybe<NoteChangeLogMutationResponse>;
  /** delete single row from the table: "visits_notechangelog" */
  deleteNoteChangeLogByPk?: Maybe<NoteChangeLog>;
  /** delete data from the table: "visits_note_form_submissions" */
  deleteNoteFormSubmission?: Maybe<NoteFormSubmissionMutationResponse>;
  /** delete single row from the table: "visits_note_form_submissions" */
  deleteNoteFormSubmissionByPk?: Maybe<NoteFormSubmission>;
  /** delete data from the table: "resources_notetemplate" */
  deleteNoteTemplate?: Maybe<NoteTemplateMutationResponse>;
  /** delete single row from the table: "resources_notetemplate" */
  deleteNoteTemplateByPk?: Maybe<NoteTemplate>;
  /** delete data from the table: "resources_package" */
  deletePackage?: Maybe<PackageMutationResponse>;
  /** delete single row from the table: "resources_package" */
  deletePackageByPk?: Maybe<Package>;
  /** delete data from the table: "resources_packageitem" */
  deletePackageItem?: Maybe<PackageItemMutationResponse>;
  /** delete single row from the table: "resources_packageitem" */
  deletePackageItemByPk?: Maybe<PackageItem>;
  /** delete data from the table: "package_item_line" */
  deletePackageItemLine?: Maybe<PackageItemLineMutationResponse>;
  /** delete single row from the table: "package_item_line" */
  deletePackageItemLineByPk?: Maybe<PackageItemLine>;
  /** delete data from the table: "visits_packageline" */
  deletePackageLine?: Maybe<PackageLineMutationResponse>;
  /** delete single row from the table: "visits_packageline" */
  deletePackageLineByPk?: Maybe<PackageLine>;
  deletePackageLineWithItemLines?: Maybe<DeletePackageLineWithItemLines>;
  /** delete data from the table: "payments_payment" */
  deletePayment?: Maybe<PaymentMutationResponse>;
  /** delete single row from the table: "payments_payment" */
  deletePaymentByPk?: Maybe<Payment>;
  /** delete data from the table: "visits_photo" */
  deletePhoto?: Maybe<PhotoMutationResponse>;
  /** delete single row from the table: "visits_photo" */
  deletePhotoByPk?: Maybe<Photo>;
  /** delete data from the table: "resources_product" */
  deleteProduct?: Maybe<ProductMutationResponse>;
  /** delete single row from the table: "resources_product" */
  deleteProductByPk?: Maybe<Product>;
  /** delete data from the table: "visits_productline" */
  deleteProductLine?: Maybe<ProductLineMutationResponse>;
  /** delete single row from the table: "visits_productline" */
  deleteProductLineByPk?: Maybe<ProductLine>;
  /** delete data from the table: "provider_not_require_documents" */
  deleteProviderNotRequireDocuments?: Maybe<ProviderNotRequireDocumentsMutationResponse>;
  /** delete single row from the table: "provider_not_require_documents" */
  deleteProviderNotRequireDocumentsByPk?: Maybe<ProviderNotRequireDocuments>;
  /** delete data from the table: "scheduling_recurring_availability" */
  deleteRecurringAvailability?: Maybe<RecurringAvailabilityMutationResponse>;
  /** delete single row from the table: "scheduling_recurring_availability" */
  deleteRecurringAvailabilityByPk?: Maybe<RecurringAvailability>;
  /** delete data from the table: "payments_refund" */
  deleteRefund?: Maybe<RefundMutationResponse>;
  /** delete single row from the table: "payments_refund" */
  deleteRefundByPk?: Maybe<Refund>;
  /** delete data from the table: "medspas_report" */
  deleteReport?: Maybe<ReportMutationResponse>;
  /** delete single row from the table: "medspas_report" */
  deleteReportByPk?: Maybe<Report>;
  /** delete data from the table: "medspas_reportcategory" */
  deleteReportCategory?: Maybe<ReportCategoryMutationResponse>;
  /** delete single row from the table: "medspas_reportcategory" */
  deleteReportCategoryByPk?: Maybe<ReportCategory>;
  /** delete data from the table: "resources_medspa_room" */
  deleteResourcesMedspaRoom?: Maybe<ResourcesMedspaRoomMutationResponse>;
  /** delete single row from the table: "resources_medspa_room" */
  deleteResourcesMedspaRoomByPk?: Maybe<ResourcesMedspaRoom>;
  /** delete data from the table: "scheduling_appointment" */
  deleteSchedulingAppointment?: Maybe<SchedulingAppointmentMutationResponse>;
  /** delete single row from the table: "scheduling_appointment" */
  deleteSchedulingAppointmentByPk?: Maybe<SchedulingAppointment>;
  /** delete data from the table: "script_product" */
  deleteScriptProduct?: Maybe<ScriptProductMutationResponse>;
  /** delete single row from the table: "script_product" */
  deleteScriptProductByPk?: Maybe<ScriptProduct>;
  /** delete data from the table: "script_request" */
  deleteScriptRequest?: Maybe<ScriptRequestMutationResponse>;
  /** delete single row from the table: "script_request" */
  deleteScriptRequestByPk?: Maybe<ScriptRequest>;
  /** delete data from the table: "script_request_status_change_log" */
  deleteScriptRequestStatusChangeLog?: Maybe<ScriptRequestStatusChangeLogMutationResponse>;
  /** delete single row from the table: "script_request_status_change_log" */
  deleteScriptRequestStatusChangeLogByPk?: Maybe<ScriptRequestStatusChangeLog>;
  /** delete data from the table: "resources_servicecategory" */
  deleteServiceCategory?: Maybe<ServiceCategoryMutationResponse>;
  /** delete single row from the table: "resources_servicecategory" */
  deleteServiceCategoryByPk?: Maybe<ServiceCategory>;
  /** delete data from the table: "service_device" */
  deleteServiceDevice?: Maybe<ServiceDeviceMutationResponse>;
  /** delete single row from the table: "service_device" */
  deleteServiceDeviceByPk?: Maybe<ServiceDevice>;
  /** delete data from the table: "service_devices_appointments" */
  deleteServiceDevicesAppointments?: Maybe<ServiceDevicesAppointmentsMutationResponse>;
  /** delete single row from the table: "service_devices_appointments" */
  deleteServiceDevicesAppointmentsByPk?: Maybe<ServiceDevicesAppointments>;
  /** delete data from the table: "service_devices_service_types" */
  deleteServiceDevicesServiceTypes?: Maybe<ServiceDevicesServiceTypesMutationResponse>;
  /** delete single row from the table: "service_devices_service_types" */
  deleteServiceDevicesServiceTypesByPk?: Maybe<ServiceDevicesServiceTypes>;
  /** delete data from the table: "service_menu_item_line" */
  deleteServiceMenuItemLine?: Maybe<ServiceMenuItemLineMutationResponse>;
  /** delete single row from the table: "service_menu_item_line" */
  deleteServiceMenuItemLineByPk?: Maybe<ServiceMenuItemLine>;
  /** delete data from the table: "service_menu_item_line_standing_orders" */
  deleteServiceMenuItemLineStandingOrders?: Maybe<ServiceMenuItemLineStandingOrdersMutationResponse>;
  /** delete single row from the table: "service_menu_item_line_standing_orders" */
  deleteServiceMenuItemLineStandingOrdersByPk?: Maybe<ServiceMenuItemLineStandingOrders>;
  /** delete data from the table: "service_product" */
  deleteServiceProduct?: Maybe<ServiceProductMutationResponse>;
  /** delete single row from the table: "service_product" */
  deleteServiceProductByPk?: Maybe<ServiceProduct>;
  /** delete data from the table: "service_product_charting_note" */
  deleteServiceProductChartingNote?: Maybe<ServiceProductChartingNoteMutationResponse>;
  /** delete single row from the table: "service_product_charting_note" */
  deleteServiceProductChartingNoteByPk?: Maybe<ServiceProductChartingNote>;
  /** delete data from the table: "service_product_line" */
  deleteServiceProductLine?: Maybe<ServiceProductLineMutationResponse>;
  /** delete single row from the table: "service_product_line" */
  deleteServiceProductLineByPk?: Maybe<ServiceProductLine>;
  /** delete data from the table: "service_product_type" */
  deleteServiceProductType?: Maybe<ServiceProductTypeMutationResponse>;
  /** delete single row from the table: "service_product_type" */
  deleteServiceProductTypeByPk?: Maybe<ServiceProductType>;
  /** delete data from the table: "service_product_usage" */
  deleteServiceProductUsage?: Maybe<ServiceProductUsageMutationResponse>;
  /** delete single row from the table: "service_product_usage" */
  deleteServiceProductUsageByPk?: Maybe<ServiceProductUsage>;
  /** delete data from the table: "service_protocol" */
  deleteServiceProtocol?: Maybe<ServiceProtocolMutationResponse>;
  /** delete single row from the table: "service_protocol" */
  deleteServiceProtocolByPk?: Maybe<ServiceProtocol>;
  /** delete data from the table: "service_protocol_template" */
  deleteServiceProtocolTemplate?: Maybe<ServiceProtocolTemplateMutationResponse>;
  /** delete single row from the table: "service_protocol_template" */
  deleteServiceProtocolTemplateByPk?: Maybe<ServiceProtocolTemplate>;
  /** delete data from the table: "service_type" */
  deleteServiceType?: Maybe<ServiceTypeMutationResponse>;
  /** delete single row from the table: "service_type" */
  deleteServiceTypeByPk?: Maybe<ServiceType>;
  /** delete data from the table: "service_type_category" */
  deleteServiceTypeCategory?: Maybe<ServiceTypeCategoryMutationResponse>;
  /** delete single row from the table: "service_type_category" */
  deleteServiceTypeCategoryByPk?: Maybe<ServiceTypeCategory>;
  /** delete data from the table: "utils_shortmoxieurl" */
  deleteShortMoxieUrl?: Maybe<ShortMoxieUrlMutationResponse>;
  /** delete single row from the table: "utils_shortmoxieurl" */
  deleteShortMoxieUrlByPk?: Maybe<ShortMoxieUrl>;
  /** delete data from the table: "medspas_standingorder" */
  deleteStandingOrder?: Maybe<StandingOrderMutationResponse>;
  /** delete single row from the table: "medspas_standingorder" */
  deleteStandingOrderByPk?: Maybe<StandingOrder>;
  /** delete data from the table: "standing_order_template" */
  deleteStandingOrderTemplate?: Maybe<StandingOrderTemplateMutationResponse>;
  /** delete single row from the table: "standing_order_template" */
  deleteStandingOrderTemplateByPk?: Maybe<StandingOrderTemplate>;
  /** delete data from the table: "supplier_information" */
  deleteSupplierInformation?: Maybe<SupplierInformationMutationResponse>;
  /** delete single row from the table: "supplier_information" */
  deleteSupplierInformationByPk?: Maybe<SupplierInformation>;
  /** delete data from the table: "telehealth_visit_details" */
  deleteTelehealthVisitDetails?: Maybe<TelehealthVisitDetailsMutationResponse>;
  /** delete single row from the table: "telehealth_visit_details" */
  deleteTelehealthVisitDetailsByPk?: Maybe<TelehealthVisitDetails>;
  /** delete data from the table: "standing_order" */
  deleteTmpStandingOrder?: Maybe<TmpStandingOrderMutationResponse>;
  /** delete single row from the table: "standing_order" */
  deleteTmpStandingOrderByPk?: Maybe<TmpStandingOrder>;
  /** delete data from the table: "url_pattern_permission" */
  deleteUrlPatternPermission?: Maybe<UrlPatternPermissionMutationResponse>;
  /** delete single row from the table: "url_pattern_permission" */
  deleteUrlPatternPermissionByPk?: Maybe<UrlPatternPermission>;
  /** delete data from the table: "accounts_user" */
  deleteUser?: Maybe<UserMutationResponse>;
  /** delete single row from the table: "accounts_user" */
  deleteUserByPk?: Maybe<User>;
  /** delete data from the table: "medspas_usermedspa" */
  deleteUserMedspa?: Maybe<UserMedspaMutationResponse>;
  /** delete single row from the table: "medspas_usermedspa" */
  deleteUserMedspaByPk?: Maybe<UserMedspa>;
  /** delete data from the table: "visits_visit" */
  deleteVisit?: Maybe<VisitMutationResponse>;
  /** delete single row from the table: "visits_visit" */
  deleteVisitByPk?: Maybe<Visit>;
  /** delete data from the table: "visits_visitreviewstatuschangelog" */
  deleteVisitReviewStatusChangeLog?: Maybe<VisitReviewStatusChangeLogMutationResponse>;
  /** delete single row from the table: "visits_visitreviewstatuschangelog" */
  deleteVisitReviewStatusChangeLogByPk?: Maybe<VisitReviewStatusChangeLog>;
  /** delete data from the table: "visits_visitstatuschangelog" */
  deleteVisitStatusChangeLog?: Maybe<VisitStatusChangeLogMutationResponse>;
  /** delete single row from the table: "visits_visitstatuschangelog" */
  deleteVisitStatusChangeLogByPk?: Maybe<VisitStatusChangeLog>;
  /** delete data from the table: "visits_adversereactionchangelog" */
  deleteVisitsAdversereactionchangelog?: Maybe<VisitsAdversereactionchangelogMutationResponse>;
  /** delete single row from the table: "visits_adversereactionchangelog" */
  deleteVisitsAdversereactionchangelogByPk?: Maybe<VisitsAdversereactionchangelog>;
  /** delete data from the table: "visits_chartsignature" */
  deleteVisitsChartsignature?: Maybe<VisitsChartsignatureMutationResponse>;
  /** delete single row from the table: "visits_chartsignature" */
  deleteVisitsChartsignatureByPk?: Maybe<VisitsChartsignature>;
  /** delete data from the table: "visits_telehealthvisitdetailschangelog" */
  deleteVisitsTelehealthvisitdetailschangelog?: Maybe<VisitsTelehealthvisitdetailschangelogMutationResponse>;
  /** delete single row from the table: "visits_telehealthvisitdetailschangelog" */
  deleteVisitsTelehealthvisitdetailschangelogByPk?: Maybe<VisitsTelehealthvisitdetailschangelog>;
  /** delete data from the table: "wallet_credit_line" */
  deleteWalletCreditLine?: Maybe<WalletCreditLineMutationResponse>;
  /** delete single row from the table: "wallet_credit_line" */
  deleteWalletCreditLineByPk?: Maybe<WalletCreditLine>;
  /** delete data from the table: "wallet_credit_type" */
  deleteWalletCreditType?: Maybe<WalletCreditTypeMutationResponse>;
  /** delete single row from the table: "wallet_credit_type" */
  deleteWalletCreditTypeByPk?: Maybe<WalletCreditType>;
  /** delete data from the table: "wallets_wallettransaction" */
  deleteWalletTransaction?: Maybe<WalletTransactionMutationResponse>;
  /** delete single row from the table: "wallets_wallettransaction" */
  deleteWalletTransactionByPk?: Maybe<WalletTransaction>;
  /** delete data from the table: "wallet_transaction_source_tmp" */
  deleteWalletTransactionSourceTmp?: Maybe<WalletTransactionSourceTmpMutationResponse>;
  /** delete single row from the table: "wallet_transaction_source_tmp" */
  deleteWalletTransactionSourceTmpByPk?: Maybe<WalletTransactionSourceTmp>;
  /** delete data from the table: "wallet_transactions_sources" */
  deleteWalletTransactionsSources?: Maybe<WalletTransactionsSourcesMutationResponse>;
  /** delete single row from the table: "wallet_transactions_sources" */
  deleteWalletTransactionsSourcesByPk?: Maybe<WalletTransactionsSources>;
  duplicateDiagram?: Maybe<DuplicateDiagram>;
  duplicateMembership?: Maybe<DuplicateMembership>;
  duplicateNote?: Maybe<DuplicateNote>;
  editAdverseReaction?: Maybe<EditAdverseReaction>;
  editPersonalData?: Maybe<EditPersonalData>;
  editTelehealthDetails?: Maybe<EditTelehealthDetails>;
  expireClientAccessToken?: Maybe<ExpireClientAccessToken>;
  expireConsentForms?: Maybe<ExpireConsentForms>;
  expireFormSubmissions?: Maybe<ExpireFormSubmissions>;
  finalizePaymentWithWalletCredits?: Maybe<FinalizePaymentWithWalletCredits>;
  generateBooking2faForClient?: Maybe<GenerateBooking2FaForClient>;
  generateCardSetupIntent?: Maybe<GenerateCardSetupIntent>;
  generateClientAccessTokenWithOtp?: Maybe<GenerateClientAccessTokenWithOtp>;
  generateClientPortalOtp?: Maybe<GenerateClientPortalOtp>;
  generateSyncDocUploadUrl?: Maybe<GenerateSyncDocUploadUrl>;
  hardDeleteClient?: Maybe<HardDeleteClient>;
  highLevelSendNewMessageToConversation?: Maybe<SendMessageResultType>;
  initMobilePayment?: Maybe<InitMobilePayment>;
  /** insert data into the table: "utils_address" */
  insertAddress?: Maybe<AddressMutationResponse>;
  /** insert a single row into the table: "utils_address" */
  insertAddressOne?: Maybe<Address>;
  /** insert data into the table: "administrative_division" */
  insertAdministrativeDivision?: Maybe<AdministrativeDivisionMutationResponse>;
  /** insert a single row into the table: "administrative_division" */
  insertAdministrativeDivisionOne?: Maybe<AdministrativeDivision>;
  /** insert data into the table: "adverse_reaction" */
  insertAdverseReaction?: Maybe<AdverseReactionMutationResponse>;
  /** insert a single row into the table: "adverse_reaction" */
  insertAdverseReactionOne?: Maybe<AdverseReaction>;
  /** insert data into the table: "adverse_reaction_template" */
  insertAdverseReactionTemplate?: Maybe<AdverseReactionTemplateMutationResponse>;
  /** insert a single row into the table: "adverse_reaction_template" */
  insertAdverseReactionTemplateOne?: Maybe<AdverseReactionTemplate>;
  /** insert data into the table: "scheduling_availabilityevent" */
  insertAvailabilityEvent?: Maybe<AvailabilityEventMutationResponse>;
  /** insert a single row into the table: "scheduling_availabilityevent" */
  insertAvailabilityEventOne?: Maybe<AvailabilityEvent>;
  /** insert data into the table: "resources_careform" */
  insertCareForm?: Maybe<CareFormMutationResponse>;
  /** insert a single row into the table: "resources_careform" */
  insertCareFormOne?: Maybe<CareForm>;
  /** insert data into the table: "resources_careform_service_menu_items" */
  insertCareFormServiceMenuItems?: Maybe<CareFormServiceMenuItemsMutationResponse>;
  /** insert a single row into the table: "resources_careform_service_menu_items" */
  insertCareFormServiceMenuItemsOne?: Maybe<CareFormServiceMenuItems>;
  /** insert data into the table: "medspas_client" */
  insertClient?: Maybe<ClientMutationResponse>;
  /** insert data into the table: "medspas_clientaccesstoken" */
  insertClientAccessToken?: Maybe<ClientAccessTokenMutationResponse>;
  /** insert a single row into the table: "medspas_clientaccesstoken" */
  insertClientAccessTokenOne?: Maybe<ClientAccessToken>;
  /** insert data into the table: "client_membership" */
  insertClientMembership?: Maybe<ClientMembershipMutationResponse>;
  /** insert a single row into the table: "client_membership" */
  insertClientMembershipOne?: Maybe<ClientMembership>;
  /** insert a single row into the table: "medspas_client" */
  insertClientOne?: Maybe<Client>;
  /** insert data into the table: "compliance_task" */
  insertComplianceTask?: Maybe<ComplianceTaskMutationResponse>;
  /** insert data into the table: "compliance_task_document" */
  insertComplianceTaskDocument?: Maybe<ComplianceTaskDocumentMutationResponse>;
  /** insert a single row into the table: "compliance_task_document" */
  insertComplianceTaskDocumentOne?: Maybe<ComplianceTaskDocument>;
  /** insert a single row into the table: "compliance_task" */
  insertComplianceTaskOne?: Maybe<ComplianceTask>;
  /** insert data into the table: "compliance_task_template" */
  insertComplianceTaskTemplate?: Maybe<ComplianceTaskTemplateMutationResponse>;
  /** insert a single row into the table: "compliance_task_template" */
  insertComplianceTaskTemplateOne?: Maybe<ComplianceTaskTemplate>;
  /** insert data into the table: "compliant_note" */
  insertCompliantNote?: Maybe<CompliantNoteMutationResponse>;
  /** insert a single row into the table: "compliant_note" */
  insertCompliantNoteOne?: Maybe<CompliantNote>;
  /** insert data into the table: "compliant_note_template" */
  insertCompliantNoteTemplate?: Maybe<CompliantNoteTemplateMutationResponse>;
  /** insert a single row into the table: "compliant_note_template" */
  insertCompliantNoteTemplateOne?: Maybe<CompliantNoteTemplate>;
  /** insert data into the table: "resources_consentform" */
  insertConsentForm?: Maybe<ConsentFormMutationResponse>;
  /** insert a single row into the table: "resources_consentform" */
  insertConsentFormOne?: Maybe<ConsentForm>;
  /** insert data into the table: "resources_consentform_service_menu_items" */
  insertConsentFormServiceMenuItems?: Maybe<ConsentFormServiceMenuItemsMutationResponse>;
  /** insert a single row into the table: "resources_consentform_service_menu_items" */
  insertConsentFormServiceMenuItemsOne?: Maybe<ConsentFormServiceMenuItems>;
  /** insert data into the table: "medspas_consentformsignature" */
  insertConsentFormSignature?: Maybe<ConsentFormSignatureMutationResponse>;
  /** insert a single row into the table: "medspas_consentformsignature" */
  insertConsentFormSignatureOne?: Maybe<ConsentFormSignature>;
  /** insert data into the table: "deposit_line" */
  insertDepositLine?: Maybe<DepositLineMutationResponse>;
  /** insert a single row into the table: "deposit_line" */
  insertDepositLineOne?: Maybe<DepositLine>;
  /** insert data into the table: "visits_diagram" */
  insertDiagram?: Maybe<DiagramMutationResponse>;
  /** insert a single row into the table: "visits_diagram" */
  insertDiagramOne?: Maybe<Diagram>;
  /** insert data into the table: "resources_diagramtemplate" */
  insertDiagramTemplate?: Maybe<DiagramTemplateMutationResponse>;
  /** insert a single row into the table: "resources_diagramtemplate" */
  insertDiagramTemplateOne?: Maybe<DiagramTemplate>;
  /** insert data into the table: "resources_discount" */
  insertDiscount?: Maybe<DiscountMutationResponse>;
  /** insert data into the table: "visits_discountline" */
  insertDiscountLine?: Maybe<DiscountLineMutationResponse>;
  /** insert a single row into the table: "visits_discountline" */
  insertDiscountLineOne?: Maybe<DiscountLine>;
  /** insert a single row into the table: "resources_discount" */
  insertDiscountOne?: Maybe<Discount>;
  /** insert data into the table: "discount_product" */
  insertDiscountRetailProducts?: Maybe<DiscountRetailProductsMutationResponse>;
  /** insert a single row into the table: "discount_product" */
  insertDiscountRetailProductsOne?: Maybe<DiscountRetailProducts>;
  /** insert data into the table: "resources_discount_service_menu_items" */
  insertDiscountServiceMenuItems?: Maybe<DiscountServiceMenuItemsMutationResponse>;
  /** insert a single row into the table: "resources_discount_service_menu_items" */
  insertDiscountServiceMenuItemsOne?: Maybe<DiscountServiceMenuItems>;
  /** insert data into the table: "resources_discount_service_products" */
  insertDiscountServiceProducts?: Maybe<DiscountServiceProductsMutationResponse>;
  /** insert a single row into the table: "resources_discount_service_products" */
  insertDiscountServiceProductsOne?: Maybe<DiscountServiceProducts>;
  /** insert data into the table: "feature_permission" */
  insertFeaturePermission?: Maybe<FeaturePermissionMutationResponse>;
  /** insert a single row into the table: "feature_permission" */
  insertFeaturePermissionOne?: Maybe<FeaturePermission>;
  /** insert data into the table: "resources_fee" */
  insertFee?: Maybe<FeeMutationResponse>;
  /** insert data into the table: "visits_feeline" */
  insertFeeLine?: Maybe<FeeLineMutationResponse>;
  /** insert a single row into the table: "visits_feeline" */
  insertFeeLineOne?: Maybe<FeeLine>;
  /** insert a single row into the table: "resources_fee" */
  insertFeeOne?: Maybe<Fee>;
  /** insert data into the table: "forms_form" */
  insertForm?: Maybe<FormMutationResponse>;
  /** insert a single row into the table: "forms_form" */
  insertFormOne?: Maybe<Form>;
  /** insert data into the table: "forms_form_service_menu_items" */
  insertFormServiceMenuItem?: Maybe<FormServiceMenuItemMutationResponse>;
  /** insert a single row into the table: "forms_form_service_menu_items" */
  insertFormServiceMenuItemOne?: Maybe<FormServiceMenuItem>;
  /** insert data into the table: "forms_formsubmission" */
  insertFormSubmission?: Maybe<FormSubmissionMutationResponse>;
  /** insert data into the table: "forms_formsubmissiongfestatuschangelog" */
  insertFormSubmissionGFEStatusChangeLog?: Maybe<FormSubmissionGfeStatusChangeLogMutationResponse>;
  /** insert a single row into the table: "forms_formsubmissiongfestatuschangelog" */
  insertFormSubmissionGFEStatusChangeLogOne?: Maybe<FormSubmissionGfeStatusChangeLog>;
  /** insert a single row into the table: "forms_formsubmission" */
  insertFormSubmissionOne?: Maybe<FormSubmission>;
  /** insert data into the table: "forms_form_medspa_service_offerings" */
  insertFormsFormMedspaServiceOfferings?: Maybe<FormsFormMedspaServiceOfferingsMutationResponse>;
  /** insert a single row into the table: "forms_form_medspa_service_offerings" */
  insertFormsFormMedspaServiceOfferingsOne?: Maybe<FormsFormMedspaServiceOfferings>;
  /** insert data into the table: "forms_formsubmissionversion" */
  insertFormsFormsubmissionversion?: Maybe<FormsFormsubmissionversionMutationResponse>;
  /** insert a single row into the table: "forms_formsubmissionversion" */
  insertFormsFormsubmissionversionOne?: Maybe<FormsFormsubmissionversion>;
  /** insert data into the table: "gfe_diagnosis" */
  insertGfeDiagnosis?: Maybe<GfeDiagnosisMutationResponse>;
  /** insert a single row into the table: "gfe_diagnosis" */
  insertGfeDiagnosisOne?: Maybe<GfeDiagnosis>;
  /** insert data into the table: "gfe_review_diagnosis" */
  insertGfeReviewDiagnosis?: Maybe<GfeReviewDiagnosisMutationResponse>;
  /** insert a single row into the table: "gfe_review_diagnosis" */
  insertGfeReviewDiagnosisOne?: Maybe<GfeReviewDiagnosis>;
  /** insert data into the table: "gfe_review_request" */
  insertGfeReviewRequest?: Maybe<GfeReviewRequestMutationResponse>;
  /** insert a single row into the table: "gfe_review_request" */
  insertGfeReviewRequestOne?: Maybe<GfeReviewRequest>;
  /** insert data into the table: "gfe_review_submission" */
  insertGfeReviewSubmission?: Maybe<GfeReviewSubmissionMutationResponse>;
  /** insert a single row into the table: "gfe_review_submission" */
  insertGfeReviewSubmissionOne?: Maybe<GfeReviewSubmission>;
  /** insert data into the table: "gfe_service_product_indication" */
  insertGfeServiceProductIndication?: Maybe<GfeServiceProductIndicationMutationResponse>;
  /** insert a single row into the table: "gfe_service_product_indication" */
  insertGfeServiceProductIndicationOne?: Maybe<GfeServiceProductIndication>;
  /** insert data into the table: "gfe_synchronous_review_document" */
  insertGfeSynchronousReviewDocument?: Maybe<GfeSynchronousReviewDocumentMutationResponse>;
  /** insert a single row into the table: "gfe_synchronous_review_document" */
  insertGfeSynchronousReviewDocumentOne?: Maybe<GfeSynchronousReviewDocument>;
  /** insert data into the table: "gfe_treatment_indication" */
  insertGfeTreatmentIndication?: Maybe<GfeTreatmentIndicationMutationResponse>;
  /** insert a single row into the table: "gfe_treatment_indication" */
  insertGfeTreatmentIndicationOne?: Maybe<GfeTreatmentIndication>;
  /** insert data into the table: "gift_builder_banner" */
  insertGiftBuilderBanner?: Maybe<GiftBuilderBannerMutationResponse>;
  /** insert a single row into the table: "gift_builder_banner" */
  insertGiftBuilderBannerOne?: Maybe<GiftBuilderBanner>;
  /** insert data into the table: "inventory_lot" */
  insertInventoryLot?: Maybe<InventoryLotMutationResponse>;
  /** insert a single row into the table: "inventory_lot" */
  insertInventoryLotOne?: Maybe<InventoryLot>;
  /** insert data into the table: "inventory_transaction" */
  insertInventoryTransaction?: Maybe<InventoryTransactionMutationResponse>;
  /** insert a single row into the table: "inventory_transaction" */
  insertInventoryTransactionOne?: Maybe<InventoryTransaction>;
  /** insert data into the table: "visits_invoice" */
  insertInvoice?: Maybe<InvoiceMutationResponse>;
  /** insert a single row into the table: "visits_invoice" */
  insertInvoiceOne?: Maybe<Invoice>;
  /** insert data into the table: "marketing_template" */
  insertMarketingTemplate?: Maybe<MarketingTemplateMutationResponse>;
  /** insert a single row into the table: "marketing_template" */
  insertMarketingTemplateOne?: Maybe<MarketingTemplate>;
  /** insert data into the table: "md_meeting" */
  insertMdMeeting?: Maybe<MdMeetingMutationResponse>;
  /** insert data into the table: "md_meeting_attendees" */
  insertMdMeetingAttendees?: Maybe<MdMeetingAttendeesMutationResponse>;
  /** insert a single row into the table: "md_meeting_attendees" */
  insertMdMeetingAttendeesOne?: Maybe<MdMeetingAttendees>;
  /** insert data into the table: "md_meeting_note" */
  insertMdMeetingNote?: Maybe<MdMeetingNoteMutationResponse>;
  /** insert a single row into the table: "md_meeting_note" */
  insertMdMeetingNoteOne?: Maybe<MdMeetingNote>;
  /** insert data into the table: "md_meeting_note_template" */
  insertMdMeetingNoteTemplate?: Maybe<MdMeetingNoteTemplateMutationResponse>;
  /** insert a single row into the table: "md_meeting_note_template" */
  insertMdMeetingNoteTemplateOne?: Maybe<MdMeetingNoteTemplate>;
  /** insert a single row into the table: "md_meeting" */
  insertMdMeetingOne?: Maybe<MdMeeting>;
  /** insert data into the table: "medspas_medicaldirector" */
  insertMedicalDirector?: Maybe<MedicalDirectorMutationResponse>;
  /** insert a single row into the table: "medspas_medicaldirector" */
  insertMedicalDirectorOne?: Maybe<MedicalDirector>;
  /** insert data into the table: "medspas_medicaldirectorsubscription" */
  insertMedicalDirectorSubscription?: Maybe<MedicalDirectorSubscriptionMutationResponse>;
  /** insert a single row into the table: "medspas_medicaldirectorsubscription" */
  insertMedicalDirectorSubscriptionOne?: Maybe<MedicalDirectorSubscription>;
  /** insert data into the table: "medspas_medspa" */
  insertMedspa?: Maybe<MedspaMutationResponse>;
  /** insert a single row into the table: "medspas_medspa" */
  insertMedspaOne?: Maybe<Medspa>;
  /** insert data into the table: "medspa_rooms_appointments" */
  insertMedspaRoomsAppointments?: Maybe<MedspaRoomsAppointmentsMutationResponse>;
  /** insert a single row into the table: "medspa_rooms_appointments" */
  insertMedspaRoomsAppointmentsOne?: Maybe<MedspaRoomsAppointments>;
  /** insert data into the table: "medspa_service_menu_item" */
  insertMedspaServiceMenuItem?: Maybe<MedspaServiceMenuItemMutationResponse>;
  /** insert data into the table: "medspa_service_menu_item_medspa_service_offerings" */
  insertMedspaServiceMenuItemMedspaServiceOfferings?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsMutationResponse>;
  /** insert a single row into the table: "medspa_service_menu_item_medspa_service_offerings" */
  insertMedspaServiceMenuItemMedspaServiceOfferingsOne?: Maybe<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** insert data into the table: "medspa_service_menu_item_old_products" */
  insertMedspaServiceMenuItemOldProducts?: Maybe<MedspaServiceMenuItemOldProductsMutationResponse>;
  /** insert a single row into the table: "medspa_service_menu_item_old_products" */
  insertMedspaServiceMenuItemOldProductsOne?: Maybe<MedspaServiceMenuItemOldProducts>;
  /** insert a single row into the table: "medspa_service_menu_item" */
  insertMedspaServiceMenuItemOne?: Maybe<MedspaServiceMenuItem>;
  /** insert data into the table: "medspa_service_menu_item_providers" */
  insertMedspaServiceMenuItemProviders?: Maybe<MedspaServiceMenuItemProvidersMutationResponse>;
  /** insert a single row into the table: "medspa_service_menu_item_providers" */
  insertMedspaServiceMenuItemProvidersOne?: Maybe<MedspaServiceMenuItemProviders>;
  /** insert data into the table: "medspa_service_offering" */
  insertMedspaServiceOffering?: Maybe<MedspaServiceOfferingMutationResponse>;
  /** insert data into the table: "medspa_service_offering_note_templates" */
  insertMedspaServiceOfferingNoteTemplates?: Maybe<MedspaServiceOfferingNoteTemplatesMutationResponse>;
  /** insert a single row into the table: "medspa_service_offering_note_templates" */
  insertMedspaServiceOfferingNoteTemplatesOne?: Maybe<MedspaServiceOfferingNoteTemplates>;
  /** insert data into the table: "medspa_service_offering_notes" */
  insertMedspaServiceOfferingNotes?: Maybe<MedspaServiceOfferingNotesMutationResponse>;
  /** insert a single row into the table: "medspa_service_offering_notes" */
  insertMedspaServiceOfferingNotesOne?: Maybe<MedspaServiceOfferingNotes>;
  /** insert a single row into the table: "medspa_service_offering" */
  insertMedspaServiceOfferingOne?: Maybe<MedspaServiceOffering>;
  /** insert data into the table: "medspa_service_product" */
  insertMedspaServiceProduct?: Maybe<MedspaServiceProductMutationResponse>;
  /** insert a single row into the table: "medspa_service_product" */
  insertMedspaServiceProductOne?: Maybe<MedspaServiceProduct>;
  /** insert data into the table: "medspa_service_protocol_template" */
  insertMedspaServiceProtocolTemplate?: Maybe<MedspaServiceProtocolTemplateMutationResponse>;
  /** insert a single row into the table: "medspa_service_protocol_template" */
  insertMedspaServiceProtocolTemplateOne?: Maybe<MedspaServiceProtocolTemplate>;
  /** insert data into the table: "medspa_standing_order_template" */
  insertMedspaStandingOrderTemplate?: Maybe<MedspaStandingOrderTemplateMutationResponse>;
  /** insert a single row into the table: "medspa_standing_order_template" */
  insertMedspaStandingOrderTemplateOne?: Maybe<MedspaStandingOrderTemplate>;
  /** insert data into the table: "medspa_wallet_credit_type" */
  insertMedspaWalletCreditType?: Maybe<MedspaWalletCreditTypeMutationResponse>;
  /** insert a single row into the table: "medspa_wallet_credit_type" */
  insertMedspaWalletCreditTypeOne?: Maybe<MedspaWalletCreditType>;
  /** insert data into the table: "medspas_featureflag" */
  insertMedspasFeatureflag?: Maybe<MedspasFeatureflagMutationResponse>;
  /** insert a single row into the table: "medspas_featureflag" */
  insertMedspasFeatureflagOne?: Maybe<MedspasFeatureflag>;
  /** insert data into the table: "medspas_medicallicense" */
  insertMedspasMedicallicense?: Maybe<MedspasMedicallicenseMutationResponse>;
  /** insert a single row into the table: "medspas_medicallicense" */
  insertMedspasMedicallicenseOne?: Maybe<MedspasMedicallicense>;
  /** insert data into the table: "medspas_medspaconfiguration" */
  insertMedspasMedspaconfiguration?: Maybe<MedspasMedspaconfigurationMutationResponse>;
  /** insert a single row into the table: "medspas_medspaconfiguration" */
  insertMedspasMedspaconfigurationOne?: Maybe<MedspasMedspaconfiguration>;
  /** insert data into the table: "medspas_professionalcorporation" */
  insertMedspasProfessionalcorporation?: Maybe<MedspasProfessionalcorporationMutationResponse>;
  /** insert a single row into the table: "medspas_professionalcorporation" */
  insertMedspasProfessionalcorporationOne?: Maybe<MedspasProfessionalcorporation>;
  /** insert data into the table: "medspas_stripeoutboundpaymentmethod" */
  insertMedspasStripeoutboundpaymentmethod?: Maybe<MedspasStripeoutboundpaymentmethodMutationResponse>;
  /** insert a single row into the table: "medspas_stripeoutboundpaymentmethod" */
  insertMedspasStripeoutboundpaymentmethodOne?: Maybe<MedspasStripeoutboundpaymentmethod>;
  /** insert data into the table: "medspas_usermedspa_acquired_medical_licenses" */
  insertMedspasUsermedspaAcquiredMedicalLicenses?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesMutationResponse>;
  /** insert a single row into the table: "medspas_usermedspa_acquired_medical_licenses" */
  insertMedspasUsermedspaAcquiredMedicalLicensesOne?: Maybe<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** insert data into the table: "membership" */
  insertMembership?: Maybe<MembershipMutationResponse>;
  /** insert data into the table: "membership_category" */
  insertMembershipCategory?: Maybe<MembershipCategoryMutationResponse>;
  /** insert a single row into the table: "membership_category" */
  insertMembershipCategoryOne?: Maybe<MembershipCategory>;
  /** insert data into the table: "membership_line" */
  insertMembershipLine?: Maybe<MembershipLineMutationResponse>;
  /** insert a single row into the table: "membership_line" */
  insertMembershipLineOne?: Maybe<MembershipLine>;
  /** insert a single row into the table: "membership" */
  insertMembershipOne?: Maybe<Membership>;
  /** insert data into the table: "membership_perk" */
  insertMembershipPerk?: Maybe<MembershipPerkMutationResponse>;
  /** insert data into the table: "membership_perk_line" */
  insertMembershipPerkLine?: Maybe<MembershipPerkLineMutationResponse>;
  /** insert a single row into the table: "membership_perk_line" */
  insertMembershipPerkLineOne?: Maybe<MembershipPerkLine>;
  /** insert a single row into the table: "membership_perk" */
  insertMembershipPerkOne?: Maybe<MembershipPerk>;
  /** insert data into the table: "model_permission" */
  insertModelPermission?: Maybe<ModelPermissionMutationResponse>;
  /** insert a single row into the table: "model_permission" */
  insertModelPermissionOne?: Maybe<ModelPermission>;
  /** insert data into the table: "visits_note" */
  insertNote?: Maybe<NoteMutationResponse>;
  /** insert data into the table: "visits_notechangelog" */
  insertNoteChangeLog?: Maybe<NoteChangeLogMutationResponse>;
  /** insert a single row into the table: "visits_notechangelog" */
  insertNoteChangeLogOne?: Maybe<NoteChangeLog>;
  /** insert data into the table: "visits_note_form_submissions" */
  insertNoteFormSubmission?: Maybe<NoteFormSubmissionMutationResponse>;
  /** insert a single row into the table: "visits_note_form_submissions" */
  insertNoteFormSubmissionOne?: Maybe<NoteFormSubmission>;
  /** insert a single row into the table: "visits_note" */
  insertNoteOne?: Maybe<Note>;
  /** insert data into the table: "resources_notetemplate" */
  insertNoteTemplate?: Maybe<NoteTemplateMutationResponse>;
  /** insert a single row into the table: "resources_notetemplate" */
  insertNoteTemplateOne?: Maybe<NoteTemplate>;
  /** insert data into the table: "resources_package" */
  insertPackage?: Maybe<PackageMutationResponse>;
  /** insert data into the table: "resources_packageitem" */
  insertPackageItem?: Maybe<PackageItemMutationResponse>;
  /** insert data into the table: "package_item_line" */
  insertPackageItemLine?: Maybe<PackageItemLineMutationResponse>;
  /** insert a single row into the table: "package_item_line" */
  insertPackageItemLineOne?: Maybe<PackageItemLine>;
  /** insert a single row into the table: "resources_packageitem" */
  insertPackageItemOne?: Maybe<PackageItem>;
  /** insert data into the table: "visits_packageline" */
  insertPackageLine?: Maybe<PackageLineMutationResponse>;
  /** insert a single row into the table: "visits_packageline" */
  insertPackageLineOne?: Maybe<PackageLine>;
  /** insert a single row into the table: "resources_package" */
  insertPackageOne?: Maybe<Package>;
  /** insert data into the table: "payments_payment" */
  insertPayment?: Maybe<PaymentMutationResponse>;
  /** insert a single row into the table: "payments_payment" */
  insertPaymentOne?: Maybe<Payment>;
  /** insert data into the table: "visits_photo" */
  insertPhoto?: Maybe<PhotoMutationResponse>;
  /** insert a single row into the table: "visits_photo" */
  insertPhotoOne?: Maybe<Photo>;
  /** insert data into the table: "resources_product" */
  insertProduct?: Maybe<ProductMutationResponse>;
  /** insert data into the table: "visits_productline" */
  insertProductLine?: Maybe<ProductLineMutationResponse>;
  /** insert a single row into the table: "visits_productline" */
  insertProductLineOne?: Maybe<ProductLine>;
  /** insert a single row into the table: "resources_product" */
  insertProductOne?: Maybe<Product>;
  /** insert data into the table: "provider_not_require_documents" */
  insertProviderNotRequireDocuments?: Maybe<ProviderNotRequireDocumentsMutationResponse>;
  /** insert a single row into the table: "provider_not_require_documents" */
  insertProviderNotRequireDocumentsOne?: Maybe<ProviderNotRequireDocuments>;
  /** insert data into the table: "scheduling_recurring_availability" */
  insertRecurringAvailability?: Maybe<RecurringAvailabilityMutationResponse>;
  /** insert a single row into the table: "scheduling_recurring_availability" */
  insertRecurringAvailabilityOne?: Maybe<RecurringAvailability>;
  /** insert data into the table: "payments_refund" */
  insertRefund?: Maybe<RefundMutationResponse>;
  /** insert a single row into the table: "payments_refund" */
  insertRefundOne?: Maybe<Refund>;
  /** insert data into the table: "medspas_report" */
  insertReport?: Maybe<ReportMutationResponse>;
  /** insert data into the table: "medspas_reportcategory" */
  insertReportCategory?: Maybe<ReportCategoryMutationResponse>;
  /** insert a single row into the table: "medspas_reportcategory" */
  insertReportCategoryOne?: Maybe<ReportCategory>;
  /** insert a single row into the table: "medspas_report" */
  insertReportOne?: Maybe<Report>;
  /** insert data into the table: "resources_medspa_room" */
  insertResourcesMedspaRoom?: Maybe<ResourcesMedspaRoomMutationResponse>;
  /** insert a single row into the table: "resources_medspa_room" */
  insertResourcesMedspaRoomOne?: Maybe<ResourcesMedspaRoom>;
  /** insert data into the table: "scheduling_appointment" */
  insertSchedulingAppointment?: Maybe<SchedulingAppointmentMutationResponse>;
  /** insert a single row into the table: "scheduling_appointment" */
  insertSchedulingAppointmentOne?: Maybe<SchedulingAppointment>;
  /** insert data into the table: "script_product" */
  insertScriptProduct?: Maybe<ScriptProductMutationResponse>;
  /** insert a single row into the table: "script_product" */
  insertScriptProductOne?: Maybe<ScriptProduct>;
  /** insert data into the table: "script_request" */
  insertScriptRequest?: Maybe<ScriptRequestMutationResponse>;
  /** insert a single row into the table: "script_request" */
  insertScriptRequestOne?: Maybe<ScriptRequest>;
  /** insert data into the table: "script_request_status_change_log" */
  insertScriptRequestStatusChangeLog?: Maybe<ScriptRequestStatusChangeLogMutationResponse>;
  /** insert a single row into the table: "script_request_status_change_log" */
  insertScriptRequestStatusChangeLogOne?: Maybe<ScriptRequestStatusChangeLog>;
  /** insert data into the table: "resources_servicecategory" */
  insertServiceCategory?: Maybe<ServiceCategoryMutationResponse>;
  /** insert a single row into the table: "resources_servicecategory" */
  insertServiceCategoryOne?: Maybe<ServiceCategory>;
  /** insert data into the table: "service_device" */
  insertServiceDevice?: Maybe<ServiceDeviceMutationResponse>;
  /** insert a single row into the table: "service_device" */
  insertServiceDeviceOne?: Maybe<ServiceDevice>;
  /** insert data into the table: "service_devices_appointments" */
  insertServiceDevicesAppointments?: Maybe<ServiceDevicesAppointmentsMutationResponse>;
  /** insert a single row into the table: "service_devices_appointments" */
  insertServiceDevicesAppointmentsOne?: Maybe<ServiceDevicesAppointments>;
  /** insert data into the table: "service_devices_service_types" */
  insertServiceDevicesServiceTypes?: Maybe<ServiceDevicesServiceTypesMutationResponse>;
  /** insert a single row into the table: "service_devices_service_types" */
  insertServiceDevicesServiceTypesOne?: Maybe<ServiceDevicesServiceTypes>;
  /** insert data into the table: "service_menu_item_line" */
  insertServiceMenuItemLine?: Maybe<ServiceMenuItemLineMutationResponse>;
  /** insert a single row into the table: "service_menu_item_line" */
  insertServiceMenuItemLineOne?: Maybe<ServiceMenuItemLine>;
  /** insert data into the table: "service_menu_item_line_standing_orders" */
  insertServiceMenuItemLineStandingOrders?: Maybe<ServiceMenuItemLineStandingOrdersMutationResponse>;
  /** insert a single row into the table: "service_menu_item_line_standing_orders" */
  insertServiceMenuItemLineStandingOrdersOne?: Maybe<ServiceMenuItemLineStandingOrders>;
  /** insert data into the table: "service_product" */
  insertServiceProduct?: Maybe<ServiceProductMutationResponse>;
  /** insert data into the table: "service_product_charting_note" */
  insertServiceProductChartingNote?: Maybe<ServiceProductChartingNoteMutationResponse>;
  /** insert a single row into the table: "service_product_charting_note" */
  insertServiceProductChartingNoteOne?: Maybe<ServiceProductChartingNote>;
  /** insert data into the table: "service_product_line" */
  insertServiceProductLine?: Maybe<ServiceProductLineMutationResponse>;
  /** insert a single row into the table: "service_product_line" */
  insertServiceProductLineOne?: Maybe<ServiceProductLine>;
  /** insert a single row into the table: "service_product" */
  insertServiceProductOne?: Maybe<ServiceProduct>;
  /** insert data into the table: "service_product_type" */
  insertServiceProductType?: Maybe<ServiceProductTypeMutationResponse>;
  /** insert a single row into the table: "service_product_type" */
  insertServiceProductTypeOne?: Maybe<ServiceProductType>;
  /** insert data into the table: "service_product_usage" */
  insertServiceProductUsage?: Maybe<ServiceProductUsageMutationResponse>;
  /** insert a single row into the table: "service_product_usage" */
  insertServiceProductUsageOne?: Maybe<ServiceProductUsage>;
  /** insert data into the table: "service_protocol" */
  insertServiceProtocol?: Maybe<ServiceProtocolMutationResponse>;
  /** insert a single row into the table: "service_protocol" */
  insertServiceProtocolOne?: Maybe<ServiceProtocol>;
  /** insert data into the table: "service_protocol_template" */
  insertServiceProtocolTemplate?: Maybe<ServiceProtocolTemplateMutationResponse>;
  /** insert a single row into the table: "service_protocol_template" */
  insertServiceProtocolTemplateOne?: Maybe<ServiceProtocolTemplate>;
  /** insert data into the table: "service_type" */
  insertServiceType?: Maybe<ServiceTypeMutationResponse>;
  /** insert data into the table: "service_type_category" */
  insertServiceTypeCategory?: Maybe<ServiceTypeCategoryMutationResponse>;
  /** insert a single row into the table: "service_type_category" */
  insertServiceTypeCategoryOne?: Maybe<ServiceTypeCategory>;
  /** insert a single row into the table: "service_type" */
  insertServiceTypeOne?: Maybe<ServiceType>;
  /** insert data into the table: "utils_shortmoxieurl" */
  insertShortMoxieUrl?: Maybe<ShortMoxieUrlMutationResponse>;
  /** insert a single row into the table: "utils_shortmoxieurl" */
  insertShortMoxieUrlOne?: Maybe<ShortMoxieUrl>;
  /** insert data into the table: "medspas_standingorder" */
  insertStandingOrder?: Maybe<StandingOrderMutationResponse>;
  /** insert a single row into the table: "medspas_standingorder" */
  insertStandingOrderOne?: Maybe<StandingOrder>;
  /** insert data into the table: "standing_order_template" */
  insertStandingOrderTemplate?: Maybe<StandingOrderTemplateMutationResponse>;
  /** insert a single row into the table: "standing_order_template" */
  insertStandingOrderTemplateOne?: Maybe<StandingOrderTemplate>;
  /** insert data into the table: "supplier_information" */
  insertSupplierInformation?: Maybe<SupplierInformationMutationResponse>;
  /** insert a single row into the table: "supplier_information" */
  insertSupplierInformationOne?: Maybe<SupplierInformation>;
  /** insert data into the table: "telehealth_visit_details" */
  insertTelehealthVisitDetails?: Maybe<TelehealthVisitDetailsMutationResponse>;
  /** insert a single row into the table: "telehealth_visit_details" */
  insertTelehealthVisitDetailsOne?: Maybe<TelehealthVisitDetails>;
  /** insert data into the table: "standing_order" */
  insertTmpStandingOrder?: Maybe<TmpStandingOrderMutationResponse>;
  /** insert a single row into the table: "standing_order" */
  insertTmpStandingOrderOne?: Maybe<TmpStandingOrder>;
  /** insert data into the table: "url_pattern_permission" */
  insertUrlPatternPermission?: Maybe<UrlPatternPermissionMutationResponse>;
  /** insert a single row into the table: "url_pattern_permission" */
  insertUrlPatternPermissionOne?: Maybe<UrlPatternPermission>;
  /** insert data into the table: "accounts_user" */
  insertUser?: Maybe<UserMutationResponse>;
  /** insert data into the table: "medspas_usermedspa" */
  insertUserMedspa?: Maybe<UserMedspaMutationResponse>;
  /** insert a single row into the table: "medspas_usermedspa" */
  insertUserMedspaOne?: Maybe<UserMedspa>;
  /** insert a single row into the table: "accounts_user" */
  insertUserOne?: Maybe<User>;
  /** insert data into the table: "visits_visit" */
  insertVisit?: Maybe<VisitMutationResponse>;
  /** insert a single row into the table: "visits_visit" */
  insertVisitOne?: Maybe<Visit>;
  /** insert data into the table: "visits_visitreviewstatuschangelog" */
  insertVisitReviewStatusChangeLog?: Maybe<VisitReviewStatusChangeLogMutationResponse>;
  /** insert a single row into the table: "visits_visitreviewstatuschangelog" */
  insertVisitReviewStatusChangeLogOne?: Maybe<VisitReviewStatusChangeLog>;
  /** insert data into the table: "visits_visitstatuschangelog" */
  insertVisitStatusChangeLog?: Maybe<VisitStatusChangeLogMutationResponse>;
  /** insert a single row into the table: "visits_visitstatuschangelog" */
  insertVisitStatusChangeLogOne?: Maybe<VisitStatusChangeLog>;
  /** insert data into the table: "visits_adversereactionchangelog" */
  insertVisitsAdversereactionchangelog?: Maybe<VisitsAdversereactionchangelogMutationResponse>;
  /** insert a single row into the table: "visits_adversereactionchangelog" */
  insertVisitsAdversereactionchangelogOne?: Maybe<VisitsAdversereactionchangelog>;
  /** insert data into the table: "visits_chartsignature" */
  insertVisitsChartsignature?: Maybe<VisitsChartsignatureMutationResponse>;
  /** insert a single row into the table: "visits_chartsignature" */
  insertVisitsChartsignatureOne?: Maybe<VisitsChartsignature>;
  /** insert data into the table: "visits_telehealthvisitdetailschangelog" */
  insertVisitsTelehealthvisitdetailschangelog?: Maybe<VisitsTelehealthvisitdetailschangelogMutationResponse>;
  /** insert a single row into the table: "visits_telehealthvisitdetailschangelog" */
  insertVisitsTelehealthvisitdetailschangelogOne?: Maybe<VisitsTelehealthvisitdetailschangelog>;
  /** insert data into the table: "wallet_credit_line" */
  insertWalletCreditLine?: Maybe<WalletCreditLineMutationResponse>;
  /** insert a single row into the table: "wallet_credit_line" */
  insertWalletCreditLineOne?: Maybe<WalletCreditLine>;
  /** insert data into the table: "wallet_credit_type" */
  insertWalletCreditType?: Maybe<WalletCreditTypeMutationResponse>;
  /** insert a single row into the table: "wallet_credit_type" */
  insertWalletCreditTypeOne?: Maybe<WalletCreditType>;
  /** insert data into the table: "wallets_wallettransaction" */
  insertWalletTransaction?: Maybe<WalletTransactionMutationResponse>;
  /** insert a single row into the table: "wallets_wallettransaction" */
  insertWalletTransactionOne?: Maybe<WalletTransaction>;
  /** insert data into the table: "wallet_transaction_source_tmp" */
  insertWalletTransactionSourceTmp?: Maybe<WalletTransactionSourceTmpMutationResponse>;
  /** insert a single row into the table: "wallet_transaction_source_tmp" */
  insertWalletTransactionSourceTmpOne?: Maybe<WalletTransactionSourceTmp>;
  /** insert data into the table: "wallet_transactions_sources" */
  insertWalletTransactionsSources?: Maybe<WalletTransactionsSourcesMutationResponse>;
  /** insert a single row into the table: "wallet_transactions_sources" */
  insertWalletTransactionsSourcesOne?: Maybe<WalletTransactionsSources>;
  joinTwilioConversation?: Maybe<JoinTwilioConversation>;
  leaveTwilioConversation?: Maybe<LeaveTwilioConversation>;
  makeRefund?: Maybe<MakeRefund>;
  markInvoiceAsPaid?: Maybe<MarkInvoiceAsPaid>;
  payAffirm?: Maybe<PayAffirm>;
  payCard?: Maybe<PayCard>;
  payExternalMethod?: Maybe<PayExternalMethod>;
  payTerminal?: Maybe<PayTerminal>;
  payWallet?: Maybe<PayWallet>;
  refreshClientAccessToken?: Maybe<RefreshClientAccessToken>;
  refreshServiceMenuItemLineGfeStatus?: Maybe<RefreshServiceMenuItemLineGfeStatus>;
  registerReaderForMerchant?: Maybe<RegisterReaderForMerchant>;
  removeAdverseReaction?: Maybe<RemoveAdverseReaction>;
  removeAvailabilityEvent?: Maybe<RemoveAvailabilityEvent>;
  removeDiagramTemplate?: Maybe<RemoveDiagramTemplate>;
  removeRecurringAvailability?: Maybe<RemoveRecurringAvailability>;
  removeStripeTerminal?: Maybe<RemoveStripeTerminal>;
  requestConsentForms?: Maybe<RequestConsentForms>;
  requestGfePartialReReview?: Maybe<RequestGfePartialReReview>;
  requestGfeReview?: Maybe<RequestGfeReview>;
  requestSyncGfeInvite?: Maybe<RequestSyncGfeInvite>;
  rescheduleAppointment?: Maybe<RescheduleAppointment>;
  rescheduleAppointmentByClient?: Maybe<RescheduleAppointmentByClient>;
  rescheduleAvailabilityEvent?: Maybe<RescheduleAvailabilityEvent>;
  rescheduleMdMeeting?: Maybe<RescheduleMdMeeting>;
  sendClientLoginLink?: Maybe<SendClientLoginLink>;
  sendClientPortalLink?: Maybe<SendClientPortalLink>;
  sendForms?: Maybe<SendForms>;
  sendFormsToGfeReview?: Maybe<SendFormsToGfeReview>;
  sendGfeReviewRequestMailToMd?: Maybe<SendGfeReviewRequestMailToMd>;
  sendNoShowFeeCommunication?: Maybe<SendNoShowFeeCommunication>;
  sendReceipt?: Maybe<SendReceipt>;
  sendVisitToReview?: Maybe<SendVisitToReview>;
  signChart?: Maybe<SignChart>;
  splitChartingNote?: Maybe<SplitChartingNote>;
  submitGfeReview?: Maybe<SubmitGfeReview>;
  submitSyncGfeReview?: Maybe<SubmitSyncGfeReview>;
  /** update data of the table: "utils_address" */
  updateAddress?: Maybe<AddressMutationResponse>;
  /** update single row of the table: "utils_address" */
  updateAddressByPk?: Maybe<Address>;
  /** update multiples rows of table: "utils_address" */
  updateAddressMany?: Maybe<Array<Maybe<AddressMutationResponse>>>;
  /** update data of the table: "administrative_division" */
  updateAdministrativeDivision?: Maybe<AdministrativeDivisionMutationResponse>;
  /** update single row of the table: "administrative_division" */
  updateAdministrativeDivisionByPk?: Maybe<AdministrativeDivision>;
  /** update multiples rows of table: "administrative_division" */
  updateAdministrativeDivisionMany?: Maybe<
    Array<Maybe<AdministrativeDivisionMutationResponse>>
  >;
  /** update data of the table: "adverse_reaction" */
  updateAdverseReaction?: Maybe<AdverseReactionMutationResponse>;
  /** update single row of the table: "adverse_reaction" */
  updateAdverseReactionByPk?: Maybe<AdverseReaction>;
  /** update multiples rows of table: "adverse_reaction" */
  updateAdverseReactionMany?: Maybe<
    Array<Maybe<AdverseReactionMutationResponse>>
  >;
  /** update data of the table: "adverse_reaction_template" */
  updateAdverseReactionTemplate?: Maybe<AdverseReactionTemplateMutationResponse>;
  /** update single row of the table: "adverse_reaction_template" */
  updateAdverseReactionTemplateByPk?: Maybe<AdverseReactionTemplate>;
  /** update multiples rows of table: "adverse_reaction_template" */
  updateAdverseReactionTemplateMany?: Maybe<
    Array<Maybe<AdverseReactionTemplateMutationResponse>>
  >;
  /** update data of the table: "scheduling_availabilityevent" */
  updateAvailabilityEvent?: Maybe<AvailabilityEventMutationResponse>;
  /** update single row of the table: "scheduling_availabilityevent" */
  updateAvailabilityEventByPk?: Maybe<AvailabilityEvent>;
  /** update multiples rows of table: "scheduling_availabilityevent" */
  updateAvailabilityEventMany?: Maybe<
    Array<Maybe<AvailabilityEventMutationResponse>>
  >;
  /** update data of the table: "resources_careform" */
  updateCareForm?: Maybe<CareFormMutationResponse>;
  /** update single row of the table: "resources_careform" */
  updateCareFormByPk?: Maybe<CareForm>;
  /** update multiples rows of table: "resources_careform" */
  updateCareFormMany?: Maybe<Array<Maybe<CareFormMutationResponse>>>;
  /** update data of the table: "resources_careform_service_menu_items" */
  updateCareFormServiceMenuItems?: Maybe<CareFormServiceMenuItemsMutationResponse>;
  /** update single row of the table: "resources_careform_service_menu_items" */
  updateCareFormServiceMenuItemsByPk?: Maybe<CareFormServiceMenuItems>;
  /** update multiples rows of table: "resources_careform_service_menu_items" */
  updateCareFormServiceMenuItemsMany?: Maybe<
    Array<Maybe<CareFormServiceMenuItemsMutationResponse>>
  >;
  updateCherryApiKey?: Maybe<UpdateCherryApiKeyMutation>;
  /** update data of the table: "medspas_client" */
  updateClient?: Maybe<ClientMutationResponse>;
  /** update data of the table: "medspas_clientaccesstoken" */
  updateClientAccessToken?: Maybe<ClientAccessTokenMutationResponse>;
  /** update single row of the table: "medspas_clientaccesstoken" */
  updateClientAccessTokenByPk?: Maybe<ClientAccessToken>;
  /** update multiples rows of table: "medspas_clientaccesstoken" */
  updateClientAccessTokenMany?: Maybe<
    Array<Maybe<ClientAccessTokenMutationResponse>>
  >;
  /** update single row of the table: "medspas_client" */
  updateClientByPk?: Maybe<Client>;
  /** update multiples rows of table: "medspas_client" */
  updateClientMany?: Maybe<Array<Maybe<ClientMutationResponse>>>;
  /** update data of the table: "client_membership" */
  updateClientMembership?: Maybe<ClientMembershipMutationResponse>;
  /** update single row of the table: "client_membership" */
  updateClientMembershipByPk?: Maybe<ClientMembership>;
  /** update multiples rows of table: "client_membership" */
  updateClientMembershipMany?: Maybe<
    Array<Maybe<ClientMembershipMutationResponse>>
  >;
  /** update data of the table: "compliance_task" */
  updateComplianceTask?: Maybe<ComplianceTaskMutationResponse>;
  /** update single row of the table: "compliance_task" */
  updateComplianceTaskByPk?: Maybe<ComplianceTask>;
  /** update data of the table: "compliance_task_document" */
  updateComplianceTaskDocument?: Maybe<ComplianceTaskDocumentMutationResponse>;
  /** update single row of the table: "compliance_task_document" */
  updateComplianceTaskDocumentByPk?: Maybe<ComplianceTaskDocument>;
  /** update multiples rows of table: "compliance_task_document" */
  updateComplianceTaskDocumentMany?: Maybe<
    Array<Maybe<ComplianceTaskDocumentMutationResponse>>
  >;
  /** update multiples rows of table: "compliance_task" */
  updateComplianceTaskMany?: Maybe<
    Array<Maybe<ComplianceTaskMutationResponse>>
  >;
  /** update data of the table: "compliance_task_template" */
  updateComplianceTaskTemplate?: Maybe<ComplianceTaskTemplateMutationResponse>;
  /** update single row of the table: "compliance_task_template" */
  updateComplianceTaskTemplateByPk?: Maybe<ComplianceTaskTemplate>;
  /** update multiples rows of table: "compliance_task_template" */
  updateComplianceTaskTemplateMany?: Maybe<
    Array<Maybe<ComplianceTaskTemplateMutationResponse>>
  >;
  /** update data of the table: "compliant_note" */
  updateCompliantNote?: Maybe<CompliantNoteMutationResponse>;
  /** update single row of the table: "compliant_note" */
  updateCompliantNoteByPk?: Maybe<CompliantNote>;
  /** update multiples rows of table: "compliant_note" */
  updateCompliantNoteMany?: Maybe<Array<Maybe<CompliantNoteMutationResponse>>>;
  /** update data of the table: "compliant_note_template" */
  updateCompliantNoteTemplate?: Maybe<CompliantNoteTemplateMutationResponse>;
  /** update single row of the table: "compliant_note_template" */
  updateCompliantNoteTemplateByPk?: Maybe<CompliantNoteTemplate>;
  /** update multiples rows of table: "compliant_note_template" */
  updateCompliantNoteTemplateMany?: Maybe<
    Array<Maybe<CompliantNoteTemplateMutationResponse>>
  >;
  /** update data of the table: "resources_consentform" */
  updateConsentForm?: Maybe<ConsentFormMutationResponse>;
  /** update single row of the table: "resources_consentform" */
  updateConsentFormByPk?: Maybe<ConsentForm>;
  /** update multiples rows of table: "resources_consentform" */
  updateConsentFormMany?: Maybe<Array<Maybe<ConsentFormMutationResponse>>>;
  /** update data of the table: "resources_consentform_service_menu_items" */
  updateConsentFormServiceMenuItems?: Maybe<ConsentFormServiceMenuItemsMutationResponse>;
  /** update single row of the table: "resources_consentform_service_menu_items" */
  updateConsentFormServiceMenuItemsByPk?: Maybe<ConsentFormServiceMenuItems>;
  /** update multiples rows of table: "resources_consentform_service_menu_items" */
  updateConsentFormServiceMenuItemsMany?: Maybe<
    Array<Maybe<ConsentFormServiceMenuItemsMutationResponse>>
  >;
  /** update data of the table: "medspas_consentformsignature" */
  updateConsentFormSignature?: Maybe<ConsentFormSignatureMutationResponse>;
  /** update single row of the table: "medspas_consentformsignature" */
  updateConsentFormSignatureByPk?: Maybe<ConsentFormSignature>;
  /** update multiples rows of table: "medspas_consentformsignature" */
  updateConsentFormSignatureMany?: Maybe<
    Array<Maybe<ConsentFormSignatureMutationResponse>>
  >;
  updateDefaultCardForClient?: Maybe<UpdateDefaultCardForClient>;
  /** update data of the table: "deposit_line" */
  updateDepositLine?: Maybe<DepositLineMutationResponse>;
  /** update single row of the table: "deposit_line" */
  updateDepositLineByPk?: Maybe<DepositLine>;
  /** update multiples rows of table: "deposit_line" */
  updateDepositLineMany?: Maybe<Array<Maybe<DepositLineMutationResponse>>>;
  /** update data of the table: "visits_diagram" */
  updateDiagram?: Maybe<DiagramMutationResponse>;
  /** update single row of the table: "visits_diagram" */
  updateDiagramByPk?: Maybe<Diagram>;
  /** update multiples rows of table: "visits_diagram" */
  updateDiagramMany?: Maybe<Array<Maybe<DiagramMutationResponse>>>;
  /** update data of the table: "resources_diagramtemplate" */
  updateDiagramTemplate?: Maybe<DiagramTemplateMutationResponse>;
  /** update single row of the table: "resources_diagramtemplate" */
  updateDiagramTemplateByPk?: Maybe<DiagramTemplate>;
  /** update multiples rows of table: "resources_diagramtemplate" */
  updateDiagramTemplateMany?: Maybe<
    Array<Maybe<DiagramTemplateMutationResponse>>
  >;
  /** update data of the table: "resources_discount" */
  updateDiscount?: Maybe<DiscountMutationResponse>;
  /** update single row of the table: "resources_discount" */
  updateDiscountByPk?: Maybe<Discount>;
  /** update data of the table: "visits_discountline" */
  updateDiscountLine?: Maybe<DiscountLineMutationResponse>;
  /** update single row of the table: "visits_discountline" */
  updateDiscountLineByPk?: Maybe<DiscountLine>;
  /** update multiples rows of table: "visits_discountline" */
  updateDiscountLineMany?: Maybe<Array<Maybe<DiscountLineMutationResponse>>>;
  /** update multiples rows of table: "resources_discount" */
  updateDiscountMany?: Maybe<Array<Maybe<DiscountMutationResponse>>>;
  /** update data of the table: "discount_product" */
  updateDiscountRetailProducts?: Maybe<DiscountRetailProductsMutationResponse>;
  /** update single row of the table: "discount_product" */
  updateDiscountRetailProductsByPk?: Maybe<DiscountRetailProducts>;
  /** update multiples rows of table: "discount_product" */
  updateDiscountRetailProductsMany?: Maybe<
    Array<Maybe<DiscountRetailProductsMutationResponse>>
  >;
  /** update data of the table: "resources_discount_service_menu_items" */
  updateDiscountServiceMenuItems?: Maybe<DiscountServiceMenuItemsMutationResponse>;
  /** update single row of the table: "resources_discount_service_menu_items" */
  updateDiscountServiceMenuItemsByPk?: Maybe<DiscountServiceMenuItems>;
  /** update multiples rows of table: "resources_discount_service_menu_items" */
  updateDiscountServiceMenuItemsMany?: Maybe<
    Array<Maybe<DiscountServiceMenuItemsMutationResponse>>
  >;
  /** update data of the table: "resources_discount_service_products" */
  updateDiscountServiceProducts?: Maybe<DiscountServiceProductsMutationResponse>;
  /** update single row of the table: "resources_discount_service_products" */
  updateDiscountServiceProductsByPk?: Maybe<DiscountServiceProducts>;
  /** update multiples rows of table: "resources_discount_service_products" */
  updateDiscountServiceProductsMany?: Maybe<
    Array<Maybe<DiscountServiceProductsMutationResponse>>
  >;
  /** update data of the table: "feature_permission" */
  updateFeaturePermission?: Maybe<FeaturePermissionMutationResponse>;
  /** update single row of the table: "feature_permission" */
  updateFeaturePermissionByPk?: Maybe<FeaturePermission>;
  /** update multiples rows of table: "feature_permission" */
  updateFeaturePermissionMany?: Maybe<
    Array<Maybe<FeaturePermissionMutationResponse>>
  >;
  /** update data of the table: "resources_fee" */
  updateFee?: Maybe<FeeMutationResponse>;
  /** update single row of the table: "resources_fee" */
  updateFeeByPk?: Maybe<Fee>;
  /** update data of the table: "visits_feeline" */
  updateFeeLine?: Maybe<FeeLineMutationResponse>;
  /** update single row of the table: "visits_feeline" */
  updateFeeLineByPk?: Maybe<FeeLine>;
  /** update multiples rows of table: "visits_feeline" */
  updateFeeLineMany?: Maybe<Array<Maybe<FeeLineMutationResponse>>>;
  /** update multiples rows of table: "resources_fee" */
  updateFeeMany?: Maybe<Array<Maybe<FeeMutationResponse>>>;
  /** update data of the table: "forms_form" */
  updateForm?: Maybe<FormMutationResponse>;
  /** update single row of the table: "forms_form" */
  updateFormByPk?: Maybe<Form>;
  /** update multiples rows of table: "forms_form" */
  updateFormMany?: Maybe<Array<Maybe<FormMutationResponse>>>;
  /** update data of the table: "forms_form_service_menu_items" */
  updateFormServiceMenuItem?: Maybe<FormServiceMenuItemMutationResponse>;
  /** update single row of the table: "forms_form_service_menu_items" */
  updateFormServiceMenuItemByPk?: Maybe<FormServiceMenuItem>;
  /** update multiples rows of table: "forms_form_service_menu_items" */
  updateFormServiceMenuItemMany?: Maybe<
    Array<Maybe<FormServiceMenuItemMutationResponse>>
  >;
  /** update data of the table: "forms_formsubmission" */
  updateFormSubmission?: Maybe<FormSubmissionMutationResponse>;
  /** update single row of the table: "forms_formsubmission" */
  updateFormSubmissionByPk?: Maybe<FormSubmission>;
  /** update data of the table: "forms_formsubmissiongfestatuschangelog" */
  updateFormSubmissionGFEStatusChangeLog?: Maybe<FormSubmissionGfeStatusChangeLogMutationResponse>;
  /** update single row of the table: "forms_formsubmissiongfestatuschangelog" */
  updateFormSubmissionGFEStatusChangeLogByPk?: Maybe<FormSubmissionGfeStatusChangeLog>;
  /** update multiples rows of table: "forms_formsubmissiongfestatuschangelog" */
  updateFormSubmissionGFEStatusChangeLogMany?: Maybe<
    Array<Maybe<FormSubmissionGfeStatusChangeLogMutationResponse>>
  >;
  /** update multiples rows of table: "forms_formsubmission" */
  updateFormSubmissionMany?: Maybe<
    Array<Maybe<FormSubmissionMutationResponse>>
  >;
  /** update data of the table: "forms_form_medspa_service_offerings" */
  updateFormsFormMedspaServiceOfferings?: Maybe<FormsFormMedspaServiceOfferingsMutationResponse>;
  /** update single row of the table: "forms_form_medspa_service_offerings" */
  updateFormsFormMedspaServiceOfferingsByPk?: Maybe<FormsFormMedspaServiceOfferings>;
  /** update multiples rows of table: "forms_form_medspa_service_offerings" */
  updateFormsFormMedspaServiceOfferingsMany?: Maybe<
    Array<Maybe<FormsFormMedspaServiceOfferingsMutationResponse>>
  >;
  /** update data of the table: "forms_formsubmissionversion" */
  updateFormsFormsubmissionversion?: Maybe<FormsFormsubmissionversionMutationResponse>;
  /** update single row of the table: "forms_formsubmissionversion" */
  updateFormsFormsubmissionversionByPk?: Maybe<FormsFormsubmissionversion>;
  /** update multiples rows of table: "forms_formsubmissionversion" */
  updateFormsFormsubmissionversionMany?: Maybe<
    Array<Maybe<FormsFormsubmissionversionMutationResponse>>
  >;
  /** update data of the table: "gfe_diagnosis" */
  updateGfeDiagnosis?: Maybe<GfeDiagnosisMutationResponse>;
  /** update single row of the table: "gfe_diagnosis" */
  updateGfeDiagnosisByPk?: Maybe<GfeDiagnosis>;
  /** update multiples rows of table: "gfe_diagnosis" */
  updateGfeDiagnosisMany?: Maybe<Array<Maybe<GfeDiagnosisMutationResponse>>>;
  /** update data of the table: "gfe_review_diagnosis" */
  updateGfeReviewDiagnosis?: Maybe<GfeReviewDiagnosisMutationResponse>;
  /** update single row of the table: "gfe_review_diagnosis" */
  updateGfeReviewDiagnosisByPk?: Maybe<GfeReviewDiagnosis>;
  /** update multiples rows of table: "gfe_review_diagnosis" */
  updateGfeReviewDiagnosisMany?: Maybe<
    Array<Maybe<GfeReviewDiagnosisMutationResponse>>
  >;
  /** update data of the table: "gfe_review_request" */
  updateGfeReviewRequest?: Maybe<GfeReviewRequestMutationResponse>;
  /** update single row of the table: "gfe_review_request" */
  updateGfeReviewRequestByPk?: Maybe<GfeReviewRequest>;
  /** update multiples rows of table: "gfe_review_request" */
  updateGfeReviewRequestMany?: Maybe<
    Array<Maybe<GfeReviewRequestMutationResponse>>
  >;
  /** update data of the table: "gfe_review_submission" */
  updateGfeReviewSubmission?: Maybe<GfeReviewSubmissionMutationResponse>;
  /** update single row of the table: "gfe_review_submission" */
  updateGfeReviewSubmissionByPk?: Maybe<GfeReviewSubmission>;
  /** update multiples rows of table: "gfe_review_submission" */
  updateGfeReviewSubmissionMany?: Maybe<
    Array<Maybe<GfeReviewSubmissionMutationResponse>>
  >;
  /** update data of the table: "gfe_service_product_indication" */
  updateGfeServiceProductIndication?: Maybe<GfeServiceProductIndicationMutationResponse>;
  /** update single row of the table: "gfe_service_product_indication" */
  updateGfeServiceProductIndicationByPk?: Maybe<GfeServiceProductIndication>;
  /** update multiples rows of table: "gfe_service_product_indication" */
  updateGfeServiceProductIndicationMany?: Maybe<
    Array<Maybe<GfeServiceProductIndicationMutationResponse>>
  >;
  /** update data of the table: "gfe_synchronous_review_document" */
  updateGfeSynchronousReviewDocument?: Maybe<GfeSynchronousReviewDocumentMutationResponse>;
  /** update single row of the table: "gfe_synchronous_review_document" */
  updateGfeSynchronousReviewDocumentByPk?: Maybe<GfeSynchronousReviewDocument>;
  /** update multiples rows of table: "gfe_synchronous_review_document" */
  updateGfeSynchronousReviewDocumentMany?: Maybe<
    Array<Maybe<GfeSynchronousReviewDocumentMutationResponse>>
  >;
  /** update data of the table: "gfe_treatment_indication" */
  updateGfeTreatmentIndication?: Maybe<GfeTreatmentIndicationMutationResponse>;
  /** update single row of the table: "gfe_treatment_indication" */
  updateGfeTreatmentIndicationByPk?: Maybe<GfeTreatmentIndication>;
  /** update multiples rows of table: "gfe_treatment_indication" */
  updateGfeTreatmentIndicationMany?: Maybe<
    Array<Maybe<GfeTreatmentIndicationMutationResponse>>
  >;
  /** update data of the table: "gift_builder_banner" */
  updateGiftBuilderBanner?: Maybe<GiftBuilderBannerMutationResponse>;
  /** update single row of the table: "gift_builder_banner" */
  updateGiftBuilderBannerByPk?: Maybe<GiftBuilderBanner>;
  /** update multiples rows of table: "gift_builder_banner" */
  updateGiftBuilderBannerMany?: Maybe<
    Array<Maybe<GiftBuilderBannerMutationResponse>>
  >;
  updateHighLevelUnreadMessagesCount?: Maybe<UpdateHighLevelUnreadMessagesCount>;
  /** update data of the table: "inventory_lot" */
  updateInventoryLot?: Maybe<InventoryLotMutationResponse>;
  /** update single row of the table: "inventory_lot" */
  updateInventoryLotByPk?: Maybe<InventoryLot>;
  /** update multiples rows of table: "inventory_lot" */
  updateInventoryLotMany?: Maybe<Array<Maybe<InventoryLotMutationResponse>>>;
  /** update data of the table: "inventory_transaction" */
  updateInventoryTransaction?: Maybe<InventoryTransactionMutationResponse>;
  /** update single row of the table: "inventory_transaction" */
  updateInventoryTransactionByPk?: Maybe<InventoryTransaction>;
  /** update multiples rows of table: "inventory_transaction" */
  updateInventoryTransactionMany?: Maybe<
    Array<Maybe<InventoryTransactionMutationResponse>>
  >;
  /** update data of the table: "visits_invoice" */
  updateInvoice?: Maybe<InvoiceMutationResponse>;
  /** update single row of the table: "visits_invoice" */
  updateInvoiceByPk?: Maybe<Invoice>;
  /** update multiples rows of table: "visits_invoice" */
  updateInvoiceMany?: Maybe<Array<Maybe<InvoiceMutationResponse>>>;
  updateInvoiceStatus?: Maybe<UpdateInvoiceStatus>;
  /** update data of the table: "marketing_template" */
  updateMarketingTemplate?: Maybe<MarketingTemplateMutationResponse>;
  /** update single row of the table: "marketing_template" */
  updateMarketingTemplateByPk?: Maybe<MarketingTemplate>;
  /** update multiples rows of table: "marketing_template" */
  updateMarketingTemplateMany?: Maybe<
    Array<Maybe<MarketingTemplateMutationResponse>>
  >;
  /** update data of the table: "md_meeting" */
  updateMdMeeting?: Maybe<MdMeetingMutationResponse>;
  /** update data of the table: "md_meeting_attendees" */
  updateMdMeetingAttendees?: Maybe<MdMeetingAttendeesMutationResponse>;
  /** update single row of the table: "md_meeting_attendees" */
  updateMdMeetingAttendeesByPk?: Maybe<MdMeetingAttendees>;
  /** update multiples rows of table: "md_meeting_attendees" */
  updateMdMeetingAttendeesMany?: Maybe<
    Array<Maybe<MdMeetingAttendeesMutationResponse>>
  >;
  /** update single row of the table: "md_meeting" */
  updateMdMeetingByPk?: Maybe<MdMeeting>;
  /** update multiples rows of table: "md_meeting" */
  updateMdMeetingMany?: Maybe<Array<Maybe<MdMeetingMutationResponse>>>;
  /** update data of the table: "md_meeting_note" */
  updateMdMeetingNote?: Maybe<MdMeetingNoteMutationResponse>;
  /** update single row of the table: "md_meeting_note" */
  updateMdMeetingNoteByPk?: Maybe<MdMeetingNote>;
  /** update multiples rows of table: "md_meeting_note" */
  updateMdMeetingNoteMany?: Maybe<Array<Maybe<MdMeetingNoteMutationResponse>>>;
  /** update data of the table: "md_meeting_note_template" */
  updateMdMeetingNoteTemplate?: Maybe<MdMeetingNoteTemplateMutationResponse>;
  /** update single row of the table: "md_meeting_note_template" */
  updateMdMeetingNoteTemplateByPk?: Maybe<MdMeetingNoteTemplate>;
  /** update multiples rows of table: "md_meeting_note_template" */
  updateMdMeetingNoteTemplateMany?: Maybe<
    Array<Maybe<MdMeetingNoteTemplateMutationResponse>>
  >;
  updateMdStripeOnboardingDone?: Maybe<UpdateMdStripeOnboardingDone>;
  /** update data of the table: "medspas_medicaldirector" */
  updateMedicalDirector?: Maybe<MedicalDirectorMutationResponse>;
  /** update single row of the table: "medspas_medicaldirector" */
  updateMedicalDirectorByPk?: Maybe<MedicalDirector>;
  /** update multiples rows of table: "medspas_medicaldirector" */
  updateMedicalDirectorMany?: Maybe<
    Array<Maybe<MedicalDirectorMutationResponse>>
  >;
  /** update data of the table: "medspas_medicaldirectorsubscription" */
  updateMedicalDirectorSubscription?: Maybe<MedicalDirectorSubscriptionMutationResponse>;
  /** update single row of the table: "medspas_medicaldirectorsubscription" */
  updateMedicalDirectorSubscriptionByPk?: Maybe<MedicalDirectorSubscription>;
  /** update multiples rows of table: "medspas_medicaldirectorsubscription" */
  updateMedicalDirectorSubscriptionMany?: Maybe<
    Array<Maybe<MedicalDirectorSubscriptionMutationResponse>>
  >;
  /** update data of the table: "medspas_medspa" */
  updateMedspa?: Maybe<MedspaMutationResponse>;
  /** update single row of the table: "medspas_medspa" */
  updateMedspaByPk?: Maybe<Medspa>;
  updateMedspaClientCommunicationSettings?: Maybe<UpdateMedSpaClientCommunicationSettings>;
  /** update multiples rows of table: "medspas_medspa" */
  updateMedspaMany?: Maybe<Array<Maybe<MedspaMutationResponse>>>;
  /** update data of the table: "medspa_rooms_appointments" */
  updateMedspaRoomsAppointments?: Maybe<MedspaRoomsAppointmentsMutationResponse>;
  /** update single row of the table: "medspa_rooms_appointments" */
  updateMedspaRoomsAppointmentsByPk?: Maybe<MedspaRoomsAppointments>;
  /** update multiples rows of table: "medspa_rooms_appointments" */
  updateMedspaRoomsAppointmentsMany?: Maybe<
    Array<Maybe<MedspaRoomsAppointmentsMutationResponse>>
  >;
  /** update data of the table: "medspa_service_menu_item" */
  updateMedspaServiceMenuItem?: Maybe<MedspaServiceMenuItemMutationResponse>;
  /** update single row of the table: "medspa_service_menu_item" */
  updateMedspaServiceMenuItemByPk?: Maybe<MedspaServiceMenuItem>;
  /** update multiples rows of table: "medspa_service_menu_item" */
  updateMedspaServiceMenuItemMany?: Maybe<
    Array<Maybe<MedspaServiceMenuItemMutationResponse>>
  >;
  /** update data of the table: "medspa_service_menu_item_medspa_service_offerings" */
  updateMedspaServiceMenuItemMedspaServiceOfferings?: Maybe<MedspaServiceMenuItemMedspaServiceOfferingsMutationResponse>;
  /** update single row of the table: "medspa_service_menu_item_medspa_service_offerings" */
  updateMedspaServiceMenuItemMedspaServiceOfferingsByPk?: Maybe<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** update multiples rows of table: "medspa_service_menu_item_medspa_service_offerings" */
  updateMedspaServiceMenuItemMedspaServiceOfferingsMany?: Maybe<
    Array<Maybe<MedspaServiceMenuItemMedspaServiceOfferingsMutationResponse>>
  >;
  /** update data of the table: "medspa_service_menu_item_old_products" */
  updateMedspaServiceMenuItemOldProducts?: Maybe<MedspaServiceMenuItemOldProductsMutationResponse>;
  /** update single row of the table: "medspa_service_menu_item_old_products" */
  updateMedspaServiceMenuItemOldProductsByPk?: Maybe<MedspaServiceMenuItemOldProducts>;
  /** update multiples rows of table: "medspa_service_menu_item_old_products" */
  updateMedspaServiceMenuItemOldProductsMany?: Maybe<
    Array<Maybe<MedspaServiceMenuItemOldProductsMutationResponse>>
  >;
  /** update data of the table: "medspa_service_menu_item_providers" */
  updateMedspaServiceMenuItemProviders?: Maybe<MedspaServiceMenuItemProvidersMutationResponse>;
  /** update single row of the table: "medspa_service_menu_item_providers" */
  updateMedspaServiceMenuItemProvidersByPk?: Maybe<MedspaServiceMenuItemProviders>;
  /** update multiples rows of table: "medspa_service_menu_item_providers" */
  updateMedspaServiceMenuItemProvidersMany?: Maybe<
    Array<Maybe<MedspaServiceMenuItemProvidersMutationResponse>>
  >;
  /** update data of the table: "medspa_service_offering" */
  updateMedspaServiceOffering?: Maybe<MedspaServiceOfferingMutationResponse>;
  /** update single row of the table: "medspa_service_offering" */
  updateMedspaServiceOfferingByPk?: Maybe<MedspaServiceOffering>;
  /** update multiples rows of table: "medspa_service_offering" */
  updateMedspaServiceOfferingMany?: Maybe<
    Array<Maybe<MedspaServiceOfferingMutationResponse>>
  >;
  /** update data of the table: "medspa_service_offering_note_templates" */
  updateMedspaServiceOfferingNoteTemplates?: Maybe<MedspaServiceOfferingNoteTemplatesMutationResponse>;
  /** update single row of the table: "medspa_service_offering_note_templates" */
  updateMedspaServiceOfferingNoteTemplatesByPk?: Maybe<MedspaServiceOfferingNoteTemplates>;
  /** update multiples rows of table: "medspa_service_offering_note_templates" */
  updateMedspaServiceOfferingNoteTemplatesMany?: Maybe<
    Array<Maybe<MedspaServiceOfferingNoteTemplatesMutationResponse>>
  >;
  /** update data of the table: "medspa_service_offering_notes" */
  updateMedspaServiceOfferingNotes?: Maybe<MedspaServiceOfferingNotesMutationResponse>;
  /** update single row of the table: "medspa_service_offering_notes" */
  updateMedspaServiceOfferingNotesByPk?: Maybe<MedspaServiceOfferingNotes>;
  /** update multiples rows of table: "medspa_service_offering_notes" */
  updateMedspaServiceOfferingNotesMany?: Maybe<
    Array<Maybe<MedspaServiceOfferingNotesMutationResponse>>
  >;
  /** update data of the table: "medspa_service_product" */
  updateMedspaServiceProduct?: Maybe<MedspaServiceProductMutationResponse>;
  /** update single row of the table: "medspa_service_product" */
  updateMedspaServiceProductByPk?: Maybe<MedspaServiceProduct>;
  /** update multiples rows of table: "medspa_service_product" */
  updateMedspaServiceProductMany?: Maybe<
    Array<Maybe<MedspaServiceProductMutationResponse>>
  >;
  /** update data of the table: "medspa_service_protocol_template" */
  updateMedspaServiceProtocolTemplate?: Maybe<MedspaServiceProtocolTemplateMutationResponse>;
  /** update single row of the table: "medspa_service_protocol_template" */
  updateMedspaServiceProtocolTemplateByPk?: Maybe<MedspaServiceProtocolTemplate>;
  /** update multiples rows of table: "medspa_service_protocol_template" */
  updateMedspaServiceProtocolTemplateMany?: Maybe<
    Array<Maybe<MedspaServiceProtocolTemplateMutationResponse>>
  >;
  /** update data of the table: "medspa_standing_order_template" */
  updateMedspaStandingOrderTemplate?: Maybe<MedspaStandingOrderTemplateMutationResponse>;
  /** update single row of the table: "medspa_standing_order_template" */
  updateMedspaStandingOrderTemplateByPk?: Maybe<MedspaStandingOrderTemplate>;
  /** update multiples rows of table: "medspa_standing_order_template" */
  updateMedspaStandingOrderTemplateMany?: Maybe<
    Array<Maybe<MedspaStandingOrderTemplateMutationResponse>>
  >;
  /** update data of the table: "medspa_wallet_credit_type" */
  updateMedspaWalletCreditType?: Maybe<MedspaWalletCreditTypeMutationResponse>;
  /** update single row of the table: "medspa_wallet_credit_type" */
  updateMedspaWalletCreditTypeByPk?: Maybe<MedspaWalletCreditType>;
  /** update multiples rows of table: "medspa_wallet_credit_type" */
  updateMedspaWalletCreditTypeMany?: Maybe<
    Array<Maybe<MedspaWalletCreditTypeMutationResponse>>
  >;
  /** update data of the table: "medspas_featureflag" */
  updateMedspasFeatureflag?: Maybe<MedspasFeatureflagMutationResponse>;
  /** update single row of the table: "medspas_featureflag" */
  updateMedspasFeatureflagByPk?: Maybe<MedspasFeatureflag>;
  /** update multiples rows of table: "medspas_featureflag" */
  updateMedspasFeatureflagMany?: Maybe<
    Array<Maybe<MedspasFeatureflagMutationResponse>>
  >;
  /** update data of the table: "medspas_medicallicense" */
  updateMedspasMedicallicense?: Maybe<MedspasMedicallicenseMutationResponse>;
  /** update single row of the table: "medspas_medicallicense" */
  updateMedspasMedicallicenseByPk?: Maybe<MedspasMedicallicense>;
  /** update multiples rows of table: "medspas_medicallicense" */
  updateMedspasMedicallicenseMany?: Maybe<
    Array<Maybe<MedspasMedicallicenseMutationResponse>>
  >;
  /** update data of the table: "medspas_medspaconfiguration" */
  updateMedspasMedspaconfiguration?: Maybe<MedspasMedspaconfigurationMutationResponse>;
  /** update single row of the table: "medspas_medspaconfiguration" */
  updateMedspasMedspaconfigurationByPk?: Maybe<MedspasMedspaconfiguration>;
  /** update multiples rows of table: "medspas_medspaconfiguration" */
  updateMedspasMedspaconfigurationMany?: Maybe<
    Array<Maybe<MedspasMedspaconfigurationMutationResponse>>
  >;
  /** update data of the table: "medspas_professionalcorporation" */
  updateMedspasProfessionalcorporation?: Maybe<MedspasProfessionalcorporationMutationResponse>;
  /** update single row of the table: "medspas_professionalcorporation" */
  updateMedspasProfessionalcorporationByPk?: Maybe<MedspasProfessionalcorporation>;
  /** update multiples rows of table: "medspas_professionalcorporation" */
  updateMedspasProfessionalcorporationMany?: Maybe<
    Array<Maybe<MedspasProfessionalcorporationMutationResponse>>
  >;
  /** update data of the table: "medspas_stripeoutboundpaymentmethod" */
  updateMedspasStripeoutboundpaymentmethod?: Maybe<MedspasStripeoutboundpaymentmethodMutationResponse>;
  /** update single row of the table: "medspas_stripeoutboundpaymentmethod" */
  updateMedspasStripeoutboundpaymentmethodByPk?: Maybe<MedspasStripeoutboundpaymentmethod>;
  /** update multiples rows of table: "medspas_stripeoutboundpaymentmethod" */
  updateMedspasStripeoutboundpaymentmethodMany?: Maybe<
    Array<Maybe<MedspasStripeoutboundpaymentmethodMutationResponse>>
  >;
  /** update data of the table: "medspas_usermedspa_acquired_medical_licenses" */
  updateMedspasUsermedspaAcquiredMedicalLicenses?: Maybe<MedspasUsermedspaAcquiredMedicalLicensesMutationResponse>;
  /** update single row of the table: "medspas_usermedspa_acquired_medical_licenses" */
  updateMedspasUsermedspaAcquiredMedicalLicensesByPk?: Maybe<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** update multiples rows of table: "medspas_usermedspa_acquired_medical_licenses" */
  updateMedspasUsermedspaAcquiredMedicalLicensesMany?: Maybe<
    Array<Maybe<MedspasUsermedspaAcquiredMedicalLicensesMutationResponse>>
  >;
  /** update data of the table: "membership" */
  updateMembership?: Maybe<MembershipMutationResponse>;
  /** update single row of the table: "membership" */
  updateMembershipByPk?: Maybe<Membership>;
  /** update data of the table: "membership_category" */
  updateMembershipCategory?: Maybe<MembershipCategoryMutationResponse>;
  /** update single row of the table: "membership_category" */
  updateMembershipCategoryByPk?: Maybe<MembershipCategory>;
  /** update multiples rows of table: "membership_category" */
  updateMembershipCategoryMany?: Maybe<
    Array<Maybe<MembershipCategoryMutationResponse>>
  >;
  /** update data of the table: "membership_line" */
  updateMembershipLine?: Maybe<MembershipLineMutationResponse>;
  /** update single row of the table: "membership_line" */
  updateMembershipLineByPk?: Maybe<MembershipLine>;
  /** update multiples rows of table: "membership_line" */
  updateMembershipLineMany?: Maybe<
    Array<Maybe<MembershipLineMutationResponse>>
  >;
  /** update multiples rows of table: "membership" */
  updateMembershipMany?: Maybe<Array<Maybe<MembershipMutationResponse>>>;
  /** update data of the table: "membership_perk" */
  updateMembershipPerk?: Maybe<MembershipPerkMutationResponse>;
  /** update single row of the table: "membership_perk" */
  updateMembershipPerkByPk?: Maybe<MembershipPerk>;
  /** update data of the table: "membership_perk_line" */
  updateMembershipPerkLine?: Maybe<MembershipPerkLineMutationResponse>;
  /** update single row of the table: "membership_perk_line" */
  updateMembershipPerkLineByPk?: Maybe<MembershipPerkLine>;
  /** update multiples rows of table: "membership_perk_line" */
  updateMembershipPerkLineMany?: Maybe<
    Array<Maybe<MembershipPerkLineMutationResponse>>
  >;
  /** update multiples rows of table: "membership_perk" */
  updateMembershipPerkMany?: Maybe<
    Array<Maybe<MembershipPerkMutationResponse>>
  >;
  /** update data of the table: "model_permission" */
  updateModelPermission?: Maybe<ModelPermissionMutationResponse>;
  /** update single row of the table: "model_permission" */
  updateModelPermissionByPk?: Maybe<ModelPermission>;
  /** update multiples rows of table: "model_permission" */
  updateModelPermissionMany?: Maybe<
    Array<Maybe<ModelPermissionMutationResponse>>
  >;
  /** update data of the table: "visits_note" */
  updateNote?: Maybe<NoteMutationResponse>;
  /** update single row of the table: "visits_note" */
  updateNoteByPk?: Maybe<Note>;
  /** update data of the table: "visits_notechangelog" */
  updateNoteChangeLog?: Maybe<NoteChangeLogMutationResponse>;
  /** update single row of the table: "visits_notechangelog" */
  updateNoteChangeLogByPk?: Maybe<NoteChangeLog>;
  /** update multiples rows of table: "visits_notechangelog" */
  updateNoteChangeLogMany?: Maybe<Array<Maybe<NoteChangeLogMutationResponse>>>;
  /** update data of the table: "visits_note_form_submissions" */
  updateNoteFormSubmission?: Maybe<NoteFormSubmissionMutationResponse>;
  /** update single row of the table: "visits_note_form_submissions" */
  updateNoteFormSubmissionByPk?: Maybe<NoteFormSubmission>;
  /** update multiples rows of table: "visits_note_form_submissions" */
  updateNoteFormSubmissionMany?: Maybe<
    Array<Maybe<NoteFormSubmissionMutationResponse>>
  >;
  /** update multiples rows of table: "visits_note" */
  updateNoteMany?: Maybe<Array<Maybe<NoteMutationResponse>>>;
  /** update data of the table: "resources_notetemplate" */
  updateNoteTemplate?: Maybe<NoteTemplateMutationResponse>;
  /** update single row of the table: "resources_notetemplate" */
  updateNoteTemplateByPk?: Maybe<NoteTemplate>;
  /** update multiples rows of table: "resources_notetemplate" */
  updateNoteTemplateMany?: Maybe<Array<Maybe<NoteTemplateMutationResponse>>>;
  /** update data of the table: "resources_package" */
  updatePackage?: Maybe<PackageMutationResponse>;
  /** update single row of the table: "resources_package" */
  updatePackageByPk?: Maybe<Package>;
  /** update data of the table: "resources_packageitem" */
  updatePackageItem?: Maybe<PackageItemMutationResponse>;
  /** update single row of the table: "resources_packageitem" */
  updatePackageItemByPk?: Maybe<PackageItem>;
  /** update data of the table: "package_item_line" */
  updatePackageItemLine?: Maybe<PackageItemLineMutationResponse>;
  /** update single row of the table: "package_item_line" */
  updatePackageItemLineByPk?: Maybe<PackageItemLine>;
  /** update multiples rows of table: "package_item_line" */
  updatePackageItemLineMany?: Maybe<
    Array<Maybe<PackageItemLineMutationResponse>>
  >;
  /** update multiples rows of table: "resources_packageitem" */
  updatePackageItemMany?: Maybe<Array<Maybe<PackageItemMutationResponse>>>;
  /** update data of the table: "visits_packageline" */
  updatePackageLine?: Maybe<PackageLineMutationResponse>;
  /** update single row of the table: "visits_packageline" */
  updatePackageLineByPk?: Maybe<PackageLine>;
  /** update multiples rows of table: "visits_packageline" */
  updatePackageLineMany?: Maybe<Array<Maybe<PackageLineMutationResponse>>>;
  /** update multiples rows of table: "resources_package" */
  updatePackageMany?: Maybe<Array<Maybe<PackageMutationResponse>>>;
  /** update data of the table: "payments_payment" */
  updatePayment?: Maybe<PaymentMutationResponse>;
  /** update single row of the table: "payments_payment" */
  updatePaymentByPk?: Maybe<Payment>;
  /** update multiples rows of table: "payments_payment" */
  updatePaymentMany?: Maybe<Array<Maybe<PaymentMutationResponse>>>;
  /** update data of the table: "visits_photo" */
  updatePhoto?: Maybe<PhotoMutationResponse>;
  /** update single row of the table: "visits_photo" */
  updatePhotoByPk?: Maybe<Photo>;
  /** update multiples rows of table: "visits_photo" */
  updatePhotoMany?: Maybe<Array<Maybe<PhotoMutationResponse>>>;
  /** update data of the table: "resources_product" */
  updateProduct?: Maybe<ProductMutationResponse>;
  /** update single row of the table: "resources_product" */
  updateProductByPk?: Maybe<Product>;
  /** update data of the table: "visits_productline" */
  updateProductLine?: Maybe<ProductLineMutationResponse>;
  /** update single row of the table: "visits_productline" */
  updateProductLineByPk?: Maybe<ProductLine>;
  /** update multiples rows of table: "visits_productline" */
  updateProductLineMany?: Maybe<Array<Maybe<ProductLineMutationResponse>>>;
  /** update multiples rows of table: "resources_product" */
  updateProductMany?: Maybe<Array<Maybe<ProductMutationResponse>>>;
  /** update data of the table: "provider_not_require_documents" */
  updateProviderNotRequireDocuments?: Maybe<ProviderNotRequireDocumentsMutationResponse>;
  /** update single row of the table: "provider_not_require_documents" */
  updateProviderNotRequireDocumentsByPk?: Maybe<ProviderNotRequireDocuments>;
  /** update multiples rows of table: "provider_not_require_documents" */
  updateProviderNotRequireDocumentsMany?: Maybe<
    Array<Maybe<ProviderNotRequireDocumentsMutationResponse>>
  >;
  /** update data of the table: "scheduling_recurring_availability" */
  updateRecurringAvailability?: Maybe<RecurringAvailabilityMutationResponse>;
  /** update single row of the table: "scheduling_recurring_availability" */
  updateRecurringAvailabilityByPk?: Maybe<RecurringAvailability>;
  /** update multiples rows of table: "scheduling_recurring_availability" */
  updateRecurringAvailabilityMany?: Maybe<
    Array<Maybe<RecurringAvailabilityMutationResponse>>
  >;
  /** update data of the table: "payments_refund" */
  updateRefund?: Maybe<RefundMutationResponse>;
  /** update single row of the table: "payments_refund" */
  updateRefundByPk?: Maybe<Refund>;
  /** update multiples rows of table: "payments_refund" */
  updateRefundMany?: Maybe<Array<Maybe<RefundMutationResponse>>>;
  /** update data of the table: "medspas_report" */
  updateReport?: Maybe<ReportMutationResponse>;
  /** update single row of the table: "medspas_report" */
  updateReportByPk?: Maybe<Report>;
  /** update data of the table: "medspas_reportcategory" */
  updateReportCategory?: Maybe<ReportCategoryMutationResponse>;
  /** update single row of the table: "medspas_reportcategory" */
  updateReportCategoryByPk?: Maybe<ReportCategory>;
  /** update multiples rows of table: "medspas_reportcategory" */
  updateReportCategoryMany?: Maybe<
    Array<Maybe<ReportCategoryMutationResponse>>
  >;
  /** update multiples rows of table: "medspas_report" */
  updateReportMany?: Maybe<Array<Maybe<ReportMutationResponse>>>;
  /** update data of the table: "resources_medspa_room" */
  updateResourcesMedspaRoom?: Maybe<ResourcesMedspaRoomMutationResponse>;
  /** update single row of the table: "resources_medspa_room" */
  updateResourcesMedspaRoomByPk?: Maybe<ResourcesMedspaRoom>;
  /** update multiples rows of table: "resources_medspa_room" */
  updateResourcesMedspaRoomMany?: Maybe<
    Array<Maybe<ResourcesMedspaRoomMutationResponse>>
  >;
  /** update data of the table: "scheduling_appointment" */
  updateSchedulingAppointment?: Maybe<SchedulingAppointmentMutationResponse>;
  /** update single row of the table: "scheduling_appointment" */
  updateSchedulingAppointmentByPk?: Maybe<SchedulingAppointment>;
  /** update multiples rows of table: "scheduling_appointment" */
  updateSchedulingAppointmentMany?: Maybe<
    Array<Maybe<SchedulingAppointmentMutationResponse>>
  >;
  /** update data of the table: "script_product" */
  updateScriptProduct?: Maybe<ScriptProductMutationResponse>;
  /** update single row of the table: "script_product" */
  updateScriptProductByPk?: Maybe<ScriptProduct>;
  /** update multiples rows of table: "script_product" */
  updateScriptProductMany?: Maybe<Array<Maybe<ScriptProductMutationResponse>>>;
  /** update data of the table: "script_request" */
  updateScriptRequest?: Maybe<ScriptRequestMutationResponse>;
  /** update single row of the table: "script_request" */
  updateScriptRequestByPk?: Maybe<ScriptRequest>;
  /** update multiples rows of table: "script_request" */
  updateScriptRequestMany?: Maybe<Array<Maybe<ScriptRequestMutationResponse>>>;
  /** update data of the table: "script_request_status_change_log" */
  updateScriptRequestStatusChangeLog?: Maybe<ScriptRequestStatusChangeLogMutationResponse>;
  /** update single row of the table: "script_request_status_change_log" */
  updateScriptRequestStatusChangeLogByPk?: Maybe<ScriptRequestStatusChangeLog>;
  /** update multiples rows of table: "script_request_status_change_log" */
  updateScriptRequestStatusChangeLogMany?: Maybe<
    Array<Maybe<ScriptRequestStatusChangeLogMutationResponse>>
  >;
  /** update data of the table: "resources_servicecategory" */
  updateServiceCategory?: Maybe<ServiceCategoryMutationResponse>;
  /** update single row of the table: "resources_servicecategory" */
  updateServiceCategoryByPk?: Maybe<ServiceCategory>;
  /** update multiples rows of table: "resources_servicecategory" */
  updateServiceCategoryMany?: Maybe<
    Array<Maybe<ServiceCategoryMutationResponse>>
  >;
  /** update data of the table: "service_device" */
  updateServiceDevice?: Maybe<ServiceDeviceMutationResponse>;
  /** update single row of the table: "service_device" */
  updateServiceDeviceByPk?: Maybe<ServiceDevice>;
  /** update multiples rows of table: "service_device" */
  updateServiceDeviceMany?: Maybe<Array<Maybe<ServiceDeviceMutationResponse>>>;
  /** update data of the table: "service_devices_appointments" */
  updateServiceDevicesAppointments?: Maybe<ServiceDevicesAppointmentsMutationResponse>;
  /** update single row of the table: "service_devices_appointments" */
  updateServiceDevicesAppointmentsByPk?: Maybe<ServiceDevicesAppointments>;
  /** update multiples rows of table: "service_devices_appointments" */
  updateServiceDevicesAppointmentsMany?: Maybe<
    Array<Maybe<ServiceDevicesAppointmentsMutationResponse>>
  >;
  /** update data of the table: "service_devices_service_types" */
  updateServiceDevicesServiceTypes?: Maybe<ServiceDevicesServiceTypesMutationResponse>;
  /** update single row of the table: "service_devices_service_types" */
  updateServiceDevicesServiceTypesByPk?: Maybe<ServiceDevicesServiceTypes>;
  /** update multiples rows of table: "service_devices_service_types" */
  updateServiceDevicesServiceTypesMany?: Maybe<
    Array<Maybe<ServiceDevicesServiceTypesMutationResponse>>
  >;
  /** update data of the table: "service_menu_item_line" */
  updateServiceMenuItemLine?: Maybe<ServiceMenuItemLineMutationResponse>;
  /** update single row of the table: "service_menu_item_line" */
  updateServiceMenuItemLineByPk?: Maybe<ServiceMenuItemLine>;
  /** update multiples rows of table: "service_menu_item_line" */
  updateServiceMenuItemLineMany?: Maybe<
    Array<Maybe<ServiceMenuItemLineMutationResponse>>
  >;
  /** update data of the table: "service_menu_item_line_standing_orders" */
  updateServiceMenuItemLineStandingOrders?: Maybe<ServiceMenuItemLineStandingOrdersMutationResponse>;
  /** update single row of the table: "service_menu_item_line_standing_orders" */
  updateServiceMenuItemLineStandingOrdersByPk?: Maybe<ServiceMenuItemLineStandingOrders>;
  /** update multiples rows of table: "service_menu_item_line_standing_orders" */
  updateServiceMenuItemLineStandingOrdersMany?: Maybe<
    Array<Maybe<ServiceMenuItemLineStandingOrdersMutationResponse>>
  >;
  /** update data of the table: "service_product" */
  updateServiceProduct?: Maybe<ServiceProductMutationResponse>;
  /** update single row of the table: "service_product" */
  updateServiceProductByPk?: Maybe<ServiceProduct>;
  /** update data of the table: "service_product_charting_note" */
  updateServiceProductChartingNote?: Maybe<ServiceProductChartingNoteMutationResponse>;
  /** update single row of the table: "service_product_charting_note" */
  updateServiceProductChartingNoteByPk?: Maybe<ServiceProductChartingNote>;
  /** update multiples rows of table: "service_product_charting_note" */
  updateServiceProductChartingNoteMany?: Maybe<
    Array<Maybe<ServiceProductChartingNoteMutationResponse>>
  >;
  /** update data of the table: "service_product_line" */
  updateServiceProductLine?: Maybe<ServiceProductLineMutationResponse>;
  /** update single row of the table: "service_product_line" */
  updateServiceProductLineByPk?: Maybe<ServiceProductLine>;
  /** update multiples rows of table: "service_product_line" */
  updateServiceProductLineMany?: Maybe<
    Array<Maybe<ServiceProductLineMutationResponse>>
  >;
  /** update multiples rows of table: "service_product" */
  updateServiceProductMany?: Maybe<
    Array<Maybe<ServiceProductMutationResponse>>
  >;
  /** update data of the table: "service_product_type" */
  updateServiceProductType?: Maybe<ServiceProductTypeMutationResponse>;
  /** update single row of the table: "service_product_type" */
  updateServiceProductTypeByPk?: Maybe<ServiceProductType>;
  /** update multiples rows of table: "service_product_type" */
  updateServiceProductTypeMany?: Maybe<
    Array<Maybe<ServiceProductTypeMutationResponse>>
  >;
  /** update data of the table: "service_product_usage" */
  updateServiceProductUsage?: Maybe<ServiceProductUsageMutationResponse>;
  /** update single row of the table: "service_product_usage" */
  updateServiceProductUsageByPk?: Maybe<ServiceProductUsage>;
  /** update multiples rows of table: "service_product_usage" */
  updateServiceProductUsageMany?: Maybe<
    Array<Maybe<ServiceProductUsageMutationResponse>>
  >;
  /** update data of the table: "service_protocol" */
  updateServiceProtocol?: Maybe<ServiceProtocolMutationResponse>;
  /** update single row of the table: "service_protocol" */
  updateServiceProtocolByPk?: Maybe<ServiceProtocol>;
  /** update multiples rows of table: "service_protocol" */
  updateServiceProtocolMany?: Maybe<
    Array<Maybe<ServiceProtocolMutationResponse>>
  >;
  /** update data of the table: "service_protocol_template" */
  updateServiceProtocolTemplate?: Maybe<ServiceProtocolTemplateMutationResponse>;
  /** update single row of the table: "service_protocol_template" */
  updateServiceProtocolTemplateByPk?: Maybe<ServiceProtocolTemplate>;
  /** update multiples rows of table: "service_protocol_template" */
  updateServiceProtocolTemplateMany?: Maybe<
    Array<Maybe<ServiceProtocolTemplateMutationResponse>>
  >;
  /** update data of the table: "service_type" */
  updateServiceType?: Maybe<ServiceTypeMutationResponse>;
  /** update single row of the table: "service_type" */
  updateServiceTypeByPk?: Maybe<ServiceType>;
  /** update data of the table: "service_type_category" */
  updateServiceTypeCategory?: Maybe<ServiceTypeCategoryMutationResponse>;
  /** update single row of the table: "service_type_category" */
  updateServiceTypeCategoryByPk?: Maybe<ServiceTypeCategory>;
  /** update multiples rows of table: "service_type_category" */
  updateServiceTypeCategoryMany?: Maybe<
    Array<Maybe<ServiceTypeCategoryMutationResponse>>
  >;
  /** update multiples rows of table: "service_type" */
  updateServiceTypeMany?: Maybe<Array<Maybe<ServiceTypeMutationResponse>>>;
  /** update data of the table: "utils_shortmoxieurl" */
  updateShortMoxieUrl?: Maybe<ShortMoxieUrlMutationResponse>;
  /** update single row of the table: "utils_shortmoxieurl" */
  updateShortMoxieUrlByPk?: Maybe<ShortMoxieUrl>;
  /** update multiples rows of table: "utils_shortmoxieurl" */
  updateShortMoxieUrlMany?: Maybe<Array<Maybe<ShortMoxieUrlMutationResponse>>>;
  /** update data of the table: "medspas_standingorder" */
  updateStandingOrder?: Maybe<StandingOrderMutationResponse>;
  /** update single row of the table: "medspas_standingorder" */
  updateStandingOrderByPk?: Maybe<StandingOrder>;
  /** update multiples rows of table: "medspas_standingorder" */
  updateStandingOrderMany?: Maybe<Array<Maybe<StandingOrderMutationResponse>>>;
  /** update data of the table: "standing_order_template" */
  updateStandingOrderTemplate?: Maybe<StandingOrderTemplateMutationResponse>;
  /** update single row of the table: "standing_order_template" */
  updateStandingOrderTemplateByPk?: Maybe<StandingOrderTemplate>;
  /** update multiples rows of table: "standing_order_template" */
  updateStandingOrderTemplateMany?: Maybe<
    Array<Maybe<StandingOrderTemplateMutationResponse>>
  >;
  updateStripeCard?: Maybe<UpdateStripeCard>;
  /** update data of the table: "supplier_information" */
  updateSupplierInformation?: Maybe<SupplierInformationMutationResponse>;
  /** update single row of the table: "supplier_information" */
  updateSupplierInformationByPk?: Maybe<SupplierInformation>;
  /** update multiples rows of table: "supplier_information" */
  updateSupplierInformationMany?: Maybe<
    Array<Maybe<SupplierInformationMutationResponse>>
  >;
  /** update data of the table: "telehealth_visit_details" */
  updateTelehealthVisitDetails?: Maybe<TelehealthVisitDetailsMutationResponse>;
  /** update single row of the table: "telehealth_visit_details" */
  updateTelehealthVisitDetailsByPk?: Maybe<TelehealthVisitDetails>;
  /** update multiples rows of table: "telehealth_visit_details" */
  updateTelehealthVisitDetailsMany?: Maybe<
    Array<Maybe<TelehealthVisitDetailsMutationResponse>>
  >;
  /** update data of the table: "standing_order" */
  updateTmpStandingOrder?: Maybe<TmpStandingOrderMutationResponse>;
  /** update single row of the table: "standing_order" */
  updateTmpStandingOrderByPk?: Maybe<TmpStandingOrder>;
  /** update multiples rows of table: "standing_order" */
  updateTmpStandingOrderMany?: Maybe<
    Array<Maybe<TmpStandingOrderMutationResponse>>
  >;
  /** update data of the table: "url_pattern_permission" */
  updateUrlPatternPermission?: Maybe<UrlPatternPermissionMutationResponse>;
  /** update single row of the table: "url_pattern_permission" */
  updateUrlPatternPermissionByPk?: Maybe<UrlPatternPermission>;
  /** update multiples rows of table: "url_pattern_permission" */
  updateUrlPatternPermissionMany?: Maybe<
    Array<Maybe<UrlPatternPermissionMutationResponse>>
  >;
  /** update data of the table: "accounts_user" */
  updateUser?: Maybe<UserMutationResponse>;
  /** update single row of the table: "accounts_user" */
  updateUserByPk?: Maybe<User>;
  /** update multiples rows of table: "accounts_user" */
  updateUserMany?: Maybe<Array<Maybe<UserMutationResponse>>>;
  /** update data of the table: "medspas_usermedspa" */
  updateUserMedspa?: Maybe<UserMedspaMutationResponse>;
  /** update single row of the table: "medspas_usermedspa" */
  updateUserMedspaByPk?: Maybe<UserMedspa>;
  /** update multiples rows of table: "medspas_usermedspa" */
  updateUserMedspaMany?: Maybe<Array<Maybe<UserMedspaMutationResponse>>>;
  /** update data of the table: "visits_visit" */
  updateVisit?: Maybe<VisitMutationResponse>;
  /** update single row of the table: "visits_visit" */
  updateVisitByPk?: Maybe<Visit>;
  /** update multiples rows of table: "visits_visit" */
  updateVisitMany?: Maybe<Array<Maybe<VisitMutationResponse>>>;
  /** update data of the table: "visits_visitreviewstatuschangelog" */
  updateVisitReviewStatusChangeLog?: Maybe<VisitReviewStatusChangeLogMutationResponse>;
  /** update single row of the table: "visits_visitreviewstatuschangelog" */
  updateVisitReviewStatusChangeLogByPk?: Maybe<VisitReviewStatusChangeLog>;
  /** update multiples rows of table: "visits_visitreviewstatuschangelog" */
  updateVisitReviewStatusChangeLogMany?: Maybe<
    Array<Maybe<VisitReviewStatusChangeLogMutationResponse>>
  >;
  /** update data of the table: "visits_visitstatuschangelog" */
  updateVisitStatusChangeLog?: Maybe<VisitStatusChangeLogMutationResponse>;
  /** update single row of the table: "visits_visitstatuschangelog" */
  updateVisitStatusChangeLogByPk?: Maybe<VisitStatusChangeLog>;
  /** update multiples rows of table: "visits_visitstatuschangelog" */
  updateVisitStatusChangeLogMany?: Maybe<
    Array<Maybe<VisitStatusChangeLogMutationResponse>>
  >;
  /** update data of the table: "visits_adversereactionchangelog" */
  updateVisitsAdversereactionchangelog?: Maybe<VisitsAdversereactionchangelogMutationResponse>;
  /** update single row of the table: "visits_adversereactionchangelog" */
  updateVisitsAdversereactionchangelogByPk?: Maybe<VisitsAdversereactionchangelog>;
  /** update multiples rows of table: "visits_adversereactionchangelog" */
  updateVisitsAdversereactionchangelogMany?: Maybe<
    Array<Maybe<VisitsAdversereactionchangelogMutationResponse>>
  >;
  /** update data of the table: "visits_chartsignature" */
  updateVisitsChartsignature?: Maybe<VisitsChartsignatureMutationResponse>;
  /** update single row of the table: "visits_chartsignature" */
  updateVisitsChartsignatureByPk?: Maybe<VisitsChartsignature>;
  /** update multiples rows of table: "visits_chartsignature" */
  updateVisitsChartsignatureMany?: Maybe<
    Array<Maybe<VisitsChartsignatureMutationResponse>>
  >;
  /** update data of the table: "visits_telehealthvisitdetailschangelog" */
  updateVisitsTelehealthvisitdetailschangelog?: Maybe<VisitsTelehealthvisitdetailschangelogMutationResponse>;
  /** update single row of the table: "visits_telehealthvisitdetailschangelog" */
  updateVisitsTelehealthvisitdetailschangelogByPk?: Maybe<VisitsTelehealthvisitdetailschangelog>;
  /** update multiples rows of table: "visits_telehealthvisitdetailschangelog" */
  updateVisitsTelehealthvisitdetailschangelogMany?: Maybe<
    Array<Maybe<VisitsTelehealthvisitdetailschangelogMutationResponse>>
  >;
  /** update data of the table: "wallet_credit_line" */
  updateWalletCreditLine?: Maybe<WalletCreditLineMutationResponse>;
  /** update single row of the table: "wallet_credit_line" */
  updateWalletCreditLineByPk?: Maybe<WalletCreditLine>;
  /** update multiples rows of table: "wallet_credit_line" */
  updateWalletCreditLineMany?: Maybe<
    Array<Maybe<WalletCreditLineMutationResponse>>
  >;
  /** update data of the table: "wallet_credit_type" */
  updateWalletCreditType?: Maybe<WalletCreditTypeMutationResponse>;
  /** update single row of the table: "wallet_credit_type" */
  updateWalletCreditTypeByPk?: Maybe<WalletCreditType>;
  /** update multiples rows of table: "wallet_credit_type" */
  updateWalletCreditTypeMany?: Maybe<
    Array<Maybe<WalletCreditTypeMutationResponse>>
  >;
  /** update data of the table: "wallets_wallettransaction" */
  updateWalletTransaction?: Maybe<WalletTransactionMutationResponse>;
  /** update single row of the table: "wallets_wallettransaction" */
  updateWalletTransactionByPk?: Maybe<WalletTransaction>;
  /** update multiples rows of table: "wallets_wallettransaction" */
  updateWalletTransactionMany?: Maybe<
    Array<Maybe<WalletTransactionMutationResponse>>
  >;
  /** update data of the table: "wallet_transaction_source_tmp" */
  updateWalletTransactionSourceTmp?: Maybe<WalletTransactionSourceTmpMutationResponse>;
  /** update single row of the table: "wallet_transaction_source_tmp" */
  updateWalletTransactionSourceTmpByPk?: Maybe<WalletTransactionSourceTmp>;
  /** update multiples rows of table: "wallet_transaction_source_tmp" */
  updateWalletTransactionSourceTmpMany?: Maybe<
    Array<Maybe<WalletTransactionSourceTmpMutationResponse>>
  >;
  /** update data of the table: "wallet_transactions_sources" */
  updateWalletTransactionsSources?: Maybe<WalletTransactionsSourcesMutationResponse>;
  /** update single row of the table: "wallet_transactions_sources" */
  updateWalletTransactionsSourcesByPk?: Maybe<WalletTransactionsSources>;
  /** update multiples rows of table: "wallet_transactions_sources" */
  updateWalletTransactionsSourcesMany?: Maybe<
    Array<Maybe<WalletTransactionsSourcesMutationResponse>>
  >;
  verifyClient2faCode?: Maybe<VerifyClient2FaCode>;
};

/** mutation root */
export type Mutation_RootAddMobileStripeTerminalArgs = {
  medspaId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  serialNumber: Scalars["String"]["input"];
  type: TerminalType;
};

/** mutation root */
export type Mutation_RootAddSmartStripeTerminalArgs = {
  medspaId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  pairingCode: Scalars["String"]["input"];
  type: TerminalType;
};

/** mutation root */
export type Mutation_RootAddWalletTransactionArgs = {
  amount: Scalars["Decimal"]["input"];
  clientId: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  resourceType: Scalars["String"]["input"];
  serviceMenuItemId?: InputMaybe<Scalars["String"]["input"]>;
  serviceProductId?: InputMaybe<Scalars["String"]["input"]>;
  walletCreditTypeId?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootCancelAffirmPaymentArgs = {
  medspaId?: InputMaybe<Scalars["BigInt"]["input"]>;
  paymentId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootCancelAppointmentArgs = {
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  sendCommunication?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** mutation root */
export type Mutation_RootCancelAppointmentByClientArgs = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootCancelClientMembershipArgs = {
  chargeCancellationFee?: InputMaybe<Scalars["Boolean"]["input"]>;
  clientMembershipId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootCancelMdMeetingArgs = {
  mdMeetingId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootCancelTerminalActionArgs = {
  medspaId?: InputMaybe<Scalars["BigInt"]["input"]>;
  paymentId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootCancelVisitWithoutAppointmentArgs = {
  visitId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootChargeNoShowFeeArgs = {
  visitId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootConfirmAppointmentArgs = {
  fromSms?: InputMaybe<Scalars["Boolean"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootCreateAdverseReactionArgs = {
  reportContent: Scalars["String"]["input"];
  visitId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootCreateAvailabilityEventArgs = {
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  endTime: Scalars["DateTime"]["input"];
  eventType: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  medspaId: Scalars["BigInt"]["input"];
  providerId?: InputMaybe<Scalars["BigInt"]["input"]>;
  startTime: Scalars["DateTime"]["input"];
};

/** mutation root */
export type Mutation_RootCreateCherryPatientAccountArgs = {
  clientId: Scalars["ID"]["input"];
};

/** mutation root */
export type Mutation_RootCreateClientArgs = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  medspaSlug: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootCreateClientByProviderArgs = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  birthdate?: InputMaybe<Scalars["Date"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  medspaId: Scalars["BigInt"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  phone: Scalars["String"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootCreateClientMembershipArgs = {
  clientId: Scalars["BigInt"]["input"];
  membershipId: Scalars["BigInt"]["input"];
  removeInitiationFee?: InputMaybe<Scalars["Boolean"]["input"]>;
  signature: Scalars["String"]["input"];
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/** mutation root */
export type Mutation_RootCreateDiagramArgs = {
  templateId?: InputMaybe<Scalars["BigInt"]["input"]>;
  visitId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootCreateDocumentsArgs = {
  documents: Array<InputMaybe<DocumentInputType>>;
  isStandingOrdersRequested?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootCreateFormSubmissionsArgs = {
  clientId: Scalars["BigInt"]["input"];
  formIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
};

/** mutation root */
export type Mutation_RootCreateInvoiceArgs = {
  clientId?: InputMaybe<Scalars["BigInt"]["input"]>;
  medspaId?: InputMaybe<Scalars["BigInt"]["input"]>;
  visitId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootCreateMdMeetingArgs = {
  attendeesIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
  bookedById: Scalars["BigInt"]["input"];
  bookingFlow: Scalars["String"]["input"];
  details?: InputMaybe<Scalars["String"]["input"]>;
  endTime: Scalars["DateTime"]["input"];
  format: Scalars["String"]["input"];
  startTime: Scalars["DateTime"]["input"];
};

/** mutation root */
export type Mutation_RootCreateMembershipArgs = {
  categoryId: Scalars["BigInt"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  isOnlinePurchaseEnabled: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootCreateOrUpdateDiscountLinesArgs = {
  discountIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  discountLineIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  discountType?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  productLineIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  quantityLimit?: InputMaybe<Scalars["Int"]["input"]>;
  serviceLineIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  serviceProductLineIds?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  value?: InputMaybe<Scalars["Decimal"]["input"]>;
};

/** mutation root */
export type Mutation_RootCreatePackageCheckoutArgs = {
  packages: Array<InputMaybe<PackageInputType>>;
  recipientEmail?: InputMaybe<Scalars["String"]["input"]>;
  recipientFirstName?: InputMaybe<Scalars["String"]["input"]>;
  recipientLastName?: InputMaybe<Scalars["String"]["input"]>;
  recipientPhone?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootCreatePackageGiftCheckoutArgs = {
  bannerId?: InputMaybe<Scalars["BigInt"]["input"]>;
  giftMessage?: InputMaybe<Scalars["String"]["input"]>;
  packages: Array<InputMaybe<PackageInputType>>;
  recipientEmail: Scalars["String"]["input"];
  recipientFirstName: Scalars["String"]["input"];
  recipientLastName: Scalars["String"]["input"];
  recipientPhone: Scalars["String"]["input"];
  sendDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  senderName: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootCreatePackageLinesArgs = {
  packageLines: Array<InputMaybe<PackageLineType>>;
};

/** mutation root */
export type Mutation_RootCreateRecurringAvailabilityArgs = {
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  endTime: Scalars["Time"]["input"];
  eventType?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  medspaId: Scalars["BigInt"]["input"];
  providerId?: InputMaybe<Scalars["BigInt"]["input"]>;
  startTime: Scalars["Time"]["input"];
  weekday: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootCreateSchedulingAppointmentArgs = {
  clientId?: InputMaybe<Scalars["BigInt"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["BigInt"]["input"]>;
  serviceMenuItemIds?: InputMaybe<
    Array<InputMaybe<Scalars["BigInt"]["input"]>>
  >;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  videoLink?: InputMaybe<Scalars["String"]["input"]>;
  visitDetails?: InputMaybe<Scalars["String"]["input"]>;
  visitType?: InputMaybe<TelehealthType>;
};

/** mutation root */
export type Mutation_RootCreateSchedulingAppointmentByClientArgs = {
  email: Scalars["String"]["input"];
  endTime: Scalars["DateTime"]["input"];
  firstName: Scalars["String"]["input"];
  isFds?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  phone: Scalars["String"]["input"];
  providerId: Scalars["BigInt"]["input"];
  serviceMenuItemIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
  startTime: Scalars["DateTime"]["input"];
};

/** mutation root */
export type Mutation_RootCreateShortMoxieUrlArgs = {
  fullUrl: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootCreateStripeCardArgs = {
  stripeAccountId: Scalars["String"]["input"];
  stripeCardholderId: Scalars["String"]["input"];
  stripeFinancialAccountId: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootCreateStripeCardholderArgs = {
  billingAddressCity: Scalars["String"]["input"];
  billingAddressLine1: Scalars["String"]["input"];
  billingAddressLine2?: InputMaybe<Scalars["String"]["input"]>;
  billingAddressPostalCode: Scalars["String"]["input"];
  billingAddressState?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  moxieUserId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  stripeAccountId: Scalars["String"]["input"];
  tosAccepted: Scalars["Boolean"]["input"];
};

/** mutation root */
export type Mutation_RootCreateStripeOutboundPaymentArgs = {
  addressCity?: InputMaybe<Scalars["String"]["input"]>;
  addressCountry?: InputMaybe<Scalars["String"]["input"]>;
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  addressPostalCode?: InputMaybe<Scalars["String"]["input"]>;
  addressState?: InputMaybe<Scalars["String"]["input"]>;
  amountInCents: Scalars["Int"]["input"];
  bankAccountHolderType?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountType?: InputMaybe<Scalars["String"]["input"]>;
  bankRoutingNumber?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  shouldSavePaymentMethod?: InputMaybe<Scalars["Boolean"]["input"]>;
  statementDescriptor?: InputMaybe<Scalars["String"]["input"]>;
  stripeAccountId: Scalars["String"]["input"];
  stripeFinancialAccountId: Scalars["String"]["input"];
  stripePaymentMethodId?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootCreateTelehealthDetailsArgs = {
  videoLink?: InputMaybe<Scalars["String"]["input"]>;
  visitDetails?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["BigInt"]["input"];
  visitType: TelehealthType;
};

/** mutation root */
export type Mutation_RootDeleteAddressArgs = {
  where: AddressBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAddressByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteAdministrativeDivisionArgs = {
  where: AdministrativeDivisionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAdministrativeDivisionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteAdverseReactionArgs = {
  where: AdverseReactionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAdverseReactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteAdverseReactionTemplateArgs = {
  where: AdverseReactionTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAdverseReactionTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteAvailabilityEventArgs = {
  where: AvailabilityEventBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAvailabilityEventByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteCardArgs = {
  cardId: Scalars["String"]["input"];
  clientId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteCareFormArgs = {
  where: CareFormBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCareFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteCareFormServiceMenuItemsArgs = {
  where: CareFormServiceMenuItemsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCareFormServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteClientArgs = {
  where: ClientBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClientAccessTokenArgs = {
  where: ClientAccessTokenBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClientAccessTokenByPkArgs = {
  token: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteClientByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteClientMembershipArgs = {
  where: ClientMembershipBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClientMembershipByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteComplianceTaskArgs = {
  where: ComplianceTaskBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteComplianceTaskByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteComplianceTaskDocumentArgs = {
  where: ComplianceTaskDocumentBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteComplianceTaskDocumentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteComplianceTaskTemplateArgs = {
  where: ComplianceTaskTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteComplianceTaskTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteCompliantNoteArgs = {
  where: CompliantNoteBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCompliantNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteCompliantNoteTemplateArgs = {
  where: CompliantNoteTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCompliantNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteConsentFormArgs = {
  where: ConsentFormBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteConsentFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteConsentFormServiceMenuItemsArgs = {
  where: ConsentFormServiceMenuItemsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteConsentFormServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteConsentFormSignatureArgs = {
  where: ConsentFormSignatureBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteConsentFormSignatureByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteDepositLineArgs = {
  where: DepositLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDepositLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteDiagramArgs = {
  where: DiagramBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDiagramByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteDiagramTemplateArgs = {
  where: DiagramTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDiagramTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteDiscountArgs = {
  where: DiscountBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDiscountByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteDiscountLineArgs = {
  where: DiscountLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDiscountLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteDiscountRetailProductsArgs = {
  where: DiscountRetailProductsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDiscountRetailProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteDiscountServiceMenuItemsArgs = {
  where: DiscountServiceMenuItemsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDiscountServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteDiscountServiceProductsArgs = {
  where: DiscountServiceProductsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteDiscountServiceProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFeaturePermissionArgs = {
  where: FeaturePermissionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFeaturePermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFeeArgs = {
  where: FeeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFeeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFeeLineArgs = {
  where: FeeLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFeeLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFormArgs = {
  where: FormBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFormServiceMenuItemArgs = {
  where: FormServiceMenuItemBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFormServiceMenuItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFormSubmissionArgs = {
  where: FormSubmissionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFormSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFormSubmissionGfeStatusChangeLogArgs = {
  where: FormSubmissionGfeStatusChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFormSubmissionGfeStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFormsFormMedspaServiceOfferingsArgs = {
  where: FormsFormMedspaServiceOfferingsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFormsFormMedspaServiceOfferingsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteFormsFormsubmissionversionArgs = {
  where: FormsFormsubmissionversionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFormsFormsubmissionversionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGfeDiagnosisArgs = {
  where: GfeDiagnosisBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGfeDiagnosisByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGfeReviewDiagnosisArgs = {
  where: GfeReviewDiagnosisBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGfeReviewDiagnosisByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGfeReviewRequestArgs = {
  where: GfeReviewRequestBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGfeReviewRequestByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGfeReviewSubmissionArgs = {
  where: GfeReviewSubmissionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGfeReviewSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGfeServiceProductIndicationArgs = {
  where: GfeServiceProductIndicationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGfeServiceProductIndicationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGfeSynchronousReviewDocumentArgs = {
  where: GfeSynchronousReviewDocumentBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGfeSynchronousReviewDocumentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGfeTreatmentIndicationArgs = {
  where: GfeTreatmentIndicationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGfeTreatmentIndicationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteGiftBuilderBannerArgs = {
  where: GiftBuilderBannerBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteGiftBuilderBannerByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteInventoryLotArgs = {
  where: InventoryLotBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteInventoryLotByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteInventoryTransactionArgs = {
  where: InventoryTransactionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteInventoryTransactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteInvoiceArgs = {
  where: InvoiceBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteInvoiceByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMarketingTemplateArgs = {
  where: MarketingTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMarketingTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMdMeetingArgs = {
  where: MdMeetingBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMdMeetingAttendeesArgs = {
  where: MdMeetingAttendeesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMdMeetingAttendeesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMdMeetingByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMdMeetingNoteArgs = {
  where: MdMeetingNoteBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMdMeetingNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMdMeetingNoteTemplateArgs = {
  where: MdMeetingNoteTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMdMeetingNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedicalDirectorArgs = {
  where: MedicalDirectorBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedicalDirectorByPkArgs = {
  userId: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedicalDirectorSubscriptionArgs = {
  where: MedicalDirectorSubscriptionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedicalDirectorSubscriptionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaArgs = {
  where: MedspaBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaRoomsAppointmentsArgs = {
  where: MedspaRoomsAppointmentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaRoomsAppointmentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceMenuItemArgs = {
  where: MedspaServiceMenuItemBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceMenuItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceMenuItemMedspaServiceOfferingsArgs =
  {
    where: MedspaServiceMenuItemMedspaServiceOfferingsBoolExp;
  };

/** mutation root */
export type Mutation_RootDeleteMedspaServiceMenuItemMedspaServiceOfferingsByPkArgs =
  {
    id: Scalars["bigint"]["input"];
  };

/** mutation root */
export type Mutation_RootDeleteMedspaServiceMenuItemOldProductsArgs = {
  where: MedspaServiceMenuItemOldProductsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceMenuItemOldProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceMenuItemProvidersArgs = {
  where: MedspaServiceMenuItemProvidersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceMenuItemProvidersByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceOfferingArgs = {
  where: MedspaServiceOfferingBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceOfferingByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceOfferingNoteTemplatesArgs = {
  where: MedspaServiceOfferingNoteTemplatesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceOfferingNoteTemplatesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceOfferingNotesArgs = {
  where: MedspaServiceOfferingNotesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceOfferingNotesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceProductArgs = {
  where: MedspaServiceProductBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceProtocolTemplateArgs = {
  where: MedspaServiceProtocolTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaServiceProtocolTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaStandingOrderTemplateArgs = {
  where: MedspaStandingOrderTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaStandingOrderTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspaWalletCreditTypeArgs = {
  where: MedspaWalletCreditTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspaWalletCreditTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspasFeatureflagArgs = {
  where: MedspasFeatureflagBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspasFeatureflagByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspasMedicallicenseArgs = {
  where: MedspasMedicallicenseBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspasMedicallicenseByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspasMedspaconfigurationArgs = {
  where: MedspasMedspaconfigurationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspasMedspaconfigurationByPkArgs = {
  medspaId: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspasProfessionalcorporationArgs = {
  where: MedspasProfessionalcorporationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspasProfessionalcorporationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspasStripeoutboundpaymentmethodArgs = {
  where: MedspasStripeoutboundpaymentmethodBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspasStripeoutboundpaymentmethodByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMedspasUsermedspaAcquiredMedicalLicensesArgs = {
  where: MedspasUsermedspaAcquiredMedicalLicensesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMedspasUsermedspaAcquiredMedicalLicensesByPkArgs =
  {
    id: Scalars["bigint"]["input"];
  };

/** mutation root */
export type Mutation_RootDeleteMembershipArgs = {
  where: MembershipBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMembershipByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMembershipCategoryArgs = {
  where: MembershipCategoryBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMembershipCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMembershipLineArgs = {
  where: MembershipLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMembershipLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMembershipPerkArgs = {
  where: MembershipPerkBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMembershipPerkByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteMembershipPerkLineArgs = {
  where: MembershipPerkLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMembershipPerkLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteModelPermissionArgs = {
  where: ModelPermissionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteModelPermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteNoteArgs = {
  where: NoteBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteNoteChangeLogArgs = {
  where: NoteChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteNoteChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteNoteFormSubmissionArgs = {
  where: NoteFormSubmissionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteNoteFormSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteNoteTemplateArgs = {
  where: NoteTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeletePackageArgs = {
  where: PackageBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePackageByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeletePackageItemArgs = {
  where: PackageItemBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePackageItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeletePackageItemLineArgs = {
  where: PackageItemLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePackageItemLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeletePackageLineArgs = {
  where: PackageLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePackageLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeletePackageLineWithItemLinesArgs = {
  packageLineId: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDeletePaymentArgs = {
  where: PaymentBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePaymentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeletePhotoArgs = {
  where: PhotoBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePhotoByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteProductArgs = {
  where: ProductBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteProductLineArgs = {
  where: ProductLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProductLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteProviderNotRequireDocumentsArgs = {
  where: ProviderNotRequireDocumentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProviderNotRequireDocumentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteRecurringAvailabilityArgs = {
  where: RecurringAvailabilityBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteRecurringAvailabilityByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteRefundArgs = {
  where: RefundBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteRefundByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteReportArgs = {
  where: ReportBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteReportByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteReportCategoryArgs = {
  where: ReportCategoryBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteReportCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteResourcesMedspaRoomArgs = {
  where: ResourcesMedspaRoomBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteResourcesMedspaRoomByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteSchedulingAppointmentArgs = {
  where: SchedulingAppointmentBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSchedulingAppointmentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteScriptProductArgs = {
  where: ScriptProductBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteScriptProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteScriptRequestArgs = {
  where: ScriptRequestBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteScriptRequestByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteScriptRequestStatusChangeLogArgs = {
  where: ScriptRequestStatusChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteScriptRequestStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceCategoryArgs = {
  where: ServiceCategoryBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceDeviceArgs = {
  where: ServiceDeviceBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceDeviceByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceDevicesAppointmentsArgs = {
  where: ServiceDevicesAppointmentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceDevicesAppointmentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceDevicesServiceTypesArgs = {
  where: ServiceDevicesServiceTypesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceDevicesServiceTypesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceMenuItemLineArgs = {
  where: ServiceMenuItemLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceMenuItemLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceMenuItemLineStandingOrdersArgs = {
  where: ServiceMenuItemLineStandingOrdersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceMenuItemLineStandingOrdersByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceProductArgs = {
  where: ServiceProductBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceProductChartingNoteArgs = {
  where: ServiceProductChartingNoteBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceProductChartingNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceProductLineArgs = {
  where: ServiceProductLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceProductLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceProductTypeArgs = {
  where: ServiceProductTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceProductTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceProductUsageArgs = {
  where: ServiceProductUsageBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceProductUsageByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceProtocolArgs = {
  where: ServiceProtocolBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceProtocolByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceProtocolTemplateArgs = {
  where: ServiceProtocolTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceProtocolTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceTypeArgs = {
  where: ServiceTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteServiceTypeCategoryArgs = {
  where: ServiceTypeCategoryBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteServiceTypeCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteShortMoxieUrlArgs = {
  where: ShortMoxieUrlBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteShortMoxieUrlByPkArgs = {
  uuid: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteStandingOrderArgs = {
  where: StandingOrderBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStandingOrderByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteStandingOrderTemplateArgs = {
  where: StandingOrderTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStandingOrderTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteSupplierInformationArgs = {
  where: SupplierInformationBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSupplierInformationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteTelehealthVisitDetailsArgs = {
  where: TelehealthVisitDetailsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTelehealthVisitDetailsByPkArgs = {
  visitId: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteTmpStandingOrderArgs = {
  where: TmpStandingOrderBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTmpStandingOrderByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteUrlPatternPermissionArgs = {
  where: UrlPatternPermissionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUrlPatternPermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  where: UserBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUserByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteUserMedspaArgs = {
  where: UserMedspaBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUserMedspaByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteVisitArgs = {
  where: VisitBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteVisitByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteVisitReviewStatusChangeLogArgs = {
  where: VisitReviewStatusChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteVisitReviewStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteVisitStatusChangeLogArgs = {
  where: VisitStatusChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteVisitStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteVisitsAdversereactionchangelogArgs = {
  where: VisitsAdversereactionchangelogBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteVisitsAdversereactionchangelogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteVisitsChartsignatureArgs = {
  where: VisitsChartsignatureBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteVisitsChartsignatureByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteVisitsTelehealthvisitdetailschangelogArgs = {
  where: VisitsTelehealthvisitdetailschangelogBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteVisitsTelehealthvisitdetailschangelogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteWalletCreditLineArgs = {
  where: WalletCreditLineBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWalletCreditLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteWalletCreditTypeArgs = {
  where: WalletCreditTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWalletCreditTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteWalletTransactionArgs = {
  where: WalletTransactionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWalletTransactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteWalletTransactionSourceTmpArgs = {
  where: WalletTransactionSourceTmpBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWalletTransactionSourceTmpByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDeleteWalletTransactionsSourcesArgs = {
  where: WalletTransactionsSourcesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWalletTransactionsSourcesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** mutation root */
export type Mutation_RootDuplicateDiagramArgs = {
  diagramId?: InputMaybe<Scalars["BigInt"]["input"]>;
  visitId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootDuplicateMembershipArgs = {
  membershipId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootDuplicateNoteArgs = {
  noteId?: InputMaybe<Scalars["BigInt"]["input"]>;
  visitId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootEditAdverseReactionArgs = {
  adverseReactionId: Scalars["BigInt"]["input"];
  reportContent: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootEditPersonalDataArgs = {
  clientPhoneNumber: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  medspaSlug: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootEditTelehealthDetailsArgs = {
  videoLink?: InputMaybe<Scalars["String"]["input"]>;
  visitDetails?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["BigInt"]["input"];
  visitType: TelehealthType;
};

/** mutation root */
export type Mutation_RootExpireClientAccessTokenArgs = {
  clientAccessToken: Scalars["String"]["input"];
  medspaSlug: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootExpireConsentFormsArgs = {
  consentFormSignatureIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
};

/** mutation root */
export type Mutation_RootExpireFormSubmissionsArgs = {
  formSubmissionIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
};

/** mutation root */
export type Mutation_RootFinalizePaymentWithWalletCreditsArgs = {
  amountPaid?: InputMaybe<Scalars["Decimal"]["input"]>;
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootGenerateBooking2faForClientArgs = {
  clientPhoneNumber: Scalars["String"]["input"];
  medspaSlug: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootGenerateCardSetupIntentArgs = {
  clientId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootGenerateClientAccessTokenWithOtpArgs = {
  clientEmail?: InputMaybe<Scalars["String"]["input"]>;
  clientPhone?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  medspaSlug: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootGenerateClientPortalOtpArgs = {
  clientEmail?: InputMaybe<Scalars["String"]["input"]>;
  clientPhone?: InputMaybe<Scalars["String"]["input"]>;
  medspaSlug: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootGenerateSyncDocUploadUrlArgs = {
  clientId: Scalars["BigInt"]["input"];
  filename?: InputMaybe<Scalars["String"]["input"]>;
  gfeSyncDocumentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  serviceOfferingIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
};

/** mutation root */
export type Mutation_RootHardDeleteClientArgs = {
  clientId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootHighLevelSendNewMessageToConversationArgs = {
  contactId: Scalars["String"]["input"];
  locationId: Scalars["String"]["input"];
  messageData: SendMessageDataInputType;
  messageType: OutboundMessageTypeValues;
};

/** mutation root */
export type Mutation_RootInitMobilePaymentArgs = {
  amountPaid?: InputMaybe<Scalars["Decimal"]["input"]>;
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
  shouldSaveCard?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** mutation root */
export type Mutation_RootInsertAddressArgs = {
  objects: Array<AddressInsertInput>;
  onConflict?: InputMaybe<AddressOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAddressOneArgs = {
  object: AddressInsertInput;
  onConflict?: InputMaybe<AddressOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAdministrativeDivisionArgs = {
  objects: Array<AdministrativeDivisionInsertInput>;
  onConflict?: InputMaybe<AdministrativeDivisionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAdministrativeDivisionOneArgs = {
  object: AdministrativeDivisionInsertInput;
  onConflict?: InputMaybe<AdministrativeDivisionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAdverseReactionArgs = {
  objects: Array<AdverseReactionInsertInput>;
  onConflict?: InputMaybe<AdverseReactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAdverseReactionOneArgs = {
  object: AdverseReactionInsertInput;
  onConflict?: InputMaybe<AdverseReactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAdverseReactionTemplateArgs = {
  objects: Array<AdverseReactionTemplateInsertInput>;
  onConflict?: InputMaybe<AdverseReactionTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAdverseReactionTemplateOneArgs = {
  object: AdverseReactionTemplateInsertInput;
  onConflict?: InputMaybe<AdverseReactionTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAvailabilityEventArgs = {
  objects: Array<AvailabilityEventInsertInput>;
  onConflict?: InputMaybe<AvailabilityEventOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAvailabilityEventOneArgs = {
  object: AvailabilityEventInsertInput;
  onConflict?: InputMaybe<AvailabilityEventOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCareFormArgs = {
  objects: Array<CareFormInsertInput>;
  onConflict?: InputMaybe<CareFormOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCareFormOneArgs = {
  object: CareFormInsertInput;
  onConflict?: InputMaybe<CareFormOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCareFormServiceMenuItemsArgs = {
  objects: Array<CareFormServiceMenuItemsInsertInput>;
  onConflict?: InputMaybe<CareFormServiceMenuItemsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCareFormServiceMenuItemsOneArgs = {
  object: CareFormServiceMenuItemsInsertInput;
  onConflict?: InputMaybe<CareFormServiceMenuItemsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClientArgs = {
  objects: Array<ClientInsertInput>;
  onConflict?: InputMaybe<ClientOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClientAccessTokenArgs = {
  objects: Array<ClientAccessTokenInsertInput>;
  onConflict?: InputMaybe<ClientAccessTokenOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClientAccessTokenOneArgs = {
  object: ClientAccessTokenInsertInput;
  onConflict?: InputMaybe<ClientAccessTokenOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClientMembershipArgs = {
  objects: Array<ClientMembershipInsertInput>;
  onConflict?: InputMaybe<ClientMembershipOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClientMembershipOneArgs = {
  object: ClientMembershipInsertInput;
  onConflict?: InputMaybe<ClientMembershipOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClientOneArgs = {
  object: ClientInsertInput;
  onConflict?: InputMaybe<ClientOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertComplianceTaskArgs = {
  objects: Array<ComplianceTaskInsertInput>;
  onConflict?: InputMaybe<ComplianceTaskOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertComplianceTaskDocumentArgs = {
  objects: Array<ComplianceTaskDocumentInsertInput>;
  onConflict?: InputMaybe<ComplianceTaskDocumentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertComplianceTaskDocumentOneArgs = {
  object: ComplianceTaskDocumentInsertInput;
  onConflict?: InputMaybe<ComplianceTaskDocumentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertComplianceTaskOneArgs = {
  object: ComplianceTaskInsertInput;
  onConflict?: InputMaybe<ComplianceTaskOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertComplianceTaskTemplateArgs = {
  objects: Array<ComplianceTaskTemplateInsertInput>;
  onConflict?: InputMaybe<ComplianceTaskTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertComplianceTaskTemplateOneArgs = {
  object: ComplianceTaskTemplateInsertInput;
  onConflict?: InputMaybe<ComplianceTaskTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCompliantNoteArgs = {
  objects: Array<CompliantNoteInsertInput>;
  onConflict?: InputMaybe<CompliantNoteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCompliantNoteOneArgs = {
  object: CompliantNoteInsertInput;
  onConflict?: InputMaybe<CompliantNoteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCompliantNoteTemplateArgs = {
  objects: Array<CompliantNoteTemplateInsertInput>;
  onConflict?: InputMaybe<CompliantNoteTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCompliantNoteTemplateOneArgs = {
  object: CompliantNoteTemplateInsertInput;
  onConflict?: InputMaybe<CompliantNoteTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertConsentFormArgs = {
  objects: Array<ConsentFormInsertInput>;
  onConflict?: InputMaybe<ConsentFormOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertConsentFormOneArgs = {
  object: ConsentFormInsertInput;
  onConflict?: InputMaybe<ConsentFormOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertConsentFormServiceMenuItemsArgs = {
  objects: Array<ConsentFormServiceMenuItemsInsertInput>;
  onConflict?: InputMaybe<ConsentFormServiceMenuItemsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertConsentFormServiceMenuItemsOneArgs = {
  object: ConsentFormServiceMenuItemsInsertInput;
  onConflict?: InputMaybe<ConsentFormServiceMenuItemsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertConsentFormSignatureArgs = {
  objects: Array<ConsentFormSignatureInsertInput>;
  onConflict?: InputMaybe<ConsentFormSignatureOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertConsentFormSignatureOneArgs = {
  object: ConsentFormSignatureInsertInput;
  onConflict?: InputMaybe<ConsentFormSignatureOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDepositLineArgs = {
  objects: Array<DepositLineInsertInput>;
  onConflict?: InputMaybe<DepositLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDepositLineOneArgs = {
  object: DepositLineInsertInput;
  onConflict?: InputMaybe<DepositLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiagramArgs = {
  objects: Array<DiagramInsertInput>;
  onConflict?: InputMaybe<DiagramOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiagramOneArgs = {
  object: DiagramInsertInput;
  onConflict?: InputMaybe<DiagramOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiagramTemplateArgs = {
  objects: Array<DiagramTemplateInsertInput>;
  onConflict?: InputMaybe<DiagramTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiagramTemplateOneArgs = {
  object: DiagramTemplateInsertInput;
  onConflict?: InputMaybe<DiagramTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountArgs = {
  objects: Array<DiscountInsertInput>;
  onConflict?: InputMaybe<DiscountOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountLineArgs = {
  objects: Array<DiscountLineInsertInput>;
  onConflict?: InputMaybe<DiscountLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountLineOneArgs = {
  object: DiscountLineInsertInput;
  onConflict?: InputMaybe<DiscountLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountOneArgs = {
  object: DiscountInsertInput;
  onConflict?: InputMaybe<DiscountOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountRetailProductsArgs = {
  objects: Array<DiscountRetailProductsInsertInput>;
  onConflict?: InputMaybe<DiscountRetailProductsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountRetailProductsOneArgs = {
  object: DiscountRetailProductsInsertInput;
  onConflict?: InputMaybe<DiscountRetailProductsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountServiceMenuItemsArgs = {
  objects: Array<DiscountServiceMenuItemsInsertInput>;
  onConflict?: InputMaybe<DiscountServiceMenuItemsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountServiceMenuItemsOneArgs = {
  object: DiscountServiceMenuItemsInsertInput;
  onConflict?: InputMaybe<DiscountServiceMenuItemsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountServiceProductsArgs = {
  objects: Array<DiscountServiceProductsInsertInput>;
  onConflict?: InputMaybe<DiscountServiceProductsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertDiscountServiceProductsOneArgs = {
  object: DiscountServiceProductsInsertInput;
  onConflict?: InputMaybe<DiscountServiceProductsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFeaturePermissionArgs = {
  objects: Array<FeaturePermissionInsertInput>;
  onConflict?: InputMaybe<FeaturePermissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFeaturePermissionOneArgs = {
  object: FeaturePermissionInsertInput;
  onConflict?: InputMaybe<FeaturePermissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFeeArgs = {
  objects: Array<FeeInsertInput>;
  onConflict?: InputMaybe<FeeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFeeLineArgs = {
  objects: Array<FeeLineInsertInput>;
  onConflict?: InputMaybe<FeeLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFeeLineOneArgs = {
  object: FeeLineInsertInput;
  onConflict?: InputMaybe<FeeLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFeeOneArgs = {
  object: FeeInsertInput;
  onConflict?: InputMaybe<FeeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormArgs = {
  objects: Array<FormInsertInput>;
  onConflict?: InputMaybe<FormOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormOneArgs = {
  object: FormInsertInput;
  onConflict?: InputMaybe<FormOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormServiceMenuItemArgs = {
  objects: Array<FormServiceMenuItemInsertInput>;
  onConflict?: InputMaybe<FormServiceMenuItemOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormServiceMenuItemOneArgs = {
  object: FormServiceMenuItemInsertInput;
  onConflict?: InputMaybe<FormServiceMenuItemOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormSubmissionArgs = {
  objects: Array<FormSubmissionInsertInput>;
  onConflict?: InputMaybe<FormSubmissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormSubmissionGfeStatusChangeLogArgs = {
  objects: Array<FormSubmissionGfeStatusChangeLogInsertInput>;
  onConflict?: InputMaybe<FormSubmissionGfeStatusChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormSubmissionGfeStatusChangeLogOneArgs = {
  object: FormSubmissionGfeStatusChangeLogInsertInput;
  onConflict?: InputMaybe<FormSubmissionGfeStatusChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormSubmissionOneArgs = {
  object: FormSubmissionInsertInput;
  onConflict?: InputMaybe<FormSubmissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormsFormMedspaServiceOfferingsArgs = {
  objects: Array<FormsFormMedspaServiceOfferingsInsertInput>;
  onConflict?: InputMaybe<FormsFormMedspaServiceOfferingsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormsFormMedspaServiceOfferingsOneArgs = {
  object: FormsFormMedspaServiceOfferingsInsertInput;
  onConflict?: InputMaybe<FormsFormMedspaServiceOfferingsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormsFormsubmissionversionArgs = {
  objects: Array<FormsFormsubmissionversionInsertInput>;
  onConflict?: InputMaybe<FormsFormsubmissionversionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFormsFormsubmissionversionOneArgs = {
  object: FormsFormsubmissionversionInsertInput;
  onConflict?: InputMaybe<FormsFormsubmissionversionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeDiagnosisArgs = {
  objects: Array<GfeDiagnosisInsertInput>;
  onConflict?: InputMaybe<GfeDiagnosisOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeDiagnosisOneArgs = {
  object: GfeDiagnosisInsertInput;
  onConflict?: InputMaybe<GfeDiagnosisOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeReviewDiagnosisArgs = {
  objects: Array<GfeReviewDiagnosisInsertInput>;
  onConflict?: InputMaybe<GfeReviewDiagnosisOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeReviewDiagnosisOneArgs = {
  object: GfeReviewDiagnosisInsertInput;
  onConflict?: InputMaybe<GfeReviewDiagnosisOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeReviewRequestArgs = {
  objects: Array<GfeReviewRequestInsertInput>;
  onConflict?: InputMaybe<GfeReviewRequestOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeReviewRequestOneArgs = {
  object: GfeReviewRequestInsertInput;
  onConflict?: InputMaybe<GfeReviewRequestOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeReviewSubmissionArgs = {
  objects: Array<GfeReviewSubmissionInsertInput>;
  onConflict?: InputMaybe<GfeReviewSubmissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeReviewSubmissionOneArgs = {
  object: GfeReviewSubmissionInsertInput;
  onConflict?: InputMaybe<GfeReviewSubmissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeServiceProductIndicationArgs = {
  objects: Array<GfeServiceProductIndicationInsertInput>;
  onConflict?: InputMaybe<GfeServiceProductIndicationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeServiceProductIndicationOneArgs = {
  object: GfeServiceProductIndicationInsertInput;
  onConflict?: InputMaybe<GfeServiceProductIndicationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeSynchronousReviewDocumentArgs = {
  objects: Array<GfeSynchronousReviewDocumentInsertInput>;
  onConflict?: InputMaybe<GfeSynchronousReviewDocumentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeSynchronousReviewDocumentOneArgs = {
  object: GfeSynchronousReviewDocumentInsertInput;
  onConflict?: InputMaybe<GfeSynchronousReviewDocumentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeTreatmentIndicationArgs = {
  objects: Array<GfeTreatmentIndicationInsertInput>;
  onConflict?: InputMaybe<GfeTreatmentIndicationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGfeTreatmentIndicationOneArgs = {
  object: GfeTreatmentIndicationInsertInput;
  onConflict?: InputMaybe<GfeTreatmentIndicationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGiftBuilderBannerArgs = {
  objects: Array<GiftBuilderBannerInsertInput>;
  onConflict?: InputMaybe<GiftBuilderBannerOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertGiftBuilderBannerOneArgs = {
  object: GiftBuilderBannerInsertInput;
  onConflict?: InputMaybe<GiftBuilderBannerOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertInventoryLotArgs = {
  objects: Array<InventoryLotInsertInput>;
  onConflict?: InputMaybe<InventoryLotOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertInventoryLotOneArgs = {
  object: InventoryLotInsertInput;
  onConflict?: InputMaybe<InventoryLotOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertInventoryTransactionArgs = {
  objects: Array<InventoryTransactionInsertInput>;
  onConflict?: InputMaybe<InventoryTransactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertInventoryTransactionOneArgs = {
  object: InventoryTransactionInsertInput;
  onConflict?: InputMaybe<InventoryTransactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertInvoiceArgs = {
  objects: Array<InvoiceInsertInput>;
  onConflict?: InputMaybe<InvoiceOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertInvoiceOneArgs = {
  object: InvoiceInsertInput;
  onConflict?: InputMaybe<InvoiceOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMarketingTemplateArgs = {
  objects: Array<MarketingTemplateInsertInput>;
  onConflict?: InputMaybe<MarketingTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMarketingTemplateOneArgs = {
  object: MarketingTemplateInsertInput;
  onConflict?: InputMaybe<MarketingTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMdMeetingArgs = {
  objects: Array<MdMeetingInsertInput>;
  onConflict?: InputMaybe<MdMeetingOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMdMeetingAttendeesArgs = {
  objects: Array<MdMeetingAttendeesInsertInput>;
  onConflict?: InputMaybe<MdMeetingAttendeesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMdMeetingAttendeesOneArgs = {
  object: MdMeetingAttendeesInsertInput;
  onConflict?: InputMaybe<MdMeetingAttendeesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMdMeetingNoteArgs = {
  objects: Array<MdMeetingNoteInsertInput>;
  onConflict?: InputMaybe<MdMeetingNoteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMdMeetingNoteOneArgs = {
  object: MdMeetingNoteInsertInput;
  onConflict?: InputMaybe<MdMeetingNoteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMdMeetingNoteTemplateArgs = {
  objects: Array<MdMeetingNoteTemplateInsertInput>;
  onConflict?: InputMaybe<MdMeetingNoteTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMdMeetingNoteTemplateOneArgs = {
  object: MdMeetingNoteTemplateInsertInput;
  onConflict?: InputMaybe<MdMeetingNoteTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMdMeetingOneArgs = {
  object: MdMeetingInsertInput;
  onConflict?: InputMaybe<MdMeetingOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedicalDirectorArgs = {
  objects: Array<MedicalDirectorInsertInput>;
  onConflict?: InputMaybe<MedicalDirectorOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedicalDirectorOneArgs = {
  object: MedicalDirectorInsertInput;
  onConflict?: InputMaybe<MedicalDirectorOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedicalDirectorSubscriptionArgs = {
  objects: Array<MedicalDirectorSubscriptionInsertInput>;
  onConflict?: InputMaybe<MedicalDirectorSubscriptionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedicalDirectorSubscriptionOneArgs = {
  object: MedicalDirectorSubscriptionInsertInput;
  onConflict?: InputMaybe<MedicalDirectorSubscriptionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaArgs = {
  objects: Array<MedspaInsertInput>;
  onConflict?: InputMaybe<MedspaOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaOneArgs = {
  object: MedspaInsertInput;
  onConflict?: InputMaybe<MedspaOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaRoomsAppointmentsArgs = {
  objects: Array<MedspaRoomsAppointmentsInsertInput>;
  onConflict?: InputMaybe<MedspaRoomsAppointmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaRoomsAppointmentsOneArgs = {
  object: MedspaRoomsAppointmentsInsertInput;
  onConflict?: InputMaybe<MedspaRoomsAppointmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceMenuItemArgs = {
  objects: Array<MedspaServiceMenuItemInsertInput>;
  onConflict?: InputMaybe<MedspaServiceMenuItemOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceMenuItemMedspaServiceOfferingsArgs =
  {
    objects: Array<MedspaServiceMenuItemMedspaServiceOfferingsInsertInput>;
    onConflict?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsOnConflict>;
  };

/** mutation root */
export type Mutation_RootInsertMedspaServiceMenuItemMedspaServiceOfferingsOneArgs =
  {
    object: MedspaServiceMenuItemMedspaServiceOfferingsInsertInput;
    onConflict?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsOnConflict>;
  };

/** mutation root */
export type Mutation_RootInsertMedspaServiceMenuItemOldProductsArgs = {
  objects: Array<MedspaServiceMenuItemOldProductsInsertInput>;
  onConflict?: InputMaybe<MedspaServiceMenuItemOldProductsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceMenuItemOldProductsOneArgs = {
  object: MedspaServiceMenuItemOldProductsInsertInput;
  onConflict?: InputMaybe<MedspaServiceMenuItemOldProductsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceMenuItemOneArgs = {
  object: MedspaServiceMenuItemInsertInput;
  onConflict?: InputMaybe<MedspaServiceMenuItemOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceMenuItemProvidersArgs = {
  objects: Array<MedspaServiceMenuItemProvidersInsertInput>;
  onConflict?: InputMaybe<MedspaServiceMenuItemProvidersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceMenuItemProvidersOneArgs = {
  object: MedspaServiceMenuItemProvidersInsertInput;
  onConflict?: InputMaybe<MedspaServiceMenuItemProvidersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceOfferingArgs = {
  objects: Array<MedspaServiceOfferingInsertInput>;
  onConflict?: InputMaybe<MedspaServiceOfferingOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceOfferingNoteTemplatesArgs = {
  objects: Array<MedspaServiceOfferingNoteTemplatesInsertInput>;
  onConflict?: InputMaybe<MedspaServiceOfferingNoteTemplatesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceOfferingNoteTemplatesOneArgs = {
  object: MedspaServiceOfferingNoteTemplatesInsertInput;
  onConflict?: InputMaybe<MedspaServiceOfferingNoteTemplatesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceOfferingNotesArgs = {
  objects: Array<MedspaServiceOfferingNotesInsertInput>;
  onConflict?: InputMaybe<MedspaServiceOfferingNotesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceOfferingNotesOneArgs = {
  object: MedspaServiceOfferingNotesInsertInput;
  onConflict?: InputMaybe<MedspaServiceOfferingNotesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceOfferingOneArgs = {
  object: MedspaServiceOfferingInsertInput;
  onConflict?: InputMaybe<MedspaServiceOfferingOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceProductArgs = {
  objects: Array<MedspaServiceProductInsertInput>;
  onConflict?: InputMaybe<MedspaServiceProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceProductOneArgs = {
  object: MedspaServiceProductInsertInput;
  onConflict?: InputMaybe<MedspaServiceProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceProtocolTemplateArgs = {
  objects: Array<MedspaServiceProtocolTemplateInsertInput>;
  onConflict?: InputMaybe<MedspaServiceProtocolTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaServiceProtocolTemplateOneArgs = {
  object: MedspaServiceProtocolTemplateInsertInput;
  onConflict?: InputMaybe<MedspaServiceProtocolTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaStandingOrderTemplateArgs = {
  objects: Array<MedspaStandingOrderTemplateInsertInput>;
  onConflict?: InputMaybe<MedspaStandingOrderTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaStandingOrderTemplateOneArgs = {
  object: MedspaStandingOrderTemplateInsertInput;
  onConflict?: InputMaybe<MedspaStandingOrderTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaWalletCreditTypeArgs = {
  objects: Array<MedspaWalletCreditTypeInsertInput>;
  onConflict?: InputMaybe<MedspaWalletCreditTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspaWalletCreditTypeOneArgs = {
  object: MedspaWalletCreditTypeInsertInput;
  onConflict?: InputMaybe<MedspaWalletCreditTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasFeatureflagArgs = {
  objects: Array<MedspasFeatureflagInsertInput>;
  onConflict?: InputMaybe<MedspasFeatureflagOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasFeatureflagOneArgs = {
  object: MedspasFeatureflagInsertInput;
  onConflict?: InputMaybe<MedspasFeatureflagOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasMedicallicenseArgs = {
  objects: Array<MedspasMedicallicenseInsertInput>;
  onConflict?: InputMaybe<MedspasMedicallicenseOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasMedicallicenseOneArgs = {
  object: MedspasMedicallicenseInsertInput;
  onConflict?: InputMaybe<MedspasMedicallicenseOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasMedspaconfigurationArgs = {
  objects: Array<MedspasMedspaconfigurationInsertInput>;
  onConflict?: InputMaybe<MedspasMedspaconfigurationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasMedspaconfigurationOneArgs = {
  object: MedspasMedspaconfigurationInsertInput;
  onConflict?: InputMaybe<MedspasMedspaconfigurationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasProfessionalcorporationArgs = {
  objects: Array<MedspasProfessionalcorporationInsertInput>;
  onConflict?: InputMaybe<MedspasProfessionalcorporationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasProfessionalcorporationOneArgs = {
  object: MedspasProfessionalcorporationInsertInput;
  onConflict?: InputMaybe<MedspasProfessionalcorporationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasStripeoutboundpaymentmethodArgs = {
  objects: Array<MedspasStripeoutboundpaymentmethodInsertInput>;
  onConflict?: InputMaybe<MedspasStripeoutboundpaymentmethodOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasStripeoutboundpaymentmethodOneArgs = {
  object: MedspasStripeoutboundpaymentmethodInsertInput;
  onConflict?: InputMaybe<MedspasStripeoutboundpaymentmethodOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasUsermedspaAcquiredMedicalLicensesArgs = {
  objects: Array<MedspasUsermedspaAcquiredMedicalLicensesInsertInput>;
  onConflict?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMedspasUsermedspaAcquiredMedicalLicensesOneArgs =
  {
    object: MedspasUsermedspaAcquiredMedicalLicensesInsertInput;
    onConflict?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesOnConflict>;
  };

/** mutation root */
export type Mutation_RootInsertMembershipArgs = {
  objects: Array<MembershipInsertInput>;
  onConflict?: InputMaybe<MembershipOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipCategoryArgs = {
  objects: Array<MembershipCategoryInsertInput>;
  onConflict?: InputMaybe<MembershipCategoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipCategoryOneArgs = {
  object: MembershipCategoryInsertInput;
  onConflict?: InputMaybe<MembershipCategoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipLineArgs = {
  objects: Array<MembershipLineInsertInput>;
  onConflict?: InputMaybe<MembershipLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipLineOneArgs = {
  object: MembershipLineInsertInput;
  onConflict?: InputMaybe<MembershipLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipOneArgs = {
  object: MembershipInsertInput;
  onConflict?: InputMaybe<MembershipOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipPerkArgs = {
  objects: Array<MembershipPerkInsertInput>;
  onConflict?: InputMaybe<MembershipPerkOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipPerkLineArgs = {
  objects: Array<MembershipPerkLineInsertInput>;
  onConflict?: InputMaybe<MembershipPerkLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipPerkLineOneArgs = {
  object: MembershipPerkLineInsertInput;
  onConflict?: InputMaybe<MembershipPerkLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMembershipPerkOneArgs = {
  object: MembershipPerkInsertInput;
  onConflict?: InputMaybe<MembershipPerkOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertModelPermissionArgs = {
  objects: Array<ModelPermissionInsertInput>;
  onConflict?: InputMaybe<ModelPermissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertModelPermissionOneArgs = {
  object: ModelPermissionInsertInput;
  onConflict?: InputMaybe<ModelPermissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNoteArgs = {
  objects: Array<NoteInsertInput>;
  onConflict?: InputMaybe<NoteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNoteChangeLogArgs = {
  objects: Array<NoteChangeLogInsertInput>;
  onConflict?: InputMaybe<NoteChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNoteChangeLogOneArgs = {
  object: NoteChangeLogInsertInput;
  onConflict?: InputMaybe<NoteChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNoteFormSubmissionArgs = {
  objects: Array<NoteFormSubmissionInsertInput>;
  onConflict?: InputMaybe<NoteFormSubmissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNoteFormSubmissionOneArgs = {
  object: NoteFormSubmissionInsertInput;
  onConflict?: InputMaybe<NoteFormSubmissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNoteOneArgs = {
  object: NoteInsertInput;
  onConflict?: InputMaybe<NoteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNoteTemplateArgs = {
  objects: Array<NoteTemplateInsertInput>;
  onConflict?: InputMaybe<NoteTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNoteTemplateOneArgs = {
  object: NoteTemplateInsertInput;
  onConflict?: InputMaybe<NoteTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPackageArgs = {
  objects: Array<PackageInsertInput>;
  onConflict?: InputMaybe<PackageOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPackageItemArgs = {
  objects: Array<PackageItemInsertInput>;
  onConflict?: InputMaybe<PackageItemOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPackageItemLineArgs = {
  objects: Array<PackageItemLineInsertInput>;
  onConflict?: InputMaybe<PackageItemLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPackageItemLineOneArgs = {
  object: PackageItemLineInsertInput;
  onConflict?: InputMaybe<PackageItemLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPackageItemOneArgs = {
  object: PackageItemInsertInput;
  onConflict?: InputMaybe<PackageItemOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPackageLineArgs = {
  objects: Array<PackageLineInsertInput>;
  onConflict?: InputMaybe<PackageLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPackageLineOneArgs = {
  object: PackageLineInsertInput;
  onConflict?: InputMaybe<PackageLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPackageOneArgs = {
  object: PackageInsertInput;
  onConflict?: InputMaybe<PackageOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentArgs = {
  objects: Array<PaymentInsertInput>;
  onConflict?: InputMaybe<PaymentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentOneArgs = {
  object: PaymentInsertInput;
  onConflict?: InputMaybe<PaymentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPhotoArgs = {
  objects: Array<PhotoInsertInput>;
  onConflict?: InputMaybe<PhotoOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPhotoOneArgs = {
  object: PhotoInsertInput;
  onConflict?: InputMaybe<PhotoOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductArgs = {
  objects: Array<ProductInsertInput>;
  onConflict?: InputMaybe<ProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductLineArgs = {
  objects: Array<ProductLineInsertInput>;
  onConflict?: InputMaybe<ProductLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductLineOneArgs = {
  object: ProductLineInsertInput;
  onConflict?: InputMaybe<ProductLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProductOneArgs = {
  object: ProductInsertInput;
  onConflict?: InputMaybe<ProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProviderNotRequireDocumentsArgs = {
  objects: Array<ProviderNotRequireDocumentsInsertInput>;
  onConflict?: InputMaybe<ProviderNotRequireDocumentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProviderNotRequireDocumentsOneArgs = {
  object: ProviderNotRequireDocumentsInsertInput;
  onConflict?: InputMaybe<ProviderNotRequireDocumentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRecurringAvailabilityArgs = {
  objects: Array<RecurringAvailabilityInsertInput>;
  onConflict?: InputMaybe<RecurringAvailabilityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRecurringAvailabilityOneArgs = {
  object: RecurringAvailabilityInsertInput;
  onConflict?: InputMaybe<RecurringAvailabilityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRefundArgs = {
  objects: Array<RefundInsertInput>;
  onConflict?: InputMaybe<RefundOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRefundOneArgs = {
  object: RefundInsertInput;
  onConflict?: InputMaybe<RefundOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportArgs = {
  objects: Array<ReportInsertInput>;
  onConflict?: InputMaybe<ReportOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportCategoryArgs = {
  objects: Array<ReportCategoryInsertInput>;
  onConflict?: InputMaybe<ReportCategoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportCategoryOneArgs = {
  object: ReportCategoryInsertInput;
  onConflict?: InputMaybe<ReportCategoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportOneArgs = {
  object: ReportInsertInput;
  onConflict?: InputMaybe<ReportOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertResourcesMedspaRoomArgs = {
  objects: Array<ResourcesMedspaRoomInsertInput>;
  onConflict?: InputMaybe<ResourcesMedspaRoomOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertResourcesMedspaRoomOneArgs = {
  object: ResourcesMedspaRoomInsertInput;
  onConflict?: InputMaybe<ResourcesMedspaRoomOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSchedulingAppointmentArgs = {
  objects: Array<SchedulingAppointmentInsertInput>;
  onConflict?: InputMaybe<SchedulingAppointmentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSchedulingAppointmentOneArgs = {
  object: SchedulingAppointmentInsertInput;
  onConflict?: InputMaybe<SchedulingAppointmentOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertScriptProductArgs = {
  objects: Array<ScriptProductInsertInput>;
  onConflict?: InputMaybe<ScriptProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertScriptProductOneArgs = {
  object: ScriptProductInsertInput;
  onConflict?: InputMaybe<ScriptProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertScriptRequestArgs = {
  objects: Array<ScriptRequestInsertInput>;
  onConflict?: InputMaybe<ScriptRequestOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertScriptRequestOneArgs = {
  object: ScriptRequestInsertInput;
  onConflict?: InputMaybe<ScriptRequestOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertScriptRequestStatusChangeLogArgs = {
  objects: Array<ScriptRequestStatusChangeLogInsertInput>;
  onConflict?: InputMaybe<ScriptRequestStatusChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertScriptRequestStatusChangeLogOneArgs = {
  object: ScriptRequestStatusChangeLogInsertInput;
  onConflict?: InputMaybe<ScriptRequestStatusChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceCategoryArgs = {
  objects: Array<ServiceCategoryInsertInput>;
  onConflict?: InputMaybe<ServiceCategoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceCategoryOneArgs = {
  object: ServiceCategoryInsertInput;
  onConflict?: InputMaybe<ServiceCategoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceDeviceArgs = {
  objects: Array<ServiceDeviceInsertInput>;
  onConflict?: InputMaybe<ServiceDeviceOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceDeviceOneArgs = {
  object: ServiceDeviceInsertInput;
  onConflict?: InputMaybe<ServiceDeviceOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceDevicesAppointmentsArgs = {
  objects: Array<ServiceDevicesAppointmentsInsertInput>;
  onConflict?: InputMaybe<ServiceDevicesAppointmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceDevicesAppointmentsOneArgs = {
  object: ServiceDevicesAppointmentsInsertInput;
  onConflict?: InputMaybe<ServiceDevicesAppointmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceDevicesServiceTypesArgs = {
  objects: Array<ServiceDevicesServiceTypesInsertInput>;
  onConflict?: InputMaybe<ServiceDevicesServiceTypesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceDevicesServiceTypesOneArgs = {
  object: ServiceDevicesServiceTypesInsertInput;
  onConflict?: InputMaybe<ServiceDevicesServiceTypesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceMenuItemLineArgs = {
  objects: Array<ServiceMenuItemLineInsertInput>;
  onConflict?: InputMaybe<ServiceMenuItemLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceMenuItemLineOneArgs = {
  object: ServiceMenuItemLineInsertInput;
  onConflict?: InputMaybe<ServiceMenuItemLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceMenuItemLineStandingOrdersArgs = {
  objects: Array<ServiceMenuItemLineStandingOrdersInsertInput>;
  onConflict?: InputMaybe<ServiceMenuItemLineStandingOrdersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceMenuItemLineStandingOrdersOneArgs = {
  object: ServiceMenuItemLineStandingOrdersInsertInput;
  onConflict?: InputMaybe<ServiceMenuItemLineStandingOrdersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductArgs = {
  objects: Array<ServiceProductInsertInput>;
  onConflict?: InputMaybe<ServiceProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductChartingNoteArgs = {
  objects: Array<ServiceProductChartingNoteInsertInput>;
  onConflict?: InputMaybe<ServiceProductChartingNoteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductChartingNoteOneArgs = {
  object: ServiceProductChartingNoteInsertInput;
  onConflict?: InputMaybe<ServiceProductChartingNoteOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductLineArgs = {
  objects: Array<ServiceProductLineInsertInput>;
  onConflict?: InputMaybe<ServiceProductLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductLineOneArgs = {
  object: ServiceProductLineInsertInput;
  onConflict?: InputMaybe<ServiceProductLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductOneArgs = {
  object: ServiceProductInsertInput;
  onConflict?: InputMaybe<ServiceProductOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductTypeArgs = {
  objects: Array<ServiceProductTypeInsertInput>;
  onConflict?: InputMaybe<ServiceProductTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductTypeOneArgs = {
  object: ServiceProductTypeInsertInput;
  onConflict?: InputMaybe<ServiceProductTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductUsageArgs = {
  objects: Array<ServiceProductUsageInsertInput>;
  onConflict?: InputMaybe<ServiceProductUsageOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProductUsageOneArgs = {
  object: ServiceProductUsageInsertInput;
  onConflict?: InputMaybe<ServiceProductUsageOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProtocolArgs = {
  objects: Array<ServiceProtocolInsertInput>;
  onConflict?: InputMaybe<ServiceProtocolOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProtocolOneArgs = {
  object: ServiceProtocolInsertInput;
  onConflict?: InputMaybe<ServiceProtocolOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProtocolTemplateArgs = {
  objects: Array<ServiceProtocolTemplateInsertInput>;
  onConflict?: InputMaybe<ServiceProtocolTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceProtocolTemplateOneArgs = {
  object: ServiceProtocolTemplateInsertInput;
  onConflict?: InputMaybe<ServiceProtocolTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceTypeArgs = {
  objects: Array<ServiceTypeInsertInput>;
  onConflict?: InputMaybe<ServiceTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceTypeCategoryArgs = {
  objects: Array<ServiceTypeCategoryInsertInput>;
  onConflict?: InputMaybe<ServiceTypeCategoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceTypeCategoryOneArgs = {
  object: ServiceTypeCategoryInsertInput;
  onConflict?: InputMaybe<ServiceTypeCategoryOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertServiceTypeOneArgs = {
  object: ServiceTypeInsertInput;
  onConflict?: InputMaybe<ServiceTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertShortMoxieUrlArgs = {
  objects: Array<ShortMoxieUrlInsertInput>;
  onConflict?: InputMaybe<ShortMoxieUrlOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertShortMoxieUrlOneArgs = {
  object: ShortMoxieUrlInsertInput;
  onConflict?: InputMaybe<ShortMoxieUrlOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStandingOrderArgs = {
  objects: Array<StandingOrderInsertInput>;
  onConflict?: InputMaybe<StandingOrderOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStandingOrderOneArgs = {
  object: StandingOrderInsertInput;
  onConflict?: InputMaybe<StandingOrderOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStandingOrderTemplateArgs = {
  objects: Array<StandingOrderTemplateInsertInput>;
  onConflict?: InputMaybe<StandingOrderTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStandingOrderTemplateOneArgs = {
  object: StandingOrderTemplateInsertInput;
  onConflict?: InputMaybe<StandingOrderTemplateOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSupplierInformationArgs = {
  objects: Array<SupplierInformationInsertInput>;
  onConflict?: InputMaybe<SupplierInformationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSupplierInformationOneArgs = {
  object: SupplierInformationInsertInput;
  onConflict?: InputMaybe<SupplierInformationOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTelehealthVisitDetailsArgs = {
  objects: Array<TelehealthVisitDetailsInsertInput>;
  onConflict?: InputMaybe<TelehealthVisitDetailsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTelehealthVisitDetailsOneArgs = {
  object: TelehealthVisitDetailsInsertInput;
  onConflict?: InputMaybe<TelehealthVisitDetailsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTmpStandingOrderArgs = {
  objects: Array<TmpStandingOrderInsertInput>;
  onConflict?: InputMaybe<TmpStandingOrderOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTmpStandingOrderOneArgs = {
  object: TmpStandingOrderInsertInput;
  onConflict?: InputMaybe<TmpStandingOrderOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUrlPatternPermissionArgs = {
  objects: Array<UrlPatternPermissionInsertInput>;
  onConflict?: InputMaybe<UrlPatternPermissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUrlPatternPermissionOneArgs = {
  object: UrlPatternPermissionInsertInput;
  onConflict?: InputMaybe<UrlPatternPermissionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserArgs = {
  objects: Array<UserInsertInput>;
  onConflict?: InputMaybe<UserOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserMedspaArgs = {
  objects: Array<UserMedspaInsertInput>;
  onConflict?: InputMaybe<UserMedspaOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserMedspaOneArgs = {
  object: UserMedspaInsertInput;
  onConflict?: InputMaybe<UserMedspaOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserOneArgs = {
  object: UserInsertInput;
  onConflict?: InputMaybe<UserOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitArgs = {
  objects: Array<VisitInsertInput>;
  onConflict?: InputMaybe<VisitOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitOneArgs = {
  object: VisitInsertInput;
  onConflict?: InputMaybe<VisitOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitReviewStatusChangeLogArgs = {
  objects: Array<VisitReviewStatusChangeLogInsertInput>;
  onConflict?: InputMaybe<VisitReviewStatusChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitReviewStatusChangeLogOneArgs = {
  object: VisitReviewStatusChangeLogInsertInput;
  onConflict?: InputMaybe<VisitReviewStatusChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitStatusChangeLogArgs = {
  objects: Array<VisitStatusChangeLogInsertInput>;
  onConflict?: InputMaybe<VisitStatusChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitStatusChangeLogOneArgs = {
  object: VisitStatusChangeLogInsertInput;
  onConflict?: InputMaybe<VisitStatusChangeLogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitsAdversereactionchangelogArgs = {
  objects: Array<VisitsAdversereactionchangelogInsertInput>;
  onConflict?: InputMaybe<VisitsAdversereactionchangelogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitsAdversereactionchangelogOneArgs = {
  object: VisitsAdversereactionchangelogInsertInput;
  onConflict?: InputMaybe<VisitsAdversereactionchangelogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitsChartsignatureArgs = {
  objects: Array<VisitsChartsignatureInsertInput>;
  onConflict?: InputMaybe<VisitsChartsignatureOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitsChartsignatureOneArgs = {
  object: VisitsChartsignatureInsertInput;
  onConflict?: InputMaybe<VisitsChartsignatureOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitsTelehealthvisitdetailschangelogArgs = {
  objects: Array<VisitsTelehealthvisitdetailschangelogInsertInput>;
  onConflict?: InputMaybe<VisitsTelehealthvisitdetailschangelogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVisitsTelehealthvisitdetailschangelogOneArgs = {
  object: VisitsTelehealthvisitdetailschangelogInsertInput;
  onConflict?: InputMaybe<VisitsTelehealthvisitdetailschangelogOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletCreditLineArgs = {
  objects: Array<WalletCreditLineInsertInput>;
  onConflict?: InputMaybe<WalletCreditLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletCreditLineOneArgs = {
  object: WalletCreditLineInsertInput;
  onConflict?: InputMaybe<WalletCreditLineOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletCreditTypeArgs = {
  objects: Array<WalletCreditTypeInsertInput>;
  onConflict?: InputMaybe<WalletCreditTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletCreditTypeOneArgs = {
  object: WalletCreditTypeInsertInput;
  onConflict?: InputMaybe<WalletCreditTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletTransactionArgs = {
  objects: Array<WalletTransactionInsertInput>;
  onConflict?: InputMaybe<WalletTransactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletTransactionOneArgs = {
  object: WalletTransactionInsertInput;
  onConflict?: InputMaybe<WalletTransactionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletTransactionSourceTmpArgs = {
  objects: Array<WalletTransactionSourceTmpInsertInput>;
  onConflict?: InputMaybe<WalletTransactionSourceTmpOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletTransactionSourceTmpOneArgs = {
  object: WalletTransactionSourceTmpInsertInput;
  onConflict?: InputMaybe<WalletTransactionSourceTmpOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletTransactionsSourcesArgs = {
  objects: Array<WalletTransactionsSourcesInsertInput>;
  onConflict?: InputMaybe<WalletTransactionsSourcesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWalletTransactionsSourcesOneArgs = {
  object: WalletTransactionsSourcesInsertInput;
  onConflict?: InputMaybe<WalletTransactionsSourcesOnConflict>;
};

/** mutation root */
export type Mutation_RootJoinTwilioConversationArgs = {
  conversationSid: Scalars["String"]["input"];
  medspaId: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootLeaveTwilioConversationArgs = {
  conversationSid: Scalars["String"]["input"];
  medspaId: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootMakeRefundArgs = {
  amount?: InputMaybe<Scalars["Decimal"]["input"]>;
  explanation?: InputMaybe<Scalars["String"]["input"]>;
  method?: InputMaybe<Scalars["String"]["input"]>;
  paymentId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootMarkInvoiceAsPaidArgs = {
  amountPaid?: InputMaybe<Scalars["Decimal"]["input"]>;
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootPayAffirmArgs = {
  amountPaid?: InputMaybe<Scalars["Decimal"]["input"]>;
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootPayCardArgs = {
  amountPaid?: InputMaybe<Scalars["Decimal"]["input"]>;
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
  shouldSaveCard?: InputMaybe<Scalars["Boolean"]["input"]>;
  stripeCardId?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootPayExternalMethodArgs = {
  amountPaid?: InputMaybe<Scalars["Decimal"]["input"]>;
  externalPaymentMethod: Scalars["String"]["input"];
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootPayTerminalArgs = {
  amountPaid?: InputMaybe<Scalars["Decimal"]["input"]>;
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
  shouldSaveCard?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** mutation root */
export type Mutation_RootPayWalletArgs = {
  amountPaid?: InputMaybe<Scalars["Decimal"]["input"]>;
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
  walletCreditTypeId: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootRefreshClientAccessTokenArgs = {
  clientAccessToken: Scalars["String"]["input"];
  medspaSlug: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootRefreshServiceMenuItemLineGfeStatusArgs = {
  serviceMenuItemLineId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootRegisterReaderForMerchantArgs = {
  pairingCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootRemoveAdverseReactionArgs = {
  adverseReactionId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootRemoveAvailabilityEventArgs = {
  eventId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootRemoveDiagramTemplateArgs = {
  diagramTemplateId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootRemoveRecurringAvailabilityArgs = {
  recurringAvailabilityId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootRemoveStripeTerminalArgs = {
  id: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootRequestConsentFormsArgs = {
  clientId: Scalars["BigInt"]["input"];
  consentFormIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
};

/** mutation root */
export type Mutation_RootRequestGfePartialReReviewArgs = {
  clientId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootRequestGfeReviewArgs = {
  clientId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootRequestSyncGfeInviteArgs = {
  clientBirthdate?: InputMaybe<Scalars["Date"]["input"]>;
  clientId: Scalars["BigInt"]["input"];
  clientPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  device: Scalars["String"]["input"];
  linkSentToEmail?: InputMaybe<Scalars["String"]["input"]>;
  linkSentToPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  serviceOfferingIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
};

/** mutation root */
export type Mutation_RootRescheduleAppointmentArgs = {
  appointmentId?: InputMaybe<Scalars["BigInt"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  providerId?: InputMaybe<Scalars["BigInt"]["input"]>;
  sendCommunication?: InputMaybe<Scalars["Boolean"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/** mutation root */
export type Mutation_RootRescheduleAppointmentByClientArgs = {
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootRescheduleAvailabilityEventArgs = {
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  eventId: Scalars["BigInt"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["BigInt"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/** mutation root */
export type Mutation_RootRescheduleMdMeetingArgs = {
  attendeesIds: Array<InputMaybe<Scalars["BigInt"]["input"]>>;
  bookingFlow: Scalars["String"]["input"];
  details?: InputMaybe<Scalars["String"]["input"]>;
  endTime: Scalars["DateTime"]["input"];
  format?: InputMaybe<Scalars["String"]["input"]>;
  mdMeetingId: Scalars["BigInt"]["input"];
  sendCommunication?: InputMaybe<Scalars["Boolean"]["input"]>;
  startTime: Scalars["DateTime"]["input"];
};

/** mutation root */
export type Mutation_RootSendClientLoginLinkArgs = {
  email: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootSendClientPortalLinkArgs = {
  clientId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootSendFormsArgs = {
  clientId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootSendFormsToGfeReviewArgs = {
  formSubmissions?: InputMaybe<Array<InputMaybe<Scalars["BigInt"]["input"]>>>;
};

/** mutation root */
export type Mutation_RootSendGfeReviewRequestMailToMdArgs = {
  clientId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootSendNoShowFeeCommunicationArgs = {
  visitId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootSendReceiptArgs = {
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** mutation root */
export type Mutation_RootSendVisitToReviewArgs = {
  visitId: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootSignChartArgs = {
  newReviewStatus?: InputMaybe<VisitReviewStatusEnum>;
  signature: Scalars["String"]["input"];
  signatureAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootSplitChartingNoteArgs = {
  chartingNoteId: Scalars["BigInt"]["input"];
  productDetails: Array<InputMaybe<ProductDetailsInput>>;
};

/** mutation root */
export type Mutation_RootSubmitGfeReviewArgs = {
  gfeReviewDiagnoses: Array<InputMaybe<GfeReviewDiagnosisInput>>;
  gfeReviewRequestId: Scalars["BigInt"]["input"];
  gfeReviewServiceProductIndications: Array<
    InputMaybe<GfeServiceProductIndicationInput>
  >;
  gfeReviewTreatmentIndications: Array<InputMaybe<GfeTreatmentIndicationInput>>;
  signature?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootSubmitSyncGfeReviewArgs = {
  date: Scalars["DateTime"]["input"];
  gfeReviewDiagnoses?: InputMaybe<Array<InputMaybe<GfeReviewDiagnosisInput>>>;
  gfeReviewRequestId: Scalars["BigInt"]["input"];
  gfeReviewServiceProductIndications: Array<
    InputMaybe<GfeServiceProductIndicationInput>
  >;
  gfeReviewTreatmentIndications: Array<InputMaybe<GfeTreatmentIndicationInput>>;
  gfeSyncDocumentId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootUpdateAddressArgs = {
  _inc?: InputMaybe<AddressIncInput>;
  _set?: InputMaybe<AddressSetInput>;
  where: AddressBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAddressByPkArgs = {
  _inc?: InputMaybe<AddressIncInput>;
  _set?: InputMaybe<AddressSetInput>;
  pkColumns: AddressPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAddressManyArgs = {
  updates: Array<AddressUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAdministrativeDivisionArgs = {
  _inc?: InputMaybe<AdministrativeDivisionIncInput>;
  _set?: InputMaybe<AdministrativeDivisionSetInput>;
  where: AdministrativeDivisionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAdministrativeDivisionByPkArgs = {
  _inc?: InputMaybe<AdministrativeDivisionIncInput>;
  _set?: InputMaybe<AdministrativeDivisionSetInput>;
  pkColumns: AdministrativeDivisionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAdministrativeDivisionManyArgs = {
  updates: Array<AdministrativeDivisionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAdverseReactionArgs = {
  _append?: InputMaybe<AdverseReactionAppendInput>;
  _deleteAtPath?: InputMaybe<AdverseReactionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AdverseReactionDeleteElemInput>;
  _deleteKey?: InputMaybe<AdverseReactionDeleteKeyInput>;
  _inc?: InputMaybe<AdverseReactionIncInput>;
  _prepend?: InputMaybe<AdverseReactionPrependInput>;
  _set?: InputMaybe<AdverseReactionSetInput>;
  where: AdverseReactionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAdverseReactionByPkArgs = {
  _append?: InputMaybe<AdverseReactionAppendInput>;
  _deleteAtPath?: InputMaybe<AdverseReactionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AdverseReactionDeleteElemInput>;
  _deleteKey?: InputMaybe<AdverseReactionDeleteKeyInput>;
  _inc?: InputMaybe<AdverseReactionIncInput>;
  _prepend?: InputMaybe<AdverseReactionPrependInput>;
  _set?: InputMaybe<AdverseReactionSetInput>;
  pkColumns: AdverseReactionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAdverseReactionManyArgs = {
  updates: Array<AdverseReactionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAdverseReactionTemplateArgs = {
  _append?: InputMaybe<AdverseReactionTemplateAppendInput>;
  _deleteAtPath?: InputMaybe<AdverseReactionTemplateDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AdverseReactionTemplateDeleteElemInput>;
  _deleteKey?: InputMaybe<AdverseReactionTemplateDeleteKeyInput>;
  _inc?: InputMaybe<AdverseReactionTemplateIncInput>;
  _prepend?: InputMaybe<AdverseReactionTemplatePrependInput>;
  _set?: InputMaybe<AdverseReactionTemplateSetInput>;
  where: AdverseReactionTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAdverseReactionTemplateByPkArgs = {
  _append?: InputMaybe<AdverseReactionTemplateAppendInput>;
  _deleteAtPath?: InputMaybe<AdverseReactionTemplateDeleteAtPathInput>;
  _deleteElem?: InputMaybe<AdverseReactionTemplateDeleteElemInput>;
  _deleteKey?: InputMaybe<AdverseReactionTemplateDeleteKeyInput>;
  _inc?: InputMaybe<AdverseReactionTemplateIncInput>;
  _prepend?: InputMaybe<AdverseReactionTemplatePrependInput>;
  _set?: InputMaybe<AdverseReactionTemplateSetInput>;
  pkColumns: AdverseReactionTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAdverseReactionTemplateManyArgs = {
  updates: Array<AdverseReactionTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAvailabilityEventArgs = {
  _inc?: InputMaybe<AvailabilityEventIncInput>;
  _set?: InputMaybe<AvailabilityEventSetInput>;
  where: AvailabilityEventBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAvailabilityEventByPkArgs = {
  _inc?: InputMaybe<AvailabilityEventIncInput>;
  _set?: InputMaybe<AvailabilityEventSetInput>;
  pkColumns: AvailabilityEventPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAvailabilityEventManyArgs = {
  updates: Array<AvailabilityEventUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCareFormArgs = {
  _inc?: InputMaybe<CareFormIncInput>;
  _set?: InputMaybe<CareFormSetInput>;
  where: CareFormBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCareFormByPkArgs = {
  _inc?: InputMaybe<CareFormIncInput>;
  _set?: InputMaybe<CareFormSetInput>;
  pkColumns: CareFormPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCareFormManyArgs = {
  updates: Array<CareFormUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCareFormServiceMenuItemsArgs = {
  _inc?: InputMaybe<CareFormServiceMenuItemsIncInput>;
  _set?: InputMaybe<CareFormServiceMenuItemsSetInput>;
  where: CareFormServiceMenuItemsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCareFormServiceMenuItemsByPkArgs = {
  _inc?: InputMaybe<CareFormServiceMenuItemsIncInput>;
  _set?: InputMaybe<CareFormServiceMenuItemsSetInput>;
  pkColumns: CareFormServiceMenuItemsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCareFormServiceMenuItemsManyArgs = {
  updates: Array<CareFormServiceMenuItemsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCherryApiKeyArgs = {
  cherryApiKey: Scalars["String"]["input"];
  medspaId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootUpdateClientArgs = {
  _append?: InputMaybe<ClientAppendInput>;
  _deleteAtPath?: InputMaybe<ClientDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClientDeleteElemInput>;
  _deleteKey?: InputMaybe<ClientDeleteKeyInput>;
  _inc?: InputMaybe<ClientIncInput>;
  _prepend?: InputMaybe<ClientPrependInput>;
  _set?: InputMaybe<ClientSetInput>;
  where: ClientBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClientAccessTokenArgs = {
  _inc?: InputMaybe<ClientAccessTokenIncInput>;
  _set?: InputMaybe<ClientAccessTokenSetInput>;
  where: ClientAccessTokenBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClientAccessTokenByPkArgs = {
  _inc?: InputMaybe<ClientAccessTokenIncInput>;
  _set?: InputMaybe<ClientAccessTokenSetInput>;
  pkColumns: ClientAccessTokenPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClientAccessTokenManyArgs = {
  updates: Array<ClientAccessTokenUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClientByPkArgs = {
  _append?: InputMaybe<ClientAppendInput>;
  _deleteAtPath?: InputMaybe<ClientDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClientDeleteElemInput>;
  _deleteKey?: InputMaybe<ClientDeleteKeyInput>;
  _inc?: InputMaybe<ClientIncInput>;
  _prepend?: InputMaybe<ClientPrependInput>;
  _set?: InputMaybe<ClientSetInput>;
  pkColumns: ClientPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClientManyArgs = {
  updates: Array<ClientUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClientMembershipArgs = {
  _inc?: InputMaybe<ClientMembershipIncInput>;
  _set?: InputMaybe<ClientMembershipSetInput>;
  where: ClientMembershipBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClientMembershipByPkArgs = {
  _inc?: InputMaybe<ClientMembershipIncInput>;
  _set?: InputMaybe<ClientMembershipSetInput>;
  pkColumns: ClientMembershipPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClientMembershipManyArgs = {
  updates: Array<ClientMembershipUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskArgs = {
  _inc?: InputMaybe<ComplianceTaskIncInput>;
  _set?: InputMaybe<ComplianceTaskSetInput>;
  where: ComplianceTaskBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskByPkArgs = {
  _inc?: InputMaybe<ComplianceTaskIncInput>;
  _set?: InputMaybe<ComplianceTaskSetInput>;
  pkColumns: ComplianceTaskPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskDocumentArgs = {
  _inc?: InputMaybe<ComplianceTaskDocumentIncInput>;
  _set?: InputMaybe<ComplianceTaskDocumentSetInput>;
  where: ComplianceTaskDocumentBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskDocumentByPkArgs = {
  _inc?: InputMaybe<ComplianceTaskDocumentIncInput>;
  _set?: InputMaybe<ComplianceTaskDocumentSetInput>;
  pkColumns: ComplianceTaskDocumentPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskDocumentManyArgs = {
  updates: Array<ComplianceTaskDocumentUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskManyArgs = {
  updates: Array<ComplianceTaskUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskTemplateArgs = {
  _inc?: InputMaybe<ComplianceTaskTemplateIncInput>;
  _set?: InputMaybe<ComplianceTaskTemplateSetInput>;
  where: ComplianceTaskTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskTemplateByPkArgs = {
  _inc?: InputMaybe<ComplianceTaskTemplateIncInput>;
  _set?: InputMaybe<ComplianceTaskTemplateSetInput>;
  pkColumns: ComplianceTaskTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateComplianceTaskTemplateManyArgs = {
  updates: Array<ComplianceTaskTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCompliantNoteArgs = {
  _append?: InputMaybe<CompliantNoteAppendInput>;
  _deleteAtPath?: InputMaybe<CompliantNoteDeleteAtPathInput>;
  _deleteElem?: InputMaybe<CompliantNoteDeleteElemInput>;
  _deleteKey?: InputMaybe<CompliantNoteDeleteKeyInput>;
  _inc?: InputMaybe<CompliantNoteIncInput>;
  _prepend?: InputMaybe<CompliantNotePrependInput>;
  _set?: InputMaybe<CompliantNoteSetInput>;
  where: CompliantNoteBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCompliantNoteByPkArgs = {
  _append?: InputMaybe<CompliantNoteAppendInput>;
  _deleteAtPath?: InputMaybe<CompliantNoteDeleteAtPathInput>;
  _deleteElem?: InputMaybe<CompliantNoteDeleteElemInput>;
  _deleteKey?: InputMaybe<CompliantNoteDeleteKeyInput>;
  _inc?: InputMaybe<CompliantNoteIncInput>;
  _prepend?: InputMaybe<CompliantNotePrependInput>;
  _set?: InputMaybe<CompliantNoteSetInput>;
  pkColumns: CompliantNotePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCompliantNoteManyArgs = {
  updates: Array<CompliantNoteUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCompliantNoteTemplateArgs = {
  _append?: InputMaybe<CompliantNoteTemplateAppendInput>;
  _deleteAtPath?: InputMaybe<CompliantNoteTemplateDeleteAtPathInput>;
  _deleteElem?: InputMaybe<CompliantNoteTemplateDeleteElemInput>;
  _deleteKey?: InputMaybe<CompliantNoteTemplateDeleteKeyInput>;
  _inc?: InputMaybe<CompliantNoteTemplateIncInput>;
  _prepend?: InputMaybe<CompliantNoteTemplatePrependInput>;
  _set?: InputMaybe<CompliantNoteTemplateSetInput>;
  where: CompliantNoteTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCompliantNoteTemplateByPkArgs = {
  _append?: InputMaybe<CompliantNoteTemplateAppendInput>;
  _deleteAtPath?: InputMaybe<CompliantNoteTemplateDeleteAtPathInput>;
  _deleteElem?: InputMaybe<CompliantNoteTemplateDeleteElemInput>;
  _deleteKey?: InputMaybe<CompliantNoteTemplateDeleteKeyInput>;
  _inc?: InputMaybe<CompliantNoteTemplateIncInput>;
  _prepend?: InputMaybe<CompliantNoteTemplatePrependInput>;
  _set?: InputMaybe<CompliantNoteTemplateSetInput>;
  pkColumns: CompliantNoteTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCompliantNoteTemplateManyArgs = {
  updates: Array<CompliantNoteTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormArgs = {
  _inc?: InputMaybe<ConsentFormIncInput>;
  _set?: InputMaybe<ConsentFormSetInput>;
  where: ConsentFormBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormByPkArgs = {
  _inc?: InputMaybe<ConsentFormIncInput>;
  _set?: InputMaybe<ConsentFormSetInput>;
  pkColumns: ConsentFormPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormManyArgs = {
  updates: Array<ConsentFormUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormServiceMenuItemsArgs = {
  _inc?: InputMaybe<ConsentFormServiceMenuItemsIncInput>;
  _set?: InputMaybe<ConsentFormServiceMenuItemsSetInput>;
  where: ConsentFormServiceMenuItemsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormServiceMenuItemsByPkArgs = {
  _inc?: InputMaybe<ConsentFormServiceMenuItemsIncInput>;
  _set?: InputMaybe<ConsentFormServiceMenuItemsSetInput>;
  pkColumns: ConsentFormServiceMenuItemsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormServiceMenuItemsManyArgs = {
  updates: Array<ConsentFormServiceMenuItemsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormSignatureArgs = {
  _inc?: InputMaybe<ConsentFormSignatureIncInput>;
  _set?: InputMaybe<ConsentFormSignatureSetInput>;
  where: ConsentFormSignatureBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormSignatureByPkArgs = {
  _inc?: InputMaybe<ConsentFormSignatureIncInput>;
  _set?: InputMaybe<ConsentFormSignatureSetInput>;
  pkColumns: ConsentFormSignaturePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateConsentFormSignatureManyArgs = {
  updates: Array<ConsentFormSignatureUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDefaultCardForClientArgs = {
  cardId: Scalars["String"]["input"];
  clientId: Scalars["BigInt"]["input"];
};

/** mutation root */
export type Mutation_RootUpdateDepositLineArgs = {
  _inc?: InputMaybe<DepositLineIncInput>;
  _set?: InputMaybe<DepositLineSetInput>;
  where: DepositLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDepositLineByPkArgs = {
  _inc?: InputMaybe<DepositLineIncInput>;
  _set?: InputMaybe<DepositLineSetInput>;
  pkColumns: DepositLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDepositLineManyArgs = {
  updates: Array<DepositLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDiagramArgs = {
  _append?: InputMaybe<DiagramAppendInput>;
  _deleteAtPath?: InputMaybe<DiagramDeleteAtPathInput>;
  _deleteElem?: InputMaybe<DiagramDeleteElemInput>;
  _deleteKey?: InputMaybe<DiagramDeleteKeyInput>;
  _inc?: InputMaybe<DiagramIncInput>;
  _prepend?: InputMaybe<DiagramPrependInput>;
  _set?: InputMaybe<DiagramSetInput>;
  where: DiagramBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDiagramByPkArgs = {
  _append?: InputMaybe<DiagramAppendInput>;
  _deleteAtPath?: InputMaybe<DiagramDeleteAtPathInput>;
  _deleteElem?: InputMaybe<DiagramDeleteElemInput>;
  _deleteKey?: InputMaybe<DiagramDeleteKeyInput>;
  _inc?: InputMaybe<DiagramIncInput>;
  _prepend?: InputMaybe<DiagramPrependInput>;
  _set?: InputMaybe<DiagramSetInput>;
  pkColumns: DiagramPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDiagramManyArgs = {
  updates: Array<DiagramUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDiagramTemplateArgs = {
  _inc?: InputMaybe<DiagramTemplateIncInput>;
  _set?: InputMaybe<DiagramTemplateSetInput>;
  where: DiagramTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDiagramTemplateByPkArgs = {
  _inc?: InputMaybe<DiagramTemplateIncInput>;
  _set?: InputMaybe<DiagramTemplateSetInput>;
  pkColumns: DiagramTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDiagramTemplateManyArgs = {
  updates: Array<DiagramTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDiscountArgs = {
  _inc?: InputMaybe<DiscountIncInput>;
  _set?: InputMaybe<DiscountSetInput>;
  where: DiscountBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDiscountByPkArgs = {
  _inc?: InputMaybe<DiscountIncInput>;
  _set?: InputMaybe<DiscountSetInput>;
  pkColumns: DiscountPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDiscountLineArgs = {
  _inc?: InputMaybe<DiscountLineIncInput>;
  _set?: InputMaybe<DiscountLineSetInput>;
  where: DiscountLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDiscountLineByPkArgs = {
  _inc?: InputMaybe<DiscountLineIncInput>;
  _set?: InputMaybe<DiscountLineSetInput>;
  pkColumns: DiscountLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDiscountLineManyArgs = {
  updates: Array<DiscountLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDiscountManyArgs = {
  updates: Array<DiscountUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDiscountRetailProductsArgs = {
  _inc?: InputMaybe<DiscountRetailProductsIncInput>;
  _set?: InputMaybe<DiscountRetailProductsSetInput>;
  where: DiscountRetailProductsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDiscountRetailProductsByPkArgs = {
  _inc?: InputMaybe<DiscountRetailProductsIncInput>;
  _set?: InputMaybe<DiscountRetailProductsSetInput>;
  pkColumns: DiscountRetailProductsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDiscountRetailProductsManyArgs = {
  updates: Array<DiscountRetailProductsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDiscountServiceMenuItemsArgs = {
  _inc?: InputMaybe<DiscountServiceMenuItemsIncInput>;
  _set?: InputMaybe<DiscountServiceMenuItemsSetInput>;
  where: DiscountServiceMenuItemsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDiscountServiceMenuItemsByPkArgs = {
  _inc?: InputMaybe<DiscountServiceMenuItemsIncInput>;
  _set?: InputMaybe<DiscountServiceMenuItemsSetInput>;
  pkColumns: DiscountServiceMenuItemsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDiscountServiceMenuItemsManyArgs = {
  updates: Array<DiscountServiceMenuItemsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateDiscountServiceProductsArgs = {
  _inc?: InputMaybe<DiscountServiceProductsIncInput>;
  _set?: InputMaybe<DiscountServiceProductsSetInput>;
  where: DiscountServiceProductsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateDiscountServiceProductsByPkArgs = {
  _inc?: InputMaybe<DiscountServiceProductsIncInput>;
  _set?: InputMaybe<DiscountServiceProductsSetInput>;
  pkColumns: DiscountServiceProductsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateDiscountServiceProductsManyArgs = {
  updates: Array<DiscountServiceProductsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFeaturePermissionArgs = {
  _inc?: InputMaybe<FeaturePermissionIncInput>;
  _set?: InputMaybe<FeaturePermissionSetInput>;
  where: FeaturePermissionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFeaturePermissionByPkArgs = {
  _inc?: InputMaybe<FeaturePermissionIncInput>;
  _set?: InputMaybe<FeaturePermissionSetInput>;
  pkColumns: FeaturePermissionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFeaturePermissionManyArgs = {
  updates: Array<FeaturePermissionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFeeArgs = {
  _inc?: InputMaybe<FeeIncInput>;
  _set?: InputMaybe<FeeSetInput>;
  where: FeeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFeeByPkArgs = {
  _inc?: InputMaybe<FeeIncInput>;
  _set?: InputMaybe<FeeSetInput>;
  pkColumns: FeePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFeeLineArgs = {
  _inc?: InputMaybe<FeeLineIncInput>;
  _set?: InputMaybe<FeeLineSetInput>;
  where: FeeLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFeeLineByPkArgs = {
  _inc?: InputMaybe<FeeLineIncInput>;
  _set?: InputMaybe<FeeLineSetInput>;
  pkColumns: FeeLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFeeLineManyArgs = {
  updates: Array<FeeLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFeeManyArgs = {
  updates: Array<FeeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFormArgs = {
  _append?: InputMaybe<FormAppendInput>;
  _deleteAtPath?: InputMaybe<FormDeleteAtPathInput>;
  _deleteElem?: InputMaybe<FormDeleteElemInput>;
  _deleteKey?: InputMaybe<FormDeleteKeyInput>;
  _inc?: InputMaybe<FormIncInput>;
  _prepend?: InputMaybe<FormPrependInput>;
  _set?: InputMaybe<FormSetInput>;
  where: FormBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFormByPkArgs = {
  _append?: InputMaybe<FormAppendInput>;
  _deleteAtPath?: InputMaybe<FormDeleteAtPathInput>;
  _deleteElem?: InputMaybe<FormDeleteElemInput>;
  _deleteKey?: InputMaybe<FormDeleteKeyInput>;
  _inc?: InputMaybe<FormIncInput>;
  _prepend?: InputMaybe<FormPrependInput>;
  _set?: InputMaybe<FormSetInput>;
  pkColumns: FormPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFormManyArgs = {
  updates: Array<FormUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFormServiceMenuItemArgs = {
  _inc?: InputMaybe<FormServiceMenuItemIncInput>;
  _set?: InputMaybe<FormServiceMenuItemSetInput>;
  where: FormServiceMenuItemBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFormServiceMenuItemByPkArgs = {
  _inc?: InputMaybe<FormServiceMenuItemIncInput>;
  _set?: InputMaybe<FormServiceMenuItemSetInput>;
  pkColumns: FormServiceMenuItemPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFormServiceMenuItemManyArgs = {
  updates: Array<FormServiceMenuItemUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFormSubmissionArgs = {
  _append?: InputMaybe<FormSubmissionAppendInput>;
  _deleteAtPath?: InputMaybe<FormSubmissionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<FormSubmissionDeleteElemInput>;
  _deleteKey?: InputMaybe<FormSubmissionDeleteKeyInput>;
  _inc?: InputMaybe<FormSubmissionIncInput>;
  _prepend?: InputMaybe<FormSubmissionPrependInput>;
  _set?: InputMaybe<FormSubmissionSetInput>;
  where: FormSubmissionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFormSubmissionByPkArgs = {
  _append?: InputMaybe<FormSubmissionAppendInput>;
  _deleteAtPath?: InputMaybe<FormSubmissionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<FormSubmissionDeleteElemInput>;
  _deleteKey?: InputMaybe<FormSubmissionDeleteKeyInput>;
  _inc?: InputMaybe<FormSubmissionIncInput>;
  _prepend?: InputMaybe<FormSubmissionPrependInput>;
  _set?: InputMaybe<FormSubmissionSetInput>;
  pkColumns: FormSubmissionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFormSubmissionGfeStatusChangeLogArgs = {
  _inc?: InputMaybe<FormSubmissionGfeStatusChangeLogIncInput>;
  _set?: InputMaybe<FormSubmissionGfeStatusChangeLogSetInput>;
  where: FormSubmissionGfeStatusChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFormSubmissionGfeStatusChangeLogByPkArgs = {
  _inc?: InputMaybe<FormSubmissionGfeStatusChangeLogIncInput>;
  _set?: InputMaybe<FormSubmissionGfeStatusChangeLogSetInput>;
  pkColumns: FormSubmissionGfeStatusChangeLogPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFormSubmissionGfeStatusChangeLogManyArgs = {
  updates: Array<FormSubmissionGfeStatusChangeLogUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFormSubmissionManyArgs = {
  updates: Array<FormSubmissionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFormsFormMedspaServiceOfferingsArgs = {
  _inc?: InputMaybe<FormsFormMedspaServiceOfferingsIncInput>;
  _set?: InputMaybe<FormsFormMedspaServiceOfferingsSetInput>;
  where: FormsFormMedspaServiceOfferingsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFormsFormMedspaServiceOfferingsByPkArgs = {
  _inc?: InputMaybe<FormsFormMedspaServiceOfferingsIncInput>;
  _set?: InputMaybe<FormsFormMedspaServiceOfferingsSetInput>;
  pkColumns: FormsFormMedspaServiceOfferingsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFormsFormMedspaServiceOfferingsManyArgs = {
  updates: Array<FormsFormMedspaServiceOfferingsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFormsFormsubmissionversionArgs = {
  _append?: InputMaybe<FormsFormsubmissionversionAppendInput>;
  _deleteAtPath?: InputMaybe<FormsFormsubmissionversionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<FormsFormsubmissionversionDeleteElemInput>;
  _deleteKey?: InputMaybe<FormsFormsubmissionversionDeleteKeyInput>;
  _inc?: InputMaybe<FormsFormsubmissionversionIncInput>;
  _prepend?: InputMaybe<FormsFormsubmissionversionPrependInput>;
  _set?: InputMaybe<FormsFormsubmissionversionSetInput>;
  where: FormsFormsubmissionversionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFormsFormsubmissionversionByPkArgs = {
  _append?: InputMaybe<FormsFormsubmissionversionAppendInput>;
  _deleteAtPath?: InputMaybe<FormsFormsubmissionversionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<FormsFormsubmissionversionDeleteElemInput>;
  _deleteKey?: InputMaybe<FormsFormsubmissionversionDeleteKeyInput>;
  _inc?: InputMaybe<FormsFormsubmissionversionIncInput>;
  _prepend?: InputMaybe<FormsFormsubmissionversionPrependInput>;
  _set?: InputMaybe<FormsFormsubmissionversionSetInput>;
  pkColumns: FormsFormsubmissionversionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFormsFormsubmissionversionManyArgs = {
  updates: Array<FormsFormsubmissionversionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGfeDiagnosisArgs = {
  _inc?: InputMaybe<GfeDiagnosisIncInput>;
  _set?: InputMaybe<GfeDiagnosisSetInput>;
  where: GfeDiagnosisBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGfeDiagnosisByPkArgs = {
  _inc?: InputMaybe<GfeDiagnosisIncInput>;
  _set?: InputMaybe<GfeDiagnosisSetInput>;
  pkColumns: GfeDiagnosisPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGfeDiagnosisManyArgs = {
  updates: Array<GfeDiagnosisUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewDiagnosisArgs = {
  _inc?: InputMaybe<GfeReviewDiagnosisIncInput>;
  _set?: InputMaybe<GfeReviewDiagnosisSetInput>;
  where: GfeReviewDiagnosisBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewDiagnosisByPkArgs = {
  _inc?: InputMaybe<GfeReviewDiagnosisIncInput>;
  _set?: InputMaybe<GfeReviewDiagnosisSetInput>;
  pkColumns: GfeReviewDiagnosisPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewDiagnosisManyArgs = {
  updates: Array<GfeReviewDiagnosisUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewRequestArgs = {
  _inc?: InputMaybe<GfeReviewRequestIncInput>;
  _set?: InputMaybe<GfeReviewRequestSetInput>;
  where: GfeReviewRequestBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewRequestByPkArgs = {
  _inc?: InputMaybe<GfeReviewRequestIncInput>;
  _set?: InputMaybe<GfeReviewRequestSetInput>;
  pkColumns: GfeReviewRequestPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewRequestManyArgs = {
  updates: Array<GfeReviewRequestUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewSubmissionArgs = {
  _inc?: InputMaybe<GfeReviewSubmissionIncInput>;
  _set?: InputMaybe<GfeReviewSubmissionSetInput>;
  where: GfeReviewSubmissionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewSubmissionByPkArgs = {
  _inc?: InputMaybe<GfeReviewSubmissionIncInput>;
  _set?: InputMaybe<GfeReviewSubmissionSetInput>;
  pkColumns: GfeReviewSubmissionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGfeReviewSubmissionManyArgs = {
  updates: Array<GfeReviewSubmissionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGfeServiceProductIndicationArgs = {
  _inc?: InputMaybe<GfeServiceProductIndicationIncInput>;
  _set?: InputMaybe<GfeServiceProductIndicationSetInput>;
  where: GfeServiceProductIndicationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGfeServiceProductIndicationByPkArgs = {
  _inc?: InputMaybe<GfeServiceProductIndicationIncInput>;
  _set?: InputMaybe<GfeServiceProductIndicationSetInput>;
  pkColumns: GfeServiceProductIndicationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGfeServiceProductIndicationManyArgs = {
  updates: Array<GfeServiceProductIndicationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGfeSynchronousReviewDocumentArgs = {
  _inc?: InputMaybe<GfeSynchronousReviewDocumentIncInput>;
  _set?: InputMaybe<GfeSynchronousReviewDocumentSetInput>;
  where: GfeSynchronousReviewDocumentBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGfeSynchronousReviewDocumentByPkArgs = {
  _inc?: InputMaybe<GfeSynchronousReviewDocumentIncInput>;
  _set?: InputMaybe<GfeSynchronousReviewDocumentSetInput>;
  pkColumns: GfeSynchronousReviewDocumentPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGfeSynchronousReviewDocumentManyArgs = {
  updates: Array<GfeSynchronousReviewDocumentUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGfeTreatmentIndicationArgs = {
  _inc?: InputMaybe<GfeTreatmentIndicationIncInput>;
  _set?: InputMaybe<GfeTreatmentIndicationSetInput>;
  where: GfeTreatmentIndicationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGfeTreatmentIndicationByPkArgs = {
  _inc?: InputMaybe<GfeTreatmentIndicationIncInput>;
  _set?: InputMaybe<GfeTreatmentIndicationSetInput>;
  pkColumns: GfeTreatmentIndicationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGfeTreatmentIndicationManyArgs = {
  updates: Array<GfeTreatmentIndicationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateGiftBuilderBannerArgs = {
  _inc?: InputMaybe<GiftBuilderBannerIncInput>;
  _set?: InputMaybe<GiftBuilderBannerSetInput>;
  where: GiftBuilderBannerBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateGiftBuilderBannerByPkArgs = {
  _inc?: InputMaybe<GiftBuilderBannerIncInput>;
  _set?: InputMaybe<GiftBuilderBannerSetInput>;
  pkColumns: GiftBuilderBannerPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateGiftBuilderBannerManyArgs = {
  updates: Array<GiftBuilderBannerUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateHighLevelUnreadMessagesCountArgs = {
  clientId: Scalars["Int"]["input"];
  count: Scalars["Int"]["input"];
  medspaId: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootUpdateInventoryLotArgs = {
  _inc?: InputMaybe<InventoryLotIncInput>;
  _set?: InputMaybe<InventoryLotSetInput>;
  where: InventoryLotBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateInventoryLotByPkArgs = {
  _inc?: InputMaybe<InventoryLotIncInput>;
  _set?: InputMaybe<InventoryLotSetInput>;
  pkColumns: InventoryLotPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateInventoryLotManyArgs = {
  updates: Array<InventoryLotUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateInventoryTransactionArgs = {
  _inc?: InputMaybe<InventoryTransactionIncInput>;
  _set?: InputMaybe<InventoryTransactionSetInput>;
  where: InventoryTransactionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateInventoryTransactionByPkArgs = {
  _inc?: InputMaybe<InventoryTransactionIncInput>;
  _set?: InputMaybe<InventoryTransactionSetInput>;
  pkColumns: InventoryTransactionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateInventoryTransactionManyArgs = {
  updates: Array<InventoryTransactionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateInvoiceArgs = {
  _inc?: InputMaybe<InvoiceIncInput>;
  _set?: InputMaybe<InvoiceSetInput>;
  where: InvoiceBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateInvoiceByPkArgs = {
  _inc?: InputMaybe<InvoiceIncInput>;
  _set?: InputMaybe<InvoiceSetInput>;
  pkColumns: InvoicePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateInvoiceManyArgs = {
  updates: Array<InvoiceUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateInvoiceStatusArgs = {
  invoiceId?: InputMaybe<Scalars["BigInt"]["input"]>;
  invoiceStatus?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootUpdateMarketingTemplateArgs = {
  _inc?: InputMaybe<MarketingTemplateIncInput>;
  _set?: InputMaybe<MarketingTemplateSetInput>;
  where: MarketingTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMarketingTemplateByPkArgs = {
  _inc?: InputMaybe<MarketingTemplateIncInput>;
  _set?: InputMaybe<MarketingTemplateSetInput>;
  pkColumns: MarketingTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMarketingTemplateManyArgs = {
  updates: Array<MarketingTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingArgs = {
  _inc?: InputMaybe<MdMeetingIncInput>;
  _set?: InputMaybe<MdMeetingSetInput>;
  where: MdMeetingBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingAttendeesArgs = {
  _inc?: InputMaybe<MdMeetingAttendeesIncInput>;
  _set?: InputMaybe<MdMeetingAttendeesSetInput>;
  where: MdMeetingAttendeesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingAttendeesByPkArgs = {
  _inc?: InputMaybe<MdMeetingAttendeesIncInput>;
  _set?: InputMaybe<MdMeetingAttendeesSetInput>;
  pkColumns: MdMeetingAttendeesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingAttendeesManyArgs = {
  updates: Array<MdMeetingAttendeesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingByPkArgs = {
  _inc?: InputMaybe<MdMeetingIncInput>;
  _set?: InputMaybe<MdMeetingSetInput>;
  pkColumns: MdMeetingPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingManyArgs = {
  updates: Array<MdMeetingUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingNoteArgs = {
  _inc?: InputMaybe<MdMeetingNoteIncInput>;
  _set?: InputMaybe<MdMeetingNoteSetInput>;
  where: MdMeetingNoteBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingNoteByPkArgs = {
  _inc?: InputMaybe<MdMeetingNoteIncInput>;
  _set?: InputMaybe<MdMeetingNoteSetInput>;
  pkColumns: MdMeetingNotePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingNoteManyArgs = {
  updates: Array<MdMeetingNoteUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingNoteTemplateArgs = {
  _inc?: InputMaybe<MdMeetingNoteTemplateIncInput>;
  _set?: InputMaybe<MdMeetingNoteTemplateSetInput>;
  where: MdMeetingNoteTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingNoteTemplateByPkArgs = {
  _inc?: InputMaybe<MdMeetingNoteTemplateIncInput>;
  _set?: InputMaybe<MdMeetingNoteTemplateSetInput>;
  pkColumns: MdMeetingNoteTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMdMeetingNoteTemplateManyArgs = {
  updates: Array<MdMeetingNoteTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMdStripeOnboardingDoneArgs = {
  mdId: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootUpdateMedicalDirectorArgs = {
  _inc?: InputMaybe<MedicalDirectorIncInput>;
  _set?: InputMaybe<MedicalDirectorSetInput>;
  where: MedicalDirectorBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedicalDirectorByPkArgs = {
  _inc?: InputMaybe<MedicalDirectorIncInput>;
  _set?: InputMaybe<MedicalDirectorSetInput>;
  pkColumns: MedicalDirectorPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedicalDirectorManyArgs = {
  updates: Array<MedicalDirectorUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedicalDirectorSubscriptionArgs = {
  _inc?: InputMaybe<MedicalDirectorSubscriptionIncInput>;
  _set?: InputMaybe<MedicalDirectorSubscriptionSetInput>;
  where: MedicalDirectorSubscriptionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedicalDirectorSubscriptionByPkArgs = {
  _inc?: InputMaybe<MedicalDirectorSubscriptionIncInput>;
  _set?: InputMaybe<MedicalDirectorSubscriptionSetInput>;
  pkColumns: MedicalDirectorSubscriptionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedicalDirectorSubscriptionManyArgs = {
  updates: Array<MedicalDirectorSubscriptionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaArgs = {
  _inc?: InputMaybe<MedspaIncInput>;
  _set?: InputMaybe<MedspaSetInput>;
  where: MedspaBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaByPkArgs = {
  _inc?: InputMaybe<MedspaIncInput>;
  _set?: InputMaybe<MedspaSetInput>;
  pkColumns: MedspaPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaClientCommunicationSettingsArgs = {
  afterAppointmentMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  afterAppointmentSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  afterPaymentReceiptMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  afterPaymentReceiptSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  appointmentConfirmedSmsAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  bookingConfirmationMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  bookingConfirmationSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  confirmationReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  confirmationReminderMailAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  confirmationReminderSmsAdditionalText?: InputMaybe<
    Scalars["String"]["input"]
  >;
  followupNoteDelayHours?: InputMaybe<Scalars["Int"]["input"]>;
  followupNoteMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  followupNoteSmsText?: InputMaybe<Scalars["String"]["input"]>;
  medspaId: Scalars["BigInt"]["input"];
  secondReminderAdvanceHours?: InputMaybe<Scalars["Int"]["input"]>;
  secondReminderMailAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  secondReminderSmsAdditionalText?: InputMaybe<Scalars["String"]["input"]>;
  sendAfterAppointmentNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendAfterPaymentReceipt?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendBookingConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendConfirmationReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendFollowupNote?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendOnRescheduleConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  sendSecondReminder?: InputMaybe<Scalars["Boolean"]["input"]>;
  updateCurrentlyScheduledReminders?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaManyArgs = {
  updates: Array<MedspaUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaRoomsAppointmentsArgs = {
  _inc?: InputMaybe<MedspaRoomsAppointmentsIncInput>;
  _set?: InputMaybe<MedspaRoomsAppointmentsSetInput>;
  where: MedspaRoomsAppointmentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaRoomsAppointmentsByPkArgs = {
  _inc?: InputMaybe<MedspaRoomsAppointmentsIncInput>;
  _set?: InputMaybe<MedspaRoomsAppointmentsSetInput>;
  pkColumns: MedspaRoomsAppointmentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaRoomsAppointmentsManyArgs = {
  updates: Array<MedspaRoomsAppointmentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemArgs = {
  _inc?: InputMaybe<MedspaServiceMenuItemIncInput>;
  _set?: InputMaybe<MedspaServiceMenuItemSetInput>;
  where: MedspaServiceMenuItemBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemByPkArgs = {
  _inc?: InputMaybe<MedspaServiceMenuItemIncInput>;
  _set?: InputMaybe<MedspaServiceMenuItemSetInput>;
  pkColumns: MedspaServiceMenuItemPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemManyArgs = {
  updates: Array<MedspaServiceMenuItemUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemMedspaServiceOfferingsArgs =
  {
    _inc?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsIncInput>;
    _set?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsSetInput>;
    where: MedspaServiceMenuItemMedspaServiceOfferingsBoolExp;
  };

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemMedspaServiceOfferingsByPkArgs =
  {
    _inc?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsIncInput>;
    _set?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsSetInput>;
    pkColumns: MedspaServiceMenuItemMedspaServiceOfferingsPkColumnsInput;
  };

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemMedspaServiceOfferingsManyArgs =
  {
    updates: Array<MedspaServiceMenuItemMedspaServiceOfferingsUpdates>;
  };

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemOldProductsArgs = {
  _inc?: InputMaybe<MedspaServiceMenuItemOldProductsIncInput>;
  _set?: InputMaybe<MedspaServiceMenuItemOldProductsSetInput>;
  where: MedspaServiceMenuItemOldProductsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemOldProductsByPkArgs = {
  _inc?: InputMaybe<MedspaServiceMenuItemOldProductsIncInput>;
  _set?: InputMaybe<MedspaServiceMenuItemOldProductsSetInput>;
  pkColumns: MedspaServiceMenuItemOldProductsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemOldProductsManyArgs = {
  updates: Array<MedspaServiceMenuItemOldProductsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemProvidersArgs = {
  _inc?: InputMaybe<MedspaServiceMenuItemProvidersIncInput>;
  _set?: InputMaybe<MedspaServiceMenuItemProvidersSetInput>;
  where: MedspaServiceMenuItemProvidersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemProvidersByPkArgs = {
  _inc?: InputMaybe<MedspaServiceMenuItemProvidersIncInput>;
  _set?: InputMaybe<MedspaServiceMenuItemProvidersSetInput>;
  pkColumns: MedspaServiceMenuItemProvidersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceMenuItemProvidersManyArgs = {
  updates: Array<MedspaServiceMenuItemProvidersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingArgs = {
  _inc?: InputMaybe<MedspaServiceOfferingIncInput>;
  _set?: InputMaybe<MedspaServiceOfferingSetInput>;
  where: MedspaServiceOfferingBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingByPkArgs = {
  _inc?: InputMaybe<MedspaServiceOfferingIncInput>;
  _set?: InputMaybe<MedspaServiceOfferingSetInput>;
  pkColumns: MedspaServiceOfferingPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingManyArgs = {
  updates: Array<MedspaServiceOfferingUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingNoteTemplatesArgs = {
  _inc?: InputMaybe<MedspaServiceOfferingNoteTemplatesIncInput>;
  _set?: InputMaybe<MedspaServiceOfferingNoteTemplatesSetInput>;
  where: MedspaServiceOfferingNoteTemplatesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingNoteTemplatesByPkArgs = {
  _inc?: InputMaybe<MedspaServiceOfferingNoteTemplatesIncInput>;
  _set?: InputMaybe<MedspaServiceOfferingNoteTemplatesSetInput>;
  pkColumns: MedspaServiceOfferingNoteTemplatesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingNoteTemplatesManyArgs = {
  updates: Array<MedspaServiceOfferingNoteTemplatesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingNotesArgs = {
  _inc?: InputMaybe<MedspaServiceOfferingNotesIncInput>;
  _set?: InputMaybe<MedspaServiceOfferingNotesSetInput>;
  where: MedspaServiceOfferingNotesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingNotesByPkArgs = {
  _inc?: InputMaybe<MedspaServiceOfferingNotesIncInput>;
  _set?: InputMaybe<MedspaServiceOfferingNotesSetInput>;
  pkColumns: MedspaServiceOfferingNotesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceOfferingNotesManyArgs = {
  updates: Array<MedspaServiceOfferingNotesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceProductArgs = {
  _inc?: InputMaybe<MedspaServiceProductIncInput>;
  _set?: InputMaybe<MedspaServiceProductSetInput>;
  where: MedspaServiceProductBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceProductByPkArgs = {
  _inc?: InputMaybe<MedspaServiceProductIncInput>;
  _set?: InputMaybe<MedspaServiceProductSetInput>;
  pkColumns: MedspaServiceProductPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceProductManyArgs = {
  updates: Array<MedspaServiceProductUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceProtocolTemplateArgs = {
  _inc?: InputMaybe<MedspaServiceProtocolTemplateIncInput>;
  _set?: InputMaybe<MedspaServiceProtocolTemplateSetInput>;
  where: MedspaServiceProtocolTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceProtocolTemplateByPkArgs = {
  _inc?: InputMaybe<MedspaServiceProtocolTemplateIncInput>;
  _set?: InputMaybe<MedspaServiceProtocolTemplateSetInput>;
  pkColumns: MedspaServiceProtocolTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaServiceProtocolTemplateManyArgs = {
  updates: Array<MedspaServiceProtocolTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaStandingOrderTemplateArgs = {
  _inc?: InputMaybe<MedspaStandingOrderTemplateIncInput>;
  _set?: InputMaybe<MedspaStandingOrderTemplateSetInput>;
  where: MedspaStandingOrderTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaStandingOrderTemplateByPkArgs = {
  _inc?: InputMaybe<MedspaStandingOrderTemplateIncInput>;
  _set?: InputMaybe<MedspaStandingOrderTemplateSetInput>;
  pkColumns: MedspaStandingOrderTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaStandingOrderTemplateManyArgs = {
  updates: Array<MedspaStandingOrderTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspaWalletCreditTypeArgs = {
  _inc?: InputMaybe<MedspaWalletCreditTypeIncInput>;
  _set?: InputMaybe<MedspaWalletCreditTypeSetInput>;
  where: MedspaWalletCreditTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspaWalletCreditTypeByPkArgs = {
  _inc?: InputMaybe<MedspaWalletCreditTypeIncInput>;
  _set?: InputMaybe<MedspaWalletCreditTypeSetInput>;
  pkColumns: MedspaWalletCreditTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspaWalletCreditTypeManyArgs = {
  updates: Array<MedspaWalletCreditTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspasFeatureflagArgs = {
  _inc?: InputMaybe<MedspasFeatureflagIncInput>;
  _set?: InputMaybe<MedspasFeatureflagSetInput>;
  where: MedspasFeatureflagBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspasFeatureflagByPkArgs = {
  _inc?: InputMaybe<MedspasFeatureflagIncInput>;
  _set?: InputMaybe<MedspasFeatureflagSetInput>;
  pkColumns: MedspasFeatureflagPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspasFeatureflagManyArgs = {
  updates: Array<MedspasFeatureflagUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspasMedicallicenseArgs = {
  _inc?: InputMaybe<MedspasMedicallicenseIncInput>;
  _set?: InputMaybe<MedspasMedicallicenseSetInput>;
  where: MedspasMedicallicenseBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspasMedicallicenseByPkArgs = {
  _inc?: InputMaybe<MedspasMedicallicenseIncInput>;
  _set?: InputMaybe<MedspasMedicallicenseSetInput>;
  pkColumns: MedspasMedicallicensePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspasMedicallicenseManyArgs = {
  updates: Array<MedspasMedicallicenseUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspasMedspaconfigurationArgs = {
  _inc?: InputMaybe<MedspasMedspaconfigurationIncInput>;
  _set?: InputMaybe<MedspasMedspaconfigurationSetInput>;
  where: MedspasMedspaconfigurationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspasMedspaconfigurationByPkArgs = {
  _inc?: InputMaybe<MedspasMedspaconfigurationIncInput>;
  _set?: InputMaybe<MedspasMedspaconfigurationSetInput>;
  pkColumns: MedspasMedspaconfigurationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspasMedspaconfigurationManyArgs = {
  updates: Array<MedspasMedspaconfigurationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspasProfessionalcorporationArgs = {
  _inc?: InputMaybe<MedspasProfessionalcorporationIncInput>;
  _set?: InputMaybe<MedspasProfessionalcorporationSetInput>;
  where: MedspasProfessionalcorporationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspasProfessionalcorporationByPkArgs = {
  _inc?: InputMaybe<MedspasProfessionalcorporationIncInput>;
  _set?: InputMaybe<MedspasProfessionalcorporationSetInput>;
  pkColumns: MedspasProfessionalcorporationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspasProfessionalcorporationManyArgs = {
  updates: Array<MedspasProfessionalcorporationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspasStripeoutboundpaymentmethodArgs = {
  _inc?: InputMaybe<MedspasStripeoutboundpaymentmethodIncInput>;
  _set?: InputMaybe<MedspasStripeoutboundpaymentmethodSetInput>;
  where: MedspasStripeoutboundpaymentmethodBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspasStripeoutboundpaymentmethodByPkArgs = {
  _inc?: InputMaybe<MedspasStripeoutboundpaymentmethodIncInput>;
  _set?: InputMaybe<MedspasStripeoutboundpaymentmethodSetInput>;
  pkColumns: MedspasStripeoutboundpaymentmethodPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMedspasStripeoutboundpaymentmethodManyArgs = {
  updates: Array<MedspasStripeoutboundpaymentmethodUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMedspasUsermedspaAcquiredMedicalLicensesArgs = {
  _inc?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesIncInput>;
  _set?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesSetInput>;
  where: MedspasUsermedspaAcquiredMedicalLicensesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMedspasUsermedspaAcquiredMedicalLicensesByPkArgs =
  {
    _inc?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesIncInput>;
    _set?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesSetInput>;
    pkColumns: MedspasUsermedspaAcquiredMedicalLicensesPkColumnsInput;
  };

/** mutation root */
export type Mutation_RootUpdateMedspasUsermedspaAcquiredMedicalLicensesManyArgs =
  {
    updates: Array<MedspasUsermedspaAcquiredMedicalLicensesUpdates>;
  };

/** mutation root */
export type Mutation_RootUpdateMembershipArgs = {
  _inc?: InputMaybe<MembershipIncInput>;
  _set?: InputMaybe<MembershipSetInput>;
  where: MembershipBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMembershipByPkArgs = {
  _inc?: InputMaybe<MembershipIncInput>;
  _set?: InputMaybe<MembershipSetInput>;
  pkColumns: MembershipPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMembershipCategoryArgs = {
  _inc?: InputMaybe<MembershipCategoryIncInput>;
  _set?: InputMaybe<MembershipCategorySetInput>;
  where: MembershipCategoryBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMembershipCategoryByPkArgs = {
  _inc?: InputMaybe<MembershipCategoryIncInput>;
  _set?: InputMaybe<MembershipCategorySetInput>;
  pkColumns: MembershipCategoryPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMembershipCategoryManyArgs = {
  updates: Array<MembershipCategoryUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMembershipLineArgs = {
  _inc?: InputMaybe<MembershipLineIncInput>;
  _set?: InputMaybe<MembershipLineSetInput>;
  where: MembershipLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMembershipLineByPkArgs = {
  _inc?: InputMaybe<MembershipLineIncInput>;
  _set?: InputMaybe<MembershipLineSetInput>;
  pkColumns: MembershipLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMembershipLineManyArgs = {
  updates: Array<MembershipLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMembershipManyArgs = {
  updates: Array<MembershipUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMembershipPerkArgs = {
  _inc?: InputMaybe<MembershipPerkIncInput>;
  _set?: InputMaybe<MembershipPerkSetInput>;
  where: MembershipPerkBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMembershipPerkByPkArgs = {
  _inc?: InputMaybe<MembershipPerkIncInput>;
  _set?: InputMaybe<MembershipPerkSetInput>;
  pkColumns: MembershipPerkPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMembershipPerkLineArgs = {
  _inc?: InputMaybe<MembershipPerkLineIncInput>;
  _set?: InputMaybe<MembershipPerkLineSetInput>;
  where: MembershipPerkLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMembershipPerkLineByPkArgs = {
  _inc?: InputMaybe<MembershipPerkLineIncInput>;
  _set?: InputMaybe<MembershipPerkLineSetInput>;
  pkColumns: MembershipPerkLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMembershipPerkLineManyArgs = {
  updates: Array<MembershipPerkLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMembershipPerkManyArgs = {
  updates: Array<MembershipPerkUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateModelPermissionArgs = {
  _inc?: InputMaybe<ModelPermissionIncInput>;
  _set?: InputMaybe<ModelPermissionSetInput>;
  where: ModelPermissionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateModelPermissionByPkArgs = {
  _inc?: InputMaybe<ModelPermissionIncInput>;
  _set?: InputMaybe<ModelPermissionSetInput>;
  pkColumns: ModelPermissionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateModelPermissionManyArgs = {
  updates: Array<ModelPermissionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateNoteArgs = {
  _inc?: InputMaybe<NoteIncInput>;
  _set?: InputMaybe<NoteSetInput>;
  where: NoteBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateNoteByPkArgs = {
  _inc?: InputMaybe<NoteIncInput>;
  _set?: InputMaybe<NoteSetInput>;
  pkColumns: NotePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateNoteChangeLogArgs = {
  _inc?: InputMaybe<NoteChangeLogIncInput>;
  _set?: InputMaybe<NoteChangeLogSetInput>;
  where: NoteChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateNoteChangeLogByPkArgs = {
  _inc?: InputMaybe<NoteChangeLogIncInput>;
  _set?: InputMaybe<NoteChangeLogSetInput>;
  pkColumns: NoteChangeLogPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateNoteChangeLogManyArgs = {
  updates: Array<NoteChangeLogUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateNoteFormSubmissionArgs = {
  _inc?: InputMaybe<NoteFormSubmissionIncInput>;
  _set?: InputMaybe<NoteFormSubmissionSetInput>;
  where: NoteFormSubmissionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateNoteFormSubmissionByPkArgs = {
  _inc?: InputMaybe<NoteFormSubmissionIncInput>;
  _set?: InputMaybe<NoteFormSubmissionSetInput>;
  pkColumns: NoteFormSubmissionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateNoteFormSubmissionManyArgs = {
  updates: Array<NoteFormSubmissionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateNoteManyArgs = {
  updates: Array<NoteUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateNoteTemplateArgs = {
  _inc?: InputMaybe<NoteTemplateIncInput>;
  _set?: InputMaybe<NoteTemplateSetInput>;
  where: NoteTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateNoteTemplateByPkArgs = {
  _inc?: InputMaybe<NoteTemplateIncInput>;
  _set?: InputMaybe<NoteTemplateSetInput>;
  pkColumns: NoteTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateNoteTemplateManyArgs = {
  updates: Array<NoteTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePackageArgs = {
  _inc?: InputMaybe<PackageIncInput>;
  _set?: InputMaybe<PackageSetInput>;
  where: PackageBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePackageByPkArgs = {
  _inc?: InputMaybe<PackageIncInput>;
  _set?: InputMaybe<PackageSetInput>;
  pkColumns: PackagePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePackageItemArgs = {
  _inc?: InputMaybe<PackageItemIncInput>;
  _set?: InputMaybe<PackageItemSetInput>;
  where: PackageItemBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePackageItemByPkArgs = {
  _inc?: InputMaybe<PackageItemIncInput>;
  _set?: InputMaybe<PackageItemSetInput>;
  pkColumns: PackageItemPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePackageItemLineArgs = {
  _inc?: InputMaybe<PackageItemLineIncInput>;
  _set?: InputMaybe<PackageItemLineSetInput>;
  where: PackageItemLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePackageItemLineByPkArgs = {
  _inc?: InputMaybe<PackageItemLineIncInput>;
  _set?: InputMaybe<PackageItemLineSetInput>;
  pkColumns: PackageItemLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePackageItemLineManyArgs = {
  updates: Array<PackageItemLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePackageItemManyArgs = {
  updates: Array<PackageItemUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePackageLineArgs = {
  _inc?: InputMaybe<PackageLineIncInput>;
  _set?: InputMaybe<PackageLineSetInput>;
  where: PackageLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePackageLineByPkArgs = {
  _inc?: InputMaybe<PackageLineIncInput>;
  _set?: InputMaybe<PackageLineSetInput>;
  pkColumns: PackageLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePackageLineManyArgs = {
  updates: Array<PackageLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePackageManyArgs = {
  updates: Array<PackageUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePaymentArgs = {
  _inc?: InputMaybe<PaymentIncInput>;
  _set?: InputMaybe<PaymentSetInput>;
  where: PaymentBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePaymentByPkArgs = {
  _inc?: InputMaybe<PaymentIncInput>;
  _set?: InputMaybe<PaymentSetInput>;
  pkColumns: PaymentPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePaymentManyArgs = {
  updates: Array<PaymentUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePhotoArgs = {
  _append?: InputMaybe<PhotoAppendInput>;
  _deleteAtPath?: InputMaybe<PhotoDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PhotoDeleteElemInput>;
  _deleteKey?: InputMaybe<PhotoDeleteKeyInput>;
  _inc?: InputMaybe<PhotoIncInput>;
  _prepend?: InputMaybe<PhotoPrependInput>;
  _set?: InputMaybe<PhotoSetInput>;
  where: PhotoBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePhotoByPkArgs = {
  _append?: InputMaybe<PhotoAppendInput>;
  _deleteAtPath?: InputMaybe<PhotoDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PhotoDeleteElemInput>;
  _deleteKey?: InputMaybe<PhotoDeleteKeyInput>;
  _inc?: InputMaybe<PhotoIncInput>;
  _prepend?: InputMaybe<PhotoPrependInput>;
  _set?: InputMaybe<PhotoSetInput>;
  pkColumns: PhotoPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePhotoManyArgs = {
  updates: Array<PhotoUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProductArgs = {
  _inc?: InputMaybe<ProductIncInput>;
  _set?: InputMaybe<ProductSetInput>;
  where: ProductBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProductByPkArgs = {
  _inc?: InputMaybe<ProductIncInput>;
  _set?: InputMaybe<ProductSetInput>;
  pkColumns: ProductPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProductLineArgs = {
  _inc?: InputMaybe<ProductLineIncInput>;
  _set?: InputMaybe<ProductLineSetInput>;
  where: ProductLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProductLineByPkArgs = {
  _inc?: InputMaybe<ProductLineIncInput>;
  _set?: InputMaybe<ProductLineSetInput>;
  pkColumns: ProductLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProductLineManyArgs = {
  updates: Array<ProductLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProductManyArgs = {
  updates: Array<ProductUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProviderNotRequireDocumentsArgs = {
  _inc?: InputMaybe<ProviderNotRequireDocumentsIncInput>;
  _set?: InputMaybe<ProviderNotRequireDocumentsSetInput>;
  where: ProviderNotRequireDocumentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProviderNotRequireDocumentsByPkArgs = {
  _inc?: InputMaybe<ProviderNotRequireDocumentsIncInput>;
  _set?: InputMaybe<ProviderNotRequireDocumentsSetInput>;
  pkColumns: ProviderNotRequireDocumentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProviderNotRequireDocumentsManyArgs = {
  updates: Array<ProviderNotRequireDocumentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateRecurringAvailabilityArgs = {
  _inc?: InputMaybe<RecurringAvailabilityIncInput>;
  _set?: InputMaybe<RecurringAvailabilitySetInput>;
  where: RecurringAvailabilityBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateRecurringAvailabilityByPkArgs = {
  _inc?: InputMaybe<RecurringAvailabilityIncInput>;
  _set?: InputMaybe<RecurringAvailabilitySetInput>;
  pkColumns: RecurringAvailabilityPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateRecurringAvailabilityManyArgs = {
  updates: Array<RecurringAvailabilityUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateRefundArgs = {
  _inc?: InputMaybe<RefundIncInput>;
  _set?: InputMaybe<RefundSetInput>;
  where: RefundBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateRefundByPkArgs = {
  _inc?: InputMaybe<RefundIncInput>;
  _set?: InputMaybe<RefundSetInput>;
  pkColumns: RefundPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateRefundManyArgs = {
  updates: Array<RefundUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateReportArgs = {
  _append?: InputMaybe<ReportAppendInput>;
  _deleteAtPath?: InputMaybe<ReportDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ReportDeleteElemInput>;
  _deleteKey?: InputMaybe<ReportDeleteKeyInput>;
  _inc?: InputMaybe<ReportIncInput>;
  _prepend?: InputMaybe<ReportPrependInput>;
  _set?: InputMaybe<ReportSetInput>;
  where: ReportBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateReportByPkArgs = {
  _append?: InputMaybe<ReportAppendInput>;
  _deleteAtPath?: InputMaybe<ReportDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ReportDeleteElemInput>;
  _deleteKey?: InputMaybe<ReportDeleteKeyInput>;
  _inc?: InputMaybe<ReportIncInput>;
  _prepend?: InputMaybe<ReportPrependInput>;
  _set?: InputMaybe<ReportSetInput>;
  pkColumns: ReportPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateReportCategoryArgs = {
  _inc?: InputMaybe<ReportCategoryIncInput>;
  _set?: InputMaybe<ReportCategorySetInput>;
  where: ReportCategoryBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateReportCategoryByPkArgs = {
  _inc?: InputMaybe<ReportCategoryIncInput>;
  _set?: InputMaybe<ReportCategorySetInput>;
  pkColumns: ReportCategoryPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateReportCategoryManyArgs = {
  updates: Array<ReportCategoryUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateReportManyArgs = {
  updates: Array<ReportUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateResourcesMedspaRoomArgs = {
  _inc?: InputMaybe<ResourcesMedspaRoomIncInput>;
  _set?: InputMaybe<ResourcesMedspaRoomSetInput>;
  where: ResourcesMedspaRoomBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateResourcesMedspaRoomByPkArgs = {
  _inc?: InputMaybe<ResourcesMedspaRoomIncInput>;
  _set?: InputMaybe<ResourcesMedspaRoomSetInput>;
  pkColumns: ResourcesMedspaRoomPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateResourcesMedspaRoomManyArgs = {
  updates: Array<ResourcesMedspaRoomUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSchedulingAppointmentArgs = {
  _inc?: InputMaybe<SchedulingAppointmentIncInput>;
  _set?: InputMaybe<SchedulingAppointmentSetInput>;
  where: SchedulingAppointmentBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSchedulingAppointmentByPkArgs = {
  _inc?: InputMaybe<SchedulingAppointmentIncInput>;
  _set?: InputMaybe<SchedulingAppointmentSetInput>;
  pkColumns: SchedulingAppointmentPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSchedulingAppointmentManyArgs = {
  updates: Array<SchedulingAppointmentUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateScriptProductArgs = {
  _inc?: InputMaybe<ScriptProductIncInput>;
  _set?: InputMaybe<ScriptProductSetInput>;
  where: ScriptProductBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateScriptProductByPkArgs = {
  _inc?: InputMaybe<ScriptProductIncInput>;
  _set?: InputMaybe<ScriptProductSetInput>;
  pkColumns: ScriptProductPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateScriptProductManyArgs = {
  updates: Array<ScriptProductUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateScriptRequestArgs = {
  _inc?: InputMaybe<ScriptRequestIncInput>;
  _set?: InputMaybe<ScriptRequestSetInput>;
  where: ScriptRequestBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateScriptRequestByPkArgs = {
  _inc?: InputMaybe<ScriptRequestIncInput>;
  _set?: InputMaybe<ScriptRequestSetInput>;
  pkColumns: ScriptRequestPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateScriptRequestManyArgs = {
  updates: Array<ScriptRequestUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateScriptRequestStatusChangeLogArgs = {
  _inc?: InputMaybe<ScriptRequestStatusChangeLogIncInput>;
  _set?: InputMaybe<ScriptRequestStatusChangeLogSetInput>;
  where: ScriptRequestStatusChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateScriptRequestStatusChangeLogByPkArgs = {
  _inc?: InputMaybe<ScriptRequestStatusChangeLogIncInput>;
  _set?: InputMaybe<ScriptRequestStatusChangeLogSetInput>;
  pkColumns: ScriptRequestStatusChangeLogPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateScriptRequestStatusChangeLogManyArgs = {
  updates: Array<ScriptRequestStatusChangeLogUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceCategoryArgs = {
  _inc?: InputMaybe<ServiceCategoryIncInput>;
  _set?: InputMaybe<ServiceCategorySetInput>;
  where: ServiceCategoryBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceCategoryByPkArgs = {
  _inc?: InputMaybe<ServiceCategoryIncInput>;
  _set?: InputMaybe<ServiceCategorySetInput>;
  pkColumns: ServiceCategoryPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceCategoryManyArgs = {
  updates: Array<ServiceCategoryUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceDeviceArgs = {
  _inc?: InputMaybe<ServiceDeviceIncInput>;
  _set?: InputMaybe<ServiceDeviceSetInput>;
  where: ServiceDeviceBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceDeviceByPkArgs = {
  _inc?: InputMaybe<ServiceDeviceIncInput>;
  _set?: InputMaybe<ServiceDeviceSetInput>;
  pkColumns: ServiceDevicePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceDeviceManyArgs = {
  updates: Array<ServiceDeviceUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceDevicesAppointmentsArgs = {
  _inc?: InputMaybe<ServiceDevicesAppointmentsIncInput>;
  _set?: InputMaybe<ServiceDevicesAppointmentsSetInput>;
  where: ServiceDevicesAppointmentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceDevicesAppointmentsByPkArgs = {
  _inc?: InputMaybe<ServiceDevicesAppointmentsIncInput>;
  _set?: InputMaybe<ServiceDevicesAppointmentsSetInput>;
  pkColumns: ServiceDevicesAppointmentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceDevicesAppointmentsManyArgs = {
  updates: Array<ServiceDevicesAppointmentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceDevicesServiceTypesArgs = {
  _inc?: InputMaybe<ServiceDevicesServiceTypesIncInput>;
  _set?: InputMaybe<ServiceDevicesServiceTypesSetInput>;
  where: ServiceDevicesServiceTypesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceDevicesServiceTypesByPkArgs = {
  _inc?: InputMaybe<ServiceDevicesServiceTypesIncInput>;
  _set?: InputMaybe<ServiceDevicesServiceTypesSetInput>;
  pkColumns: ServiceDevicesServiceTypesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceDevicesServiceTypesManyArgs = {
  updates: Array<ServiceDevicesServiceTypesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceMenuItemLineArgs = {
  _inc?: InputMaybe<ServiceMenuItemLineIncInput>;
  _set?: InputMaybe<ServiceMenuItemLineSetInput>;
  where: ServiceMenuItemLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceMenuItemLineByPkArgs = {
  _inc?: InputMaybe<ServiceMenuItemLineIncInput>;
  _set?: InputMaybe<ServiceMenuItemLineSetInput>;
  pkColumns: ServiceMenuItemLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceMenuItemLineManyArgs = {
  updates: Array<ServiceMenuItemLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceMenuItemLineStandingOrdersArgs = {
  _inc?: InputMaybe<ServiceMenuItemLineStandingOrdersIncInput>;
  _set?: InputMaybe<ServiceMenuItemLineStandingOrdersSetInput>;
  where: ServiceMenuItemLineStandingOrdersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceMenuItemLineStandingOrdersByPkArgs = {
  _inc?: InputMaybe<ServiceMenuItemLineStandingOrdersIncInput>;
  _set?: InputMaybe<ServiceMenuItemLineStandingOrdersSetInput>;
  pkColumns: ServiceMenuItemLineStandingOrdersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceMenuItemLineStandingOrdersManyArgs = {
  updates: Array<ServiceMenuItemLineStandingOrdersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductArgs = {
  _inc?: InputMaybe<ServiceProductIncInput>;
  _set?: InputMaybe<ServiceProductSetInput>;
  where: ServiceProductBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductByPkArgs = {
  _inc?: InputMaybe<ServiceProductIncInput>;
  _set?: InputMaybe<ServiceProductSetInput>;
  pkColumns: ServiceProductPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductChartingNoteArgs = {
  _inc?: InputMaybe<ServiceProductChartingNoteIncInput>;
  _set?: InputMaybe<ServiceProductChartingNoteSetInput>;
  where: ServiceProductChartingNoteBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductChartingNoteByPkArgs = {
  _inc?: InputMaybe<ServiceProductChartingNoteIncInput>;
  _set?: InputMaybe<ServiceProductChartingNoteSetInput>;
  pkColumns: ServiceProductChartingNotePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductChartingNoteManyArgs = {
  updates: Array<ServiceProductChartingNoteUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductLineArgs = {
  _inc?: InputMaybe<ServiceProductLineIncInput>;
  _set?: InputMaybe<ServiceProductLineSetInput>;
  where: ServiceProductLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductLineByPkArgs = {
  _inc?: InputMaybe<ServiceProductLineIncInput>;
  _set?: InputMaybe<ServiceProductLineSetInput>;
  pkColumns: ServiceProductLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductLineManyArgs = {
  updates: Array<ServiceProductLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductManyArgs = {
  updates: Array<ServiceProductUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductTypeArgs = {
  _inc?: InputMaybe<ServiceProductTypeIncInput>;
  _set?: InputMaybe<ServiceProductTypeSetInput>;
  where: ServiceProductTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductTypeByPkArgs = {
  _inc?: InputMaybe<ServiceProductTypeIncInput>;
  _set?: InputMaybe<ServiceProductTypeSetInput>;
  pkColumns: ServiceProductTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductTypeManyArgs = {
  updates: Array<ServiceProductTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductUsageArgs = {
  _inc?: InputMaybe<ServiceProductUsageIncInput>;
  _set?: InputMaybe<ServiceProductUsageSetInput>;
  where: ServiceProductUsageBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductUsageByPkArgs = {
  _inc?: InputMaybe<ServiceProductUsageIncInput>;
  _set?: InputMaybe<ServiceProductUsageSetInput>;
  pkColumns: ServiceProductUsagePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceProductUsageManyArgs = {
  updates: Array<ServiceProductUsageUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceProtocolArgs = {
  _inc?: InputMaybe<ServiceProtocolIncInput>;
  _set?: InputMaybe<ServiceProtocolSetInput>;
  where: ServiceProtocolBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceProtocolByPkArgs = {
  _inc?: InputMaybe<ServiceProtocolIncInput>;
  _set?: InputMaybe<ServiceProtocolSetInput>;
  pkColumns: ServiceProtocolPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceProtocolManyArgs = {
  updates: Array<ServiceProtocolUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceProtocolTemplateArgs = {
  _inc?: InputMaybe<ServiceProtocolTemplateIncInput>;
  _set?: InputMaybe<ServiceProtocolTemplateSetInput>;
  where: ServiceProtocolTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceProtocolTemplateByPkArgs = {
  _inc?: InputMaybe<ServiceProtocolTemplateIncInput>;
  _set?: InputMaybe<ServiceProtocolTemplateSetInput>;
  pkColumns: ServiceProtocolTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceProtocolTemplateManyArgs = {
  updates: Array<ServiceProtocolTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceTypeArgs = {
  _inc?: InputMaybe<ServiceTypeIncInput>;
  _set?: InputMaybe<ServiceTypeSetInput>;
  where: ServiceTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceTypeByPkArgs = {
  _inc?: InputMaybe<ServiceTypeIncInput>;
  _set?: InputMaybe<ServiceTypeSetInput>;
  pkColumns: ServiceTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceTypeCategoryArgs = {
  _inc?: InputMaybe<ServiceTypeCategoryIncInput>;
  _set?: InputMaybe<ServiceTypeCategorySetInput>;
  where: ServiceTypeCategoryBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateServiceTypeCategoryByPkArgs = {
  _inc?: InputMaybe<ServiceTypeCategoryIncInput>;
  _set?: InputMaybe<ServiceTypeCategorySetInput>;
  pkColumns: ServiceTypeCategoryPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateServiceTypeCategoryManyArgs = {
  updates: Array<ServiceTypeCategoryUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateServiceTypeManyArgs = {
  updates: Array<ServiceTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateShortMoxieUrlArgs = {
  _set?: InputMaybe<ShortMoxieUrlSetInput>;
  where: ShortMoxieUrlBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateShortMoxieUrlByPkArgs = {
  _set?: InputMaybe<ShortMoxieUrlSetInput>;
  pkColumns: ShortMoxieUrlPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateShortMoxieUrlManyArgs = {
  updates: Array<ShortMoxieUrlUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStandingOrderArgs = {
  _inc?: InputMaybe<StandingOrderIncInput>;
  _set?: InputMaybe<StandingOrderSetInput>;
  where: StandingOrderBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStandingOrderByPkArgs = {
  _inc?: InputMaybe<StandingOrderIncInput>;
  _set?: InputMaybe<StandingOrderSetInput>;
  pkColumns: StandingOrderPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStandingOrderManyArgs = {
  updates: Array<StandingOrderUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStandingOrderTemplateArgs = {
  _inc?: InputMaybe<StandingOrderTemplateIncInput>;
  _set?: InputMaybe<StandingOrderTemplateSetInput>;
  where: StandingOrderTemplateBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStandingOrderTemplateByPkArgs = {
  _inc?: InputMaybe<StandingOrderTemplateIncInput>;
  _set?: InputMaybe<StandingOrderTemplateSetInput>;
  pkColumns: StandingOrderTemplatePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStandingOrderTemplateManyArgs = {
  updates: Array<StandingOrderTemplateUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStripeCardArgs = {
  cancellationReason?: InputMaybe<Scalars["String"]["input"]>;
  status: Scalars["String"]["input"];
  stripeAccountId: Scalars["String"]["input"];
  stripeCardId: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootUpdateSupplierInformationArgs = {
  _inc?: InputMaybe<SupplierInformationIncInput>;
  _set?: InputMaybe<SupplierInformationSetInput>;
  where: SupplierInformationBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSupplierInformationByPkArgs = {
  _inc?: InputMaybe<SupplierInformationIncInput>;
  _set?: InputMaybe<SupplierInformationSetInput>;
  pkColumns: SupplierInformationPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSupplierInformationManyArgs = {
  updates: Array<SupplierInformationUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTelehealthVisitDetailsArgs = {
  _inc?: InputMaybe<TelehealthVisitDetailsIncInput>;
  _set?: InputMaybe<TelehealthVisitDetailsSetInput>;
  where: TelehealthVisitDetailsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTelehealthVisitDetailsByPkArgs = {
  _inc?: InputMaybe<TelehealthVisitDetailsIncInput>;
  _set?: InputMaybe<TelehealthVisitDetailsSetInput>;
  pkColumns: TelehealthVisitDetailsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTelehealthVisitDetailsManyArgs = {
  updates: Array<TelehealthVisitDetailsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTmpStandingOrderArgs = {
  _inc?: InputMaybe<TmpStandingOrderIncInput>;
  _set?: InputMaybe<TmpStandingOrderSetInput>;
  where: TmpStandingOrderBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTmpStandingOrderByPkArgs = {
  _inc?: InputMaybe<TmpStandingOrderIncInput>;
  _set?: InputMaybe<TmpStandingOrderSetInput>;
  pkColumns: TmpStandingOrderPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTmpStandingOrderManyArgs = {
  updates: Array<TmpStandingOrderUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUrlPatternPermissionArgs = {
  _inc?: InputMaybe<UrlPatternPermissionIncInput>;
  _set?: InputMaybe<UrlPatternPermissionSetInput>;
  where: UrlPatternPermissionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUrlPatternPermissionByPkArgs = {
  _inc?: InputMaybe<UrlPatternPermissionIncInput>;
  _set?: InputMaybe<UrlPatternPermissionSetInput>;
  pkColumns: UrlPatternPermissionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUrlPatternPermissionManyArgs = {
  updates: Array<UrlPatternPermissionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _inc?: InputMaybe<UserIncInput>;
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUserByPkArgs = {
  _inc?: InputMaybe<UserIncInput>;
  _set?: InputMaybe<UserSetInput>;
  pkColumns: UserPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUserManyArgs = {
  updates: Array<UserUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUserMedspaArgs = {
  _inc?: InputMaybe<UserMedspaIncInput>;
  _set?: InputMaybe<UserMedspaSetInput>;
  where: UserMedspaBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUserMedspaByPkArgs = {
  _inc?: InputMaybe<UserMedspaIncInput>;
  _set?: InputMaybe<UserMedspaSetInput>;
  pkColumns: UserMedspaPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUserMedspaManyArgs = {
  updates: Array<UserMedspaUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateVisitArgs = {
  _inc?: InputMaybe<VisitIncInput>;
  _set?: InputMaybe<VisitSetInput>;
  where: VisitBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateVisitByPkArgs = {
  _inc?: InputMaybe<VisitIncInput>;
  _set?: InputMaybe<VisitSetInput>;
  pkColumns: VisitPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateVisitManyArgs = {
  updates: Array<VisitUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateVisitReviewStatusChangeLogArgs = {
  _inc?: InputMaybe<VisitReviewStatusChangeLogIncInput>;
  _set?: InputMaybe<VisitReviewStatusChangeLogSetInput>;
  where: VisitReviewStatusChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateVisitReviewStatusChangeLogByPkArgs = {
  _inc?: InputMaybe<VisitReviewStatusChangeLogIncInput>;
  _set?: InputMaybe<VisitReviewStatusChangeLogSetInput>;
  pkColumns: VisitReviewStatusChangeLogPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateVisitReviewStatusChangeLogManyArgs = {
  updates: Array<VisitReviewStatusChangeLogUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateVisitStatusChangeLogArgs = {
  _inc?: InputMaybe<VisitStatusChangeLogIncInput>;
  _set?: InputMaybe<VisitStatusChangeLogSetInput>;
  where: VisitStatusChangeLogBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateVisitStatusChangeLogByPkArgs = {
  _inc?: InputMaybe<VisitStatusChangeLogIncInput>;
  _set?: InputMaybe<VisitStatusChangeLogSetInput>;
  pkColumns: VisitStatusChangeLogPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateVisitStatusChangeLogManyArgs = {
  updates: Array<VisitStatusChangeLogUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateVisitsAdversereactionchangelogArgs = {
  _append?: InputMaybe<VisitsAdversereactionchangelogAppendInput>;
  _deleteAtPath?: InputMaybe<VisitsAdversereactionchangelogDeleteAtPathInput>;
  _deleteElem?: InputMaybe<VisitsAdversereactionchangelogDeleteElemInput>;
  _deleteKey?: InputMaybe<VisitsAdversereactionchangelogDeleteKeyInput>;
  _inc?: InputMaybe<VisitsAdversereactionchangelogIncInput>;
  _prepend?: InputMaybe<VisitsAdversereactionchangelogPrependInput>;
  _set?: InputMaybe<VisitsAdversereactionchangelogSetInput>;
  where: VisitsAdversereactionchangelogBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateVisitsAdversereactionchangelogByPkArgs = {
  _append?: InputMaybe<VisitsAdversereactionchangelogAppendInput>;
  _deleteAtPath?: InputMaybe<VisitsAdversereactionchangelogDeleteAtPathInput>;
  _deleteElem?: InputMaybe<VisitsAdversereactionchangelogDeleteElemInput>;
  _deleteKey?: InputMaybe<VisitsAdversereactionchangelogDeleteKeyInput>;
  _inc?: InputMaybe<VisitsAdversereactionchangelogIncInput>;
  _prepend?: InputMaybe<VisitsAdversereactionchangelogPrependInput>;
  _set?: InputMaybe<VisitsAdversereactionchangelogSetInput>;
  pkColumns: VisitsAdversereactionchangelogPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateVisitsAdversereactionchangelogManyArgs = {
  updates: Array<VisitsAdversereactionchangelogUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateVisitsChartsignatureArgs = {
  _inc?: InputMaybe<VisitsChartsignatureIncInput>;
  _set?: InputMaybe<VisitsChartsignatureSetInput>;
  where: VisitsChartsignatureBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateVisitsChartsignatureByPkArgs = {
  _inc?: InputMaybe<VisitsChartsignatureIncInput>;
  _set?: InputMaybe<VisitsChartsignatureSetInput>;
  pkColumns: VisitsChartsignaturePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateVisitsChartsignatureManyArgs = {
  updates: Array<VisitsChartsignatureUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateVisitsTelehealthvisitdetailschangelogArgs = {
  _inc?: InputMaybe<VisitsTelehealthvisitdetailschangelogIncInput>;
  _set?: InputMaybe<VisitsTelehealthvisitdetailschangelogSetInput>;
  where: VisitsTelehealthvisitdetailschangelogBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateVisitsTelehealthvisitdetailschangelogByPkArgs = {
  _inc?: InputMaybe<VisitsTelehealthvisitdetailschangelogIncInput>;
  _set?: InputMaybe<VisitsTelehealthvisitdetailschangelogSetInput>;
  pkColumns: VisitsTelehealthvisitdetailschangelogPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateVisitsTelehealthvisitdetailschangelogManyArgs = {
  updates: Array<VisitsTelehealthvisitdetailschangelogUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWalletCreditLineArgs = {
  _inc?: InputMaybe<WalletCreditLineIncInput>;
  _set?: InputMaybe<WalletCreditLineSetInput>;
  where: WalletCreditLineBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWalletCreditLineByPkArgs = {
  _inc?: InputMaybe<WalletCreditLineIncInput>;
  _set?: InputMaybe<WalletCreditLineSetInput>;
  pkColumns: WalletCreditLinePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWalletCreditLineManyArgs = {
  updates: Array<WalletCreditLineUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWalletCreditTypeArgs = {
  _inc?: InputMaybe<WalletCreditTypeIncInput>;
  _set?: InputMaybe<WalletCreditTypeSetInput>;
  where: WalletCreditTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWalletCreditTypeByPkArgs = {
  _inc?: InputMaybe<WalletCreditTypeIncInput>;
  _set?: InputMaybe<WalletCreditTypeSetInput>;
  pkColumns: WalletCreditTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWalletCreditTypeManyArgs = {
  updates: Array<WalletCreditTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionArgs = {
  _inc?: InputMaybe<WalletTransactionIncInput>;
  _set?: InputMaybe<WalletTransactionSetInput>;
  where: WalletTransactionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionByPkArgs = {
  _inc?: InputMaybe<WalletTransactionIncInput>;
  _set?: InputMaybe<WalletTransactionSetInput>;
  pkColumns: WalletTransactionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionManyArgs = {
  updates: Array<WalletTransactionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionSourceTmpArgs = {
  _inc?: InputMaybe<WalletTransactionSourceTmpIncInput>;
  _set?: InputMaybe<WalletTransactionSourceTmpSetInput>;
  where: WalletTransactionSourceTmpBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionSourceTmpByPkArgs = {
  _inc?: InputMaybe<WalletTransactionSourceTmpIncInput>;
  _set?: InputMaybe<WalletTransactionSourceTmpSetInput>;
  pkColumns: WalletTransactionSourceTmpPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionSourceTmpManyArgs = {
  updates: Array<WalletTransactionSourceTmpUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionsSourcesArgs = {
  _inc?: InputMaybe<WalletTransactionsSourcesIncInput>;
  _set?: InputMaybe<WalletTransactionsSourcesSetInput>;
  where: WalletTransactionsSourcesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionsSourcesByPkArgs = {
  _inc?: InputMaybe<WalletTransactionsSourcesIncInput>;
  _set?: InputMaybe<WalletTransactionsSourcesSetInput>;
  pkColumns: WalletTransactionsSourcesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWalletTransactionsSourcesManyArgs = {
  updates: Array<WalletTransactionsSourcesUpdates>;
};

/** mutation root */
export type Mutation_RootVerifyClient2faCodeArgs = {
  clientPhoneNumber: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  medspaSlug: Scalars["String"]["input"];
};

/** columns and relationships of "visits_note" */
export type Note = {
  __typename?: "note";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  author: User;
  authorId: Scalars["bigint"]["output"];
  content: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  deleted: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaServiceOfferings: Array<MedspaServiceOfferingNotes>;
  /** An aggregate relationship */
  medspaServiceOfferingsAggregate: MedspaServiceOfferingNotesAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** An array relationship */
  noteChangeLogs: Array<NoteChangeLog>;
  /** An aggregate relationship */
  noteChangeLogsAggregate: NoteChangeLogAggregate;
  /** An array relationship */
  noteFormSubmissions: Array<NoteFormSubmission>;
  /** An aggregate relationship */
  noteFormSubmissionsAggregate: NoteFormSubmissionAggregate;
  /** An object relationship */
  noteTemplate?: Maybe<NoteTemplate>;
  noteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of note */
  noteType?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** columns and relationships of "visits_note" */
export type NoteMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNotesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
};

/** columns and relationships of "visits_note" */
export type NoteMedspaServiceOfferingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNotesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
};

/** columns and relationships of "visits_note" */
export type NoteNoteChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<NoteChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteChangeLogOrderBy>>;
  where?: InputMaybe<NoteChangeLogBoolExp>;
};

/** columns and relationships of "visits_note" */
export type NoteNoteChangeLogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteChangeLogOrderBy>>;
  where?: InputMaybe<NoteChangeLogBoolExp>;
};

/** columns and relationships of "visits_note" */
export type NoteNoteFormSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteFormSubmissionOrderBy>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

/** columns and relationships of "visits_note" */
export type NoteNoteFormSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteFormSubmissionOrderBy>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

/** aggregated selection of "visits_note" */
export type NoteAggregate = {
  __typename?: "noteAggregate";
  aggregate?: Maybe<NoteAggregateFields>;
  nodes: Array<Note>;
};

export type NoteAggregateBoolExp = {
  bool_and?: InputMaybe<NoteAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<NoteAggregateBoolExpBool_Or>;
  count?: InputMaybe<NoteAggregateBoolExpCount>;
};

export type NoteAggregateBoolExpBool_And = {
  arguments: NoteSelectColumnNoteAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteBoolExp>;
  predicate: BooleanComparisonExp;
};

export type NoteAggregateBoolExpBool_Or = {
  arguments: NoteSelectColumnNoteAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteBoolExp>;
  predicate: BooleanComparisonExp;
};

export type NoteAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<NoteSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_note" */
export type NoteAggregateFields = {
  __typename?: "noteAggregateFields";
  avg?: Maybe<NoteAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<NoteMaxFields>;
  min?: Maybe<NoteMinFields>;
  stddev?: Maybe<NoteStddevFields>;
  stddevPop?: Maybe<NoteStddevPopFields>;
  stddevSamp?: Maybe<NoteStddevSampFields>;
  sum?: Maybe<NoteSumFields>;
  varPop?: Maybe<NoteVarPopFields>;
  varSamp?: Maybe<NoteVarSampFields>;
  variance?: Maybe<NoteVarianceFields>;
};

/** aggregate fields of "visits_note" */
export type NoteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<NoteSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_note" */
export type NoteAggregateOrderBy = {
  avg?: InputMaybe<NoteAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<NoteMaxOrderBy>;
  min?: InputMaybe<NoteMinOrderBy>;
  stddev?: InputMaybe<NoteStddevOrderBy>;
  stddevPop?: InputMaybe<NoteStddevPopOrderBy>;
  stddevSamp?: InputMaybe<NoteStddevSampOrderBy>;
  sum?: InputMaybe<NoteSumOrderBy>;
  varPop?: InputMaybe<NoteVarPopOrderBy>;
  varSamp?: InputMaybe<NoteVarSampOrderBy>;
  variance?: InputMaybe<NoteVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_note" */
export type NoteArrRelInsertInput = {
  data: Array<NoteInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<NoteOnConflict>;
};

/** aggregate avg on columns */
export type NoteAvgFields = {
  __typename?: "noteAvgFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  authorId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  noteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_note" */
export type NoteAvgOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_note". All fields are combined with a logical 'AND'. */
export type NoteBoolExp = {
  _and?: InputMaybe<Array<NoteBoolExp>>;
  _not?: InputMaybe<NoteBoolExp>;
  _or?: InputMaybe<Array<NoteBoolExp>>;
  adverseReactionId?: InputMaybe<BigintComparisonExp>;
  author?: InputMaybe<UserBoolExp>;
  authorId?: InputMaybe<BigintComparisonExp>;
  content?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaServiceOfferings?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
  medspaServiceOfferingsAggregate?: InputMaybe<MedspaServiceOfferingNotesAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  noteChangeLogs?: InputMaybe<NoteChangeLogBoolExp>;
  noteChangeLogsAggregate?: InputMaybe<NoteChangeLogAggregateBoolExp>;
  noteFormSubmissions?: InputMaybe<NoteFormSubmissionBoolExp>;
  noteFormSubmissionsAggregate?: InputMaybe<NoteFormSubmissionAggregateBoolExp>;
  noteTemplate?: InputMaybe<NoteTemplateBoolExp>;
  noteTemplateId?: InputMaybe<BigintComparisonExp>;
  noteType?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** columns and relationships of "visits_notechangelog" */
export type NoteChangeLog = {
  __typename?: "noteChangeLog";
  contentNew: Scalars["String"]["output"];
  contentOld: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedNew: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  note: Note;
  noteId: Scalars["bigint"]["output"];
  titleNew?: Maybe<Scalars["String"]["output"]>;
  titleOld?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  user: User;
  userId: Scalars["bigint"]["output"];
};

/** aggregated selection of "visits_notechangelog" */
export type NoteChangeLogAggregate = {
  __typename?: "noteChangeLogAggregate";
  aggregate?: Maybe<NoteChangeLogAggregateFields>;
  nodes: Array<NoteChangeLog>;
};

export type NoteChangeLogAggregateBoolExp = {
  bool_and?: InputMaybe<NoteChangeLogAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<NoteChangeLogAggregateBoolExpBool_Or>;
  count?: InputMaybe<NoteChangeLogAggregateBoolExpCount>;
};

export type NoteChangeLogAggregateBoolExpBool_And = {
  arguments: NoteChangeLogSelectColumnNoteChangeLogAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteChangeLogBoolExp>;
  predicate: BooleanComparisonExp;
};

export type NoteChangeLogAggregateBoolExpBool_Or = {
  arguments: NoteChangeLogSelectColumnNoteChangeLogAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteChangeLogBoolExp>;
  predicate: BooleanComparisonExp;
};

export type NoteChangeLogAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<NoteChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteChangeLogBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_notechangelog" */
export type NoteChangeLogAggregateFields = {
  __typename?: "noteChangeLogAggregateFields";
  avg?: Maybe<NoteChangeLogAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<NoteChangeLogMaxFields>;
  min?: Maybe<NoteChangeLogMinFields>;
  stddev?: Maybe<NoteChangeLogStddevFields>;
  stddevPop?: Maybe<NoteChangeLogStddevPopFields>;
  stddevSamp?: Maybe<NoteChangeLogStddevSampFields>;
  sum?: Maybe<NoteChangeLogSumFields>;
  varPop?: Maybe<NoteChangeLogVarPopFields>;
  varSamp?: Maybe<NoteChangeLogVarSampFields>;
  variance?: Maybe<NoteChangeLogVarianceFields>;
};

/** aggregate fields of "visits_notechangelog" */
export type NoteChangeLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<NoteChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_notechangelog" */
export type NoteChangeLogAggregateOrderBy = {
  avg?: InputMaybe<NoteChangeLogAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<NoteChangeLogMaxOrderBy>;
  min?: InputMaybe<NoteChangeLogMinOrderBy>;
  stddev?: InputMaybe<NoteChangeLogStddevOrderBy>;
  stddevPop?: InputMaybe<NoteChangeLogStddevPopOrderBy>;
  stddevSamp?: InputMaybe<NoteChangeLogStddevSampOrderBy>;
  sum?: InputMaybe<NoteChangeLogSumOrderBy>;
  varPop?: InputMaybe<NoteChangeLogVarPopOrderBy>;
  varSamp?: InputMaybe<NoteChangeLogVarSampOrderBy>;
  variance?: InputMaybe<NoteChangeLogVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_notechangelog" */
export type NoteChangeLogArrRelInsertInput = {
  data: Array<NoteChangeLogInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<NoteChangeLogOnConflict>;
};

/** aggregate avg on columns */
export type NoteChangeLogAvgFields = {
  __typename?: "noteChangeLogAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_notechangelog" */
export type NoteChangeLogAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_notechangelog". All fields are combined with a logical 'AND'. */
export type NoteChangeLogBoolExp = {
  _and?: InputMaybe<Array<NoteChangeLogBoolExp>>;
  _not?: InputMaybe<NoteChangeLogBoolExp>;
  _or?: InputMaybe<Array<NoteChangeLogBoolExp>>;
  contentNew?: InputMaybe<StringComparisonExp>;
  contentOld?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedNew?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  note?: InputMaybe<NoteBoolExp>;
  noteId?: InputMaybe<BigintComparisonExp>;
  titleNew?: InputMaybe<StringComparisonExp>;
  titleOld?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_notechangelog" */
export enum NoteChangeLogConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsNotechangelogPkey = "visits_notechangelog_pkey",
}

/** input type for incrementing numeric columns in table "visits_notechangelog" */
export type NoteChangeLogIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_notechangelog" */
export type NoteChangeLogInsertInput = {
  contentNew?: InputMaybe<Scalars["String"]["input"]>;
  contentOld?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedNew?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<NoteObjRelInsertInput>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
  titleNew?: InputMaybe<Scalars["String"]["input"]>;
  titleOld?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type NoteChangeLogMaxFields = {
  __typename?: "noteChangeLogMaxFields";
  contentNew?: Maybe<Scalars["String"]["output"]>;
  contentOld?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
  titleNew?: Maybe<Scalars["String"]["output"]>;
  titleOld?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_notechangelog" */
export type NoteChangeLogMaxOrderBy = {
  contentNew?: InputMaybe<OrderBy>;
  contentOld?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  titleNew?: InputMaybe<OrderBy>;
  titleOld?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type NoteChangeLogMinFields = {
  __typename?: "noteChangeLogMinFields";
  contentNew?: Maybe<Scalars["String"]["output"]>;
  contentOld?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
  titleNew?: Maybe<Scalars["String"]["output"]>;
  titleOld?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_notechangelog" */
export type NoteChangeLogMinOrderBy = {
  contentNew?: InputMaybe<OrderBy>;
  contentOld?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  titleNew?: InputMaybe<OrderBy>;
  titleOld?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_notechangelog" */
export type NoteChangeLogMutationResponse = {
  __typename?: "noteChangeLogMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<NoteChangeLog>;
};

/** on_conflict condition type for table "visits_notechangelog" */
export type NoteChangeLogOnConflict = {
  constraint: NoteChangeLogConstraint;
  updateColumns?: Array<NoteChangeLogUpdateColumn>;
  where?: InputMaybe<NoteChangeLogBoolExp>;
};

/** Ordering options when selecting data from "visits_notechangelog". */
export type NoteChangeLogOrderBy = {
  contentNew?: InputMaybe<OrderBy>;
  contentOld?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedNew?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<NoteOrderBy>;
  noteId?: InputMaybe<OrderBy>;
  titleNew?: InputMaybe<OrderBy>;
  titleOld?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_notechangelog */
export type NoteChangeLogPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_notechangelog" */
export enum NoteChangeLogSelectColumn {
  /** column name */
  ContentNew = "contentNew",
  /** column name */
  ContentOld = "contentOld",
  /** column name */
  Created = "created",
  /** column name */
  DeletedNew = "deletedNew",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NoteId = "noteId",
  /** column name */
  TitleNew = "titleNew",
  /** column name */
  TitleOld = "titleOld",
  /** column name */
  UserId = "userId",
}

/** select "noteChangeLogAggregateBoolExpBool_andArgumentsColumns" columns of table "visits_notechangelog" */
export enum NoteChangeLogSelectColumnNoteChangeLogAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  DeletedNew = "deletedNew",
}

/** select "noteChangeLogAggregateBoolExpBool_orArgumentsColumns" columns of table "visits_notechangelog" */
export enum NoteChangeLogSelectColumnNoteChangeLogAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  DeletedNew = "deletedNew",
}

/** input type for updating data in table "visits_notechangelog" */
export type NoteChangeLogSetInput = {
  contentNew?: InputMaybe<Scalars["String"]["input"]>;
  contentOld?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedNew?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
  titleNew?: InputMaybe<Scalars["String"]["input"]>;
  titleOld?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type NoteChangeLogStddevFields = {
  __typename?: "noteChangeLogStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_notechangelog" */
export type NoteChangeLogStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type NoteChangeLogStddevPopFields = {
  __typename?: "noteChangeLogStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_notechangelog" */
export type NoteChangeLogStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type NoteChangeLogStddevSampFields = {
  __typename?: "noteChangeLogStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_notechangelog" */
export type NoteChangeLogStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "noteChangeLog" */
export type NoteChangeLogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: NoteChangeLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NoteChangeLogStreamCursorValueInput = {
  contentNew?: InputMaybe<Scalars["String"]["input"]>;
  contentOld?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedNew?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
  titleNew?: InputMaybe<Scalars["String"]["input"]>;
  titleOld?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type NoteChangeLogSumFields = {
  __typename?: "noteChangeLogSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_notechangelog" */
export type NoteChangeLogSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_notechangelog" */
export enum NoteChangeLogUpdateColumn {
  /** column name */
  ContentNew = "contentNew",
  /** column name */
  ContentOld = "contentOld",
  /** column name */
  Created = "created",
  /** column name */
  DeletedNew = "deletedNew",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NoteId = "noteId",
  /** column name */
  TitleNew = "titleNew",
  /** column name */
  TitleOld = "titleOld",
  /** column name */
  UserId = "userId",
}

export type NoteChangeLogUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NoteChangeLogIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NoteChangeLogSetInput>;
  /** filter the rows which have to be updated */
  where: NoteChangeLogBoolExp;
};

/** aggregate varPop on columns */
export type NoteChangeLogVarPopFields = {
  __typename?: "noteChangeLogVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_notechangelog" */
export type NoteChangeLogVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type NoteChangeLogVarSampFields = {
  __typename?: "noteChangeLogVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_notechangelog" */
export type NoteChangeLogVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type NoteChangeLogVarianceFields = {
  __typename?: "noteChangeLogVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_notechangelog" */
export type NoteChangeLogVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** unique or primary key constraints on table "visits_note" */
export enum NoteConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsNotePkey = "visits_note_pkey",
}

/** columns and relationships of "visits_note_form_submissions" */
export type NoteFormSubmission = {
  __typename?: "noteFormSubmission";
  /** An object relationship */
  formSubmission: FormSubmission;
  formsubmissionId: Scalars["bigint"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  note: Note;
  noteId: Scalars["bigint"]["output"];
};

/** aggregated selection of "visits_note_form_submissions" */
export type NoteFormSubmissionAggregate = {
  __typename?: "noteFormSubmissionAggregate";
  aggregate?: Maybe<NoteFormSubmissionAggregateFields>;
  nodes: Array<NoteFormSubmission>;
};

export type NoteFormSubmissionAggregateBoolExp = {
  count?: InputMaybe<NoteFormSubmissionAggregateBoolExpCount>;
};

export type NoteFormSubmissionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteFormSubmissionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_note_form_submissions" */
export type NoteFormSubmissionAggregateFields = {
  __typename?: "noteFormSubmissionAggregateFields";
  avg?: Maybe<NoteFormSubmissionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<NoteFormSubmissionMaxFields>;
  min?: Maybe<NoteFormSubmissionMinFields>;
  stddev?: Maybe<NoteFormSubmissionStddevFields>;
  stddevPop?: Maybe<NoteFormSubmissionStddevPopFields>;
  stddevSamp?: Maybe<NoteFormSubmissionStddevSampFields>;
  sum?: Maybe<NoteFormSubmissionSumFields>;
  varPop?: Maybe<NoteFormSubmissionVarPopFields>;
  varSamp?: Maybe<NoteFormSubmissionVarSampFields>;
  variance?: Maybe<NoteFormSubmissionVarianceFields>;
};

/** aggregate fields of "visits_note_form_submissions" */
export type NoteFormSubmissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_note_form_submissions" */
export type NoteFormSubmissionAggregateOrderBy = {
  avg?: InputMaybe<NoteFormSubmissionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<NoteFormSubmissionMaxOrderBy>;
  min?: InputMaybe<NoteFormSubmissionMinOrderBy>;
  stddev?: InputMaybe<NoteFormSubmissionStddevOrderBy>;
  stddevPop?: InputMaybe<NoteFormSubmissionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<NoteFormSubmissionStddevSampOrderBy>;
  sum?: InputMaybe<NoteFormSubmissionSumOrderBy>;
  varPop?: InputMaybe<NoteFormSubmissionVarPopOrderBy>;
  varSamp?: InputMaybe<NoteFormSubmissionVarSampOrderBy>;
  variance?: InputMaybe<NoteFormSubmissionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_note_form_submissions" */
export type NoteFormSubmissionArrRelInsertInput = {
  data: Array<NoteFormSubmissionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<NoteFormSubmissionOnConflict>;
};

/** aggregate avg on columns */
export type NoteFormSubmissionAvgFields = {
  __typename?: "noteFormSubmissionAvgFields";
  formsubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionAvgOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_note_form_submissions". All fields are combined with a logical 'AND'. */
export type NoteFormSubmissionBoolExp = {
  _and?: InputMaybe<Array<NoteFormSubmissionBoolExp>>;
  _not?: InputMaybe<NoteFormSubmissionBoolExp>;
  _or?: InputMaybe<Array<NoteFormSubmissionBoolExp>>;
  formSubmission?: InputMaybe<FormSubmissionBoolExp>;
  formsubmissionId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  note?: InputMaybe<NoteBoolExp>;
  noteId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_note_form_submissions" */
export enum NoteFormSubmissionConstraint {
  /** unique or primary key constraint on columns "formsubmission_id", "note_id" */
  VisitsNoteFormSubmissNoteIdFormsubmissionI_77327766Uniq = "visits_note_form_submiss_note_id_formsubmission_i_77327766_uniq",
  /** unique or primary key constraint on columns "id" */
  VisitsNoteFormSubmissionsPkey = "visits_note_form_submissions_pkey",
}

/** input type for incrementing numeric columns in table "visits_note_form_submissions" */
export type NoteFormSubmissionIncInput = {
  formsubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_note_form_submissions" */
export type NoteFormSubmissionInsertInput = {
  formSubmission?: InputMaybe<FormSubmissionObjRelInsertInput>;
  formsubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  note?: InputMaybe<NoteObjRelInsertInput>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type NoteFormSubmissionMaxFields = {
  __typename?: "noteFormSubmissionMaxFields";
  formsubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionMaxOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type NoteFormSubmissionMinFields = {
  __typename?: "noteFormSubmissionMinFields";
  formsubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionMinOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_note_form_submissions" */
export type NoteFormSubmissionMutationResponse = {
  __typename?: "noteFormSubmissionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<NoteFormSubmission>;
};

/** on_conflict condition type for table "visits_note_form_submissions" */
export type NoteFormSubmissionOnConflict = {
  constraint: NoteFormSubmissionConstraint;
  updateColumns?: Array<NoteFormSubmissionUpdateColumn>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

/** Ordering options when selecting data from "visits_note_form_submissions". */
export type NoteFormSubmissionOrderBy = {
  formSubmission?: InputMaybe<FormSubmissionOrderBy>;
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<NoteOrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_note_form_submissions */
export type NoteFormSubmissionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_note_form_submissions" */
export enum NoteFormSubmissionSelectColumn {
  /** column name */
  FormsubmissionId = "formsubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  NoteId = "noteId",
}

/** input type for updating data in table "visits_note_form_submissions" */
export type NoteFormSubmissionSetInput = {
  formsubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type NoteFormSubmissionStddevFields = {
  __typename?: "noteFormSubmissionStddevFields";
  formsubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionStddevOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type NoteFormSubmissionStddevPopFields = {
  __typename?: "noteFormSubmissionStddevPopFields";
  formsubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionStddevPopOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type NoteFormSubmissionStddevSampFields = {
  __typename?: "noteFormSubmissionStddevSampFields";
  formsubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionStddevSampOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "noteFormSubmission" */
export type NoteFormSubmissionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: NoteFormSubmissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NoteFormSubmissionStreamCursorValueInput = {
  formsubmissionId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  noteId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type NoteFormSubmissionSumFields = {
  __typename?: "noteFormSubmissionSumFields";
  formsubmissionId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  noteId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionSumOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_note_form_submissions" */
export enum NoteFormSubmissionUpdateColumn {
  /** column name */
  FormsubmissionId = "formsubmissionId",
  /** column name */
  Id = "id",
  /** column name */
  NoteId = "noteId",
}

export type NoteFormSubmissionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NoteFormSubmissionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NoteFormSubmissionSetInput>;
  /** filter the rows which have to be updated */
  where: NoteFormSubmissionBoolExp;
};

/** aggregate varPop on columns */
export type NoteFormSubmissionVarPopFields = {
  __typename?: "noteFormSubmissionVarPopFields";
  formsubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionVarPopOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type NoteFormSubmissionVarSampFields = {
  __typename?: "noteFormSubmissionVarSampFields";
  formsubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionVarSampOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type NoteFormSubmissionVarianceFields = {
  __typename?: "noteFormSubmissionVarianceFields";
  formsubmissionId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  noteId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_note_form_submissions" */
export type NoteFormSubmissionVarianceOrderBy = {
  formsubmissionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  noteId?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "visits_note" */
export type NoteIncInput = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  authorId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  noteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_note" */
export type NoteInsertInput = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  author?: InputMaybe<UserObjRelInsertInput>;
  authorId?: InputMaybe<Scalars["bigint"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOfferings?: InputMaybe<MedspaServiceOfferingNotesArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noteChangeLogs?: InputMaybe<NoteChangeLogArrRelInsertInput>;
  noteFormSubmissions?: InputMaybe<NoteFormSubmissionArrRelInsertInput>;
  noteTemplate?: InputMaybe<NoteTemplateObjRelInsertInput>;
  noteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of note */
  noteType?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type NoteMaxFields = {
  __typename?: "noteMaxFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["bigint"]["output"]>;
  authorId?: Maybe<Scalars["bigint"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  noteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of note */
  noteType?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_note" */
export type NoteMaxOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  /** Type of note */
  noteType?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type NoteMinFields = {
  __typename?: "noteMinFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["bigint"]["output"]>;
  authorId?: Maybe<Scalars["bigint"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  noteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  /** Type of note */
  noteType?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_note" */
export type NoteMinOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  /** Type of note */
  noteType?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_note" */
export type NoteMutationResponse = {
  __typename?: "noteMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Note>;
};

/** input type for inserting object relation for remote table "visits_note" */
export type NoteObjRelInsertInput = {
  data: NoteInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<NoteOnConflict>;
};

/** on_conflict condition type for table "visits_note" */
export type NoteOnConflict = {
  constraint: NoteConstraint;
  updateColumns?: Array<NoteUpdateColumn>;
  where?: InputMaybe<NoteBoolExp>;
};

/** Ordering options when selecting data from "visits_note". */
export type NoteOrderBy = {
  adverseReactionId?: InputMaybe<OrderBy>;
  author?: InputMaybe<UserOrderBy>;
  authorId?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaServiceOfferingsAggregate?: InputMaybe<MedspaServiceOfferingNotesAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  noteChangeLogsAggregate?: InputMaybe<NoteChangeLogAggregateOrderBy>;
  noteFormSubmissionsAggregate?: InputMaybe<NoteFormSubmissionAggregateOrderBy>;
  noteTemplate?: InputMaybe<NoteTemplateOrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  noteType?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_note */
export type NotePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_note" */
export enum NoteSelectColumn {
  /** column name */
  AdverseReactionId = "adverseReactionId",
  /** column name */
  AuthorId = "authorId",
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  NoteTemplateId = "noteTemplateId",
  /** column name */
  NoteType = "noteType",
  /** column name */
  Title = "title",
  /** column name */
  VisitId = "visitId",
}

/** select "noteAggregateBoolExpBool_andArgumentsColumns" columns of table "visits_note" */
export enum NoteSelectColumnNoteAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** select "noteAggregateBoolExpBool_orArgumentsColumns" columns of table "visits_note" */
export enum NoteSelectColumnNoteAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** input type for updating data in table "visits_note" */
export type NoteSetInput = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  authorId?: InputMaybe<Scalars["bigint"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of note */
  noteType?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type NoteStddevFields = {
  __typename?: "noteStddevFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  authorId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  noteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_note" */
export type NoteStddevOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type NoteStddevPopFields = {
  __typename?: "noteStddevPopFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  authorId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  noteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_note" */
export type NoteStddevPopOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type NoteStddevSampFields = {
  __typename?: "noteStddevSampFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  authorId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  noteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_note" */
export type NoteStddevSampOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "note" */
export type NoteStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: NoteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NoteStreamCursorValueInput = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<Scalars["bigint"]["input"]>;
  authorId?: InputMaybe<Scalars["bigint"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  noteTemplateId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Type of note */
  noteType?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type NoteSumFields = {
  __typename?: "noteSumFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["bigint"]["output"]>;
  authorId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  noteTemplateId?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_note" */
export type NoteSumOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "resources_notetemplate" */
export type NoteTemplate = {
  __typename?: "noteTemplate";
  /** If true, the note template will pick up newly enabled medspa service offerings. */
  allServices: Scalars["Boolean"]["output"];
  content: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  editedBy?: Maybe<User>;
  editedById?: Maybe<Scalars["bigint"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  /** An array relationship */
  medspaConfigurations: Array<MedspasMedspaconfiguration>;
  /** An aggregate relationship */
  medspaConfigurationsAggregate: MedspasMedspaconfigurationAggregate;
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaServiceOfferings: Array<MedspaServiceOfferingNoteTemplates>;
  /** An aggregate relationship */
  medspaServiceOfferingsAggregate: MedspaServiceOfferingNoteTemplatesAggregate;
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  /** An array relationship */
  notes: Array<Note>;
  /** An aggregate relationship */
  notesAggregate: NoteAggregate;
};

/** columns and relationships of "resources_notetemplate" */
export type NoteTemplateMedspaConfigurationsArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedspaconfigurationOrderBy>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

/** columns and relationships of "resources_notetemplate" */
export type NoteTemplateMedspaConfigurationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedspaconfigurationOrderBy>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

/** columns and relationships of "resources_notetemplate" */
export type NoteTemplateMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceOfferingNoteTemplatesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
};

/** columns and relationships of "resources_notetemplate" */
export type NoteTemplateMedspaServiceOfferingsAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceOfferingNoteTemplatesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
};

/** columns and relationships of "resources_notetemplate" */
export type NoteTemplateNotesArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

/** columns and relationships of "resources_notetemplate" */
export type NoteTemplateNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

/** aggregated selection of "resources_notetemplate" */
export type NoteTemplateAggregate = {
  __typename?: "noteTemplateAggregate";
  aggregate?: Maybe<NoteTemplateAggregateFields>;
  nodes: Array<NoteTemplate>;
};

export type NoteTemplateAggregateBoolExp = {
  bool_and?: InputMaybe<NoteTemplateAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<NoteTemplateAggregateBoolExpBool_Or>;
  count?: InputMaybe<NoteTemplateAggregateBoolExpCount>;
};

export type NoteTemplateAggregateBoolExpBool_And = {
  arguments: NoteTemplateSelectColumnNoteTemplateAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteTemplateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type NoteTemplateAggregateBoolExpBool_Or = {
  arguments: NoteTemplateSelectColumnNoteTemplateAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteTemplateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type NoteTemplateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<NoteTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<NoteTemplateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_notetemplate" */
export type NoteTemplateAggregateFields = {
  __typename?: "noteTemplateAggregateFields";
  avg?: Maybe<NoteTemplateAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<NoteTemplateMaxFields>;
  min?: Maybe<NoteTemplateMinFields>;
  stddev?: Maybe<NoteTemplateStddevFields>;
  stddevPop?: Maybe<NoteTemplateStddevPopFields>;
  stddevSamp?: Maybe<NoteTemplateStddevSampFields>;
  sum?: Maybe<NoteTemplateSumFields>;
  varPop?: Maybe<NoteTemplateVarPopFields>;
  varSamp?: Maybe<NoteTemplateVarSampFields>;
  variance?: Maybe<NoteTemplateVarianceFields>;
};

/** aggregate fields of "resources_notetemplate" */
export type NoteTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<NoteTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_notetemplate" */
export type NoteTemplateAggregateOrderBy = {
  avg?: InputMaybe<NoteTemplateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<NoteTemplateMaxOrderBy>;
  min?: InputMaybe<NoteTemplateMinOrderBy>;
  stddev?: InputMaybe<NoteTemplateStddevOrderBy>;
  stddevPop?: InputMaybe<NoteTemplateStddevPopOrderBy>;
  stddevSamp?: InputMaybe<NoteTemplateStddevSampOrderBy>;
  sum?: InputMaybe<NoteTemplateSumOrderBy>;
  varPop?: InputMaybe<NoteTemplateVarPopOrderBy>;
  varSamp?: InputMaybe<NoteTemplateVarSampOrderBy>;
  variance?: InputMaybe<NoteTemplateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_notetemplate" */
export type NoteTemplateArrRelInsertInput = {
  data: Array<NoteTemplateInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<NoteTemplateOnConflict>;
};

/** aggregate avg on columns */
export type NoteTemplateAvgFields = {
  __typename?: "noteTemplateAvgFields";
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_notetemplate" */
export type NoteTemplateAvgOrderBy = {
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_notetemplate". All fields are combined with a logical 'AND'. */
export type NoteTemplateBoolExp = {
  _and?: InputMaybe<Array<NoteTemplateBoolExp>>;
  _not?: InputMaybe<NoteTemplateBoolExp>;
  _or?: InputMaybe<Array<NoteTemplateBoolExp>>;
  allServices?: InputMaybe<BooleanComparisonExp>;
  content?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  editedBy?: InputMaybe<UserBoolExp>;
  editedById?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaConfigurations?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
  medspaConfigurationsAggregate?: InputMaybe<MedspasMedspaconfigurationAggregateBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaServiceOfferings?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
  medspaServiceOfferingsAggregate?: InputMaybe<MedspaServiceOfferingNoteTemplatesAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  notes?: InputMaybe<NoteBoolExp>;
  notesAggregate?: InputMaybe<NoteAggregateBoolExp>;
};

/** unique or primary key constraints on table "resources_notetemplate" */
export enum NoteTemplateConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesNotetemplatePkey = "resources_notetemplate_pkey",
}

/** input type for incrementing numeric columns in table "resources_notetemplate" */
export type NoteTemplateIncInput = {
  editedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_notetemplate" */
export type NoteTemplateInsertInput = {
  /** If true, the note template will pick up newly enabled medspa service offerings. */
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  editedBy?: InputMaybe<UserObjRelInsertInput>;
  editedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaConfigurations?: InputMaybe<MedspasMedspaconfigurationArrRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceOfferings?: InputMaybe<MedspaServiceOfferingNoteTemplatesArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<NoteArrRelInsertInput>;
};

/** aggregate max on columns */
export type NoteTemplateMaxFields = {
  __typename?: "noteTemplateMaxFields";
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  editedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "resources_notetemplate" */
export type NoteTemplateMaxOrderBy = {
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type NoteTemplateMinFields = {
  __typename?: "noteTemplateMinFields";
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  editedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "resources_notetemplate" */
export type NoteTemplateMinOrderBy = {
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_notetemplate" */
export type NoteTemplateMutationResponse = {
  __typename?: "noteTemplateMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<NoteTemplate>;
};

/** input type for inserting object relation for remote table "resources_notetemplate" */
export type NoteTemplateObjRelInsertInput = {
  data: NoteTemplateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<NoteTemplateOnConflict>;
};

/** on_conflict condition type for table "resources_notetemplate" */
export type NoteTemplateOnConflict = {
  constraint: NoteTemplateConstraint;
  updateColumns?: Array<NoteTemplateUpdateColumn>;
  where?: InputMaybe<NoteTemplateBoolExp>;
};

/** Ordering options when selecting data from "resources_notetemplate". */
export type NoteTemplateOrderBy = {
  allServices?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  editedBy?: InputMaybe<UserOrderBy>;
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaConfigurationsAggregate?: InputMaybe<MedspasMedspaconfigurationAggregateOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaServiceOfferingsAggregate?: InputMaybe<MedspaServiceOfferingNoteTemplatesAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notesAggregate?: InputMaybe<NoteAggregateOrderBy>;
};

/** primary key columns input for table: resources_notetemplate */
export type NoteTemplatePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_notetemplate" */
export enum NoteTemplateSelectColumn {
  /** column name */
  AllServices = "allServices",
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  EditedById = "editedById",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

/** select "noteTemplateAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_notetemplate" */
export enum NoteTemplateSelectColumnNoteTemplateAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  AllServices = "allServices",
}

/** select "noteTemplateAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_notetemplate" */
export enum NoteTemplateSelectColumnNoteTemplateAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  AllServices = "allServices",
}

/** input type for updating data in table "resources_notetemplate" */
export type NoteTemplateSetInput = {
  /** If true, the note template will pick up newly enabled medspa service offerings. */
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  editedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type NoteTemplateStddevFields = {
  __typename?: "noteTemplateStddevFields";
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_notetemplate" */
export type NoteTemplateStddevOrderBy = {
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type NoteTemplateStddevPopFields = {
  __typename?: "noteTemplateStddevPopFields";
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_notetemplate" */
export type NoteTemplateStddevPopOrderBy = {
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type NoteTemplateStddevSampFields = {
  __typename?: "noteTemplateStddevSampFields";
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_notetemplate" */
export type NoteTemplateStddevSampOrderBy = {
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "noteTemplate" */
export type NoteTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: NoteTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NoteTemplateStreamCursorValueInput = {
  /** If true, the note template will pick up newly enabled medspa service offerings. */
  allServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  editedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type NoteTemplateSumFields = {
  __typename?: "noteTemplateSumFields";
  editedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_notetemplate" */
export type NoteTemplateSumOrderBy = {
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_notetemplate" */
export enum NoteTemplateUpdateColumn {
  /** column name */
  AllServices = "allServices",
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  EditedById = "editedById",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
}

export type NoteTemplateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NoteTemplateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NoteTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: NoteTemplateBoolExp;
};

/** aggregate varPop on columns */
export type NoteTemplateVarPopFields = {
  __typename?: "noteTemplateVarPopFields";
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_notetemplate" */
export type NoteTemplateVarPopOrderBy = {
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type NoteTemplateVarSampFields = {
  __typename?: "noteTemplateVarSampFields";
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_notetemplate" */
export type NoteTemplateVarSampOrderBy = {
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type NoteTemplateVarianceFields = {
  __typename?: "noteTemplateVarianceFields";
  editedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_notetemplate" */
export type NoteTemplateVarianceOrderBy = {
  editedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_note" */
export enum NoteUpdateColumn {
  /** column name */
  AdverseReactionId = "adverseReactionId",
  /** column name */
  AuthorId = "authorId",
  /** column name */
  Content = "content",
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  NoteTemplateId = "noteTemplateId",
  /** column name */
  NoteType = "noteType",
  /** column name */
  Title = "title",
  /** column name */
  VisitId = "visitId",
}

export type NoteUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NoteIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NoteSetInput>;
  /** filter the rows which have to be updated */
  where: NoteBoolExp;
};

/** aggregate varPop on columns */
export type NoteVarPopFields = {
  __typename?: "noteVarPopFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  authorId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  noteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_note" */
export type NoteVarPopOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type NoteVarSampFields = {
  __typename?: "noteVarSampFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  authorId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  noteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_note" */
export type NoteVarSampOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type NoteVarianceFields = {
  __typename?: "noteVarianceFields";
  /** Adverse reaction associated with the note */
  adverseReactionId?: Maybe<Scalars["Float"]["output"]>;
  authorId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  noteTemplateId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_note" */
export type NoteVarianceOrderBy = {
  /** Adverse reaction associated with the note */
  adverseReactionId?: InputMaybe<OrderBy>;
  authorId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  noteTemplateId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "resources_package" */
export type Package = {
  __typename?: "package";
  additionalInfo?: Maybe<PackageType>;
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description: Scalars["String"]["output"];
  endDate?: Maybe<Scalars["date"]["output"]>;
  id: Scalars["bigint"]["output"];
  isOnlinePurchaseEnabled: Scalars["Boolean"]["output"];
  /** A computed field, executes function "is_package_currently_in_online_store" */
  isPackageCurrentlyInOnlineStore?: Maybe<Scalars["Boolean"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order: Scalars["Int"]["output"];
  /** An array relationship */
  packageItems: Array<PackageItem>;
  /** An aggregate relationship */
  packageItemsAggregate: PackageItemAggregate;
  packageName: Scalars["String"]["output"];
  packagePrice: Scalars["numeric"]["output"];
  packageSavings: Scalars["numeric"]["output"];
  startDate?: Maybe<Scalars["date"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  thumbnail?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "resources_package" */
export type PackagePackageItemsArgs = {
  distinctOn?: InputMaybe<Array<PackageItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemOrderBy>>;
  where?: InputMaybe<PackageItemBoolExp>;
};

/** columns and relationships of "resources_package" */
export type PackagePackageItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemOrderBy>>;
  where?: InputMaybe<PackageItemBoolExp>;
};

/** aggregated selection of "resources_package" */
export type PackageAggregate = {
  __typename?: "packageAggregate";
  aggregate?: Maybe<PackageAggregateFields>;
  nodes: Array<Package>;
};

export type PackageAggregateBoolExp = {
  bool_and?: InputMaybe<PackageAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<PackageAggregateBoolExpBool_Or>;
  count?: InputMaybe<PackageAggregateBoolExpCount>;
};

export type PackageAggregateBoolExpBool_And = {
  arguments: PackageSelectColumnPackageAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PackageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PackageAggregateBoolExpBool_Or = {
  arguments: PackageSelectColumnPackageAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PackageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PackageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PackageSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PackageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_package" */
export type PackageAggregateFields = {
  __typename?: "packageAggregateFields";
  avg?: Maybe<PackageAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<PackageMaxFields>;
  min?: Maybe<PackageMinFields>;
  stddev?: Maybe<PackageStddevFields>;
  stddevPop?: Maybe<PackageStddevPopFields>;
  stddevSamp?: Maybe<PackageStddevSampFields>;
  sum?: Maybe<PackageSumFields>;
  varPop?: Maybe<PackageVarPopFields>;
  varSamp?: Maybe<PackageVarSampFields>;
  variance?: Maybe<PackageVarianceFields>;
};

/** aggregate fields of "resources_package" */
export type PackageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PackageSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_package" */
export type PackageAggregateOrderBy = {
  avg?: InputMaybe<PackageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PackageMaxOrderBy>;
  min?: InputMaybe<PackageMinOrderBy>;
  stddev?: InputMaybe<PackageStddevOrderBy>;
  stddevPop?: InputMaybe<PackageStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PackageStddevSampOrderBy>;
  sum?: InputMaybe<PackageSumOrderBy>;
  varPop?: InputMaybe<PackageVarPopOrderBy>;
  varSamp?: InputMaybe<PackageVarSampOrderBy>;
  variance?: InputMaybe<PackageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_package" */
export type PackageArrRelInsertInput = {
  data: Array<PackageInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PackageOnConflict>;
};

/** aggregate avg on columns */
export type PackageAvgFields = {
  __typename?: "packageAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  packagePrice?: Maybe<Scalars["Float"]["output"]>;
  packageSavings?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_package" */
export type PackageAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_package". All fields are combined with a logical 'AND'. */
export type PackageBoolExp = {
  _and?: InputMaybe<Array<PackageBoolExp>>;
  _not?: InputMaybe<PackageBoolExp>;
  _or?: InputMaybe<Array<PackageBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isOnlinePurchaseEnabled?: InputMaybe<BooleanComparisonExp>;
  isPackageCurrentlyInOnlineStore?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  packageItems?: InputMaybe<PackageItemBoolExp>;
  packageItemsAggregate?: InputMaybe<PackageItemAggregateBoolExp>;
  packageName?: InputMaybe<StringComparisonExp>;
  packagePrice?: InputMaybe<NumericComparisonExp>;
  packageSavings?: InputMaybe<NumericComparisonExp>;
  startDate?: InputMaybe<DateComparisonExp>;
  tax?: InputMaybe<NumericComparisonExp>;
  thumbnail?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "resources_package" */
export enum PackageConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesPackagePkey = "resources_package_pkey",
}

/** input type for incrementing numeric columns in table "resources_package" */
export type PackageIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  packagePrice?: InputMaybe<Scalars["numeric"]["input"]>;
  packageSavings?: InputMaybe<Scalars["numeric"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "resources_package" */
export type PackageInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isOnlinePurchaseEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  packageItems?: InputMaybe<PackageItemArrRelInsertInput>;
  packageName?: InputMaybe<Scalars["String"]["input"]>;
  packagePrice?: InputMaybe<Scalars["numeric"]["input"]>;
  packageSavings?: InputMaybe<Scalars["numeric"]["input"]>;
  startDate?: InputMaybe<Scalars["date"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "resources_packageitem" */
export type PackageItem = {
  __typename?: "packageItem";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id: Scalars["bigint"]["output"];
  itemAmount: Scalars["Int"]["output"];
  itemType: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  package: Package;
  packageId: Scalars["bigint"]["output"];
  /** An object relationship */
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  serviceMenuItem?: Maybe<MedspaServiceMenuItem>;
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  serviceProduct?: Maybe<MedspaServiceProduct>;
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "resources_packageitem" */
export type PackageItemAggregate = {
  __typename?: "packageItemAggregate";
  aggregate?: Maybe<PackageItemAggregateFields>;
  nodes: Array<PackageItem>;
};

export type PackageItemAggregateBoolExp = {
  count?: InputMaybe<PackageItemAggregateBoolExpCount>;
};

export type PackageItemAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PackageItemSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PackageItemBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_packageitem" */
export type PackageItemAggregateFields = {
  __typename?: "packageItemAggregateFields";
  avg?: Maybe<PackageItemAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<PackageItemMaxFields>;
  min?: Maybe<PackageItemMinFields>;
  stddev?: Maybe<PackageItemStddevFields>;
  stddevPop?: Maybe<PackageItemStddevPopFields>;
  stddevSamp?: Maybe<PackageItemStddevSampFields>;
  sum?: Maybe<PackageItemSumFields>;
  varPop?: Maybe<PackageItemVarPopFields>;
  varSamp?: Maybe<PackageItemVarSampFields>;
  variance?: Maybe<PackageItemVarianceFields>;
};

/** aggregate fields of "resources_packageitem" */
export type PackageItemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PackageItemSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_packageitem" */
export type PackageItemAggregateOrderBy = {
  avg?: InputMaybe<PackageItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PackageItemMaxOrderBy>;
  min?: InputMaybe<PackageItemMinOrderBy>;
  stddev?: InputMaybe<PackageItemStddevOrderBy>;
  stddevPop?: InputMaybe<PackageItemStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PackageItemStddevSampOrderBy>;
  sum?: InputMaybe<PackageItemSumOrderBy>;
  varPop?: InputMaybe<PackageItemVarPopOrderBy>;
  varSamp?: InputMaybe<PackageItemVarSampOrderBy>;
  variance?: InputMaybe<PackageItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_packageitem" */
export type PackageItemArrRelInsertInput = {
  data: Array<PackageItemInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PackageItemOnConflict>;
};

/** aggregate avg on columns */
export type PackageItemAvgFields = {
  __typename?: "packageItemAvgFields";
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_packageitem" */
export type PackageItemAvgOrderBy = {
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_packageitem". All fields are combined with a logical 'AND'. */
export type PackageItemBoolExp = {
  _and?: InputMaybe<Array<PackageItemBoolExp>>;
  _not?: InputMaybe<PackageItemBoolExp>;
  _or?: InputMaybe<Array<PackageItemBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  dollarCreditAmount?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  itemAmount?: InputMaybe<IntComparisonExp>;
  itemType?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  package?: InputMaybe<PackageBoolExp>;
  packageId?: InputMaybe<BigintComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<BigintComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  serviceMenuItemId?: InputMaybe<BigintComparisonExp>;
  serviceProduct?: InputMaybe<MedspaServiceProductBoolExp>;
  serviceProductId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "resources_packageitem" */
export enum PackageItemConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesPackageitemPkey = "resources_packageitem_pkey",
}

/** input type for incrementing numeric columns in table "resources_packageitem" */
export type PackageItemIncInput = {
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  itemAmount?: InputMaybe<Scalars["Int"]["input"]>;
  packageId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "resources_packageitem" */
export type PackageItemInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  itemAmount?: InputMaybe<Scalars["Int"]["input"]>;
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  package?: InputMaybe<PackageObjRelInsertInput>;
  packageId?: InputMaybe<Scalars["bigint"]["input"]>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProduct?: InputMaybe<MedspaServiceProductObjRelInsertInput>;
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type PackageItemMaxFields = {
  __typename?: "packageItemMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  itemAmount?: Maybe<Scalars["Int"]["output"]>;
  itemType?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  packageId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "resources_packageitem" */
export type PackageItemMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  itemType?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PackageItemMinFields = {
  __typename?: "packageItemMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  itemAmount?: Maybe<Scalars["Int"]["output"]>;
  itemType?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  packageId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "resources_packageitem" */
export type PackageItemMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  itemType?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_packageitem" */
export type PackageItemMutationResponse = {
  __typename?: "packageItemMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<PackageItem>;
};

/** input type for inserting object relation for remote table "resources_packageitem" */
export type PackageItemObjRelInsertInput = {
  data: PackageItemInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PackageItemOnConflict>;
};

/** on_conflict condition type for table "resources_packageitem" */
export type PackageItemOnConflict = {
  constraint: PackageItemConstraint;
  updateColumns?: Array<PackageItemUpdateColumn>;
  where?: InputMaybe<PackageItemBoolExp>;
};

/** Ordering options when selecting data from "resources_packageitem". */
export type PackageItemOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  itemType?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  package?: InputMaybe<PackageOrderBy>;
  packageId?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProduct?: InputMaybe<MedspaServiceProductOrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_packageitem */
export type PackageItemPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_packageitem" */
export enum PackageItemSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DollarCreditAmount = "dollarCreditAmount",
  /** column name */
  Id = "id",
  /** column name */
  ItemAmount = "itemAmount",
  /** column name */
  ItemType = "itemType",
  /** column name */
  Modified = "modified",
  /** column name */
  PackageId = "packageId",
  /** column name */
  ProductId = "productId",
  /** column name */
  ServiceMenuItemId = "serviceMenuItemId",
  /** column name */
  ServiceProductId = "serviceProductId",
}

/** input type for updating data in table "resources_packageitem" */
export type PackageItemSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  itemAmount?: InputMaybe<Scalars["Int"]["input"]>;
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  packageId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type PackageItemStddevFields = {
  __typename?: "packageItemStddevFields";
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_packageitem" */
export type PackageItemStddevOrderBy = {
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type PackageItemStddevPopFields = {
  __typename?: "packageItemStddevPopFields";
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_packageitem" */
export type PackageItemStddevPopOrderBy = {
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type PackageItemStddevSampFields = {
  __typename?: "packageItemStddevSampFields";
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_packageitem" */
export type PackageItemStddevSampOrderBy = {
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "packageItem" */
export type PackageItemStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PackageItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PackageItemStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dollarCreditAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  itemAmount?: InputMaybe<Scalars["Int"]["input"]>;
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  packageId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type PackageItemSumFields = {
  __typename?: "packageItemSumFields";
  dollarCreditAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  itemAmount?: Maybe<Scalars["Int"]["output"]>;
  packageId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "resources_packageitem" */
export type PackageItemSumOrderBy = {
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_packageitem" */
export enum PackageItemUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DollarCreditAmount = "dollarCreditAmount",
  /** column name */
  Id = "id",
  /** column name */
  ItemAmount = "itemAmount",
  /** column name */
  ItemType = "itemType",
  /** column name */
  Modified = "modified",
  /** column name */
  PackageId = "packageId",
  /** column name */
  ProductId = "productId",
  /** column name */
  ServiceMenuItemId = "serviceMenuItemId",
  /** column name */
  ServiceProductId = "serviceProductId",
}

export type PackageItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PackageItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PackageItemSetInput>;
  /** filter the rows which have to be updated */
  where: PackageItemBoolExp;
};

/** aggregate varPop on columns */
export type PackageItemVarPopFields = {
  __typename?: "packageItemVarPopFields";
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_packageitem" */
export type PackageItemVarPopOrderBy = {
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type PackageItemVarSampFields = {
  __typename?: "packageItemVarSampFields";
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_packageitem" */
export type PackageItemVarSampOrderBy = {
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PackageItemVarianceFields = {
  __typename?: "packageItemVarianceFields";
  dollarCreditAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  itemAmount?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_packageitem" */
export type PackageItemVarianceOrderBy = {
  dollarCreditAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemAmount?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "visits_packageline" */
export type PackageLine = {
  __typename?: "packageLine";
  amount: Scalars["numeric"]["output"];
  created: Scalars["timestamptz"]["output"];
  creditIssued: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  package: Package;
  packageId: Scalars["bigint"]["output"];
  price: Scalars["numeric"]["output"];
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** An object relationship */
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  walletTransactionSources: Array<WalletTransactionSourceTmp>;
  /** An aggregate relationship */
  walletTransactionSourcesAggregate: WalletTransactionSourceTmpAggregate;
};

/** columns and relationships of "visits_packageline" */
export type PackageLineWalletTransactionSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** columns and relationships of "visits_packageline" */
export type PackageLineWalletTransactionSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** aggregated selection of "visits_packageline" */
export type PackageLineAggregate = {
  __typename?: "packageLineAggregate";
  aggregate?: Maybe<PackageLineAggregateFields>;
  nodes: Array<PackageLine>;
};

export type PackageLineAggregateBoolExp = {
  bool_and?: InputMaybe<PackageLineAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<PackageLineAggregateBoolExpBool_Or>;
  count?: InputMaybe<PackageLineAggregateBoolExpCount>;
};

export type PackageLineAggregateBoolExpBool_And = {
  arguments: PackageLineSelectColumnPackageLineAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PackageLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PackageLineAggregateBoolExpBool_Or = {
  arguments: PackageLineSelectColumnPackageLineAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PackageLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PackageLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PackageLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PackageLineBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_packageline" */
export type PackageLineAggregateFields = {
  __typename?: "packageLineAggregateFields";
  avg?: Maybe<PackageLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<PackageLineMaxFields>;
  min?: Maybe<PackageLineMinFields>;
  stddev?: Maybe<PackageLineStddevFields>;
  stddevPop?: Maybe<PackageLineStddevPopFields>;
  stddevSamp?: Maybe<PackageLineStddevSampFields>;
  sum?: Maybe<PackageLineSumFields>;
  varPop?: Maybe<PackageLineVarPopFields>;
  varSamp?: Maybe<PackageLineVarSampFields>;
  variance?: Maybe<PackageLineVarianceFields>;
};

/** aggregate fields of "visits_packageline" */
export type PackageLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PackageLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_packageline" */
export type PackageLineAggregateOrderBy = {
  avg?: InputMaybe<PackageLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PackageLineMaxOrderBy>;
  min?: InputMaybe<PackageLineMinOrderBy>;
  stddev?: InputMaybe<PackageLineStddevOrderBy>;
  stddevPop?: InputMaybe<PackageLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PackageLineStddevSampOrderBy>;
  sum?: InputMaybe<PackageLineSumOrderBy>;
  varPop?: InputMaybe<PackageLineVarPopOrderBy>;
  varSamp?: InputMaybe<PackageLineVarSampOrderBy>;
  variance?: InputMaybe<PackageLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_packageline" */
export type PackageLineArrRelInsertInput = {
  data: Array<PackageLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PackageLineOnConflict>;
};

/** aggregate avg on columns */
export type PackageLineAvgFields = {
  __typename?: "packageLineAvgFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_packageline" */
export type PackageLineAvgOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_packageline". All fields are combined with a logical 'AND'. */
export type PackageLineBoolExp = {
  _and?: InputMaybe<Array<PackageLineBoolExp>>;
  _not?: InputMaybe<PackageLineBoolExp>;
  _or?: InputMaybe<Array<PackageLineBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  creditIssued?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  package?: InputMaybe<PackageBoolExp>;
  packageId?: InputMaybe<BigintComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  tax?: InputMaybe<NumericComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateBoolExp>;
};

/** unique or primary key constraints on table "visits_packageline" */
export enum PackageLineConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsPackagelinePkey = "visits_packageline_pkey",
}

/** input type for incrementing numeric columns in table "visits_packageline" */
export type PackageLineIncInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  packageId?: InputMaybe<Scalars["bigint"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_packageline" */
export type PackageLineInsertInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  creditIssued?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  package?: InputMaybe<PackageObjRelInsertInput>;
  packageId?: InputMaybe<Scalars["bigint"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpArrRelInsertInput>;
};

/** aggregate max on columns */
export type PackageLineMaxFields = {
  __typename?: "packageLineMaxFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  packageId?: Maybe<Scalars["bigint"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_packageline" */
export type PackageLineMaxOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PackageLineMinFields = {
  __typename?: "packageLineMinFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  packageId?: Maybe<Scalars["bigint"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_packageline" */
export type PackageLineMinOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_packageline" */
export type PackageLineMutationResponse = {
  __typename?: "packageLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<PackageLine>;
};

/** input type for inserting object relation for remote table "visits_packageline" */
export type PackageLineObjRelInsertInput = {
  data: PackageLineInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PackageLineOnConflict>;
};

/** on_conflict condition type for table "visits_packageline" */
export type PackageLineOnConflict = {
  constraint: PackageLineConstraint;
  updateColumns?: Array<PackageLineUpdateColumn>;
  where?: InputMaybe<PackageLineBoolExp>;
};

/** Ordering options when selecting data from "visits_packageline". */
export type PackageLineOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  creditIssued?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  package?: InputMaybe<PackageOrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateOrderBy>;
};

/** primary key columns input for table: visits_packageline */
export type PackageLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_packageline" */
export enum PackageLineSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  Created = "created",
  /** column name */
  CreditIssued = "creditIssued",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  PackageId = "packageId",
  /** column name */
  Price = "price",
  /** column name */
  Tax = "tax",
  /** column name */
  VisitId = "visitId",
}

/** select "packageLineAggregateBoolExpBool_andArgumentsColumns" columns of table "visits_packageline" */
export enum PackageLineSelectColumnPackageLineAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  CreditIssued = "creditIssued",
}

/** select "packageLineAggregateBoolExpBool_orArgumentsColumns" columns of table "visits_packageline" */
export enum PackageLineSelectColumnPackageLineAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  CreditIssued = "creditIssued",
}

/** input type for updating data in table "visits_packageline" */
export type PackageLineSetInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  creditIssued?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  packageId?: InputMaybe<Scalars["bigint"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type PackageLineStddevFields = {
  __typename?: "packageLineStddevFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_packageline" */
export type PackageLineStddevOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type PackageLineStddevPopFields = {
  __typename?: "packageLineStddevPopFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_packageline" */
export type PackageLineStddevPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type PackageLineStddevSampFields = {
  __typename?: "packageLineStddevSampFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_packageline" */
export type PackageLineStddevSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "packageLine" */
export type PackageLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PackageLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PackageLineStreamCursorValueInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  creditIssued?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  packageId?: InputMaybe<Scalars["bigint"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type PackageLineSumFields = {
  __typename?: "packageLineSumFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  packageId?: Maybe<Scalars["bigint"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_packageline" */
export type PackageLineSumOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_packageline" */
export enum PackageLineUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  Created = "created",
  /** column name */
  CreditIssued = "creditIssued",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  PackageId = "packageId",
  /** column name */
  Price = "price",
  /** column name */
  Tax = "tax",
  /** column name */
  VisitId = "visitId",
}

export type PackageLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PackageLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PackageLineSetInput>;
  /** filter the rows which have to be updated */
  where: PackageLineBoolExp;
};

/** aggregate varPop on columns */
export type PackageLineVarPopFields = {
  __typename?: "packageLineVarPopFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_packageline" */
export type PackageLineVarPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type PackageLineVarSampFields = {
  __typename?: "packageLineVarSampFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_packageline" */
export type PackageLineVarSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PackageLineVarianceFields = {
  __typename?: "packageLineVarianceFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  packageId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_packageline" */
export type PackageLineVarianceOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  packageId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type PackageMaxFields = {
  __typename?: "packageMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["date"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  packageName?: Maybe<Scalars["String"]["output"]>;
  packagePrice?: Maybe<Scalars["numeric"]["output"]>;
  packageSavings?: Maybe<Scalars["numeric"]["output"]>;
  startDate?: Maybe<Scalars["date"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  thumbnail?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "resources_package" */
export type PackageMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packageName?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  thumbnail?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PackageMinFields = {
  __typename?: "packageMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["date"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  packageName?: Maybe<Scalars["String"]["output"]>;
  packagePrice?: Maybe<Scalars["numeric"]["output"]>;
  packageSavings?: Maybe<Scalars["numeric"]["output"]>;
  startDate?: Maybe<Scalars["date"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  thumbnail?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "resources_package" */
export type PackageMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packageName?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  thumbnail?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_package" */
export type PackageMutationResponse = {
  __typename?: "packageMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Package>;
};

/** input type for inserting object relation for remote table "resources_package" */
export type PackageObjRelInsertInput = {
  data: PackageInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PackageOnConflict>;
};

/** on_conflict condition type for table "resources_package" */
export type PackageOnConflict = {
  constraint: PackageConstraint;
  updateColumns?: Array<PackageUpdateColumn>;
  where?: InputMaybe<PackageBoolExp>;
};

/** Ordering options when selecting data from "resources_package". */
export type PackageOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isOnlinePurchaseEnabled?: InputMaybe<OrderBy>;
  isPackageCurrentlyInOnlineStore?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  packageItemsAggregate?: InputMaybe<PackageItemAggregateOrderBy>;
  packageName?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  thumbnail?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_package */
export type PackagePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_package" */
export enum PackageSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  EndDate = "endDate",
  /** column name */
  Id = "id",
  /** column name */
  IsOnlinePurchaseEnabled = "isOnlinePurchaseEnabled",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Order = "order",
  /** column name */
  PackageName = "packageName",
  /** column name */
  PackagePrice = "packagePrice",
  /** column name */
  PackageSavings = "packageSavings",
  /** column name */
  StartDate = "startDate",
  /** column name */
  Tax = "tax",
  /** column name */
  Thumbnail = "thumbnail",
}

/** select "packageAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_package" */
export enum PackageSelectColumnPackageAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsOnlinePurchaseEnabled = "isOnlinePurchaseEnabled",
}

/** select "packageAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_package" */
export enum PackageSelectColumnPackageAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsOnlinePurchaseEnabled = "isOnlinePurchaseEnabled",
}

/** input type for updating data in table "resources_package" */
export type PackageSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isOnlinePurchaseEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  packageName?: InputMaybe<Scalars["String"]["input"]>;
  packagePrice?: InputMaybe<Scalars["numeric"]["input"]>;
  packageSavings?: InputMaybe<Scalars["numeric"]["input"]>;
  startDate?: InputMaybe<Scalars["date"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type PackageStddevFields = {
  __typename?: "packageStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  packagePrice?: Maybe<Scalars["Float"]["output"]>;
  packageSavings?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_package" */
export type PackageStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type PackageStddevPopFields = {
  __typename?: "packageStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  packagePrice?: Maybe<Scalars["Float"]["output"]>;
  packageSavings?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_package" */
export type PackageStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type PackageStddevSampFields = {
  __typename?: "packageStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  packagePrice?: Maybe<Scalars["Float"]["output"]>;
  packageSavings?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_package" */
export type PackageStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "package" */
export type PackageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PackageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PackageStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isOnlinePurchaseEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  packageName?: InputMaybe<Scalars["String"]["input"]>;
  packagePrice?: InputMaybe<Scalars["numeric"]["input"]>;
  packageSavings?: InputMaybe<Scalars["numeric"]["input"]>;
  startDate?: InputMaybe<Scalars["date"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type PackageSumFields = {
  __typename?: "packageSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  packagePrice?: Maybe<Scalars["numeric"]["output"]>;
  packageSavings?: Maybe<Scalars["numeric"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "resources_package" */
export type PackageSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_package" */
export enum PackageUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Description = "description",
  /** column name */
  EndDate = "endDate",
  /** column name */
  Id = "id",
  /** column name */
  IsOnlinePurchaseEnabled = "isOnlinePurchaseEnabled",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Order = "order",
  /** column name */
  PackageName = "packageName",
  /** column name */
  PackagePrice = "packagePrice",
  /** column name */
  PackageSavings = "packageSavings",
  /** column name */
  StartDate = "startDate",
  /** column name */
  Tax = "tax",
  /** column name */
  Thumbnail = "thumbnail",
}

export type PackageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PackageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PackageSetInput>;
  /** filter the rows which have to be updated */
  where: PackageBoolExp;
};

/** aggregate varPop on columns */
export type PackageVarPopFields = {
  __typename?: "packageVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  packagePrice?: Maybe<Scalars["Float"]["output"]>;
  packageSavings?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_package" */
export type PackageVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type PackageVarSampFields = {
  __typename?: "packageVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  packagePrice?: Maybe<Scalars["Float"]["output"]>;
  packageSavings?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_package" */
export type PackageVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PackageVarianceFields = {
  __typename?: "packageVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  packagePrice?: Maybe<Scalars["Float"]["output"]>;
  packageSavings?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_package" */
export type PackageVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the packages should be displayed on the UI (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  packagePrice?: InputMaybe<OrderBy>;
  packageSavings?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** columns and relationships of "payments_payment" */
export type Payment = {
  __typename?: "payment";
  additionalFeeAmount: Scalars["numeric"]["output"];
  amount: Scalars["numeric"]["output"];
  cardRefundBlocked: Scalars["Boolean"]["output"];
  chargeType: Scalars["String"]["output"];
  /** An object relationship */
  client: Client;
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  declineCode: Scalars["String"]["output"];
  errorCode: Scalars["String"]["output"];
  feeAmount: Scalars["numeric"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice: Invoice;
  invoiceId: Scalars["bigint"]["output"];
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  paymentIntentId: Scalars["String"]["output"];
  /** An array relationship */
  refunds: Array<Refund>;
  /** An aggregate relationship */
  refundsAggregate: RefundAggregate;
  refundsInfo?: Maybe<PaymentAdditionalInfoType>;
  status: Scalars["String"]["output"];
  /** An object relationship */
  walletTransaction?: Maybe<WalletTransaction>;
};

/** columns and relationships of "payments_payment" */
export type PaymentRefundsArgs = {
  distinctOn?: InputMaybe<Array<RefundSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RefundOrderBy>>;
  where?: InputMaybe<RefundBoolExp>;
};

/** columns and relationships of "payments_payment" */
export type PaymentRefundsAggregateArgs = {
  distinctOn?: InputMaybe<Array<RefundSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RefundOrderBy>>;
  where?: InputMaybe<RefundBoolExp>;
};

/** aggregated selection of "payments_payment" */
export type PaymentAggregate = {
  __typename?: "paymentAggregate";
  aggregate?: Maybe<PaymentAggregateFields>;
  nodes: Array<Payment>;
};

export type PaymentAggregateBoolExp = {
  bool_and?: InputMaybe<PaymentAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<PaymentAggregateBoolExpBool_Or>;
  count?: InputMaybe<PaymentAggregateBoolExpCount>;
};

export type PaymentAggregateBoolExpBool_And = {
  arguments: PaymentSelectColumnPaymentAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PaymentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PaymentAggregateBoolExpBool_Or = {
  arguments: PaymentSelectColumnPaymentAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PaymentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PaymentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PaymentSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<PaymentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "payments_payment" */
export type PaymentAggregateFields = {
  __typename?: "paymentAggregateFields";
  avg?: Maybe<PaymentAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<PaymentMaxFields>;
  min?: Maybe<PaymentMinFields>;
  stddev?: Maybe<PaymentStddevFields>;
  stddevPop?: Maybe<PaymentStddevPopFields>;
  stddevSamp?: Maybe<PaymentStddevSampFields>;
  sum?: Maybe<PaymentSumFields>;
  varPop?: Maybe<PaymentVarPopFields>;
  varSamp?: Maybe<PaymentVarSampFields>;
  variance?: Maybe<PaymentVarianceFields>;
};

/** aggregate fields of "payments_payment" */
export type PaymentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "payments_payment" */
export type PaymentAggregateOrderBy = {
  avg?: InputMaybe<PaymentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PaymentMaxOrderBy>;
  min?: InputMaybe<PaymentMinOrderBy>;
  stddev?: InputMaybe<PaymentStddevOrderBy>;
  stddevPop?: InputMaybe<PaymentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PaymentStddevSampOrderBy>;
  sum?: InputMaybe<PaymentSumOrderBy>;
  varPop?: InputMaybe<PaymentVarPopOrderBy>;
  varSamp?: InputMaybe<PaymentVarSampOrderBy>;
  variance?: InputMaybe<PaymentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "payments_payment" */
export type PaymentArrRelInsertInput = {
  data: Array<PaymentInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentOnConflict>;
};

/** aggregate avg on columns */
export type PaymentAvgFields = {
  __typename?: "paymentAvgFields";
  additionalFeeAmount?: Maybe<Scalars["Float"]["output"]>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  feeAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "payments_payment" */
export type PaymentAvgOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "payments_payment". All fields are combined with a logical 'AND'. */
export type PaymentBoolExp = {
  _and?: InputMaybe<Array<PaymentBoolExp>>;
  _not?: InputMaybe<PaymentBoolExp>;
  _or?: InputMaybe<Array<PaymentBoolExp>>;
  additionalFeeAmount?: InputMaybe<NumericComparisonExp>;
  amount?: InputMaybe<NumericComparisonExp>;
  cardRefundBlocked?: InputMaybe<BooleanComparisonExp>;
  chargeType?: InputMaybe<StringComparisonExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  declineCode?: InputMaybe<StringComparisonExp>;
  errorCode?: InputMaybe<StringComparisonExp>;
  feeAmount?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  layerfiId?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  paymentIntentId?: InputMaybe<StringComparisonExp>;
  refunds?: InputMaybe<RefundBoolExp>;
  refundsAggregate?: InputMaybe<RefundAggregateBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
  walletTransaction?: InputMaybe<WalletTransactionBoolExp>;
};

/** unique or primary key constraints on table "payments_payment" */
export enum PaymentConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentsPaymentPkey = "payments_payment_pkey",
}

/** input type for incrementing numeric columns in table "payments_payment" */
export type PaymentIncInput = {
  additionalFeeAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  feeAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "payments_payment" */
export type PaymentInsertInput = {
  additionalFeeAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  cardRefundBlocked?: InputMaybe<Scalars["Boolean"]["input"]>;
  chargeType?: InputMaybe<Scalars["String"]["input"]>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  declineCode?: InputMaybe<Scalars["String"]["input"]>;
  errorCode?: InputMaybe<Scalars["String"]["input"]>;
  feeAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]["input"]>;
  refunds?: InputMaybe<RefundArrRelInsertInput>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  walletTransaction?: InputMaybe<WalletTransactionObjRelInsertInput>;
};

/** aggregate max on columns */
export type PaymentMaxFields = {
  __typename?: "paymentMaxFields";
  additionalFeeAmount?: Maybe<Scalars["numeric"]["output"]>;
  amount?: Maybe<Scalars["numeric"]["output"]>;
  chargeType?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  declineCode?: Maybe<Scalars["String"]["output"]>;
  errorCode?: Maybe<Scalars["String"]["output"]>;
  feeAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  paymentIntentId?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "payments_payment" */
export type PaymentMaxOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  chargeType?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  declineCode?: InputMaybe<OrderBy>;
  errorCode?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  paymentIntentId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PaymentMinFields = {
  __typename?: "paymentMinFields";
  additionalFeeAmount?: Maybe<Scalars["numeric"]["output"]>;
  amount?: Maybe<Scalars["numeric"]["output"]>;
  chargeType?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  declineCode?: Maybe<Scalars["String"]["output"]>;
  errorCode?: Maybe<Scalars["String"]["output"]>;
  feeAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  paymentIntentId?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "payments_payment" */
export type PaymentMinOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  chargeType?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  declineCode?: InputMaybe<OrderBy>;
  errorCode?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  paymentIntentId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "payments_payment" */
export type PaymentMutationResponse = {
  __typename?: "paymentMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Payment>;
};

/** input type for inserting object relation for remote table "payments_payment" */
export type PaymentObjRelInsertInput = {
  data: PaymentInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentOnConflict>;
};

/** on_conflict condition type for table "payments_payment" */
export type PaymentOnConflict = {
  constraint: PaymentConstraint;
  updateColumns?: Array<PaymentUpdateColumn>;
  where?: InputMaybe<PaymentBoolExp>;
};

/** Ordering options when selecting data from "payments_payment". */
export type PaymentOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  cardRefundBlocked?: InputMaybe<OrderBy>;
  chargeType?: InputMaybe<OrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  declineCode?: InputMaybe<OrderBy>;
  errorCode?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  layerfiId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  paymentIntentId?: InputMaybe<OrderBy>;
  refundsAggregate?: InputMaybe<RefundAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  walletTransaction?: InputMaybe<WalletTransactionOrderBy>;
};

/** primary key columns input for table: payments_payment */
export type PaymentPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "payments_payment" */
export enum PaymentSelectColumn {
  /** column name */
  AdditionalFeeAmount = "additionalFeeAmount",
  /** column name */
  Amount = "amount",
  /** column name */
  CardRefundBlocked = "cardRefundBlocked",
  /** column name */
  ChargeType = "chargeType",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DeclineCode = "declineCode",
  /** column name */
  ErrorCode = "errorCode",
  /** column name */
  FeeAmount = "feeAmount",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  LayerfiId = "layerfiId",
  /** column name */
  Modified = "modified",
  /** column name */
  PaymentIntentId = "paymentIntentId",
  /** column name */
  Status = "status",
}

/** select "paymentAggregateBoolExpBool_andArgumentsColumns" columns of table "payments_payment" */
export enum PaymentSelectColumnPaymentAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  CardRefundBlocked = "cardRefundBlocked",
}

/** select "paymentAggregateBoolExpBool_orArgumentsColumns" columns of table "payments_payment" */
export enum PaymentSelectColumnPaymentAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  CardRefundBlocked = "cardRefundBlocked",
}

/** input type for updating data in table "payments_payment" */
export type PaymentSetInput = {
  additionalFeeAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  cardRefundBlocked?: InputMaybe<Scalars["Boolean"]["input"]>;
  chargeType?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  declineCode?: InputMaybe<Scalars["String"]["input"]>;
  errorCode?: InputMaybe<Scalars["String"]["input"]>;
  feeAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type PaymentStddevFields = {
  __typename?: "paymentStddevFields";
  additionalFeeAmount?: Maybe<Scalars["Float"]["output"]>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  feeAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "payments_payment" */
export type PaymentStddevOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type PaymentStddevPopFields = {
  __typename?: "paymentStddevPopFields";
  additionalFeeAmount?: Maybe<Scalars["Float"]["output"]>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  feeAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "payments_payment" */
export type PaymentStddevPopOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type PaymentStddevSampFields = {
  __typename?: "paymentStddevSampFields";
  additionalFeeAmount?: Maybe<Scalars["Float"]["output"]>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  feeAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "payments_payment" */
export type PaymentStddevSampOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "payment" */
export type PaymentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentStreamCursorValueInput = {
  additionalFeeAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  cardRefundBlocked?: InputMaybe<Scalars["Boolean"]["input"]>;
  chargeType?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  declineCode?: InputMaybe<Scalars["String"]["input"]>;
  errorCode?: InputMaybe<Scalars["String"]["input"]>;
  feeAmount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type PaymentSumFields = {
  __typename?: "paymentSumFields";
  additionalFeeAmount?: Maybe<Scalars["numeric"]["output"]>;
  amount?: Maybe<Scalars["numeric"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  feeAmount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "payments_payment" */
export type PaymentSumOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
};

/** update columns of table "payments_payment" */
export enum PaymentUpdateColumn {
  /** column name */
  AdditionalFeeAmount = "additionalFeeAmount",
  /** column name */
  Amount = "amount",
  /** column name */
  CardRefundBlocked = "cardRefundBlocked",
  /** column name */
  ChargeType = "chargeType",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  DeclineCode = "declineCode",
  /** column name */
  ErrorCode = "errorCode",
  /** column name */
  FeeAmount = "feeAmount",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  LayerfiId = "layerfiId",
  /** column name */
  Modified = "modified",
  /** column name */
  PaymentIntentId = "paymentIntentId",
  /** column name */
  Status = "status",
}

export type PaymentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentBoolExp;
};

/** aggregate varPop on columns */
export type PaymentVarPopFields = {
  __typename?: "paymentVarPopFields";
  additionalFeeAmount?: Maybe<Scalars["Float"]["output"]>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  feeAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "payments_payment" */
export type PaymentVarPopOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type PaymentVarSampFields = {
  __typename?: "paymentVarSampFields";
  additionalFeeAmount?: Maybe<Scalars["Float"]["output"]>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  feeAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "payments_payment" */
export type PaymentVarSampOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PaymentVarianceFields = {
  __typename?: "paymentVarianceFields";
  additionalFeeAmount?: Maybe<Scalars["Float"]["output"]>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  feeAmount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "payments_payment" */
export type PaymentVarianceOrderBy = {
  additionalFeeAmount?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "visits_photo" */
export type Photo = {
  __typename?: "photo";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  editMetadata: Scalars["jsonb"]["output"];
  id: Scalars["bigint"]["output"];
  isProcessed: Scalars["Boolean"]["output"];
  isUploaded: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  original?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  uploadedBy?: Maybe<User>;
  uploadedByClientId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  visit: Visit;
  visitId: Scalars["bigint"]["output"];
};

/** columns and relationships of "visits_photo" */
export type PhotoEditMetadataArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "visits_photo" */
export type PhotoAggregate = {
  __typename?: "photoAggregate";
  aggregate?: Maybe<PhotoAggregateFields>;
  nodes: Array<Photo>;
};

/** aggregate fields of "visits_photo" */
export type PhotoAggregateFields = {
  __typename?: "photoAggregateFields";
  avg?: Maybe<PhotoAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<PhotoMaxFields>;
  min?: Maybe<PhotoMinFields>;
  stddev?: Maybe<PhotoStddevFields>;
  stddevPop?: Maybe<PhotoStddevPopFields>;
  stddevSamp?: Maybe<PhotoStddevSampFields>;
  sum?: Maybe<PhotoSumFields>;
  varPop?: Maybe<PhotoVarPopFields>;
  varSamp?: Maybe<PhotoVarSampFields>;
  variance?: Maybe<PhotoVarianceFields>;
};

/** aggregate fields of "visits_photo" */
export type PhotoAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PhotoSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PhotoAppendInput = {
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate avg on columns */
export type PhotoAvgFields = {
  __typename?: "photoAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "visits_photo". All fields are combined with a logical 'AND'. */
export type PhotoBoolExp = {
  _and?: InputMaybe<Array<PhotoBoolExp>>;
  _not?: InputMaybe<PhotoBoolExp>;
  _or?: InputMaybe<Array<PhotoBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  editMetadata?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isProcessed?: InputMaybe<BooleanComparisonExp>;
  isUploaded?: InputMaybe<BooleanComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  original?: InputMaybe<StringComparisonExp>;
  photo?: InputMaybe<StringComparisonExp>;
  uploadedBy?: InputMaybe<UserBoolExp>;
  uploadedByClientId?: InputMaybe<BigintComparisonExp>;
  uploadedById?: InputMaybe<BigintComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_photo" */
export enum PhotoConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsPhotoPkey = "visits_photo_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PhotoDeleteAtPathInput = {
  editMetadata?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PhotoDeleteElemInput = {
  editMetadata?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PhotoDeleteKeyInput = {
  editMetadata?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "visits_photo" */
export type PhotoIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedByClientId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_photo" */
export type PhotoInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isProcessed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  original?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  uploadedBy?: InputMaybe<UserObjRelInsertInput>;
  uploadedByClientId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type PhotoMaxFields = {
  __typename?: "photoMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  original?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregate min on columns */
export type PhotoMinFields = {
  __typename?: "photoMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  original?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** response of any mutation on the table "visits_photo" */
export type PhotoMutationResponse = {
  __typename?: "photoMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Photo>;
};

/** on_conflict condition type for table "visits_photo" */
export type PhotoOnConflict = {
  constraint: PhotoConstraint;
  updateColumns?: Array<PhotoUpdateColumn>;
  where?: InputMaybe<PhotoBoolExp>;
};

/** Ordering options when selecting data from "visits_photo". */
export type PhotoOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  editMetadata?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isProcessed?: InputMaybe<OrderBy>;
  isUploaded?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  original?: InputMaybe<OrderBy>;
  photo?: InputMaybe<OrderBy>;
  uploadedBy?: InputMaybe<UserOrderBy>;
  uploadedByClientId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_photo */
export type PhotoPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PhotoPrependInput = {
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "visits_photo" */
export enum PhotoSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  EditMetadata = "editMetadata",
  /** column name */
  Id = "id",
  /** column name */
  IsProcessed = "isProcessed",
  /** column name */
  IsUploaded = "isUploaded",
  /** column name */
  Label = "label",
  /** column name */
  Modified = "modified",
  /** column name */
  Original = "original",
  /** column name */
  Photo = "photo",
  /** column name */
  UploadedByClientId = "uploadedByClientId",
  /** column name */
  UploadedById = "uploadedById",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "visits_photo" */
export type PhotoSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isProcessed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  original?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  uploadedByClientId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type PhotoStddevFields = {
  __typename?: "photoStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type PhotoStddevPopFields = {
  __typename?: "photoStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type PhotoStddevSampFields = {
  __typename?: "photoStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "photo" */
export type PhotoStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PhotoStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PhotoStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  editMetadata?: InputMaybe<Scalars["jsonb"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isProcessed?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  original?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  uploadedByClientId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type PhotoSumFields = {
  __typename?: "photoSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "visits_photo" */
export enum PhotoUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  EditMetadata = "editMetadata",
  /** column name */
  Id = "id",
  /** column name */
  IsProcessed = "isProcessed",
  /** column name */
  IsUploaded = "isUploaded",
  /** column name */
  Label = "label",
  /** column name */
  Modified = "modified",
  /** column name */
  Original = "original",
  /** column name */
  Photo = "photo",
  /** column name */
  UploadedByClientId = "uploadedByClientId",
  /** column name */
  UploadedById = "uploadedById",
  /** column name */
  VisitId = "visitId",
}

export type PhotoUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PhotoAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<PhotoDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<PhotoDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<PhotoDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PhotoIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PhotoPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PhotoSetInput>;
  /** filter the rows which have to be updated */
  where: PhotoBoolExp;
};

/** aggregate varPop on columns */
export type PhotoVarPopFields = {
  __typename?: "photoVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type PhotoVarSampFields = {
  __typename?: "photoVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type PhotoVarianceFields = {
  __typename?: "photoVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  uploadedByClientId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "resources_product" */
export type Product = {
  __typename?: "product";
  created: Scalars["timestamptz"]["output"];
  deleted: Scalars["Boolean"]["output"];
  /** An array relationship */
  discountRetailProducts: Array<DiscountRetailProducts>;
  /** An aggregate relationship */
  discountRetailProductsAggregate: DiscountRetailProductsAggregate;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["numeric"]["output"];
  /** An array relationship */
  productLines: Array<ProductLine>;
  /** An aggregate relationship */
  productLinesAggregate: ProductLineAggregate;
  productType: Scalars["String"]["output"];
  /** An array relationship */
  serviceMenuItemProducts: Array<MedspaServiceMenuItemOldProducts>;
  /** An aggregate relationship */
  serviceMenuItemProductsAggregate: MedspaServiceMenuItemOldProductsAggregate;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  unit?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "resources_product" */
export type ProductDiscountRetailProductsArgs = {
  distinctOn?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountRetailProductsOrderBy>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

/** columns and relationships of "resources_product" */
export type ProductDiscountRetailProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountRetailProductsOrderBy>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

/** columns and relationships of "resources_product" */
export type ProductProductLinesArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

/** columns and relationships of "resources_product" */
export type ProductProductLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

/** columns and relationships of "resources_product" */
export type ProductServiceMenuItemProductsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOldProductsOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

/** columns and relationships of "resources_product" */
export type ProductServiceMenuItemProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOldProductsOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

/** aggregated selection of "resources_product" */
export type ProductAggregate = {
  __typename?: "productAggregate";
  aggregate?: Maybe<ProductAggregateFields>;
  nodes: Array<Product>;
};

export type ProductAggregateBoolExp = {
  bool_and?: InputMaybe<ProductAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ProductAggregateBoolExpBool_Or>;
  count?: InputMaybe<ProductAggregateBoolExpCount>;
};

export type ProductAggregateBoolExpBool_And = {
  arguments: ProductSelectColumnProductAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProductAggregateBoolExpBool_Or = {
  arguments: ProductSelectColumnProductAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProductAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProductSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ProductBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_product" */
export type ProductAggregateFields = {
  __typename?: "productAggregateFields";
  avg?: Maybe<ProductAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ProductMaxFields>;
  min?: Maybe<ProductMinFields>;
  stddev?: Maybe<ProductStddevFields>;
  stddevPop?: Maybe<ProductStddevPopFields>;
  stddevSamp?: Maybe<ProductStddevSampFields>;
  sum?: Maybe<ProductSumFields>;
  varPop?: Maybe<ProductVarPopFields>;
  varSamp?: Maybe<ProductVarSampFields>;
  variance?: Maybe<ProductVarianceFields>;
};

/** aggregate fields of "resources_product" */
export type ProductAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProductSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_product" */
export type ProductAggregateOrderBy = {
  avg?: InputMaybe<ProductAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProductMaxOrderBy>;
  min?: InputMaybe<ProductMinOrderBy>;
  stddev?: InputMaybe<ProductStddevOrderBy>;
  stddevPop?: InputMaybe<ProductStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ProductStddevSampOrderBy>;
  sum?: InputMaybe<ProductSumOrderBy>;
  varPop?: InputMaybe<ProductVarPopOrderBy>;
  varSamp?: InputMaybe<ProductVarSampOrderBy>;
  variance?: InputMaybe<ProductVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_product" */
export type ProductArrRelInsertInput = {
  data: Array<ProductInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProductOnConflict>;
};

/** aggregate avg on columns */
export type ProductAvgFields = {
  __typename?: "productAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_product" */
export type ProductAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_product". All fields are combined with a logical 'AND'. */
export type ProductBoolExp = {
  _and?: InputMaybe<Array<ProductBoolExp>>;
  _not?: InputMaybe<ProductBoolExp>;
  _or?: InputMaybe<Array<ProductBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  discountRetailProducts?: InputMaybe<DiscountRetailProductsBoolExp>;
  discountRetailProductsAggregate?: InputMaybe<DiscountRetailProductsAggregateBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  productLines?: InputMaybe<ProductLineBoolExp>;
  productLinesAggregate?: InputMaybe<ProductLineAggregateBoolExp>;
  productType?: InputMaybe<StringComparisonExp>;
  serviceMenuItemProducts?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
  serviceMenuItemProductsAggregate?: InputMaybe<MedspaServiceMenuItemOldProductsAggregateBoolExp>;
  tax?: InputMaybe<NumericComparisonExp>;
  unit?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "resources_product" */
export enum ProductConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesProductPkey = "resources_product_pkey",
}

/** input type for incrementing numeric columns in table "resources_product" */
export type ProductIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
};

/** input type for inserting data into table "resources_product" */
export type ProductInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  discountRetailProducts?: InputMaybe<DiscountRetailProductsArrRelInsertInput>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  productLines?: InputMaybe<ProductLineArrRelInsertInput>;
  productType?: InputMaybe<Scalars["String"]["input"]>;
  serviceMenuItemProducts?: InputMaybe<MedspaServiceMenuItemOldProductsArrRelInsertInput>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  unit?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "visits_productline" */
export type ProductLine = {
  __typename?: "productLine";
  amount: Scalars["numeric"]["output"];
  amountFreeOfCharge: Scalars["numeric"]["output"];
  created: Scalars["timestamptz"]["output"];
  fundFromWallet: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  medspa?: Maybe<Medspa>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  price: Scalars["numeric"]["output"];
  priceSummary?: Maybe<ProductLinePricesType>;
  /** An object relationship */
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  /** An object relationship */
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "visits_productline" */
export type ProductLineAggregate = {
  __typename?: "productLineAggregate";
  aggregate?: Maybe<ProductLineAggregateFields>;
  nodes: Array<ProductLine>;
};

export type ProductLineAggregateBoolExp = {
  bool_and?: InputMaybe<ProductLineAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ProductLineAggregateBoolExpBool_Or>;
  count?: InputMaybe<ProductLineAggregateBoolExpCount>;
};

export type ProductLineAggregateBoolExpBool_And = {
  arguments: ProductLineSelectColumnProductLineAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ProductLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProductLineAggregateBoolExpBool_Or = {
  arguments: ProductLineSelectColumnProductLineAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ProductLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProductLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProductLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ProductLineBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_productline" */
export type ProductLineAggregateFields = {
  __typename?: "productLineAggregateFields";
  avg?: Maybe<ProductLineAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ProductLineMaxFields>;
  min?: Maybe<ProductLineMinFields>;
  stddev?: Maybe<ProductLineStddevFields>;
  stddevPop?: Maybe<ProductLineStddevPopFields>;
  stddevSamp?: Maybe<ProductLineStddevSampFields>;
  sum?: Maybe<ProductLineSumFields>;
  varPop?: Maybe<ProductLineVarPopFields>;
  varSamp?: Maybe<ProductLineVarSampFields>;
  variance?: Maybe<ProductLineVarianceFields>;
};

/** aggregate fields of "visits_productline" */
export type ProductLineAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProductLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_productline" */
export type ProductLineAggregateOrderBy = {
  avg?: InputMaybe<ProductLineAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProductLineMaxOrderBy>;
  min?: InputMaybe<ProductLineMinOrderBy>;
  stddev?: InputMaybe<ProductLineStddevOrderBy>;
  stddevPop?: InputMaybe<ProductLineStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ProductLineStddevSampOrderBy>;
  sum?: InputMaybe<ProductLineSumOrderBy>;
  varPop?: InputMaybe<ProductLineVarPopOrderBy>;
  varSamp?: InputMaybe<ProductLineVarSampOrderBy>;
  variance?: InputMaybe<ProductLineVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_productline" */
export type ProductLineArrRelInsertInput = {
  data: Array<ProductLineInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProductLineOnConflict>;
};

/** aggregate avg on columns */
export type ProductLineAvgFields = {
  __typename?: "productLineAvgFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_productline" */
export type ProductLineAvgOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_productline". All fields are combined with a logical 'AND'. */
export type ProductLineBoolExp = {
  _and?: InputMaybe<Array<ProductLineBoolExp>>;
  _not?: InputMaybe<ProductLineBoolExp>;
  _or?: InputMaybe<Array<ProductLineBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  amountFreeOfCharge?: InputMaybe<NumericComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  fundFromWallet?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<BigintComparisonExp>;
  tax?: InputMaybe<NumericComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_productline" */
export enum ProductLineConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsProductlinePkey = "visits_productline_pkey",
}

/** input type for incrementing numeric columns in table "visits_productline" */
export type ProductLineIncInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_productline" */
export type ProductLineInsertInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type ProductLineMaxFields = {
  __typename?: "productLineMaxFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_productline" */
export type ProductLineMaxOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductLineMinFields = {
  __typename?: "productLineMinFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_productline" */
export type ProductLineMinOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_productline" */
export type ProductLineMutationResponse = {
  __typename?: "productLineMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ProductLine>;
};

/** input type for inserting object relation for remote table "visits_productline" */
export type ProductLineObjRelInsertInput = {
  data: ProductLineInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProductLineOnConflict>;
};

/** on_conflict condition type for table "visits_productline" */
export type ProductLineOnConflict = {
  constraint: ProductLineConstraint;
  updateColumns?: Array<ProductLineUpdateColumn>;
  where?: InputMaybe<ProductLineBoolExp>;
};

/** Ordering options when selecting data from "visits_productline". */
export type ProductLineOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  fundFromWallet?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_productline */
export type ProductLinePkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_productline" */
export enum ProductLineSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  AmountFreeOfCharge = "amountFreeOfCharge",
  /** column name */
  Created = "created",
  /** column name */
  FundFromWallet = "fundFromWallet",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  ProductId = "productId",
  /** column name */
  Tax = "tax",
  /** column name */
  VisitId = "visitId",
}

/** select "productLineAggregateBoolExpBool_andArgumentsColumns" columns of table "visits_productline" */
export enum ProductLineSelectColumnProductLineAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  FundFromWallet = "fundFromWallet",
}

/** select "productLineAggregateBoolExpBool_orArgumentsColumns" columns of table "visits_productline" */
export enum ProductLineSelectColumnProductLineAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  FundFromWallet = "fundFromWallet",
}

/** input type for updating data in table "visits_productline" */
export type ProductLineSetInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type ProductLineStddevFields = {
  __typename?: "productLineStddevFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_productline" */
export type ProductLineStddevOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProductLineStddevPopFields = {
  __typename?: "productLineStddevPopFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_productline" */
export type ProductLineStddevPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProductLineStddevSampFields = {
  __typename?: "productLineStddevSampFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_productline" */
export type ProductLineStddevSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "productLine" */
export type ProductLineStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProductLineStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductLineStreamCursorValueInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  amountFreeOfCharge?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fundFromWallet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type ProductLineSumFields = {
  __typename?: "productLineSumFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_productline" */
export type ProductLineSumOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_productline" */
export enum ProductLineUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  AmountFreeOfCharge = "amountFreeOfCharge",
  /** column name */
  Created = "created",
  /** column name */
  FundFromWallet = "fundFromWallet",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  ProductId = "productId",
  /** column name */
  Tax = "tax",
  /** column name */
  VisitId = "visitId",
}

export type ProductLineUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProductLineIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductLineSetInput>;
  /** filter the rows which have to be updated */
  where: ProductLineBoolExp;
};

/** aggregate varPop on columns */
export type ProductLineVarPopFields = {
  __typename?: "productLineVarPopFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_productline" */
export type ProductLineVarPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProductLineVarSampFields = {
  __typename?: "productLineVarSampFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_productline" */
export type ProductLineVarSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProductLineVarianceFields = {
  __typename?: "productLineVarianceFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  amountFreeOfCharge?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_productline" */
export type ProductLineVarianceOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountFreeOfCharge?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type ProductMaxFields = {
  __typename?: "productMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  productType?: Maybe<Scalars["String"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  unit?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "resources_product" */
export type ProductMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productType?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  unit?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductMinFields = {
  __typename?: "productMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  productType?: Maybe<Scalars["String"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
  unit?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "resources_product" */
export type ProductMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productType?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  unit?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_product" */
export type ProductMutationResponse = {
  __typename?: "productMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "resources_product" */
export type ProductObjRelInsertInput = {
  data: ProductInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProductOnConflict>;
};

/** on_conflict condition type for table "resources_product" */
export type ProductOnConflict = {
  constraint: ProductConstraint;
  updateColumns?: Array<ProductUpdateColumn>;
  where?: InputMaybe<ProductBoolExp>;
};

/** Ordering options when selecting data from "resources_product". */
export type ProductOrderBy = {
  created?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  discountRetailProductsAggregate?: InputMaybe<DiscountRetailProductsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  productLinesAggregate?: InputMaybe<ProductLineAggregateOrderBy>;
  productType?: InputMaybe<OrderBy>;
  serviceMenuItemProductsAggregate?: InputMaybe<MedspaServiceMenuItemOldProductsAggregateOrderBy>;
  tax?: InputMaybe<OrderBy>;
  unit?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_product */
export type ProductPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_product" */
export enum ProductSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  ProductType = "productType",
  /** column name */
  Tax = "tax",
  /** column name */
  Unit = "unit",
}

/** select "productAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_product" */
export enum ProductSelectColumnProductAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** select "productAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_product" */
export enum ProductSelectColumnProductAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** input type for updating data in table "resources_product" */
export type ProductSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  productType?: InputMaybe<Scalars["String"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  unit?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ProductStddevFields = {
  __typename?: "productStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_product" */
export type ProductStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProductStddevPopFields = {
  __typename?: "productStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_product" */
export type ProductStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProductStddevSampFields = {
  __typename?: "productStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_product" */
export type ProductStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "product" */
export type ProductStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProductStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["numeric"]["input"]>;
  productType?: InputMaybe<Scalars["String"]["input"]>;
  tax?: InputMaybe<Scalars["numeric"]["input"]>;
  unit?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ProductSumFields = {
  __typename?: "productSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  price?: Maybe<Scalars["numeric"]["output"]>;
  tax?: Maybe<Scalars["numeric"]["output"]>;
};

/** order by sum() on columns of table "resources_product" */
export type ProductSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_product" */
export enum ProductUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  ProductType = "productType",
  /** column name */
  Tax = "tax",
  /** column name */
  Unit = "unit",
}

export type ProductUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProductIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductSetInput>;
  /** filter the rows which have to be updated */
  where: ProductBoolExp;
};

/** aggregate varPop on columns */
export type ProductVarPopFields = {
  __typename?: "productVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_product" */
export type ProductVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProductVarSampFields = {
  __typename?: "productVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_product" */
export type ProductVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProductVarianceFields = {
  __typename?: "productVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  tax?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_product" */
export type ProductVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

export type ProviderNotRequireDocumentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
  predicate: IntComparisonExp;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "utils_address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "utils_address" */
  addressAggregate: AddressAggregate;
  /** fetch data from the table: "utils_address" using primary key columns */
  addressByPk?: Maybe<Address>;
  /** fetch data from the table: "administrative_division" */
  administrativeDivision: Array<AdministrativeDivision>;
  /** fetch aggregated fields from the table: "administrative_division" */
  administrativeDivisionAggregate: AdministrativeDivisionAggregate;
  /** fetch data from the table: "administrative_division" using primary key columns */
  administrativeDivisionByPk?: Maybe<AdministrativeDivision>;
  /** fetch data from the table: "adverse_reaction" */
  adverseReaction: Array<AdverseReaction>;
  /** fetch aggregated fields from the table: "adverse_reaction" */
  adverseReactionAggregate: AdverseReactionAggregate;
  /** fetch data from the table: "adverse_reaction" using primary key columns */
  adverseReactionByPk?: Maybe<AdverseReaction>;
  /** fetch data from the table: "adverse_reaction_template" */
  adverseReactionTemplate: Array<AdverseReactionTemplate>;
  /** fetch aggregated fields from the table: "adverse_reaction_template" */
  adverseReactionTemplateAggregate: AdverseReactionTemplateAggregate;
  /** fetch data from the table: "adverse_reaction_template" using primary key columns */
  adverseReactionTemplateByPk?: Maybe<AdverseReactionTemplate>;
  appointmentPublicInfo?: Maybe<AppointmentPublicInfoType>;
  /** fetch data from the table: "scheduling_availabilityevent" */
  availabilityEvent: Array<AvailabilityEvent>;
  /** fetch aggregated fields from the table: "scheduling_availabilityevent" */
  availabilityEventAggregate: AvailabilityEventAggregate;
  /** fetch data from the table: "scheduling_availabilityevent" using primary key columns */
  availabilityEventByPk?: Maybe<AvailabilityEvent>;
  availableResources?: Maybe<AvailableResourcesType>;
  availableTimeSlots?: Maybe<AvailableTimeSlotsType>;
  /** fetch data from the table: "resources_careform" */
  careForm: Array<CareForm>;
  /** fetch aggregated fields from the table: "resources_careform" */
  careFormAggregate: CareFormAggregate;
  /** fetch data from the table: "resources_careform" using primary key columns */
  careFormByPk?: Maybe<CareForm>;
  /** An array relationship */
  careFormServiceMenuItems: Array<CareFormServiceMenuItems>;
  /** An aggregate relationship */
  careFormServiceMenuItemsAggregate: CareFormServiceMenuItemsAggregate;
  /** fetch data from the table: "resources_careform_service_menu_items" using primary key columns */
  careFormServiceMenuItemsByPk?: Maybe<CareFormServiceMenuItems>;
  chartSignatureAdditionalInfo?: Maybe<ChartSignatureType>;
  chartsToReview?: Maybe<VisitsToReviewType>;
  cherryAccount?: Maybe<ClientCherryAccountType>;
  cherryApiKey?: Maybe<Scalars["String"]["output"]>;
  /** fetch data from the table: "medspas_client" */
  client: Array<Client>;
  /** fetch data from the table: "medspas_clientaccesstoken" */
  clientAccessToken: Array<ClientAccessToken>;
  /** fetch aggregated fields from the table: "medspas_clientaccesstoken" */
  clientAccessTokenAggregate: ClientAccessTokenAggregate;
  /** fetch data from the table: "medspas_clientaccesstoken" using primary key columns */
  clientAccessTokenByPk?: Maybe<ClientAccessToken>;
  clientAdditionalInfo?: Maybe<ClientType>;
  /** fetch aggregated fields from the table: "medspas_client" */
  clientAggregate: ClientAggregate;
  /** fetch data from the table: "medspas_client" using primary key columns */
  clientByPk?: Maybe<Client>;
  /** fetch data from the table: "client_membership" */
  clientMembership: Array<ClientMembership>;
  clientMembershipAdditionalInfo?: Maybe<ClientMembershipType>;
  /** fetch aggregated fields from the table: "client_membership" */
  clientMembershipAggregate: ClientMembershipAggregate;
  /** fetch data from the table: "client_membership" using primary key columns */
  clientMembershipByPk?: Maybe<ClientMembership>;
  clientStripeInfo?: Maybe<ClientStripeType>;
  clientWallet?: Maybe<ClientWalletType>;
  /** fetch data from the table: "compliance_task" */
  complianceTask: Array<ComplianceTask>;
  /** fetch aggregated fields from the table: "compliance_task" */
  complianceTaskAggregate: ComplianceTaskAggregate;
  /** fetch data from the table: "compliance_task" using primary key columns */
  complianceTaskByPk?: Maybe<ComplianceTask>;
  /** fetch data from the table: "compliance_task_document" */
  complianceTaskDocument: Array<ComplianceTaskDocument>;
  /** fetch aggregated fields from the table: "compliance_task_document" */
  complianceTaskDocumentAggregate: ComplianceTaskDocumentAggregate;
  /** fetch data from the table: "compliance_task_document" using primary key columns */
  complianceTaskDocumentByPk?: Maybe<ComplianceTaskDocument>;
  complianceTaskDocumentSignedUrl?: Maybe<Scalars["String"]["output"]>;
  /** fetch data from the table: "compliance_task_template" */
  complianceTaskTemplate: Array<ComplianceTaskTemplate>;
  /** fetch aggregated fields from the table: "compliance_task_template" */
  complianceTaskTemplateAggregate: ComplianceTaskTemplateAggregate;
  /** fetch data from the table: "compliance_task_template" using primary key columns */
  complianceTaskTemplateByPk?: Maybe<ComplianceTaskTemplate>;
  /** fetch data from the table: "compliant_note" */
  compliantNote: Array<CompliantNote>;
  /** fetch aggregated fields from the table: "compliant_note" */
  compliantNoteAggregate: CompliantNoteAggregate;
  /** fetch data from the table: "compliant_note" using primary key columns */
  compliantNoteByPk?: Maybe<CompliantNote>;
  /** fetch data from the table: "compliant_note_template" */
  compliantNoteTemplate: Array<CompliantNoteTemplate>;
  /** fetch aggregated fields from the table: "compliant_note_template" */
  compliantNoteTemplateAggregate: CompliantNoteTemplateAggregate;
  /** fetch data from the table: "compliant_note_template" using primary key columns */
  compliantNoteTemplateByPk?: Maybe<CompliantNoteTemplate>;
  /** fetch data from the table: "resources_consentform" */
  consentForm: Array<ConsentForm>;
  /** fetch aggregated fields from the table: "resources_consentform" */
  consentFormAggregate: ConsentFormAggregate;
  /** fetch data from the table: "resources_consentform" using primary key columns */
  consentFormByPk?: Maybe<ConsentForm>;
  /** An array relationship */
  consentFormServiceMenuItems: Array<ConsentFormServiceMenuItems>;
  /** An aggregate relationship */
  consentFormServiceMenuItemsAggregate: ConsentFormServiceMenuItemsAggregate;
  /** fetch data from the table: "resources_consentform_service_menu_items" using primary key columns */
  consentFormServiceMenuItemsByPk?: Maybe<ConsentFormServiceMenuItems>;
  /** fetch data from the table: "medspas_consentformsignature" */
  consentFormSignature: Array<ConsentFormSignature>;
  consentFormSignatureAdditionalInfo?: Maybe<ConsentFormSignatureType>;
  /** fetch aggregated fields from the table: "medspas_consentformsignature" */
  consentFormSignatureAggregate: ConsentFormSignatureAggregate;
  /** fetch data from the table: "medspas_consentformsignature" using primary key columns */
  consentFormSignatureByPk?: Maybe<ConsentFormSignature>;
  /** fetch data from the table: "deposit_line" */
  depositLine: Array<DepositLine>;
  /** fetch aggregated fields from the table: "deposit_line" */
  depositLineAggregate: DepositLineAggregate;
  /** fetch data from the table: "deposit_line" using primary key columns */
  depositLineByPk?: Maybe<DepositLine>;
  /** fetch data from the table: "visits_diagram" */
  diagram: Array<Diagram>;
  diagramAdditionalInfo?: Maybe<DiagramType>;
  /** fetch aggregated fields from the table: "visits_diagram" */
  diagramAggregate: DiagramAggregate;
  /** fetch data from the table: "visits_diagram" using primary key columns */
  diagramByPk?: Maybe<Diagram>;
  /** fetch data from the table: "resources_diagramtemplate" */
  diagramTemplate: Array<DiagramTemplate>;
  diagramTemplateAdditionalInfo?: Maybe<DiagramTemplateType>;
  /** fetch aggregated fields from the table: "resources_diagramtemplate" */
  diagramTemplateAggregate: DiagramTemplateAggregate;
  /** fetch data from the table: "resources_diagramtemplate" using primary key columns */
  diagramTemplateByPk?: Maybe<DiagramTemplate>;
  /** fetch data from the table: "resources_discount" */
  discount: Array<Discount>;
  /** fetch aggregated fields from the table: "resources_discount" */
  discountAggregate: DiscountAggregate;
  /** fetch data from the table: "resources_discount" using primary key columns */
  discountByPk?: Maybe<Discount>;
  /** An array relationship */
  discountLine: Array<DiscountLine>;
  /** An aggregate relationship */
  discountLineAggregate: DiscountLineAggregate;
  /** fetch data from the table: "visits_discountline" using primary key columns */
  discountLineByPk?: Maybe<DiscountLine>;
  discountLineTotalPrices?: Maybe<DiscountLinePricesType>;
  discountLineTotalPricesWithoutWalletCredits?: Maybe<DiscountLinePricesWithoutWalletCreditsType>;
  /** An array relationship */
  discountRetailProducts: Array<DiscountRetailProducts>;
  /** An aggregate relationship */
  discountRetailProductsAggregate: DiscountRetailProductsAggregate;
  /** fetch data from the table: "discount_product" using primary key columns */
  discountRetailProductsByPk?: Maybe<DiscountRetailProducts>;
  /** An array relationship */
  discountServiceMenuItems: Array<DiscountServiceMenuItems>;
  /** An aggregate relationship */
  discountServiceMenuItemsAggregate: DiscountServiceMenuItemsAggregate;
  /** fetch data from the table: "resources_discount_service_menu_items" using primary key columns */
  discountServiceMenuItemsByPk?: Maybe<DiscountServiceMenuItems>;
  /** An array relationship */
  discountServiceProducts: Array<DiscountServiceProducts>;
  /** An aggregate relationship */
  discountServiceProductsAggregate: DiscountServiceProductsAggregate;
  /** fetch data from the table: "resources_discount_service_products" using primary key columns */
  discountServiceProductsByPk?: Maybe<DiscountServiceProducts>;
  documentCountByMedspa?: Maybe<Array<Maybe<MedSpaDocumentsType>>>;
  experiment?: Maybe<ExperimentType>;
  /** fetch data from the table: "feature_permission" */
  featurePermission: Array<FeaturePermission>;
  /** fetch aggregated fields from the table: "feature_permission" */
  featurePermissionAggregate: FeaturePermissionAggregate;
  /** fetch data from the table: "feature_permission" using primary key columns */
  featurePermissionByPk?: Maybe<FeaturePermission>;
  /** fetch data from the table: "resources_fee" */
  fee: Array<Fee>;
  /** fetch aggregated fields from the table: "resources_fee" */
  feeAggregate: FeeAggregate;
  /** fetch data from the table: "resources_fee" using primary key columns */
  feeByPk?: Maybe<Fee>;
  /** fetch data from the table: "visits_feeline" */
  feeLine: Array<FeeLine>;
  /** fetch aggregated fields from the table: "visits_feeline" */
  feeLineAggregate: FeeLineAggregate;
  /** fetch data from the table: "visits_feeline" using primary key columns */
  feeLineByPk?: Maybe<FeeLine>;
  feeLineTotalPrices?: Maybe<FeeLinePricesType>;
  feeLineTotalPricesWithoutWalletCredits?: Maybe<FeeLinePricesWithoutWalletCreditsType>;
  /** fetch data from the table: "forms_form" */
  form: Array<Form>;
  /** fetch aggregated fields from the table: "forms_form" */
  formAggregate: FormAggregate;
  /** fetch data from the table: "forms_form" using primary key columns */
  formByPk?: Maybe<Form>;
  /** fetch data from the table: "forms_form_service_menu_items" */
  formServiceMenuItem: Array<FormServiceMenuItem>;
  /** fetch aggregated fields from the table: "forms_form_service_menu_items" */
  formServiceMenuItemAggregate: FormServiceMenuItemAggregate;
  /** fetch data from the table: "forms_form_service_menu_items" using primary key columns */
  formServiceMenuItemByPk?: Maybe<FormServiceMenuItem>;
  /** fetch data from the table: "forms_formsubmission" */
  formSubmission: Array<FormSubmission>;
  /** fetch aggregated fields from the table: "forms_formsubmission" */
  formSubmissionAggregate: FormSubmissionAggregate;
  /** fetch data from the table: "forms_formsubmission" using primary key columns */
  formSubmissionByPk?: Maybe<FormSubmission>;
  /** fetch data from the table: "forms_formsubmissiongfestatuschangelog" */
  formSubmissionGFEStatusChangeLog: Array<FormSubmissionGfeStatusChangeLog>;
  /** fetch aggregated fields from the table: "forms_formsubmissiongfestatuschangelog" */
  formSubmissionGFEStatusChangeLogAggregate: FormSubmissionGfeStatusChangeLogAggregate;
  /** fetch data from the table: "forms_formsubmissiongfestatuschangelog" using primary key columns */
  formSubmissionGFEStatusChangeLogByPk?: Maybe<FormSubmissionGfeStatusChangeLog>;
  formSubmissionVersionAdditionalInfo?: Maybe<FormSubmissionVersionType>;
  /** fetch data from the table: "forms_form_medspa_service_offerings" */
  formsFormMedspaServiceOfferings: Array<FormsFormMedspaServiceOfferings>;
  /** fetch aggregated fields from the table: "forms_form_medspa_service_offerings" */
  formsFormMedspaServiceOfferingsAggregate: FormsFormMedspaServiceOfferingsAggregate;
  /** fetch data from the table: "forms_form_medspa_service_offerings" using primary key columns */
  formsFormMedspaServiceOfferingsByPk?: Maybe<FormsFormMedspaServiceOfferings>;
  /** fetch data from the table: "forms_formsubmissionversion" */
  formsFormsubmissionversion: Array<FormsFormsubmissionversion>;
  /** fetch aggregated fields from the table: "forms_formsubmissionversion" */
  formsFormsubmissionversionAggregate: FormsFormsubmissionversionAggregate;
  /** fetch data from the table: "forms_formsubmissionversion" using primary key columns */
  formsFormsubmissionversionByPk?: Maybe<FormsFormsubmissionversion>;
  getLayerBusinessAccessToken?: Maybe<GetLayerBusinessAccessToken>;
  /** fetch data from the table: "gfe_diagnosis" */
  gfeDiagnosis: Array<GfeDiagnosis>;
  /** fetch aggregated fields from the table: "gfe_diagnosis" */
  gfeDiagnosisAggregate: GfeDiagnosisAggregate;
  /** fetch data from the table: "gfe_diagnosis" using primary key columns */
  gfeDiagnosisByPk?: Maybe<GfeDiagnosis>;
  /** fetch data from the table: "gfe_review_diagnosis" */
  gfeReviewDiagnosis: Array<GfeReviewDiagnosis>;
  /** fetch aggregated fields from the table: "gfe_review_diagnosis" */
  gfeReviewDiagnosisAggregate: GfeReviewDiagnosisAggregate;
  /** fetch data from the table: "gfe_review_diagnosis" using primary key columns */
  gfeReviewDiagnosisByPk?: Maybe<GfeReviewDiagnosis>;
  /** fetch data from the table: "gfe_review_request" */
  gfeReviewRequest: Array<GfeReviewRequest>;
  /** fetch aggregated fields from the table: "gfe_review_request" */
  gfeReviewRequestAggregate: GfeReviewRequestAggregate;
  /** fetch data from the table: "gfe_review_request" using primary key columns */
  gfeReviewRequestByPk?: Maybe<GfeReviewRequest>;
  /** fetch data from the table: "gfe_review_submission" */
  gfeReviewSubmission: Array<GfeReviewSubmission>;
  /** fetch aggregated fields from the table: "gfe_review_submission" */
  gfeReviewSubmissionAggregate: GfeReviewSubmissionAggregate;
  /** fetch data from the table: "gfe_review_submission" using primary key columns */
  gfeReviewSubmissionByPk?: Maybe<GfeReviewSubmission>;
  /** fetch data from the table: "gfe_service_product_indication" */
  gfeServiceProductIndication: Array<GfeServiceProductIndication>;
  /** fetch aggregated fields from the table: "gfe_service_product_indication" */
  gfeServiceProductIndicationAggregate: GfeServiceProductIndicationAggregate;
  /** fetch data from the table: "gfe_service_product_indication" using primary key columns */
  gfeServiceProductIndicationByPk?: Maybe<GfeServiceProductIndication>;
  /** fetch data from the table: "gfe_synchronous_review_document" */
  gfeSynchronousReviewDocument: Array<GfeSynchronousReviewDocument>;
  /** fetch aggregated fields from the table: "gfe_synchronous_review_document" */
  gfeSynchronousReviewDocumentAggregate: GfeSynchronousReviewDocumentAggregate;
  /** fetch data from the table: "gfe_synchronous_review_document" using primary key columns */
  gfeSynchronousReviewDocumentByPk?: Maybe<GfeSynchronousReviewDocument>;
  gfeSynchronousReviewDocumentFileUrl?: Maybe<Scalars["String"]["output"]>;
  /** fetch data from the table: "gfe_treatment_indication" */
  gfeTreatmentIndication: Array<GfeTreatmentIndication>;
  /** fetch aggregated fields from the table: "gfe_treatment_indication" */
  gfeTreatmentIndicationAggregate: GfeTreatmentIndicationAggregate;
  /** fetch data from the table: "gfe_treatment_indication" using primary key columns */
  gfeTreatmentIndicationByPk?: Maybe<GfeTreatmentIndication>;
  /** fetch data from the table: "gift_builder_banner" */
  giftBuilderBanner: Array<GiftBuilderBanner>;
  /** fetch aggregated fields from the table: "gift_builder_banner" */
  giftBuilderBannerAggregate: GiftBuilderBannerAggregate;
  /** fetch data from the table: "gift_builder_banner" using primary key columns */
  giftBuilderBannerByPk?: Maybe<GiftBuilderBanner>;
  highlevelBaseUrl?: Maybe<Scalars["String"]["output"]>;
  highlevelGetContactById?: Maybe<ContactType>;
  highlevelMessagesByConversationId?: Maybe<MessagesType>;
  highlevelSearchConversations?: Maybe<ConversationsType>;
  intakeFormPhotos?: Maybe<Array<Maybe<FormSubmissionVersionType>>>;
  /** fetch data from the table: "inventory_lot" */
  inventoryLot: Array<InventoryLot>;
  /** fetch aggregated fields from the table: "inventory_lot" */
  inventoryLotAggregate: InventoryLotAggregate;
  /** fetch data from the table: "inventory_lot" using primary key columns */
  inventoryLotByPk?: Maybe<InventoryLot>;
  /** fetch data from the table: "inventory_transaction" */
  inventoryTransaction: Array<InventoryTransaction>;
  /** fetch aggregated fields from the table: "inventory_transaction" */
  inventoryTransactionAggregate: InventoryTransactionAggregate;
  /** fetch data from the table: "inventory_transaction" using primary key columns */
  inventoryTransactionByPk?: Maybe<InventoryTransaction>;
  /** fetch data from the table: "visits_invoice" */
  invoice: Array<Invoice>;
  invoiceAdditionalInfo?: Maybe<InvoiceAdditionalInfoType>;
  invoiceAdditionalInfoWithoutWalletCredits?: Maybe<InvoiceAdditionalInfoWithoutWalletCreditsType>;
  /** fetch aggregated fields from the table: "visits_invoice" */
  invoiceAggregate: InvoiceAggregate;
  /** fetch data from the table: "visits_invoice" using primary key columns */
  invoiceByPk?: Maybe<Invoice>;
  /** fetch data from the table: "last_gfe_review_treatment_indication" */
  lastGfeReviewTreatmentIndication: Array<LastGfeReviewTreatmentIndication>;
  /** fetch aggregated fields from the table: "last_gfe_review_treatment_indication" */
  lastGfeReviewTreatmentIndicationAggregate: LastGfeReviewTreatmentIndicationAggregate;
  /** fetch data from the table: "marketing_template" */
  marketingTemplate: Array<MarketingTemplate>;
  marketingTemplateAdditionalInfo?: Maybe<MarketingTemplateType>;
  /** fetch aggregated fields from the table: "marketing_template" */
  marketingTemplateAggregate: MarketingTemplateAggregate;
  /** fetch data from the table: "marketing_template" using primary key columns */
  marketingTemplateByPk?: Maybe<MarketingTemplate>;
  /** fetch data from the table: "md_meeting" */
  mdMeeting: Array<MdMeeting>;
  /** fetch aggregated fields from the table: "md_meeting" */
  mdMeetingAggregate: MdMeetingAggregate;
  /** fetch data from the table: "md_meeting_attendees" */
  mdMeetingAttendees: Array<MdMeetingAttendees>;
  /** fetch aggregated fields from the table: "md_meeting_attendees" */
  mdMeetingAttendeesAggregate: MdMeetingAttendeesAggregate;
  /** fetch data from the table: "md_meeting_attendees" using primary key columns */
  mdMeetingAttendeesByPk?: Maybe<MdMeetingAttendees>;
  /** fetch data from the table: "md_meeting" using primary key columns */
  mdMeetingByPk?: Maybe<MdMeeting>;
  /** fetch data from the table: "md_meeting_note" */
  mdMeetingNote: Array<MdMeetingNote>;
  /** fetch aggregated fields from the table: "md_meeting_note" */
  mdMeetingNoteAggregate: MdMeetingNoteAggregate;
  /** fetch data from the table: "md_meeting_note" using primary key columns */
  mdMeetingNoteByPk?: Maybe<MdMeetingNote>;
  /** fetch data from the table: "md_meeting_note_template" */
  mdMeetingNoteTemplate: Array<MdMeetingNoteTemplate>;
  /** fetch aggregated fields from the table: "md_meeting_note_template" */
  mdMeetingNoteTemplateAggregate: MdMeetingNoteTemplateAggregate;
  /** fetch data from the table: "md_meeting_note_template" using primary key columns */
  mdMeetingNoteTemplateByPk?: Maybe<MdMeetingNoteTemplate>;
  /** fetch data from the table: "medspas_medicaldirector" */
  medicalDirector: Array<MedicalDirector>;
  /** fetch aggregated fields from the table: "medspas_medicaldirector" */
  medicalDirectorAggregate: MedicalDirectorAggregate;
  /** fetch data from the table: "medspas_medicaldirector" using primary key columns */
  medicalDirectorByPk?: Maybe<MedicalDirector>;
  /** fetch data from the table: "medspas_medicaldirectorsubscription" */
  medicalDirectorSubscription: Array<MedicalDirectorSubscription>;
  medicalDirectorSubscriptionAdditionalInfo?: Maybe<MedicalDirectorSubscriptionType>;
  /** fetch aggregated fields from the table: "medspas_medicaldirectorsubscription" */
  medicalDirectorSubscriptionAggregate: MedicalDirectorSubscriptionAggregate;
  /** fetch data from the table: "medspas_medicaldirectorsubscription" using primary key columns */
  medicalDirectorSubscriptionByPk?: Maybe<MedicalDirectorSubscription>;
  /** fetch data from the table: "medspas_medspa" */
  medspa: Array<Medspa>;
  medspaAdditionalInfo?: Maybe<MedSpaType>;
  /** fetch aggregated fields from the table: "medspas_medspa" */
  medspaAggregate: MedspaAggregate;
  /** fetch data from the table: "medspas_medspa" using primary key columns */
  medspaByPk?: Maybe<Medspa>;
  medspaComplianceTasks?: Maybe<MedSpaComplianceTasksType>;
  medspaConfigurationAdditionalInfo?: Maybe<MedSpaConfigurationType>;
  medspaPublicAdditionalInfo?: Maybe<MedspaPublicInfoType>;
  /** An array relationship */
  medspaRoomsAppointments: Array<MedspaRoomsAppointments>;
  /** An aggregate relationship */
  medspaRoomsAppointmentsAggregate: MedspaRoomsAppointmentsAggregate;
  /** fetch data from the table: "medspa_rooms_appointments" using primary key columns */
  medspaRoomsAppointmentsByPk?: Maybe<MedspaRoomsAppointments>;
  /** fetch data from the table: "medspa_service_menu_item" */
  medspaServiceMenuItem: Array<MedspaServiceMenuItem>;
  /** fetch aggregated fields from the table: "medspa_service_menu_item" */
  medspaServiceMenuItemAggregate: MedspaServiceMenuItemAggregate;
  /** fetch data from the table: "medspa_service_menu_item" using primary key columns */
  medspaServiceMenuItemByPk?: Maybe<MedspaServiceMenuItem>;
  /** fetch data from the table: "medspa_service_menu_item_medspa_service_offerings" */
  medspaServiceMenuItemMedspaServiceOfferings: Array<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** fetch aggregated fields from the table: "medspa_service_menu_item_medspa_service_offerings" */
  medspaServiceMenuItemMedspaServiceOfferingsAggregate: MedspaServiceMenuItemMedspaServiceOfferingsAggregate;
  /** fetch data from the table: "medspa_service_menu_item_medspa_service_offerings" using primary key columns */
  medspaServiceMenuItemMedspaServiceOfferingsByPk?: Maybe<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** fetch data from the table: "medspa_service_menu_item_old_products" */
  medspaServiceMenuItemOldProducts: Array<MedspaServiceMenuItemOldProducts>;
  /** fetch aggregated fields from the table: "medspa_service_menu_item_old_products" */
  medspaServiceMenuItemOldProductsAggregate: MedspaServiceMenuItemOldProductsAggregate;
  /** fetch data from the table: "medspa_service_menu_item_old_products" using primary key columns */
  medspaServiceMenuItemOldProductsByPk?: Maybe<MedspaServiceMenuItemOldProducts>;
  /** fetch data from the table: "medspa_service_menu_item_providers" */
  medspaServiceMenuItemProviders: Array<MedspaServiceMenuItemProviders>;
  /** fetch aggregated fields from the table: "medspa_service_menu_item_providers" */
  medspaServiceMenuItemProvidersAggregate: MedspaServiceMenuItemProvidersAggregate;
  /** fetch data from the table: "medspa_service_menu_item_providers" using primary key columns */
  medspaServiceMenuItemProvidersByPk?: Maybe<MedspaServiceMenuItemProviders>;
  /** fetch data from the table: "medspa_service_offering" */
  medspaServiceOffering: Array<MedspaServiceOffering>;
  /** fetch aggregated fields from the table: "medspa_service_offering" */
  medspaServiceOfferingAggregate: MedspaServiceOfferingAggregate;
  /** fetch data from the table: "medspa_service_offering" using primary key columns */
  medspaServiceOfferingByPk?: Maybe<MedspaServiceOffering>;
  /** fetch data from the table: "medspa_service_offering_compliance_requirements" */
  medspaServiceOfferingComplianceRequirements: Array<MedspaServiceOfferingComplianceRequirements>;
  /** fetch aggregated fields from the table: "medspa_service_offering_compliance_requirements" */
  medspaServiceOfferingComplianceRequirementsAggregate: MedspaServiceOfferingComplianceRequirementsAggregate;
  /** fetch data from the table: "medspa_service_offering_note_templates" */
  medspaServiceOfferingNoteTemplates: Array<MedspaServiceOfferingNoteTemplates>;
  /** fetch aggregated fields from the table: "medspa_service_offering_note_templates" */
  medspaServiceOfferingNoteTemplatesAggregate: MedspaServiceOfferingNoteTemplatesAggregate;
  /** fetch data from the table: "medspa_service_offering_note_templates" using primary key columns */
  medspaServiceOfferingNoteTemplatesByPk?: Maybe<MedspaServiceOfferingNoteTemplates>;
  /** fetch data from the table: "medspa_service_offering_notes" */
  medspaServiceOfferingNotes: Array<MedspaServiceOfferingNotes>;
  /** fetch aggregated fields from the table: "medspa_service_offering_notes" */
  medspaServiceOfferingNotesAggregate: MedspaServiceOfferingNotesAggregate;
  /** fetch data from the table: "medspa_service_offering_notes" using primary key columns */
  medspaServiceOfferingNotesByPk?: Maybe<MedspaServiceOfferingNotes>;
  /** fetch data from the table: "medspa_service_product" */
  medspaServiceProduct: Array<MedspaServiceProduct>;
  medspaServiceProductAdditionalInfo?: Maybe<MedSpaServiceProductType>;
  /** fetch aggregated fields from the table: "medspa_service_product" */
  medspaServiceProductAggregate: MedspaServiceProductAggregate;
  /** fetch data from the table: "medspa_service_product" using primary key columns */
  medspaServiceProductByPk?: Maybe<MedspaServiceProduct>;
  /** fetch data from the table: "medspa_service_protocol_template" */
  medspaServiceProtocolTemplate: Array<MedspaServiceProtocolTemplate>;
  /** fetch aggregated fields from the table: "medspa_service_protocol_template" */
  medspaServiceProtocolTemplateAggregate: MedspaServiceProtocolTemplateAggregate;
  /** fetch data from the table: "medspa_service_protocol_template" using primary key columns */
  medspaServiceProtocolTemplateByPk?: Maybe<MedspaServiceProtocolTemplate>;
  /** fetch data from the table: "medspa_standing_order_template" */
  medspaStandingOrderTemplate: Array<MedspaStandingOrderTemplate>;
  /** fetch aggregated fields from the table: "medspa_standing_order_template" */
  medspaStandingOrderTemplateAggregate: MedspaStandingOrderTemplateAggregate;
  /** fetch data from the table: "medspa_standing_order_template" using primary key columns */
  medspaStandingOrderTemplateByPk?: Maybe<MedspaStandingOrderTemplate>;
  /** fetch data from the table: "medspa_wallet_credit_type" */
  medspaWalletCreditType: Array<MedspaWalletCreditType>;
  /** fetch aggregated fields from the table: "medspa_wallet_credit_type" */
  medspaWalletCreditTypeAggregate: MedspaWalletCreditTypeAggregate;
  /** fetch data from the table: "medspa_wallet_credit_type" using primary key columns */
  medspaWalletCreditTypeByPk?: Maybe<MedspaWalletCreditType>;
  /** fetch data from the table: "medspas_featureflag" */
  medspasFeatureflag: Array<MedspasFeatureflag>;
  /** fetch aggregated fields from the table: "medspas_featureflag" */
  medspasFeatureflagAggregate: MedspasFeatureflagAggregate;
  /** fetch data from the table: "medspas_featureflag" using primary key columns */
  medspasFeatureflagByPk?: Maybe<MedspasFeatureflag>;
  /** fetch data from the table: "medspas_medicallicense" */
  medspasMedicallicense: Array<MedspasMedicallicense>;
  /** fetch aggregated fields from the table: "medspas_medicallicense" */
  medspasMedicallicenseAggregate: MedspasMedicallicenseAggregate;
  /** fetch data from the table: "medspas_medicallicense" using primary key columns */
  medspasMedicallicenseByPk?: Maybe<MedspasMedicallicense>;
  /** fetch data from the table: "medspas_medspaconfiguration" */
  medspasMedspaconfiguration: Array<MedspasMedspaconfiguration>;
  /** fetch aggregated fields from the table: "medspas_medspaconfiguration" */
  medspasMedspaconfigurationAggregate: MedspasMedspaconfigurationAggregate;
  /** fetch data from the table: "medspas_medspaconfiguration" using primary key columns */
  medspasMedspaconfigurationByPk?: Maybe<MedspasMedspaconfiguration>;
  /** fetch data from the table: "medspas_professionalcorporation" */
  medspasProfessionalcorporation: Array<MedspasProfessionalcorporation>;
  /** fetch aggregated fields from the table: "medspas_professionalcorporation" */
  medspasProfessionalcorporationAggregate: MedspasProfessionalcorporationAggregate;
  /** fetch data from the table: "medspas_professionalcorporation" using primary key columns */
  medspasProfessionalcorporationByPk?: Maybe<MedspasProfessionalcorporation>;
  /** fetch data from the table: "medspas_stripeoutboundpaymentmethod" */
  medspasStripeoutboundpaymentmethod: Array<MedspasStripeoutboundpaymentmethod>;
  /** fetch aggregated fields from the table: "medspas_stripeoutboundpaymentmethod" */
  medspasStripeoutboundpaymentmethodAggregate: MedspasStripeoutboundpaymentmethodAggregate;
  /** fetch data from the table: "medspas_stripeoutboundpaymentmethod" using primary key columns */
  medspasStripeoutboundpaymentmethodByPk?: Maybe<MedspasStripeoutboundpaymentmethod>;
  /** fetch data from the table: "medspas_usermedspa_acquired_medical_licenses" */
  medspasUsermedspaAcquiredMedicalLicenses: Array<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** fetch aggregated fields from the table: "medspas_usermedspa_acquired_medical_licenses" */
  medspasUsermedspaAcquiredMedicalLicensesAggregate: MedspasUsermedspaAcquiredMedicalLicensesAggregate;
  /** fetch data from the table: "medspas_usermedspa_acquired_medical_licenses" using primary key columns */
  medspasUsermedspaAcquiredMedicalLicensesByPk?: Maybe<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** fetch data from the table: "membership" */
  membership: Array<Membership>;
  /** fetch aggregated fields from the table: "membership" */
  membershipAggregate: MembershipAggregate;
  /** fetch data from the table: "membership" using primary key columns */
  membershipByPk?: Maybe<Membership>;
  /** fetch data from the table: "membership_category" */
  membershipCategory: Array<MembershipCategory>;
  /** fetch aggregated fields from the table: "membership_category" */
  membershipCategoryAggregate: MembershipCategoryAggregate;
  /** fetch data from the table: "membership_category" using primary key columns */
  membershipCategoryByPk?: Maybe<MembershipCategory>;
  /** fetch data from the table: "membership_line" */
  membershipLine: Array<MembershipLine>;
  /** fetch aggregated fields from the table: "membership_line" */
  membershipLineAggregate: MembershipLineAggregate;
  /** fetch data from the table: "membership_line" using primary key columns */
  membershipLineByPk?: Maybe<MembershipLine>;
  /** fetch data from the table: "membership_perk" */
  membershipPerk: Array<MembershipPerk>;
  /** fetch aggregated fields from the table: "membership_perk" */
  membershipPerkAggregate: MembershipPerkAggregate;
  /** fetch data from the table: "membership_perk" using primary key columns */
  membershipPerkByPk?: Maybe<MembershipPerk>;
  /** fetch data from the table: "membership_perk_line" */
  membershipPerkLine: Array<MembershipPerkLine>;
  /** fetch aggregated fields from the table: "membership_perk_line" */
  membershipPerkLineAggregate: MembershipPerkLineAggregate;
  /** fetch data from the table: "membership_perk_line" using primary key columns */
  membershipPerkLineByPk?: Maybe<MembershipPerkLine>;
  /** fetch data from the table: "model_permission" */
  modelPermission: Array<ModelPermission>;
  /** fetch aggregated fields from the table: "model_permission" */
  modelPermissionAggregate: ModelPermissionAggregate;
  /** fetch data from the table: "model_permission" using primary key columns */
  modelPermissionByPk?: Maybe<ModelPermission>;
  /** fetch data from the table: "visits_note" */
  note: Array<Note>;
  /** fetch aggregated fields from the table: "visits_note" */
  noteAggregate: NoteAggregate;
  /** fetch data from the table: "visits_note" using primary key columns */
  noteByPk?: Maybe<Note>;
  /** fetch data from the table: "visits_notechangelog" */
  noteChangeLog: Array<NoteChangeLog>;
  /** fetch aggregated fields from the table: "visits_notechangelog" */
  noteChangeLogAggregate: NoteChangeLogAggregate;
  /** fetch data from the table: "visits_notechangelog" using primary key columns */
  noteChangeLogByPk?: Maybe<NoteChangeLog>;
  /** fetch data from the table: "visits_note_form_submissions" */
  noteFormSubmission: Array<NoteFormSubmission>;
  /** fetch aggregated fields from the table: "visits_note_form_submissions" */
  noteFormSubmissionAggregate: NoteFormSubmissionAggregate;
  /** fetch data from the table: "visits_note_form_submissions" using primary key columns */
  noteFormSubmissionByPk?: Maybe<NoteFormSubmission>;
  /** fetch data from the table: "resources_notetemplate" */
  noteTemplate: Array<NoteTemplate>;
  /** fetch aggregated fields from the table: "resources_notetemplate" */
  noteTemplateAggregate: NoteTemplateAggregate;
  /** fetch data from the table: "resources_notetemplate" using primary key columns */
  noteTemplateByPk?: Maybe<NoteTemplate>;
  /** fetch data from the table: "resources_package" */
  package: Array<Package>;
  packageAdditionalInfo?: Maybe<PackageType>;
  /** fetch aggregated fields from the table: "resources_package" */
  packageAggregate: PackageAggregate;
  /** fetch data from the table: "resources_package" using primary key columns */
  packageByPk?: Maybe<Package>;
  /** fetch data from the table: "resources_packageitem" */
  packageItem: Array<PackageItem>;
  /** fetch aggregated fields from the table: "resources_packageitem" */
  packageItemAggregate: PackageItemAggregate;
  /** fetch data from the table: "resources_packageitem" using primary key columns */
  packageItemByPk?: Maybe<PackageItem>;
  /** fetch data from the table: "package_item_line" */
  packageItemLine: Array<PackageItemLine>;
  /** fetch aggregated fields from the table: "package_item_line" */
  packageItemLineAggregate: PackageItemLineAggregate;
  /** fetch data from the table: "package_item_line" using primary key columns */
  packageItemLineByPk?: Maybe<PackageItemLine>;
  /** fetch data from the table: "visits_packageline" */
  packageLine: Array<PackageLine>;
  /** fetch aggregated fields from the table: "visits_packageline" */
  packageLineAggregate: PackageLineAggregate;
  /** fetch data from the table: "visits_packageline" using primary key columns */
  packageLineByPk?: Maybe<PackageLine>;
  /** fetch data from the table: "payments_payment" */
  payment: Array<Payment>;
  paymentAdditionalInfo?: Maybe<PaymentAdditionalInfoType>;
  /** fetch aggregated fields from the table: "payments_payment" */
  paymentAggregate: PaymentAggregate;
  /** fetch data from the table: "payments_payment" using primary key columns */
  paymentByPk?: Maybe<Payment>;
  /** fetch data from the table: "visits_photo" */
  photo: Array<Photo>;
  /** fetch aggregated fields from the table: "visits_photo" */
  photoAggregate: PhotoAggregate;
  /** fetch data from the table: "visits_photo" using primary key columns */
  photoByPk?: Maybe<Photo>;
  /** fetch data from the table: "resources_product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "resources_product" */
  productAggregate: ProductAggregate;
  /** fetch data from the table: "resources_product" using primary key columns */
  productByPk?: Maybe<Product>;
  /** fetch data from the table: "visits_productline" */
  productLine: Array<ProductLine>;
  /** fetch aggregated fields from the table: "visits_productline" */
  productLineAggregate: ProductLineAggregate;
  /** fetch data from the table: "visits_productline" using primary key columns */
  productLineByPk?: Maybe<ProductLine>;
  productLineTotalPrices?: Maybe<ProductLinePricesType>;
  productLineTotalPricesWithoutWalletCredits?: Maybe<ProductLinePricesWithoutWalletCreditsType>;
  /** An array relationship */
  providerNotRequireDocuments: Array<ProviderNotRequireDocuments>;
  /** An aggregate relationship */
  providerNotRequireDocumentsAggregate: ProviderNotRequireDocumentsAggregate;
  /** fetch data from the table: "provider_not_require_documents" using primary key columns */
  providerNotRequireDocumentsByPk?: Maybe<ProviderNotRequireDocuments>;
  /** fetch data from the table: "scheduling_recurring_availability" */
  recurringAvailability: Array<RecurringAvailability>;
  /** fetch aggregated fields from the table: "scheduling_recurring_availability" */
  recurringAvailabilityAggregate: RecurringAvailabilityAggregate;
  /** fetch data from the table: "scheduling_recurring_availability" using primary key columns */
  recurringAvailabilityByPk?: Maybe<RecurringAvailability>;
  /** fetch data from the table: "payments_refund" */
  refund: Array<Refund>;
  /** fetch aggregated fields from the table: "payments_refund" */
  refundAggregate: RefundAggregate;
  /** fetch data from the table: "payments_refund" using primary key columns */
  refundByPk?: Maybe<Refund>;
  /** fetch data from the table: "medspas_report" */
  report: Array<Report>;
  /** fetch aggregated fields from the table: "medspas_report" */
  reportAggregate: ReportAggregate;
  /** fetch data from the table: "medspas_report" using primary key columns */
  reportByPk?: Maybe<Report>;
  /** fetch data from the table: "medspas_reportcategory" */
  reportCategory: Array<ReportCategory>;
  /** fetch aggregated fields from the table: "medspas_reportcategory" */
  reportCategoryAggregate: ReportCategoryAggregate;
  /** fetch data from the table: "medspas_reportcategory" using primary key columns */
  reportCategoryByPk?: Maybe<ReportCategory>;
  /** fetch data from the table: "resources_medspa_room" */
  resourcesMedspaRoom: Array<ResourcesMedspaRoom>;
  /** fetch aggregated fields from the table: "resources_medspa_room" */
  resourcesMedspaRoomAggregate: ResourcesMedspaRoomAggregate;
  /** fetch data from the table: "resources_medspa_room" using primary key columns */
  resourcesMedspaRoomByPk?: Maybe<ResourcesMedspaRoom>;
  /** fetch data from the table: "scheduling_appointment" */
  schedulingAppointment: Array<SchedulingAppointment>;
  /** fetch aggregated fields from the table: "scheduling_appointment" */
  schedulingAppointmentAggregate: SchedulingAppointmentAggregate;
  /** fetch data from the table: "scheduling_appointment" using primary key columns */
  schedulingAppointmentByPk?: Maybe<SchedulingAppointment>;
  /** fetch data from the table: "script_product" */
  scriptProduct: Array<ScriptProduct>;
  /** fetch aggregated fields from the table: "script_product" */
  scriptProductAggregate: ScriptProductAggregate;
  /** fetch data from the table: "script_product" using primary key columns */
  scriptProductByPk?: Maybe<ScriptProduct>;
  /** fetch data from the table: "script_request" */
  scriptRequest: Array<ScriptRequest>;
  /** fetch aggregated fields from the table: "script_request" */
  scriptRequestAggregate: ScriptRequestAggregate;
  /** fetch data from the table: "script_request" using primary key columns */
  scriptRequestByPk?: Maybe<ScriptRequest>;
  /** fetch data from the table: "script_request_status_change_log" */
  scriptRequestStatusChangeLog: Array<ScriptRequestStatusChangeLog>;
  /** fetch aggregated fields from the table: "script_request_status_change_log" */
  scriptRequestStatusChangeLogAggregate: ScriptRequestStatusChangeLogAggregate;
  /** fetch data from the table: "script_request_status_change_log" using primary key columns */
  scriptRequestStatusChangeLogByPk?: Maybe<ScriptRequestStatusChangeLog>;
  /** fetch data from the table: "resources_servicecategory" */
  serviceCategory: Array<ServiceCategory>;
  /** fetch aggregated fields from the table: "resources_servicecategory" */
  serviceCategoryAggregate: ServiceCategoryAggregate;
  /** fetch data from the table: "resources_servicecategory" using primary key columns */
  serviceCategoryByPk?: Maybe<ServiceCategory>;
  /** fetch data from the table: "service_device" */
  serviceDevice: Array<ServiceDevice>;
  /** fetch aggregated fields from the table: "service_device" */
  serviceDeviceAggregate: ServiceDeviceAggregate;
  /** fetch data from the table: "service_device" using primary key columns */
  serviceDeviceByPk?: Maybe<ServiceDevice>;
  /** An array relationship */
  serviceDevicesAppointments: Array<ServiceDevicesAppointments>;
  /** An aggregate relationship */
  serviceDevicesAppointmentsAggregate: ServiceDevicesAppointmentsAggregate;
  /** fetch data from the table: "service_devices_appointments" using primary key columns */
  serviceDevicesAppointmentsByPk?: Maybe<ServiceDevicesAppointments>;
  /** An array relationship */
  serviceDevicesServiceTypes: Array<ServiceDevicesServiceTypes>;
  /** An aggregate relationship */
  serviceDevicesServiceTypesAggregate: ServiceDevicesServiceTypesAggregate;
  /** fetch data from the table: "service_devices_service_types" using primary key columns */
  serviceDevicesServiceTypesByPk?: Maybe<ServiceDevicesServiceTypes>;
  /** fetch data from the table: "service_menu_item_line" */
  serviceMenuItemLine: Array<ServiceMenuItemLine>;
  /** fetch aggregated fields from the table: "service_menu_item_line" */
  serviceMenuItemLineAggregate: ServiceMenuItemLineAggregate;
  /** fetch data from the table: "service_menu_item_line" using primary key columns */
  serviceMenuItemLineByPk?: Maybe<ServiceMenuItemLine>;
  /** An array relationship */
  serviceMenuItemLineStandingOrders: Array<ServiceMenuItemLineStandingOrders>;
  /** An aggregate relationship */
  serviceMenuItemLineStandingOrdersAggregate: ServiceMenuItemLineStandingOrdersAggregate;
  /** fetch data from the table: "service_menu_item_line_standing_orders" using primary key columns */
  serviceMenuItemLineStandingOrdersByPk?: Maybe<ServiceMenuItemLineStandingOrders>;
  serviceMenuItemLineTotalPrices?: Maybe<ServiceMenuItemLinePricesType>;
  serviceMenuItemLineTotalPricesWithoutWalletCredits?: Maybe<ServiceMenuItemLinePricesWithoutWalletCreditsType>;
  /** fetch data from the table: "service_product" */
  serviceProduct: Array<ServiceProduct>;
  /** fetch aggregated fields from the table: "service_product" */
  serviceProductAggregate: ServiceProductAggregate;
  /** fetch data from the table: "service_product" using primary key columns */
  serviceProductByPk?: Maybe<ServiceProduct>;
  /** fetch data from the table: "service_product_charting_note" */
  serviceProductChartingNote: Array<ServiceProductChartingNote>;
  /** fetch aggregated fields from the table: "service_product_charting_note" */
  serviceProductChartingNoteAggregate: ServiceProductChartingNoteAggregate;
  /** fetch data from the table: "service_product_charting_note" using primary key columns */
  serviceProductChartingNoteByPk?: Maybe<ServiceProductChartingNote>;
  /** fetch data from the table: "service_product_line" */
  serviceProductLine: Array<ServiceProductLine>;
  /** fetch aggregated fields from the table: "service_product_line" */
  serviceProductLineAggregate: ServiceProductLineAggregate;
  /** fetch data from the table: "service_product_line" using primary key columns */
  serviceProductLineByPk?: Maybe<ServiceProductLine>;
  /** fetch data from the table: "service_product_type" */
  serviceProductType: Array<ServiceProductType>;
  /** fetch aggregated fields from the table: "service_product_type" */
  serviceProductTypeAggregate: ServiceProductTypeAggregate;
  /** fetch data from the table: "service_product_type" using primary key columns */
  serviceProductTypeByPk?: Maybe<ServiceProductType>;
  /** fetch data from the table: "service_product_usage" */
  serviceProductUsage: Array<ServiceProductUsage>;
  /** fetch aggregated fields from the table: "service_product_usage" */
  serviceProductUsageAggregate: ServiceProductUsageAggregate;
  /** fetch data from the table: "service_product_usage" using primary key columns */
  serviceProductUsageByPk?: Maybe<ServiceProductUsage>;
  /** fetch data from the table: "service_protocol" */
  serviceProtocol: Array<ServiceProtocol>;
  /** fetch aggregated fields from the table: "service_protocol" */
  serviceProtocolAggregate: ServiceProtocolAggregate;
  /** fetch data from the table: "service_protocol" using primary key columns */
  serviceProtocolByPk?: Maybe<ServiceProtocol>;
  serviceProtocolSignedUrl?: Maybe<Scalars["String"]["output"]>;
  serviceProtocolSigningLink?: Maybe<Scalars["String"]["output"]>;
  /** fetch data from the table: "service_protocol_template" */
  serviceProtocolTemplate: Array<ServiceProtocolTemplate>;
  /** fetch aggregated fields from the table: "service_protocol_template" */
  serviceProtocolTemplateAggregate: ServiceProtocolTemplateAggregate;
  /** fetch data from the table: "service_protocol_template" using primary key columns */
  serviceProtocolTemplateByPk?: Maybe<ServiceProtocolTemplate>;
  /** fetch data from the table: "service_type" */
  serviceType: Array<ServiceType>;
  /** fetch aggregated fields from the table: "service_type" */
  serviceTypeAggregate: ServiceTypeAggregate;
  /** fetch data from the table: "service_type" using primary key columns */
  serviceTypeByPk?: Maybe<ServiceType>;
  /** fetch data from the table: "service_type_category" */
  serviceTypeCategory: Array<ServiceTypeCategory>;
  /** fetch aggregated fields from the table: "service_type_category" */
  serviceTypeCategoryAggregate: ServiceTypeCategoryAggregate;
  /** fetch data from the table: "service_type_category" using primary key columns */
  serviceTypeCategoryByPk?: Maybe<ServiceTypeCategory>;
  /** fetch data from the table: "utils_shortmoxieurl" */
  shortMoxieUrl: Array<ShortMoxieUrl>;
  /** fetch aggregated fields from the table: "utils_shortmoxieurl" */
  shortMoxieUrlAggregate: ShortMoxieUrlAggregate;
  /** fetch data from the table: "utils_shortmoxieurl" using primary key columns */
  shortMoxieUrlByPk?: Maybe<ShortMoxieUrl>;
  signedIframeUrl?: Maybe<Scalars["String"]["output"]>;
  /** fetch data from the table: "medspas_standingorder" */
  standingOrder: Array<StandingOrder>;
  /** fetch aggregated fields from the table: "medspas_standingorder" */
  standingOrderAggregate: StandingOrderAggregate;
  /** fetch data from the table: "medspas_standingorder" using primary key columns */
  standingOrderByPk?: Maybe<StandingOrder>;
  standingOrderSignedUrl?: Maybe<Scalars["String"]["output"]>;
  standingOrderSigningLink?: Maybe<Scalars["String"]["output"]>;
  /** fetch data from the table: "standing_order_template" */
  standingOrderTemplate: Array<StandingOrderTemplate>;
  /** fetch aggregated fields from the table: "standing_order_template" */
  standingOrderTemplateAggregate: StandingOrderTemplateAggregate;
  /** fetch data from the table: "standing_order_template" using primary key columns */
  standingOrderTemplateByPk?: Maybe<StandingOrderTemplate>;
  standingOrders?: Maybe<Array<Maybe<StandingOrderType>>>;
  stripeAccountSession?: Maybe<Scalars["String"]["output"]>;
  stripeCardInfo?: Maybe<HydratedStripeIssuingCardType>;
  stripeConnectionToken?: Maybe<Scalars["String"]["output"]>;
  stripeData?: Maybe<StripeOutboundPaymentMethodType>;
  sumUnreadHighlevelMessages?: Maybe<UnreadHighlevelMessagesCountType>;
  sumUnreadHighlevelMessagesByClient?: Maybe<UnreadHighlevelMessagesCountType>;
  /** fetch data from the table: "supplier_information" */
  supplierInformation: Array<SupplierInformation>;
  /** fetch aggregated fields from the table: "supplier_information" */
  supplierInformationAggregate: SupplierInformationAggregate;
  /** fetch data from the table: "supplier_information" using primary key columns */
  supplierInformationByPk?: Maybe<SupplierInformation>;
  /** fetch data from the table: "telehealth_visit_details" */
  telehealthVisitDetails: Array<TelehealthVisitDetails>;
  /** fetch aggregated fields from the table: "telehealth_visit_details" */
  telehealthVisitDetailsAggregate: TelehealthVisitDetailsAggregate;
  /** fetch data from the table: "telehealth_visit_details" using primary key columns */
  telehealthVisitDetailsByPk?: Maybe<TelehealthVisitDetails>;
  /** fetch data from the table: "standing_order" */
  tmpStandingOrder: Array<TmpStandingOrder>;
  /** fetch aggregated fields from the table: "standing_order" */
  tmpStandingOrderAggregate: TmpStandingOrderAggregate;
  /** fetch data from the table: "standing_order" using primary key columns */
  tmpStandingOrderByPk?: Maybe<TmpStandingOrder>;
  /** fetch data from the table: "url_pattern_permission" */
  urlPatternPermission: Array<UrlPatternPermission>;
  /** fetch aggregated fields from the table: "url_pattern_permission" */
  urlPatternPermissionAggregate: UrlPatternPermissionAggregate;
  /** fetch data from the table: "url_pattern_permission" using primary key columns */
  urlPatternPermissionByPk?: Maybe<UrlPatternPermission>;
  /** fetch data from the table: "accounts_user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "accounts_user" */
  userAggregate: UserAggregate;
  /** fetch data from the table: "accounts_user" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "medspas_usermedspa" */
  userMedspa: Array<UserMedspa>;
  /** fetch aggregated fields from the table: "medspas_usermedspa" */
  userMedspaAggregate: UserMedspaAggregate;
  /** fetch data from the table: "medspas_usermedspa" using primary key columns */
  userMedspaByPk?: Maybe<UserMedspa>;
  /** fetch data from the table: "user_medspa_feature_permissions" */
  userMedspaFeaturePermissions: Array<UserMedspaFeaturePermissions>;
  /** fetch aggregated fields from the table: "user_medspa_feature_permissions" */
  userMedspaFeaturePermissionsAggregate: UserMedspaFeaturePermissionsAggregate;
  /** fetch data from the table: "user_medspa_model_permissions" */
  userMedspaModelPermissions: Array<UserMedspaModelPermissions>;
  /** fetch aggregated fields from the table: "user_medspa_model_permissions" */
  userMedspaModelPermissionsAggregate: UserMedspaModelPermissionsAggregate;
  userMedspaProviderInfo?: Maybe<UserMedSpaProviderType>;
  /** fetch data from the table: "user_medspa_url_permissions" */
  userMedspaUrlPermissions: Array<UserMedspaUrlPermissions>;
  /** fetch aggregated fields from the table: "user_medspa_url_permissions" */
  userMedspaUrlPermissionsAggregate: UserMedspaUrlPermissionsAggregate;
  /** fetch data from the table: "visits_visit" */
  visit: Array<Visit>;
  visitAdditionalInfo?: Maybe<VisitType>;
  /** fetch aggregated fields from the table: "visits_visit" */
  visitAggregate: VisitAggregate;
  /** fetch data from the table: "visits_visit" using primary key columns */
  visitByPk?: Maybe<Visit>;
  /** fetch data from the table: "visits_visitreviewstatuschangelog" */
  visitReviewStatusChangeLog: Array<VisitReviewStatusChangeLog>;
  /** fetch aggregated fields from the table: "visits_visitreviewstatuschangelog" */
  visitReviewStatusChangeLogAggregate: VisitReviewStatusChangeLogAggregate;
  /** fetch data from the table: "visits_visitreviewstatuschangelog" using primary key columns */
  visitReviewStatusChangeLogByPk?: Maybe<VisitReviewStatusChangeLog>;
  /** fetch data from the table: "visits_visitstatuschangelog" */
  visitStatusChangeLog: Array<VisitStatusChangeLog>;
  /** fetch aggregated fields from the table: "visits_visitstatuschangelog" */
  visitStatusChangeLogAggregate: VisitStatusChangeLogAggregate;
  /** fetch data from the table: "visits_visitstatuschangelog" using primary key columns */
  visitStatusChangeLogByPk?: Maybe<VisitStatusChangeLog>;
  /** fetch data from the table: "visits_adversereactionchangelog" */
  visitsAdversereactionchangelog: Array<VisitsAdversereactionchangelog>;
  /** fetch aggregated fields from the table: "visits_adversereactionchangelog" */
  visitsAdversereactionchangelogAggregate: VisitsAdversereactionchangelogAggregate;
  /** fetch data from the table: "visits_adversereactionchangelog" using primary key columns */
  visitsAdversereactionchangelogByPk?: Maybe<VisitsAdversereactionchangelog>;
  /** fetch data from the table: "visits_chartsignature" */
  visitsChartsignature: Array<VisitsChartsignature>;
  /** fetch aggregated fields from the table: "visits_chartsignature" */
  visitsChartsignatureAggregate: VisitsChartsignatureAggregate;
  /** fetch data from the table: "visits_chartsignature" using primary key columns */
  visitsChartsignatureByPk?: Maybe<VisitsChartsignature>;
  /** fetch data from the table: "visits_telehealthvisitdetailschangelog" */
  visitsTelehealthvisitdetailschangelog: Array<VisitsTelehealthvisitdetailschangelog>;
  /** fetch aggregated fields from the table: "visits_telehealthvisitdetailschangelog" */
  visitsTelehealthvisitdetailschangelogAggregate: VisitsTelehealthvisitdetailschangelogAggregate;
  /** fetch data from the table: "visits_telehealthvisitdetailschangelog" using primary key columns */
  visitsTelehealthvisitdetailschangelogByPk?: Maybe<VisitsTelehealthvisitdetailschangelog>;
  /** fetch data from the table: "wallet_credit_line" */
  walletCreditLine: Array<WalletCreditLine>;
  /** fetch aggregated fields from the table: "wallet_credit_line" */
  walletCreditLineAggregate: WalletCreditLineAggregate;
  /** fetch data from the table: "wallet_credit_line" using primary key columns */
  walletCreditLineByPk?: Maybe<WalletCreditLine>;
  /** fetch data from the table: "wallet_credit_type" */
  walletCreditType: Array<WalletCreditType>;
  /** fetch aggregated fields from the table: "wallet_credit_type" */
  walletCreditTypeAggregate: WalletCreditTypeAggregate;
  /** fetch data from the table: "wallet_credit_type" using primary key columns */
  walletCreditTypeByPk?: Maybe<WalletCreditType>;
  /** fetch data from the table: "wallets_wallettransaction" */
  walletTransaction: Array<WalletTransaction>;
  /** fetch aggregated fields from the table: "wallets_wallettransaction" */
  walletTransactionAggregate: WalletTransactionAggregate;
  /** fetch data from the table: "wallets_wallettransaction" using primary key columns */
  walletTransactionByPk?: Maybe<WalletTransaction>;
  /** fetch data from the table: "wallet_transaction_source_tmp" */
  walletTransactionSourceTmp: Array<WalletTransactionSourceTmp>;
  /** fetch aggregated fields from the table: "wallet_transaction_source_tmp" */
  walletTransactionSourceTmpAggregate: WalletTransactionSourceTmpAggregate;
  /** fetch data from the table: "wallet_transaction_source_tmp" using primary key columns */
  walletTransactionSourceTmpByPk?: Maybe<WalletTransactionSourceTmp>;
  /** An array relationship */
  walletTransactionsSources: Array<WalletTransactionsSources>;
  /** An aggregate relationship */
  walletTransactionsSourcesAggregate: WalletTransactionsSourcesAggregate;
  /** fetch data from the table: "wallet_transactions_sources" using primary key columns */
  walletTransactionsSourcesByPk?: Maybe<WalletTransactionsSources>;
};

export type Query_RootAddressArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};

export type Query_RootAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};

export type Query_RootAddressByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootAdministrativeDivisionArgs = {
  distinctOn?: InputMaybe<Array<AdministrativeDivisionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdministrativeDivisionOrderBy>>;
  where?: InputMaybe<AdministrativeDivisionBoolExp>;
};

export type Query_RootAdministrativeDivisionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AdministrativeDivisionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdministrativeDivisionOrderBy>>;
  where?: InputMaybe<AdministrativeDivisionBoolExp>;
};

export type Query_RootAdministrativeDivisionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootAdverseReactionArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionOrderBy>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

export type Query_RootAdverseReactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionOrderBy>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

export type Query_RootAdverseReactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootAdverseReactionTemplateArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionTemplateOrderBy>>;
  where?: InputMaybe<AdverseReactionTemplateBoolExp>;
};

export type Query_RootAdverseReactionTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionTemplateOrderBy>>;
  where?: InputMaybe<AdverseReactionTemplateBoolExp>;
};

export type Query_RootAdverseReactionTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootAppointmentPublicInfoArgs = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootAvailabilityEventArgs = {
  distinctOn?: InputMaybe<Array<AvailabilityEventSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvailabilityEventOrderBy>>;
  where?: InputMaybe<AvailabilityEventBoolExp>;
};

export type Query_RootAvailabilityEventAggregateArgs = {
  distinctOn?: InputMaybe<Array<AvailabilityEventSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvailabilityEventOrderBy>>;
  where?: InputMaybe<AvailabilityEventBoolExp>;
};

export type Query_RootAvailabilityEventByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootAvailableResourcesArgs = {
  medspaId: Scalars["Int"]["input"];
  serviceMenuItemIds: Array<InputMaybe<Scalars["Int"]["input"]>>;
  visit?: InputMaybe<Scalars["Int"]["input"]>;
  visitEnd: Scalars["DateTime"]["input"];
  visitStart: Scalars["DateTime"]["input"];
};

export type Query_RootAvailableTimeSlotsArgs = {
  durationInMinutes: Scalars["Int"]["input"];
  endDate: Scalars["Date"]["input"];
  providerId: Scalars["BigInt"]["input"];
  serviceMenuItemIds?: InputMaybe<
    Array<InputMaybe<Scalars["BigInt"]["input"]>>
  >;
  startDate: Scalars["Date"]["input"];
};

export type Query_RootCareFormArgs = {
  distinctOn?: InputMaybe<Array<CareFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormOrderBy>>;
  where?: InputMaybe<CareFormBoolExp>;
};

export type Query_RootCareFormAggregateArgs = {
  distinctOn?: InputMaybe<Array<CareFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormOrderBy>>;
  where?: InputMaybe<CareFormBoolExp>;
};

export type Query_RootCareFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootCareFormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

export type Query_RootCareFormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

export type Query_RootCareFormServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootChartSignatureAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootChartsToReviewArgs = {
  providerIds: Array<InputMaybe<Scalars["Int"]["input"]>>;
  visitEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  visitStart?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Query_RootCherryAccountArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootCherryApiKeyArgs = {
  medspaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootClientArgs = {
  distinctOn?: InputMaybe<Array<ClientSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientOrderBy>>;
  where?: InputMaybe<ClientBoolExp>;
};

export type Query_RootClientAccessTokenArgs = {
  distinctOn?: InputMaybe<Array<ClientAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientAccessTokenOrderBy>>;
  where?: InputMaybe<ClientAccessTokenBoolExp>;
};

export type Query_RootClientAccessTokenAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientAccessTokenOrderBy>>;
  where?: InputMaybe<ClientAccessTokenBoolExp>;
};

export type Query_RootClientAccessTokenByPkArgs = {
  token: Scalars["String"]["input"];
};

export type Query_RootClientAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootClientAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientOrderBy>>;
  where?: InputMaybe<ClientBoolExp>;
};

export type Query_RootClientByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootClientMembershipArgs = {
  distinctOn?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientMembershipOrderBy>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

export type Query_RootClientMembershipAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootClientMembershipAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientMembershipOrderBy>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

export type Query_RootClientMembershipByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootClientStripeInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootClientWalletArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootComplianceTaskArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskOrderBy>>;
  where?: InputMaybe<ComplianceTaskBoolExp>;
};

export type Query_RootComplianceTaskAggregateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskOrderBy>>;
  where?: InputMaybe<ComplianceTaskBoolExp>;
};

export type Query_RootComplianceTaskByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootComplianceTaskDocumentArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskDocumentOrderBy>>;
  where?: InputMaybe<ComplianceTaskDocumentBoolExp>;
};

export type Query_RootComplianceTaskDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskDocumentOrderBy>>;
  where?: InputMaybe<ComplianceTaskDocumentBoolExp>;
};

export type Query_RootComplianceTaskDocumentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootComplianceTaskDocumentSignedUrlArgs = {
  id: Scalars["BigInt"]["input"];
  medspaId: Scalars["BigInt"]["input"];
};

export type Query_RootComplianceTaskTemplateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskTemplateOrderBy>>;
  where?: InputMaybe<ComplianceTaskTemplateBoolExp>;
};

export type Query_RootComplianceTaskTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskTemplateOrderBy>>;
  where?: InputMaybe<ComplianceTaskTemplateBoolExp>;
};

export type Query_RootComplianceTaskTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootCompliantNoteArgs = {
  distinctOn?: InputMaybe<Array<CompliantNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompliantNoteOrderBy>>;
  where?: InputMaybe<CompliantNoteBoolExp>;
};

export type Query_RootCompliantNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompliantNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompliantNoteOrderBy>>;
  where?: InputMaybe<CompliantNoteBoolExp>;
};

export type Query_RootCompliantNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootCompliantNoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<CompliantNoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompliantNoteTemplateOrderBy>>;
  where?: InputMaybe<CompliantNoteTemplateBoolExp>;
};

export type Query_RootCompliantNoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompliantNoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompliantNoteTemplateOrderBy>>;
  where?: InputMaybe<CompliantNoteTemplateBoolExp>;
};

export type Query_RootCompliantNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootConsentFormArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormOrderBy>>;
  where?: InputMaybe<ConsentFormBoolExp>;
};

export type Query_RootConsentFormAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormOrderBy>>;
  where?: InputMaybe<ConsentFormBoolExp>;
};

export type Query_RootConsentFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootConsentFormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

export type Query_RootConsentFormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

export type Query_RootConsentFormServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootConsentFormSignatureArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

export type Query_RootConsentFormSignatureAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootConsentFormSignatureAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

export type Query_RootConsentFormSignatureByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootDepositLineArgs = {
  distinctOn?: InputMaybe<Array<DepositLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DepositLineOrderBy>>;
  where?: InputMaybe<DepositLineBoolExp>;
};

export type Query_RootDepositLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<DepositLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DepositLineOrderBy>>;
  where?: InputMaybe<DepositLineBoolExp>;
};

export type Query_RootDepositLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootDiagramArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

export type Query_RootDiagramAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootDiagramAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

export type Query_RootDiagramByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootDiagramTemplateArgs = {
  distinctOn?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramTemplateOrderBy>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

export type Query_RootDiagramTemplateAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootDiagramTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramTemplateOrderBy>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

export type Query_RootDiagramTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootDiscountArgs = {
  distinctOn?: InputMaybe<Array<DiscountSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountOrderBy>>;
  where?: InputMaybe<DiscountBoolExp>;
};

export type Query_RootDiscountAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountOrderBy>>;
  where?: InputMaybe<DiscountBoolExp>;
};

export type Query_RootDiscountByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootDiscountLineArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

export type Query_RootDiscountLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

export type Query_RootDiscountLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootDiscountLineTotalPricesArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootDiscountLineTotalPricesWithoutWalletCreditsArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootDiscountRetailProductsArgs = {
  distinctOn?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountRetailProductsOrderBy>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

export type Query_RootDiscountRetailProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountRetailProductsOrderBy>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

export type Query_RootDiscountRetailProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootDiscountServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceMenuItemsOrderBy>>;
  where?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
};

export type Query_RootDiscountServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceMenuItemsOrderBy>>;
  where?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
};

export type Query_RootDiscountServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootDiscountServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceProductsOrderBy>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

export type Query_RootDiscountServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceProductsOrderBy>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

export type Query_RootDiscountServiceProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootExperimentArgs = {
  medspaId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootFeaturePermissionArgs = {
  distinctOn?: InputMaybe<Array<FeaturePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeaturePermissionOrderBy>>;
  where?: InputMaybe<FeaturePermissionBoolExp>;
};

export type Query_RootFeaturePermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeaturePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeaturePermissionOrderBy>>;
  where?: InputMaybe<FeaturePermissionBoolExp>;
};

export type Query_RootFeaturePermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootFeeArgs = {
  distinctOn?: InputMaybe<Array<FeeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeOrderBy>>;
  where?: InputMaybe<FeeBoolExp>;
};

export type Query_RootFeeAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeOrderBy>>;
  where?: InputMaybe<FeeBoolExp>;
};

export type Query_RootFeeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootFeeLineArgs = {
  distinctOn?: InputMaybe<Array<FeeLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeLineOrderBy>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

export type Query_RootFeeLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeeLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeLineOrderBy>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

export type Query_RootFeeLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootFeeLineTotalPricesArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootFeeLineTotalPricesWithoutWalletCreditsArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootFormArgs = {
  distinctOn?: InputMaybe<Array<FormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormOrderBy>>;
  where?: InputMaybe<FormBoolExp>;
};

export type Query_RootFormAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormOrderBy>>;
  where?: InputMaybe<FormBoolExp>;
};

export type Query_RootFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootFormServiceMenuItemArgs = {
  distinctOn?: InputMaybe<Array<FormServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormServiceMenuItemOrderBy>>;
  where?: InputMaybe<FormServiceMenuItemBoolExp>;
};

export type Query_RootFormServiceMenuItemAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormServiceMenuItemOrderBy>>;
  where?: InputMaybe<FormServiceMenuItemBoolExp>;
};

export type Query_RootFormServiceMenuItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootFormSubmissionArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

export type Query_RootFormSubmissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

export type Query_RootFormSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootFormSubmissionGfeStatusChangeLogArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogOrderBy>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

export type Query_RootFormSubmissionGfeStatusChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogOrderBy>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

export type Query_RootFormSubmissionGfeStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootFormSubmissionVersionAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootFormsFormMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

export type Query_RootFormsFormMedspaServiceOfferingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

export type Query_RootFormsFormMedspaServiceOfferingsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootFormsFormsubmissionversionArgs = {
  distinctOn?: InputMaybe<Array<FormsFormsubmissionversionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormsubmissionversionOrderBy>>;
  where?: InputMaybe<FormsFormsubmissionversionBoolExp>;
};

export type Query_RootFormsFormsubmissionversionAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormsFormsubmissionversionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormsubmissionversionOrderBy>>;
  where?: InputMaybe<FormsFormsubmissionversionBoolExp>;
};

export type Query_RootFormsFormsubmissionversionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootGetLayerBusinessAccessTokenArgs = {
  medspaId: Scalars["BigInt"]["input"];
};

export type Query_RootGfeDiagnosisArgs = {
  distinctOn?: InputMaybe<Array<GfeDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeDiagnosisOrderBy>>;
  where?: InputMaybe<GfeDiagnosisBoolExp>;
};

export type Query_RootGfeDiagnosisAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeDiagnosisOrderBy>>;
  where?: InputMaybe<GfeDiagnosisBoolExp>;
};

export type Query_RootGfeDiagnosisByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootGfeReviewDiagnosisArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

export type Query_RootGfeReviewDiagnosisAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

export type Query_RootGfeReviewDiagnosisByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootGfeReviewRequestArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

export type Query_RootGfeReviewRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

export type Query_RootGfeReviewRequestByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootGfeReviewSubmissionArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewSubmissionOrderBy>>;
  where?: InputMaybe<GfeReviewSubmissionBoolExp>;
};

export type Query_RootGfeReviewSubmissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewSubmissionOrderBy>>;
  where?: InputMaybe<GfeReviewSubmissionBoolExp>;
};

export type Query_RootGfeReviewSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootGfeServiceProductIndicationArgs = {
  distinctOn?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeServiceProductIndicationOrderBy>>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

export type Query_RootGfeServiceProductIndicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeServiceProductIndicationOrderBy>>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

export type Query_RootGfeServiceProductIndicationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootGfeSynchronousReviewDocumentArgs = {
  distinctOn?: InputMaybe<Array<GfeSynchronousReviewDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeSynchronousReviewDocumentOrderBy>>;
  where?: InputMaybe<GfeSynchronousReviewDocumentBoolExp>;
};

export type Query_RootGfeSynchronousReviewDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeSynchronousReviewDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeSynchronousReviewDocumentOrderBy>>;
  where?: InputMaybe<GfeSynchronousReviewDocumentBoolExp>;
};

export type Query_RootGfeSynchronousReviewDocumentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootGfeSynchronousReviewDocumentFileUrlArgs = {
  documentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootGfeTreatmentIndicationArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

export type Query_RootGfeTreatmentIndicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

export type Query_RootGfeTreatmentIndicationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootGiftBuilderBannerArgs = {
  distinctOn?: InputMaybe<Array<GiftBuilderBannerSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GiftBuilderBannerOrderBy>>;
  where?: InputMaybe<GiftBuilderBannerBoolExp>;
};

export type Query_RootGiftBuilderBannerAggregateArgs = {
  distinctOn?: InputMaybe<Array<GiftBuilderBannerSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GiftBuilderBannerOrderBy>>;
  where?: InputMaybe<GiftBuilderBannerBoolExp>;
};

export type Query_RootGiftBuilderBannerByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootHighlevelBaseUrlArgs = {
  medspaId: Scalars["Int"]["input"];
};

export type Query_RootHighlevelGetContactByIdArgs = {
  contactId: Scalars["String"]["input"];
  locationId: Scalars["String"]["input"];
};

export type Query_RootHighlevelMessagesByConversationIdArgs = {
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  lastMessageId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootHighlevelSearchConversationsArgs = {
  contactId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
  startAfter?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootIntakeFormPhotosArgs = {
  clientId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootInventoryLotArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

export type Query_RootInventoryLotAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

export type Query_RootInventoryLotByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootInventoryTransactionArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

export type Query_RootInventoryTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

export type Query_RootInventoryTransactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootInvoiceArgs = {
  distinctOn?: InputMaybe<Array<InvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceOrderBy>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

export type Query_RootInvoiceAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  skipWalletCredits?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Query_RootInvoiceAdditionalInfoWithoutWalletCreditsArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootInvoiceAggregateArgs = {
  distinctOn?: InputMaybe<Array<InvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceOrderBy>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

export type Query_RootInvoiceByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootLastGfeReviewTreatmentIndicationArgs = {
  distinctOn?: InputMaybe<Array<LastGfeReviewTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LastGfeReviewTreatmentIndicationOrderBy>>;
  where?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
};

export type Query_RootLastGfeReviewTreatmentIndicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<LastGfeReviewTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LastGfeReviewTreatmentIndicationOrderBy>>;
  where?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
};

export type Query_RootMarketingTemplateArgs = {
  distinctOn?: InputMaybe<Array<MarketingTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MarketingTemplateOrderBy>>;
  where?: InputMaybe<MarketingTemplateBoolExp>;
};

export type Query_RootMarketingTemplateAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootMarketingTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<MarketingTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MarketingTemplateOrderBy>>;
  where?: InputMaybe<MarketingTemplateBoolExp>;
};

export type Query_RootMarketingTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMdMeetingArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingOrderBy>>;
  where?: InputMaybe<MdMeetingBoolExp>;
};

export type Query_RootMdMeetingAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingOrderBy>>;
  where?: InputMaybe<MdMeetingBoolExp>;
};

export type Query_RootMdMeetingAttendeesArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingAttendeesOrderBy>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

export type Query_RootMdMeetingAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingAttendeesOrderBy>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

export type Query_RootMdMeetingAttendeesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMdMeetingByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMdMeetingNoteArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteOrderBy>>;
  where?: InputMaybe<MdMeetingNoteBoolExp>;
};

export type Query_RootMdMeetingNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteOrderBy>>;
  where?: InputMaybe<MdMeetingNoteBoolExp>;
};

export type Query_RootMdMeetingNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMdMeetingNoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteTemplateOrderBy>>;
  where?: InputMaybe<MdMeetingNoteTemplateBoolExp>;
};

export type Query_RootMdMeetingNoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteTemplateOrderBy>>;
  where?: InputMaybe<MdMeetingNoteTemplateBoolExp>;
};

export type Query_RootMdMeetingNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedicalDirectorArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorOrderBy>>;
  where?: InputMaybe<MedicalDirectorBoolExp>;
};

export type Query_RootMedicalDirectorAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorOrderBy>>;
  where?: InputMaybe<MedicalDirectorBoolExp>;
};

export type Query_RootMedicalDirectorByPkArgs = {
  userId: Scalars["bigint"]["input"];
};

export type Query_RootMedicalDirectorSubscriptionArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorSubscriptionOrderBy>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

export type Query_RootMedicalDirectorSubscriptionAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootMedicalDirectorSubscriptionAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorSubscriptionOrderBy>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

export type Query_RootMedicalDirectorSubscriptionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaArgs = {
  distinctOn?: InputMaybe<Array<MedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaOrderBy>>;
  where?: InputMaybe<MedspaBoolExp>;
};

export type Query_RootMedspaAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootMedspaAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaOrderBy>>;
  where?: InputMaybe<MedspaBoolExp>;
};

export type Query_RootMedspaByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaComplianceTasksArgs = {
  medspaId: Scalars["BigInt"]["input"];
};

export type Query_RootMedspaConfigurationAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootMedspaPublicAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootMedspaRoomsAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaRoomsAppointmentsOrderBy>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

export type Query_RootMedspaRoomsAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaRoomsAppointmentsOrderBy>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

export type Query_RootMedspaRoomsAppointmentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceMenuItemArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

export type Query_RootMedspaServiceMenuItemAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

export type Query_RootMedspaServiceMenuItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceMenuItemMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsOrderBy>
  >;
  where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
};

export type Query_RootMedspaServiceMenuItemMedspaServiceOfferingsAggregateArgs =
  {
    distinctOn?: InputMaybe<
      Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
    >;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<
      Array<MedspaServiceMenuItemMedspaServiceOfferingsOrderBy>
    >;
    where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
  };

export type Query_RootMedspaServiceMenuItemMedspaServiceOfferingsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceMenuItemOldProductsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOldProductsOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

export type Query_RootMedspaServiceMenuItemOldProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOldProductsOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

export type Query_RootMedspaServiceMenuItemOldProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceMenuItemProvidersArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemProvidersOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

export type Query_RootMedspaServiceMenuItemProvidersAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemProvidersOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

export type Query_RootMedspaServiceMenuItemProvidersByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceOfferingArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

export type Query_RootMedspaServiceOfferingAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

export type Query_RootMedspaServiceOfferingByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceOfferingComplianceRequirementsArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceOfferingComplianceRequirementsSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<MedspaServiceOfferingComplianceRequirementsOrderBy>
  >;
  where?: InputMaybe<MedspaServiceOfferingComplianceRequirementsBoolExp>;
};

export type Query_RootMedspaServiceOfferingComplianceRequirementsAggregateArgs =
  {
    distinctOn?: InputMaybe<
      Array<MedspaServiceOfferingComplianceRequirementsSelectColumn>
    >;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<
      Array<MedspaServiceOfferingComplianceRequirementsOrderBy>
    >;
    where?: InputMaybe<MedspaServiceOfferingComplianceRequirementsBoolExp>;
  };

export type Query_RootMedspaServiceOfferingNoteTemplatesArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceOfferingNoteTemplatesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
};

export type Query_RootMedspaServiceOfferingNoteTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceOfferingNoteTemplatesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
};

export type Query_RootMedspaServiceOfferingNoteTemplatesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceOfferingNotesArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNotesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
};

export type Query_RootMedspaServiceOfferingNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNotesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
};

export type Query_RootMedspaServiceOfferingNotesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceProductArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

export type Query_RootMedspaServiceProductAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootMedspaServiceProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

export type Query_RootMedspaServiceProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaServiceProtocolTemplateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
};

export type Query_RootMedspaServiceProtocolTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
};

export type Query_RootMedspaServiceProtocolTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaStandingOrderTemplateArgs = {
  distinctOn?: InputMaybe<Array<MedspaStandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaStandingOrderTemplateOrderBy>>;
  where?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
};

export type Query_RootMedspaStandingOrderTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaStandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaStandingOrderTemplateOrderBy>>;
  where?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
};

export type Query_RootMedspaStandingOrderTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspaWalletCreditTypeArgs = {
  distinctOn?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaWalletCreditTypeOrderBy>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

export type Query_RootMedspaWalletCreditTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaWalletCreditTypeOrderBy>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

export type Query_RootMedspaWalletCreditTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspasFeatureflagArgs = {
  distinctOn?: InputMaybe<Array<MedspasFeatureflagSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasFeatureflagOrderBy>>;
  where?: InputMaybe<MedspasFeatureflagBoolExp>;
};

export type Query_RootMedspasFeatureflagAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasFeatureflagSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasFeatureflagOrderBy>>;
  where?: InputMaybe<MedspasFeatureflagBoolExp>;
};

export type Query_RootMedspasFeatureflagByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspasMedicallicenseArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedicallicenseSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedicallicenseOrderBy>>;
  where?: InputMaybe<MedspasMedicallicenseBoolExp>;
};

export type Query_RootMedspasMedicallicenseAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedicallicenseSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedicallicenseOrderBy>>;
  where?: InputMaybe<MedspasMedicallicenseBoolExp>;
};

export type Query_RootMedspasMedicallicenseByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspasMedspaconfigurationArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedspaconfigurationOrderBy>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

export type Query_RootMedspasMedspaconfigurationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedspaconfigurationOrderBy>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

export type Query_RootMedspasMedspaconfigurationByPkArgs = {
  medspaId: Scalars["bigint"]["input"];
};

export type Query_RootMedspasProfessionalcorporationArgs = {
  distinctOn?: InputMaybe<Array<MedspasProfessionalcorporationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasProfessionalcorporationOrderBy>>;
  where?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
};

export type Query_RootMedspasProfessionalcorporationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasProfessionalcorporationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasProfessionalcorporationOrderBy>>;
  where?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
};

export type Query_RootMedspasProfessionalcorporationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspasStripeoutboundpaymentmethodArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasStripeoutboundpaymentmethodSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasStripeoutboundpaymentmethodOrderBy>>;
  where?: InputMaybe<MedspasStripeoutboundpaymentmethodBoolExp>;
};

export type Query_RootMedspasStripeoutboundpaymentmethodAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasStripeoutboundpaymentmethodSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasStripeoutboundpaymentmethodOrderBy>>;
  where?: InputMaybe<MedspasStripeoutboundpaymentmethodBoolExp>;
};

export type Query_RootMedspasStripeoutboundpaymentmethodByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMedspasUsermedspaAcquiredMedicalLicensesArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesOrderBy>>;
  where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
};

export type Query_RootMedspasUsermedspaAcquiredMedicalLicensesAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesOrderBy>>;
  where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
};

export type Query_RootMedspasUsermedspaAcquiredMedicalLicensesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMembershipArgs = {
  distinctOn?: InputMaybe<Array<MembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipOrderBy>>;
  where?: InputMaybe<MembershipBoolExp>;
};

export type Query_RootMembershipAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipOrderBy>>;
  where?: InputMaybe<MembershipBoolExp>;
};

export type Query_RootMembershipByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMembershipCategoryArgs = {
  distinctOn?: InputMaybe<Array<MembershipCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipCategoryOrderBy>>;
  where?: InputMaybe<MembershipCategoryBoolExp>;
};

export type Query_RootMembershipCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipCategoryOrderBy>>;
  where?: InputMaybe<MembershipCategoryBoolExp>;
};

export type Query_RootMembershipCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMembershipLineArgs = {
  distinctOn?: InputMaybe<Array<MembershipLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipLineOrderBy>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

export type Query_RootMembershipLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipLineOrderBy>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

export type Query_RootMembershipLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMembershipPerkArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkOrderBy>>;
  where?: InputMaybe<MembershipPerkBoolExp>;
};

export type Query_RootMembershipPerkAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkOrderBy>>;
  where?: InputMaybe<MembershipPerkBoolExp>;
};

export type Query_RootMembershipPerkByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootMembershipPerkLineArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

export type Query_RootMembershipPerkLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

export type Query_RootMembershipPerkLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootModelPermissionArgs = {
  distinctOn?: InputMaybe<Array<ModelPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ModelPermissionOrderBy>>;
  where?: InputMaybe<ModelPermissionBoolExp>;
};

export type Query_RootModelPermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<ModelPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ModelPermissionOrderBy>>;
  where?: InputMaybe<ModelPermissionBoolExp>;
};

export type Query_RootModelPermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootNoteArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

export type Query_RootNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

export type Query_RootNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootNoteChangeLogArgs = {
  distinctOn?: InputMaybe<Array<NoteChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteChangeLogOrderBy>>;
  where?: InputMaybe<NoteChangeLogBoolExp>;
};

export type Query_RootNoteChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteChangeLogOrderBy>>;
  where?: InputMaybe<NoteChangeLogBoolExp>;
};

export type Query_RootNoteChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootNoteFormSubmissionArgs = {
  distinctOn?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteFormSubmissionOrderBy>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

export type Query_RootNoteFormSubmissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteFormSubmissionOrderBy>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

export type Query_RootNoteFormSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootNoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<NoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteTemplateOrderBy>>;
  where?: InputMaybe<NoteTemplateBoolExp>;
};

export type Query_RootNoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteTemplateOrderBy>>;
  where?: InputMaybe<NoteTemplateBoolExp>;
};

export type Query_RootNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootPackageArgs = {
  distinctOn?: InputMaybe<Array<PackageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageOrderBy>>;
  where?: InputMaybe<PackageBoolExp>;
};

export type Query_RootPackageAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootPackageAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageOrderBy>>;
  where?: InputMaybe<PackageBoolExp>;
};

export type Query_RootPackageByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootPackageItemArgs = {
  distinctOn?: InputMaybe<Array<PackageItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemOrderBy>>;
  where?: InputMaybe<PackageItemBoolExp>;
};

export type Query_RootPackageItemAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemOrderBy>>;
  where?: InputMaybe<PackageItemBoolExp>;
};

export type Query_RootPackageItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootPackageItemLineArgs = {
  distinctOn?: InputMaybe<Array<PackageItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemLineOrderBy>>;
  where?: InputMaybe<PackageItemLineBoolExp>;
};

export type Query_RootPackageItemLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemLineOrderBy>>;
  where?: InputMaybe<PackageItemLineBoolExp>;
};

export type Query_RootPackageItemLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootPackageLineArgs = {
  distinctOn?: InputMaybe<Array<PackageLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageLineOrderBy>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

export type Query_RootPackageLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageLineOrderBy>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

export type Query_RootPackageLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootPaymentArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Query_RootPaymentAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootPaymentAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Query_RootPaymentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootPhotoArgs = {
  distinctOn?: InputMaybe<Array<PhotoSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PhotoOrderBy>>;
  where?: InputMaybe<PhotoBoolExp>;
};

export type Query_RootPhotoAggregateArgs = {
  distinctOn?: InputMaybe<Array<PhotoSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PhotoOrderBy>>;
  where?: InputMaybe<PhotoBoolExp>;
};

export type Query_RootPhotoByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootProductArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Query_RootProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Query_RootProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootProductLineArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

export type Query_RootProductLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

export type Query_RootProductLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootProductLineTotalPricesArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootProductLineTotalPricesWithoutWalletCreditsArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootProviderNotRequireDocumentsArgs = {
  distinctOn?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderNotRequireDocumentsOrderBy>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

export type Query_RootProviderNotRequireDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderNotRequireDocumentsOrderBy>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

export type Query_RootProviderNotRequireDocumentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootRecurringAvailabilityArgs = {
  distinctOn?: InputMaybe<Array<RecurringAvailabilitySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecurringAvailabilityOrderBy>>;
  where?: InputMaybe<RecurringAvailabilityBoolExp>;
};

export type Query_RootRecurringAvailabilityAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecurringAvailabilitySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecurringAvailabilityOrderBy>>;
  where?: InputMaybe<RecurringAvailabilityBoolExp>;
};

export type Query_RootRecurringAvailabilityByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootRefundArgs = {
  distinctOn?: InputMaybe<Array<RefundSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RefundOrderBy>>;
  where?: InputMaybe<RefundBoolExp>;
};

export type Query_RootRefundAggregateArgs = {
  distinctOn?: InputMaybe<Array<RefundSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RefundOrderBy>>;
  where?: InputMaybe<RefundBoolExp>;
};

export type Query_RootRefundByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootReportArgs = {
  distinctOn?: InputMaybe<Array<ReportSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportOrderBy>>;
  where?: InputMaybe<ReportBoolExp>;
};

export type Query_RootReportAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportOrderBy>>;
  where?: InputMaybe<ReportBoolExp>;
};

export type Query_RootReportByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootReportCategoryArgs = {
  distinctOn?: InputMaybe<Array<ReportCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportCategoryOrderBy>>;
  where?: InputMaybe<ReportCategoryBoolExp>;
};

export type Query_RootReportCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportCategoryOrderBy>>;
  where?: InputMaybe<ReportCategoryBoolExp>;
};

export type Query_RootReportCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootResourcesMedspaRoomArgs = {
  distinctOn?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ResourcesMedspaRoomOrderBy>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

export type Query_RootResourcesMedspaRoomAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ResourcesMedspaRoomOrderBy>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

export type Query_RootResourcesMedspaRoomByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootSchedulingAppointmentArgs = {
  distinctOn?: InputMaybe<Array<SchedulingAppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SchedulingAppointmentOrderBy>>;
  where?: InputMaybe<SchedulingAppointmentBoolExp>;
};

export type Query_RootSchedulingAppointmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<SchedulingAppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SchedulingAppointmentOrderBy>>;
  where?: InputMaybe<SchedulingAppointmentBoolExp>;
};

export type Query_RootSchedulingAppointmentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootScriptProductArgs = {
  distinctOn?: InputMaybe<Array<ScriptProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptProductOrderBy>>;
  where?: InputMaybe<ScriptProductBoolExp>;
};

export type Query_RootScriptProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<ScriptProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptProductOrderBy>>;
  where?: InputMaybe<ScriptProductBoolExp>;
};

export type Query_RootScriptProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootScriptRequestArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestOrderBy>>;
  where?: InputMaybe<ScriptRequestBoolExp>;
};

export type Query_RootScriptRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestOrderBy>>;
  where?: InputMaybe<ScriptRequestBoolExp>;
};

export type Query_RootScriptRequestByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootScriptRequestStatusChangeLogArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestStatusChangeLogOrderBy>>;
  where?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
};

export type Query_RootScriptRequestStatusChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestStatusChangeLogOrderBy>>;
  where?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
};

export type Query_RootScriptRequestStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceCategoryArgs = {
  distinctOn?: InputMaybe<Array<ServiceCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceCategoryOrderBy>>;
  where?: InputMaybe<ServiceCategoryBoolExp>;
};

export type Query_RootServiceCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceCategoryOrderBy>>;
  where?: InputMaybe<ServiceCategoryBoolExp>;
};

export type Query_RootServiceCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceDeviceArgs = {
  distinctOn?: InputMaybe<Array<ServiceDeviceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDeviceOrderBy>>;
  where?: InputMaybe<ServiceDeviceBoolExp>;
};

export type Query_RootServiceDeviceAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDeviceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDeviceOrderBy>>;
  where?: InputMaybe<ServiceDeviceBoolExp>;
};

export type Query_RootServiceDeviceByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceDevicesAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesAppointmentsOrderBy>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

export type Query_RootServiceDevicesAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesAppointmentsOrderBy>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

export type Query_RootServiceDevicesAppointmentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceDevicesServiceTypesArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesServiceTypesOrderBy>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

export type Query_RootServiceDevicesServiceTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesServiceTypesOrderBy>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

export type Query_RootServiceDevicesServiceTypesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceMenuItemLineArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

export type Query_RootServiceMenuItemLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

export type Query_RootServiceMenuItemLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceMenuItemLineStandingOrdersArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
};

export type Query_RootServiceMenuItemLineStandingOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
};

export type Query_RootServiceMenuItemLineStandingOrdersByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceMenuItemLineTotalPricesArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootServiceMenuItemLineTotalPricesWithoutWalletCreditsArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootServiceProductArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductOrderBy>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

export type Query_RootServiceProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductOrderBy>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

export type Query_RootServiceProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceProductChartingNoteArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

export type Query_RootServiceProductChartingNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

export type Query_RootServiceProductChartingNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceProductLineArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

export type Query_RootServiceProductLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

export type Query_RootServiceProductLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceProductTypeArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductTypeOrderBy>>;
  where?: InputMaybe<ServiceProductTypeBoolExp>;
};

export type Query_RootServiceProductTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductTypeOrderBy>>;
  where?: InputMaybe<ServiceProductTypeBoolExp>;
};

export type Query_RootServiceProductTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceProductUsageArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductUsageOrderBy>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

export type Query_RootServiceProductUsageAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductUsageOrderBy>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

export type Query_RootServiceProductUsageByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceProtocolArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolOrderBy>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

export type Query_RootServiceProtocolAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolOrderBy>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

export type Query_RootServiceProtocolByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceProtocolSignedUrlArgs = {
  id: Scalars["BigInt"]["input"];
  medspaId: Scalars["String"]["input"];
};

export type Query_RootServiceProtocolSigningLinkArgs = {
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  medspaId: Scalars["String"]["input"];
};

export type Query_RootServiceProtocolTemplateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<ServiceProtocolTemplateBoolExp>;
};

export type Query_RootServiceProtocolTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<ServiceProtocolTemplateBoolExp>;
};

export type Query_RootServiceProtocolTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceTypeArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

export type Query_RootServiceTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

export type Query_RootServiceTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootServiceTypeCategoryArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeCategoryOrderBy>>;
  where?: InputMaybe<ServiceTypeCategoryBoolExp>;
};

export type Query_RootServiceTypeCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeCategoryOrderBy>>;
  where?: InputMaybe<ServiceTypeCategoryBoolExp>;
};

export type Query_RootServiceTypeCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootShortMoxieUrlArgs = {
  distinctOn?: InputMaybe<Array<ShortMoxieUrlSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ShortMoxieUrlOrderBy>>;
  where?: InputMaybe<ShortMoxieUrlBoolExp>;
};

export type Query_RootShortMoxieUrlAggregateArgs = {
  distinctOn?: InputMaybe<Array<ShortMoxieUrlSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ShortMoxieUrlOrderBy>>;
  where?: InputMaybe<ShortMoxieUrlBoolExp>;
};

export type Query_RootShortMoxieUrlByPkArgs = {
  uuid: Scalars["String"]["input"];
};

export type Query_RootSignedIframeUrlArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  medspaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootStandingOrderArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderOrderBy>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

export type Query_RootStandingOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderOrderBy>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

export type Query_RootStandingOrderByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootStandingOrderSignedUrlArgs = {
  id: Scalars["BigInt"]["input"];
  medspaId: Scalars["String"]["input"];
};

export type Query_RootStandingOrderSigningLinkArgs = {
  id?: InputMaybe<Scalars["BigInt"]["input"]>;
  medspaId: Scalars["String"]["input"];
};

export type Query_RootStandingOrderTemplateArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderTemplateOrderBy>>;
  where?: InputMaybe<StandingOrderTemplateBoolExp>;
};

export type Query_RootStandingOrderTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderTemplateOrderBy>>;
  where?: InputMaybe<StandingOrderTemplateBoolExp>;
};

export type Query_RootStandingOrderTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootStandingOrdersArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootStripeAccountSessionArgs = {
  medspaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootStripeCardInfoArgs = {
  stripeAccountId?: InputMaybe<Scalars["String"]["input"]>;
  stripeCardId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootStripeConnectionTokenArgs = {
  medspaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootStripeDataArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootSumUnreadHighlevelMessagesArgs = {
  medspaId: Scalars["Int"]["input"];
};

export type Query_RootSumUnreadHighlevelMessagesByClientArgs = {
  clientId: Scalars["Int"]["input"];
  medspaId: Scalars["Int"]["input"];
};

export type Query_RootSupplierInformationArgs = {
  distinctOn?: InputMaybe<Array<SupplierInformationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SupplierInformationOrderBy>>;
  where?: InputMaybe<SupplierInformationBoolExp>;
};

export type Query_RootSupplierInformationAggregateArgs = {
  distinctOn?: InputMaybe<Array<SupplierInformationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SupplierInformationOrderBy>>;
  where?: InputMaybe<SupplierInformationBoolExp>;
};

export type Query_RootSupplierInformationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootTelehealthVisitDetailsArgs = {
  distinctOn?: InputMaybe<Array<TelehealthVisitDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TelehealthVisitDetailsOrderBy>>;
  where?: InputMaybe<TelehealthVisitDetailsBoolExp>;
};

export type Query_RootTelehealthVisitDetailsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelehealthVisitDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TelehealthVisitDetailsOrderBy>>;
  where?: InputMaybe<TelehealthVisitDetailsBoolExp>;
};

export type Query_RootTelehealthVisitDetailsByPkArgs = {
  visitId: Scalars["bigint"]["input"];
};

export type Query_RootTmpStandingOrderArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

export type Query_RootTmpStandingOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

export type Query_RootTmpStandingOrderByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootUrlPatternPermissionArgs = {
  distinctOn?: InputMaybe<Array<UrlPatternPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UrlPatternPermissionOrderBy>>;
  where?: InputMaybe<UrlPatternPermissionBoolExp>;
};

export type Query_RootUrlPatternPermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<UrlPatternPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UrlPatternPermissionOrderBy>>;
  where?: InputMaybe<UrlPatternPermissionBoolExp>;
};

export type Query_RootUrlPatternPermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootUserArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Query_RootUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Query_RootUserByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootUserMedspaArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaOrderBy>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

export type Query_RootUserMedspaAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaOrderBy>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

export type Query_RootUserMedspaByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootUserMedspaFeaturePermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaFeaturePermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaFeaturePermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
};

export type Query_RootUserMedspaFeaturePermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaFeaturePermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaFeaturePermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
};

export type Query_RootUserMedspaModelPermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaModelPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

export type Query_RootUserMedspaModelPermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaModelPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

export type Query_RootUserMedspaProviderInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootUserMedspaUrlPermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaUrlPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

export type Query_RootUserMedspaUrlPermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaUrlPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

export type Query_RootVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

export type Query_RootVisitAdditionalInfoArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query_RootVisitAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

export type Query_RootVisitByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootVisitReviewStatusChangeLogArgs = {
  distinctOn?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitReviewStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

export type Query_RootVisitReviewStatusChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitReviewStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

export type Query_RootVisitReviewStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootVisitStatusChangeLogArgs = {
  distinctOn?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

export type Query_RootVisitStatusChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

export type Query_RootVisitStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootVisitsAdversereactionchangelogArgs = {
  distinctOn?: InputMaybe<Array<VisitsAdversereactionchangelogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsAdversereactionchangelogOrderBy>>;
  where?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
};

export type Query_RootVisitsAdversereactionchangelogAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitsAdversereactionchangelogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsAdversereactionchangelogOrderBy>>;
  where?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
};

export type Query_RootVisitsAdversereactionchangelogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootVisitsChartsignatureArgs = {
  distinctOn?: InputMaybe<Array<VisitsChartsignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsChartsignatureOrderBy>>;
  where?: InputMaybe<VisitsChartsignatureBoolExp>;
};

export type Query_RootVisitsChartsignatureAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitsChartsignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsChartsignatureOrderBy>>;
  where?: InputMaybe<VisitsChartsignatureBoolExp>;
};

export type Query_RootVisitsChartsignatureByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootVisitsTelehealthvisitdetailschangelogArgs = {
  distinctOn?: InputMaybe<
    Array<VisitsTelehealthvisitdetailschangelogSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsTelehealthvisitdetailschangelogOrderBy>>;
  where?: InputMaybe<VisitsTelehealthvisitdetailschangelogBoolExp>;
};

export type Query_RootVisitsTelehealthvisitdetailschangelogAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<VisitsTelehealthvisitdetailschangelogSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsTelehealthvisitdetailschangelogOrderBy>>;
  where?: InputMaybe<VisitsTelehealthvisitdetailschangelogBoolExp>;
};

export type Query_RootVisitsTelehealthvisitdetailschangelogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootWalletCreditLineArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditLineOrderBy>>;
  where?: InputMaybe<WalletCreditLineBoolExp>;
};

export type Query_RootWalletCreditLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditLineOrderBy>>;
  where?: InputMaybe<WalletCreditLineBoolExp>;
};

export type Query_RootWalletCreditLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootWalletCreditTypeArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditTypeOrderBy>>;
  where?: InputMaybe<WalletCreditTypeBoolExp>;
};

export type Query_RootWalletCreditTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditTypeOrderBy>>;
  where?: InputMaybe<WalletCreditTypeBoolExp>;
};

export type Query_RootWalletCreditTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootWalletTransactionArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionOrderBy>>;
  where?: InputMaybe<WalletTransactionBoolExp>;
};

export type Query_RootWalletTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionOrderBy>>;
  where?: InputMaybe<WalletTransactionBoolExp>;
};

export type Query_RootWalletTransactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootWalletTransactionSourceTmpArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

export type Query_RootWalletTransactionSourceTmpAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

export type Query_RootWalletTransactionSourceTmpByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Query_RootWalletTransactionsSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionsSourcesOrderBy>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

export type Query_RootWalletTransactionsSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionsSourcesOrderBy>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

export type Query_RootWalletTransactionsSourcesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

/** Table storing recurring availability periods (working hours and recurring time blocks) for providers */
export type RecurringAvailability = {
  __typename?: "recurringAvailability";
  /** A boolean indicating if the availability covers the entire day */
  allDay: Scalars["Boolean"]["output"];
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Upper bound for the availability period */
  endTime: Scalars["time"]["output"];
  /** The type of availability event, can allow or block time period */
  eventType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An optional label for the availability period */
  label: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  provider: UserMedspa;
  /** The provider to which this availability applies */
  providerId: Scalars["bigint"]["output"];
  /** Lower bound for the availability period */
  startTime: Scalars["time"]["output"];
  /** The day of the week for which availability applies */
  weekday: Scalars["String"]["output"];
};

/** aggregated selection of "scheduling_recurring_availability" */
export type RecurringAvailabilityAggregate = {
  __typename?: "recurringAvailabilityAggregate";
  aggregate?: Maybe<RecurringAvailabilityAggregateFields>;
  nodes: Array<RecurringAvailability>;
};

/** aggregate fields of "scheduling_recurring_availability" */
export type RecurringAvailabilityAggregateFields = {
  __typename?: "recurringAvailabilityAggregateFields";
  avg?: Maybe<RecurringAvailabilityAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<RecurringAvailabilityMaxFields>;
  min?: Maybe<RecurringAvailabilityMinFields>;
  stddev?: Maybe<RecurringAvailabilityStddevFields>;
  stddevPop?: Maybe<RecurringAvailabilityStddevPopFields>;
  stddevSamp?: Maybe<RecurringAvailabilityStddevSampFields>;
  sum?: Maybe<RecurringAvailabilitySumFields>;
  varPop?: Maybe<RecurringAvailabilityVarPopFields>;
  varSamp?: Maybe<RecurringAvailabilityVarSampFields>;
  variance?: Maybe<RecurringAvailabilityVarianceFields>;
};

/** aggregate fields of "scheduling_recurring_availability" */
export type RecurringAvailabilityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RecurringAvailabilitySelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type RecurringAvailabilityAvgFields = {
  __typename?: "recurringAvailabilityAvgFields";
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "scheduling_recurring_availability". All fields are combined with a logical 'AND'. */
export type RecurringAvailabilityBoolExp = {
  _and?: InputMaybe<Array<RecurringAvailabilityBoolExp>>;
  _not?: InputMaybe<RecurringAvailabilityBoolExp>;
  _or?: InputMaybe<Array<RecurringAvailabilityBoolExp>>;
  allDay?: InputMaybe<BooleanComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedById?: InputMaybe<BigintComparisonExp>;
  endTime?: InputMaybe<TimeComparisonExp>;
  eventType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  startTime?: InputMaybe<TimeComparisonExp>;
  weekday?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "scheduling_recurring_availability" */
export enum RecurringAvailabilityConstraint {
  /** unique or primary key constraint on columns "id" */
  SchedulingRecurringAvailabilityPkey = "scheduling_recurring_availability_pkey",
  /** unique or primary key constraint on columns "weekday", "event_type", "end_time", "provider_id", "start_time" */
  UniqueRecurringAvailability = "unique_recurring_availability",
}

/** input type for incrementing numeric columns in table "scheduling_recurring_availability" */
export type RecurringAvailabilityIncInput = {
  /** The user who performed the soft delete action */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** The provider to which this availability applies */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "scheduling_recurring_availability" */
export type RecurringAvailabilityInsertInput = {
  /** A boolean indicating if the availability covers the entire day */
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The user who performed the soft delete action */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Upper bound for the availability period */
  endTime?: InputMaybe<Scalars["time"]["input"]>;
  /** The type of availability event, can allow or block time period */
  eventType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** An optional label for the availability period */
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** The provider to which this availability applies */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Lower bound for the availability period */
  startTime?: InputMaybe<Scalars["time"]["input"]>;
  /** The day of the week for which availability applies */
  weekday?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type RecurringAvailabilityMaxFields = {
  __typename?: "recurringAvailabilityMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** The type of availability event, can allow or block time period */
  eventType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** An optional label for the availability period */
  label?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** The day of the week for which availability applies */
  weekday?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type RecurringAvailabilityMinFields = {
  __typename?: "recurringAvailabilityMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  /** The type of availability event, can allow or block time period */
  eventType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** An optional label for the availability period */
  label?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** The day of the week for which availability applies */
  weekday?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "scheduling_recurring_availability" */
export type RecurringAvailabilityMutationResponse = {
  __typename?: "recurringAvailabilityMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<RecurringAvailability>;
};

/** on_conflict condition type for table "scheduling_recurring_availability" */
export type RecurringAvailabilityOnConflict = {
  constraint: RecurringAvailabilityConstraint;
  updateColumns?: Array<RecurringAvailabilityUpdateColumn>;
  where?: InputMaybe<RecurringAvailabilityBoolExp>;
};

/** Ordering options when selecting data from "scheduling_recurring_availability". */
export type RecurringAvailabilityOrderBy = {
  allDay?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  eventType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  weekday?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: scheduling_recurring_availability */
export type RecurringAvailabilityPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "scheduling_recurring_availability" */
export enum RecurringAvailabilitySelectColumn {
  /** column name */
  AllDay = "allDay",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  EndTime = "endTime",
  /** column name */
  EventType = "eventType",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  StartTime = "startTime",
  /** column name */
  Weekday = "weekday",
}

/** input type for updating data in table "scheduling_recurring_availability" */
export type RecurringAvailabilitySetInput = {
  /** A boolean indicating if the availability covers the entire day */
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The user who performed the soft delete action */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Upper bound for the availability period */
  endTime?: InputMaybe<Scalars["time"]["input"]>;
  /** The type of availability event, can allow or block time period */
  eventType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** An optional label for the availability period */
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The provider to which this availability applies */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Lower bound for the availability period */
  startTime?: InputMaybe<Scalars["time"]["input"]>;
  /** The day of the week for which availability applies */
  weekday?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type RecurringAvailabilityStddevFields = {
  __typename?: "recurringAvailabilityStddevFields";
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type RecurringAvailabilityStddevPopFields = {
  __typename?: "recurringAvailabilityStddevPopFields";
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type RecurringAvailabilityStddevSampFields = {
  __typename?: "recurringAvailabilityStddevSampFields";
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "recurringAvailability" */
export type RecurringAvailabilityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: RecurringAvailabilityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RecurringAvailabilityStreamCursorValueInput = {
  /** A boolean indicating if the availability covers the entire day */
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The user who performed the soft delete action */
  deletedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Upper bound for the availability period */
  endTime?: InputMaybe<Scalars["time"]["input"]>;
  /** The type of availability event, can allow or block time period */
  eventType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** An optional label for the availability period */
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** The provider to which this availability applies */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Lower bound for the availability period */
  startTime?: InputMaybe<Scalars["time"]["input"]>;
  /** The day of the week for which availability applies */
  weekday?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type RecurringAvailabilitySumFields = {
  __typename?: "recurringAvailabilitySumFields";
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "scheduling_recurring_availability" */
export enum RecurringAvailabilityUpdateColumn {
  /** column name */
  AllDay = "allDay",
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DeletedById = "deletedById",
  /** column name */
  EndTime = "endTime",
  /** column name */
  EventType = "eventType",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  StartTime = "startTime",
  /** column name */
  Weekday = "weekday",
}

export type RecurringAvailabilityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RecurringAvailabilityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RecurringAvailabilitySetInput>;
  /** filter the rows which have to be updated */
  where: RecurringAvailabilityBoolExp;
};

/** aggregate varPop on columns */
export type RecurringAvailabilityVarPopFields = {
  __typename?: "recurringAvailabilityVarPopFields";
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type RecurringAvailabilityVarSampFields = {
  __typename?: "recurringAvailabilityVarSampFields";
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type RecurringAvailabilityVarianceFields = {
  __typename?: "recurringAvailabilityVarianceFields";
  /** The user who performed the soft delete action */
  deletedById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** The provider to which this availability applies */
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "payments_refund" */
export type Refund = {
  __typename?: "refund";
  amount: Scalars["numeric"]["output"];
  created: Scalars["timestamptz"]["output"];
  errorCode: Scalars["String"]["output"];
  explanation: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  payment: Payment;
  paymentId: Scalars["bigint"]["output"];
  refundMethod: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  stripeRefundId: Scalars["String"]["output"];
  /** An object relationship */
  walletTransaction?: Maybe<WalletTransaction>;
};

/** aggregated selection of "payments_refund" */
export type RefundAggregate = {
  __typename?: "refundAggregate";
  aggregate?: Maybe<RefundAggregateFields>;
  nodes: Array<Refund>;
};

export type RefundAggregateBoolExp = {
  count?: InputMaybe<RefundAggregateBoolExpCount>;
};

export type RefundAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RefundSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<RefundBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "payments_refund" */
export type RefundAggregateFields = {
  __typename?: "refundAggregateFields";
  avg?: Maybe<RefundAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<RefundMaxFields>;
  min?: Maybe<RefundMinFields>;
  stddev?: Maybe<RefundStddevFields>;
  stddevPop?: Maybe<RefundStddevPopFields>;
  stddevSamp?: Maybe<RefundStddevSampFields>;
  sum?: Maybe<RefundSumFields>;
  varPop?: Maybe<RefundVarPopFields>;
  varSamp?: Maybe<RefundVarSampFields>;
  variance?: Maybe<RefundVarianceFields>;
};

/** aggregate fields of "payments_refund" */
export type RefundAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RefundSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "payments_refund" */
export type RefundAggregateOrderBy = {
  avg?: InputMaybe<RefundAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RefundMaxOrderBy>;
  min?: InputMaybe<RefundMinOrderBy>;
  stddev?: InputMaybe<RefundStddevOrderBy>;
  stddevPop?: InputMaybe<RefundStddevPopOrderBy>;
  stddevSamp?: InputMaybe<RefundStddevSampOrderBy>;
  sum?: InputMaybe<RefundSumOrderBy>;
  varPop?: InputMaybe<RefundVarPopOrderBy>;
  varSamp?: InputMaybe<RefundVarSampOrderBy>;
  variance?: InputMaybe<RefundVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "payments_refund" */
export type RefundArrRelInsertInput = {
  data: Array<RefundInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<RefundOnConflict>;
};

/** aggregate avg on columns */
export type RefundAvgFields = {
  __typename?: "refundAvgFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "payments_refund" */
export type RefundAvgOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "payments_refund". All fields are combined with a logical 'AND'. */
export type RefundBoolExp = {
  _and?: InputMaybe<Array<RefundBoolExp>>;
  _not?: InputMaybe<RefundBoolExp>;
  _or?: InputMaybe<Array<RefundBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  errorCode?: InputMaybe<StringComparisonExp>;
  explanation?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  layerfiId?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  payment?: InputMaybe<PaymentBoolExp>;
  paymentId?: InputMaybe<BigintComparisonExp>;
  refundMethod?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  stripeRefundId?: InputMaybe<StringComparisonExp>;
  walletTransaction?: InputMaybe<WalletTransactionBoolExp>;
};

/** unique or primary key constraints on table "payments_refund" */
export enum RefundConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentsRefundPkey = "payments_refund_pkey",
}

/** input type for incrementing numeric columns in table "payments_refund" */
export type RefundIncInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "payments_refund" */
export type RefundInsertInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  errorCode?: InputMaybe<Scalars["String"]["input"]>;
  explanation?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  payment?: InputMaybe<PaymentObjRelInsertInput>;
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  refundMethod?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeRefundId?: InputMaybe<Scalars["String"]["input"]>;
  walletTransaction?: InputMaybe<WalletTransactionObjRelInsertInput>;
};

/** aggregate max on columns */
export type RefundMaxFields = {
  __typename?: "refundMaxFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  errorCode?: Maybe<Scalars["String"]["output"]>;
  explanation?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  refundMethod?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  stripeRefundId?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "payments_refund" */
export type RefundMaxOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  errorCode?: InputMaybe<OrderBy>;
  explanation?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  refundMethod?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeRefundId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RefundMinFields = {
  __typename?: "refundMinFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  errorCode?: Maybe<Scalars["String"]["output"]>;
  explanation?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  refundMethod?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  stripeRefundId?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "payments_refund" */
export type RefundMinOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  errorCode?: InputMaybe<OrderBy>;
  explanation?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  refundMethod?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeRefundId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "payments_refund" */
export type RefundMutationResponse = {
  __typename?: "refundMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Refund>;
};

/** input type for inserting object relation for remote table "payments_refund" */
export type RefundObjRelInsertInput = {
  data: RefundInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<RefundOnConflict>;
};

/** on_conflict condition type for table "payments_refund" */
export type RefundOnConflict = {
  constraint: RefundConstraint;
  updateColumns?: Array<RefundUpdateColumn>;
  where?: InputMaybe<RefundBoolExp>;
};

/** Ordering options when selecting data from "payments_refund". */
export type RefundOrderBy = {
  amount?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  errorCode?: InputMaybe<OrderBy>;
  explanation?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  layerfiId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  payment?: InputMaybe<PaymentOrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  refundMethod?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeRefundId?: InputMaybe<OrderBy>;
  walletTransaction?: InputMaybe<WalletTransactionOrderBy>;
};

/** primary key columns input for table: payments_refund */
export type RefundPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "payments_refund" */
export enum RefundSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  Created = "created",
  /** column name */
  ErrorCode = "errorCode",
  /** column name */
  Explanation = "explanation",
  /** column name */
  Id = "id",
  /** column name */
  LayerfiId = "layerfiId",
  /** column name */
  Modified = "modified",
  /** column name */
  PaymentId = "paymentId",
  /** column name */
  RefundMethod = "refundMethod",
  /** column name */
  Status = "status",
  /** column name */
  StripeRefundId = "stripeRefundId",
}

/** input type for updating data in table "payments_refund" */
export type RefundSetInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  errorCode?: InputMaybe<Scalars["String"]["input"]>;
  explanation?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  refundMethod?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeRefundId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type RefundStddevFields = {
  __typename?: "refundStddevFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "payments_refund" */
export type RefundStddevOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type RefundStddevPopFields = {
  __typename?: "refundStddevPopFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "payments_refund" */
export type RefundStddevPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type RefundStddevSampFields = {
  __typename?: "refundStddevSampFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "payments_refund" */
export type RefundStddevSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "refund" */
export type RefundStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: RefundStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RefundStreamCursorValueInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  errorCode?: InputMaybe<Scalars["String"]["input"]>;
  explanation?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Unique identifier for the payment in LayerFi (third party accounting platform) */
  layerfiId?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  refundMethod?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stripeRefundId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type RefundSumFields = {
  __typename?: "refundSumFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "payments_refund" */
export type RefundSumOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
};

/** update columns of table "payments_refund" */
export enum RefundUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  Created = "created",
  /** column name */
  ErrorCode = "errorCode",
  /** column name */
  Explanation = "explanation",
  /** column name */
  Id = "id",
  /** column name */
  LayerfiId = "layerfiId",
  /** column name */
  Modified = "modified",
  /** column name */
  PaymentId = "paymentId",
  /** column name */
  RefundMethod = "refundMethod",
  /** column name */
  Status = "status",
  /** column name */
  StripeRefundId = "stripeRefundId",
}

export type RefundUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RefundIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RefundSetInput>;
  /** filter the rows which have to be updated */
  where: RefundBoolExp;
};

/** aggregate varPop on columns */
export type RefundVarPopFields = {
  __typename?: "refundVarPopFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "payments_refund" */
export type RefundVarPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type RefundVarSampFields = {
  __typename?: "refundVarSampFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "payments_refund" */
export type RefundVarSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RefundVarianceFields = {
  __typename?: "refundVarianceFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "payments_refund" */
export type RefundVarianceOrderBy = {
  amount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "medspas_report" */
export type Report = {
  __typename?: "report";
  /** An object relationship */
  category: ReportCategory;
  created: Scalars["timestamptz"]["output"];
  dataSource: Scalars["String"]["output"];
  deleted: Scalars["Boolean"]["output"];
  embedType: Scalars["String"]["output"];
  /** An object relationship */
  featureFlag?: Maybe<MedspasFeatureflag>;
  featureFlagId?: Maybe<Scalars["bigint"]["output"]>;
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  omniContentPath?: Maybe<Scalars["String"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order: Scalars["Int"]["output"];
  reportCategoryId: Scalars["bigint"]["output"];
  signedIframeParams?: Maybe<Scalars["jsonb"]["output"]>;
  signedIframeUrl?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "medspas_report" */
export type ReportSignedIframeParamsArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "medspas_report" */
export type ReportSignedIframeUrlArgs = {
  medspaId?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "medspas_report" */
export type ReportAggregate = {
  __typename?: "reportAggregate";
  aggregate?: Maybe<ReportAggregateFields>;
  nodes: Array<Report>;
};

export type ReportAggregateBoolExp = {
  bool_and?: InputMaybe<ReportAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ReportAggregateBoolExpBool_Or>;
  count?: InputMaybe<ReportAggregateBoolExpCount>;
};

export type ReportAggregateBoolExpBool_And = {
  arguments: ReportSelectColumnReportAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ReportBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ReportAggregateBoolExpBool_Or = {
  arguments: ReportSelectColumnReportAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ReportBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ReportAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ReportSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ReportBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_report" */
export type ReportAggregateFields = {
  __typename?: "reportAggregateFields";
  avg?: Maybe<ReportAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ReportMaxFields>;
  min?: Maybe<ReportMinFields>;
  stddev?: Maybe<ReportStddevFields>;
  stddevPop?: Maybe<ReportStddevPopFields>;
  stddevSamp?: Maybe<ReportStddevSampFields>;
  sum?: Maybe<ReportSumFields>;
  varPop?: Maybe<ReportVarPopFields>;
  varSamp?: Maybe<ReportVarSampFields>;
  variance?: Maybe<ReportVarianceFields>;
};

/** aggregate fields of "medspas_report" */
export type ReportAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReportSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_report" */
export type ReportAggregateOrderBy = {
  avg?: InputMaybe<ReportAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReportMaxOrderBy>;
  min?: InputMaybe<ReportMinOrderBy>;
  stddev?: InputMaybe<ReportStddevOrderBy>;
  stddevPop?: InputMaybe<ReportStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ReportStddevSampOrderBy>;
  sum?: InputMaybe<ReportSumOrderBy>;
  varPop?: InputMaybe<ReportVarPopOrderBy>;
  varSamp?: InputMaybe<ReportVarSampOrderBy>;
  variance?: InputMaybe<ReportVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ReportAppendInput = {
  signedIframeParams?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "medspas_report" */
export type ReportArrRelInsertInput = {
  data: Array<ReportInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ReportOnConflict>;
};

/** aggregate avg on columns */
export type ReportAvgFields = {
  __typename?: "reportAvgFields";
  featureFlagId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  reportCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_report" */
export type ReportAvgOrderBy = {
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_report". All fields are combined with a logical 'AND'. */
export type ReportBoolExp = {
  _and?: InputMaybe<Array<ReportBoolExp>>;
  _not?: InputMaybe<ReportBoolExp>;
  _or?: InputMaybe<Array<ReportBoolExp>>;
  category?: InputMaybe<ReportCategoryBoolExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  dataSource?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  embedType?: InputMaybe<StringComparisonExp>;
  featureFlag?: InputMaybe<MedspasFeatureflagBoolExp>;
  featureFlagId?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  omniContentPath?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  reportCategoryId?: InputMaybe<BigintComparisonExp>;
  signedIframeParams?: InputMaybe<JsonbComparisonExp>;
};

/** columns and relationships of "medspas_reportcategory" */
export type ReportCategory = {
  __typename?: "reportCategory";
  created: Scalars["timestamptz"]["output"];
  deleted: Scalars["Boolean"]["output"];
  description: Scalars["String"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order: Scalars["Int"]["output"];
  /** An array relationship */
  reports: Array<Report>;
  /** An aggregate relationship */
  reportsAggregate: ReportAggregate;
  url: Scalars["String"]["output"];
};

/** columns and relationships of "medspas_reportcategory" */
export type ReportCategoryReportsArgs = {
  distinctOn?: InputMaybe<Array<ReportSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportOrderBy>>;
  where?: InputMaybe<ReportBoolExp>;
};

/** columns and relationships of "medspas_reportcategory" */
export type ReportCategoryReportsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportOrderBy>>;
  where?: InputMaybe<ReportBoolExp>;
};

/** aggregated selection of "medspas_reportcategory" */
export type ReportCategoryAggregate = {
  __typename?: "reportCategoryAggregate";
  aggregate?: Maybe<ReportCategoryAggregateFields>;
  nodes: Array<ReportCategory>;
};

/** aggregate fields of "medspas_reportcategory" */
export type ReportCategoryAggregateFields = {
  __typename?: "reportCategoryAggregateFields";
  avg?: Maybe<ReportCategoryAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ReportCategoryMaxFields>;
  min?: Maybe<ReportCategoryMinFields>;
  stddev?: Maybe<ReportCategoryStddevFields>;
  stddevPop?: Maybe<ReportCategoryStddevPopFields>;
  stddevSamp?: Maybe<ReportCategoryStddevSampFields>;
  sum?: Maybe<ReportCategorySumFields>;
  varPop?: Maybe<ReportCategoryVarPopFields>;
  varSamp?: Maybe<ReportCategoryVarSampFields>;
  variance?: Maybe<ReportCategoryVarianceFields>;
};

/** aggregate fields of "medspas_reportcategory" */
export type ReportCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReportCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type ReportCategoryAvgFields = {
  __typename?: "reportCategoryAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "medspas_reportcategory". All fields are combined with a logical 'AND'. */
export type ReportCategoryBoolExp = {
  _and?: InputMaybe<Array<ReportCategoryBoolExp>>;
  _not?: InputMaybe<ReportCategoryBoolExp>;
  _or?: InputMaybe<Array<ReportCategoryBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  icon?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  reports?: InputMaybe<ReportBoolExp>;
  reportsAggregate?: InputMaybe<ReportAggregateBoolExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "medspas_reportcategory" */
export enum ReportCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasReportcategoryPkey = "medspas_reportcategory_pkey",
}

/** input type for incrementing numeric columns in table "medspas_reportcategory" */
export type ReportCategoryIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "medspas_reportcategory" */
export type ReportCategoryInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  reports?: InputMaybe<ReportArrRelInsertInput>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ReportCategoryMaxFields = {
  __typename?: "reportCategoryMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type ReportCategoryMinFields = {
  __typename?: "reportCategoryMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "medspas_reportcategory" */
export type ReportCategoryMutationResponse = {
  __typename?: "reportCategoryMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ReportCategory>;
};

/** input type for inserting object relation for remote table "medspas_reportcategory" */
export type ReportCategoryObjRelInsertInput = {
  data: ReportCategoryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ReportCategoryOnConflict>;
};

/** on_conflict condition type for table "medspas_reportcategory" */
export type ReportCategoryOnConflict = {
  constraint: ReportCategoryConstraint;
  updateColumns?: Array<ReportCategoryUpdateColumn>;
  where?: InputMaybe<ReportCategoryBoolExp>;
};

/** Ordering options when selecting data from "medspas_reportcategory". */
export type ReportCategoryOrderBy = {
  created?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  reportsAggregate?: InputMaybe<ReportAggregateOrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_reportcategory */
export type ReportCategoryPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_reportcategory" */
export enum ReportCategorySelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Description = "description",
  /** column name */
  Icon = "icon",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  Url = "url",
}

/** input type for updating data in table "medspas_reportcategory" */
export type ReportCategorySetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type ReportCategoryStddevFields = {
  __typename?: "reportCategoryStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type ReportCategoryStddevPopFields = {
  __typename?: "reportCategoryStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type ReportCategoryStddevSampFields = {
  __typename?: "reportCategoryStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "reportCategory" */
export type ReportCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ReportCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ReportCategoryStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type ReportCategorySumFields = {
  __typename?: "reportCategorySumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "medspas_reportcategory" */
export enum ReportCategoryUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Description = "description",
  /** column name */
  Icon = "icon",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  Url = "url",
}

export type ReportCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReportCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportCategorySetInput>;
  /** filter the rows which have to be updated */
  where: ReportCategoryBoolExp;
};

/** aggregate varPop on columns */
export type ReportCategoryVarPopFields = {
  __typename?: "reportCategoryVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type ReportCategoryVarSampFields = {
  __typename?: "reportCategoryVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type ReportCategoryVarianceFields = {
  __typename?: "reportCategoryVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which report categories will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** unique or primary key constraints on table "medspas_report" */
export enum ReportConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasReportPkey = "medspas_report_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ReportDeleteAtPathInput = {
  signedIframeParams?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ReportDeleteElemInput = {
  signedIframeParams?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ReportDeleteKeyInput = {
  signedIframeParams?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for incrementing numeric columns in table "medspas_report" */
export type ReportIncInput = {
  featureFlagId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  reportCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_report" */
export type ReportInsertInput = {
  category?: InputMaybe<ReportCategoryObjRelInsertInput>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dataSource?: InputMaybe<Scalars["String"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedType?: InputMaybe<Scalars["String"]["input"]>;
  featureFlag?: InputMaybe<MedspasFeatureflagObjRelInsertInput>;
  featureFlagId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  omniContentPath?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  reportCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  signedIframeParams?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate max on columns */
export type ReportMaxFields = {
  __typename?: "reportMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  dataSource?: Maybe<Scalars["String"]["output"]>;
  embedType?: Maybe<Scalars["String"]["output"]>;
  featureFlagId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  omniContentPath?: Maybe<Scalars["String"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  reportCategoryId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspas_report" */
export type ReportMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  dataSource?: InputMaybe<OrderBy>;
  embedType?: InputMaybe<OrderBy>;
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  omniContentPath?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ReportMinFields = {
  __typename?: "reportMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  dataSource?: Maybe<Scalars["String"]["output"]>;
  embedType?: Maybe<Scalars["String"]["output"]>;
  featureFlagId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  omniContentPath?: Maybe<Scalars["String"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  reportCategoryId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspas_report" */
export type ReportMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  dataSource?: InputMaybe<OrderBy>;
  embedType?: InputMaybe<OrderBy>;
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  omniContentPath?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_report" */
export type ReportMutationResponse = {
  __typename?: "reportMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Report>;
};

/** on_conflict condition type for table "medspas_report" */
export type ReportOnConflict = {
  constraint: ReportConstraint;
  updateColumns?: Array<ReportUpdateColumn>;
  where?: InputMaybe<ReportBoolExp>;
};

/** Ordering options when selecting data from "medspas_report". */
export type ReportOrderBy = {
  category?: InputMaybe<ReportCategoryOrderBy>;
  created?: InputMaybe<OrderBy>;
  dataSource?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  embedType?: InputMaybe<OrderBy>;
  featureFlag?: InputMaybe<MedspasFeatureflagOrderBy>;
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  omniContentPath?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
  signedIframeParams?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_report */
export type ReportPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ReportPrependInput = {
  signedIframeParams?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "medspas_report" */
export enum ReportSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DataSource = "dataSource",
  /** column name */
  Deleted = "deleted",
  /** column name */
  EmbedType = "embedType",
  /** column name */
  FeatureFlagId = "featureFlagId",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  OmniContentPath = "omniContentPath",
  /** column name */
  Order = "order",
  /** column name */
  ReportCategoryId = "reportCategoryId",
  /** column name */
  SignedIframeParams = "signedIframeParams",
}

/** select "reportAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_report" */
export enum ReportSelectColumnReportAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** select "reportAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_report" */
export enum ReportSelectColumnReportAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** input type for updating data in table "medspas_report" */
export type ReportSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dataSource?: InputMaybe<Scalars["String"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedType?: InputMaybe<Scalars["String"]["input"]>;
  featureFlagId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  omniContentPath?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  reportCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  signedIframeParams?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate stddev on columns */
export type ReportStddevFields = {
  __typename?: "reportStddevFields";
  featureFlagId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  reportCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_report" */
export type ReportStddevOrderBy = {
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ReportStddevPopFields = {
  __typename?: "reportStddevPopFields";
  featureFlagId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  reportCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_report" */
export type ReportStddevPopOrderBy = {
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ReportStddevSampFields = {
  __typename?: "reportStddevSampFields";
  featureFlagId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  reportCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_report" */
export type ReportStddevSampOrderBy = {
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "report" */
export type ReportStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ReportStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ReportStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dataSource?: InputMaybe<Scalars["String"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedType?: InputMaybe<Scalars["String"]["input"]>;
  featureFlagId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  omniContentPath?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  reportCategoryId?: InputMaybe<Scalars["bigint"]["input"]>;
  signedIframeParams?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate sum on columns */
export type ReportSumFields = {
  __typename?: "reportSumFields";
  featureFlagId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
  reportCategoryId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_report" */
export type ReportSumOrderBy = {
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_report" */
export enum ReportUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DataSource = "dataSource",
  /** column name */
  Deleted = "deleted",
  /** column name */
  EmbedType = "embedType",
  /** column name */
  FeatureFlagId = "featureFlagId",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  OmniContentPath = "omniContentPath",
  /** column name */
  Order = "order",
  /** column name */
  ReportCategoryId = "reportCategoryId",
  /** column name */
  SignedIframeParams = "signedIframeParams",
}

export type ReportUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ReportAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<ReportDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<ReportDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<ReportDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReportIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ReportPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportSetInput>;
  /** filter the rows which have to be updated */
  where: ReportBoolExp;
};

/** aggregate varPop on columns */
export type ReportVarPopFields = {
  __typename?: "reportVarPopFields";
  featureFlagId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  reportCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_report" */
export type ReportVarPopOrderBy = {
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ReportVarSampFields = {
  __typename?: "reportVarSampFields";
  featureFlagId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  reportCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_report" */
export type ReportVarSampOrderBy = {
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ReportVarianceFields = {
  __typename?: "reportVarianceFields";
  featureFlagId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
  reportCategoryId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_report" */
export type ReportVarianceOrderBy = {
  featureFlagId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Order in which reports will be displayed on the Django Admin (lower value comes first) */
  order?: InputMaybe<OrderBy>;
  reportCategoryId?: InputMaybe<OrderBy>;
};

export type ResourcesMedspaRoomAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ResourcesMedspaRoomBoolExp>;
  predicate: IntComparisonExp;
};

export type ScriptRequestStatusChangeLogAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ScriptRequestStatusChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "resources_servicecategory" */
export type ServiceCategory = {
  __typename?: "serviceCategory";
  created: Scalars["timestamptz"]["output"];
  deleted: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaServiceMenuItems: Array<MedspaServiceMenuItem>;
  /** An aggregate relationship */
  medspaServiceMenuItemsAggregate: MedspaServiceMenuItemAggregate;
  modified: Scalars["timestamptz"]["output"];
  name: Scalars["String"]["output"];
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order: Scalars["Int"]["output"];
};

/** columns and relationships of "resources_servicecategory" */
export type ServiceCategoryMedspaServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** columns and relationships of "resources_servicecategory" */
export type ServiceCategoryMedspaServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

/** aggregated selection of "resources_servicecategory" */
export type ServiceCategoryAggregate = {
  __typename?: "serviceCategoryAggregate";
  aggregate?: Maybe<ServiceCategoryAggregateFields>;
  nodes: Array<ServiceCategory>;
};

export type ServiceCategoryAggregateBoolExp = {
  bool_and?: InputMaybe<ServiceCategoryAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ServiceCategoryAggregateBoolExpBool_Or>;
  count?: InputMaybe<ServiceCategoryAggregateBoolExpCount>;
};

export type ServiceCategoryAggregateBoolExpBool_And = {
  arguments: ServiceCategorySelectColumnServiceCategoryAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceCategoryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceCategoryAggregateBoolExpBool_Or = {
  arguments: ServiceCategorySelectColumnServiceCategoryAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceCategoryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "resources_servicecategory" */
export type ServiceCategoryAggregateFields = {
  __typename?: "serviceCategoryAggregateFields";
  avg?: Maybe<ServiceCategoryAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<ServiceCategoryMaxFields>;
  min?: Maybe<ServiceCategoryMinFields>;
  stddev?: Maybe<ServiceCategoryStddevFields>;
  stddevPop?: Maybe<ServiceCategoryStddevPopFields>;
  stddevSamp?: Maybe<ServiceCategoryStddevSampFields>;
  sum?: Maybe<ServiceCategorySumFields>;
  varPop?: Maybe<ServiceCategoryVarPopFields>;
  varSamp?: Maybe<ServiceCategoryVarSampFields>;
  variance?: Maybe<ServiceCategoryVarianceFields>;
};

/** aggregate fields of "resources_servicecategory" */
export type ServiceCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServiceCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "resources_servicecategory" */
export type ServiceCategoryAggregateOrderBy = {
  avg?: InputMaybe<ServiceCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceCategoryMaxOrderBy>;
  min?: InputMaybe<ServiceCategoryMinOrderBy>;
  stddev?: InputMaybe<ServiceCategoryStddevOrderBy>;
  stddevPop?: InputMaybe<ServiceCategoryStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ServiceCategoryStddevSampOrderBy>;
  sum?: InputMaybe<ServiceCategorySumOrderBy>;
  varPop?: InputMaybe<ServiceCategoryVarPopOrderBy>;
  varSamp?: InputMaybe<ServiceCategoryVarSampOrderBy>;
  variance?: InputMaybe<ServiceCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "resources_servicecategory" */
export type ServiceCategoryArrRelInsertInput = {
  data: Array<ServiceCategoryInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceCategoryOnConflict>;
};

/** aggregate avg on columns */
export type ServiceCategoryAvgFields = {
  __typename?: "serviceCategoryAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "resources_servicecategory" */
export type ServiceCategoryAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "resources_servicecategory". All fields are combined with a logical 'AND'. */
export type ServiceCategoryBoolExp = {
  _and?: InputMaybe<Array<ServiceCategoryBoolExp>>;
  _not?: InputMaybe<ServiceCategoryBoolExp>;
  _or?: InputMaybe<Array<ServiceCategoryBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaServiceMenuItems?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  medspaServiceMenuItemsAggregate?: InputMaybe<MedspaServiceMenuItemAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "resources_servicecategory" */
export enum ServiceCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesServicecategoryPkey = "resources_servicecategory_pkey",
}

/** input type for incrementing numeric columns in table "resources_servicecategory" */
export type ServiceCategoryIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "resources_servicecategory" */
export type ServiceCategoryInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceMenuItems?: InputMaybe<MedspaServiceMenuItemArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type ServiceCategoryMaxFields = {
  __typename?: "serviceCategoryMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** order by max() on columns of table "resources_servicecategory" */
export type ServiceCategoryMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ServiceCategoryMinFields = {
  __typename?: "serviceCategoryMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** order by min() on columns of table "resources_servicecategory" */
export type ServiceCategoryMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "resources_servicecategory" */
export type ServiceCategoryMutationResponse = {
  __typename?: "serviceCategoryMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceCategory>;
};

/** input type for inserting object relation for remote table "resources_servicecategory" */
export type ServiceCategoryObjRelInsertInput = {
  data: ServiceCategoryInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ServiceCategoryOnConflict>;
};

/** on_conflict condition type for table "resources_servicecategory" */
export type ServiceCategoryOnConflict = {
  constraint: ServiceCategoryConstraint;
  updateColumns?: Array<ServiceCategoryUpdateColumn>;
  where?: InputMaybe<ServiceCategoryBoolExp>;
};

/** Ordering options when selecting data from "resources_servicecategory". */
export type ServiceCategoryOrderBy = {
  created?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaServiceMenuItemsAggregate?: InputMaybe<MedspaServiceMenuItemAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources_servicecategory */
export type ServiceCategoryPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "resources_servicecategory" */
export enum ServiceCategorySelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
}

/** select "serviceCategoryAggregateBoolExpBool_andArgumentsColumns" columns of table "resources_servicecategory" */
export enum ServiceCategorySelectColumnServiceCategoryAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** select "serviceCategoryAggregateBoolExpBool_orArgumentsColumns" columns of table "resources_servicecategory" */
export enum ServiceCategorySelectColumnServiceCategoryAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Deleted = "deleted",
}

/** input type for updating data in table "resources_servicecategory" */
export type ServiceCategorySetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type ServiceCategoryStddevFields = {
  __typename?: "serviceCategoryStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "resources_servicecategory" */
export type ServiceCategoryStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ServiceCategoryStddevPopFields = {
  __typename?: "serviceCategoryStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "resources_servicecategory" */
export type ServiceCategoryStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ServiceCategoryStddevSampFields = {
  __typename?: "serviceCategoryStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "resources_servicecategory" */
export type ServiceCategoryStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "serviceCategory" */
export type ServiceCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ServiceCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceCategoryStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type ServiceCategorySumFields = {
  __typename?: "serviceCategorySumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "resources_servicecategory" */
export type ServiceCategorySumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** update columns of table "resources_servicecategory" */
export enum ServiceCategoryUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Deleted = "deleted",
  /** column name */
  Id = "id",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
}

export type ServiceCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceCategorySetInput>;
  /** filter the rows which have to be updated */
  where: ServiceCategoryBoolExp;
};

/** aggregate varPop on columns */
export type ServiceCategoryVarPopFields = {
  __typename?: "serviceCategoryVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "resources_servicecategory" */
export type ServiceCategoryVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ServiceCategoryVarSampFields = {
  __typename?: "serviceCategoryVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "resources_servicecategory" */
export type ServiceCategoryVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ServiceCategoryVarianceFields = {
  __typename?: "serviceCategoryVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "resources_servicecategory" */
export type ServiceCategoryVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Order in which the categories are displayed in booking page (lower value comes first) */
  order?: InputMaybe<OrderBy>;
};

export type ServiceDeviceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceDeviceSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceDeviceBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceDevicesAppointmentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceDevicesServiceTypesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceMenuItemLineAggregateBoolExpBool_And = {
  arguments: ServiceMenuItemLineSelectColumnServiceMenuItemLineAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceMenuItemLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceMenuItemLineAggregateBoolExpBool_Or = {
  arguments: ServiceMenuItemLineSelectColumnServiceMenuItemLineAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceMenuItemLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceMenuItemLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceMenuItemLineBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceMenuItemLineStandingOrdersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceProductAggregateBoolExpBool_And = {
  arguments: ServiceProductSelectColumnServiceProductAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceProductAggregateBoolExpBool_Or = {
  arguments: ServiceProductSelectColumnServiceProductAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceProductAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceProductSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceProductChartingNoteAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceProductLineAggregateBoolExpBool_And = {
  arguments: ServiceProductLineSelectColumnServiceProductLineAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceProductLineAggregateBoolExpBool_Or = {
  arguments: ServiceProductLineSelectColumnServiceProductLineAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductLineBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceProductLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductLineBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceProductTypeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceProductTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductTypeBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceProductUsageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProductUsageBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceProtocolAggregateBoolExpBool_And = {
  arguments: ServiceProtocolSelectColumnServiceProtocolAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProtocolBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceProtocolAggregateBoolExpBool_Or = {
  arguments: ServiceProtocolSelectColumnServiceProtocolAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProtocolBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceProtocolAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceProtocolBoolExp>;
  predicate: IntComparisonExp;
};

export type ServiceTypeAggregateBoolExpBool_And = {
  arguments: ServiceTypeSelectColumnServiceTypeAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceTypeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceTypeAggregateBoolExpBool_Or = {
  arguments: ServiceTypeSelectColumnServiceTypeAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceTypeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ServiceTypeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<ServiceTypeBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "utils_shortmoxieurl" */
export type ShortMoxieUrl = {
  __typename?: "shortMoxieUrl";
  created: Scalars["timestamptz"]["output"];
  fullUrl: Scalars["String"]["output"];
  modified: Scalars["timestamptz"]["output"];
  uuid: Scalars["String"]["output"];
};

/** aggregated selection of "utils_shortmoxieurl" */
export type ShortMoxieUrlAggregate = {
  __typename?: "shortMoxieUrlAggregate";
  aggregate?: Maybe<ShortMoxieUrlAggregateFields>;
  nodes: Array<ShortMoxieUrl>;
};

/** aggregate fields of "utils_shortmoxieurl" */
export type ShortMoxieUrlAggregateFields = {
  __typename?: "shortMoxieUrlAggregateFields";
  count: Scalars["Int"]["output"];
  max?: Maybe<ShortMoxieUrlMaxFields>;
  min?: Maybe<ShortMoxieUrlMinFields>;
};

/** aggregate fields of "utils_shortmoxieurl" */
export type ShortMoxieUrlAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ShortMoxieUrlSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "utils_shortmoxieurl". All fields are combined with a logical 'AND'. */
export type ShortMoxieUrlBoolExp = {
  _and?: InputMaybe<Array<ShortMoxieUrlBoolExp>>;
  _not?: InputMaybe<ShortMoxieUrlBoolExp>;
  _or?: InputMaybe<Array<ShortMoxieUrlBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  fullUrl?: InputMaybe<StringComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  uuid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "utils_shortmoxieurl" */
export enum ShortMoxieUrlConstraint {
  /** unique or primary key constraint on columns "full_url" */
  UtilsShortmoxieurlFullUrl_76f98ee1Uniq = "utils_shortmoxieurl_full_url_76f98ee1_uniq",
  /** unique or primary key constraint on columns "uuid" */
  UtilsShortmoxieurlPkey = "utils_shortmoxieurl_pkey",
}

/** input type for inserting data into table "utils_shortmoxieurl" */
export type ShortMoxieUrlInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fullUrl?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type ShortMoxieUrlMaxFields = {
  __typename?: "shortMoxieUrlMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  fullUrl?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type ShortMoxieUrlMinFields = {
  __typename?: "shortMoxieUrlMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  fullUrl?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "utils_shortmoxieurl" */
export type ShortMoxieUrlMutationResponse = {
  __typename?: "shortMoxieUrlMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<ShortMoxieUrl>;
};

/** on_conflict condition type for table "utils_shortmoxieurl" */
export type ShortMoxieUrlOnConflict = {
  constraint: ShortMoxieUrlConstraint;
  updateColumns?: Array<ShortMoxieUrlUpdateColumn>;
  where?: InputMaybe<ShortMoxieUrlBoolExp>;
};

/** Ordering options when selecting data from "utils_shortmoxieurl". */
export type ShortMoxieUrlOrderBy = {
  created?: InputMaybe<OrderBy>;
  fullUrl?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  uuid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: utils_shortmoxieurl */
export type ShortMoxieUrlPkColumnsInput = {
  uuid: Scalars["String"]["input"];
};

/** select columns of table "utils_shortmoxieurl" */
export enum ShortMoxieUrlSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  FullUrl = "fullUrl",
  /** column name */
  Modified = "modified",
  /** column name */
  Uuid = "uuid",
}

/** input type for updating data in table "utils_shortmoxieurl" */
export type ShortMoxieUrlSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fullUrl?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "shortMoxieUrl" */
export type ShortMoxieUrlStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ShortMoxieUrlStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ShortMoxieUrlStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fullUrl?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "utils_shortmoxieurl" */
export enum ShortMoxieUrlUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  FullUrl = "fullUrl",
  /** column name */
  Modified = "modified",
  /** column name */
  Uuid = "uuid",
}

export type ShortMoxieUrlUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShortMoxieUrlSetInput>;
  /** filter the rows which have to be updated */
  where: ShortMoxieUrlBoolExp;
};

/** columns and relationships of "medspas_standingorder" */
export type StandingOrder = {
  __typename?: "standingOrder";
  created: Scalars["timestamptz"]["output"];
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  file: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  /** An object relationship */
  provider: UserMedspa;
  providerId: Scalars["bigint"]["output"];
  /** An object relationship */
  uploadedBy?: Maybe<UserMedspa>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "medspas_standingorder" */
export type StandingOrderAggregate = {
  __typename?: "standingOrderAggregate";
  aggregate?: Maybe<StandingOrderAggregateFields>;
  nodes: Array<StandingOrder>;
};

export type StandingOrderAggregateBoolExp = {
  count?: InputMaybe<StandingOrderAggregateBoolExpCount>;
};

export type StandingOrderAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<StandingOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<StandingOrderBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_standingorder" */
export type StandingOrderAggregateFields = {
  __typename?: "standingOrderAggregateFields";
  avg?: Maybe<StandingOrderAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<StandingOrderMaxFields>;
  min?: Maybe<StandingOrderMinFields>;
  stddev?: Maybe<StandingOrderStddevFields>;
  stddevPop?: Maybe<StandingOrderStddevPopFields>;
  stddevSamp?: Maybe<StandingOrderStddevSampFields>;
  sum?: Maybe<StandingOrderSumFields>;
  varPop?: Maybe<StandingOrderVarPopFields>;
  varSamp?: Maybe<StandingOrderVarSampFields>;
  variance?: Maybe<StandingOrderVarianceFields>;
};

/** aggregate fields of "medspas_standingorder" */
export type StandingOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StandingOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_standingorder" */
export type StandingOrderAggregateOrderBy = {
  avg?: InputMaybe<StandingOrderAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<StandingOrderMaxOrderBy>;
  min?: InputMaybe<StandingOrderMinOrderBy>;
  stddev?: InputMaybe<StandingOrderStddevOrderBy>;
  stddevPop?: InputMaybe<StandingOrderStddevPopOrderBy>;
  stddevSamp?: InputMaybe<StandingOrderStddevSampOrderBy>;
  sum?: InputMaybe<StandingOrderSumOrderBy>;
  varPop?: InputMaybe<StandingOrderVarPopOrderBy>;
  varSamp?: InputMaybe<StandingOrderVarSampOrderBy>;
  variance?: InputMaybe<StandingOrderVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_standingorder" */
export type StandingOrderArrRelInsertInput = {
  data: Array<StandingOrderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<StandingOrderOnConflict>;
};

/** aggregate avg on columns */
export type StandingOrderAvgFields = {
  __typename?: "standingOrderAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_standingorder" */
export type StandingOrderAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_standingorder". All fields are combined with a logical 'AND'. */
export type StandingOrderBoolExp = {
  _and?: InputMaybe<Array<StandingOrderBoolExp>>;
  _not?: InputMaybe<StandingOrderBoolExp>;
  _or?: InputMaybe<Array<StandingOrderBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  file?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  uploadedBy?: InputMaybe<UserMedspaBoolExp>;
  uploadedById?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspas_standingorder" */
export enum StandingOrderConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasStandingorderPkey = "medspas_standingorder_pkey",
}

/** input type for incrementing numeric columns in table "medspas_standingorder" */
export type StandingOrderIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_standingorder" */
export type StandingOrderInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  file?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedBy?: InputMaybe<UserMedspaObjRelInsertInput>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type StandingOrderMaxFields = {
  __typename?: "standingOrderMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  file?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspas_standingorder" */
export type StandingOrderMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  file?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type StandingOrderMinFields = {
  __typename?: "standingOrderMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  file?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspas_standingorder" */
export type StandingOrderMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  file?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "medspas_standingorder" */
export type StandingOrderMutationResponse = {
  __typename?: "standingOrderMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<StandingOrder>;
};

/** on_conflict condition type for table "medspas_standingorder" */
export type StandingOrderOnConflict = {
  constraint: StandingOrderConstraint;
  updateColumns?: Array<StandingOrderUpdateColumn>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

/** Ordering options when selecting data from "medspas_standingorder". */
export type StandingOrderOrderBy = {
  created?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  file?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedBy?: InputMaybe<UserMedspaOrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_standingorder */
export type StandingOrderPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_standingorder" */
export enum StandingOrderSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  UploadedById = "uploadedById",
}

/** input type for updating data in table "medspas_standingorder" */
export type StandingOrderSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  file?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type StandingOrderStddevFields = {
  __typename?: "standingOrderStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_standingorder" */
export type StandingOrderStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type StandingOrderStddevPopFields = {
  __typename?: "standingOrderStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_standingorder" */
export type StandingOrderStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type StandingOrderStddevSampFields = {
  __typename?: "standingOrderStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_standingorder" */
export type StandingOrderStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "standingOrder" */
export type StandingOrderStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StandingOrderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StandingOrderStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  file?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedById?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type StandingOrderSumFields = {
  __typename?: "standingOrderSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  uploadedById?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_standingorder" */
export type StandingOrderSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_standingorder" */
export enum StandingOrderUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  File = "file",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  UploadedById = "uploadedById",
}

export type StandingOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StandingOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StandingOrderSetInput>;
  /** filter the rows which have to be updated */
  where: StandingOrderBoolExp;
};

/** aggregate varPop on columns */
export type StandingOrderVarPopFields = {
  __typename?: "standingOrderVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_standingorder" */
export type StandingOrderVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type StandingOrderVarSampFields = {
  __typename?: "standingOrderVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_standingorder" */
export type StandingOrderVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type StandingOrderVarianceFields = {
  __typename?: "standingOrderVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
  uploadedById?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_standingorder" */
export type StandingOrderVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  uploadedById?: InputMaybe<OrderBy>;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "utils_address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "utils_address" */
  addressAggregate: AddressAggregate;
  /** fetch data from the table: "utils_address" using primary key columns */
  addressByPk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "utils_address" */
  addressStream: Array<Address>;
  /** fetch data from the table: "administrative_division" */
  administrativeDivision: Array<AdministrativeDivision>;
  /** fetch aggregated fields from the table: "administrative_division" */
  administrativeDivisionAggregate: AdministrativeDivisionAggregate;
  /** fetch data from the table: "administrative_division" using primary key columns */
  administrativeDivisionByPk?: Maybe<AdministrativeDivision>;
  /** fetch data from the table in a streaming manner: "administrative_division" */
  administrativeDivisionStream: Array<AdministrativeDivision>;
  /** fetch data from the table: "adverse_reaction" */
  adverseReaction: Array<AdverseReaction>;
  /** fetch aggregated fields from the table: "adverse_reaction" */
  adverseReactionAggregate: AdverseReactionAggregate;
  /** fetch data from the table: "adverse_reaction" using primary key columns */
  adverseReactionByPk?: Maybe<AdverseReaction>;
  /** fetch data from the table in a streaming manner: "adverse_reaction" */
  adverseReactionStream: Array<AdverseReaction>;
  /** fetch data from the table: "adverse_reaction_template" */
  adverseReactionTemplate: Array<AdverseReactionTemplate>;
  /** fetch aggregated fields from the table: "adverse_reaction_template" */
  adverseReactionTemplateAggregate: AdverseReactionTemplateAggregate;
  /** fetch data from the table: "adverse_reaction_template" using primary key columns */
  adverseReactionTemplateByPk?: Maybe<AdverseReactionTemplate>;
  /** fetch data from the table in a streaming manner: "adverse_reaction_template" */
  adverseReactionTemplateStream: Array<AdverseReactionTemplate>;
  /** fetch data from the table: "scheduling_availabilityevent" */
  availabilityEvent: Array<AvailabilityEvent>;
  /** fetch aggregated fields from the table: "scheduling_availabilityevent" */
  availabilityEventAggregate: AvailabilityEventAggregate;
  /** fetch data from the table: "scheduling_availabilityevent" using primary key columns */
  availabilityEventByPk?: Maybe<AvailabilityEvent>;
  /** fetch data from the table in a streaming manner: "scheduling_availabilityevent" */
  availabilityEventStream: Array<AvailabilityEvent>;
  /** fetch data from the table: "resources_careform" */
  careForm: Array<CareForm>;
  /** fetch aggregated fields from the table: "resources_careform" */
  careFormAggregate: CareFormAggregate;
  /** fetch data from the table: "resources_careform" using primary key columns */
  careFormByPk?: Maybe<CareForm>;
  /** An array relationship */
  careFormServiceMenuItems: Array<CareFormServiceMenuItems>;
  /** An aggregate relationship */
  careFormServiceMenuItemsAggregate: CareFormServiceMenuItemsAggregate;
  /** fetch data from the table: "resources_careform_service_menu_items" using primary key columns */
  careFormServiceMenuItemsByPk?: Maybe<CareFormServiceMenuItems>;
  /** fetch data from the table in a streaming manner: "resources_careform_service_menu_items" */
  careFormServiceMenuItemsStream: Array<CareFormServiceMenuItems>;
  /** fetch data from the table in a streaming manner: "resources_careform" */
  careFormStream: Array<CareForm>;
  /** fetch data from the table: "medspas_client" */
  client: Array<Client>;
  /** fetch data from the table: "medspas_clientaccesstoken" */
  clientAccessToken: Array<ClientAccessToken>;
  /** fetch aggregated fields from the table: "medspas_clientaccesstoken" */
  clientAccessTokenAggregate: ClientAccessTokenAggregate;
  /** fetch data from the table: "medspas_clientaccesstoken" using primary key columns */
  clientAccessTokenByPk?: Maybe<ClientAccessToken>;
  /** fetch data from the table in a streaming manner: "medspas_clientaccesstoken" */
  clientAccessTokenStream: Array<ClientAccessToken>;
  /** fetch aggregated fields from the table: "medspas_client" */
  clientAggregate: ClientAggregate;
  /** fetch data from the table: "medspas_client" using primary key columns */
  clientByPk?: Maybe<Client>;
  /** fetch data from the table: "client_membership" */
  clientMembership: Array<ClientMembership>;
  /** fetch aggregated fields from the table: "client_membership" */
  clientMembershipAggregate: ClientMembershipAggregate;
  /** fetch data from the table: "client_membership" using primary key columns */
  clientMembershipByPk?: Maybe<ClientMembership>;
  /** fetch data from the table in a streaming manner: "client_membership" */
  clientMembershipStream: Array<ClientMembership>;
  /** fetch data from the table in a streaming manner: "medspas_client" */
  clientStream: Array<Client>;
  /** fetch data from the table: "compliance_task" */
  complianceTask: Array<ComplianceTask>;
  /** fetch aggregated fields from the table: "compliance_task" */
  complianceTaskAggregate: ComplianceTaskAggregate;
  /** fetch data from the table: "compliance_task" using primary key columns */
  complianceTaskByPk?: Maybe<ComplianceTask>;
  /** fetch data from the table: "compliance_task_document" */
  complianceTaskDocument: Array<ComplianceTaskDocument>;
  /** fetch aggregated fields from the table: "compliance_task_document" */
  complianceTaskDocumentAggregate: ComplianceTaskDocumentAggregate;
  /** fetch data from the table: "compliance_task_document" using primary key columns */
  complianceTaskDocumentByPk?: Maybe<ComplianceTaskDocument>;
  /** fetch data from the table in a streaming manner: "compliance_task_document" */
  complianceTaskDocumentStream: Array<ComplianceTaskDocument>;
  /** fetch data from the table in a streaming manner: "compliance_task" */
  complianceTaskStream: Array<ComplianceTask>;
  /** fetch data from the table: "compliance_task_template" */
  complianceTaskTemplate: Array<ComplianceTaskTemplate>;
  /** fetch aggregated fields from the table: "compliance_task_template" */
  complianceTaskTemplateAggregate: ComplianceTaskTemplateAggregate;
  /** fetch data from the table: "compliance_task_template" using primary key columns */
  complianceTaskTemplateByPk?: Maybe<ComplianceTaskTemplate>;
  /** fetch data from the table in a streaming manner: "compliance_task_template" */
  complianceTaskTemplateStream: Array<ComplianceTaskTemplate>;
  /** fetch data from the table: "compliant_note" */
  compliantNote: Array<CompliantNote>;
  /** fetch aggregated fields from the table: "compliant_note" */
  compliantNoteAggregate: CompliantNoteAggregate;
  /** fetch data from the table: "compliant_note" using primary key columns */
  compliantNoteByPk?: Maybe<CompliantNote>;
  /** fetch data from the table in a streaming manner: "compliant_note" */
  compliantNoteStream: Array<CompliantNote>;
  /** fetch data from the table: "compliant_note_template" */
  compliantNoteTemplate: Array<CompliantNoteTemplate>;
  /** fetch aggregated fields from the table: "compliant_note_template" */
  compliantNoteTemplateAggregate: CompliantNoteTemplateAggregate;
  /** fetch data from the table: "compliant_note_template" using primary key columns */
  compliantNoteTemplateByPk?: Maybe<CompliantNoteTemplate>;
  /** fetch data from the table in a streaming manner: "compliant_note_template" */
  compliantNoteTemplateStream: Array<CompliantNoteTemplate>;
  /** fetch data from the table: "resources_consentform" */
  consentForm: Array<ConsentForm>;
  /** fetch aggregated fields from the table: "resources_consentform" */
  consentFormAggregate: ConsentFormAggregate;
  /** fetch data from the table: "resources_consentform" using primary key columns */
  consentFormByPk?: Maybe<ConsentForm>;
  /** An array relationship */
  consentFormServiceMenuItems: Array<ConsentFormServiceMenuItems>;
  /** An aggregate relationship */
  consentFormServiceMenuItemsAggregate: ConsentFormServiceMenuItemsAggregate;
  /** fetch data from the table: "resources_consentform_service_menu_items" using primary key columns */
  consentFormServiceMenuItemsByPk?: Maybe<ConsentFormServiceMenuItems>;
  /** fetch data from the table in a streaming manner: "resources_consentform_service_menu_items" */
  consentFormServiceMenuItemsStream: Array<ConsentFormServiceMenuItems>;
  /** fetch data from the table: "medspas_consentformsignature" */
  consentFormSignature: Array<ConsentFormSignature>;
  /** fetch aggregated fields from the table: "medspas_consentformsignature" */
  consentFormSignatureAggregate: ConsentFormSignatureAggregate;
  /** fetch data from the table: "medspas_consentformsignature" using primary key columns */
  consentFormSignatureByPk?: Maybe<ConsentFormSignature>;
  /** fetch data from the table in a streaming manner: "medspas_consentformsignature" */
  consentFormSignatureStream: Array<ConsentFormSignature>;
  /** fetch data from the table in a streaming manner: "resources_consentform" */
  consentFormStream: Array<ConsentForm>;
  /** fetch data from the table: "deposit_line" */
  depositLine: Array<DepositLine>;
  /** fetch aggregated fields from the table: "deposit_line" */
  depositLineAggregate: DepositLineAggregate;
  /** fetch data from the table: "deposit_line" using primary key columns */
  depositLineByPk?: Maybe<DepositLine>;
  /** fetch data from the table in a streaming manner: "deposit_line" */
  depositLineStream: Array<DepositLine>;
  /** fetch data from the table: "visits_diagram" */
  diagram: Array<Diagram>;
  /** fetch aggregated fields from the table: "visits_diagram" */
  diagramAggregate: DiagramAggregate;
  /** fetch data from the table: "visits_diagram" using primary key columns */
  diagramByPk?: Maybe<Diagram>;
  /** fetch data from the table in a streaming manner: "visits_diagram" */
  diagramStream: Array<Diagram>;
  /** fetch data from the table: "resources_diagramtemplate" */
  diagramTemplate: Array<DiagramTemplate>;
  /** fetch aggregated fields from the table: "resources_diagramtemplate" */
  diagramTemplateAggregate: DiagramTemplateAggregate;
  /** fetch data from the table: "resources_diagramtemplate" using primary key columns */
  diagramTemplateByPk?: Maybe<DiagramTemplate>;
  /** fetch data from the table in a streaming manner: "resources_diagramtemplate" */
  diagramTemplateStream: Array<DiagramTemplate>;
  /** fetch data from the table: "resources_discount" */
  discount: Array<Discount>;
  /** fetch aggregated fields from the table: "resources_discount" */
  discountAggregate: DiscountAggregate;
  /** fetch data from the table: "resources_discount" using primary key columns */
  discountByPk?: Maybe<Discount>;
  /** An array relationship */
  discountLine: Array<DiscountLine>;
  /** An aggregate relationship */
  discountLineAggregate: DiscountLineAggregate;
  /** fetch data from the table: "visits_discountline" using primary key columns */
  discountLineByPk?: Maybe<DiscountLine>;
  /** fetch data from the table in a streaming manner: "visits_discountline" */
  discountLineStream: Array<DiscountLine>;
  /** An array relationship */
  discountRetailProducts: Array<DiscountRetailProducts>;
  /** An aggregate relationship */
  discountRetailProductsAggregate: DiscountRetailProductsAggregate;
  /** fetch data from the table: "discount_product" using primary key columns */
  discountRetailProductsByPk?: Maybe<DiscountRetailProducts>;
  /** fetch data from the table in a streaming manner: "discount_product" */
  discountRetailProductsStream: Array<DiscountRetailProducts>;
  /** An array relationship */
  discountServiceMenuItems: Array<DiscountServiceMenuItems>;
  /** An aggregate relationship */
  discountServiceMenuItemsAggregate: DiscountServiceMenuItemsAggregate;
  /** fetch data from the table: "resources_discount_service_menu_items" using primary key columns */
  discountServiceMenuItemsByPk?: Maybe<DiscountServiceMenuItems>;
  /** fetch data from the table in a streaming manner: "resources_discount_service_menu_items" */
  discountServiceMenuItemsStream: Array<DiscountServiceMenuItems>;
  /** An array relationship */
  discountServiceProducts: Array<DiscountServiceProducts>;
  /** An aggregate relationship */
  discountServiceProductsAggregate: DiscountServiceProductsAggregate;
  /** fetch data from the table: "resources_discount_service_products" using primary key columns */
  discountServiceProductsByPk?: Maybe<DiscountServiceProducts>;
  /** fetch data from the table in a streaming manner: "resources_discount_service_products" */
  discountServiceProductsStream: Array<DiscountServiceProducts>;
  /** fetch data from the table in a streaming manner: "resources_discount" */
  discountStream: Array<Discount>;
  /** fetch data from the table: "feature_permission" */
  featurePermission: Array<FeaturePermission>;
  /** fetch aggregated fields from the table: "feature_permission" */
  featurePermissionAggregate: FeaturePermissionAggregate;
  /** fetch data from the table: "feature_permission" using primary key columns */
  featurePermissionByPk?: Maybe<FeaturePermission>;
  /** fetch data from the table in a streaming manner: "feature_permission" */
  featurePermissionStream: Array<FeaturePermission>;
  /** fetch data from the table: "resources_fee" */
  fee: Array<Fee>;
  /** fetch aggregated fields from the table: "resources_fee" */
  feeAggregate: FeeAggregate;
  /** fetch data from the table: "resources_fee" using primary key columns */
  feeByPk?: Maybe<Fee>;
  /** fetch data from the table: "visits_feeline" */
  feeLine: Array<FeeLine>;
  /** fetch aggregated fields from the table: "visits_feeline" */
  feeLineAggregate: FeeLineAggregate;
  /** fetch data from the table: "visits_feeline" using primary key columns */
  feeLineByPk?: Maybe<FeeLine>;
  /** fetch data from the table in a streaming manner: "visits_feeline" */
  feeLineStream: Array<FeeLine>;
  /** fetch data from the table in a streaming manner: "resources_fee" */
  feeStream: Array<Fee>;
  /** fetch data from the table: "forms_form" */
  form: Array<Form>;
  /** fetch aggregated fields from the table: "forms_form" */
  formAggregate: FormAggregate;
  /** fetch data from the table: "forms_form" using primary key columns */
  formByPk?: Maybe<Form>;
  /** fetch data from the table: "forms_form_service_menu_items" */
  formServiceMenuItem: Array<FormServiceMenuItem>;
  /** fetch aggregated fields from the table: "forms_form_service_menu_items" */
  formServiceMenuItemAggregate: FormServiceMenuItemAggregate;
  /** fetch data from the table: "forms_form_service_menu_items" using primary key columns */
  formServiceMenuItemByPk?: Maybe<FormServiceMenuItem>;
  /** fetch data from the table in a streaming manner: "forms_form_service_menu_items" */
  formServiceMenuItemStream: Array<FormServiceMenuItem>;
  /** fetch data from the table in a streaming manner: "forms_form" */
  formStream: Array<Form>;
  /** fetch data from the table: "forms_formsubmission" */
  formSubmission: Array<FormSubmission>;
  /** fetch aggregated fields from the table: "forms_formsubmission" */
  formSubmissionAggregate: FormSubmissionAggregate;
  /** fetch data from the table: "forms_formsubmission" using primary key columns */
  formSubmissionByPk?: Maybe<FormSubmission>;
  /** fetch data from the table: "forms_formsubmissiongfestatuschangelog" */
  formSubmissionGFEStatusChangeLog: Array<FormSubmissionGfeStatusChangeLog>;
  /** fetch aggregated fields from the table: "forms_formsubmissiongfestatuschangelog" */
  formSubmissionGFEStatusChangeLogAggregate: FormSubmissionGfeStatusChangeLogAggregate;
  /** fetch data from the table: "forms_formsubmissiongfestatuschangelog" using primary key columns */
  formSubmissionGFEStatusChangeLogByPk?: Maybe<FormSubmissionGfeStatusChangeLog>;
  /** fetch data from the table in a streaming manner: "forms_formsubmissiongfestatuschangelog" */
  formSubmissionGFEStatusChangeLogStream: Array<FormSubmissionGfeStatusChangeLog>;
  /** fetch data from the table in a streaming manner: "forms_formsubmission" */
  formSubmissionStream: Array<FormSubmission>;
  /** fetch data from the table: "forms_form_medspa_service_offerings" */
  formsFormMedspaServiceOfferings: Array<FormsFormMedspaServiceOfferings>;
  /** fetch aggregated fields from the table: "forms_form_medspa_service_offerings" */
  formsFormMedspaServiceOfferingsAggregate: FormsFormMedspaServiceOfferingsAggregate;
  /** fetch data from the table: "forms_form_medspa_service_offerings" using primary key columns */
  formsFormMedspaServiceOfferingsByPk?: Maybe<FormsFormMedspaServiceOfferings>;
  /** fetch data from the table in a streaming manner: "forms_form_medspa_service_offerings" */
  formsFormMedspaServiceOfferingsStream: Array<FormsFormMedspaServiceOfferings>;
  /** fetch data from the table: "forms_formsubmissionversion" */
  formsFormsubmissionversion: Array<FormsFormsubmissionversion>;
  /** fetch aggregated fields from the table: "forms_formsubmissionversion" */
  formsFormsubmissionversionAggregate: FormsFormsubmissionversionAggregate;
  /** fetch data from the table: "forms_formsubmissionversion" using primary key columns */
  formsFormsubmissionversionByPk?: Maybe<FormsFormsubmissionversion>;
  /** fetch data from the table in a streaming manner: "forms_formsubmissionversion" */
  formsFormsubmissionversionStream: Array<FormsFormsubmissionversion>;
  /** fetch data from the table: "gfe_diagnosis" */
  gfeDiagnosis: Array<GfeDiagnosis>;
  /** fetch aggregated fields from the table: "gfe_diagnosis" */
  gfeDiagnosisAggregate: GfeDiagnosisAggregate;
  /** fetch data from the table: "gfe_diagnosis" using primary key columns */
  gfeDiagnosisByPk?: Maybe<GfeDiagnosis>;
  /** fetch data from the table in a streaming manner: "gfe_diagnosis" */
  gfeDiagnosisStream: Array<GfeDiagnosis>;
  /** fetch data from the table: "gfe_review_diagnosis" */
  gfeReviewDiagnosis: Array<GfeReviewDiagnosis>;
  /** fetch aggregated fields from the table: "gfe_review_diagnosis" */
  gfeReviewDiagnosisAggregate: GfeReviewDiagnosisAggregate;
  /** fetch data from the table: "gfe_review_diagnosis" using primary key columns */
  gfeReviewDiagnosisByPk?: Maybe<GfeReviewDiagnosis>;
  /** fetch data from the table in a streaming manner: "gfe_review_diagnosis" */
  gfeReviewDiagnosisStream: Array<GfeReviewDiagnosis>;
  /** fetch data from the table: "gfe_review_request" */
  gfeReviewRequest: Array<GfeReviewRequest>;
  /** fetch aggregated fields from the table: "gfe_review_request" */
  gfeReviewRequestAggregate: GfeReviewRequestAggregate;
  /** fetch data from the table: "gfe_review_request" using primary key columns */
  gfeReviewRequestByPk?: Maybe<GfeReviewRequest>;
  /** fetch data from the table in a streaming manner: "gfe_review_request" */
  gfeReviewRequestStream: Array<GfeReviewRequest>;
  /** fetch data from the table: "gfe_review_submission" */
  gfeReviewSubmission: Array<GfeReviewSubmission>;
  /** fetch aggregated fields from the table: "gfe_review_submission" */
  gfeReviewSubmissionAggregate: GfeReviewSubmissionAggregate;
  /** fetch data from the table: "gfe_review_submission" using primary key columns */
  gfeReviewSubmissionByPk?: Maybe<GfeReviewSubmission>;
  /** fetch data from the table in a streaming manner: "gfe_review_submission" */
  gfeReviewSubmissionStream: Array<GfeReviewSubmission>;
  /** fetch data from the table: "gfe_service_product_indication" */
  gfeServiceProductIndication: Array<GfeServiceProductIndication>;
  /** fetch aggregated fields from the table: "gfe_service_product_indication" */
  gfeServiceProductIndicationAggregate: GfeServiceProductIndicationAggregate;
  /** fetch data from the table: "gfe_service_product_indication" using primary key columns */
  gfeServiceProductIndicationByPk?: Maybe<GfeServiceProductIndication>;
  /** fetch data from the table in a streaming manner: "gfe_service_product_indication" */
  gfeServiceProductIndicationStream: Array<GfeServiceProductIndication>;
  /** fetch data from the table: "gfe_synchronous_review_document" */
  gfeSynchronousReviewDocument: Array<GfeSynchronousReviewDocument>;
  /** fetch aggregated fields from the table: "gfe_synchronous_review_document" */
  gfeSynchronousReviewDocumentAggregate: GfeSynchronousReviewDocumentAggregate;
  /** fetch data from the table: "gfe_synchronous_review_document" using primary key columns */
  gfeSynchronousReviewDocumentByPk?: Maybe<GfeSynchronousReviewDocument>;
  /** fetch data from the table in a streaming manner: "gfe_synchronous_review_document" */
  gfeSynchronousReviewDocumentStream: Array<GfeSynchronousReviewDocument>;
  /** fetch data from the table: "gfe_treatment_indication" */
  gfeTreatmentIndication: Array<GfeTreatmentIndication>;
  /** fetch aggregated fields from the table: "gfe_treatment_indication" */
  gfeTreatmentIndicationAggregate: GfeTreatmentIndicationAggregate;
  /** fetch data from the table: "gfe_treatment_indication" using primary key columns */
  gfeTreatmentIndicationByPk?: Maybe<GfeTreatmentIndication>;
  /** fetch data from the table in a streaming manner: "gfe_treatment_indication" */
  gfeTreatmentIndicationStream: Array<GfeTreatmentIndication>;
  /** fetch data from the table: "gift_builder_banner" */
  giftBuilderBanner: Array<GiftBuilderBanner>;
  /** fetch aggregated fields from the table: "gift_builder_banner" */
  giftBuilderBannerAggregate: GiftBuilderBannerAggregate;
  /** fetch data from the table: "gift_builder_banner" using primary key columns */
  giftBuilderBannerByPk?: Maybe<GiftBuilderBanner>;
  /** fetch data from the table in a streaming manner: "gift_builder_banner" */
  giftBuilderBannerStream: Array<GiftBuilderBanner>;
  /** fetch data from the table: "inventory_lot" */
  inventoryLot: Array<InventoryLot>;
  /** fetch aggregated fields from the table: "inventory_lot" */
  inventoryLotAggregate: InventoryLotAggregate;
  /** fetch data from the table: "inventory_lot" using primary key columns */
  inventoryLotByPk?: Maybe<InventoryLot>;
  /** fetch data from the table in a streaming manner: "inventory_lot" */
  inventoryLotStream: Array<InventoryLot>;
  /** fetch data from the table: "inventory_transaction" */
  inventoryTransaction: Array<InventoryTransaction>;
  /** fetch aggregated fields from the table: "inventory_transaction" */
  inventoryTransactionAggregate: InventoryTransactionAggregate;
  /** fetch data from the table: "inventory_transaction" using primary key columns */
  inventoryTransactionByPk?: Maybe<InventoryTransaction>;
  /** fetch data from the table in a streaming manner: "inventory_transaction" */
  inventoryTransactionStream: Array<InventoryTransaction>;
  /** fetch data from the table: "visits_invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "visits_invoice" */
  invoiceAggregate: InvoiceAggregate;
  /** fetch data from the table: "visits_invoice" using primary key columns */
  invoiceByPk?: Maybe<Invoice>;
  /** fetch data from the table in a streaming manner: "visits_invoice" */
  invoiceStream: Array<Invoice>;
  /** fetch data from the table: "last_gfe_review_treatment_indication" */
  lastGfeReviewTreatmentIndication: Array<LastGfeReviewTreatmentIndication>;
  /** fetch aggregated fields from the table: "last_gfe_review_treatment_indication" */
  lastGfeReviewTreatmentIndicationAggregate: LastGfeReviewTreatmentIndicationAggregate;
  /** fetch data from the table in a streaming manner: "last_gfe_review_treatment_indication" */
  lastGfeReviewTreatmentIndicationStream: Array<LastGfeReviewTreatmentIndication>;
  /** fetch data from the table: "marketing_template" */
  marketingTemplate: Array<MarketingTemplate>;
  /** fetch aggregated fields from the table: "marketing_template" */
  marketingTemplateAggregate: MarketingTemplateAggregate;
  /** fetch data from the table: "marketing_template" using primary key columns */
  marketingTemplateByPk?: Maybe<MarketingTemplate>;
  /** fetch data from the table in a streaming manner: "marketing_template" */
  marketingTemplateStream: Array<MarketingTemplate>;
  /** fetch data from the table: "md_meeting" */
  mdMeeting: Array<MdMeeting>;
  /** fetch aggregated fields from the table: "md_meeting" */
  mdMeetingAggregate: MdMeetingAggregate;
  /** fetch data from the table: "md_meeting_attendees" */
  mdMeetingAttendees: Array<MdMeetingAttendees>;
  /** fetch aggregated fields from the table: "md_meeting_attendees" */
  mdMeetingAttendeesAggregate: MdMeetingAttendeesAggregate;
  /** fetch data from the table: "md_meeting_attendees" using primary key columns */
  mdMeetingAttendeesByPk?: Maybe<MdMeetingAttendees>;
  /** fetch data from the table in a streaming manner: "md_meeting_attendees" */
  mdMeetingAttendeesStream: Array<MdMeetingAttendees>;
  /** fetch data from the table: "md_meeting" using primary key columns */
  mdMeetingByPk?: Maybe<MdMeeting>;
  /** fetch data from the table: "md_meeting_note" */
  mdMeetingNote: Array<MdMeetingNote>;
  /** fetch aggregated fields from the table: "md_meeting_note" */
  mdMeetingNoteAggregate: MdMeetingNoteAggregate;
  /** fetch data from the table: "md_meeting_note" using primary key columns */
  mdMeetingNoteByPk?: Maybe<MdMeetingNote>;
  /** fetch data from the table in a streaming manner: "md_meeting_note" */
  mdMeetingNoteStream: Array<MdMeetingNote>;
  /** fetch data from the table: "md_meeting_note_template" */
  mdMeetingNoteTemplate: Array<MdMeetingNoteTemplate>;
  /** fetch aggregated fields from the table: "md_meeting_note_template" */
  mdMeetingNoteTemplateAggregate: MdMeetingNoteTemplateAggregate;
  /** fetch data from the table: "md_meeting_note_template" using primary key columns */
  mdMeetingNoteTemplateByPk?: Maybe<MdMeetingNoteTemplate>;
  /** fetch data from the table in a streaming manner: "md_meeting_note_template" */
  mdMeetingNoteTemplateStream: Array<MdMeetingNoteTemplate>;
  /** fetch data from the table in a streaming manner: "md_meeting" */
  mdMeetingStream: Array<MdMeeting>;
  /** fetch data from the table: "medspas_medicaldirector" */
  medicalDirector: Array<MedicalDirector>;
  /** fetch aggregated fields from the table: "medspas_medicaldirector" */
  medicalDirectorAggregate: MedicalDirectorAggregate;
  /** fetch data from the table: "medspas_medicaldirector" using primary key columns */
  medicalDirectorByPk?: Maybe<MedicalDirector>;
  /** fetch data from the table in a streaming manner: "medspas_medicaldirector" */
  medicalDirectorStream: Array<MedicalDirector>;
  /** fetch data from the table: "medspas_medicaldirectorsubscription" */
  medicalDirectorSubscription: Array<MedicalDirectorSubscription>;
  /** fetch aggregated fields from the table: "medspas_medicaldirectorsubscription" */
  medicalDirectorSubscriptionAggregate: MedicalDirectorSubscriptionAggregate;
  /** fetch data from the table: "medspas_medicaldirectorsubscription" using primary key columns */
  medicalDirectorSubscriptionByPk?: Maybe<MedicalDirectorSubscription>;
  /** fetch data from the table in a streaming manner: "medspas_medicaldirectorsubscription" */
  medicalDirectorSubscriptionStream: Array<MedicalDirectorSubscription>;
  /** fetch data from the table: "medspas_medspa" */
  medspa: Array<Medspa>;
  /** fetch aggregated fields from the table: "medspas_medspa" */
  medspaAggregate: MedspaAggregate;
  /** fetch data from the table: "medspas_medspa" using primary key columns */
  medspaByPk?: Maybe<Medspa>;
  /** An array relationship */
  medspaRoomsAppointments: Array<MedspaRoomsAppointments>;
  /** An aggregate relationship */
  medspaRoomsAppointmentsAggregate: MedspaRoomsAppointmentsAggregate;
  /** fetch data from the table: "medspa_rooms_appointments" using primary key columns */
  medspaRoomsAppointmentsByPk?: Maybe<MedspaRoomsAppointments>;
  /** fetch data from the table in a streaming manner: "medspa_rooms_appointments" */
  medspaRoomsAppointmentsStream: Array<MedspaRoomsAppointments>;
  /** fetch data from the table: "medspa_service_menu_item" */
  medspaServiceMenuItem: Array<MedspaServiceMenuItem>;
  /** fetch aggregated fields from the table: "medspa_service_menu_item" */
  medspaServiceMenuItemAggregate: MedspaServiceMenuItemAggregate;
  /** fetch data from the table: "medspa_service_menu_item" using primary key columns */
  medspaServiceMenuItemByPk?: Maybe<MedspaServiceMenuItem>;
  /** fetch data from the table: "medspa_service_menu_item_medspa_service_offerings" */
  medspaServiceMenuItemMedspaServiceOfferings: Array<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** fetch aggregated fields from the table: "medspa_service_menu_item_medspa_service_offerings" */
  medspaServiceMenuItemMedspaServiceOfferingsAggregate: MedspaServiceMenuItemMedspaServiceOfferingsAggregate;
  /** fetch data from the table: "medspa_service_menu_item_medspa_service_offerings" using primary key columns */
  medspaServiceMenuItemMedspaServiceOfferingsByPk?: Maybe<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** fetch data from the table in a streaming manner: "medspa_service_menu_item_medspa_service_offerings" */
  medspaServiceMenuItemMedspaServiceOfferingsStream: Array<MedspaServiceMenuItemMedspaServiceOfferings>;
  /** fetch data from the table: "medspa_service_menu_item_old_products" */
  medspaServiceMenuItemOldProducts: Array<MedspaServiceMenuItemOldProducts>;
  /** fetch aggregated fields from the table: "medspa_service_menu_item_old_products" */
  medspaServiceMenuItemOldProductsAggregate: MedspaServiceMenuItemOldProductsAggregate;
  /** fetch data from the table: "medspa_service_menu_item_old_products" using primary key columns */
  medspaServiceMenuItemOldProductsByPk?: Maybe<MedspaServiceMenuItemOldProducts>;
  /** fetch data from the table in a streaming manner: "medspa_service_menu_item_old_products" */
  medspaServiceMenuItemOldProductsStream: Array<MedspaServiceMenuItemOldProducts>;
  /** fetch data from the table: "medspa_service_menu_item_providers" */
  medspaServiceMenuItemProviders: Array<MedspaServiceMenuItemProviders>;
  /** fetch aggregated fields from the table: "medspa_service_menu_item_providers" */
  medspaServiceMenuItemProvidersAggregate: MedspaServiceMenuItemProvidersAggregate;
  /** fetch data from the table: "medspa_service_menu_item_providers" using primary key columns */
  medspaServiceMenuItemProvidersByPk?: Maybe<MedspaServiceMenuItemProviders>;
  /** fetch data from the table in a streaming manner: "medspa_service_menu_item_providers" */
  medspaServiceMenuItemProvidersStream: Array<MedspaServiceMenuItemProviders>;
  /** fetch data from the table in a streaming manner: "medspa_service_menu_item" */
  medspaServiceMenuItemStream: Array<MedspaServiceMenuItem>;
  /** fetch data from the table: "medspa_service_offering" */
  medspaServiceOffering: Array<MedspaServiceOffering>;
  /** fetch aggregated fields from the table: "medspa_service_offering" */
  medspaServiceOfferingAggregate: MedspaServiceOfferingAggregate;
  /** fetch data from the table: "medspa_service_offering" using primary key columns */
  medspaServiceOfferingByPk?: Maybe<MedspaServiceOffering>;
  /** fetch data from the table: "medspa_service_offering_compliance_requirements" */
  medspaServiceOfferingComplianceRequirements: Array<MedspaServiceOfferingComplianceRequirements>;
  /** fetch aggregated fields from the table: "medspa_service_offering_compliance_requirements" */
  medspaServiceOfferingComplianceRequirementsAggregate: MedspaServiceOfferingComplianceRequirementsAggregate;
  /** fetch data from the table in a streaming manner: "medspa_service_offering_compliance_requirements" */
  medspaServiceOfferingComplianceRequirementsStream: Array<MedspaServiceOfferingComplianceRequirements>;
  /** fetch data from the table: "medspa_service_offering_note_templates" */
  medspaServiceOfferingNoteTemplates: Array<MedspaServiceOfferingNoteTemplates>;
  /** fetch aggregated fields from the table: "medspa_service_offering_note_templates" */
  medspaServiceOfferingNoteTemplatesAggregate: MedspaServiceOfferingNoteTemplatesAggregate;
  /** fetch data from the table: "medspa_service_offering_note_templates" using primary key columns */
  medspaServiceOfferingNoteTemplatesByPk?: Maybe<MedspaServiceOfferingNoteTemplates>;
  /** fetch data from the table in a streaming manner: "medspa_service_offering_note_templates" */
  medspaServiceOfferingNoteTemplatesStream: Array<MedspaServiceOfferingNoteTemplates>;
  /** fetch data from the table: "medspa_service_offering_notes" */
  medspaServiceOfferingNotes: Array<MedspaServiceOfferingNotes>;
  /** fetch aggregated fields from the table: "medspa_service_offering_notes" */
  medspaServiceOfferingNotesAggregate: MedspaServiceOfferingNotesAggregate;
  /** fetch data from the table: "medspa_service_offering_notes" using primary key columns */
  medspaServiceOfferingNotesByPk?: Maybe<MedspaServiceOfferingNotes>;
  /** fetch data from the table in a streaming manner: "medspa_service_offering_notes" */
  medspaServiceOfferingNotesStream: Array<MedspaServiceOfferingNotes>;
  /** fetch data from the table in a streaming manner: "medspa_service_offering" */
  medspaServiceOfferingStream: Array<MedspaServiceOffering>;
  /** fetch data from the table: "medspa_service_product" */
  medspaServiceProduct: Array<MedspaServiceProduct>;
  /** fetch aggregated fields from the table: "medspa_service_product" */
  medspaServiceProductAggregate: MedspaServiceProductAggregate;
  /** fetch data from the table: "medspa_service_product" using primary key columns */
  medspaServiceProductByPk?: Maybe<MedspaServiceProduct>;
  /** fetch data from the table in a streaming manner: "medspa_service_product" */
  medspaServiceProductStream: Array<MedspaServiceProduct>;
  /** fetch data from the table: "medspa_service_protocol_template" */
  medspaServiceProtocolTemplate: Array<MedspaServiceProtocolTemplate>;
  /** fetch aggregated fields from the table: "medspa_service_protocol_template" */
  medspaServiceProtocolTemplateAggregate: MedspaServiceProtocolTemplateAggregate;
  /** fetch data from the table: "medspa_service_protocol_template" using primary key columns */
  medspaServiceProtocolTemplateByPk?: Maybe<MedspaServiceProtocolTemplate>;
  /** fetch data from the table in a streaming manner: "medspa_service_protocol_template" */
  medspaServiceProtocolTemplateStream: Array<MedspaServiceProtocolTemplate>;
  /** fetch data from the table: "medspa_standing_order_template" */
  medspaStandingOrderTemplate: Array<MedspaStandingOrderTemplate>;
  /** fetch aggregated fields from the table: "medspa_standing_order_template" */
  medspaStandingOrderTemplateAggregate: MedspaStandingOrderTemplateAggregate;
  /** fetch data from the table: "medspa_standing_order_template" using primary key columns */
  medspaStandingOrderTemplateByPk?: Maybe<MedspaStandingOrderTemplate>;
  /** fetch data from the table in a streaming manner: "medspa_standing_order_template" */
  medspaStandingOrderTemplateStream: Array<MedspaStandingOrderTemplate>;
  /** fetch data from the table in a streaming manner: "medspas_medspa" */
  medspaStream: Array<Medspa>;
  /** fetch data from the table: "medspa_wallet_credit_type" */
  medspaWalletCreditType: Array<MedspaWalletCreditType>;
  /** fetch aggregated fields from the table: "medspa_wallet_credit_type" */
  medspaWalletCreditTypeAggregate: MedspaWalletCreditTypeAggregate;
  /** fetch data from the table: "medspa_wallet_credit_type" using primary key columns */
  medspaWalletCreditTypeByPk?: Maybe<MedspaWalletCreditType>;
  /** fetch data from the table in a streaming manner: "medspa_wallet_credit_type" */
  medspaWalletCreditTypeStream: Array<MedspaWalletCreditType>;
  /** fetch data from the table: "medspas_featureflag" */
  medspasFeatureflag: Array<MedspasFeatureflag>;
  /** fetch aggregated fields from the table: "medspas_featureflag" */
  medspasFeatureflagAggregate: MedspasFeatureflagAggregate;
  /** fetch data from the table: "medspas_featureflag" using primary key columns */
  medspasFeatureflagByPk?: Maybe<MedspasFeatureflag>;
  /** fetch data from the table in a streaming manner: "medspas_featureflag" */
  medspasFeatureflagStream: Array<MedspasFeatureflag>;
  /** fetch data from the table: "medspas_medicallicense" */
  medspasMedicallicense: Array<MedspasMedicallicense>;
  /** fetch aggregated fields from the table: "medspas_medicallicense" */
  medspasMedicallicenseAggregate: MedspasMedicallicenseAggregate;
  /** fetch data from the table: "medspas_medicallicense" using primary key columns */
  medspasMedicallicenseByPk?: Maybe<MedspasMedicallicense>;
  /** fetch data from the table in a streaming manner: "medspas_medicallicense" */
  medspasMedicallicenseStream: Array<MedspasMedicallicense>;
  /** fetch data from the table: "medspas_medspaconfiguration" */
  medspasMedspaconfiguration: Array<MedspasMedspaconfiguration>;
  /** fetch aggregated fields from the table: "medspas_medspaconfiguration" */
  medspasMedspaconfigurationAggregate: MedspasMedspaconfigurationAggregate;
  /** fetch data from the table: "medspas_medspaconfiguration" using primary key columns */
  medspasMedspaconfigurationByPk?: Maybe<MedspasMedspaconfiguration>;
  /** fetch data from the table in a streaming manner: "medspas_medspaconfiguration" */
  medspasMedspaconfigurationStream: Array<MedspasMedspaconfiguration>;
  /** fetch data from the table: "medspas_professionalcorporation" */
  medspasProfessionalcorporation: Array<MedspasProfessionalcorporation>;
  /** fetch aggregated fields from the table: "medspas_professionalcorporation" */
  medspasProfessionalcorporationAggregate: MedspasProfessionalcorporationAggregate;
  /** fetch data from the table: "medspas_professionalcorporation" using primary key columns */
  medspasProfessionalcorporationByPk?: Maybe<MedspasProfessionalcorporation>;
  /** fetch data from the table in a streaming manner: "medspas_professionalcorporation" */
  medspasProfessionalcorporationStream: Array<MedspasProfessionalcorporation>;
  /** fetch data from the table: "medspas_stripeoutboundpaymentmethod" */
  medspasStripeoutboundpaymentmethod: Array<MedspasStripeoutboundpaymentmethod>;
  /** fetch aggregated fields from the table: "medspas_stripeoutboundpaymentmethod" */
  medspasStripeoutboundpaymentmethodAggregate: MedspasStripeoutboundpaymentmethodAggregate;
  /** fetch data from the table: "medspas_stripeoutboundpaymentmethod" using primary key columns */
  medspasStripeoutboundpaymentmethodByPk?: Maybe<MedspasStripeoutboundpaymentmethod>;
  /** fetch data from the table in a streaming manner: "medspas_stripeoutboundpaymentmethod" */
  medspasStripeoutboundpaymentmethodStream: Array<MedspasStripeoutboundpaymentmethod>;
  /** fetch data from the table: "medspas_usermedspa_acquired_medical_licenses" */
  medspasUsermedspaAcquiredMedicalLicenses: Array<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** fetch aggregated fields from the table: "medspas_usermedspa_acquired_medical_licenses" */
  medspasUsermedspaAcquiredMedicalLicensesAggregate: MedspasUsermedspaAcquiredMedicalLicensesAggregate;
  /** fetch data from the table: "medspas_usermedspa_acquired_medical_licenses" using primary key columns */
  medspasUsermedspaAcquiredMedicalLicensesByPk?: Maybe<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** fetch data from the table in a streaming manner: "medspas_usermedspa_acquired_medical_licenses" */
  medspasUsermedspaAcquiredMedicalLicensesStream: Array<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** fetch data from the table: "membership" */
  membership: Array<Membership>;
  /** fetch aggregated fields from the table: "membership" */
  membershipAggregate: MembershipAggregate;
  /** fetch data from the table: "membership" using primary key columns */
  membershipByPk?: Maybe<Membership>;
  /** fetch data from the table: "membership_category" */
  membershipCategory: Array<MembershipCategory>;
  /** fetch aggregated fields from the table: "membership_category" */
  membershipCategoryAggregate: MembershipCategoryAggregate;
  /** fetch data from the table: "membership_category" using primary key columns */
  membershipCategoryByPk?: Maybe<MembershipCategory>;
  /** fetch data from the table in a streaming manner: "membership_category" */
  membershipCategoryStream: Array<MembershipCategory>;
  /** fetch data from the table: "membership_line" */
  membershipLine: Array<MembershipLine>;
  /** fetch aggregated fields from the table: "membership_line" */
  membershipLineAggregate: MembershipLineAggregate;
  /** fetch data from the table: "membership_line" using primary key columns */
  membershipLineByPk?: Maybe<MembershipLine>;
  /** fetch data from the table in a streaming manner: "membership_line" */
  membershipLineStream: Array<MembershipLine>;
  /** fetch data from the table: "membership_perk" */
  membershipPerk: Array<MembershipPerk>;
  /** fetch aggregated fields from the table: "membership_perk" */
  membershipPerkAggregate: MembershipPerkAggregate;
  /** fetch data from the table: "membership_perk" using primary key columns */
  membershipPerkByPk?: Maybe<MembershipPerk>;
  /** fetch data from the table: "membership_perk_line" */
  membershipPerkLine: Array<MembershipPerkLine>;
  /** fetch aggregated fields from the table: "membership_perk_line" */
  membershipPerkLineAggregate: MembershipPerkLineAggregate;
  /** fetch data from the table: "membership_perk_line" using primary key columns */
  membershipPerkLineByPk?: Maybe<MembershipPerkLine>;
  /** fetch data from the table in a streaming manner: "membership_perk_line" */
  membershipPerkLineStream: Array<MembershipPerkLine>;
  /** fetch data from the table in a streaming manner: "membership_perk" */
  membershipPerkStream: Array<MembershipPerk>;
  /** fetch data from the table in a streaming manner: "membership" */
  membershipStream: Array<Membership>;
  /** fetch data from the table: "model_permission" */
  modelPermission: Array<ModelPermission>;
  /** fetch aggregated fields from the table: "model_permission" */
  modelPermissionAggregate: ModelPermissionAggregate;
  /** fetch data from the table: "model_permission" using primary key columns */
  modelPermissionByPk?: Maybe<ModelPermission>;
  /** fetch data from the table in a streaming manner: "model_permission" */
  modelPermissionStream: Array<ModelPermission>;
  /** fetch data from the table: "visits_note" */
  note: Array<Note>;
  /** fetch aggregated fields from the table: "visits_note" */
  noteAggregate: NoteAggregate;
  /** fetch data from the table: "visits_note" using primary key columns */
  noteByPk?: Maybe<Note>;
  /** fetch data from the table: "visits_notechangelog" */
  noteChangeLog: Array<NoteChangeLog>;
  /** fetch aggregated fields from the table: "visits_notechangelog" */
  noteChangeLogAggregate: NoteChangeLogAggregate;
  /** fetch data from the table: "visits_notechangelog" using primary key columns */
  noteChangeLogByPk?: Maybe<NoteChangeLog>;
  /** fetch data from the table in a streaming manner: "visits_notechangelog" */
  noteChangeLogStream: Array<NoteChangeLog>;
  /** fetch data from the table: "visits_note_form_submissions" */
  noteFormSubmission: Array<NoteFormSubmission>;
  /** fetch aggregated fields from the table: "visits_note_form_submissions" */
  noteFormSubmissionAggregate: NoteFormSubmissionAggregate;
  /** fetch data from the table: "visits_note_form_submissions" using primary key columns */
  noteFormSubmissionByPk?: Maybe<NoteFormSubmission>;
  /** fetch data from the table in a streaming manner: "visits_note_form_submissions" */
  noteFormSubmissionStream: Array<NoteFormSubmission>;
  /** fetch data from the table in a streaming manner: "visits_note" */
  noteStream: Array<Note>;
  /** fetch data from the table: "resources_notetemplate" */
  noteTemplate: Array<NoteTemplate>;
  /** fetch aggregated fields from the table: "resources_notetemplate" */
  noteTemplateAggregate: NoteTemplateAggregate;
  /** fetch data from the table: "resources_notetemplate" using primary key columns */
  noteTemplateByPk?: Maybe<NoteTemplate>;
  /** fetch data from the table in a streaming manner: "resources_notetemplate" */
  noteTemplateStream: Array<NoteTemplate>;
  /** fetch data from the table: "resources_package" */
  package: Array<Package>;
  /** fetch aggregated fields from the table: "resources_package" */
  packageAggregate: PackageAggregate;
  /** fetch data from the table: "resources_package" using primary key columns */
  packageByPk?: Maybe<Package>;
  /** fetch data from the table: "resources_packageitem" */
  packageItem: Array<PackageItem>;
  /** fetch aggregated fields from the table: "resources_packageitem" */
  packageItemAggregate: PackageItemAggregate;
  /** fetch data from the table: "resources_packageitem" using primary key columns */
  packageItemByPk?: Maybe<PackageItem>;
  /** fetch data from the table: "package_item_line" */
  packageItemLine: Array<PackageItemLine>;
  /** fetch aggregated fields from the table: "package_item_line" */
  packageItemLineAggregate: PackageItemLineAggregate;
  /** fetch data from the table: "package_item_line" using primary key columns */
  packageItemLineByPk?: Maybe<PackageItemLine>;
  /** fetch data from the table in a streaming manner: "package_item_line" */
  packageItemLineStream: Array<PackageItemLine>;
  /** fetch data from the table in a streaming manner: "resources_packageitem" */
  packageItemStream: Array<PackageItem>;
  /** fetch data from the table: "visits_packageline" */
  packageLine: Array<PackageLine>;
  /** fetch aggregated fields from the table: "visits_packageline" */
  packageLineAggregate: PackageLineAggregate;
  /** fetch data from the table: "visits_packageline" using primary key columns */
  packageLineByPk?: Maybe<PackageLine>;
  /** fetch data from the table in a streaming manner: "visits_packageline" */
  packageLineStream: Array<PackageLine>;
  /** fetch data from the table in a streaming manner: "resources_package" */
  packageStream: Array<Package>;
  /** fetch data from the table: "payments_payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payments_payment" */
  paymentAggregate: PaymentAggregate;
  /** fetch data from the table: "payments_payment" using primary key columns */
  paymentByPk?: Maybe<Payment>;
  /** fetch data from the table in a streaming manner: "payments_payment" */
  paymentStream: Array<Payment>;
  /** fetch data from the table: "visits_photo" */
  photo: Array<Photo>;
  /** fetch aggregated fields from the table: "visits_photo" */
  photoAggregate: PhotoAggregate;
  /** fetch data from the table: "visits_photo" using primary key columns */
  photoByPk?: Maybe<Photo>;
  /** fetch data from the table in a streaming manner: "visits_photo" */
  photoStream: Array<Photo>;
  /** fetch data from the table: "resources_product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "resources_product" */
  productAggregate: ProductAggregate;
  /** fetch data from the table: "resources_product" using primary key columns */
  productByPk?: Maybe<Product>;
  /** fetch data from the table: "visits_productline" */
  productLine: Array<ProductLine>;
  /** fetch aggregated fields from the table: "visits_productline" */
  productLineAggregate: ProductLineAggregate;
  /** fetch data from the table: "visits_productline" using primary key columns */
  productLineByPk?: Maybe<ProductLine>;
  /** fetch data from the table in a streaming manner: "visits_productline" */
  productLineStream: Array<ProductLine>;
  /** fetch data from the table in a streaming manner: "resources_product" */
  productStream: Array<Product>;
  /** An array relationship */
  providerNotRequireDocuments: Array<ProviderNotRequireDocuments>;
  /** An aggregate relationship */
  providerNotRequireDocumentsAggregate: ProviderNotRequireDocumentsAggregate;
  /** fetch data from the table: "provider_not_require_documents" using primary key columns */
  providerNotRequireDocumentsByPk?: Maybe<ProviderNotRequireDocuments>;
  /** fetch data from the table in a streaming manner: "provider_not_require_documents" */
  providerNotRequireDocumentsStream: Array<ProviderNotRequireDocuments>;
  /** fetch data from the table: "scheduling_recurring_availability" */
  recurringAvailability: Array<RecurringAvailability>;
  /** fetch aggregated fields from the table: "scheduling_recurring_availability" */
  recurringAvailabilityAggregate: RecurringAvailabilityAggregate;
  /** fetch data from the table: "scheduling_recurring_availability" using primary key columns */
  recurringAvailabilityByPk?: Maybe<RecurringAvailability>;
  /** fetch data from the table in a streaming manner: "scheduling_recurring_availability" */
  recurringAvailabilityStream: Array<RecurringAvailability>;
  /** fetch data from the table: "payments_refund" */
  refund: Array<Refund>;
  /** fetch aggregated fields from the table: "payments_refund" */
  refundAggregate: RefundAggregate;
  /** fetch data from the table: "payments_refund" using primary key columns */
  refundByPk?: Maybe<Refund>;
  /** fetch data from the table in a streaming manner: "payments_refund" */
  refundStream: Array<Refund>;
  /** fetch data from the table: "medspas_report" */
  report: Array<Report>;
  /** fetch aggregated fields from the table: "medspas_report" */
  reportAggregate: ReportAggregate;
  /** fetch data from the table: "medspas_report" using primary key columns */
  reportByPk?: Maybe<Report>;
  /** fetch data from the table: "medspas_reportcategory" */
  reportCategory: Array<ReportCategory>;
  /** fetch aggregated fields from the table: "medspas_reportcategory" */
  reportCategoryAggregate: ReportCategoryAggregate;
  /** fetch data from the table: "medspas_reportcategory" using primary key columns */
  reportCategoryByPk?: Maybe<ReportCategory>;
  /** fetch data from the table in a streaming manner: "medspas_reportcategory" */
  reportCategoryStream: Array<ReportCategory>;
  /** fetch data from the table in a streaming manner: "medspas_report" */
  reportStream: Array<Report>;
  /** fetch data from the table: "resources_medspa_room" */
  resourcesMedspaRoom: Array<ResourcesMedspaRoom>;
  /** fetch aggregated fields from the table: "resources_medspa_room" */
  resourcesMedspaRoomAggregate: ResourcesMedspaRoomAggregate;
  /** fetch data from the table: "resources_medspa_room" using primary key columns */
  resourcesMedspaRoomByPk?: Maybe<ResourcesMedspaRoom>;
  /** fetch data from the table in a streaming manner: "resources_medspa_room" */
  resourcesMedspaRoomStream: Array<ResourcesMedspaRoom>;
  /** fetch data from the table: "scheduling_appointment" */
  schedulingAppointment: Array<SchedulingAppointment>;
  /** fetch aggregated fields from the table: "scheduling_appointment" */
  schedulingAppointmentAggregate: SchedulingAppointmentAggregate;
  /** fetch data from the table: "scheduling_appointment" using primary key columns */
  schedulingAppointmentByPk?: Maybe<SchedulingAppointment>;
  /** fetch data from the table in a streaming manner: "scheduling_appointment" */
  schedulingAppointmentStream: Array<SchedulingAppointment>;
  /** fetch data from the table: "script_product" */
  scriptProduct: Array<ScriptProduct>;
  /** fetch aggregated fields from the table: "script_product" */
  scriptProductAggregate: ScriptProductAggregate;
  /** fetch data from the table: "script_product" using primary key columns */
  scriptProductByPk?: Maybe<ScriptProduct>;
  /** fetch data from the table in a streaming manner: "script_product" */
  scriptProductStream: Array<ScriptProduct>;
  /** fetch data from the table: "script_request" */
  scriptRequest: Array<ScriptRequest>;
  /** fetch aggregated fields from the table: "script_request" */
  scriptRequestAggregate: ScriptRequestAggregate;
  /** fetch data from the table: "script_request" using primary key columns */
  scriptRequestByPk?: Maybe<ScriptRequest>;
  /** fetch data from the table: "script_request_status_change_log" */
  scriptRequestStatusChangeLog: Array<ScriptRequestStatusChangeLog>;
  /** fetch aggregated fields from the table: "script_request_status_change_log" */
  scriptRequestStatusChangeLogAggregate: ScriptRequestStatusChangeLogAggregate;
  /** fetch data from the table: "script_request_status_change_log" using primary key columns */
  scriptRequestStatusChangeLogByPk?: Maybe<ScriptRequestStatusChangeLog>;
  /** fetch data from the table in a streaming manner: "script_request_status_change_log" */
  scriptRequestStatusChangeLogStream: Array<ScriptRequestStatusChangeLog>;
  /** fetch data from the table in a streaming manner: "script_request" */
  scriptRequestStream: Array<ScriptRequest>;
  /** fetch data from the table: "resources_servicecategory" */
  serviceCategory: Array<ServiceCategory>;
  /** fetch aggregated fields from the table: "resources_servicecategory" */
  serviceCategoryAggregate: ServiceCategoryAggregate;
  /** fetch data from the table: "resources_servicecategory" using primary key columns */
  serviceCategoryByPk?: Maybe<ServiceCategory>;
  /** fetch data from the table in a streaming manner: "resources_servicecategory" */
  serviceCategoryStream: Array<ServiceCategory>;
  /** fetch data from the table: "service_device" */
  serviceDevice: Array<ServiceDevice>;
  /** fetch aggregated fields from the table: "service_device" */
  serviceDeviceAggregate: ServiceDeviceAggregate;
  /** fetch data from the table: "service_device" using primary key columns */
  serviceDeviceByPk?: Maybe<ServiceDevice>;
  /** fetch data from the table in a streaming manner: "service_device" */
  serviceDeviceStream: Array<ServiceDevice>;
  /** An array relationship */
  serviceDevicesAppointments: Array<ServiceDevicesAppointments>;
  /** An aggregate relationship */
  serviceDevicesAppointmentsAggregate: ServiceDevicesAppointmentsAggregate;
  /** fetch data from the table: "service_devices_appointments" using primary key columns */
  serviceDevicesAppointmentsByPk?: Maybe<ServiceDevicesAppointments>;
  /** fetch data from the table in a streaming manner: "service_devices_appointments" */
  serviceDevicesAppointmentsStream: Array<ServiceDevicesAppointments>;
  /** An array relationship */
  serviceDevicesServiceTypes: Array<ServiceDevicesServiceTypes>;
  /** An aggregate relationship */
  serviceDevicesServiceTypesAggregate: ServiceDevicesServiceTypesAggregate;
  /** fetch data from the table: "service_devices_service_types" using primary key columns */
  serviceDevicesServiceTypesByPk?: Maybe<ServiceDevicesServiceTypes>;
  /** fetch data from the table in a streaming manner: "service_devices_service_types" */
  serviceDevicesServiceTypesStream: Array<ServiceDevicesServiceTypes>;
  /** fetch data from the table: "service_menu_item_line" */
  serviceMenuItemLine: Array<ServiceMenuItemLine>;
  /** fetch aggregated fields from the table: "service_menu_item_line" */
  serviceMenuItemLineAggregate: ServiceMenuItemLineAggregate;
  /** fetch data from the table: "service_menu_item_line" using primary key columns */
  serviceMenuItemLineByPk?: Maybe<ServiceMenuItemLine>;
  /** An array relationship */
  serviceMenuItemLineStandingOrders: Array<ServiceMenuItemLineStandingOrders>;
  /** An aggregate relationship */
  serviceMenuItemLineStandingOrdersAggregate: ServiceMenuItemLineStandingOrdersAggregate;
  /** fetch data from the table: "service_menu_item_line_standing_orders" using primary key columns */
  serviceMenuItemLineStandingOrdersByPk?: Maybe<ServiceMenuItemLineStandingOrders>;
  /** fetch data from the table in a streaming manner: "service_menu_item_line_standing_orders" */
  serviceMenuItemLineStandingOrdersStream: Array<ServiceMenuItemLineStandingOrders>;
  /** fetch data from the table in a streaming manner: "service_menu_item_line" */
  serviceMenuItemLineStream: Array<ServiceMenuItemLine>;
  /** fetch data from the table: "service_product" */
  serviceProduct: Array<ServiceProduct>;
  /** fetch aggregated fields from the table: "service_product" */
  serviceProductAggregate: ServiceProductAggregate;
  /** fetch data from the table: "service_product" using primary key columns */
  serviceProductByPk?: Maybe<ServiceProduct>;
  /** fetch data from the table: "service_product_charting_note" */
  serviceProductChartingNote: Array<ServiceProductChartingNote>;
  /** fetch aggregated fields from the table: "service_product_charting_note" */
  serviceProductChartingNoteAggregate: ServiceProductChartingNoteAggregate;
  /** fetch data from the table: "service_product_charting_note" using primary key columns */
  serviceProductChartingNoteByPk?: Maybe<ServiceProductChartingNote>;
  /** fetch data from the table in a streaming manner: "service_product_charting_note" */
  serviceProductChartingNoteStream: Array<ServiceProductChartingNote>;
  /** fetch data from the table: "service_product_line" */
  serviceProductLine: Array<ServiceProductLine>;
  /** fetch aggregated fields from the table: "service_product_line" */
  serviceProductLineAggregate: ServiceProductLineAggregate;
  /** fetch data from the table: "service_product_line" using primary key columns */
  serviceProductLineByPk?: Maybe<ServiceProductLine>;
  /** fetch data from the table in a streaming manner: "service_product_line" */
  serviceProductLineStream: Array<ServiceProductLine>;
  /** fetch data from the table in a streaming manner: "service_product" */
  serviceProductStream: Array<ServiceProduct>;
  /** fetch data from the table: "service_product_type" */
  serviceProductType: Array<ServiceProductType>;
  /** fetch aggregated fields from the table: "service_product_type" */
  serviceProductTypeAggregate: ServiceProductTypeAggregate;
  /** fetch data from the table: "service_product_type" using primary key columns */
  serviceProductTypeByPk?: Maybe<ServiceProductType>;
  /** fetch data from the table in a streaming manner: "service_product_type" */
  serviceProductTypeStream: Array<ServiceProductType>;
  /** fetch data from the table: "service_product_usage" */
  serviceProductUsage: Array<ServiceProductUsage>;
  /** fetch aggregated fields from the table: "service_product_usage" */
  serviceProductUsageAggregate: ServiceProductUsageAggregate;
  /** fetch data from the table: "service_product_usage" using primary key columns */
  serviceProductUsageByPk?: Maybe<ServiceProductUsage>;
  /** fetch data from the table in a streaming manner: "service_product_usage" */
  serviceProductUsageStream: Array<ServiceProductUsage>;
  /** fetch data from the table: "service_protocol" */
  serviceProtocol: Array<ServiceProtocol>;
  /** fetch aggregated fields from the table: "service_protocol" */
  serviceProtocolAggregate: ServiceProtocolAggregate;
  /** fetch data from the table: "service_protocol" using primary key columns */
  serviceProtocolByPk?: Maybe<ServiceProtocol>;
  /** fetch data from the table in a streaming manner: "service_protocol" */
  serviceProtocolStream: Array<ServiceProtocol>;
  /** fetch data from the table: "service_protocol_template" */
  serviceProtocolTemplate: Array<ServiceProtocolTemplate>;
  /** fetch aggregated fields from the table: "service_protocol_template" */
  serviceProtocolTemplateAggregate: ServiceProtocolTemplateAggregate;
  /** fetch data from the table: "service_protocol_template" using primary key columns */
  serviceProtocolTemplateByPk?: Maybe<ServiceProtocolTemplate>;
  /** fetch data from the table in a streaming manner: "service_protocol_template" */
  serviceProtocolTemplateStream: Array<ServiceProtocolTemplate>;
  /** fetch data from the table: "service_type" */
  serviceType: Array<ServiceType>;
  /** fetch aggregated fields from the table: "service_type" */
  serviceTypeAggregate: ServiceTypeAggregate;
  /** fetch data from the table: "service_type" using primary key columns */
  serviceTypeByPk?: Maybe<ServiceType>;
  /** fetch data from the table: "service_type_category" */
  serviceTypeCategory: Array<ServiceTypeCategory>;
  /** fetch aggregated fields from the table: "service_type_category" */
  serviceTypeCategoryAggregate: ServiceTypeCategoryAggregate;
  /** fetch data from the table: "service_type_category" using primary key columns */
  serviceTypeCategoryByPk?: Maybe<ServiceTypeCategory>;
  /** fetch data from the table in a streaming manner: "service_type_category" */
  serviceTypeCategoryStream: Array<ServiceTypeCategory>;
  /** fetch data from the table in a streaming manner: "service_type" */
  serviceTypeStream: Array<ServiceType>;
  /** fetch data from the table: "utils_shortmoxieurl" */
  shortMoxieUrl: Array<ShortMoxieUrl>;
  /** fetch aggregated fields from the table: "utils_shortmoxieurl" */
  shortMoxieUrlAggregate: ShortMoxieUrlAggregate;
  /** fetch data from the table: "utils_shortmoxieurl" using primary key columns */
  shortMoxieUrlByPk?: Maybe<ShortMoxieUrl>;
  /** fetch data from the table in a streaming manner: "utils_shortmoxieurl" */
  shortMoxieUrlStream: Array<ShortMoxieUrl>;
  /** fetch data from the table: "medspas_standingorder" */
  standingOrder: Array<StandingOrder>;
  /** fetch aggregated fields from the table: "medspas_standingorder" */
  standingOrderAggregate: StandingOrderAggregate;
  /** fetch data from the table: "medspas_standingorder" using primary key columns */
  standingOrderByPk?: Maybe<StandingOrder>;
  /** fetch data from the table in a streaming manner: "medspas_standingorder" */
  standingOrderStream: Array<StandingOrder>;
  /** fetch data from the table: "standing_order_template" */
  standingOrderTemplate: Array<StandingOrderTemplate>;
  /** fetch aggregated fields from the table: "standing_order_template" */
  standingOrderTemplateAggregate: StandingOrderTemplateAggregate;
  /** fetch data from the table: "standing_order_template" using primary key columns */
  standingOrderTemplateByPk?: Maybe<StandingOrderTemplate>;
  /** fetch data from the table in a streaming manner: "standing_order_template" */
  standingOrderTemplateStream: Array<StandingOrderTemplate>;
  /** fetch data from the table: "supplier_information" */
  supplierInformation: Array<SupplierInformation>;
  /** fetch aggregated fields from the table: "supplier_information" */
  supplierInformationAggregate: SupplierInformationAggregate;
  /** fetch data from the table: "supplier_information" using primary key columns */
  supplierInformationByPk?: Maybe<SupplierInformation>;
  /** fetch data from the table in a streaming manner: "supplier_information" */
  supplierInformationStream: Array<SupplierInformation>;
  /** fetch data from the table: "telehealth_visit_details" */
  telehealthVisitDetails: Array<TelehealthVisitDetails>;
  /** fetch aggregated fields from the table: "telehealth_visit_details" */
  telehealthVisitDetailsAggregate: TelehealthVisitDetailsAggregate;
  /** fetch data from the table: "telehealth_visit_details" using primary key columns */
  telehealthVisitDetailsByPk?: Maybe<TelehealthVisitDetails>;
  /** fetch data from the table in a streaming manner: "telehealth_visit_details" */
  telehealthVisitDetailsStream: Array<TelehealthVisitDetails>;
  /** fetch data from the table: "standing_order" */
  tmpStandingOrder: Array<TmpStandingOrder>;
  /** fetch aggregated fields from the table: "standing_order" */
  tmpStandingOrderAggregate: TmpStandingOrderAggregate;
  /** fetch data from the table: "standing_order" using primary key columns */
  tmpStandingOrderByPk?: Maybe<TmpStandingOrder>;
  /** fetch data from the table in a streaming manner: "standing_order" */
  tmpStandingOrderStream: Array<TmpStandingOrder>;
  /** fetch data from the table: "url_pattern_permission" */
  urlPatternPermission: Array<UrlPatternPermission>;
  /** fetch aggregated fields from the table: "url_pattern_permission" */
  urlPatternPermissionAggregate: UrlPatternPermissionAggregate;
  /** fetch data from the table: "url_pattern_permission" using primary key columns */
  urlPatternPermissionByPk?: Maybe<UrlPatternPermission>;
  /** fetch data from the table in a streaming manner: "url_pattern_permission" */
  urlPatternPermissionStream: Array<UrlPatternPermission>;
  /** fetch data from the table: "accounts_user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "accounts_user" */
  userAggregate: UserAggregate;
  /** fetch data from the table: "accounts_user" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "medspas_usermedspa" */
  userMedspa: Array<UserMedspa>;
  /** fetch aggregated fields from the table: "medspas_usermedspa" */
  userMedspaAggregate: UserMedspaAggregate;
  /** fetch data from the table: "medspas_usermedspa" using primary key columns */
  userMedspaByPk?: Maybe<UserMedspa>;
  /** fetch data from the table: "user_medspa_feature_permissions" */
  userMedspaFeaturePermissions: Array<UserMedspaFeaturePermissions>;
  /** fetch aggregated fields from the table: "user_medspa_feature_permissions" */
  userMedspaFeaturePermissionsAggregate: UserMedspaFeaturePermissionsAggregate;
  /** fetch data from the table in a streaming manner: "user_medspa_feature_permissions" */
  userMedspaFeaturePermissionsStream: Array<UserMedspaFeaturePermissions>;
  /** fetch data from the table: "user_medspa_model_permissions" */
  userMedspaModelPermissions: Array<UserMedspaModelPermissions>;
  /** fetch aggregated fields from the table: "user_medspa_model_permissions" */
  userMedspaModelPermissionsAggregate: UserMedspaModelPermissionsAggregate;
  /** fetch data from the table in a streaming manner: "user_medspa_model_permissions" */
  userMedspaModelPermissionsStream: Array<UserMedspaModelPermissions>;
  /** fetch data from the table in a streaming manner: "medspas_usermedspa" */
  userMedspaStream: Array<UserMedspa>;
  /** fetch data from the table: "user_medspa_url_permissions" */
  userMedspaUrlPermissions: Array<UserMedspaUrlPermissions>;
  /** fetch aggregated fields from the table: "user_medspa_url_permissions" */
  userMedspaUrlPermissionsAggregate: UserMedspaUrlPermissionsAggregate;
  /** fetch data from the table in a streaming manner: "user_medspa_url_permissions" */
  userMedspaUrlPermissionsStream: Array<UserMedspaUrlPermissions>;
  /** fetch data from the table in a streaming manner: "accounts_user" */
  userStream: Array<User>;
  /** fetch data from the table: "visits_visit" */
  visit: Array<Visit>;
  /** fetch aggregated fields from the table: "visits_visit" */
  visitAggregate: VisitAggregate;
  /** fetch data from the table: "visits_visit" using primary key columns */
  visitByPk?: Maybe<Visit>;
  /** fetch data from the table: "visits_visitreviewstatuschangelog" */
  visitReviewStatusChangeLog: Array<VisitReviewStatusChangeLog>;
  /** fetch aggregated fields from the table: "visits_visitreviewstatuschangelog" */
  visitReviewStatusChangeLogAggregate: VisitReviewStatusChangeLogAggregate;
  /** fetch data from the table: "visits_visitreviewstatuschangelog" using primary key columns */
  visitReviewStatusChangeLogByPk?: Maybe<VisitReviewStatusChangeLog>;
  /** fetch data from the table in a streaming manner: "visits_visitreviewstatuschangelog" */
  visitReviewStatusChangeLogStream: Array<VisitReviewStatusChangeLog>;
  /** fetch data from the table: "visits_visitstatuschangelog" */
  visitStatusChangeLog: Array<VisitStatusChangeLog>;
  /** fetch aggregated fields from the table: "visits_visitstatuschangelog" */
  visitStatusChangeLogAggregate: VisitStatusChangeLogAggregate;
  /** fetch data from the table: "visits_visitstatuschangelog" using primary key columns */
  visitStatusChangeLogByPk?: Maybe<VisitStatusChangeLog>;
  /** fetch data from the table in a streaming manner: "visits_visitstatuschangelog" */
  visitStatusChangeLogStream: Array<VisitStatusChangeLog>;
  /** fetch data from the table in a streaming manner: "visits_visit" */
  visitStream: Array<Visit>;
  /** fetch data from the table: "visits_adversereactionchangelog" */
  visitsAdversereactionchangelog: Array<VisitsAdversereactionchangelog>;
  /** fetch aggregated fields from the table: "visits_adversereactionchangelog" */
  visitsAdversereactionchangelogAggregate: VisitsAdversereactionchangelogAggregate;
  /** fetch data from the table: "visits_adversereactionchangelog" using primary key columns */
  visitsAdversereactionchangelogByPk?: Maybe<VisitsAdversereactionchangelog>;
  /** fetch data from the table in a streaming manner: "visits_adversereactionchangelog" */
  visitsAdversereactionchangelogStream: Array<VisitsAdversereactionchangelog>;
  /** fetch data from the table: "visits_chartsignature" */
  visitsChartsignature: Array<VisitsChartsignature>;
  /** fetch aggregated fields from the table: "visits_chartsignature" */
  visitsChartsignatureAggregate: VisitsChartsignatureAggregate;
  /** fetch data from the table: "visits_chartsignature" using primary key columns */
  visitsChartsignatureByPk?: Maybe<VisitsChartsignature>;
  /** fetch data from the table in a streaming manner: "visits_chartsignature" */
  visitsChartsignatureStream: Array<VisitsChartsignature>;
  /** fetch data from the table: "visits_telehealthvisitdetailschangelog" */
  visitsTelehealthvisitdetailschangelog: Array<VisitsTelehealthvisitdetailschangelog>;
  /** fetch aggregated fields from the table: "visits_telehealthvisitdetailschangelog" */
  visitsTelehealthvisitdetailschangelogAggregate: VisitsTelehealthvisitdetailschangelogAggregate;
  /** fetch data from the table: "visits_telehealthvisitdetailschangelog" using primary key columns */
  visitsTelehealthvisitdetailschangelogByPk?: Maybe<VisitsTelehealthvisitdetailschangelog>;
  /** fetch data from the table in a streaming manner: "visits_telehealthvisitdetailschangelog" */
  visitsTelehealthvisitdetailschangelogStream: Array<VisitsTelehealthvisitdetailschangelog>;
  /** fetch data from the table: "wallet_credit_line" */
  walletCreditLine: Array<WalletCreditLine>;
  /** fetch aggregated fields from the table: "wallet_credit_line" */
  walletCreditLineAggregate: WalletCreditLineAggregate;
  /** fetch data from the table: "wallet_credit_line" using primary key columns */
  walletCreditLineByPk?: Maybe<WalletCreditLine>;
  /** fetch data from the table in a streaming manner: "wallet_credit_line" */
  walletCreditLineStream: Array<WalletCreditLine>;
  /** fetch data from the table: "wallet_credit_type" */
  walletCreditType: Array<WalletCreditType>;
  /** fetch aggregated fields from the table: "wallet_credit_type" */
  walletCreditTypeAggregate: WalletCreditTypeAggregate;
  /** fetch data from the table: "wallet_credit_type" using primary key columns */
  walletCreditTypeByPk?: Maybe<WalletCreditType>;
  /** fetch data from the table in a streaming manner: "wallet_credit_type" */
  walletCreditTypeStream: Array<WalletCreditType>;
  /** fetch data from the table: "wallets_wallettransaction" */
  walletTransaction: Array<WalletTransaction>;
  /** fetch aggregated fields from the table: "wallets_wallettransaction" */
  walletTransactionAggregate: WalletTransactionAggregate;
  /** fetch data from the table: "wallets_wallettransaction" using primary key columns */
  walletTransactionByPk?: Maybe<WalletTransaction>;
  /** fetch data from the table: "wallet_transaction_source_tmp" */
  walletTransactionSourceTmp: Array<WalletTransactionSourceTmp>;
  /** fetch aggregated fields from the table: "wallet_transaction_source_tmp" */
  walletTransactionSourceTmpAggregate: WalletTransactionSourceTmpAggregate;
  /** fetch data from the table: "wallet_transaction_source_tmp" using primary key columns */
  walletTransactionSourceTmpByPk?: Maybe<WalletTransactionSourceTmp>;
  /** fetch data from the table in a streaming manner: "wallet_transaction_source_tmp" */
  walletTransactionSourceTmpStream: Array<WalletTransactionSourceTmp>;
  /** fetch data from the table in a streaming manner: "wallets_wallettransaction" */
  walletTransactionStream: Array<WalletTransaction>;
  /** An array relationship */
  walletTransactionsSources: Array<WalletTransactionsSources>;
  /** An aggregate relationship */
  walletTransactionsSourcesAggregate: WalletTransactionsSourcesAggregate;
  /** fetch data from the table: "wallet_transactions_sources" using primary key columns */
  walletTransactionsSourcesByPk?: Maybe<WalletTransactionsSources>;
  /** fetch data from the table in a streaming manner: "wallet_transactions_sources" */
  walletTransactionsSourcesStream: Array<WalletTransactionsSources>;
};

export type Subscription_RootAddressArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};

export type Subscription_RootAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};

export type Subscription_RootAddressByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootAddressStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<AddressStreamCursorInput>>;
  where?: InputMaybe<AddressBoolExp>;
};

export type Subscription_RootAdministrativeDivisionArgs = {
  distinctOn?: InputMaybe<Array<AdministrativeDivisionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdministrativeDivisionOrderBy>>;
  where?: InputMaybe<AdministrativeDivisionBoolExp>;
};

export type Subscription_RootAdministrativeDivisionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AdministrativeDivisionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdministrativeDivisionOrderBy>>;
  where?: InputMaybe<AdministrativeDivisionBoolExp>;
};

export type Subscription_RootAdministrativeDivisionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootAdministrativeDivisionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<AdministrativeDivisionStreamCursorInput>>;
  where?: InputMaybe<AdministrativeDivisionBoolExp>;
};

export type Subscription_RootAdverseReactionArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionOrderBy>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

export type Subscription_RootAdverseReactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionOrderBy>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

export type Subscription_RootAdverseReactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootAdverseReactionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<AdverseReactionStreamCursorInput>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

export type Subscription_RootAdverseReactionTemplateArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionTemplateOrderBy>>;
  where?: InputMaybe<AdverseReactionTemplateBoolExp>;
};

export type Subscription_RootAdverseReactionTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionTemplateOrderBy>>;
  where?: InputMaybe<AdverseReactionTemplateBoolExp>;
};

export type Subscription_RootAdverseReactionTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootAdverseReactionTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<AdverseReactionTemplateStreamCursorInput>>;
  where?: InputMaybe<AdverseReactionTemplateBoolExp>;
};

export type Subscription_RootAvailabilityEventArgs = {
  distinctOn?: InputMaybe<Array<AvailabilityEventSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvailabilityEventOrderBy>>;
  where?: InputMaybe<AvailabilityEventBoolExp>;
};

export type Subscription_RootAvailabilityEventAggregateArgs = {
  distinctOn?: InputMaybe<Array<AvailabilityEventSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvailabilityEventOrderBy>>;
  where?: InputMaybe<AvailabilityEventBoolExp>;
};

export type Subscription_RootAvailabilityEventByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootAvailabilityEventStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<AvailabilityEventStreamCursorInput>>;
  where?: InputMaybe<AvailabilityEventBoolExp>;
};

export type Subscription_RootCareFormArgs = {
  distinctOn?: InputMaybe<Array<CareFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormOrderBy>>;
  where?: InputMaybe<CareFormBoolExp>;
};

export type Subscription_RootCareFormAggregateArgs = {
  distinctOn?: InputMaybe<Array<CareFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormOrderBy>>;
  where?: InputMaybe<CareFormBoolExp>;
};

export type Subscription_RootCareFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootCareFormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

export type Subscription_RootCareFormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CareFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CareFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

export type Subscription_RootCareFormServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootCareFormServiceMenuItemsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<CareFormServiceMenuItemsStreamCursorInput>>;
  where?: InputMaybe<CareFormServiceMenuItemsBoolExp>;
};

export type Subscription_RootCareFormStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<CareFormStreamCursorInput>>;
  where?: InputMaybe<CareFormBoolExp>;
};

export type Subscription_RootClientArgs = {
  distinctOn?: InputMaybe<Array<ClientSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientOrderBy>>;
  where?: InputMaybe<ClientBoolExp>;
};

export type Subscription_RootClientAccessTokenArgs = {
  distinctOn?: InputMaybe<Array<ClientAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientAccessTokenOrderBy>>;
  where?: InputMaybe<ClientAccessTokenBoolExp>;
};

export type Subscription_RootClientAccessTokenAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientAccessTokenOrderBy>>;
  where?: InputMaybe<ClientAccessTokenBoolExp>;
};

export type Subscription_RootClientAccessTokenByPkArgs = {
  token: Scalars["String"]["input"];
};

export type Subscription_RootClientAccessTokenStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ClientAccessTokenStreamCursorInput>>;
  where?: InputMaybe<ClientAccessTokenBoolExp>;
};

export type Subscription_RootClientAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientOrderBy>>;
  where?: InputMaybe<ClientBoolExp>;
};

export type Subscription_RootClientByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootClientMembershipArgs = {
  distinctOn?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientMembershipOrderBy>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

export type Subscription_RootClientMembershipAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClientMembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientMembershipOrderBy>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

export type Subscription_RootClientMembershipByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootClientMembershipStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ClientMembershipStreamCursorInput>>;
  where?: InputMaybe<ClientMembershipBoolExp>;
};

export type Subscription_RootClientStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ClientStreamCursorInput>>;
  where?: InputMaybe<ClientBoolExp>;
};

export type Subscription_RootComplianceTaskArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskOrderBy>>;
  where?: InputMaybe<ComplianceTaskBoolExp>;
};

export type Subscription_RootComplianceTaskAggregateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskOrderBy>>;
  where?: InputMaybe<ComplianceTaskBoolExp>;
};

export type Subscription_RootComplianceTaskByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootComplianceTaskDocumentArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskDocumentOrderBy>>;
  where?: InputMaybe<ComplianceTaskDocumentBoolExp>;
};

export type Subscription_RootComplianceTaskDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskDocumentOrderBy>>;
  where?: InputMaybe<ComplianceTaskDocumentBoolExp>;
};

export type Subscription_RootComplianceTaskDocumentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootComplianceTaskDocumentStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ComplianceTaskDocumentStreamCursorInput>>;
  where?: InputMaybe<ComplianceTaskDocumentBoolExp>;
};

export type Subscription_RootComplianceTaskStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ComplianceTaskStreamCursorInput>>;
  where?: InputMaybe<ComplianceTaskBoolExp>;
};

export type Subscription_RootComplianceTaskTemplateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskTemplateOrderBy>>;
  where?: InputMaybe<ComplianceTaskTemplateBoolExp>;
};

export type Subscription_RootComplianceTaskTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskTemplateOrderBy>>;
  where?: InputMaybe<ComplianceTaskTemplateBoolExp>;
};

export type Subscription_RootComplianceTaskTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootComplianceTaskTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ComplianceTaskTemplateStreamCursorInput>>;
  where?: InputMaybe<ComplianceTaskTemplateBoolExp>;
};

export type Subscription_RootCompliantNoteArgs = {
  distinctOn?: InputMaybe<Array<CompliantNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompliantNoteOrderBy>>;
  where?: InputMaybe<CompliantNoteBoolExp>;
};

export type Subscription_RootCompliantNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompliantNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompliantNoteOrderBy>>;
  where?: InputMaybe<CompliantNoteBoolExp>;
};

export type Subscription_RootCompliantNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootCompliantNoteStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<CompliantNoteStreamCursorInput>>;
  where?: InputMaybe<CompliantNoteBoolExp>;
};

export type Subscription_RootCompliantNoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<CompliantNoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompliantNoteTemplateOrderBy>>;
  where?: InputMaybe<CompliantNoteTemplateBoolExp>;
};

export type Subscription_RootCompliantNoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<CompliantNoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<CompliantNoteTemplateOrderBy>>;
  where?: InputMaybe<CompliantNoteTemplateBoolExp>;
};

export type Subscription_RootCompliantNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootCompliantNoteTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<CompliantNoteTemplateStreamCursorInput>>;
  where?: InputMaybe<CompliantNoteTemplateBoolExp>;
};

export type Subscription_RootConsentFormArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormOrderBy>>;
  where?: InputMaybe<ConsentFormBoolExp>;
};

export type Subscription_RootConsentFormAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormOrderBy>>;
  where?: InputMaybe<ConsentFormBoolExp>;
};

export type Subscription_RootConsentFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootConsentFormServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

export type Subscription_RootConsentFormServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormServiceMenuItemsOrderBy>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

export type Subscription_RootConsentFormServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootConsentFormServiceMenuItemsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ConsentFormServiceMenuItemsStreamCursorInput>>;
  where?: InputMaybe<ConsentFormServiceMenuItemsBoolExp>;
};

export type Subscription_RootConsentFormSignatureArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

export type Subscription_RootConsentFormSignatureAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConsentFormSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ConsentFormSignatureOrderBy>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

export type Subscription_RootConsentFormSignatureByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootConsentFormSignatureStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ConsentFormSignatureStreamCursorInput>>;
  where?: InputMaybe<ConsentFormSignatureBoolExp>;
};

export type Subscription_RootConsentFormStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ConsentFormStreamCursorInput>>;
  where?: InputMaybe<ConsentFormBoolExp>;
};

export type Subscription_RootDepositLineArgs = {
  distinctOn?: InputMaybe<Array<DepositLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DepositLineOrderBy>>;
  where?: InputMaybe<DepositLineBoolExp>;
};

export type Subscription_RootDepositLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<DepositLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DepositLineOrderBy>>;
  where?: InputMaybe<DepositLineBoolExp>;
};

export type Subscription_RootDepositLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootDepositLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<DepositLineStreamCursorInput>>;
  where?: InputMaybe<DepositLineBoolExp>;
};

export type Subscription_RootDiagramArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

export type Subscription_RootDiagramAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

export type Subscription_RootDiagramByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootDiagramStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<DiagramStreamCursorInput>>;
  where?: InputMaybe<DiagramBoolExp>;
};

export type Subscription_RootDiagramTemplateArgs = {
  distinctOn?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramTemplateOrderBy>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

export type Subscription_RootDiagramTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramTemplateOrderBy>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

export type Subscription_RootDiagramTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootDiagramTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<DiagramTemplateStreamCursorInput>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

export type Subscription_RootDiscountArgs = {
  distinctOn?: InputMaybe<Array<DiscountSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountOrderBy>>;
  where?: InputMaybe<DiscountBoolExp>;
};

export type Subscription_RootDiscountAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountOrderBy>>;
  where?: InputMaybe<DiscountBoolExp>;
};

export type Subscription_RootDiscountByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootDiscountLineArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

export type Subscription_RootDiscountLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountLineOrderBy>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

export type Subscription_RootDiscountLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootDiscountLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<DiscountLineStreamCursorInput>>;
  where?: InputMaybe<DiscountLineBoolExp>;
};

export type Subscription_RootDiscountRetailProductsArgs = {
  distinctOn?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountRetailProductsOrderBy>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

export type Subscription_RootDiscountRetailProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountRetailProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountRetailProductsOrderBy>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

export type Subscription_RootDiscountRetailProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootDiscountRetailProductsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<DiscountRetailProductsStreamCursorInput>>;
  where?: InputMaybe<DiscountRetailProductsBoolExp>;
};

export type Subscription_RootDiscountServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceMenuItemsOrderBy>>;
  where?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
};

export type Subscription_RootDiscountServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceMenuItemsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceMenuItemsOrderBy>>;
  where?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
};

export type Subscription_RootDiscountServiceMenuItemsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootDiscountServiceMenuItemsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<DiscountServiceMenuItemsStreamCursorInput>>;
  where?: InputMaybe<DiscountServiceMenuItemsBoolExp>;
};

export type Subscription_RootDiscountServiceProductsArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceProductsOrderBy>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

export type Subscription_RootDiscountServiceProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiscountServiceProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiscountServiceProductsOrderBy>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

export type Subscription_RootDiscountServiceProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootDiscountServiceProductsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<DiscountServiceProductsStreamCursorInput>>;
  where?: InputMaybe<DiscountServiceProductsBoolExp>;
};

export type Subscription_RootDiscountStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<DiscountStreamCursorInput>>;
  where?: InputMaybe<DiscountBoolExp>;
};

export type Subscription_RootFeaturePermissionArgs = {
  distinctOn?: InputMaybe<Array<FeaturePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeaturePermissionOrderBy>>;
  where?: InputMaybe<FeaturePermissionBoolExp>;
};

export type Subscription_RootFeaturePermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeaturePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeaturePermissionOrderBy>>;
  where?: InputMaybe<FeaturePermissionBoolExp>;
};

export type Subscription_RootFeaturePermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFeaturePermissionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FeaturePermissionStreamCursorInput>>;
  where?: InputMaybe<FeaturePermissionBoolExp>;
};

export type Subscription_RootFeeArgs = {
  distinctOn?: InputMaybe<Array<FeeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeOrderBy>>;
  where?: InputMaybe<FeeBoolExp>;
};

export type Subscription_RootFeeAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeOrderBy>>;
  where?: InputMaybe<FeeBoolExp>;
};

export type Subscription_RootFeeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFeeLineArgs = {
  distinctOn?: InputMaybe<Array<FeeLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeLineOrderBy>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

export type Subscription_RootFeeLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<FeeLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FeeLineOrderBy>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

export type Subscription_RootFeeLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFeeLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FeeLineStreamCursorInput>>;
  where?: InputMaybe<FeeLineBoolExp>;
};

export type Subscription_RootFeeStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FeeStreamCursorInput>>;
  where?: InputMaybe<FeeBoolExp>;
};

export type Subscription_RootFormArgs = {
  distinctOn?: InputMaybe<Array<FormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormOrderBy>>;
  where?: InputMaybe<FormBoolExp>;
};

export type Subscription_RootFormAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormOrderBy>>;
  where?: InputMaybe<FormBoolExp>;
};

export type Subscription_RootFormByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFormServiceMenuItemArgs = {
  distinctOn?: InputMaybe<Array<FormServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormServiceMenuItemOrderBy>>;
  where?: InputMaybe<FormServiceMenuItemBoolExp>;
};

export type Subscription_RootFormServiceMenuItemAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormServiceMenuItemOrderBy>>;
  where?: InputMaybe<FormServiceMenuItemBoolExp>;
};

export type Subscription_RootFormServiceMenuItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFormServiceMenuItemStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FormServiceMenuItemStreamCursorInput>>;
  where?: InputMaybe<FormServiceMenuItemBoolExp>;
};

export type Subscription_RootFormStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FormStreamCursorInput>>;
  where?: InputMaybe<FormBoolExp>;
};

export type Subscription_RootFormSubmissionArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

export type Subscription_RootFormSubmissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionOrderBy>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

export type Subscription_RootFormSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFormSubmissionGfeStatusChangeLogArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogOrderBy>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

export type Subscription_RootFormSubmissionGfeStatusChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogOrderBy>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

export type Subscription_RootFormSubmissionGfeStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFormSubmissionGfeStatusChangeLogStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FormSubmissionGfeStatusChangeLogStreamCursorInput>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

export type Subscription_RootFormSubmissionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FormSubmissionStreamCursorInput>>;
  where?: InputMaybe<FormSubmissionBoolExp>;
};

export type Subscription_RootFormsFormMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

export type Subscription_RootFormsFormMedspaServiceOfferingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormsFormMedspaServiceOfferingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormMedspaServiceOfferingsOrderBy>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

export type Subscription_RootFormsFormMedspaServiceOfferingsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFormsFormMedspaServiceOfferingsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FormsFormMedspaServiceOfferingsStreamCursorInput>>;
  where?: InputMaybe<FormsFormMedspaServiceOfferingsBoolExp>;
};

export type Subscription_RootFormsFormsubmissionversionArgs = {
  distinctOn?: InputMaybe<Array<FormsFormsubmissionversionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormsubmissionversionOrderBy>>;
  where?: InputMaybe<FormsFormsubmissionversionBoolExp>;
};

export type Subscription_RootFormsFormsubmissionversionAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormsFormsubmissionversionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormsFormsubmissionversionOrderBy>>;
  where?: InputMaybe<FormsFormsubmissionversionBoolExp>;
};

export type Subscription_RootFormsFormsubmissionversionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootFormsFormsubmissionversionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<FormsFormsubmissionversionStreamCursorInput>>;
  where?: InputMaybe<FormsFormsubmissionversionBoolExp>;
};

export type Subscription_RootGfeDiagnosisArgs = {
  distinctOn?: InputMaybe<Array<GfeDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeDiagnosisOrderBy>>;
  where?: InputMaybe<GfeDiagnosisBoolExp>;
};

export type Subscription_RootGfeDiagnosisAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeDiagnosisOrderBy>>;
  where?: InputMaybe<GfeDiagnosisBoolExp>;
};

export type Subscription_RootGfeDiagnosisByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootGfeDiagnosisStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GfeDiagnosisStreamCursorInput>>;
  where?: InputMaybe<GfeDiagnosisBoolExp>;
};

export type Subscription_RootGfeReviewDiagnosisArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

export type Subscription_RootGfeReviewDiagnosisAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewDiagnosisSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewDiagnosisOrderBy>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

export type Subscription_RootGfeReviewDiagnosisByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootGfeReviewDiagnosisStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GfeReviewDiagnosisStreamCursorInput>>;
  where?: InputMaybe<GfeReviewDiagnosisBoolExp>;
};

export type Subscription_RootGfeReviewRequestArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

export type Subscription_RootGfeReviewRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewRequestOrderBy>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

export type Subscription_RootGfeReviewRequestByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootGfeReviewRequestStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GfeReviewRequestStreamCursorInput>>;
  where?: InputMaybe<GfeReviewRequestBoolExp>;
};

export type Subscription_RootGfeReviewSubmissionArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewSubmissionOrderBy>>;
  where?: InputMaybe<GfeReviewSubmissionBoolExp>;
};

export type Subscription_RootGfeReviewSubmissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeReviewSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeReviewSubmissionOrderBy>>;
  where?: InputMaybe<GfeReviewSubmissionBoolExp>;
};

export type Subscription_RootGfeReviewSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootGfeReviewSubmissionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GfeReviewSubmissionStreamCursorInput>>;
  where?: InputMaybe<GfeReviewSubmissionBoolExp>;
};

export type Subscription_RootGfeServiceProductIndicationArgs = {
  distinctOn?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeServiceProductIndicationOrderBy>>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

export type Subscription_RootGfeServiceProductIndicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeServiceProductIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeServiceProductIndicationOrderBy>>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

export type Subscription_RootGfeServiceProductIndicationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootGfeServiceProductIndicationStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GfeServiceProductIndicationStreamCursorInput>>;
  where?: InputMaybe<GfeServiceProductIndicationBoolExp>;
};

export type Subscription_RootGfeSynchronousReviewDocumentArgs = {
  distinctOn?: InputMaybe<Array<GfeSynchronousReviewDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeSynchronousReviewDocumentOrderBy>>;
  where?: InputMaybe<GfeSynchronousReviewDocumentBoolExp>;
};

export type Subscription_RootGfeSynchronousReviewDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeSynchronousReviewDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeSynchronousReviewDocumentOrderBy>>;
  where?: InputMaybe<GfeSynchronousReviewDocumentBoolExp>;
};

export type Subscription_RootGfeSynchronousReviewDocumentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootGfeSynchronousReviewDocumentStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GfeSynchronousReviewDocumentStreamCursorInput>>;
  where?: InputMaybe<GfeSynchronousReviewDocumentBoolExp>;
};

export type Subscription_RootGfeTreatmentIndicationArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

export type Subscription_RootGfeTreatmentIndicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<GfeTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GfeTreatmentIndicationOrderBy>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

export type Subscription_RootGfeTreatmentIndicationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootGfeTreatmentIndicationStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GfeTreatmentIndicationStreamCursorInput>>;
  where?: InputMaybe<GfeTreatmentIndicationBoolExp>;
};

export type Subscription_RootGiftBuilderBannerArgs = {
  distinctOn?: InputMaybe<Array<GiftBuilderBannerSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GiftBuilderBannerOrderBy>>;
  where?: InputMaybe<GiftBuilderBannerBoolExp>;
};

export type Subscription_RootGiftBuilderBannerAggregateArgs = {
  distinctOn?: InputMaybe<Array<GiftBuilderBannerSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<GiftBuilderBannerOrderBy>>;
  where?: InputMaybe<GiftBuilderBannerBoolExp>;
};

export type Subscription_RootGiftBuilderBannerByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootGiftBuilderBannerStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<GiftBuilderBannerStreamCursorInput>>;
  where?: InputMaybe<GiftBuilderBannerBoolExp>;
};

export type Subscription_RootInventoryLotArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

export type Subscription_RootInventoryLotAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

export type Subscription_RootInventoryLotByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootInventoryLotStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<InventoryLotStreamCursorInput>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

export type Subscription_RootInventoryTransactionArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

export type Subscription_RootInventoryTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

export type Subscription_RootInventoryTransactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootInventoryTransactionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<InventoryTransactionStreamCursorInput>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

export type Subscription_RootInvoiceArgs = {
  distinctOn?: InputMaybe<Array<InvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceOrderBy>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

export type Subscription_RootInvoiceAggregateArgs = {
  distinctOn?: InputMaybe<Array<InvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceOrderBy>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

export type Subscription_RootInvoiceByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootInvoiceStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<InvoiceStreamCursorInput>>;
  where?: InputMaybe<InvoiceBoolExp>;
};

export type Subscription_RootLastGfeReviewTreatmentIndicationArgs = {
  distinctOn?: InputMaybe<Array<LastGfeReviewTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LastGfeReviewTreatmentIndicationOrderBy>>;
  where?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
};

export type Subscription_RootLastGfeReviewTreatmentIndicationAggregateArgs = {
  distinctOn?: InputMaybe<Array<LastGfeReviewTreatmentIndicationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<LastGfeReviewTreatmentIndicationOrderBy>>;
  where?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
};

export type Subscription_RootLastGfeReviewTreatmentIndicationStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<LastGfeReviewTreatmentIndicationStreamCursorInput>>;
  where?: InputMaybe<LastGfeReviewTreatmentIndicationBoolExp>;
};

export type Subscription_RootMarketingTemplateArgs = {
  distinctOn?: InputMaybe<Array<MarketingTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MarketingTemplateOrderBy>>;
  where?: InputMaybe<MarketingTemplateBoolExp>;
};

export type Subscription_RootMarketingTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<MarketingTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MarketingTemplateOrderBy>>;
  where?: InputMaybe<MarketingTemplateBoolExp>;
};

export type Subscription_RootMarketingTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMarketingTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MarketingTemplateStreamCursorInput>>;
  where?: InputMaybe<MarketingTemplateBoolExp>;
};

export type Subscription_RootMdMeetingArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingOrderBy>>;
  where?: InputMaybe<MdMeetingBoolExp>;
};

export type Subscription_RootMdMeetingAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingOrderBy>>;
  where?: InputMaybe<MdMeetingBoolExp>;
};

export type Subscription_RootMdMeetingAttendeesArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingAttendeesOrderBy>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

export type Subscription_RootMdMeetingAttendeesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingAttendeesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingAttendeesOrderBy>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

export type Subscription_RootMdMeetingAttendeesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMdMeetingAttendeesStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MdMeetingAttendeesStreamCursorInput>>;
  where?: InputMaybe<MdMeetingAttendeesBoolExp>;
};

export type Subscription_RootMdMeetingByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMdMeetingNoteArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteOrderBy>>;
  where?: InputMaybe<MdMeetingNoteBoolExp>;
};

export type Subscription_RootMdMeetingNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteOrderBy>>;
  where?: InputMaybe<MdMeetingNoteBoolExp>;
};

export type Subscription_RootMdMeetingNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMdMeetingNoteStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MdMeetingNoteStreamCursorInput>>;
  where?: InputMaybe<MdMeetingNoteBoolExp>;
};

export type Subscription_RootMdMeetingNoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteTemplateOrderBy>>;
  where?: InputMaybe<MdMeetingNoteTemplateBoolExp>;
};

export type Subscription_RootMdMeetingNoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<MdMeetingNoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MdMeetingNoteTemplateOrderBy>>;
  where?: InputMaybe<MdMeetingNoteTemplateBoolExp>;
};

export type Subscription_RootMdMeetingNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMdMeetingNoteTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MdMeetingNoteTemplateStreamCursorInput>>;
  where?: InputMaybe<MdMeetingNoteTemplateBoolExp>;
};

export type Subscription_RootMdMeetingStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MdMeetingStreamCursorInput>>;
  where?: InputMaybe<MdMeetingBoolExp>;
};

export type Subscription_RootMedicalDirectorArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorOrderBy>>;
  where?: InputMaybe<MedicalDirectorBoolExp>;
};

export type Subscription_RootMedicalDirectorAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorOrderBy>>;
  where?: InputMaybe<MedicalDirectorBoolExp>;
};

export type Subscription_RootMedicalDirectorByPkArgs = {
  userId: Scalars["bigint"]["input"];
};

export type Subscription_RootMedicalDirectorStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedicalDirectorStreamCursorInput>>;
  where?: InputMaybe<MedicalDirectorBoolExp>;
};

export type Subscription_RootMedicalDirectorSubscriptionArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorSubscriptionOrderBy>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

export type Subscription_RootMedicalDirectorSubscriptionAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedicalDirectorSubscriptionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedicalDirectorSubscriptionOrderBy>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

export type Subscription_RootMedicalDirectorSubscriptionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedicalDirectorSubscriptionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedicalDirectorSubscriptionStreamCursorInput>>;
  where?: InputMaybe<MedicalDirectorSubscriptionBoolExp>;
};

export type Subscription_RootMedspaArgs = {
  distinctOn?: InputMaybe<Array<MedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaOrderBy>>;
  where?: InputMaybe<MedspaBoolExp>;
};

export type Subscription_RootMedspaAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaOrderBy>>;
  where?: InputMaybe<MedspaBoolExp>;
};

export type Subscription_RootMedspaByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaRoomsAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaRoomsAppointmentsOrderBy>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

export type Subscription_RootMedspaRoomsAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaRoomsAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaRoomsAppointmentsOrderBy>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

export type Subscription_RootMedspaRoomsAppointmentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaRoomsAppointmentsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaRoomsAppointmentsStreamCursorInput>>;
  where?: InputMaybe<MedspaRoomsAppointmentsBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaServiceMenuItemMedspaServiceOfferingsArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<MedspaServiceMenuItemMedspaServiceOfferingsOrderBy>
  >;
  where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemMedspaServiceOfferingsAggregateArgs =
  {
    distinctOn?: InputMaybe<
      Array<MedspaServiceMenuItemMedspaServiceOfferingsSelectColumn>
    >;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<
      Array<MedspaServiceMenuItemMedspaServiceOfferingsOrderBy>
    >;
    where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
  };

export type Subscription_RootMedspaServiceMenuItemMedspaServiceOfferingsByPkArgs =
  {
    id: Scalars["bigint"]["input"];
  };

export type Subscription_RootMedspaServiceMenuItemMedspaServiceOfferingsStreamArgs =
  {
    batchSize: Scalars["Int"]["input"];
    cursor: Array<
      InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsStreamCursorInput>
    >;
    where?: InputMaybe<MedspaServiceMenuItemMedspaServiceOfferingsBoolExp>;
  };

export type Subscription_RootMedspaServiceMenuItemOldProductsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOldProductsOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemOldProductsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemOldProductsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemOldProductsOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemOldProductsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaServiceMenuItemOldProductsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaServiceMenuItemOldProductsStreamCursorInput>>;
  where?: InputMaybe<MedspaServiceMenuItemOldProductsBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemProvidersArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemProvidersOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemProvidersAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemProvidersOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemProvidersByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaServiceMenuItemProvidersStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaServiceMenuItemProvidersStreamCursorInput>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

export type Subscription_RootMedspaServiceMenuItemStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaServiceMenuItemStreamCursorInput>>;
  where?: InputMaybe<MedspaServiceMenuItemBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaServiceOfferingComplianceRequirementsArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceOfferingComplianceRequirementsSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<
    Array<MedspaServiceOfferingComplianceRequirementsOrderBy>
  >;
  where?: InputMaybe<MedspaServiceOfferingComplianceRequirementsBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingComplianceRequirementsAggregateArgs =
  {
    distinctOn?: InputMaybe<
      Array<MedspaServiceOfferingComplianceRequirementsSelectColumn>
    >;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<
      Array<MedspaServiceOfferingComplianceRequirementsOrderBy>
    >;
    where?: InputMaybe<MedspaServiceOfferingComplianceRequirementsBoolExp>;
  };

export type Subscription_RootMedspaServiceOfferingComplianceRequirementsStreamArgs =
  {
    batchSize: Scalars["Int"]["input"];
    cursor: Array<
      InputMaybe<MedspaServiceOfferingComplianceRequirementsStreamCursorInput>
    >;
    where?: InputMaybe<MedspaServiceOfferingComplianceRequirementsBoolExp>;
  };

export type Subscription_RootMedspaServiceOfferingNoteTemplatesArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceOfferingNoteTemplatesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingNoteTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspaServiceOfferingNoteTemplatesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNoteTemplatesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingNoteTemplatesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaServiceOfferingNoteTemplatesStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<
    InputMaybe<MedspaServiceOfferingNoteTemplatesStreamCursorInput>
  >;
  where?: InputMaybe<MedspaServiceOfferingNoteTemplatesBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingNotesArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNotesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceOfferingNotesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceOfferingNotesOrderBy>>;
  where?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingNotesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaServiceOfferingNotesStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaServiceOfferingNotesStreamCursorInput>>;
  where?: InputMaybe<MedspaServiceOfferingNotesBoolExp>;
};

export type Subscription_RootMedspaServiceOfferingStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaServiceOfferingStreamCursorInput>>;
  where?: InputMaybe<MedspaServiceOfferingBoolExp>;
};

export type Subscription_RootMedspaServiceProductArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

export type Subscription_RootMedspaServiceProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProductOrderBy>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

export type Subscription_RootMedspaServiceProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaServiceProductStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaServiceProductStreamCursorInput>>;
  where?: InputMaybe<MedspaServiceProductBoolExp>;
};

export type Subscription_RootMedspaServiceProtocolTemplateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
};

export type Subscription_RootMedspaServiceProtocolTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
};

export type Subscription_RootMedspaServiceProtocolTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaServiceProtocolTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaServiceProtocolTemplateStreamCursorInput>>;
  where?: InputMaybe<MedspaServiceProtocolTemplateBoolExp>;
};

export type Subscription_RootMedspaStandingOrderTemplateArgs = {
  distinctOn?: InputMaybe<Array<MedspaStandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaStandingOrderTemplateOrderBy>>;
  where?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
};

export type Subscription_RootMedspaStandingOrderTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaStandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaStandingOrderTemplateOrderBy>>;
  where?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
};

export type Subscription_RootMedspaStandingOrderTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaStandingOrderTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaStandingOrderTemplateStreamCursorInput>>;
  where?: InputMaybe<MedspaStandingOrderTemplateBoolExp>;
};

export type Subscription_RootMedspaStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaStreamCursorInput>>;
  where?: InputMaybe<MedspaBoolExp>;
};

export type Subscription_RootMedspaWalletCreditTypeArgs = {
  distinctOn?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaWalletCreditTypeOrderBy>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

export type Subscription_RootMedspaWalletCreditTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaWalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaWalletCreditTypeOrderBy>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

export type Subscription_RootMedspaWalletCreditTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspaWalletCreditTypeStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspaWalletCreditTypeStreamCursorInput>>;
  where?: InputMaybe<MedspaWalletCreditTypeBoolExp>;
};

export type Subscription_RootMedspasFeatureflagArgs = {
  distinctOn?: InputMaybe<Array<MedspasFeatureflagSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasFeatureflagOrderBy>>;
  where?: InputMaybe<MedspasFeatureflagBoolExp>;
};

export type Subscription_RootMedspasFeatureflagAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasFeatureflagSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasFeatureflagOrderBy>>;
  where?: InputMaybe<MedspasFeatureflagBoolExp>;
};

export type Subscription_RootMedspasFeatureflagByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspasFeatureflagStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspasFeatureflagStreamCursorInput>>;
  where?: InputMaybe<MedspasFeatureflagBoolExp>;
};

export type Subscription_RootMedspasMedicallicenseArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedicallicenseSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedicallicenseOrderBy>>;
  where?: InputMaybe<MedspasMedicallicenseBoolExp>;
};

export type Subscription_RootMedspasMedicallicenseAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedicallicenseSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedicallicenseOrderBy>>;
  where?: InputMaybe<MedspasMedicallicenseBoolExp>;
};

export type Subscription_RootMedspasMedicallicenseByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspasMedicallicenseStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspasMedicallicenseStreamCursorInput>>;
  where?: InputMaybe<MedspasMedicallicenseBoolExp>;
};

export type Subscription_RootMedspasMedspaconfigurationArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedspaconfigurationOrderBy>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

export type Subscription_RootMedspasMedspaconfigurationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasMedspaconfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasMedspaconfigurationOrderBy>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

export type Subscription_RootMedspasMedspaconfigurationByPkArgs = {
  medspaId: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspasMedspaconfigurationStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspasMedspaconfigurationStreamCursorInput>>;
  where?: InputMaybe<MedspasMedspaconfigurationBoolExp>;
};

export type Subscription_RootMedspasProfessionalcorporationArgs = {
  distinctOn?: InputMaybe<Array<MedspasProfessionalcorporationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasProfessionalcorporationOrderBy>>;
  where?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
};

export type Subscription_RootMedspasProfessionalcorporationAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasProfessionalcorporationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasProfessionalcorporationOrderBy>>;
  where?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
};

export type Subscription_RootMedspasProfessionalcorporationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspasProfessionalcorporationStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MedspasProfessionalcorporationStreamCursorInput>>;
  where?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
};

export type Subscription_RootMedspasStripeoutboundpaymentmethodArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasStripeoutboundpaymentmethodSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasStripeoutboundpaymentmethodOrderBy>>;
  where?: InputMaybe<MedspasStripeoutboundpaymentmethodBoolExp>;
};

export type Subscription_RootMedspasStripeoutboundpaymentmethodAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasStripeoutboundpaymentmethodSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasStripeoutboundpaymentmethodOrderBy>>;
  where?: InputMaybe<MedspasStripeoutboundpaymentmethodBoolExp>;
};

export type Subscription_RootMedspasStripeoutboundpaymentmethodByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMedspasStripeoutboundpaymentmethodStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<
    InputMaybe<MedspasStripeoutboundpaymentmethodStreamCursorInput>
  >;
  where?: InputMaybe<MedspasStripeoutboundpaymentmethodBoolExp>;
};

export type Subscription_RootMedspasUsermedspaAcquiredMedicalLicensesArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesOrderBy>>;
  where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
};

export type Subscription_RootMedspasUsermedspaAcquiredMedicalLicensesAggregateArgs =
  {
    distinctOn?: InputMaybe<
      Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
    >;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<
      Array<MedspasUsermedspaAcquiredMedicalLicensesOrderBy>
    >;
    where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
  };

export type Subscription_RootMedspasUsermedspaAcquiredMedicalLicensesByPkArgs =
  {
    id: Scalars["bigint"]["input"];
  };

export type Subscription_RootMedspasUsermedspaAcquiredMedicalLicensesStreamArgs =
  {
    batchSize: Scalars["Int"]["input"];
    cursor: Array<
      InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesStreamCursorInput>
    >;
    where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
  };

export type Subscription_RootMembershipArgs = {
  distinctOn?: InputMaybe<Array<MembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipOrderBy>>;
  where?: InputMaybe<MembershipBoolExp>;
};

export type Subscription_RootMembershipAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipOrderBy>>;
  where?: InputMaybe<MembershipBoolExp>;
};

export type Subscription_RootMembershipByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMembershipCategoryArgs = {
  distinctOn?: InputMaybe<Array<MembershipCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipCategoryOrderBy>>;
  where?: InputMaybe<MembershipCategoryBoolExp>;
};

export type Subscription_RootMembershipCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipCategoryOrderBy>>;
  where?: InputMaybe<MembershipCategoryBoolExp>;
};

export type Subscription_RootMembershipCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMembershipCategoryStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MembershipCategoryStreamCursorInput>>;
  where?: InputMaybe<MembershipCategoryBoolExp>;
};

export type Subscription_RootMembershipLineArgs = {
  distinctOn?: InputMaybe<Array<MembershipLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipLineOrderBy>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

export type Subscription_RootMembershipLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipLineOrderBy>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

export type Subscription_RootMembershipLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMembershipLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MembershipLineStreamCursorInput>>;
  where?: InputMaybe<MembershipLineBoolExp>;
};

export type Subscription_RootMembershipPerkArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkOrderBy>>;
  where?: InputMaybe<MembershipPerkBoolExp>;
};

export type Subscription_RootMembershipPerkAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkOrderBy>>;
  where?: InputMaybe<MembershipPerkBoolExp>;
};

export type Subscription_RootMembershipPerkByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMembershipPerkLineArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

export type Subscription_RootMembershipPerkLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

export type Subscription_RootMembershipPerkLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootMembershipPerkLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MembershipPerkLineStreamCursorInput>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

export type Subscription_RootMembershipPerkStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MembershipPerkStreamCursorInput>>;
  where?: InputMaybe<MembershipPerkBoolExp>;
};

export type Subscription_RootMembershipStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<MembershipStreamCursorInput>>;
  where?: InputMaybe<MembershipBoolExp>;
};

export type Subscription_RootModelPermissionArgs = {
  distinctOn?: InputMaybe<Array<ModelPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ModelPermissionOrderBy>>;
  where?: InputMaybe<ModelPermissionBoolExp>;
};

export type Subscription_RootModelPermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<ModelPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ModelPermissionOrderBy>>;
  where?: InputMaybe<ModelPermissionBoolExp>;
};

export type Subscription_RootModelPermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootModelPermissionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ModelPermissionStreamCursorInput>>;
  where?: InputMaybe<ModelPermissionBoolExp>;
};

export type Subscription_RootNoteArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

export type Subscription_RootNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

export type Subscription_RootNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootNoteChangeLogArgs = {
  distinctOn?: InputMaybe<Array<NoteChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteChangeLogOrderBy>>;
  where?: InputMaybe<NoteChangeLogBoolExp>;
};

export type Subscription_RootNoteChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteChangeLogOrderBy>>;
  where?: InputMaybe<NoteChangeLogBoolExp>;
};

export type Subscription_RootNoteChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootNoteChangeLogStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<NoteChangeLogStreamCursorInput>>;
  where?: InputMaybe<NoteChangeLogBoolExp>;
};

export type Subscription_RootNoteFormSubmissionArgs = {
  distinctOn?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteFormSubmissionOrderBy>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

export type Subscription_RootNoteFormSubmissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteFormSubmissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteFormSubmissionOrderBy>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

export type Subscription_RootNoteFormSubmissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootNoteFormSubmissionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<NoteFormSubmissionStreamCursorInput>>;
  where?: InputMaybe<NoteFormSubmissionBoolExp>;
};

export type Subscription_RootNoteStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<NoteStreamCursorInput>>;
  where?: InputMaybe<NoteBoolExp>;
};

export type Subscription_RootNoteTemplateArgs = {
  distinctOn?: InputMaybe<Array<NoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteTemplateOrderBy>>;
  where?: InputMaybe<NoteTemplateBoolExp>;
};

export type Subscription_RootNoteTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteTemplateOrderBy>>;
  where?: InputMaybe<NoteTemplateBoolExp>;
};

export type Subscription_RootNoteTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootNoteTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<NoteTemplateStreamCursorInput>>;
  where?: InputMaybe<NoteTemplateBoolExp>;
};

export type Subscription_RootPackageArgs = {
  distinctOn?: InputMaybe<Array<PackageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageOrderBy>>;
  where?: InputMaybe<PackageBoolExp>;
};

export type Subscription_RootPackageAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageOrderBy>>;
  where?: InputMaybe<PackageBoolExp>;
};

export type Subscription_RootPackageByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootPackageItemArgs = {
  distinctOn?: InputMaybe<Array<PackageItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemOrderBy>>;
  where?: InputMaybe<PackageItemBoolExp>;
};

export type Subscription_RootPackageItemAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageItemSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemOrderBy>>;
  where?: InputMaybe<PackageItemBoolExp>;
};

export type Subscription_RootPackageItemByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootPackageItemLineArgs = {
  distinctOn?: InputMaybe<Array<PackageItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemLineOrderBy>>;
  where?: InputMaybe<PackageItemLineBoolExp>;
};

export type Subscription_RootPackageItemLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageItemLineOrderBy>>;
  where?: InputMaybe<PackageItemLineBoolExp>;
};

export type Subscription_RootPackageItemLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootPackageItemLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<PackageItemLineStreamCursorInput>>;
  where?: InputMaybe<PackageItemLineBoolExp>;
};

export type Subscription_RootPackageItemStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<PackageItemStreamCursorInput>>;
  where?: InputMaybe<PackageItemBoolExp>;
};

export type Subscription_RootPackageLineArgs = {
  distinctOn?: InputMaybe<Array<PackageLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageLineOrderBy>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

export type Subscription_RootPackageLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageLineOrderBy>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

export type Subscription_RootPackageLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootPackageLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<PackageLineStreamCursorInput>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

export type Subscription_RootPackageStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<PackageStreamCursorInput>>;
  where?: InputMaybe<PackageBoolExp>;
};

export type Subscription_RootPaymentArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Subscription_RootPaymentAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PaymentOrderBy>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Subscription_RootPaymentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootPaymentStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<PaymentStreamCursorInput>>;
  where?: InputMaybe<PaymentBoolExp>;
};

export type Subscription_RootPhotoArgs = {
  distinctOn?: InputMaybe<Array<PhotoSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PhotoOrderBy>>;
  where?: InputMaybe<PhotoBoolExp>;
};

export type Subscription_RootPhotoAggregateArgs = {
  distinctOn?: InputMaybe<Array<PhotoSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PhotoOrderBy>>;
  where?: InputMaybe<PhotoBoolExp>;
};

export type Subscription_RootPhotoByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootPhotoStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<PhotoStreamCursorInput>>;
  where?: InputMaybe<PhotoBoolExp>;
};

export type Subscription_RootProductArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Subscription_RootProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Subscription_RootProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootProductLineArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

export type Subscription_RootProductLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

export type Subscription_RootProductLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootProductLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ProductLineStreamCursorInput>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

export type Subscription_RootProductStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ProductStreamCursorInput>>;
  where?: InputMaybe<ProductBoolExp>;
};

export type Subscription_RootProviderNotRequireDocumentsArgs = {
  distinctOn?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderNotRequireDocumentsOrderBy>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

export type Subscription_RootProviderNotRequireDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderNotRequireDocumentsOrderBy>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

export type Subscription_RootProviderNotRequireDocumentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootProviderNotRequireDocumentsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ProviderNotRequireDocumentsStreamCursorInput>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

export type Subscription_RootRecurringAvailabilityArgs = {
  distinctOn?: InputMaybe<Array<RecurringAvailabilitySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecurringAvailabilityOrderBy>>;
  where?: InputMaybe<RecurringAvailabilityBoolExp>;
};

export type Subscription_RootRecurringAvailabilityAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecurringAvailabilitySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RecurringAvailabilityOrderBy>>;
  where?: InputMaybe<RecurringAvailabilityBoolExp>;
};

export type Subscription_RootRecurringAvailabilityByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootRecurringAvailabilityStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<RecurringAvailabilityStreamCursorInput>>;
  where?: InputMaybe<RecurringAvailabilityBoolExp>;
};

export type Subscription_RootRefundArgs = {
  distinctOn?: InputMaybe<Array<RefundSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RefundOrderBy>>;
  where?: InputMaybe<RefundBoolExp>;
};

export type Subscription_RootRefundAggregateArgs = {
  distinctOn?: InputMaybe<Array<RefundSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<RefundOrderBy>>;
  where?: InputMaybe<RefundBoolExp>;
};

export type Subscription_RootRefundByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootRefundStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<RefundStreamCursorInput>>;
  where?: InputMaybe<RefundBoolExp>;
};

export type Subscription_RootReportArgs = {
  distinctOn?: InputMaybe<Array<ReportSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportOrderBy>>;
  where?: InputMaybe<ReportBoolExp>;
};

export type Subscription_RootReportAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportOrderBy>>;
  where?: InputMaybe<ReportBoolExp>;
};

export type Subscription_RootReportByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootReportCategoryArgs = {
  distinctOn?: InputMaybe<Array<ReportCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportCategoryOrderBy>>;
  where?: InputMaybe<ReportCategoryBoolExp>;
};

export type Subscription_RootReportCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportCategoryOrderBy>>;
  where?: InputMaybe<ReportCategoryBoolExp>;
};

export type Subscription_RootReportCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootReportCategoryStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ReportCategoryStreamCursorInput>>;
  where?: InputMaybe<ReportCategoryBoolExp>;
};

export type Subscription_RootReportStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ReportStreamCursorInput>>;
  where?: InputMaybe<ReportBoolExp>;
};

export type Subscription_RootResourcesMedspaRoomArgs = {
  distinctOn?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ResourcesMedspaRoomOrderBy>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

export type Subscription_RootResourcesMedspaRoomAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ResourcesMedspaRoomOrderBy>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

export type Subscription_RootResourcesMedspaRoomByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootResourcesMedspaRoomStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ResourcesMedspaRoomStreamCursorInput>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

export type Subscription_RootSchedulingAppointmentArgs = {
  distinctOn?: InputMaybe<Array<SchedulingAppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SchedulingAppointmentOrderBy>>;
  where?: InputMaybe<SchedulingAppointmentBoolExp>;
};

export type Subscription_RootSchedulingAppointmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<SchedulingAppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SchedulingAppointmentOrderBy>>;
  where?: InputMaybe<SchedulingAppointmentBoolExp>;
};

export type Subscription_RootSchedulingAppointmentByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootSchedulingAppointmentStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<SchedulingAppointmentStreamCursorInput>>;
  where?: InputMaybe<SchedulingAppointmentBoolExp>;
};

export type Subscription_RootScriptProductArgs = {
  distinctOn?: InputMaybe<Array<ScriptProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptProductOrderBy>>;
  where?: InputMaybe<ScriptProductBoolExp>;
};

export type Subscription_RootScriptProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<ScriptProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptProductOrderBy>>;
  where?: InputMaybe<ScriptProductBoolExp>;
};

export type Subscription_RootScriptProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootScriptProductStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ScriptProductStreamCursorInput>>;
  where?: InputMaybe<ScriptProductBoolExp>;
};

export type Subscription_RootScriptRequestArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestOrderBy>>;
  where?: InputMaybe<ScriptRequestBoolExp>;
};

export type Subscription_RootScriptRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestOrderBy>>;
  where?: InputMaybe<ScriptRequestBoolExp>;
};

export type Subscription_RootScriptRequestByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootScriptRequestStatusChangeLogArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestStatusChangeLogOrderBy>>;
  where?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
};

export type Subscription_RootScriptRequestStatusChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<ScriptRequestStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ScriptRequestStatusChangeLogOrderBy>>;
  where?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
};

export type Subscription_RootScriptRequestStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootScriptRequestStatusChangeLogStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ScriptRequestStatusChangeLogStreamCursorInput>>;
  where?: InputMaybe<ScriptRequestStatusChangeLogBoolExp>;
};

export type Subscription_RootScriptRequestStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ScriptRequestStreamCursorInput>>;
  where?: InputMaybe<ScriptRequestBoolExp>;
};

export type Subscription_RootServiceCategoryArgs = {
  distinctOn?: InputMaybe<Array<ServiceCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceCategoryOrderBy>>;
  where?: InputMaybe<ServiceCategoryBoolExp>;
};

export type Subscription_RootServiceCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceCategoryOrderBy>>;
  where?: InputMaybe<ServiceCategoryBoolExp>;
};

export type Subscription_RootServiceCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceCategoryStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceCategoryStreamCursorInput>>;
  where?: InputMaybe<ServiceCategoryBoolExp>;
};

export type Subscription_RootServiceDeviceArgs = {
  distinctOn?: InputMaybe<Array<ServiceDeviceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDeviceOrderBy>>;
  where?: InputMaybe<ServiceDeviceBoolExp>;
};

export type Subscription_RootServiceDeviceAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDeviceSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDeviceOrderBy>>;
  where?: InputMaybe<ServiceDeviceBoolExp>;
};

export type Subscription_RootServiceDeviceByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceDeviceStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceDeviceStreamCursorInput>>;
  where?: InputMaybe<ServiceDeviceBoolExp>;
};

export type Subscription_RootServiceDevicesAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesAppointmentsOrderBy>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

export type Subscription_RootServiceDevicesAppointmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesAppointmentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesAppointmentsOrderBy>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

export type Subscription_RootServiceDevicesAppointmentsByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceDevicesAppointmentsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceDevicesAppointmentsStreamCursorInput>>;
  where?: InputMaybe<ServiceDevicesAppointmentsBoolExp>;
};

export type Subscription_RootServiceDevicesServiceTypesArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesServiceTypesOrderBy>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

export type Subscription_RootServiceDevicesServiceTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceDevicesServiceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceDevicesServiceTypesOrderBy>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

export type Subscription_RootServiceDevicesServiceTypesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceDevicesServiceTypesStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceDevicesServiceTypesStreamCursorInput>>;
  where?: InputMaybe<ServiceDevicesServiceTypesBoolExp>;
};

export type Subscription_RootServiceMenuItemLineArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

export type Subscription_RootServiceMenuItemLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

export type Subscription_RootServiceMenuItemLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceMenuItemLineStandingOrdersArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
};

export type Subscription_RootServiceMenuItemLineStandingOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineStandingOrdersOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
};

export type Subscription_RootServiceMenuItemLineStandingOrdersByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceMenuItemLineStandingOrdersStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceMenuItemLineStandingOrdersStreamCursorInput>>;
  where?: InputMaybe<ServiceMenuItemLineStandingOrdersBoolExp>;
};

export type Subscription_RootServiceMenuItemLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceMenuItemLineStreamCursorInput>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

export type Subscription_RootServiceProductArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductOrderBy>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

export type Subscription_RootServiceProductAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductOrderBy>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

export type Subscription_RootServiceProductByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceProductChartingNoteArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

export type Subscription_RootServiceProductChartingNoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

export type Subscription_RootServiceProductChartingNoteByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceProductChartingNoteStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceProductChartingNoteStreamCursorInput>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

export type Subscription_RootServiceProductLineArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

export type Subscription_RootServiceProductLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

export type Subscription_RootServiceProductLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceProductLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceProductLineStreamCursorInput>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

export type Subscription_RootServiceProductStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceProductStreamCursorInput>>;
  where?: InputMaybe<ServiceProductBoolExp>;
};

export type Subscription_RootServiceProductTypeArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductTypeOrderBy>>;
  where?: InputMaybe<ServiceProductTypeBoolExp>;
};

export type Subscription_RootServiceProductTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductTypeOrderBy>>;
  where?: InputMaybe<ServiceProductTypeBoolExp>;
};

export type Subscription_RootServiceProductTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceProductTypeStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceProductTypeStreamCursorInput>>;
  where?: InputMaybe<ServiceProductTypeBoolExp>;
};

export type Subscription_RootServiceProductUsageArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductUsageOrderBy>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

export type Subscription_RootServiceProductUsageAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductUsageSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductUsageOrderBy>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

export type Subscription_RootServiceProductUsageByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceProductUsageStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceProductUsageStreamCursorInput>>;
  where?: InputMaybe<ServiceProductUsageBoolExp>;
};

export type Subscription_RootServiceProtocolArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolOrderBy>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

export type Subscription_RootServiceProtocolAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolOrderBy>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

export type Subscription_RootServiceProtocolByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceProtocolStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceProtocolStreamCursorInput>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

export type Subscription_RootServiceProtocolTemplateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<ServiceProtocolTemplateBoolExp>;
};

export type Subscription_RootServiceProtocolTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolTemplateOrderBy>>;
  where?: InputMaybe<ServiceProtocolTemplateBoolExp>;
};

export type Subscription_RootServiceProtocolTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceProtocolTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceProtocolTemplateStreamCursorInput>>;
  where?: InputMaybe<ServiceProtocolTemplateBoolExp>;
};

export type Subscription_RootServiceTypeArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

export type Subscription_RootServiceTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeOrderBy>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

export type Subscription_RootServiceTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceTypeCategoryArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeCategoryOrderBy>>;
  where?: InputMaybe<ServiceTypeCategoryBoolExp>;
};

export type Subscription_RootServiceTypeCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceTypeCategorySelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceTypeCategoryOrderBy>>;
  where?: InputMaybe<ServiceTypeCategoryBoolExp>;
};

export type Subscription_RootServiceTypeCategoryByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootServiceTypeCategoryStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceTypeCategoryStreamCursorInput>>;
  where?: InputMaybe<ServiceTypeCategoryBoolExp>;
};

export type Subscription_RootServiceTypeStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ServiceTypeStreamCursorInput>>;
  where?: InputMaybe<ServiceTypeBoolExp>;
};

export type Subscription_RootShortMoxieUrlArgs = {
  distinctOn?: InputMaybe<Array<ShortMoxieUrlSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ShortMoxieUrlOrderBy>>;
  where?: InputMaybe<ShortMoxieUrlBoolExp>;
};

export type Subscription_RootShortMoxieUrlAggregateArgs = {
  distinctOn?: InputMaybe<Array<ShortMoxieUrlSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ShortMoxieUrlOrderBy>>;
  where?: InputMaybe<ShortMoxieUrlBoolExp>;
};

export type Subscription_RootShortMoxieUrlByPkArgs = {
  uuid: Scalars["String"]["input"];
};

export type Subscription_RootShortMoxieUrlStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<ShortMoxieUrlStreamCursorInput>>;
  where?: InputMaybe<ShortMoxieUrlBoolExp>;
};

export type Subscription_RootStandingOrderArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderOrderBy>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

export type Subscription_RootStandingOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderOrderBy>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

export type Subscription_RootStandingOrderByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootStandingOrderStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<StandingOrderStreamCursorInput>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

export type Subscription_RootStandingOrderTemplateArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderTemplateOrderBy>>;
  where?: InputMaybe<StandingOrderTemplateBoolExp>;
};

export type Subscription_RootStandingOrderTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderTemplateOrderBy>>;
  where?: InputMaybe<StandingOrderTemplateBoolExp>;
};

export type Subscription_RootStandingOrderTemplateByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootStandingOrderTemplateStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<StandingOrderTemplateStreamCursorInput>>;
  where?: InputMaybe<StandingOrderTemplateBoolExp>;
};

export type Subscription_RootSupplierInformationArgs = {
  distinctOn?: InputMaybe<Array<SupplierInformationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SupplierInformationOrderBy>>;
  where?: InputMaybe<SupplierInformationBoolExp>;
};

export type Subscription_RootSupplierInformationAggregateArgs = {
  distinctOn?: InputMaybe<Array<SupplierInformationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<SupplierInformationOrderBy>>;
  where?: InputMaybe<SupplierInformationBoolExp>;
};

export type Subscription_RootSupplierInformationByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootSupplierInformationStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<SupplierInformationStreamCursorInput>>;
  where?: InputMaybe<SupplierInformationBoolExp>;
};

export type Subscription_RootTelehealthVisitDetailsArgs = {
  distinctOn?: InputMaybe<Array<TelehealthVisitDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TelehealthVisitDetailsOrderBy>>;
  where?: InputMaybe<TelehealthVisitDetailsBoolExp>;
};

export type Subscription_RootTelehealthVisitDetailsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TelehealthVisitDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TelehealthVisitDetailsOrderBy>>;
  where?: InputMaybe<TelehealthVisitDetailsBoolExp>;
};

export type Subscription_RootTelehealthVisitDetailsByPkArgs = {
  visitId: Scalars["bigint"]["input"];
};

export type Subscription_RootTelehealthVisitDetailsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<TelehealthVisitDetailsStreamCursorInput>>;
  where?: InputMaybe<TelehealthVisitDetailsBoolExp>;
};

export type Subscription_RootTmpStandingOrderArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

export type Subscription_RootTmpStandingOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

export type Subscription_RootTmpStandingOrderByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootTmpStandingOrderStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<TmpStandingOrderStreamCursorInput>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

export type Subscription_RootUrlPatternPermissionArgs = {
  distinctOn?: InputMaybe<Array<UrlPatternPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UrlPatternPermissionOrderBy>>;
  where?: InputMaybe<UrlPatternPermissionBoolExp>;
};

export type Subscription_RootUrlPatternPermissionAggregateArgs = {
  distinctOn?: InputMaybe<Array<UrlPatternPermissionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UrlPatternPermissionOrderBy>>;
  where?: InputMaybe<UrlPatternPermissionBoolExp>;
};

export type Subscription_RootUrlPatternPermissionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootUrlPatternPermissionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<UrlPatternPermissionStreamCursorInput>>;
  where?: InputMaybe<UrlPatternPermissionBoolExp>;
};

export type Subscription_RootUserArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Subscription_RootUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Subscription_RootUserByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootUserMedspaArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaOrderBy>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

export type Subscription_RootUserMedspaAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaOrderBy>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

export type Subscription_RootUserMedspaByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootUserMedspaFeaturePermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaFeaturePermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaFeaturePermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
};

export type Subscription_RootUserMedspaFeaturePermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaFeaturePermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaFeaturePermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
};

export type Subscription_RootUserMedspaFeaturePermissionsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<UserMedspaFeaturePermissionsStreamCursorInput>>;
  where?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
};

export type Subscription_RootUserMedspaModelPermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaModelPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

export type Subscription_RootUserMedspaModelPermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaModelPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

export type Subscription_RootUserMedspaModelPermissionsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<UserMedspaModelPermissionsStreamCursorInput>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

export type Subscription_RootUserMedspaStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<UserMedspaStreamCursorInput>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

export type Subscription_RootUserMedspaUrlPermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaUrlPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

export type Subscription_RootUserMedspaUrlPermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaUrlPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

export type Subscription_RootUserMedspaUrlPermissionsStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<UserMedspaUrlPermissionsStreamCursorInput>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

export type Subscription_RootUserStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<UserStreamCursorInput>>;
  where?: InputMaybe<UserBoolExp>;
};

export type Subscription_RootVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

export type Subscription_RootVisitAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

export type Subscription_RootVisitByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootVisitReviewStatusChangeLogArgs = {
  distinctOn?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitReviewStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

export type Subscription_RootVisitReviewStatusChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitReviewStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

export type Subscription_RootVisitReviewStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootVisitReviewStatusChangeLogStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<VisitReviewStatusChangeLogStreamCursorInput>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

export type Subscription_RootVisitStatusChangeLogArgs = {
  distinctOn?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

export type Subscription_RootVisitStatusChangeLogAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

export type Subscription_RootVisitStatusChangeLogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootVisitStatusChangeLogStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<VisitStatusChangeLogStreamCursorInput>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

export type Subscription_RootVisitStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<VisitStreamCursorInput>>;
  where?: InputMaybe<VisitBoolExp>;
};

export type Subscription_RootVisitsAdversereactionchangelogArgs = {
  distinctOn?: InputMaybe<Array<VisitsAdversereactionchangelogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsAdversereactionchangelogOrderBy>>;
  where?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
};

export type Subscription_RootVisitsAdversereactionchangelogAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitsAdversereactionchangelogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsAdversereactionchangelogOrderBy>>;
  where?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
};

export type Subscription_RootVisitsAdversereactionchangelogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootVisitsAdversereactionchangelogStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<VisitsAdversereactionchangelogStreamCursorInput>>;
  where?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
};

export type Subscription_RootVisitsChartsignatureArgs = {
  distinctOn?: InputMaybe<Array<VisitsChartsignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsChartsignatureOrderBy>>;
  where?: InputMaybe<VisitsChartsignatureBoolExp>;
};

export type Subscription_RootVisitsChartsignatureAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitsChartsignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsChartsignatureOrderBy>>;
  where?: InputMaybe<VisitsChartsignatureBoolExp>;
};

export type Subscription_RootVisitsChartsignatureByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootVisitsChartsignatureStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<VisitsChartsignatureStreamCursorInput>>;
  where?: InputMaybe<VisitsChartsignatureBoolExp>;
};

export type Subscription_RootVisitsTelehealthvisitdetailschangelogArgs = {
  distinctOn?: InputMaybe<
    Array<VisitsTelehealthvisitdetailschangelogSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsTelehealthvisitdetailschangelogOrderBy>>;
  where?: InputMaybe<VisitsTelehealthvisitdetailschangelogBoolExp>;
};

export type Subscription_RootVisitsTelehealthvisitdetailschangelogAggregateArgs =
  {
    distinctOn?: InputMaybe<
      Array<VisitsTelehealthvisitdetailschangelogSelectColumn>
    >;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<VisitsTelehealthvisitdetailschangelogOrderBy>>;
    where?: InputMaybe<VisitsTelehealthvisitdetailschangelogBoolExp>;
  };

export type Subscription_RootVisitsTelehealthvisitdetailschangelogByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootVisitsTelehealthvisitdetailschangelogStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<
    InputMaybe<VisitsTelehealthvisitdetailschangelogStreamCursorInput>
  >;
  where?: InputMaybe<VisitsTelehealthvisitdetailschangelogBoolExp>;
};

export type Subscription_RootWalletCreditLineArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditLineOrderBy>>;
  where?: InputMaybe<WalletCreditLineBoolExp>;
};

export type Subscription_RootWalletCreditLineAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditLineOrderBy>>;
  where?: InputMaybe<WalletCreditLineBoolExp>;
};

export type Subscription_RootWalletCreditLineByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootWalletCreditLineStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<WalletCreditLineStreamCursorInput>>;
  where?: InputMaybe<WalletCreditLineBoolExp>;
};

export type Subscription_RootWalletCreditTypeArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditTypeOrderBy>>;
  where?: InputMaybe<WalletCreditTypeBoolExp>;
};

export type Subscription_RootWalletCreditTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletCreditTypeSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletCreditTypeOrderBy>>;
  where?: InputMaybe<WalletCreditTypeBoolExp>;
};

export type Subscription_RootWalletCreditTypeByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootWalletCreditTypeStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<WalletCreditTypeStreamCursorInput>>;
  where?: InputMaybe<WalletCreditTypeBoolExp>;
};

export type Subscription_RootWalletTransactionArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionOrderBy>>;
  where?: InputMaybe<WalletTransactionBoolExp>;
};

export type Subscription_RootWalletTransactionAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionOrderBy>>;
  where?: InputMaybe<WalletTransactionBoolExp>;
};

export type Subscription_RootWalletTransactionByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootWalletTransactionSourceTmpArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

export type Subscription_RootWalletTransactionSourceTmpAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

export type Subscription_RootWalletTransactionSourceTmpByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootWalletTransactionSourceTmpStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<WalletTransactionSourceTmpStreamCursorInput>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

export type Subscription_RootWalletTransactionStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<WalletTransactionStreamCursorInput>>;
  where?: InputMaybe<WalletTransactionBoolExp>;
};

export type Subscription_RootWalletTransactionsSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionsSourcesOrderBy>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

export type Subscription_RootWalletTransactionsSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionsSourcesOrderBy>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

export type Subscription_RootWalletTransactionsSourcesByPkArgs = {
  id: Scalars["bigint"]["input"];
};

export type Subscription_RootWalletTransactionsSourcesStreamArgs = {
  batchSize: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<WalletTransactionsSourcesStreamCursorInput>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

/** Table to store Standing Orders, extending the documents_document table withspecific fields */
export type TmpStandingOrder = {
  __typename?: "tmpStandingOrder";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId: Scalars["String"]["output"];
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  created: Scalars["timestamptz"]["output"];
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Reason for expiration of the Standing Order */
  expirationReason: Scalars["String"]["output"];
  /** Date in which the Standing Order expires (365 days after first signature) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  medicalDirector?: Maybe<UserMedspa>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** Date in which the Medical Director signed the Standing Order */
  medicalDirectorSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  /** MedSpa that owns the Standing Order */
  medspaId: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource: Scalars["String"]["output"];
  /** An object relationship */
  provider: UserMedspa;
  /** Provider assigned to the Standing Order */
  providerId: Scalars["bigint"]["output"];
  /** An object relationship */
  serviceProtocol?: Maybe<ServiceProtocol>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  serviceType?: Maybe<ServiceType>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Document current status */
  status: Scalars["String"]["output"];
  /** An object relationship */
  template?: Maybe<StandingOrderTemplate>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** aggregated selection of "standing_order" */
export type TmpStandingOrderAggregate = {
  __typename?: "tmpStandingOrderAggregate";
  aggregate?: Maybe<TmpStandingOrderAggregateFields>;
  nodes: Array<TmpStandingOrder>;
};

export type TmpStandingOrderAggregateBoolExp = {
  count?: InputMaybe<TmpStandingOrderAggregateBoolExpCount>;
};

export type TmpStandingOrderAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<TmpStandingOrderBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "standing_order" */
export type TmpStandingOrderAggregateFields = {
  __typename?: "tmpStandingOrderAggregateFields";
  avg?: Maybe<TmpStandingOrderAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<TmpStandingOrderMaxFields>;
  min?: Maybe<TmpStandingOrderMinFields>;
  stddev?: Maybe<TmpStandingOrderStddevFields>;
  stddevPop?: Maybe<TmpStandingOrderStddevPopFields>;
  stddevSamp?: Maybe<TmpStandingOrderStddevSampFields>;
  sum?: Maybe<TmpStandingOrderSumFields>;
  varPop?: Maybe<TmpStandingOrderVarPopFields>;
  varSamp?: Maybe<TmpStandingOrderVarSampFields>;
  variance?: Maybe<TmpStandingOrderVarianceFields>;
};

/** aggregate fields of "standing_order" */
export type TmpStandingOrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "standing_order" */
export type TmpStandingOrderAggregateOrderBy = {
  avg?: InputMaybe<TmpStandingOrderAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TmpStandingOrderMaxOrderBy>;
  min?: InputMaybe<TmpStandingOrderMinOrderBy>;
  stddev?: InputMaybe<TmpStandingOrderStddevOrderBy>;
  stddevPop?: InputMaybe<TmpStandingOrderStddevPopOrderBy>;
  stddevSamp?: InputMaybe<TmpStandingOrderStddevSampOrderBy>;
  sum?: InputMaybe<TmpStandingOrderSumOrderBy>;
  varPop?: InputMaybe<TmpStandingOrderVarPopOrderBy>;
  varSamp?: InputMaybe<TmpStandingOrderVarSampOrderBy>;
  variance?: InputMaybe<TmpStandingOrderVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "standing_order" */
export type TmpStandingOrderArrRelInsertInput = {
  data: Array<TmpStandingOrderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TmpStandingOrderOnConflict>;
};

/** aggregate avg on columns */
export type TmpStandingOrderAvgFields = {
  __typename?: "tmpStandingOrderAvgFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "standing_order" */
export type TmpStandingOrderAvgOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "standing_order". All fields are combined with a logical 'AND'. */
export type TmpStandingOrderBoolExp = {
  _and?: InputMaybe<Array<TmpStandingOrderBoolExp>>;
  _not?: InputMaybe<TmpStandingOrderBoolExp>;
  _or?: InputMaybe<Array<TmpStandingOrderBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  archivedById?: InputMaybe<BigintComparisonExp>;
  boldsignDocumentId?: InputMaybe<StringComparisonExp>;
  completedAt?: InputMaybe<TimestamptzComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  expirationReason?: InputMaybe<StringComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  medicalDirector?: InputMaybe<UserMedspaBoolExp>;
  medicalDirectorId?: InputMaybe<BigintComparisonExp>;
  medicalDirectorSignedAt?: InputMaybe<TimestamptzComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  pdfFilePath?: InputMaybe<StringComparisonExp>;
  pdfFileSource?: InputMaybe<StringComparisonExp>;
  provider?: InputMaybe<UserMedspaBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  serviceProtocol?: InputMaybe<ServiceProtocolBoolExp>;
  serviceProtocolId?: InputMaybe<BigintComparisonExp>;
  serviceType?: InputMaybe<ServiceTypeBoolExp>;
  serviceTypeId?: InputMaybe<BigintComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  template?: InputMaybe<StandingOrderTemplateBoolExp>;
  templateId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "standing_order" */
export enum TmpStandingOrderConstraint {
  /** unique or primary key constraint on columns "id" */
  StandingOrderPkey = "standing_order_pkey",
}

/** input type for incrementing numeric columns in table "standing_order" */
export type TmpStandingOrderIncInput = {
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Standing Order Template used */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "standing_order" */
export type TmpStandingOrderInsertInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Reason for expiration of the Standing Order */
  expirationReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the Standing Order expires (365 days after first signature) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medicalDirector?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Medical Director signed the Standing Order */
  medicalDirectorSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<UserMedspaObjRelInsertInput>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProtocol?: InputMaybe<ServiceProtocolObjRelInsertInput>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceType?: InputMaybe<ServiceTypeObjRelInsertInput>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
  template?: InputMaybe<StandingOrderTemplateObjRelInsertInput>;
  /** Standing Order Template used */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type TmpStandingOrderMaxFields = {
  __typename?: "tmpStandingOrderMaxFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: Maybe<Scalars["String"]["output"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Reason for expiration of the Standing Order */
  expirationReason?: Maybe<Scalars["String"]["output"]>;
  /** Date in which the Standing Order expires (365 days after first signature) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** Date in which the Medical Director signed the Standing Order */
  medicalDirectorSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: Maybe<Scalars["String"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Document current status */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "standing_order" */
export type TmpStandingOrderMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<OrderBy>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  /** Reason for expiration of the Standing Order */
  expirationReason?: InputMaybe<OrderBy>;
  /** Date in which the Standing Order expires (365 days after first signature) */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** Date in which the Medical Director signed the Standing Order */
  medicalDirectorSignedAt?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<OrderBy>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Document current status */
  status?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TmpStandingOrderMinFields = {
  __typename?: "tmpStandingOrderMinFields";
  archivedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: Maybe<Scalars["String"]["output"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  /** Reason for expiration of the Standing Order */
  expirationReason?: Maybe<Scalars["String"]["output"]>;
  /** Date in which the Standing Order expires (365 days after first signature) */
  expiresAt?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** Date in which the Medical Director signed the Standing Order */
  medicalDirectorSignedAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: Maybe<Scalars["String"]["output"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: Maybe<Scalars["String"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Document current status */
  status?: Maybe<Scalars["String"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "standing_order" */
export type TmpStandingOrderMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<OrderBy>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  /** Reason for expiration of the Standing Order */
  expirationReason?: InputMaybe<OrderBy>;
  /** Date in which the Standing Order expires (365 days after first signature) */
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** Date in which the Medical Director signed the Standing Order */
  medicalDirectorSignedAt?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<OrderBy>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Document current status */
  status?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "standing_order" */
export type TmpStandingOrderMutationResponse = {
  __typename?: "tmpStandingOrderMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<TmpStandingOrder>;
};

/** input type for inserting object relation for remote table "standing_order" */
export type TmpStandingOrderObjRelInsertInput = {
  data: TmpStandingOrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TmpStandingOrderOnConflict>;
};

/** on_conflict condition type for table "standing_order" */
export type TmpStandingOrderOnConflict = {
  constraint: TmpStandingOrderConstraint;
  updateColumns?: Array<TmpStandingOrderUpdateColumn>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

/** Ordering options when selecting data from "standing_order". */
export type TmpStandingOrderOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  archivedById?: InputMaybe<OrderBy>;
  boldsignDocumentId?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  expirationReason?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medicalDirector?: InputMaybe<UserMedspaOrderBy>;
  medicalDirectorId?: InputMaybe<OrderBy>;
  medicalDirectorSignedAt?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  pdfFilePath?: InputMaybe<OrderBy>;
  pdfFileSource?: InputMaybe<OrderBy>;
  provider?: InputMaybe<UserMedspaOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  serviceProtocol?: InputMaybe<ServiceProtocolOrderBy>;
  serviceProtocolId?: InputMaybe<OrderBy>;
  serviceType?: InputMaybe<ServiceTypeOrderBy>;
  serviceTypeId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  template?: InputMaybe<StandingOrderTemplateOrderBy>;
  templateId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: standing_order */
export type TmpStandingOrderPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "standing_order" */
export enum TmpStandingOrderSelectColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  BoldsignDocumentId = "boldsignDocumentId",
  /** column name */
  CompletedAt = "completedAt",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  ExpirationReason = "expirationReason",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  MedicalDirectorId = "medicalDirectorId",
  /** column name */
  MedicalDirectorSignedAt = "medicalDirectorSignedAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfFilePath = "pdfFilePath",
  /** column name */
  PdfFileSource = "pdfFileSource",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  ServiceProtocolId = "serviceProtocolId",
  /** column name */
  ServiceTypeId = "serviceTypeId",
  /** column name */
  Status = "status",
  /** column name */
  TemplateId = "templateId",
}

/** input type for updating data in table "standing_order" */
export type TmpStandingOrderSetInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Reason for expiration of the Standing Order */
  expirationReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the Standing Order expires (365 days after first signature) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Medical Director signed the Standing Order */
  medicalDirectorSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Standing Order Template used */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type TmpStandingOrderStddevFields = {
  __typename?: "tmpStandingOrderStddevFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "standing_order" */
export type TmpStandingOrderStddevOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TmpStandingOrderStddevPopFields = {
  __typename?: "tmpStandingOrderStddevPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "standing_order" */
export type TmpStandingOrderStddevPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TmpStandingOrderStddevSampFields = {
  __typename?: "tmpStandingOrderStddevSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "standing_order" */
export type TmpStandingOrderStddevSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "tmpStandingOrder" */
export type TmpStandingOrderStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TmpStandingOrderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TmpStandingOrderStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  archivedById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Signing vendor document id */
  boldsignDocumentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which a Document finished its cycle, ie: signed by all parties */
  completedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Reason for expiration of the Standing Order */
  expirationReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Date in which the Standing Order expires (365 days after first signature) */
  expiresAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Date in which the Medical Director signed the Standing Order */
  medicalDirectorSignedAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Document pdf file stored in storage Vendor */
  pdfFilePath?: InputMaybe<Scalars["String"]["input"]>;
  /** Informs if the file is from signing vendor or manually uploaded */
  pdfFileSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Document current status */
  status?: InputMaybe<Scalars["String"]["input"]>;
  /** Standing Order Template used */
  templateId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type TmpStandingOrderSumFields = {
  __typename?: "tmpStandingOrderSumFields";
  archivedById?: Maybe<Scalars["bigint"]["output"]>;
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["bigint"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["bigint"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "standing_order" */
export type TmpStandingOrderSumOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** update columns of table "standing_order" */
export enum TmpStandingOrderUpdateColumn {
  /** column name */
  ArchivedAt = "archivedAt",
  /** column name */
  ArchivedById = "archivedById",
  /** column name */
  BoldsignDocumentId = "boldsignDocumentId",
  /** column name */
  CompletedAt = "completedAt",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  ExpirationReason = "expirationReason",
  /** column name */
  ExpiresAt = "expiresAt",
  /** column name */
  Id = "id",
  /** column name */
  MedicalDirectorId = "medicalDirectorId",
  /** column name */
  MedicalDirectorSignedAt = "medicalDirectorSignedAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PdfFilePath = "pdfFilePath",
  /** column name */
  PdfFileSource = "pdfFileSource",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  ServiceProtocolId = "serviceProtocolId",
  /** column name */
  ServiceTypeId = "serviceTypeId",
  /** column name */
  Status = "status",
  /** column name */
  TemplateId = "templateId",
}

export type TmpStandingOrderUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TmpStandingOrderIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TmpStandingOrderSetInput>;
  /** filter the rows which have to be updated */
  where: TmpStandingOrderBoolExp;
};

/** aggregate varPop on columns */
export type TmpStandingOrderVarPopFields = {
  __typename?: "tmpStandingOrderVarPopFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "standing_order" */
export type TmpStandingOrderVarPopOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TmpStandingOrderVarSampFields = {
  __typename?: "tmpStandingOrderVarSampFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "standing_order" */
export type TmpStandingOrderVarSampOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TmpStandingOrderVarianceFields = {
  __typename?: "tmpStandingOrderVarianceFields";
  archivedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: Maybe<Scalars["Float"]["output"]>;
  /** MedSpa that owns the Standing Order */
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider assigned to the Standing Order */
  providerId?: Maybe<Scalars["Float"]["output"]>;
  /** Associated Service Protocol */
  serviceProtocolId?: Maybe<Scalars["Float"]["output"]>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: Maybe<Scalars["Float"]["output"]>;
  /** Standing Order Template used */
  templateId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "standing_order" */
export type TmpStandingOrderVarianceOrderBy = {
  archivedById?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Medical Director assigned to the Standing Order */
  medicalDirectorId?: InputMaybe<OrderBy>;
  /** MedSpa that owns the Standing Order */
  medspaId?: InputMaybe<OrderBy>;
  /** Provider assigned to the Standing Order */
  providerId?: InputMaybe<OrderBy>;
  /** Associated Service Protocol */
  serviceProtocolId?: InputMaybe<OrderBy>;
  /** Service Type associated to the Standing Order */
  serviceTypeId?: InputMaybe<OrderBy>;
  /** Standing Order Template used */
  templateId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "accounts_user" */
export type User = {
  __typename?: "user";
  /** An array relationship */
  archivedInventoryLots: Array<InventoryLot>;
  /** An aggregate relationship */
  archivedInventoryLotsAggregate: InventoryLotAggregate;
  birthdate?: Maybe<Scalars["date"]["output"]>;
  clerkUserId: Scalars["String"]["output"];
  created: Scalars["timestamptz"]["output"];
  dateJoined: Scalars["timestamptz"]["output"];
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  /** An array relationship */
  formSubmissionGFEStatusChangeLogs: Array<FormSubmissionGfeStatusChangeLog>;
  /** An aggregate relationship */
  formSubmissionGFEStatusChangeLogsAggregate: FormSubmissionGfeStatusChangeLogAggregate;
  /** A computed field, executes function "user_full_name" */
  fullName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId: Scalars["String"]["output"];
  /** Internal value for the "Onboarding Manager" property in HubSpot */
  hubspotOnboardingManagerInternalValue: Scalars["String"]["output"];
  /** Internal value for the "Provider Success Manager (Cloned)" property in HubSpot */
  hubspotPsmInternalValue: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isStaff: Scalars["Boolean"]["output"];
  isSuperuser: Scalars["Boolean"]["output"];
  lastLogin?: Maybe<Scalars["timestamptz"]["output"]>;
  lastName: Scalars["String"]["output"];
  /** A computed field, executes function "last_no_show_visit" */
  lastNoShowVisit?: Maybe<Array<Visit>>;
  /** An object relationship */
  medicalDirector?: Maybe<MedicalDirector>;
  /** An array relationship */
  medspasProfessionalcorporationsByOwnerId: Array<MedspasProfessionalcorporation>;
  /** An aggregate relationship */
  medspasProfessionalcorporationsByOwnerIdAggregate: MedspasProfessionalcorporationAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** A computed field, executes function "most_recent_visit" */
  mostRecentVisit?: Maybe<Array<Visit>>;
  note: Scalars["String"]["output"];
  /** An array relationship */
  notes: Array<Note>;
  /** An aggregate relationship */
  notesAggregate: NoteAggregate;
  password: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
  /** An array relationship */
  providedVisits: Array<Visit>;
  /** An aggregate relationship */
  providedVisitsAggregate: VisitAggregate;
  /** An array relationship */
  uploadedDiagramTemplates: Array<DiagramTemplate>;
  /** An aggregate relationship */
  uploadedDiagramTemplatesAggregate: DiagramTemplateAggregate;
  /** An array relationship */
  uploadedDiagrams: Array<Diagram>;
  /** An aggregate relationship */
  uploadedDiagramsAggregate: DiagramAggregate;
  /** An array relationship */
  userMedspas: Array<UserMedspa>;
  /** An aggregate relationship */
  userMedspasAggregate: UserMedspaAggregate;
  /** An array relationship */
  visitReviewStatusChangeLogs: Array<VisitReviewStatusChangeLog>;
  /** An aggregate relationship */
  visitReviewStatusChangeLogsAggregate: VisitReviewStatusChangeLogAggregate;
  /** An array relationship */
  visitStatusChangeLogs: Array<VisitStatusChangeLog>;
  /** An aggregate relationship */
  visitStatusChangeLogsAggregate: VisitStatusChangeLogAggregate;
  /** An array relationship */
  walletTransactionSources: Array<WalletTransactionSourceTmp>;
  /** An aggregate relationship */
  walletTransactionSourcesAggregate: WalletTransactionSourceTmpAggregate;
};

/** columns and relationships of "accounts_user" */
export type UserArchivedInventoryLotsArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserArchivedInventoryLotsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserFormSubmissionGfeStatusChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogOrderBy>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserFormSubmissionGfeStatusChangeLogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<FormSubmissionGfeStatusChangeLogOrderBy>>;
  where?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserLastNoShowVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserMedspasProfessionalcorporationsByOwnerIdArgs = {
  distinctOn?: InputMaybe<Array<MedspasProfessionalcorporationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasProfessionalcorporationOrderBy>>;
  where?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserMedspasProfessionalcorporationsByOwnerIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspasProfessionalcorporationSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasProfessionalcorporationOrderBy>>;
  where?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserMostRecentVisitArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserNotesArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserProvidedVisitsArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserProvidedVisitsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitOrderBy>>;
  where?: InputMaybe<VisitBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserUploadedDiagramTemplatesArgs = {
  distinctOn?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramTemplateOrderBy>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserUploadedDiagramTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramTemplateOrderBy>>;
  where?: InputMaybe<DiagramTemplateBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserUploadedDiagramsArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserUploadedDiagramsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserUserMedspasArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaOrderBy>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserUserMedspasAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaOrderBy>>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserVisitReviewStatusChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitReviewStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserVisitReviewStatusChangeLogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitReviewStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserVisitStatusChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserVisitStatusChangeLogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserWalletTransactionSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** columns and relationships of "accounts_user" */
export type UserWalletTransactionSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionSourceTmpOrderBy>>;
  where?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
};

/** aggregated selection of "accounts_user" */
export type UserAggregate = {
  __typename?: "userAggregate";
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

/** aggregate fields of "accounts_user" */
export type UserAggregateFields = {
  __typename?: "userAggregateFields";
  avg?: Maybe<UserAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
  stddev?: Maybe<UserStddevFields>;
  stddevPop?: Maybe<UserStddevPopFields>;
  stddevSamp?: Maybe<UserStddevSampFields>;
  sum?: Maybe<UserSumFields>;
  varPop?: Maybe<UserVarPopFields>;
  varSamp?: Maybe<UserVarSampFields>;
  variance?: Maybe<UserVarianceFields>;
};

/** aggregate fields of "accounts_user" */
export type UserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type UserAvgFields = {
  __typename?: "userAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "accounts_user". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  archivedInventoryLots?: InputMaybe<InventoryLotBoolExp>;
  archivedInventoryLotsAggregate?: InputMaybe<InventoryLotAggregateBoolExp>;
  birthdate?: InputMaybe<DateComparisonExp>;
  clerkUserId?: InputMaybe<StringComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  dateJoined?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  formSubmissionGFEStatusChangeLogs?: InputMaybe<FormSubmissionGfeStatusChangeLogBoolExp>;
  formSubmissionGFEStatusChangeLogsAggregate?: InputMaybe<FormSubmissionGfeStatusChangeLogAggregateBoolExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  gender?: InputMaybe<StringComparisonExp>;
  hubspotContactId?: InputMaybe<StringComparisonExp>;
  hubspotOnboardingManagerInternalValue?: InputMaybe<StringComparisonExp>;
  hubspotPsmInternalValue?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  isStaff?: InputMaybe<BooleanComparisonExp>;
  isSuperuser?: InputMaybe<BooleanComparisonExp>;
  lastLogin?: InputMaybe<TimestamptzComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  lastNoShowVisit?: InputMaybe<VisitBoolExp>;
  medicalDirector?: InputMaybe<MedicalDirectorBoolExp>;
  medspasProfessionalcorporationsByOwnerId?: InputMaybe<MedspasProfessionalcorporationBoolExp>;
  medspasProfessionalcorporationsByOwnerIdAggregate?: InputMaybe<MedspasProfessionalcorporationAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  mostRecentVisit?: InputMaybe<VisitBoolExp>;
  note?: InputMaybe<StringComparisonExp>;
  notes?: InputMaybe<NoteBoolExp>;
  notesAggregate?: InputMaybe<NoteAggregateBoolExp>;
  password?: InputMaybe<StringComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  providedVisits?: InputMaybe<VisitBoolExp>;
  providedVisitsAggregate?: InputMaybe<VisitAggregateBoolExp>;
  uploadedDiagramTemplates?: InputMaybe<DiagramTemplateBoolExp>;
  uploadedDiagramTemplatesAggregate?: InputMaybe<DiagramTemplateAggregateBoolExp>;
  uploadedDiagrams?: InputMaybe<DiagramBoolExp>;
  uploadedDiagramsAggregate?: InputMaybe<DiagramAggregateBoolExp>;
  userMedspas?: InputMaybe<UserMedspaBoolExp>;
  userMedspasAggregate?: InputMaybe<UserMedspaAggregateBoolExp>;
  visitReviewStatusChangeLogs?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
  visitReviewStatusChangeLogsAggregate?: InputMaybe<VisitReviewStatusChangeLogAggregateBoolExp>;
  visitStatusChangeLogs?: InputMaybe<VisitStatusChangeLogBoolExp>;
  visitStatusChangeLogsAggregate?: InputMaybe<VisitStatusChangeLogAggregateBoolExp>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateBoolExp>;
};

/** unique or primary key constraints on table "accounts_user" */
export enum UserConstraint {
  /** unique or primary key constraint on columns "email" */
  AccountsUserEmailB2644a56Uniq = "accounts_user_email_b2644a56_uniq",
  /** unique or primary key constraint on columns "id" */
  AccountsUserPkey = "accounts_user_pkey",
}

/** input type for incrementing numeric columns in table "accounts_user" */
export type UserIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "accounts_user" */
export type UserInsertInput = {
  archivedInventoryLots?: InputMaybe<InventoryLotArrRelInsertInput>;
  birthdate?: InputMaybe<Scalars["date"]["input"]>;
  clerkUserId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dateJoined?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  formSubmissionGFEStatusChangeLogs?: InputMaybe<FormSubmissionGfeStatusChangeLogArrRelInsertInput>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: InputMaybe<Scalars["String"]["input"]>;
  /** Internal value for the "Onboarding Manager" property in HubSpot */
  hubspotOnboardingManagerInternalValue?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** Internal value for the "Provider Success Manager (Cloned)" property in HubSpot */
  hubspotPsmInternalValue?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLogin?: InputMaybe<Scalars["timestamptz"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  medicalDirector?: InputMaybe<MedicalDirectorObjRelInsertInput>;
  medspasProfessionalcorporationsByOwnerId?: InputMaybe<MedspasProfessionalcorporationArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<NoteArrRelInsertInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  providedVisits?: InputMaybe<VisitArrRelInsertInput>;
  uploadedDiagramTemplates?: InputMaybe<DiagramTemplateArrRelInsertInput>;
  uploadedDiagrams?: InputMaybe<DiagramArrRelInsertInput>;
  userMedspas?: InputMaybe<UserMedspaArrRelInsertInput>;
  visitReviewStatusChangeLogs?: InputMaybe<VisitReviewStatusChangeLogArrRelInsertInput>;
  visitStatusChangeLogs?: InputMaybe<VisitStatusChangeLogArrRelInsertInput>;
  walletTransactionSources?: InputMaybe<WalletTransactionSourceTmpArrRelInsertInput>;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: "userMaxFields";
  birthdate?: Maybe<Scalars["date"]["output"]>;
  clerkUserId?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  dateJoined?: Maybe<Scalars["timestamptz"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "user_full_name" */
  fullName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: Maybe<Scalars["String"]["output"]>;
  /** Internal value for the "Onboarding Manager" property in HubSpot */
  hubspotOnboardingManagerInternalValue?: Maybe<Scalars["String"]["output"]>;
  /** Internal value for the "Provider Success Manager (Cloned)" property in HubSpot */
  hubspotPsmInternalValue?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  lastLogin?: Maybe<Scalars["timestamptz"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspa = {
  __typename?: "userMedspa";
  /** An array relationship */
  acquiredMedicalLicenses: Array<MedspasUsermedspaAcquiredMedicalLicenses>;
  /** An aggregate relationship */
  acquiredMedicalLicensesAggregate: MedspasUsermedspaAcquiredMedicalLicensesAggregate;
  active: Scalars["Boolean"]["output"];
  /** An array relationship */
  availabilityEvents: Array<AvailabilityEvent>;
  /** An aggregate relationship */
  availabilityEventsAggregate: AvailabilityEventAggregate;
  cherryAccount?: Maybe<CherryAccountInfo>;
  /** An array relationship */
  complianceTasks: Array<ComplianceTask>;
  /** An aggregate relationship */
  complianceTasksAggregate: ComplianceTaskAggregate;
  created: Scalars["timestamptz"]["output"];
  /** An array relationship */
  deletedInventoryLots: Array<InventoryLot>;
  /** An aggregate relationship */
  deletedInventoryLotsAggregate: InventoryLotAggregate;
  /** An array relationship */
  deletedInventoryTransactions: Array<InventoryTransaction>;
  /** An aggregate relationship */
  deletedInventoryTransactionsAggregate: InventoryTransactionAggregate;
  /** An array relationship */
  deletedRooms: Array<ResourcesMedspaRoom>;
  /** An aggregate relationship */
  deletedRoomsAggregate: ResourcesMedspaRoomAggregate;
  /** An array relationship */
  deletedServiceProductChartingNotes: Array<ServiceProductChartingNote>;
  /** An aggregate relationship */
  deletedServiceProductChartingNotesAggregate: ServiceProductChartingNoteAggregate;
  expoPushToken: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  isEligibleForEmpowerWeightLoss: Scalars["Boolean"]["output"];
  /** Defines if a provider can request scripts for clients. */
  isEligibleForScripts: Scalars["Boolean"]["output"];
  isEligibleForSelfGfe?: Maybe<Scalars["Boolean"]["output"]>;
  isEligibleForStrive503aWeightLoss: Scalars["Boolean"]["output"];
  isEligibleForStrive503bWeightLoss: Scalars["Boolean"]["output"];
  isGfeReviewer: Scalars["Boolean"]["output"];
  /** Defines if a provider requires a medical director to review their charts. */
  isMdChartReviewRequired: Scalars["Boolean"]["output"];
  isProviderOwner: Scalars["Boolean"]["output"];
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  medspaServiceMenuItems: Array<MedspaServiceMenuItemProviders>;
  /** An aggregate relationship */
  medspaServiceMenuItemsAggregate: MedspaServiceMenuItemProvidersAggregate;
  /** An array relationship */
  modelPermissions: Array<UserMedspaModelPermissions>;
  /** An aggregate relationship */
  modelPermissionsAggregate: UserMedspaModelPermissionsAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview: Scalars["Int"]["output"];
  providerInfo?: Maybe<UserMedSpaProviderType>;
  /** An array relationship */
  providerNotRequireDocuments: Array<ProviderNotRequireDocuments>;
  /** An aggregate relationship */
  providerNotRequireDocumentsAggregate: ProviderNotRequireDocumentsAggregate;
  /** An array relationship */
  providerServiceProtocols: Array<ServiceProtocol>;
  /** An aggregate relationship */
  providerServiceProtocolsAggregate: ServiceProtocolAggregate;
  /** An array relationship */
  providerStandingOrders: Array<TmpStandingOrder>;
  /** An aggregate relationship */
  providerStandingOrdersAggregate: TmpStandingOrderAggregate;
  role: Scalars["String"]["output"];
  roleId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  standingOrders: Array<StandingOrder>;
  /** An aggregate relationship */
  standingOrdersAggregate: StandingOrderAggregate;
  stripeData?: Maybe<ClientStripeType>;
  suppliesShopifyCustomerId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  uploadedStandingOrders: Array<StandingOrder>;
  /** An aggregate relationship */
  uploadedStandingOrdersAggregate: StandingOrderAggregate;
  /** An array relationship */
  urlPermissions: Array<UserMedspaUrlPermissions>;
  /** An aggregate relationship */
  urlPermissionsAggregate: UserMedspaUrlPermissionsAggregate;
  /** An object relationship */
  user: User;
  userId: Scalars["bigint"]["output"];
  wallet?: Maybe<ClientWalletType>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaAcquiredMedicalLicensesArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesOrderBy>>;
  where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaAcquiredMedicalLicensesAggregateArgs = {
  distinctOn?: InputMaybe<
    Array<MedspasUsermedspaAcquiredMedicalLicensesSelectColumn>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspasUsermedspaAcquiredMedicalLicensesOrderBy>>;
  where?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaAvailabilityEventsArgs = {
  distinctOn?: InputMaybe<Array<AvailabilityEventSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvailabilityEventOrderBy>>;
  where?: InputMaybe<AvailabilityEventBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaAvailabilityEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AvailabilityEventSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AvailabilityEventOrderBy>>;
  where?: InputMaybe<AvailabilityEventBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaComplianceTasksArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskOrderBy>>;
  where?: InputMaybe<ComplianceTaskBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaComplianceTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<ComplianceTaskSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ComplianceTaskOrderBy>>;
  where?: InputMaybe<ComplianceTaskBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaDeletedInventoryLotsArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaDeletedInventoryLotsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryLotSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryLotOrderBy>>;
  where?: InputMaybe<InventoryLotBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaDeletedInventoryTransactionsArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaDeletedInventoryTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<InventoryTransactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InventoryTransactionOrderBy>>;
  where?: InputMaybe<InventoryTransactionBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaDeletedRoomsArgs = {
  distinctOn?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ResourcesMedspaRoomOrderBy>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaDeletedRoomsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResourcesMedspaRoomSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ResourcesMedspaRoomOrderBy>>;
  where?: InputMaybe<ResourcesMedspaRoomBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaDeletedServiceProductChartingNotesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaDeletedServiceProductChartingNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaMedspaServiceMenuItemsArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemProvidersOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaMedspaServiceMenuItemsAggregateArgs = {
  distinctOn?: InputMaybe<Array<MedspaServiceMenuItemProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MedspaServiceMenuItemProvidersOrderBy>>;
  where?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaModelPermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaModelPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaModelPermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaModelPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaProviderNotRequireDocumentsArgs = {
  distinctOn?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderNotRequireDocumentsOrderBy>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaProviderNotRequireDocumentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProviderNotRequireDocumentsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderNotRequireDocumentsOrderBy>>;
  where?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaProviderServiceProtocolsArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolOrderBy>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaProviderServiceProtocolsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProtocolSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProtocolOrderBy>>;
  where?: InputMaybe<ServiceProtocolBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaProviderStandingOrdersArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaProviderStandingOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TmpStandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<TmpStandingOrderOrderBy>>;
  where?: InputMaybe<TmpStandingOrderBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaStandingOrdersArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderOrderBy>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaStandingOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderOrderBy>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaUploadedStandingOrdersArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderOrderBy>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaUploadedStandingOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StandingOrderSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<StandingOrderOrderBy>>;
  where?: InputMaybe<StandingOrderBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaUrlPermissionsArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaUrlPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

/** columns and relationships of "medspas_usermedspa" */
export type UserMedspaUrlPermissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserMedspaUrlPermissionsOrderBy>>;
  where?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
};

/** aggregated selection of "medspas_usermedspa" */
export type UserMedspaAggregate = {
  __typename?: "userMedspaAggregate";
  aggregate?: Maybe<UserMedspaAggregateFields>;
  nodes: Array<UserMedspa>;
};

export type UserMedspaAggregateBoolExp = {
  bool_and?: InputMaybe<UserMedspaAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<UserMedspaAggregateBoolExpBool_Or>;
  count?: InputMaybe<UserMedspaAggregateBoolExpCount>;
};

export type UserMedspaAggregateBoolExpBool_And = {
  arguments: UserMedspaSelectColumnUserMedspaAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<UserMedspaBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserMedspaAggregateBoolExpBool_Or = {
  arguments: UserMedspaSelectColumnUserMedspaAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<UserMedspaBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserMedspaAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserMedspaSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<UserMedspaBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "medspas_usermedspa" */
export type UserMedspaAggregateFields = {
  __typename?: "userMedspaAggregateFields";
  avg?: Maybe<UserMedspaAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<UserMedspaMaxFields>;
  min?: Maybe<UserMedspaMinFields>;
  stddev?: Maybe<UserMedspaStddevFields>;
  stddevPop?: Maybe<UserMedspaStddevPopFields>;
  stddevSamp?: Maybe<UserMedspaStddevSampFields>;
  sum?: Maybe<UserMedspaSumFields>;
  varPop?: Maybe<UserMedspaVarPopFields>;
  varSamp?: Maybe<UserMedspaVarSampFields>;
  variance?: Maybe<UserMedspaVarianceFields>;
};

/** aggregate fields of "medspas_usermedspa" */
export type UserMedspaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserMedspaSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "medspas_usermedspa" */
export type UserMedspaAggregateOrderBy = {
  avg?: InputMaybe<UserMedspaAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserMedspaMaxOrderBy>;
  min?: InputMaybe<UserMedspaMinOrderBy>;
  stddev?: InputMaybe<UserMedspaStddevOrderBy>;
  stddevPop?: InputMaybe<UserMedspaStddevPopOrderBy>;
  stddevSamp?: InputMaybe<UserMedspaStddevSampOrderBy>;
  sum?: InputMaybe<UserMedspaSumOrderBy>;
  varPop?: InputMaybe<UserMedspaVarPopOrderBy>;
  varSamp?: InputMaybe<UserMedspaVarSampOrderBy>;
  variance?: InputMaybe<UserMedspaVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "medspas_usermedspa" */
export type UserMedspaArrRelInsertInput = {
  data: Array<UserMedspaInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<UserMedspaOnConflict>;
};

/** aggregate avg on columns */
export type UserMedspaAvgFields = {
  __typename?: "userMedspaAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Float"]["output"]>;
  roleId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "medspas_usermedspa" */
export type UserMedspaAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "medspas_usermedspa". All fields are combined with a logical 'AND'. */
export type UserMedspaBoolExp = {
  _and?: InputMaybe<Array<UserMedspaBoolExp>>;
  _not?: InputMaybe<UserMedspaBoolExp>;
  _or?: InputMaybe<Array<UserMedspaBoolExp>>;
  acquiredMedicalLicenses?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesBoolExp>;
  acquiredMedicalLicensesAggregate?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesAggregateBoolExp>;
  active?: InputMaybe<BooleanComparisonExp>;
  availabilityEvents?: InputMaybe<AvailabilityEventBoolExp>;
  availabilityEventsAggregate?: InputMaybe<AvailabilityEventAggregateBoolExp>;
  complianceTasks?: InputMaybe<ComplianceTaskBoolExp>;
  complianceTasksAggregate?: InputMaybe<ComplianceTaskAggregateBoolExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deletedInventoryLots?: InputMaybe<InventoryLotBoolExp>;
  deletedInventoryLotsAggregate?: InputMaybe<InventoryLotAggregateBoolExp>;
  deletedInventoryTransactions?: InputMaybe<InventoryTransactionBoolExp>;
  deletedInventoryTransactionsAggregate?: InputMaybe<InventoryTransactionAggregateBoolExp>;
  deletedRooms?: InputMaybe<ResourcesMedspaRoomBoolExp>;
  deletedRoomsAggregate?: InputMaybe<ResourcesMedspaRoomAggregateBoolExp>;
  deletedServiceProductChartingNotes?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  deletedServiceProductChartingNotesAggregate?: InputMaybe<ServiceProductChartingNoteAggregateBoolExp>;
  expoPushToken?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  isEligibleForEmpowerWeightLoss?: InputMaybe<BooleanComparisonExp>;
  isEligibleForScripts?: InputMaybe<BooleanComparisonExp>;
  isEligibleForSelfGfe?: InputMaybe<BooleanComparisonExp>;
  isEligibleForStrive503aWeightLoss?: InputMaybe<BooleanComparisonExp>;
  isEligibleForStrive503bWeightLoss?: InputMaybe<BooleanComparisonExp>;
  isGfeReviewer?: InputMaybe<BooleanComparisonExp>;
  isMdChartReviewRequired?: InputMaybe<BooleanComparisonExp>;
  isProviderOwner?: InputMaybe<BooleanComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  medspaServiceMenuItems?: InputMaybe<MedspaServiceMenuItemProvidersBoolExp>;
  medspaServiceMenuItemsAggregate?: InputMaybe<MedspaServiceMenuItemProvidersAggregateBoolExp>;
  modelPermissions?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
  modelPermissionsAggregate?: InputMaybe<UserMedspaModelPermissionsAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  percentageOfChartsToReview?: InputMaybe<IntComparisonExp>;
  providerNotRequireDocuments?: InputMaybe<ProviderNotRequireDocumentsBoolExp>;
  providerNotRequireDocumentsAggregate?: InputMaybe<ProviderNotRequireDocumentsAggregateBoolExp>;
  providerServiceProtocols?: InputMaybe<ServiceProtocolBoolExp>;
  providerServiceProtocolsAggregate?: InputMaybe<ServiceProtocolAggregateBoolExp>;
  providerStandingOrders?: InputMaybe<TmpStandingOrderBoolExp>;
  providerStandingOrdersAggregate?: InputMaybe<TmpStandingOrderAggregateBoolExp>;
  role?: InputMaybe<StringComparisonExp>;
  roleId?: InputMaybe<BigintComparisonExp>;
  standingOrders?: InputMaybe<StandingOrderBoolExp>;
  standingOrdersAggregate?: InputMaybe<StandingOrderAggregateBoolExp>;
  suppliesShopifyCustomerId?: InputMaybe<BigintComparisonExp>;
  uploadedStandingOrders?: InputMaybe<StandingOrderBoolExp>;
  uploadedStandingOrdersAggregate?: InputMaybe<StandingOrderAggregateBoolExp>;
  urlPermissions?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
  urlPermissionsAggregate?: InputMaybe<UserMedspaUrlPermissionsAggregateBoolExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "medspas_usermedspa" */
export enum UserMedspaConstraint {
  /** unique or primary key constraint on columns "id" */
  MedspasUsermedspaPkey = "medspas_usermedspa_pkey",
  /** unique or primary key constraint on columns "user_id", "role", "medspa_id" */
  MedspasUsermedspaUserIdMedspaIdRoleBeb6b909Uniq = "medspas_usermedspa_user_id_medspa_id_role_beb6b909_uniq",
}

export type UserMedspaFeaturePermissionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserMedspaFeaturePermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<UserMedspaFeaturePermissionsBoolExp>;
  predicate: IntComparisonExp;
};

/** input type for incrementing numeric columns in table "medspas_usermedspa" */
export type UserMedspaIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<Scalars["Int"]["input"]>;
  roleId?: InputMaybe<Scalars["bigint"]["input"]>;
  suppliesShopifyCustomerId?: InputMaybe<Scalars["bigint"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "medspas_usermedspa" */
export type UserMedspaInsertInput = {
  acquiredMedicalLicenses?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesArrRelInsertInput>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  availabilityEvents?: InputMaybe<AvailabilityEventArrRelInsertInput>;
  complianceTasks?: InputMaybe<ComplianceTaskArrRelInsertInput>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  deletedInventoryLots?: InputMaybe<InventoryLotArrRelInsertInput>;
  deletedInventoryTransactions?: InputMaybe<InventoryTransactionArrRelInsertInput>;
  deletedRooms?: InputMaybe<ResourcesMedspaRoomArrRelInsertInput>;
  deletedServiceProductChartingNotes?: InputMaybe<ServiceProductChartingNoteArrRelInsertInput>;
  expoPushToken?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isEligibleForEmpowerWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Defines if a provider can request scripts for clients. */
  isEligibleForScripts?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForSelfGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForStrive503aWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForStrive503bWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGfeReviewer?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Defines if a provider requires a medical director to review their charts. */
  isMdChartReviewRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isProviderOwner?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaServiceMenuItems?: InputMaybe<MedspaServiceMenuItemProvidersArrRelInsertInput>;
  modelPermissions?: InputMaybe<UserMedspaModelPermissionsArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<Scalars["Int"]["input"]>;
  providerNotRequireDocuments?: InputMaybe<ProviderNotRequireDocumentsArrRelInsertInput>;
  providerServiceProtocols?: InputMaybe<ServiceProtocolArrRelInsertInput>;
  providerStandingOrders?: InputMaybe<TmpStandingOrderArrRelInsertInput>;
  role?: InputMaybe<Scalars["String"]["input"]>;
  roleId?: InputMaybe<Scalars["bigint"]["input"]>;
  standingOrders?: InputMaybe<StandingOrderArrRelInsertInput>;
  suppliesShopifyCustomerId?: InputMaybe<Scalars["bigint"]["input"]>;
  uploadedStandingOrders?: InputMaybe<StandingOrderArrRelInsertInput>;
  urlPermissions?: InputMaybe<UserMedspaUrlPermissionsArrRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type UserMedspaMaxFields = {
  __typename?: "userMedspaMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  expoPushToken?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Int"]["output"]>;
  role?: Maybe<Scalars["String"]["output"]>;
  roleId?: Maybe<Scalars["bigint"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["bigint"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "medspas_usermedspa" */
export type UserMedspaMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  expoPushToken?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMedspaMinFields = {
  __typename?: "userMedspaMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  expoPushToken?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Int"]["output"]>;
  role?: Maybe<Scalars["String"]["output"]>;
  roleId?: Maybe<Scalars["bigint"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["bigint"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "medspas_usermedspa" */
export type UserMedspaMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  expoPushToken?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

export type UserMedspaModelPermissionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserMedspaModelPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<UserMedspaModelPermissionsBoolExp>;
  predicate: IntComparisonExp;
};

/** response of any mutation on the table "medspas_usermedspa" */
export type UserMedspaMutationResponse = {
  __typename?: "userMedspaMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<UserMedspa>;
};

/** input type for inserting object relation for remote table "medspas_usermedspa" */
export type UserMedspaObjRelInsertInput = {
  data: UserMedspaInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UserMedspaOnConflict>;
};

/** on_conflict condition type for table "medspas_usermedspa" */
export type UserMedspaOnConflict = {
  constraint: UserMedspaConstraint;
  updateColumns?: Array<UserMedspaUpdateColumn>;
  where?: InputMaybe<UserMedspaBoolExp>;
};

/** Ordering options when selecting data from "medspas_usermedspa". */
export type UserMedspaOrderBy = {
  acquiredMedicalLicensesAggregate?: InputMaybe<MedspasUsermedspaAcquiredMedicalLicensesAggregateOrderBy>;
  active?: InputMaybe<OrderBy>;
  availabilityEventsAggregate?: InputMaybe<AvailabilityEventAggregateOrderBy>;
  complianceTasksAggregate?: InputMaybe<ComplianceTaskAggregateOrderBy>;
  created?: InputMaybe<OrderBy>;
  deletedInventoryLotsAggregate?: InputMaybe<InventoryLotAggregateOrderBy>;
  deletedInventoryTransactionsAggregate?: InputMaybe<InventoryTransactionAggregateOrderBy>;
  deletedRoomsAggregate?: InputMaybe<ResourcesMedspaRoomAggregateOrderBy>;
  deletedServiceProductChartingNotesAggregate?: InputMaybe<ServiceProductChartingNoteAggregateOrderBy>;
  expoPushToken?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isEligibleForEmpowerWeightLoss?: InputMaybe<OrderBy>;
  isEligibleForScripts?: InputMaybe<OrderBy>;
  isEligibleForSelfGfe?: InputMaybe<OrderBy>;
  isEligibleForStrive503aWeightLoss?: InputMaybe<OrderBy>;
  isEligibleForStrive503bWeightLoss?: InputMaybe<OrderBy>;
  isGfeReviewer?: InputMaybe<OrderBy>;
  isMdChartReviewRequired?: InputMaybe<OrderBy>;
  isProviderOwner?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  medspaServiceMenuItemsAggregate?: InputMaybe<MedspaServiceMenuItemProvidersAggregateOrderBy>;
  modelPermissionsAggregate?: InputMaybe<UserMedspaModelPermissionsAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  providerNotRequireDocumentsAggregate?: InputMaybe<ProviderNotRequireDocumentsAggregateOrderBy>;
  providerServiceProtocolsAggregate?: InputMaybe<ServiceProtocolAggregateOrderBy>;
  providerStandingOrdersAggregate?: InputMaybe<TmpStandingOrderAggregateOrderBy>;
  role?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  standingOrdersAggregate?: InputMaybe<StandingOrderAggregateOrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  uploadedStandingOrdersAggregate?: InputMaybe<StandingOrderAggregateOrderBy>;
  urlPermissionsAggregate?: InputMaybe<UserMedspaUrlPermissionsAggregateOrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: medspas_usermedspa */
export type UserMedspaPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "medspas_usermedspa" */
export enum UserMedspaSelectColumn {
  /** column name */
  Active = "active",
  /** column name */
  Created = "created",
  /** column name */
  ExpoPushToken = "expoPushToken",
  /** column name */
  Id = "id",
  /** column name */
  IsEligibleForEmpowerWeightLoss = "isEligibleForEmpowerWeightLoss",
  /** column name */
  IsEligibleForScripts = "isEligibleForScripts",
  /** column name */
  IsEligibleForSelfGfe = "isEligibleForSelfGfe",
  /** column name */
  IsEligibleForStrive503aWeightLoss = "isEligibleForStrive503aWeightLoss",
  /** column name */
  IsEligibleForStrive503bWeightLoss = "isEligibleForStrive503bWeightLoss",
  /** column name */
  IsGfeReviewer = "isGfeReviewer",
  /** column name */
  IsMdChartReviewRequired = "isMdChartReviewRequired",
  /** column name */
  IsProviderOwner = "isProviderOwner",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PercentageOfChartsToReview = "percentageOfChartsToReview",
  /** column name */
  Role = "role",
  /** column name */
  RoleId = "roleId",
  /** column name */
  SuppliesShopifyCustomerId = "suppliesShopifyCustomerId",
  /** column name */
  UserId = "userId",
}

/** select "userMedspaAggregateBoolExpBool_andArgumentsColumns" columns of table "medspas_usermedspa" */
export enum UserMedspaSelectColumnUserMedspaAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Active = "active",
  /** column name */
  IsEligibleForEmpowerWeightLoss = "isEligibleForEmpowerWeightLoss",
  /** column name */
  IsEligibleForScripts = "isEligibleForScripts",
  /** column name */
  IsEligibleForSelfGfe = "isEligibleForSelfGfe",
  /** column name */
  IsEligibleForStrive503aWeightLoss = "isEligibleForStrive503aWeightLoss",
  /** column name */
  IsEligibleForStrive503bWeightLoss = "isEligibleForStrive503bWeightLoss",
  /** column name */
  IsGfeReviewer = "isGfeReviewer",
  /** column name */
  IsMdChartReviewRequired = "isMdChartReviewRequired",
  /** column name */
  IsProviderOwner = "isProviderOwner",
}

/** select "userMedspaAggregateBoolExpBool_orArgumentsColumns" columns of table "medspas_usermedspa" */
export enum UserMedspaSelectColumnUserMedspaAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Active = "active",
  /** column name */
  IsEligibleForEmpowerWeightLoss = "isEligibleForEmpowerWeightLoss",
  /** column name */
  IsEligibleForScripts = "isEligibleForScripts",
  /** column name */
  IsEligibleForSelfGfe = "isEligibleForSelfGfe",
  /** column name */
  IsEligibleForStrive503aWeightLoss = "isEligibleForStrive503aWeightLoss",
  /** column name */
  IsEligibleForStrive503bWeightLoss = "isEligibleForStrive503bWeightLoss",
  /** column name */
  IsGfeReviewer = "isGfeReviewer",
  /** column name */
  IsMdChartReviewRequired = "isMdChartReviewRequired",
  /** column name */
  IsProviderOwner = "isProviderOwner",
}

/** input type for updating data in table "medspas_usermedspa" */
export type UserMedspaSetInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expoPushToken?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isEligibleForEmpowerWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Defines if a provider can request scripts for clients. */
  isEligibleForScripts?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForSelfGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForStrive503aWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForStrive503bWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGfeReviewer?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Defines if a provider requires a medical director to review their charts. */
  isMdChartReviewRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isProviderOwner?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<Scalars["Int"]["input"]>;
  role?: InputMaybe<Scalars["String"]["input"]>;
  roleId?: InputMaybe<Scalars["bigint"]["input"]>;
  suppliesShopifyCustomerId?: InputMaybe<Scalars["bigint"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type UserMedspaStddevFields = {
  __typename?: "userMedspaStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Float"]["output"]>;
  roleId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "medspas_usermedspa" */
export type UserMedspaStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type UserMedspaStddevPopFields = {
  __typename?: "userMedspaStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Float"]["output"]>;
  roleId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "medspas_usermedspa" */
export type UserMedspaStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type UserMedspaStddevSampFields = {
  __typename?: "userMedspaStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Float"]["output"]>;
  roleId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "medspas_usermedspa" */
export type UserMedspaStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "userMedspa" */
export type UserMedspaStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserMedspaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserMedspaStreamCursorValueInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expoPushToken?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isEligibleForEmpowerWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Defines if a provider can request scripts for clients. */
  isEligibleForScripts?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForSelfGfe?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForStrive503aWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEligibleForStrive503bWeightLoss?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGfeReviewer?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Defines if a provider requires a medical director to review their charts. */
  isMdChartReviewRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isProviderOwner?: InputMaybe<Scalars["Boolean"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<Scalars["Int"]["input"]>;
  role?: InputMaybe<Scalars["String"]["input"]>;
  roleId?: InputMaybe<Scalars["bigint"]["input"]>;
  suppliesShopifyCustomerId?: InputMaybe<Scalars["bigint"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type UserMedspaSumFields = {
  __typename?: "userMedspaSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Int"]["output"]>;
  roleId?: Maybe<Scalars["bigint"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["bigint"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "medspas_usermedspa" */
export type UserMedspaSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** update columns of table "medspas_usermedspa" */
export enum UserMedspaUpdateColumn {
  /** column name */
  Active = "active",
  /** column name */
  Created = "created",
  /** column name */
  ExpoPushToken = "expoPushToken",
  /** column name */
  Id = "id",
  /** column name */
  IsEligibleForEmpowerWeightLoss = "isEligibleForEmpowerWeightLoss",
  /** column name */
  IsEligibleForScripts = "isEligibleForScripts",
  /** column name */
  IsEligibleForSelfGfe = "isEligibleForSelfGfe",
  /** column name */
  IsEligibleForStrive503aWeightLoss = "isEligibleForStrive503aWeightLoss",
  /** column name */
  IsEligibleForStrive503bWeightLoss = "isEligibleForStrive503bWeightLoss",
  /** column name */
  IsGfeReviewer = "isGfeReviewer",
  /** column name */
  IsMdChartReviewRequired = "isMdChartReviewRequired",
  /** column name */
  IsProviderOwner = "isProviderOwner",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  PercentageOfChartsToReview = "percentageOfChartsToReview",
  /** column name */
  Role = "role",
  /** column name */
  RoleId = "roleId",
  /** column name */
  SuppliesShopifyCustomerId = "suppliesShopifyCustomerId",
  /** column name */
  UserId = "userId",
}

export type UserMedspaUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserMedspaIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserMedspaSetInput>;
  /** filter the rows which have to be updated */
  where: UserMedspaBoolExp;
};

export type UserMedspaUrlPermissionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserMedspaUrlPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<UserMedspaUrlPermissionsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate varPop on columns */
export type UserMedspaVarPopFields = {
  __typename?: "userMedspaVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Float"]["output"]>;
  roleId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "medspas_usermedspa" */
export type UserMedspaVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type UserMedspaVarSampFields = {
  __typename?: "userMedspaVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Float"]["output"]>;
  roleId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "medspas_usermedspa" */
export type UserMedspaVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserMedspaVarianceFields = {
  __typename?: "userMedspaVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: Maybe<Scalars["Float"]["output"]>;
  roleId?: Maybe<Scalars["Float"]["output"]>;
  suppliesShopifyCustomerId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "medspas_usermedspa" */
export type UserMedspaVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  /** Sets percentage of charts to review for a provider. */
  percentageOfChartsToReview?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  suppliesShopifyCustomerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: "userMinFields";
  birthdate?: Maybe<Scalars["date"]["output"]>;
  clerkUserId?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  dateJoined?: Maybe<Scalars["timestamptz"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** A computed field, executes function "user_full_name" */
  fullName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: Maybe<Scalars["String"]["output"]>;
  /** Internal value for the "Onboarding Manager" property in HubSpot */
  hubspotOnboardingManagerInternalValue?: Maybe<Scalars["String"]["output"]>;
  /** Internal value for the "Provider Success Manager (Cloned)" property in HubSpot */
  hubspotPsmInternalValue?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  lastLogin?: Maybe<Scalars["timestamptz"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "accounts_user" */
export type UserMutationResponse = {
  __typename?: "userMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "accounts_user" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UserOnConflict>;
};

/** on_conflict condition type for table "accounts_user" */
export type UserOnConflict = {
  constraint: UserConstraint;
  updateColumns?: Array<UserUpdateColumn>;
  where?: InputMaybe<UserBoolExp>;
};

/** Ordering options when selecting data from "accounts_user". */
export type UserOrderBy = {
  archivedInventoryLotsAggregate?: InputMaybe<InventoryLotAggregateOrderBy>;
  birthdate?: InputMaybe<OrderBy>;
  clerkUserId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  dateJoined?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  formSubmissionGFEStatusChangeLogsAggregate?: InputMaybe<FormSubmissionGfeStatusChangeLogAggregateOrderBy>;
  fullName?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  hubspotContactId?: InputMaybe<OrderBy>;
  hubspotOnboardingManagerInternalValue?: InputMaybe<OrderBy>;
  hubspotPsmInternalValue?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  isStaff?: InputMaybe<OrderBy>;
  isSuperuser?: InputMaybe<OrderBy>;
  lastLogin?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastNoShowVisitAggregate?: InputMaybe<VisitAggregateOrderBy>;
  medicalDirector?: InputMaybe<MedicalDirectorOrderBy>;
  medspasProfessionalcorporationsByOwnerIdAggregate?: InputMaybe<MedspasProfessionalcorporationAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  mostRecentVisitAggregate?: InputMaybe<VisitAggregateOrderBy>;
  note?: InputMaybe<OrderBy>;
  notesAggregate?: InputMaybe<NoteAggregateOrderBy>;
  password?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  providedVisitsAggregate?: InputMaybe<VisitAggregateOrderBy>;
  uploadedDiagramTemplatesAggregate?: InputMaybe<DiagramTemplateAggregateOrderBy>;
  uploadedDiagramsAggregate?: InputMaybe<DiagramAggregateOrderBy>;
  userMedspasAggregate?: InputMaybe<UserMedspaAggregateOrderBy>;
  visitReviewStatusChangeLogsAggregate?: InputMaybe<VisitReviewStatusChangeLogAggregateOrderBy>;
  visitStatusChangeLogsAggregate?: InputMaybe<VisitStatusChangeLogAggregateOrderBy>;
  walletTransactionSourcesAggregate?: InputMaybe<WalletTransactionSourceTmpAggregateOrderBy>;
};

/** primary key columns input for table: accounts_user */
export type UserPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "accounts_user" */
export enum UserSelectColumn {
  /** column name */
  Birthdate = "birthdate",
  /** column name */
  ClerkUserId = "clerkUserId",
  /** column name */
  Created = "created",
  /** column name */
  DateJoined = "dateJoined",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "firstName",
  /** column name */
  Gender = "gender",
  /** column name */
  HubspotContactId = "hubspotContactId",
  /** column name */
  HubspotOnboardingManagerInternalValue = "hubspotOnboardingManagerInternalValue",
  /** column name */
  HubspotPsmInternalValue = "hubspotPsmInternalValue",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "isActive",
  /** column name */
  IsStaff = "isStaff",
  /** column name */
  IsSuperuser = "isSuperuser",
  /** column name */
  LastLogin = "lastLogin",
  /** column name */
  LastName = "lastName",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  Password = "password",
  /** column name */
  Phone = "phone",
}

/** input type for updating data in table "accounts_user" */
export type UserSetInput = {
  birthdate?: InputMaybe<Scalars["date"]["input"]>;
  clerkUserId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dateJoined?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: InputMaybe<Scalars["String"]["input"]>;
  /** Internal value for the "Onboarding Manager" property in HubSpot */
  hubspotOnboardingManagerInternalValue?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** Internal value for the "Provider Success Manager (Cloned)" property in HubSpot */
  hubspotPsmInternalValue?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLogin?: InputMaybe<Scalars["timestamptz"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type UserStddevFields = {
  __typename?: "userStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevPop on columns */
export type UserStddevPopFields = {
  __typename?: "userStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddevSamp on columns */
export type UserStddevSampFields = {
  __typename?: "userStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "user" */
export type UserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStreamCursorValueInput = {
  birthdate?: InputMaybe<Scalars["date"]["input"]>;
  clerkUserId?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dateJoined?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  /** Unique identifier for the contact object in HubSpot */
  hubspotContactId?: InputMaybe<Scalars["String"]["input"]>;
  /** Internal value for the "Onboarding Manager" property in HubSpot */
  hubspotOnboardingManagerInternalValue?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** Internal value for the "Provider Success Manager (Cloned)" property in HubSpot */
  hubspotPsmInternalValue?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStaff?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuperuser?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLogin?: InputMaybe<Scalars["timestamptz"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type UserSumFields = {
  __typename?: "userSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
};

/** update columns of table "accounts_user" */
export enum UserUpdateColumn {
  /** column name */
  Birthdate = "birthdate",
  /** column name */
  ClerkUserId = "clerkUserId",
  /** column name */
  Created = "created",
  /** column name */
  DateJoined = "dateJoined",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "firstName",
  /** column name */
  Gender = "gender",
  /** column name */
  HubspotContactId = "hubspotContactId",
  /** column name */
  HubspotOnboardingManagerInternalValue = "hubspotOnboardingManagerInternalValue",
  /** column name */
  HubspotPsmInternalValue = "hubspotPsmInternalValue",
  /** column name */
  Id = "id",
  /** column name */
  IsActive = "isActive",
  /** column name */
  IsStaff = "isStaff",
  /** column name */
  IsSuperuser = "isSuperuser",
  /** column name */
  LastLogin = "lastLogin",
  /** column name */
  LastName = "lastName",
  /** column name */
  Modified = "modified",
  /** column name */
  Note = "note",
  /** column name */
  Password = "password",
  /** column name */
  Phone = "phone",
}

export type UserUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSetInput>;
  /** filter the rows which have to be updated */
  where: UserBoolExp;
};

/** aggregate varPop on columns */
export type UserVarPopFields = {
  __typename?: "userVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate varSamp on columns */
export type UserVarSampFields = {
  __typename?: "userVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type UserVarianceFields = {
  __typename?: "userVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "visits_visit" */
export type Visit = {
  __typename?: "visit";
  additionalInfo?: Maybe<VisitType>;
  /** An array relationship */
  adverseReactions: Array<AdverseReaction>;
  /** An aggregate relationship */
  adverseReactionsAggregate: AdverseReactionAggregate;
  /** An object relationship */
  appointment?: Maybe<SchedulingAppointment>;
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  chartSignatures: Array<VisitsChartsignature>;
  /** An aggregate relationship */
  chartSignaturesAggregate: VisitsChartsignatureAggregate;
  /** An object relationship */
  client: Client;
  /** Client affirmation text associated with this visit. */
  clientAffirmation: Scalars["String"]["output"];
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** A computed field, executes function "visit_deprecated_gfe_status" */
  deprecatedGfeStatus?: Maybe<Scalars["String"]["output"]>;
  /** An array relationship */
  diagrams: Array<Diagram>;
  /** An aggregate relationship */
  diagramsAggregate: DiagramAggregate;
  /** GFE status for a visit that is being persisted once appointment is complete. It is calculated based on a client form submissions (deprecated GFE flow) and used mostly for record keeping purposes. */
  finalDeprecatedGfeStatus: Scalars["String"]["output"];
  followupVisitBooked: Scalars["Boolean"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  /** A computed field, executes function "is_visit_telehealth" */
  isVisitTelehealth?: Maybe<Scalars["Boolean"]["output"]>;
  /** Timestamp of when the visit charts should be marked as overdue. */
  markChartsAsOverdueAt?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  medspa: Medspa;
  medspaId: Scalars["bigint"]["output"];
  /** An array relationship */
  membershipPerkLines: Array<MembershipPerkLine>;
  /** An aggregate relationship */
  membershipPerkLinesAggregate: MembershipPerkLineAggregate;
  modified: Scalars["timestamptz"]["output"];
  /** An array relationship */
  notes: Array<Note>;
  /** An aggregate relationship */
  notesAggregate: NoteAggregate;
  /** An array relationship */
  packageLines: Array<PackageLine>;
  /** An aggregate relationship */
  packageLinesAggregate: PackageLineAggregate;
  photos?: Maybe<Array<Maybe<PhotoType>>>;
  /** An array relationship */
  productLines: Array<ProductLine>;
  /** An aggregate relationship */
  productLinesAggregate: ProductLineAggregate;
  /** An object relationship */
  provider: User;
  providerId: Scalars["bigint"]["output"];
  reviewStatus: Scalars["String"]["output"];
  /** An array relationship */
  reviewStatusChangeLogs: Array<VisitReviewStatusChangeLog>;
  /** An aggregate relationship */
  reviewStatusChangeLogsAggregate: VisitReviewStatusChangeLogAggregate;
  /** An array relationship */
  serviceMenuItemLines: Array<ServiceMenuItemLine>;
  /** An aggregate relationship */
  serviceMenuItemLinesAggregate: ServiceMenuItemLineAggregate;
  /** An array relationship */
  serviceProductChartingNotes: Array<ServiceProductChartingNote>;
  /** An aggregate relationship */
  serviceProductChartingNotesAggregate: ServiceProductChartingNoteAggregate;
  /** An array relationship */
  serviceProductLines: Array<ServiceProductLine>;
  /** An aggregate relationship */
  serviceProductLinesAggregate: ServiceProductLineAggregate;
  startTime: Scalars["timestamptz"]["output"];
  status: Scalars["String"]["output"];
  /** An array relationship */
  statusChangeLogs: Array<VisitStatusChangeLog>;
  /** An aggregate relationship */
  statusChangeLogsAggregate: VisitStatusChangeLogAggregate;
  /** An object relationship */
  telehealthVisitDetails?: Maybe<TelehealthVisitDetails>;
};

/** columns and relationships of "visits_visit" */
export type VisitAdverseReactionsArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionOrderBy>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitAdverseReactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AdverseReactionSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AdverseReactionOrderBy>>;
  where?: InputMaybe<AdverseReactionBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitChartSignaturesArgs = {
  distinctOn?: InputMaybe<Array<VisitsChartsignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsChartsignatureOrderBy>>;
  where?: InputMaybe<VisitsChartsignatureBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitChartSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitsChartsignatureSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitsChartsignatureOrderBy>>;
  where?: InputMaybe<VisitsChartsignatureBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitDiagramsArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitDiagramsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DiagramSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DiagramOrderBy>>;
  where?: InputMaybe<DiagramBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitMembershipPerkLinesArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitMembershipPerkLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MembershipPerkLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MembershipPerkLineOrderBy>>;
  where?: InputMaybe<MembershipPerkLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitNotesArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<NoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NoteOrderBy>>;
  where?: InputMaybe<NoteBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitPackageLinesArgs = {
  distinctOn?: InputMaybe<Array<PackageLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageLineOrderBy>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitPackageLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PackageLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PackageLineOrderBy>>;
  where?: InputMaybe<PackageLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitPhotosArgs = {
  excludeDiagrams?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** columns and relationships of "visits_visit" */
export type VisitProductLinesArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitProductLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProductLineOrderBy>>;
  where?: InputMaybe<ProductLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitReviewStatusChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitReviewStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitReviewStatusChangeLogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitReviewStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitServiceMenuItemLinesArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitServiceMenuItemLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceMenuItemLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceMenuItemLineOrderBy>>;
  where?: InputMaybe<ServiceMenuItemLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitServiceProductChartingNotesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitServiceProductChartingNotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductChartingNoteSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductChartingNoteOrderBy>>;
  where?: InputMaybe<ServiceProductChartingNoteBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitServiceProductLinesArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitServiceProductLinesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ServiceProductLineSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ServiceProductLineOrderBy>>;
  where?: InputMaybe<ServiceProductLineBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitStatusChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

/** columns and relationships of "visits_visit" */
export type VisitStatusChangeLogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<VisitStatusChangeLogOrderBy>>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

/** aggregated selection of "visits_visit" */
export type VisitAggregate = {
  __typename?: "visitAggregate";
  aggregate?: Maybe<VisitAggregateFields>;
  nodes: Array<Visit>;
};

export type VisitAggregateBoolExp = {
  bool_and?: InputMaybe<VisitAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<VisitAggregateBoolExpBool_Or>;
  count?: InputMaybe<VisitAggregateBoolExpCount>;
};

export type VisitAggregateBoolExpBool_And = {
  arguments: VisitSelectColumnVisitAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<VisitBoolExp>;
  predicate: BooleanComparisonExp;
};

export type VisitAggregateBoolExpBool_Or = {
  arguments: VisitSelectColumnVisitAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<VisitBoolExp>;
  predicate: BooleanComparisonExp;
};

export type VisitAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<VisitSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<VisitBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_visit" */
export type VisitAggregateFields = {
  __typename?: "visitAggregateFields";
  avg?: Maybe<VisitAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<VisitMaxFields>;
  min?: Maybe<VisitMinFields>;
  stddev?: Maybe<VisitStddevFields>;
  stddevPop?: Maybe<VisitStddevPopFields>;
  stddevSamp?: Maybe<VisitStddevSampFields>;
  sum?: Maybe<VisitSumFields>;
  varPop?: Maybe<VisitVarPopFields>;
  varSamp?: Maybe<VisitVarSampFields>;
  variance?: Maybe<VisitVarianceFields>;
};

/** aggregate fields of "visits_visit" */
export type VisitAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_visit" */
export type VisitAggregateOrderBy = {
  avg?: InputMaybe<VisitAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VisitMaxOrderBy>;
  min?: InputMaybe<VisitMinOrderBy>;
  stddev?: InputMaybe<VisitStddevOrderBy>;
  stddevPop?: InputMaybe<VisitStddevPopOrderBy>;
  stddevSamp?: InputMaybe<VisitStddevSampOrderBy>;
  sum?: InputMaybe<VisitSumOrderBy>;
  varPop?: InputMaybe<VisitVarPopOrderBy>;
  varSamp?: InputMaybe<VisitVarSampOrderBy>;
  variance?: InputMaybe<VisitVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_visit" */
export type VisitArrRelInsertInput = {
  data: Array<VisitInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VisitOnConflict>;
};

/** aggregate avg on columns */
export type VisitAvgFields = {
  __typename?: "visitAvgFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_visit" */
export type VisitAvgOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_visit". All fields are combined with a logical 'AND'. */
export type VisitBoolExp = {
  _and?: InputMaybe<Array<VisitBoolExp>>;
  _not?: InputMaybe<VisitBoolExp>;
  _or?: InputMaybe<Array<VisitBoolExp>>;
  adverseReactions?: InputMaybe<AdverseReactionBoolExp>;
  adverseReactionsAggregate?: InputMaybe<AdverseReactionAggregateBoolExp>;
  appointment?: InputMaybe<SchedulingAppointmentBoolExp>;
  appointmentId?: InputMaybe<BigintComparisonExp>;
  chartSignatures?: InputMaybe<VisitsChartsignatureBoolExp>;
  chartSignaturesAggregate?: InputMaybe<VisitsChartsignatureAggregateBoolExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientAffirmation?: InputMaybe<StringComparisonExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  deprecatedGfeStatus?: InputMaybe<StringComparisonExp>;
  diagrams?: InputMaybe<DiagramBoolExp>;
  diagramsAggregate?: InputMaybe<DiagramAggregateBoolExp>;
  finalDeprecatedGfeStatus?: InputMaybe<StringComparisonExp>;
  followupVisitBooked?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  isVisitTelehealth?: InputMaybe<BooleanComparisonExp>;
  markChartsAsOverdueAt?: InputMaybe<TimestamptzComparisonExp>;
  medspa?: InputMaybe<MedspaBoolExp>;
  medspaId?: InputMaybe<BigintComparisonExp>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineBoolExp>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateBoolExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  notes?: InputMaybe<NoteBoolExp>;
  notesAggregate?: InputMaybe<NoteAggregateBoolExp>;
  packageLines?: InputMaybe<PackageLineBoolExp>;
  packageLinesAggregate?: InputMaybe<PackageLineAggregateBoolExp>;
  productLines?: InputMaybe<ProductLineBoolExp>;
  productLinesAggregate?: InputMaybe<ProductLineAggregateBoolExp>;
  provider?: InputMaybe<UserBoolExp>;
  providerId?: InputMaybe<BigintComparisonExp>;
  reviewStatus?: InputMaybe<StringComparisonExp>;
  reviewStatusChangeLogs?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
  reviewStatusChangeLogsAggregate?: InputMaybe<VisitReviewStatusChangeLogAggregateBoolExp>;
  serviceMenuItemLines?: InputMaybe<ServiceMenuItemLineBoolExp>;
  serviceMenuItemLinesAggregate?: InputMaybe<ServiceMenuItemLineAggregateBoolExp>;
  serviceProductChartingNotes?: InputMaybe<ServiceProductChartingNoteBoolExp>;
  serviceProductChartingNotesAggregate?: InputMaybe<ServiceProductChartingNoteAggregateBoolExp>;
  serviceProductLines?: InputMaybe<ServiceProductLineBoolExp>;
  serviceProductLinesAggregate?: InputMaybe<ServiceProductLineAggregateBoolExp>;
  startTime?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  statusChangeLogs?: InputMaybe<VisitStatusChangeLogBoolExp>;
  statusChangeLogsAggregate?: InputMaybe<VisitStatusChangeLogAggregateBoolExp>;
  telehealthVisitDetails?: InputMaybe<TelehealthVisitDetailsBoolExp>;
};

/** unique or primary key constraints on table "visits_visit" */
export enum VisitConstraint {
  /** unique or primary key constraint on columns "appointment_id" */
  VisitsVisitAppointmentIdKey = "visits_visit_appointment_id_key",
  /** unique or primary key constraint on columns "id" */
  VisitsVisitPkey = "visits_visit_pkey",
}

/** input type for incrementing numeric columns in table "visits_visit" */
export type VisitIncInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_visit" */
export type VisitInsertInput = {
  adverseReactions?: InputMaybe<AdverseReactionArrRelInsertInput>;
  appointment?: InputMaybe<SchedulingAppointmentObjRelInsertInput>;
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  chartSignatures?: InputMaybe<VisitsChartsignatureArrRelInsertInput>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  /** Client affirmation text associated with this visit. */
  clientAffirmation?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  diagrams?: InputMaybe<DiagramArrRelInsertInput>;
  /** GFE status for a visit that is being persisted once appointment is complete. It is calculated based on a client form submissions (deprecated GFE flow) and used mostly for record keeping purposes. */
  finalDeprecatedGfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  followupVisitBooked?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  /** Timestamp of when the visit charts should be marked as overdue. */
  markChartsAsOverdueAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspa?: InputMaybe<MedspaObjRelInsertInput>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  membershipPerkLines?: InputMaybe<MembershipPerkLineArrRelInsertInput>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  notes?: InputMaybe<NoteArrRelInsertInput>;
  packageLines?: InputMaybe<PackageLineArrRelInsertInput>;
  productLines?: InputMaybe<ProductLineArrRelInsertInput>;
  provider?: InputMaybe<UserObjRelInsertInput>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  reviewStatus?: InputMaybe<Scalars["String"]["input"]>;
  reviewStatusChangeLogs?: InputMaybe<VisitReviewStatusChangeLogArrRelInsertInput>;
  serviceMenuItemLines?: InputMaybe<ServiceMenuItemLineArrRelInsertInput>;
  serviceProductChartingNotes?: InputMaybe<ServiceProductChartingNoteArrRelInsertInput>;
  serviceProductLines?: InputMaybe<ServiceProductLineArrRelInsertInput>;
  startTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  statusChangeLogs?: InputMaybe<VisitStatusChangeLogArrRelInsertInput>;
  telehealthVisitDetails?: InputMaybe<TelehealthVisitDetailsObjRelInsertInput>;
};

/** aggregate max on columns */
export type VisitMaxFields = {
  __typename?: "visitMaxFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  /** Client affirmation text associated with this visit. */
  clientAffirmation?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A computed field, executes function "visit_deprecated_gfe_status" */
  deprecatedGfeStatus?: Maybe<Scalars["String"]["output"]>;
  /** GFE status for a visit that is being persisted once appointment is complete. It is calculated based on a client form submissions (deprecated GFE flow) and used mostly for record keeping purposes. */
  finalDeprecatedGfeStatus?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Timestamp of when the visit charts should be marked as overdue. */
  markChartsAsOverdueAt?: Maybe<Scalars["timestamptz"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  reviewStatus?: Maybe<Scalars["String"]["output"]>;
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by max() on columns of table "visits_visit" */
export type VisitMaxOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  /** Client affirmation text associated with this visit. */
  clientAffirmation?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** GFE status for a visit that is being persisted once appointment is complete. It is calculated based on a client form submissions (deprecated GFE flow) and used mostly for record keeping purposes. */
  finalDeprecatedGfeStatus?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Timestamp of when the visit charts should be marked as overdue. */
  markChartsAsOverdueAt?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  reviewStatus?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type VisitMinFields = {
  __typename?: "visitMinFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  /** Client affirmation text associated with this visit. */
  clientAffirmation?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** A computed field, executes function "visit_deprecated_gfe_status" */
  deprecatedGfeStatus?: Maybe<Scalars["String"]["output"]>;
  /** GFE status for a visit that is being persisted once appointment is complete. It is calculated based on a client form submissions (deprecated GFE flow) and used mostly for record keeping purposes. */
  finalDeprecatedGfeStatus?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  /** Timestamp of when the visit charts should be marked as overdue. */
  markChartsAsOverdueAt?: Maybe<Scalars["timestamptz"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
  reviewStatus?: Maybe<Scalars["String"]["output"]>;
  startTime?: Maybe<Scalars["timestamptz"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

/** order by min() on columns of table "visits_visit" */
export type VisitMinOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  /** Client affirmation text associated with this visit. */
  clientAffirmation?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** GFE status for a visit that is being persisted once appointment is complete. It is calculated based on a client form submissions (deprecated GFE flow) and used mostly for record keeping purposes. */
  finalDeprecatedGfeStatus?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** Timestamp of when the visit charts should be marked as overdue. */
  markChartsAsOverdueAt?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
  reviewStatus?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_visit" */
export type VisitMutationResponse = {
  __typename?: "visitMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Visit>;
};

/** input type for inserting object relation for remote table "visits_visit" */
export type VisitObjRelInsertInput = {
  data: VisitInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<VisitOnConflict>;
};

/** on_conflict condition type for table "visits_visit" */
export type VisitOnConflict = {
  constraint: VisitConstraint;
  updateColumns?: Array<VisitUpdateColumn>;
  where?: InputMaybe<VisitBoolExp>;
};

/** Ordering options when selecting data from "visits_visit". */
export type VisitOrderBy = {
  adverseReactionsAggregate?: InputMaybe<AdverseReactionAggregateOrderBy>;
  appointment?: InputMaybe<SchedulingAppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  chartSignaturesAggregate?: InputMaybe<VisitsChartsignatureAggregateOrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientAffirmation?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  deprecatedGfeStatus?: InputMaybe<OrderBy>;
  diagramsAggregate?: InputMaybe<DiagramAggregateOrderBy>;
  finalDeprecatedGfeStatus?: InputMaybe<OrderBy>;
  followupVisitBooked?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  isVisitTelehealth?: InputMaybe<OrderBy>;
  markChartsAsOverdueAt?: InputMaybe<OrderBy>;
  medspa?: InputMaybe<MedspaOrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  membershipPerkLinesAggregate?: InputMaybe<MembershipPerkLineAggregateOrderBy>;
  modified?: InputMaybe<OrderBy>;
  notesAggregate?: InputMaybe<NoteAggregateOrderBy>;
  packageLinesAggregate?: InputMaybe<PackageLineAggregateOrderBy>;
  productLinesAggregate?: InputMaybe<ProductLineAggregateOrderBy>;
  provider?: InputMaybe<UserOrderBy>;
  providerId?: InputMaybe<OrderBy>;
  reviewStatus?: InputMaybe<OrderBy>;
  reviewStatusChangeLogsAggregate?: InputMaybe<VisitReviewStatusChangeLogAggregateOrderBy>;
  serviceMenuItemLinesAggregate?: InputMaybe<ServiceMenuItemLineAggregateOrderBy>;
  serviceProductChartingNotesAggregate?: InputMaybe<ServiceProductChartingNoteAggregateOrderBy>;
  serviceProductLinesAggregate?: InputMaybe<ServiceProductLineAggregateOrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  statusChangeLogsAggregate?: InputMaybe<VisitStatusChangeLogAggregateOrderBy>;
  telehealthVisitDetails?: InputMaybe<TelehealthVisitDetailsOrderBy>;
};

/** primary key columns input for table: visits_visit */
export type VisitPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** columns and relationships of "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLog = {
  __typename?: "visitReviewStatusChangeLog";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  newStatus: Scalars["String"]["output"];
  oldStatus: Scalars["String"]["output"];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  visit: Visit;
  visitId: Scalars["bigint"]["output"];
};

/** aggregated selection of "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogAggregate = {
  __typename?: "visitReviewStatusChangeLogAggregate";
  aggregate?: Maybe<VisitReviewStatusChangeLogAggregateFields>;
  nodes: Array<VisitReviewStatusChangeLog>;
};

export type VisitReviewStatusChangeLogAggregateBoolExp = {
  count?: InputMaybe<VisitReviewStatusChangeLogAggregateBoolExpCount>;
};

export type VisitReviewStatusChangeLogAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogAggregateFields = {
  __typename?: "visitReviewStatusChangeLogAggregateFields";
  avg?: Maybe<VisitReviewStatusChangeLogAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<VisitReviewStatusChangeLogMaxFields>;
  min?: Maybe<VisitReviewStatusChangeLogMinFields>;
  stddev?: Maybe<VisitReviewStatusChangeLogStddevFields>;
  stddevPop?: Maybe<VisitReviewStatusChangeLogStddevPopFields>;
  stddevSamp?: Maybe<VisitReviewStatusChangeLogStddevSampFields>;
  sum?: Maybe<VisitReviewStatusChangeLogSumFields>;
  varPop?: Maybe<VisitReviewStatusChangeLogVarPopFields>;
  varSamp?: Maybe<VisitReviewStatusChangeLogVarSampFields>;
  variance?: Maybe<VisitReviewStatusChangeLogVarianceFields>;
};

/** aggregate fields of "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitReviewStatusChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogAggregateOrderBy = {
  avg?: InputMaybe<VisitReviewStatusChangeLogAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VisitReviewStatusChangeLogMaxOrderBy>;
  min?: InputMaybe<VisitReviewStatusChangeLogMinOrderBy>;
  stddev?: InputMaybe<VisitReviewStatusChangeLogStddevOrderBy>;
  stddevPop?: InputMaybe<VisitReviewStatusChangeLogStddevPopOrderBy>;
  stddevSamp?: InputMaybe<VisitReviewStatusChangeLogStddevSampOrderBy>;
  sum?: InputMaybe<VisitReviewStatusChangeLogSumOrderBy>;
  varPop?: InputMaybe<VisitReviewStatusChangeLogVarPopOrderBy>;
  varSamp?: InputMaybe<VisitReviewStatusChangeLogVarSampOrderBy>;
  variance?: InputMaybe<VisitReviewStatusChangeLogVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogArrRelInsertInput = {
  data: Array<VisitReviewStatusChangeLogInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VisitReviewStatusChangeLogOnConflict>;
};

/** aggregate avg on columns */
export type VisitReviewStatusChangeLogAvgFields = {
  __typename?: "visitReviewStatusChangeLogAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_visitreviewstatuschangelog". All fields are combined with a logical 'AND'. */
export type VisitReviewStatusChangeLogBoolExp = {
  _and?: InputMaybe<Array<VisitReviewStatusChangeLogBoolExp>>;
  _not?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
  _or?: InputMaybe<Array<VisitReviewStatusChangeLogBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  newStatus?: InputMaybe<StringComparisonExp>;
  oldStatus?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<BigintComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_visitreviewstatuschangelog" */
export enum VisitReviewStatusChangeLogConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsVisitreviewstatuschangelogPkey = "visits_visitreviewstatuschangelog_pkey",
}

/** input type for incrementing numeric columns in table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type VisitReviewStatusChangeLogMaxFields = {
  __typename?: "visitReviewStatusChangeLogMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  newStatus?: Maybe<Scalars["String"]["output"]>;
  oldStatus?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type VisitReviewStatusChangeLogMinFields = {
  __typename?: "visitReviewStatusChangeLogMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  newStatus?: Maybe<Scalars["String"]["output"]>;
  oldStatus?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogMutationResponse = {
  __typename?: "visitReviewStatusChangeLogMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<VisitReviewStatusChangeLog>;
};

/** on_conflict condition type for table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogOnConflict = {
  constraint: VisitReviewStatusChangeLogConstraint;
  updateColumns?: Array<VisitReviewStatusChangeLogUpdateColumn>;
  where?: InputMaybe<VisitReviewStatusChangeLogBoolExp>;
};

/** Ordering options when selecting data from "visits_visitreviewstatuschangelog". */
export type VisitReviewStatusChangeLogOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_visitreviewstatuschangelog */
export type VisitReviewStatusChangeLogPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_visitreviewstatuschangelog" */
export enum VisitReviewStatusChangeLogSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewStatus = "newStatus",
  /** column name */
  OldStatus = "oldStatus",
  /** column name */
  UserId = "userId",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type VisitReviewStatusChangeLogStddevFields = {
  __typename?: "visitReviewStatusChangeLogStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type VisitReviewStatusChangeLogStddevPopFields = {
  __typename?: "visitReviewStatusChangeLogStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type VisitReviewStatusChangeLogStddevSampFields = {
  __typename?: "visitReviewStatusChangeLogStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "visitReviewStatusChangeLog" */
export type VisitReviewStatusChangeLogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitReviewStatusChangeLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitReviewStatusChangeLogStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type VisitReviewStatusChangeLogSumFields = {
  __typename?: "visitReviewStatusChangeLogSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_visitreviewstatuschangelog" */
export enum VisitReviewStatusChangeLogUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewStatus = "newStatus",
  /** column name */
  OldStatus = "oldStatus",
  /** column name */
  UserId = "userId",
  /** column name */
  VisitId = "visitId",
}

export type VisitReviewStatusChangeLogUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitReviewStatusChangeLogIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitReviewStatusChangeLogSetInput>;
  /** filter the rows which have to be updated */
  where: VisitReviewStatusChangeLogBoolExp;
};

/** aggregate varPop on columns */
export type VisitReviewStatusChangeLogVarPopFields = {
  __typename?: "visitReviewStatusChangeLogVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type VisitReviewStatusChangeLogVarSampFields = {
  __typename?: "visitReviewStatusChangeLogVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type VisitReviewStatusChangeLogVarianceFields = {
  __typename?: "visitReviewStatusChangeLogVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_visitreviewstatuschangelog" */
export type VisitReviewStatusChangeLogVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** select columns of table "visits_visit" */
export enum VisitSelectColumn {
  /** column name */
  AppointmentId = "appointmentId",
  /** column name */
  ClientAffirmation = "clientAffirmation",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  FinalDeprecatedGfeStatus = "finalDeprecatedGfeStatus",
  /** column name */
  FollowupVisitBooked = "followupVisitBooked",
  /** column name */
  Id = "id",
  /** column name */
  MarkChartsAsOverdueAt = "markChartsAsOverdueAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  ReviewStatus = "reviewStatus",
  /** column name */
  StartTime = "startTime",
  /** column name */
  Status = "status",
}

/** select "visitAggregateBoolExpBool_andArgumentsColumns" columns of table "visits_visit" */
export enum VisitSelectColumnVisitAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  FollowupVisitBooked = "followupVisitBooked",
}

/** select "visitAggregateBoolExpBool_orArgumentsColumns" columns of table "visits_visit" */
export enum VisitSelectColumnVisitAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  FollowupVisitBooked = "followupVisitBooked",
}

/** input type for updating data in table "visits_visit" */
export type VisitSetInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Client affirmation text associated with this visit. */
  clientAffirmation?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** GFE status for a visit that is being persisted once appointment is complete. It is calculated based on a client form submissions (deprecated GFE flow) and used mostly for record keeping purposes. */
  finalDeprecatedGfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  followupVisitBooked?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Timestamp of when the visit charts should be marked as overdue. */
  markChartsAsOverdueAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  reviewStatus?: InputMaybe<Scalars["String"]["input"]>;
  startTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "visits_visitstatuschangelog" */
export type VisitStatusChangeLog = {
  __typename?: "visitStatusChangeLog";
  created: Scalars["timestamptz"]["output"];
  id: Scalars["bigint"]["output"];
  modified: Scalars["timestamptz"]["output"];
  newStatus: Scalars["String"]["output"];
  oldStatus: Scalars["String"]["output"];
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  visit: Visit;
  visitId: Scalars["bigint"]["output"];
};

/** aggregated selection of "visits_visitstatuschangelog" */
export type VisitStatusChangeLogAggregate = {
  __typename?: "visitStatusChangeLogAggregate";
  aggregate?: Maybe<VisitStatusChangeLogAggregateFields>;
  nodes: Array<VisitStatusChangeLog>;
};

export type VisitStatusChangeLogAggregateBoolExp = {
  count?: InputMaybe<VisitStatusChangeLogAggregateBoolExpCount>;
};

export type VisitStatusChangeLogAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<VisitStatusChangeLogBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "visits_visitstatuschangelog" */
export type VisitStatusChangeLogAggregateFields = {
  __typename?: "visitStatusChangeLogAggregateFields";
  avg?: Maybe<VisitStatusChangeLogAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<VisitStatusChangeLogMaxFields>;
  min?: Maybe<VisitStatusChangeLogMinFields>;
  stddev?: Maybe<VisitStatusChangeLogStddevFields>;
  stddevPop?: Maybe<VisitStatusChangeLogStddevPopFields>;
  stddevSamp?: Maybe<VisitStatusChangeLogStddevSampFields>;
  sum?: Maybe<VisitStatusChangeLogSumFields>;
  varPop?: Maybe<VisitStatusChangeLogVarPopFields>;
  varSamp?: Maybe<VisitStatusChangeLogVarSampFields>;
  variance?: Maybe<VisitStatusChangeLogVarianceFields>;
};

/** aggregate fields of "visits_visitstatuschangelog" */
export type VisitStatusChangeLogAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VisitStatusChangeLogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogAggregateOrderBy = {
  avg?: InputMaybe<VisitStatusChangeLogAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VisitStatusChangeLogMaxOrderBy>;
  min?: InputMaybe<VisitStatusChangeLogMinOrderBy>;
  stddev?: InputMaybe<VisitStatusChangeLogStddevOrderBy>;
  stddevPop?: InputMaybe<VisitStatusChangeLogStddevPopOrderBy>;
  stddevSamp?: InputMaybe<VisitStatusChangeLogStddevSampOrderBy>;
  sum?: InputMaybe<VisitStatusChangeLogSumOrderBy>;
  varPop?: InputMaybe<VisitStatusChangeLogVarPopOrderBy>;
  varSamp?: InputMaybe<VisitStatusChangeLogVarSampOrderBy>;
  variance?: InputMaybe<VisitStatusChangeLogVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogArrRelInsertInput = {
  data: Array<VisitStatusChangeLogInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VisitStatusChangeLogOnConflict>;
};

/** aggregate avg on columns */
export type VisitStatusChangeLogAvgFields = {
  __typename?: "visitStatusChangeLogAvgFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "visits_visitstatuschangelog". All fields are combined with a logical 'AND'. */
export type VisitStatusChangeLogBoolExp = {
  _and?: InputMaybe<Array<VisitStatusChangeLogBoolExp>>;
  _not?: InputMaybe<VisitStatusChangeLogBoolExp>;
  _or?: InputMaybe<Array<VisitStatusChangeLogBoolExp>>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  newStatus?: InputMaybe<StringComparisonExp>;
  oldStatus?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<BigintComparisonExp>;
  visit?: InputMaybe<VisitBoolExp>;
  visitId?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "visits_visitstatuschangelog" */
export enum VisitStatusChangeLogConstraint {
  /** unique or primary key constraint on columns "id" */
  VisitsVisitstatuschangelogPkey = "visits_visitstatuschangelog_pkey",
}

/** input type for incrementing numeric columns in table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogIncInput = {
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogInsertInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
  visit?: InputMaybe<VisitObjRelInsertInput>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate max on columns */
export type VisitStatusChangeLogMaxFields = {
  __typename?: "visitStatusChangeLogMaxFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  newStatus?: Maybe<Scalars["String"]["output"]>;
  oldStatus?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogMaxOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type VisitStatusChangeLogMinFields = {
  __typename?: "visitStatusChangeLogMinFields";
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  newStatus?: Maybe<Scalars["String"]["output"]>;
  oldStatus?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogMinOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogMutationResponse = {
  __typename?: "visitStatusChangeLogMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<VisitStatusChangeLog>;
};

/** on_conflict condition type for table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogOnConflict = {
  constraint: VisitStatusChangeLogConstraint;
  updateColumns?: Array<VisitStatusChangeLogUpdateColumn>;
  where?: InputMaybe<VisitStatusChangeLogBoolExp>;
};

/** Ordering options when selecting data from "visits_visitstatuschangelog". */
export type VisitStatusChangeLogOrderBy = {
  created?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  newStatus?: InputMaybe<OrderBy>;
  oldStatus?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  visit?: InputMaybe<VisitOrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: visits_visitstatuschangelog */
export type VisitStatusChangeLogPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "visits_visitstatuschangelog" */
export enum VisitStatusChangeLogSelectColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewStatus = "newStatus",
  /** column name */
  OldStatus = "oldStatus",
  /** column name */
  UserId = "userId",
  /** column name */
  VisitId = "visitId",
}

/** input type for updating data in table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogSetInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate stddev on columns */
export type VisitStatusChangeLogStddevFields = {
  __typename?: "visitStatusChangeLogStddevFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type VisitStatusChangeLogStddevPopFields = {
  __typename?: "visitStatusChangeLogStddevPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type VisitStatusChangeLogStddevSampFields = {
  __typename?: "visitStatusChangeLogStddevSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "visitStatusChangeLog" */
export type VisitStatusChangeLogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitStatusChangeLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitStatusChangeLogStreamCursorValueInput = {
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  newStatus?: InputMaybe<Scalars["String"]["input"]>;
  oldStatus?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["bigint"]["input"]>;
  visitId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type VisitStatusChangeLogSumFields = {
  __typename?: "visitStatusChangeLogSumFields";
  id?: Maybe<Scalars["bigint"]["output"]>;
  userId?: Maybe<Scalars["bigint"]["output"]>;
  visitId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_visitstatuschangelog" */
export enum VisitStatusChangeLogUpdateColumn {
  /** column name */
  Created = "created",
  /** column name */
  Id = "id",
  /** column name */
  Modified = "modified",
  /** column name */
  NewStatus = "newStatus",
  /** column name */
  OldStatus = "oldStatus",
  /** column name */
  UserId = "userId",
  /** column name */
  VisitId = "visitId",
}

export type VisitStatusChangeLogUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitStatusChangeLogIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitStatusChangeLogSetInput>;
  /** filter the rows which have to be updated */
  where: VisitStatusChangeLogBoolExp;
};

/** aggregate varPop on columns */
export type VisitStatusChangeLogVarPopFields = {
  __typename?: "visitStatusChangeLogVarPopFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type VisitStatusChangeLogVarSampFields = {
  __typename?: "visitStatusChangeLogVarSampFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type VisitStatusChangeLogVarianceFields = {
  __typename?: "visitStatusChangeLogVarianceFields";
  id?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
  visitId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_visitstatuschangelog" */
export type VisitStatusChangeLogVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  visitId?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type VisitStddevFields = {
  __typename?: "visitStddevFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "visits_visit" */
export type VisitStddevOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type VisitStddevPopFields = {
  __typename?: "visitStddevPopFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "visits_visit" */
export type VisitStddevPopOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type VisitStddevSampFields = {
  __typename?: "visitStddevSampFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "visits_visit" */
export type VisitStddevSampOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "visit" */
export type VisitStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VisitStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VisitStreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Client affirmation text associated with this visit. */
  clientAffirmation?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** GFE status for a visit that is being persisted once appointment is complete. It is calculated based on a client form submissions (deprecated GFE flow) and used mostly for record keeping purposes. */
  finalDeprecatedGfeStatus?: InputMaybe<Scalars["String"]["input"]>;
  followupVisitBooked?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Timestamp of when the visit charts should be marked as overdue. */
  markChartsAsOverdueAt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  medspaId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  providerId?: InputMaybe<Scalars["bigint"]["input"]>;
  reviewStatus?: InputMaybe<Scalars["String"]["input"]>;
  startTime?: InputMaybe<Scalars["timestamptz"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type VisitSumFields = {
  __typename?: "visitSumFields";
  appointmentId?: Maybe<Scalars["bigint"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  medspaId?: Maybe<Scalars["bigint"]["output"]>;
  providerId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "visits_visit" */
export type VisitSumOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** update columns of table "visits_visit" */
export enum VisitUpdateColumn {
  /** column name */
  AppointmentId = "appointmentId",
  /** column name */
  ClientAffirmation = "clientAffirmation",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  FinalDeprecatedGfeStatus = "finalDeprecatedGfeStatus",
  /** column name */
  FollowupVisitBooked = "followupVisitBooked",
  /** column name */
  Id = "id",
  /** column name */
  MarkChartsAsOverdueAt = "markChartsAsOverdueAt",
  /** column name */
  MedspaId = "medspaId",
  /** column name */
  Modified = "modified",
  /** column name */
  ProviderId = "providerId",
  /** column name */
  ReviewStatus = "reviewStatus",
  /** column name */
  StartTime = "startTime",
  /** column name */
  Status = "status",
}

export type VisitUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VisitIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VisitSetInput>;
  /** filter the rows which have to be updated */
  where: VisitBoolExp;
};

/** aggregate varPop on columns */
export type VisitVarPopFields = {
  __typename?: "visitVarPopFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "visits_visit" */
export type VisitVarPopOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type VisitVarSampFields = {
  __typename?: "visitVarSampFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "visits_visit" */
export type VisitVarSampOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type VisitVarianceFields = {
  __typename?: "visitVarianceFields";
  appointmentId?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  medspaId?: Maybe<Scalars["Float"]["output"]>;
  providerId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "visits_visit" */
export type VisitVarianceOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  medspaId?: InputMaybe<OrderBy>;
  providerId?: InputMaybe<OrderBy>;
};

export type VisitsAdversereactionchangelogAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<VisitsAdversereactionchangelogSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<VisitsAdversereactionchangelogBoolExp>;
  predicate: IntComparisonExp;
};

export type VisitsChartsignatureAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<VisitsChartsignatureSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<VisitsChartsignatureBoolExp>;
  predicate: IntComparisonExp;
};

export type WalletCreditLineAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WalletCreditLineSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<WalletCreditLineBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "wallets_wallettransaction" */
export type WalletTransaction = {
  __typename?: "walletTransaction";
  amount: Scalars["numeric"]["output"];
  /** An object relationship */
  client: Client;
  clientId: Scalars["bigint"]["output"];
  created: Scalars["timestamptz"]["output"];
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  creditType: Scalars["String"]["output"];
  id: Scalars["bigint"]["output"];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified: Scalars["timestamptz"]["output"];
  /** An object relationship */
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  refund?: Maybe<Refund>;
  refundId?: Maybe<Scalars["bigint"]["output"]>;
  resourceType: Scalars["String"]["output"];
  /** An object relationship */
  serviceMenuItem?: Maybe<MedspaServiceMenuItem>;
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  serviceProduct?: Maybe<MedspaServiceProduct>;
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  /** An object relationship */
  walletCreditLine?: Maybe<WalletCreditLine>;
  /** An object relationship */
  walletCreditType?: Maybe<WalletCreditType>;
  walletCreditTypeId?: Maybe<Scalars["bigint"]["output"]>;
  /** An array relationship */
  walletTransactionsSources: Array<WalletTransactionsSources>;
  /** An aggregate relationship */
  walletTransactionsSourcesAggregate: WalletTransactionsSourcesAggregate;
};

/** columns and relationships of "wallets_wallettransaction" */
export type WalletTransactionWalletTransactionsSourcesArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionsSourcesOrderBy>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

/** columns and relationships of "wallets_wallettransaction" */
export type WalletTransactionWalletTransactionsSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WalletTransactionsSourcesOrderBy>>;
  where?: InputMaybe<WalletTransactionsSourcesBoolExp>;
};

/** aggregated selection of "wallets_wallettransaction" */
export type WalletTransactionAggregate = {
  __typename?: "walletTransactionAggregate";
  aggregate?: Maybe<WalletTransactionAggregateFields>;
  nodes: Array<WalletTransaction>;
};

export type WalletTransactionAggregateBoolExp = {
  count?: InputMaybe<WalletTransactionAggregateBoolExpCount>;
};

export type WalletTransactionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WalletTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<WalletTransactionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "wallets_wallettransaction" */
export type WalletTransactionAggregateFields = {
  __typename?: "walletTransactionAggregateFields";
  avg?: Maybe<WalletTransactionAvgFields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<WalletTransactionMaxFields>;
  min?: Maybe<WalletTransactionMinFields>;
  stddev?: Maybe<WalletTransactionStddevFields>;
  stddevPop?: Maybe<WalletTransactionStddevPopFields>;
  stddevSamp?: Maybe<WalletTransactionStddevSampFields>;
  sum?: Maybe<WalletTransactionSumFields>;
  varPop?: Maybe<WalletTransactionVarPopFields>;
  varSamp?: Maybe<WalletTransactionVarSampFields>;
  variance?: Maybe<WalletTransactionVarianceFields>;
};

/** aggregate fields of "wallets_wallettransaction" */
export type WalletTransactionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WalletTransactionSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "wallets_wallettransaction" */
export type WalletTransactionAggregateOrderBy = {
  avg?: InputMaybe<WalletTransactionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WalletTransactionMaxOrderBy>;
  min?: InputMaybe<WalletTransactionMinOrderBy>;
  stddev?: InputMaybe<WalletTransactionStddevOrderBy>;
  stddevPop?: InputMaybe<WalletTransactionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<WalletTransactionStddevSampOrderBy>;
  sum?: InputMaybe<WalletTransactionSumOrderBy>;
  varPop?: InputMaybe<WalletTransactionVarPopOrderBy>;
  varSamp?: InputMaybe<WalletTransactionVarSampOrderBy>;
  variance?: InputMaybe<WalletTransactionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "wallets_wallettransaction" */
export type WalletTransactionArrRelInsertInput = {
  data: Array<WalletTransactionInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WalletTransactionOnConflict>;
};

/** aggregate avg on columns */
export type WalletTransactionAvgFields = {
  __typename?: "walletTransactionAvgFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  refundId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "wallets_wallettransaction" */
export type WalletTransactionAvgOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "wallets_wallettransaction". All fields are combined with a logical 'AND'. */
export type WalletTransactionBoolExp = {
  _and?: InputMaybe<Array<WalletTransactionBoolExp>>;
  _not?: InputMaybe<WalletTransactionBoolExp>;
  _or?: InputMaybe<Array<WalletTransactionBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  client?: InputMaybe<ClientBoolExp>;
  clientId?: InputMaybe<BigintComparisonExp>;
  created?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<UserBoolExp>;
  createdById?: InputMaybe<BigintComparisonExp>;
  creditType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<BigintComparisonExp>;
  modified?: InputMaybe<TimestamptzComparisonExp>;
  payment?: InputMaybe<PaymentBoolExp>;
  paymentId?: InputMaybe<BigintComparisonExp>;
  product?: InputMaybe<ProductBoolExp>;
  productId?: InputMaybe<BigintComparisonExp>;
  refund?: InputMaybe<RefundBoolExp>;
  refundId?: InputMaybe<BigintComparisonExp>;
  resourceType?: InputMaybe<StringComparisonExp>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemBoolExp>;
  serviceMenuItemId?: InputMaybe<BigintComparisonExp>;
  serviceProduct?: InputMaybe<MedspaServiceProductBoolExp>;
  serviceProductId?: InputMaybe<BigintComparisonExp>;
  walletCreditLine?: InputMaybe<WalletCreditLineBoolExp>;
  walletCreditType?: InputMaybe<WalletCreditTypeBoolExp>;
  walletCreditTypeId?: InputMaybe<BigintComparisonExp>;
  walletTransactionsSources?: InputMaybe<WalletTransactionsSourcesBoolExp>;
  walletTransactionsSourcesAggregate?: InputMaybe<WalletTransactionsSourcesAggregateBoolExp>;
};

/** unique or primary key constraints on table "wallets_wallettransaction" */
export enum WalletTransactionConstraint {
  /** unique or primary key constraint on columns "payment_id" */
  WalletsWallettransactionPaymentIdKey = "wallets_wallettransaction_payment_id_key",
  /** unique or primary key constraint on columns "id" */
  WalletsWallettransactionPkey = "wallets_wallettransaction_pkey",
  /** unique or primary key constraint on columns "refund_id" */
  WalletsWallettransactionRefundIdKey = "wallets_wallettransaction_refund_id_key",
}

/** input type for incrementing numeric columns in table "wallets_wallettransaction" */
export type WalletTransactionIncInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  refundId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletCreditTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** input type for inserting data into table "wallets_wallettransaction" */
export type WalletTransactionInsertInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  client?: InputMaybe<ClientObjRelInsertInput>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  creditType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoice?: InputMaybe<InvoiceObjRelInsertInput>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  payment?: InputMaybe<PaymentObjRelInsertInput>;
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  product?: InputMaybe<ProductObjRelInsertInput>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  refund?: InputMaybe<RefundObjRelInsertInput>;
  refundId?: InputMaybe<Scalars["bigint"]["input"]>;
  resourceType?: InputMaybe<Scalars["String"]["input"]>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemObjRelInsertInput>;
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProduct?: InputMaybe<MedspaServiceProductObjRelInsertInput>;
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletCreditLine?: InputMaybe<WalletCreditLineObjRelInsertInput>;
  walletCreditType?: InputMaybe<WalletCreditTypeObjRelInsertInput>;
  walletCreditTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletTransactionsSources?: InputMaybe<WalletTransactionsSourcesArrRelInsertInput>;
};

/** aggregate max on columns */
export type WalletTransactionMaxFields = {
  __typename?: "walletTransactionMaxFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  creditType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  refundId?: Maybe<Scalars["bigint"]["output"]>;
  resourceType?: Maybe<Scalars["String"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by max() on columns of table "wallets_wallettransaction" */
export type WalletTransactionMaxOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  creditType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WalletTransactionMinFields = {
  __typename?: "walletTransactionMinFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  created?: Maybe<Scalars["timestamptz"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  creditType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  modified?: Maybe<Scalars["timestamptz"]["output"]>;
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  refundId?: Maybe<Scalars["bigint"]["output"]>;
  resourceType?: Maybe<Scalars["String"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by min() on columns of table "wallets_wallettransaction" */
export type WalletTransactionMinOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  creditType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "wallets_wallettransaction" */
export type WalletTransactionMutationResponse = {
  __typename?: "walletTransactionMutationResponse";
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<WalletTransaction>;
};

/** input type for inserting object relation for remote table "wallets_wallettransaction" */
export type WalletTransactionObjRelInsertInput = {
  data: WalletTransactionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WalletTransactionOnConflict>;
};

/** on_conflict condition type for table "wallets_wallettransaction" */
export type WalletTransactionOnConflict = {
  constraint: WalletTransactionConstraint;
  updateColumns?: Array<WalletTransactionUpdateColumn>;
  where?: InputMaybe<WalletTransactionBoolExp>;
};

/** Ordering options when selecting data from "wallets_wallettransaction". */
export type WalletTransactionOrderBy = {
  amount?: InputMaybe<OrderBy>;
  client?: InputMaybe<ClientOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  created?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  creditType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  modified?: InputMaybe<OrderBy>;
  payment?: InputMaybe<PaymentOrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  product?: InputMaybe<ProductOrderBy>;
  productId?: InputMaybe<OrderBy>;
  refund?: InputMaybe<RefundOrderBy>;
  refundId?: InputMaybe<OrderBy>;
  resourceType?: InputMaybe<OrderBy>;
  serviceMenuItem?: InputMaybe<MedspaServiceMenuItemOrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProduct?: InputMaybe<MedspaServiceProductOrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditLine?: InputMaybe<WalletCreditLineOrderBy>;
  walletCreditType?: InputMaybe<WalletCreditTypeOrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
  walletTransactionsSourcesAggregate?: InputMaybe<WalletTransactionsSourcesAggregateOrderBy>;
};

/** primary key columns input for table: wallets_wallettransaction */
export type WalletTransactionPkColumnsInput = {
  id: Scalars["bigint"]["input"];
};

/** select columns of table "wallets_wallettransaction" */
export enum WalletTransactionSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  CreditType = "creditType",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  PaymentId = "paymentId",
  /** column name */
  ProductId = "productId",
  /** column name */
  RefundId = "refundId",
  /** column name */
  ResourceType = "resourceType",
  /** column name */
  ServiceMenuItemId = "serviceMenuItemId",
  /** column name */
  ServiceProductId = "serviceProductId",
  /** column name */
  WalletCreditTypeId = "walletCreditTypeId",
}

/** input type for updating data in table "wallets_wallettransaction" */
export type WalletTransactionSetInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  creditType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  refundId?: InputMaybe<Scalars["bigint"]["input"]>;
  resourceType?: InputMaybe<Scalars["String"]["input"]>;
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletCreditTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

export type WalletTransactionSourceTmpAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WalletTransactionSourceTmpSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<WalletTransactionSourceTmpBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate stddev on columns */
export type WalletTransactionStddevFields = {
  __typename?: "walletTransactionStddevFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  refundId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "wallets_wallettransaction" */
export type WalletTransactionStddevOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type WalletTransactionStddevPopFields = {
  __typename?: "walletTransactionStddevPopFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  refundId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevPop() on columns of table "wallets_wallettransaction" */
export type WalletTransactionStddevPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type WalletTransactionStddevSampFields = {
  __typename?: "walletTransactionStddevSampFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  refundId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddevSamp() on columns of table "wallets_wallettransaction" */
export type WalletTransactionStddevSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "walletTransaction" */
export type WalletTransactionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WalletTransactionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WalletTransactionStreamCursorValueInput = {
  amount?: InputMaybe<Scalars["numeric"]["input"]>;
  clientId?: InputMaybe<Scalars["bigint"]["input"]>;
  created?: InputMaybe<Scalars["timestamptz"]["input"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<Scalars["bigint"]["input"]>;
  creditType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["bigint"]["input"]>;
  invoiceId?: InputMaybe<Scalars["bigint"]["input"]>;
  modified?: InputMaybe<Scalars["timestamptz"]["input"]>;
  paymentId?: InputMaybe<Scalars["bigint"]["input"]>;
  productId?: InputMaybe<Scalars["bigint"]["input"]>;
  refundId?: InputMaybe<Scalars["bigint"]["input"]>;
  resourceType?: InputMaybe<Scalars["String"]["input"]>;
  serviceMenuItemId?: InputMaybe<Scalars["bigint"]["input"]>;
  serviceProductId?: InputMaybe<Scalars["bigint"]["input"]>;
  walletCreditTypeId?: InputMaybe<Scalars["bigint"]["input"]>;
};

/** aggregate sum on columns */
export type WalletTransactionSumFields = {
  __typename?: "walletTransactionSumFields";
  amount?: Maybe<Scalars["numeric"]["output"]>;
  clientId?: Maybe<Scalars["bigint"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["bigint"]["output"]>;
  id?: Maybe<Scalars["bigint"]["output"]>;
  invoiceId?: Maybe<Scalars["bigint"]["output"]>;
  paymentId?: Maybe<Scalars["bigint"]["output"]>;
  productId?: Maybe<Scalars["bigint"]["output"]>;
  refundId?: Maybe<Scalars["bigint"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["bigint"]["output"]>;
  serviceProductId?: Maybe<Scalars["bigint"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["bigint"]["output"]>;
};

/** order by sum() on columns of table "wallets_wallettransaction" */
export type WalletTransactionSumOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** update columns of table "wallets_wallettransaction" */
export enum WalletTransactionUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  ClientId = "clientId",
  /** column name */
  Created = "created",
  /** column name */
  CreatedById = "createdById",
  /** column name */
  CreditType = "creditType",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  Modified = "modified",
  /** column name */
  PaymentId = "paymentId",
  /** column name */
  ProductId = "productId",
  /** column name */
  RefundId = "refundId",
  /** column name */
  ResourceType = "resourceType",
  /** column name */
  ServiceMenuItemId = "serviceMenuItemId",
  /** column name */
  ServiceProductId = "serviceProductId",
  /** column name */
  WalletCreditTypeId = "walletCreditTypeId",
}

export type WalletTransactionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WalletTransactionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WalletTransactionSetInput>;
  /** filter the rows which have to be updated */
  where: WalletTransactionBoolExp;
};

/** aggregate varPop on columns */
export type WalletTransactionVarPopFields = {
  __typename?: "walletTransactionVarPopFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  refundId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varPop() on columns of table "wallets_wallettransaction" */
export type WalletTransactionVarPopOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type WalletTransactionVarSampFields = {
  __typename?: "walletTransactionVarSampFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  refundId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by varSamp() on columns of table "wallets_wallettransaction" */
export type WalletTransactionVarSampOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WalletTransactionVarianceFields = {
  __typename?: "walletTransactionVarianceFields";
  amount?: Maybe<Scalars["Float"]["output"]>;
  clientId?: Maybe<Scalars["Float"]["output"]>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  invoiceId?: Maybe<Scalars["Float"]["output"]>;
  paymentId?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  refundId?: Maybe<Scalars["Float"]["output"]>;
  serviceMenuItemId?: Maybe<Scalars["Float"]["output"]>;
  serviceProductId?: Maybe<Scalars["Float"]["output"]>;
  walletCreditTypeId?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "wallets_wallettransaction" */
export type WalletTransactionVarianceOrderBy = {
  amount?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  /** Provider that created this wallet transactions. See wallet_transaction_source_tmp for more details */
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  productId?: InputMaybe<OrderBy>;
  refundId?: InputMaybe<OrderBy>;
  serviceMenuItemId?: InputMaybe<OrderBy>;
  serviceProductId?: InputMaybe<OrderBy>;
  walletCreditTypeId?: InputMaybe<OrderBy>;
};

export type WalletTransactionsSourcesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WalletTransactionsSourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<WalletTransactionsSourcesBoolExp>;
  predicate: IntComparisonExp;
};
