// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type CreateAppointmentMutationVariables = Types.Exact<{
  services?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.Scalars["BigInt"]["input"]>>
    | Types.InputMaybe<Types.Scalars["BigInt"]["input"]>
  >;
  startTime?: Types.InputMaybe<Types.Scalars["DateTime"]["input"]>;
  endTime?: Types.InputMaybe<Types.Scalars["DateTime"]["input"]>;
  note?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  providerId?: Types.InputMaybe<Types.Scalars["BigInt"]["input"]>;
  clientId?: Types.InputMaybe<Types.Scalars["BigInt"]["input"]>;
  visitType?: Types.InputMaybe<Types.TelehealthType>;
  videoLink?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  visitDetails?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type CreateAppointmentMutation = {
  __typename?: "mutation_root";
  createSchedulingAppointment?: {
    __typename?: "CreateAppointment";
    message?: string | null;
    ok?: boolean | null;
    visitId?: string | null;
  } | null;
};

export const CreateAppointmentDocument = gql`
  mutation createAppointment(
    $services: [BigInt]
    $startTime: DateTime
    $endTime: DateTime
    $note: String
    $providerId: BigInt
    $clientId: BigInt
    $visitType: TelehealthType
    $videoLink: String
    $visitDetails: String
  ) {
    createSchedulingAppointment(
      endTime: $endTime
      note: $note
      providerId: $providerId
      clientId: $clientId
      serviceMenuItemIds: $services
      startTime: $startTime
      visitType: $visitType
      videoLink: $videoLink
      visitDetails: $visitDetails
    ) {
      message
      ok
      visitId
    }
  }
`;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      services: // value for 'services'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      note: // value for 'note'
 *      providerId: // value for 'providerId'
 *      clientId: // value for 'clientId'
 *      visitType: // value for 'visitType'
 *      videoLink: // value for 'videoLink'
 *      visitDetails: // value for 'visitDetails'
 *   },
 * });
 */
export function useCreateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >(CreateAppointmentDocument, options);
}
export type CreateAppointmentMutationHookResult = ReturnType<
  typeof useCreateAppointmentMutation
>;
export type CreateAppointmentMutationResult =
  Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;
