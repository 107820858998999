import { Stack, Typography } from "@mui/material";
import React from "react";
import DropdownMenu, {
  DropdownMenuItems,
} from "@/components/common/dropdownMenu/dropdownMenu";
import { MenuHorizontalIcon } from "@/components/common/icons";
import TelehealthDetailsForm, {
  TelehealthDetailsFormProps,
} from "@/components/serviceFlow/visits/telehealthDetailsForm";
import { PRIMARY } from "@/config/mui/colorPalette";

export type TelehealthDetailsSectionProps = {
  telehealthDropdownActions: DropdownMenuItems;
} & TelehealthDetailsFormProps;

export default function TelehealthDetailsSection({
  telehealthDropdownActions,
  ...telehealthDetailsFormProps
}: TelehealthDetailsSectionProps) {
  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          height: "24px",
        }}
      >
        <Typography
          variant="labelTiny"
          sx={{
            color: "text.secondary",
          }}
        >
          Telehealth details
        </Typography>
        <DropdownMenu
          type={null}
          buttonVariant="icon"
          icon={<MenuHorizontalIcon color={PRIMARY} />}
          menuItems={telehealthDropdownActions}
          ButtonProps={{
            sx: {
              position: "relative",
              right: "-8px",
            },
          }}
        />
      </Stack>
      <TelehealthDetailsForm {...telehealthDetailsFormProps} />
    </Stack>
  );
}
