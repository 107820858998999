import { Typography, useTheme, Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { isAfter } from "date-fns";
import DatePicker, {
  DatePickerProps,
} from "@/components/common/datetimePicker/DatePicker";
import { GREY } from "@/config/mui/colorPalette";

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

type DateRangePickerProps = {
  selectedRange: DateRange;
  onRangeChange: (range: DateRange) => void;
  allowOneDate?: boolean;
  slotProps?: DatePickerProps["slotProps"];
};

function DateRangePicker({
  selectedRange,
  onRangeChange,
  allowOneDate,
  slotProps,
}: DateRangePickerProps) {
  const theme = useTheme();

  const handleStartDateChange = (value: unknown) => {
    const startDate = value as Date;
    const currentEndDate = selectedRange.endDate;

    const getEndDate = () => {
      return isAfter(startDate, currentEndDate) ? null : currentEndDate;
    };

    if (allowOneDate) {
      return onRangeChange({
        startDate,
        endDate: currentEndDate,
      });
    }

    onRangeChange({
      startDate,
      endDate: currentEndDate ? getEndDate() : startDate,
    });
  };

  const handleEndDateChange = (value: unknown) => {
    const endDate = value as Date;
    const startDate = selectedRange.startDate;

    onRangeChange({ startDate, endDate });
  };

  const { startDate, endDate } = selectedRange;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: "center",
          pt: 1,
        }}
      >
        <DatePicker
          label="Start date"
          sx={{ flex: 1 }}
          value={startDate}
          onAccept={handleStartDateChange}
          slotProps={slotProps}
          maxDate={endDate ? endDate : undefined}
        />
        <Typography
          sx={{
            color: theme.palette.gray.main,
          }}
        >
          {" "}
          –{" "}
        </Typography>
        <DatePicker
          label="End date"
          sx={{
            flex: 1,
            "& div.Mui-disabled": {
              background: GREY[5],
            },
          }}
          value={endDate}
          onAccept={handleEndDateChange}
          disabled={!startDate && !allowOneDate}
          minDate={startDate}
          slotProps={slotProps}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default DateRangePicker;
