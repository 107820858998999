import { capitalize } from "@mui/material";
import { InvoiceStatus, InvoiceStatusColors, VisitStatus } from "@/types";

export const shouldShowInvoiceChip = (
  status: VisitStatus,
  invoiceStatus: InvoiceStatus
) => {
  if (!status || !invoiceStatus) return false;

  const visitCancelled = status === VisitStatus.CANCELLED;

  if (visitCancelled) return false;

  return true;
};

export const getInvoiceLabelAndColor = (
  status: InvoiceStatus,
  withPrefix = true
) => {
  const displayStatus =
    status === InvoiceStatus.DRAFT ? InvoiceStatus.UNPAID : status;

  const capitalizedStatus = capitalize(displayStatus.replace("_", " "));

  return {
    label: withPrefix ? `Invoice: ${capitalizedStatus}` : capitalizedStatus,
    baseColor: InvoiceStatusColors[displayStatus],
  };
};
