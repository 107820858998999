import { useEffect } from "react";
import { MessageListenerCallback } from "@/hooks/moxieMobile/useMoxieMobileMessageListener";
import { cacheEvents, CacheEvents } from "@/lib/moxieMobile/MoxieMobileCache";

interface Params {
  addMessageListener: MessageListenerCallback;
  removeMessageListener: MessageListenerCallback;
}

const useCacheOperation = (
  type: keyof typeof CacheEvents,
  { addMessageListener, removeMessageListener }: Params
) => {
  useEffect(() => {
    const handleEvent = (payload: unknown) => {
      cacheEvents.emit(type, payload);
    };

    addMessageListener(type, handleEvent);

    return () => {
      removeMessageListener(type, handleEvent);
    };
  }, [type, addMessageListener, removeMessageListener]);
};

export const useMoxieMobileCacheListener = (params: Params) => {
  useCacheOperation("onCacheGc", params);
  useCacheOperation("onCacheEvict", params);
  useCacheOperation("onCacheWrite", params);
  useCacheOperation("onCacheModify", params);
  useCacheOperation("onCacheRestored", params);
};
