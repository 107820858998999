// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type CreateShortUrlMutationVariables = Types.Exact<{
  fullUrl: Types.Scalars["String"]["input"];
}>;

export type CreateShortUrlMutation = {
  __typename?: "mutation_root";
  createShortMoxieUrl?: {
    __typename?: "CreateShortMoxieUrl";
    uuid?: string | null;
    fullUrl?: string | null;
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const CreateShortUrlDocument = gql`
  mutation CreateShortUrl($fullUrl: String!) {
    createShortMoxieUrl(fullUrl: $fullUrl) {
      uuid
      fullUrl
      ok
      message
    }
  }
`;
export type CreateShortUrlMutationFn = Apollo.MutationFunction<
  CreateShortUrlMutation,
  CreateShortUrlMutationVariables
>;

/**
 * __useCreateShortUrlMutation__
 *
 * To run a mutation, you first call `useCreateShortUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShortUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShortUrlMutation, { data, loading, error }] = useCreateShortUrlMutation({
 *   variables: {
 *      fullUrl: // value for 'fullUrl'
 *   },
 * });
 */
export function useCreateShortUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShortUrlMutation,
    CreateShortUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateShortUrlMutation,
    CreateShortUrlMutationVariables
  >(CreateShortUrlDocument, options);
}
export type CreateShortUrlMutationHookResult = ReturnType<
  typeof useCreateShortUrlMutation
>;
export type CreateShortUrlMutationResult =
  Apollo.MutationResult<CreateShortUrlMutation>;
export type CreateShortUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateShortUrlMutation,
  CreateShortUrlMutationVariables
>;
