export type ClientDetailsInput = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
  ageConsent?: boolean;
};

export enum BookingStep {
  SERVICES = "SERVICES",
  PROVIDER_SELECTION = "PROVIDER_SELECTION",
  AVAILABILITY = "AVAILABILITY",
  CLIENT_DETAILS = "CLIENT_DETAILS",
  BOOKED = "BOOKED",
  COLLECT_CREDIT_CARD_INFO = "COLLECT_CREDIT_CARD_INFO",
  FRONT_DESK_BOOKING = "FRONT_DESK_BOOKING",
  PHONE_NUMBER = "PHONE_NUMBER",
  CODE_INPUT = "CODE_INPUT",
  PHONE_CONFLICT = "PHONE_CONFLICT",
  RETURNING_CLIENT = "RETURNING_CLIENT",
  RETURNING_CLIENT_EDIT_DETAILS = "RETURNING_CLIENT_EDIT_DETAILS",
  RETURNING_CLIENT_EDIT_CARD = "RETURNING_CLIENT_EDIT_CARD",
}

export type TimeSlot = {
  endStr: string;
  startStr: string;
};
