import { hasRole, useUser } from "@/auth/useUser";
import { useOverdueChartsPerProviderSubscription } from "@/graphql/subscriptions/visit/overdueChartsPerProvider.graphql.types";
import { PROVIDER } from "@/types";
import { NotificationsDot } from "./notificationsDot";

export const OverdueChartsNotificationsDot = () => {
  const { user } = useUser();
  const isProvider = hasRole(user, [PROVIDER]);

  const { data } = useOverdueChartsPerProviderSubscription({
    variables: {
      id: user?.id,
    },
    skip: !isProvider,
  });

  if (!data) return null;

  const overdueChartsCount = data.visitAggregate.aggregate.count;

  if (overdueChartsCount === 0) return null;

  return <NotificationsDot count={overdueChartsCount} />;
};
