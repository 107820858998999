import { Box, Skeleton, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import Image from "next/image";
import { DATE_FORMATS, TIME_FORMATS } from "@/config";

type Props = {
  signedAt: string;
  signatureUrl?: string;
  loading?: boolean;
  signedText?: string;
  displayName?: string;
  size?: number;
  withTime?: boolean;
};

const SIGNATURE_SIZE = 280;

export default function Signature({
  signatureUrl,
  loading,
  signedAt,
  signedText = "Signed",
  displayName = "",
  size = SIGNATURE_SIZE,
  withTime = false,
}: Props) {
  const signedDate = format(parseISO(signedAt), DATE_FORMATS.DATE_PICKER);
  const signedTime = format(parseISO(signedAt), TIME_FORMATS.TILE);

  return (
    <Box>
      <Typography
        sx={{
          color: "secondary.main",
          py: 3,
        }}
      >
        {signedText}: {signedDate} {withTime ? `at ${signedTime}` : ""}
        <br />
        <span data-ls-disabled data-dd-privacy="mask">
          {displayName}
        </span>
      </Typography>
      {loading && <Skeleton variant="rectangular" width={size} height={size} />}
      {signatureUrl && (
        <Image
          src={signatureUrl}
          alt="signature"
          width={size}
          height={size}
          unoptimized
          style={{
            objectFit: "scale-down",
            objectPosition: "left top",
          }}
        />
      )}
    </Box>
  );
}
