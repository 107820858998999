// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SendNoShowFeeCommunicationMutationVariables = Types.Exact<{
  visitId: Types.Scalars["BigInt"]["input"];
}>;

export type SendNoShowFeeCommunicationMutation = {
  __typename?: "mutation_root";
  sendNoShowFeeCommunication?: {
    __typename?: "SendNoShowFeeCommunication";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const SendNoShowFeeCommunicationDocument = gql`
  mutation SendNoShowFeeCommunication($visitId: BigInt!) {
    sendNoShowFeeCommunication(visitId: $visitId) {
      ok
      message
    }
  }
`;
export type SendNoShowFeeCommunicationMutationFn = Apollo.MutationFunction<
  SendNoShowFeeCommunicationMutation,
  SendNoShowFeeCommunicationMutationVariables
>;

/**
 * __useSendNoShowFeeCommunicationMutation__
 *
 * To run a mutation, you first call `useSendNoShowFeeCommunicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNoShowFeeCommunicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNoShowFeeCommunicationMutation, { data, loading, error }] = useSendNoShowFeeCommunicationMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useSendNoShowFeeCommunicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendNoShowFeeCommunicationMutation,
    SendNoShowFeeCommunicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendNoShowFeeCommunicationMutation,
    SendNoShowFeeCommunicationMutationVariables
  >(SendNoShowFeeCommunicationDocument, options);
}
export type SendNoShowFeeCommunicationMutationHookResult = ReturnType<
  typeof useSendNoShowFeeCommunicationMutation
>;
export type SendNoShowFeeCommunicationMutationResult =
  Apollo.MutationResult<SendNoShowFeeCommunicationMutation>;
export type SendNoShowFeeCommunicationMutationOptions =
  Apollo.BaseMutationOptions<
    SendNoShowFeeCommunicationMutation,
    SendNoShowFeeCommunicationMutationVariables
  >;
