import { createContext, PropsWithChildren, useContext } from "react";
import { useMoxieMobileCacheListener } from "@/hooks/moxieMobile/useMoxieMobileCacheListener";
import {
  MessageListenerCallback,
  useMoxieMobileListener,
} from "@/hooks/moxieMobile/useMoxieMobileMessageListener";
import { useMoxieMobileNavigation } from "@/hooks/moxieMobile/useMoxieMobileNavigation";

export interface MobileAppContextValue {
  isMoxieMobile: boolean;
  addMessageListener: MessageListenerCallback;
  removeMessageListener: MessageListenerCallback;
}

const MobileAppContext = createContext<MobileAppContextValue | null>(null);

export const MobileAppContextProvider = ({
  isMoxieMobile,
  children,
}: PropsWithChildren<{ isMoxieMobile: boolean }>) => {
  const moxieMobileListener = useMoxieMobileListener(isMoxieMobile);
  useMoxieMobileNavigation(moxieMobileListener);
  useMoxieMobileCacheListener(moxieMobileListener);

  return (
    <MobileAppContext.Provider value={moxieMobileListener}>
      {children}
    </MobileAppContext.Provider>
  );
};

export const useMobileAppContext = () => {
  const value = useContext(MobileAppContext);
  if (!value) {
    throw new Error(
      "useMobileAppContext must be used within MobileAppContextProvider"
    );
  }
  return value as MobileAppContextValue;
};
