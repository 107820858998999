import { useRouter } from "next/router";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { DropdownAction } from "@/components/dropdown/dropdown";
import { useMedspaId } from "@/hooks/common/useMedspaId";
import { useGfeReviewTimestamps } from "@/hooks/review/gfe/useGfeReviewTimestamps";
import { useIsEligibleForSelfReviewGfe } from "@/hooks/user/useIsEligibleForSelfReviewGfe";

type PendingReview =
  | { id: string; asyncReviewedAt?: string }
  | null
  | undefined;

const useStartReview = (
  pendingReview: PendingReview,
  lastReviewId: string,
  clientId: string,
  backUrl: string
) => {
  const { push } = useRouter();
  const medspa = useMedspaId();
  const pendingReviewId = pendingReview?.id;

  const hasAnyReview = pendingReviewId || lastReviewId;
  const isEdit =
    (!pendingReviewId && lastReviewId) || !!pendingReview?.asyncReviewedAt;

  const { startGfeReviewProcess } = useGfeReviewTimestamps(
    pendingReviewId ? pendingReviewId : lastReviewId
  );

  const startReview = () => {
    startGfeReviewProcess();

    const formType = isEdit ? "edit" : "new";
    push(
      `/${medspa}/review/gfe/${clientId}/form/${formType}${
        backUrl ? `?backUrl=${backUrl}` : ""
      }`
    );
  };

  return {
    isEdit,
    hasAnyReview,
    startReview,
  };
};

export const useStartReviewDropdownItem = (
  pendingReview: PendingReview,
  lastReviewId: string,
  clientId: string,
  backUrl: string
): DropdownMenuItem => {
  const isProviderEligibleForReview = useIsEligibleForSelfReviewGfe();

  const { isEdit, hasAnyReview, startReview } = useStartReview(
    pendingReview,
    lastReviewId,
    clientId,
    backUrl
  );

  return {
    component: `${isEdit ? "Edit" : "Start"} GFE review`,
    onClick: startReview,
    disabled: !(hasAnyReview && isProviderEligibleForReview),
  };
};

export const useStartReviewDropdownAction = (
  pendingReview: PendingReview,
  lastReviewId: string,
  clientId: string,
  backUrl?: string
): DropdownAction => {
  const { isEdit, startReview } = useStartReview(
    pendingReview,
    lastReviewId,
    clientId,
    backUrl
  );

  return {
    title: `${isEdit ? "Edit" : "Start"} GFE review`,
    action: startReview,
  };
};
