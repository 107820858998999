import Box, { type BoxProps } from "@mui/material/Box";
import React from "react";

const DrawerContent = ({
  children,
  ...props
}: React.PropsWithChildren<Partial<BoxProps>>) => (
  <Box
    sx={[
      {
        flex: 1,
        overflow: "auto",
        p: (theme) => theme.spacing(2),
        overflowY: "auto",
      },
      ...(Array.isArray(props.sx) ? props.sx : [props?.sx]),
    ]}
    {...props}
  >
    {children}
  </Box>
);

export default DrawerContent;
