import { useTheme } from "@mui/material";
import { isValid, set } from "date-fns";
import React from "react";
import { Clock } from "react-swm-icon-pack";
import TimePicker, {
  type TimePickerProps,
} from "@/components/common/datetimePicker/TimePicker";
import { GREY } from "@/config/mui/colorPalette";

type Props = Omit<TimePickerProps, "onAccept"> & {
  value?: Date | null;
  onTimeChange?: React.Dispatch<Date>;
};

const TimeSelectorTimePicker = ({ value, onTimeChange, ...props }: Props) => {
  const theme = useTheme();
  const handleTimeChange = (newDate: Date, prevDate: Date) => {
    onTimeChange?.(
      set(new Date(prevDate), {
        hours: newDate.getHours(),
        minutes: newDate.getMinutes(),
        seconds: newDate.getSeconds(),
      })
    );
  };
  return (
    <TimePicker
      {...props}
      slotProps={{
        textField: {
          InputProps: {
            startAdornment: (
              <Clock
                size={24}
                color={GREY[50]}
                style={{ marginRight: theme.spacing(1) }}
              />
            ),
          },
        },
      }}
      value={value}
      onAccept={(val) => {
        const date = val as Date;

        if (isValid(date)) {
          handleTimeChange(date, value);
        }
      }}
    />
  );
};

export default TimeSelectorTimePicker;
