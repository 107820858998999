import dynamic from "next/dynamic";
import { ComponentType, CSSProperties } from "react";

export type Icon = {
  color?: string;
  size?: number | string;
  strokeWidth?: number | string;
  style?: CSSProperties;
  set?: "broken" | "curved" | "duotone" | "outline";
  onClick?: () => void;
};

export const CalendarIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Calendar),
  {
    ssr: false,
  }
);

export const CalendarClockIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.CalendarClock),
  {
    ssr: false,
  }
);

export const CalendarCheckIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.CalendarCheck),
  {
    ssr: false,
  }
);

export const CalendarCrossIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.CalendarCross),
  {
    ssr: false,
  }
);

export const CalendarUserIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.CalendarUser),
  {
    ssr: false,
  }
);

export const UsersIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Users),
  {
    ssr: false,
  }
);

export const UserIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.User1),
  {
    ssr: false,
  }
);

export const SettingsIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Settings),
  {
    ssr: false,
  }
);

export const PackageIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Package),
  {
    ssr: false,
  }
);

export const QuestionCircleIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.QuestionCircle),
  {
    ssr: false,
  }
);

export const ReportsIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ChartVertical),
  {
    ssr: false,
  }
);
export const TrendingUpIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.TrendingUp),
  {
    ssr: false,
  }
);
export const CoinIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Coin),
  {
    ssr: false,
  }
);
export const TagIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Tag),
  {
    ssr: false,
  }
);

export const MenuVerticalIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.MenuVertical),
  {
    ssr: false,
  }
);

export const MenuHorizontalIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.MenuHorizontal),
  {
    ssr: false,
  }
);

export const ChevronSmallDownIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ChevronSmallDown),
  {
    ssr: false,
  }
);

export const OfferIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Offer),
  {
    ssr: false,
  }
);

export const ChevronSmallUpIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ChevronSmallUp),
  {
    ssr: false,
  }
);

export const ChevronSmallRightIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ChevronSmallRight),
  {
    ssr: false,
  }
);

export const ChevronSmallLeftIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ChevronSmallLeft),
  {
    ssr: false,
  }
);

export const ArrowLeftIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ArrowLeft),
  {
    ssr: false,
  }
);

export const ArrowRightIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ArrowRight),
  {
    ssr: false,
  }
);

export const ArrowSmallUpIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ArrowSmallUp),
  {
    ssr: false,
  }
);

export const ArrowSmallDownIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ArrowSmallDown),
  {
    ssr: false,
  }
);

export const PlusCircleIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.PlusCircle),
  {
    ssr: false,
  }
);

export const MinusCircleIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.MinusCircle),
  {
    ssr: false,
  }
);

export const CrossCircleIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.CrossCircle),
  {
    ssr: false,
  }
);

export const PlusSmallIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.PlusSmall),
  {
    ssr: false,
  }
);

export const MedsIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Meds),
  {
    ssr: false,
  }
);

export const MedkitIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Medkit),
  {
    ssr: false,
  }
);

export const DocumentIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.DocumentFilled),
  {
    ssr: false,
  }
);

export const SearchIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Search),
  {
    ssr: false,
  }
);

export const PhoneIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Phone),
  {
    ssr: false,
  }
);

export const ListRightIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ListRight),
  {
    ssr: false,
  }
);

export const PinpaperCrossIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.PinpaperCross),
  {
    ssr: false,
  }
);

export const PinpaperFilledIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.PinpaperFilled),
  {
    ssr: false,
  }
);

export const UserWarningIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.UserWarning),
  {
    ssr: false,
  }
);

export const TrashIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Trash2),
  {
    ssr: false,
  }
);

export const CheckIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Check),
  {
    ssr: false,
  }
);

export const CrossIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Cross),
  {
    ssr: false,
  }
);

export const PlusIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Plus),
  {
    ssr: false,
  }
);

export const EditIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Edit1),
  {
    ssr: false,
  }
);

export const Edit2Icon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Edit2),
  {
    ssr: false,
  }
);

export const EditTemplateIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Edit4),
  {
    ssr: false,
  }
);

export const HomeIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Home1),
  {
    ssr: false,
  }
);

export const CheckSmallIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.CheckSmall),
  {
    ssr: false,
  }
);

export const CrossSmallIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.CrossSmall),
  {
    ssr: false,
  }
);

export const MessageIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.MessageCircleDots),
  {
    ssr: false,
  }
);

export const MessageSquareIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.MessageSquareDots),
  {
    ssr: false,
  }
);

export const CheckCircleIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.CheckCircle),
  {
    ssr: false,
  }
);

export const UploadIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Upload),
  {
    ssr: false,
  }
);

export const EyeIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.EyeOpen),
  {
    ssr: false,
  }
);

export const SendIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Send2),
  {
    ssr: false,
  }
);

export const ListRight: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.ListRight),
  {
    ssr: false,
  }
);

export const ImageIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Image1),
  {
    ssr: false,
  }
);

export const WarningCircleIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.WarningCircle),
  {
    ssr: false,
  }
);

export const InfoCircleIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.InfoCircle),
  {
    ssr: false,
  }
);

export const InfoSmallIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.InfoSmall),
  {
    ssr: false,
  }
);

export const WarningSmallIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.WarningSmall),
  {
    ssr: false,
  }
);

export const CreditCardIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Creditcard),
  {
    ssr: false,
  }
);

export const WalletIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Wallet),
  {
    ssr: false,
  }
);

export const SaveIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Save),
  {
    ssr: false,
  }
);

export const DownloadIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Download),
  {
    ssr: false,
  }
);

export const ShareIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Share1),
  {
    ssr: false,
  }
);

export const SpeakerIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Speaker1),
  {
    ssr: false,
  }
);

export const GridIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Grid4),
  {
    ssr: false,
  }
);

export const MailIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Mail),
  {
    ssr: false,
  }
);

export const CartIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Cart4),
  {
    ssr: false,
  }
);

export const PlayIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Play),
  {
    ssr: false,
  }
);

export const Location1Icon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Location1),
  {
    ssr: false,
  }
);

export const ClockIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Clock),
  {
    ssr: false,
  }
);

export const AlarmIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Alarm),
  {
    ssr: false,
  }
);

export const CrownIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Crown2),
  {
    ssr: false,
  }
);

export const CardiologyIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Cardiology),
  {
    ssr: false,
  }
);

export const CamIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Cam),
  {
    ssr: false,
  }
);

export const CameraIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Camera),
  {
    ssr: false,
  }
);

export const CapsuleIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Capsule),
  {
    ssr: false,
  }
);

export const MinusSmallIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.MinusSmall),
  {
    ssr: false,
  }
);

export const LogoutIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Logout),
  {
    ssr: false,
  }
);

export const MenuHamburgerIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.MenuHamburger),
  {
    ssr: false,
  }
);

export const UserQuestionIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.UserQuestion),
  {
    ssr: false,
  }
);

export const NewScreenIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Newscreen),
  {
    ssr: false,
  }
);

export const ShieldCheckIcon: ComponentType<Icon> = dynamic(
  // It looks like ShieldCross and ShieldCheck are mixed up in the library.
  // That's why we are using ShieldCross inside so it actually looks like the checked one.
  () => import("react-swm-icon-pack").then((mod) => mod.ShieldCross),
  {
    ssr: false,
  }
);

export const HomeHospitalIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.HomeHospital),
  {
    ssr: false,
  }
);

export const WarningIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Warning),
  {
    ssr: false,
  }
);

export const AttachmentIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Attachment),
  {
    ssr: false,
  }
);

export const BellIcon: ComponentType<Icon> = dynamic(
  () => import("react-swm-icon-pack").then((mod) => mod.Bell),
  {
    ssr: false,
  }
);
