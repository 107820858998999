// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type LeaveConversationMutationVariables = Types.Exact<{
  conversationSid: Types.Scalars["String"]["input"];
  medspaId: Types.Scalars["Int"]["input"];
}>;

export type LeaveConversationMutation = {
  __typename?: "mutation_root";
  leaveTwilioConversation?: {
    __typename?: "LeaveTwilioConversation";
    message?: string | null;
    ok?: boolean | null;
  } | null;
};

export const LeaveConversationDocument = gql`
  mutation LeaveConversation($conversationSid: String!, $medspaId: Int!) {
    leaveTwilioConversation(
      conversationSid: $conversationSid
      medspaId: $medspaId
    ) {
      message
      ok
    }
  }
`;
export type LeaveConversationMutationFn = Apollo.MutationFunction<
  LeaveConversationMutation,
  LeaveConversationMutationVariables
>;

/**
 * __useLeaveConversationMutation__
 *
 * To run a mutation, you first call `useLeaveConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveConversationMutation, { data, loading, error }] = useLeaveConversationMutation({
 *   variables: {
 *      conversationSid: // value for 'conversationSid'
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useLeaveConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveConversationMutation,
    LeaveConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveConversationMutation,
    LeaveConversationMutationVariables
  >(LeaveConversationDocument, options);
}
export type LeaveConversationMutationHookResult = ReturnType<
  typeof useLeaveConversationMutation
>;
export type LeaveConversationMutationResult =
  Apollo.MutationResult<LeaveConversationMutation>;
export type LeaveConversationMutationOptions = Apollo.BaseMutationOptions<
  LeaveConversationMutation,
  LeaveConversationMutationVariables
>;
