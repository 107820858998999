// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type MarkDocumentAsNotRequiredMutationVariables = Types.Exact<{
  documentType: Types.Scalars["String"]["input"];
  medspaServiceOfferingId: Types.Scalars["bigint"]["input"];
  providerId: Types.Scalars["bigint"]["input"];
}>;

export type MarkDocumentAsNotRequiredMutation = {
  __typename?: "mutation_root";
  insertProviderNotRequireDocumentsOne?: {
    __typename?: "ProviderNotRequireDocuments";
    id: string;
  } | null;
};

export type MarkDocumentAsRequiredMutationVariables = Types.Exact<{
  documentType: Types.Scalars["String"]["input"];
  medspaServiceOfferingId: Types.Scalars["bigint"]["input"];
  providerId: Types.Scalars["bigint"]["input"];
}>;

export type MarkDocumentAsRequiredMutation = {
  __typename?: "mutation_root";
  deleteProviderNotRequireDocuments?: {
    __typename?: "ProviderNotRequireDocumentsMutationResponse";
    affectedRows: number;
  } | null;
};

export const MarkDocumentAsNotRequiredDocument = gql`
  mutation MarkDocumentAsNotRequired(
    $documentType: String!
    $medspaServiceOfferingId: bigint!
    $providerId: bigint!
  ) {
    insertProviderNotRequireDocumentsOne(
      object: {
        documentType: $documentType
        medspaServiceOfferingId: $medspaServiceOfferingId
        providerId: $providerId
      }
      onConflict: {
        constraint: provider_not_require_doc_provider_id_document_typ_0c49c880_uniq
        updateColumns: [created, modified]
      }
    ) {
      id
    }
  }
`;
export type MarkDocumentAsNotRequiredMutationFn = Apollo.MutationFunction<
  MarkDocumentAsNotRequiredMutation,
  MarkDocumentAsNotRequiredMutationVariables
>;

/**
 * __useMarkDocumentAsNotRequiredMutation__
 *
 * To run a mutation, you first call `useMarkDocumentAsNotRequiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDocumentAsNotRequiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDocumentAsNotRequiredMutation, { data, loading, error }] = useMarkDocumentAsNotRequiredMutation({
 *   variables: {
 *      documentType: // value for 'documentType'
 *      medspaServiceOfferingId: // value for 'medspaServiceOfferingId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useMarkDocumentAsNotRequiredMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkDocumentAsNotRequiredMutation,
    MarkDocumentAsNotRequiredMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkDocumentAsNotRequiredMutation,
    MarkDocumentAsNotRequiredMutationVariables
  >(MarkDocumentAsNotRequiredDocument, options);
}
export type MarkDocumentAsNotRequiredMutationHookResult = ReturnType<
  typeof useMarkDocumentAsNotRequiredMutation
>;
export type MarkDocumentAsNotRequiredMutationResult =
  Apollo.MutationResult<MarkDocumentAsNotRequiredMutation>;
export type MarkDocumentAsNotRequiredMutationOptions =
  Apollo.BaseMutationOptions<
    MarkDocumentAsNotRequiredMutation,
    MarkDocumentAsNotRequiredMutationVariables
  >;
export const MarkDocumentAsRequiredDocument = gql`
  mutation MarkDocumentAsRequired(
    $documentType: String!
    $medspaServiceOfferingId: bigint!
    $providerId: bigint!
  ) {
    deleteProviderNotRequireDocuments(
      where: {
        documentType: { _eq: $documentType }
        medspaServiceOfferingId: { _eq: $medspaServiceOfferingId }
        providerId: { _eq: $providerId }
      }
    ) {
      affectedRows
    }
  }
`;
export type MarkDocumentAsRequiredMutationFn = Apollo.MutationFunction<
  MarkDocumentAsRequiredMutation,
  MarkDocumentAsRequiredMutationVariables
>;

/**
 * __useMarkDocumentAsRequiredMutation__
 *
 * To run a mutation, you first call `useMarkDocumentAsRequiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDocumentAsRequiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDocumentAsRequiredMutation, { data, loading, error }] = useMarkDocumentAsRequiredMutation({
 *   variables: {
 *      documentType: // value for 'documentType'
 *      medspaServiceOfferingId: // value for 'medspaServiceOfferingId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useMarkDocumentAsRequiredMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkDocumentAsRequiredMutation,
    MarkDocumentAsRequiredMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkDocumentAsRequiredMutation,
    MarkDocumentAsRequiredMutationVariables
  >(MarkDocumentAsRequiredDocument, options);
}
export type MarkDocumentAsRequiredMutationHookResult = ReturnType<
  typeof useMarkDocumentAsRequiredMutation
>;
export type MarkDocumentAsRequiredMutationResult =
  Apollo.MutationResult<MarkDocumentAsRequiredMutation>;
export type MarkDocumentAsRequiredMutationOptions = Apollo.BaseMutationOptions<
  MarkDocumentAsRequiredMutation,
  MarkDocumentAsRequiredMutationVariables
>;
