import { Box, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { ReactNode, useState } from "react";
import {
  DROPDOWN_MENU_ITEM_SX,
  DROPDOWN_MENU_SX,
} from "@/components/common/dropdownMenu/dropdownMenu";
import IconCircleContainer from "@/components/common/icons/containers/iconCircleContainer";

type DropdownItem = {
  label: string;
  onClick: () => void;
};

type IconActionContainerProps = {
  icon: ReactNode;
  size: string;
  primaryColor: string;
  secondaryColor: string;
  actions: DropdownItem[];
  tooltipText?: string;
};

export default function ActionIconContainer({
  icon,
  size,
  primaryColor,
  secondaryColor,
  actions,
  tooltipText,
}: IconActionContainerProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: () => void) => {
    action();
    handleDropdownClose();
  };

  return (
    <Stack>
      <Tooltip
        title={tooltipText}
        placement="bottom"
        arrow
        slotProps={{
          popper: {
            sx: {
              "& .MuiTooltip-tooltip": {
                boxShadow: "0 4px 16px rgba(0, 0, 0, 0.16)",
              },
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleClick}
        >
          <IconCircleContainer
            size={size}
            icon={icon}
            color={secondaryColor}
            sx={{
              boxSizing: "border-box",
              ...(open && {
                border: `1px solid ${primaryColor}`,
              }),
            }}
          />
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleDropdownClose}
        aria-hidden={!open}
        slotProps={{
          paper: {
            sx: DROPDOWN_MENU_SX,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {actions.map(({ label, onClick }) => (
          <MenuItem
            key={label}
            onClick={() => handleAction(onClick)}
            sx={DROPDOWN_MENU_ITEM_SX}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}
