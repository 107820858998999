// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ChargeNoShowFeeMutationVariables = Types.Exact<{
  visitId: Types.Scalars["BigInt"]["input"];
}>;

export type ChargeNoShowFeeMutation = {
  __typename?: "mutation_root";
  chargeNoShowFee?: {
    __typename?: "ChargeNoShowFee";
    ok?: boolean | null;
    message?: string | null;
    selectedCardId?: string | null;
    paymentIntent?: {
      __typename?: "PaymentIntentType";
      id?: string | null;
      clientSecret?: string | null;
    } | null;
    payment?: { __typename?: "PaymentType"; id: string } | null;
    invoice?: { __typename?: "InvoiceType"; id: string } | null;
  } | null;
};

export const ChargeNoShowFeeDocument = gql`
  mutation ChargeNoShowFee($visitId: BigInt!) {
    chargeNoShowFee(visitId: $visitId) {
      ok
      message
      paymentIntent {
        id
        clientSecret
      }
      payment {
        id
      }
      invoice {
        id
      }
      selectedCardId
    }
  }
`;
export type ChargeNoShowFeeMutationFn = Apollo.MutationFunction<
  ChargeNoShowFeeMutation,
  ChargeNoShowFeeMutationVariables
>;

/**
 * __useChargeNoShowFeeMutation__
 *
 * To run a mutation, you first call `useChargeNoShowFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeNoShowFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeNoShowFeeMutation, { data, loading, error }] = useChargeNoShowFeeMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useChargeNoShowFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeNoShowFeeMutation,
    ChargeNoShowFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChargeNoShowFeeMutation,
    ChargeNoShowFeeMutationVariables
  >(ChargeNoShowFeeDocument, options);
}
export type ChargeNoShowFeeMutationHookResult = ReturnType<
  typeof useChargeNoShowFeeMutation
>;
export type ChargeNoShowFeeMutationResult =
  Apollo.MutationResult<ChargeNoShowFeeMutation>;
export type ChargeNoShowFeeMutationOptions = Apollo.BaseMutationOptions<
  ChargeNoShowFeeMutation,
  ChargeNoShowFeeMutationVariables
>;
