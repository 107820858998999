import { useMemo } from "react";
import { useUser } from "@/auth/useUser";
import { VIOLET } from "@/config/mui/colorPalette";
import { MedspaProvidersQuery } from "@/graphql/queries/medspaProviders.graphql.types";
import { ServiceMenuItemsBasicInfoQuery } from "@/graphql/queries/serviceMenuItems.graphql.types";
import { useVisitsAndAvailabilityEventsWithDateRangeQuery } from "@/graphql/queries/visit/visitsAndAvailabilityEventsWithDateRange.graphql.types";
import { ACTIVE_VISIT_STATUSES, ReviewStatus, VisitStatus } from "@/types";
import { ExtendedEventProps } from "@/types/calendarEventType";
import { getEndTime } from "@/utils/visit";
import useAvailabilityEvents from "./useAvailabilityEvents";
import { useDateToMedspaWeekRange } from "./useDateToMedspaWeekRange";
import useVisitEvents from "./useVisitEvents";

export default function useCreateViewCalendarEvents(
  start?: Date,
  end?: Date,
  provider?: MedspaProvidersQuery["medspaByPk"]["userMedspas"][number],
  availableServices?: ServiceMenuItemsBasicInfoQuery,
  client?: { firstName: string; lastName: string },
  excludeVisitId?: string,
  currentServicesLabel?: string
) {
  const { medspa } = useUser();
  const dateRange = useDateToMedspaWeekRange(start ?? new Date());

  const { data } = useVisitsAndAvailabilityEventsWithDateRangeQuery({
    variables: {
      medspaId: medspa,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      status: ACTIVE_VISIT_STATUSES,
      providers: [provider?.user.id],
      gfeStatusesExp: {},
      serviceDevicesExp: {},
    },
    skip: !start || !provider || !medspa || !dateRange,
    fetchPolicy: "cache-and-network",
  });

  const visitEvents = useVisitEvents(data?.visit);
  const { timeBlockEvents, specialShiftsEvents } = useAvailabilityEvents(
    data?.availabilityEvent
  );

  return useMemo(() => {
    const plannedVisits =
      visitEvents
        .filter(({ id }) => id !== excludeVisitId)
        .map(({ extendedProps, ...visit }, i) => {
          return {
            ...visit,
            end: visit.end || getEndTime(data.visit[i], availableServices),
            extendedProps: {
              ...extendedProps,
              showDropdown: false,
              clickable: false,
            },
          };
        }) || [];

    const timeBlockers = timeBlockEvents.map(({ extendedProps, ...event }) => ({
      ...event,
      extendedProps: {
        ...extendedProps,
        showDropdown: false,
        clickable: false,
      },
    }));

    const specialShifts = specialShiftsEvents.map(
      ({ extendedProps, ...event }) => ({
        ...event,
        extendedProps: {
          ...extendedProps,
          showDropdown: false,
        },
      })
    );

    const extendedProps: ExtendedEventProps = {
      isTimeBlock: false,
      isSpecialShift: false,
      showDropdown: false,
      status: VisitStatus.SCHEDULED,
      reviewStatus: ReviewStatus.INCOMPLETE,
      services: currentServicesLabel,
      isCreatingEvent: true,
      clientMembershipsList: "",
    };

    return [
      ...plannedVisits,
      ...timeBlockers,
      ...specialShifts,
      {
        start: start?.toISOString(),
        end: end?.toISOString(),
        title: `${client?.firstName} ${client?.lastName}`,
        backgroundColor: "#fbf5ff",
        borderColor: VIOLET[90],
        textColor: VIOLET[90],
        editable: true,
        extendedProps,
      },
    ];
  }, [
    visitEvents,
    start,
    end,
    availableServices,
    client,
    excludeVisitId,
    currentServicesLabel,
    data?.visit,
    specialShiftsEvents,
    timeBlockEvents,
  ]);
}
