import { useCurrentSessionDate } from "./useCurrentSessionDate";

export const useFormattedCurrentSessionDate = () => {
  const { sessionDate } = useCurrentSessionDate();
  if (!sessionDate) return new Date();

  const currentDate = new Date(sessionDate);
  const currentTime = new Date();

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentTime.getHours(),
    currentTime.getMinutes(),
    currentTime.getSeconds()
  );
};
