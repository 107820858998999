import {
  addDays,
  getWeek,
  isPast,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import Cookies from "js-cookie";
import { roundUpToNearestHalfHour } from "@/utils/date";
import useMedspaTimezone from "../common/useMedspaTimezone";
import {
  CALENDAR_VIEW_KEY,
  CalendarViews,
} from "../visits/useCalendarTypeView";
import { useCalendarClickDate } from "./useCalendarClickDate";
import { useFormattedCurrentSessionDate } from "./useFormattedCurrentSessionDate";

type CalendarView = `${CalendarViews}`;

export const useCreateVisitDefaultDate = () => {
  const { utcToMedspaZonedTime } = useMedspaTimezone();

  const selectedSessionDate = utcToMedspaZonedTime(
    roundUpToNearestHalfHour(useFormattedCurrentSessionDate())
  );
  const today = utcToMedspaZonedTime(roundUpToNearestHalfHour(new Date()));

  const calendarClickDate = useCalendarClickDate();

  const currentCalendarView = Cookies.get(CALENDAR_VIEW_KEY) as CalendarView;

  switch (currentCalendarView) {
    case CalendarViews.LIST:
      return today;
    case CalendarViews.DAY:
      return calendarClickDate ?? selectedSessionDate;

    // If we are in the today's current week or month, we use today.
    // For dates in the past, we use today
    case CalendarViews.WEEK: {
      if (calendarClickDate) return calendarClickDate;

      const selectedDateWeek = getWeek(selectedSessionDate, {
        weekStartsOn: 0,
      });
      const todayWeek = getWeek(today, { weekStartsOn: 0 });

      if (selectedDateWeek === todayWeek) return today;

      const startOfWeekSelected = startOfWeek(selectedSessionDate, {
        weekStartsOn: 0,
      }).setHours(today.getHours(), today.getMinutes());

      if (isPast(startOfWeekSelected)) return today;

      // Return Monday, not Sunday
      return utcToMedspaZonedTime(addDays(startOfWeekSelected, 1));
    }
    case CalendarViews.MONTH: {
      if (isSameMonth(selectedSessionDate, today)) return today;

      // If we are in the future, we use the first day from the month
      const startOfMonthSelected = startOfMonth(selectedSessionDate).setHours(
        today.getHours(),
        today.getMinutes()
      );
      if (isPast(startOfMonthSelected)) return today;

      return utcToMedspaZonedTime(startOfMonthSelected);
    }
    default:
      return today;
  }
};
