import { useUser } from "@/auth/useUser";

export const useIsProviderOwner = () => {
  const { userMedspa } = useUser();

  return {
    isProviderOwner: userMedspa?.isProviderOwner,
    isLoading: !userMedspa,
  };
};

export const useCanConfigureMedspa = () => {
  const { isProviderOwner } = useIsProviderOwner();
  return isProviderOwner;
};
