import React, { CSSProperties, forwardRef } from "react";

type Props = {
  size?: number | string;
  color?: CSSProperties["color"];
  style?: CSSProperties;
  strokeWidth?: number | string;
};

const UserHeart: React.ForwardRefRenderFunction<SVGSVGElement, Props> = (
  { size = 24, color = "#131316", style, strokeWidth = "1.5" },
  ref?: React.ForwardedRef<SVGSVGElement>
) => {
  return (
    <svg
      ref={ref}
      width={size}
      height={size}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 15H8C5.79086 15 4 16.7909 4 19V21H20V19C20 16.7909 18.2091 15 16 15Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3658 9.08574L20.2171 8.94124C19.9736 8.70444 19.6432 8.57141 19.2988 8.57141C18.5714 8.57141 18 9.10713 18 10.102C18 11.7857 20.5714 12.8571 20.5714 12.8571C20.5714 12.8571 23.1429 11.7857 23.1429 10.102C23.1429 9.10713 22.5614 8.57141 21.8441 8.57141C21.4996 8.57141 21.1693 8.70444 20.9257 8.94124L20.7771 9.08574C20.6647 9.19498 20.4781 9.19498 20.3658 9.08574Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default forwardRef(UserHeart);
