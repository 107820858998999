// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type CancelVisitWithoutAppointmentMutationVariables = Types.Exact<{
  visitId: Types.Scalars["BigInt"]["input"];
}>;

export type CancelVisitWithoutAppointmentMutation = {
  __typename?: "mutation_root";
  cancelVisitWithoutAppointment?: {
    __typename?: "CancelVisitWithoutAppointment";
    message?: string | null;
    ok?: boolean | null;
  } | null;
};

export const CancelVisitWithoutAppointmentDocument = gql`
  mutation CancelVisitWithoutAppointment($visitId: BigInt!) {
    cancelVisitWithoutAppointment(visitId: $visitId) {
      message
      ok
    }
  }
`;
export type CancelVisitWithoutAppointmentMutationFn = Apollo.MutationFunction<
  CancelVisitWithoutAppointmentMutation,
  CancelVisitWithoutAppointmentMutationVariables
>;

/**
 * __useCancelVisitWithoutAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelVisitWithoutAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelVisitWithoutAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelVisitWithoutAppointmentMutation, { data, loading, error }] = useCancelVisitWithoutAppointmentMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useCancelVisitWithoutAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelVisitWithoutAppointmentMutation,
    CancelVisitWithoutAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelVisitWithoutAppointmentMutation,
    CancelVisitWithoutAppointmentMutationVariables
  >(CancelVisitWithoutAppointmentDocument, options);
}
export type CancelVisitWithoutAppointmentMutationHookResult = ReturnType<
  typeof useCancelVisitWithoutAppointmentMutation
>;
export type CancelVisitWithoutAppointmentMutationResult =
  Apollo.MutationResult<CancelVisitWithoutAppointmentMutation>;
export type CancelVisitWithoutAppointmentMutationOptions =
  Apollo.BaseMutationOptions<
    CancelVisitWithoutAppointmentMutation,
    CancelVisitWithoutAppointmentMutationVariables
  >;
