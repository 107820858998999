import { AppBar, Toolbar, Tooltip } from "@mui/material";
import { ReactNode } from "react";
import { useUser } from "@/auth/useUser";
import LoadingButton from "@/components/common/buttons/loadingButton";
import { NAV_WIDTH } from "@/components/common/navigation/verticalNav";
import { GREY } from "@/config/mui/colorPalette";
import useAction from "@/hooks/misc/useAction";

type Action = {
  title: string;
  url?: string;
  onClick?: () => Promise<void>;
  disabled?: boolean;
  tooltipTitle?: ReactNode;
};

export default function CreateEventNavigation({
  title,
  onCreateEventClick,
  disabled = false,
  tooltipTitle,
  secondaryAction,
  tertiaryAction,
}: Omit<Action, "url" | "onClick"> & {
  onCreateEventClick: () => Promise<void>;
  secondaryAction?: Action;
  tertiaryAction?: Action;
}) {
  const { handleAction: onClickPrimary, isLoading: isLoadingPrimary } =
    useAction(onCreateEventClick);
  const { handleAction: onClickSecondary, isLoading: isLoadingSecondary } =
    useAction(secondaryAction?.onClick);
  const { handleAction: onClickTertiary, isLoading: isLoadingTertiary } =
    useAction(tertiaryAction?.onClick);

  const isLoading = isLoadingPrimary || isLoadingSecondary || isLoadingTertiary;

  const { user } = useUser();
  const navVisible = !!user;

  return (
    <AppBar
      color="primary"
      position="fixed"
      sx={{
        width: {
          xs: "100%",
          md: navVisible ? `calc(100% - ${NAV_WIDTH})` : "100%",
        },
        left: { xs: "0", md: navVisible ? NAV_WIDTH : 0 },
        pt: 2,
        pb: 0,
        top: "auto",
        bottom: 0,
        bgcolor: "white",
        borderTop: "1px solid",
        borderColor: GREY[20],
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "stretch",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Tooltip title={tooltipTitle}>
          <span>
            <LoadingButton
              fullWidth
              onClick={onClickPrimary}
              aria-label={title}
              size="large"
              variant="contained"
              loading={isLoading}
              disabled={disabled}
              sx={{ mb: 2 }}
            >
              {title}
            </LoadingButton>
          </span>
        </Tooltip>
        {secondaryAction !== undefined && (
          <Tooltip title={secondaryAction.tooltipTitle}>
            <span>
              <LoadingButton
                onClick={
                  secondaryAction?.onClick ? onClickSecondary : undefined
                }
                href={secondaryAction.url}
                disabled={secondaryAction.disabled}
                loading={isLoading}
                fullWidth
                size="large"
                sx={{
                  mb: 2,
                }}
                variant="outlined"
              >
                {secondaryAction.title}
              </LoadingButton>
            </span>
          </Tooltip>
        )}
        {tertiaryAction !== undefined && (
          <Tooltip title={tertiaryAction.tooltipTitle}>
            <span>
              <LoadingButton
                onClick={tertiaryAction.onClick ? onClickTertiary : undefined}
                href={tertiaryAction.url}
                disabled={tertiaryAction.disabled}
                loading={isLoading}
                fullWidth
                size="large"
                sx={{
                  mb: 2,
                }}
                variant="text"
              >
                {tertiaryAction.title}
              </LoadingButton>
            </span>
          </Tooltip>
        )}
      </Toolbar>
    </AppBar>
  );
}
