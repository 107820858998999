import { useRouter } from "next/router";
import { useMemo } from "react";
import useMedspaTimezone from "../common/useMedspaTimezone";

export function useCalendarClickDate() {
  const {
    query: { startDate },
  } = useRouter();

  const { addTimezoneOffset, utcToMedspaZonedTime } = useMedspaTimezone();

  const calendarClickDate = useMemo(() => {
    if (startDate) {
      const decodedDateString = decodeURIComponent(startDate as string);
      const withTimeZoneOffset = addTimezoneOffset(new Date(decodedDateString));
      return utcToMedspaZonedTime(withTimeZoneOffset);
    }
    return null;
  }, [addTimezoneOffset, startDate, utcToMedspaZonedTime]);

  return calendarClickDate;
}
