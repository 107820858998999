// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type UserQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type UserQuery = {
  __typename?: "query_root";
  userMedspa: Array<{
    __typename?: "userMedspa";
    id: string;
    isGfeReviewer: boolean;
    role: string;
    isProviderOwner: boolean;
    roleId?: string | null;
    medspa: {
      __typename?: "medspa";
      id: string;
      name: string;
      logo?: string | null;
      isMso: boolean;
      stripeOnboardingDone: boolean;
      stripeAccountId?: string | null;
      stripeFinancialAccountId?: string | null;
      stripeRestricted: boolean;
      stripeAccountType: string;
      professionalCorporation?: {
        __typename?: "MedspasProfessionalcorporation";
        id: string;
        pcType: string;
        ownerId?: string | null;
        isMoxiePc: boolean;
        companyName: string;
        employerIdentificationNumber: string;
        owner?: {
          __typename?: "user";
          id: string;
          fullName?: string | null;
        } | null;
      } | null;
    };
  }>;
};

export type ProviderInfoQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type ProviderInfoQuery = {
  __typename?: "query_root";
  userMedspa: Array<{
    __typename?: "userMedspa";
    id: string;
    isEligibleForScripts: boolean;
    isEligibleForStrive503aWeightLoss: boolean;
    isEligibleForStrive503bWeightLoss: boolean;
    isEligibleForEmpowerWeightLoss: boolean;
    providerInfo?: {
      __typename?: "UserMedSpaProviderType";
      id: string;
      isEligibleForSelfGfe?: boolean | null;
    } | null;
  }>;
};

export type IsUserAGfeReviewerQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type IsUserAGfeReviewerQuery = {
  __typename?: "query_root";
  userMedspa: Array<{
    __typename?: "userMedspa";
    id: string;
    isGfeReviewer: boolean;
    active: boolean;
  }>;
};

export const UserDocument = gql`
  query User($id: bigint!) {
    userMedspa(
      where: {
        userId: { _eq: $id }
        role: { _neq: "client" }
        active: { _eq: true }
      }
    ) {
      id
      isGfeReviewer
      role
      isProviderOwner
      roleId
      medspa {
        id
        name
        logo
        isMso
        professionalCorporation {
          id
          pcType
          ownerId
          isMoxiePc
          companyName
          employerIdentificationNumber
          owner {
            id
            fullName
          }
        }
        stripeOnboardingDone
        stripeAccountId
        stripeFinancialAccountId
        stripeRestricted
        stripeAccountType
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<
  typeof useUserSuspenseQuery
>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const ProviderInfoDocument = gql`
  query ProviderInfo($id: bigint!) {
    userMedspa(
      where: {
        userId: { _eq: $id }
        role: { _eq: "provider" }
        active: { _eq: true }
      }
    ) {
      id
      isEligibleForScripts
      isEligibleForStrive503aWeightLoss
      isEligibleForStrive503bWeightLoss
      isEligibleForEmpowerWeightLoss
      providerInfo {
        id
        isEligibleForSelfGfe
      }
    }
  }
`;

/**
 * __useProviderInfoQuery__
 *
 * To run a query within a React component, call `useProviderInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProviderInfoQuery,
    ProviderInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderInfoQuery, ProviderInfoQueryVariables>(
    ProviderInfoDocument,
    options
  );
}
export function useProviderInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderInfoQuery,
    ProviderInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderInfoQuery, ProviderInfoQueryVariables>(
    ProviderInfoDocument,
    options
  );
}
export function useProviderInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProviderInfoQuery,
    ProviderInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProviderInfoQuery, ProviderInfoQueryVariables>(
    ProviderInfoDocument,
    options
  );
}
export type ProviderInfoQueryHookResult = ReturnType<
  typeof useProviderInfoQuery
>;
export type ProviderInfoLazyQueryHookResult = ReturnType<
  typeof useProviderInfoLazyQuery
>;
export type ProviderInfoSuspenseQueryHookResult = ReturnType<
  typeof useProviderInfoSuspenseQuery
>;
export type ProviderInfoQueryResult = Apollo.QueryResult<
  ProviderInfoQuery,
  ProviderInfoQueryVariables
>;
export const IsUserAGfeReviewerDocument = gql`
  query IsUserAGfeReviewer($id: bigint!) {
    userMedspa(where: { userId: { _eq: $id }, role: { _neq: "client" } }) {
      id
      isGfeReviewer
      active
    }
  }
`;

/**
 * __useIsUserAGfeReviewerQuery__
 *
 * To run a query within a React component, call `useIsUserAGfeReviewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserAGfeReviewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserAGfeReviewerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIsUserAGfeReviewerQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsUserAGfeReviewerQuery,
    IsUserAGfeReviewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsUserAGfeReviewerQuery,
    IsUserAGfeReviewerQueryVariables
  >(IsUserAGfeReviewerDocument, options);
}
export function useIsUserAGfeReviewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsUserAGfeReviewerQuery,
    IsUserAGfeReviewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsUserAGfeReviewerQuery,
    IsUserAGfeReviewerQueryVariables
  >(IsUserAGfeReviewerDocument, options);
}
export function useIsUserAGfeReviewerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsUserAGfeReviewerQuery,
    IsUserAGfeReviewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsUserAGfeReviewerQuery,
    IsUserAGfeReviewerQueryVariables
  >(IsUserAGfeReviewerDocument, options);
}
export type IsUserAGfeReviewerQueryHookResult = ReturnType<
  typeof useIsUserAGfeReviewerQuery
>;
export type IsUserAGfeReviewerLazyQueryHookResult = ReturnType<
  typeof useIsUserAGfeReviewerLazyQuery
>;
export type IsUserAGfeReviewerSuspenseQueryHookResult = ReturnType<
  typeof useIsUserAGfeReviewerSuspenseQuery
>;
export type IsUserAGfeReviewerQueryResult = Apollo.QueryResult<
  IsUserAGfeReviewerQuery,
  IsUserAGfeReviewerQueryVariables
>;
