import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";

function SectionHeader({
  title,
  action,
  sx,
}: {
  title: ReactNode;
  action?: ReactNode;
  sx?: SxProps<Theme>;
}) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        mb: "10px",
        ...sx,
      }}
    >
      <Typography
        variant="subtitleSmall"
        sx={{
          textAlign: "left",
          color: "text.secondary",
          flexGrow: 1,
        }}
      >
        {title}
      </Typography>
      {action && <Box sx={{ position: "absolute", right: 0 }}>{action}</Box>}
    </Box>
  );
}

export default SectionHeader;
