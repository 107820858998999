import { ApolloError } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Stack } from "@mui/material";
import { parseISO } from "date-fns";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useForm, UseFormSetError } from "react-hook-form";
import toast from "react-hot-toast";
import { useUser } from "@/auth/useUser";
import PageContainer from "@/components/common/pageContainer/pageContainer";
import PageHeader, {
  PageHeaderButton,
} from "@/components/common/pageHeader/pageHeader";
import { useConfirm } from "@/components/providers/confirmProvider";
import ClientForm from "@/components/serviceFlow/clientDetails/forms/clientForm";
import {
  createClientFormDefaultValues,
  schema,
} from "@/components/serviceFlow/clientDetails/forms/clientFormHelpers";
import {
  UpdateClientMutationVariables,
  useClientsByPhoneLazyQuery,
  useUpdateClientMutation,
} from "@/graphql/mutations/clients.graphql.types";
import useClientBasicInfo from "@/hooks/client/useClientBasicInfo";
import useAction from "@/hooks/misc/useAction";
import { FormValidationError } from "@/types";
import { handleApolloError } from "@/utils";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";

export const addGraphQLError = ({
  error,
  message,
  setError,
  alternativeMessage,
  toastId,
}: {
  error: FormValidationError;
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError: UseFormSetError<any>;
  alternativeMessage: string;
  toastId: string;
}) => {
  let toastMessage = alternativeMessage;
  if (
    error === FormValidationError.CONSTRAINT_VIOLATION &&
    message.includes("unique_client_per_medspa")
  ) {
    setError("email", { type: "custom", message: "Email already exists" });
    toastMessage = "Email already exists";
  }
  toast.error(toastMessage, { id: toastId });
};

function EditClientView() {
  const logError = useErrorLogger();
  const {
    push,
    query: { slug },
  } = useRouter();
  const { getConfirm } = useConfirm();
  const { medspa } = useUser();

  const { data: client, updateQuery } = useClientBasicInfo();
  const [getClientsByPhone] = useClientsByPhoneLazyQuery();
  const [updateClientMutation] = useUpdateClientMutation();

  const verifyIfPhoneAlreadyExists = async (phone: string) => {
    const { data: clientsByPhoneData } = await getClientsByPhone({
      variables: {
        phone,
      },
    });

    return (
      clientsByPhoneData.client.length > 0 &&
      clientsByPhoneData.client[0].id !== slug
    );
  };

  const onSubmitCallback = async () => {
    const data = getValues();
    if (await verifyIfPhoneAlreadyExists(data.phone)) {
      setError("phone", { type: "custom", message: "Phone already exists" });
      toast.error("Phone already exists");
      return;
    }

    const shouldEdit = await getConfirm({
      title: "Save changes?",
      description:
        "Are you sure you want to edit the client? You cannot reverse the changes.",
      discardButtonText: "Cancel",
      confirmButtonText: "Save",
    });
    if (!shouldEdit) return;

    data.email = data.email.toLowerCase();

    const toastId = toast.loading("Editing the client...");
    try {
      const state = data.state || "";
      const result = await updateClientMutation({
        variables: {
          id: slug as string,
          ...data,
          state,
          phone: data.phone,
          birthdate: data.birthdate
            ? new Date(data.birthdate).toDateString()
            : null,
        },
      });
      toast.success("Client edited!", { id: toastId });

      if (result.data) {
        updateQuery((previousQueryResult) => ({
          clientByPk: {
            ...previousQueryResult.clientByPk,
            ...result.data.updateClientByPk,
            address: result.data.updateAddress.returning[0],
          },
        }));
      }
    } catch (error) {
      const alternativeMessage = hasDjangoMutationError(error)
        ? error.message
        : "Failed to edit the client";

      if (error instanceof ApolloError) {
        addGraphQLError({
          ...handleApolloError(error),
          setError,
          alternativeMessage,
          toastId,
        });
      } else {
        toast.error(alternativeMessage, { id: toastId });
      }

      logError(error);
      return;
    }

    push(`/${medspa}/clients/${slug}`);
  };

  const { handleAction: onSubmit, isLoading } = useAction(onSubmitCallback);

  const clientData = useMemo(() => {
    if (!client) {
      return {};
    }

    const {
      email,
      birthdate,
      note,
      firstName,
      lastName,
      phone,
      gender,
      address,
    } = client.clientByPk;

    return {
      firstName,
      lastName,
      email,
      birthdate: birthdate ? parseISO(birthdate) : null,
      note,
      phone,
      gender,
      ...address,
    };
  }, [client]);

  useEffect(() => {
    if (client) {
      reset({
        ...clientData,
      });
    }
  }, [client]);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    setError,
    trigger,
  } = useForm<UpdateClientMutationVariables>({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return client ? clientData : createClientFormDefaultValues;
    }, [client]),
  });

  return (
    <PageContainer
      sx={{ height: "auto", background: "#ffffff", overflow: "visible" }}
    >
      <PageHeader
        title="Edit details"
        goBackStrategy="history"
        action={
          <PageHeaderButton
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Save
          </PageHeaderButton>
        }
      />
      <Stack
        data-ls-disabled
        data-dd-privacy="mask"
        sx={{
          alignItems: "center",
        }}
      >
        <Avatar sx={{ width: 64, height: 64, mb: 4 }} />
        <ClientForm<UpdateClientMutationVariables>
          control={control}
          setValue={setValue}
          trigger={trigger}
        />
      </Stack>
    </PageContainer>
  );
}

export default EditClientView;
