import { useTheme } from "@mui/material";
import { isValid, set } from "date-fns";
import React from "react";
import { Clock } from "react-swm-icon-pack";
import TimePicker, {
  type TimePickerProps,
} from "@/components/common/datetimePicker/TimePicker";
import { GREY } from "@/config/mui/colorPalette";

type Props = Omit<TimePickerProps, "onAccept" | "value"> & {
  value: Date;
  onChange: (date: Date) => void;
};

export default function TimeSelectorTimePicker({
  value,
  onChange,
  ...props
}: Props) {
  const theme = useTheme();

  return (
    <TimePicker
      {...props}
      slotProps={{
        textField: {
          InputProps: {
            startAdornment: (
              <Clock
                size={24}
                color={GREY[50]}
                style={{ marginRight: theme.spacing(1) }}
              />
            ),
          },
        },
      }}
      value={value}
      onAccept={(val) => {
        const date = val as Date;

        if (isValid(date)) {
          const newDate = set(new Date(value), {
            hours: date.getHours(),
            minutes: date.getMinutes(),
            seconds: date.getSeconds(),
          });
          onChange(newDate);
        }
      }}
    />
  );
}
