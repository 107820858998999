// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type UpdateVisitReviewStatusMutationVariables = Types.Exact<{
  visitId: Types.Scalars["bigint"]["input"];
  reviewStatus: Types.Scalars["String"]["input"];
}>;

export type UpdateVisitReviewStatusMutation = {
  __typename?: "mutation_root";
  updateVisitByPk?: {
    __typename?: "visit";
    id: string;
    reviewStatus: string;
  } | null;
};

export const UpdateVisitReviewStatusDocument = gql`
  mutation UpdateVisitReviewStatus($visitId: bigint!, $reviewStatus: String!) {
    updateVisitByPk(
      pkColumns: { id: $visitId }
      _set: { reviewStatus: $reviewStatus }
    ) {
      id
      reviewStatus
    }
  }
`;
export type UpdateVisitReviewStatusMutationFn = Apollo.MutationFunction<
  UpdateVisitReviewStatusMutation,
  UpdateVisitReviewStatusMutationVariables
>;

/**
 * __useUpdateVisitReviewStatusMutation__
 *
 * To run a mutation, you first call `useUpdateVisitReviewStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisitReviewStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisitReviewStatusMutation, { data, loading, error }] = useUpdateVisitReviewStatusMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      reviewStatus: // value for 'reviewStatus'
 *   },
 * });
 */
export function useUpdateVisitReviewStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVisitReviewStatusMutation,
    UpdateVisitReviewStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVisitReviewStatusMutation,
    UpdateVisitReviewStatusMutationVariables
  >(UpdateVisitReviewStatusDocument, options);
}
export type UpdateVisitReviewStatusMutationHookResult = ReturnType<
  typeof useUpdateVisitReviewStatusMutation
>;
export type UpdateVisitReviewStatusMutationResult =
  Apollo.MutationResult<UpdateVisitReviewStatusMutation>;
export type UpdateVisitReviewStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisitReviewStatusMutation,
  UpdateVisitReviewStatusMutationVariables
>;
