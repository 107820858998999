import {
  parseISO,
  format,
  intervalToDuration,
  addMinutes,
  formatDuration,
  roundToNearestMinutes,
  isAfter,
  setMinutes,
  addHours,
  setSeconds,
  differenceInYears,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  subDays,
  addDays,
  isToday,
  isThisWeek,
  differenceInDays,
  differenceInHours,
  startOfToday,
  endOfToday,
} from "date-fns";
import { DATE_FORMATS, TIME_FORMATS } from "@/config";
import { pluralize } from "@/utils/index";

export function formatClientsBirthdate(birthdate: string) {
  if (!birthdate || birthdate === "") return "";

  const clientsBirthdate = format(
    parseISO(birthdate),
    DATE_FORMATS.DATE_PICKER
  );

  const clientAge = differenceInYears(new Date(), new Date(birthdate));

  return `${clientsBirthdate} • Age ${clientAge}`;
}

export function formattedTime(minutes: number) {
  if (!minutes) return "0 min";
  const formatDistanceLocale = {
    xSeconds: "{{count}} sec",
    xMinutes: "{{count}} min",
    xHours: "{{count}} h",
  };
  const shortEnLocale = {
    formatDistance: (token, count) =>
      formatDistanceLocale[token].replace("{{count}}", count),
  };

  const duration = intervalToDuration({
    start: 0,
    end: addMinutes(new Date(0), minutes),
  });

  return formatDuration(duration, {
    format: ["hours", "minutes"],
    locale: shortEnLocale,
  });
}

export const roundToNearestMinutesInTheFuture = (minutes: number) => {
  const date = roundToNearestMinutes(new Date(), {
    nearestTo: minutes,
  });

  if (isAfter(date, new Date())) {
    return date;
  }

  return addMinutes(date, minutes);
};

export const roundUpToNearestHalfHour = (date: Date) => {
  const dateWithoutSeconds = setSeconds(date, 0);
  const newDate = addMinutes(dateWithoutSeconds, 5); // 5 minutes gap to make sure visit is not scheduled in the past
  const minutes = newDate.getMinutes();
  // If already at the half hour, nothing to do
  if (minutes % 30 === 0) {
    return newDate;
  }
  // If before the half hour, round up to the half hour
  if (minutes < 30) {
    return setMinutes(newDate, 30);
  }
  // Otherwise round up to next hour
  return setMinutes(addHours(newDate, 1), 0);
};

const weekdaysSortWeights = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const weekSort = <
  T extends {
    day: string;
    start: string;
  },
>(
  a: T,
  b: T
) => {
  const day1 = a.day.toLowerCase();
  const day2 = b.day.toLowerCase();

  const sortWeek = weekdaysSortWeights[day1] - weekdaysSortWeights[day2];

  if (sortWeek !== 0) return sortWeek;

  return +a.start.replace(":", "") - +b.start.replace(":", "");
};

export const formatDateForServer = (date: string) => {
  const myDate = new Date(date);

  return format(myDate, DATE_FORMATS.SERVER_DATE);
};

export const getMiddleOfDay = (date: string | Date) => {
  const newDate = new Date(date);
  const start = startOfDay(newDate);
  const end = endOfDay(newDate);

  return new Date((start.getTime() + end.getTime()) / 2);
};

const createUTCDateFromDate = (
  date: Date,
  hours: number,
  minutes: number,
  seconds: number,
  ms: number
) => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes,
      seconds,
      ms
    )
  );
};

export const createUTCStartOfTheDay = (date: Date) => {
  return createUTCDateFromDate(date, 0, 0, 0, 0);
};

export const createUTCEndOfTheDay = (date: Date) => {
  return createUTCDateFromDate(date, 23, 59, 59, 999);
};

const getWeekStartEnd = (week: Date) => {
  const monday = 1;
  const startDate = startOfWeek(week, { weekStartsOn: monday });
  const endDate = endOfWeek(week, { weekStartsOn: monday });

  return { startDate, endDate };
};

const getUTCStartOfToday = () => startOfToday();
const getUTCEndOfToday = () => endOfToday();

export const getCurrentWeek = () => {
  return getWeekStartEnd(getUTCEndOfToday());
};

export const getLastWeek = () => {
  return getWeekStartEnd(subWeeks(getUTCEndOfToday(), 1));
};

const getMonthStartEnd = (month: Date) => {
  const startDate = startOfMonth(month);
  const endDate = endOfMonth(month);

  return { startDate, endDate };
};

export const getNext30Days = () => {
  const startDate = getUTCStartOfToday();
  const endDate = addDays(getUTCEndOfToday(), 30);

  return { startDate, endDate };
};

export const getCurrentMonth = () => {
  return getMonthStartEnd(getUTCEndOfToday());
};

export const getLastMonth = () => {
  return getMonthStartEnd(subMonths(getUTCEndOfToday(), 1));
};

export const getLastNDays = (days: number) => {
  const endDate = getUTCEndOfToday();
  const startDate = subDays(getUTCStartOfToday(), days);

  return { startDate, endDate };
};

export function diffForHumans(date: string | Date) {
  const parsedDate = typeof date === "string" ? parseISO(date) : date;

  if (isToday(parsedDate)) {
    return format(parsedDate, TIME_FORMATS.COMPACT); // e.g., 02:15 PM
  }

  if (isThisWeek(parsedDate)) {
    return format(parsedDate, DATE_FORMATS.DAY_OF_WEEK); // e.g., Monday
  }

  return format(parsedDate, DATE_FORMATS.DATE_PICKER); // e.g., 09/13/2023
}

export function getTimeDifferenceString(laterDate: Date, date: Date) {
  const differenceDays = differenceInDays(laterDate, date);
  if (differenceDays >= 1) {
    return `${differenceDays} ${pluralize("day", differenceDays)}`;
  }

  const differenceHours = differenceInHours(laterDate, date);
  if (differenceHours >= 1) {
    return `${differenceHours} ${pluralize("hour", differenceHours)}`;
  }

  return "less than one hour";
}
export const formatDateForMarketingConversation = (date: Date) => {
  return date.toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};
