import { RuleEffect, Scoped, UISchemaElement } from "@jsonforms/core";
import { differenceInDays, format, parseISO } from "date-fns";
import { difference, isEmpty, uniq } from "lodash";
import { MakeOptional } from "@/__generated__/schema.graphql.types";
import { DATE_FORMATS } from "@/config";
import {
  GfeFormSubmissionWithDetailsFragment,
  GfeFormSubmissionsFragment,
  MedspaGfeOfferingsFragment,
  ReviewedMedspaOfferingsFragment,
} from "@/graphql/fragments/gfe/gfeReviewRequestFields.graphql.types";
import { ClientByTokenQuery } from "@/graphql/queries/clientByToken.graphql.types";
import { GfeServiceTypeLogsQuery } from "@/graphql/queries/gfeReview/gfeServiceTypeLogs.graphql.types";
import { FormStatus, GfeStatus } from "@/types/gfe";
import { isObj } from ".";

type ExtendedLayout = {
  elements: UISchema[];
};

export type UISchema = UISchemaElement &
  ExtendedLayout &
  Scoped & {
    rule: {
      condition: {
        scope: string;
        schema: {
          const: string;
        };
      };
    };
  };

export const hasIncompleteGFE = (client: {
  formSubmissions?: { gfeStatus: string }[];
}) =>
  client.formSubmissions?.some(
    (formSubmission) =>
      formSubmission.gfeStatus !== "" &&
      formSubmission.gfeStatus !== GfeStatus.GFE_APPROVED
  );

export const hasClientFormsToFill = (
  client: {
    consentFormSignatures?: {
      visitId?: string;
      signedAt?: string;
      consentForm?: {
        sendOnce?: boolean;
      };
    }[];
    formSubmissions?: BasicFormSubmission[];
  },
  visitId?: string
) =>
  !hasAllIntakeFormsCompleted(client.formSubmissions) ||
  client.consentFormSignatures
    ?.filter(
      (consentFormSignature) =>
        consentFormSignature.visitId === visitId &&
        !consentFormSignature.consentForm?.sendOnce
    )
    ?.some((consentFormSignature) => !consentFormSignature.signedAt) ||
  client.consentFormSignatures
    ?.filter(
      (consentFormSignature) => consentFormSignature.consentForm?.sendOnce
    )
    ?.some((consentFormSignature) => !consentFormSignature.signedAt);

export const isMigratedToJsonForms = (form?: {
  schema?: string;
  uiSchema?: string;
}) => !isEmpty(form?.schema) && !isEmpty(form?.uiSchema);

export const hasPhotos = ({ uiSchema }: { uiSchema: string }) =>
  !isEmpty(uiSchema) &&
  findPhotosUiSchema((uiSchema as unknown as { elements: [] }).elements);

export const hasMedicalNote = (uiSchema: string) => {
  return (
    !isEmpty(uiSchema) &&
    !!findMedicalNoteUiSchema(
      (uiSchema as unknown as { elements: [] }).elements
    )
  );
};

type FormWithSchema = {
  schema: string;
  uiSchema: string;
};

export const hasPhotosRequired = ({ schema, uiSchema }: FormWithSchema) => {
  if (!isMigratedToJsonForms({ schema, uiSchema })) return false;

  const schemaObj = schema as unknown as { properties: object };
  const uiSchemaObj = uiSchema as unknown as { elements: object[] };

  const photoRequiredInSchema =
    isObj(schemaObj.properties) &&
    "photosRequired" in schemaObj.properties &&
    isObj(schemaObj.properties.photosRequired) &&
    "default" in schemaObj.properties.photosRequired &&
    schemaObj.properties.photosRequired.default;
  const photosUiSchema = findPhotosUiSchema(uiSchemaObj.elements);
  if (!photosUiSchema) return false;

  const photosRequiredInUiSchema =
    photosUiSchema.label.includes(REQUIRE_ASTERISK);

  return photoRequiredInSchema && photosRequiredInUiSchema;
};

const findPhotosUiSchema = (elements: object[]) =>
  elements.find(
    (element) =>
      isObj(element) &&
      "label" in element &&
      typeof element.label === "string" &&
      element.label.toLowerCase().includes("photos")
  ) as { label: string; elements: object[] } | undefined;

const findMedicalNoteUiSchema = (elements: object[]) => {
  return elements.find(
    (element) =>
      isObj(element) &&
      "label" in element &&
      typeof element.label === "string" &&
      element.label.toLowerCase().includes("medical note")
  ) as { label: string; elements: object[] } | undefined;
};

const findPhotosDescriptionUiSchema = (elements: object[]) =>
  elements.find(
    (element) =>
      isObj(element) &&
      "text" in element &&
      typeof element.text === "string" &&
      element.text.toLowerCase().includes("photo")
  ) as { text: string } | undefined;

const REQUIRE_ASTERISK = " *";
export const REQUIRE_COPY = "\n\nAt least one photo is required*";

const getParsedSchemaElements = ({ schema, uiSchema }: FormWithSchema) => {
  const newSchema = JSON.parse(JSON.stringify(schema)) as {
    properties: object;
  };
  const newUiSchema = JSON.parse(JSON.stringify(uiSchema)) as {
    elements: object[];
  };
  const photosUiSchema = findPhotosUiSchema(newUiSchema.elements);
  const photosDescriptionUiSchema = findPhotosDescriptionUiSchema(
    photosUiSchema.elements
  );

  return { newSchema, newUiSchema, photosUiSchema, photosDescriptionUiSchema };
};

export const makePhotoRequired = (form: FormWithSchema) => {
  const { newSchema, newUiSchema, photosUiSchema, photosDescriptionUiSchema } =
    getParsedSchemaElements(form);
  // modify newSchema
  newSchema.properties = {
    ...newSchema.properties,
    photosRequired: {
      default: true,
    },
  };
  // modify newUiSchema
  if (!photosUiSchema.label.includes(REQUIRE_ASTERISK))
    // handle case of old Patient Medical History Form
    photosUiSchema.label += REQUIRE_ASTERISK;
  if (!photosDescriptionUiSchema.text.includes(REQUIRE_COPY))
    photosDescriptionUiSchema.text += REQUIRE_COPY;

  return {
    schema: newSchema,
    uiSchema: newUiSchema,
  };
};

export const makePhotoOptional = (form: FormWithSchema) => {
  const { newSchema, newUiSchema, photosUiSchema, photosDescriptionUiSchema } =
    getParsedSchemaElements(form);
  // modify newSchema
  if (isObj(newSchema.properties) && "photosRequired" in newSchema.properties)
    delete newSchema.properties.photosRequired;
  // modify newUiSchema
  photosUiSchema.label = photosUiSchema.label.replace(REQUIRE_ASTERISK, "");
  photosDescriptionUiSchema.text = photosDescriptionUiSchema.text.replace(
    REQUIRE_COPY,
    ""
  );

  return {
    schema: newSchema,
    uiSchema: newUiSchema,
  };
};

export type FormSubmission =
  | GfeFormSubmissionsFragment["gfeForms"][number]
  | GfeFormSubmissionWithDetailsFragment["gfeForms"][number];

type BasicFormSubmission = MakeOptional<
  Pick<
    FormSubmission,
    "id" | "submittedAt" | "versions" | "archivedAt" | "gfeStatus"
  >,
  "versions"
>;

export const isFormSubmitted = (formSubmission: BasicFormSubmission) =>
  formSubmission.submittedAt || formSubmission.versions?.length > 0;

export const hasAllIntakeFormsCompleted = (
  formSubmissions: BasicFormSubmission[]
) => {
  if (isEmpty(formSubmissions)) return false;

  const incompleteForms =
    getFormStatusMap(formSubmissions)[FormStatus.INCOMPLETE];

  return incompleteForms.length === 0;
};

export const isFormArchived = (formSubmission: {
  form?: { archivedAt?: string };
}) => !!formSubmission.form?.archivedAt;

export const isFormSubmissionArchived = (formSubmission: {
  archivedAt?: string;
}) => {
  if (!("archivedAt" in formSubmission)) {
    // it is impossible to enforce using typescript because codegen generates this type as optional
    throw new Error("Form submission does not have an archivedAt property");
  }
  return !!formSubmission.archivedAt;
};

export const getFormStatusMap = <T extends BasicFormSubmission>(
  formSubmissions: T[]
) => {
  const activeForms = formSubmissions.filter(
    (formSubmission) => !isFormSubmissionArchived(formSubmission)
  );
  const completed = activeForms.filter(
    (form) => isFormSubmitted(form) && !hasFormExpired(form)
  );
  const incomplete = activeForms.filter(
    (form) => !isFormSubmitted(form) || hasFormExpired(form)
  );

  return {
    [FormStatus.COMPLETED]: completed,
    [FormStatus.INCOMPLETE]: incomplete,
  } as const;
};

export const hasFormExpired = (formSubmission: { gfeStatus: string }) =>
  formSubmission.gfeStatus === GfeStatus.EXPIRED;

export const isFormExpiringSoon = (
  formSubmission: { expiresAt?: string },
  daysInAdvance = 30
) => {
  if (!formSubmission.expiresAt) return false;

  const expirationDate = new Date(formSubmission.expiresAt);
  const now = new Date();
  const daysLeft = differenceInDays(expirationDate, now);

  return daysLeft <= daysInAdvance;
};

export const shouldShowFormExpiredToClient = (formSubmission: {
  expiresAt?: string;
  gfeStatus: string;
}) => isFormExpiringSoon(formSubmission) || hasFormExpired(formSubmission);

export const formatLogDate = (date: string) =>
  format(parseISO(date), DATE_FORMATS.DATE_PICKER);

type GfeForm =
  | FormSubmission
  | GfeServiceTypeLogsQuery["formSubmission"][number];

export const getFormOfferingsIds = (formSubmissions: GfeForm[]) =>
  uniq(
    formSubmissions
      .flatMap((form) => form.form.medspaServiceOfferings)
      .map((offering) => offering.medspaServiceOffering.id)
  );

export type MedspaOffering =
  MedspaGfeOfferingsFragment["medspa"]["serviceOfferings"][number];

export const getOfferingsNotPartOfTheReview = (
  formSubmissions: FormSubmission[],
  medspaOfferings: MedspaOffering[]
) => {
  /*
    Returns medspa offerings that are not connected to any form
   */

  const formOfferingsIds = getFormOfferingsIds(formSubmissions);

  return medspaOfferings.filter(
    (offering) => !formOfferingsIds.includes(offering.id)
  );
};

export type ReviewedOffering =
  ReviewedMedspaOfferingsFragment["reviewedMedspaOfferings"][number];

export const getUnreviewedOfferings = (
  formSubmissions: FormSubmission[],
  medspaOfferings: MedspaOffering[],
  reviewedOfferings: ReviewedOffering[]
): MedspaOffering[] => {
  /*
    Return medspa offerings that are connected to a form, but were never requested
   */

  const completedForms =
    getFormStatusMap(formSubmissions)[FormStatus.COMPLETED];
  const formOfferingsIds = getFormOfferingsIds(completedForms);

  const reviewedOfferingsIds = reviewedOfferings.map(
    (indication) => indication.medspaServiceOffering.id
  );

  const unreviewedFormOfferingsIds = difference(
    formOfferingsIds,
    reviewedOfferingsIds
  );

  return medspaOfferings.filter((offering) =>
    unreviewedFormOfferingsIds.includes(offering.id)
  );
};

export type ClientFormSubmission = NonNullable<
  ClientByTokenQuery["clientAccessTokenByPk"]
>["client"]["formSubmissions"][number];

export type FormVersion = Omit<
  ClientFormSubmission["versions"][number],
  "created"
>;

export const isJotFormSubmission = <
  F extends {
    submittedAt?: ClientFormSubmission["submittedAt"];
    versions: FormVersion[];
  },
>(
  formSubmission: F
): boolean => {
  return !!(
    formSubmission.submittedAt && formSubmission.versions?.length === 0
  );
};

export const getMostRecentFormVersion = <
  F extends {
    versions?: FormVersion[];
  },
>(
  formSubmission: F
): FormVersion => {
  const versions = formSubmission.versions || [];

  const mostRecentVersionNumber = Math.max(...versions.map((o) => o.version));

  return versions.find(({ version }) => version === mostRecentVersionNumber);
};

export const getJotFormSource = (id: string, jotformId: string) => {
  return `https://form.jotform.com/${jotformId}?submission_id=${id}&submissionId=${id}`;
};

export const getHiddenFields = (uiSchema: UISchema, content: string) => {
  const accumulator = [];

  collectHiddenFields(uiSchema, content, accumulator);

  return accumulator;
};

// The `getHiddenFields` function traverses the `uiSchema` object to identify fields
// that should be omitted from rendering based on certain conditions.
// The function returns an array of paths to the fields that should be omitted.
const collectHiddenFields = (
  uiSchema: UISchema,
  content: string,
  acc: string[][],
  path: string[] = []
) => {
  const elements = uiSchema?.elements || [];

  elements.forEach((element) => {
    // If a rule exists with the effect of 'SHOW',
    // then the condition for equality of the field specified by 'scope' and the 'schema.const' field is checked.
    if (element?.rule?.effect === RuleEffect.SHOW) {
      const { scope, schema } = element.rule.condition;
      const pathElements = getPathElements(scope);
      let value = content;

      for (const pathElement of pathElements) {
        if (!value[pathElement]) return;

        value = value[pathElement];
      }

      // If the condition is not met, the fields specified in the 'elements' array are omitted from rendering.
      if (schema.const !== value) {
        acc.push(
          ...element.elements.map((el) => {
            return getPathElements(el.scope);
          })
        );
      }
    }

    collectHiddenFields(element, content, acc, path);
  });
};

// The `getDataWithoutFields` function removes fields from a copy of the `data` object based on the paths provided.
export const getDataWithoutFields = (
  data: string,
  fieldToSkipPaths: string[][]
) => {
  const newData = JSON.parse(JSON.stringify(data));

  fieldToSkipPaths.forEach((path) => {
    const lastElement = path.pop();
    let value = newData;

    for (const pathElement of path) {
      if (!value[pathElement]) return;

      value = value[pathElement];
    }

    delete value[lastElement];
  });

  return newData;
};

// The `getPathElements` function splits the `scope` string (from jsonforms/uiSchema) into an array of path elements.
export const getPathElements = (scope: string) => {
  return scope
    .split("/")
    .filter(
      (pathElement) => pathElement !== "#" && pathElement !== "properties"
    );
};
