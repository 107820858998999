import {
  Autocomplete,
  BaseTextFieldProps,
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  OutlinedTextFieldProps,
  Radio,
  RadioGroup,
  SxProps,
  TextField, // Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ReactNode, useMemo } from "react";
import {
  Control,
  Controller,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormTrigger,
  useFormState,
} from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DatePicker, {
  DatePickerProps,
} from "@/components/common/datetimePicker/DatePicker";
import Quill, { QuillProps, hasText } from "@/components/common/quill/quill";
import { DATE_FORMATS } from "@/config";
import { GREY } from "@/config/mui/colorPalette";
import { isAndroidDevice } from "@/utils/device";
import { US_STATES_SHORT } from "@/utils/statesCodes";

export type FieldProps<T> = {
  choices?: { value: string; label: string }[];
  InputAdornment?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<T, any>;
  name: Path<T>;
  label?: string | ReactNode;
  setValue?: UseFormSetValue<T>;
  trigger?: UseFormTrigger<T>;
  defaultValue?: PathValue<T, Path<T> & string>;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  sx?: SxProps<Theme>;
};

function FormTextField<T>(
  props: FieldProps<T> & Omit<OutlinedTextFieldProps, "variant">
) {
  const isEmailField = props.name === "email";
  const emailInputProps = { autoCapitalize: "none", type: "email" };

  return (
    <Controller
      name={props.name}
      defaultValue={props.defaultValue}
      control={props.control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            label={props.label}
            type={props.type}
            {...field}
            multiline={props.multiline}
            rows={props.rows}
            placeholder={props.placeholder}
            slotProps={{
              input: {
                startAdornment: props.InputAdornment ? (
                  <InputAdornment position="start">
                    {props.InputAdornment}
                  </InputAdornment>
                ) : undefined,
                ...props.slotProps?.input,
              },

              htmlInput: {
                ...props.slotProps?.htmlInput,
                ...(isEmailField && emailInputProps),
              },

              inputLabel: {
                shrink: !!field.value || !!props.InputAdornment,
              },
            }}
            minRows={props.minRows}
            fullWidth
            error={!!error}
            helperText={error ? error.message : ""}
            onClick={props.onClick}
          />
        );
      }}
    />
  );
}

function FormRadioField<T>(props: FieldProps<T> & BaseTextFieldProps) {
  const buttons = useMemo(() => {
    return props.choices.map((choice) => {
      return (
        <FormControlLabel
          key={choice.label}
          value={choice.value}
          label={choice.label}
          control={<Radio />}
        />
      );
    });
  }, [props.choices]);

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return (
          <Box>
            <Typography
              variant="labelDefault"
              sx={{
                color: "secondary.main",
                textAlign: "left",
              }}
            >
              {props.label}
            </Typography>
            <RadioGroup {...field}>{buttons}</RadioGroup>
            <FormHelperText error={!!error}>
              {(error?.message as string) ?? ""}
            </FormHelperText>
          </Box>
        );
      }}
    />
  );
}

function FormDateField<T>(
  props: FieldProps<T> &
    BaseTextFieldProps & {
      minDate?: Date;
      maxDate?: Date;
      disableFuture?: boolean;
    }
) {
  const { errors } = useFormState({ control: props.control });
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...field}
              sx={props.sx as DatePickerProps["sx"]}
              minDate={props.minDate}
              maxDate={props.maxDate}
              disableFuture={props.disableFuture}
              value={field.value}
              label={props.label}
              format={DATE_FORMATS.DATE_PICKER}
              slotProps={{
                textField: {
                  error: !!errors[props.name as string],
                  helperText:
                    !!errors[props.name as string] &&
                    (errors[props.name].message as string),

                  fullWidth: true,
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}

function FormCheckbox<T>(props: FieldProps<T> & CheckboxProps) {
  return (
    <Controller<T>
      name={props.name}
      control={props.control}
      defaultValue={props.defaultChecked as unknown as PathValue<T, Path<T>>}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        return (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={Boolean(field.value)}
                  sx={{
                    color: error ? "error.main" : undefined,
                    alignSelf: "flex-start",
                  }}
                />
              }
              label={
                <Typography
                  variant="labelDefault"
                  sx={{
                    color: "secondary.main",
                    mt: 1,
                  }}
                >
                  {props.label}
                </Typography>
              }
            />
            <FormHelperText error={!!error}>
              {error?.message ?? ""}
            </FormHelperText>
          </>
        );
      }}
    />
  );
}

const transformPhoneNumber = (value: string | null | undefined): string => {
  return value == null ? "" : value;
};

const isPhoneFieldValid = (value: string) => {
  const phone = transformPhoneNumber(value);
  return isValidPhoneNumber(phone);
};

function FormPhoneField<T>(props: FieldProps<T>) {
  const label = props.label || "Phone";

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { isDirty, error }, formState }) => {
        const value = field.value;
        const displayPhoneValidationError =
          (isDirty && !!value && !isPhoneFieldValid(field.value as string)) ||
          error;
        const defaultValue =
          props.name in formState.defaultValues
            ? (formState.defaultValues as Record<string, string>)[props.name]
            : "";

        return (
          <div className="PhoneInputContainer">
            <Box className="PhoneInputBox" sx={{ ...props.sx }}>
              <PhoneInput
                value={defaultValue}
                onChange={(value) =>
                  field.onChange(transformPhoneNumber(value))
                }
                defaultCountry="US"
                limitMaxLength
                countryCallingCodeEditable={false}
                international
                readOnly={props.readOnly}
                disabled={props.readOnly}
                required={props.required}
                smartCaret={isAndroidDevice() ? false : true} // this is because Android phones are not handling caret positioning properly
              />

              <legend
                className={`PhoneNumberLabel ${
                  !value ? "PhoneNumberLabelEmpty" : ""
                } ${displayPhoneValidationError ? "error" : ""}`}
              >
                {label}
              </legend>
              <fieldset
                className={`PhoneInputLabelNotch ${
                  displayPhoneValidationError ? "error" : ""
                }`}
              >
                <legend
                  className={`PhoneInputLabelNotchPlaceholder ${
                    !value ? "PhoneInputLabelNotchPlaceholderHidden" : ""
                  }`}
                >
                  <span>{label}</span>
                </legend>
              </fieldset>
            </Box>
            {displayPhoneValidationError && (
              <Typography
                sx={{
                  position: "relative",
                  color: "error.main",
                  marginTop: "3px",
                  marginLeft: "14px",
                  fontSize: "0.75rem",
                }}
              >
                {error?.message || "Enter a valid phone number."}
              </Typography>
            )}
          </div>
        );
      }}
    />
  );
}

// TODO: Adjust FormStateField to use FormSelectField
function FormStateField<T>(props: FieldProps<T> & BaseTextFieldProps) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <Autocomplete
          options={US_STATES_SHORT}
          slotProps={{
            paper: {
              sx: {
                borderRadius: 3,
                boxShadow: "0px 4px 15px rgba(56, 19, 60, 0.25)",
                border: "1px solid",
                borderColor: GREY[20],
              },
            },
          }}
          value={(field?.value as string) || null}
          onChange={(_, data) => onChange(data)}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              error={!!error}
              helperText={error ? error.message : ""}
              label="State"
            />
          )}
        />
      )}
    />
  );
}

// TODO: Adjust FormStateField to use FormSelectField
function FormSelectField<T>(
  props: FieldProps<T> &
    BaseTextFieldProps & { options: { id: string; label: string }[] }
) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          options={props.options}
          slotProps={{
            paper: {
              sx: {
                borderRadius: 3,
                boxShadow: "0px 4px 15px rgba(56, 19, 60, 0.25)",
                border: "1px solid",
                borderColor: GREY[20],
              },
            },
          }}
          value={field.value as { id: string; label: string }}
          disabled={props.disabled}
          onChange={(_, value) => onChange(value)}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              disabled={props.disabled}
              helperText={error ? error.message : ""}
              label={props.label}
            />
          )}
        />
      )}
    />
  );
}

function FormRichTextField<T>(props: FieldProps<T> & QuillProps) {
  const theme = useTheme();
  return (
    <Controller
      name={props.name}
      defaultValue={props.defaultValue}
      control={props.control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Box>
            <Quill
              placeholder={props.placeholder}
              onChange={(value) => field.onChange(hasText(value) ? value : "")}
              sx={{
                borderRadius: "12px",
                ...props.sx,
                ...(error
                  ? {
                      "& .ql-toolbar": {
                        ...(props.sx ? props.sx["& .ql-toolbar"] : {}),
                        borderColor: `${theme.palette.error.main} !important`,
                      },
                      "& .ql-container": {
                        ...(props.sx ? props.sx["& .ql-container"] : {}),
                        borderColor: `${theme.palette.error.main} !important`,
                      },
                    }
                  : {}),
              }}
              value={field.value as string}
              readOnly={props.readOnly}
            />

            <FormHelperText error={!!error} sx={{ marginX: "14px" }}>
              {(error?.message as string) ?? ""}
            </FormHelperText>
          </Box>
        );
      }}
    />
  );
}

export {
  FormCheckbox,
  FormDateField,
  FormPhoneField,
  FormRadioField,
  FormRichTextField,
  FormSelectField,
  FormStateField,
  FormTextField,
};
