// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type RescheduleMdMeetingMutationVariables = Types.Exact<{
  bookingFlow: Types.Scalars["String"]["input"];
  details: Types.Scalars["String"]["input"];
  endTime: Types.Scalars["DateTime"]["input"];
  attendeesIds:
    | Array<Types.InputMaybe<Types.Scalars["BigInt"]["input"]>>
    | Types.InputMaybe<Types.Scalars["BigInt"]["input"]>;
  startTime: Types.Scalars["DateTime"]["input"];
  mdMeetingId: Types.Scalars["BigInt"]["input"];
  sendCommunication: Types.Scalars["Boolean"]["input"];
  format: Types.Scalars["String"]["input"];
}>;

export type RescheduleMdMeetingMutation = {
  __typename?: "mutation_root";
  rescheduleMdMeeting?: {
    __typename?: "RescheduleMDMeeting";
    message?: string | null;
    ok?: boolean | null;
    mdMeeting?: {
      __typename?: "MDMeetingType";
      created: string;
      endTime?: string | null;
      id: string;
      modified: string;
      startTime?: string | null;
    } | null;
  } | null;
};

export const RescheduleMdMeetingDocument = gql`
  mutation RescheduleMdMeeting(
    $bookingFlow: String!
    $details: String!
    $endTime: DateTime!
    $attendeesIds: [BigInt]!
    $startTime: DateTime!
    $mdMeetingId: BigInt!
    $sendCommunication: Boolean!
    $format: String!
  ) {
    rescheduleMdMeeting(
      bookingFlow: $bookingFlow
      details: $details
      endTime: $endTime
      attendeesIds: $attendeesIds
      startTime: $startTime
      mdMeetingId: $mdMeetingId
      sendCommunication: $sendCommunication
      format: $format
    ) {
      mdMeeting {
        created
        endTime
        id
        modified
        startTime
      }
      message
      ok
    }
  }
`;
export type RescheduleMdMeetingMutationFn = Apollo.MutationFunction<
  RescheduleMdMeetingMutation,
  RescheduleMdMeetingMutationVariables
>;

/**
 * __useRescheduleMdMeetingMutation__
 *
 * To run a mutation, you first call `useRescheduleMdMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleMdMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleMdMeetingMutation, { data, loading, error }] = useRescheduleMdMeetingMutation({
 *   variables: {
 *      bookingFlow: // value for 'bookingFlow'
 *      details: // value for 'details'
 *      endTime: // value for 'endTime'
 *      attendeesIds: // value for 'attendeesIds'
 *      startTime: // value for 'startTime'
 *      mdMeetingId: // value for 'mdMeetingId'
 *      sendCommunication: // value for 'sendCommunication'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useRescheduleMdMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleMdMeetingMutation,
    RescheduleMdMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleMdMeetingMutation,
    RescheduleMdMeetingMutationVariables
  >(RescheduleMdMeetingDocument, options);
}
export type RescheduleMdMeetingMutationHookResult = ReturnType<
  typeof useRescheduleMdMeetingMutation
>;
export type RescheduleMdMeetingMutationResult =
  Apollo.MutationResult<RescheduleMdMeetingMutation>;
export type RescheduleMdMeetingMutationOptions = Apollo.BaseMutationOptions<
  RescheduleMdMeetingMutation,
  RescheduleMdMeetingMutationVariables
>;
