import {
  ApolloClient,
  ApolloClientOptions,
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from "@apollo/client";
import { restoreMoxieMobileCache } from "./restoreMoxieMobileCache";

const isServerSide = typeof window === "undefined";

export class MoxieMobileApolloClient<
  TCacheShape,
> extends ApolloClient<TCacheShape> {
  isCacheInitialized: boolean;
  cacheInitializePromise: Promise<unknown>;

  constructor(options: ApolloClientOptions<TCacheShape>) {
    super(options);

    if (isServerSide) {
      this.isCacheInitialized = true;
      this.cacheInitializePromise = Promise.resolve();
      return;
    }

    this.isCacheInitialized = false;
    this.cacheInitializePromise = restoreMoxieMobileCache(this.cache).finally(
      () => {
        this.isCacheInitialized = true;
      }
    );
  }

  async query<
    T = unknown,
    TVariables extends OperationVariables = OperationVariables,
  >(options: QueryOptions<TVariables, T>): Promise<ApolloQueryResult<T>> {
    if (!this.isCacheInitialized && options.fetchPolicy !== "no-cache") {
      await this.cacheInitializePromise;
    }

    return super.query(options);
  }

  async mutate<
    TData = unknown,
    TVariables extends OperationVariables = OperationVariables,
    TContext extends Record<string, unknown> = DefaultContext,
  >(
    options: MutationOptions<TData, TVariables, TContext>
  ): Promise<FetchResult<TData>> {
    if (!this.isCacheInitialized) {
      await this.cacheInitializePromise;
    }

    return super.mutate(options);
  }
}
