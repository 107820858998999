// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type UserMedSpaFeaturePermissionsQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type UserMedSpaFeaturePermissionsQuery = {
  __typename?: "query_root";
  userMedspaFeaturePermissions: Array<{
    __typename?: "UserMedspaFeaturePermissions";
    codename?: string | null;
  }>;
};

export const UserMedSpaFeaturePermissionsDocument = gql`
  query UserMedSpaFeaturePermissions($id: bigint!) {
    userMedspaFeaturePermissions(where: { userMedspaId: { _eq: $id } }) {
      codename
    }
  }
`;

/**
 * __useUserMedSpaFeaturePermissionsQuery__
 *
 * To run a query within a React component, call `useUserMedSpaFeaturePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMedSpaFeaturePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMedSpaFeaturePermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserMedSpaFeaturePermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserMedSpaFeaturePermissionsQuery,
    UserMedSpaFeaturePermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserMedSpaFeaturePermissionsQuery,
    UserMedSpaFeaturePermissionsQueryVariables
  >(UserMedSpaFeaturePermissionsDocument, options);
}
export function useUserMedSpaFeaturePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserMedSpaFeaturePermissionsQuery,
    UserMedSpaFeaturePermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserMedSpaFeaturePermissionsQuery,
    UserMedSpaFeaturePermissionsQueryVariables
  >(UserMedSpaFeaturePermissionsDocument, options);
}
export function useUserMedSpaFeaturePermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserMedSpaFeaturePermissionsQuery,
    UserMedSpaFeaturePermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserMedSpaFeaturePermissionsQuery,
    UserMedSpaFeaturePermissionsQueryVariables
  >(UserMedSpaFeaturePermissionsDocument, options);
}
export type UserMedSpaFeaturePermissionsQueryHookResult = ReturnType<
  typeof useUserMedSpaFeaturePermissionsQuery
>;
export type UserMedSpaFeaturePermissionsLazyQueryHookResult = ReturnType<
  typeof useUserMedSpaFeaturePermissionsLazyQuery
>;
export type UserMedSpaFeaturePermissionsSuspenseQueryHookResult = ReturnType<
  typeof useUserMedSpaFeaturePermissionsSuspenseQuery
>;
export type UserMedSpaFeaturePermissionsQueryResult = Apollo.QueryResult<
  UserMedSpaFeaturePermissionsQuery,
  UserMedSpaFeaturePermissionsQueryVariables
>;
