import { BusinessHoursInput } from "@fullcalendar/core";
import { useMemo } from "react";
import {
  ProviderWorkingHoursQuery,
  useProviderWorkingHoursQuery,
} from "@/graphql/queries/workingHours.graphql.types";

const mapWeekDayToFullCalendarDayOfWeek = (weekDay: string) =>
  [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ].indexOf(weekDay);

export const mapAvailabilityToBusinessHours = (
  availability: ProviderWorkingHoursQuery["recurringAvailability"][number]
) => ({
  daysOfWeek: [mapWeekDayToFullCalendarDayOfWeek(availability.weekday)],
  startTime: availability.startTime,
  endTime: availability.endTime,
});

export function useProviderBusinessHours(
  providerId?: string
): BusinessHoursInput {
  const { data: workingHoursData } = useProviderWorkingHoursQuery({
    variables: {
      providerId,
    },
    skip: !providerId,
  });

  return useMemo(() => {
    if (!workingHoursData) return false;

    return workingHoursData.recurringAvailability.map(
      mapAvailabilityToBusinessHours
    );
  }, [workingHoursData]);
}
