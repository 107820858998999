import {
  ApolloLink,
  ApolloProvider,
  DefaultContext,
  from,
  NormalizedCacheObject,
  Operation,
  split,
} from "@apollo/client";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth } from "@clerk/nextjs";
import { GetToken, SignOut } from "@clerk/types";
import merge from "deepmerge";
import { GraphQLError } from "graphql";
import { isEqual } from "lodash";
import { AppProps } from "next/app";
import { ReactNode, useMemo } from "react";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { HASURA_CLIENT_TOKEN_KEY } from "@/config";
import { MoxieMobileApolloProviderWrapper } from "@/lib/moxieMobile/moxieMobileApollo";
import {
  CLERK_JWT_HASURA_TEMPLATE,
  HASURA_INVALID_JWT_ERROR_CODE,
} from "@/types";
import { DjangoMutationError } from "@/utils/djangoMutationError";

type PageProps<Props = Record<string, unknown>> = {
  props: Props;
};

const getGraphqlURI = () => {
  // This application makes server-side and client-side calls to Hasura.
  // In dev, we need to use a different hostname for server-side requests.
  // This function helps us assign the correct hostname to each request.
  const isServer = typeof window === "undefined" ? true : false;
  if (isServer) return process.env.GRAPHQL_SERVERSIDE_URI as string;

  return process.env.NEXT_PUBLIC_GRAPHQL_URI as string;
};

export const APOLLO_STATE_PROPERTY_NAME = "__APOLLO_STATE__";
export const COOKIES_TOKEN_NAME = "auth";

const isMutation = ({ query }: Operation) => {
  const definition = getMainDefinition(query);

  return "operation" in definition && definition.operation === "mutation";
};

const getAuthorizationHeader = async (getClerkToken: GetToken) => {
  const token = await getClerkToken(CLERK_JWT_HASURA_TEMPLATE);
  if (token) {
    return {
      authorization: `Bearer ${token}`,
    };
  }
  return {};
};

let apolloClient: ApolloClient<NormalizedCacheObject> = null;

type ApolloContext =
  | { noAuth?: boolean; headers: { authorization: string } }
  | {
      noAuth: boolean;
      headers: { [HASURA_CLIENT_TOKEN_KEY]: string };
    };

const createApolloClient = (getClerkToken: GetToken, signOut?: SignOut) => {
  const authLink = setContext(async (_, prevContext: DefaultContext) => {
    // Get the authentication token from cookie
    const previousContext = prevContext as ApolloContext;

    const isClientTokenAuth =
      previousContext?.headers &&
      HASURA_CLIENT_TOKEN_KEY in previousContext.headers;

    const newHeaders =
      !previousContext?.noAuth && !isClientTokenAuth
        ? await getAuthorizationHeader(getClerkToken)
        : {};

    return {
      headers: {
        ...previousContext?.headers,
        ...newHeaders,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ extensions }) => {
        const code = extensions?.code;
        if (code === HASURA_INVALID_JWT_ERROR_CODE) {
          signOut &&
            signOut().catch((e) => {
              console.error(`[Apollo graphQLErrors handler error]: ${e}`);
              return false;
            });
        }
        return;
      });
  });

  const handleCustomErrorsLink = new ApolloLink((operation, forward) => {
    if (isMutation(operation)) {
      return forward(operation).map((response) => {
        if (response.errors) {
          return response;
        }
        const dataKey = Object.keys(response.data)[0];

        if ("ok" in response.data[dataKey] && !response.data[dataKey].ok) {
          const { message } = response.data[dataKey];

          if (message) {
            response.errors = [
              new DjangoMutationError(message),
              ...(response.errors || []),
            ];
          } else {
            response.errors = [
              new GraphQLError(
                "Unexpected response from Django custom mutation. No message provided"
              ),
              ...(response.errors || []),
            ];
          }
        }
        return response;
      });
    } else return forward(operation);
  });

  const httpLink = from([
    authLink,
    errorLink,
    new HttpLink({
      uri: getGraphqlURI(),
      credentials: "include",
      fetchOptions: {
        mode: "cors",
      },
    }),
  ]);

  const wsLink = () => {
    return new WebSocketLink(
      new SubscriptionClient(getGraphqlURI().replace("http", "ws"), {
        reconnect: true,
        connectionParams: async () => ({
          headers: {
            Authorization: `Bearer ${await getClerkToken(
              CLERK_JWT_HASURA_TEMPLATE
            )}`,
          },
        }),
        lazy: true,
      })
    );
  };

  const splitLink = () => {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink(),
      httpLink
    );
  };

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([
      handleCustomErrorsLink,
      typeof window === "undefined" ? httpLink : splitLink(),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        medspa: {
          fields: {
            configuration: {
              // fixes error: configuration medspa either ensure all objects
              // of type MedspasMedspaconfiguration have an ID or a custom merge function
              merge: true,
            },
          },
        },
      },
    }),
  });
};

export function initializeApollo(
  initialState = null,
  getClerkToken: GetToken,
  signOut?: SignOut
) {
  const client = apolloClient ?? createApolloClient(getClerkToken, signOut);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function addApolloState<T>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: PageProps<T>
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROPERTY_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo<Props = Record<string, unknown>>(props: Props) {
  const { getToken: getClerkToken, signOut } = useAuth();
  const state = props[APOLLO_STATE_PROPERTY_NAME];
  const store = useMemo(
    () => initializeApollo(state, getClerkToken, signOut),
    [state, getClerkToken, signOut]
  );

  return store;
}

interface BaseApolloProviderWrapperProps {
  children: ReactNode;
  pageProps: AppProps["pageProps"];
}

export const BaseApolloProviderWrapper = ({
  children,
  pageProps,
}: BaseApolloProviderWrapperProps) => {
  const apolloClient = useApollo(pageProps);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

interface ApolloProviderWrapperProps {
  isMoxieMobile: boolean;
  children: ReactNode;
  pageProps: AppProps["pageProps"];
}

export const ApolloProviderWrapper = ({
  isMoxieMobile,
  children,
  pageProps,
}: ApolloProviderWrapperProps) => {
  if (isMoxieMobile) {
    return (
      <MoxieMobileApolloProviderWrapper pageProps={pageProps}>
        {children}
      </MoxieMobileApolloProviderWrapper>
    );
  }

  return (
    <BaseApolloProviderWrapper pageProps={pageProps}>
      {children}
    </BaseApolloProviderWrapper>
  );
};
