import { useClerk } from "@clerk/nextjs";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  capitalize,
} from "@mui/material";
import { useRouter } from "next/router";
import { useState } from "react";
import { useUser } from "@/auth/useUser";
import {
  DROPDOWN_MENU_ITEM_SX,
  DROPDOWN_MENU_SX,
} from "@/components/common/dropdownMenu/dropdownMenu";
import { DJANGO_ADMIN_STOP_IMPERSONATION_URL } from "@/config";
import { GREY, VIOLET } from "@/config/mui/colorPalette";
import useMoxieMediaQuery from "@/hooks/misc/useMoxieMediaQuery";
import { MenuHorizontalIcon } from "../icons";

type ActionButton = {
  label: string;
  href?: string;
  target?: string;
  onClick?: () => Promise<void>;
};
export const IMPERSONATION_BAR_HEIGHT = "50px";

const generateRoleName = (role: string, isProviderOwner, isGfeReviewer) => {
  switch (role) {
    case isGfeReviewer:
      return "GFE Reviewer";
    case isProviderOwner:
      return "Provider Owner";
    case "front_desk":
      return "FDS";
    case "medical_director":
      return "MD";
    case undefined:
      return "Unknown Role";
    default:
      return capitalize(role);
  }
};

const ImpersonationBar = () => {
  const { user, userMedspa } = useUser();
  const router = useRouter();
  const { signOut } = useClerk();
  const [mobileMenuTarget, setMobileMenuTarget] = useState(null);
  const isMobile = useMoxieMediaQuery("ipad-ver", "down");

  const { impersonatedId, role, displayName } = user;
  const mobileMenuOpen = !!mobileMenuTarget;

  const actionButtons = [
    {
      label: "Switch",
      target: "_blank",
      href: DJANGO_ADMIN_STOP_IMPERSONATION_URL,
    },
    { label: "Reload", href: "/" },
    {
      label: "Exit",
      onClick: async () => {
        await signOut();
        router.push(DJANGO_ADMIN_STOP_IMPERSONATION_URL);
      },
    },
  ];

  const toggleMobileMenu = (event) => {
    setMobileMenuTarget(event.currentTarget);
  };
  const closeMobileMenu = () => {
    setMobileMenuTarget(null);
  };

  if (!impersonatedId) return null;

  return (
    <AppBar position="static" sx={{ backgroundColor: VIOLET[100] }}>
      <Toolbar
        variant="dense"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: IMPERSONATION_BAR_HEIGHT,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="paragraphSmall"
            sx={{
              fontWeight: "bold",
            }}
          >
            {displayName}
          </Typography>

          <Typography variant="paragraphSmall" color={GREY[15]}>
            {generateRoleName(
              role,
              userMedspa?.isProviderOwner,
              userMedspa?.isGfeReviewer
            )}{" "}
            @ {userMedspa?.medspa.name || "Unknown Medspa"}
          </Typography>
        </Stack>

        {isMobile ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleMobileMenu}
          >
            <MenuHorizontalIcon color="white" />
          </IconButton>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <DesktopButtons actionButtons={actionButtons} />
          </Stack>
        )}

        {/* Mobile Menu Drawer */}
        <Menu
          anchorEl={mobileMenuTarget}
          open={mobileMenuOpen}
          onClose={closeMobileMenu}
          slotProps={{
            paper: {
              sx: DROPDOWN_MENU_SX,
            },
          }}
          sx={{
            "& .MuiMenuItem-root": DROPDOWN_MENU_ITEM_SX,
          }}
        >
          <MobileMenuItems actionButtons={actionButtons} />
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

const DesktopButtons = ({
  actionButtons,
}: {
  actionButtons: ActionButton[];
}) => {
  return (
    <>
      {actionButtons.map((button) => (
        <Button
          key={button.label}
          component="a" // needed to override the default Link component, need hard refresh here to get new clerk token
          color="inherit"
          target={button.target}
          href={button.href}
          onClick={button.onClick}
          size="small"
        >
          {button.label}
        </Button>
      ))}
    </>
  );
};

const MobileMenuItems = ({
  actionButtons,
}: {
  actionButtons: ActionButton[];
}) => {
  return (
    <>
      {actionButtons.map((button) =>
        button.href ? (
          <MenuItem
            key={button.label}
            href={button.href}
            component="a" // needed to override the default Link component, need hard refresh here to get new clerk token
            target={button.target}
          >
            {button.label}
          </MenuItem>
        ) : (
          <MenuItem key={button.label} onClick={button.onClick}>
            {button.label}
          </MenuItem>
        )
      )}
    </>
  );
};

export default ImpersonationBar;
