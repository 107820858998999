// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ProviderWorkingHoursQueryVariables = Types.Exact<{
  providerId: Types.Scalars["bigint"]["input"];
}>;

export type ProviderWorkingHoursQuery = {
  __typename?: "query_root";
  recurringAvailability: Array<{
    __typename?: "recurringAvailability";
    id: string;
    startTime: string;
    endTime: string;
    weekday: string;
  }>;
};

export type MedSpaProvidersWorkingHoursQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type MedSpaProvidersWorkingHoursQuery = {
  __typename?: "query_root";
  recurringAvailability: Array<{
    __typename?: "recurringAvailability";
    id: string;
    startTime: string;
    endTime: string;
    weekday: string;
    provider: { __typename?: "userMedspa"; userId: string };
  }>;
};

export const ProviderWorkingHoursDocument = gql`
  query ProviderWorkingHours($providerId: bigint!) {
    recurringAvailability(where: { providerId: { _eq: $providerId } }) {
      id
      startTime
      endTime
      weekday
    }
  }
`;

/**
 * __useProviderWorkingHoursQuery__
 *
 * To run a query within a React component, call `useProviderWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderWorkingHoursQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useProviderWorkingHoursQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProviderWorkingHoursQuery,
    ProviderWorkingHoursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProviderWorkingHoursQuery,
    ProviderWorkingHoursQueryVariables
  >(ProviderWorkingHoursDocument, options);
}
export function useProviderWorkingHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderWorkingHoursQuery,
    ProviderWorkingHoursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProviderWorkingHoursQuery,
    ProviderWorkingHoursQueryVariables
  >(ProviderWorkingHoursDocument, options);
}
export function useProviderWorkingHoursSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProviderWorkingHoursQuery,
    ProviderWorkingHoursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProviderWorkingHoursQuery,
    ProviderWorkingHoursQueryVariables
  >(ProviderWorkingHoursDocument, options);
}
export type ProviderWorkingHoursQueryHookResult = ReturnType<
  typeof useProviderWorkingHoursQuery
>;
export type ProviderWorkingHoursLazyQueryHookResult = ReturnType<
  typeof useProviderWorkingHoursLazyQuery
>;
export type ProviderWorkingHoursSuspenseQueryHookResult = ReturnType<
  typeof useProviderWorkingHoursSuspenseQuery
>;
export type ProviderWorkingHoursQueryResult = Apollo.QueryResult<
  ProviderWorkingHoursQuery,
  ProviderWorkingHoursQueryVariables
>;
export const MedSpaProvidersWorkingHoursDocument = gql`
  query MedSpaProvidersWorkingHours($medspaId: bigint!) {
    recurringAvailability(
      where: { provider: { medspaId: { _eq: $medspaId } } }
    ) {
      id
      startTime
      endTime
      weekday
      provider {
        userId
      }
    }
  }
`;

/**
 * __useMedSpaProvidersWorkingHoursQuery__
 *
 * To run a query within a React component, call `useMedSpaProvidersWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedSpaProvidersWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedSpaProvidersWorkingHoursQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useMedSpaProvidersWorkingHoursQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedSpaProvidersWorkingHoursQuery,
    MedSpaProvidersWorkingHoursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MedSpaProvidersWorkingHoursQuery,
    MedSpaProvidersWorkingHoursQueryVariables
  >(MedSpaProvidersWorkingHoursDocument, options);
}
export function useMedSpaProvidersWorkingHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedSpaProvidersWorkingHoursQuery,
    MedSpaProvidersWorkingHoursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedSpaProvidersWorkingHoursQuery,
    MedSpaProvidersWorkingHoursQueryVariables
  >(MedSpaProvidersWorkingHoursDocument, options);
}
export function useMedSpaProvidersWorkingHoursSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MedSpaProvidersWorkingHoursQuery,
    MedSpaProvidersWorkingHoursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedSpaProvidersWorkingHoursQuery,
    MedSpaProvidersWorkingHoursQueryVariables
  >(MedSpaProvidersWorkingHoursDocument, options);
}
export type MedSpaProvidersWorkingHoursQueryHookResult = ReturnType<
  typeof useMedSpaProvidersWorkingHoursQuery
>;
export type MedSpaProvidersWorkingHoursLazyQueryHookResult = ReturnType<
  typeof useMedSpaProvidersWorkingHoursLazyQuery
>;
export type MedSpaProvidersWorkingHoursSuspenseQueryHookResult = ReturnType<
  typeof useMedSpaProvidersWorkingHoursSuspenseQuery
>;
export type MedSpaProvidersWorkingHoursQueryResult = Apollo.QueryResult<
  MedSpaProvidersWorkingHoursQuery,
  MedSpaProvidersWorkingHoursQueryVariables
>;
