export const isAppleDevice = () => {
  return (
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)
  );
};

export const isAndroidDevice = () => {
  return (
    typeof navigator !== "undefined" && /Android/i.test(navigator.userAgent)
  );
};
