import { useCallback, useMemo } from "react";
import { useUserMedSpaFeaturePermissionsQuery } from "@/graphql/queries/userMedSpaFeaturePermissions.graphql.types";

export type UseFeaturePermissionsResult = {
  featurePermissions: string[];
  hasFeaturePermission: (permission: string) => boolean;
};

export const useFeaturePermissions = (
  userMedSpaId: string | undefined
): UseFeaturePermissionsResult => {
  const { data: userMedspaFeaturePermissions } =
    useUserMedSpaFeaturePermissionsQuery({
      variables: {
        id: userMedSpaId,
      },
      skip: !userMedSpaId,
    });

  const featurePermissions = useMemo(() => {
    if (!userMedspaFeaturePermissions) return [];
    return userMedspaFeaturePermissions?.userMedspaFeaturePermissions.map(
      (p) => p.codename
    );
  }, [userMedspaFeaturePermissions]);

  const hasFeaturePermission = useCallback(
    (permission: string) => featurePermissions.includes(permission),
    [featurePermissions]
  );

  return {
    featurePermissions,
    hasFeaturePermission,
  };
};
