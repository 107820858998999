import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import { ReactNode, useState } from "react";
import { toast } from "react-hot-toast";
import { hasRole, useUser } from "@/auth/useUser";
import DropdownMenu, {
  DropdownMenuItems,
} from "@/components/common/dropdownMenu/dropdownMenu";
import {
  MenuHorizontalIcon,
  WarningCircleIcon,
} from "@/components/common/icons";
import { useConfirm } from "@/components/providers/confirmProvider";
import TelehealthDetailsDialog from "@/components/serviceFlow/visitDetails/telehealth/telehealthDetailsDialog";
import { IS_PRODUCTION } from "@/config";
import { ERROR_PRIMARY, PRIMARY } from "@/config/mui/colorPalette";
import { useSetVisitStatusMutation } from "@/graphql/mutations/setVisitStatus.graphql.types";
import { useDeleteTelehealthVisitDetailsMutation } from "@/graphql/mutations/telehealth/deleteTelehealthVisitDetails.graphql.types";
import { useChargeFeeAdditionalInfoLazyQuery } from "@/graphql/queries/visit/visitDropdown.graphql.types";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import useCancelAppointment, {
  checkIfItIsLateCancellation,
} from "@/hooks/serviceFlow/visitDropdown/useCancelAppointment";
import useChargeLateCancellationFee from "@/hooks/serviceFlow/visitDropdown/useChargeLateCancellationFee";
import { useChartReviewActions } from "@/hooks/serviceFlow/visitDropdown/useChartReviewActions";
import useCurrentVisitDropdownFragment from "@/hooks/serviceFlow/visitDropdown/useCurrentVisitDropdownFragment";
import useMarkAsNoShow from "@/hooks/serviceFlow/visitDropdown/useMarkAsNoShow";
import { useSelfReviewGfeItem } from "@/hooks/serviceFlow/visitDropdown/useSelfReviewGfeItem";
import { useSendGfeToReviewItem } from "@/hooks/serviceFlow/visitDropdown/useSendGfeToReviewItem";
import { useStartReviewDropdownItem } from "@/hooks/serviceFlow/visitDropdown/useStartReviewDropdownItem";
import { useIsEligibleToRequestScripts } from "@/hooks/user/useIsEligibleToRequestScripts";
import { useIsEligibleWeightLossPharmacies } from "@/hooks/user/useIsEligibleWeightLossPharmacies";
import useCompleteVisit from "@/hooks/visits/useCompleteVisit";
import {
  InvoiceStatus,
  PROVIDER,
  ReviewStatus,
  TelehealthType,
  VisitStatus,
} from "@/types";
import { REMOVE_TELEHEALTH_DETAILS_CONFIRM_MODAL_CONTENT } from "@/utils/telehealth";
import useErrorLogger from "@/utils/useErrorLogger";
import { GfeForm, hasSomeGfesFilled } from "@/utils/visit";

type VisitDropdownProps = {
  visitId: string;
  formSubmissions: GfeForm[];
  reviewStatus: ReviewStatus;
  visitStatus: VisitStatus;
};

// TODO: Adjust formSubmissions type
function VisitDropdown({
  visitId,
  formSubmissions = [],
  reviewStatus,
  visitStatus,
}: VisitDropdownProps) {
  const { newGfeFlowV1, telehealthServicesV1Enabled } = useFeatureFlags();
  const isEligibleToRequestScripts = useIsEligibleToRequestScripts();
  const eligibleWeightLossPharmacies = useIsEligibleWeightLossPharmacies();
  const logError = useErrorLogger();
  const { medspa, user, hasFeaturePermission, newPermissionsEnabledForUser } =
    useUser();
  const {
    query: { slug, tab },
    push,
  } = useRouter();
  const { getConfirm } = useConfirm();

  const { data } = useCurrentVisitDropdownFragment(visitId);

  const [getChargeFeeData, { data: chargeFeeData }] =
    useChargeFeeAdditionalInfoLazyQuery({
      variables: {
        visitId,
      },
    });

  const [setVisitStatus] = useSetVisitStatusMutation();

  const cancelAppointment = useCancelAppointment(visitId);
  const markVisitAsNoShow = useMarkAsNoShow(visitId);
  const chargeLateCancellationFee = useChargeLateCancellationFee(visitId);
  const completeVisit = useCompleteVisit({
    id: visitId,
    status: visitStatus,
    invoice: data.invoice,
  });

  const [telehealthFormType, setTelehealthFormType] = useState<"add" | "edit">(
    "add"
  );
  const [telehealthModalOpen, setTelehealthModalOpen] = useState(false);

  const [removeTelehealthDetails] = useDeleteTelehealthVisitDetailsMutation();

  const handleChangeVisitStatus = async (status: VisitStatus) => {
    try {
      await setVisitStatus({
        variables: {
          id: visitId || (slug as string),
          status,
        },
      });
    } catch (errors) {
      logError(errors);
    }
  };
  const hasCardsOnFile =
    chargeFeeData?.visitByPk?.client.stripeData.stripeCardsList.length > 0;
  const collectCardOnBooking =
    chargeFeeData?.visitByPk?.medspa.configuration.collectCardOnBooking;
  const isAlreadyInPaymentFlow =
    data?.invoice && data.invoice.status !== InvoiceStatus.DRAFT;
  const clientId = data?.client.id;

  const isEditable = [VisitStatus.SCHEDULED, VisitStatus.CONFIRMED].includes(
    visitStatus
  );

  const { selfApproveGfeItem, selfDeclineGfeItem } = useSelfReviewGfeItem(
    visitId,
    formSubmissions
  );
  const sendGfeToMdItem = useSendGfeToReviewItem(formSubmissions);

  const isChartSigned = data?.lastSignature?.length > 0;

  const { actions: chartDropdownItems, dialogComponent } =
    useChartReviewActions(
      visitId,
      visitStatus,
      reviewStatus,
      isChartSigned,
      data?.invoice?.id
    );

  const hasGfesFilled = hasSomeGfesFilled(formSubmissions);

  const pendingReview = data?.client?.pendingGfeReview?.[0];
  const lastReviewId = data?.client?.lastGfeReview?.[0]?.id;
  const backUrl = `/${medspa}/visits/${visitId}/${tab ? tab : "overview"}`;

  const startReviewItem = useStartReviewDropdownItem(
    pendingReview as { id: string; asyncReviewedAt?: string },
    lastReviewId,
    clientId,
    backUrl
  );

  const isLateCancellation = checkIfItIsLateCancellation(
    data?.appointment?.startTime,
    chargeFeeData
  );

  const getCancelAppointmentLabel = () => {
    return isLateCancellation
      ? "Late cancel appointment"
      : "Cancel appointment";
  };

  const hasTelehealthDetails = !!data.telehealthVisitDetails;

  const handleRemoveTelehealthDetails = async () => {
    const confirm = await getConfirm(
      REMOVE_TELEHEALTH_DETAILS_CONFIRM_MODAL_CONTENT
    );
    if (!confirm) return;

    const toastId = toast.loading("Removing telehealth details...");

    try {
      await removeTelehealthDetails({
        variables: {
          visitId: visitId,
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify({ __typename: "visit", id: visitId }),
            fields: {
              telehealthVisitDetails: () => undefined,
            },
          });
        },
      });

      toast.success("Telehealth details have been removed successfully!", {
        id: toastId,
      });
    } catch (e) {
      logError(e);
      toast.error("An error occurred while removing telehealth details", {
        id: toastId,
      });
    }
  };

  const telehealthDetailsInitData = data.telehealthVisitDetails && {
    ...data.telehealthVisitDetails,
    visitType: data.telehealthVisitDetails.visitType as TelehealthType,
  };

  const items: DropdownMenuItems = [
    {
      component: "Go to checkout",
      onClick: completeVisit,
      disabled: !(isEditable && hasRole(user, [PROVIDER])),
    },
    {
      component: "Mark as scheduled",
      onClick: () => handleChangeVisitStatus(VisitStatus.SCHEDULED),
      disabled: !(
        [VisitStatus.COMPLETED, VisitStatus.NO_SHOW].includes(visitStatus) &&
        hasRole(user, [PROVIDER])
      ),
    },
    {
      component: "Mark as confirmed",
      onClick: () => handleChangeVisitStatus(VisitStatus.CONFIRMED),
      disabled: !(
        visitStatus === VisitStatus.SCHEDULED &&
        (hasRole(user, [PROVIDER]) ||
          (newPermissionsEnabledForUser &&
            hasFeaturePermission("edit_appointment")))
      ),
    },
    {
      component: "Mark as no-show",
      onClick: markVisitAsNoShow,
      disabled: !(isEditable && hasRole(user, [PROVIDER])),
      divider: true,
    },
    ...(hasGfesFilled && !newGfeFlowV1
      ? [sendGfeToMdItem, selfApproveGfeItem, selfDeclineGfeItem]
      : []),
    ...(newGfeFlowV1 ? [startReviewItem] : []),
    ...chartDropdownItems,
    {
      component: "Refer to prescriber",
      onClick: () =>
        push(`/${medspa}/scripts/new?clientId=${clientId}&visitId=${visitId}`),
      disabled: !(isEligibleToRequestScripts && hasRole(user, [PROVIDER])),
    },
    {
      component: "Order Empower Patient Specific Script",
      onClick: () =>
        push(
          `/${medspa}/weight-loss-order/empower?client=${clientId}&visit=${visitId}`
        ),
      disabled: !(
        eligibleWeightLossPharmacies["empower"] && hasRole(user, [PROVIDER])
      ),
    },
    ...(!IS_PRODUCTION
      ? [
          {
            component: "Order Test Form Specific",
            onClick: () =>
              push(
                `/${medspa}/weight-loss-order/test-form?client=${clientId}&visit=${visitId}`
              ),
          },
        ]
      : []),
    {
      component: "Order Strive Patient Specific",
      onClick: () =>
        push(
          `/${medspa}/weight-loss-order/strive-503a?client=${clientId}&visit=${visitId}`
        ),
      disabled: !(
        eligibleWeightLossPharmacies["strive503A"] && hasRole(user, [PROVIDER])
      ),
    },
    {
      component: "Order Strive Patient Specific",
      onClick: () =>
        push(
          `/${medspa}/weight-loss-order/strive-503b?client=${clientId}&visit=${visitId}`
        ),
      disabled: !(
        eligibleWeightLossPharmacies["strive503B"] && hasRole(user, [PROVIDER])
      ),
    },
    {
      component: "Edit appointment",
      onClick: () => push(`/${medspa}/visits/${visitId}/edit`),
      disabled:
        !isEditable ||
        (newPermissionsEnabledForUser &&
          !hasFeaturePermission("edit_appointment")),
    },
    ...(telehealthServicesV1Enabled
      ? [
          {
            component: "Add telehealth details",
            onClick: () => {
              setTelehealthFormType("add");
              setTelehealthModalOpen(true);
            },
            disabled: hasTelehealthDetails || !hasRole(user, [PROVIDER]),
          },
          {
            component: "Edit telehealth details",
            onClick: () => {
              setTelehealthFormType("edit");
              setTelehealthModalOpen(true);
            },
            disabled: !hasTelehealthDetails || !hasRole(user, [PROVIDER]),
          },
          {
            component: "Remove telehealth details",
            onClick: handleRemoveTelehealthDetails,
            disabled: !hasTelehealthDetails || !hasRole(user, [PROVIDER]),
          },
        ]
      : []),
    // This is a default one, it could be either "Cancel appointment" or "Late cancel Appointment"
    {
      component: (
        <LazyItem
          isLoading={!chargeFeeData}
          label={getCancelAppointmentLabel()}
        />
      ),
      onClick: () =>
        chargeFeeData && cancelAppointment({ skipFeeChargeAttempt: false }),
      disabled:
        !isEditable ||
        (newPermissionsEnabledForUser &&
          !hasFeaturePermission("edit_appointment")),
    },
    // This one appears only in pair with "Late cancel appointment" as an option for providers to mark as Cancelled while in late cancellation window
    {
      component: (
        <LazyItem isLoading={!chargeFeeData} label="Cancel appointment" />
      ),
      onClick: () =>
        chargeFeeData && cancelAppointment({ skipFeeChargeAttempt: true }),
      disabled:
        !isEditable ||
        !isLateCancellation ||
        (newPermissionsEnabledForUser &&
          !hasFeaturePermission("edit_appointment")),
    },
    {
      component: (
        <LazyItem
          isLoading={!chargeFeeData}
          label="Charge late cancellation fee"
        >
          {collectCardOnBooking && !hasCardsOnFile && (
            <Tooltip title="Client has no card on file">
              <IconButton sx={{ p: 0 }}>
                <WarningCircleIcon color={ERROR_PRIMARY} size="24px" />
              </IconButton>
            </Tooltip>
          )}
        </LazyItem>
      ),
      onClick: () => chargeFeeData && chargeLateCancellationFee(),
      disabled: !(
        visitStatus === VisitStatus.CANCELLED_LATE &&
        hasRole(user, [PROVIDER]) &&
        !isAlreadyInPaymentFlow
      ),
    },
    {
      component: "Report Adverse Reaction",
      onClick: () => push(`/${medspa}/visits/${visitId}/adverse-reactions/new`),
      disabled: !(
        hasRole(user, [PROVIDER]) &&
        ![
          VisitStatus.CANCELLED_LATE,
          VisitStatus.CANCELLED,
          VisitStatus.NO_SHOW,
        ].includes(visitStatus)
      ),
    },
  ];

  const menuItems = items.filter((item) => !item.disabled);

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <DropdownMenu
        type={null}
        onMenuOpen={() => getChargeFeeData()}
        menuItems={menuItems}
        disabled={menuItems.length < 1}
        buttonVariant="icon"
        icon={<MenuHorizontalIcon color={PRIMARY} />}
      />
      {dialogComponent}
      <TelehealthDetailsDialog
        formType={telehealthFormType}
        visitId={visitId}
        initData={telehealthDetailsInitData}
        isOpen={telehealthModalOpen}
        onClose={() => setTelehealthModalOpen(false)}
      />
    </Box>
  );
}

function LazyItem({
  isLoading,
  label,
  children,
}: {
  isLoading: boolean;
  label: string;
  children?: ReactNode;
}) {
  return (
    <Stack direction="row" alignItems="center" gap={1.5}>
      <Typography variant="paragraphSmall">{label}</Typography>

      {isLoading ? (
        <CircularProgress size="16px" color="secondary" />
      ) : (
        children
      )}
    </Stack>
  );
}

export default VisitDropdown;
