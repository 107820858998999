// @ts-nocheck
import { gql } from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

export type LastChartSignatureFragment = {
  __typename?: "visit";
  lastSignature: Array<{ __typename?: "VisitsChartsignature"; id: string }>;
};

export const LastChartSignatureFragmentDoc = gql`
  fragment LastChartSignature on visit {
    lastSignature: chartSignatures(
      where: { deletedAt: { _isNull: true } }
      limit: 1
    ) {
      id
    }
  }
`;
