import { isValid, set } from "date-fns";
import React from "react";
import DatePicker, {
  type DatePickerProps,
} from "@/components/common/datetimePicker/DatePicker";

type Props = Omit<DatePickerProps, "onAccept"> & {
  fullWidth?: boolean;
  onDateChange?: React.Dispatch<Date>;
  value?: Date | null;
};

const TimeSelectorDatePicker = ({
  onDateChange,
  fullWidth = false,
  value,
  ...props
}: Props) => {
  const handleDateChange = (newDate: Date, prevDate: Date) => {
    onDateChange?.(
      set(new Date(prevDate), {
        year: newDate.getFullYear(),
        month: newDate.getMonth(),
        date: newDate.getDate(),
      })
    );
  };
  return (
    <DatePicker
      {...props}
      value={value}
      slotProps={{
        textField: {
          fullWidth: fullWidth,
        },
      }}
      onAccept={(val) => {
        const date = val as Date;

        if (isValid(date)) {
          handleDateChange(date, value);
        }
      }}
    />
  );
};

export default TimeSelectorDatePicker;
