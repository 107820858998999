import { useMemo, useState } from "react";
import { ClientsQueryResult } from "@/graphql/queries/clients.graphql.types";
import { ClientForVisitCreation } from "../visits/useCreateVisit";

// use this to have the pre-selected client at the TOP of the list
export default function usePreselectedClients(
  clientsData: ClientsQueryResult["data"]["client"],
  preselectedUser?: ClientForVisitCreation
) {
  const [preselected] = useState(preselectedUser);

  return useMemo(() => {
    if (!clientsData) return;
    if (!preselected) return clientsData;

    const otherClients = clientsData.filter(
      (client) => client.id !== preselected.id
    );
    return [preselected, ...otherClients];
  }, [clientsData, preselected]);
}
