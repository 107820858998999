// @ts-nocheck
import { gql } from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

export type ClientsFirstVisitFragment = {
  __typename?: "client";
  visits: Array<{ __typename?: "visit"; id: string }>;
};

export const ClientsFirstVisitFragmentDoc = gql`
  fragment ClientsFirstVisit on client {
    visits(
      where: { status: { _nin: ["cancelled_late", "cancelled", "no_show"] } }
      orderBy: { startTime: ASC }
      limit: 1
    ) {
      id
    }
  }
`;
