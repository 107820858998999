import { ApolloCache } from "@apollo/client";
import { CacheEvents } from "./MoxieMobileCache";

const TIMEOUT = 5000;

export const restoreMoxieMobileCache = (cache: ApolloCache<unknown>) => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      window.removeEventListener("message", handleMessage, true);
      reject(new Error("Timeout waiting for response"));
    }, TIMEOUT);

    const handleMessage = (event: MessageEvent) => {
      try {
        const response = JSON.parse(event.data);
        if (response.type === CacheEvents.onCacheInitialized) {
          window.removeEventListener("message", handleMessage, true);
          clearTimeout(timeout);
          cache.restore(response.payload);
          resolve(null);
        }
      } catch (e) {
        // Ignore invalid JSON
        // We may think about rejecting promise here.
        // However, we still have timeout which should handle general errors.
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    };

    window.addEventListener("message", handleMessage, true);

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: CacheEvents.onCacheInitialize })
    );
  });
};
