// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type AvailabilityEventListFieldsFragment = {
  __typename?: "availabilityEvent";
  id: string;
  startTime?: string | null;
  endTime?: string | null;
  eventType: string;
  label: string;
  allDay: boolean;
  providerId: string;
  userMedspa: {
    __typename?: "userMedspa";
    id: string;
    userId: string;
    user: {
      __typename?: "user";
      id: string;
      firstName: string;
      lastName: string;
    };
  };
};

export type AvailabilityEventQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type AvailabilityEventQuery = {
  __typename?: "query_root";
  availabilityEventByPk?: {
    __typename?: "availabilityEvent";
    id: string;
    label: string;
    startTime?: string | null;
    endTime?: string | null;
    eventType: string;
    allDay: boolean;
    userMedspa: {
      __typename?: "userMedspa";
      id: string;
      user: {
        __typename?: "user";
        id: string;
        firstName: string;
        lastName: string;
      };
    };
  } | null;
};

export const AvailabilityEventListFieldsFragmentDoc = gql`
  fragment availabilityEventListFields on availabilityEvent {
    id
    startTime
    endTime
    eventType
    label
    allDay
    providerId
    userMedspa {
      id
      userId
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
export const AvailabilityEventDocument = gql`
  query AvailabilityEvent($id: bigint!) {
    availabilityEventByPk(id: $id) {
      id
      label
      startTime
      endTime
      eventType
      allDay
      userMedspa {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useAvailabilityEventQuery__
 *
 * To run a query within a React component, call `useAvailabilityEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAvailabilityEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailabilityEventQuery,
    AvailabilityEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailabilityEventQuery,
    AvailabilityEventQueryVariables
  >(AvailabilityEventDocument, options);
}
export function useAvailabilityEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilityEventQuery,
    AvailabilityEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailabilityEventQuery,
    AvailabilityEventQueryVariables
  >(AvailabilityEventDocument, options);
}
export function useAvailabilityEventSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AvailabilityEventQuery,
    AvailabilityEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AvailabilityEventQuery,
    AvailabilityEventQueryVariables
  >(AvailabilityEventDocument, options);
}
export type AvailabilityEventQueryHookResult = ReturnType<
  typeof useAvailabilityEventQuery
>;
export type AvailabilityEventLazyQueryHookResult = ReturnType<
  typeof useAvailabilityEventLazyQuery
>;
export type AvailabilityEventSuspenseQueryHookResult = ReturnType<
  typeof useAvailabilityEventSuspenseQuery
>;
export type AvailabilityEventQueryResult = Apollo.QueryResult<
  AvailabilityEventQuery,
  AvailabilityEventQueryVariables
>;
