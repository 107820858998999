// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ApptHistoryFragment = {
  __typename?: "visit";
  id: string;
  startTime: string;
  reviewStatus: string;
  medspaId: string;
  provider: { __typename?: "user"; id: string };
  client: { __typename?: "client"; id: string; fullName?: string | null };
  serviceMenuItemLines: Array<{
    __typename?: "ServiceMenuItemLine";
    id: string;
    finalGfeStatus: string;
  }>;
  isChartOverdue: Array<{
    __typename?: "visitReviewStatusChangeLog";
    id: string;
  }>;
};

export type ApptHistoryProviderFragment = {
  __typename?: "userMedspa";
  id: string;
  isMdChartReviewRequired: boolean;
  percentageOfChartsToReview: number;
  user: { __typename?: "user"; id: string; fullName?: string | null };
};

export type ProviderApptHistoryTableQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
  providerId: Types.Scalars["bigint"]["input"];
  startDate: Types.Scalars["timestamptz"]["input"];
  endDate: Types.Scalars["timestamptz"]["input"];
}>;

export type ProviderApptHistoryTableQuery = {
  __typename?: "query_root";
  providers: Array<{
    __typename?: "userMedspa";
    id: string;
    isMdChartReviewRequired: boolean;
    percentageOfChartsToReview: number;
    user: { __typename?: "user"; id: string; fullName?: string | null };
  }>;
  appointments: Array<{
    __typename?: "visit";
    id: string;
    startTime: string;
    reviewStatus: string;
    medspaId: string;
    provider: { __typename?: "user"; id: string };
    client: { __typename?: "client"; id: string; fullName?: string | null };
    serviceMenuItemLines: Array<{
      __typename?: "ServiceMenuItemLine";
      id: string;
      finalGfeStatus: string;
    }>;
    isChartOverdue: Array<{
      __typename?: "visitReviewStatusChangeLog";
      id: string;
    }>;
  }>;
};

export type AllProvidersApptHistoryTableQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
  startDate: Types.Scalars["timestamptz"]["input"];
  endDate: Types.Scalars["timestamptz"]["input"];
}>;

export type AllProvidersApptHistoryTableQuery = {
  __typename?: "query_root";
  providers: Array<{
    __typename?: "userMedspa";
    id: string;
    isMdChartReviewRequired: boolean;
    percentageOfChartsToReview: number;
    user: { __typename?: "user"; id: string; fullName?: string | null };
  }>;
  appointments: Array<{
    __typename?: "visit";
    id: string;
    startTime: string;
    reviewStatus: string;
    medspaId: string;
    provider: { __typename?: "user"; id: string };
    client: { __typename?: "client"; id: string; fullName?: string | null };
    serviceMenuItemLines: Array<{
      __typename?: "ServiceMenuItemLine";
      id: string;
      finalGfeStatus: string;
    }>;
    isChartOverdue: Array<{
      __typename?: "visitReviewStatusChangeLog";
      id: string;
    }>;
  }>;
};

export const ApptHistoryFragmentDoc = gql`
  fragment ApptHistory on visit {
    id
    startTime
    reviewStatus
    medspaId
    provider {
      id
    }
    client {
      id
      fullName
    }
    serviceMenuItemLines {
      id
      finalGfeStatus
    }
    isChartOverdue: reviewStatusChangeLogs(
      where: {
        _or: [
          { oldStatus: { _eq: "overdue" } }
          { newStatus: { _eq: "overdue" } }
        ]
      }
    ) {
      id
    }
  }
`;
export const ApptHistoryProviderFragmentDoc = gql`
  fragment ApptHistoryProvider on userMedspa {
    id
    user {
      id
      fullName
    }
    isMdChartReviewRequired
    percentageOfChartsToReview
  }
`;
export const ProviderApptHistoryTableDocument = gql`
  query ProviderApptHistoryTable(
    $medspaId: bigint!
    $providerId: bigint!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    providers: userMedspa(
      where: {
        medspaId: { _eq: $medspaId }
        role: { _eq: "provider" }
        active: { _eq: true }
        user: { id: { _eq: $providerId } }
      }
    ) {
      id
      ...ApptHistoryProvider
    }
    appointments: visit(
      where: {
        medspaId: { _eq: $medspaId }
        startTime: { _gte: $startDate, _lte: $endDate }
        status: { _eq: "completed" }
        reviewStatus: { _neq: "not_required" }
        providerId: { _eq: $providerId }
      }
      orderBy: { startTime: DESC }
    ) {
      id
      ...ApptHistory
    }
  }
  ${ApptHistoryProviderFragmentDoc}
  ${ApptHistoryFragmentDoc}
`;

/**
 * __useProviderApptHistoryTableQuery__
 *
 * To run a query within a React component, call `useProviderApptHistoryTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderApptHistoryTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderApptHistoryTableQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      providerId: // value for 'providerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useProviderApptHistoryTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProviderApptHistoryTableQuery,
    ProviderApptHistoryTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProviderApptHistoryTableQuery,
    ProviderApptHistoryTableQueryVariables
  >(ProviderApptHistoryTableDocument, options);
}
export function useProviderApptHistoryTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderApptHistoryTableQuery,
    ProviderApptHistoryTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProviderApptHistoryTableQuery,
    ProviderApptHistoryTableQueryVariables
  >(ProviderApptHistoryTableDocument, options);
}
export function useProviderApptHistoryTableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProviderApptHistoryTableQuery,
    ProviderApptHistoryTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProviderApptHistoryTableQuery,
    ProviderApptHistoryTableQueryVariables
  >(ProviderApptHistoryTableDocument, options);
}
export type ProviderApptHistoryTableQueryHookResult = ReturnType<
  typeof useProviderApptHistoryTableQuery
>;
export type ProviderApptHistoryTableLazyQueryHookResult = ReturnType<
  typeof useProviderApptHistoryTableLazyQuery
>;
export type ProviderApptHistoryTableSuspenseQueryHookResult = ReturnType<
  typeof useProviderApptHistoryTableSuspenseQuery
>;
export type ProviderApptHistoryTableQueryResult = Apollo.QueryResult<
  ProviderApptHistoryTableQuery,
  ProviderApptHistoryTableQueryVariables
>;
export const AllProvidersApptHistoryTableDocument = gql`
  query AllProvidersApptHistoryTable(
    $medspaId: bigint!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    providers: userMedspa(
      where: {
        medspaId: { _eq: $medspaId }
        role: { _eq: "provider" }
        active: { _eq: true }
      }
      orderBy: { user: { firstName: ASC } }
    ) {
      id
      ...ApptHistoryProvider
    }
    appointments: visit(
      where: {
        medspaId: { _eq: $medspaId }
        startTime: { _gte: $startDate, _lte: $endDate }
        status: { _eq: "completed" }
        reviewStatus: { _neq: "not_required" }
      }
      orderBy: { startTime: DESC }
    ) {
      id
      ...ApptHistory
    }
  }
  ${ApptHistoryProviderFragmentDoc}
  ${ApptHistoryFragmentDoc}
`;

/**
 * __useAllProvidersApptHistoryTableQuery__
 *
 * To run a query within a React component, call `useAllProvidersApptHistoryTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProvidersApptHistoryTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProvidersApptHistoryTableQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAllProvidersApptHistoryTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllProvidersApptHistoryTableQuery,
    AllProvidersApptHistoryTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllProvidersApptHistoryTableQuery,
    AllProvidersApptHistoryTableQueryVariables
  >(AllProvidersApptHistoryTableDocument, options);
}
export function useAllProvidersApptHistoryTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllProvidersApptHistoryTableQuery,
    AllProvidersApptHistoryTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllProvidersApptHistoryTableQuery,
    AllProvidersApptHistoryTableQueryVariables
  >(AllProvidersApptHistoryTableDocument, options);
}
export function useAllProvidersApptHistoryTableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AllProvidersApptHistoryTableQuery,
    AllProvidersApptHistoryTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AllProvidersApptHistoryTableQuery,
    AllProvidersApptHistoryTableQueryVariables
  >(AllProvidersApptHistoryTableDocument, options);
}
export type AllProvidersApptHistoryTableQueryHookResult = ReturnType<
  typeof useAllProvidersApptHistoryTableQuery
>;
export type AllProvidersApptHistoryTableLazyQueryHookResult = ReturnType<
  typeof useAllProvidersApptHistoryTableLazyQuery
>;
export type AllProvidersApptHistoryTableSuspenseQueryHookResult = ReturnType<
  typeof useAllProvidersApptHistoryTableSuspenseQuery
>;
export type AllProvidersApptHistoryTableQueryResult = Apollo.QueryResult<
  AllProvidersApptHistoryTableQuery,
  AllProvidersApptHistoryTableQueryVariables
>;
