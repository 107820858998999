import { CacheEvents } from "./MoxieMobileCache";

const TIMEOUT = 30000;

interface SuccessEventData {
  id: string;
  body: string;
  status: "success";
  init: {
    status: number;
    statusText: string;
    headers: RequestInit["headers"];
  };
}

interface ErrorEventData {
  id: string;
  status: "error";
}

type EventData = SuccessEventData | ErrorEventData;

const sendMessage = (type: string, payload: Record<string, unknown>) => {
  const data = JSON.stringify({
    type,
    payload,
  });

  window.ReactNativeWebView?.postMessage(data);
};

const generateId = () => {
  if (self.isSecureContext) {
    return self.crypto.randomUUID();
  }

  return Math.random().toString(36).substring(2);
};

export const fetchUsingMoxieMobile = (
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> => {
  if (typeof window === "undefined") return fetch(input, init);

  return new Promise((resolve, reject) => {
    const { mode, method, body, headers } = init;
    const id = generateId();

    const payload = { id, input, body, headers, method, mode };
    sendMessage(CacheEvents.onWebViewFetch, payload);

    const isProperEvent = (receivedId: string, type: string) => {
      return receivedId === id && type === CacheEvents.onWebViewFetched;
    };

    const timeout = setTimeout(() => {
      window.removeEventListener("message", handleMessage, true);
      reject(new Error("Timeout waiting for WebView response"));
    }, TIMEOUT);

    const handleMessage = (event: MessageEvent) => {
      const handleError = () => {
        clearTimeout(timeout);
        window.removeEventListener("message", handleMessage, true);

        reject(new Error("Error while fetching data"));
      };

      const handleSuccess = ({ body, init }: SuccessEventData) => {
        clearTimeout(timeout);
        window.removeEventListener("message", handleMessage, true);

        const response = new Response(JSON.stringify(body), {
          status: init.status,
          headers: init.headers,
          statusText: init.statusText,
        });

        resolve(response);
      };

      try {
        if (!event.data || event.data === "undefined") return;
        const eventData = JSON.parse(event.data);

        if (!isProperEvent(eventData.payload.id, eventData.type)) return;
        const payload = eventData.payload as EventData;

        if (payload.status === "success") {
          handleSuccess(payload);
          return;
        }

        handleError();
      } catch (e) {
        handleError();
      }
    };

    window.addEventListener("message", handleMessage, true);
  });
};
