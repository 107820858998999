import { parsePhoneNumber } from "react-phone-number-input";
import { HYPHEN } from "@/config";

type PhoneNumberFormat = "national" | "dashed";

const stripParentheses = (value: string) => {
  return value.replace(/[()]/g, "").trim();
};

const changeSpaceToHyphen = (value: string) => {
  return value.replace(/\s/g, HYPHEN);
};

export const formatPhoneNumber = (
  phone: string,
  format: PhoneNumberFormat = "national"
) => {
  if (!phone) return "";

  const phoneNumber = parsePhoneNumber(phone);

  if (!phoneNumber) {
    return phone;
  }

  if (format === "dashed") {
    return changeSpaceToHyphen(stripParentheses(phoneNumber.formatNational()));
  }

  return `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`;
};
