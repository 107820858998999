import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import { forwardRef } from "react";
import {
  ChevronSmallDownIcon,
  ChevronSmallLeftIcon,
  ChevronSmallRightIcon,
} from "../icons";

export type DatePickerProps = React.ComponentProps<typeof MuiDatePicker>;

const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  ({ slots, ...props }, ref) => (
    <MuiDatePicker
      {...props}
      ref={ref}
      sx={props.sx}
      slots={{
        ...slots,
        toolbar: null,
        switchViewIcon: ChevronSmallDownIcon,
        leftArrowIcon: ChevronSmallLeftIcon,
        rightArrowIcon: ChevronSmallRightIcon,
      }}
      slotProps={{
        ...props.slotProps,
        textField: {
          ...props.slotProps?.textField,
          onBlur: (e) => {
            const date = new Date(e.target.value);

            if (isValid(date)) {
              props.onAccept?.(date);
            } else {
              props.onAccept?.(null);
            }
          },
        },
      }}
    />
  )
);

DatePicker.displayName = "DatePicker";
export default DatePicker;
