import { AppContext } from "next/app";

const testUserAgent = (userAgent: string) => /Moxie Suite/.test(userAgent);

export const getIsMoxieMobile = (appContext: AppContext) => {
  try {
    if (appContext.ctx.req) {
      const userAgent = appContext.ctx.req.headers["user-agent"];
      const xRequestedWith = appContext.ctx.req.headers["x-requested-with"];
      return testUserAgent(userAgent) || xRequestedWith === "com.moxie.suite";
    }

    if (typeof window !== "undefined" && window.navigator?.userAgent) {
      return testUserAgent(window.navigator.userAgent);
    }

    return false;
  } catch (e) {
    console.error("MoxieApp.getInitialProps error", e);
    return false;
  }
};
