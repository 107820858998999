import { useEffect, type EffectCallback, type DependencyList } from "react";

export function useEffectOnMount(
  effect: EffectCallback,
  deps: DependencyList
): void {
  const isServer = typeof window === "undefined";

  useEffect(() => {
    if (!isServer) {
      return effect();
    }
  }, [isServer, effect, deps]);
}

export function useOnMount(cb: EffectCallback) {
  useEffect(() => {
    const isServer = typeof window === "undefined";

    if (!isServer) {
      return cb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
