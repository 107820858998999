import { QueryHookOptions } from "@apollo/client";
import { useRouter } from "next/router";
import { Exact } from "@/__generated__/schema.graphql.types";
import { useUser } from "@/auth/useUser";
import {
  ClientBasicInfoQuery,
  useClientBasicInfoQuery,
} from "@/graphql/queries/client/basicInfo.graphql.types";

type CustomQueryOptions = Omit<
  QueryHookOptions<ClientBasicInfoQuery, Exact<{ clientId: string }>>,
  "variables"
>;

export default function useClientBasicInfo(options: CustomQueryOptions = {}) {
  const {
    query: { slug },
  } = useRouter();
  const { user } = useUser();

  return useClientBasicInfoQuery({
    variables: {
      clientId: slug as string,
    },
    skip: !slug || !user,
    ...options,
  });
}
