// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SignChartMutationVariables = Types.Exact<{
  visitId: Types.Scalars["BigInt"]["input"];
  signature: Types.Scalars["String"]["input"];
  signatureAdditionalText?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  newReviewStatus?: Types.InputMaybe<Types.VisitReviewStatusEnum>;
}>;

export type SignChartMutation = {
  __typename?: "mutation_root";
  signChart?: {
    __typename?: "SignChart";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const SignChartDocument = gql`
  mutation SignChart(
    $visitId: BigInt!
    $signature: String!
    $signatureAdditionalText: String
    $newReviewStatus: VisitReviewStatusEnum
  ) {
    signChart(
      visitId: $visitId
      signature: $signature
      signatureAdditionalText: $signatureAdditionalText
      newReviewStatus: $newReviewStatus
    ) {
      ok
      message
    }
  }
`;
export type SignChartMutationFn = Apollo.MutationFunction<
  SignChartMutation,
  SignChartMutationVariables
>;

/**
 * __useSignChartMutation__
 *
 * To run a mutation, you first call `useSignChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signChartMutation, { data, loading, error }] = useSignChartMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      signature: // value for 'signature'
 *      signatureAdditionalText: // value for 'signatureAdditionalText'
 *      newReviewStatus: // value for 'newReviewStatus'
 *   },
 * });
 */
export function useSignChartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignChartMutation,
    SignChartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignChartMutation, SignChartMutationVariables>(
    SignChartDocument,
    options
  );
}
export type SignChartMutationHookResult = ReturnType<
  typeof useSignChartMutation
>;
export type SignChartMutationResult = Apollo.MutationResult<SignChartMutation>;
export type SignChartMutationOptions = Apollo.BaseMutationOptions<
  SignChartMutation,
  SignChartMutationVariables
>;
