import { addWeeks } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { InitializeDrawerData } from "@/components/booking/createVisitDrawer";
import { useSetFollowupVisitBookedMutation } from "@/graphql/mutations/setFollowupVisitBooked.graphql.types";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import { ClientForVisitCreation } from "@/hooks/visits/useCreateVisit";

export function useInitializeBookingDrawer({
  isDrawerOpen,
  initData,
  medspa,
  weeksToAdd,
  setClient,
  setServices,
  setStart,
  setEnd,
}: {
  isDrawerOpen: boolean;
  initData: InitializeDrawerData;
  medspa: string;
  weeksToAdd?: number;
  setClient: (client: ClientForVisitCreation) => void;
  setServices: (services: string[], startDate?: Date) => void;
  setStart: (startTime: Date) => void;
  setEnd: (endTime: Date) => void;
}) {
  const { utcToMedspaZonedTime, addTimezoneOffset } = useMedspaTimezone(medspa);

  const [syncVisitData, setSyncVisitData] = useState(false);

  const [syncTimeSelector, setSyncTimeSelector] = useState(false);

  const [checkFollowupVisitBooked] = useSetFollowupVisitBookedMutation();

  const calculateNewAppointmentTime = useCallback(
    (originalTime?: string): Date | null => {
      return originalTime
        ? addWeeks(
            utcToMedspaZonedTime(new Date(originalTime)),
            weeksToAdd ?? 0
          )
        : null;
    },
    [utcToMedspaZonedTime, weeksToAdd]
  );

  useEffect(() => {
    if (isDrawerOpen && !syncVisitData && initData) {
      const visitServices =
        initData?.serviceMenuItemLines?.map(
          (service) => service.serviceMenuItemId
        ) ?? [];

      setClient(initData.client);
      setServices(visitServices);
      setSyncVisitData(true);
    }
  }, [isDrawerOpen, initData, setClient, setServices, syncVisitData]);

  useEffect(() => {
    // This is used for rebooking init
    if (isDrawerOpen && !syncTimeSelector && initData?.appointment) {
      const newAppointmentStartTime = calculateNewAppointmentTime(
        initData.appointment?.startTime
      );

      const newAppointmentEndTime = calculateNewAppointmentTime(
        initData.appointment?.endTime ?? initData.appointment?.startTime
      );

      setStart(newAppointmentStartTime);
      setEnd(newAppointmentEndTime);
      setSyncTimeSelector(true);
    }
  }, [
    syncTimeSelector,
    isDrawerOpen,
    initData?.appointment,
    calculateNewAppointmentTime,
    setStart,
    setEnd,
  ]);

  useEffect(() => {
    // This is used for new booking init using calendar click
    if (isDrawerOpen && !syncTimeSelector && initData?.calendarClickDate) {
      const withTimeZoneOffset = addTimezoneOffset(
        new Date(initData.calendarClickDate)
      );

      const newAppointmentStartTime = utcToMedspaZonedTime(withTimeZoneOffset);

      setStart(newAppointmentStartTime);
      setSyncTimeSelector(true);
    }
  }, [
    syncTimeSelector,
    utcToMedspaZonedTime,
    isDrawerOpen,
    initData?.calendarClickDate,
    setStart,
    addTimezoneOffset,
  ]);

  return {
    timeInitHandledByRebooking: syncTimeSelector,
    checkFollowupVisitBooked,
  };
}
