import { Box } from "@mui/material";
import { TelehealthType as TelehealthTypeSchema } from "@/__generated__/schema.graphql.types";
import CamIconSvg from "@/components/common/icons/camIconSvg";
import PhoneIconSvg from "@/components/common/icons/phoneIconSvg";
import { BLUE } from "@/config/mui/colorPalette";
import { TelehealthType } from "@/types";

type TelehealthDetails = {
  visitType: string;
  videoLink?: string;
};

export const getTelehealthIcon = (
  telehealthDetails?: TelehealthDetails,
  iconSize: string = "24px",
  iconColor: string = BLUE[80]
) => {
  const getIcon = () => {
    if (!telehealthDetails) {
      return <CamIconSvg size={iconSize} color={iconColor} />;
    }

    const { visitType, videoLink } = telehealthDetails;
    const type = visitType.toLocaleLowerCase();

    if (type === TelehealthType.VIDEO) {
      return (
        <CamIconSvg
          size={iconSize}
          set={videoLink ? "duotone" : "outline"}
          color={iconColor}
        />
      );
    }

    if (type === TelehealthType.PHONE) {
      return <PhoneIconSvg size={iconSize} color={iconColor} />;
    }

    return null;
  };

  const icon = getIcon();

  if (!icon) return null;

  return <Box sx={{ minWidth: iconSize, minHeight: iconSize }}>{icon}</Box>;
};

export const getTelehealthTypeSchema = (telehealthType: TelehealthType) => {
  switch (telehealthType) {
    case TelehealthType.VIDEO:
      return TelehealthTypeSchema.Video;
    case TelehealthType.PHONE:
      return TelehealthTypeSchema.Phone;
    default:
      return telehealthType satisfies never;
  }
};

export const SERVICE_EDIT_WARNING =
  "You cannot edit the appointment format after it has been created. If you want this service to be a telehealth one, you will need to create a new identical service menu item, and stop using this one.";

export const SERVICE_CREATE_WARNING =
  "You will not be able to change the appointment format for this service in the future.";

export const REMOVE_TELEHEALTH_DETAILS_MODAL_CONTENT = {
  title: "Remove telehealth details?",
  description:
    "Removing telehealth details will delete any information you’ve added. These cannot be recovered. Are you sure you want to continue?",
  discardButtonText: "Cancel",
  confirmButtonText: "Yes, remove",
};

export const REMOVE_TELEHEALTH_DETAILS_CONFIRM_MODAL_CONTENT = {
  title: "Remove telehealth details?",
  description:
    "Removing telehealth details will delete any information you’ve added. These cannot be recovered. Are you sure you want to continue?",
  discardButtonText: "Cancel",
  confirmButtonText: "Yes, remove",
};
