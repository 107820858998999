// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type FrontDeskBookingInfoQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type FrontDeskBookingInfoQuery = {
  __typename?: "query_root";
  medspaByPk?: {
    __typename?: "medspa";
    id: string;
    slug: string;
    configuration?: {
      __typename?: "MedspasMedspaconfiguration";
      collectCardOnBooking: boolean;
      maximumAdvanceBookingHours: number;
      minimumBookingNoticeHours: number;
    } | null;
  } | null;
};

export const FrontDeskBookingInfoDocument = gql`
  query FrontDeskBookingInfo($medspaId: bigint!) {
    medspaByPk(id: $medspaId) {
      id
      slug
      configuration {
        collectCardOnBooking
        maximumAdvanceBookingHours
        minimumBookingNoticeHours
      }
    }
  }
`;

/**
 * __useFrontDeskBookingInfoQuery__
 *
 * To run a query within a React component, call `useFrontDeskBookingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskBookingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskBookingInfoQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useFrontDeskBookingInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    FrontDeskBookingInfoQuery,
    FrontDeskBookingInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FrontDeskBookingInfoQuery,
    FrontDeskBookingInfoQueryVariables
  >(FrontDeskBookingInfoDocument, options);
}
export function useFrontDeskBookingInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FrontDeskBookingInfoQuery,
    FrontDeskBookingInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FrontDeskBookingInfoQuery,
    FrontDeskBookingInfoQueryVariables
  >(FrontDeskBookingInfoDocument, options);
}
export function useFrontDeskBookingInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FrontDeskBookingInfoQuery,
    FrontDeskBookingInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FrontDeskBookingInfoQuery,
    FrontDeskBookingInfoQueryVariables
  >(FrontDeskBookingInfoDocument, options);
}
export type FrontDeskBookingInfoQueryHookResult = ReturnType<
  typeof useFrontDeskBookingInfoQuery
>;
export type FrontDeskBookingInfoLazyQueryHookResult = ReturnType<
  typeof useFrontDeskBookingInfoLazyQuery
>;
export type FrontDeskBookingInfoSuspenseQueryHookResult = ReturnType<
  typeof useFrontDeskBookingInfoSuspenseQuery
>;
export type FrontDeskBookingInfoQueryResult = Apollo.QueryResult<
  FrontDeskBookingInfoQuery,
  FrontDeskBookingInfoQueryVariables
>;
