// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type CreateMdMeetingMutationVariables = Types.Exact<{
  bookedById: Types.Scalars["BigInt"]["input"];
  bookingFlow: Types.Scalars["String"]["input"];
  details: Types.Scalars["String"]["input"];
  endTime: Types.Scalars["DateTime"]["input"];
  attendeesIds:
    | Array<Types.InputMaybe<Types.Scalars["BigInt"]["input"]>>
    | Types.InputMaybe<Types.Scalars["BigInt"]["input"]>;
  startTime: Types.Scalars["DateTime"]["input"];
  format: Types.Scalars["String"]["input"];
}>;

export type CreateMdMeetingMutation = {
  __typename?: "mutation_root";
  createMdMeeting?: {
    __typename?: "CreateMDMeeting";
    message?: string | null;
    ok?: boolean | null;
    mdMeeting?: {
      __typename?: "MDMeetingType";
      created: string;
      endTime?: string | null;
      id: string;
      modified: string;
      startTime?: string | null;
    } | null;
  } | null;
};

export const CreateMdMeetingDocument = gql`
  mutation CreateMdMeeting(
    $bookedById: BigInt!
    $bookingFlow: String!
    $details: String!
    $endTime: DateTime!
    $attendeesIds: [BigInt]!
    $startTime: DateTime!
    $format: String!
  ) {
    createMdMeeting(
      bookedById: $bookedById
      bookingFlow: $bookingFlow
      details: $details
      endTime: $endTime
      attendeesIds: $attendeesIds
      startTime: $startTime
      format: $format
    ) {
      mdMeeting {
        created
        endTime
        id
        modified
        startTime
      }
      message
      ok
    }
  }
`;
export type CreateMdMeetingMutationFn = Apollo.MutationFunction<
  CreateMdMeetingMutation,
  CreateMdMeetingMutationVariables
>;

/**
 * __useCreateMdMeetingMutation__
 *
 * To run a mutation, you first call `useCreateMdMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMdMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMdMeetingMutation, { data, loading, error }] = useCreateMdMeetingMutation({
 *   variables: {
 *      bookedById: // value for 'bookedById'
 *      bookingFlow: // value for 'bookingFlow'
 *      details: // value for 'details'
 *      endTime: // value for 'endTime'
 *      attendeesIds: // value for 'attendeesIds'
 *      startTime: // value for 'startTime'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useCreateMdMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMdMeetingMutation,
    CreateMdMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMdMeetingMutation,
    CreateMdMeetingMutationVariables
  >(CreateMdMeetingDocument, options);
}
export type CreateMdMeetingMutationHookResult = ReturnType<
  typeof useCreateMdMeetingMutation
>;
export type CreateMdMeetingMutationResult =
  Apollo.MutationResult<CreateMdMeetingMutation>;
export type CreateMdMeetingMutationOptions = Apollo.BaseMutationOptions<
  CreateMdMeetingMutation,
  CreateMdMeetingMutationVariables
>;
