// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ServiceMenuItemDocumentsTableQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ServiceMenuItemDocumentsTableQuery = {
  __typename?: "query_root";
  medspaServiceMenuItem: Array<{
    __typename?: "MedspaServiceMenuItem";
    id: string;
    name: string;
    requiresConsentForms: boolean;
    requiresIntakeForms: boolean;
    requiresPostCareForms: boolean;
    requiresPreCareForms: boolean;
    preCareFormAggregate: {
      __typename?: "careFormServiceMenuItemsAggregate";
      aggregate?: {
        __typename?: "careFormServiceMenuItemsAggregateFields";
        count: number;
      } | null;
    };
    postCareFormAggregate: {
      __typename?: "careFormServiceMenuItemsAggregate";
      aggregate?: {
        __typename?: "careFormServiceMenuItemsAggregateFields";
        count: number;
      } | null;
    };
    consentFormServiceMenuItemsAggregate: {
      __typename?: "consentFormServiceMenuItemsAggregate";
      aggregate?: {
        __typename?: "consentFormServiceMenuItemsAggregateFields";
        count: number;
      } | null;
    };
    medspaServiceOfferings: Array<{
      __typename?: "MedspaServiceMenuItemMedspaServiceOfferings";
      id: string;
      serviceOffering: {
        __typename?: "MedspaServiceOffering";
        id: string;
        forms: Array<{
          __typename?: "FormsFormMedspaServiceOfferings";
          id: string;
          formId: string;
        }>;
        serviceType: { __typename?: "ServiceType"; id: string; name: string };
      };
    }>;
  }>;
};

export const ServiceMenuItemDocumentsTableDocument = gql`
  query ServiceMenuItemDocumentsTable {
    medspaServiceMenuItem(
      where: {
        deletedAt: { _isNull: true }
        medspaServiceOfferings: {
          serviceOffering: { isMedicalService: { _eq: true } }
        }
      }
      orderBy: { name: ASC }
    ) {
      id
      name
      requiresConsentForms
      requiresIntakeForms
      requiresPostCareForms
      requiresPreCareForms
      preCareFormAggregate: careFormServiceMenuItemsAggregate(
        where: {
          careform: { formType: { _eq: "precare" }, deleted: { _eq: false } }
        }
      ) {
        aggregate {
          count
        }
      }
      postCareFormAggregate: careFormServiceMenuItemsAggregate(
        where: {
          careform: { formType: { _eq: "postcare" }, deleted: { _eq: false } }
        }
      ) {
        aggregate {
          count
        }
      }
      consentFormServiceMenuItemsAggregate(
        where: { consentForm: { deleted: { _eq: false } } }
      ) {
        aggregate {
          count
        }
      }
      medspaServiceOfferings {
        id
        serviceOffering {
          id
          forms(where: { form: { archivedAt: { _isNull: true } } }) {
            id
            formId
          }
          serviceType {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useServiceMenuItemDocumentsTableQuery__
 *
 * To run a query within a React component, call `useServiceMenuItemDocumentsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceMenuItemDocumentsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceMenuItemDocumentsTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceMenuItemDocumentsTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceMenuItemDocumentsTableQuery,
    ServiceMenuItemDocumentsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ServiceMenuItemDocumentsTableQuery,
    ServiceMenuItemDocumentsTableQueryVariables
  >(ServiceMenuItemDocumentsTableDocument, options);
}
export function useServiceMenuItemDocumentsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceMenuItemDocumentsTableQuery,
    ServiceMenuItemDocumentsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServiceMenuItemDocumentsTableQuery,
    ServiceMenuItemDocumentsTableQueryVariables
  >(ServiceMenuItemDocumentsTableDocument, options);
}
export function useServiceMenuItemDocumentsTableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ServiceMenuItemDocumentsTableQuery,
    ServiceMenuItemDocumentsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ServiceMenuItemDocumentsTableQuery,
    ServiceMenuItemDocumentsTableQueryVariables
  >(ServiceMenuItemDocumentsTableDocument, options);
}
export type ServiceMenuItemDocumentsTableQueryHookResult = ReturnType<
  typeof useServiceMenuItemDocumentsTableQuery
>;
export type ServiceMenuItemDocumentsTableLazyQueryHookResult = ReturnType<
  typeof useServiceMenuItemDocumentsTableLazyQuery
>;
export type ServiceMenuItemDocumentsTableSuspenseQueryHookResult = ReturnType<
  typeof useServiceMenuItemDocumentsTableSuspenseQuery
>;
export type ServiceMenuItemDocumentsTableQueryResult = Apollo.QueryResult<
  ServiceMenuItemDocumentsTableQuery,
  ServiceMenuItemDocumentsTableQueryVariables
>;
