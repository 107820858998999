// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SetVisitStatusMutationVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
  status: Types.Scalars["String"]["input"];
}>;

export type SetVisitStatusMutation = {
  __typename?: "mutation_root";
  updateVisitByPk?: { __typename?: "visit"; id: string; status: string } | null;
};

export const SetVisitStatusDocument = gql`
  mutation SetVisitStatus($id: bigint!, $status: String!) {
    updateVisitByPk(pkColumns: { id: $id }, _set: { status: $status }) {
      id
      status
    }
  }
`;
export type SetVisitStatusMutationFn = Apollo.MutationFunction<
  SetVisitStatusMutation,
  SetVisitStatusMutationVariables
>;

/**
 * __useSetVisitStatusMutation__
 *
 * To run a mutation, you first call `useSetVisitStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVisitStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVisitStatusMutation, { data, loading, error }] = useSetVisitStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetVisitStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVisitStatusMutation,
    SetVisitStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetVisitStatusMutation,
    SetVisitStatusMutationVariables
  >(SetVisitStatusDocument, options);
}
export type SetVisitStatusMutationHookResult = ReturnType<
  typeof useSetVisitStatusMutation
>;
export type SetVisitStatusMutationResult =
  Apollo.MutationResult<SetVisitStatusMutation>;
export type SetVisitStatusMutationOptions = Apollo.BaseMutationOptions<
  SetVisitStatusMutation,
  SetVisitStatusMutationVariables
>;
