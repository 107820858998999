// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ProviderDisplayNameQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type ProviderDisplayNameQuery = {
  __typename?: "query_root";
  userByPk?: {
    __typename?: "user";
    firstName: string;
    lastName: string;
    id: string;
  } | null;
};

export const ProviderDisplayNameDocument = gql`
  query ProviderDisplayName($id: bigint!) {
    userByPk(id: $id) {
      firstName
      lastName
      id
    }
  }
`;

/**
 * __useProviderDisplayNameQuery__
 *
 * To run a query within a React component, call `useProviderDisplayNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderDisplayNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderDisplayNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderDisplayNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProviderDisplayNameQuery,
    ProviderDisplayNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProviderDisplayNameQuery,
    ProviderDisplayNameQueryVariables
  >(ProviderDisplayNameDocument, options);
}
export function useProviderDisplayNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderDisplayNameQuery,
    ProviderDisplayNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProviderDisplayNameQuery,
    ProviderDisplayNameQueryVariables
  >(ProviderDisplayNameDocument, options);
}
export function useProviderDisplayNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProviderDisplayNameQuery,
    ProviderDisplayNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProviderDisplayNameQuery,
    ProviderDisplayNameQueryVariables
  >(ProviderDisplayNameDocument, options);
}
export type ProviderDisplayNameQueryHookResult = ReturnType<
  typeof useProviderDisplayNameQuery
>;
export type ProviderDisplayNameLazyQueryHookResult = ReturnType<
  typeof useProviderDisplayNameLazyQuery
>;
export type ProviderDisplayNameSuspenseQueryHookResult = ReturnType<
  typeof useProviderDisplayNameSuspenseQuery
>;
export type ProviderDisplayNameQueryResult = Apollo.QueryResult<
  ProviderDisplayNameQuery,
  ProviderDisplayNameQueryVariables
>;
