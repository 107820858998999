// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type AddTelehealthVisitDetailsMutationVariables = Types.Exact<{
  visitId: Types.Scalars["BigInt"]["input"];
  visitType: Types.TelehealthType;
  videoLink?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  visitDetails?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type AddTelehealthVisitDetailsMutation = {
  __typename?: "mutation_root";
  createTelehealthDetails?: {
    __typename?: "CreateTelehealthDetails";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const AddTelehealthVisitDetailsDocument = gql`
  mutation AddTelehealthVisitDetails(
    $visitId: BigInt!
    $visitType: TelehealthType!
    $videoLink: String
    $visitDetails: String
  ) {
    createTelehealthDetails(
      visitId: $visitId
      visitType: $visitType
      videoLink: $videoLink
      visitDetails: $visitDetails
    ) {
      ok
      message
    }
  }
`;
export type AddTelehealthVisitDetailsMutationFn = Apollo.MutationFunction<
  AddTelehealthVisitDetailsMutation,
  AddTelehealthVisitDetailsMutationVariables
>;

/**
 * __useAddTelehealthVisitDetailsMutation__
 *
 * To run a mutation, you first call `useAddTelehealthVisitDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTelehealthVisitDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTelehealthVisitDetailsMutation, { data, loading, error }] = useAddTelehealthVisitDetailsMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      visitType: // value for 'visitType'
 *      videoLink: // value for 'videoLink'
 *      visitDetails: // value for 'visitDetails'
 *   },
 * });
 */
export function useAddTelehealthVisitDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTelehealthVisitDetailsMutation,
    AddTelehealthVisitDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTelehealthVisitDetailsMutation,
    AddTelehealthVisitDetailsMutationVariables
  >(AddTelehealthVisitDetailsDocument, options);
}
export type AddTelehealthVisitDetailsMutationHookResult = ReturnType<
  typeof useAddTelehealthVisitDetailsMutation
>;
export type AddTelehealthVisitDetailsMutationResult =
  Apollo.MutationResult<AddTelehealthVisitDetailsMutation>;
export type AddTelehealthVisitDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    AddTelehealthVisitDetailsMutation,
    AddTelehealthVisitDetailsMutationVariables
  >;
