// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type DeleteTelehealthVisitDetailsMutationVariables = Types.Exact<{
  visitId: Types.Scalars["bigint"]["input"];
}>;

export type DeleteTelehealthVisitDetailsMutation = {
  __typename?: "mutation_root";
  deleteVisitsTelehealthvisitdetailschangelog?: {
    __typename?: "VisitsTelehealthvisitdetailschangelogMutationResponse";
    returning: Array<{
      __typename?: "VisitsTelehealthvisitdetailschangelog";
      telehealthVisitDetailsId: string;
    }>;
  } | null;
  deleteTelehealthVisitDetailsByPk?: {
    __typename?: "TelehealthVisitDetails";
    visitId: string;
  } | null;
};

export const DeleteTelehealthVisitDetailsDocument = gql`
  mutation DeleteTelehealthVisitDetails($visitId: bigint!) {
    deleteVisitsTelehealthvisitdetailschangelog(
      where: { telehealthVisitDetailsId: { _eq: $visitId } }
    ) {
      returning {
        telehealthVisitDetailsId
      }
    }
    deleteTelehealthVisitDetailsByPk(visitId: $visitId) {
      visitId
    }
  }
`;
export type DeleteTelehealthVisitDetailsMutationFn = Apollo.MutationFunction<
  DeleteTelehealthVisitDetailsMutation,
  DeleteTelehealthVisitDetailsMutationVariables
>;

/**
 * __useDeleteTelehealthVisitDetailsMutation__
 *
 * To run a mutation, you first call `useDeleteTelehealthVisitDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTelehealthVisitDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTelehealthVisitDetailsMutation, { data, loading, error }] = useDeleteTelehealthVisitDetailsMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useDeleteTelehealthVisitDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTelehealthVisitDetailsMutation,
    DeleteTelehealthVisitDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTelehealthVisitDetailsMutation,
    DeleteTelehealthVisitDetailsMutationVariables
  >(DeleteTelehealthVisitDetailsDocument, options);
}
export type DeleteTelehealthVisitDetailsMutationHookResult = ReturnType<
  typeof useDeleteTelehealthVisitDetailsMutation
>;
export type DeleteTelehealthVisitDetailsMutationResult =
  Apollo.MutationResult<DeleteTelehealthVisitDetailsMutation>;
export type DeleteTelehealthVisitDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteTelehealthVisitDetailsMutation,
    DeleteTelehealthVisitDetailsMutationVariables
  >;
