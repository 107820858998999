import {
  Cache,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
} from "@apollo/client";
import EventEmitter from "eventemitter3";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Entity = Record<string, any>;
type WriteOptions = Cache.WriteOptions;
type EvictOptions = Cache.EvictOptions;
type ModifyOptions<T> = Cache.ModifyOptions<T>;
type GcOptions = {
  resetResultCache?: boolean;
  resetResultIdentities?: boolean;
};

export const CacheEvents = {
  onCacheGc: "onCacheGc",
  onCacheWrite: "onCacheWrite",
  onCacheEvict: "onCacheEvict",
  onCacheModify: "onCacheModify",
  onCacheRestored: "onCacheRestored",
  onCacheInitialize: "onCacheInitialize",
  onCacheInitialized: "onCacheInitialized",
  onWebViewFetch: "onWebViewFetch",
  onWebViewFetched: "onWebViewFetched",
};

export const cacheEvents = new EventEmitter<keyof typeof CacheEvents>();

export class MoxieMobileCache extends InMemoryCache {
  constructor(config: InMemoryCacheConfig) {
    super(config);

    cacheEvents.on("onCacheGc", this.handleGcEvent.bind(this));
    cacheEvents.on("onCacheWrite", this.handleWriteEvent.bind(this));
    cacheEvents.on("onCacheEvict", this.handleEvictEvent.bind(this));
    cacheEvents.on("onCacheModify", this.handleModifyEvent.bind(this));
    cacheEvents.on("onCacheRestored", this.handleRestoredEvent.bind(this));
  }

  private sendMobileEvent(type: string, payload?: unknown) {
    if (typeof window === "undefined") return;

    const data = JSON.stringify({ type, payload });
    window.ReactNativeWebView?.postMessage(data);
  }

  private handleRestoredEvent(cache: NormalizedCacheObject) {
    this.restore(cache);
  }

  private handleWriteEvent(options: WriteOptions) {
    this.write(options, true);
  }

  private handleEvictEvent(options: EvictOptions) {
    this.evict(options, true);
  }

  private handleModifyEvent<T extends Entity>(options: ModifyOptions<T>) {
    this.modify(options, true);
  }

  private handleGcEvent(options?: GcOptions) {
    this.gc(options, true);
  }

  override write(options: WriteOptions, skip = false) {
    const result = super.write(options);
    if (!skip) this.sendMobileEvent(CacheEvents.onCacheWrite, options);

    return result;
  }

  override evict(options: EvictOptions, skip = false) {
    const result = super.evict(options);
    if (!skip) this.sendMobileEvent(CacheEvents.onCacheEvict, options);

    return result;
  }

  override modify<T extends Entity>(options: ModifyOptions<T>, skip = false) {
    const parsedOptions = {
      ...options,
      fields: options.fields?.toString(),
    };

    const result = super.modify(options);
    if (!skip) this.sendMobileEvent(CacheEvents.onCacheModify, parsedOptions);

    return result;
  }

  override gc(options?: GcOptions, skip = false) {
    const result = super.gc(options);
    if (!skip) this.sendMobileEvent(CacheEvents.onCacheGc, options);

    return result;
  }
}
