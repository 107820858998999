// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type UpdateFormSubmissionsGfeStatusMutationVariables = Types.Exact<{
  formSubmissionIds:
    | Array<Types.Scalars["bigint"]["input"]>
    | Types.Scalars["bigint"]["input"];
  gfeStatus: Types.Scalars["String"]["input"];
  gfeCompletedByUserId?: Types.InputMaybe<Types.Scalars["bigint"]["input"]>;
}>;

export type UpdateFormSubmissionsGfeStatusMutation = {
  __typename?: "mutation_root";
  updateFormSubmissionMany?: Array<{
    __typename?: "formSubmissionMutationResponse";
    returning: Array<{
      __typename?: "formSubmission";
      id: string;
      gfeStatus: string;
      gfeCompletedById?: string | null;
    }>;
  } | null> | null;
};

export const UpdateFormSubmissionsGfeStatusDocument = gql`
  mutation UpdateFormSubmissionsGfeStatus(
    $formSubmissionIds: [bigint!]!
    $gfeStatus: String!
    $gfeCompletedByUserId: bigint
  ) {
    updateFormSubmissionMany(
      updates: {
        where: { id: { _in: $formSubmissionIds } }
        _set: { gfeStatus: $gfeStatus, gfeCompletedById: $gfeCompletedByUserId }
      }
    ) {
      returning {
        id
        gfeStatus
        gfeCompletedById
      }
    }
  }
`;
export type UpdateFormSubmissionsGfeStatusMutationFn = Apollo.MutationFunction<
  UpdateFormSubmissionsGfeStatusMutation,
  UpdateFormSubmissionsGfeStatusMutationVariables
>;

/**
 * __useUpdateFormSubmissionsGfeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateFormSubmissionsGfeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormSubmissionsGfeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormSubmissionsGfeStatusMutation, { data, loading, error }] = useUpdateFormSubmissionsGfeStatusMutation({
 *   variables: {
 *      formSubmissionIds: // value for 'formSubmissionIds'
 *      gfeStatus: // value for 'gfeStatus'
 *      gfeCompletedByUserId: // value for 'gfeCompletedByUserId'
 *   },
 * });
 */
export function useUpdateFormSubmissionsGfeStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormSubmissionsGfeStatusMutation,
    UpdateFormSubmissionsGfeStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFormSubmissionsGfeStatusMutation,
    UpdateFormSubmissionsGfeStatusMutationVariables
  >(UpdateFormSubmissionsGfeStatusDocument, options);
}
export type UpdateFormSubmissionsGfeStatusMutationHookResult = ReturnType<
  typeof useUpdateFormSubmissionsGfeStatusMutation
>;
export type UpdateFormSubmissionsGfeStatusMutationResult =
  Apollo.MutationResult<UpdateFormSubmissionsGfeStatusMutation>;
export type UpdateFormSubmissionsGfeStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateFormSubmissionsGfeStatusMutation,
    UpdateFormSubmissionsGfeStatusMutationVariables
  >;
