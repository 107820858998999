import { Chip, Stack, Typography } from "@mui/material";
import { ComponentType } from "react";
import {
  GFE_SERVICE_STATUS_ICON,
  mapToGfeChipType,
} from "@/components/common/gfe/gfeIndicationChip";
import CheckboxList from "@/components/common/list/checkboxList";
import ListAccordion, {
  DefaultParentNodeTitle,
} from "@/components/common/list/listAccordion";
import { TEXT_SECONDARY } from "@/config/mui/colorPalette";
import {
  ServiceCategoriesQuery,
  ServiceCategoriesWithClientIndicationsQuery,
} from "@/graphql/queries/settings/serviceCategories.graphql.types";
import { formattedPrice } from "@/utils";

type ServiceCategoryWithIndications =
  ServiceCategoriesWithClientIndicationsQuery["medspaByPk"]["serviceCategories"][number];

type MedspaServiceMenuItemWithIndication =
  ServiceCategoryWithIndications["medspaServiceMenuItems"][number];

type ServiceCategoryWithoutIndications =
  ServiceCategoriesQuery["medspaByPk"]["serviceCategories"][number];

type MedspaServiceMenuItemWithoutIndication =
  ServiceCategoryWithoutIndications["medspaServiceMenuItems"][number];

type MedspaServiceMenuItem =
  | MedspaServiceMenuItemWithIndication
  | MedspaServiceMenuItemWithoutIndication;

type ServiceCategory =
  | ServiceCategoryWithoutIndications
  | ServiceCategoryWithIndications;

export type ServiceCategories = ServiceCategory[];

type ServiceWithPriceProps = {
  serviceName: string;
  price: string;
  isVariablePricing: boolean;
  withGfeIndicator?: boolean;
  gfeStatus: string;
};

export type CustomRowRendererProps = Pick<
  ServiceWithPriceProps,
  "withGfeIndicator"
> & {
  category: ServiceCategory;
  disabledServicesIds?: string[];
  selectedServices: string[];
  onCheck: (serviceMenuItemId: string) => void;
  markServicesNotBookableOnline?: boolean;
  withServicePrice?: boolean;
};

const NotBookableLabel = ({ serviceName }) => (
  <Stack direction="row" spacing={0.5}>
    <Typography>{serviceName}</Typography>
    <Chip size="small" color="red" label="Not bookable online" />
  </Stack>
);

const ServiceWithPrice = ({
  serviceName,
  price,
  gfeStatus,
  isVariablePricing,
  withGfeIndicator,
}: ServiceWithPriceProps) => {
  const GfeIcon = GFE_SERVICE_STATUS_ICON[mapToGfeChipType[gfeStatus]];

  return (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Typography noWrap>{serviceName}</Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: TEXT_SECONDARY,
            flexWrap: "wrap",
            textAlign: "right",
          }}
        >
          {isVariablePricing
            ? getVariablePricingCopy(price)
            : formattedPrice(price)}
        </Typography>

        {withGfeIndicator && GfeIcon && <GfeIcon />}
      </Stack>
    </Stack>
  );
};

const DefaultRenderer: React.ComponentType<CustomRowRendererProps> = ({
  category,
  disabledServicesIds,
  selectedServices,
  onCheck,
  markServicesNotBookableOnline = false,
  withServicePrice,
  withGfeIndicator,
}) => {
  const getLabel = (service: MedspaServiceMenuItem) => {
    if (markServicesNotBookableOnline && !service.isOnlineBookingEnabled) {
      return <NotBookableLabel serviceName={service.name} />;
    }

    if (withServicePrice) {
      return (
        <ServiceWithPrice
          serviceName={service.name}
          isVariablePricing={service.isVariablePricing}
          price={service.price}
          withGfeIndicator={withGfeIndicator}
          gfeStatus={
            (service as MedspaServiceMenuItemWithIndication).clientGfeIndication
          }
        />
      );
    }

    return <Typography>{service.name}</Typography>;
  };

  return (
    <CheckboxList
      items={category.medspaServiceMenuItems.map(
        (service: MedspaServiceMenuItem) => ({
          id: service.id,
          label: getLabel(service),
          onChange: onCheck,
          isChecked: selectedServices.some(
            (serviceMenuItemId) => serviceMenuItemId === service.id
          ),
          isDisabled: disabledServicesIds.includes(service.id),
        })
      )}
      sx={{ py: 0 }}
    />
  );
};

export type GroupedServicesSelectionListProps = Pick<
  CustomRowRendererProps,
  "withServicePrice" | "withGfeIndicator" | "markServicesNotBookableOnline"
> & {
  servicesGroupedByCategory: ServiceCategories;
  disabledServicesIds: string[];
  selectedServices: string[];
  handleCheck: (serviceMenuItemId: string) => void;
  customListRenderer?: ComponentType<CustomRowRendererProps>;
};

export default function GroupedServicesSelectionList({
  servicesGroupedByCategory,
  disabledServicesIds,
  selectedServices,
  handleCheck,
  customListRenderer,
  markServicesNotBookableOnline = false,
  withServicePrice = false,
  withGfeIndicator,
}: GroupedServicesSelectionListProps) {
  const CustomRenderer = customListRenderer || DefaultRenderer;

  const items = servicesGroupedByCategory.map((category) => {
    return {
      id: category.id,
      parentNode: (
        <DefaultParentNodeTitle>{category.name}</DefaultParentNodeTitle>
      ),
      childNode: (
        <CustomRenderer
          category={category}
          disabledServicesIds={disabledServicesIds}
          selectedServices={selectedServices}
          onCheck={handleCheck}
          markServicesNotBookableOnline={markServicesNotBookableOnline}
          withServicePrice={withServicePrice}
          withGfeIndicator={withGfeIndicator}
        />
      ),
    };
  });

  return <ListAccordion items={items} />;
}

type VariablePricingCopy = `${string} + Price varies` | "Price varies";

export const getVariablePricingCopy = (
  basePrice: string
): VariablePricingCopy => {
  if (+basePrice > 0) return `${formattedPrice(basePrice)} + Price varies`;
  return "Price varies";
};
