import { Conversation } from "@twilio/conversations";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSumUnreadHighlevelMessagesLazyQuery } from "@/graphql/queries/highlevelMessages.graphql.types";
import { ConversationData } from "@/utils/messages";
import {
  getNotificationsCount,
  getMutipleRequestsError,
} from "@/utils/messages";
import useErrorLogger from "@/utils/useErrorLogger";

export const useNotifications = (
  conversations: Conversation[],
  medspaId?: number
) => {
  const logError = useErrorLogger();
  const router = useRouter();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [highlevelUnreadMessagesCount, setHighlevelUnreadMessagesCount] =
    useState(0);
  const [conversationsData, setConversationsData] = useState<
    Map<string, ConversationData>
  >(new Map());
  const isCalculatingNotificationsCount = useRef(false);
  const [fetchingHighlevelUnreadMessages, setFetchingHighlevelUnreadMessages] =
    useState(false);

  const [
    getHighlevelUnreadMessages,
    { data: highlevelUnreadMessages, refetch: refetchHighlevelUnreadMessages },
  ] = useSumUnreadHighlevelMessagesLazyQuery();

  const appendConversationsData = (sid: string, data: ConversationData) => {
    setConversationsData((prev) => {
      const newData = new Map(prev);
      if (newData.has(sid)) {
        newData.set(sid, { ...newData.get(sid), ...data });
      } else {
        newData.set(sid, data);
      }
      return newData;
    });
  };

  const calculateNotificationsCount = useCallback(async () => {
    setFetchingHighlevelUnreadMessages(true);

    if (
      isCalculatingNotificationsCount.current ||
      fetchingHighlevelUnreadMessages
    )
      return;
    if (!conversations.length) return;

    isCalculatingNotificationsCount.current = true;
    const {
      notificationsCount: count,
      getLastMessageErrorsCount,
      getUnreadMessagesCountErrorsCount,
    } = await getNotificationsCount(conversations, appendConversationsData);
    isCalculatingNotificationsCount.current = false;

    if (getLastMessageErrorsCount || getUnreadMessagesCountErrorsCount) {
      logError(
        getMutipleRequestsError(
          "getNotificationsCount()",
          getLastMessageErrorsCount,
          getUnreadMessagesCountErrorsCount
        )
      );
    }

    setNotificationsCount(count);
  }, [conversations, fetchingHighlevelUnreadMessages, logError]);

  useEffect(() => {
    if (!medspaId) {
      setHighlevelUnreadMessagesCount(0);
      return;
    }

    const fetchHighlevelUnreadMessages = async () => {
      try {
        const { data } = highlevelUnreadMessages
          ? await refetchHighlevelUnreadMessages()
          : await getHighlevelUnreadMessages({
              variables: { medspaId },
            });

        setHighlevelUnreadMessagesCount(
          data?.sumUnreadHighlevelMessages?.count ?? 0
        );
      } catch (error) {
        console.error(
          `Failed to ${highlevelUnreadMessages ? "refetch" : "fetch"} highlevel unread messages:`,
          error
        );
      } finally {
        setFetchingHighlevelUnreadMessages(false);
      }
    };

    fetchHighlevelUnreadMessages();
    router.events.on("routeChangeComplete", fetchHighlevelUnreadMessages);

    return () => {
      router.events.off("routeChangeComplete", fetchHighlevelUnreadMessages);
    };
  }, [
    getHighlevelUnreadMessages,
    highlevelUnreadMessages,
    medspaId,
    refetchHighlevelUnreadMessages,
    router.events,
  ]);

  return {
    notificationsCount,
    highlevelUnreadMessagesCount,
    calculateNotificationsCount,
    conversationsData,
  };
};
