import { useRouter } from "next/router";
import { useUser } from "@/auth/useUser";
import { useClientAccessToken } from "@/contexts/clientAccessTokenContext";
import { useMedSpaFeatureFlagsQuery } from "@/graphql/queries/medspaFeatureFlags.graphql.types";
import { useMedspaFeatureFlagsByAppointmentAccessTokenQuery } from "@/graphql/queries/medspaFeatureFlagsByAppointmentAccessToken.graphql.types";
import { useMedspaFeatureFlagsByClientAccessTokenQuery } from "@/graphql/queries/medspaFeatureFlagsByClientAccessToken.graphql.types";
import { FeatureFlags } from "@/utils/featureFlags";
import { getStripeIdsFromUserMedspa } from "@/utils/stripe";

// WARNING: DO NOT USE ON THE PUBLIC BOOKING PAGE - USE `medspaInfo.additionalPublicInfo.enabledFeatures` INSTEAD
export default function useFeatureFlags() {
  const {
    query: { appointmentAccessToken },
  } = useRouter();
  const { medspa, additionalUserDetails } = useUser();

  const { clientAccessToken: token, getTokenContext } = useClientAccessToken();

  const { stripeFinancialAccountId } =
    getStripeIdsFromUserMedspa(additionalUserDetails?.userMedspa, medspa) || {};
  const { data } = useMedSpaFeatureFlagsQuery({
    variables: {
      medspaId: medspa,
    },
    skip: !medspa,
  });

  const { data: clientAccessTokenData } =
    useMedspaFeatureFlagsByClientAccessTokenQuery({
      variables: {
        token: token as string,
      },
      skip: !token,
      ...getTokenContext(),
    });

  const { data: appointmentAccessTokenData } =
    useMedspaFeatureFlagsByAppointmentAccessTokenQuery({
      variables: {
        token: appointmentAccessToken as string,
      },
      skip: !appointmentAccessToken,
    });

  const { enabledFeatures = [] } =
    data?.medspaPublicAdditionalInfo ||
    clientAccessTokenData?.clientAccessTokenByPk?.client.medspa
      .additionalPublicInfo ||
    appointmentAccessTokenData?.appointmentPublicInfo.medspa ||
    {};

  return {
    // return all `enabledFeatures` so that is can used more directly my components
    enabledFeatures,
    // boolean to check if feature flags are still loading from BE
    featureFlagsLoading:
      !data && !clientAccessTokenData && !appointmentAccessTokenData,
    // below are specific feature flags making it easy to destructure in components and check if a feature is enabled
    balanceEnabled:
      enabledFeatures.includes(FeatureFlags.BALANCE_V1) &&
      !!stripeFinancialAccountId,
    cherryPaymentsEnabled: enabledFeatures.includes(
      FeatureFlags.CHERRY_PAYMENTS_V1
    ),
    packagesAllowRetailProducts: enabledFeatures.includes(
      FeatureFlags.PACKAGES_ALLOW_RETAIL_PRODUCTS
    ),
    messagingMaintenance: enabledFeatures.includes(
      FeatureFlags.MESSAGING_MAINTENANCE
    ),
    standingOrdersV1Enabled: enabledFeatures.includes(
      FeatureFlags.STANDING_ORDERS_V1
    ),
    affirmPaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.AFFIRM_PAYMENT_METHOD
    ),
    venmoPaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.VENMO_PAYMENT_METHOD
    ),
    zellePaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.ZELLE_PAYMENT_METHOD
    ),
    newGfeFlowV1: enabledFeatures.includes(FeatureFlags.NEW_GFE_FLOW_V1),
    newGfeFlowV2: enabledFeatures.includes(FeatureFlags.NEW_GFE_FLOW_V2),
    isMaintenanceScheduled: enabledFeatures.includes(
      FeatureFlags.IS_IN_MAINTENANCE
    ),
    consentFormInEveryAppt: enabledFeatures.includes(
      FeatureFlags.CONSENT_FORM_IN_EVERY_APPT
    ),
    invoiceSummaryUpdateServicesEnabled: enabledFeatures.includes(
      FeatureFlags.INVOICE_SUMMARY_UPDATE_SERVICES
    ),
    complianceHubV1Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_V1
    ),
    complianceHubV2Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_V2
    ),
    complianceHubV3Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_V3
    ),
    complianceHubV4Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_V4
    ),
    complianceHubApptHistoryEnabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_APPT_HISTORY
    ),
    complianceMdMeetingsV1Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_MD_MEETINGS_V1
    ),
    syncGfeV2Enabled: enabledFeatures.includes(FeatureFlags.SYNC_GFE_V2),
    comboGfeV1Enabled: enabledFeatures.includes(FeatureFlags.COMBO_GFE_V1),
    marketingMessagesV1Enabled: enabledFeatures.includes(
      FeatureFlags.MARKETING_MESSAGES_V1
    ),
    newBookingFlowEnabled: enabledFeatures.includes(
      FeatureFlags.NEW_BOOKING_FLOW_V1
    ),
    rebookingV2Enabled: enabledFeatures.includes(FeatureFlags.REBOOKING_V2),
    commandAiEnabled: enabledFeatures.includes(FeatureFlags.COMMAND_AI),
    intakeFormsEnhancementsV1Enabled: enabledFeatures.includes(
      FeatureFlags.INTAKE_FORMS_ENHANCEMENTS_V1
    ),
    onlineStoreV2Enabled: enabledFeatures.includes(
      FeatureFlags.ONLINE_STORE_V2
    ),
    moxieEmailGiftBuilderV1Enabled: enabledFeatures.includes(
      FeatureFlags.MOXIE_EMAIL_GIFT_BUILDER_V1
    ),
    marketingMessagesResponseV1Enabled:
      enabledFeatures.includes(FeatureFlags.MARKETING_MESSAGES_RESPONSE_V1) &&
      enabledFeatures.includes(FeatureFlags.MARKETING_MESSAGES_V1),
    telehealthServicesV1Enabled: enabledFeatures.includes(
      FeatureFlags.TELEHEALTH_SERVICES_V1
    ),
  };
}
