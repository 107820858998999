import {
  Divider,
  FormControlLabel,
  RadioGroup,
  Stack,
  Avatar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SlideBottomDialog, {
  SlideBottomDialogBaseProps,
} from "@/components/common/modals/slideBottomDialog";
import { MoxieRadio } from "@/components/common/radio/moxieRadio";
import { Option, Props } from "./radioOptionsTypes";

export default function RadioModal<O extends Option = Option>({
  activeOptionId: defaultFilterId,
  options,
  onOptionChange,
  onClose,
  open,
  title,
  showLogos = false,
  buttonLabel = "Show results",
}: Props<O> & SlideBottomDialogBaseProps) {
  const [activeFilterId, setActiveFilterId] = useState(defaultFilterId);

  useEffect(() => {
    if (open && defaultFilterId !== activeFilterId)
      setActiveFilterId(defaultFilterId);
  }, [open]);

  const onSubmit = () => {
    onOptionChange(options.find(({ id }) => id === activeFilterId));
    onClose();
  };

  const handleClose = () => {
    setActiveFilterId(defaultFilterId);
    onClose();
  };

  return (
    <SlideBottomDialog
      onClose={handleClose}
      open={open}
      title={title}
      onSubmit={onSubmit}
      submitButtonText={buttonLabel}
    >
      <RadioGroup
        aria-label="choose-sort-options"
        name="sort-options-radio-buttons-group"
        value={activeFilterId}
        onChange={({ target }) => setActiveFilterId(target.value)}
      >
        <Stack divider={<Divider />}>
          {options.map(({ id, label, image }) => (
            <FormControlLabel
              key={id}
              value={id}
              label={
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                  }}
                >
                  {showLogos && <Avatar src={image} alt={`${label} logo`} />}
                  <Typography className="ml-4">{label}</Typography>
                </Stack>
              }
              control={<MoxieRadio />}
              sx={{ p: 1.5, mx: 0 }}
            />
          ))}
        </Stack>
        <Divider />
      </RadioGroup>
    </SlideBottomDialog>
  );
}
