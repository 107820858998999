// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type CategoryFragment = {
  __typename?: "serviceCategory";
  id: string;
  name: string;
  medspaServiceMenuItems: Array<{
    __typename?: "MedspaServiceMenuItem";
    id: string;
    name: string;
    price: string;
    isVariablePricing: boolean;
    isOnlineBookingEnabled: boolean;
  }>;
};

export type CategoriesQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type CategoriesQuery = {
  __typename?: "query_root";
  serviceCategory: Array<{
    __typename?: "serviceCategory";
    id: string;
    name: string;
    medspaServiceMenuItems: Array<{
      __typename?: "MedspaServiceMenuItem";
      id: string;
      name: string;
      price: string;
      isVariablePricing: boolean;
      isOnlineBookingEnabled: boolean;
    }>;
  }>;
};

export type CategoriesWithClientIndicationsQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
  clientId: Types.Scalars["bigint"]["input"];
}>;

export type CategoriesWithClientIndicationsQuery = {
  __typename?: "query_root";
  serviceCategory: Array<{
    __typename?: "serviceCategory";
    id: string;
    name: string;
    medspaServiceMenuItems: Array<{
      __typename?: "MedspaServiceMenuItem";
      clientGfeIndication?: string | null;
      appointmentType: string;
      id: string;
      name: string;
      price: string;
      isVariablePricing: boolean;
      isOnlineBookingEnabled: boolean;
    }>;
  }>;
};

export const CategoryFragmentDoc = gql`
  fragment Category on serviceCategory {
    id
    name
    medspaServiceMenuItems(
      where: { deletedAt: { _isNull: true }, medspaId: { _eq: $medspaId } }
      orderBy: { name: ASC }
    ) {
      id
      name
      price
      isVariablePricing
      isOnlineBookingEnabled
    }
  }
`;
export const CategoriesDocument = gql`
  query Categories($medspaId: bigint!) {
    serviceCategory(orderBy: { name: ASC }) {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options
  );
}
export function useCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options
  );
}
export function useCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options
  );
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<
  typeof useCategoriesLazyQuery
>;
export type CategoriesSuspenseQueryHookResult = ReturnType<
  typeof useCategoriesSuspenseQuery
>;
export type CategoriesQueryResult = Apollo.QueryResult<
  CategoriesQuery,
  CategoriesQueryVariables
>;
export const CategoriesWithClientIndicationsDocument = gql`
  query CategoriesWithClientIndications(
    $medspaId: bigint!
    $clientId: bigint!
  ) {
    serviceCategory(orderBy: { name: ASC }) {
      ...Category
      medspaServiceMenuItems(
        where: { deletedAt: { _isNull: true }, medspaId: { _eq: $medspaId } }
        orderBy: { name: ASC }
      ) {
        clientGfeIndication(args: { arg_client_id: $clientId })
        appointmentType
      }
    }
  }
  ${CategoryFragmentDoc}
`;

/**
 * __useCategoriesWithClientIndicationsQuery__
 *
 * To run a query within a React component, call `useCategoriesWithClientIndicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesWithClientIndicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesWithClientIndicationsQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useCategoriesWithClientIndicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategoriesWithClientIndicationsQuery,
    CategoriesWithClientIndicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CategoriesWithClientIndicationsQuery,
    CategoriesWithClientIndicationsQueryVariables
  >(CategoriesWithClientIndicationsDocument, options);
}
export function useCategoriesWithClientIndicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoriesWithClientIndicationsQuery,
    CategoriesWithClientIndicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CategoriesWithClientIndicationsQuery,
    CategoriesWithClientIndicationsQueryVariables
  >(CategoriesWithClientIndicationsDocument, options);
}
export function useCategoriesWithClientIndicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CategoriesWithClientIndicationsQuery,
    CategoriesWithClientIndicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CategoriesWithClientIndicationsQuery,
    CategoriesWithClientIndicationsQueryVariables
  >(CategoriesWithClientIndicationsDocument, options);
}
export type CategoriesWithClientIndicationsQueryHookResult = ReturnType<
  typeof useCategoriesWithClientIndicationsQuery
>;
export type CategoriesWithClientIndicationsLazyQueryHookResult = ReturnType<
  typeof useCategoriesWithClientIndicationsLazyQuery
>;
export type CategoriesWithClientIndicationsSuspenseQueryHookResult = ReturnType<
  typeof useCategoriesWithClientIndicationsSuspenseQuery
>;
export type CategoriesWithClientIndicationsQueryResult = Apollo.QueryResult<
  CategoriesWithClientIndicationsQuery,
  CategoriesWithClientIndicationsQueryVariables
>;
